import React, { useEffect, useState } from "react";

import styled from "styled-components";

interface Props {
  title?: string;
  description?: string;
  children: React.ReactNode;
}

export const ChartContainer: React.FC<Props> = (props) => {
  const { title, description, children } = props;
  return (
    <Container>
      {title && <Title className="heading-18-sb">{title}</Title>}
      {description && (
        <Description className="caption-12-rg">
          {description.split("|").map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </Description>
      )}
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid var(--light-gray-200, #edeeef);
  background: var(--light-background-contents-01, #fff);
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  padding: 24px;
`;

const Title = styled.div`
  color: var(--text-01, #1f2022);
`;

const Description = styled.div`
  color: var(--text-inactive, #bec0c2);
  margin-top: 3px;
  margin-bottom: 7px;
  display: flex;
  flex-direction: column;
`;
