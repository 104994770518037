import React from "react";
import { useMediaQuery } from "react-responsive";
import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";
import { MobileEvaluationDetail } from "./mobile/MobileEvaluationDetail";
import { DeskEvaluationDetail } from "./desktop/DeskEvaluationDetail";

const EvaluationDetail: React.FC<PortfolioNavigationDefaultProps> = (props) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobileEvaluationDetail {...props} />
  ) : (
    <DeskEvaluationDetail {...props} />
  );
};
export default EvaluationDetail;
