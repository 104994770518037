import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/locale"; // 한국어 설정

import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as Calendar } from "../../../../assets/image/icon_calendar.svg";
import { getNewDate } from "../../../../common/commonUtil";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  defaultDate?: string;
  isMonth?: boolean;
  width?: number | string;
  onChangeDate: (date: Date) => void;
}

const range = (start: number, end: number, diff: number) => {
  const year: number[] = [];
  for (let i = start; i < end; i += diff) {
    year.push(i);
  }
  return year;
};
const getYear = (date: Date) => date.getFullYear();
const getMonth = (date: Date) => date.getMonth();

export const PickerDate: React.FC<Props> = (props) => {
  const {
    isReadOnly,
    disabled,
    defaultDate,
    isMonth,
    width = "100%",
    onChangeDate,
  } = props;

  const years = range(1990, getYear(new Date()) + 10, 1);
  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];

  const datePicker = useRef<any>(null);
  const [selectedDate, updateDate] = useState<Date | undefined>(undefined);
  const onChangeTime = (value: Date) => {
    onChangeDate(value);
  };

  useEffect(() => {
    updateDate(defaultDate ? getNewDate(defaultDate) : undefined);
  }, [defaultDate]);

  return (
    <Container
      className={`body-14-rg ${disabled ? "disabled" : ""}`}
      style={{ width: width }}
    >
      <PickerWrappe
        className={`${isReadOnly ? "read__only" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        <StyledDatePicker
          className={`body-14-md ${disabled || isReadOnly ? "disabled" : ""}`}
          ref={datePicker}
          disabled={disabled || isReadOnly}
          onInputClick={() => {
            console.log("click");
          }}
          renderCustomHeader={
            isMonth
              ? undefined
              : ({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <DateHeader>
                    <ArrowBtn
                      type="button"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {"<"}
                    </ArrowBtn>
                    <Select
                      className="heading-16-sb"
                      value={getYear(date)}
                      onChange={({ target: { value } }) =>
                        changeYear(parseInt(value, 10))
                      }
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>

                    <Select
                      className="heading-16-sb"
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>

                    <ArrowBtn
                      type="button"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {">"}
                    </ArrowBtn>
                  </DateHeader>
                )
          }
          selected={selectedDate}
          placeholderText={isMonth ? "YYYY.MM" : "YYYY.MM.DD"}
          onChange={(newDate: Date) => {
            updateDate(newDate);
          }}
          onCalendarClose={() => {
            if (selectedDate) onChangeTime(selectedDate);
          }}
          locale={ko}
          withPortal
          showMonthYearPicker={isMonth}
          dateFormat={isMonth ? "yyyy.MM" : "yyyy.MM.dd"}
        />
        {!isReadOnly && !disabled && (
          <Calendar
            style={{
              position: "absolute",
              right: 12,
              minWidth: 16,
              minHeight: 16,
            }}
          />
        )}
      </PickerWrappe>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;

  transition: all 0.2s ease;

  &.disabled {
    background-color: var(--gray-50);
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    font-family: "SUIT Bold";
    font-size: 20px;
    letter-spacing: -1%;
  }

  .react-datepicker__month-wrapper {
    display: flex;
    flex-direction: row;
  }

  .react-datepicker__month-text {
    display: flex;
    flex-direction: row;
    width: 6rem;
    height: 6rem;
    align-items: center;
    justify-content: center;

    white-space: nowrap;
    font-family: "SUIT Regular";
    font-size: 20px;
    letter-spacing: -1%;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
    font-family: "SUIT Regular";
    font-size: 12px;
    letter-spacing: -1%;
  }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
    font-family: "SUIT Regular";
    font-size: 14px;
    letter-spacing: -1%;
  }

  .react-datepicker-time__header {
    display: none;
  }

  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-family: "SUIT SemiBold";
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -1%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: var(--blue-500);
    color: #fff;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--blue-500);
    color: white;
    font-weight: bold;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--dim-100);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }
`;

const PickerWrappe = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--gray-300);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 6px 12px;

  gap: 8px;

  cursor: pointer;

  &.read__only {
    padding: 6px 0px;
    cursor: default;
    border-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &.disabled {
    cursor: default;
    border-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  :hover {
    border: 1px solid var(--blue-200);
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  border: 0;

  background-color: transparent;
  color: var(--text-01);
  cursor: pointer;
  input {
    width: 100%;
  }
  &.disabled {
    cursor: default;
  }

  ::placeholder {
    color: var(--text-disabled);
  }
  :focus {
    outline: none;
  }
`;

const DateHeader = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Select = styled.select`
  padding: 8px 24px;
  border-radius: 4px;
  border: 0;
  :focus {
    outline: none;
  }
`;

const ArrowBtn = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;

  border: 1px solid var(--text-01);
  font-weight: 900;
  font-size: 20px;

  transition: all 0.2s ease;

  :hover {
    background-color: var(--button-disabled);
  }
`;
