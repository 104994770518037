import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Tree,
  NodeModel,
  DndProvider,
  MultiBackend,
  getBackendOptions,
} from "@minoru/react-dnd-treeview";

import {
  StructureHeader,
  StructureHeaderProps,
  StructureInfo,
} from "../interface/Structure.interface";
import { CustomNode } from "../components/treeview/CustomNode";

interface Props {
  structureData: NodeModel<StructureInfo>[];
  selectedNode?: NodeModel<StructureInfo>;
  headerList: StructureHeaderProps[];
  selectedHeader?: StructureHeader;
  onClickStructure: (target: NodeModel<StructureInfo>) => void;
  onClickStructureHeader: (header: StructureHeader) => void;
}

export const StructureNew: React.FC<Props> = (props) => {
  const {
    structureData,
    selectedNode,
    selectedHeader,
    headerList,
    onClickStructure,
    onClickStructureHeader,
  } = props;

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <StructureTreeContainer className="scroll__invisible">
        {headerList.map((item) => {
          if (item.isOptional) {
            return (
              item.count !== 0 && (
                <HeaderStructure
                  className={`body-14-sb ${
                    selectedHeader === item.type ? "selected" : ""
                  }`}
                  onClick={() => {
                    onClickStructureHeader(item.type);
                  }}
                >
                  {item.title} ({item.count})
                </HeaderStructure>
              )
            );
          } else {
            return (
              <HeaderStructure
                className={`body-14-sb ${
                  selectedHeader === item.type ? "selected" : ""
                }`}
                onClick={() => {
                  onClickStructureHeader(item.type);
                }}
              >
                {item.title} ({item.count})
              </HeaderStructure>
            );
          }
        })}
        <Tree
          tree={structureData}
          rootId={0}
          canDrag={() => false}
          canDrop={() => {
            false;
          }}
          render={(node, { depth, isOpen, onToggle }) => (
            <CustomNode
              node={node}
              depth={depth}
              isOpen={isOpen}
              isSelected={node.id === selectedNode?.id}
              hasChildren={
                structureData.filter((item) => item.parent === node.id).length >
                0
              }
              onToggle={onToggle}
              onSelect={onClickStructure}
            />
          )}
          onDrop={(tree) => {}}
          classes={{}}
          sort={false}
          insertDroppableFirst={false}
          dropTargetOffset={5}
        />
      </StructureTreeContainer>
    </DndProvider>
  );
};

const StructureTreeContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  max-height: 100%;

  padding: 24px;
  display: flex;
  flex-direction: column;

  ul {
    list-style: none;
  }

  .treeRoot {
    height: 100%;
  }
  .draggingSource {
    opacity: 0.3;
  }
  .placeholderContainer {
    position: relative;
  }
`;

const HeaderStructure = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  color: var(--text-01);

  &.selected {
    color: var(--primary-purple);
  }
`;
