import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";

interface Props {
  text: string;
  transfromPixel: (value: number) => number;
}

const UnreadTooltipBouncer: React.FC<Props> = (props) => {
  const style: React.CSSProperties = {
    borderRadius: props.transfromPixel(8),
    paddingTop: props.transfromPixel(8),
    paddingBottom: props.transfromPixel(8),
    paddingLeft: props.transfromPixel(12),
    paddingRight: props.transfromPixel(12),
  };
  return (
    <Container>
      <SpeechBubble
        className="caption-12-rg"
        style={style}
        transfromPixel={props.transfromPixel}
      >
        {props.text}
      </SpeechBubble>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;

  right: -50%;
  bottom: 130%;

  background: transparent;

  animation-name: bounce;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-5px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
`;

const SpeechBubble = styled.div<{ transfromPixel: (value: number) => number }>`
  position: relative;
  width: max-content;
  background-color: #1f2022;
  box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.1);

  color: #ffffff;

  ::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 50%;
    width: 0;
    height: 0;
    border: ${(props) => props.transfromPixel(8)}px solid transparent;
    border-top-color: #1f2022;
    border-bottom: 0;
    margin-left: -${(props) => props.transfromPixel(8)}px;
    margin-bottom: -${(props) => props.transfromPixel(8)}px;
  }
`;
export default UnreadTooltipBouncer;
