import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../components/navigation/NavigationLayout";
import MobileExcavationCreate from "./mobile/MobileExcavationCreate";
import DeskExcavationCreate from "./desktop/DeskExcavationCreate";

const ExcavationCreate = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobileExcavationCreate /> : <DeskExcavationCreate />}
    </NavigationLayout>
  );
};
export default ExcavationCreate;
