import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useInput, { InputType } from "../../useInput";
import { IAReportUser } from "../../../../../type/data";
import { ReactComponent as DefaultUser } from "../../../../../assets/image/default_user_img.svg";
import { ReactComponent as Crown } from "../../../../../assets/image/crown.svg";

interface Props {
  user: IAReportUser;
}
export const InvitedUser: React.FC<Props> = (props) => {
  const { user } = props;
  return (
    <Container>
      <AvatarContainer>
        {user.acUser.profileImgUrl ? (
          <img
            className="avatar"
            src={user.acUser.profileImgUrl}
            loading="lazy"
            alt={"profile img"}
          />
        ) : (
          <DefaultUser className="avatar" />
        )}
        {(user.isFundManager ||
          (user.priorityInfo &&
            [1, 2].includes(user.priorityInfo.priorityType))) && (
          <Crown
            className={
              user.priorityInfo?.priorityType === 1 || user.isFundManager
                ? "crown__fund__manager"
                : "crown__expert__manager"
            }
          />
        )}
      </AvatarContainer>
      <UserInfoContainer>
        <div className="heading-14-md">{user.acUser.name}</div>
        <div className="body-14-rg">
          {user.priorityInfo?.priorityType === 1 || user.isFundManager
            ? "대표펀드매니저"
            : user.priorityInfo?.priorityType === 2
            ? "전문투자심사위원"
            : "일반투자심사위원"}
        </div>
      </UserInfoContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  min-width: 196px;
  max-width: 196px;

  padding: 12px 16px;
  background: var(--background-contents01);
  border: 1px solid var(--gray-200);
  border-radius: 4px;

  gap: 12px;
`;

const AvatarContainer = styled.div`
  position: relative;
  width: clamp(48px, 100%, 48px);
  height: clamp(48px, 100%, 48px);

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    border: 1px solid var(--gray-200);
    border-radius: 50%;
  }

  .crown__fund__manager {
    position: absolute;
    bottom: 0;
    right: 0;
    path:nth-of-type(1) {
      fill: var(--primary-blue);
    }
  }

  .crown__expert__manager {
    position: absolute;
    bottom: 0;
    right: 0;
    path:nth-of-type(1) {
      fill: var(--blue-300);
    }
  }
`;

const UserInfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;
