import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import {
  DatabaseHistory,
  DBTotalSearchPortfolio,
  DPBPTotalSearch,
} from "../../../../../../type/data";
import DBResultListFunctionRow from "../../DBResultListFunctionRow";
import TotalSearchModal from "./TotalSearchModal";
import TotalSearchDatabaseGrid from "./TotalSearchDatabaseGrid";
import { getDatabaseTotalSearch } from "../../../../../../api/repository/database/DatabaseRepository";
import FilterTotalSearch from "../../../organisms/FilterTotalSearch";
import { setDatabaseHistory } from "../../../../../../common/pageHistoryUtil";
import {
  IsAllSelectedType,
  SearchValueType,
} from "../../../../interface/Database.interface";
import useMobileDatabase from "../../../hook/useMobileDatabase";
interface Props {
  history?: DatabaseHistory;
}

/**
 * 통합 검색 탭 화면
 *
 * 구성
 *  - 통합검색 조건 컴포넌트
 *  - 결과 Grid
 */
const TotalSearchTab: React.FC<Props> = (props) => {
  const [
    industrialTechs,
    phaseDictionary,
    participants,
    channels,
    searchObject,
    isAllSelectedObject,
    totalCount,
    countPerPage,
    totalPage,
    curPage,
    currentPortfolio,
    resultPortfolios,
    openPortfolioModal,
    updateCountPerPage,
    setSearchObject,
    updatePortfolioModal,
    updateCurPage,
    onClickPortfolio,
  ] = useMobileDatabase();

  return (
    <Container>
      {/* 통합검색 탭 검색 컴포넌트 부분*/}
      <FilterTotalSearch
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        isAllSelectedObject={isAllSelectedObject}
        history={props.history}
        industrialTechs={industrialTechs}
        phaseDictionary={phaseDictionary}
        participants={participants}
        channels={channels}
      />

      <ResultContainer>
        <DBResultListFunctionRow
          totalCount={totalCount}
          countPerPage={countPerPage}
          curPage={searchObject.curPage || 1}
          totalPage={totalPage}
          resultPortfolioListCount={
            resultPortfolios ? resultPortfolios.portfolio.length : 0
          }
          updateCountPerPage={updateCountPerPage}
          updateCurPage={(updatedPage: number) => {
            setSearchObject((prev) => ({
              ...prev,
              curPage: updatedPage,
            }));
          }}
          searchKeyword={searchObject.keyword}
        />
        <div style={{ height: "100%" }}>
          {resultPortfolios && (
            <TotalSearchDatabaseGrid
              resultList={resultPortfolios}
              totalPage={totalPage}
              curPage={curPage}
              updateCurPage={updateCurPage}
              onClickPortfolio={onClickPortfolio}
            />
          )}
        </div>
      </ResultContainer>
      {resultPortfolios && currentPortfolio.current && openPortfolioModal && (
        <TotalSearchModal
          isOpen={openPortfolioModal}
          close={() => {
            updatePortfolioModal(false);
          }}
          portfolioInfo={currentPortfolio.current}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ResultContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 87px;
  gap: 4px;
`;
export default TotalSearchTab;
