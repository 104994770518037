import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";

import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import { DefaultButton } from "lattice_core";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { ReactComponent as Trash } from "../../../../../assets/image/icon_trash.svg";
import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";

import { ReactComponent as Radio } from "../../../../../assets/image/icon_radio.svg";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import {
  AcInvestmentOrganization,
  OrganizationType,
  StakeHolderType,
} from "../../../../../type/data";
import { LPInfoProps } from "../interface/FundAccount.interface";
import ModalLayout from "../../../../../components/modal/ModalLayout";
import ConfirmModal from "../../../../../2.0lattice/components/modal/ConfirmModal";

interface Props {
  investmentOrganizations: AcInvestmentOrganization[];
  createInvestmentOrganization: (
    newData: LPInfoProps,
    callback: (result: { success: boolean; message?: string }) => void
  ) => void;
  modifyInvestmentOrganization: (
    newData: LPInfoProps,
    callback: (result: { success: boolean; message?: string }) => void
  ) => void;
  isUsedOrganizationStatus: (id: number) => boolean;
  deleteInvestmentOrganization: (
    newData: LPInfoProps,
    callback: (result: { success: boolean; message?: string }) => void
  ) => void;
}

export const InvestmentOrganizationModal: React.FC<Props> = (props) => {
  const { investmentOrganizations } = props;

  const selectedTarget = useRef<LPInfoProps | undefined>(undefined);

  const [createModal, setCreateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const newOrganization = useRef<LPInfoProps | undefined>(undefined);
  const [render, setRender] = useState<boolean>(false);

  const reRender = () => setRender((prev) => !prev);

  const EmptyComponent = (text: string) => {
    return (
      <EmptyContainer className="body-14-md">
        <div>{text}</div>
      </EmptyContainer>
    );
  };

  const ItemComponent = (
    item: AcInvestmentOrganization,
    index: number,
    isUsed: boolean
  ) => {
    const getLPData = (item: AcInvestmentOrganization): LPInfoProps => {
      return {
        acInvestmentOrganizationId: item.acInvestmentOrganizationId,
        type: 1,
        name: item.name,
        stakeType: item.stakeHolder!.type,
        uniqueNumber: item.stakeHolder?.uniqueNumber,
        managerName: item.managerName,
        managerEmail: item.managerEmail,
        managerPhone: item.managerPhone,
      };
    };
    return (
      <CardContainer
        key={index}
        onClick={() => {
          if (createModal) return;
          newOrganization.current = getLPData(item);
          setCreateModal((prev) => !prev);
        }}
      >
        <div className="body-14-rg text__wrapper">
          <FlexRow style={{ gap: 6, alignItems: "center" }}>
            <CustomColorLabel
              bgColor={isUsed ? "var(--primary-purple)" : "var(--gray-700)"}
              textColor={isUsed ? "var(--white)" : "var(--white)"}
            >
              {isUsed ? "사용중" : "미사용"}
            </CustomColorLabel>
            <span className="heading-16-sb" style={{ maxWidth: 300 }}>
              {item.name}
            </span>
            <span className="caption-12-sb" style={{ color: "var(--text-03)" }}>
              {item.stakeHolder?.uniqueNumber || "없음"}
            </span>
          </FlexRow>
          <FlexRow className="caption-12-sb" style={{ gap: 24 }}>
            {item.stakeHolder?.type === 2 && (
              <FlexRow style={{ gap: 8 }}>
                <span style={{ color: "var(--text-05)" }}>담당자</span>
                <span>{item.managerName || "없음"}</span>
              </FlexRow>
            )}

            <FlexRow style={{ gap: 8 }}>
              <span style={{ color: "var(--text-05)" }}>이메일</span>
              <span>{item.managerEmail || "없음"}</span>
            </FlexRow>

            <FlexRow style={{ gap: 8 }}>
              <span style={{ color: "var(--text-05)" }}>전화번호</span>
              <span>{item.managerPhone || "없음"}</span>
            </FlexRow>
          </FlexRow>
          {!isUsed && (
            <div className="delete__btn">
              <Trash
                onClick={(e) => {
                  e.stopPropagation();
                  selectedTarget.current = getLPData(item);
                  setDeleteModal(true);
                }}
              />
            </div>
          )}
        </div>
      </CardContainer>
    );
  };

  return (
    <Container>
      <Header>
        <span className="heading-18-sb">LP관리</span>
        <DefaultButton
          className="heading-14-sb"
          sizeType="xxsmall"
          buttonType="text"
          buttonColor={createModal ? "var(--primary-purple)" : "var(--text-03)"}
          onClick={() => {
            newOrganization.current = { type: 1 };
            setCreateModal((prev) => !prev);
          }}
          text="생성하기"
        />
      </Header>
      <Contents>
        <FlexColumn>
          <div className="heading-14-sb">기관</div>
          {investmentOrganizations
            .filter(
              (item) => item.stakeHolder?.type === StakeHolderType.CORPORATION
            )
            .map((item, index) => {
              return ItemComponent(
                item,
                index,
                item.acInvestmentOrganizationId
                  ? props.isUsedOrganizationStatus(
                      item.acInvestmentOrganizationId
                    )
                  : false
              );
            })}
          {investmentOrganizations.filter(
            (item) => item.stakeHolder?.type === StakeHolderType.CORPORATION
          ).length === 0 && EmptyComponent("기관 LP가 없습니다.")}
        </FlexColumn>
        <FlexColumn>
          <div className="heading-14-sb">개인</div>
          {investmentOrganizations
            .filter(
              (item) => item.stakeHolder?.type === StakeHolderType.INDIVIDUAL
            )
            .map((item, index) => {
              return ItemComponent(
                item,
                index,
                item.acInvestmentOrganizationId
                  ? props.isUsedOrganizationStatus(
                      item.acInvestmentOrganizationId
                    )
                  : false
              );
            })}
          {investmentOrganizations.filter(
            (item) => item.stakeHolder?.type === StakeHolderType.INDIVIDUAL
          ).length === 0 && EmptyComponent("개인 LP가 없습니다.")}
        </FlexColumn>
      </Contents>
      <BottomModal className={createModal ? "open" : ""}>
        {createModal && newOrganization.current && (
          <CreateContainer>
            <div className="create__header">
              <span className="heading-16-sb">
                LP{" "}
                {newOrganization.current.acInvestmentOrganizationId ===
                undefined
                  ? "생성"
                  : "수정"}
              </span>
              <div className="close">
                <Close
                  className="close__icon"
                  onClick={() => {
                    setCreateModal(false);
                  }}
                />
              </div>
            </div>
            <RowTitleBox
              required
              title="기관유형"
              titleWidth={160}
              style={{ marginTop: 24 }}
            >
              <CheckboxContainer className="body-14-rg">
                <div
                  className="check__item"
                  onClick={() => {
                    if (newOrganization.current) {
                      newOrganization.current.stakeType =
                        StakeHolderType.CORPORATION;
                      reRender();
                    }
                  }}
                >
                  <Radio
                    className={
                      newOrganization.current.stakeType ===
                      StakeHolderType.CORPORATION
                        ? "checked"
                        : ""
                    }
                  />
                  <span>기관</span>
                </div>

                <div
                  className="check__item"
                  onClick={() => {
                    if (newOrganization.current) {
                      newOrganization.current.stakeType =
                        StakeHolderType.INDIVIDUAL;
                      reRender();
                    }
                  }}
                >
                  <Radio
                    className={
                      newOrganization.current.stakeType ===
                      StakeHolderType.INDIVIDUAL
                        ? "checked"
                        : ""
                    }
                  />
                  <span>개인</span>
                </div>
              </CheckboxContainer>
            </RowTitleBox>
            {newOrganization.current.stakeType && (
              <RowTitleBox
                required
                title={
                  newOrganization.current.stakeType ===
                  StakeHolderType.CORPORATION
                    ? "기관명"
                    : "이름"
                }
                titleWidth={160}
                style={{ marginTop: 6 }}
              >
                <InputBox
                  width={332}
                  placeholder="이름을 입력하세요."
                  defaultValue={newOrganization.current.name}
                  onChange={(value) => {
                    if (newOrganization.current) {
                      newOrganization.current.name = value;
                      reRender();
                    }
                  }}
                />
              </RowTitleBox>
            )}

            {newOrganization.current.stakeType && (
              <RowTitleBox
                required
                title={
                  newOrganization.current.stakeType ===
                  StakeHolderType.CORPORATION
                    ? "사업자 번호"
                    : "주민등록 번호"
                }
                titleWidth={160}
                style={{ marginTop: 6 }}
              >
                <InputBox
                  width={332}
                  placeholder="이름을 입력하세요."
                  textType={
                    newOrganization.current.stakeType ===
                    StakeHolderType.CORPORATION
                      ? "businessNumber"
                      : "residentRegistrationNumber"
                  }
                  defaultValue={newOrganization.current.uniqueNumber}
                  onChange={(value) => {
                    if (newOrganization.current) {
                      newOrganization.current.uniqueNumber = value;
                      reRender();
                    }
                  }}
                />
              </RowTitleBox>
            )}

            {newOrganization.current.stakeType &&
              newOrganization.current.stakeType ===
                StakeHolderType.CORPORATION && (
                <RowTitleBox
                  title={"담당자"}
                  titleWidth={160}
                  style={{ marginTop: 6 }}
                >
                  <InputBox
                    width={332}
                    placeholder="이름을 입력하세요."
                    defaultValue={newOrganization.current.managerName}
                    onChange={(value) => {
                      if (newOrganization.current) {
                        newOrganization.current.managerName = value;
                        reRender();
                      }
                    }}
                  />
                </RowTitleBox>
              )}
            {newOrganization.current.stakeType && (
              <RowTitleBox
                title={"연락처"}
                titleWidth={160}
                style={{ marginTop: 6 }}
              >
                <InputBox
                  width={332}
                  placeholder="010-XXXX-XXXX"
                  textType="phone"
                  defaultValue={newOrganization.current.managerPhone}
                  onChange={(value) => {
                    if (newOrganization.current) {
                      newOrganization.current.managerPhone = value;
                      reRender();
                    }
                  }}
                />
              </RowTitleBox>
            )}
            {newOrganization.current.stakeType && (
              <RowTitleBox
                title={"이메일"}
                titleWidth={160}
                style={{ marginTop: 6 }}
              >
                <InputBox
                  width={332}
                  placeholder="이메일을 입력해주세요."
                  defaultValue={newOrganization.current.managerEmail}
                  onChange={(value) => {
                    if (newOrganization.current) {
                      newOrganization.current.managerEmail = value;
                      reRender();
                    }
                  }}
                />
              </RowTitleBox>
            )}

            <div className="submit__btn">
              <DefaultButton
                className="heading-16-md"
                sizeType="small"
                text={
                  newOrganization.current.acInvestmentOrganizationId ===
                  undefined
                    ? "생성"
                    : "수정"
                }
                buttonColor="var(--primary-purple)"
                hoverColor="var(--purple-700)"
                disabled={
                  newOrganization.current === undefined ||
                  newOrganization.current.stakeType === undefined ||
                  newOrganization.current.name === undefined ||
                  newOrganization.current.uniqueNumber === undefined
                }
                onClick={() => {
                  if (newOrganization.current) {
                    if (newOrganization.current.acInvestmentOrganizationId) {
                      props.modifyInvestmentOrganization(
                        newOrganization.current,
                        (result) => {
                          if (result.success) {
                            setCreateModal(false);
                          } else {
                            alert(result.message || "");
                          }
                        }
                      );
                    } else {
                      props.createInvestmentOrganization(
                        newOrganization.current,
                        (result) => {
                          if (result.success) {
                            setCreateModal(false);
                          } else {
                            alert(result.message || "");
                          }
                        }
                      );
                    }
                  }
                }}
              />
            </div>
          </CreateContainer>
        )}
      </BottomModal>

      {deleteModal &&
        selectedTarget.current &&
        !props.isUsedOrganizationStatus(
          selectedTarget.current.acInvestmentOrganizationId || 0
        ) && (
          <ModalLayout
            isFullScreen
            isOpen={deleteModal}
            toggle={() => {
              setDeleteModal(false);
              selectedTarget.current = undefined;
            }}
          >
            <ConfirmModal
              type="confirm"
              contents={[
                `LP "${selectedTarget.current.name || ""}"를`,
                "삭제하시겠습니까?",
              ]}
              onClickPositiveBtn={() => {
                if (selectedTarget.current) {
                  props.deleteInvestmentOrganization(
                    selectedTarget.current,
                    (result) => {
                      if (result.success) {
                        setDeleteModal(false);
                      } else {
                        alert(result.message || "");
                      }
                    }
                  );
                }
              }}
              onClickNegativeBtn={() => {
                setDeleteModal(false);
                selectedTarget.current = undefined;
              }}
            />
          </ModalLayout>
        )}
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div(
  () => css`
    width: 100%;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    border-bottom: 1px solid var(--gray-300);

    .warnning {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ffc800;
    }
  `
);

const Contents = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  gap: 44px;

  padding: 28px 40px;

  .group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    .single__item {
      flex: 1;
    }
    .double__item {
      flex: 2;
    }
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  .text__wrapper {
    position: relative;
    flex: 9;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px 12px;

    gap: 8px;

    border-radius: 4px;
    border: 1px solid var(--gray-300);
    background-color: var(--white);
    transition: all 0.2s ease;

    cursor: pointer;

    .delete__btn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 32px;
      height: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      transition: all 0.2s ease;
      :hover {
        background-color: var(--gray-400);
      }
    }
    :hover {
      background-color: var(--gray-200);
    }
  }
`;
const BottomModal = styled.div`
  position: absolute;
  width: 100%;
  height: 500px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  bottom: -510px;
  left: 0;

  background-color: var(--white);
  border-top: 1px solid var(--gary-200);

  box-shadow: 0 -5px 5px -5px rgba(100, 103, 109, 0.4);
  transition: all 0.25s ease;

  &.open {
    bottom: 0;
  }
`;

const CreateContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;

  .create__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .close {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .close__icon {
      cursor: pointer;
    }
  }

  .submit__btn {
    position: absolute;
    left: 0;
    bottom: 84px;
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 24px;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 20px;

  .check__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .checked {
      path {
        fill: var(--primary-purple);
      }
    }
  }
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--text-04);
`;
