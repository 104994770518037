import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  showParticipantNum?: number;
  participants?: {
    pfParticipantId: number;
    acUser: {
      acUserId: number;
      name: string;
    };
  }[];
}

const PortfolioParticipant: React.FC<Props> = (props) => {
  if (!props.participants || props.participants.length === 0)
    return <span className="body-14-rg">-</span>;
  return (
    <Container>
      <ParticipantContainer className="body-14-rg">
        <span>
          {props.participants
            .slice(0, props.showParticipantNum || 1)
            .map((item) => item.acUser.name)
            .join(", ")}
        </span>
        {props.participants.length - (props.showParticipantNum || 1) > 0 && (
          <CircleNumber>
            +{props.participants.length - (props.showParticipantNum || 1)}
          </CircleNumber>
        )}
      </ParticipantContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  margin-top: 6px;
`;

const ParticipantContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1;
  gap: 10px;
`;

const CircleNumber = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 1;
  font-size: 10px;

  background-color: #f0f0f0;
  color: #828282;
`;
export default PortfolioParticipant;
