import React, { useEffect, useRef, useState } from "react";
import {
  DESC,
  NotificationCardProps,
  NotificationGroupProps,
  NotificationStatus,
} from "../interface/Notification.interface";
import {
  deleteAllreadedNotification,
  deleteNotification,
  getAllNotification,
  readedNotification,
} from "../../../api/repository/user/UserRepository";
import { NotificationHistory, SelectorProps } from "../../../type/data";
import {
  getMyInfo,
  showCustomToast,
  showToast,
} from "../../../common/commonUtil";
import { useNavigate } from "react-router-dom";
import { readNotificationById } from "../../../api/api";
import {
  getNotificationHistory,
  setNotificationHistory,
} from "../../../common/pageHistoryUtil";
import { Bounce } from "react-toastify";
import { getFinanceDataEmptyList } from "../../../api/repository/portfolio/PortfolioRepository";

const useNotification = (
  onChangeNewNoti: (exist: boolean) => void,
  closeRightModal: () => void
) => {
  const TASK = 1;
  const NOTI = 2;

  const myInfo = getMyInfo();
  const navigate = useNavigate();
  const timeOutId = useRef<number | undefined>(undefined);
  const [toastMessege, updateToastMessage] = useState<string | undefined>(
    undefined
  );
  const [selectedOrder, setSelectedOrder] = useState<string>("");
  const [selectedOrderNoti, setSelectedOrderNoti] = useState<number>(DESC);
  const [tabIndex, updateTabIndex] = useState<number>(TASK);
  const [notiGroup, setNotiGorup] = useState<SelectorProps[]>([]);
  const [myTask, setMyTask] = useState<NotificationCardProps[]>([]);
  const [newTaskCount, setNewTaskCount] = useState<number>(0);
  const [notification, setNotification] = useState<NotificationCardProps[]>([]);
  const [newNotiCount, setNewNotiCount] = useState<number>(0);

  const [doneFinanceDataInput, setDoneFinanceDataInput] =
    useState<boolean>(true);

  const [loaded, setLoaded] = useState<boolean>(false);

  const onClickMyTaskTab = () => {
    if (tabIndex === TASK) return;
    updateTabIndex(TASK);
  };

  const onClickNotificationTab = () => {
    if (tabIndex === NOTI) return;
    updateTabIndex(NOTI);
  };

  const setNotiHistory = (data: NotificationHistory) => {
    setNotificationHistory(data);
  };

  const onChangeOrder = (data: SelectorProps) => {
    setSelectedOrder(data.text);
    setNotiHistory({
      myTask: { order: data.text },
      noti: { order: selectedOrderNoti },
    });
  };

  const onChangeNotiOrder = (data: SelectorProps) => {
    setSelectedOrderNoti(data.id);
    setNotiHistory({
      myTask: { order: selectedOrder },
      noti: { order: data.id },
    });
  };

  const goTolink = async (
    card: NotificationCardProps,
    isNotification?: boolean
  ) => {
    if (isNotification) {
      const result = await readedNotification(card.id);
      if (result) {
        init();
        closeRightModal();
        navigate(card.link);
      }
    } else {
      init();
      closeRightModal();
      navigate(card.link);
    }
  };

  const deleteNotificationById = async (id: number) => {
    const result = await deleteNotification(id);
    if (result) {
      updateToastMessage("알림이 삭제 되었습니다 :)");

      init();
    }
  };

  const deleteReadNotification = async () => {
    const result = await deleteAllreadedNotification();
    if (result) {
      updateToastMessage("읽은 알림이 모두 삭제되었습니다 :)");
      // showToast({
      //   customElement: (
      //     <span className="heading-16-sb" style={{ lineHeight: 1 }}>
      //       읽은 알림이 모두 삭제되었습니다 :)
      //     </span>
      //   ),
      //   backgroundColor: "var(--gray-800)",
      //   color: "var(--text-02)",
      //   width: 260,
      //   height: 44,
      //   milliSec: 150000,
      //   transition: Bounce,
      //   position: "bottom-right",
      //   toastId: new Date().toString(),
      // });
      init();
    }
  };

  const getFilteredMyTask = () => {
    const filteredData = myTask.filter(
      (item) => item.status === NotificationStatus.PENDING
    );
    const orderedData = filteredData.filter(
      (item) => item.category.group.name === selectedOrder
    );
    const otherData = filteredData.filter(
      (item) => item.category.group.name !== selectedOrder
    );

    return [...orderedData, ...otherData];
  };
  const getMyTaskInProgress = () => {
    const filteredData = myTask.filter(
      (item) => item.status === NotificationStatus.IN_PROGRESS
    );

    return filteredData;
  };

  const getOrderedNotification = () => {
    const orderBy = (a: NotificationCardProps, b: NotificationCardProps) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateA.getTime() - dateB.getTime();
    };

    const orderedNotification = notification.sort(orderBy);
    if (selectedOrderNoti === DESC) {
      return orderedNotification.reverse();
    }

    return orderedNotification;
  };

  const getFinanceDataInput = async () => {
    const result = await getFinanceDataEmptyList();
    if (result !== undefined && result.length > 0) {
      setDoneFinanceDataInput(false);
    }
  };

  const init = async () => {
    const result = await getAllNotification();

    const notiHistory = getNotificationHistory();

    console.log(notiHistory);

    if (notiHistory) {
      if (notiHistory.myTask?.order) {
        setSelectedOrder(notiHistory.myTask.order || "");
      }
      if (notiHistory.noti?.order) {
        setSelectedOrderNoti(notiHistory.noti.order);
      }
    }

    if (result) {
      setNotiGorup(
        result.group
          .map((item) => ({
            id: item.id,
            text: item.name,
            data: item,
          }))
          .filter((item) => {
            if (myInfo.authorityId === 1) {
              return [1, 2, 4, 5].includes(item.id);
            }
            if (myInfo.authorityId === 2) {
              return [3, 4].includes(item.id);
            }
            return false;
          })
      );
      setMyTask(result.task);
      setNewTaskCount(
        result.task.filter((item) => item.status === NotificationStatus.PENDING)
          .length
      );
      setNotification(result.notification);
      setNewNotiCount(
        result.notification.filter(
          (item) => item.status === NotificationStatus.PENDING
        ).length
      );
    }

    setLoaded(true);
  };

  useEffect(() => {
    if (toastMessege) {
      console.log("토스트 메세지 이씅ㅁ");
      setTimeout(() => {
        console.log("토스트 메세지 삭제함");
        updateToastMessage(undefined);
      }, 1500);
    }
  }, [toastMessege]);

  useEffect(() => {
    onChangeNewNoti(
      newTaskCount > 0 || newNotiCount > 0 || !doneFinanceDataInput
    );
  }, [newTaskCount, newNotiCount]);

  useEffect(() => {
    init();
    //재무정보 입력 했는지 임식적인것
    getFinanceDataInput();
  }, []);

  return [
    doneFinanceDataInput,
    myInfo,
    toastMessege,
    loaded,
    tabIndex,
    notiGroup,
    newTaskCount,
    newNotiCount,
    selectedOrder,
    selectedOrderNoti,
    onClickMyTaskTab,
    onClickNotificationTab,
    onChangeOrder,
    onChangeNotiOrder,
    goTolink,
    deleteNotificationById,
    deleteReadNotification,
    getFilteredMyTask,
    getMyTaskInProgress,
    getOrderedNotification,
  ] as const;
};

export default useNotification;
