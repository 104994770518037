import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IAFinancingPlan } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";
import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { RemoveComma, getYMD } from "../../../../../../common/commonUtil";
import { CustomTextArea } from "../../table/custom-cell/CustomTextArea";
import {
  halfTypeList,
  capitalIncreaseTypeList,
} from "../../../interface/REI.interface";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IAFinancingPlan[];
  onDataChange: (data: IAFinancingPlan[]) => void;
}

export const FinancingPlanTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<IAFinancingPlan[] | undefined>(
    undefined
  );

  const headers: TableGridHeaderProps[] = [
    {
      header: "일자",
      key: "year",
      width: 140,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="number"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="YYYY"
            onBlur={(value) => {
              if (!dataList) return;
              const numValue =
                value.length > 0 ? parseInt(value, 10) : undefined;
              rowData.year = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "상반기/하반기",
      key: "halfType",
      width: 160,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultData={data}
            selectList={halfTypeList}
            placehoder="선택"
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.halfType = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },

    {
      header: "자금 조달 방법",
      key: "capitalIncreaseType",
      width: 184,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultData={data}
            selectList={capitalIncreaseTypeList}
            placehoder="선택"
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.capitalIncreaseType = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },

    {
      header: "금액 (억 원)",
      key: "capitalIncreaseAmount",
      width: 160,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1,000"
            onBlur={(value) => {
              if (!dataList) return;

              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.capitalIncreaseAmount = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "비고 (옵션)",
      key: "note",
      width: 482,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="투자기관명, 시리즈 등"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.note = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newData: IAFinancingPlan = {};
    const newDataList: IAFinancingPlan[] = [...(dataList || []), newData];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    updateDataList(props.dataList);
  }, [props.dataList]);

  useEffect(() => {
    if (dataList) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
