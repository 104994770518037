import { UserInfo } from "../../../../type/data";
import { DealFlowPhase } from "../../../deal-flow/interface/DealFlow.interface";
export type PortfolioPhase =
  | "EXCAVATION"
  | "MANAGE"
  | "EXIT"
  | "DROP"
  | "DAMAGE"
  | "CLOSURE";

export enum DealFlowStatus {
  OPEN = "OPEN",
  CANCEL = "CANCEL",
  CONTRACT = "CONTRACT",
  COMPLETE = "COMPLETE",
}

export interface PortfolioNavigationDefaultProps {
  portfolioId: number;
  portfolioNavInfo: PortfolioNavigationProps;
  isEditable?: boolean;
  emptyData?: string[];
  duplicateData?: string[];
  init: () => void; // 초기화
  setCurrentPageName?: (name: string) => void;
}

export interface PortfolioNavigationProps {
  portfolioId: number;
  companyName: string;
  companyImgUrl?: string;
  companyImgKey?: string;
  pfIndustrialTech: {
    pfIndustrialTechId: number;
    acIndustrialTech: {
      acIndustrialTechId: number;
      icon: string;
      name: string;
    };
  }[];
  pfPhase: {
    type: PortfolioPhase;
  };
  dealFlowId?: number;
  phase?: DealFlowPhase;
  mainParticipant?: {
    id: number;
    name: string;
  };
  subParticipant?: {
    id: number;
    name: string;
  }[];
  riskStatus?: {
    id: number;
    name: string;
  };
}

export interface PortfolioNavEmptyDuplicateProps {
  duplicate: string[];
  empty: string[];
}

export interface PortfolioRoutePath {
  pageName: string;
  pageAddress: string;
}
