import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { IndustryTech } from "../../../../type/data";
import DatabaseChip from "../component/DatabaseChip";
import { SearchValueType } from "./FilterTotalSearch";

interface Props {
  width?: number;
  isAliginCenter?: boolean;
  selectedIndustrialTechs?: IndustryTech[];
  isAllIndustrySelected: boolean;
  onSelectedIndustrialTechs: (data: any[]) => void;
  setIsAllIndustrySelected: React.Dispatch<React.SetStateAction<boolean>>;
  industrialTechs: IndustryTech[];
}
const DatabaseIndustrialTechs = ({
  width,
  isAliginCenter,
  selectedIndustrialTechs,
  isAllIndustrySelected,
  onSelectedIndustrialTechs,
  setIsAllIndustrySelected,
  industrialTechs,
}: Props) => {
  const allTech: IndustryTech = {
    acIndustrialTechId: 0,
    name: "All",
  };

  const didMount = useRef<boolean>(false);

  const onClickIndustrialTech = (data: any) => {
    // IndustryTech
    // 1. All 클릭
    if (data.name === "All") {
      onSelectedIndustrialTechs(industrialTechs);
      setIsAllIndustrySelected(true);
    }
    // 2. All 이 아닐 때
    else {
      if (isAllIndustrySelected) {
        onSelectedIndustrialTechs([data]);
        setIsAllIndustrySelected(false);
      } else {
        if (!selectedIndustrialTechs) return;

        const includesMeArray = selectedIndustrialTechs.filter(
          (selectedTech) =>
            selectedTech.acIndustrialTechId === data.acIndustrialTechId
        );
        const notIncludesMeArray = selectedIndustrialTechs.filter(
          (selectedItem) =>
            selectedItem.acIndustrialTechId !== data.acIndustrialTechId
        );
        if (includesMeArray.length > 0) {
          // 선택되었으면 체크 풀기
          if (notIncludesMeArray.length === 0) {
            // 나 빼고 다 해제되어 있는 상태에서 나 까지 해제하면 전체 해제 => 전체 선택이 되게 해달라는 요청사항
            onSelectedIndustrialTechs(industrialTechs);
            setIsAllIndustrySelected(true);
          } else if (notIncludesMeArray) {
            // 나 빼고 선택되어 있는 것들이 있는 상태에서 나도 이미 선택되어 있으면 해제
            onSelectedIndustrialTechs(notIncludesMeArray);
            setIsAllIndustrySelected(false);
          }
        } else {
          // 선택되어 있지 않으면 체크하기
          onSelectedIndustrialTechs([...selectedIndustrialTechs, data]);
          setIsAllIndustrySelected(
            selectedIndustrialTechs.length + 1 === industrialTechs.length
          );
        }
      }
    }
  };
  useEffect(() => {
    if (selectedIndustrialTechs && selectedIndustrialTechs.length === 0) {
      onSelectedIndustrialTechs(industrialTechs);
      setIsAllIndustrySelected(true);
    }
  }, [selectedIndustrialTechs]);

  return (
    <Container width={width} isAliginCenter={isAliginCenter}>
      {industrialTechs &&
        [allTech, ...industrialTechs].map((industry) => (
          <DatabaseChip
            type={
              (industry.name === "All" && isAllIndustrySelected) ||
              (industry.name !== "All" &&
                (selectedIndustrialTechs?.filter(
                  (selectedTech) =>
                    selectedTech.acIndustrialTechId ===
                    industry.acIndustrialTechId
                ).length || 0) > 0)
                ? "industry-selected"
                : "industry-unselected"
            }
            label={industry.name}
            data={industry} // IndustryTech
            key={industry.name}
            onClickChip={(data) => onClickIndustrialTech(data)}
          />
        ))}
    </Container>
  );
};

const Container = styled.div(
  ({
    width,
    isAliginCenter,
  }: {
    width?: number;
    isAliginCenter?: boolean;
  }) => css`
    width: ${width ? `${width}px` : "100%"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${isAliginCenter ? "center" : "flex-start"};

    flex-flow: row wrap;
    column-gap: 4px;
    row-gap: 4px;
  `
);

export default DatabaseIndustrialTechs;
