import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DefaultButton } from "lattice_core";
import { getStockChangeListInfo } from "../../../../../api/repository/portfolio/PortfolioRepository";
import {
  AddComma,
  RemoveComma,
  addCommasToIntegerPart,
} from "../../../../../common/commonUtil";
import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import CheckedText from "../../../../../components-2.0v/check-box/CheckedText";

interface Props {
  reportDocumentId: number;
  cancel: () => void;
  stockChangeListConfirm: (
    reportDocumentId: number,
    issuingPrice: number
  ) => void;
}

export interface StockChangeListData {
  acFundAccount: {
    name: string;
    nickName: string;
    registrationNumber: string;
    percentage: number;
  }[];
  stockInfo: {
    issuingPrice?: number;
    totalStockNumber: number;
  };
}

const StockChangeListConfirmModal = (props: Props) => {
  const { reportDocumentId, cancel, stockChangeListConfirm } = props;
  const [stockChangeListInfo, setStockChangeListInfo] = useState<
    StockChangeListData | undefined
  >(undefined);
  const [issuingPrice, updateIssuingPrice] = useState<number | undefined>(
    undefined
  );

  const [checked, updateChecked] = useState<boolean>(false);

  const init = async () => {
    const data = await getStockChangeListInfo(reportDocumentId);
    if (data) {
      setStockChangeListInfo(data);
      updateIssuingPrice(data.stockInfo.issuingPrice || 0);
    }
  };
  useEffect(() => {
    init();
  }, []);

  if (!stockChangeListInfo) return null;

  return (
    <Container>
      <Header className="body-14-sb">
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          확정하기
        </span>
      </Header>
      <Body className="body-14-md">
        <SectorContainer style={{ gap: 16 }}>
          {stockChangeListInfo.stockInfo.issuingPrice === undefined ? (
            <div className="body-14-md">
              주당 가격을 특정할 수 없어 입력이 필요합니다.
              <br />
              투자 내용을 확인해 주세요.
            </div>
          ) : (
            <div className="body-14-md">투자 내용을 확인해 주세요.</div>
          )}
          <FlexRow
            className="heading-14-md"
            style={{ color: "var(--text-04)" }}
          >
            <div style={{ flex: 6 }}>투자 재원</div>
            <div style={{ flex: 4, textAlign: "end" }}>지분율</div>
          </FlexRow>
          {stockChangeListInfo.acFundAccount.map((item) => {
            return (
              <FlexRow
                className="body-14-rg"
                style={{ color: "var(--text-01)" }}
              >
                <div style={{ flex: 6 }}>{item.nickName}</div>
                <div style={{ flex: 4, textAlign: "end" }}>
                  {item.percentage}%
                </div>
              </FlexRow>
            );
          })}
        </SectorContainer>
        <Divider />
        <SectorContainer>
          <FlexRow style={{ height: 40 }}>
            <div
              className="heading-14-md"
              style={{ flex: 4, color: "var(--text-04)" }}
            >
              주당 가격
            </div>
            {stockChangeListInfo.stockInfo.issuingPrice !== undefined ? (
              <div
                className="body-14-rg"
                style={{ color: "var(--text-01)", flex: 6, textAlign: "end" }}
              >
                {addCommasToIntegerPart(issuingPrice)} 원
              </div>
            ) : (
              <FlexRow
                className="body-14-rg"
                style={{
                  width: "fit-content",
                  color: "var(--text-01)",
                  gap: 4,
                }}
              >
                <InputBox
                  textType="numberWithComma"
                  width={180}
                  textAlign="end"
                  placeholder="주당 가격을 입력해주세요."
                  onChange={(value) => {
                    console.log(value);
                    updateIssuingPrice(parseFloat(RemoveComma(value)));
                  }}
                />
                원
              </FlexRow>
            )}
          </FlexRow>
          <FlexRow style={{ height: 40 }}>
            <div
              className="heading-14-md"
              style={{ flex: 4, color: "var(--text-04)" }}
            >
              총 발행 주식 수
            </div>
            <div
              className="body-14-rg"
              style={{ color: "var(--text-01)", flex: 6, textAlign: "end" }}
            >
              {addCommasToIntegerPart(
                stockChangeListInfo.stockInfo.totalStockNumber
              )}{" "}
              주
            </div>
          </FlexRow>
          <FlexRow style={{ height: 40 }}>
            <div
              className="heading-14-md"
              style={{ flex: 4, color: "var(--text-04)" }}
            >
              기업 가치
            </div>
            {issuingPrice ? (
              <div
                className="body-14-rg"
                style={{ color: "var(--text-01)", flex: 6, textAlign: "end" }}
              >
                {addCommasToIntegerPart(
                  issuingPrice * stockChangeListInfo.stockInfo.totalStockNumber
                )}{" "}
                원
              </div>
            ) : (
              <div
                className="body-14-rg"
                style={{ color: "var(--text-01)", flex: 6, textAlign: "end" }}
              >
                기업 가치는 자동 계산 됩니다.
              </div>
            )}
          </FlexRow>

          <FlexRow style={{ height: 40, gap: 4 }}>
            <CheckedText
              isChecked={checked}
              onClick={() => {
                updateChecked((prev) => !prev);
              }}
            >
              위 내용을 확인하였습니다.
            </CheckedText>
          </FlexRow>
        </SectorContainer>
        <Divider />
      </Body>
      <Footer>
        <DefaultButton
          className="heading-14-md"
          buttonType="text"
          sizeType="xsmall"
          buttonColor={"#1F2022"}
          text={"취소"}
          onClick={() => {
            cancel();
          }}
        />

        <DefaultButton
          className="heading-14-md"
          buttonType="filled"
          sizeType="xsmall"
          buttonColor={"#173AFF"}
          hoverColor={"#112CBF"}
          text={"확정"}
          disabled={
            !checked || issuingPrice === undefined || issuingPrice === 0
          }
          onClick={() => {
            if (issuingPrice) {
              stockChangeListConfirm(reportDocumentId, issuingPrice);
            }
          }}
        />
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  background: var(--background-contents01);
  width: 420px;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 7px 24px;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SectorContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 36px;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-200, #edeeef);
`;

const Footer = styled.div`
  padding: 10px 24px 20px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

export default StockChangeListConfirmModal;
