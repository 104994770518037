import React from "react";

import styled from "styled-components";

import {
  StakeHolderType,
  StockHolderGrouping,
} from "../../../interface/InvestmentInfo.interface";
import {
  AddComma,
  residentRegistrationNumberMaskStar,
} from "../../../../../../common/commonUtil";

interface Props {
  index: number;
  data: StockHolderGrouping;
}
export const StockholderRow: React.FC<Props> = ({ index, data }) => {
  return (
    <Container className={`caption-12-rg`}>
      <FlexRow style={{ justifyContent: "space-between" }}>
        <StockholderName>{data.name}</StockholderName>(
        {data.countryId === 30 && data.stakeType === StakeHolderType.INDIVIDUAL
          ? residentRegistrationNumberMaskStar(data.registrationNumber)
          : data.registrationNumber}
        )
      </FlexRow>
      <FlexColumn>
        {data.stockData.map((stock) => {
          return (
            <FlexRow
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                {stock.stockType.name} : {AddComma(stock.stockNumber)}주
              </div>
              <div>지분율 : {stock.percentage}%</div>
            </FlexRow>
          );
        })}
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background-contents01, #fff);
  padding: 4px 12px;
  border-bottom: 1px solid var(--divider-200);
`;

const StockholderName = styled.div`
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
