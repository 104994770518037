import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartDataset,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { OperatingFundProps } from "../../interface/PerformanceMetrics.interface";
import {
  AddComma,
  RemoveComma,
  addCommasToIntegerPart,
} from "../../../../../common/commonUtil";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  fundData?: OperatingFundProps;
}
export const FundAccountUsedRate: React.FC<Props> = (props) => {
  const { fundData } = props;
  const DIVISION_VALUE = 100000000;
  const [originCount, setOriginCount] = useState<number>(0);
  const [originAmount, setOriginAmount] = useState<number>(0);
  const [fundCount, setFundCount] = useState<number>(0);
  const [fundAmount, setFundAmount] = useState<number>(0);

  const centerTextPluginCount = {
    id: "centerTextPluginCount",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      ctx.save();
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = "10px SUIT";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#7E8086";
      ctx.fillText("단위 : 건수", centerX, centerY);
      ctx.restore();
    },
  };

  const centerTextPluginPrice = {
    id: "centerTextPluginPrice",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      ctx.save();
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = "10px SUIT";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#7E8086";
      ctx.fillText("단위 : 금액", centerX, centerY);
      ctx.restore();
    },
  };
  const labels = ["본계정", "펀드"];

  useEffect(() => {
    const _originCount = fundData?.orig.length;
    const _originAmount = fundData?.orig.reduce((p, c) => p + c, 0);

    const _fundCount = fundData?.fund.length;
    const _fundAmount = fundData?.fund.reduce((p, c) => p + c, 0);

    setOriginCount(_originCount || 0);
    setOriginAmount(_originAmount || 0);
    setFundCount(_fundCount || 0);
    setFundAmount(_fundAmount || 0);
  }, [fundData]);

  return (
    <Container>
      <TitleContainer className="heading-18-sb">재원</TitleContainer>

      <BodyContainer>
        <FlexRow
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FlexColumn style={{ gap: 20 }}>
            <ProgressItem textColor="var(--skyblue-500)">
              <div className="heading-14-sb sub__title">본계정</div>
              <div className="heading-20-b">
                {originCount > 0 && fundCount > 0
                  ? ((originCount / (originCount + fundCount)) * 100).toFixed(0)
                  : 0}
                %
              </div>
            </ProgressItem>

            <ProgressItem textColor="var(--skyblue-200)">
              <div className="heading-14-sb sub__title">펀드</div>
              <div className="heading-20-b">
                {originCount > 0 && fundCount > 0
                  ? ((fundCount / (originCount + fundCount)) * 100).toFixed(0)
                  : 0}
                %
              </div>
            </ProgressItem>
          </FlexColumn>
          <div style={{ width: 130, height: 130 }}>
            <Doughnut
              style={{ display: "inline-block" }}
              data={{
                labels,
                datasets: [
                  {
                    label: "",
                    data: [originCount, fundCount],
                    backgroundColor: [
                      "rgba(39, 151, 255, 1)",
                      "rgba(205, 231, 255, 1)",
                    ],
                    borderColor: [
                      "rgba(39, 151, 255, 1)",
                      "rgba(205, 231, 255, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                    text: "Chart.js Bar Chart",
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        const count = tooltipItem.formattedValue;
                        return `${AddComma(count)}건 `;
                      },
                    },
                  },
                },
                devicePixelRatio: 2,
                cutout: 40,
              }}
              plugins={[centerTextPluginCount]}
            />
          </div>
        </FlexRow>
        <FlexRow
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FlexColumn style={{ gap: 20 }}>
            <ProgressItem textColor="var(--skyblue-500)">
              <div className="heading-14-sb sub__title">본계정</div>
              <div className="heading-20-b">
                {originAmount > 0 && fundAmount > 0
                  ? (
                      (originAmount / (originAmount + fundAmount)) *
                      100
                    ).toFixed(0)
                  : 0}
                %
              </div>
            </ProgressItem>

            <ProgressItem textColor="var(--skyblue-200)">
              <div className="heading-14-sb sub__title">펀드</div>
              <div className="heading-20-b">
                {originAmount > 0 && fundAmount > 0
                  ? ((fundAmount / (originAmount + fundAmount)) * 100).toFixed(
                      0
                    )
                  : 0}
                %
              </div>
            </ProgressItem>
          </FlexColumn>
          <div style={{ width: 130, height: 130 }}>
            <Doughnut
              style={{ display: "inline-block" }}
              data={{
                labels,
                datasets: [
                  {
                    label: "",
                    data: [originAmount, fundAmount],
                    backgroundColor: [
                      "rgba(39, 151, 255, 1)",
                      "rgba(205, 231, 255, 1)",
                    ],
                    borderColor: [
                      "rgba(39, 151, 255, 1)",
                      "rgba(205, 231, 255, 1)",
                    ],
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                    text: "Chart.js Bar Chart",
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        console.log(tooltipItem);
                        const amount =
                          parseInt(
                            `${RemoveComma(tooltipItem.formattedValue)}`
                          ) / DIVISION_VALUE;

                        return `${addCommasToIntegerPart(amount.toFixed(2))}억`;
                      },
                    },
                  },
                },
                devicePixelRatio: 2,
                cutout: 40,
              }}
              plugins={[centerTextPluginPrice]}
            />
          </div>
        </FlexRow>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 16px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 0 12px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--gray-300);
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text-05);
  margin-top: 4px;
`;

const ProgressItem = styled.div<{ textColor: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  color: ${(props) => props.textColor};

  .sub__title {
    color: var(--text-04);
  }

  ::before {
    position: absolute;
    content: " ";
    width: 4px;
    height: calc(100% - 10px);
    left: 0;
    top: 4px;
    background-color: ${(props) => props.textColor};
  }
`;
