import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

interface Props {
  isReadOnly?: boolean;
  placeholder?: string;
  textClassName?: string;
  maxLength?: number;
  defaultData?: string;
  onChangeData?: (data: string) => void;
}

export const CustomTextArea: React.FC<Props> = (props) => {
  const {
    isReadOnly,
    placeholder,
    textClassName = "body-14-rg",
    maxLength = 1000,
    defaultData,
    onChangeData,
  } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaValue, setTextareaValue] = useState<string>("");

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      console.log(textareaRef);
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    setTextareaValue(defaultData || "");
  }, [defaultData]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [textareaValue]);

  return (
    <Container>
      <TextArea
        className={`${textClassName} ${isReadOnly ? "read__only" : ""}`}
        ref={textareaRef}
        value={textareaValue}
        readOnly={props.isReadOnly}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(e) => {
          !isReadOnly && handleTextareaChange(e);
        }}
        onBlur={() =>
          !isReadOnly && onChangeData && onChangeData(textareaValue)
        }
        style={{ resize: "none", overflow: "hidden" }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px;
`;
const TextArea = styled.textarea`
  width: 100%;
  min-height: 30px;
  border: 1px solid var(--gray-300);
  padding: 6px 12px;

  border-radius: 4px;

  &.read__only {
    border: 1px solid transparent;
    padding: 9px 0px;

    :focus {
      outline: none;
      border: 1px solid transparent;
    }
  }
  ::placeholder {
    color: var(--text-inactive);
  }
  :focus {
    outline: none;
    border: 1px solid var(--blue-300);
  }
`;
