import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartOptions,
  ChartDataset,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { NewInvestmentProps } from "../../interface/PerformanceMetrics.interface";
import {
  AddComma,
  RemoveComma,
  getNewDate,
} from "../../../../../common/commonUtil";
import { ItemTypes } from "@minoru/react-dnd-treeview";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  isTotal?: boolean;
  newInvestment?: NewInvestmentProps[];
}
export const NewInvestment: React.FC<Props> = (props) => {
  const DIVISION_VALUE = 100000000;

  const TotalColor = "rgba(69, 132, 255, 1)";
  const DefaultColor = [
    "rgba(240, 247, 255, 1)",
    "rgba(232, 240, 255, 1)",
    "rgba(209, 227, 255, 1)",
    "rgba(186, 213, 255, 1)",
    "rgba(156, 195, 255, 1)",
    "rgba(156, 195, 255, 1)",
    "rgba(87, 144, 255, 1)",
    "rgba(29, 106, 255, 1)",
    "rgba(0, 86, 252, 1)",
    "rgba(0, 73, 214, 1)",
    "rgba(247, 245, 255, 1)",
    "rgba(239, 237, 255, 1)",
    "rgba(222, 217, 255, 1)",
    "rgba(201, 193, 255, 1)",
    "rgba(174, 161, 255, 1)",
    "rgba(142, 125, 250, 1)",
    "rgba(126, 106, 254, 1)",
    "rgba(106, 85, 244, 1)",
    "rgba(82, 63, 207, 1)",
    "rgba(64, 50, 158, 1)",
    "rgba(239, 249, 245, 1)",
    "rgba(216, 248, 235, 1)",
    "rgba(189, 242, 221, 1)",
    "rgba(122, 224, 184, 1)",
    "rgba(59, 214, 153, 1)",
    "rgba(40, 190, 131, 1)",
    "rgba(24, 158, 105, 1)",
    "rgba(18, 129, 86, 1)",
    "rgba(15, 103, 68, 1)",
    "rgba(15, 103, 68, 1)",
  ];
  const { newInvestment = [] } = props;
  const arr = useRef<
    {
      totalValue: number;
      totalCount: number;
    }[]
  >([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [barData, setBarData] = useState<ChartDataset<"bar">[]>([]);

  useEffect(() => {
    const dataset: ChartDataset<"bar">[] = [];
    arr.current = [
      { totalValue: 0, totalCount: 0 },
      { totalValue: 0, totalCount: 0 },
      { totalValue: 0, totalCount: 0 },
      { totalValue: 0, totalCount: 0 },
    ];
    let amount = 0;

    const getQuarterData = (amount: number, date: string) => {
      const quarterData = [0, 0, 0, 0];
      quarterData[Math.floor(getNewDate(date).getMonth() / 3)] = amount;
      arr.current[Math.floor(getNewDate(date).getMonth() / 3)].totalValue +=
        amount;
      arr.current[Math.floor(getNewDate(date).getMonth() / 3)].totalCount++;
      return quarterData;
    };
    newInvestment.forEach((invest) => {
      invest.investmentAccount.forEach((account) => {
        amount += account.investmentAmount;

        const quarterData = getQuarterData(
          account.investmentAmount,
          account.paymentDate
        );

        if (!props.isTotal) {
          dataset.push({
            label: invest.companyName,
            data: quarterData,
          });
        }
      });
    });

    if (props.isTotal) {
      dataset.push({
        label: "",
        data: arr.current.map((item) => item.totalValue),
      });
    }
    console.log(arr);

    setTotalAmount(amount);
    setTotalCount(newInvestment.length);
    setBarData(
      dataset.map((item, index) => ({
        ...item,
        backgroundColor: props.isTotal
          ? TotalColor
          : DefaultColor[index % DefaultColor.length],
      }))
    );
  }, [newInvestment]);

  return (
    <Container>
      <TitleContainer className="heading-18-sb">신규투자</TitleContainer>
      <BodyContainer>
        <FlexColumn style={{ height: "100%" }}>
          <FlexRow
            style={{
              width: "100%",
              gap: 36,
            }}
          >
            <TextItem textColor="var(--blue-500)">
              <div className="heading-14-sb sub__title">신규투자금액</div>
              <div className="heading-20-b">
                {AddComma((totalAmount / DIVISION_VALUE).toFixed(0))}억
              </div>
            </TextItem>
            <TextItem textColor="var(--blue-500)">
              <div className="heading-14-sb sub__title">신규투자건수</div>
              <div className="heading-20-b">{AddComma(totalCount)}건</div>
            </TextItem>
          </FlexRow>
          <div style={{ flex: 3, height: "100%" }}>
            <div style={{ marginTop: 16, height: "100%" }}>
              <Chart
                type="bar"
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: props.isTotal
                      ? {
                          callbacks: {
                            label: (tooltipItem) => {
                              console.log(tooltipItem);
                              const tooltip = `신규투자금액 : ${(
                                parseInt(
                                  RemoveComma(tooltipItem.formattedValue)
                                ) / 100000000
                              ).toFixed(2)}억 (${
                                arr.current[tooltipItem.dataIndex].totalCount
                              }건) `;
                              return tooltip;
                            },
                          },
                        }
                      : undefined,
                  },
                  devicePixelRatio: 2,
                  datasets: {
                    bar: {
                      barPercentage: 0.6,
                      categoryPercentage: 0.5,
                    },
                  },

                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                      grid: {
                        display: false,
                      },
                      ticks: {
                        callback: (value, index, ticks) => {
                          if (barData.length === 0) return undefined;
                          const dataValue = parseInt(`${value}`);
                          if (index === 0) {
                            return `${dataValue / DIVISION_VALUE} (억)`;
                          }
                          return AddComma(dataValue / DIVISION_VALUE);
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: ["1분기", "2분기", "3분기", "4분기"],
                  datasets: barData,
                }}
                height={125}
              />
            </div>
          </div>
        </FlexColumn>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextItem = styled.div<{ textColor: string }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  color: ${(props) => props.textColor};

  .sub__title {
    color: var(--text-04);
  }
`;
