import React from "react";
import styled from "styled-components";
import { getYMDKo } from "../../../../../common/commonUtil";

interface Props {
  investmentDay: number;
  operationDay: number;
  addOperatingDay: number;
  toDay: number;
  fundStartDate: string;
  fundEndDate: string;
  fundInvestmentDate: string;
  addEndDate?: string | null;
}
export const FundDurationChart: React.FC<Props> = (props) => {
  const {
    investmentDay,
    operationDay,
    addOperatingDay,
    toDay,
    fundStartDate,
    fundEndDate,
    fundInvestmentDate,
    addEndDate,
  } = props;

  return (
    <Container>
      <Wrapper>
        <ChartContainer>
          <InvestmentBar
            style={{
              width: `${
                (investmentDay /
                  (investmentDay + operationDay + addOperatingDay)) *
                100
              }%`,
            }}
          />
          <OperatingBar
            style={{
              width: `${
                (operationDay /
                  (investmentDay + operationDay + addOperatingDay)) *
                100
              }%`,
            }}
          />
          <AddOperatingBar
            style={{
              width: `${
                (addOperatingDay /
                  (investmentDay + operationDay + addOperatingDay)) *
                100
              }%`,
            }}
          />
          <TodayBar
            style={{
              width: `${
                toDay / (investmentDay + operationDay + addOperatingDay) >= 1
                  ? 99.9
                  : (toDay / (investmentDay + operationDay + addOperatingDay)) *
                    100
              }%`,
            }}
          />
          <Tip
            className="caption-10-md"
            style={{ left: 0, transform: "translateX(-50%)" }}
          >
            {getYMDKo(fundStartDate).slice(2)}
          </Tip>

          <Tip
            className="caption-10-md"
            style={{
              left: `calc(${
                (investmentDay /
                  (investmentDay + operationDay + addOperatingDay)) *
                100
              }%)`,
              transform: "translateX(-50%)",
            }}
          >
            {getYMDKo(fundInvestmentDate).slice(2)}
          </Tip>
        </ChartContainer>
        <Tip
          className="caption-10-md"
          style={{
            right: 4,
            transform: "translateX(18%)",
          }}
        >
          {getYMDKo(addEndDate ? addEndDate : fundEndDate).slice(2)}
        </Tip>
      </Wrapper>
      <LegendContainer>
        <Legend className="caption-10-md">
          <div
            className="legend__box"
            style={{ backgroundColor: "var(--blue-400)" }}
          />
          <span>현재 진행 기간</span>
        </Legend>
        <Legend className="caption-10-md">
          <div
            className="legend__box"
            style={{ backgroundColor: "var(--blue-200)" }}
          />
          <span>투자기간</span>
        </Legend>
        <Legend className="caption-10-md">
          <div
            className="legend__box"
            style={{ backgroundColor: "var(--yellow-200)" }}
          />
          <span>운영기간</span>
        </Legend>
        <Legend className="caption-10-md">
          <div
            className="legend__box"
            style={{ backgroundColor: "var(--green-200)" }}
          />
          <span>청산연장</span>
        </Legend>
      </LegendContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ChartContainer = styled.div`
  position: relative;
  width: calc(100% - 48px);
  height: 28px;
  display: flex;
  flex-direction: row;
`;

const InvestmentBar = styled.div`
  height: 100%;
  background-color: var(--blue-200);
`;

const OperatingBar = styled.div`
  height: 100%;
  background-color: var(--yellow-200);
`;

const AddOperatingBar = styled.div`
  height: 100%;
  background-color: var(--green-200);
`;

const TodayBar = styled.div`
  position: absolute;
  height: 50%;
  background-color: var(--blue-400);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Tip = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: calc(100% + 10px);

  color: var(--gray-700);

  ::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 6px;
    top: -10px;
    background-color: var(--gray-500);
  }
`;

const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  margin-top: 34px;
  padding-right: 26px;
  gap: 10px;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--gray-700);

  .legend__box {
    width: 12px;
    min-width: 12px;
    max-width: 12px;
    height: 12px;
    min-width: 12px;
    max-width: 12px;
  }
`;
