import React, { useEffect } from "react";

import { BoxComponent } from "../../../../../components-2.0v/box/BoxComponent";
import RowTitleBox from "../../../../../components/box/RowTitleBox";

import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import { PickerDate } from "../../../../../components-2.0v/date-picker/PickerDate";
import { StartupInfoProps } from "../../../info/interface/PortfolioInfo.interface";
import { ChangeDataProps, ErrorMSG } from "../../hook/usePortfolioInfoModify";
import AddressSearch from "../../../../../components-2.0v/address-search/AddressSearch";
import { getYMD } from "../../../../../common/commonUtil";
import { DealFlowPhase } from "../../../../deal-flow/interface/DealFlow.interface";

interface Props {
  startupInfo: StartupInfoProps;
  phase?: DealFlowPhase;
  errorMSGArr: ErrorMSG[];
  onChangeData: (data: ChangeDataProps) => void;
}
export const StartupInfoEdit: React.FC<Props> = (props) => {
  const { startupInfo, phase, errorMSGArr, onChangeData } = props;

  useEffect(() => {
    console.log(errorMSGArr);
  }, [errorMSGArr]);

  useEffect(() => {
    console.log(startupInfo);
  }, []);
  return (
    <BoxComponent>
      <RowTitleBox title="기업명" required titleWidth={128}>
        <InputBox
          defaultValue={startupInfo.companyName}
          placeholder="법인등기부등본상 법인명과 일치하도록 입력해 주세요."
          isShowValidationMsg={
            errorMSGArr.find((item) => item.type === "companyName") !==
            undefined
          }
          validationMsg={
            errorMSGArr.find((item) => item.type === "companyName")?.value || ""
          }
          onBlur={(value) => {
            onChangeData({ type: "companyName", value: value });
          }}
        />
      </RowTitleBox>
      <RowTitleBox
        title="대표 이사"
        required={phase && phase === "investment"}
        titleWidth={128}
      >
        <InputBox
          defaultValue={startupInfo.ceoName}
          placeholder="대표 이사 성함을 입력해 주세요."
          onBlur={(value) => {
            onChangeData({ type: "ceoName", value: value });
          }}
        />
      </RowTitleBox>
      <RowTitleBox
        title="핸드폰 번호"
        required={phase && phase === "investment"}
        titleWidth={128}
      >
        <InputBox
          defaultValue={startupInfo.ceoTel}
          placeholder="- 를 포함해 입력해 주세요."
          textType="phone"
          isShowValidationMsg={
            errorMSGArr.find((item) => item.type === "ceoTel") !== undefined
          }
          validationMsg={
            errorMSGArr.find((item) => item.type === "ceoTel")?.value || ""
          }
          onBlur={(value) => {
            onChangeData({ type: "ceoTel", value: value });
          }}
        />
      </RowTitleBox>
      <RowTitleBox
        title="이메일"
        required={phase && phase === "investment"}
        titleWidth={128}
      >
        <InputBox
          defaultValue={startupInfo.ceoEmail}
          placeholder="기업의 이메일을 입력해 주세요."
          isShowValidationMsg={
            errorMSGArr.find((item) => item.type === "ceoEmail") !== undefined
          }
          validationMsg={
            errorMSGArr.find((item) => item.type === "ceoEmail")?.value || ""
          }
          onBlur={(value) => {
            onChangeData({ type: "ceoEmail", value: value });
          }}
        />
      </RowTitleBox>

      <RowTitleBox
        title="주소"
        required={phase && phase === "investment"}
        titleWidth={128}
      >
        <AddressSearch
          defaultValue={startupInfo.companyAddress}
          placeholder="도로명, 지번, 건물명으로 검색해 주세요."
          onChange={(value) => {
            onChangeData({ type: "companyAddress", value: value });
          }}
        />
      </RowTitleBox>
      <RowTitleBox
        title="사업자 등록번호"
        required={phase && phase === "investment"}
        titleWidth={128}
      >
        <InputBox
          defaultValue={startupInfo.businessNumber}
          placeholder="- 를 포함해 입력해 주세요."
          isShowValidationMsg={
            errorMSGArr.find((item) => item.type === "businessNumber") !==
            undefined
          }
          validationMsg={
            errorMSGArr.find((item) => item.type === "businessNumber")?.value ||
            ""
          }
          onBlur={(value) => {
            onChangeData({ type: "businessNumber", value: value });
          }}
        />
      </RowTitleBox>
      <RowTitleBox
        title="설립일"
        required={phase && phase === "investment"}
        titleWidth={128}
      >
        <PickerDate
          defaultDate={startupInfo.establishDate}
          onChangeDate={(date) => {
            onChangeData({ type: "establishDate", value: getYMD(date) });
          }}
        />
      </RowTitleBox>
    </BoxComponent>
  );
};
