import React from "react";
import styled from "styled-components";
import { Cookies } from "react-cookie";
import { Link } from "react-router-dom";
import { ReactComponent as Check } from "../../../assets/image/checkbox_checked.svg";
import { ReactComponent as CheckBox } from "../../../assets/image/checkbox.svg";
import {
  checkEmailForm,
  checkPublicEmailForm,
} from "../../../common/commonUtil";

import ModalLayout from "../../../components/modal/ModalLayout";
import { removeAllPageHistory } from "../../../common/pageHistoryUtil";
import { removeAllLoginToken } from "../../../api/api";
import LoginInputBox from "./components/LoginInputBox";
import ConfirmModal from "../../../2.0lattice/components/modal/ConfirmModal";
import { DefaultButton } from "lattice_core";
import config from "../../../config";
import useLogin from "../hook/useLogin";
import { GoogleLoginBtn } from "./components/GoogleLoginBtn";

export const Logout = () => {
  const cookie = new Cookies();
  cookie.remove("isAutoLogin");
  cookie.remove("myInfo");

  removeAllLoginToken();
  removeAllPageHistory();
  window.location.replace("/");
};

const MobileLogin = () => {
  const [
    email,
    password,
    isChecked,
    modalOpen,
    isLoggedIn,
    modalMessage,
    loginErrorModal,
    isLoginOtherDomain,
    updateEmail,
    updatePassword,
    submitLogin,
    onChangeAutoLogin,
    onLoginLattice,
    handleSignInClick,
    updateModalOpen,
    setLoginErrorModal,
    setLoginOtherDomain,
  ] = useLogin();

  return (
    <Wrapper className="scroll__invisible">
      <Box>
        <div className={"login-container"}>
          <div
            className={"logo-64-hv"}
            style={{ textAlign: "center", color: "var(--primary-blue)" }}
          >
            Lattice
          </div>

          <LoginInputBox
            defaultValue={email}
            placeholder="이메일을 입력하세요."
            validationMsg="이메일 형식이 올바르지 않습니다."
            style={{ marginTop: 50 }}
            onChange={(e) => {
              updateEmail(e.target.value);
            }}
            onBlur={(e) => {
              updateEmail(e.target.value);
            }}
            onEnterPress={(value) => {
              updateEmail(value);
            }}
            isShowValidationMsg={!checkEmailForm(email)}
            transfromPixel={(n) => n}
          />
          <LoginInputBox
            isPassword
            defaultValue={password}
            style={{ marginTop: !checkEmailForm(email) ? 8 : 16 }}
            placeholder="비밀번호"
            onChange={(e) => {
              updatePassword(e.target.value);
            }}
            onEnterPress={(value) => {
              updatePassword(value);
              if (
                email.length > 0 &&
                checkEmailForm(email) &&
                value.length > 0
              ) {
                submitLogin();
              }
            }}
            isShowValidationMsg={false}
            transfromPixel={(n) => n}
          />
          <div className="check-container" style={{ marginTop: 16 }}>
            <div onClick={() => onChangeAutoLogin()}>
              {isChecked ? (
                <Check width={24} height={18} />
              ) : (
                <CheckBox width={24} height={24} />
              )}
              <label className="font-regular-14" style={{ paddingBottom: 2 }}>
                로그인 상태 유지하기
              </label>
            </div>
          </div>

          <div style={{ marginTop: 40 }}>
            <DefaultButton
              className="heading-16-md"
              buttonType="filled"
              sizeType="large"
              buttonColor="var(--primary-blue)"
              hoverColor="var(--blue-600)"
              disabled={
                email.length === 0 ||
                password.length === 0 ||
                !checkEmailForm(email) ||
                checkPublicEmailForm(email)
              }
              onClick={submitLogin}
              text="로그인"
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <GoogleLoginBtn
              isLoggedIn={isLoggedIn}
              onLoginLattice={onLoginLattice}
              handleSignInClick={handleSignInClick}
            />
          </div>

          <div className={"footer-container"}>
            <Link to="/admin/login" className={"font-regular-14"}>
              Admin Login으로 전환
            </Link>
          </div>
          <ModalLayout isOpen={modalOpen} toggle={() => updateModalOpen(false)}>
            <ConfirmModal
              title="로그인"
              type="alert"
              contents={[modalMessage]}
              onClickPositiveBtn={() => updateModalOpen(false)}
              transfromPixel={(n) => n}
            />
          </ModalLayout>
          {loginErrorModal && (
            <ModalLayout
              isOpen={loginErrorModal}
              toggle={() => setLoginErrorModal(false)}
              isFullScreen
            >
              <ConfirmModal
                type="alert"
                title="로그인 실패"
                contents={[
                  "이메일 혹은 비밀번호가 일치하지 않습니다.",
                  "다시 확인해주세요.",
                ]}
                positiveTitle="확인"
                onClickPositiveBtn={() => setLoginErrorModal(false)}
                transfromPixel={(n) => n}
              />
            </ModalLayout>
          )}

          {isLoginOtherDomain && (
            <ModalLayout
              isOpen={isLoginOtherDomain}
              toggle={() => setLoginOtherDomain(false)}
              isFullScreen
            >
              <ConfirmModal
                type="confirm"
                title="로그인 실패"
                contents={[
                  `${config.companyName} 임직원이 사용하는 시스템 입니다.`,
                  "포트폴리오사용 래티스로 연결할 까요?",
                ]}
                positiveTitle="연결"
                negativeTitle="취소"
                onClickPositiveBtn={() => {
                  const startupLatticeUrl = config.portfolioClientUrl;
                  console.log(startupLatticeUrl);
                  if (startupLatticeUrl) {
                    window.location.replace(startupLatticeUrl);
                  }
                }}
                onClickNegativeBtn={() => setLoginOtherDomain(false)}
                transfromPixel={(n) => n}
              />
            </ModalLayout>
          )}
        </div>
      </Box>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--withe);
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: white;

  .login-container {
    width: calc(fit-content + 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 24px;

    .subtitle-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 24px;
    }

    & > span.font-medium-20 {
      display: flex;
      width: 240px;
      white-space: normal;
      margin-bottom: 24px;
      align-self: center;
    }

    .check-container {
      width: 100%;
      display: flex;
      align-items: center;
      & > div {
        height: 24px;
        display: flex;
        gap: 8px;
        align-items: center;
        color: var(--grey_50);
        & > label {
          cursor: pointer;
        }
      }
    }

    .footer-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      & > a {
        color: var(--grey_50);
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
`;

export default MobileLogin;
