import React, { useRef, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { GridHeaderProps } from "./StockHolderGrid";

interface Props {
  header: GridHeaderProps;
  cellData: any;
  rowHeight: number;
  isLast?: boolean;
  rowIndex: number;
}

const StockHolderGridDataCell: React.FC<Props> = ({
  header,
  cellData,
  rowHeight,
  rowIndex,
}) => {
  const cellRef = useRef<HTMLDivElement>(null);
  return (
    <Container
      ref={cellRef}
      style={{
        width: header.width || 100,
        minWidth: header.width || 100,
        height: rowHeight,
        minHeight: rowHeight,
        maxHeight: rowHeight,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {/* 수정 불가 상태 text 만있는거 */}
      <CellContainer
        className="font-medium-14"
        style={{
          height: rowHeight,
          minHeight: rowHeight,
          maxHeight: rowHeight,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {header.customCell ? (
          header.customCell(cellData, rowIndex)
        ) : (
          <div>
            {`${cellData}`.includes("|")
              ? `${cellData}`
                  .split("|")
                  .map((item, index) => <span key={index}>{item}</span>)
              : `${cellData}`}
          </div>
        )}
      </CellContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: normal;
  word-wrap: normal;
`;
const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;

  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #e3e4e5;
  &.last {
    border-right: 0;
  }
`;
export default React.memo(StockHolderGridDataCell);
