import React from "react";

import styled from "styled-components";
import { CapitalRaisingProps } from "../../../portfolio-viewer-stock-change-history/interface/type";
import {
  addCommasToIntegerPart,
  getNewDate,
  getYMD,
} from "../../../../common/commonUtil";

interface Props {
  index?: number;
  isSelected: boolean;
  data: CapitalRaisingProps;
  onClick?: () => void;
}
export const CapitalRaisingRow: React.FC<Props> = ({
  index = 0,
  isSelected,
  data,
  onClick,
}) => {
  const height = 40;
  return (
    <Container
      className={`body-14-rg ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <CellConatiner
        width={60}
        height={height}
        style={{ justifyContent: "center" }}
      >
        {index + 1}
      </CellConatiner>
      <CellConatiner
        width={120}
        height={height}
        style={{ justifyContent: "center" }}
      >
        {getYMD(getNewDate(data.date))}
      </CellConatiner>

      <CellConatiner
        width={200}
        height={height}
        style={{ justifyContent: "center" }}
      >
        {data.capitalRaisingType?.name}({data.capitalRaisingType.acronym})
      </CellConatiner>

      <CellConatiner
        width={500}
        height={height}
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        {data.pfStakeholder?.name}
      </CellConatiner>

      <CellConatiner
        width={200}
        height={height}
        style={{ justifyContent: "flex-end" }}
      >
        {addCommasToIntegerPart(data.amount)}
      </CellConatiner>
      <CellConatiner
        width={150}
        height={height}
        style={{ justifyContent: "center" }}
      >
        {data.round.name}
      </CellConatiner>

      <CellConatiner
        width={200}
        height={height}
        style={{
          justifyContent: "center",
          borderRight: "0.5px solid transparent",
        }}
      >
        {data.note}
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  width: max-content;
  height: 100%;
  height: max-content;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  background-color: var(--background-contents01, #fff);
  cursor: pointer;
  :hover {
    background-color: var(--hover-100);
  }

  &.selected {
    background-color: var(--blue-50);
    :hover {
      background-color: var(--blue-50);
    }
  }
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  min-height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  color: var(--text-01);
  border-right: 0.5px solid var(--divider-300, #e3e4e5);
  border-bottom: 0.5px solid var(--divider-300, #e3e4e5);
  padding: 8px 10px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
