import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../components/navigation/NavigationLayout";
import MobileDatabase from "./mobile/MobileDatabase";
import DeskDatabase from "./desktop/DeskDatabase";

const Database = () => {
  // const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobileDatabase /> : <DeskDatabase />}
    </NavigationLayout>
  );
};
export default Database;
