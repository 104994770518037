import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

interface Theme {
  type: string;
  screenWidthRate: number;
}

export const rootStyle = getComputedStyle(document.documentElement);

export const ThemeStyles = createGlobalStyle`

  ${reset};

  * {
    margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
  :root {
    // Color 변수
    --white: #FFFFFF;
    
    --gray-50: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FAFAFB" : "#1C1D20"};
    --gray-100: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#F3F4F4" : "#282A2F"};
    --gray-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#EDEEEF" : "#36383F"};
    --gray-300: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#E3E4E5" : "#45464F"};
    --gray-400: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#D5D6D8" : "#4C4D56"};
    --gray-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#BEC0C2" : "#7F8289"};
    --gray-600: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#9C9FA3" : "#CED1DA"};
    --gray-700: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#64676D" : "#E6E8ED"};
    --gray-800: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#36383E" : "#EFEFEF"};
    --gray-900: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#1F2022" : "#FAFAFA"};

    
    --blue-50: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#F6FAFF" : "#021A58"};
    --blue-100: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#E8F0FF" : "#103592"};
    --blue-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#D1E3FF" : "#103DAF"};
    --blue-300: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#A2C7FF" : "#144BD6"};
    --blue-400: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#4584FF" : "#1B58F4"};
    --blue-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#173AFF" : "#2260FF"};
    --blue-600: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#112CBF" : "#4D94FF"};
    --blue-700: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#0C1D80" : "#97C1FF"};
    --blue-800: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#060F40" : "#C4DBFE"};
    
    --purple-50: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#F9F8FF" : "#262243"};
    --purple-100: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#ECE9FF" : "#322874"};
    --purple-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#DED9FF" : "#43359D"};
    --purple-300: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#AB9EFF" : "#5847C5"};
    --purple-400: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#8E7DFA" : "#6E5CE4"};
    --purple-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#6A55F4" : "#816DFF"};
    --purple-600: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#523FCF" : "#9B8BFF"};
    --purple-700: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#40329E" : "#CCC5FF"};
    --purple-800: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#28205C" : "#E6E2FF"};
    
    --cyan-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#C2F0F6" : "#21C2D8"};
    --cyan-400: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#5EDBEC" : "#37D8EE"};
    --cyan-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#5EDBEC" : "#55DBED"};
    

    --green-50: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#EFF9F5" : "#22835D"};
    --green-100: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#E9F7F1" : "#22835D"};
    --green-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#BDF2DD" : "#38C08B"};
    --green-400: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#61DFAE" : "#49D7A0"};
    --green-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#3BD699" : "#51DEA7"};
    --green-700: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#28BE83" : "#8AE4C1"};

    --yellow-100: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FFF8E0" : "#F4CA36"};
    --yellow-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FFE897" : "#F4CA36"};
    --yellow-400: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FFDF6B" : "#FBD243"};
    --yellow-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FFD84F" : "#FBD85D"};
    --yellow-600: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#F5BF00" : "#49D7A"};

    
    --orange-50: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FFF5EE" : "#BC682A"};
    --orange-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FFB987" : "#F8842F"};
    --orange-400: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FF9F5A" : "#FC9042"};
    --orange-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FE9041" : "#FB9850"};
    
    --skyblue-50: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#F5FAFF" : "transparent"};        
    --skyblue-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#CDE7FF" : "#187FDF"};
    --skyblue-400: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#55ACFD" : "#389FFF"};
    --skyblue-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#2797FF" : "#41A3FE"};
            
    --navy-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#CAD0F2" : "#3B4FC0"};
    --navy-400: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#687AE1" : "#596FE8"};
    --navy-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#4961E1" : "#6176EA"};

    
    --red-50: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FFF2F3" : "#B7343B"};
    --red-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FFCACD" : "#F44852"};
    --red-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#F6444F" : "#F95861"};
    --red-700: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#D43E47" : "#F57F86"};
          
    

    // Color Style
    --background-header: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--white)" : "var(--gray-50)"};
    --background-page: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#FBFCFD" : "#1F2023"};
    --background-contents01: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--white)" : "#222326"};
    --background-contents02: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--white)" : "#27292D"};
    --background-table: var(--gray-50);
    --background-pagebutton: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#F4F5F6" : "#292A2E"};
    

    --primary-blue: var(--blue-500);
    --primary-black: var(--gray-900);
    --primary-purple: var(--purple-500);


    --text-01: var(--gray-900);
    --text-02: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--white)" : "#1C1E20"};
    --text-03: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#5B5D61" : "#C8CAD1"};
    --text-04: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#7E8086" : "#AAADB5"};
    --text-05: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "#96999F" : "#94969C"};
    --text-06: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--gray-500)" : "#5C5E64"};
    --text-inactive: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--gray-500)" : "var(--gray-400)"};
    --text-disabled: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--gray-500)" : "var(--gray-400)"};
    --text-white: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--white)" : "var(--white)"};
    

    --icon-01: var(--gray-900);
    --icon-02: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--gray-600)" : "var(--gray-500)"};
    --icon-03: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--gray-400)" : "var(--gray-300)"};
    --icon-04: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--white)" : "var(--gray-50)"};
    

    --dim-100: ${({ theme }: { theme: Theme }) =>
      theme.type === "light"
        ? "rgba(31, 32, 34, 0.20)"
        : "rgba(16, 16, 16, 0.6)"};
    --dim-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light"
        ? "rgba(31, 32, 34, 0.5)"
        : "rgba(16, 16, 16, 0.8)"};
    --dim-800: ${({ theme }: { theme: Theme }) =>
      theme.type === "light"
        ? "rgba(31, 32, 34, 0.8)"
        : "rgba(16, 16, 16, 0.9)"};
    

    --hover-100: ${({ theme }: { theme: Theme }) =>
      theme.type === "light"
        ? "var(--gray-100)"
        : "rgba(196, 219, 254, 0.05)"}; // --blue-800
    --hover-200: ${({ theme }: { theme: Theme }) =>
      theme.type === "light"
        ? "var(--gray-200)"
        : "rgba(196, 219, 254, 0.15)"}; // --blue-800
    --button-disabled: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--gray-300)" : "var(--gray-200)"};
    --focus-field-blue: var(--blue-300);
    --focus-field-purple: var(--purple-300);
    

    --shadow-500: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "rgba(100, 103, 109, 0.1)" : ""};
    --shadow-600: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "rgba(100, 103, 109, 0.3);" : ""};
    --shadow-700: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "rgba(100, 103, 109, 0.5);" : ""};
    

    --divider-50: ${({ theme }: { theme: Theme }) =>
      theme.type === "light" ? "var(--gray-50)" : ""};
    --divider-100: var(--gray-100);
    --divider-200: var(--gray-200);
    --divider-300: var(--gray-300);    



    /* 칼라 정의 1.0version*/
    /* --primary:#174274 */
    --primary: #24364a;
    --nav_background: #24364a;
    --nav_item_default: #cad7e7;
    --background: #f0f0f0;
    --red: #ff0033;
    --white: #ffffff;
    --border-color: #c4c4c4;
    --underline: #c4c4c4;
    --black1: #000000;
    --black2: #999;
    --grey_0: #f0f0f0;
    --grey_10: #dcdcdc;
    --grey_20: #c4c4c4;
    --grey_50: #828282;
    --grey_60: #3d3d3d;
    --alarm_red: #ff0033;
    --BPP_blue: #2196f3;

    /* 폰트 사이즈 정의 */
    --xx-large: 40px;
    --x-large: 20px;
    --large: 14px;
    --medium: 12px;
    --small: 8px;
    --x-small: 4px;
    --xx-small: 2px;

    /* gap 정의 */
    --gap-basic: 8px;
    --gap-multiple: 16px;
    --gap-triple: 24px;
  }

  div * {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background-color: transparent;
    }
    ::-webkit-scrollbar {
      width: 3px !important;
      height: 0px !important;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: transparent;

    }
  }

  .scroll__invisible {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .scroll__invisible::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  .scroll__invisible::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .on-scrollbar {
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    height: 5px;
  }

  .on-scrollbar::-webkit-scrollbar-track {
    width: 18px !important;

    height: 18px !important;

    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  .on-scrollbar::-webkit-scrollbar {
    width: 18px !important;
    height: 18px !important;
    background-color: transparent;
  }

  .on-scrollbar::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: #edeeef;
    border-radius: 9px;
    background-clip: padding-box;
    border: 6px solid transparent;
    transition: color 0.2s ease;
  }

  .no-scrollbar {
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .no-scrollbar::-webkit-scrollbar-track {
    width: 18px !important;
    height: 18px !important;

    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  .no-scrollbar::-webkit-scrollbar {
    width: 18px !important;
    height: 18px !important;

    background-color: transparent;
  }

  .no-scrollbar::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: transparent;
    border-radius: 9px;
    background-clip: padding-box;
    border: 6px solid transparent;
    transition: color 0.2s ease;
  }

  /* Font Style */
  /* {style}-{weight}-{size} */

  /* Heading */

  .logo-64-hv {
    font-family: "SUIT Heavy";    
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 64}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 72}px`};
    letter-spacing: 1.6px;
  }

  .logo-20-hv {
    font-family: "SUIT Heavy";    
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 32}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 40}px`};
    
  }


  .heading-24-b {
    font-family: "SUIT Bold";
    
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 24}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 36}px`};
    letter-spacing: -1%;
  }

  .heading-24-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 24}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 36}px`};
    letter-spacing: -1%;
  }

  .heading-24-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 24}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 36}px`};
    letter-spacing: -1%;
  }

  .heading-20-b {
    font-family: "SUIT Bold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 20}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 30}px`};
    letter-spacing: -1%;
  }

  .heading-20-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 20}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 30}px`};
    letter-spacing: -1%;
  }

  .heading-20-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 20}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 30}px`};
    letter-spacing: -1%;
  }

  .heading-18-b {
    font-family: "SUIT Bold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 18}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 28}px`};
    letter-spacing: -1%;
  }

  .heading-18-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 18}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 28}px`};
    letter-spacing: -1%;
  }

  .heading-18-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 18}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 28}px`};
    letter-spacing: -1%;
  }

  .heading-16-b {
    font-family: "SUIT Bold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 26}px`};
    letter-spacing: -1%;
  }

  .heading-16-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 26}px`};
    letter-spacing: -1%;
  }

  .heading-16-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 26}px`};
    letter-spacing: -1%;
  }

  .heading-14-b {
    font-family: "SUIT Bold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 14}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 22}px`};
    letter-spacing: -1%;
  }

  .heading-14-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 14}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 22}px`};
    letter-spacing: -1%;
  }

  .heading-14-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 14}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 22}px`};
    letter-spacing: -1%;
  }

  /* Body */
  .body-16-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 26}px`};
    letter-spacing: -1%;
  }

  .body-16-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 26}px`};
    letter-spacing: -1%;
  }

  .body-16-rg {
    font-family: "SUIT Regular";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 26}px`};
    letter-spacing: -1%;
  }

  .body-14-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 14}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 22}px`};
    letter-spacing: -1%;
  }

  .body-14-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 14}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 22}px`};
    letter-spacing: -1%;
  }

  .body-14-rg {
    font-family: "SUIT Regular";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 14}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 22}px`};
    letter-spacing: -1%;
  }

  /* Caption */
  .caption-14-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 14}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 22}px`};
    letter-spacing: -1%;
  }

  .caption-14-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 14}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 22}px`};
    letter-spacing: -1%;
  }

  .caption-14-rg {
    font-family: "SUIT Regular";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 14}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 22}px`};
    letter-spacing: -1%;
  }

  .caption-12-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 12}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 20}px`};
    letter-spacing: -1%;
  }

  .caption-12-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 12}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 20}px`};
    letter-spacing: -1%;
  }

  .caption-12-rg {
    font-family: "SUIT Regular";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 12}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 20}px`};
    letter-spacing: -1%;
  }

  .caption-11-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 11}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    letter-spacing: -1%;
  }

  .caption-11-rg {
    font-family: "SUIT Regular";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 11}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    letter-spacing: -1%;
  }

  .caption-10-sb {
    font-family: "SUIT SemiBold";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 10}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    letter-spacing: -1%;
  }

  .caption-10-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 10}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    letter-spacing: -1%;
  }

  .caption-10-rg {
    font-family: "SUIT Regular";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 10}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    letter-spacing: -1%;
  }

  .caption-9-md {
    font-family: "SUIT Medium";
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 9}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 16}px`};
    letter-spacing: -1%;
  }

  /* Reporting Card font */
  .reporting-card-font {
    font-family: "SUIT Regular";
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 34}px`};
    line-height: ${({ theme }: { theme: Theme }) =>
      `${theme.screenWidthRate * 30}px`};
  }

  // 드래그 막기
  .block-draggable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-use-select: none;
    user-select: none;
  }

  body {

    //대체될 것들
    max-width: calc(200px + 1480px);

    height: 100%;
    min-height: 100%;
    max-height: 100vh;


    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
  }
  ul {
    list-style: none;
  }
  .main_title {
  font-family: Bpp Regular;
  font-style: normal;
  font-weight: bold;
  font-size: var(--xx-large);
  line-height: 48px;
  letter-spacing: 0.1px;

  color: var(--black1);
}

.main_sub_title {
  font-family: Bpp Regular;
  font-style: normal;
  font-weight: bold;
  font-size: var(--x-large);
  letter-spacing: 0.1px;
  color: var(--black1);
}

.sub_title {
  font-family: Bpp Regular;
  font-style: normal;
  font-weight: 500;
  font-size: var(---large);
  letter-spacing: 0.1px;
  color: var(--black1);
}

.font-medium-32 {
  font-family: "Bpp bold";
  font-size: 32px;
  line-height: 1;
}

.font-regular-10 {
  font-family: "Bpp Regular";
  font-size: 10px;
  white-space: pre;
}
.font-regular-12 {
  font-family: "Bpp Regular";
  font-size: 12px;
  white-space: pre;
}
.font-regular-14 {
  font-family: "Bpp Regular";
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 100%;
  vertical-align: middle;
  white-space: pre;
}

.font-regular-16 {
  font-family: "Bpp Regular";
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 100%;
  vertical-align: middle;
  white-space: pre;
}

.font-medium-10 {
  font-family: "Bpp Medium";
  font-size: 10px;
  white-space: pre;
}
.font-medium-12 {
  font-family: "Bpp Medium";
  font-size: 12px;
  white-space: pre;
}
.font-medium-14 {
  font-family: "Bpp Medium";
  font-size: var(--large);
  letter-spacing: 0.1px;
  line-height: 20px;
  white-space: pre;
}
.font-medium-16 {
  font-family: "Bpp Medium";
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 20px;
  white-space: pre;
}
.font-medium-20 {
  font-family: "Bpp Medium";
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 1;
}
.font-medium-24 {
  font-family: "Bpp Medium";
  font-size: 24px;
  letter-spacing: 0.2px;
  line-height: 1;
}
.font-bold-40 {
  font-family: "Bpp Bold";
  font-size: var(--xx-large);
  letter-spacing: 0.1px;
  line-height: 48px;
  white-space: pre;
}

.font-bold-20 {
  font-family: "Bpp Bold";
  font-size: 20px;
  letter-spacing: 0.1px;

  white-space: pre;
}

.font-bold-18 {
  font-family: "Bpp Bold";
  font-size: 18px;
  letter-spacing: 0.1px;
  line-height: 1;
  white-space: pre;
}

.font-bold-16 {
  font-family: "Bpp Bold";
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 1;
  white-space: pre;
}

.font-bold-14 {
  font-family: "Bpp Bold";
  font-size: var(--large);
  letter-spacing: 0.1px;
  line-height: 20px;
  white-space: pre;
}

/* Heading */
.heading-24-b {
  font-family: "SUIT Bold";
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -1%;
}

.heading-24-sb {
  font-family: "SUIT SemiBold";
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -1%;
}

.heading-24-md {
  font-family: "SUIT Medium";
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -1%;
}

.heading-20-b {
  font-family: "SUIT Bold";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1%;
}

.heading-20-sb {
  font-family: "SUIT SemiBold";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1%;
}

.heading-20-md {
  font-family: "SUIT Medium";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1%;
}

.heading-18-b {
  font-family: "SUIT Bold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -1%;
}

.heading-18-sb {
  font-family: "SUIT SemiBold";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -1%;
}

.heading-18-md {
  font-family: "SUIT Medium";
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -1%;
}

.heading-16-b {
  font-family: "SUIT Bold";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -1%;
}

.heading-16-sb {
  font-family: "SUIT SemiBold";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -1%;
}

.heading-16-md {
  font-family: "SUIT Medium";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -1%;
}

.heading-14-b {
  font-family: "SUIT Bold";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -1%;
}

.heading-14-sb {
  font-family: "SUIT SemiBold";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -1%;
}

.heading-14-md {
  font-family: "SUIT Medium";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -1%;
}

/* Body */
.body-16-sb {
  font-family: "SUIT SemiBold";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -1%;
}

.body-16-md {
  font-family: "SUIT Medium";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -1%;
}

.body-16-rg {
  font-family: "SUIT Regular";
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -1%;
}

.body-14-sb {
  font-family: "SUIT SemiBold";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -1%;
}

.body-14-md {
  font-family: "SUIT Medium";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -1%;
}

.body-14-rg {
  font-family: "SUIT Regular";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -1%;
}

/* Caption */
.caption-12-sb {
  font-family: "SUIT SemiBold";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -1%;
}

.caption-12-md {
  font-family: "SUIT Medium";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -1%;
}

.caption-12-rg {
  font-family: "SUIT Regular";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -1%;
}

.caption-11-rg {
  font-family: "SUIT Regular";
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -1%;
}

.caption-10-sb {
  font-family: "SUIT SemiBold";
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -1%;
}

.caption-10-md {
  font-family: "SUIT Medium";
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -1%;
}

.caption-10-rg {
  font-family: "SUIT Regular";
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -1%;
}

.caption-9-md {
  font-family: "SUIT Medium";
  font-size: 9px;
  line-height: 16px;
  letter-spacing: -1%;
}
`;
