import React from "react";
import { useMediaQuery } from "react-responsive";
import MobileRiskManagementEvaluationDetail from "./mobile/MobileRiskManagementEvaluationDetail";
import DeskRiskManagementEvaluationDetail from "./desktop/DeskRiskManagementEvaluationDetail";

const RiskManagementEvaluationDetail = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobileRiskManagementEvaluationDetail />
  ) : (
    <DeskRiskManagementEvaluationDetail />
  );
};
export default RiskManagementEvaluationDetail;
