import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../SummaryBusinessPlanV2Download";

interface Props {
  title: string;
}

export const CompanyTitle: React.FC<Props> = (props) => {
  return (
    <Container convertPixelToCm={convertPixelToCm}>
      <span className="heading-24-sb" style={{ fontSize: 36 }}>
        {props.title}
      </span>
    </Container>
  );
};

const Container = styled.div<{ convertPixelToCm: (n: number) => number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 10px;

  span {
    width: max-content;
    min-width: max-content;
  }
`;
