import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  isValidCode?: boolean;
  setIsAuthSuccess: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  checkAuthCode: (code: string) => void;
}
const CheckAuthCode: React.FC<Props> = (props) => {
  const { isValidCode, setIsAuthSuccess, checkAuthCode } = props;
  const [code, updateCode] = useState<string>("");
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    focusInput();
  }, []);

  useEffect(() => {
    if (code.length === 6) {
      checkAuthCode(code);
    } else {
      setIsAuthSuccess(undefined);
    }
  }, [code]);
  return (
    <Container>
      <Logo
        className={"logo-20-hv"}
        style={{ textAlign: "center", color: "var(--primary-blue)" }}
      >
        Lattice
      </Logo>

      <AuthCodeContainer>
        <div className="heading-24-sb">인증코드 입력</div>
        <FlexRow className="body-14-md" style={{ gap: 14, marginTop: 50 }}>
          <CodeBox
            className={`
              ${
                isValidCode === undefined
                  ? ""
                  : isValidCode
                  ? "success_code"
                  : "fail_code"
              }
              ${code[0] !== undefined ? "fill_code" : ""} 
              ${inputFocus && code.length === 0 ? "current_code" : ""}`}
            onClick={focusInput}
          >
            {code[0] || ""}
          </CodeBox>
          <CodeBox
            className={`
            ${
              isValidCode === undefined
                ? ""
                : isValidCode
                ? "success_code"
                : "fail_code"
            }
            ${code[1] !== undefined ? "fill_code" : ""}  ${
              inputFocus && code.length === 1 ? "current_code" : ""
            }`}
            onClick={focusInput}
          >
            {code[1] || ""}
          </CodeBox>
          <CodeBox
            className={`
            ${
              isValidCode === undefined
                ? ""
                : isValidCode
                ? "success_code"
                : "fail_code"
            }
            ${code[2] !== undefined ? "fill_code" : ""}  ${
              inputFocus && code.length === 2 ? "current_code" : ""
            }`}
            onClick={focusInput}
          >
            {code[2] || ""}
          </CodeBox>
          <CodeBox
            className={`
            ${
              isValidCode === undefined
                ? ""
                : isValidCode
                ? "success_code"
                : "fail_code"
            }
            ${code[3] !== undefined ? "fill_code" : ""}  ${
              inputFocus && code.length === 3 ? "current_code" : ""
            }`}
            onClick={focusInput}
          >
            {code[3] || ""}
          </CodeBox>
          <CodeBox
            className={`
            ${
              isValidCode === undefined
                ? ""
                : isValidCode
                ? "success_code"
                : "fail_code"
            }
            ${code[4] !== undefined ? "fill_code" : ""}  ${
              inputFocus && code.length === 4 ? "current_code" : ""
            }`}
            onClick={focusInput}
          >
            {code[4] || ""}
          </CodeBox>
          <CodeBox
            className={`
            ${
              isValidCode === undefined
                ? ""
                : isValidCode
                ? "success_code"
                : "fail_code"
            }
            ${code[5] !== undefined ? "fill_code" : ""}  ${
              inputFocus && code.length === 5 ? "current_code" : ""
            }`}
            onClick={focusInput}
          >
            {code[5] || ""}
          </CodeBox>
        </FlexRow>
        <div
          className="body-14-md"
          style={{
            color:
              isValidCode === undefined || !isValidCode
                ? "var(--text-05)"
                : "var(--green-500)",
            marginTop: 8,
            width: "100%",
          }}
        >
          {isValidCode === undefined || !isValidCode
            ? "안내받은 이메일을 확인해 주세요."
            : "인증 완료 되었습니다."}
        </div>
      </AuthCodeContainer>

      <input
        ref={inputRef}
        type="text"
        disabled={isValidCode}
        maxLength={6}
        style={{ opacity: 0, position: "absolute", top: -100 }}
        onFocus={() => {
          setInputFocus(true);
        }}
        onBlur={() => {
          setInputFocus(false);
        }}
        onChange={(e) => {
          if (e.target.value.length <= 6) {
            updateCode(e.target.value);
          }
        }}
        value={code}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Logo = styled.div`
  position: fixed;

  top: 45px;
  left: 50px;
`;

const AuthCodeContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CodeBox = styled.div`
  position: relative;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;

  border-radius: 4px;

  background-color: var(--background-content01);
  border: 1px solid var(--gray-300);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  text-transform: uppercase;

  &.fill_code {
    border: 1px solid var(--focus-field-blue);
  }

  &.current_code {
    border: 1px solid var(--focus-field-blue);
    ::after {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% - 24px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: black;

      animation: show_hide 1s steps(2) infinite;
    }
  }

  &.success_code {
    border: 1px solid var(--green-500);
  }

  &.fail_code {
    border: 1px solid var(--red-500);
    animation: shake 200ms;
  }

  @keyframes show_hide {
    0% {
      opacity: 0;
    }
  }

  @keyframes shake {
    25% {
      transform: translateX(2px);
    }
    50% {
      transform: translateX(-2px);
    }
    75% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(-2px);
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default CheckAuthCode;
