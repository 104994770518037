import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Channel, IndustryTech } from "../../../../type/data";
import CheckedChip from "../components/CheckedChip";
import RefreshChip from "../components/RefreshChip";

import { getNewDate } from "../../../../common/commonUtil";
import {
  ExcavationFilterData,
  ExcavationFilterType,
} from "../../interface/Excavation.interface";
import ModalLayout from "../../../../components/modal/ModalLayout";
import { FilterModal } from "./FilterModal";

import { PickerDateRange } from "./PickerDateRange";
import CheckedText from "../../../../components-2.0v/check-box/CheckedText";

type Filter = "INDUSTRIAL_TECH" | "CHANNEL" | "BOTH" | undefined;
interface Props {
  allIndustyTech: IndustryTech[];
  allChannel: Channel[];
  filterData: ExcavationFilterData;
  onChangeFilterData: (type: ExcavationFilterType, data: any) => void;
  myIndustrialTech: number[];
  myHeadQuarterIndustrialTech: number[];
  isShowDetail?: boolean;
  minDate?: string;
  maxDate?: string;
}

const FilterView: React.FC<Props> = (props) => {
  const {
    isShowDetail,
    allIndustyTech,
    allChannel,
    filterData,
    myHeadQuarterIndustrialTech,
    myIndustrialTech,
    minDate,
    maxDate,
    onChangeFilterData,
  } = props;
  const [isShowFilter, updateShowFilter] = useState<boolean>(false);
  const [openFilter, updateOpenFilter] = useState<Filter>(undefined);

  const [isShowFilterModal, updateShowFilterModal] = useState<boolean>(false);

  const isActiveMyIndustry = () => {
    const activeIndustry = filterData.industry;
    if (activeIndustry.length !== myIndustrialTech.length) return false;
    if (
      activeIndustry.filter((item) => !myIndustrialTech.includes(item)).length >
      0
    )
      return false;

    return true;
  };

  const isActiveMyHeadquarterIndustry = () => {
    const activeIndustry = filterData.industry;
    if (activeIndustry.length !== myHeadQuarterIndustrialTech.length)
      return false;
    if (
      activeIndustry.filter(
        (item) => !myHeadQuarterIndustrialTech.includes(item)
      ).length > 0
    )
      return false;

    return true;
  };

  const onClickIndustry = (item: number) => {
    const selectedIndustry = [...filterData.industry];
    const targetIndex = selectedIndustry.findIndex((data) => data === item);
    if (targetIndex === -1) {
      onChangeFilterData("industry", [...selectedIndustry, item]);
    } else {
      selectedIndustry.splice(targetIndex, 1);
      onChangeFilterData("industry", [...selectedIndustry]);
    }
  };

  const onClickChannel = (item: number) => {
    const selectedChannel = [...filterData.channel];
    const targetIndex = selectedChannel.findIndex((data) => data === item);
    if (targetIndex === -1) {
      onChangeFilterData("channel", [...selectedChannel, item]);
    } else {
      selectedChannel.splice(targetIndex, 1);
      onChangeFilterData("channel", [...selectedChannel]);
    }
  };

  useEffect(() => {
    if (isShowDetail) {
      updateOpenFilter(undefined);
      updateShowFilter(false);
    } else {
      updateShowFilterModal(false);
    }
  }, [isShowDetail]);

  return (
    <Container>
      <FelxRow
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FelxRow style={{ gap: 12, flexWrap: "wrap" }}>
          <PickerDateRange
            date={filterData.dateRange}
            minDate={minDate ? getNewDate(minDate) : undefined}
            maxDate={maxDate ? getNewDate(maxDate) : undefined}
            onChangeDate={(dates) => {
              onChangeFilterData("dateRange", dates);
            }}
          />
          <CheckedChip
            hideArrow={isShowDetail}
            isSelected={!isShowDetail && openFilter === "INDUSTRIAL_TECH"}
            isActive={filterData.industry.length > 0}
            contentsString={`산업기술${
              filterData.industry.length > 0
                ? ` (${filterData.industry.length})`
                : ""
            }`}
            onClick={() => {
              if (isShowDetail) return updateShowFilterModal((prev) => !prev);

              if (openFilter === "INDUSTRIAL_TECH") {
                updateOpenFilter(undefined);
                updateShowFilter(false);
              } else {
                updateOpenFilter("INDUSTRIAL_TECH");
                updateShowFilter(true);
              }
            }}
          />
          <CheckedChip
            hideArrow={isShowDetail}
            isSelected={!isShowDetail && openFilter === "CHANNEL"}
            isActive={filterData.channel.length > 0}
            contentsString={`채널${
              filterData.channel.length > 0
                ? ` (${filterData.channel.length})`
                : ""
            }`}
            onClick={() => {
              if (isShowDetail) return updateShowFilterModal((prev) => !prev);

              if (openFilter === "CHANNEL") {
                updateOpenFilter(undefined);
                updateShowFilter(false);
              } else {
                updateOpenFilter("CHANNEL");
                updateShowFilter(true);
              }
            }}
          />

          <RefreshChip
            onClick={() => {
              onChangeFilterData("init", "");
            }}
          />
        </FelxRow>
      </FelxRow>
      <FilterContainer
        className={!isShowDetail && isShowFilter ? "show__filter" : ""}
      >
        <Divider style={{ marginBottom: 20 }} />
        {openFilter === "INDUSTRIAL_TECH" ? (
          <FelxColumn style={{ gap: 12 }}>
            <FelxRow style={{ gap: 20, paddingLeft: 16 }}>
              <CheckedText
                isChecked={isActiveMyIndustry()}
                onClick={() => {
                  onChangeFilterData(
                    "industry",
                    isActiveMyIndustry() ? [] : myIndustrialTech
                  );
                }}
              >
                마이 산업기술
              </CheckedText>
              <CheckedText
                isChecked={isActiveMyHeadquarterIndustry()}
                onClick={() => {
                  onChangeFilterData(
                    "industry",
                    isActiveMyHeadquarterIndustry()
                      ? []
                      : myHeadQuarterIndustrialTech
                  );
                }}
              >
                본부 산업기술
              </CheckedText>
            </FelxRow>
            <FilterWrapper>
              {allIndustyTech.map((item, key) => {
                return (
                  <CheckedText
                    key={key}
                    isChecked={filterData.industry.includes(
                      parseInt(`${item.acIndustrialTechId}`)
                    )}
                    onClick={() => {
                      onClickIndustry(parseInt(`${item.acIndustrialTechId}`));
                    }}
                  >
                    {item.name}
                  </CheckedText>
                );
              })}
            </FilterWrapper>
          </FelxColumn>
        ) : openFilter === "CHANNEL" ? (
          <FilterWrapper>
            {allChannel.map((item, key) => {
              return (
                <CheckedText
                  key={key}
                  isChecked={filterData.channel.includes(
                    parseInt(`${item.acChannelId}`)
                  )}
                  onClick={() => {
                    onClickChannel(parseInt(`${item.acChannelId}`));
                  }}
                >
                  {item.name}
                </CheckedText>
              );
            })}
          </FilterWrapper>
        ) : null}

        <Divider style={{ marginTop: 20 }} />
      </FilterContainer>

      {isShowFilterModal && (
        <ModalLayout
          isOpen={isShowFilterModal}
          toggle={() => updateShowFilterModal(false)}
          isFullScreen
        >
          <FilterModal
            allIndustyTech={allIndustyTech}
            allChannel={allChannel}
            filterData={filterData}
            myIndustrialTech={myIndustrialTech}
            myHeadQuarterIndustrialTech={myHeadQuarterIndustrialTech}
            onChangeFilterData={onChangeFilterData}
            close={() => updateShowFilterModal(false)}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 0px;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-contents01);
  overflow: hidden;

  transition: all 0.2s ease;

  &.show__filter {
    height: fit-content;
    min-height: 92px;
    margin-top: 20px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-300);
`;
const FilterWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
  padding: 0 16px;
`;
const FilterDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-200);
`;

const RightContainer = styled.div`
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const FelxRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FelxColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export default FilterView;
