import React from "react";
import styled from "styled-components";

import { CommentEditor } from "../../desk/components/CommentEditor";
import { CommentViewer } from "./CommentViewer";
import { CommentViewerEditor } from "./CommentViewerEditor";
import { BusinessInfoProps } from "../../interface/PortfolioInfo.interface";
import { LoginInfo } from "../../../../../type/data";

interface Props {
  myInfo: LoginInfo;
  businessInfo: BusinessInfoProps;
  sendComment: (data: string, callback: () => void) => void;
  updateComment: (pfCommentId: number, data: string) => void;
  deleteComment: (pfCommentId: number) => void;
  updateCommentId?: number;
  setUpdateCommentId?: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const BusinessComment: React.FC<Props> = (props) => {
  const {
    myInfo,
    businessInfo,
    sendComment,
    updateComment,
    deleteComment,
    updateCommentId,
    setUpdateCommentId,
  } = props;
  return (
    <Container id="business__comment">
      <CommentEditor onSubmit={sendComment} />
      {businessInfo.comment?.map((item) => {
        return item.id === updateCommentId ? (
          <CommentViewerEditor
            myInfo={myInfo}
            comment={item}
            updateComment={updateComment}
            cancelUpdate={() => {
              setUpdateCommentId && setUpdateCommentId(undefined);
            }}
          />
        ) : (
          <CommentViewer
            myInfo={myInfo}
            comment={item}
            deleteComment={deleteComment}
            setUpdateCommentId={setUpdateCommentId}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 28px;

  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const TextBox = styled.pre`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  word-break: break-all;
  white-space: break-spaces;

  color: var(--text-01, #1f2022);
`;
