import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  initDatabaseHistory,
  setDatabaseHistory,
} from "../../../../common/pageHistoryUtil";
import { DefaultButton } from "lattice_core";

interface TabItemProps {
  label: string;
  isSelected: boolean;
  index: number;
  onClick: (index: number) => void;
}
interface Props {
  selectedTabIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  isShowfilter: boolean;
  updateIsShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

const TabItem = ({ label, isSelected, index, onClick }: TabItemProps) => {
  const onClickItem = () => {
    onClick(index);
  };
  return (
    <StyledTabItem
      className="main_sub_title"
      onClick={onClickItem}
      isSelected={isSelected}
    >
      <span>{label}</span>
      {isSelected && <div className="divider" />}
    </StyledTabItem>
  );
};
const DatabaseTab = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(
    props.selectedTabIndex
  );

  const onClick = (index: number) => {
    setSelectedIndex(index);
    props.setSelectedIndex(index);
    initDatabaseHistory();
    setDatabaseHistory({
      tab: index,
    });
  };

  return (
    <Container>
      <FlexRow style={{ gap: 24 }}>
        {["통합검색", "관리", "Alumni", "폐업,드롭"].map((item, index) => (
          <TabItem
            label={item}
            key={item}
            isSelected={index === selectedIndex}
            index={index}
            onClick={(index: number) => onClick(index)}
          />
        ))}
      </FlexRow>
      <FlexRow>
        <DefaultButton
          className="heading-16-sb"
          buttonType="text"
          sizeType="small"
          buttonColor={
            props.isShowfilter ? "var(--primary-blue)" : "var(--text-01)"
          }
          text={props.isShowfilter ? "필터 닫기" : "필터 열기"}
          onClick={() => props.updateIsShowFilter((prev) => !prev)}
        />
      </FlexRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 61px;
  min-height: 61px;
  max-height: 61px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 60px;
  /* light/background-header */
  background: #ffffff;
  /* light/divider100 */
  border-bottom: 1px solid #f3f4f4;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTabItem = styled.div(
  ({ isSelected }: { isSelected: boolean }) => css`
    cursor: pointer;
    font-weight: 600;
    span {
      color: ${isSelected ? "var(--primary-blue)" : "var(--grey_50)"};
    }

    .divider {
      margin-top: 4px;
      height: 2px;
      width: 100%;
      background: var(--primary-blue);

      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  `
);
export default DatabaseTab;
