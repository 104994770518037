import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Pdf } from "../../assets/image/large_pdf.svg";
import { DefaultButton } from "lattice_core";
import ModalLayout from "../../components/modal/ModalLayout";
import ConfirmModal from "../../2.0lattice/components/modal/ConfirmModal";

interface Props {
  defaultData?: string;
  width?: number | string;
  height?: number | string;
  maxSizeMB?: number;
  onChangeFile: (data: FileUploadProps) => void;
}

export interface FileUploadProps {
  isNew: boolean;
  file?: File;
}
export const PdfFileUpload: React.FC<Props> = (props) => {
  const {
    defaultData,
    width = "100%",
    height = "100%",
    maxSizeMB = 11,
    onChangeFile,
  } = props;
  const [fileName, setFileName] = useState<string | undefined>(defaultData);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [onlyPDFAlert, setOnlyPDFAlert] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;
    const file = event.target.files[0];
    const limitSize = maxSizeMB * 1024 * 1024;

    if (!file.type.includes("pdf")) {
      event.target.value = "";
      return setOnlyPDFAlert(true);
    }
    if (file.size > limitSize) {
      event.target.value = "";
      return setOpenAlert(true);
    }

    setFileName(file.name);
    onChangeFile({
      isNew: true,
      file: file,
    });
    event.target.value = "";
  };

  return (
    <Container style={{ width: width, height: height }}>
      {fileName ? (
        <FileWrapper className="body-14-rg">
          <FlexRow
            style={{
              gap: 6,
              alignItems: "center",
              width: "calc(100% - 134px + 48px)",
            }}
          >
            <Pdf width={24} height={24} />
            <FileName style={{ lineHeight: 1 }}>{fileName}</FileName>
          </FlexRow>
          <div>
            <DefaultButton
              className="caption-12-md"
              buttonType="colored-outline"
              sizeType="xxsmall"
              buttonColor={"#1F2022"}
              borderColor={"#D5D6D8"}
              text="파일 교체"
              onClick={(e) => {
                e && e.stopPropagation();
                if (inputRef && inputRef.current) {
                  inputRef.current?.click();
                }
              }}
            />
          </div>
        </FileWrapper>
      ) : (
        <div>
          <DefaultButton
            className="caption-12-md"
            buttonType="colored-outline"
            sizeType="xxsmall"
            buttonColor={"#1F2022"}
            borderColor={"#D5D6D8"}
            text="파일 업로드"
            onClick={(e) => {
              e && e.stopPropagation();
              if (inputRef && inputRef.current) {
                inputRef.current?.click();
              }
            }}
          />
        </div>
      )}
      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        accept=".pdf"
        onChange={(e) => {
          handleFileUpload(e);
        }}
      />
      {openAlert && (
        <ModalLayout
          isOpen={openAlert}
          isFullScreen
          toggle={() => {
            setOpenAlert(false);
          }}
        >
          <ConfirmModal
            type="alert"
            contents={[
              `파일 크키가 너무 큽니다.`,
              `파일 사이즈 ${maxSizeMB - 1}MB 이하의 파일을 올려주세요.`,
            ]}
            onClickPositiveBtn={() => setOpenAlert(false)}
          />
        </ModalLayout>
      )}

      {onlyPDFAlert && (
        <ModalLayout
          isOpen={onlyPDFAlert}
          isFullScreen
          toggle={() => {
            setOnlyPDFAlert(false);
          }}
        >
          <ConfirmModal
            type="alert"
            contents={[
              `오직 PDF파일만 업로드 가능합니다.`,
              `파일 확장자를 확인해주세요.`,
            ]}
            onClickPositiveBtn={() => setOnlyPDFAlert(false)}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border: 1px solid var(--gray-200);
  border-radius: 4px;

  padding: 0 12px;

  color: var(--text-01);
`;

const FileName = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
