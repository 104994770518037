import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import * as api from "../../../../api/api";
import { useNavigate } from "react-router-dom";

import {
  getInvestmentAssessmentIdInLocation,
  getReportAccountIdInLocation,
  getPortfolioIdInLocation,
} from "../../../../common/commonUtil";
import { Bounce, toast } from "react-toastify";
import {
  IAAccount,
  IACompanyInfo,
  IAReportAccount,
  InvestmentAssessment,
} from "../../../../type/data";

const useEvaluationConfirmTask = () => {
  const navigate = useNavigate();

  const portfolioId = useRef<number>(getPortfolioIdInLocation(location));
  const investmentAssessmentId = useRef<number>(
    getInvestmentAssessmentIdInLocation(location)
  );
  const reportId = useRef<number | string>(
    getReportAccountIdInLocation(location)
  );

  const [iaDocument, updateIaDocument] = useState<
    InvestmentAssessment | undefined
  >(undefined);
  const [reportAccount, updateReportAccount] = useState<
    IAReportAccount | undefined
  >(undefined);

  const initEvaluationData = async () => {
    try {
      //투자심사 보고서 정보 가져오기
      const result = await axios.get(
        api.getInvestmentAssessmentDocument(
          portfolioId.current,
          investmentAssessmentId.current
        )
      );

      if (result.status === 200) {
        console.log(result);
        const document: InvestmentAssessment = result.data;
        const targetReport: IAReportAccount | undefined =
          document.investmentAssessmentReportAccount.find(
            (item) =>
              item.investmentAssessmentReportAccountId === reportId.current
          );

        if (document.isConfirmed || !targetReport) {
          navigate("/mytask", { replace: true });
        }
        updateIaDocument({ ...document });
        updateReportAccount(targetReport);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initEvaluationData();
  }, []);

  return [iaDocument, reportAccount, navigate] as const;
};

export default useEvaluationConfirmTask;
