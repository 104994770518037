import React from "react";

import styled from "styled-components";
import { AddComma } from "../../../../../../common/commonUtil";

interface Props {
  totalStockNum: number;
  totalPercent: number;
  totalAmount: number;
}

export const StockholderFooter: React.FC<Props> = ({
  totalAmount,
  totalStockNum,
  totalPercent,
}) => {
  const height = 32;
  return (
    <Container className={`caption-12-b`}>
      총발행 주식수 : {AddComma(totalStockNum)}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
`;
