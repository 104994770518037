import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  cancelPrePitching,
  finishPrePitching,
  getDealflowStatusById,
  getPrePitchingResult,
  getPrepitchingOne,
  modifyPrePitching,
  publishPrePitching,
} from "../../../../api/repository/portfolio/PortfolioRepository";

import {
  getPortfolioIdInLocation,
  getYMD,
  trnasfromBase64ToObject,
} from "../../../../common/commonUtil";
import { getAllAcUser } from "../../../../api/repository/accelerator/AcceleratorRepository";
import { UserInfo } from "../../../../type/data";
import {
  PitchingChangeDataType,
  PitchingResultType,
  PitchingStatus,
  PrePitchingData,
  SectionType,
} from "../interface/PrePitchingDetail.interface";
import { DealFlowStatus } from "../../portfolio-detail/interface/PortfolioNavigation.interface";

const usePrePitchingDetail = (setPageName?: (name: string) => void) => {
  const getIdByLocation = () => {
    const path = location.pathname.split("/");
    return trnasfromBase64ToObject(path[path.length - 1]).id;
  };

  const location = useLocation();
  const navigate = useNavigate();
  const portfolioId = getPortfolioIdInLocation(location);
  const evaluationId = getIdByLocation();

  const curPitchingData = useRef<PrePitchingData | undefined>(undefined);
  const [isOpenDeal, setIsOpenDeal] = useState<boolean>(false);
  const [allUser, setAllUser] = useState<UserInfo[]>([]);
  const [pitchingData, setPitchingData] = useState<PrePitchingData | undefined>(
    undefined
  );
  const [pitchingDataResult, setPitchingDataResult] = useState<
    PitchingResultType[] | undefined
  >(undefined);

  const [releaseAble, setReleaseAble] = useState<boolean>(false);
  const [isModifyMode, updateIsModifyMode] = useState<boolean>(false);
  const [alReadyPublish, setAlreadyPublish] = useState<boolean>(false);

  const onCheckRelaseable = () => {
    if (!curPitchingData.current) return;
    const result =
      curPitchingData.current.preEvaluationDate !== undefined &&
      curPitchingData.current.preEvaluationData !== undefined &&
      curPitchingData.current.preEvaluationData.length !== 0 &&
      curPitchingData.current.preEvaluationData[0].companyInfo !== undefined &&
      curPitchingData.current.preEvaluationData[0].companyInfo.length !== 0 &&
      curPitchingData.current.preEvaluationData[0].investmentInfo !==
        undefined &&
      curPitchingData.current.preEvaluationData[0].investmentInfo.length !==
        0 &&
      curPitchingData.current.preEvaluationInviteUser !== undefined &&
      curPitchingData.current.preEvaluationInviteUser.length !== 0;
    setReleaseAble(result);
    return result;
  };

  const onChangeData = (data: { type: PitchingChangeDataType; data: any }) => {
    if (!curPitchingData.current) return;

    switch (data.type) {
      case "preEvaluationDate":
        curPitchingData.current = {
          ...curPitchingData.current,
          preEvaluationDate: data.data,
        };
        break;

      case "preEvaluationInviteUser":
        curPitchingData.current = {
          ...curPitchingData.current,
          preEvaluationInviteUser: data.data,
        };
        break;

      case "investmentInfo":
        if (
          curPitchingData.current.preEvaluationData &&
          curPitchingData.current.preEvaluationData[0]
        ) {
          const newData: SectionType = {
            ...curPitchingData.current.preEvaluationData[0],
            investmentInfo: data.data,
          };
          curPitchingData.current = {
            ...curPitchingData.current,
            preEvaluationData: [newData],
          };
        }
        break;

      case "companyInfo":
        if (
          curPitchingData.current.preEvaluationData &&
          curPitchingData.current.preEvaluationData[0]
        ) {
          const newData: SectionType = {
            ...curPitchingData.current.preEvaluationData[0],
            companyInfo: data.data,
          };
          curPitchingData.current = {
            ...curPitchingData.current,
            preEvaluationData: [newData],
          };
        }
        break;
    }
    onCheckRelaseable();
  };

  const publishingModify = async () => {
    await cancelPitching();
    updateIsModifyMode(true);
  };

  const setModifyMode = () => {
    if (!pitchingData) return;

    if (pitchingData.status === PitchingStatus.OPEN) {
      updateIsModifyMode(true);
    } else {
      setAlreadyPublish(true);
    }
  };

  const publishPitching = async () => {
    if (!pitchingData || !pitchingData.preEvaluationId) return;
    const result = await publishPrePitching(pitchingData.preEvaluationId);
    if (result) {
      init();
    }
  };

  const cancelPitching = async () => {
    if (!pitchingData || !pitchingData.preEvaluationId) return;
    const result = await cancelPrePitching(pitchingData.preEvaluationId);
    if (result) {
      await init();
    }
  };

  const finishPitching = async () => {
    if (!pitchingData || !pitchingData.preEvaluationId) return;
    const result = await finishPrePitching(pitchingData.preEvaluationId);
    if (result) {
      init();
    }
  };

  const updatePitchingData = async () => {
    if (!curPitchingData.current) return;

    const result = await modifyPrePitching(
      portfolioId,
      curPitchingData.current
    );
    if (result) {
      init(true);
      updateIsModifyMode(false);
    }
  };

  const isVotedCountZero = () => {
    if (
      pitchingData &&
      pitchingData.preEvaluationId &&
      pitchingData.pfPreEvaluationResult &&
      pitchingData.pfPreEvaluationResult.length > 0
    ) {
      const data = pitchingData.pfPreEvaluationResult.filter((item) => {
        return item.templateJson !== null;
      });
      return data.length === 0;
    }

    return true;
  };

  const init = async (asdf?: boolean) => {
    const pitchingResult = await getPrepitchingOne(evaluationId);
    const userResult = await getAllAcUser("user");

    const dealFlowSatus = await getDealflowStatusById(evaluationId, "pitching");
    if (dealFlowSatus) {
      setIsOpenDeal(dealFlowSatus === DealFlowStatus.OPEN);
    }
    if (pitchingResult) {
      const data = {
        ...pitchingResult,
      };
      setPitchingData(data);
      curPitchingData.current = structuredClone(data);
      if (!asdf) {
        updateIsModifyMode(
          !onCheckRelaseable() && PitchingStatus.OPEN === data.status
        );
      }

      if (
        [PitchingStatus.PITCHING, PitchingStatus.FINISH] &&
        pitchingResult.preEvaluationId
      ) {
        const finishResult = await getPrePitchingResult(
          pitchingResult.preEvaluationId
        );
        if (finishResult) {
          setPitchingDataResult(finishResult);
          // setPitchingDataResult(dumyData);
        }
      }
    }
    if (userResult)
      setAllUser(
        userResult.filter(
          (item) => item.employmentStatusId === 2 && item.authorityId === 1
        )
      );
  };

  useEffect(() => {
    init();
  }, [location.pathname]);

  useEffect(() => {
    console.log(pitchingData);
    if (!pitchingData) return;

    if (setPageName) {
      const transformDate = getYMD(pitchingData.createdAt)
        .replaceAll("-", "")
        .slice(2);
      setPageName(`${transformDate} 피칭심사`);
    }
  }, [pitchingData]);

  return [
    isOpenDeal,
    allUser,
    pitchingData,
    releaseAble,
    isModifyMode,
    alReadyPublish,
    pitchingDataResult,
    setAlreadyPublish,
    onChangeData,
    updatePitchingData,
    publishPitching,
    cancelPitching,
    finishPitching,
    setModifyMode,
    publishingModify,
    isVotedCountZero,
  ] as const;
};

export default usePrePitchingDetail;
