import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as Edit } from "../../../../../../assets/image/profile_edit.svg";
import DefaultUserProfile from "../../../../../../assets/image/default_user_profile.svg";
import * as api from "../../../../../../api/api";
import axios from "axios";

interface Props {
  className?: string;
  profile?: string;
  limitSize?: number;
  callback?: (url: string, key: string) => void;
  deleteImage?: () => void;
}
export const ProfileUpload: React.FC<Props> = (props) => {
  const { limitSize = 300 } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [alertModal, updateAlerModal] = useState<boolean>(false);
  const [render, setRender] = useState<boolean>(false);
  const reRender = () => setRender((prevState) => !prevState);

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length === 0) return;

    if (e.target.files[0].size / 1024 > limitSize) {
      updateAlerModal(true);
      e.target.value = "";
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const result = (await axios.post(api.uploadAndGetUrl(), formData)).data;
      console.log(result);
      if (result.urlKeyList.length > 0) {
        props.callback &&
          props.callback(result.urlKeyList[0].url, result.urlKeyList[0].key);
      }
      e.target.value = "";
    } catch (error) {}
  };

  useEffect(() => {
    console.log(props.profile);
    reRender();
  }, [props.profile]);
  return (
    <Container>
      <div
        className="profile__container"
        onClick={() => {
          inputRef.current && inputRef.current.click();
        }}
      >
        <div className="profile__img__container">
          <img
            className="profile"
            src={props.profile ? props.profile : DefaultUserProfile}
          />
        </div>
        <Edit className="profile__edit" />
      </div>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        accept="image/png, image/jpeg"
        onChange={uploadFile}
        onClick={(e) => {
          console.log(e);
        }}
      />
    </Container>
  );
};

const Container = styled.div(
  () => css`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;

    .profile__container {
      position: relative;
      width: 80px;
      height: 80px;
      box-sizing: border-box;
      cursor: pointer;

      .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 1px);
        height: calc(100% - 1px);
        cursor: pointer;
      }

      .profile__img__container {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid var(--gray-300);

        .profile {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          object-fit: contain;
          object-position: center center;
        }
      }

      .profile__edit {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .upload__container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 12px;

      .upload__btn {
        width: 142px;
        height: 42px;
        background: #ffffff;
        border: 1px solid var(--primary);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        color: var(--primary);

        cursor: pointer;
      }

      .upload__description {
        color: var(--grey_20);
      }
    }

    .icon {
      width: 24px;
      height: 24px;
    }
    .text {
      flex-grow: 1;
    }
    .center_text {
      width: 100%;
      text-align: center;
    }
  `
);
