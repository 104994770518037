import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";

const MobileBluecardManagePage: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const { portfolioId, init, isEditable = false } = props;
  return <Container>준비중 입니다.</Container>;
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default MobileBluecardManagePage;
