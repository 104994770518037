import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

import { Tooltip } from "react-tooltip";
import { AddComma, getTransformedId } from "../../../../../common/commonUtil";
import { InvestmentPerformanceProps } from "../../interface/FundAccountDetail.interface";

interface Props {
  index: number;
  data: InvestmentPerformanceProps;
}
export const TableRow: React.FC<Props> = ({ index, data }) => {
  const DIVISION_NUM = 1000000;

  const goToPortfolio = (portfolioId: number) => {
    const to = `/portfolio/${getTransformedId(portfolioId)}/dashboard`;
    window.open(to, "_blank", "rel=noopener noreferrer");
  };

  const dataValidation = () => {
    if (data.originInvestmentAmount < 0) {
      return "error";
    }
    if (data.originExitAmount + data.originInvestmentAmount < 10000000) {
      return "error";
    }
    if (
      data.phase === "MANAGE" &&
      data.originInvestmentAmount !== 0 &&
      data.investedValue === 0
    ) {
      return "error";
    }
    if (
      data.phase === "MANAGE" &&
      data.originInvestmentAmount === 0 &&
      data.investedValue > 0
    ) {
      return "error";
    }

    return "";
  };
  const getRiskStatusCircle = () => {
    if (data.riskStatus === null) {
      return <Circle style={{ backgroundColor: "var(--gray-600)" }} />;
    }
    switch (data.riskStatus.id) {
      case 1:
        return <Circle style={{ backgroundColor: "var(--blue-500)" }} />;
      case 2:
        return <Circle style={{ backgroundColor: "var(--green-500)" }} />;
      case 3:
        return <Circle style={{ backgroundColor: "var(--yellow-500)" }} />;
      case 4:
        return <Circle style={{ backgroundColor: "var(--orange-500)" }} />;
      default:
        return <Circle style={{ backgroundColor: "var(--red-500)" }} />;
    }
  };
  const getRiskStatusSquere = () => {
    if (data.riskStatus === null) {
      return <Squere style={{ backgroundColor: "var(--gray-600)" }} />;
    }
    switch (data.riskStatus.id) {
      case 1:
        return <Squere style={{ backgroundColor: "var(--blue-500)" }} />;
      case 2:
        return <Squere style={{ backgroundColor: "var(--green-500)" }} />;
      case 3:
        return <Squere style={{ backgroundColor: "var(--yellow-500)" }} />;
      case 4:
        return <Squere style={{ backgroundColor: "var(--orange-500)" }} />;
      default:
        return <Squere style={{ backgroundColor: "var(--red-500)" }} />;
    }
  };

  if (data.phase === "DAMAGE") {
    console.log(data);
  }
  return (
    <Container className={`caption-12-rg ${dataValidation()}`}>
      <CellConatiner
        className={`risk__status__${index} link__cell`}
        width={140}
        style={{
          position: "sticky",
          left: 0,
          cursor: "pointer",
          justifyContent: "flex-start",
          gap: 6,
        }}
        onClick={() => {
          goToPortfolio(data.portfolioId);
        }}
      >
        {getRiskStatusCircle()}
        {data.companyName}
      </CellConatiner>

      <CellConatiner width={72} style={{ justifyContent: "flex-start" }}>
        {data.mainParticipant}
      </CellConatiner>

      <CellConatiner width={72} style={{ justifyContent: "flex-start" }}>
        {data.firstPaymentDate.replaceAll("-", ".").slice(2)}
      </CellConatiner>
      <CellConatiner width={78}>
        {AddComma((data.originExitAmount / DIVISION_NUM).toFixed(0))}
      </CellConatiner>
      <CellConatiner width={78}>
        {AddComma((data.originInvestmentAmount / DIVISION_NUM).toFixed(0))}
      </CellConatiner>
      <CellConatiner
        width={78}
        style={{
          color: "var(--blue-400)",
        }}
      >
        {AddComma(
          (
            (data.originExitAmount + data.originInvestmentAmount) /
            DIVISION_NUM
          ).toFixed(0)
        )}
      </CellConatiner>
      <CellConatiner width={78}>
        {AddComma((data.exitAmount / DIVISION_NUM).toFixed(0))}
      </CellConatiner>
      <CellConatiner width={78}>
        {AddComma((data.investedValue / DIVISION_NUM).toFixed(0))}
      </CellConatiner>
      <CellConatiner
        width={78}
        style={{
          color: "var(--blue-400)",
        }}
      >
        {AddComma(
          ((data.exitAmount + data.investedValue) / DIVISION_NUM).toFixed(0)
        )}
      </CellConatiner>
      <CellConatiner width={64}>
        {data.originExitAmount === 0 ? "" : (data.exitMOIC || 0).toFixed(1)}
      </CellConatiner>
      <CellConatiner width={64}>
        {data.originExitAmount === 0
          ? ""
          : ["-0.0", "0.0"].includes(data.exitIRR.toFixed(1))
          ? "0.0"
          : data.exitIRR.toFixed(1)}
      </CellConatiner>
      <CellConatiner width={64}>
        {data.phase === "DAMAGE" ||
        data.phase === "CLOSURE" ||
        data.originInvestmentAmount === 0
          ? ""
          : data.investedMOIC.toFixed(1)}
      </CellConatiner>
      <CellConatiner width={64}>
        {data.phase === "DAMAGE" ||
        data.phase === "CLOSURE" ||
        data.originInvestmentAmount === 0
          ? ""
          : ["-0.0", "0.0"].includes(data.investedIRR.toFixed(1))
          ? "0.0"
          : data.investedIRR.toFixed(1)}
      </CellConatiner>
      <CellConatiner width={64}>
        {data.resultMOIC === 0 ? "" : data.resultMOIC.toFixed(1)}
      </CellConatiner>
      <CellConatiner width={64} style={{ borderRight: 0 }}>
        {data.resultIRR === 0
          ? ""
          : ["-0.0", "0.0"].includes(data.resultIRR.toFixed(1))
          ? "0.0"
          : data.resultIRR.toFixed(1)}
      </CellConatiner>

      <Tooltip
        className="caption-12-sb"
        anchorSelect={`.risk__status__${index}`}
        place="bottom"
        border={0}
        style={{ zIndex: 99999999, borderRadius: 4 }}
      >
        <FlexRow style={{ alignItems: "center", gap: 6 }}>
          {getRiskStatusSquere()}
          {`${data.companyName} : ${
            data.riskStatus ? data.riskStatus.name : "평가없음"
          }`}
        </FlexRow>
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  width: max-content;
  height: 100%;
  height: max-content;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  &.error {
    background-color: var(--red-50);
  }
  :hover {
    background-color: var(--hover-100);
  }
`;

const Circle = styled.div`
  width: 6px;
  min-width: 6px;
  max-width: 6px;
  height: 6px;
  min-height: 6px;
  max-height: 6px;
  border-radius: 50%;
  overflow: hidden;
`;
const Squere = styled.div`
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  min-height: 16px;
  max-height: 16px;
  border: 1px solid var(--gray-200);
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: default;
  color: var(--text-01);
  background-color: var(--background-contents01, #fff);
  border-right: 0.5px solid var(--divider-300, #e3e4e5);
  border-bottom: 0.5px solid var(--divider-300, #e3e4e5);
  padding: 8px 10px;

  &.link__cell {
    :hover {
      text-decoration: underline;
    }
  }
`;
