import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../../components/navigation/NavigationLayout";
import { MobilePerformanceMetrics } from "./mobile/ MobilePerformanceMetrics";
import { DeskPerformanceMetrics } from "./desktop/DeskPerformanceMetrics";

const PerformanceMetrics = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobilePerformanceMetrics /> : <DeskPerformanceMetrics />}
    </NavigationLayout>
  );
};
export default PerformanceMetrics;
