import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DefaultButton } from "lattice_core";

import useRequestEvaluation from "./hook/useRequestEvaluation";
import CheckAuthCode from "./CheckAuthCode";
import EvaluationStartupInfo from "./EvaluationStartupInfo";
import AgreeTerms from "./AgreeTerms";

const RequestEvaluationInfo = () => {
  const [
    authSuccess,
    isAuthSuccess,
    isPortfolioTermAgree,
    privacy,
    personal,
    setIsAuthSuccess,
    checkAuthCode,
    getTerms,
    agreeTerms,
  ] = useRequestEvaluation();
  return (
    <Container>
      {!authSuccess && (
        <CheckAuthCode
          checkAuthCode={checkAuthCode}
          isValidCode={isAuthSuccess}
          setIsAuthSuccess={setIsAuthSuccess}
        />
      )}
      {authSuccess && !isPortfolioTermAgree && (
        <AgreeTerms
          privacy={privacy}
          personal={personal}
          agreeTerms={agreeTerms}
        />
      )}
      {authSuccess && isPortfolioTermAgree && <EvaluationStartupInfo />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export default RequestEvaluationInfo;
