import { CSSProperties, useRef, useState } from "react";
import styled, { css } from "styled-components";
import DefaultSearch from "../../../../components-2.0v/search/DefaultSearch";

interface Props {
  data: SearchSelectorItemProps[];
  disabled?: boolean;
  transfromPixel: (value: number) => number;
  onSelectItem: (data: SearchSelectorItemProps) => void;
}

export interface SearchSelectorItemProps {
  id: number;
  text: string;
  data: any;
}

const SearchSelector = (props: Props) => {
  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  return (
    <Container transfromPixel={props.transfromPixel}>
      <DefaultSearch
        isInitValue
        value={searchKeyword}
        placeholder={"기업명 검색"}
        transfromPixel={props.transfromPixel}
        onChangeValue={(value) => updateSearchKeyword(value)}
        onPressEnter={(value) => {
          const items = props.data.filter((item) => item.text.includes(value));
          if (items.length !== 1) return;

          updateSearchKeyword("");
          props.onSelectItem(items[0]);
        }}
      />
      {searchKeyword.length > 0 &&
        props.data.filter((item) => item.text.includes(searchKeyword)).length >
          0 && (
          <SelectListContainer transfromPixel={props.transfromPixel}>
            {props.data
              .filter((item) => item.text.includes(searchKeyword))
              .map((item) => {
                return (
                  <SelectListItem
                    className="body-14-rg"
                    transfromPixel={props.transfromPixel}
                    onClick={() => {
                      updateSearchKeyword("");
                      props.onSelectItem(item);
                    }}
                  >
                    {item.text}
                  </SelectListItem>
                );
              })}
          </SelectListContainer>
        )}
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  position: relative;
  width: ${(props) => props.transfromPixel(282)}px;
  height: ${(props) => props.transfromPixel(36)}px;
  cursor: default;
`;

const SelectListContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;
    top: calc(100% + ${transfromPixel(10)}px);
    left: 0;
    width: 100%;
    height: fit-content;
    max-height: ${transfromPixel(36 * 6 + 16)}px;
    padding: ${transfromPixel(8)}px 0px;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.1);
    border-radius: ${transfromPixel(4)}px;

    background-color: #ffffff;
    z-index: 1001;
  `
);

const SelectListItem = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: 100%;
    height: ${transfromPixel(36)}px;
    padding: ${transfromPixel(7)}px ${transfromPixel(16)}px;
    display: flex;
    flex-direction: row;

    cursor: pointer;

    :hover {
      background-color: #f3f4f4;
    }
  `
);
export default SearchSelector;
