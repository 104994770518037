import React, {
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  getReportingHistory,
  setReportingHistory,
} from "../../../common/pageHistoryUtil";
import { VirtuosoHandle } from "react-virtuoso";
import { getMyInfo } from "../../../common/commonUtil";
import useResize from "../../../hooks/useResize";
import {
  GridDataProps,
  GridHeaderProps,
} from "../desktop/components/grid/LatticeGrid";
import {
  AcceleratorReportRangeProps,
  PortfolioQuarterReportProps,
  PortfolioReportDocumentListProps,
  ReportSearchParamProps,
  ReportingUIData,
  reportingPortfolioFilterList,
} from "../interface/Reporting.interface";
import { RollingSelectorDataProps } from "../../../components-2.0v/rolling-selector/RollingSelector";
import CustomHeader from "../desktop/components/CustomHeader";
import CustomCell from "../desktop/components/CustomCell";
import { DefaultButton } from "lattice_core";
import {
  closeQuarter,
  getOpenQuarter,
  getReportList,
  getReportRange,
  openQuarter,
  openSelectPortfolioQuarter,
} from "../repository/ReportingRepository";
import styled from "styled-components";
import { SelectorProps } from "../../../type/data";
import { CustomColorLabel } from "../../../components-2.0v/label/CustomColorLabel";

const EmptySentenceContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const useReporting = () => {
  const navigate = useNavigate();

  const reportingHistory = getReportingHistory();
  const history = reportingHistory?.search;

  const [paused, updatePaused] = useState<boolean>(false);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const virtuoso = useRef<VirtuosoHandle>(null);
  const timer = useRef<NodeJS.Timeout | undefined>(undefined);

  const [scrollStart, setScrollStart] = useState<boolean>(true);
  const [scrollEnd, setScrollEnd] = useState<boolean>(false);

  const myInfo = getMyInfo();
  const [screenRate, transfromPixel] = useResize();

  const [reportData, updateReportData] = useState<GridDataProps[]>([]);
  const [checkedData, updateCheckedData] = useState<GridDataProps[]>([]);
  const [showCheckedDataList, updateShowCheckedDataList] =
    useState<boolean>(false);
  const [header, updateHeader] = useState<GridHeaderProps[]>([]);

  const [isQuarterOpen, updateIsQuarterOpen] = useState<boolean>(false);
  const [isPartiallyQuarterOpen, updateIsPartiallyQuarterOpen] =
    useState<boolean>(false);
  const [openedQuarter, updateOpenedQuarter] = useState<
    { year: number; quarterNum: number } | undefined
  >(undefined);

  const [isDataExist, updateIsDataExist] = useState<boolean>(false);
  const [reportRangeData, updateReportRangeData] = useState<
    AcceleratorReportRangeProps | undefined
  >(undefined);
  const [yearSelectorList, updateYearSelectorList] = useState<
    RollingSelectorDataProps[]
  >([]);

  const [tempData, updateTempData] = useState<ReportingUIData[]>([]);
  const [statusFilter, updateStatusFilter] = useState<SelectorProps>(
    reportingPortfolioFilterList[0]
  );

  const selectedYear = useRef<number>(0);
  const selectedQuarter = useRef<number>(0);
  const selectedSortItem = useRef<number>(-1);
  const selectedSortType = useRef<number>(-1);

  const [openedQuarterPortfolioCount, updateOpenedQuarterPortfolioCount] =
    useState<number>(0);

  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [searched, updateSearched] = useState<boolean>(false);

  const [openQuarterModal, updateOpenQuarterModal] = useState<boolean>(false);
  const [closeQuarterModal, updateCloseQuarterModal] = useState<boolean>(false);
  const [alertModal, updateAlertModal] = useState<boolean>(false);
  const alertModalTitle = useRef<string>("");
  const alertModalContents = useRef<string[]>([]);
  const alertModalPositiveTitle = useRef<string>("");

  const tranfromData = (
    documentList: PortfolioReportDocumentListProps[],
    data: PortfolioQuarterReportProps[]
  ): any[] => {
    const newData = data.map((item) => {
      const participantMain = item.portfolio.pfParticipant.find(
        (item) => item.screenRole.screenRoleId === 1
      );
      const participantUsers = item.portfolio.pfParticipant.map(
        (item) => item.acUser.acUserId
      );
      const transData: any = {
        isOpen: item.isOpen,
        accessUsers: participantUsers,
        portfolioId: item.portfolio.portfolioId,
        portfolioName: item.portfolio.companyName,
        participantMain: participantMain ? participantMain.acUser.name : "",
        phase: item.portfolio.pfPhase.pfPhaseId,
      };

      documentList.forEach((report, index) => {
        const targetDocument = item.pfReportDocument.find(
          (document) =>
            document.acPfDocument.acPfDocumentId === report.acPfDocumentId
        );

        transData[`report-${report.acPfDocumentId}`] = {
          acPfDocumentId: report.acPfDocumentId,
          name: targetDocument?.documentFile?.fileName || report.name,
          extension: report.extension,
          required: report.required,
          file: targetDocument
            ? {
                reportDocumentId: targetDocument.pfReportDocumentId,
                uploadStatusId: targetDocument.uploadStatus.uploadStatusId,
                uploadStatusName: targetDocument.uploadStatus.name,
              }
            : undefined,
        };
      });
      return transData;
    });
    return newData;
  };

  const getWidth = (documentId: number) => {
    switch (documentId) {
      case 1:
        return 148;
      case 2:
        return 148;
      case 3:
        return 132;
      case 4:
        return 148;
      case 5:
        return 164;
      case 6:
        return 148;
      case 7:
        return 172;
      case 8:
        return 148;
      case 9:
        return 148;
      case 10:
        return 148;
      default:
        return 180;
    }
  };

  const getGridHeader = (
    transfromPixel: (value: number) => number,
    documentList: PortfolioReportDocumentListProps[]
  ) => {
    const header: GridHeaderProps[] = [
      {
        header: "기업명",
        key: "portfolioName",
        width: transfromPixel(282),
        customHeaderCell: (data) => {
          return (
            <CustomHeader
              title={"기업명"}
              transfromPixel={transfromPixel}
              documentId={-1}
            />
          );
        },
        customCell: (data) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 12,
              }}
            >
              <span
                className="body-14-rg"
                style={{
                  padding: `0 ${transfromPixel(10)}px`,
                }}
              >
                {data}
              </span>
              {data.data?.phase && [5, 6].includes(data.data.phase) && (
                <CustomColorLabel
                  bgColor={
                    data.data?.phase === 5 ? "var(--blue-100)" : "var(--red-50)"
                  }
                  textColor={
                    data.data?.phase === 5
                      ? "var(--blue-500)"
                      : "var(--red-500)"
                  }
                >
                  {data.data?.phase === 5 ? "관리" : "손상"}
                </CustomColorLabel>
              )}
            </div>
          );
        },
      },
      {
        header: "상태",
        key: "phase",
        width: transfromPixel(80),
        customHeaderCell: (data) => {
          return (
            <CustomHeader
              title={"상태"}
              transfromPixel={transfromPixel}
              documentId={-1}
            />
          );
        },
      },
      {
        header: "담당자명",
        key: "participantMain",
        width: transfromPixel(90),
        customHeaderCell: (data) => {
          return (
            <CustomHeader
              title={"담당자명"}
              transfromPixel={transfromPixel}
              documentId={-1}
            />
          );
        },
        customCell: (data) => {
          return <CustomCell data={data} transfromPixel={transfromPixel} />;
        },
      },
      ...documentList.map((document, index): GridHeaderProps => {
        return {
          header: document.name,
          key: `report-${document.acPfDocumentId}`,
          width: transfromPixel(getWidth(document.acPfDocumentId)),
          customHeaderCell: (allData) => {
            return (
              <CustomHeader
                title={document.name}
                transfromPixel={transfromPixel}
                hasOrder
                selectedSortItem={selectedSortItem.current}
                selectedSortType={selectedSortType.current}
                documentId={document.acPfDocumentId}
                onOrdering={(documentId, sortingId) => {
                  if (
                    selectedSortItem.current === documentId &&
                    selectedSortType.current === sortingId
                  ) {
                    selectedSortItem.current = -1;
                    selectedSortType.current = -1;
                  } else {
                    selectedSortItem.current = documentId;
                    selectedSortType.current = sortingId;
                  }

                  getReportListData({
                    year: selectedYear.current,
                    quarterNum: selectedQuarter.current,
                    search: "",
                    sortItem:
                      selectedSortItem.current !== -1
                        ? selectedSortItem.current
                        : undefined,
                    sortType:
                      selectedSortType.current !== -1
                        ? selectedSortType.current
                        : undefined,
                  });
                }}
              />
            );
          },
          customCell: (data) => {
            return <CustomCell data={data} transfromPixel={transfromPixel} />;
          },
        };
      }),
    ];
    return header;
  };

  const checkQuarterOpen = (): boolean => {
    if (!openedQuarter) return true;
    if (
      openedQuarter.year === selectedYear.current &&
      openedQuarter.quarterNum === selectedQuarter.current
    )
      return true;

    alertModalTitle.current = "분기보고를 열 수 없음";
    alertModalContents.current = [
      `분기보고가 열려있으면 다른 분기를 열 수 없습니다.`,
      `${openedQuarter.year}년 ${openedQuarter.quarterNum}분기를 종료 후 해당 분기를 열어주세요`,
    ];
    alertModalPositiveTitle.current = "확인";
    updateAlertModal(true);

    return false;
  };

  const isEndQuarter = (): boolean => {
    return (
      reportRangeData !== undefined &&
      selectedYear.current === reportRangeData.acceleratorRange.end.year &&
      selectedQuarter.current === reportRangeData.acceleratorRange.end.quarter
    );
  };

  const isHideSearch = (): boolean => {
    return (
      reportRangeData !== undefined &&
      selectedYear.current === reportRangeData.acceleratorRange.end.year &&
      selectedQuarter.current >= reportRangeData.acceleratorRange.end.quarter &&
      !isQuarterOpen
    );
  };

  const getEmptyComponent = () => {
    if (!reportRangeData) return;

    const endYear = reportRangeData.acceleratorRange.end.year;
    const endQuarter = reportRangeData.acceleratorRange.end.quarter;
    const myAuthorityId = myInfo.authorityId;

    const textArr = [];
    let existOpenQuarter = false;

    console.log(myInfo);

    if (myAuthorityId === 1) {
      //심사역
      if (
        selectedYear.current === endYear &&
        selectedQuarter.current > endQuarter
      ) {
        //분기보고가 아직 열리지 않았습니다.
        textArr.push("분기보고가 아직 열리지 않았습니다.");
      } else {
        if (searchKeyword.length > 0) {
          // 검색 결과가 없습니다.
          textArr.push("검색 결과가 없습니다.");
        } else {
          // 분기보고 대상인 담당 포트폴리오가 없습니다.
          textArr.push("분기보고 대상인 담당 포트폴리오가 없습니다.");
        }
      }
    }

    if (myAuthorityId === 2) {
      //매니저
      if (
        selectedYear.current === endYear &&
        selectedQuarter.current >= endQuarter
      ) {
        //분기보고가 아직 열리지 않았습니다. \n 분기보고를 시작하려면 아래 버튼을 눌러주세요.
        textArr.push("분기보고가 아직 열리지 않았습니다.");
        if (
          selectedYear.current === endYear &&
          selectedQuarter.current === endQuarter
        ) {
          textArr.push("분기보고를 시작하려면 아래 버튼을 눌러주세요.");
          existOpenQuarter = isEndQuarter();
        }
      } else {
        if (searchKeyword.length > 0) {
          // 검색 결과가 없습니다.
          textArr.push("검색 결과가 없습니다.");
        } else {
          // 분기보고 대상인 포트폴리오가 없습니다.
          textArr.push("분기보고 대상인 포트폴리오가 없습니다.");
        }
      }
    }

    //Todo 분기보고 시작 버튼 스타일 변경, 분기보고 시작 api
    return (
      <EmptySentenceContainer style={{ marginTop: transfromPixel(20) }}>
        {textArr.map((item, index) => {
          return (
            <div
              key={index}
              className="heading-18-md"
              style={{
                color: "#7E8086",
              }}
            >
              {item}
            </div>
          );
        })}
        {existOpenQuarter && (
          <div style={{ marginTop: transfromPixel(30) }}>
            <DefaultButton
              className="heading-16-md"
              buttonType="filled"
              sizeType="small"
              buttonColor="#173AFF"
              hoverColor="#112CBF"
              text="분기보고 시작"
              onClick={() => {
                updateOpenQuarterModal(true);
              }}
            />
          </div>
        )}
      </EmptySentenceContainer>
    );
  };

  const onOpenQuarter = async () => {
    try {
      const result = await openQuarter(
        selectedYear.current,
        selectedQuarter.current
      );
      if (result) {
        alertModalTitle.current = "분기보고 시작";
        alertModalContents.current = [
          `${selectedYear.current}년 ${selectedQuarter.current}분기를 시작했습니다. :)`,
        ];
        alertModalPositiveTitle.current = "확인";

        await getReportListData({
          year: selectedYear.current,
          quarterNum: selectedQuarter.current,
          search: "",
        });
        updateCheckedData([]);
        updateAlertModal(true);
        virtuoso.current?.scrollTo({ top: 0, behavior: "auto" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onOpenSelectPortfolioQuarter = async () => {
    try {
      if (checkedData.length === 0) return;

      const selectedPortfolio = checkedData.map((item) => ({
        portfolioId: item.identificationNumber,
      }));
      const result = await openSelectPortfolioQuarter(
        selectedYear.current,
        selectedQuarter.current,
        selectedPortfolio
      );
      console.log(checkedData);
      if (result) {
        alertModalTitle.current = "분기보고 열기";
        alertModalContents.current = [
          `'${checkedData[0].data.portfolioName}'외 ${
            checkedData.length - 1
          }건의`,
          `${selectedYear.current}년 ${selectedQuarter.current}분기를 열었습니다. :)`,
        ];
        alertModalPositiveTitle.current = "확인";

        await getReportListData({
          year: selectedYear.current,
          quarterNum: selectedQuarter.current,
          search: "",
        });
        updateCheckedData([]);
        updateAlertModal(true);
        virtuoso.current?.scrollTo({ top: 0, behavior: "auto" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseQuarter = async () => {
    try {
      const result = await closeQuarter();
      if (result) {
        alertModalTitle.current = "분기보고 종료";
        alertModalContents.current = [
          `${selectedYear.current}년 ${selectedQuarter.current}분기를 종료했습니다. :)`,
        ];
        alertModalPositiveTitle.current = "확인";

        await getReportListData({
          year: selectedYear.current,
          quarterNum: selectedQuarter.current,
          search: "",
        });
        updateCheckedData([]);
        updateAlertModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReportRangeData = async () => {
    try {
      const data = await getReportRange();
      if (!data) return;

      const { acceleratorRange, portfolioRange, current } = data;
      const yearArray = Array.from(
        { length: acceleratorRange.end.year - acceleratorRange.start.year + 1 },
        (v, i) => acceleratorRange.start.year + i
      );
      const rangeData = yearArray.map(
        (value): RollingSelectorDataProps => ({
          id: value,
          text: `${value}년`,
          data: value,
        })
      );
      updateReportRangeData({ acceleratorRange, portfolioRange, current });
      updateYearSelectorList(rangeData);

      if (history && history.year && history.quarter) {
        selectedYear.current = history.year;
        selectedQuarter.current = history.quarter;

        return {
          currentYear: history.year,
          currentQuarter: history.quarter,
        };
      } else {
        selectedYear.current = current.year;
        selectedQuarter.current = current.quarter;
        return {
          currentYear: current.year,
          currentQuarter: current.quarter,
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReportListData = async (param: ReportSearchParamProps) => {
    try {
      updatePaused(true);
      console.log(param);
      setReportingHistory({
        search: {
          year: param.year,
          quarter: param.quarterNum,
          sortItem: param.sortItem,
          sortType: param.sortType,
        },
      });
      const reportListData = await getReportList(param);
      if (reportListData) {
        console.log(reportListData);

        //isExist 추가
        updateIsDataExist(reportListData.isExist);
        const data = tranfromData(
          reportListData.acPfDocument,
          reportListData.portfolioList
        );
        console.log(reportListData.portfolioList.filter((item) => item.isOpen));
        const openPortfolio = reportListData.portfolioList.filter(
          (item) => item.isOpen
        );

        const openedQuarter = await getOpenQuarter();
        if (openedQuarter) {
          updateIsQuarterOpen(
            openedQuarter.year === param.year &&
              openedQuarter.quarterNum === param.quarterNum
          );
          updateOpenedQuarter({ ...openedQuarter });
        } else {
          updateOpenedQuarter(undefined);
        }

        updateIsPartiallyQuarterOpen(
          data.length > 0 && data.length !== openPortfolio.length
        );

        updateOpenedQuarterPortfolioCount(openPortfolio.length);
        updateHeader(
          getGridHeader(transfromPixel, reportListData.acPfDocument)
        );

        const reportGridData = data.map(
          (item: any, index): GridDataProps => ({
            id: index,
            identificationNumber: item.portfolioId,
            data: item,
          })
        );
        console.log(reportGridData);
        const openedData = reportGridData.filter((item) => item.data.isOpen);
        const closedData = reportGridData.filter((item) => !item.data.isOpen);
        updateReportData([...openedData, ...closedData]);
      } else {
        //리포트 리스트 에러
        updatePaused(false);
      }
    } catch (error) {
      console.log(error);
      updatePaused(false);
    }
  };

  const statusInit = () => {
    updateSearchKeyword("");
    updateCheckedData([]);
    selectedSortItem.current = -1;
    selectedSortType.current = -1;
  };
  const dataInit = async () => {
    try {
      const rangeData = await getReportRangeData();
      if (rangeData) {
        console.log(history);
        const { currentYear, currentQuarter } = rangeData;
        selectedSortItem.current =
          history?.sortItem === undefined ? -1 : history.sortItem;
        selectedSortType.current =
          history?.sortType === undefined ? -1 : history.sortType;

        await getReportListData({
          year: currentYear,
          quarterNum: currentQuarter,
          search: history?.keyword || "",
          sortItem: selectedSortItem.current,
          sortType: selectedSortType.current,
        });
        await setIsLoaded(true);
      } else {
        //쿼터 범위 에러
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleScroll = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setIsScrolling(false);
      timer.current = undefined;
    }, 3000);
  };

  useEffect(() => {
    if (isScrolling) {
      handleScroll();
    }
  }, [isScrolling]);

  useEffect(() => {
    dataInit();
  }, []);

  useEffect(() => {
    const uiData: ReportingUIData[] = [];
    reportData.forEach((item) => {
      uiData.push({ type: "TABLE_DATA", data: item });
    });

    updateTempData([...uiData]);
    updatePaused(false);
  }, [reportData]);

  useEffect(() => {
    if (tableRef.current) {
      setScrollStart(true);
      setScrollEnd(
        tableRef.current.clientWidth >
          148 + 148 + 132 + 148 + 164 + 148 + 172 + 148 + 148 + 148 + 180
      );
    }

    if (history && virtuoso.current) {
      setTimeout(() => {
        history.scrollTop &&
          virtuoso.current?.scrollTo({ top: history.scrollTop });
      }, 10);

      history.keyword && updateSearchKeyword(history.keyword);
    }
  }, [tempData]);

  return [
    isLoaded,
    myInfo,
    isQuarterOpen,
    openedQuarterPortfolioCount,
    yearSelectorList,
    paused,
    selectedYear,
    selectedQuarter,
    isDataExist,
    searchKeyword,
    selectedSortItem,
    selectedSortType,
    tempData,
    searched,
    tableRef,
    virtuoso,
    isScrolling,
    isPartiallyQuarterOpen,
    header,
    checkedData,
    alertModalTitle,
    alertModalContents,
    alertModalPositiveTitle,
    scrollStart,
    scrollEnd,
    openQuarterModal,
    alertModal,
    showCheckedDataList,
    closeQuarterModal,
    statusFilter,
    updateStatusFilter,
    transfromPixel,
    statusInit,
    getReportListData,
    updateSearchKeyword,
    updateSearched,
    updateCloseQuarterModal,
    setIsScrolling,
    setScrollStart,
    setScrollEnd,
    updateCheckedData,
    getEmptyComponent,
    updateAlertModal,
    navigate,
    updateOpenQuarterModal,
    onOpenQuarter,
    onCloseQuarter,
    updateShowCheckedDataList,
    checkQuarterOpen,
    onOpenSelectPortfolioQuarter,
  ] as const;
};

export default useReporting;
