import React, { useEffect, useRef, useState } from "react";
import { Bounce } from "react-toastify";

import { FinancialStatementEventProps } from "../interface/FinancialStatement.interface";
import { rootStyle } from "../../../../../../style/ThemeStyles";

import { showToast } from "../../../../../../common/commonUtil";
import {
  changeReportingFile,
  getSBPData,
  postReportingFile,
  saveFinancialStatementData,
} from "../../../../repository/ReportingRepository";
import { ReportingFile } from "../../../../../reporting-detail/interface/ReportingDetail.interface";
import { FinancialStatementFullProps } from "../../../../../../type/data";

const useFinancialStatement = (props: {
  portfolioId: number;
  data: ReportingFile;
  fileUploadInfo: {
    year: number;
    quarter: number;
    index: number;
  };
  isOpen?: boolean;
  onFileReupload?: () => void;
}) => {
  const [localPdfFile, setLocalPdfFile] = useState<File | undefined>(undefined);
  const [financialStatement, updateFinancialStatement] =
    useState<FinancialStatementFullProps>({});
  const [financialStatementEvent, updateFinancialStatementEvent] = useState<
    FinancialStatementEventProps | undefined
  >(undefined);

  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const onCheckFileType = (file: File) => {
    return ["application/pdf", "application/haansoftpdf"].includes(file.type);
  };

  const checkFileImport = (file: File, importType: "file" | "dnd") => {
    if (!onCheckFileType(file)) {
      return updateFinancialStatementEvent({
        type: "NOT_FILE_TYPE",
        importType,
        file,
      });
    }

    return updateFinancialStatementEvent({
      type: "READ",
      importType,
      file,
    });
  };

  const onChangeFileDND = (e: any) => {
    if (!props.isOpen || props.data.file !== undefined) return;
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      checkFileImport(e.dataTransfer.files[0], "dnd");
    }
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.isOpen) return;
    if (e.target.files && e.target.files.length > 0)
      checkFileImport(e.target.files[0], "file");
  };

  const onChangeFinancialStatement = (data: FinancialStatementFullProps) => {
    const currentAssets =
      (data.cashAssets || 0) +
      (data.inventoryAssets || 0) +
      (data.otherCurrentAssets || 0);
    const nonCurrentAssets =
      (data.investmentAssets || 0) +
      (data.tangibleAssets || 0) +
      (data.intangibleAssets || 0) +
      (data.otherNonCurrentAssets || 0);
    const totalLiabilities =
      (data.currentLiabilities || 0) + (data.nonCurrentLiabilities || 0);
    const totalEquity =
      (data.capital || 0) +
      (data.capitalSurplus || 0) +
      (data.capitalAdjustment || 0) +
      (data.accumulatedOtherComprehensiveIncome || 0) +
      (data.retainedEarnings || 0) -
      (data.accumulatedDeficit || 0);

    const grossProfit = (data.revenue || 0) - (data.costOfSales || 0);
    const operatingProfit =
      grossProfit - (data.sellingGeneralAdministrativeExpenses || 0);
    const profitBeforeTaxExpense =
      operatingProfit +
      (data.nonOperatingIncome || 0) -
      (data.nonOperatingExpenses || 0);
    const netIncome = profitBeforeTaxExpense - (data.incomeTaxExpense || 0);

    const newData: FinancialStatementFullProps = {
      ...data,
      currentAssets,
      nonCurrentAssets,
      totalAssets: currentAssets + nonCurrentAssets,
      totalLiabilities,
      totalEquity,
      totalLiabilitiesEquity: totalLiabilities + totalEquity,
      grossProfit,
      operatingProfit,
      profitBeforeTaxExpense,
      netIncome,
    };

    updateFinancialStatement({ ...newData });
  };

  const validationAsset = () => {
    return (
      financialStatement.currentAssets !== undefined &&
      financialStatement.cashAssets !== undefined &&
      financialStatement.inventoryAssets !== undefined &&
      financialStatement.otherCurrentAssets !== undefined &&
      financialStatement.nonCurrentAssets !== undefined &&
      financialStatement.investmentAssets !== undefined &&
      financialStatement.tangibleAssets !== undefined &&
      financialStatement.intangibleAssets !== undefined &&
      financialStatement.otherNonCurrentAssets !== undefined &&
      financialStatement.totalAssets !== undefined
    );
  };

  const validationDebtCapital = () => {
    return (
      financialStatement.totalLiabilities !== undefined &&
      financialStatement.currentLiabilities !== undefined &&
      financialStatement.nonCurrentLiabilities !== undefined &&
      financialStatement.totalEquity !== undefined &&
      financialStatement.capital !== undefined &&
      financialStatement.capitalAdjustment !== undefined &&
      financialStatement.capitalSurplus !== undefined &&
      financialStatement.accumulatedOtherComprehensiveIncome !== undefined &&
      financialStatement.retainedEarnings !== undefined &&
      financialStatement.accumulatedDeficit !== undefined &&
      financialStatement.totalLiabilitiesEquity !== undefined
    );
  };

  const validationIncomeStatement = () => {
    return (
      financialStatement.grossProfit !== undefined &&
      financialStatement.revenue !== undefined &&
      financialStatement.costOfSales !== undefined &&
      financialStatement.operatingProfit !== undefined &&
      financialStatement.sellingGeneralAdministrativeExpenses !== undefined &&
      financialStatement.profitBeforeTaxExpense !== undefined &&
      financialStatement.nonOperatingIncome !== undefined &&
      financialStatement.nonOperatingExpenses !== undefined &&
      financialStatement.incomeTaxExpense !== undefined &&
      financialStatement.netIncome !== undefined
    );
  };

  const validationEqualAssetAndDebtCapital = () => {
    if (
      financialStatement.totalAssets === undefined ||
      financialStatement.totalLiabilitiesEquity === undefined
    )
      return true;

    return (
      financialStatement.totalAssets ===
      financialStatement.totalLiabilitiesEquity
    );
  };

  const onSubmitAble = () => {
    if (!financialStatement) return false;

    return (
      validationAsset() &&
      validationDebtCapital() &&
      validationIncomeStatement() &&
      validationEqualAssetAndDebtCapital()
    );
  };

  const submit = async () => {
    setUploading(true);
    if (props.data.file) {
      console.log("파일이 있어");
      if (localPdfFile) {
        const res = await changeReportingFile({
          portfolioId: props.portfolioId,
          documentFile: localPdfFile,
          isValidation: true,
          uploadStatusId: 1,
          pfReportDoumentId: props.data.file.pfReportDocumentId,
          pfReportDocumentDataId: props.data.file.pfReportDocumentData?.id,
          data: financialStatement,
        });
        console.log(res);
        if (res) {
          setUploading(false);
          setUploadModal(false);
          props.onFileReupload && props.onFileReupload();
          return;
        }
      } else {
        const newData: any = {
          portfolioId: props.portfolioId,
          id: props.data.file.pfReportDocumentData?.id,
          data: { ...financialStatement },
        };
        const res = await saveFinancialStatementData(newData);
        if (res.success) {
          setUploading(false);
          setUploadModal(false);
          showToast({
            customElement: (
              <span className="heading-16-sb">파일 정보 수정 완료 :)</span>
            ),
            backgroundColor: rootStyle.getPropertyValue("--green-400"),
            color: rootStyle.getPropertyValue("--text-02"),
            width: 274,
            height: 48,
            milliSec: 1500,
            transition: Bounce,
            position: "bottom-center",
            toastId: new Date().toString(),
          });
          props.onFileReupload && props.onFileReupload();

          return;
        }
      }
    } else if (localPdfFile) {
      const res = await postReportingFile({
        portfolioId: props.portfolioId,
        documentFile: localPdfFile,
        acPfDocumentId: props.data.acPfDocumentId,
        year: props.fileUploadInfo.year,
        quarterNum: props.fileUploadInfo.quarter,
        isValidation: true,
        index: props.fileUploadInfo.index,
        templateId: 1,
        data: financialStatement,
      });
      setUploading(false);
      if (res?.success) {
        props.onFileReupload && props.onFileReupload();
        setUploadModal(false);
        return;
      }
    }
    setUploading(false);
    showToast({
      customElement: (
        <span className="heading-16-sb">파일 업로드에 실패하였습니다.</span>
      ),
      backgroundColor: rootStyle.getPropertyValue("--red-500"),
      color: rootStyle.getPropertyValue("--text-02"),
      width: 274,
      height: 48,
      milliSec: 1500,
      transition: Bounce,
      position: "bottom-center",
      toastId: new Date().toString(),
    });
  };

  const setFinancialStatement = async () => {
    if (
      !props.data.file ||
      !props.data.file.pfReportDocumentData ||
      !props.data.file.pfReportDocumentData.id
    )
      return;

    console.log("asdfasf");

    const result = await getSBPData(props.data.file.pfReportDocumentData.id);

    if (result) {
      updateFinancialStatement(result.data);
    }
  };

  const initFinanceData = () => {
    setLocalPdfFile(undefined);
    updateFinancialStatementEvent(undefined);
    updateFinancialStatement({});
  };

  useEffect(() => {
    if (!financialStatementEvent) return;
    console.log(financialStatementEvent);

    switch (financialStatementEvent.type) {
      case "READ":
        setLocalPdfFile(financialStatementEvent.file);
        setUploadModal(true);
        break;
      case "SUCCESS":
        break;
      case "NOT_FILE_TYPE":
        // todo 파일 타입 틀렸다는 토스트 띄우기
        showToast({
          customElement: (
            <span className="heading-16-sb">PDF 파일만 선택해주세요.</span>
          ),
          backgroundColor: rootStyle.getPropertyValue("--red-500"),
          color: rootStyle.getPropertyValue("--text-02"),
          width: 274,
          height: 48,
          milliSec: 1500,
          transition: Bounce,
          position: "bottom-center",
          toastId: new Date().toString(),
        });
        break;

      default:
        break;
    }
  }, [financialStatementEvent]);

  useEffect(() => {
    if (uploadModal) {
      setFinancialStatement();
    } else {
      initFinanceData();
    }
  }, [uploadModal]);

  return [
    localPdfFile,
    financialStatement,
    uploadModal,
    uploading,
    onChangeFile,
    onChangeFileDND,
    setUploadModal,
    initFinanceData,
    onChangeFinancialStatement,
    onSubmitAble,
    submit,
    validationAsset,
    validationDebtCapital,
    validationIncomeStatement,
    validationEqualAssetAndDebtCapital,
  ] as const;
};

export default useFinancialStatement;
