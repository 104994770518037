import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { NavigationMenu } from "./MobileNavigationLayout";
import { ReactComponent as Arrow } from "../../../2.0lattice/assets/image/new_icon_arrow.svg";
import {
  initAcceleratingHistory,
  initDashboardHistory,
  initDatabaseHistory,
  initDealFlowHistory,
  initReportingHistory,
  initTotalEvaluationHistory,
} from "../../../common/pageHistoryUtil";

interface Props {
  index: number;
  item: NavigationMenu;
  isSpread: boolean;
  onChangeSpreadItem: (index: number) => void;
  transfromPixel: (value: number) => number;
}

export const MobileNavigationMenu: React.FC<Props> = (props) => {
  const { index, item, isSpread, onChangeSpreadItem, transfromPixel } = props;
  const navigate = useNavigate();
  const submenu = item.subMenu
    ? item.subMenu.filter(
        (menu) => menu.validationAuth === undefined || menu.validationAuth()
      )
    : [];
  return (
    <Container>
      <MainMenuItem
        className={`heading-14-md ${
          !item.subMenu &&
          window.location.pathname.split("/").includes(item.addressName)
            ? "selected"
            : ""
        }`}
        transfromPixel={transfromPixel}
        onClick={() => {
          if (item.subMenu) {
            onChangeSpreadItem(index);
            return;
          }
          if (item.pageAddress === "/deal-flow") {
            initDealFlowHistory();
          } else if (item.pageAddress === "/accelerating") {
            initAcceleratingHistory();
          } else if (item.pageAddress === "/dashboard") {
            initDashboardHistory();
          } else if (item.pageAddress === "/database") {
            initDatabaseHistory();
          } else if (
            item.pageAddress === "/quarter-management/total-evaluation"
          ) {
            initTotalEvaluationHistory();
          }

          if (window.location.pathname.split("/").includes(item.addressName)) {
            if (location.pathname.split("/").length === 2) {
              window.location.reload();
            } else {
              navigate(item.pageAddress);
            }
          } else {
            navigate(item.pageAddress);
          }
        }}
      >
        <span style={{ position: "relative" }}>{item.pageName}</span>
        {item.subMenu && (
          <div
            className={`nav__menu__arrow ${isSpread ? "spread" : ""}`}
            onClick={() => onChangeSpreadItem(index)}
          >
            <Arrow width={transfromPixel(16)} height={transfromPixel(16)} />
          </div>
        )}
      </MainMenuItem>

      {submenu.length > 0 && (
        <SubMenuContainer
          className={`${isSpread ? "show__sub__menu" : ""}`}
          style={{
            height: isSpread ? submenu.length * transfromPixel(40) : 0,
          }}
          transfromPixel={transfromPixel}
        >
          {submenu.map((menu) => {
            return (
              <SubMenuItem
                className={`heading-14-md ${
                  window.location.pathname.split("/").includes(menu.addressName)
                    ? "sub__menu__selected"
                    : ""
                }`}
                transfromPixel={transfromPixel}
                onClick={() => {
                  const paths = window.location.pathname.split("/");
                  if (menu.addressName === "total-evaluation") {
                    initTotalEvaluationHistory();
                  } else if (menu.addressName === "reporting") {
                    initReportingHistory();
                  }

                  if (paths[paths.length - 1] === menu.addressName) {
                    window.location.reload();
                  } else {
                    navigate(menu.pageAddress);
                  }
                }}
              >
                {menu.pageName}
              </SubMenuItem>
            );
          })}
        </SubMenuContainer>
      )}
    </Container>
  );
};

const Container = styled.li`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const MainMenuItem = styled.div<{ transfromPixel: (value: number) => number }>`
  position: relative;
  width: 100%;
  height: ${(props) => props.transfromPixel(40)}px;
  min-height: ${(props) => props.transfromPixel(40)}px;
  max-height: ${(props) => props.transfromPixel(40)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #1f2022; // text01
  list-style: none;

  padding-left: ${(props) => props.transfromPixel(45)}px;
  padding-right: ${(props) => props.transfromPixel(18)}px;

  font-weight: 500;

  cursor: pointer;

  .nav__menu__arrow {
    width: ${(props) => props.transfromPixel(32)}px;
    min-width: ${(props) => props.transfromPixel(32)}px;
    max-width: ${(props) => props.transfromPixel(32)}px;
    height: ${(props) => props.transfromPixel(32)}px;
    min-height: ${(props) => props.transfromPixel(32)}px;
    max-height: ${(props) => props.transfromPixel(32)}px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    transition: all 0.2s ease;
    transform: rotate(-90deg);
    cursor: pointer;
    path {
      fill: #1f2022;
    }

    &.spread {
      transform: rotate(90deg);
    }

    :hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  :hover {
    background-color: var(--hover-100);
  }

  &.selected {
    color: #173aff; // primary-blue
    /* background-color: #fafafb; //focus-basic */

    ::before {
      position: absolute;
      content: "";
      left: 0;
      width: ${(props) => props.transfromPixel(3)}px;
      height: 100%;
      background: #173aff;
    }
  }
`;

const Bedge = styled.span`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 14px;
  min-height: 14px;
  max-height: 14px;
  border-radius: 10px;
  top: 0px;
  right: 0px;
  transform: translate3d(100%, -50%, 0);

  font-family: Noto Sans KR;
  font-weight: 500;
  font-size: 8px;
  letter-spacing: 0.1px;

  padding-left: 4px;
  padding-right: 4px;
  padding-top: 1px;
  color: white;
  background-color: #ff0033;
`;

const SubMenuContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: 0px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  overflow: hidden;
  background-color: #fafafb; //focus-basic

  .show__sub__menu {
    height: fit-content;
  }
`;
const SubMenuItem = styled.div<{ transfromPixel: (value: number) => number }>`
  position: relative;
  width: 100%;
  height: ${(props) => props.transfromPixel(40)}px;
  min-height: ${(props) => props.transfromPixel(40)}px;
  max-height: ${(props) => props.transfromPixel(40)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--text-01); // text01
  cursor: pointer;

  padding-left: ${(props) => props.transfromPixel(55)}px;
  padding-right: ${(props) => props.transfromPixel(18)}px;

  font-weight: 500;

  &.sub__menu__selected {
    color: #173aff; // primary-blue

    ::before {
      position: absolute;
      content: "";
      left: 0;
      width: ${(props) => props.transfromPixel(3)}px;
      height: 100%;
      background: #173aff;
    }
  }
`;
