import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import RowTitleBox from "./RowTitleBox";
import EditorText from "./EditorText";

import { ReactComponent as CheckedOff } from "../../../assets/image/checkbox_off.svg";
import { ReactComponent as Checked } from "../../../assets/image/checkbox_checked.svg";
import { CheckBox, DefaultButton } from "lattice_core";
import { checkEmailForm } from "../../../common/commonUtil";
import { AuthrityDataProps } from "../type";
import config from "../../../config";

interface Props {
  sendAuthrityData: (data: AuthrityDataProps) => void;
  transfromPixel: (value: number) => number;
}
const AuthorityForm: React.FC<Props> = (props) => {
  const authorityInfo = useRef<AuthrityDataProps>({
    affiliation: "",
    name: "",
    position: "",
    email: "",
    dataCollectionConsent: false,
  });

  const [modifyAble, updateModifyAble] = useState<boolean>(false);
  const [render, setRender] = useState<boolean>(false);

  const [dataCollectionConsent, updateDataCollectionConsent] =
    useState<boolean>(false);

  const [showValidationMessage, updateShowValidationMessage] =
    useState<boolean>(false);

  const [spreadAgreeInfo, updateSpreadAgreeInfo] = useState<boolean>(false);

  const reRender = () => setRender((prev) => !prev);

  const onChangeData = (key: string, value: any) => {
    switch (key) {
      case "affiliation":
        return (authorityInfo.current.affiliation = value.trim());
      case "name":
        return (authorityInfo.current.name = value.trim());
      case "position":
        return (authorityInfo.current.position = value.trim());
      case "email":
        return (authorityInfo.current.email = value.trim());
      case "dataCollectionConsent":
        return (authorityInfo.current.dataCollectionConsent = value);
    }
  };

  const isEmpty = (value: string) => {
    return value.length === 0;
  };

  const isModifiable = () => {
    let modify = true;
    if (isEmpty(authorityInfo.current.affiliation)) {
      modify = false;
    }

    if (isEmpty(authorityInfo.current.name)) {
      modify = false;
    }

    if (isEmpty(authorityInfo.current.position)) {
      modify = false;
    }

    if (
      isEmpty(authorityInfo.current.email) ||
      !checkEmailForm(authorityInfo.current.email)
    ) {
      modify = false;
    }

    if (!dataCollectionConsent) {
      modify = false;
    }

    updateModifyAble(modify);
    reRender();
  };

  useEffect(() => {
    isModifiable();
  }, [dataCollectionConsent]);

  return (
    <Container>
      <Header>
        <span className="heading-16-sb">블루카드 열람 확인</span>
      </Header>
      <Body>
        <RowTitleBox
          title="소속"
          titleWidth={98}
          transfromPixel={props.transfromPixel}
        >
          <EditorText
            className="body-14-rg"
            transfromPixel={props.transfromPixel}
            validationMsg="필수값입니다."
            isShowValidationMsg={
              showValidationMessage &&
              isEmpty(authorityInfo.current.affiliation)
            }
            placeholder="소속(기업명) 입력."
            onChange={(e) => {
              onChangeData("affiliation", e.target.value);
            }}
            onBlur={() => {
              isModifiable();
            }}
          />
        </RowTitleBox>
        <RowTitleBox
          title="이름"
          titleWidth={98}
          transfromPixel={props.transfromPixel}
        >
          <EditorText
            className="body-14-rg"
            transfromPixel={props.transfromPixel}
            validationMsg="필수값입니다."
            isShowValidationMsg={
              showValidationMessage && isEmpty(authorityInfo.current.name)
            }
            placeholder="이름 입력"
            onChange={(e) => {
              onChangeData("name", e.target.value);
            }}
            onBlur={() => {
              isModifiable();
            }}
          />
        </RowTitleBox>
        <RowTitleBox
          title="직급"
          titleWidth={98}
          transfromPixel={props.transfromPixel}
        >
          <EditorText
            className="body-14-rg"
            transfromPixel={props.transfromPixel}
            validationMsg="필수값입니다."
            isShowValidationMsg={
              showValidationMessage && isEmpty(authorityInfo.current.position)
            }
            placeholder="직급 입력."
            onChange={(e) => {
              onChangeData("position", e.target.value);
            }}
            onBlur={() => {
              isModifiable();
            }}
          />
        </RowTitleBox>
        <RowTitleBox
          title="이메일"
          titleWidth={98}
          transfromPixel={props.transfromPixel}
        >
          <EditorText
            className="body-14-rg"
            transfromPixel={props.transfromPixel}
            validationMsg={
              !isEmpty(authorityInfo.current.email) &&
              !checkEmailForm(authorityInfo.current.email)
                ? "이메일 형식을 정확히 입력해주세요."
                : "필수값입니다."
            }
            isShowValidationMsg={
              showValidationMessage &&
              (isEmpty(authorityInfo.current.email) ||
                !checkEmailForm(authorityInfo.current.email))
            }
            placeholder="이메일 입력."
            onChange={(e) => {
              onChangeData("email", e.target.value);
            }}
            onBlur={() => {
              isModifiable();
            }}
          />
        </RowTitleBox>

        <PersonalInfoContainer>
          <div className="heading-14-sb">
            블루카드 제공을 위한 개인정보 수집･이용 동의
          </div>
          <div className="caption-12-rg" style={{ paddingLeft: 8 }}>
            {config.companyName}는 블루카드 제공을 위해 아래와 같이 개인정보를
            수집･이용하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여
            주세요
          </div>
          <div
            className="caption-12-sb"
            style={{ cursor: "pointer" }}
            onClick={() => {
              updateSpreadAgreeInfo((prev) => !prev);
            }}
          >
            ▶︎ 개인정보 수집･이용 내역
          </div>
          <SpreadContainer className={`caption-12-rg`}>
            <li>○ 수집 항목</li>
            <li style={{ marginLeft: 8 }}>
              - 필수적 정보 : 회사명, 성명, 직급, 이메일 주소
            </li>
            <li>○ 수집･이용 목적: 블루카드 제공</li>
            <li>
              ○ 보유･이용기간: 개인정보의 수집 및 이용목적이 달성되었거나
              당사자가 개인정보제공 동의를 철회하였을 경우 지체 없이 당사자의
              개인정보를 파기합니다.
            </li>
          </SpreadContainer>
          <div className="caption-11-sb" style={{ marginTop: 8 }}>
            ※ 위의 개인정보 수집･이용에 대한 동의를 거부할 권리가 있습니다.
            그러나 동의를 거부할 경우 블루카드의 수령에 제한을 받을 수 있습니다.
          </div>
        </PersonalInfoContainer>
        <CheckBoxContainer transfromPixel={props.transfromPixel}>
          <span
            style={{ paddingTop: props.transfromPixel(6) }}
            onClick={() => {
              updateDataCollectionConsent((prev) => !prev);
              onChangeData(
                "dataCollectionConsent",
                !authorityInfo.current.dataCollectionConsent
              );
            }}
          >
            {dataCollectionConsent ? (
              <Checked width={16} height={16} />
            ) : (
              <CheckedOff width={16} height={16} />
            )}
          </span>
          <span
            className="body-14-rg"
            onClick={() => {
              updateDataCollectionConsent((prev) => !prev);
              onChangeData(
                "dataCollectionConsent",
                !authorityInfo.current.dataCollectionConsent
              );
            }}
          >
            개인정보 이용에 동의합니다.(필수)
          </span>
        </CheckBoxContainer>
      </Body>
      <Footer>
        <div>
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor="var(--primary-blue)"
            hoverColor="var(--blue-600)"
            disabled={!modifyAble}
            disabledOnClick={() => {
              updateShowValidationMessage(true);
            }}
            onClick={() => {
              props.sendAuthrityData(authorityInfo.current);
            }}
            text="확인"
          />
        </div>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 512px;
  height: auto;
  display: flex;
  flex-direction: column;

  background: #ffffff;

  box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
  border-radius: 8px;

  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  height: 100%;
  min-height: 48px;
  max-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  padding-left: 24px;
  background-color: var(--background-contents01);
`;
const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 30px 50px 0px 32px;

  gap: 16px;
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding: 10px 24px 0px 0px;
`;

const PersonalInfoContainer = styled.div`
  height: 120px;
  display: flex;
  flex-direction: column;

  overflow: scroll;
  padding: 16px;
  border-radius: 8px;

  border: 1px solid var(--gray-300);
`;

const SpreadContainer = styled.ul`
  width: 100%;
  height: fit-content;
  margin-left: 16px;
`;

const CheckBoxContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  cursor: pointer;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.transfromPixel(6)}px;
  user-select: none;

  rect {
    fill: var(--primary-blue);
  }
`;

export default AuthorityForm;
