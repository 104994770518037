import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  label: string;
  style?: React.CSSProperties;
}
export const Label: React.FC<Props> = (props) => {
  return (
    <Container className="body-16-sb" style={props.style}>
      {props.label}
    </Container>
  );
};

const Container = styled.div`
  width: max-content;
  padding: 2px 10px;
  border-radius: 2px;
  background: var(--blue700, #0c1d80);
  color: var(--text-white, #fff);
`;
