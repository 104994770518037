import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import * as api from "../../../../api/api";
import { useNavigate, useLocation } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

import { getToday } from "../../../../common/commonApiData";
import {
  getNewDate,
  getPortfolioIdInLocation,
  historyBack,
  showToast,
  trnasfromBase64ToObject,
} from "../../../../common/commonUtil";
import { SectionType } from "../../../portfolio/pre-pitching-detail/interface/PrePitchingDetail.interface";
import { PrePitchingTaskChangeType } from "../interface/PrePitchingTask.interface";

const usePrepitchingTask = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getPreEvaluationId = (location: any) => {
    const transformedObject = location.pathname.split("/")[4];
    const object = trnasfromBase64ToObject(transformedObject);

    return Number.parseInt(object.id);
  };

  const portfolioId = useRef<number>(getPortfolioIdInLocation(location));
  const preEvaluationId = useRef<number>(getPreEvaluationId(location));

  const [sections, setSections] = useState<SectionType[]>([]);
  const currentSections = useRef<SectionType[]>([]);

  const [isUpdateAuth, setIsUpdateAuth] = useState<boolean>(false);
  const [submitAble, updateSubmitAble] = useState<boolean>(false);

  const validationCheckPreEvaluation = (): boolean => {
    if (currentSections.current.length === 0) return false;

    let validationResult = currentSections.current ? true : false;
    currentSections.current.map((section) => {
      section.list?.map((item) => {
        if (item.isRequired && validationResult) {
          if (item.type === "short" || item.type === "long") {
            validationResult = item.answer ? true : false;
          } else if (item.type === "single" || item.type === "multiple") {
            validationResult = item.answer ? true : false;
            if (!validationResult) {
              validationResult =
                item.hasOtherOpinion !== undefined &&
                item.otherOpinion !== undefined &&
                item.hasOtherOpinion &&
                item.otherOpinion.isSelected;
            }
          } else if (item.type === "linear") {
            validationResult = item.answer ? true : false;
          }
        }
      });
    });
    updateSubmitAble(validationResult);
    return validationResult;
  };

  const getPreEvaluation = async () => {
    try {
      if (preEvaluationId.current === undefined) return;

      const preEvaluationData = (
        await axios.get(
          api.getPreEvaluationTask(portfolioId.current, preEvaluationId.current)
        )
      ).data.preEvaluation;
      setSections(preEvaluationData.templateJson.preEvaluationData);
      currentSections.current = structuredClone(
        preEvaluationData.templateJson.preEvaluationData
      );

      if (preEvaluationData.preEvaluationDate) {
        const serverToday = await getToday();
        const today = getNewDate(serverToday);
        const evaluationDate = getNewDate(preEvaluationData.preEvaluationDate);
        if (today.getTime() >= evaluationDate.getTime()) {
          setIsUpdateAuth(true);
        } else {
          setIsUpdateAuth(false);
        }
      } else {
        setIsUpdateAuth(false);
      }
      validationCheckPreEvaluation();
    } catch (error) {}
  };

  const releasePreEvaluationResult = async () => {
    try {
      const result: boolean = validationCheckPreEvaluation();
      if (result) {
        if (!preEvaluationId.current) return;
        if (sections.length === 0) return;

        const params = {
          pfPreEvaluationId: preEvaluationId.current,
          preEvaluationData: currentSections.current,
        };

        console.log(params);
        const result = await axios.patch(
          api.relasePreEvaluationResult(),
          params
        );

        if (result.status === 200) {
          showToast({
            customElement: (
              <div
                className="heading-16-sb"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>제출 하였습니다.</span>
              </div>
            ),
            backgroundColor: "var(--green-500)",
            color: "var(--text-white)",
            width: 274,
            milliSec: 1500,
            transition: Bounce,
            position: "bottom-center",
            toastId: new Date().toString(),
          });

          historyBack(() => {
            navigate("/deal-flow");
          });
        }
      } else {
        return alert("필수항목을 선택하여 주세요.");
      }
    } catch (error) {}
  };

  const onChangeData = (
    type: PrePitchingTaskChangeType,
    dataIndex: number,
    data: any
  ) => {
    console.log(type);
    console.log(dataIndex);
    console.log(data);
    const section = { ...currentSections.current[0] };

    const newSectionData: SectionType = {
      ...section,
      list: section.list?.map((item, idx) => {
        if (idx === dataIndex) {
          switch (type) {
            case "long":
              return { ...item, answer: data };
            case "multiple":
            case "single": {
              const newColumn = item.column?.map((column, index) => {
                return { ...column, isSelected: data.includes(index) };
              });
              const newAnswer = newColumn?.filter((item) => item.isSelected);
              return { ...item, column: newColumn, answer: newAnswer };
            }
            case "other": {
              return { ...item, otherOpinion: data };
            }
            default:
          }
        }
        return item;
      }),
    };
    console.log(newSectionData);
    currentSections.current = [newSectionData];
    validationCheckPreEvaluation();
  };

  useEffect(() => {
    getPreEvaluation();
    validationCheckPreEvaluation();
  }, []);

  return [
    sections,
    isUpdateAuth,
    submitAble,
    validationCheckPreEvaluation,
    releasePreEvaluationResult,
    onChangeData,
  ] as const;
};

export default usePrepitchingTask;
