import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { getDatabaseAlumni } from "../../../../../../api/repository/database/DatabaseRepository";
import {
  DatabaseHistory,
  DBAlumniPortfolio,
  DPBPAlumni,
} from "../../../../../../type/data";
import FilterAlumni, {
  InputSearchValueType,
  IsAllSelectedType,
  SearchValueType,
} from "../../../organisms/FilterAlumni";
import DBResultListFunctionRow from "../../DBResultListFunctionRow";
import AlumniDatabaseGrid from "./AlumniDatabaseGrid";
import AlumniModal from "./AlumniModal";
import { setDatabaseHistory } from "../../../../../../common/pageHistoryUtil";
import useAlumniSearch from "./useAlumniSearch";
import DatabaseInput from "../../DatabaseInput";
interface Props {
  history?: DatabaseHistory;
  isShowFilter: boolean;
}

/**
 * Alumni 탭 화면
 *
 * 구성
 *  - Alumni 검색 조건 컴포넌트
 *  - 결과 Grid
 */
const AlumniTab: React.FC<Props> = (props) => {
  const [
    searchObject,
    inputChipObject,
    investmentYears,
    totalCount,
    countPerPage,
    totalPage,
    resultPortfolios,
    currentPortfolio,
    openPortfolioModal,
    industrialTechs,
    round,
    participants,
    addrList,
    fundAccountList,
    setSearchObject,
    setInputChipObject,
    onPortfolioModal,
    updatePortfolioModal,
  ] = useAlumniSearch();

  const [keyword, setKeyword] = useState<string>(
    props.history?.search?.keyword || ""
  );

  if (!searchObject) return null;

  return (
    <Container>
      {/* 통합검색 탭 검색 컴포넌트 부분*/}

      <ResultContainer
        style={{
          paddingRight: props.isShowFilter ? 0 : 60,
          paddingTop: 24,
          gap: 8,
        }}
      >
        <DBResultListFunctionRow
          totalCount={totalCount}
          countPerPage={countPerPage}
          curPage={searchObject.curPage || 1}
          totalPage={totalPage}
          resultPortfolioListCount={
            resultPortfolios ? resultPortfolios.portfolio.length : 0
          }
          customFunctions={
            <FlexRow style={{ width: 600, alignItems: "center" }}>
              <span className="body-14-sb" style={{ width: 60 }}>
                검색어
              </span>
              <DatabaseInput
                type="search"
                placeholder="기업명 또는 관련 키워드를 2글자 이상 입력해 주세요."
                onClickSearch={(value) => {
                  if (typeof value === "string") {
                    setSearchObject({
                      ...searchObject,
                      keyword: keyword,
                      curPage: 1,
                    });
                  }
                }}
                onChangeValue={(value) => {
                  if (typeof value === "string") {
                    setKeyword(value);
                  }
                }}
                value={keyword || ""}
              />
            </FlexRow>
          }
          updateCurPage={(updatedPage: number) => {
            setSearchObject((prev) => ({
              ...prev,
              curPage: updatedPage,
            }));
          }}
          searchKeyword={searchObject.keyword}
        />
        {resultPortfolios && (
          <AlumniDatabaseGrid
            resultList={resultPortfolios}
            onPortfolioModal={onPortfolioModal}
            searchObject={searchObject}
            sortByKey={(sortItem: string) => {
              console.log(sortItem);

              setSearchObject((prev) => {
                if (!prev) return;
                if (prev.sortItem === sortItem) {
                  let _sortItem: string | undefined = prev.sortItem;
                  let _sortType: "asc" | "desc" | undefined = undefined;
                  switch (prev.sortType) {
                    case "desc":
                      _sortType = "asc";
                      break;
                    case "asc":
                      _sortType = undefined;
                      _sortItem = undefined;
                      break;
                    default:
                      _sortType = "desc";
                      break;
                  }
                  return {
                    ...prev,
                    sortItem: _sortItem,
                    sortType: _sortType,
                    curPage: 1,
                  };
                } else {
                  return {
                    ...prev,
                    sortItem: sortItem,
                    sortType: "desc",
                    curPage: 1,
                  };
                }
              });
            }}
          />
        )}
      </ResultContainer>
      <FilterContainer className={props.isShowFilter ? "show__filter" : ""}>
        <FilterAlumni
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          inputChipObject={inputChipObject}
          setInputChipObject={setInputChipObject}
          industrialTechs={industrialTechs}
          participants={participants}
          round={round}
          investmentYears={investmentYears}
          addrList={addrList}
          fundAccountList={fundAccountList}
        />
      </FilterContainer>
      {resultPortfolios && currentPortfolio.current && openPortfolioModal && (
        <AlumniModal
          isOpen={openPortfolioModal}
          close={() => {
            updatePortfolioModal(false);
          }}
          portfolioInfo={currentPortfolio.current}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const ResultContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  gap: 4px;

  .function__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .function__row__group {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .per__page__item {
    cursor: pointer;
    color: #828282;
  }

  .per__page__item__selected {
    cursor: pointer;
    font-weight: 600;
    color: var(--primary);
  }

  .divider {
    width: 1px;
    height: 12px;
    background-color: #c4c4c4;
  }
`;

const FilterContainer = styled.div`
  width: 0px;
  height: 100%;
  transition: all 0.3s ease;
  border-left: 1px solid #f3f4f4;
  overflow: hidden;
  &.show__filter {
    width: 700px;
    transition: all 0.3s ease;
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export default AlumniTab;
