import { Bounce, toast, ToastTransition } from "react-toastify";
import { getNewDate } from "../common/commonUtil";

export type ToastType = "success" | "error";
export const showMobileToast = (
  type: ToastType,
  msg: string,
  toastId?: string
) => {
  switch (type) {
    case "success":
      openToast(msg, "var(--green-400)", "100%", toastId);
      break;
    case "error":
      openToast(msg, "var(--red-500)", "100%", toastId);
      break;
  }
};

export const showDeskToast = (
  type: ToastType,
  msg: string,
  toastId?: string
) => {
  switch (type) {
    case "success":
      openToast(msg, "var(--green-400)", 300, toastId);
      break;
    case "error":
      openToast(msg, "var(--red-500)", 300, toastId);
      break;
  }
};

const openToast = (
  text: string,
  backgroundColor: string,
  width: string | number,
  toastId?: string
) => {
  toast(
    <div
      className="heading-16-sb"
      style={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {text}
    </div>,
    {
      toastId: toastId || getNewDate().toString(),
      autoClose: 3000,
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: backgroundColor,
        color: "var(--text-02)",
        width: width,
        textAlign: "center",
      },
      bodyStyle: {
        margin: "0 auto",
      },
      hideProgressBar: true,
      closeButton: false,
      position: "bottom-center",
      transition: Bounce,
    }
  );
};
