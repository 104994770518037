import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

interface Props {
  isReadOnly?: boolean;
  title?: string;
}

export const CustomNothing: React.FC<Props> = ({
  title = "불필요",
  isReadOnly,
}) => {
  return (
    <Container className="body-14-rg">
      <InputContainer
        className={`${isReadOnly ? "disabled" : ""}`}
        style={{ color: "var(--text-04)" }}
      >
        <span>{title}</span>
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 8px 20px;

  display: flex;
  flex-direction: row;

  &.disabled {
    background-color: var(--gray-50);
  }
`;

const InputContainer = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;

  &.disabled {
    padding: 6px 0px;
  }
`;
