import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  CLevelEmployee,
  FundsBorrowed,
  GovermentTask,
  onBoardingStatusList,
  registrationStatusList,
} from "../../../interface/SBP.interface";
import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";
import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { CustomFile } from "../../table/custom-cell/CustomFile";
import { CustomNothing } from "../../table/custom-cell/CustomNothing";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  cLevelDataList: CLevelEmployee[];
  onDataChange: (data: CLevelEmployee[]) => void;
}

export const CLevelChangeComponent: React.FC<Props> = (props) => {
  const [cLevelDataList, updateCLevelDataList] = useState<
    CLevelEmployee[] | undefined
  >(undefined);
  const headers: TableGridHeaderProps[] = [
    {
      header: "입사자/퇴사자",
      key: "isOnboarding",
      width: 144,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            defaultData={data}
            selectList={onBoardingStatusList}
            placehoder="선택"
            onChangeData={(value) => {
              console.log(cLevelDataList);
              if (!cLevelDataList) return;
              allData.isOnboarding = value;
              updateCLevelDataList([...cLevelDataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "성명",
      key: "name",
      width: 186,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            transfromPixel={(n) => n}
            placeholder="김치프"
            defaultValue={data}
            onBlur={(value) => {
              if (!cLevelDataList) return;
              allData.name = value;
              updateCLevelDataList([...cLevelDataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "역할",
      key: "position",
      width: 186,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            transfromPixel={(n) => n}
            placeholder="CTO"
            defaultValue={data}
            onBlur={(value) => {
              if (!cLevelDataList) return;
              allData.position = value;
              updateCLevelDataList([...cLevelDataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "등기여부",
      key: "hasRegistration",
      width: 144,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            defaultData={data}
            selectList={registrationStatusList}
            placehoder="선택"
            onChangeData={(value) => {
              if (!cLevelDataList) return;
              allData.hasRegistration = value;
              updateCLevelDataList([...cLevelDataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "이력서 (PDF)",
      key: "resume",
      width: 466,
      customCell: (data, allData, isReadOnly, disabled) => {
        return allData.isOnboarding === "퇴사자" ? (
          <CustomNothing title="불필요" isReadOnly={isReadOnly} />
        ) : (
          <CustomFile
            disabled={disabled}
            isReadOnly={isReadOnly}
            defaultData={data}
            onChangeData={(file) => {
              if (!cLevelDataList) return;
              allData.resume = file;
              updateCLevelDataList([...cLevelDataList]);
            }}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    if (!cLevelDataList) return;
    const newDate: CLevelEmployee = {};
    const newClevelData: CLevelEmployee[] = [...cLevelDataList, newDate];
    updateCLevelDataList(newClevelData);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!cLevelDataList) return;
    const newClevelData = cLevelDataList.filter((item, index) => index !== id);
    updateCLevelDataList([...newClevelData]);
  };

  useEffect(() => {
    updateCLevelDataList(props.cLevelDataList);
  }, [props.cLevelDataList]);

  useEffect(() => {
    if (!cLevelDataList) return;
    props.onDataChange([...cLevelDataList]);
  }, [cLevelDataList]);
  if (!cLevelDataList) return null;
  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={cLevelDataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;
