import React, { useRef } from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../SummaryBusinessPlanV2Download";
import { SBPContentsPropsV2 } from "../../../interface/SBP.interface";
import RowTitleBox from "../../RowTitleBox";
import { AutoResizableTextarea } from "./AutoResizableTextarea";
import config from "../../../../../../../../../config";

interface Props {
  index: number;
  sbpDocument: SBPContentsPropsV2;
  onLoaded: (index: number) => void;
}

export const Status5: React.FC<Props> = (props) => {
  const loadedRef = useRef<boolean[]>([]);
  const onLoaded = () => {
    loadedRef.current.push(true);

    console.log(`call status: ${props.index}`);
    console.log(loadedRef.current);
    if (loadedRef.current.length === 1) {
      props.onLoaded(props.index);
      loadedRef.current = [];
    }
  };

  return (
    <FlexColumn style={{ gap: 8 }}>
      <div className="body-16-md" style={{ color: "var(--text-03)" }}>
        0{props.index}. {config.companyName} 투자 후 누적 출원 및 등록
      </div>
      <FlexColumn
        style={{
          padding: 14,
          borderRadius: 4,
          gap: 4,
          backgroundColor: "var(--background-contents01)",
          border: "1px solid var(--gray-300)",
        }}
      >
        <FlexRow style={{ gap: convertPixelToCm(12) }}>
          <RowTitleBox
            textClassName="caption-12-md"
            title="누적 국내 출원"
            titleWidth={convertPixelToCm(100 + 50)}
            transfromPixel={(n) => n}
          >
            <span
              className="caption-12-rg"
              style={{
                width: convertPixelToCm(70),
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 9,
              }}
            >
              {props.sbpDocument.businessStatus.patentInfo.accumulatePatent
                ?.domesticRegistration || 0}
              건
            </span>
          </RowTitleBox>
          <RowTitleBox
            textClassName="caption-12-md"
            title="누적 국내 등록"
            titleWidth={convertPixelToCm(100 + 50)}
            transfromPixel={(n) => n}
          >
            <span
              className="caption-12-rg"
              style={{
                width: convertPixelToCm(70),
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 9,
              }}
            >
              {props.sbpDocument.businessStatus.patentInfo.accumulatePatent
                ?.domesticApplication || 0}
              건
            </span>
          </RowTitleBox>
          <RowTitleBox
            textClassName="caption-12-md"
            title="누적 해외 출원"
            titleWidth={convertPixelToCm(100 + 50)}
            transfromPixel={(n) => n}
          >
            <span
              className="caption-12-rg"
              style={{
                width: convertPixelToCm(70),
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 9,
              }}
            >
              {props.sbpDocument.businessStatus.patentInfo.accumulatePatent
                ?.internationalRegistration || 0}
              건
            </span>
          </RowTitleBox>
          <RowTitleBox
            textClassName="caption-12-md"
            title="누적 해외 등록"
            titleWidth={convertPixelToCm(100 + 50)}
            transfromPixel={(n) => n}
          >
            <span
              className="caption-12-rg"
              style={{
                width: convertPixelToCm(70),
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 9,
              }}
            >
              {props.sbpDocument.businessStatus.patentInfo.accumulatePatent
                ?.internationalApplication || 0}
              건
            </span>
          </RowTitleBox>
        </FlexRow>
        <FlexRow style={{ gap: convertPixelToCm(12) }}>
          <RowTitleBox
            textClassName="caption-12-md"
            title="신규 국내 출원"
            titleWidth={convertPixelToCm(100 + 50)}
            transfromPixel={(n) => n}
          >
            <span
              className="caption-12-rg"
              style={{
                width: convertPixelToCm(70),
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 9,
              }}
            >
              {props.sbpDocument.businessStatus.patentInfo.newPatent
                ?.domesticRegistration || 0}
              건
            </span>
          </RowTitleBox>
          <RowTitleBox
            textClassName="caption-12-md"
            title="신규 국내 등록"
            titleWidth={convertPixelToCm(100 + 50)}
            transfromPixel={(n) => n}
          >
            <span
              className="caption-12-rg"
              style={{
                width: convertPixelToCm(70),
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 9,
              }}
            >
              {props.sbpDocument.businessStatus.patentInfo.newPatent
                ?.domesticApplication || 0}
              건
            </span>
          </RowTitleBox>
          <RowTitleBox
            textClassName="caption-12-md"
            title="신규 해외 출원"
            titleWidth={convertPixelToCm(100 + 50)}
            transfromPixel={(n) => n}
          >
            <span
              className="caption-12-rg"
              style={{
                width: convertPixelToCm(70),
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 9,
              }}
            >
              {props.sbpDocument.businessStatus.patentInfo.newPatent
                ?.internationalRegistration || 0}
              건
            </span>
          </RowTitleBox>
          <RowTitleBox
            textClassName="caption-12-md"
            title="신규 해외 등록"
            titleWidth={convertPixelToCm(100 + 50)}
            transfromPixel={(n) => n}
          >
            <span
              className="caption-12-rg"
              style={{
                width: convertPixelToCm(70),
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 9,
              }}
            >
              {props.sbpDocument.businessStatus.patentInfo.newPatent
                ?.internationalApplication || 0}
              건
            </span>
          </RowTitleBox>
        </FlexRow>
        <FlexRow style={{ gap: 131 }}>
          <RowTitleBox
            textClassName="caption-12-md"
            title="비고"
            titleWidth={convertPixelToCm(100 + 50)}
            style={{ width: "100%" }}
            transfromPixel={(n) => n}
          >
            <AutoResizableTextarea
              isReadOnly
              textClassName="caption-12-rg"
              placeholder="입력한 내용이 없습니다."
              defaultData={
                props.sbpDocument.businessStatus.patentInfo.note || ""
              }
              onLoaded={onLoaded}
            />
          </RowTitleBox>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
