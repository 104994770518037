import React, { useState } from "react";
import styled from "styled-components";
import useNotification from "./hook/useNotification";
import { MyTask } from "./organism/MyTask";
import { Noti } from "./organism/Noti";

interface Props {
  onChangeNewNoti: (exist: boolean) => void;
  closeRightModal: () => void;
}
const Notification: React.FC<Props> = (props) => {
  const [
    doneFinanceDataInput,
    myInfo,
    toastMessege,
    loaded,
    tabIndex,
    notiGroup,
    newTaskCount,
    newNotiCount,
    selectedOrder,
    selectedOrderNoti,
    onClickMyTaskTab,
    onClickNotificationTab,
    onChangeOrder,
    onChangeNotiOrder,
    goTolink,
    deleteNotificationById,
    deleteReadNotification,
    getFilteredMyTask,
    getMyTaskInProgress,
    getOrderedNotification,
  ] = useNotification(props.onChangeNewNoti, props.closeRightModal);

  if (!loaded) return null;

  return (
    <Container id="root__notification">
      <TabLayout>
        <TabWapper onClick={onClickMyTaskTab}>
          <TabItem
            className={`heading-16-sb ${tabIndex === 1 ? "selected" : ""}`}
          >
            내 할일
            {(newTaskCount > 0 || !doneFinanceDataInput) && (
              <div className="caption-10-sb bedge">
                {newTaskCount < 100
                  ? doneFinanceDataInput
                    ? newTaskCount
                    : newTaskCount + 1
                  : "99+"}
              </div>
            )}
          </TabItem>
        </TabWapper>
        <TabWapper onClick={onClickNotificationTab}>
          <TabItem
            className={`heading-16-sb ${tabIndex === 2 ? "selected" : ""}`}
          >
            알림
            {newNotiCount > 0 && (
              <div className="caption-10-sb bedge">
                {newNotiCount < 100 ? newNotiCount : "99+"}
              </div>
            )}
          </TabItem>
        </TabWapper>
      </TabLayout>
      <NotiBody>
        {tabIndex === 1 && (
          <MyTask
            myInfo={myInfo}
            notiGorup={notiGroup}
            selectedOrder={selectedOrder}
            getFilteredMyTask={getFilteredMyTask}
            getMyTaskInProgress={getMyTaskInProgress}
            goTolink={goTolink}
            onChangeOrder={onChangeOrder}
            doneFinanceDataInput={doneFinanceDataInput}
          />
        )}
        {tabIndex === 2 && (
          <Noti
            selectedOrderNoti={selectedOrderNoti}
            goTolink={goTolink}
            onChangeNotiOrder={onChangeNotiOrder}
            deleteNotificationById={deleteNotificationById}
            deleteReadNotification={deleteReadNotification}
            getOrderedNotification={getOrderedNotification}
          />
        )}
      </NotiBody>
      <NotiToast
        className={`heading-16-sb ${toastMessege !== undefined ? "show" : ""}`}
      >
        {toastMessege}
      </NotiToast>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabLayout = styled.div`
  width: 100%;
  height: 66px;
  min-height: 66px;
  max-height: 66px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  border-bottom: 1px solid var(--gray-200);
`;

const TabWapper = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 0 20px;
  cursor: pointer;
`;

const TabItem = styled.div`
  position: relative;
  padding-bottom: 8px;
  color: var(--text-inactive, #bec0c2);
  border-bottom: 2px solid transparent;

  &.selected {
    color: var(--text-01, #1f2022);
    border-color: var(--primary-blue);
  }

  .bedge {
    position: absolute;
    top: -6px;
    left: calc(100% + 2px);
    min-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    border-radius: 8px;
    background-color: var(--red-500, #f6444f);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);

    padding: 1px 4px 0px 4px;
    line-height: 1;
  }
`;

const NotiBody = styled.div`
  width: 100%;
  height: calc(100% - 66px);
  overflow: scroll;
`;

const NotiToast = styled.div`
  position: fixed;

  bottom: -50px;
  right: 26px;
  width: 270px;
  height: 44px;
  line-height: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--gray-800);
  color: var(--text-02);

  transition: all 0.2s ease-in-out;

  &.show {
    bottom: 56px;
    transition: all 0.2s ease-in-out;
  }
`;

export default Notification;
