import React, { useState, useRef } from "react";
import styled from "styled-components";

interface Props {
  title: string;
  description: string;
  isSelected: boolean;
  onClickItem?: () => void;
}

export const FinanceCard: React.FC<Props> = (props) => {
  const { title, description, isSelected, onClickItem } = props;
  return (
    <Container
      className={`${isSelected ? "selected" : ""}`}
      onClick={onClickItem}
    >
      <Title className="heading-18-sb">{title}</Title>
      <Description className="caption-12-rg">{description}</Description>
    </Container>
  );
};

const Container = styled.div`
  width: 260px;
  height: 158px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  border-radius: 4px;
  border: 1px solid var(--gray200, #edeeef);
  background: var(--background-contents-01, #fff);

  cursor: pointer;
  opacity: 0.6;

  &.selected {
    opacity: 1;

    border-color: var(--blue-400);
  }

  :hover {
    opacity: 1;
  }
`;

const Title = styled.div`
  color: var(--text01, #1f2022);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 6px;
`;
const Description = styled.div`
  display: -webkit-box;
  margin-top: 12px;
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text05, #96999f);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
