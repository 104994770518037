import React, { useEffect, useRef, useState } from "react";
import { getMyInfo, getYMD, getYMDHM } from "../../../../../common/commonUtil";
import {
  ChatItemProps,
  ChatUserProps,
  getChatMsg,
  getNewChatMsg,
  getPageChatMsg,
  getUnReadMsgCount,
  sendChatMsg,
} from "./chatingRepository";

export interface ChatingSpeachBubbleProps {
  msgs?: ChatItemProps;
  createAt: string;
  user?: ChatUserProps;
  isMyChat?: boolean;
  groupId: number;
  groupIsFirst?: boolean;
  groupIsLast?: boolean;
  type: 1 | 2 | 3 | 4 | 5; // 1: 시스템 시작 , 2: 시스템 메세지, 3: 유저대화, 4: 날짜 divider, 5: 여기까지 읽음
}
const useChating = () => {
  const START_INDEX = 10000;

  const portfolioId = useRef<number>(-1);
  const participant = useRef<boolean>(false);
  const isOpenChatingRef = useRef<boolean>(false);
  const [firstIndex, updateFirstIndex] = useState<number>(0);
  const [isFirstOpen, updateIsFirstOpen] = useState<boolean>(true);
  const [isOpenChating, updateIsOpenChating] = useState<boolean>(false);
  const [chatingFocusing, updateChatingFocusing] = useState<boolean>(false);
  const [currentReadChatItemId, updateCurrentReadChatItemId] =
    useState<number>(-1);
  const [chatItems, updateChatItems] = useState<ChatItemProps[]>([]);
  const [SBChatingMsg, updateSBChatingMsg] = useState<
    ChatingSpeachBubbleProps[]
  >([]);
  const [unreadMsgCount, updateUnreadMsgCount] = useState<number>(0);
  const isCountZero = useRef<boolean>(true);
  const [showUnreadTooltip, updateShowUnreadTooltip] = useState<boolean>(false);
  const myinfo = getMyInfo();

  const clearUnreadMsgCount = () => {
    updateUnreadMsgCount(0);
  };

  const sendChating = (text: string) => {
    if (!participant.current) return;
    if (portfolioId.current === -1) return;

    sendChatMsg(portfolioId.current, text).then((sendRes) => {
      if (sendRes) updateChatItems((prev) => [...prev, ...sendRes]);
    });
  };

  const openChating = () => {
    if (!participant.current) return;
    if (portfolioId.current === -1) return;

    updateIsOpenChating(true);
    isOpenChatingRef.current = true;
    getChatMsg(portfolioId.current).then((res) => {
      if (res) {
        updateCurrentReadChatItemId(res.lastIndexId);
        updateChatItems([...res.chatItems]);
      }
    });
  };

  const callOpenChating = () => {
    if (!participant.current) return;
    if (portfolioId.current === -1) return;
    getChatMsg(portfolioId.current).then((res) => {
      if (res) {
        updateCurrentReadChatItemId(res.lastIndexId);
        updateChatItems([...res.chatItems]);
      }
    });
  };

  const closeChating = () => {
    updateIsOpenChating(false);
    isOpenChatingRef.current = false;
  };

  const nextPageChat = () => {
    if (!participant.current) return;
    if (portfolioId.current === -1) return;

    if (chatItems.length > 0) {
      getPageChatMsg(
        portfolioId.current,
        chatItems[0].reportingChatRoomItemId
      ).then((res) => {
        if (res) {
          updateChatItems((prev) => [...res, ...prev]);
        }
      });
    }
  };

  const setPortfolioId = (pfId: number, isParticipant: boolean) => {
    portfolioId.current = pfId;
    participant.current = isParticipant;
    closeChating();
  };

  const getUnreadMsg = () => {
    if (!participant.current) return;
    getUnReadMsgCount(portfolioId.current).then((res) => {
      if (res) {
        updateUnreadMsgCount(res);
      }
    });
  };
  const getNewChating = () => {
    if (!participant.current) return;
    getNewChatMsg(portfolioId.current).then((res) => {
      if (res) {
        updateChatItems((prev) => [...prev, ...res]);
      }
    });
  };

  useEffect(() => {
    if (!participant.current) return;

    if (chatItems.length > 0) {
      const sbChatMsg: ChatingSpeachBubbleProps[] = [];
      let groupId = 0;
      let currentDate = "";
      chatItems.forEach((item, index) => {
        if (index === 0 || currentDate !== getYMD(item.createdAt)) {
          currentDate = getYMD(item.createdAt);
          groupId += 1;
          sbChatMsg.push({
            createAt: getYMD(item.createdAt),
            type: 4,
            groupId,
          });
          if (index === 0) groupId += 1;
        }

        if (
          index !== 0 &&
          !(
            chatItems[index - 1].user &&
            chatItems[index - 1].user?.id === item.user?.id &&
            chatItems[index - 1].user?.type === item.user?.type &&
            getYMDHM(chatItems[index - 1].createdAt) ===
              getYMDHM(item.createdAt)
          )
        ) {
          groupId += 1;
        }

        sbChatMsg.push({
          msgs: item,
          user: item.user,
          createAt: getYMDHM(item.createdAt),
          isMyChat:
            item.type === 3 &&
            item.user &&
            item.user.type === "A" &&
            item.user.id === myinfo.id,
          type: item.type,
          groupId,
        });

        if (item.reportingChatRoomItemId === currentReadChatItemId) {
          groupId += 1;
          sbChatMsg.push({
            createAt: getYMD(item.createdAt),
            type: 5,
            groupId,
          });
        }
      });

      let currentGroupId = -1;
      const newSBChatMsg = sbChatMsg.map((item, index) => {
        if (currentGroupId !== item.groupId) {
          currentGroupId = item.groupId;
          if (sbChatMsg[index + 1]) {
            return {
              ...item,
              groupIsFirst: true,
              groupIsLast: currentGroupId !== sbChatMsg[index + 1].groupId,
            };
          }
          return {
            ...item,
            groupIsFirst: true,
            groupIsLast: true,
          };
        }

        if (sbChatMsg[index + 1]) {
          return {
            ...item,
            groupIsFirst: false,
            groupIsLast: currentGroupId !== sbChatMsg[index + 1].groupId,
          };
        }

        return {
          ...item,
          groupIsFirst: false,
          groupIsLast: true,
        };
      });
      console.log(newSBChatMsg);
      updateSBChatingMsg([...newSBChatMsg]);
      updateFirstIndex(START_INDEX - newSBChatMsg.length);
    }
  }, [chatItems]);

  useEffect(() => {
    if (!participant.current) return;

    if (unreadMsgCount === 0) {
      isCountZero.current = true;
      return;
    }

    if (isCountZero.current) {
      isCountZero.current = false;
      updateShowUnreadTooltip(true);
      setTimeout(() => {
        updateShowUnreadTooltip(false);
      }, 10 * 1000);
    }
  }, [unreadMsgCount]);
  useEffect(() => {
    if (!participant.current) return;

    getUnreadMsg();
    const intervalId = setInterval(() => {
      if (!isOpenChatingRef.current) {
        getUnreadMsg();
      }
    }, 10 * 1000);

    return () => clearInterval(intervalId); // cleanup 함수
  }, []);

  useEffect(() => {
    if (!participant.current) return;

    const intervalId = setInterval(
      () => {
        if (isOpenChating) {
          getNewChating();
        }
      },
      chatingFocusing ? 1000 * 1 : 1000 * 10
    );

    return () => clearInterval(intervalId); // cleanup 함수
  }, [isOpenChating, chatingFocusing]);

  // todo 채팅 setTimeout open ? 3초 : 10초

  return [
    isOpenChatingRef,
    showUnreadTooltip,
    unreadMsgCount,
    firstIndex,
    SBChatingMsg,
    currentReadChatItemId,
    isFirstOpen,
    isOpenChating,
    chatingFocusing,
    setPortfolioId,
    updateIsOpenChating,
    openChating,
    closeChating,
    updateChatingFocusing,
    nextPageChat,
    sendChating,
    clearUnreadMsgCount,
    callOpenChating,
  ] as const;
};

export default useChating;
