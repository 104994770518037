import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../../assets/image/modal_close.svg";
import { DefaultButton } from "lattice_core";
import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import InputBox from "../../components/InputBox";
import {
  getActiveFundaccount,
  getAllAcUser,
  getAllDictionary,
  getAllFundaccount,
  getNewOldStock,
} from "../../../../../../api/repository/accelerator/AcceleratorRepository";
import {
  ActiveFundAccountType,
  FundAccountType,
  FundManager,
  IAReportAccount,
  IAReportUser,
  InvestmentAssessment,
  NewOldStockType,
  QuorumType,
  StockType,
  UserInfo,
} from "../../../../../../type/data";
import { AddComma, RemoveComma } from "../../../../../../common/commonUtil";
import { SelectBox, SelectBoxData } from "../../components/SelectBox";
import {
  NewOldStockRadio,
  NewOldStockRadioProps,
} from "../../components/NewOldStockRadio";

import ModalLayout from "../../../../../../components/modal/ModalLayout";
import ConfirmModal from "../../../../../../2.0lattice/components/modal/ConfirmModal";

interface Props {
  document: InvestmentAssessment;
  reportAccountIndex?: number;
  reportAccount?: IAReportAccount;
  isUpdateAuth: boolean;
  close: () => void;
  transfromPixel: (value: number) => number;
  onChangeReportAccount: (
    reportAccount: IAReportAccount,
    index: number
  ) => void;
  onCreateReportAccount: (reportAccount: IAReportAccount) => void;
}
const EvaluationInvestFundAccountModal: React.FC<Props> = (props) => {
  const {
    document,
    reportAccountIndex,
    reportAccount,
    isUpdateAuth,
    close,
    transfromPixel,
    onChangeReportAccount,
    onCreateReportAccount,
  } = props;

  const TITLE_WIDTH = 168;

  const [isOriginAccount, updateOriginAccount] = useState<boolean>(true);

  const [loadedList, updateLoadedList] = useState<boolean>(false);
  const [fundAccountList, updateFundAccountList] = useState<
    ActiveFundAccountType[]
  >([]);

  const [allAcUser, updateAllAcUser] = useState<UserInfo[]>([]);
  const [stockTypeList, updateStockTypeList] = useState<StockType[]>([]);
  const [quorumTypeList, updateQuorumTypeList] = useState<QuorumType[]>([]);
  const [newOldStockList, updateNewOldStockList] = useState<NewOldStockType[]>(
    []
  );
  const [fundManagerList, updateFundManagerList] = useState<FundManager[]>([]);

  const [selectedFundAccount, updateFundAccount] = useState<
    ActiveFundAccountType | undefined
  >(undefined);
  const [selectedStockType, updateStockType] = useState<StockType | undefined>(
    undefined
  );
  const [selectedNewOldStockType, updateNewOldStockType] = useState<
    NewOldStockType | undefined
  >(undefined);

  const [selectedQuorumType, updateQuorumType] = useState<
    QuorumType | undefined
  >(undefined);

  const [allocationWay, updateAllocationWay] = useState<string>("제 3자 방식");
  const [investmentAmount, updateInvestmentAmount] = useState<string>(""); //당사 투자 금액 , 당 조합 투자 금액
  const [stockNumber, updateStockNumber] = useState<string>(""); // 당사 배정 주식 수, 당 조합 배정 주식 수
  const [issuingPrice, updateIssuingPrice] = useState<string>(""); // 신주발행가
  const [oldStockPurchasePrice, updateOldStockPurchasePrice] =
    useState<string>(""); // 구주발행가
  const [shareholdingRatio, updateShareholdingRatio] = useState<string>(""); // 투자 후 지분율

  const [buyCompany, updateBuyCompany] = useState<string>("");
  const [initialIssuingPrice, updateInitialIssuingPrice] = useState<string>("");
  const [buyCompanyValue, updateBuyCompanyValue] = useState<string>("");
  const [curNewIssuingPrice, updateCurNewIssuingPrice] = useState<string>("");
  const [discount, updateDiscount] = useState<string>("");

  const [modifyInviteUserModal1, updateModifyInviteUserModal1] =
    useState<boolean>(false);

  const setShareholdingRatio = (stockNum: number | string) => {
    const data =
      typeof stockNum === "string" ? convertNumber(stockNum) : stockNum;
    const previousTotalStockNumber = document
      .investmentAssessmentReportAccount[0]
      ? document.investmentAssessmentReportAccount[0]
          .investmentAssessmentAccount.previousTotalStockNumber || 0
      : 0;
    const postTotalStockNumber = document.investmentAssessmentReportAccount[0]
      ? document.investmentAssessmentReportAccount[0]
          .investmentAssessmentAccount.postTotalStockNumber || 0
      : 0;

    const totalStockNumber = previousTotalStockNumber + postTotalStockNumber;
    updateShareholdingRatio(((data / totalStockNumber) * 100).toFixed(2));
  };

  // 구주 매입가 기준 기업 가치
  const setBuycompanayValue = (stockNum: number | string) => {
    const data =
      typeof stockNum === "string" ? convertNumber(stockNum) : stockNum;
    const previousTotalStockNumber = document
      .investmentAssessmentReportAccount[0]
      ? document.investmentAssessmentReportAccount[0]
          .investmentAssessmentAccount.previousTotalStockNumber || 0
      : 0;
    const postTotalStockNumber = document.investmentAssessmentReportAccount[0]
      ? document.investmentAssessmentReportAccount[0]
          .investmentAssessmentAccount.postTotalStockNumber || 0
      : 0;
    const totalStockNumber =
      convertNumber(previousTotalStockNumber) +
      convertNumber(postTotalStockNumber);
    updateBuyCompanyValue(AddComma(data * totalStockNumber));
  };

  //신주 당사 투자 금액
  const getOurInvestmentAmount = () => {
    const _issuingPrice =
      issuingPrice.length > 0 ? parseInt(RemoveComma(issuingPrice), 10) : 0;
    const _stockNumber =
      stockNumber.length > 0 ? parseInt(RemoveComma(stockNumber), 10) : 0;

    const _totalInvestmentAmount =
      _issuingPrice !== 0 && _stockNumber !== 0
        ? `${_issuingPrice * _stockNumber}`
        : "0";
    return _totalInvestmentAmount;
  };
  //구주 당사 구입 금액
  const getOurPurchaseAmount = () => {
    const _oldStockPurchasePrice =
      oldStockPurchasePrice.length > 0
        ? parseInt(RemoveComma(oldStockPurchasePrice), 10)
        : 0;
    const _stockNumber =
      stockNumber.length > 0 ? parseInt(RemoveComma(stockNumber), 10) : 0;

    const _totalInvestmentAmount =
      _oldStockPurchasePrice !== 0 && _stockNumber !== 0
        ? `${_oldStockPurchasePrice * _stockNumber}`
        : "0";
    return _totalInvestmentAmount;
  };

  const convertNumber = (value: string | number) => {
    const _value = RemoveComma(`${value}`);
    return parseInt(_value, 10);
  };

  const convertFloat = (value: string | number) => {
    const _value = `${value}`;
    return parseFloat(_value);
  };

  const modifyAccount = () => {
    if (reportAccountIndex === undefined) return;
    if (!reportAccount) return;
    if (!selectedFundAccount) return;

    const isExistQuorum =
      selectedFundAccount.quorum !== null &&
      selectedFundAccount.quorum !== undefined;

    const newAccount: IAReportAccount = {
      ...reportAccount,
      investmentAssessmentAccount: {
        // 신주 배정 방식 초기화
        ...reportAccount.investmentAssessmentAccount,
        accountName: selectedFundAccount.nameKo,
        acFundAccount: {
          acFundAccountId: parseInt(`${selectedFundAccount.acFundAccountId}`),
        },
        allocationWay: allocationWay,
        buyCompany: buyCompany,
        buyCompanyValue: convertNumber(buyCompanyValue),
        curNewIssuingPrice: convertNumber(curNewIssuingPrice),
        discount: convertFloat(discount),
        investmentAmount: convertNumber(investmentAmount),
        issuingPrice: convertNumber(issuingPrice),
        isExistQuorum: isExistQuorum,
        initialIssuingPrice: convertNumber(initialIssuingPrice),
        oldStockPurchasePrice: convertNumber(oldStockPurchasePrice),
        newOldStock: selectedNewOldStockType,
        stockType: selectedStockType,
        stockNumber: convertNumber(stockNumber),
        shareholdingRatio: convertFloat(shareholdingRatio),
        quorumType: {
          number: isExistQuorum
            ? selectedFundAccount.quorum
            : selectedQuorumType?.number, // 본계정인 경우 본부 투심이 디폴트
          id: isExistQuorum ? undefined : selectedQuorumType?.id, // 본계정인 경우 본부 투심이 디폴트
          name: isExistQuorum ? undefined : selectedQuorumType?.name, // 본계정인 경우 본부 투심이 디폴트
        },
      },
      requiredTables: selectedFundAccount.tableForm,
    };

    // 투자 계정이 변경 되었는가?
    if (
      reportAccount?.investmentAssessmentAccount.acFundAccount
        ?.acFundAccountId !== selectedFundAccount.acFundAccountId
    ) {
      // 해당 계정의 대펀을 찾는다.
      const fundManagerId: number | string | undefined = fundManagerList.find(
        (item) =>
          item.acFundAccount?.acFundAccountId ===
          selectedFundAccount.acFundAccountId
      )?.acUser?.acUserId;

      // 대펀 UserInfo를 찾는다.
      const fundManagerUserInfo: UserInfo | undefined = allAcUser.find(
        (item) => item.acUserId === fundManagerId
      );

      const inviteList = [];
      if (fundManagerUserInfo) {
        const inviteUserObj: IAReportUser = {
          acUser: {
            acUserId: parseInt(`${fundManagerUserInfo.id}`),
            name: fundManagerUserInfo.name,
            isShowProfile: fundManagerUserInfo.isShowProfile,
            profileImgUrl: fundManagerUserInfo.profileImgUrl,
            email: fundManagerUserInfo.email || "",
          },
          priorityInfo: {
            priorityType: 1,
            priorityCount: 2,
          },
        };
        inviteList.push(inviteUserObj);
      }

      newAccount.investmentAssessmentInvite = inviteList;
      newAccount.fundManagerConfirm = undefined;
      newAccount.requestFundManager = undefined;
    }

    console.log(newAccount);

    onChangeReportAccount(newAccount, reportAccountIndex);
  };

  const createAccount = () => {
    if (!selectedFundAccount) return;

    const isExistQuorum =
      selectedFundAccount.quorum !== null &&
      selectedFundAccount.quorum !== undefined;

    // 해당 계정의 대펀을 찾는다.
    const fundManagerId: number | string | undefined = fundManagerList.find(
      (item) =>
        item.acFundAccount?.acFundAccountId ===
        selectedFundAccount.acFundAccountId
    )?.acUser?.acUserId;

    // 대펀 UserInfo를 찾는다.
    const fundManagerUserInfo: UserInfo | undefined = allAcUser.find(
      (item) => item.acUserId === fundManagerId
    );

    const inviteList = [];
    if (selectedFundAccount.acFundAccountId !== 1 && fundManagerUserInfo) {
      const inviteUserObj: IAReportUser = {
        acUser: {
          acUserId: parseInt(`${fundManagerUserInfo.id}`),
          name: fundManagerUserInfo.name,
          isShowProfile: fundManagerUserInfo.isShowProfile,
          profileImgUrl: fundManagerUserInfo.profileImgUrl,
          email: fundManagerUserInfo.email || "",
        },
        priorityInfo: {
          priorityType: 1,
          priorityCount: 2,
        },
      };
      inviteList.push(inviteUserObj);
    }

    const newAccount: IAReportAccount = {
      investmentAssessmentReportAccountId: `newReportAccoun`,
      isCompleted: false,
      isPublished: false,
      investmentAssessmentInvite: inviteList,
      investmentAssessmentAccount: {
        // 신주 배정 방식 초기화
        accountName: selectedFundAccount.nameKo,
        acFundAccount: {
          acFundAccountId: parseInt(`${selectedFundAccount.acFundAccountId}`),
        },
        allocationWay: allocationWay,
        buyCompany: buyCompany,
        buyCompanyValue: convertNumber(buyCompanyValue),
        curNewIssuingPrice: convertNumber(curNewIssuingPrice),
        discount: convertFloat(discount),
        investmentAmount: convertNumber(investmentAmount),
        issuingPrice: convertNumber(issuingPrice),
        isExistQuorum: isExistQuorum,
        initialIssuingPrice: convertNumber(initialIssuingPrice),
        oldStockPurchasePrice: convertNumber(oldStockPurchasePrice),
        newOldStock: selectedNewOldStockType,
        note: "",
        stockType: selectedStockType,
        stockNumber: convertNumber(stockNumber),
        shareholdingRatio: convertFloat(shareholdingRatio),
        stage: "",
        quorumType: {
          number: isExistQuorum
            ? selectedFundAccount.quorum
            : selectedQuorumType?.number, // 본계정인 경우 본부 투심이 디폴트
          id: isExistQuorum ? undefined : selectedQuorumType?.id, // 본계정인 경우 본부 투심이 디폴트
          name: isExistQuorum ? undefined : selectedQuorumType?.name, // 본계정인 경우 본부 투심이 디폴트
        },
      },
      requiredTables: selectedFundAccount.tableForm,
    };

    onCreateReportAccount(newAccount);
  };

  const validationCheckPage2 = () => {
    if (!selectedNewOldStockType) return false;
    if (!selectedFundAccount) return false;
    if (!selectedStockType) return false;
    if (isOriginAccount && !selectedQuorumType) return false;

    if (selectedNewOldStockType.newOldStockId === 1) {
      return (
        investmentAmount.length !== 0 &&
        stockNumber.length !== 0 &&
        issuingPrice.length !== 0 &&
        shareholdingRatio.length !== 0
      );
    } else {
      return (
        investmentAmount.length !== 0 &&
        stockNumber.length !== 0 &&
        buyCompany.length !== 0 &&
        oldStockPurchasePrice.length !== 0 &&
        buyCompanyValue.length !== 0 &&
        curNewIssuingPrice.length !== 0 &&
        shareholdingRatio.length !== 0
      );
    }
  };

  const initReportAccount = () => {
    const _issingPrice = document.investmentAssessmentReportAccount[0]
      ? document.investmentAssessmentReportAccount[0]
          .investmentAssessmentAccount.issuingPrice || 0
      : 0;
    updateIssuingPrice(`${_issingPrice}`);
    if (!reportAccount) return;
    const _account = reportAccount.investmentAssessmentAccount;
    const _selectedFundAccount = fundAccountList.find(
      (item) => item.acFundAccountId === _account.acFundAccount?.acFundAccountId
    );
    const _selectedStockType = stockTypeList.find(
      (item) => item.stockTypeId === _account.stockType?.stockTypeId
    );
    const _selectedNewOldStockType = newOldStockList.find(
      (item) => item.newOldStockId === _account.newOldStock?.newOldStockId
    );
    const _selectedQuorumType = quorumTypeList.find(
      (item) => item.id === _account.quorumType?.id
    );
    updateFundAccount(_selectedFundAccount);
    updateOriginAccount(_selectedFundAccount?.acFundAccountId === 1);
    updateStockType(_selectedStockType);
    updateNewOldStockType(_selectedNewOldStockType);
    updateQuorumType(_selectedQuorumType);

    updateAllocationWay(_account.allocationWay || "");
    updateInvestmentAmount(RemoveComma(_account.investmentAmount || ""));
    updateStockNumber(RemoveComma(_account.stockNumber || ""));
    updateOldStockPurchasePrice(
      RemoveComma(_account.oldStockPurchasePrice || "")
    );
    updateBuyCompany(_account.buyCompany || "");
    updateInitialIssuingPrice(RemoveComma(_account.initialIssuingPrice || ""));
    updateBuyCompanyValue(RemoveComma(_account.buyCompanyValue || ""));
    updateCurNewIssuingPrice(RemoveComma(_account.curNewIssuingPrice || ""));
    updateDiscount(`${_account.discount || ""}`);

    setShareholdingRatio(RemoveComma(_account.stockNumber || ""));
  };

  const init = async () => {
    try {
      const fundAccount = await getActiveFundaccount();
      if (fundAccount) {
        updateFundAccountList(fundAccount);
        updateFundAccount(fundAccount[0]);
      }

      const allUsers = await getAllAcUser("user");
      updateAllAcUser(allUsers || []);

      const newOldStockList = await getNewOldStock();
      if (newOldStockList && newOldStockList.length > 0) {
        updateNewOldStockList(newOldStockList);
        updateNewOldStockType(newOldStockList[0]);
      }

      const dicResult = await getAllDictionary();
      updateStockTypeList([...(dicResult?.stockType || [])]);
      updateQuorumTypeList([...(dicResult?.quorumType || [])]);
      updateFundManagerList([...(dicResult?.fundManager || [])]);
      updateLoadedList(true);
    } catch (error) {}
  };

  useEffect(() => {
    if (loadedList) {
      initReportAccount();
    }
  }, [loadedList]);

  useEffect(() => {
    init();
  }, []);

  const getTitle = () => {
    if (isUpdateAuth) {
      if (reportAccount) {
        return reportAccount.isPublished ? "투자 재원" : "투자 재원 수정";
      } else {
        return "투자 재원 추가";
      }
    } else {
      return "투자 재원";
    }
  };

  if (!loadedList) return null;

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          {getTitle()}
        </span>
        <div className="close">
          <Close onClick={close} />
        </div>
      </Header>
      <Body>
        <PageContainer style={{ gap: 20 }}>
          <RowTitleBox
            textClassName="heading-14-md"
            title="투자 재원"
            titleWidth={TITLE_WIDTH}
            transfromPixel={transfromPixel}
          >
            <SelectBox
              editable={isUpdateAuth}
              width={486}
              defaultValue={selectedFundAccount?.nameKo}
              data={fundAccountList.map(
                (item): SelectBoxData => ({
                  id: item.acFundAccountId,
                  text: item.nameKo,
                  data: item,
                })
              )}
              onChange={(item) => {
                console.log(item);
                if (item.id === 1) {
                  updateQuorumType(undefined);
                } else {
                  updateQuorumType({ number: item.data.quorum });
                }

                updateOriginAccount(item.id === 1);
                updateFundAccount({ ...item.data });
              }}
            />
          </RowTitleBox>
          <RowTitleBox
            textClassName="heading-14-md"
            title="주식 종류"
            titleWidth={TITLE_WIDTH}
            transfromPixel={transfromPixel}
          >
            <SelectBox
              editable={isUpdateAuth}
              width={486}
              defaultValue={selectedStockType?.name}
              data={stockTypeList.map(
                (item): SelectBoxData => ({
                  id: item.stockTypeId,
                  text: item.name,
                  data: item,
                })
              )}
              onChange={(item) => {
                console.log(item);
                updateStockType({ ...item.data });
              }}
            />
          </RowTitleBox>
          <FlexRow>
            <RowTitleBox
              textClassName="heading-14-md"
              title="신주/구주"
              titleWidth={TITLE_WIDTH}
              transfromPixel={transfromPixel}
            >
              <NewOldStockRadio
                editable={isUpdateAuth}
                data={newOldStockList.map(
                  (item): NewOldStockRadioProps => ({
                    id: item.newOldStockId,
                    text: item.name,
                    data: item,
                  })
                )}
                defaultValue={selectedNewOldStockType?.name}
                onChange={(item) => {
                  updateNewOldStockType({ ...item.data });
                  updateInvestmentAmount(
                    item.id === 1
                      ? getOurInvestmentAmount()
                      : getOurPurchaseAmount()
                  );
                }}
              />
            </RowTitleBox>
            {isOriginAccount && (
              <RowTitleBox
                textClassName="heading-14-md"
                title="투자 프로세스"
                titleWidth={TITLE_WIDTH}
                transfromPixel={transfromPixel}
              >
                <SelectBox
                  editable={isUpdateAuth}
                  width={240}
                  defaultValue={selectedQuorumType?.name}
                  placeholder="선택하세요"
                  data={quorumTypeList.map(
                    (item): SelectBoxData => ({
                      id: item.id,
                      text: item.name,
                      data: item,
                    })
                  )}
                  onChange={(item) => {
                    console.log(item);
                    updateQuorumType({ ...item.data });
                  }}
                />
              </RowTitleBox>
            )}
          </FlexRow>
          {selectedNewOldStockType?.newOldStockId === 1 ? (
            <FlexColumn style={{ gap: 20 }}>
              <RowTitleBox
                textClassName="heading-14-md"
                title="신주 배정 방식"
                titleWidth={TITLE_WIDTH}
                transfromPixel={transfromPixel}
              >
                <NewOldStockRadio
                  editable={isUpdateAuth}
                  data={[
                    { id: 1, text: "제 3자 방식", data: "제 3자 방식" },
                    { id: 2, text: "주주배정 방식", data: "주주배정 방식" },
                  ]}
                  defaultValue={allocationWay}
                  onChange={(item) => {
                    updateAllocationWay(item.text);
                  }}
                />
              </RowTitleBox>
              <FlexRow>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title={
                    isOriginAccount
                      ? "당사 배정 주식 수"
                      : "당 조합 배정 주식 수"
                  }
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly={!isUpdateAuth}
                    textType="numberWithComma"
                    transfromPixel={transfromPixel}
                    unit="주"
                    width={188}
                    placeholder="0"
                    defaultValue={stockNumber}
                    onChange={(value) => {
                      updateStockNumber(value);
                      setShareholdingRatio(value);

                      if (issuingPrice.length > 0 && value.length > 0) {
                        const numIssuingPrice = parseInt(
                          RemoveComma(issuingPrice),
                          10
                        );
                        const numStockNumber = parseInt(RemoveComma(value), 10);
                        updateInvestmentAmount(
                          AddComma(numIssuingPrice * numStockNumber)
                        );
                      } else {
                        updateInvestmentAmount("");
                      }
                    }}
                  />
                </RowTitleBox>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="투자 후 지분율"
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly
                    textType="percent"
                    transfromPixel={transfromPixel}
                    unit="%"
                    width={188}
                    placeholder="0"
                    defaultValue={shareholdingRatio}
                  />
                </RowTitleBox>
              </FlexRow>
              <FlexRow>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="신주발행가"
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly
                    textType="numberWithComma"
                    transfromPixel={transfromPixel}
                    unit="원"
                    width={188}
                    placeholder="0"
                    defaultValue={issuingPrice}
                    onChange={(value) => {
                      updateIssuingPrice(value);
                      if (stockNumber.length > 0 && value.length > 0) {
                        const numIssuingPrice = parseInt(
                          RemoveComma(value),
                          10
                        );
                        const numStockNumber = parseInt(
                          RemoveComma(stockNumber),
                          10
                        );
                        updateInvestmentAmount(
                          AddComma(numIssuingPrice * numStockNumber)
                        );
                      } else {
                        updateInvestmentAmount("");
                      }
                    }}
                  />
                </RowTitleBox>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title={
                    isOriginAccount ? "당사 투자 금액" : "당 조합 투자 금액"
                  }
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly
                    textType="numberWithComma"
                    transfromPixel={transfromPixel}
                    unit="원"
                    width={188}
                    placeholder="자동으로 계산됩니다."
                    defaultValue={investmentAmount}
                  />
                </RowTitleBox>
              </FlexRow>
            </FlexColumn>
          ) : (
            <FlexColumn style={{ gap: 20 }}>
              <FlexRow>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title={
                    isOriginAccount
                      ? "당사 배정 주식 수"
                      : "당 조합 배정 주식 수"
                  }
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly={!isUpdateAuth}
                    textType="numberWithComma"
                    transfromPixel={transfromPixel}
                    unit="주"
                    width={188}
                    placeholder="0"
                    defaultValue={stockNumber}
                    onChange={(value) => {
                      updateStockNumber(value);
                      setShareholdingRatio(value);
                      if (
                        oldStockPurchasePrice.length > 0 &&
                        value.length > 0
                      ) {
                        const numOldStockPurchasePrice = parseInt(
                          RemoveComma(oldStockPurchasePrice),
                          10
                        );
                        const numStockNumber = parseInt(RemoveComma(value), 10);
                        updateInvestmentAmount(
                          AddComma(numOldStockPurchasePrice * numStockNumber)
                        );
                      } else {
                        updateInvestmentAmount("");
                      }
                    }}
                  />
                </RowTitleBox>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="투자 후 지분율"
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly
                    textType="percent"
                    transfromPixel={transfromPixel}
                    unit="%"
                    width={188}
                    placeholder="0"
                    defaultValue={shareholdingRatio}
                  />
                </RowTitleBox>
              </FlexRow>
              <FlexRow>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="최근 신주 발행가"
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly={!isUpdateAuth}
                    textType="numberWithComma"
                    transfromPixel={transfromPixel}
                    unit="원"
                    width={188}
                    placeholder="0"
                    defaultValue={curNewIssuingPrice}
                    onChange={(value) => {
                      updateCurNewIssuingPrice(value);

                      if (issuingPrice.length > 0 && value.length > 0) {
                        const numIssuingPrice = parseInt(
                          RemoveComma(issuingPrice),
                          10
                        );
                        const numCurNumIssuingPrice = parseInt(
                          RemoveComma(value),
                          10
                        );
                        const numDiscount =
                          100 - (numIssuingPrice / numCurNumIssuingPrice) * 100;

                        updateDiscount(
                          numDiscount < 0 ? "0" : numDiscount.toFixed(2)
                        );
                      } else {
                        updateDiscount("");
                      }
                    }}
                  />
                </RowTitleBox>
              </FlexRow>

              <FlexRow>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="구주매입가"
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly={!isUpdateAuth}
                    textType="numberWithComma"
                    transfromPixel={transfromPixel}
                    unit="원"
                    width={188}
                    placeholder="0"
                    defaultValue={oldStockPurchasePrice}
                    onChange={(value) => {
                      updateOldStockPurchasePrice(value);
                      setBuycompanayValue(value);
                      if (stockNumber.length > 0 && value.length > 0) {
                        const numOldStockPurchasePrice = parseInt(
                          RemoveComma(value),
                          10
                        );
                        const numStockNumber = parseInt(
                          RemoveComma(stockNumber),
                          10
                        );
                        updateInvestmentAmount(
                          AddComma(numOldStockPurchasePrice * numStockNumber)
                        );
                      } else {
                        updateInvestmentAmount("");
                      }

                      if (curNewIssuingPrice.length > 0 && value.length > 0) {
                        const numCurNumIssuingPrice = parseInt(
                          RemoveComma(curNewIssuingPrice),
                          10
                        );
                        const numOldStockPurchasePrice = parseInt(
                          RemoveComma(value),
                          10
                        );
                        const numDiscount =
                          100 -
                          (numOldStockPurchasePrice / numCurNumIssuingPrice) *
                            100;

                        updateDiscount(
                          numDiscount < 0 ? "0" : numDiscount.toFixed(2)
                        );
                      } else {
                        updateDiscount("");
                      }
                    }}
                  />
                </RowTitleBox>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title={
                    isOriginAccount ? "당사 거래 금액" : "당 조합 거래 금액"
                  }
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly
                    textType="numberWithComma"
                    transfromPixel={transfromPixel}
                    unit="원"
                    width={188}
                    placeholder="자동으로 계산됩니다."
                    defaultValue={investmentAmount}
                  />
                </RowTitleBox>
              </FlexRow>
              <FlexRow>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="매입처"
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly={!isUpdateAuth}
                    textType="text"
                    transfromPixel={transfromPixel}
                    width={200}
                    placeholder="매입처를 입력하세요."
                    defaultValue={buyCompany}
                    onChange={(value) => {
                      updateBuyCompany(value);
                    }}
                  />
                </RowTitleBox>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="구주 매입가 기준 기업 가치"
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <InputBox
                    readOnly
                    textType="numberWithComma"
                    transfromPixel={transfromPixel}
                    unit="원"
                    width={188}
                    placeholder="0"
                    defaultValue={buyCompanyValue}
                  />
                </RowTitleBox>
              </FlexRow>
            </FlexColumn>
          )}
        </PageContainer>
      </Body>
      <Footer>
        <ButtonContainer>
          {isUpdateAuth && (
            <DefaultButton
              className="heading-16-md"
              buttonType="text"
              sizeType="small"
              buttonColor={"#1F2022"}
              text={"취소"}
              onClick={() => {
                close();
              }}
              transfromPixel={transfromPixel}
            />
          )}
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            disabled={isUpdateAuth ? !validationCheckPage2() : false}
            text={isUpdateAuth ? "완료" : "확인"}
            onClick={() => {
              if (isUpdateAuth) {
                if (
                  reportAccount !== undefined &&
                  selectedFundAccount !== undefined
                ) {
                  if (
                    reportAccount?.investmentAssessmentAccount.acFundAccount
                      ?.acFundAccountId !==
                      selectedFundAccount.acFundAccountId &&
                    reportAccount.fundManagerConfirm
                  ) {
                    return updateModifyInviteUserModal1(true);
                  }
                  modifyAccount();
                } else {
                  createAccount();
                }
              } else {
                close();
              }
            }}
            transfromPixel={props.transfromPixel}
          />
        </ButtonContainer>
      </Footer>

      {modifyInviteUserModal1 && (
        <ModalLayout
          isFullScreen
          isOpen={modifyInviteUserModal1}
          toggle={() => {
            updateModifyInviteUserModal1(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"확정 요청 취소"}
            contents={[
              `대표펀드 매니저에게 확정 받은 투자 재원입니다.`,
              `투자 재원을 수정하시면`,
              `다시 확정 요청을 보내야 합니다.`,
              `수정하시겠습니까?`,
            ]}
            positiveTitle={"예"}
            negativeTitle={"아니오"}
            onClickNegativeBtn={() => {
              updateModifyInviteUserModal1(false);
            }}
            onClickPositiveBtn={() => {
              updateModifyInviteUserModal1(false);
              modifyAccount();
            }}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 80vw;
  max-width: 900px;
  height: 70vh;
  max-height: 630px;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: scroll;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease;
`;

const PageContainer = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 24px 0 24px;
  overflow: scroll;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  min-height: 72px;
  max-height: 72px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 24px 0px 0px;
  gap: 12px;
`;

const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export default EvaluationInvestFundAccountModal;
