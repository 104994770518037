import React from "react";

import styled from "styled-components";
import { AddComma } from "../../../../../common/commonUtil";

interface Props {
  companyManagementStatusSum?: {
    investmentedCompanySum?: number;
    prevDamagedCompanySum?: number;
    evaluatedCompanySum?: number;
    curDamagedCompanySum?: number;
  };
  style?: React.CSSProperties;
}

export const CMSFooter: React.FC<Props> = (props) => {
  const HEIGHT = 32;
  return (
    <Container style={props.style}>
      <CellConatiner
        className={`caption-12-sb`}
        style={{
          flexGrow: 1,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        합계
      </CellConatiner>
      <CellConatiner
        className={`caption-12-sb`}
        style={{
          width: 140,
          minWidth: 140,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
          justifyContent: "flex-end",
        }}
      >
        {props.companyManagementStatusSum?.investmentedCompanySum
          ? AddComma(props.companyManagementStatusSum?.investmentedCompanySum)
          : "-"}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-sb`}
        style={{
          width: 140,
          minWidth: 140,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
          justifyContent: "flex-end",
        }}
      >
        {props.companyManagementStatusSum?.prevDamagedCompanySum
          ? AddComma(props.companyManagementStatusSum?.prevDamagedCompanySum)
          : "-"}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-sb`}
        style={{
          width: 140,
          minWidth: 140,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
          justifyContent: "flex-end",
        }}
      >
        {props.companyManagementStatusSum?.evaluatedCompanySum
          ? AddComma(props.companyManagementStatusSum?.evaluatedCompanySum)
          : "-"}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-sb`}
        style={{
          width: 140,
          minWidth: 140,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
          justifyContent: "flex-end",
        }}
      >
        {props.companyManagementStatusSum?.curDamagedCompanySum
          ? AddComma(props.companyManagementStatusSum?.curDamagedCompanySum)
          : "-"}
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.div`
  width: 100%;
  cursor: default;

  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  background: var(--gray-500);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 8px;

  color: #000;

  &:nth-child(1) {
    border-left: 2px solid #000;
  }
`;
