import React from "react";
import styled from "styled-components";

import StockHolderGrid, { GridHeaderProps } from "./grid/StockHolderGrid";
import { StockHolder } from "./interface/StockHolder.interface";
import { AddComma } from "../../../../../../common/commonUtil";

interface Props {
  data: StockHolder[];
  gridHeight: number;
  hilightIndex?: number[];
}

const StockHoldeList: React.FC<Props> = (props) => {
  const header: GridHeaderProps[] = [
    {
      header: "주주명",
      key: "stockholderName",
      width: 400,
      customHeaderCell: () => {
        return (
          <StockHolderHeaderComponent
            className="body-14-rg"
            style={{ justifyContent: "flex-start" }}
          >
            주주명
          </StockHolderHeaderComponent>
        );
      },
      customCell: (data, rowIndex) => {
        console.log(rowIndex);
        const isHilight =
          rowIndex &&
          props.hilightIndex &&
          props.hilightIndex.includes(rowIndex);
        const hilightStyle = isHilight
          ? { backgroundColor: "#FFF2F3", color: "#F6444F" }
          : undefined;

        return (
          <StockHolderCellComponent className="body-14-rg" style={hilightStyle}>
            {data}
          </StockHolderCellComponent>
        );
      },
    },
    {
      header: "사업자/주민등록번호",
      key: "registrationNumber",
      width: 184,
      customHeaderCell: () => {
        return (
          <StockHolderHeaderComponent className="body-14-rg">
            사업자/주민등록번호
          </StockHolderHeaderComponent>
        );
      },
      customCell: (data, rowIndex) => {
        const isHilight =
          rowIndex &&
          props.hilightIndex &&
          props.hilightIndex.includes(rowIndex);
        const hilightStyle = isHilight
          ? { backgroundColor: "#FFF2F3", color: "#F6444F" }
          : undefined;

        return (
          <StockHolderCellComponent
            className="body-14-rg"
            style={{ ...hilightStyle, justifyContent: "center" }}
          >
            {data}
          </StockHolderCellComponent>
        );
      },
    },
    {
      header: "주식종류",
      key: "stockType",
      width: 140,
      customHeaderCell: () => {
        return (
          <StockHolderHeaderComponent className="body-14-rg">
            주식종류
          </StockHolderHeaderComponent>
        );
      },
      customCell: (data, rowIndex) => {
        const isHilight =
          rowIndex &&
          props.hilightIndex &&
          props.hilightIndex.includes(rowIndex);
        const hilightStyle = isHilight
          ? { backgroundColor: "#FFF2F3", color: "#F6444F" }
          : undefined;

        return (
          <StockHolderCellComponent
            className="body-14-rg"
            style={{ ...hilightStyle, justifyContent: "center" }}
          >
            {data}
          </StockHolderCellComponent>
        );
      },
    },
    {
      header: "소유주식 수",
      key: "stocks",
      width: 128,
      customHeaderCell: () => {
        return (
          <StockHolderHeaderComponent className="body-14-rg">
            소유주식 수
          </StockHolderHeaderComponent>
        );
      },
      customCell: (data, rowIndex) => {
        const isHilight =
          rowIndex &&
          props.hilightIndex &&
          props.hilightIndex.includes(rowIndex);
        const hilightStyle = isHilight
          ? { backgroundColor: "#FFF2F3", color: "#F6444F" }
          : undefined;

        return (
          <StockHolderCellComponent
            className="body-14-rg"
            style={{ ...hilightStyle, justifyContent: "flex-end" }}
          >
            {AddComma(data)}
          </StockHolderCellComponent>
        );
      },
    },
  ];

  console.log(props.data);
  return (
    <Container>
      <StockHolderGrid
        headers={header}
        data={props.data.filter((item) => item.stocks > 0)}
        headerHeight={34}
        rowHeight={50}
        bodyHeight={props.gridHeight}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: fit-content;
  height: auto;
  margin: 0 auto;
`;

const StockHolderHeaderComponent = styled.div`
  width: 100%;
  height: 100%;
  color: #96999f;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StockHolderCellComponent = styled.div`
  width: 100%;
  height: 100%;
  color: #1f2022;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default StockHoldeList;
