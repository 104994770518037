import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Channel,
  DBAcceleratingPortfolio,
  DBTotalSearchPortfolio,
  DPBPAccelerating,
  DPBPTotalSearch,
  FundAccountType,
  IndustryTech,
  PortfolioPhaseProps,
  UserInfo,
} from "../../../../../../type/data";

import {
  getDatabaseAccelerating,
  getDatabaseSearchData,
  getDatabaseTotalSearch,
} from "../../../../../../api/repository/database/DatabaseRepository";
import {
  getDatabaseHistory,
  setDatabaseHistory,
} from "../../../../../../common/pageHistoryUtil";
import {
  getAllAcUser,
  getAllIndustrialTech,
} from "../../../../../../api/repository/accelerator/AcceleratorRepository";
import {
  InputSearchValueType,
  IsAllSelectedType,
  SearchValueType,
} from "../../../organisms/FilterAccelerating";
import { RoundProps } from "../../../../../portfolio-viewer-stock-change-history/interface/type";
import { getNewDate } from "../../../../../../common/commonUtil";

const useAcceleratingSearch = () => {
  const isSearching = useRef<boolean>(false);
  const currentPortfolio = useRef<DBAcceleratingPortfolio | undefined>(
    undefined
  );
  const countPerPage = 30;

  const [totalCount, updateTotalCount] = useState<number>(0);
  const [totalPage, updateTotalPage] = useState<number>(0);
  const [resultPortfolios, updateResultPortfolios] = useState<
    DPBPAccelerating | undefined
  >(undefined);
  const [searchObject, setSearchObject] = useState<SearchValueType | undefined>(
    undefined
  );
  const [inputChipObject, setInputChipObject] = useState<InputSearchValueType>(
    {}
  );
  const [selectedData, updateSelectedData] = useState<
    {
      id: number;
      identificationNumber: number;
      data: DBAcceleratingPortfolio;
    }[]
  >([]);

  const [openPortfolioModal, updatePortfolioModal] = useState<boolean>(false);
  const [showSelectedDataList, updateShowSelectedDataList] =
    useState<boolean>(false);

  const [industrialTechs, setIndustrialTechs] = useState<IndustryTech[]>([]);
  const [participants, setParticipants] = useState<UserInfo[]>([]);

  const [round, setRound] = useState<RoundProps[]>([]);
  const [investmentYears, setInvestmentYears] = useState<number[]>([]);
  const [addrList, setAddrList] = useState<string[]>([]);
  const [fundAccountList, setFundAccountList] = useState<FundAccountType[]>([]);

  const isAllSelectedObject = useRef<IsAllSelectedType>({
    industry: false,
    participant: false,
    fundAccounts: false,
    stages: false,
    addr1: false,
  });

  const onPortfolioModal = (portfolio: DBAcceleratingPortfolio) => {
    currentPortfolio.current = portfolio;
    updatePortfolioModal(true);
  };
  const getBodyBySearchObject = () => {
    if (!searchObject) return undefined;

    return {
      pageIndex: searchObject.curPage || 1,
      dataPerPage: countPerPage,
      searchWord: searchObject.keyword,
      acIndustrialTech:
        searchObject.industry!.length === industrialTechs.length ||
        searchObject.industry!.length === 0
          ? undefined
          : searchObject.industry,
      acUser:
        searchObject.participant!.length === participants.length ||
        searchObject.participant!.length === 0
          ? undefined
          : searchObject.participant,
      addr1:
        searchObject.addr1!.length === addrList.length ||
        searchObject.addr1!.length === 0
          ? undefined
          : searchObject.addr1,
      acFundAccount:
        searchObject.fundAccounts!.length === addrList.length ||
        searchObject.fundAccounts!.length === 0
          ? undefined
          : searchObject.fundAccounts,
      round:
        searchObject.round!.length === round.length ||
        searchObject.round!.length === 0
          ? undefined
          : searchObject.round,
      minInvestmentYear: searchObject.minInvestmentYear,
      maxInvestmentYear: searchObject.maxInvestmentYear,
      minInvestmentQuarter: searchObject.minInvestmentQuarter,
      maxInvestmentQuarter: searchObject.maxInvestmentQuarter,
      sortItem: searchObject.sortItem,
      sortType: searchObject.sortType,
      minPurchasePrincipal:
        inputChipObject.minPurchasePrincipal &&
        Math.floor(inputChipObject.minPurchasePrincipal * 100000000),
      maxPurchasePrincipal:
        inputChipObject.maxPurchasePrincipal &&
        Math.floor(inputChipObject.maxPurchasePrincipal * 100000000),
      minCompanyValue:
        inputChipObject.minCompanyValue &&
        Math.floor(inputChipObject.minCompanyValue * 100000000),
      maxCompanyValue:
        inputChipObject.maxCompanyValue &&
        Math.floor(inputChipObject.maxCompanyValue * 100000000),
      minSalesAmount:
        inputChipObject.minSalesAmount &&
        Math.floor(inputChipObject.minSalesAmount * 100000000),
      maxSalesAmount:
        inputChipObject.maxSalesAmount &&
        Math.floor(inputChipObject.maxSalesAmount * 100000000),
      minOwnershipPercentage:
        inputChipObject.minOwnershipPercentage &&
        inputChipObject.minOwnershipPercentage * 0.01,
      maxOwnershipPercentage:
        inputChipObject.maxOwnershipPercentage &&
        inputChipObject.maxOwnershipPercentage * 0.01,
      minRealizedMultiple: inputChipObject.minRealizedMultiple,
      maxRealizedMultiple: inputChipObject.maxRealizedMultiple,
      minUnrealizedMultiple: inputChipObject.minUnrealizedMultiple,
      maxUnrealizedMultiple: inputChipObject.maxUnrealizedMultiple,
    };
  };

  const getAcceleratingPortfolioList = async (page?: number) => {
    const body = getBodyBySearchObject();
    if (isSearching.current || !searchObject || !body) return;
    try {
      isSearching.current = true;
      const result = await getDatabaseAccelerating({ ...body });

      setDatabaseHistory({
        page: searchObject.curPage || 1,
        search: {
          keyword: searchObject.keyword,
          acIndustrialTechId: searchObject.industry,
          acUser: searchObject.participant,
          addr1: searchObject.addr1,
          acFundAccountId: searchObject.fundAccounts,
          roundId: searchObject.round,
          minInvestmentYear: searchObject.minInvestmentYear || 0,
          maxInvestmentYear: searchObject.maxInvestmentYear || 0,
          minInvestmentQuarter: searchObject.minInvestmentQuarter || 0,
          maxInvestmentQuarter: searchObject.maxInvestmentQuarter || 0,
          sortItem: searchObject.sortItem || "",
          sortType: searchObject.sortType || "",
          minPurchasePrincipal: inputChipObject.minPurchasePrincipal || 0,
          maxPurchasePrincipal: inputChipObject.maxPurchasePrincipal || 0,
          minCompanyValue: inputChipObject.minCompanyValue || 0,
          maxCompanyValue: inputChipObject.maxCompanyValue || 0,
          minSalesAmount: inputChipObject.minSalesAmount || 0,
          maxSalesAmount: inputChipObject.maxSalesAmount || 0,
          minOwnershipPercentage: inputChipObject.minOwnershipPercentage || 0,
          maxOwnershipPercentage: inputChipObject.maxOwnershipPercentage || 0,
          minRealizedMultiple: inputChipObject.minRealizedMultiple || 0,
          maxRealizedMultiple: inputChipObject.maxRealizedMultiple || 0,
          minUnrealizedMultiple: inputChipObject.minUnrealizedMultiple || 0,
          maxUnrealizedMultiple: inputChipObject.maxUnrealizedMultiple || 0,
        },
      });
      if (result.success) {
        updateTotalPage(result.data?.totalPage || 1);
        updateTotalCount(result.data?.totalCount || 0);
        updateResultPortfolios(result.data);
      }
      isSearching.current = false;
    } catch (error) {
      isSearching.current = false;
    }
  };

  const init = async () => {
    const history = getDatabaseHistory();
    const searchData = await getDatabaseSearchData();
    const industrialResult = await getAllIndustrialTech();
    const allUserResult = await getAllAcUser("user");
    const _investmentYears: number[] = [];
    for (var year = getNewDate().getFullYear(); year >= 2014; year--) {
      _investmentYears.splice(0, 0, year);
    }

    if (searchData) {
      setRound(searchData.round || []);
      setFundAccountList(searchData.acFundAccount || []);
      setAddrList(
        searchData.addr1 ? searchData.addr1.map((item) => item.addr1) : []
      );
    }
    industrialResult && setIndustrialTechs(industrialResult);
    allUserResult && setParticipants(allUserResult);
    setInvestmentYears(_investmentYears);

    const inputChip = {
      minPurchasePrincipal: history?.search?.minPurchasePrincipal || undefined,
      maxPurchasePrincipal: history?.search?.maxPurchasePrincipal || undefined,
      minCompanyValue: history?.search?.minCompanyValue || undefined,
      maxCompanyValue: history?.search?.maxCompanyValue || undefined,
      minSalesAmount: history?.search?.minSalesAmount || undefined,
      maxSalesAmount: history?.search?.maxSalesAmount || undefined,
      minOwnershipPercentage:
        history?.search?.minOwnershipPercentage || undefined,
      maxOwnershipPercentage:
        history?.search?.maxOwnershipPercentage || undefined,
      minRealizedMultiple: history?.search?.minRealizedMultiple || undefined,
      maxRealizedMultiple: history?.search?.maxRealizedMultiple || undefined,
      minUnrealizedMultiple:
        history?.search?.minUnrealizedMultiple || undefined,
      maxUnrealizedMultiple:
        history?.search?.maxUnrealizedMultiple || undefined,
    };

    setSearchObject({
      keyword: history?.search?.keyword || "",
      industry: history?.search?.acIndustrialTechId || [],
      participant: history?.search?.acUser || [],
      fundAccounts: history?.search?.acFundAccountId || [],
      round: history?.search?.roundId || [],
      addr1: history?.search?.addr1 || [],
      curPage: history?.page || 1,

      minInvestmentYear: history?.search?.minInvestmentYear || undefined,
      maxInvestmentYear: history?.search?.maxInvestmentYear || undefined,
      minInvestmentQuarter: history?.search?.minInvestmentQuarter || undefined,
      maxInvestmentQuarter: history?.search?.maxInvestmentQuarter || undefined,

      sortItem: history?.search?.sortItem || undefined,
      sortType: history?.search?.sortType || undefined,
      ...inputChip,
    });

    setInputChipObject({
      ...inputChip,
    });
  };

  useEffect(() => {
    if (
      industrialTechs.length > 0 &&
      round.length > 0 &&
      participants.length > 0 &&
      fundAccountList.length > 0 &&
      addrList.length > 0 &&
      investmentYears.length > 0
    )
      getAcceleratingPortfolioList();
  }, [
    searchObject,
    industrialTechs,
    round,
    participants,
    fundAccountList,
    addrList,
    investmentYears,
  ]);

  useEffect(() => {
    if (!openPortfolioModal) {
      currentPortfolio.current = undefined;
    }
  }, [openPortfolioModal]);

  useEffect(() => {
    init();
  }, []);

  return [
    countPerPage,
    round,
    addrList,
    investmentYears,
    currentPortfolio,
    isAllSelectedObject,
    industrialTechs,
    openPortfolioModal,
    participants,
    resultPortfolios,
    searchObject,
    totalCount,
    totalPage,
    selectedData,
    inputChipObject,
    fundAccountList,
    showSelectedDataList,
    updateShowSelectedDataList,
    setInputChipObject,
    updateSelectedData,
    onPortfolioModal,
    setSearchObject,
    updatePortfolioModal,
    getBodyBySearchObject,
  ] as const;
};

export default useAcceleratingSearch;
