import React from "react";
import { css } from "@emotion/react";
import styled from "styled-components";
import Loader from "react-spinners/PulseLoader";
import ModalLayout from "./ModalLayout";

interface Props {
  message?: string;
  isFullScreen?: boolean;
  open: boolean;
}

const override = css`
  display: block;
  margin: 0 auto;
`;
const SpinnerModal: React.FC<Props> = ({
  message = "Loading",
  open,
  isFullScreen = true,
}) => {
  return (
    <ModalLayout
      isOpen={open}
      isBackgroundTransparent
      isFullScreen={isFullScreen}
    >
      <Container className="scroll__invisible">
        <Loader
          color="#173AFF"
          loading
          css={override}
          size={16}
          margin={8}
          speedMultiplier={0.5}
        />
        <span className="font-bold-16" style={{ color: "#173AFF" }}>
          {message}
        </span>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;
export default SpinnerModal;
