import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DocumentFile, ReportingFile } from "../../../ReportingDetail";
import { getSBPData } from "../../../../repository/ReportingRepository";
import { SBPContentsPropsV2, SBPTabProps } from "../interface/SBP.interface";
import config from "../../../../../../../config";

const useSBP = (document: SBPContentsPropsV2) => {
  const [tabList, updateTabList] = useState<SBPTabProps[]>([]);
  const [selectedTabId, updateSelectedTabId] = useState<number>(1);

  const checkTabValidation = (sbpData: SBPContentsPropsV2) => {
    if (!sbpData.quarter) return;

    const status: SBPTabProps = {
      id: 1,
      name: sbpData.quarter === 2 ? "반기 사업 현황" : "온기 사업 현황",
      isComplete: false,
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "사업 진행",
          isComplete: false,
          selectId: "status1",
        },
        {
          index: 2,
          sectionTitle: "정부 지원 과제 참여",
          isComplete: false,
          selectId: "status3",
        },
        {
          index: 3,
          sectionTitle: "자금 차입",
          isComplete: false,
          selectId: "status4",
        },
        {
          index: 4,
          sectionTitle: "인력 변동",
          isComplete: false,
          selectId: "status5",
        },
        {
          index: 5,
          sectionTitle: "투자 후 누적 출원 및 등록",
          isComplete: false,
          selectId: "status6",
        },
      ],
    };

    const plan: SBPTabProps = {
      id: 2,
      name: sbpData.quarter === 2 ? "반기 사업 계획" : "온기 사업 계획",
      isComplete: false,
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "사업 계획",
          isComplete: false,
          selectId: "plan1",
        },
        {
          index: 2,
          sectionTitle: "자금 조달 계획",
          isComplete: false,
          selectId: "plan2",
        },
        {
          index: 3,
          sectionTitle: "현금 흐름 추정",
          isComplete: false,
          selectId: "plan3",
        },
      ],
    };

    const issue: SBPTabProps = {
      id: 3,
      name: "이슈 보고",
      isComplete: false,
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "경영진 이슈",
          isComplete: false,
          selectId: "issue1",
        },
        {
          index: 2,
          sectionTitle: "Milestone 이슈",
          isComplete: false,
          selectId: "issue2",
        },
        {
          index: 3,
          sectionTitle: "경쟁사 영향",
          isComplete: false,
          selectId: "issue3",
        },
        {
          index: 4,
          sectionTitle: "거시환경 영향 여부",
          isComplete: false,
          selectId: "issue4",
        },
      ],
    };

    const request: SBPTabProps = {
      id: 4,
      name: `${config.companyName} 요청 사항`,
      isComplete: false,
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "담당 심사역 및 조력자",
          isComplete: false,
          selectId: "request1",
        },
        {
          index: 2,
          sectionTitle: `${config.companyName} 만족도`,
          isComplete: false,
          selectId: "request2",
        },
      ],
    };

    updateTabList([status, plan, issue, request]);
  };

  const initTabList = (sbpData: SBPContentsPropsV2) => {
    checkTabValidation(sbpData);
  };

  const setSBP = (data: SBPContentsPropsV2) => {
    initTabList(data);
  };

  const goToTop = () => {
    const root = window.document.querySelector("#sbp__root");

    if (root) {
      root.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const init = async () => {
    setSBP(document);
  };

  useEffect(() => {
    init();
  }, []);

  return [tabList, selectedTabId, updateSelectedTabId, goToTop] as const;
};

export default useSBP;
