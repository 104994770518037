// import { gapi, loadAuth2 } from "gapi-script";
import axios from "axios";
import {
  login,
  removeAccesTokenToken,
  removeAllLoginToken,
  saveToken,
} from "../../api/api";
import { GoogleToken, GoogleTokenImplict, LoginInfo } from "../../type/data";
import config from "../../config";

export const CLIENT_ID = config.gClientId;
export const API_KEY = config.gApiKey;
export const SCOPE = config.gScopes;
export const FOLDER_ID = config.gFolderId;
export const DISCOVERY_DOCS = [config.gDiscoveryDocs];

export const checkLogin = async () => {
  if (!CLIENT_ID || !SCOPE) return;
  // auth
  const accessToken = localStorage.getItem("accessToken") || "";
  return accessToken;
};

const signUp = async (email: string, name: string, signup?: () => void) => {
  try {
    const result = await axios.post("/auth/ac-user/signup", { email, name });
    console.log(result);
    if (result.status === 200) {
      signup && signup();
    }
  } catch (error) {
    console.log(error);
  }
};

//구글 로그인 성공
export const onLogin = async (
  res: GoogleToken,
  isAutoLogin: boolean,
  signup?: () => void,
  notAuth?: () => void,
  loginSuccess?: (loginInfo: LoginInfo) => void
) => {
  try {
    const { email, name } = res.tokenInfo;
    const result = await axios.post(login(), { email, autoLogin: isAutoLogin });
    console.log(result);
    if (result.status === 200) {
      if (
        result.data.message === "tempLogin" &&
        result.data.userStatus === -1
      ) {
        signUp(email, name, signup);
      } else if (
        result.data.message === "tempLogin" &&
        result.data.userStatus === 1
      ) {
        notAuth && notAuth();
      } else if (result.data.message === "login") {
        removeAllLoginToken();
        saveToken(res);
        loginSuccess && loginSuccess(result.data.loginInfo);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

//구글 로그인 성공
export const onLoginImplict = async (
  res: GoogleTokenImplict,
  isAutoLogin: boolean,
  signup?: () => void,
  notAuth?: () => void,
  loginSuccess?: (loginInfo: LoginInfo) => void
) => {
  try {
    const { email, name } = res.tokenInfo;
    const result = await axios.post(login(), { email, autoLogin: isAutoLogin });
    console.log(result);
    if (result.status === 200) {
      if (
        result.data.message === "tempLogin" &&
        result.data.userStatus === -1
      ) {
        signUp(email, name, signup);
      } else if (
        result.data.message === "tempLogin" &&
        result.data.userStatus === 1
      ) {
        notAuth && notAuth();
      } else if (result.data.message === "login") {
        removeAccesTokenToken();
        saveToken(res);
        loginSuccess && loginSuccess(result.data.loginInfo);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
