import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FundAccountDetailInfo } from "../../interface/FundAccountDetail.interface";

interface Props {}
export const UniqueFundInfo: React.FC<Props> = (props) => {
  return (
    <Container>
      <TitleContainer className="heading-18-sb">
        <span>투자계약 및 투자금 집행 조건</span>
      </TitleContainer>
      <BodyContainer>
        <FlexRow style={{ width: "100%", gap: 68 }}>
          <FlexColumn style={{ flex: 1 }}>
            <ColorTag className="body-16-md">팀 투자심의위원회 조건</ColorTag>
            <Description className="heading-16-md">
              다음 조건 모두에 해당하는 투자 거래에 대해서는 투자심의회 가결
              조건에 따라 가결 후 계약 및 집행을 할 수 있습니다.
            </Description>
            <FlexRow
              className="body-14-md"
              style={{ marginTop: 14, gap: 8, flexWrap: "wrap" }}
            >
              <Tag>고유 계정 투자금 2억원 이하</Tag>
              <Tag>투자 후 피투자기업 가치가 40억원 이하</Tag>
            </FlexRow>
            <TipContainer className="body-14-rg">
              팀 투자심의위원회에서 부결된 건은 담당 심사역의 요청에 의해 1회에
              한하여
              <br /> 본부투자심의회에 상정할 수 있습니다. 위 조건에서 정의하는
              고유 계정 투자금 및 투자 후 피투자기업 가치의 기준 금액은 회사의
              경영성과 및 시장현황을 고려하여 경영위원회에서 달리 정할 수
              있습니다.
            </TipContainer>
          </FlexColumn>
          <FlexColumn style={{ flex: 1 }}>
            <ColorTag className="body-16-md">본부 투자심의위원회 조건</ColorTag>
            <Description className="heading-16-md">
              다음 조건 중 하나에 해당하는 투자 거래에 대해서는 본부투자심의회에
              상정하여 투자심의회 가결 조건에 따라 가결 후 계약 및 집행을 할 수
              있습니다.
            </Description>
            <FlexRow
              className="body-14-md"
              style={{ marginTop: 14, gap: 8, flexWrap: "wrap" }}
            >
              <Tag>고유 계정 투자금 2억원 초과</Tag>
              <Tag>펀드 재원을 활용하는 경우</Tag>
              <Tag>
                팀 투자심의위원회 의사결정만으로 투자 리스크가 높다고 판단되는
                경우 (기타 본부장 판단)
              </Tag>
              <Tag>
                팀 투자심의위원회 조건에 따라 담당 심사역 요청에 의해
                본부투자심의회에 상정된 경우
              </Tag>
            </FlexRow>
            <TipContainer className="body-14-rg">
              위 조건에서 정의하는 고유 계정 투자금 및 투자 후 피투자기업 가치의
              기준 금액은
              <br /> 회사의 경영성과 및 시장현황을 고려하여 경영위원회에서 달리
              정할 수 있습니다.
            </TipContainer>
          </FlexColumn>
        </FlexRow>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColorTag = styled.div`
  width: fit-content;
  padding: 6px 14px;

  border-radius: 4px;

  background: var(--blue-50);

  color: var(--blue-400);
`;

const Tag = styled.div`
  width: fit-content;
  padding: 6px 14px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  color: var(--text-04);
`;

const Description = styled.div`
  color: var(--text-01, #1f2022);
  margin-top: 15px;
`;

const TipContainer = styled.div`
  width: 100%;
  padding: 12px 14px;
  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--gray-50, #fafafb);

  display: flex;
  flex-direction: row;
  gap: 10px;

  margin-top: 40px;
`;
