import React, { useEffect, useRef, useState } from "react";
import { API_KEY, CLIENT_ID } from "../google/login/GoogleLoginUtil";
import { gapi } from "gapi-script";
import { LoginInfo } from "../type/data";
import {
  latticeLogin,
  latticeSignUp,
} from "../api/repository/accelerator/AcceleratorRepository";
import { removeAllLoginToken } from "../api/api";
import { getNewDate } from "../common/commonUtil";

const useGoogle = (
  isAutoLogin?: boolean,
  type?: "latticeLogin" | "googleLogin",
  signup?: () => void,
  notAuth?: () => void,
  latticeLoginSuccess?: (loginInfo: LoginInfo) => void
) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const [isGapiReady, setIsGapiReady] = useState<boolean>(false);
  const isLoginClick = useRef<boolean>(false);

  const handleClientLoad = () => {
    gapi.load("client:auth2", initClient);
  };

  const initClient = () => {
    gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        scope: "https://www.googleapis.com/auth/drive",
      })
      .then(() => {
        gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
        console.log(isAutoLogin);
        updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      });
  };

  //구글 로그인 성공
  const onLoginLattice = async () => {
    try {
      const user = gapi.auth2.getAuthInstance().currentUser.get();
      const email = user.wt.cu;
      const name = user.wt.Ad;
      const accessToken = user.xc.access_token;

      const result = await latticeLogin(email, true);
      if (result) {
        if (result.message === "tempLogin" && result.userStatus === -1) {
          const signupResult = await latticeSignUp(email, name);
          signupResult && signup && signup();
        } else if (result.message === "tempLogin" && result.userStatus === 1) {
          notAuth && notAuth();
        } else if (result.message === "login") {
          removeAllLoginToken();
          const accessTokenInfo = JSON.stringify({
            expireDate: getNewDate().getTime() + 50 * 60 * 1000,
          });
          localStorage.setItem("accessTokenInfo", accessTokenInfo);
          localStorage.setItem("accessToken", accessToken);
          latticeLoginSuccess && latticeLoginSuccess(result.loginInfo);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateSigninStatus = async (isSignedIn: boolean) => {
    console.log(isSignedIn);
    if (isSignedIn) {
      setIsLoggedIn(true);
      if (type === "latticeLogin") {
        if (isLoginClick.current || isAutoLogin) {
          onLoginLattice();
        }
      } else {
        removeAllLoginToken();
        const user = gapi.auth2.getAuthInstance().currentUser.get();
        const accessToken = user.xc.access_token;
        const accessTokenInfo = JSON.stringify({
          expireDate: getNewDate().getTime() + 50 * 60 * 1000,
        });
        localStorage.setItem("accessTokenInfo", accessTokenInfo);
        localStorage.setItem("accessToken", accessToken);
      }
    } else {
      setIsLoggedIn(false);
    }
  };

  const handleSignInClick = () => {
    isLoginClick.current = true;
    gapi.auth2.getAuthInstance().signIn();
  };

  const handleSignOutClick = () => {
    gapi.auth2.getAuthInstance().signOut();
  };

  const getPDFBlobFile = async (fileId: string) => {
    if (!isGapiReady) return;
    const transformToBlob = (response: any) => {
      const blob = new Blob(
        [
          new Uint8Array(response.body.length).map((_, i) =>
            response.body.charCodeAt(i)
          ),
        ],
        { type: "application/pdf" }
      );
      return blob;
    };

    const response = await gapi.client.drive.files.get(
      {
        fileId: fileId,
        alt: "media",
      },
      {
        responseType: "blob",
      }
    );
    const blob = transformToBlob(response);
    return blob;
  };

  const createFolder = async (parent: string, name: string) => {
    if (!isGapiReady) return;

    const params = {
      name: name, // 여기에 생성하려는 폴더의 이름을 입력합니다.
      mimeType: "application/vnd.google-apps.folder", // 폴더를 나타내는 MIME 타입입니다.
      parents: [parent],
    };
    const result = await gapi.client.drive.files.create({
      resource: params,
    });

    return result.result.id;
  };

  useEffect(() => {
    if (isLoggedIn) {
      gapi.client.load("drive", "v3", async (res: any) => {
        if (gapi.client.drive.files !== undefined) {
          setIsGapiReady(true);
        }
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    isLoginClick.current = false;
    handleClientLoad();
    return () => {
      isLoginClick.current = false;
      isAutoLogin = undefined;
      type = undefined;
      signup = undefined;
      notAuth = undefined;
      latticeLoginSuccess = undefined;
      return;
    };
  }, []);
  return [
    isGapiReady,
    isLoggedIn,
    handleSignInClick,
    handleSignOutClick,
    getPDFBlobFile,
    onLoginLattice,
    createFolder,
  ] as const;
};

export default useGoogle;
