import React from "react";
import styled from "styled-components";

import { SBPContentsPropsV2 } from "../../interface/SBP.interface";
import { SBPSector } from "../SBPSector";
import { AutoResizableTextarea } from "../AutoResizableTextarea";
import { SBPSectorDescription } from "../SBPSectorDescription";

import ChoiseSingle from "./organism/ChoiseSingle";
import RowTitleBox from "../RowTitleBox";
import config from "../../../../../../../../config";

interface Props {
  isReadOnly: boolean;
  data?: SBPContentsPropsV2;
  onChangeData?: (data: SBPContentsPropsV2) => void;
}

export const SBPRequest: React.FC<Props> = (props: Props) => {
  const { isReadOnly, data, onChangeData } = props;

  if (!data) return null;

  return (
    <Container>
      <SBPSector title="담당 심사역 및 조력자" id="request1">
        <FlexColumn style={{ gap: 16 }}>
          <FlexColumn style={{ gap: 8 }}>
            <FlexColumn
              style={{
                padding: 28,
                borderRadius: 4,
                gap: 24,
                backgroundColor: "var(--background-contents01)",
                border: "1px solid var(--gray-300)",
              }}
            >
              <ChoiseSingle
                disabled={isReadOnly}
                list={data.requestAndEvaluation.participant.serviceList || []}
                selectedItem={
                  data.requestAndEvaluation.participant.userSatisfactionService
                }
              />
            </FlexColumn>
          </FlexColumn>
          <FlexColumn style={{ gap: 8 }}>
            <FlexColumn
              style={{
                padding: 28,
                borderRadius: 4,
                gap: 24,
                backgroundColor: "var(--background-contents01)",
                border: "1px solid var(--gray-300)",
              }}
            >
              <ChoiseSingle
                disabled={isReadOnly}
                list={data.requestAndEvaluation.participant.serviceList || []}
                selectedItem={
                  data.requestAndEvaluation.participant.userRequestService
                }
              />
            </FlexColumn>
          </FlexColumn>
          <FlexColumn
            style={{
              padding: 28,
              borderRadius: 4,
              gap: 24,
              backgroundColor: "var(--background-contents01)",
              border: "1px solid var(--gray-300)",
            }}
          >
            <RowTitleBox
              title="요청 사항"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                placeholder={"입력 된 내용 없음"}
                defaultData={
                  data.requestAndEvaluation.participant.requestContens
                }
              />
            </RowTitleBox>
          </FlexColumn>
        </FlexColumn>
      </SBPSector>

      <SBPSector title={`${config.companyName} 만족도`} id="request2">
        <FlexColumn style={{ gap: 8 }}>
          {!props.isReadOnly && (
            <SBPSectorDescription
              text={`직전 분기에 ${config.companyName}가 주주사로 얼마나 만족스러웠는지 별점으로 나타내 주세요.`}
            />
          )}
          <FlexColumn
            style={{
              padding: 28,
              borderRadius: 4,
              gap: 24,
              backgroundColor: "var(--background-contents01)",
              border: "1px solid var(--gray-300)",
            }}
          >
            <RowTitleBox
              title="요청 사항"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                placeholder={"입력 된 내용 없음"}
                defaultData={
                  data.requestAndEvaluation.bluepointPartners.requestContens
                }
              />
            </RowTitleBox>
          </FlexColumn>
        </FlexColumn>
      </SBPSector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
