import React, { useRef } from "react";
import styled from "styled-components";
import { CustomColorLabel } from "../../../../components-2.0v/label/CustomColorLabel";
import { BubbleLabel } from "../../../../components-2.0v/label/BubbleLabel";
import { CircleUserList } from "./CircleUserList";

import { ReactComponent as Reply } from "../../../../assets/image/icon_reply.svg";

import {
  DealFlowPortfolioProps,
  getDealFlowPhaseToWord,
} from "../../interface/DealFlow.interface";
import { getYMDKo } from "../../../../common/commonUtil";

interface Props {
  portfolio: DealFlowPortfolioProps;
  onClickCard?: () => void;
}
export const DealFlowPortfolioCard: React.FC<Props> = (props) => {
  const { portfolio, onClickCard } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const getLabel = () => {
    switch (portfolio.phase.type) {
      case "review":
        return (
          <CustomColorLabel>
            {getDealFlowPhaseToWord(
              portfolio.phase.type,
              portfolio.pfPhase.type === "MANAGE"
            )}
          </CustomColorLabel>
        );
      case "pitching":
        return (
          <CustomColorLabel
            textColor="var(--yellow-600)"
            bgColor="var(--yellow-100)"
          >
            {getDealFlowPhaseToWord(
              portfolio.phase.type,
              portfolio.pfPhase.type === "MANAGE"
            )}
          </CustomColorLabel>
        );
      case "investment":
        return (
          <CustomColorLabel
            textColor="var(--green-700)"
            bgColor="var(--green-100)"
          >
            {getDealFlowPhaseToWord(
              portfolio.phase.type,
              portfolio.pfPhase.type === "MANAGE"
            )}
          </CustomColorLabel>
        );
      case "contract":
        return (
          <CustomColorLabel
            textColor="var(--purple-500)"
            bgColor="var(--purple-100)"
          >
            {getDealFlowPhaseToWord(
              portfolio.phase.type,
              portfolio.pfPhase.type === "MANAGE"
            )}
          </CustomColorLabel>
        );
    }
  };
  return (
    <Container ref={containerRef} onClick={onClickCard}>
      {getLabel()}
      <PortfolioName className="heading-18-sb">
        {portfolio.companyName}
      </PortfolioName>
      <Description className="caption-12-rg">
        {portfolio.description}
      </Description>
      <FlexRow style={{ marginTop: 6, gap: 6 }}>
        <BubbleLabel>{portfolio.acChannel}</BubbleLabel>
        {portfolio.isTips === "EXPECTED" && (
          <BubbleLabel>TIPS 희망</BubbleLabel>
        )}
      </FlexRow>

      <FlexRow
        style={{
          width: "100%",
          marginTop: 24,
          justifyContent: "space-between",
        }}
      >
        <FlexRow style={{ alignItems: "center", gap: 12 }}>
          <CircleUserList
            userList={portfolio.pfParticipant.map((item) => ({
              profileImg: item.acUser.profileImgUrl,
              userName: item.acUser.name,
            }))}
            maxShowUserCount={2}
          />
          <VerticalDivder />
          <span className="caption-12-rg" style={{ color: "var(--text-06)" }}>
            {getYMDKo(portfolio.createdAt)}
          </span>
        </FlexRow>
        <FlexRow style={{ alignItems: "center", gap: 4 }}>
          <Reply />
          <span className="caption-12-rg" style={{ color: "var(--text-06)" }}>
            {portfolio.pfComment.length}
          </span>
        </FlexRow>
      </FlexRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 24px 24px 24px 16px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const PortfolioName = styled.div`
  width: 100%;
  margin-top: 6px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  width: 100%;
  min-height: 40px;
  margin-top: 12px;
  color: var(--text-05);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const VerticalDivder = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-200, #edeeef);
`;
