import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IACompanyMember } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";
import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { getYMD } from "../../../../../../common/commonUtil";
import { CustomTextArea } from "../../table/custom-cell/CustomTextArea";
import {
  fullTimeStatusList,
  registrationStatusList,
  relationshipList,
} from "../../../interface/REI.interface";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IACompanyMember[];
  onDataChange: (data: IACompanyMember[]) => void;
}

export const CompanyMemberTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<IACompanyMember[] | undefined>(
    undefined
  );
  const headers: TableGridHeaderProps[] = [
    {
      header: "성명",
      key: "name",
      width: 122,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="김블루"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.name = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "역할",
      key: "position",
      width: 124,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="CTO"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.position = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "출생년도",
      key: "birthYear",
      width: 100,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1999"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.birthYear = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "학력 및 경력",
      key: "careerOrEducation",
      width: 292,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomTextArea
            isReadOnly={isReadOnly}
            defaultData={data}
            placeholder={`OO대학교 OO학과 학사 졸 (10')\nOO대학교 OO학과 석사 졸 (12')\nOO 선임연구요원 5년\nOO 부대표 2년`}
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.careerOrEducation = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },

    {
      header: "풀타임 여부",
      key: "fullTimeStatus",
      width: 100,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultData={data}
            selectList={fullTimeStatusList}
            placehoder="선택"
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.fullTimeStatus = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "지분율(%)",
      key: "currentStake",
      width: 108,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType={"percent"}
            defaultValue={data}
            transfromPixel={(n) => n}
            onBlur={(value) => {
              if (!dataList) return;
              rowData.currentStake = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "관계",
      key: "relationship",
      width: 180,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultData={data}
            selectList={relationshipList}
            placehoder="선택"
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.relationship = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },

    {
      header: "등기 여부",
      key: "registrationStatus",
      width: 100,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultData={data}
            selectList={registrationStatusList}
            placehoder="선택"
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.registrationStatus = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newData: IACompanyMember = {};
    const newDataList: IACompanyMember[] = [...(dataList || []), newData];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    updateDataList(props.dataList);
  }, [props.dataList]);

  useEffect(() => {
    if (dataList) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
