import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";

import { DefaultButton } from "lattice_core";
import { SelectorProps, TinyUserInfo } from "../../../../../type/data";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { ChipsSelector } from "../../../../../components-2.0v/selector/ChipsSelector";
import {
  FairValueAdminProps,
  FairValueAmountExportProps,
} from "../interface/Portfolio.interface";
import { getTimeToServer } from "../../../../../api/repository/common/CommonRepository";
import { Selector } from "../../structure/components/Selector";
import { getNewDate } from "../../../../../common/commonUtil";
import { FairValueAmountHeader } from "./fair-value-amount-table/FairValueAmountHeader";
import {
  getFairValueByPortfolioId,
  upsertFairValueByPortfolioId,
} from "../../../../../api/repository/admin/AcceleratorRepository";
import { FairValueAmountRow } from "./fair-value-amount-table/FairValueAmountRow";

interface Props {
  defaultYear?: number;
  portfolioId: number;
  paymentDate: string;
  portfolioFairValue: FairValueAdminProps[];
  close: () => void;
  submit: () => void;
}

export const RegisteFairValueModal: React.FC<Props> = (props) => {
  const {
    portfolioId,
    defaultYear,
    paymentDate,
    portfolioFairValue,
    close,
    submit,
  } = props;

  const [years, setYears] = useState<SelectorProps[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(
    defaultYear
  );
  const [fairValueData, setFairValueData] = useState<
    FairValueAmountExportProps[]
  >([]);

  const isDisabled = () => {
    if (!fairValueData || fairValueData.length === 0) return true;
    return (
      fairValueData.filter(
        (item) =>
          !item.amount ||
          !item.acFundAccountId ||
          !item.type ||
          !item.companyName ||
          !item.fundName ||
          !item.portfolioId ||
          !item.year
      ).length > 0
    );
  };

  const getFairValueData = async () => {
    if (!selectedYear) return;

    const result = await getFairValueByPortfolioId(portfolioId, selectedYear);
    const transformData: FairValueAmountExportProps[] = [];
    if (result) {
      result.acFundAccount.forEach((fund, index) => {
        transformData.push({
          year: `${selectedYear}`,
          acFundAccountId: `${fund.acFundAccountId}`,
          type: `${fund.type}`,
          portfolioId: `${portfolioId}`,
          companyName: result.companyName,
          fundName: fund.nameKo,
          amount: fund.amount ? `${fund.amount}` : "",
        });
      });
    }
    console.log(transformData);
    setFairValueData([...transformData]);
  };

  const onUpsertFairValueData = async () => {
    if (!fairValueData) return;

    const result = await upsertFairValueByPortfolioId(
      portfolioId,
      fairValueData
    );
    if (result && result.success) {
      submit();
    }
  };

  const initYears = async () => {
    const serverTime = await getTimeToServer();
    const firstYear = getNewDate(paymentDate).getFullYear();
    if (serverTime) {
      const yearData: SelectorProps[] = [];
      for (let i = serverTime.getFullYear() - 1; i >= firstYear; i--) {
        yearData.push({ id: i, text: `${i}`, data: i });
      }
      const existYear = portfolioFairValue.map((item) => item.year);
      setYears(yearData.filter((item) => !existYear.includes(item.id)));
    } else {
    }
  };

  useEffect(() => {
    if (!selectedYear) return;
    getFairValueData();
  }, [selectedYear]);

  useEffect(() => {
    initYears();
  }, []);

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          {defaultYear ? "공정가치 평가금액 수정" : "공정가치 평가금액 추가"}
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        <RowTitleBox title="연도" titleWidth={90} style={{}}>
          {defaultYear ? (
            <div className="body-14-rg" style={{ marginTop: 9 }}>
              {selectedYear}
            </div>
          ) : (
            <Selector
              selectList={years}
              placehoder="연도선택"
              width={200}
              defaultData={years.find((item) => item.data === selectedYear)}
              emptyMsg="추가할 수 있는 연도가 없습니다."
              onChangeData={(data) => setSelectedYear(data.data)}
            />
          )}
        </RowTitleBox>

        {fairValueData.length > 0 && (
          <TableContainer>
            <FairValueAmountHeader />
            {fairValueData.map((item, index) => {
              return (
                <FairValueAmountRow
                  key={index}
                  data={item}
                  onChangeRowData={(rowData) => {
                    const newData = fairValueData.map((item) => {
                      if (
                        item.year === rowData.year &&
                        item.acFundAccountId === rowData.acFundAccountId &&
                        item.portfolioId === rowData.portfolioId
                      ) {
                        return rowData;
                      }
                      return item;
                    });
                    setFairValueData([...newData]);
                  }}
                />
              );
            })}
          </TableContainer>
        )}
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={close}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-purple)"}
            hoverColor={"var(--purple-600)"}
            text={"확인"}
            disabled={isDisabled()}
            onClick={onUpsertFairValueData}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 800px;
  height: 450px;
  min-height: 450px;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  padding: 28px 24px;
  gap: 24px;
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
