import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DocumentLayout from "../../portfolio/create-evaluation-document/DocumentLayout";
import useEvaluationConfirmTask from "./hook/useEvaluationConfirmTask";

export const EvaluationConfirmTask = () => {
  const [iaDocument, reportAccount, navigate] = useEvaluationConfirmTask();

  return (
    <DocumentLayout
      title="투자심사위원 확정"
      onClickExit={() => {
        navigate("/mytask", { replace: true });
      }}
    >
      <Container>
        <Wrapper></Wrapper>
      </Container>
    </DocumentLayout>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;
const Wrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding: 60px 0px;
`;
