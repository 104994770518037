import React, { useEffect, useRef, useState } from "react";
import { OnlyNum, OnlyFloat, RemoveComma } from "../../../common/commonUtil";
import { AddComma } from "../../../common/commonUtil";

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<(() => void) | undefined>(undefined);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current && savedCallback.current();
    };
    const timerId = setInterval(tick, delay);

    return () => clearInterval(timerId);
  }, [delay]);
};

export default useInterval;
