import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../../components/navigation/NavigationLayout";
import MobileFundAccountDetail from "./mobile/MobileFundAccountDetail";
import DeskFundAccountDetail from "./desktop/DeskFundAccountDetail";

const FundAccountDetail = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobileFundAccountDetail /> : <DeskFundAccountDetail />}
    </NavigationLayout>
  );
};
export default FundAccountDetail;
