import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import {
  EvaluationListProps,
  InvestmentStatus,
} from "../../interface/Evaluation.interface";
import { AddComma } from "../../../../../common/commonUtil";

interface Props {
  evaluationCardInfo: EvaluationListProps;
  onClickCard?: () => void;
}
export const EvaluationCard: React.FC<Props> = (props) => {
  const { evaluationCardInfo, onClickCard } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const getLabel = () => {
    switch (evaluationCardInfo.status) {
      case InvestmentStatus.OPEN:
        return (
          <CustomColorLabel
            bgColor="var(--orange-500)"
            textColor="var(--text-02)"
          >
            심사준비
          </CustomColorLabel>
        );
      case InvestmentStatus.PUBLISH:
        return (
          <CustomColorLabel
            bgColor="var(--yellow-500)"
            textColor="var(--text-02)"
          >
            심사대기
          </CustomColorLabel>
        );
      case InvestmentStatus.VOTING:
        return (
          <CustomColorLabel
            bgColor="var(--green-500)"
            textColor="var(--text-02)"
          >
            심사중
          </CustomColorLabel>
        );
      case InvestmentStatus.CONFIRM:
        return (
          <CustomColorLabel
            bgColor="var(--blue-400)"
            textColor="var(--text-02)"
          >
            심사완료
          </CustomColorLabel>
        );
      case InvestmentStatus.REQUEST:
        return (
          <CustomColorLabel
            bgColor="var(--purple-400)"
            textColor="var(--text-02)"
          >
            계약중
          </CustomColorLabel>
        );
      case InvestmentStatus.CONTRACT:
        return (
          <CustomColorLabel
            bgColor="var(--blue-500)"
            textColor="var(--text-02)"
          >
            계약완료
          </CustomColorLabel>
        );
      case InvestmentStatus.REJECT:
        return (
          <CustomColorLabel bgColor="var(--red-500)" textColor="var(--text-02)">
            반려됨
          </CustomColorLabel>
        );
    }
  };
  return (
    <Container ref={containerRef} onClick={onClickCard}>
      {getLabel()}
      <CardTitle className="heading-18-md">
        {evaluationCardInfo.title}
      </CardTitle>
      <Divider />

      <FlexRow style={{ width: "100%" }}>
        <FlexColumn style={{ flex: 1 }}>
          <div className="body-14-rg" style={{ color: "var(--text-05)" }}>
            투자 전 기업 가치
          </div>
          <div className="body-14-md">
            {AddComma(evaluationCardInfo.previousValue)}원
          </div>
        </FlexColumn>
        <FlexColumn style={{ flex: 1 }}>
          <div className="body-14-rg" style={{ color: "var(--text-05)" }}>
            총 투자 금액
          </div>
          <div className="body-14-md">
            {AddComma(evaluationCardInfo.totalInvestmentAmount)}원
          </div>
        </FlexColumn>
      </FlexRow>

      <FlexColumn style={{ marginTop: 18 }}>
        <div className="body-14-rg" style={{ color: "var(--text-05)" }}>
          투자 재원
        </div>
        <FlexRow style={{ marginTop: 2, gap: 4 }}>
          {evaluationCardInfo.fundAccounts.map((item) => {
            return (
              <CustomColorLabel
                bgColor="var(--gray-300)"
                textColor="var(--text-03)"
              >
                {item}
              </CustomColorLabel>
            );
          })}
        </FlexRow>
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  flex-grow: 0;
  flex-basis: 384px;

  height: 257px;
  min-height: 257px;
  max-height: 257px;

  padding: 28px 24px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const CardTitle = styled.div`
  width: 100%;
  margin-top: 8px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const UserNames = styled.div`
  width: 100%;
  height: 44px;
  color: var(--text-01);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: var(--divider-100);
  margin-top: 16px;
  margin-bottom: 16px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
