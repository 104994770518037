import React, { useEffect } from "react";
import Styled, { css } from "styled-components";
import BasicButton from "../button/BasicButton";
import DefaultButton from "../button/DefaultButton";

interface Props {
  type?: "confirm" | "alert";
  title?: string;
  message: string;
  positiveTitle?: string;
  negativeTitle?: string;
  onClickPositiveBtn?: () => void;
  onClickNegativeBtn?: () => void;
  onCloseModal?: () => void;
}
const ConfirmModal: React.FC<Props> = ({
  type = "confirm",
  title = "확인하기",
  message,
  positiveTitle = "확인",
  negativeTitle = "취소",
  onClickPositiveBtn,
  onClickNegativeBtn,
  onCloseModal,
}) => {
  const onKeyDown = (event: KeyboardEvent) => {
    console.log("CONFIRM MODAL");

    event.preventDefault();
    if (event.key === "Enter") {
      onClickPositiveBtn && onClickPositiveBtn();
      onCloseModal && onCloseModal();
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <Container>
      <Header>
        <div className="heading-18-sb">{title}</div>
      </Header>
      <Body className="body-14-rg" style={{ textAlign: "center" }}>
        {message.split("|").map((item, index) => (
          <span key={index}>{item}</span>
        ))}
      </Body>
      <Footer>
        {type === "confirm" ? (
          <>
            <DefaultButton onClick={onClickNegativeBtn} type="text">
              {negativeTitle}
            </DefaultButton>
            <DefaultButton onClick={onClickPositiveBtn} type="submit">
              {positiveTitle}
            </DefaultButton>
          </>
        ) : (
          <DefaultButton onClick={onClickPositiveBtn} type="submit">
            {positiveTitle}
          </DefaultButton>
        )}
      </Footer>
    </Container>
  );
};

const Container = Styled.div`
  width: 480px;
  height: fit-content;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #828282;
`;

const Header = Styled.div(
  () => css`
    width: 100%;
    height: 56px;
    min-height: 56px;
    max-height: 56px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;

    border-bottom: 1px solid var(--grey_20);
  `
);
const Body = Styled.div(
  () => css`
    flex-grow: 1;
    padding: 16px;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `
);
const Footer = Styled.div(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    padding: 16px 24px;
  `
);

export default ConfirmModal;
