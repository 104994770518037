import React, {
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import * as api from "../../../api/api";
import { trnasfromBase64ToObject } from "../../../common/commonUtil";

const usePDFViewer = () => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [pdfKey, updatePdfKey] = useState<string | undefined>(undefined);
  const [pdfUrl, updatePdfUrl] = useState<string | undefined>(undefined);
  const [pdfPages, setPdfPages] = useState<number[]>([]);
  const rootRef = useRef<HTMLDivElement>(null);
  const [refArr, setRefArr] = useState<React.RefObject<HTMLDivElement>[]>([]);
  const [downloadAble, setDownloadAble] = useState<boolean>(true);
  const [sideRefArr, setSideRefArr] = useState<
    React.RefObject<HTMLDivElement>[]
  >([]);
  const refTops = useRef<{ sY: number; eY: number }[] | undefined>(undefined);

  const checkPdfKey = () => {
    const key = location.pathname.split("/")[2];
    const share =
      location.pathname.split("/")[3] !== undefined &&
      location.pathname.split("/")[3] === "share";
    const object = trnasfromBase64ToObject(key);
    updatePdfKey(object.key);
    setDownloadAble(!share);
  };

  const getPDFFile = async () => {
    if (pdfKey === undefined) return;

    const fileUrl = (
      await axios.get(api.getFile(), {
        params: {
          key: pdfKey,
        },
      })
    ).data;
    if (fileUrl) {
      updatePdfUrl(fileUrl);
    }
  };

  const onScrollHandler = (e: any) => {
    console.log("scroll");
    if (!rootRef.current) return;

    if (refTops.current === undefined) {
      const tops = refArr.map((item, index) => {
        if (index === 0) {
          return {
            sY: 0,
            eY:
              (item.current?.offsetTop || 0) +
              (item.current?.clientHeight || 0),
          };
        }
        return {
          sY: item.current?.offsetTop || 0,
          eY:
            (item.current?.offsetTop || 0) + (item.current?.clientHeight || 0),
        };
      });
      refTops.current = tops;
    }

    const index = refTops.current.findIndex(
      (item) => item.sY <= e.target.scrollTop && item.eY >= e.target.scrollTop
    );

    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      setPageNumber(pdfPages.length);
    } else {
      setPageNumber(index + 1);
    }
  };

  useEffect(() => {
    checkPdfKey();
  }, []);

  useEffect(() => {
    getPDFFile();
  }, [pdfKey]);

  useEffect(() => {
    const target = sideRefArr.find((i, index) => index === pageNumber - 1);
    if (target) {
      target.current?.scrollIntoView();
    }
  }, [pageNumber]);

  useEffect(() => {
    if (numPages === 0) return;

    const arr: number[] = [];
    const rArr: React.RefObject<HTMLDivElement>[] = [];
    const srArr: React.RefObject<HTMLDivElement>[] = [];
    for (let i = 0; i < numPages; i++) {
      arr.push(i + 1);
      rArr.push(createRef<HTMLDivElement>());
      srArr.push(createRef<HTMLDivElement>());
    }
    setPdfPages([...arr]);
    setRefArr([...rArr]);
    setSideRefArr([...srArr]);
  }, [numPages]);

  useEffect(() => {
    window.addEventListener("scroll", onScrollHandler);
    return () => {
      window.removeEventListener("scroll", onScrollHandler);
    };
  }, []);

  return [
    rootRef,
    pdfUrl,
    pdfPages,
    pageNumber,
    downloadAble,
    refArr,
    sideRefArr,
    setPageNumber,
    setNumPages,
    onScrollHandler,
  ] as const;
};

export default usePDFViewer;
