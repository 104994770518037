import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BoxComponent } from "../../../../../components-2.0v/box/BoxComponent";

import { BusinessSummary } from "./BusinessSummary";
import { BusinessDetail } from "./BusinessDetail";
import { BusinessComment } from "./BusinessComment";
import {
  BusinessInfoProps,
  BusinessInfoTab,
} from "../../interface/PortfolioInfo.interface";
import { PortfolioPhase } from "../../../portfolio-detail/interface/PortfolioNavigation.interface";
import { LoginInfo } from "../../../../../type/data";

interface Props {
  myInfo: LoginInfo;
  portfolioPhase: PortfolioPhase;
  businessInfo: BusinessInfoProps;
  updateCommentId?: number;
  emptyData?: string[];
  sendComment: (data: string, callback: () => void) => void;
  updateComment: (pfCommentId: number, data: string) => void;
  deleteComment: (pfCommentId: number) => void;
  setUpdateCommentId: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const BusinessInfo: React.FC<Props> = (props) => {
  const {
    myInfo,
    businessInfo,
    portfolioPhase,
    emptyData = [],
    sendComment,
    updateComment,
    deleteComment,
    updateCommentId,
    setUpdateCommentId,
  } = props;
  const HEADER_OFFSET = 100;
  const [selectedTab, updateSelectedTab] = useState<BusinessInfoTab>(
    BusinessInfoTab.SUMMARY
  );

  const onScroll = (e: any) => {
    requestAnimationFrame(() => {
      const businessSummary = document.querySelector("#business__summary");
      const businessDetail = document.querySelector("#business__detail");
      const businessComment = document.querySelector("#business__comment");
      const scrollTop = e.target.scrollTop;
      let currentTab = BusinessInfoTab.SUMMARY;

      if (businessSummary) {
        const targetRect = businessSummary.getBoundingClientRect();
        const elementPosition = targetRect.top;
        const offsetPosition = elementPosition + scrollTop - HEADER_OFFSET;
        currentTab = BusinessInfoTab.SUMMARY;
      }

      if (businessDetail) {
        const targetRect = businessDetail.getBoundingClientRect();
        const elementPosition = targetRect.top;
        const offsetPosition = elementPosition + scrollTop - HEADER_OFFSET;
        if (scrollTop >= offsetPosition) {
          currentTab = BusinessInfoTab.DETAIL;
        }
      }

      if (businessComment) {
        const targetRect = businessComment.getBoundingClientRect();
        const elementPosition = targetRect.top;
        const offsetPosition = elementPosition + scrollTop - HEADER_OFFSET;
        if (scrollTop >= offsetPosition) {
          currentTab = BusinessInfoTab.COMMENT;
        }
      }
      updateSelectedTab(currentTab);
    });
  };

  const onClickTab = (clickTab: BusinessInfoTab) => {
    const rootScroll = document.querySelector("#root__component");
    if (!rootScroll) return;

    switch (clickTab) {
      case BusinessInfoTab.SUMMARY: {
        const businessSummary = document.querySelector("#business__summary");
        if (businessSummary) {
          const targetRect = businessSummary.getBoundingClientRect();
          const elementPosition = targetRect.top;
          const offsetPosition =
            elementPosition + rootScroll.scrollTop - HEADER_OFFSET;
          rootScroll.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
        break;
      }
      case BusinessInfoTab.DETAIL: {
        const businessDetail = document.querySelector("#business__detail");

        if (businessDetail) {
          const targetRect = businessDetail.getBoundingClientRect();
          const elementPosition = targetRect.top;
          const offsetPosition =
            elementPosition + rootScroll.scrollTop - HEADER_OFFSET;
          rootScroll.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
        break;
      }
      case BusinessInfoTab.COMMENT: {
        const businessComment = document.querySelector("#business__comment");
        if (businessComment) {
          const targetRect = businessComment.getBoundingClientRect();
          const elementPosition = targetRect.top;
          const offsetPosition =
            elementPosition + rootScroll.scrollTop - HEADER_OFFSET;
          rootScroll.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
        break;
      }
    }

    // updateSelectedTab(clickTab);
  };

  useEffect(() => {
    const rootScroll = document.querySelector("#root__component");
    if (!rootScroll) return;

    rootScroll.addEventListener("scroll", onScroll);
    return () => {
      rootScroll.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <BoxComponent style={{ padding: 0, gap: 0, marginBottom: 32 }}>
      <TabContainer className="heading-16-sb">
        <TabItem
          className={selectedTab === BusinessInfoTab.SUMMARY ? "selected" : ""}
          onClick={() => onClickTab(BusinessInfoTab.SUMMARY)}
        >
          사업 요약
        </TabItem>
        {portfolioPhase === "EXCAVATION" && (
          <TabItem
            className={selectedTab === BusinessInfoTab.DETAIL ? "selected" : ""}
            onClick={() => onClickTab(BusinessInfoTab.DETAIL)}
          >
            사업 상세{" "}
          </TabItem>
        )}
        <TabItem
          className={selectedTab === BusinessInfoTab.COMMENT ? "selected" : ""}
          onClick={() => onClickTab(BusinessInfoTab.COMMENT)}
        >
          사업 코멘트
        </TabItem>
      </TabContainer>
      <BusinessSummary
        businessInfo={businessInfo}
        portfolioPhase={portfolioPhase}
        emptyData={emptyData}
      />
      <Divider />
      {portfolioPhase === "EXCAVATION" && (
        <BusinessDetail businessInfo={businessInfo} />
      )}
      <Divider />
      <BusinessComment
        myInfo={myInfo}
        businessInfo={businessInfo}
        updateCommentId={updateCommentId}
        sendComment={sendComment}
        updateComment={updateComment}
        deleteComment={deleteComment}
        setUpdateCommentId={setUpdateCommentId}
      />
    </BoxComponent>
  );
};

const TabContainer = styled.div`
  position: sticky;
  width: 100%;
  height: 59px;
  min-height: 59px;
  max-height: 59px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 17px 28px 0px 28px;
  border-bottom: 1px solid var(--gray-200);
  background-color: #ffffff;
  top: 0;
  z-index: 3;
`;

const TabItem = styled.div`
  width: fit-content;
  height: 100%;

  color: var(--text-05, #96999f);
  cursor: pointer;

  &.selected {
    color: var(--primary-blue);
    border-bottom: 2px solid var(--primary-blue);
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-200);
`;
