import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FinancialStatementInput } from "../FinancialStatementInput";
import { FinancialStatementFullProps } from "../../../../../../../type/data";
import { RemoveComma } from "../../../../../../../common/commonUtil";
import { showDeskToast } from "../../../../../../../2.0lattice/toast";

interface Props {
  data: FinancialStatementFullProps;
  currency: string;
  onChangeData: (data: FinancialStatementFullProps) => void;
}
export const DebtCapitalPage: React.FC<Props> = (props) => {
  const { data, onChangeData, currency } = props;
  return (
    <Container>
      <FinancialStatementInput
        readonly
        title="부채총계"
        value={
          data.totalLiabilities !== undefined
            ? `${data.totalLiabilities}`
            : undefined
        }
        currency={currency}
      />
      <FinancialStatementInput
        title="유동부채"
        value={
          data.currentLiabilities !== undefined
            ? `${data.currentLiabilities}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, currentLiabilities: transValue });
        }}
      />
      <FinancialStatementInput
        title="비유동부채"
        value={
          data.nonCurrentLiabilities !== undefined
            ? `${data.nonCurrentLiabilities}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, nonCurrentLiabilities: transValue });
        }}
      />
      <FinancialStatementInput
        title="자본총계"
        readonly
        value={
          data.totalEquity !== undefined ? `${data.totalEquity}` : undefined
        }
        currency={currency}
      />
      <FinancialStatementInput
        title="자본금"
        value={data.capital !== undefined ? `${data.capital}` : undefined}
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, capital: transValue });
        }}
      />
      <FinancialStatementInput
        title="자본잉여금"
        value={
          data.capitalSurplus !== undefined
            ? `${data.capitalSurplus}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, capitalSurplus: transValue });
        }}
      />
      <FinancialStatementInput
        title="자본조정"
        value={
          data.capitalAdjustment !== undefined
            ? `${data.capitalAdjustment}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, capitalAdjustment: transValue });
        }}
      />
      <FinancialStatementInput
        title="기타포괄손익누계액"
        value={
          data.accumulatedOtherComprehensiveIncome !== undefined
            ? `${data.accumulatedOtherComprehensiveIncome}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({
            ...data,
            accumulatedOtherComprehensiveIncome: transValue,
          });
        }}
      />
      <FinancialStatementInput
        title="이익잉여금"
        value={
          data.retainedEarnings !== undefined
            ? `${data.retainedEarnings}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          if (data.retainedEarnings === transValue) return;

          onChangeData({
            ...data,
            retainedEarnings: transValue,
            accumulatedDeficit: 0,
          });

          showDeskToast(
            "success",
            "이익잉여금을 입력하였습니다. 결손금은 0처리 됩니다.",
            "retainedEarnings"
          );
        }}
      />
      <FinancialStatementInput
        title="결손금"
        value={
          data.accumulatedDeficit !== undefined
            ? `${data.accumulatedDeficit}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          if (data.accumulatedDeficit === transValue) return;

          onChangeData({
            ...data,
            accumulatedDeficit: transValue,
            retainedEarnings: 0,
          });

          showDeskToast(
            "success",
            "결손금을 입력하였습니다.이익잉여금은 0처리 됩니다.",
            "accumulatedDeficit"
          );
        }}
      />
      <FinancialStatementInput
        title="부채와자본총계"
        readonly
        value={
          data.totalLiabilitiesEquity !== undefined
            ? `${data.totalLiabilitiesEquity}`
            : undefined
        }
        currency={currency}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--gray-500);
  background: var(--light-background-contents-01, #fff);
  gap: 8px;
`;
