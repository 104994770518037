import { DateTime, UserInfo } from "../../../../type/data";
import { DealFlowStatus } from "../../portfolio-detail/interface/PortfolioNavigation.interface";

export type QuestionType =
  | "short"
  | "long"
  | "single"
  | "multiple"
  | "linear"
  | "title"
  | "image"
  | "youtube";

export type PitchingChangeDataType =
  | "preEvaluationDate"
  | "preEvaluationInviteUser"
  | "investmentInfo"
  | "companyInfo";

export enum PitchingStatus {
  OPEN = 1,
  PUBLISH = 2,
  PITCHING = 3,
  FINISH = 4,
}

export interface PrePitchingData {
  status: PitchingStatus;
  preEvaluationId?: number | undefined;
  preEvaluationDate?: string | undefined;
  preEvaluationData?: SectionType[] | undefined;
  preEvaluationResult?: { acUserId: number }[];
  preEvaluationInviteUser?: UserInfo[];
  pfPreEvaluationResult: PrePichtingResult[];
  createdAt: string;
}

export interface PrePichtingResult {
  pfPreEvaluationResultId: number;
  templateJson: {
    preEvaluationData: SectionType[];
  };
  createdAt: string;
  updatedAt: string;
  acUser: {
    acUserId: number;
    name: string;
    profileImgKey?: string;
    profileImgUrl?: string;
    acStructure: {
      acStructureId: number;
      name: string;
    };
  };
}

export interface SectionType {
  title?: string;
  description?: string;
  investmentInfo?: string;
  companyInfo?: string;

  list?: QuestionItemType[];
}

export interface QuestionItemType {
  type: QuestionType;
  title: string;

  isRequired?: boolean;
  description?: string;
  column?: ColumnType[];
  hasOtherOpinion?: boolean;
  minRange?: number;
  minRangeTitle?: string;
  maxRange?: number;
  maxRangeTitle?: string;

  imageData?: ImageType;
  youtubeData?: YoutubeType;

  answer?: undefined | number | ColumnType | ColumnType[] | string;
  otherOpinion?: OtherOpinion;
  // descriptionImg: string;
}

export interface ColumnType {
  title: string;
  isSelected: boolean;
  // Todo 나중에 확장시
  // isImage?: boolean;
  // imgUrl?: string;
}

export interface OtherOpinion {
  opinion: string;
  isSelected: boolean;
}
export interface ImageType {
  imageUrl?: string;
  width?: number;
  ratio?: number;
}
export interface YoutubeType {
  videoId?: string;
  thumbNail?: string;
  width?: number;
  ratio?: number;
}

export interface PitchingResultType {
  title?: string;
  sectionNum?: number;
  description?: string;
  investmentInfo?: string;
  companyInfo?: string;

  data?: ResultType[];
}

export interface ResultType {
  type: QuestionType;
  title: string;
  column?: ColumnType[];
  hasOtherOpinion?: boolean;
  otherOpinion?: ResultOtherOpinion[];

  minRange?: number;
  minRangeTitle?: string;
  maxRange?: number;
  maxRangeTitle?: string;

  answer?:
    | TextAnswerProps[]
    | { id: string; label: string; value: number }[]
    | { index: string; value: number }[];
}

export interface TextAnswerProps {
  acUser: ResultUserInfo;
  text: string;
}

export interface ResultUserInfo {
  acUserId: number;
  name: string;
  profileImgKey?: string;
  profileImgUrl?: string;
}

export interface ResultOtherOpinion {
  acUser: ResultUserInfo;
  otherOpinion: OtherOpinion;
}

export interface SummaryFinanceInfoProps {
  id: number;
  year: number;
  quarterNum: number;
  revenue: number | null;
  operatingProfit: number | null;
  netIncome: number | null;
  totalAssets: number | null;
  totalLiabilities: number | null;
  totalEquity: number | null;
}

export interface EmployeeHistoryProps {
  id: number;
  year: number;
  quarterNum: number;
  total: number | null;
  entry: number | null;
  resignation: number | null;
  directorEntry?: string | null;
  directorResignation?: string | null;
}

export enum ValueChangeType {
  ESTABLISH = 1, // 설립자본금
  INCREASE = 2, // 유상증자
  REDUCTION = 3, // 유상감자
  SAFE = 4, // 세이프
}

export interface CompanyValueHistoryProps {
  id: number;
  type: ValueChangeType;
  value: number;
  date: string;
  investmentAmount: number | null;
}
