import React from "react";
import styled from "styled-components";

interface Props {
  type: "increase" | "decrease";
  disabled?: boolean;
  onClick: () => void;
}

const BtnScale: React.FC<Props> = (props) => {
  return (
    <Container
      onClick={() => !props.disabled && props.onClick && props.onClick()}
    >
      <Divider className={props.disabled ? "disabled" : ""} />
      {props.type === "increase" && (
        <Divider className={`vertical ${props.disabled ? "disabled" : ""}`} />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  width: 34px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #edeeef;
  box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
  border-radius: 4px;

  transition: all 0.3s ease;

  cursor: pointer;

  :hover {
    background: #f3f4f4;
  }
`;

const Divider = styled.div`
  position: absolute;
  width: 11px;
  height: 1px;
  top: 50%;
  left: 50%;
  border-radius: 1px;
  background-color: #1f2022;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;

  &.vertical {
    transform-origin: center center;
    transform: rotate(90deg) translate(-5%, 5px);
  }

  &.disabled {
    background-color: #d5d6d8;
  }
`;

export default BtnScale;
