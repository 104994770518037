import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

import styled from "styled-components";
import {
  DBTotalSearchPortfolio,
  DPBPTotalSearch,
} from "../../../../../../type/data";

import DatabaseGrid, { DatabaseGridHeaderProps } from "../../grid/DatabaseGrid";
import PortfolioParticipant from "../../PortfolioParticipant";
import DBPortfolioPhase from "../../DBPortfolioPhase";
import { TableGridHeaderProps } from "../../table/TableGrid";
import { TableProps, TableVirtuoso, VirtuosoHandle } from "react-virtuoso";
import useResize from "../../../../../../2.0lattice/useResize";
import TableHeader from "../../table/TableHeader";
import TableRow from "../../table/TableRow";
interface Props {
  resultList: DPBPTotalSearch;
  onPortfolioModal: (portfolio: DBTotalSearchPortfolio) => void;
}

const TotalSearchDatabaseGrid: React.FC<Props> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [rootWidth, setRootWidth] = useState<number>(0);
  const [screenRate, transfromPixel] = useResize();

  const header: TableGridHeaderProps[] = [
    {
      isRequired: true,
      header: "상태",
      key: "pfPhase",
      width: 100,
      customCell: (data) => {
        // console.log(data);
        if (!data) return <span>-</span>;
        return <DBPortfolioPhase phase={data} />;
      },
    },
    {
      isRequired: true,
      header: "기업명",
      key: "companyName",
      width: 240,
      customCell: (data) => {
        if (!data) return <span style={{ textAlign: "center" }}>-</span>;
        return <span>{data}</span>;
      },
    },
    {
      isRequired: true,
      header: "산업기술분류",
      key: "pfIndustrialTech",
      width: 240,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span>
            {data
              .map((item: any) => `#${item.acIndustrialTech.name}`)
              .join(", ")}
          </span>
        );
      },
    },
    {
      isRequired: true,
      header: "아이템 요약",
      key: "description",
      width: -1,
      customCell: (data) => {
        if (!data) return <span style={{ textAlign: "center" }}>-</span>;
        return (
          <span
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {data.replaceAll("\n", "")}
          </span>
        );
      },
    },
    {
      isRequired: true,
      header: "담당자(조력자)",
      key: "pfParticipant",
      width: 200,
      customCell: (data) => {
        console.log(data);
        if (!data) return <span>-</span>;
        return <PortfolioParticipant participants={data} />;
      },
    },
    {
      isRequired: true,
      header: "발굴채널",
      key: "acChannel",
      width: 150,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return <span>{data.name}</span>;
      },
    },
  ];

  const MyTable: React.FC<
    PropsWithChildren<TableProps & { context?: any }>
  > = ({ style, ...props }) => (
    <table
      {...props}
      style={{
        ...style,
        width: "100%",
      }}
    />
  );

  useEffect(() => {
    if (containerRef.current) {
      setRootWidth(containerRef.current.clientWidth);
    }
  }, [containerRef.current]);

  return (
    <Container ref={containerRef} className="scroll__invisible">
      <TableVirtuoso
        ref={virtuoso}
        className={`on-scrollbar`}
        style={{ width: "calc(100%)", height: "100%" }}
        components={{
          Table: MyTable,
        }}
        onScroll={(e) => {
          console.log("scrolling 중~~~");
        }}
        data={props.resultList.portfolio.map((item, index) => {
          return {
            id: index,
            identificationNumber: item.portfolioId || 0,
            data: item,
          };
        })}
        fixedHeaderContent={() => {
          return (
            <TableHeader
              headers={header}
              rootWidth={rootWidth}
              transfromPixel={transfromPixel}
            />
          );
        }}
        itemContent={(index, data) => {
          return (
            <TableRow
              key={index}
              rootWidth={rootWidth}
              headerData={header}
              rowData={data}
              transfromPixel={transfromPixel}
              onClickRow={() => {
                console.log(data);
                props.onPortfolioModal(data.data);
              }}
            />
          );
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export default TotalSearchDatabaseGrid;
