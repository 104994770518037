import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deletePortfolioComment,
  getPortfolioDetailInfo,
  modifyPortfolioComment,
  sendPortfolioComment,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import { PortfolioInfoProps } from "../interface/PortfolioInfo.interface";
import {
  getMyInfo,
  getPortfolioIdInLocation,
  getTransformedId,
} from "../../../../common/commonUtil";
import { LoginInfo } from "../../../../type/data";

const usePortfolioInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const portfolioId = getPortfolioIdInLocation(location);

  const [portfolioInfo, setPortfolioInfo] = useState<
    PortfolioInfoProps | undefined
  >(undefined);

  const myInfo = useRef<LoginInfo>(getMyInfo());
  const [updateCommentId, setUpdateCommentId] = useState<number | undefined>(
    undefined
  );

  const goToModify = () => {
    const to = `/portfolio/${getTransformedId(portfolioId)}/info-modify`;
    navigate(to);
  };

  const sendComment = async (data: string, callback: () => void) => {
    console.log(data);
    const commentResult = await sendPortfolioComment(portfolioId, data);
    if (commentResult) {
      callback();
      initPortfolioInfo();
    }
  };

  const updateComment = async (pfCommentId: number, data: string) => {
    const commentResult = await modifyPortfolioComment(pfCommentId, data);
    if (commentResult) {
      initPortfolioInfo();
      setUpdateCommentId(undefined);
    }
  };

  const deleteComment = async (pfCommentId: number) => {
    const commentResult = await deletePortfolioComment(pfCommentId);
    if (commentResult) {
      initPortfolioInfo();
      setUpdateCommentId(undefined);
    }
  };

  const initPortfolioInfo = async () => {
    try {
      const result = await getPortfolioDetailInfo(portfolioId);
      if (result) {
        console.log(result);
        setPortfolioInfo({ ...result });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const init = async () => {
    console.log(portfolioId);
    await initPortfolioInfo();
  };

  useEffect(() => {
    console.log(location.pathname);
    init();
  }, [location.pathname]);

  return [
    myInfo.current,
    portfolioInfo,
    updateCommentId,
    setUpdateCommentId,
    sendComment,
    updateComment,
    deleteComment,
    goToModify,
  ] as const;
};

export default usePortfolioInfo;
