import axios from "axios";
import * as api from "../../api";
import {
  BlueCardInfo,
  DBBPAccelerating,
  DBBPAlumni,
  DBBPDamaged,
  DBBPTotalSearch,
  DBSPAccelerating,
  DBSPAlumni,
  DBSPDamaged,
  DBSPTotalSearch,
  DPBPAccelerating,
  DPBPAlumni,
  DPBPDamaged,
  DPBPTotalSearch,
} from "../../../type/data";
import { getYMD } from "../../../common/commonUtil";
import { DatabaseSearchData } from "../../../router/database/desktop/DeskDatabase";

export const getDatabaseSearchData = async (): Promise<
  DatabaseSearchData | undefined
> => {
  const result = await axios.get(api.getDatabaseSearchData());
  if (result) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const getDatabaseTotalSearch = async (
  searchRequirement: DBBPTotalSearch
): Promise<{
  success: boolean;
  data?: DPBPTotalSearch;
  msg?: string;
}> => {
  try {
    const param: DBSPTotalSearch = {
      pageIndex: searchRequirement.pageIndex,
      dataPerPage: searchRequirement.dataPerPage,
      searchWord: searchRequirement.searchWord,
      acIndustrialTechId: searchRequirement.acIndustrialTech
        ?.map((item) => `${item.acIndustrialTechId}`)
        .join(","),
      acUserId: searchRequirement.acUser
        ?.map((item) => `${item.acUserId}`)
        .join(","),
      acFundAccountId: searchRequirement.acFundAccount
        ?.map((item) => `${item.acFundAccountId}`)
        .join(","),
      acChannelId: searchRequirement.acChannel
        ?.map((item) => `${item.acChannelId}`)
        .join(","),
      pfPhaseId: searchRequirement.pfPhase
        ?.map((item) => `${item.pfPhaseId}`)
        .join(","),
    };

    const result = await axios.get(api.getDatabaseTotalSearch(), {
      params: param,
    });
    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve({ success: true, data: result.data })
      );
    }
    return new Promise((resolve) =>
      resolve({ success: false, msg: "알수 없는 에러" })
    );
  } catch (error) {
    return new Promise((resolve) =>
      resolve({ success: false, msg: `${error}` })
    );
  }
};

export const getDatabaseAccelerating = async (
  searchRequirement: DBBPAccelerating
): Promise<{
  success: boolean;
  data?: DPBPAccelerating;
  msg?: string;
}> => {
  try {
    const param: DBSPAccelerating = {
      pageIndex: searchRequirement.pageIndex,
      dataPerPage: searchRequirement.dataPerPage,
      searchWord: searchRequirement.searchWord,
      acIndustrialTechId: searchRequirement.acIndustrialTech
        ?.map((item) => `${item.acIndustrialTechId}`)
        .join(","),
      acUserId: searchRequirement.acUser
        ?.map((item) => `${item.acUserId}`)
        .join(","),
      acFundAccountId: searchRequirement.acFundAccount
        ?.map((item) => `${item.acFundAccountId}`)
        .join(","),
      roundId: searchRequirement.round?.map((item) => `${item.id}`).join(","),
      addr1: searchRequirement.addr1?.join(","),
      minInvestmentYear: `${searchRequirement.minInvestmentYear || ""}`,
      maxInvestmentYear: `${searchRequirement.maxInvestmentYear || ""}`,
      minInvestmentQuarter: `${searchRequirement.minInvestmentQuarter || ""}`,
      maxInvestmentQuarter: `${searchRequirement.maxInvestmentQuarter || ""}`,
      minPurchasePrincipal: `${searchRequirement.minPurchasePrincipal || ""}`,
      maxPurchasePrincipal: `${searchRequirement.maxPurchasePrincipal || ""}`,
      minCompanyValue: `${searchRequirement.minCompanyValue || ""}`,
      maxCompanyValue: `${searchRequirement.maxCompanyValue || ""}`,
      minSalesAmount: `${searchRequirement.minSalesAmount || ""}`,
      maxSalesAmount: `${searchRequirement.maxSalesAmount || ""}`,
      minOwnershipPercentage: `${
        searchRequirement.minOwnershipPercentage || ""
      }`,
      maxOwnershipPercentage: `${
        searchRequirement.maxOwnershipPercentage || ""
      }`,
      minRealizedMultiple: `${searchRequirement.minRealizedMultiple || ""}`,
      maxRealizedMultiple: `${searchRequirement.maxRealizedMultiple || ""}`,
      minUnrealizedMultiple: `${searchRequirement.minUnrealizedMultiple || ""}`,
      maxUnrealizedMultiple: `${searchRequirement.maxUnrealizedMultiple || ""}`,
      sortItem: searchRequirement.sortItem,
      sortType: searchRequirement.sortType,
    };
    console.log(param);

    const result = await axios.get(api.getDatabaseAccelerating(), {
      params: param,
    });
    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve({ success: true, data: result.data })
      );
    }
    return new Promise((resolve) =>
      resolve({ success: false, msg: "알수 없는 에러" })
    );
  } catch (error) {
    return new Promise((resolve) =>
      resolve({ success: false, msg: `${error}` })
    );
  }
};

export const getDatabaseAlumni = async (
  searchRequirement: DBBPAlumni
): Promise<{
  success: boolean;
  data?: DPBPAlumni;
  msg?: string;
}> => {
  try {
    const param: DBSPAlumni = {
      pageIndex: searchRequirement.pageIndex,
      dataPerPage: searchRequirement.dataPerPage,
      searchWord: searchRequirement.searchWord,
      acIndustrialTechId: searchRequirement.acIndustrialTech
        ?.map((item) => `${item.acIndustrialTechId}`)
        .join(","),
      acUserId: searchRequirement.acUser
        ?.map((item) => `${item.acUserId}`)
        .join(","),
      acFundAccountId: searchRequirement.acFundAccount
        ?.map((item) => `${item.acFundAccountId}`)
        .join(","),
      roundId: searchRequirement.round?.map((item) => `${item.id}`).join(","),
      addr1: searchRequirement.addr1?.join(","),
      minInvestmentYear: `${searchRequirement.minInvestmentYear || ""}`,
      maxInvestmentYear: `${searchRequirement.maxInvestmentYear || ""}`,
      minInvestmentQuarter: `${searchRequirement.minInvestmentQuarter || ""}`,
      maxInvestmentQuarter: `${searchRequirement.maxInvestmentQuarter || ""}`,
      minPurchasePrincipal: `${searchRequirement.minPurchasePrincipal || ""}`,
      maxPurchasePrincipal: `${searchRequirement.maxPurchasePrincipal || ""}`,
      minSellPrincipal: `${searchRequirement.minSellPrincipal || ""}`,
      maxSellPrincipal: `${searchRequirement.maxSellPrincipal || ""}`,
      minRealizedMultiple: `${searchRequirement.minRealizedMultiple || ""}`,
      maxRealizedMultiple: `${searchRequirement.maxRealizedMultiple || ""}`,
      minExitDate: `${searchRequirement.minExitDate || ""}`,
      maxExitDate: `${searchRequirement.maxExitDate || ""}`,
      sortItem: searchRequirement.sortItem,
      sortType: searchRequirement.sortType,
    };

    const result = await axios.get(api.getDatabaseAlumni(), {
      params: param,
    });
    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve({ success: true, data: result.data })
      );
    }
    return new Promise((resolve) =>
      resolve({ success: false, msg: "알수 없는 에러" })
    );
  } catch (error) {
    return new Promise((resolve) =>
      resolve({ success: false, msg: `${error}` })
    );
  }
};

export const getDatabaseDamaged = async (
  searchRequirement: DBBPDamaged
): Promise<{
  success: boolean;
  data?: DPBPDamaged;
  msg?: string;
}> => {
  try {
    const param: DBSPDamaged = {
      pageIndex: searchRequirement.pageIndex,
      dataPerPage: searchRequirement.dataPerPage,
      searchWord: searchRequirement.searchWord,
      acIndustrialTechId: searchRequirement.acIndustrialTech
        ?.map((item) => `${item.acIndustrialTechId}`)
        .join(","),
      acUserId: searchRequirement.acUser
        ?.map((item) => `${item.acUserId}`)
        .join(","),
      acFundAccountId: searchRequirement.acFundAccount
        ?.map((item) => `${item.acFundAccountId}`)
        .join(","),
      roundId: searchRequirement.round?.map((item) => `${item.id}`).join(","),
      addr1: searchRequirement.addr1?.join(","),
      minInvestmentYear: `${searchRequirement.minInvestmentYear || ""}`,
      maxInvestmentYear: `${searchRequirement.maxInvestmentYear || ""}`,
      minInvestmentQuarter: `${searchRequirement.minInvestmentQuarter || ""}`,
      maxInvestmentQuarter: `${searchRequirement.maxInvestmentQuarter || ""}`,
      minPurchasePrincipal: `${searchRequirement.minPurchasePrincipal || ""}`,
      maxPurchasePrincipal: `${searchRequirement.maxPurchasePrincipal || ""}`,
      periodType: searchRequirement.periodType,
      minPeriod: `${searchRequirement.minPeriod || ""}`,
      maxPeriod: `${searchRequirement.maxPeriod || ""}`,
    };

    const result = await axios.get(api.getDatabaseDamaged(), {
      params: param,
    });
    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve({ success: true, data: result.data })
      );
    }
    return new Promise((resolve) =>
      resolve({ success: false, msg: "알수 없는 에러" })
    );
  } catch (error) {
    return new Promise((resolve) =>
      resolve({ success: false, msg: `${error}` })
    );
  }
};

export const getDatabaseBluecardInfo = async (
  portfolioIds: number[]
): Promise<{
  success: boolean;
  data?: BlueCardInfo[];
  msg?: string;
}> => {
  try {
    const param: any = {
      portfolioId: `${portfolioIds.join(",")}`,
    };

    const result = await axios.get(api.getBlueCardAndServiceInfo(), {
      params: param,
    });
    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve({
          success: true,
          data: result.data.bluecardInfo.map((item: any): BlueCardInfo => {
            return {
              portfolioId: item.portfolioId,
              companyName: item.companyName,
              ceoName: item.ceoName,
              homepageUrl: item.homepageUrl || "",
              companyImgUrl: item.companyImgUrl || "",
              companyImgKey: item.companyImgKey || "",
              thumbnailImgUrl: item.thumbnailImgUrl || "",
              thumbnailImgKey: item.thumbnailImgKey || "",
              companyAddressAddr1: item.companyAddressAddr1 || "",
              establishmentDay: getYMD(item.establishmentDay),
              industrialTechText: item.industrialTechText,
              itemSurmmary: item.itemSurmmary,
              companyDescription: item.companyDescription,
              userName: item.userName,
              userEmail: item.userEmail,
              userPhone: item.userPhone,
              investmentAmount: item.investmentAmount,
              companyValue: item.companyValue,
              investmentHighlight: item.investmentHighlight
                ? item.investmentHighlight[0].value
                : undefined,
              problem: item.problem ? item.problem[0].value : undefined,
              technology: item.technology
                ? item.technology[0].value
                : undefined,
              productStatus: item.productStatus
                ? item.productStatus[0].value
                : undefined,
              solutionBusinessModel: item.solutionBusinessModel
                ? item.solutionBusinessModel[0].value
                : undefined,
              updatedAt: item.updatedAt,
            };
          }),
        })
      );
    }
    return new Promise((resolve) =>
      resolve({ success: false, msg: "알수 없는 에러" })
    );
  } catch (error) {
    console.log(error);

    return new Promise((resolve) =>
      resolve({ success: false, msg: `${error}` })
    );
  }
};
