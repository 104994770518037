import axios from "axios";
import * as api from "../../api";
import { InvestmentPerformanceResponse } from "../../../router/dashboard/fund-account-detail/interface/FundAccountDetail.interface";
import {
  ExpectationRateProps,
  NewInvestmentProps,
  OperatingFundProps,
  OperatingIndustryProps,
  PerformanceExitProps,
  PerformanceTrendProps,
  PerformanceValueProps,
  SurvivalRateProps,
  TipsInvestmentProps,
  TotalPerformanceProps,
  ValueAddType,
} from "../../../router/dashboard/performance-metrics/interface/PerformanceMetrics.interface";
import { getNewDate } from "../../../common/commonUtil";
import { FundAccountLight } from "../../../type/data";

export const getAllFundAccountLight = async (): Promise<
  FundAccountLight[] | undefined
> => {
  const result = await axios.get(api.getFundAccountLight());
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.acFundAccount));
  }

  return new Promise((reject) => reject(undefined));
};
export const getInvestmentPerformanceValue = async (data: {
  dateStart?: string;
  dateEnd?: string;
  acIndustrialTechId?: number[];
  acFundAccountId?: number[];
  acStructureId?: number;
  acUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}): Promise<
  { value: PerformanceValueProps[]; exit: PerformanceExitProps[] } | undefined
> => {
  const betweenDate = (target: string, start?: string, end?: string) => {
    if (!start && !end) return true;

    const sDate = getNewDate(start).getTime();
    const eDate = getNewDate(end).getTime();
    const tDate = getNewDate(target).getTime();

    return tDate >= sDate && tDate <= eDate;
  };
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };

  const result = await axios.get(api.getInvestmentPerformanceValue(), {
    params: param,
  });
  if (result.status === 200) {
    const value: PerformanceValueProps[] = result.data.value;
    const exit: PerformanceExitProps[] = result.data.exit;

    const newValueData = value.map((item, index) => {
      const filterValueHistory = item.pfValueHistory.filter(
        (item) => item.pfValueAccount.length !== 0
      );
      return {
        ...item,
        firstInvestment: filterValueHistory.find(
          (history) =>
            history.pfValueAccount.filter(
              (account) => account.type === ValueAddType.FIRST
            ).length > 0
        ),
        pfValueHistory: filterValueHistory
          .map((history, idx) => {
            if (idx === 0) {
              return history;
            }
            return { ...history, preValueHistory: filterValueHistory[idx - 1] };
          })
          .filter(
            (history) =>
              history.pfValueAccount.filter(
                (account) => account.type === ValueAddType.NEXT
              ).length > 0
          )
          .filter((history) =>
            betweenDate(history.date, data.dateStart, data.dateEnd)
          ),
      };
    });

    return new Promise((resolve) =>
      resolve({
        value: newValueData,
        exit: exit,
      })
    );
  }

  return new Promise((reject) => reject(undefined));
};

export const getInvestmentPerformanceSummary = async (data: {
  dateStart?: string;
  dateEnd?: string;
  acIndustrialTechId?: number[];
  acFundAccountId?: number[];
  acStructureId?: number;
  acUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}): Promise<InvestmentPerformanceResponse[] | undefined> => {
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };

  const result = await axios.get(api.getInvestmentPerformanceSummary(), {
    params: param,
  });
  if (result.status === 200) {
    const data: InvestmentPerformanceResponse[] = result.data.result;
    return new Promise((resolve) => resolve(data));
  }

  return new Promise((reject) => reject(undefined));
};

export const getPerformanceTrend = async (data: {
  year: number;
  acIndustrialTechId?: number[];
  acFundAccountId?: number[];
  acStructureId?: number;
  acUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}): Promise<PerformanceTrendProps | undefined> => {
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };
  const result = await axios.get(api.getPerformanceTrendForDashboard(), {
    params: param,
  });
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }

  return new Promise((reject) => reject(undefined));
};

export const getPerformanceNewInvestment = async (data: {
  year: number;
  acIndustrialTechId?: number[];
  acFundAccountId?: number[];
  acStructureId?: number;
  acUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}): Promise<NewInvestmentProps[] | undefined> => {
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };
  const result = await axios.get(
    api.getPerformanceNewInvestmentForDashboard(),
    {
      params: param,
    }
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }

  return new Promise((reject) => reject(undefined));
};

export const getPerformanceTipsInvestment = async (data: {
  year: number;
  acIndustrialTechId?: number[];
  acFundAccountId?: number[];
  acStructureId?: number;
  acUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}): Promise<TipsInvestmentProps[] | undefined> => {
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };
  const result = await axios.get(
    api.getPerformanceTipsInvestmentForDashboard(),
    {
      params: param,
    }
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }

  return new Promise((reject) => reject(undefined));
};

export const getPerformanceOutlook = async (data: {
  acStructureId?: number;
  acFundAccountId?: number[];
  acIndustrialTechId?: number[];
  acUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}): Promise<ExpectationRateProps[] | undefined> => {
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };
  const result = await axios.get(api.getPerformanceOutlookForDashboard(), {
    params: param,
  });
  if (result.status === 200) {
    const newData: ExpectationRateProps[] = [];
    result.data.result.forEach((item: any, index: number) => {
      if ([5, 6].includes(item.riskStatusId)) {
        const mergeData = newData.find((item) => item.riskStatusId === 5);
        if (mergeData) {
          mergeData.investment = [...mergeData.investment, ...item.investment];
          mergeData.investInfo = [...mergeData.investInfo, ...item.investInfo];
          mergeData.totalInvestment += item.investment.reduce(
            (pre: any, cur: any) => cur + pre,
            0
          );
          mergeData.totalInvestInfo += item.investInfo.reduce(
            (pre: any, cur: any) => cur + pre,
            0
          );
        } else {
          newData.push({
            ...item,
            totalInvestment: item.investment.reduce(
              (pre: any, cur: any) => cur + pre,
              0
            ),
            totalInvestInfo: item.investInfo.reduce(
              (pre: any, cur: any) => cur + pre,
              0
            ),
          });
        }
      } else {
        newData.push({
          ...item,
          totalInvestment: item.investment.reduce(
            (pre: any, cur: any) => cur + pre,
            0
          ),
          totalInvestInfo: item.investInfo.reduce(
            (pre: any, cur: any) => cur + pre,
            0
          ),
        });
      }
    });

    return new Promise((resolve) => resolve(newData));
  }

  return new Promise((reject) => reject(undefined));
};

export const getPerformanceSurvival = async (data: {
  acStructureId?: number;
  acFundAccountId?: number[];
  acIndustrialTechId?: number[];
  acUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}): Promise<SurvivalRateProps | undefined> => {
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };
  const result = await axios.get(api.getPerformanceSurvivalForDashboard(), {
    params: param,
  });
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }

  return new Promise((reject) => reject(undefined));
};

export const getPerformanceOperatingFund = async (data: {
  acStructureId?: number;
  acFundAccountId?: number[];
  acIndustrialTechId?: number[];
  acUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}): Promise<OperatingFundProps | undefined> => {
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };
  const result = await axios.get(api.getInvestmentPerformanceOperatingFund(), {
    params: param,
  });
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }

  return new Promise((reject) => reject(undefined));
};

export const getPerformanceOperatingIndustry = async (data: {
  acStructureId?: number;
  acFundAccountId?: number[];
  acIndustrialTechId?: number[];
  acUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}): Promise<OperatingIndustryProps[] | undefined> => {
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };
  const result = await axios.get(
    api.getInvestmentPerformanceOperatingIndustry(),
    {
      params: param,
    }
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }

  return new Promise((reject) => reject(undefined));
};

export const getTotalPerformance = async (data: {
  acFundAccountId?: number[];
  acIndustrialTechId?: number[];
}): Promise<TotalPerformanceProps | undefined> => {
  const param = {
    ...data,
    acIndustrialTechId: data.acIndustrialTechId
      ? data.acIndustrialTechId.join(",")
      : undefined,
    acFundAccountId: data.acFundAccountId
      ? data.acFundAccountId.join(",")
      : undefined,
  };
  const result = await axios.get(api.getInvestmentTotalPerformance(), {
    params: param,
  });
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }

  return new Promise((reject) => reject(undefined));
};
