import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as Filter } from "../../../../../assets/image/icon_filtering.svg";
import ModalLayout from "../../../../../components/modal/ModalLayout";
import { RangeQuaterModal } from "./RangeQuaterModal";

interface Props {
  isOnlyYear?: boolean;
  defaultRangeDate?: DateRangeProps;
  onChangeRnage: (data: DateRangeProps) => void;
}
export interface DateRangeProps {
  startYear: string;
  startQuarter: string;
  endYear: string;
  endQuarter: string;
}

export const SelectRange: React.FC<Props> = ({
  isOnlyYear,
  defaultRangeDate,
  onChangeRnage,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [startYear, setStartYear] = useState<string>(
    defaultRangeDate?.startYear || ""
  );
  const [startQuarter, setStartQuarter] = useState<string>(
    defaultRangeDate?.startQuarter || ""
  );
  const [endYear, setEndYear] = useState<string>(
    defaultRangeDate?.endYear || ""
  );
  const [endQuarter, setEndQuarter] = useState<string>(
    defaultRangeDate?.endQuarter || ""
  );

  const getRangeText = () => {
    if (isOnlyYear) {
      return `${startYear}년 - ${endYear}년`;
    } else {
      return `${startYear}년 ${startQuarter}분기 - ${endYear}년 ${endQuarter}분기`;
    }
  };

  return (
    <>
      <Container className="caption-12-md" onClick={() => setShowModal(true)}>
        <Filter />
        <span>{getRangeText()}</span>
      </Container>
      {showModal && (
        <ModalLayout
          isOpen={showModal}
          isFullScreen
          toggle={() => setShowModal(false)}
        >
          <RangeQuaterModal
            isOnlyYear={isOnlyYear}
            defaultValue={
              startYear && startQuarter && endYear && endQuarter
                ? {
                    startYear,
                    startQuarter,
                    endYear,
                    endQuarter,
                  }
                : undefined
            }
            onChangeRange={(data) => {
              setStartYear(data.startYear);
              setStartQuarter(data.startQuarter);
              setEndYear(data.endYear);
              setEndQuarter(data.endQuarter);
              onChangeRnage(data);
            }}
            close={() => setShowModal(false)}
          />
        </ModalLayout>
      )}
    </>
  );
};

const Container = styled.div`
  position: relative;
  width: max-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  padding: 6px 8px;

  color: var(--text-04, #7e8086);

  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  background: var(--background-contents01, #fff);

  transition: all ease 0.2s;

  cursor: pointer;

  :hover {
    color: var(--blue-400, #4584ff);
    border: 1px solid var(--blue-400, #4584ff);
    background: var(--blue-50);
    path {
      stroke: var(--blue-400, #4584ff);
    }
  }
`;
