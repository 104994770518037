import React from "react";
import styled from "styled-components";

import DefaultSearch from "../../../components-2.0v/search/DefaultSearch";
import FilterView from "./organism/FilterView";
import { ExcavationPortfolioCard } from "./organism/ExcavationPortfolioCard";
import useExcavation from "../hook/useExcavation";
import { CreateExcavationPortfolioCard } from "./organism/CreateExcavationPortfolioCard";
import { ReactComponent as Close } from "../../../assets/image/excavation_close.svg";
import { ReactComponent as FileIcon } from "../../../assets/image/icon_open_folder.svg";
import { DefaultButton } from "lattice_core";
import ExcavationPortfolioDetail from "./organism/ExcavationPortfolioDetail";
import { viewPdfPopup } from "../../../common/commonUtil";
import ModalLayout from "../../../components/modal/ModalLayout";
import { RegisteParticipantModal } from "./organism/RegisteParticipantModal";

import ConfirmModal from "../../../2.0lattice/components/modal/ConfirmModal";
import { PortfolioDropModal } from "../../../components-2.0v/modal/PortfolioDropModal";

import useGoogle from "../../../hooks/useGoogle";
import ProgressModalStatic from "../../../components-2.0v/progress/ProgressModalStatic";

const DeskExcavation = () => {
  const [
    isGapiReady,
    isLoggedIn,
    handleSignInClick,
    handleSignOutClick,
    getPDFBlobFile,
    onLoginLattice,
    createFolder,
  ] = useGoogle();
  const [
    initCompleted,
    myInfo,
    minDate,
    maxDate,
    allUser,
    allIndustyTech,
    allChannel,
    myIndustrialTech,
    myHeadQuarterIndustrialTech,
    filterData,
    portfolioList,
    contentsBodyRef,
    searchKeyword,
    isDetailShow,
    selectedPortfolio,
    portfolioInfo,
    updateCommentId,
    isOpenRegisteParticipant,
    openProgress,
    progressIcon,
    progressMsg,
    progress,
    progressFail,
    registeDealflowComplete,
    dropTargetPortfolio,
    setDropTargetPortfolio,
    goToPortfolio,
    stayExcavation,
    updateOpenProgress,
    setIsOpenRegisteParticipant,
    setUpdateCommentId,
    updateSelectedPortfolio,
    updateSearchKeyword,
    getFilterPortfolio,
    onChangeFilterData,
    sendComment,
    updateComment,
    deleteComment,
    goToPortfolioCreate,
    updateDetailShow,
    registeDealFlow,
    dropComplete,
  ] = useExcavation(createFolder);

  if (!portfolioList) return null;

  return (
    <Wrapper>
      <ExcavationContainer>
        <ContentsHeader>
          <span className="heading-20-sb" style={{ color: "var(--text-01)" }}>
            발굴
          </span>
          <FlexRow style={{ gap: 12 }}>
            <DefaultSearch
              value={searchKeyword}
              placeholder="기업명, 채널명, 키워드로 검색해 주세요."
              transfromPixel={(n) => n}
              onChangeValue={(value) => {
                updateSearchKeyword(value);
              }}
              onPressEnter={(value) => {
                updateSearchKeyword(value);
              }}
            />
          </FlexRow>
        </ContentsHeader>
        <ContentsBodyWrapper>
          <ContentsBody
            id="body__component"
            ref={contentsBodyRef}
            className={isDetailShow ? "isShow" : ""}
          >
            <FilterView
              isShowDetail={isDetailShow}
              allIndustyTech={allIndustyTech}
              allChannel={allChannel}
              filterData={filterData}
              myIndustrialTech={myIndustrialTech}
              myHeadQuarterIndustrialTech={myHeadQuarterIndustrialTech}
              onChangeFilterData={onChangeFilterData}
              minDate={minDate}
              maxDate={maxDate}
            />
            {portfolioList && portfolioList.length > 0 ? (
              <ExcavationList>
                {!isDetailShow && (
                  <CreateExcavationPortfolioCard
                    onClickCard={goToPortfolioCreate}
                  />
                )}
                {getFilterPortfolio().map((item, index) => {
                  return (
                    <ExcavationPortfolioCard
                      id={`EP_${item.portfolioId}`}
                      key={index}
                      portfolio={item}
                      isSelected={
                        item.portfolioId === selectedPortfolio?.portfolioId
                      }
                      isShowDetail={isDetailShow}
                      portfolioInfo={portfolioInfo}
                      onClickCard={() => {
                        updateDetailShow(true);
                        updateSelectedPortfolio(item);
                      }}
                      onClickOptionClick={() => {
                        isDetailShow && updateSelectedPortfolio(item);
                      }}
                      onClickRegisteParticipant={() => {
                        setIsOpenRegisteParticipant(true);
                      }}
                      onClickOpenIRProposal={() => {
                        if (portfolioInfo?.businessInfo.businessPlanFile?.key) {
                          viewPdfPopup(
                            portfolioInfo.businessInfo.businessPlanFile.key,
                            1000
                          );
                        }
                      }}
                      onClickDrop={() => {
                        setDropTargetPortfolio({
                          companyName: item.companyName || "",
                          portfolioId: item.portfolioId,
                        });
                      }}
                    />
                  );
                })}
              </ExcavationList>
            ) : (
              <EmptyExcavationContainer>
                <FileIcon />
                <div
                  className="heading-18-md"
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    marginBottom: 30,
                    color: "var(--text-04)",
                  }}
                >
                  발굴 데이터가 없습니다.
                  <br />
                  새로운 발굴 정보를 추가해주세요.
                </div>
                <DefaultButton
                  className="body-14-sb"
                  sizeType="small"
                  buttonColor="var(--primary-blue)"
                  hoverColor="var(--blue-600)"
                  text="추가하기"
                  onClick={goToPortfolioCreate}
                />
              </EmptyExcavationContainer>
            )}
          </ContentsBody>
          <ContentsDetailWrapper className={isDetailShow ? "isShow" : ""}>
            <ContentsDetail id="root__component">
              {isDetailShow && selectedPortfolio && portfolioInfo && (
                <ExcavationPortfolioDetail
                  portfolio={selectedPortfolio}
                  portfolioInfo={portfolioInfo}
                  sendComment={sendComment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  updateCommentId={updateCommentId}
                  setUpdateCommentId={setUpdateCommentId}
                />
              )}
            </ContentsDetail>
            {isDetailShow && (
              <DetailFooter>
                <DefaultButton
                  className="heading-16-md"
                  buttonType="colored-outline"
                  sizeType="small"
                  buttonColor={"#1F2022"}
                  borderColor={"#D5D6D8"}
                  disabled={
                    !portfolioInfo ||
                    portfolioInfo.businessInfo.businessPlanFile === undefined ||
                    portfolioInfo.businessInfo.businessPlanFile === null
                  }
                  text="상세IR자료"
                  onClick={(e) => {
                    e && e.stopPropagation();
                    if (portfolioInfo?.businessInfo.businessPlanFile?.key) {
                      viewPdfPopup(
                        portfolioInfo.businessInfo.businessPlanFile.key,
                        1000
                      );
                    }
                  }}
                />
                {myInfo?.authorityId === 1 && (
                  <FlexRow style={{ gap: 12 }}>
                    <DefaultButton
                      className="heading-16-md"
                      buttonType="outline"
                      sizeType="small"
                      buttonColor={"var(--red-500)"}
                      text="드롭"
                      onClick={(e) => {
                        e && e.stopPropagation();
                        if (isDetailShow && selectedPortfolio) {
                          setDropTargetPortfolio({
                            companyName: selectedPortfolio.companyName || "",
                            portfolioId: selectedPortfolio.portfolioId,
                          });
                        }
                      }}
                    />
                    <DefaultButton
                      className="heading-16-md"
                      buttonType="filled"
                      sizeType="small"
                      buttonColor={"var(--primary-blue)"}
                      hoverColor="var(--blue-600)"
                      text="미팅·검토하기"
                      onClick={(e) => {
                        e && e.stopPropagation();
                        setIsOpenRegisteParticipant(true);
                      }}
                    />
                  </FlexRow>
                )}
              </DetailFooter>
            )}
            {isDetailShow && (
              <DetailCloseBtn
                onClick={() => {
                  updateDetailShow(false);
                  updateSelectedPortfolio(undefined);
                }}
              >
                <Close />
              </DetailCloseBtn>
            )}
          </ContentsDetailWrapper>
        </ContentsBodyWrapper>
        {isOpenRegisteParticipant && selectedPortfolio && (
          <ModalLayout
            isFullScreen
            isOpen={isOpenRegisteParticipant && selectedPortfolio !== undefined}
            toggle={() => {
              setIsOpenRegisteParticipant(false);
            }}
          >
            <RegisteParticipantModal
              allUser={allUser}
              hasExcavator={
                portfolioInfo?.participantInfo.excavator?.length !== 0
              }
              hasExcavationDate={
                portfolioInfo?.participantInfo.excavationDate !== undefined &&
                portfolioInfo?.participantInfo.excavationDate !== null &&
                portfolioInfo.participantInfo.excavationDate.length > 0
              }
              submit={registeDealFlow}
              close={() => {
                setIsOpenRegisteParticipant(false);
              }}
            />
          </ModalLayout>
        )}

        {registeDealflowComplete !== undefined && (
          <ModalLayout
            isOpen={registeDealflowComplete !== undefined}
            isFullScreen
            toggle={() => {
              stayExcavation();
            }}
          >
            <ConfirmModal
              type="confirm"
              title="담당자 등록 완료"
              contents={[
                `"${registeDealflowComplete.companyName}"에 담당자 등록이 완료 되었습니다!`,
                `해당 기업 상세 페이지로 이동하시겠습니까?`,
              ]}
              positiveTitle="이동"
              onClickPositiveBtn={() => {
                goToPortfolio();
              }}
              negativeTitle="아니오"
              onClickNegativeBtn={() => {
                stayExcavation();
              }}
            />
          </ModalLayout>
        )}

        {openProgress && (
          <ModalLayout isOpen={openProgress} isFullScreen>
            <ProgressModalStatic
              SvgIcon={progressIcon}
              progress={progress}
              message={progressMsg}
              progressFail={progressFail}
              transfromPixel={(n) => n}
            />
          </ModalLayout>
        )}

        {dropTargetPortfolio && (
          <ModalLayout
            isFullScreen
            isOpen={dropTargetPortfolio !== undefined}
            toggle={() => {
              setDropTargetPortfolio(undefined);
            }}
          >
            <PortfolioDropModal
              targetData={dropTargetPortfolio}
              close={() => setDropTargetPortfolio(undefined)}
              callback={() => {
                dropComplete();
              }}
            />
          </ModalLayout>
        )}
      </ExcavationContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
`;

const ExcavationContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 1320px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  width: 100%;
  height: 62px;
  min-height: 62px;
  max-height: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;

  border-bottom: 1px solid var(--divider-200);
  background: var(--background-header);
`;
const ContentsBodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;
const ContentsBody = styled.div`
  flex: 1 0 0;
  height: 100%;
  max-width: calc(282px * 4 + 96px);
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  margin: 0 auto;
  overflow: scroll;
  gap: 32px;

  &.isShow {
    max-width: 282px;
  }
`;
const ContentsDetail = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 66px);
  overflow: scroll;
`;

const ContentsDetailWrapper = styled.div`
  position: relative;
  width: 0px;
  min-width: 0px;
  height: 100%;
  overflow: hidden;
  transition: all 0.2s ease-in;

  &.isShow {
    width: calc(100% - 282px - 120px);
    min-width: 918px;
    overflow: scroll;

    border-left: 1px solid var(--gray-200, #edeeef);
    background: var(--background-page, #fcfcfd);
    box-shadow: -2px 2px 10px 0px rgba(100, 103, 109, 0.1);
    transition: all 0.2s ease-in;
  }
`;

const DetailCloseBtn = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  top: 50%;
  left: 0;

  transform: translateY(-50%);

  width: 24px;
  height: 48px;
  min-width: 24px;
  min-height: 48px;
  max-width: 24px;
  max-height: 48px;

  border-radius: 0px 4px 4px 0px;
  border-top: 1px solid var(---gray-200, #edeeef);
  border-right: 1px solid var(---gray-200, #edeeef);
  border-bottom: 1px solid var(---gray-200, #edeeef);
  background: var(--background-contents-01, #fff);

  cursor: pointer;
`;

const DetailFooter = styled.div`
  position: absolute;
  width: 100%;
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  bottom: 0px;
  padding: 0 60px;

  border-top: 2px solid var(--divider-200, #edeeef);
  background: var(--background-header, #fff);
`;
const ExcavationList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const EmptyExcavationContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default DeskExcavation;
