export enum PerformanceMetricsTabType {
  COMPANY = 1,
  DEPARTMENT = 2,
  PERSON = 3,
}

export type PerformanceMetricsFilterType = "department" | "person";

export interface PerformanceTrendProps {
  portfolio: number;
  meeting: { date: string }[];
  pitching: { date: string }[];
  assessment: { date: string }[];
  contract: { date: string }[];
}

export interface NewInvestmentProps {
  portfolioId: number;
  companyName: string;
  investmentAccount: {
    investmentAmount: number; // 투자금
    paymentDate: string; // 납입일
  }[];
}

export interface TipsInvestmentProps {
  portfolioId: number;
  companyName: string;
  pfProgram: {
    detailType: ProgramDetailType; // 1: 일반, 2: 딥테크
    status: ProgramStatus; // 1: 예상, 2: 신청, 3: 성공, 4: 실패
    applyDate: string; // 지원일
  };
}

export interface PerformanceExitProps {
  portfolioId: number;
  companyName: string;
  investmentExit: ExitHistory[];
}

export interface ExitHistory {
  date: string;
  stock: number;
  stockPrice: number;
  amount: number;
  principal: number;
}

export interface PerformanceValueProps {
  portfolioId: number;
  companyName: string;
  pfValueHistory: ValueHistory[];
  firstInvestment?: ValueHistory;
}

export interface ValueHistory {
  type: ValueChangeType;
  value: number; // 기업가치
  date: string; // 투자일
  investmentAmount: number; // 투자금
  totalStock: number | null; // 총발행주식수
  pfValueAccount: {
    type: ValueAddType; // 1: 최초투자, 2: 후속투자
    stock: number; // 보유주식수
  }[];
  preValueHistory?: ValueHistory;
}

export enum ValueChangeType { // pfValueHistory.type
  ESTABLISH = 1, // 설립자본금
  INCREASE = 2, // 유상증자
  REDUCTION = 3, // 유상감자
  SAFE = 4, // 세이프
}

export enum ValueAddType { // pfValueAccount.type
  FIRST = 1, // 최초투자
  NEXT = 2, // 후속투자
}

export interface ExpectationRateProps {
  riskStatusId: number;
  name: string;
  totalInvestment: number;
  totalInvestInfo: number;
  investment: number[];
  investInfo: number[];
  color?: string;
}

export interface SurvivalRateProps {
  survival: number;
  closure: number;
}

export interface OperatingFundProps {
  orig: number[];
  fund: number[];
}

export interface OperatingIndustryProps {
  acIndustrialTechId: number;
  name: string;
  investment: number[];
}

export interface TotalPerformanceProps {
  revenue: number;
  employee: number;
  investment: number;
  patentApplication: number;
  patentRegistration: number;
}

export interface PersonalSearchProps {
  type: "excavation" | "investor" | "charger" | "assistant";
  text: string;
  isDefault: boolean;
  description?: string;
}

export const PersonalSearchList: PersonalSearchProps[] = [
  {
    type: "excavation",
    text: "발굴",
    isDefault: false,
    description: "최초 주력발굴자",
  },
  {
    type: "investor",
    text: "최초투자",
    isDefault: false,
    description: "투자 집행한 사람, 심사자",
  },
  {
    type: "charger",
    text: "담당",
    isDefault: false,
    description:
      "인수인계받은 사후관리자를 의미하고 현재 사후관리담당자 입니다.",
  },

  {
    type: "assistant",
    text: "조력",
    isDefault: false,
    description: "현재 사후관리조력자",
  },
];

export enum ProgramDetailType {
  GENERAL = 1,
  DEEP_TECH = 2,
}

export enum ProgramStatus {
  EXPECT = 1,
  APPLY = 2,
  SUCCESS = 3,
  FAIL = 4,
}
