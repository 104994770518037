import React from "react";
import RowTitleBox from "../../../../components/box/RowTitleBox";
import { InputBox } from "../../../../components-2.0v/input-box/InputBox";
import { BoxComponent } from "../../../../components-2.0v/box/BoxComponent";
import { ExcavationPortfolioChangeDataProps } from "../../interface/ExcavationCreate.interface";
import { ErrorMSG } from "../../../portfolio/info-modify/hook/usePortfolioInfoModify";

interface Props {
  errorMSGArr: ErrorMSG[];
  onChangeData: (data: ExcavationPortfolioChangeDataProps) => void;
}
export const CompanyCeoInfo: React.FC<Props> = (props) => {
  const { errorMSGArr, onChangeData } = props;
  return (
    <BoxComponent>
      <RowTitleBox title="대표 이사" titleWidth={128}>
        <InputBox
          placeholder="대표 이사 성함을 입력해 주세요."
          width={486}
          onBlur={(value) => {
            onChangeData({ type: "ceoName", value: value });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="핸드폰 번호" titleWidth={128}>
        <InputBox
          placeholder="주로 연락을 주고 받는 기업의 전화 번호를 입력해 주세요."
          width={486}
          textType="phone"
          isShowValidationMsg={
            errorMSGArr.find((item) => item.type === "ceoTel") !== undefined
          }
          validationMsg={
            errorMSGArr.find((item) => item.type === "ceoTel")?.value || ""
          }
          onBlur={(value) => {
            onChangeData({ type: "ceoTel", value: value });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="이메일" titleWidth={128}>
        <InputBox
          placeholder="주로 연락을 주고 받는 기업의 이메일을 입력해 주세요."
          width={486}
          isShowValidationMsg={
            errorMSGArr.find((item) => item.type === "ceoEmail") !== undefined
          }
          validationMsg={
            errorMSGArr.find((item) => item.type === "ceoEmail")?.value || ""
          }
          onBlur={(value) => {
            onChangeData({ type: "ceoEmail", value: value });
          }}
        />
      </RowTitleBox>
    </BoxComponent>
  );
};
