import { useSetAtom } from "jotai";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { portfolioNameAtom } from "../../../../App";
import {
  BlueCardInfo,
  Participant,
  PortfolioInfoType,
  PortfolioMenuType,
  PortfolioUserInfo,
} from "../../../../type/data";

import { BluecardObjectProps } from "../desktop/organisms/BlueCardNew";
import {
  getNewDate,
  getPortfolioIdInLocation,
  getYMD,
} from "../../../../common/commonUtil";
import * as api from "../../../../api/api";
import axios from "axios";
import { getDatabaseBluecardInfo } from "../../../../api/repository/database/DatabaseRepository";
import {
  getPfUser,
  getPortfolioParticipant,
  getSuggestBluecard,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import { checkAccessToMenu } from "../../../../common/commonApiData";
import { toast } from "react-toastify";
import { PortfolioInfoProps } from "../interface/BluecardManage.interface";

const useBluecardManage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const portfolioId = getPortfolioIdInLocation(location);
  const setPortfolioName = useSetAtom(portfolioNameAtom);
  const [isUpdating, setUpdating] = useState<boolean>(false);

  const portfolioInfo = useRef<PortfolioInfoType>({});

  const [originPortfolioInfoData, updateOriginPortfolioInfoData] = useState<
    PortfolioInfoProps | undefined
  >(undefined);
  const [portfolioInfoData, updatePortfolioInfoData] = useState<
    PortfolioInfoProps | undefined
  >(undefined);

  const isSuggested = useRef<boolean>(false);
  const originBluecard = useRef<BluecardObjectProps | undefined>(undefined);
  const suggestBluecard = useRef<BluecardObjectProps | undefined>(undefined);

  const afterConfirmDate = (confirmDate: string, suggestDate: string) => {
    const dateConfirm = getNewDate(confirmDate);
    const dateSuggest = getNewDate(suggestDate);

    return dateConfirm.getTime() > dateSuggest.getTime();
  };

  const bluecardInit = async () => {
    try {
      await checkIsAccessible();

      const result = await axios.get(api.getPortfolio(portfolioId)); // /portfolio/:id
      const bluecardResult = await getDatabaseBluecardInfo([portfolioId]);
      const pfUserResult = await getPfUser(portfolioId);
      const participant = await getPortfolioParticipant(portfolioId);

      const resultSuggest = await getSuggestBluecard(portfolioId);

      if (result.status !== 200) return;
      if (!bluecardResult.success || bluecardResult.data === undefined) return;
      if (!participant) return;

      const portfolio = result.data.portfolio;
      console.log(portfolio);

      portfolioInfo.current = {
        portfolioId: portfolio.portfolioId,
        acceleratorId: portfolio.acceleratorId,
        acChannel: { ...portfolio.acChannel, isNew: false } || undefined,
        additionalInformation: portfolio.additionalInformation,
        companyAddress: portfolio.addr1
          ? {
              addr1: portfolio.addr1,
              addr2: portfolio.addr2,
              addr3: portfolio.addr3,
              fullAddr: `${portfolio.addr1} ${portfolio.addr2} ${portfolio.addr3}`,
              addrDetail: portfolio.addrDetail || undefined,
            }
          : undefined,
        businessPlanFile: portfolio.businessPlanFile || undefined,
        businessNumber: portfolio.businessNumber || undefined,
        ceoEmail: portfolio.ceoEmail || undefined,
        ceoName: portfolio.ceoName || undefined,
        ceoTel: portfolio.ceoTell || undefined,
        companyName: portfolio.companyName || undefined,
        description: portfolio.description || undefined,
        establishmentDay: portfolio.establishmentDay
          ? getYMD(portfolio.establishmentDay)
          : undefined,
        excavationArea: portfolio.excavationArea || undefined,
        excavationDate: portfolio.excavationDate
          ? getYMD(portfolio.excavationDate)
          : undefined,
        excavator: portfolio.excavator || undefined,
        explanatoryUrl: portfolio.explanatoryUrl || undefined,
        pfIndustrialTech: portfolio.pfIndustrialTech || undefined,
        pfKeyword: portfolio.pfKeyword || undefined,
        problem: portfolio.problem || undefined,
        solution: portfolio.solution || undefined,
        companyImgUrl: portfolio.companyImgUrl || undefined,
        companyImgKey: portfolio.companyImgKey || undefined,
        thumbnailImgUrl: portfolio.thumbnailImgUrl || undefined,
        thumbnailImgKey: portfolio.thumbnailImgKey || undefined,
        homepageUrl: portfolio.homepageUrl || "",
        stageType: portfolio.stageType || undefined,
      };

      const data: BlueCardInfo = bluecardResult.data[0];

      const portfolioData: PortfolioInfoProps = {
        portfolioInfo: portfolioInfo.current,
        portfolioBlueCardInfo: data,
        portfolioUser: pfUserResult,
        portfolioMainParticipant: participant?.find(
          (item) => item.screenRoleId === 1
        ),
        portfolioSubParticipant: participant?.filter(
          (item) => item.screenRoleId === 2
        ),
      };

      console.log(portfolioData);

      if (resultSuggest) {
        isSuggested.current = !afterConfirmDate(
          data.updatedAt || "",
          resultSuggest.createdAt
        );

        console.log(isSuggested.current);
        suggestBluecard.current = resultSuggest;
      }

      originBluecard.current = {
        itemSurmmary: data.itemSurmmary,
        investmentHighlight: data.investmentHighlight || "",
        companyDescription: data.companyDescription || "",
        problem: data.problem || "",
        solutionBusinessModel: data.solutionBusinessModel || "",
        technology: data.technology || "",
        productStatus: data.productStatus || "",
      };

      updateOriginPortfolioInfoData({ ...portfolioData });
      updatePortfolioInfoData({ ...portfolioData });
      setPortfolioName(portfolioInfoData?.portfolioInfo.companyName || "");
      console.log("Asdfsdf");
    } catch (error) {
      console.log(error);
    }
  };

  const getPortfolioInfoData = (
    portfolioInfo: PortfolioInfoType,
    portfolioBlueCardInfo: BlueCardInfo,
    portfolioUser?: PortfolioUserInfo,
    portfolioMainParticipant?: Participant,
    portfolioSubParticipant?: Participant[]
  ) => {
    const newData: PortfolioInfoProps = {
      portfolioInfo: { ...portfolioInfo },
      portfolioBlueCardInfo: {
        ...portfolioBlueCardInfo,
        industrialTechText:
          portfolioInfo.pfIndustrialTech
            ?.map((item) => `#${item.name}`)
            .join(",") || "",
        companyName: portfolioInfo.companyName || "",
        ceoName: portfolioInfo.ceoName || "",
        companyAddressAddr1: portfolioInfo.companyAddress?.addr1 || "",
        homepageUrl: portfolioInfo.homepageUrl,
        companyImgUrl:
          portfolioInfo.companyImgUrl || portfolioInfo.companyImgFile?.fileUrl,
        companyImgKey: portfolioInfo.companyImgKey,
        thumbnailImgUrl:
          portfolioInfo.thumbnailImgUrl ||
          portfolioInfo.thumbnailImgFile?.fileUrl,
        thumbnailImgKey: portfolioInfo.thumbnailImgKey,
        userEmail: portfolioMainParticipant?.acUser.email || "",
        userName: portfolioMainParticipant?.acUser.name || "",
      },
      portfolioUser: portfolioUser,
      portfolioMainParticipant: portfolioMainParticipant,
      portfolioSubParticipant: portfolioSubParticipant,
    };
    return newData;
  };

  const checkIsAccessible = async () => {
    const isAccessible = await checkAccessToMenu(
      PortfolioMenuType.PORTFOLIO_INFO,
      portfolioId
    );
    if (!isAccessible) {
      alert("현재 포트폴리오 상태에서 접근이 불가능한 페이지입니다.");
      navigate(-1);
    }
  };

  const notifyToast = () => {
    if (!toast.isActive("save")) {
      toast.success("저장 성공", {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: "save",
        autoClose: 1500,
      });
    } else {
      toast.update("save", {
        autoClose: 1500,
      });
    }
  };

  const getPortfolioPrams = (portfolioInfo?: PortfolioInfoType) => {
    if (!portfolioInfo) return undefined;

    const _params: any = {};
    _params.portfolioId = portfolioInfo.portfolioId;
    if (portfolioInfo.companyName)
      _params.companyName = portfolioInfo.companyName;
    if (portfolioInfo.description)
      _params.description = portfolioInfo.description;
    if (portfolioInfo.pfIndustrialTech)
      _params.pfIndustrialTech = portfolioInfo.pfIndustrialTech.map((item) => ({
        acIndustrialTechId: item.acIndustrialTechId,
      }));
    if (portfolioInfo.pfKeyword) _params.pfKeyword = portfolioInfo.pfKeyword;
    if (portfolioInfo.businessPlanFile)
      _params.businessPlanFile = portfolioInfo.businessPlanFile;
    if (portfolioInfo.explanatoryUrl)
      _params.explanatoryUrl = portfolioInfo.explanatoryUrl;
    if (portfolioInfo.problem) _params.problem = portfolioInfo.problem;
    if (portfolioInfo.solution) _params.solution = portfolioInfo.solution;
    if (portfolioInfo.excavationDate)
      _params.excavationDate = portfolioInfo.excavationDate;
    if (portfolioInfo.excavator) _params.excavator = portfolioInfo.excavator;
    if (portfolioInfo.excavationArea)
      _params.excavationArea = portfolioInfo.excavationArea;
    if (portfolioInfo.acChannel) _params.acChannel = portfolioInfo.acChannel;
    if (portfolioInfo.companyAddress) {
      _params.addr1 = portfolioInfo.companyAddress.addr1;
      _params.addr2 = portfolioInfo.companyAddress.addr2;
      _params.addr3 = portfolioInfo.companyAddress.addr3;
      _params.addrDetail = portfolioInfo.companyAddress.addrDetail;
    }
    if (portfolioInfo.homepageUrl)
      _params.homepageUrl = portfolioInfo.homepageUrl;
    if (portfolioInfo.ceoName) _params.ceoName = portfolioInfo.ceoName;
    if (portfolioInfo.ceoEmail) _params.ceoEmail = portfolioInfo.ceoEmail;
    if (portfolioInfo.ceoTel) _params.ceoTell = portfolioInfo.ceoTel;
    if (portfolioInfo.establishmentDay)
      _params.establishmentDay = portfolioInfo.establishmentDay;
    if (portfolioInfo.businessNumber)
      _params.businessNumber = portfolioInfo.businessNumber;
    if (portfolioInfo.additionalInformation)
      _params.additionalInformation = portfolioInfo.additionalInformation;
    if (portfolioInfo.companyImgUrl)
      _params.companyImgUrl = portfolioInfo.companyImgUrl;
    if (portfolioInfo.companyImgKey)
      _params.companyImgKey = portfolioInfo.companyImgKey;

    if (portfolioInfo.thumbnailImgUrl)
      _params.thumbnailImgUrl = portfolioInfo.thumbnailImgUrl;
    if (portfolioInfo.thumbnailImgKey)
      _params.thumbnailImgKey = portfolioInfo.thumbnailImgKey;

    if (portfolioInfo.businessPlanFile)
      _params.businessPlanFile = portfolioInfo.businessPlanFile;

    return _params;
  };

  const onSavePortfolioInfo = async () => {
    try {
      if (!portfolioInfoData) return;

      const blueCardData: any = {
        investmentHighlight: [
          {
            value: portfolioInfoData?.portfolioBlueCardInfo.investmentHighlight,
          },
        ],
        problem: [
          {
            value: portfolioInfoData?.portfolioBlueCardInfo.problem,
          },
        ],
        solutionBusinessModel: [
          {
            value:
              portfolioInfoData?.portfolioBlueCardInfo.solutionBusinessModel,
          },
        ],
        technology: [
          {
            value: portfolioInfoData?.portfolioBlueCardInfo.technology,
          },
        ],
        productStatus: [
          {
            value: portfolioInfoData?.portfolioBlueCardInfo.productStatus,
          },
        ],
        description:
          portfolioInfoData?.portfolioBlueCardInfo.companyDescription,
        summary: portfolioInfoData?.portfolioBlueCardInfo.itemSurmmary,
      };

      const saveData: any = {
        bluecard: blueCardData,
      };

      console.log(saveData);

      const result = await axios.put(
        api.updatePortfolioInfoForAccelerating(portfolioId),
        saveData
      );
      if (result.status === 200) {
        notifyToast();
        bluecardInit();
        setUpdating(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    bluecardInit();
  }, []);
  return [
    portfolioInfoData,
    isUpdating,
    originBluecard,
    suggestBluecard,
    isSuggested,
    updatePortfolioInfoData,
    getPortfolioInfoData,
    onSavePortfolioInfo,
    setUpdating,
  ] as const;
};

export default useBluecardManage;
