import React from "react";
import styled from "styled-components";
import { BoxComponent } from "../../../../../components-2.0v/box/BoxComponent";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { ParticipantInfoProps } from "../../interface/PortfolioInfo.interface";
import { selectTipsData } from "../../../../../type/data";
import { getYMD } from "../../../../../common/commonUtil";

interface Props {
  participantInfo: ParticipantInfoProps;
}
export const ParticipantInfo: React.FC<Props> = (props) => {
  const { participantInfo } = props;
  return (
    <BoxComponent>
      <RowTitleBox title="담당 본부" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.teamName?.length === 0 ? "is__empty" : ""
          }`}
        >
          {participantInfo.teamName && participantInfo.teamName.length > 0
            ? participantInfo.teamName
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="담당자" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.mainParticipantToText?.length === 0
              ? "is__empty"
              : ""
          }`}
        >
          {participantInfo.mainParticipantToText &&
          participantInfo.mainParticipantToText.length > 0
            ? participantInfo.mainParticipantToText
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="조력자" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.subParticipantToText?.length === 0
              ? "is__empty"
              : ""
          }`}
        >
          {participantInfo.subParticipantToText &&
          participantInfo.subParticipantToText.length > 0
            ? participantInfo.subParticipantToText
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="발굴자" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.excavator?.length === 0 ? "is__empty" : ""
          }`}
        >
          {participantInfo.excavator && participantInfo.excavator.length > 0
            ? participantInfo.excavator
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="발굴채널" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.channel?.length === 0 ? "is__empty" : ""
          }`}
        >
          {participantInfo.channel && participantInfo.channel.length > 0
            ? participantInfo.channel
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="발굴일자" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.excavationDate?.length === 0 ? "is__empty" : ""
          }`}
        >
          {participantInfo.excavationDate &&
          participantInfo.excavationDate.length > 0
            ? getYMD(participantInfo.excavationDate)
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="TIPS" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.tips?.length === 0 ? "is__empty" : ""
          }`}
        >
          {participantInfo.tips && participantInfo.tips.length > 0
            ? selectTipsData.find((item) => item.type === participantInfo.tips)
                ?.name
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
    </BoxComponent>
  );
};

const TextBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-01, #1f2022);
  &.is__empty {
    color: var(--text-05);
  }
`;
