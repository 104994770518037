import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { SBPContentsPropsV2 } from "../../interface/SBP.interface";
import { SBPSector } from "../SBPSector";

import { AutoResizableTextarea } from "../AutoResizableTextarea";

import { SBPSectorFooter } from "../SBPSectorFooter";
import { GovermentTaskComponent } from "./organism/GovermentTaskComponent";
import { FundBorrowComponent } from "./organism/FundBorrowComponent";
import { CLevelChangeComponent } from "./organism/CLevelChangeComponent";

import { ReactComponent as Check } from "../../../../../../../assets/image/icon_check.svg";
import RowTitleBox from "../RowTitleBox";
import InputBox from "../InputBox";
import {
  AddComma,
  RemoveComma,
} from "../../../../../../../../common/commonUtil";
import config from "../../../../../../../../config";

interface Props {
  isReadOnly: boolean;
  data?: SBPContentsPropsV2;
  onChangeData?: (data: SBPContentsPropsV2) => void;
}

export const SBPStatus: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;
  const [isCheck, updateIsCheck] = useState<boolean>(false);
  const [noEventGovTask, updateNoEventGovTask] = useState<boolean>(false);
  const [noEventFundBorrowed, updateNoEventFundBorrowed] =
    useState<boolean>(false);

  const [totalGovFunding, updateTotalGovFunding] = useState<number>(0);
  const [totalBorrowedAmount, updateTotalBorrowedAmount] = useState<number>(0);
  const [totalOutstandingBalance, updateTotalOutstandingBalance] =
    useState<number>(0);

  const getYear = (year?: number, quater?: number) => {
    if (!year || !quater) return "YYYY";
    return quater === 2 ? `${year}` : `${year - 1}`;
  };

  const getMonthRange = (quater?: number) => {
    if (!quater) return "Error";
    return quater === 2 ? "1 - 6" : "1 - 12";
  };

  const getMonth = (quater?: number) => {
    if (!quater) return "Error";
    return quater === 2 ? "6" : "12";
  };

  useEffect(() => {
    if (data) {
      updateIsCheck(data.businessStatus.employeeTurnover.noEventClevel);
      updateNoEventFundBorrowed(data.businessStatus.noEventFundBorrowed);
      updateNoEventGovTask(data.businessStatus.noEventGovTask);

      updateTotalGovFunding(
        data.businessStatus.govTasks
          .map((item) => item.govFunding || 0)
          .reduce((prev, cur) => prev + cur, 0)
      );
      updateTotalBorrowedAmount(
        data.businessStatus.fundBorrowed
          .map((item) => item.borrowedAmount || 0)
          .reduce((prev, cur) => prev + cur, 0)
      );
      updateTotalOutstandingBalance(
        data.businessStatus.fundBorrowed
          .map((item) => item.outstandingBalance || 0)
          .reduce((prev, cur) => prev + cur, 0)
      );
    }
  }, [data]);

  if (!data) return null;
  return (
    <Container>
      <SBPSector title="사업진행" id="status1">
        <FlexColumn style={{ gap: 8 }}>
          <FlexColumn
            style={{
              padding: 28,
              borderRadius: 4,
              gap: 24,
              backgroundColor: "var(--background-contents01)",
              border: "1px solid var(--gray-300)",
            }}
          >
            <RowTitleBox
              title="솔루션, 개발관점"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                defaultData={data.businessStatus.solutionAndDevlopPoint}
              />
            </RowTitleBox>
            <RowTitleBox
              title="사업관점"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                defaultData={data.businessStatus.businessPoint}
              />
            </RowTitleBox>
          </FlexColumn>
        </FlexColumn>
      </SBPSector>

      <SBPSector title="정부 지원 과제 참여" id="status3">
        <FlexColumn style={{ gap: 8 }}>
          <GovermentTaskComponent
            isReadOnly={props.isReadOnly}
            disabled={noEventGovTask}
            govDataList={data.businessStatus.govTasks}
            onDataChange={(newGovData) => {}}
          />
          {!noEventGovTask && (
            <SBPSectorFooter>
              <FlexRow
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div />
                <FlexRow
                  style={{ alignItems: "center", gap: 12, marginTop: 4 }}
                >
                  <span
                    className="body-16-md"
                    style={{ color: "var(--text-04)" }}
                  >
                    총 정부 출연금
                  </span>
                  <span
                    className="heading-16-md"
                    style={{ color: "var(--text-01)" }}
                  >
                    {noEventGovTask
                      ? "해당없음"
                      : `${AddComma(totalGovFunding)} 원`}
                  </span>
                </FlexRow>
              </FlexRow>
            </SBPSectorFooter>
          )}
        </FlexColumn>
      </SBPSector>

      <SBPSector title="자금 차입" id="status4">
        <FlexColumn style={{ gap: 8 }}>
          <FundBorrowComponent
            isReadOnly={props.isReadOnly}
            disabled={noEventFundBorrowed}
            fundDataList={data.businessStatus.fundBorrowed}
            onDataChange={(newFundData) => {}}
          />
          {!noEventFundBorrowed && (
            <SBPSectorFooter>
              <FlexRow
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div />
                <FlexRow
                  style={{ alignItems: "center", gap: 24, marginTop: 4 }}
                >
                  <FlexRow style={{ alignItems: "center", gap: 12 }}>
                    <span
                      className="body-16-md"
                      style={{ color: "var(--text-04)" }}
                    >
                      총 차입 금액
                    </span>
                    <span
                      className="heading-16-md"
                      style={{ color: "var(--text-01)" }}
                    >
                      {noEventFundBorrowed
                        ? "해당없음"
                        : `${AddComma(totalBorrowedAmount)} 원`}
                    </span>
                  </FlexRow>
                  <FlexRow style={{ alignItems: "center", gap: 12 }}>
                    <span
                      className="body-16-md"
                      style={{ color: "var(--text-04)" }}
                    >
                      총 상환 후 잔액
                    </span>
                    <span
                      className="heading-16-md"
                      style={{ color: "var(--text-01)" }}
                    >
                      {noEventFundBorrowed
                        ? "해당없음"
                        : `${AddComma(totalOutstandingBalance)} 원`}
                    </span>
                  </FlexRow>
                </FlexRow>
              </FlexRow>
            </SBPSectorFooter>
          )}
        </FlexColumn>
      </SBPSector>

      <SBPSector title="인력변동" id="status5">
        <FlexColumn style={{ gap: 16 }}>
          <FlexColumn style={{ gap: 8 }}>
            <FlexColumn
              style={{
                padding: 28,
                borderRadius: 4,
                gap: 24,
                backgroundColor: "var(--background-contents01)",
                border: "1px solid var(--gray-300)",
              }}
            >
              <FlexColumn
                style={{
                  gap: 12,
                }}
              >
                <div
                  className="heading-16-sb"
                  style={{ color: "var(--text-03)" }}
                >{`${
                  data.year
                    ? data.quarter === 2
                      ? data.year - 1
                      : data.year
                    : "YYYY"
                }년 ${
                  data.quarter ? (data.quarter === 2 ? "12" : "6") : "Error"
                }월말`}</div>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="임직원 수"
                  titleWidth={108 + 50}
                  transfromPixel={(n) => n}
                >
                  <InputBox
                    textType="numberWithComma"
                    readOnly={props.isReadOnly}
                    transfromPixel={(n) => n}
                    unit="명"
                    width={77}
                    placeholder="0"
                    defaultValue={
                      data.businessStatus.employeeTurnover.curEmployee !==
                      undefined
                        ? `${data.businessStatus.employeeTurnover.curEmployee}`
                        : undefined
                    }
                  />
                </RowTitleBox>
              </FlexColumn>

              <FlexRow>
                <FlexColumn
                  style={{
                    flex: 1,
                    gap: 12,
                  }}
                >
                  <div
                    className="heading-16-sb"
                    style={{ color: "var(--text-03)" }}
                  >{`${
                    data.year && data.quarter
                      ? data.quarter === 2
                        ? data.year
                        : data.year
                      : "YYYY"
                  }년 ${
                    data.quarter
                      ? data.quarter === 2
                        ? "1 - 6"
                        : "7 - 12"
                      : "Error"
                  }월`}</div>
                  <RowTitleBox
                    textClassName="heading-14-md"
                    title="입사자 수"
                    titleWidth={108 + 50}
                    transfromPixel={(n) => n}
                  >
                    <InputBox
                      textType="numberWithComma"
                      readOnly={props.isReadOnly}
                      transfromPixel={(n) => n}
                      unit="명"
                      width={77}
                      placeholder="0"
                      defaultValue={
                        data.businessStatus.employeeTurnover
                          .employeeOnboarding !== undefined
                          ? `${data.businessStatus.employeeTurnover.employeeOnboarding}`
                          : undefined
                      }
                    />
                  </RowTitleBox>
                </FlexColumn>
                <FlexColumn
                  style={{
                    flex: 1,
                    gap: 12,
                  }}
                >
                  <div
                    className="heading-16-sb"
                    style={{ opacity: 0, cursor: "default" }}
                  >{`{{N-1}}년 {7}-{12}월 `}</div>
                  <RowTitleBox
                    textClassName="heading-14-md"
                    title="퇴사자 수"
                    titleWidth={108 + 50}
                    transfromPixel={(n) => n}
                  >
                    <InputBox
                      textType="numberWithComma"
                      readOnly={props.isReadOnly}
                      transfromPixel={(n) => n}
                      unit="명"
                      width={77}
                      placeholder="0"
                      defaultValue={
                        data.businessStatus.employeeTurnover
                          .employeeAttrition !== undefined
                          ? `${data.businessStatus.employeeTurnover.employeeAttrition}`
                          : undefined
                      }
                    />
                  </RowTitleBox>
                </FlexColumn>
                <FlexColumn
                  style={{
                    flex: 1,
                    gap: 12,
                  }}
                >
                  <div
                    className="heading-16-sb"
                    style={{ color: "var(--text-03)" }}
                  >{`${
                    data.year && data.quarter
                      ? data.quarter === 2
                        ? data.year
                        : data.year
                      : "YYYY"
                  }년 ${
                    data.quarter ? (data.quarter === 2 ? "6" : "12") : "Error"
                  }월말`}</div>
                  <RowTitleBox
                    textClassName="heading-14-md"
                    title="임직원 수"
                    titleWidth={108 + 50}
                    transfromPixel={(n) => n}
                  >
                    <InputBox
                      readOnly
                      textType="numberWithComma"
                      transfromPixel={(n) => n}
                      unit="명"
                      width={77}
                      textValue={`${
                        (data.businessStatus.employeeTurnover.curEmployee ||
                          0) +
                        (data.businessStatus.employeeTurnover
                          .employeeOnboarding || 0) -
                        (data.businessStatus.employeeTurnover
                          .employeeAttrition || 0)
                      }`}
                    />
                  </RowTitleBox>
                </FlexColumn>
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn style={{ gap: 8 }}>
            <CLevelChangeComponent
              isReadOnly={props.isReadOnly}
              disabled={isCheck}
              cLevelDataList={
                data.businessStatus.employeeTurnover.cLevelChangeList || []
              }
              onDataChange={(newCLevelData) => {}}
            />
            {!props.isReadOnly && (
              <FlexRow
                style={{
                  gap: 12,
                  marginTop: 4,
                  paddingLeft: 20,
                  cursor: "pointer",
                  width: "fit-content",
                }}
                onClick={() => {
                  updateIsCheck(!isCheck);
                  if (onChangeData) {
                    data.businessStatus.employeeTurnover.noEventClevel =
                      !isCheck;
                    onChangeData(data);
                  }
                }}
              >
                <CheckWrapper>
                  <Check className={`check__box ${isCheck ? "checked" : ""}`} />
                </CheckWrapper>
                <span
                  className="body-14-rg"
                  style={{ color: "var(--text-03)" }}
                >
                  입사 혹은 퇴사를 한 등기임원 및 경영진이 없어요.
                </span>
              </FlexRow>
            )}
          </FlexColumn>
        </FlexColumn>
      </SBPSector>

      <SBPSector
        title={`${config.companyName} 투자 후 누적 출원 및 등록`}
        id="status5"
      >
        <FlexColumn
          style={{
            padding: 28,
            borderRadius: 4,
            gap: 24,
            backgroundColor: "var(--background-contents01)",
            border: "1px solid var(--gray-300)",
          }}
        >
          <FlexColumn
            style={{
              gap: 12,
            }}
          >
            <div className="heading-16-sb" style={{ color: "var(--text-03)" }}>
              누적 출원 및 등록
            </div>
            <FlexRow style={{ justifyContent: "space-between" }}>
              <RowTitleBox
                textClassName="heading-14-md"
                title="국내 출원"
                titleWidth={100}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly || data.hasPastData}
                  transfromPixel={(n) => n}
                  unit="건"
                  width={77}
                  placeholder="0"
                  defaultValue={
                    data.businessStatus.patentInfo.accumulatePatent &&
                    data.businessStatus.patentInfo.accumulatePatent
                      .domesticRegistration !== undefined
                      ? `${data.businessStatus.patentInfo.accumulatePatent.domesticRegistration}`
                      : undefined
                  }
                />
              </RowTitleBox>
              <RowTitleBox
                textClassName="heading-14-md"
                title="국내 등록"
                titleWidth={100}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly || data.hasPastData}
                  transfromPixel={(n) => n}
                  unit="건"
                  width={77}
                  placeholder="0"
                  defaultValue={
                    data.businessStatus.patentInfo.accumulatePatent &&
                    data.businessStatus.patentInfo.accumulatePatent
                      .domesticApplication !== undefined
                      ? `${data.businessStatus.patentInfo.accumulatePatent.domesticApplication}`
                      : undefined
                  }
                />
              </RowTitleBox>
              <RowTitleBox
                textClassName="heading-14-md"
                title="해외 출원"
                titleWidth={100}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly || data.hasPastData}
                  transfromPixel={(n) => n}
                  unit="건"
                  width={77}
                  placeholder="0"
                  defaultValue={
                    data.businessStatus.patentInfo.accumulatePatent &&
                    data.businessStatus.patentInfo.accumulatePatent
                      .internationalRegistration !== undefined
                      ? `${data.businessStatus.patentInfo.accumulatePatent.internationalRegistration}`
                      : undefined
                  }
                />
              </RowTitleBox>
              <RowTitleBox
                textClassName="heading-14-md"
                title="해외 등록"
                titleWidth={100}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly || data.hasPastData}
                  transfromPixel={(n) => n}
                  unit="건"
                  width={77}
                  placeholder="0"
                  defaultValue={
                    data.businessStatus.patentInfo.accumulatePatent &&
                    data.businessStatus.patentInfo.accumulatePatent
                      .internationalApplication !== undefined
                      ? `${data.businessStatus.patentInfo.accumulatePatent.internationalApplication}`
                      : undefined
                  }
                />
              </RowTitleBox>
            </FlexRow>
          </FlexColumn>
          {data.hasPastData && (
            <FlexColumn
              style={{
                gap: 12,
              }}
            >
              <div
                className="heading-16-sb"
                style={{ color: "var(--text-03)" }}
              >
                신규 출원 및 등록
              </div>
              <FlexRow style={{ justifyContent: "space-between" }}>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="국내 출원"
                  titleWidth={100}
                  transfromPixel={(n) => n}
                >
                  <InputBox
                    textType="numberWithComma"
                    readOnly={props.isReadOnly}
                    transfromPixel={(n) => n}
                    unit="건"
                    width={77}
                    placeholder="0"
                    defaultValue={
                      data.businessStatus.patentInfo.newPatent &&
                      data.businessStatus.patentInfo.newPatent
                        .domesticRegistration !== undefined
                        ? `${data.businessStatus.patentInfo.newPatent.domesticRegistration}`
                        : undefined
                    }
                  />
                </RowTitleBox>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="국내 등록"
                  titleWidth={100}
                  transfromPixel={(n) => n}
                >
                  <InputBox
                    textType="numberWithComma"
                    readOnly={props.isReadOnly}
                    transfromPixel={(n) => n}
                    unit="건"
                    width={77}
                    placeholder="0"
                    defaultValue={
                      data.businessStatus.patentInfo.newPatent &&
                      data.businessStatus.patentInfo.newPatent
                        .domesticApplication !== undefined
                        ? `${data.businessStatus.patentInfo.newPatent.domesticApplication}`
                        : undefined
                    }
                  />
                </RowTitleBox>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="해외 출원"
                  titleWidth={100}
                  transfromPixel={(n) => n}
                >
                  <InputBox
                    textType="numberWithComma"
                    readOnly={props.isReadOnly}
                    transfromPixel={(n) => n}
                    unit="건"
                    width={77}
                    placeholder="0"
                    defaultValue={
                      data.businessStatus.patentInfo.newPatent &&
                      data.businessStatus.patentInfo.newPatent
                        .internationalRegistration !== undefined
                        ? `${data.businessStatus.patentInfo.newPatent.internationalRegistration}`
                        : undefined
                    }
                  />
                </RowTitleBox>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="해외 등록"
                  titleWidth={100}
                  transfromPixel={(n) => n}
                >
                  <InputBox
                    textType="numberWithComma"
                    readOnly={props.isReadOnly}
                    transfromPixel={(n) => n}
                    unit="건"
                    width={77}
                    placeholder="0"
                    defaultValue={
                      data.businessStatus.patentInfo.newPatent &&
                      data.businessStatus.patentInfo.newPatent
                        .internationalApplication !== undefined
                        ? `${data.businessStatus.patentInfo.newPatent.internationalApplication}`
                        : undefined
                    }
                  />
                </RowTitleBox>
              </FlexRow>
            </FlexColumn>
          )}
        </FlexColumn>
      </SBPSector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--gray-700);
        stroke: var(--gray-700);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--gray-700);
        }
      }
    }
  }
`;
