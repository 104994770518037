import React from "react";
import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import styled from "styled-components";

import { gapi } from "gapi-script";
import usePortfolioDashboard from "../hook/usePortfolioDashboard";
import { FinancialStatement } from "./organism/FinancialStatement";
import { IncomeStatement } from "./organism/IncomeStatement";
import { EmployeeHistory } from "./organism/EmployeeHistory";
import { CompanyValueHistory } from "./organism/CompanyValueHistory";
import { CashflowStatement } from "./organism/CashflowStatement";

const DeskPortfolioDashboard: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const {
    portfolioId,
    portfolioNavInfo,
    isEditable,
    init,
    emptyData = [],
    duplicateData = [],
  } = props;

  const [
    financeHistory,
    employeeHistory,
    companyValueHistory,
    sbpData,
    serverTime,
  ] = usePortfolioDashboard();

  return (
    <Container>
      <ContentContainer id={"root__component"}>
        <ContentsWrapper>
          <FlexRow style={{ gap: 20, height: "100%" }}>
            <Flex1>
              <FinancialStatement
                financeHistory={financeHistory}
                serverTime={serverTime}
              />
            </Flex1>
            <Flex1>
              <EmployeeHistory
                employeeHistory={employeeHistory}
                serverTime={serverTime}
              />
            </Flex1>
          </FlexRow>
          <FlexRow style={{ gap: 20, height: "100%" }}>
            <Flex1>
              <IncomeStatement
                financeHistory={financeHistory}
                serverTime={serverTime}
              />
            </Flex1>

            <Flex1>
              <CompanyValueHistory companyValueHistory={companyValueHistory} />
            </Flex1>
          </FlexRow>

          <FlexRow style={{ gap: 20, height: "100%" }}>
            <Flex1>
              <CashflowStatement sbpData={sbpData} />
            </Flex1>
            <Flex1></Flex1>
          </FlexRow>
        </ContentsWrapper>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  min-width: 960px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 36px;
  gap: 20px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Flex1 = styled.div`
  flex: 1;
`;

export default DeskPortfolioDashboard;
