import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  Channel,
  DatabaseHistory,
  IndustryTech,
  PortfolioPhaseProps,
  UserInfo,
} from "../../../../type/data";
import DatabaseChip from "../component/DatabaseChip";
import DatabaseDropdownCheckbox from "../component/DatabaseDropdownCheckbox";
import DatabaseIndustrialTechs from "./DatabaseIndustrialTechs";
import DefaultSearch from "../../../../components-2.0v/search/DefaultSearch";
import {
  IsAllSelectedType,
  SearchValueType,
} from "../../interface/Database.interface";

import { ReactComponent as Update } from "../../../../assets/image/ic_update.svg";
import { ReactComponent as Filter } from "../../../../assets/image/icon_filter.svg";
import { ReactComponent as Search } from "../../../../assets/image/new_icon_search.svg";

interface Props {
  searchObject: SearchValueType;
  setSearchObject: React.Dispatch<React.SetStateAction<SearchValueType>>;
  isAllSelectedObject: React.MutableRefObject<IsAllSelectedType>;
  history?: DatabaseHistory;
  industrialTechs: IndustryTech[];
  phaseDictionary: PortfolioPhaseProps[];
  participants: UserInfo[];
  channels: Channel[];
}

const FilterTotalSearch: React.FC<Props> = (props) => {
  const {
    searchObject,
    setSearchObject,
    isAllSelectedObject,
    history,
    industrialTechs,
    phaseDictionary,
    participants,
    channels,
  } = props;

  const [isAllIndustrySelected, setIsAllIndustrySelected] =
    useState<boolean>(true);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const [selectedDropdownId, setSelectedDropdownId] = useState<
    string | undefined
  >(undefined);
  const [showFileter, updateShowFilter] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>(
    history?.search?.keyword || ""
  );

  useEffect(() => {
    if (
      searchObject.keyword ||
      (searchObject.excavationChannel &&
        searchObject.excavationChannel.length > 0 &&
        searchObject.excavationChannel.length < channels.length) ||
      (searchObject.industry &&
        searchObject.industry.length > 0 &&
        searchObject.industry.length < industrialTechs.length) ||
      (searchObject.participant &&
        searchObject.participant.length > 0 &&
        searchObject.participant.length < participants.length) ||
      (searchObject.phase &&
        searchObject.phase.length > 0 &&
        searchObject.phase.length < phaseDictionary.length)
    ) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }

    if (
      searchObject.industry &&
      searchObject.industry.length > 0 &&
      searchObject.industry.length === industrialTechs.length
    ) {
      setIsAllIndustrySelected(true);
    } else {
      setIsAllIndustrySelected(false);
    }
  }, [searchObject]);

  const onClickRefresh = () => {
    setSearchObject({
      keyword: "",
      industry: industrialTechs,
      phase: phaseDictionary,
      participant: participants,
      excavationChannel: channels,
      curPage: 1,
    });
    setKeyword("");
  };

  return (
    <Container style={{ height: showFileter ? "100%" : undefined }}>
      <ComponentWrapper>
        <ColumnSection style={{ zIndex: 2 }}>
          <span className="heading-16-sb">데이터베이스</span>
          <Row style={{ gap: 10 }}>
            <DefaultSearch
              value={keyword}
              width={"100%"}
              placeholder="기업명으로 검색"
              onChangeValue={(value) => {
                setKeyword(value);
                if (value.length === 0) {
                  setSearchObject({
                    ...searchObject,
                    keyword: "",
                    curPage: 1,
                  });
                }
              }}
              onPressEnter={(value) => {
                setKeyword(value);
                setSearchObject({
                  ...searchObject,
                  keyword: value,
                  curPage: 1,
                });
              }}
              transfromPixel={(n) => n}
            />
            {keyword.length > 0 && (
              <Row
                style={{
                  width: 32,
                  height: 32,
                  minWidth: 32,
                  minHeight: 32,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() =>
                  setSearchObject({
                    ...searchObject,
                    keyword: keyword,
                    curPage: 1,
                  })
                }
              >
                <Search width={20} height={20} />
              </Row>
            )}
            <Row
              style={{
                width: 32,
                height: 32,
                minWidth: 32,
                minHeight: 32,
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => updateShowFilter((prev) => !prev)}
            >
              <Filter
                className={showFileter ? "filter__open" : ""}
                width={24}
                height={24}
              />
            </Row>
          </Row>
        </ColumnSection>
        <FilterContainer className={showFileter ? "show" : ""}>
          <ColumnSection>
            <span className="label font-medium-12">산업기술분류</span>
            <DatabaseIndustrialTechs
              selectedIndustrialTechs={searchObject.industry}
              onSelectedIndustrialTechs={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  industry: data,
                  curPage: 1,
                }));
              }}
              setIsAllIndustrySelected={setIsAllIndustrySelected}
              isAllIndustrySelected={isAllIndustrySelected}
              industrialTechs={industrialTechs}
            />
          </ColumnSection>
          <ColumnSection>
            <span className="label font-medium-12">기업상태</span>

            <DatabaseDropdownCheckbox
              allItemList={phaseDictionary.map((data) => ({
                data: data,
                label: data.name || "",
              }))}
              selectedItemList={
                searchObject.phase?.map((data) => ({
                  data: data,
                  label: data.name || "",
                })) || []
              }
              isAllDataSelected={
                phaseDictionary.length === searchObject.phase?.length
              }
              isSame={(a, b) => {
                return a.pfPhaseId === b.pfPhaseId;
              }}
              isIncludesValue={(value, data) => {
                return data.name.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                console.log(dataList);

                setSearchObject((prev) => ({
                  ...prev,
                  phase: dataList.length === 0 ? phaseDictionary : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="phase"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </ColumnSection>
          <ColumnSection>
            <span className="label font-medium-12">담당자(조력자)</span>

            <DatabaseDropdownCheckbox
              allItemList={participants.map((data) => ({
                data: data,
                label: data.name || "",
              }))}
              selectedItemList={
                searchObject.participant?.map((data) => ({
                  data: data,
                  label: data.name || "",
                })) || []
              }
              isAllDataSelected={
                participants.length === searchObject.participant?.length
              }
              isSame={(a, b) => {
                return a.acUserId === b.acUserId;
              }}
              isIncludesValue={(value, data) => {
                return data.name.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                console.log(dataList);

                setSearchObject((prev) => ({
                  ...prev,
                  participant: dataList.length === 0 ? participants : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="participants"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </ColumnSection>
          <ColumnSection>
            <span className="label font-medium-12">발굴채널</span>
            <DatabaseDropdownCheckbox
              allItemList={channels.map((data) => ({
                data: data,
                label: data.name || "",
              }))}
              selectedItemList={
                searchObject.excavationChannel?.map((data) => ({
                  data: data,
                  label: data.name || "",
                })) || []
              }
              isAllDataSelected={
                channels.length === searchObject.excavationChannel?.length
              }
              isSame={(a, b) => {
                return a.acChannelId === b.acChannelId;
              }}
              isIncludesValue={(value, data) => {
                return data.name.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                console.log(dataList);

                setSearchObject((prev) => ({
                  ...prev,
                  excavationChannel:
                    dataList.length === 0 ? channels : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="excavationChannel"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </ColumnSection>
          <SearchKeywordSection isSelected={isSelected}>
            <div className="chip-section">
              {searchObject?.industry &&
                searchObject.industry.length > 0 &&
                !isAllIndustrySelected &&
                searchObject.industry.map((industry, index) => (
                  <div
                    key={index}
                    className={`element-chip ${index === 0 ? "first" : ""}`}
                  >
                    <DatabaseChip
                      type="keyword-general"
                      data={industry}
                      label={industry.name}
                      key={industry.name}
                      onClickDelete={(data) => {
                        setSearchObject((prev) => ({
                          ...prev,
                          industry: prev?.industry?.filter(
                            (item) =>
                              item.acIndustrialTechId !==
                              data.acIndustrialTechId
                          ),
                          curPage: 1,
                        }));
                      }}
                    />
                  </div>
                ))}
              {searchObject?.phase &&
                searchObject.phase.length > 0 &&
                phaseDictionary.length !== searchObject.phase?.length &&
                searchObject.phase.map((phase, index) => (
                  <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                    <DatabaseChip
                      type="keyword-general"
                      data={phase}
                      label={phase.name || `${phase}`}
                      key={phase.name || `${phase}`}
                      onClickDelete={(data) => {
                        setSearchObject((prev) => {
                          const prevItem = prev?.phase?.filter(
                            (item) => item.pfPhaseId !== data.pfPhaseId
                          );
                          const updatedItem =
                            prevItem && prevItem.length > 0
                              ? prevItem
                              : [...phaseDictionary];
                          return {
                            ...prev,
                            phase: updatedItem,
                            curPage: 1,
                          };
                        });
                      }}
                    />
                  </div>
                ))}
              {searchObject?.participant &&
                searchObject.participant.length > 0 &&
                participants.length !== searchObject.participant?.length &&
                searchObject.participant?.map((participant, index) => (
                  <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                    <DatabaseChip
                      type="keyword-general"
                      data={participant}
                      label={participant.name || `${participant}`}
                      key={participant.name || `${participant}`}
                      onClickDelete={(data) => {
                        setSearchObject((prev) => {
                          const prevItem = prev?.participant?.filter(
                            (item) => item.acUserId !== data.acUserId
                          );
                          const updatedItem =
                            prevItem && prevItem.length > 0
                              ? prevItem
                              : [...participants];
                          return {
                            ...prev,
                            participant: updatedItem,
                            curPage: 1,
                          };
                        });
                      }}
                    />
                  </div>
                ))}
              {searchObject?.excavationChannel &&
                searchObject.excavationChannel.length > 0 &&
                channels.length !== searchObject.excavationChannel.length &&
                searchObject.excavationChannel?.map((channel, index) => (
                  <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                    <DatabaseChip
                      type="keyword-general"
                      data={channel}
                      label={channel.name || `${channel}`}
                      key={channel.name || `${channel}`}
                      onClickDelete={(data) => {
                        setSearchObject((prev) => {
                          const prevItem = prev?.excavationChannel?.filter(
                            (item) => item.acChannelId !== data.acChannelId
                          );
                          const updatedItem =
                            prevItem && prevItem.length > 0
                              ? prevItem
                              : [...channels];
                          return {
                            ...prev,
                            excavationChannel: updatedItem,
                            curPage: 1,
                          };
                        });
                      }}
                    />
                  </div>
                ))}
            </div>
            <div className="refresh-section">
              <div
                className="update-container"
                onClick={() => {
                  if (isSelected) {
                    onClickRefresh();
                  }
                }}
              >
                <Update />
                <span className="font-regular-12">초기화</span>
              </div>
            </div>
          </SearchKeywordSection>
          <Overlay onClick={() => updateShowFilter(false)}></Overlay>
        </FilterContainer>
      </ComponentWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
`;
const ComponentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .filter__open {
    path {
      fill: var(--primary-blue);
    }
  }
`;

const FilterContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 87px);
  top: -100vh;
  left: 0px;
  display: flex;
  flex-direction: column;
  transition: top 0.2s ease-in;
  &.show {
    top: 87px;
    transition: top 0.2s ease-in-out;
  }
`;

const Overlay = styled.div`
  width: 100%;
  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0.3);
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ColumnSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-bottom: 1px solid var(--gray-300);
  background: var(--white);
  gap: 8px;
`;

const SearchKeywordSection = styled.div(
  ({ isSelected }: { isSelected: boolean }) => css`
    width: 100%;
    height: max-content;
    background: #f1f3f5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    display: flex;
    flex-direction: row;

    padding: 16px 16px 32px 16px;

    position: relative;

    .chip-section {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-flow: wrap;
      gap: 4px;
      .element-chip.first {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:not(:first-of-type)::before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 16px;
          margin: 0 8px 0 4px;
          background: var(--grey_10);
        }
      }
    }
    .refresh-section {
      width: 70px;
      position: absolute;
      right: 0px;
      bottom: 8px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .update-container {
        cursor: ${isSelected ? "pointer" : "default"};
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        span {
          color: ${isSelected ? "var(--primary)" : "#ABBBCD"};
        }
        path {
          fill: ${isSelected ? "var(--primary)" : "#ABBBCD"};
        }
      }
    }
  `
);

export default FilterTotalSearch;
