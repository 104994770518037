import {
  AcceleratingHistory,
  DashboardHistory,
  DatabaseHistory,
  DealFlowHistory,
  NotificationHistory,
  PageHistory,
  ReportingHistory,
  TotalEvaluationHistory,
} from "../type/data";

export const initAllPageHistory = (): void => {
  if (localStorage.getItem("pageHistory")) {
    localStorage.removeItem("pageHistory");
  }

  const pageHistory: PageHistory = {
    dealFlow: {
      search: {
        keyword: undefined,
        filter: undefined,
        sort: undefined,
        scrollTop: undefined,
      },
    },
    accelerating: {
      search: {
        keyword: undefined,
        filter: undefined,
        sort: undefined,
      },
    },
    reporting: {
      search: {
        year: undefined,
        quarter: undefined,
        keyword: undefined,
        sortItem: undefined,
        sortType: undefined,
        scrollTop: undefined,
      },
    },
    dashboard: {
      fundAccount: undefined,
      team: undefined,
      user: undefined,
    },
    database: {
      tab: undefined,
      search: {
        keyword: undefined,
        acIndustrialTechId: undefined,
        pfPhase: undefined,
        acUser: undefined,
        acChannel: undefined,

        acFundAccountId: undefined,
        roundId: undefined,
        addr1: undefined,
        minInvestmentYear: undefined,
        maxInvestmentYear: undefined,
        minInvestmentQuarter: undefined,
        maxInvestmentQuarter: undefined,
        minPurchasePrincipal: undefined,
        maxPurchasePrincipal: undefined,
        minCompanyValue: undefined,
        maxCompanyValue: undefined,
        minSalesAmount: undefined,
        maxSalesAmount: undefined,
        minOwnershipPercentage: undefined,
        maxOwnershipPercentage: undefined,
        minRealizedMultiple: undefined,
        maxRealizedMultiple: undefined,
        minUnrealizedMultiple: undefined,
        maxUnrealizedMultiple: undefined,

        minSellPrincipal: undefined,
        maxSellPrincipal: undefined,
        minExitDate: undefined,
        maxExitDate: undefined,

        periodType: undefined,
        minPeriod: undefined,
        maxPeriod: undefined,
      },
      page: undefined,
    },
    totalHistory: {
      search: {
        year: undefined,
        quarter: undefined,
        riskIssue: undefined,
        riskStatus: undefined,
        structure: undefined,
        keyword: undefined,
        scrollTop: undefined,
      },
    },
  };
  localStorage.setItem("pageHistory", JSON.stringify(pageHistory));
};

export const removeAllPageHistory = (): void => {
  localStorage.removeItem("pageHistory");
};

// DealFlow

export const setDealFlowHistory = (value: DealFlowHistory): void => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );
  const dealflow = {
    search: { ...pageHistory.dealFlow?.search, ...value.search },
  };
  const newPageHistory: PageHistory = {
    ...pageHistory,
    dealFlow: dealflow,
  };
  localStorage.removeItem("pageHistory");
  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

export const getDealFlowHistory = (): DealFlowHistory | undefined => {
  if (!localStorage.getItem("pageHistory")) return;

  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );
  console.log(pageHistory.dealFlow);

  return pageHistory.dealFlow || {};
};

export const initDealFlowHistory = (): void => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    dealFlow: {
      search: undefined,
    },
  };

  console.log(newPageHistory);

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

// Accelerating

export const setAcceleratingHistory = (value: AcceleratingHistory) => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const accelerating = {
    search: { ...pageHistory.accelerating?.search, ...value.search },
  };
  const newPageHistory: PageHistory = {
    ...pageHistory,
    accelerating: accelerating,
  };
  localStorage.removeItem("pageHistory");
  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

export const getAcceleratingHistory = (): AcceleratingHistory | undefined => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  console.log(pageHistory.accelerating);

  return pageHistory.accelerating || {};
};

export const initAcceleratingHistory = (): void => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    accelerating: {
      search: undefined,
    },
  };

  console.log(newPageHistory);

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

// Reporting
export const setReportingHistory = (value: ReportingHistory) => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    reporting: {
      search: {
        year:
          value.search?.year === undefined || value.search?.year === null
            ? pageHistory.reporting?.search?.year
            : value.search?.year,
        quarter:
          value.search?.quarter === undefined || value.search?.quarter === null
            ? pageHistory.reporting?.search?.quarter
            : value.search?.quarter,
        keyword:
          value.search?.keyword === undefined || value.search?.keyword === null
            ? pageHistory.reporting?.search?.keyword
            : value.search?.keyword,
        sortItem:
          value.search?.sortItem === undefined ||
          value.search?.sortItem === null
            ? pageHistory.reporting?.search?.sortItem
            : value.search?.sortItem,
        sortType:
          value.search?.sortType === undefined ||
          value.search?.sortType === null
            ? pageHistory.reporting?.search?.sortType
            : value.search?.sortType,
        scrollTop:
          value.search?.scrollTop === undefined ||
          value.search?.scrollTop === null
            ? pageHistory.reporting?.search?.scrollTop
            : value.search?.scrollTop,
      },
    },
  };

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

export const getReportingHistory = (): ReportingHistory | undefined => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  console.log(pageHistory.reporting);

  return pageHistory.reporting || {};
};

export const initReportingHistory = (): void => {
  if (!localStorage.getItem("pageHistory")) return;

  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    reporting: {
      search: {
        year: undefined,
        quarter: undefined,
        keyword: undefined,
        sortItem: undefined,
        sortType: undefined,
        scrollTop: undefined,
      },
    },
  };

  console.log(newPageHistory);

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

// Dashboard

export const setDashboardHistory = (value: DashboardHistory) => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    dashboard: {
      fundAccount:
        value.fundAccount === undefined || value.fundAccount === null
          ? pageHistory.dashboard?.fundAccount
          : value.fundAccount,
      team:
        value.team === undefined || value.team === null
          ? pageHistory.dashboard?.team
          : value.team,
      user:
        value.user === undefined || value.user === null
          ? pageHistory.dashboard?.user
          : value.user,
    },
  };

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

export const getDashboardHistory = (): DashboardHistory | undefined => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  console.log(pageHistory.dashboard);

  return pageHistory.dashboard || {};
};

export const initDashboardHistory = (): void => {
  if (!localStorage.getItem("pageHistory")) return;

  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    dashboard: {
      fundAccount: undefined,
      team: undefined,
      user: undefined,
    },
  };

  console.log(newPageHistory);

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

// Database

export const setDatabaseHistory = (value: DatabaseHistory) => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    database: {
      tab:
        value.tab === undefined || value.tab === null
          ? pageHistory.database?.tab
          : value.tab,
      search: {
        keyword:
          value.search?.keyword === undefined || value.search?.keyword === null
            ? pageHistory.database?.search?.keyword
            : value.search?.keyword,
        acIndustrialTechId:
          value.search?.acIndustrialTechId === undefined ||
          value.search?.acIndustrialTechId === null
            ? pageHistory.database?.search?.acIndustrialTechId
            : value.search?.acIndustrialTechId,
        pfPhase:
          value.search?.pfPhase === undefined || value.search?.pfPhase === null
            ? pageHistory.database?.search?.pfPhase
            : value.search?.pfPhase,
        acUser:
          value.search?.acUser === undefined || value.search?.acUser === null
            ? pageHistory.database?.search?.acUser
            : value.search?.acUser,
        acChannel:
          value.search?.acChannel === undefined ||
          value.search?.acChannel === null
            ? pageHistory.database?.search?.acChannel
            : value.search?.acChannel,

        acFundAccountId:
          value.search?.acFundAccountId === undefined ||
          value.search?.acFundAccountId === null
            ? pageHistory.database?.search?.acFundAccountId
            : value.search?.acFundAccountId,
        roundId:
          value.search?.roundId === undefined || value.search?.roundId === null
            ? pageHistory.database?.search?.roundId
            : value.search?.roundId,
        addr1:
          value.search?.addr1 === undefined || value.search?.addr1 === null
            ? pageHistory.database?.search?.addr1
            : value.search?.addr1,
        minInvestmentYear:
          value.search?.minInvestmentYear === undefined ||
          value.search?.minInvestmentYear === null
            ? pageHistory.database?.search?.minInvestmentYear
            : value.search?.minInvestmentYear,
        maxInvestmentYear:
          value.search?.maxInvestmentYear === undefined ||
          value.search?.maxInvestmentYear === null
            ? pageHistory.database?.search?.maxInvestmentYear
            : value.search?.maxInvestmentYear,
        minInvestmentQuarter:
          value.search?.minInvestmentQuarter === undefined ||
          value.search?.minInvestmentQuarter === null
            ? pageHistory.database?.search?.minInvestmentQuarter
            : value.search?.minInvestmentQuarter,
        maxInvestmentQuarter:
          value.search?.maxInvestmentQuarter === undefined ||
          value.search?.maxInvestmentQuarter === null
            ? pageHistory.database?.search?.maxInvestmentQuarter
            : value.search?.maxInvestmentQuarter,
        minPurchasePrincipal:
          value.search?.minPurchasePrincipal === undefined ||
          value.search?.minPurchasePrincipal === null
            ? pageHistory.database?.search?.minPurchasePrincipal
            : value.search?.minPurchasePrincipal,
        maxPurchasePrincipal:
          value.search?.maxPurchasePrincipal === undefined ||
          value.search?.maxPurchasePrincipal === null
            ? pageHistory.database?.search?.maxPurchasePrincipal
            : value.search?.maxPurchasePrincipal,
        minCompanyValue:
          value.search?.minCompanyValue === undefined ||
          value.search?.minCompanyValue === null
            ? pageHistory.database?.search?.minCompanyValue
            : value.search?.minCompanyValue,
        maxCompanyValue:
          value.search?.maxCompanyValue === undefined ||
          value.search?.maxCompanyValue === null
            ? pageHistory.database?.search?.maxCompanyValue
            : value.search?.maxCompanyValue,
        minSalesAmount:
          value.search?.minSalesAmount === undefined ||
          value.search?.minSalesAmount === null
            ? pageHistory.database?.search?.minSalesAmount
            : value.search?.minSalesAmount,
        maxSalesAmount:
          value.search?.maxSalesAmount === undefined ||
          value.search?.maxSalesAmount === null
            ? pageHistory.database?.search?.maxSalesAmount
            : value.search?.maxSalesAmount,
        minOwnershipPercentage:
          value.search?.minOwnershipPercentage === undefined ||
          value.search?.minOwnershipPercentage === null
            ? pageHistory.database?.search?.minOwnershipPercentage
            : value.search?.minOwnershipPercentage,
        maxOwnershipPercentage:
          value.search?.maxOwnershipPercentage === undefined ||
          value.search?.maxOwnershipPercentage === null
            ? pageHistory.database?.search?.maxOwnershipPercentage
            : value.search?.maxOwnershipPercentage,
        minRealizedMultiple:
          value.search?.minRealizedMultiple === undefined ||
          value.search?.minRealizedMultiple === null
            ? pageHistory.database?.search?.minRealizedMultiple
            : value.search?.minRealizedMultiple,
        maxRealizedMultiple:
          value.search?.maxRealizedMultiple === undefined ||
          value.search?.maxRealizedMultiple === null
            ? pageHistory.database?.search?.maxRealizedMultiple
            : value.search?.maxRealizedMultiple,
        minUnrealizedMultiple:
          value.search?.minUnrealizedMultiple === undefined ||
          value.search?.minUnrealizedMultiple === null
            ? pageHistory.database?.search?.minUnrealizedMultiple
            : value.search?.minUnrealizedMultiple,
        maxUnrealizedMultiple:
          value.search?.maxUnrealizedMultiple === undefined ||
          value.search?.maxUnrealizedMultiple === null
            ? pageHistory.database?.search?.maxUnrealizedMultiple
            : value.search?.maxUnrealizedMultiple,

        minSellPrincipal:
          value.search?.minSellPrincipal === undefined ||
          value.search?.minSellPrincipal === null
            ? pageHistory.database?.search?.minSellPrincipal
            : value.search?.minSellPrincipal,
        maxSellPrincipal:
          value.search?.maxSellPrincipal === undefined ||
          value.search?.maxSellPrincipal === null
            ? pageHistory.database?.search?.maxSellPrincipal
            : value.search?.maxSellPrincipal,
        minExitDate:
          value.search?.minExitDate === undefined ||
          value.search?.minExitDate === null
            ? pageHistory.database?.search?.minExitDate
            : value.search?.minExitDate,
        maxExitDate:
          value.search?.maxExitDate === undefined ||
          value.search?.maxExitDate === null
            ? pageHistory.database?.search?.maxExitDate
            : value.search?.maxExitDate,

        periodType:
          value.search?.periodType === undefined ||
          value.search?.periodType === null
            ? pageHistory.database?.search?.periodType
            : value.search?.periodType,
        minPeriod:
          value.search?.minPeriod === undefined ||
          value.search?.minPeriod === null
            ? pageHistory.database?.search?.minPeriod
            : value.search?.minPeriod,
        maxPeriod:
          value.search?.maxPeriod === undefined ||
          value.search?.maxPeriod === null
            ? pageHistory.database?.search?.maxPeriod
            : value.search?.maxPeriod,
        sortItem:
          value.search?.sortItem === undefined || value.search.sortItem === null
            ? pageHistory.database?.search?.sortItem
            : value.search.sortItem,
        sortType:
          value.search?.sortType === undefined || value.search.sortType === null
            ? pageHistory.database?.search?.sortType
            : value.search.sortType,
      },
      page: value.page || pageHistory.database?.page,
      detailOpen:
        value.detailOpen === undefined || value.detailOpen === null
          ? pageHistory.database?.detailOpen
          : value.detailOpen,
    },
  };

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

export const getDatabaseHistory = (): DatabaseHistory | undefined => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  console.log(pageHistory.database);

  return pageHistory.database || {};
};

export const initDatabaseHistory = (): void => {
  if (!localStorage.getItem("pageHistory")) return;

  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    database: {
      tab: undefined,
      search: {
        keyword: undefined,
        acIndustrialTechId: undefined,
        pfPhase: undefined,
        acUser: undefined,
        acChannel: undefined,

        acFundAccountId: undefined,
        roundId: undefined,
        addr1: undefined,
        minInvestmentYear: undefined,
        maxInvestmentYear: undefined,
        minInvestmentQuarter: undefined,
        maxInvestmentQuarter: undefined,
        minPurchasePrincipal: undefined,
        maxPurchasePrincipal: undefined,
        minCompanyValue: undefined,
        maxCompanyValue: undefined,
        minSalesAmount: undefined,
        maxSalesAmount: undefined,
        minOwnershipPercentage: undefined,
        maxOwnershipPercentage: undefined,
        minRealizedMultiple: undefined,
        maxRealizedMultiple: undefined,
        minUnrealizedMultiple: undefined,
        maxUnrealizedMultiple: undefined,

        minSellPrincipal: undefined,
        maxSellPrincipal: undefined,
        minExitDate: undefined,
        maxExitDate: undefined,

        periodType: undefined,
        minPeriod: undefined,
        maxPeriod: undefined,
      },
      page: undefined,
      detailOpen: undefined,
    },
  };

  console.log(newPageHistory);

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

export const setNotificationHistory = (value: NotificationHistory) => {
  if (localStorage.getItem("notificationHistory")) {
    localStorage.removeItem("notificationHistory");
  }
  localStorage.setItem("notificationHistory", JSON.stringify(value));
};

export const getNotificationHistory = (): NotificationHistory | undefined => {
  const notificationHistory = localStorage.getItem("notificationHistory");

  if (!notificationHistory) return undefined;
  return JSON.parse(notificationHistory);
};

export const setTotalEvaluationHistory = (value: TotalEvaluationHistory) => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    totalHistory: {
      search: {
        year:
          value.search?.year === undefined || value.search?.year === null
            ? pageHistory.totalHistory?.search?.year
            : value.search?.year,
        quarter:
          value.search?.quarter === undefined || value.search?.quarter === null
            ? pageHistory.totalHistory?.search?.quarter
            : value.search?.quarter,
        riskIssue:
          value.search?.riskIssue === undefined ||
          value.search?.riskIssue === null
            ? pageHistory.totalHistory?.search?.riskIssue
            : value.search?.riskIssue,
        riskStatus:
          value.search?.riskStatus === undefined ||
          value.search?.riskStatus === null
            ? pageHistory.totalHistory?.search?.riskStatus
            : value.search?.riskStatus,
        structure:
          value.search?.structure === undefined ||
          value.search?.structure === null
            ? pageHistory.totalHistory?.search?.structure
            : value.search?.structure,
        keyword:
          value.search?.keyword === undefined || value.search?.keyword === null
            ? pageHistory.totalHistory?.search?.keyword
            : value.search?.keyword,
        scrollTop:
          value.search?.scrollTop === undefined ||
          value.search?.scrollTop === null
            ? pageHistory.totalHistory?.search?.scrollTop
            : value.search?.scrollTop,
      },
    },
  };

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};

export const getTotalEvaluationHistory = ():
  | TotalEvaluationHistory
  | undefined => {
  if (!localStorage.getItem("pageHistory")) return;
  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  return pageHistory.totalHistory || {};
};

export const initTotalEvaluationHistory = (): void => {
  if (!localStorage.getItem("pageHistory")) return;

  const pageHistory: PageHistory = JSON.parse(
    localStorage.getItem("pageHistory") || ""
  );

  const newPageHistory: PageHistory = {
    ...pageHistory,
    totalHistory: {
      search: {
        year: undefined,
        quarter: undefined,
        riskIssue: undefined,
        riskStatus: undefined,
        structure: undefined,
        keyword: undefined,
        scrollTop: undefined,
      },
    },
  };

  localStorage.setItem("pageHistory", JSON.stringify(newPageHistory));
};
