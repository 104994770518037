import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import useInput from "../../../../../portfolio/evaluation-detail/useInput";
import {
  OnlyFloat,
  RemoveComma,
  addCommasToIntegerPart,
} from "../../../../../../common/commonUtil";

interface Props {
  title: string;
  readonly?: boolean;
  value?: string;
  currency?: string;
  isError?: boolean;
  placeholder?: string;
  onChangeData?: (value: string) => void;
}
export const FinancialStatementInput: React.FC<Props> = (props) => {
  const {
    title,
    readonly,
    value = "",
    isError,
    currency = "KRW",
    placeholder,
    onChangeData,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [data, updateData] = useState<string>("");
  const [isFocus, updateIsFocus] = useState<boolean>(false);
  const [getValueByType] = useInput();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readonly) return;

    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;

    if (e.target.value.length > 0) {
      const valueData = OnlyFloat(e.target.value);
      updateData(valueData);

      if (onChangeData) {
        onChangeData(valueData);
      }
      if (inputRef.current && start !== null && end !== null) {
        setTimeout(() => {
          inputRef.current!.setSelectionRange(start, end);
        }, 0);
      }
    } else {
      updateData("");
      if (onChangeData) {
        onChangeData("");
      }
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (readonly) return;
    updateIsFocus(true);

    if (data.length > 0) updateData(RemoveComma(data));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (readonly) return;

    updateIsFocus(false);
    const numberValue =
      e.target.value.length > 0 ? `${parseFloat(e.target.value)}` : "";
    if (numberValue.length > 0) {
      updateData(addCommasToIntegerPart(numberValue));
      onChangeData && onChangeData(numberValue);
    }
  };

  useEffect(() => {
    if (value && !isFocus) {
      updateData(addCommasToIntegerPart(value));
    }
  }, [value]);

  return (
    <Container>
      <span className="heading-14-md title">{title}</span>
      <FlexRow className="body-14-rg" style={{ gap: 8 }}>
        <span>{currency}</span>
        <CurrencyInput
          readOnly={readonly}
          className={`body-14-rg ${
            readonly ? "read__only" : isError ? "error" : ""
          }`}
          ref={inputRef}
          placeholder={placeholder}
          value={data}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </FlexRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: var(--text-01, #1f2022);

  .title {
    color: var(--text-04, #7e8086);
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CurrencyInput = styled.input`
  width: 140px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--gray-500, #e3e4e5);
  padding: 9px 12px;
  &.read__only {
    cursor: default;
    border: 1px solid transparent;
    :hover {
      border: 1px solid transparent;
    }
    :focus {
      border: 1px solid transparent;
    }
  }
  &.error {
    border: 1px solid var(--red-500);
    :hover {
      border: 1px solid var(--red-500);
    }
  }
  :hover {
    border: 1px solid var(--text-01);
  }
  :focus {
    outline: none;
    border: 1px solid var(--blue-400);
  }
`;
