import React from "react";
import styled from "styled-components";

interface Props {
  fitContent?: boolean;
  required?: boolean;
  className?: string;
  textClassName?: string;
  headerTextcolor?: string;
  style?: React.CSSProperties;
  title: string;
  titleWidth?: number;
  minHeight?: number;
  children: React.ReactNode;
  transfromPixel?: (value: number) => number;
}
const ColumnTitleBox: React.FC<Props> = (props) => {
  const {
    fitContent,
    required,
    className,
    textClassName = "heading-14-sb",
    headerTextcolor,
    style,
    title,
    minHeight = 40,
    children,
    transfromPixel = (n) => n,
  } = props;
  return (
    <Container
      className={className}
      transfromPixel={transfromPixel}
      style={{
        ...style,
        minHeight: transfromPixel(minHeight),
      }}
    >
      <Header
        className={textClassName}
        style={{ color: headerTextcolor ? headerTextcolor : `var(--text-04)` }}
        transfromPixel={transfromPixel}
      >
        <span>{title}</span>
        {required && <span style={{ color: "var(--primary-blue)" }}>﹡</span>}
      </Header>
      <Body transfromPixel={transfromPixel}>{children}</Body>
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  display: flex;
  flex-direction: column !important;
`;
const Header = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Body = styled.div<{ transfromPixel: (value: number) => number }>`
  flex-grow: 1;
  height: fit-content;
  padding-left: 10px;
`;
export default ColumnTitleBox;
