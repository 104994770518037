import React from "react";
import styled from "styled-components";

interface Props {
  step?: "validation" | "makeStockHolderList";
  text: string;
  isSelected: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClickStockHolder?: () => void;
}

const ValidationItem: React.FC<Props> = (props) => {
  return (
    <Container
      className={`body-14-rg ${props.isSelected ? "selected" : ""}`}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
    >
      {props.text.split("\n").map((item, index) => {
        return (
          <TextContainer>
            {item}
            {props.step &&
              props.step === "makeStockHolderList" &&
              props.text.split("\n").length - 1 === index && (
                <span
                  className="compaire__stock__holder__list"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onClickStockHolder && props.onClickStockHolder();
                  }}
                >
                  {" 주주명부 비교>"}
                </span>
              )}
          </TextContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #fafafb;
  border: 1px solid #edeeef;
  border-radius: 4px;

  transition: all 0.2s ease;
  cursor: pointer;

  &.selected {
    background-color: #fff2f3;
    color: #f6444f;
    border-color: #f6444f;
    font-weight: 500;
  }

  .compaire__stock__holder__list {
    width: fit-content;
    color: #173aff;
    cursor: pointer;
    transition: all 0.2s ease;
    :hover {
      text-decoration: underline;
    }
  }
`;

const TextContainer = styled.div`
  width: 100%;
  height: auto;
`;

export default ValidationItem;
