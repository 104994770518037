import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { FairValueAmountHeader } from "../fair-value-amount-table/FairValueAmountHeader";
import { FairValueAmountExportProps } from "../../interface/Portfolio.interface";
import { FairValueAmountRow } from "../fair-value-amount-table/FairValueAmountRow";

interface Props {
  fairValueData: FairValueAmountExportProps[];
  onDataChange: (newData: FairValueAmountExportProps[]) => void;
}

export const FairValueTable: React.FC<Props> = (props) => {
  const { fairValueData, onDataChange } = props;
  return (
    <Container>
      <FairValueAmountHeader />
      {fairValueData.map((item) => {
        return (
          <FairValueAmountRow
            data={item}
            onChangeRowData={(rowData: FairValueAmountExportProps) => {
              const newData = fairValueData.map((item) => {
                if (
                  item.year === rowData.year &&
                  item.acFundAccountId === rowData.acFundAccountId &&
                  item.portfolioId === rowData.portfolioId
                ) {
                  return rowData;
                }
                return item;
              });
              onDataChange(newData);
            }}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 0px 24px;
`;
