import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { DefaultButton } from "lattice_core";
import {
  FundAccount,
  FundPurpose,
  InvestmentAssociationProps,
  InvestmentAssociationTableProps,
} from "../../interface/FundAccount.interface";
import { AssociationTableComponent } from "../../component/AssociationTableComponent";
import { AcInvestmentOrganization } from "../../../../../../type/data";

interface Props {
  fundAccount: FundAccount;
  gpData?: AcInvestmentOrganization;
  allInvestmentOrganization: AcInvestmentOrganization[];
  onChangeFundAccountInfo: (newData: FundAccount) => void;
}

export const StepSecond: React.FC<Props> = (props) => {
  const {
    fundAccount,
    gpData,
    allInvestmentOrganization,
    onChangeFundAccountInfo,
  } = props;

  const LpItem = (
    item: AcInvestmentOrganization,
    index: number,
    isSelected: boolean,
    clickAble: boolean
  ) => {
    return (
      <CardContainer
        className={`${isSelected ? "selected" : ""}`}
        key={index}
        onClick={() => {
          if (!clickAble) return;

          const idx = fundAccount.investmentAssociation.findIndex(
            (fund) =>
              fund.acInvestmentOrganization.acInvestmentOrganizationId ===
              item.acInvestmentOrganizationId
          );
          if (idx !== -1) {
            fundAccount.investmentAssociation.splice(idx, 1);
          } else {
            fundAccount.investmentAssociation.push({
              acInvestmentOrganization: {
                acInvestmentOrganizationId:
                  item.acInvestmentOrganizationId || 0,
                name: item.name || "",
              },
            });
          }
          fundAccount.investmentOrganizationCount =
            fundAccount.investmentAssociation.length;
          onChangeFundAccountInfo(fundAccount);
        }}
      >
        <div className="heading-14-sb">{item.name}</div>
        <div className="caption-12-sb" style={{ color: "var(--text-03)" }}>
          {item.stakeHolder?.uniqueNumber}
        </div>
      </CardContainer>
    );
  };

  const getAssociationList = () => {
    const totalAmount = fundAccount.investmentAssociation
      .map((item) => item.investmentAmount || 0)
      .reduce((p, c) => p + c, 0);
    const data: InvestmentAssociationTableProps[] = [];
    fundAccount.investmentAssociation.forEach((item) => {
      const io = allInvestmentOrganization.find(
        (organization) =>
          organization.acInvestmentOrganizationId ===
          item.acInvestmentOrganization.acInvestmentOrganizationId
      );
      if (io) {
        data.push({
          IOId: io.acInvestmentOrganizationId || 0,
          IOName: io.name || "",
          IORegistrationNumber: io.stakeHolder?.uniqueNumber || "",
          investmentAmount: item.investmentAmount,
          amountRatio: ((item.investmentAmount || 0) / totalAmount) * 100,
        });
      }
    });

    return data;
  };

  return (
    <Container>
      <FlexColumn
        style={{
          flex: 3,
          height: "100%",
          overflow: "scroll",
          borderRight: "1px solid var(--gray-400)",
        }}
      >
        {gpData && (
          <div
            className="heading-16-sb"
            style={{ marginLeft: 16, marginTop: 16 }}
          >
            GP
          </div>
        )}
        {gpData && (
          <InvestmentOrganizationList>
            {LpItem(gpData, 999, true, false)}
          </InvestmentOrganizationList>
        )}

        <div
          className="heading-16-sb"
          style={{ marginLeft: 16, marginTop: 16 }}
        >
          LP
        </div>
        <InvestmentOrganizationList>
          {allInvestmentOrganization
            .filter((item) => item.type !== 0)
            .map((item, index) =>
              LpItem(
                item,
                index,
                fundAccount.investmentAssociation
                  .map(
                    (fund) =>
                      fund.acInvestmentOrganization.acInvestmentOrganizationId
                  )
                  .includes(item.acInvestmentOrganizationId || 0),
                true
              )
            )}
        </InvestmentOrganizationList>
      </FlexColumn>

      <FlexColumn style={{ flex: 7, height: "100%", overflow: "scroll" }}>
        <AssociationTableComponent
          associationList={getAssociationList()}
          onDataChange={(data) => {
            const fund = fundAccount.investmentAssociation.find(
              (fund) =>
                fund.acInvestmentOrganization.acInvestmentOrganizationId ===
                data.IOId
            );
            console.log(fund);
            if (fund) {
              fund.investmentAmount = data.investmentAmount;
            }

            if (fundAccount.fundPurpose.length > 0) {
              const totalAmount = fundAccount.investmentAssociation
                .map(
                  (item: InvestmentAssociationProps) =>
                    item.investmentAmount || 0
                )
                .reduce((p: number, c: number) => p + c, 0);
              fundAccount.fundPurpose.forEach((item: FundPurpose) => {
                item.amount = (totalAmount * (item.percent || 0)) / 100;
              });
            }
            onChangeFundAccountInfo(fundAccount);
          }}
        />
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  border-top: 1px solid var(--gray-400);
`;

const InvestmentOrganizationList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 6px 12px;

  border-radius: 4px;
  border: 1px solid var(--gray-300);
  background-color: var(--white);
  transition: all 0.2s ease;
  cursor: pointer;

  &.selected {
    background-color: var(--purple-200);
    :hover {
      background-color: var(--purple-200);
    }
  }

  :hover {
    background-color: var(--gray-200);
  }
`;
