import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import UserCircle, {
  CircleUserProfileProps,
} from "../../../../../components-2.0v/user-item/UserCircle";
import { PortfolioCommentProps } from "../../interface/PortfolioInfo.interface";
import { getYMDKo } from "../../../../../common/commonUtil";
import { LoginInfo } from "../../../../../type/data";

import { ReactComponent as Option } from "../../../../../assets/image/icon_option.svg";

interface Props {
  myInfo: LoginInfo;
  comment: PortfolioCommentProps;
  deleteComment: (pfCommentId: number) => void;
  setUpdateCommentId?: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const CommentViewer: React.FC<Props> = (props) => {
  const { myInfo, comment, deleteComment, setUpdateCommentId } = props;
  const userInfo: CircleUserProfileProps = {
    profileImg: comment.acUser.profileImgUrl,
    userName: comment.acUser.name,
  };
  const selectorRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const [showList, updateShowList] = useState<boolean>(false);

  const handleMouseDown = (event: any) => {
    if (optionRef.current && !optionRef.current.contains(event.target)) {
      updateShowList(false);
    }
  };

  const rectPositoin = () => {
    const root = window.document.querySelector("#root__component");

    if (selectorRef.current && root) {
      const targetRect = selectorRef.current.getBoundingClientRect();
      if (optionRef.current) {
        optionRef.current.style.top = `${
          targetRect.top + targetRect.height + 4 - 40
        }px`;
        optionRef.current.style.left = `${targetRect.left - 164 - 10}px`;
      }
    }
  };
  const handleScroll = (e: any) => {
    updateShowList(false);
  };

  useEffect(() => {
    const root = window.document.querySelector("#root__component");

    window.addEventListener("mousedown", handleMouseDown);
    root && root.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      root && root.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showList) {
      rectPositoin();
    }
  }, [showList]);

  return (
    <Container>
      <UserCircle userInfo={userInfo} width={48} />
      <CommentContainer>
        <FlexRow className="body-14-md">
          <span style={{ color: "var(--text-01, #1F2022)", marginRight: 2 }}>
            {comment.acUser.name}
          </span>
          <span style={{ color: "var(--text-05, #96999F)" }}>
            님이 {getYMDKo(comment.createdAt)}에 작성
            {comment.createdAt !== comment.updatedAt ? "(수정됨)" : ""}
          </span>
        </FlexRow>
        <Comment className="body-14-rg">{comment.content}</Comment>
      </CommentContainer>
      {myInfo.id === comment.acUser.acUserId && (
        <OptionIcon
          ref={selectorRef}
          onClick={() => {
            updateShowList(true);
          }}
        >
          <Option />
        </OptionIcon>
      )}

      {showList && (
        <ListContainer ref={optionRef} className="body-14-rg">
          <ListItem
            onClick={() => {
              updateShowList(false);
              setUpdateCommentId && setUpdateCommentId(comment.id);
            }}
          >
            수정하기
          </ListItem>
          <ListItem
            onClick={() => {
              updateShowList(false);
              deleteComment(comment.id);
            }}
            style={{ color: "var(--red-500)" }}
          >
            삭제하기
          </ListItem>
        </ListContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const CommentContainer = styled.div`
  flex: 1 0 0;
  height: auto;
  display: flex;
  flex-direction: column;

  gap: 6px;
`;

const Comment = styled.pre`
  width: 100%;
  height: auto;
  min-height: 40px;

  word-break: break-all;
  white-space: break-spaces;

  color: var(--text-01, #1f2022);
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionIcon = styled.div`
  position: absolute;
  top: -4px;
  right: 0px;
  padding: 8px;
  border-radius: 4px;

  cursor: pointer;
  :hover {
    background-color: var(--hover-100);
  }
`;

const ListContainer = styled.div`
  position: fixed;
  width: 164px;
  max-height: 236px;
  display: flex;
  flex-direction: column;

  z-index: 2;

  padding: 8px 0px;

  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  background-color: var(--background-contents02);
  overflow: scroll;
`;

const ListItem = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 9px 16px;

  cursor: pointer;

  &.selected {
    background-color: var(--blue-100);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
