import React, {
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";

import { ReactComponent as ArrowUp } from "../../../../assets/image/ic_arrow_up.svg";
import { ReactComponent as CheckBoxChecked } from "../../../../assets/image/checkbox_checked.svg";
import { ReactComponent as CheckBox } from "../../../../assets/image/checkbox_off.svg";

interface Props {
  width?: number;
  disabled?: boolean;
  allItemList: DropdownItemType[];
  selectedItemList: DropdownItemType[];
  onSelectedData: (dataList: any[]) => void;
  isAllDataSelected: boolean;
  isSame: (a: any, b: any) => boolean;
  isIncludesValue: (value: string, data: any) => boolean;

  /* dropdown 하나만 열리게 하기 위함 */
  dropdownId?: string; // 해당 드롭다운의 id
  selectedDropdownId?: string; // 선택된 드롭다운의 id
  setSelectedDropdownId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

// dropdown item
export interface DropdownItemType {
  data: any;
  label: string;
}

interface DropdownProps {
  item: DropdownItemType;
  type: "all" | "item";
  onClickItem: (item: DropdownItemType) => void;
  isSelected?: boolean;
}

const DropdownChild = ({
  item,
  type,
  onClickItem,
  isSelected = false,
}: DropdownProps) => {
  return (
    <Item type={type} onClick={() => onClickItem(item)}>
      {isSelected ? <CheckBoxChecked /> : <CheckBox />}
      <span className="font-regular-12">{item.label}</span>
    </Item>
  );
};

// data : 사용자가 사용할 데이터
// item : 사용자가 사용할 데이터 + 각 데이터를 나타낼 label
const DatabaseDropdownCheckbox = ({
  width,
  disabled = false,
  allItemList, // all item list
  selectedItemList,
  onSelectedData,
  isAllDataSelected,
  isSame,
  isIncludesValue,
  dropdownId,
  selectedDropdownId,
  setSelectedDropdownId,
}: Props) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>("");
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [filteredItems, setFilteredItems] = useState<DropdownItemType[]>([]);

  const inputRef = createRef<HTMLInputElement>();
  const dropdownRef = createRef<HTMLDivElement>();

  const onClickItem = (clickedItem: DropdownItemType) => {
    console.log(clickedItem);

    if (isAllDataSelected) {
      // 전체 선택 상태에서는 새로 클릭한 것만 체크되도록
      onSelectedData([clickedItem.data]);
    } else {
      if (
        selectedItemList.filter((selectedItem) =>
          isSame(selectedItem.data, clickedItem.data)
        ).length > 0
      ) {
        // 이미 선택되어 있으면 해제
        onSelectedData(
          selectedItemList
            .filter(
              (selectedItem) => !isSame(selectedItem.data, clickedItem.data)
            )
            .map((selectedItem) => selectedItem.data)
        );
      } else {
        // 선택되어 있지 않으면 체크
        onSelectedData([
          ...selectedItemList.map((item) => item.data),
          clickedItem.data,
        ]);
      }
    }
  };

  const onClickAll = () => {
    if (isAllDataSelected) {
      onSelectedData([]);
    } else {
      onSelectedData(allItemList.map((item) => item.data));
    }
  };

  const init = () => {
    setFilteredItems(allItemList);
  };

  useLayoutEffect(() => {
    if (selectedDropdownId === undefined) {
      setShowOptions(false);
    } else if (dropdownId === selectedDropdownId) {
      setShowOptions(true);
    }
  }, []);

  useEffect(() => {
    console.log(`${selectedDropdownId} ${dropdownId}`);

    if (selectedDropdownId === undefined || dropdownId !== selectedDropdownId) {
      setShowOptions(false);
    }
  }, [selectedDropdownId]);

  useEffect(() => {
    if (showOptions) {
      setSelectedDropdownId && setSelectedDropdownId(dropdownId);
    }
  }, [showOptions]);

  useEffect(() => {
    setFilteredItems(allItemList);
  }, [allItemList]);

  useEffect(() => {
    !showOptions && setValue("");
  }, [showOptions]);

  useEffect(() => {
    // dropdown 위치를 input 아래로 설정
    if (inputRef && inputRef.current && dropdownRef && dropdownRef.current) {
      dropdownRef.current.style.top = `${inputRef.current?.clientHeight + 4}px`;
      console.log(dropdownRef.current.style.top);
    }
  }, [dropdownRef]);

  const handleMouseDown = (event: any) => {
    if (selectorRef.current && !selectorRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };
  useEffect(() => {
    init();
    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <Container
      ref={selectorRef}
      width={width}
      isFocused={showOptions}
      disabled={disabled}
      isSelected={selectedItemList.length > 0 && !isAllDataSelected}
    >
      <div
        className="input-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={inputRef}
      >
        <input
          placeholder={
            showOptions || selectedItemList.length === 0 || isAllDataSelected
              ? "선택하세요"
              : "선택값"
          }
          className="font-regular-12"
          onFocus={() => setShowOptions(true)}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          disabled={disabled}
          tabIndex={-1}
        />
        <div className="arrow-container">
          <ArrowUp
            onClick={() => !disabled && setShowOptions((prev) => !prev)}
          />
        </div>
      </div>
      {showOptions && (
        <div
          className="dropdown-container scroll__invisible"
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={dropdownRef}
        >
          {value === "" && (
            <div className="select-all">
              <DropdownChild
                item={{
                  data: "전체",
                  label: "전체",
                }}
                type="all"
                onClickItem={() => onClickAll()}
                isSelected={isAllDataSelected}
              />
            </div>
          )}
          <div className="select-list">
            {allItemList
              .filter((item) => item.label.includes(value))
              .map((item, index) => {
                return (
                  <DropdownChild
                    item={item}
                    key={`${item.data}-${index}`}
                    type="item"
                    onClickItem={() => onClickItem(item)}
                    isSelected={
                      selectedItemList.filter((selectedItem) => {
                        return isSame(selectedItem.data, item.data);
                      }).length > 0
                    }
                  />
                );
              })}
            {allItemList.filter((item) => item.label.includes(value)).length ===
              0 && (
              <div className="no-list font-regular-12">
                검색 결과가 없습니다.
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div(
  ({
    width,
    isFocused,
    disabled,
    isSelected,
  }: {
    width?: number;
    isFocused: boolean;
    disabled: boolean;
    isSelected: boolean;
  }) => css`
    width: ${width ? `${width}px` : "100%"};

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    background: ${disabled ? "#f0f0f0" : "white"};

    .input-container {
      border: 1px solid
        ${isFocused || isSelected ? "var(--primary-blue)" : "var(--grey_10)"};
      border-radius: 4px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 8px;
      input {
        border: transparent;
        background: transparent;
        width: 100%;
        margin: 8px;
        :focus {
          outline: none;
        }

        ::placeholder {
          font-family: "BPP Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: ${isFocused || isSelected
            ? "var(--primary)"
            : "var(--grey_20)"};
        }
      }

      .arrow-container {
        width: 16px;
        height: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        transform: ${isFocused ? "" : "rotate(180deg)"};
        svg {
          cursor: ${!disabled && "pointer"};
        }
        path {
          stroke: ${!isFocused && !isSelected
            ? "var(--grey_20)"
            : "var(--primary)"};
        }
      }

      :hover {
        background: ${disabled ? "#f0f0f0" : "#fafcff"};
        border-radius: 4px;
        height: calc(inherit - 1px);
      }
    }

    .dropdown-container {
      position: absolute;
      width: 100%;
      height: max-content;
      max-height: 335px;

      top: calc(100% + 4px);

      border: 1px solid var(--gray-300);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      background: var(--white);

      overflow: scroll;

      z-index: 1000;

      .select-list {
        gap: 4px;
        display: flex;
        flex-direction: column;
        padding: 4px 0 8px 0;
        .no-list {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 4px;
          color: var(--grey_50);
        }
      }
    }
  `
);

const Item = styled.div(
  ({ type }: { type: string }) => css`
    height: ${type === "all" ? "35px" : "24px"};

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    padding: 4px 10px;

    cursor: default;

    border-bottom: ${type === "all" ? "1px solid var(--grey_20)" : ""};

    svg {
      width: 12px;
      height: 12px;
    }

    :hover {
      background: #f0f0f0;
    }
  `
);

export default DatabaseDropdownCheckbox;
