import React from "react";

interface Props {
  text: string;
  className?: string;
  query?: string;
}
export const HighLightText: React.FC<Props> = (props) => {
  const { text, className, query = "" } = props;
  if (query !== "" && text.includes(query)) {
    const parts = text.split(new RegExp(`(${query})`, "gi"));

    return (
      <div
        className={className}
        style={{
          width: "fit-content",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <mark key={index} style={{ backgroundColor: "var(--yellow-200)" }}>
              {part}
            </mark>
          ) : (
            part
          )
        )}
      </div>
    );
  }

  return (
    <span
      style={{
        width: "fit-content",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      {text}
    </span>
  );
};
