import React from "react";
import { useMediaQuery } from "react-responsive";

import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";
import MobileBluecardManagePage from "./mobile/MobileBluecardManagePage";
import DeskBluecardManagePage from "./desktop/DeskBluecardManagePage";

const BluecardManagePage: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobileBluecardManagePage {...props} />
  ) : (
    <DeskBluecardManagePage {...props} />
  );
};
export default BluecardManagePage;
