import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  duplicateInfoCehck,
  getPortfolioDetailInfo,
  registeIRProposalDocument,
  saveChangedPortfolioInfo,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import { PortfolioInfoProps } from "../../info/interface/PortfolioInfo.interface";
import { IndustryTech, UserInfo } from "../../../../type/data";
import {
  getAllAcUser,
  getAllIndustrialTech,
} from "../../../../api/repository/accelerator/AcceleratorRepository";
import { type } from "os";
import { PortfolioPhase } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import { DealFlowPhase } from "../../../deal-flow/interface/DealFlow.interface";
import {
  OnlyNum,
  RegexpPhone,
  RemoveHypen,
  checkEmailForm,
  checkNumber,
} from "../../../../common/commonUtil";
import {
  DeleteParticipant,
  PortfolioIndustrialTech,
  PortfolioInfoModifyProps,
} from "../interface/PortfolioInfoModify.interface";
import {
  deleteFile,
  uploadFile,
  uploadImgFile,
} from "../../../../api/repository/common/CommonRepository";

export type ModifyPortfolioKey =
  | "companyName"
  | "ceoName"
  | "ceoTel"
  | "ceoEmail"
  | "companyAddress"
  | "businessNumber"
  | "establishDate"
  | "mainParticipant"
  | "subParticipant"
  | "tips"
  | "description"
  | "mainIndustrialTech"
  | "subIndustrialTech"
  | "businessPlanFile"
  | "companyImgUrl"
  | "thumbnailImgUrl"
  | "problem"
  | "solution"
  | "businessModel"
  | "team"
  | "other"
  | "summaryProposal"
  | "detailProposal";
export interface ChangeDataProps {
  type: ModifyPortfolioKey;
  value: any;
}

export interface ErrorMSG {
  type: ModifyPortfolioKey;
  value: string;
}
const usePortfolioInfoModify = (
  portfolioId: number,
  portfolioPhase: PortfolioPhase,
  navigationInit: () => void,
  dealflowPhase?: DealFlowPhase
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const changePortfolioInfo = useRef<Map<any, any>>(new Map());

  const [portfolioInfo, setPortfolioInfo] = useState<
    PortfolioInfoProps | undefined
  >(undefined);

  const [isSubmitAble, updateSubmitAble] = useState<boolean>(false);
  const [errorMSGArr, setErrorMSGArr] = useState<ErrorMSG[]>([]);
  const [allIndustrialTech, setAllIndustrialTech] = useState<IndustryTech[]>(
    []
  );

  const [allUser, setAllUser] = useState<UserInfo[]>([]);
  const [isLoading, updateIsLoading] = useState<boolean>(false);

  const onClickCancel = () => {
    navigate(-1);
  };
  const onChangeData = (data: ChangeDataProps) => {
    changePortfolioInfo.current.delete(data.type);
    changePortfolioInfo.current.set(data.type, data.value);

    checkSubmitAble();
    console.log(changePortfolioInfo);
  };

  const checkStartUpInfo = () => {
    if (portfolioPhase === "MANAGE") return true;
    if (!portfolioInfo || !portfolioInfo.startupInfo) return false;

    const companyName =
      changePortfolioInfo.current.get("companyName") !== undefined
        ? changePortfolioInfo.current.get("companyName")
        : portfolioInfo?.startupInfo.companyName;
    const ceoName =
      changePortfolioInfo.current.get("ceoName") !== undefined
        ? changePortfolioInfo.current.get("ceoName")
        : portfolioInfo?.startupInfo.ceoName;
    const ceoTel =
      changePortfolioInfo.current.get("ceoTel") !== undefined
        ? changePortfolioInfo.current.get("ceoTel")
        : portfolioInfo?.startupInfo.ceoTel;
    const ceoEmail =
      changePortfolioInfo.current.get("ceoEmail") !== undefined
        ? changePortfolioInfo.current.get("ceoEmail")
        : portfolioInfo?.startupInfo.ceoEmail;
    const companyAddress =
      changePortfolioInfo.current.get("companyAddress") !== undefined
        ? changePortfolioInfo.current.get("companyAddress")
        : portfolioInfo?.startupInfo.companyAddress;
    const businessNumber =
      changePortfolioInfo.current.get("businessNumber") !== undefined
        ? changePortfolioInfo.current.get("businessNumber")
        : portfolioInfo?.startupInfo.businessNumber;
    const establishDate =
      changePortfolioInfo.current.get("establishDate") !== undefined
        ? changePortfolioInfo.current.get("establishDate")
        : portfolioInfo?.startupInfo.establishDate;

    console.log("1-1");
    console.log(companyName);
    console.log(ceoName);
    console.log(ceoTel);
    console.log(ceoEmail);
    if (!companyName) return false;
    console.log("1-2");
    if (
      portfolioPhase === "EXCAVATION" &&
      dealflowPhase === "investment" &&
      (!companyAddress ||
        !businessNumber ||
        !establishDate ||
        !ceoName ||
        !ceoTel ||
        !ceoEmail)
    )
      return false;
    console.log("1-3");
    return true;
  };

  const checkParticipant = () => {
    if (!portfolioInfo || !portfolioInfo.participantInfo) return false;
    console.log("2-1");
    if (
      !portfolioInfo.participantInfo.mainParticipant &&
      !changePortfolioInfo.current.get("mainParticipant")
    )
      return false;
    console.log("2-2");

    if (
      portfolioInfo.participantInfo.tips === undefined &&
      !changePortfolioInfo.current.get("tips")
    )
      return false;
    console.log("2-3");
    return true;
  };

  const checkBusinessInfo = () => {
    if (!portfolioInfo || !portfolioInfo.businessInfo) return false;
    console.log("3-1");
    if (
      (!portfolioInfo.businessInfo.description ||
        portfolioInfo.businessInfo.description.length === 0) &&
      (!changePortfolioInfo.current.get("description") ||
        changePortfolioInfo.current.get("description").length === 0)
    )
      return false;
    console.log("3-2");
    if (
      (!portfolioInfo.businessInfo.industrialTech ||
        portfolioInfo.businessInfo.industrialTech.length === 0) &&
      !changePortfolioInfo.current.get("industrialTech")
    )
      return false;
    console.log("3-3");
    const mainIndustrialTech = portfolioInfo.businessInfo.industrialTech?.find(
      (item) => item.isMain
    );
    console.log("3-4");
    if (
      !mainIndustrialTech &&
      !changePortfolioInfo.current.get("industrialTech")
    )
      return false;
    console.log("3-5");
    if (
      portfolioPhase === "EXCAVATION" &&
      dealflowPhase === "investment" &&
      !portfolioInfo.businessInfo.businessPlanFile &&
      !changePortfolioInfo.current.get("businessPlanFile")
    )
      return false;
    console.log("3-7");
    return true;
  };

  const checkSubmitAble = () => {
    if (!checkStartUpInfo() || !checkParticipant() || !checkBusinessInfo()) {
      updateSubmitAble(false);
      return false;
    }

    updateSubmitAble(true);
    return true;
  };

  const checkValidation = () => {
    let result = true;
    const errMSG: ErrorMSG[] = [];
    const ceoTel =
      changePortfolioInfo.current.get("ceoTel") ||
      portfolioInfo?.startupInfo.ceoTel;
    const ceoEmail =
      changePortfolioInfo.current.get("ceoEmail") ||
      portfolioInfo?.startupInfo.ceoEmail;
    const businessNumber =
      changePortfolioInfo.current.get("businessNumber") ||
      portfolioInfo?.startupInfo.businessNumber ||
      undefined;

    if (ceoTel) {
      const numberResult = checkNumber(ceoTel.replaceAll("-", ""));
      if (ceoTel.length > 13 || !numberResult) {
        // 전화번호 다시 입력
        console.log(ceoTel);
        errMSG.push({ type: "ceoTel", value: "올바른 형식으로 입력해주세요." });
        result = false;
      }
    }

    if (ceoEmail) {
      if (!checkEmailForm(ceoEmail)) {
        // 이메일 다시 입력
        console.log(ceoEmail);
        errMSG.push({
          type: "ceoEmail",
          value: "올바른 형식으로 입력해주세요.",
        });
        result = false;
      }
    }

    if (businessNumber) {
      const hypenCount = Array.from(businessNumber).filter(
        (item: any) => item === "-"
      ).length;
      if (hypenCount === 0) {
        //사업자 등록 번호 다시 입력
        console.log(businessNumber);
        errMSG.push({
          type: "businessNumber",
          value: "- 를 포함해 입력해 주세요.",
        });
        result = false;
      }
    }
    setErrorMSGArr([...errMSG]);
    return result;
  };

  //사업자 등록 번호가 있는가? 있으면 사업자 등록번호
  const duplicationCheck = async (): Promise<{
    type: "success" | "fail" | "warning";
    failData?: string[];
  }> => {
    const companyName =
      changePortfolioInfo.current.get("companyName") ||
      portfolioInfo?.startupInfo.companyName;
    const ceoTel =
      changePortfolioInfo.current.get("ceoTel") ||
      portfolioInfo?.startupInfo.ceoTel;
    const businessNumber =
      changePortfolioInfo.current.get("businessNumber") ||
      portfolioInfo?.startupInfo.businessNumber ||
      undefined;
    const ceoEmail =
      changePortfolioInfo.current.get("ceoEmail") ||
      portfolioInfo?.startupInfo.ceoEmail;

    const result = await duplicateInfoCehck({
      portfolioId: portfolioId,
      companyName: companyName,
      ceoPhone: ceoTel,
      businessNumber: businessNumber,
      ceoEmail: ceoEmail,
    });

    if (result) {
      if (result.includes("businessNumber") || result.includes("ceoEmail")) {
        return { type: "fail", failData: result };
      }
      if (
        !businessNumber &&
        (result.includes("companyName") || result.includes("ceoPhone"))
      ) {
        return { type: "warning", failData: result };
      }
    }
    return { type: "success" };
  };

  const preProcessData = () => {
    console.log(changePortfolioInfo.current);
    changePortfolioInfo.current.forEach((value, key, map) => {
      switch (key) {
        case "companyName":
          portfolioInfo?.startupInfo.companyName === value && map.delete(key);
          break;
        case "ceoName":
          portfolioInfo?.startupInfo.ceoName === value && map.delete(key);
          break;
        case "ceoTel":
          portfolioInfo?.startupInfo.ceoTel === value && map.delete(key);
          break;
        case "ceoEmail":
          portfolioInfo?.startupInfo.ceoEmail === value && map.delete(key);
          break;
        case "companyAddress": {
          const origin = portfolioInfo?.startupInfo.companyAddress;
          const change = value;
          if (!origin) break;

          if (
            origin.addr1 === change.addr1 &&
            origin.addr2 === change.addr2 &&
            origin.addr3 === change.addr3 &&
            origin.addrDetail === change.addrDetail
          )
            map.delete(key);
          break;
        }
        case "businessNumber":
          portfolioInfo?.startupInfo.businessNumber === value &&
            map.delete(key);
          break;
        case "establishDate":
          portfolioInfo?.startupInfo.establishDate === value && map.delete(key);
          break;
        case "subParticipant": {
          const origin = portfolioInfo?.participantInfo.subParticipant?.map(
            (item) => item.acUser.acUserId
          );
          const change = value.user.map((item: any) => item.id);

          if (
            origin &&
            origin.length === change.length &&
            change.filter((item: any) => origin.includes(item)).length ===
              change.length
          )
            map.delete(key);
          break;
        }

        case "tips":
          portfolioInfo?.participantInfo.tips === value.data.type &&
            map.delete(key);
          break;
        case "mainIndustrialTech": {
          const origin = portfolioInfo?.businessInfo.industrialTech?.find(
            (item) => item.isMain
          );
          const change = value;
          origin?.acIndustrialTech.acIndustrialTechId ===
            change.acIndustrialTechId && map.delete(key);
          break;
        }
        case "subIndustrialTech": {
          const origin = portfolioInfo?.businessInfo.industrialTech
            ?.filter((item) => !item.isMain)
            .map((item) => item.acIndustrialTech.acIndustrialTechId);
          const change = value.map((item: any) => item.id);
          if (
            origin &&
            origin.length === change.length &&
            change.filter((item: any) => origin.includes(item)).length ===
              origin.length
          )
            map.delete(key);
          break;
        }
        case "description":
          portfolioInfo?.businessInfo.description === value && map.delete(key);
          break;
        case "problem":
          portfolioInfo?.businessInfo.problem === value && map.delete(key);
          break;
        case "solution":
          portfolioInfo?.businessInfo.solution === value && map.delete(key);
          break;
        case "businessModel":
          portfolioInfo?.businessInfo.businessModel === value &&
            map.delete(key);
          break;
        case "team":
          portfolioInfo?.businessInfo.team === value && map.delete(key);
          break;
        case "other":
          portfolioInfo?.businessInfo.other === value && map.delete(key);
          break;
      }
    });
  };

  const modifyPortfolioInfo = async () => {
    updateIsLoading(true);
    preProcessData();

    const modifyRequestData: PortfolioInfoModifyProps = {
      pfExtraInfo: {
        id: portfolioInfo?.businessInfo.extrainfoId,
        portfolio: {
          portfolioId: portfolioId,
        },
        content: [
          {
            key: "businessModel",
            contents: portfolioInfo?.businessInfo.businessModel || "",
          },
          { key: "team", contents: portfolioInfo?.businessInfo.team || "" },
          { key: "other", contents: portfolioInfo?.businessInfo.other || "" },
        ],
      },
    };
    const successFiluploadKey: string[] = [];

    let modifyResult = true;

    if (portfolioInfo?.startupInfo.ceoTel) {
      const hypenCount = Array.from(portfolioInfo.startupInfo.ceoTel).filter(
        (item: any) => item === "-"
      ).length;
      if (hypenCount === 0) {
        modifyRequestData.portfolio = {
          ...modifyRequestData.portfolio,
          ceoTell: RegexpPhone(portfolioInfo.startupInfo.ceoTel),
          portfolioId: portfolioId,
        };
      }
    }

    changePortfolioInfo.current.forEach((value, key, map) => {
      switch (key) {
        case "companyName":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            companyName: value,
            portfolioId: portfolioId,
          };
          break;
        case "ceoName":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            ceoName: value,
            portfolioId: portfolioId,
          };
          break;
        case "ceoTel":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            ceoTell: value,
            portfolioId: portfolioId,
          };
          break;
        case "ceoEmail":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            ceoEmail: value,
            portfolioId: portfolioId,
          };
          break;
        case "companyAddress": {
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            addr1: value.addr1,
            addr2: value.addr2,
            addr3: value.addr3,
            addrDetail: value.addrDetail,
            portfolioId: portfolioId,
          };
          break;
        }
        case "businessNumber":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            businessNumber: value,
            portfolioId: portfolioId,
          };
          break;
        case "establishDate":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            establishmentDay: value,
            portfolioId: portfolioId,
          };
          break;
        case "tips":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            isTips: value.data.type,
            portfolioId: portfolioId,
          };
          break;

        case "description":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            description: value,
            portfolioId: portfolioId,
          };
          break;
        case "problem":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            problem: value,
            portfolioId: portfolioId,
          };
          break;
        case "solution":
          modifyRequestData.portfolio = {
            ...modifyRequestData.portfolio,
            solution: value,
            portfolioId: portfolioId,
          };
          break;
        case "businessModel":
        case "team":
        case "other":
          {
            if (modifyRequestData.pfExtraInfo) {
              modifyRequestData.pfExtraInfo = {
                ...modifyRequestData.pfExtraInfo,
                content: modifyRequestData.pfExtraInfo.content.map((item) => {
                  if (item.key === key) {
                    return { key: key, contents: value };
                  }
                  return { ...item };
                }),
              };
            }
          }
          break;

        case "mainParticipant": {
          if (
            !portfolioInfo ||
            !portfolioInfo.participantInfo.mainParticipant
          ) {
            modifyResult = false;
            break;
          }

          modifyRequestData.addParticipant = [
            ...(modifyRequestData.addParticipant || []),
            {
              acUserId: value.user.id,
              screenRoleId: 1,
            },
          ];
          modifyRequestData.deleteParticipant = [
            ...(modifyRequestData.deleteParticipant || []),
            {
              pfParticipantId:
                portfolioInfo.participantInfo.mainParticipant.pfParticipantId,
              isActive: false,
              comment: value.reason,
            },
          ];
          break;
        }

        case "subParticipant": {
          const preSubParticipant =
            portfolioInfo?.participantInfo.subParticipant || [];
          const deleteParticipant = preSubParticipant.filter(
            (item) =>
              !value.user
                .map((user: any) => user.id)
                .includes(item.acUser.acUserId)
          );
          const addParticipant = value.user
            .filter(
              (item: any) =>
                !preSubParticipant
                  .map((user) => user.acUser.acUserId)
                  .includes(item.id)
            )
            .map((item: any) => item.id);

          if (addParticipant.length > 0) {
            modifyRequestData.addParticipant = [
              ...(modifyRequestData.addParticipant || []),
              ...addParticipant.map((item: any) => {
                return {
                  acUserId: item,
                  screenRoleId: 2,
                };
              }),
            ];
          }

          if (deleteParticipant.length > 0) {
            modifyRequestData.deleteParticipant = [
              ...(modifyRequestData.deleteParticipant || []),
              ...deleteParticipant.map((item): DeleteParticipant => {
                return {
                  pfParticipantId: item.pfParticipantId,
                  isActive: false,
                  comment: value.reason,
                };
              }),
            ];
          }
          break;
        }

        case "mainIndustrialTech":
        case "subIndustrialTech": {
          const preMainIndustrialTech =
            portfolioInfo?.businessInfo.industrialTech?.find(
              (item) => item.isMain
            );
          const preSubIndustrialTech =
            portfolioInfo?.businessInfo.industrialTech?.filter(
              (item) => !item.isMain
            );
          const industrialTech: PortfolioIndustrialTech[] = [];

          const main = map.has("mainIndustrialTech")
            ? industrialTech.push({
                isMain: true,
                portfolio: { portfolioId: portfolioId },
                acIndustrialTech: {
                  acIndustrialTechId:
                    map.get("mainIndustrialTech").acIndustrialTechId,
                },
              })
            : preMainIndustrialTech !== undefined
            ? industrialTech.push({
                isMain: true,
                portfolio: { portfolioId: portfolioId },
                acIndustrialTech: {
                  acIndustrialTechId:
                    preMainIndustrialTech.acIndustrialTech.acIndustrialTechId,
                },
              })
            : undefined;

          console.log(map.has("mainIndustrialTech"));

          const sub = map.has("subIndustrialTech")
            ? map.get("subIndustrialTech").forEach((item: any) => {
                industrialTech.push({
                  isMain: false,
                  portfolio: { portfolioId: portfolioId },
                  acIndustrialTech: {
                    acIndustrialTechId: item.acIndustrialTechId,
                  },
                });
              })
            : preSubIndustrialTech && preSubIndustrialTech.length > 0
            ? preSubIndustrialTech.forEach((item) => {
                industrialTech.push({
                  isMain: false,
                  portfolio: { portfolioId: portfolioId },
                  acIndustrialTech: {
                    acIndustrialTechId:
                      item.acIndustrialTech.acIndustrialTechId,
                  },
                });
              })
            : [];

          if (main || sub.length > 0) {
            modifyRequestData.pfIndustrialTech = [...industrialTech];
          }
          break;
        }
      }
    });

    // console.log(modifyRequestData);

    if (changePortfolioInfo.current.has("businessPlanFile")) {
      const value = changePortfolioInfo.current.get("businessPlanFile");
      const result = await uploadFile(
        value.file,
        "businessPlanFile",
        portfolioId
      );
      if (result) {
        console.log(result);
        successFiluploadKey.push(result.key);
        modifyRequestData.portfolio = {
          ...modifyRequestData.portfolio,
          businessPlanFile: result,
          portfolioId: portfolioId,
        };
      }
    }

    if (changePortfolioInfo.current.has("summaryProposal")) {
      const value = changePortfolioInfo.current.get("summaryProposal");
      const result = await registeIRProposalDocument(
        portfolioId,
        value.file,
        1
      );
      if (result) {
        //success : true만 줌
        console.log(result);
      } else {
        modifyResult = false;
      }
    }

    if (changePortfolioInfo.current.has("detailProposal")) {
      const value = changePortfolioInfo.current.get("detailProposal");
      const result = await registeIRProposalDocument(
        portfolioId,
        value.file,
        2
      );
      if (result) {
        //success : true만 줌
        console.log(result);
      } else {
        modifyResult = false;
      }
    }

    if (changePortfolioInfo.current.has("companyImgUrl")) {
      const value = changePortfolioInfo.current.get("companyImgUrl");
      const result = await uploadImgFile(value.file, portfolioId, "companyImg");
      if (result) {
        successFiluploadKey.push(result.key);
        modifyRequestData.portfolio = {
          ...modifyRequestData.portfolio,
          companyImgUrl: result.url,
          companyImgKey: result.key,
          portfolioId: portfolioId,
        };
      } else {
        modifyResult = false;
      }
    }

    if (changePortfolioInfo.current.has("thumbnailImgUrl")) {
      const value = changePortfolioInfo.current.get("thumbnailImgUrl");
      const result = await uploadImgFile(value.file, portfolioId, "companyImg");
      if (result) {
        successFiluploadKey.push(result.key);
        modifyRequestData.portfolio = {
          ...modifyRequestData.portfolio,
          thumbnailImgUrl: result.url,
          thumbnailImgKey: result.key,
          portfolioId: portfolioId,
        };
      } else {
        modifyResult = false;
      }
    }

    if (modifyResult) {
      const result = await saveChangedPortfolioInfo(
        portfolioId,
        modifyRequestData
      );
      updateIsLoading(false);

      if (result) {
        console.log(result);
        const currentPage = location.pathname.split("/");
        currentPage[currentPage.length - 1] = "info";
        navigate(currentPage.join("/"));
      } else {
        alert("서버가 불안정 합니다.");
      }
    }
  };

  const onSubmit = async () => {
    if (!checkValidation()) {
      return;
    }

    const duplicateResult = await duplicationCheck();
    console.log(duplicateResult);
    if (duplicateResult.type === "fail") {
      const errMSG: ErrorMSG[] = [];
      const ceoEmail = duplicateResult.failData?.find(
        (item) => item === "ceoEmail"
      );
      const businessNumber = duplicateResult.failData?.find(
        (item) => item === "businessNumber"
      );
      if (ceoEmail) {
        errMSG.push({ type: "ceoEmail", value: "이미 존재하는 이메일입니다." });
      }
      if (businessNumber) {
        errMSG.push({
          type: "businessNumber",
          value: "이미 존재하는 사업자등록번호입니다.",
        });
      }
      setErrorMSGArr([...errMSG]);
      return;
    } else if (duplicateResult.type === "warning") {
      return;
    }

    await modifyPortfolioInfo();
  };

  const initPortfolioInfo = async () => {
    try {
      const result = await getPortfolioDetailInfo(portfolioId);
      const industrialTechResult = await getAllIndustrialTech();
      const userResult = await getAllAcUser("user");

      if (result) {
        setPortfolioInfo(result);
      }
      if (industrialTechResult) setAllIndustrialTech(industrialTechResult);
      if (userResult)
        setAllUser(userResult.filter((item) => item.authorityId === 1));
    } catch (e) {}
  };

  const init = async () => {
    await initPortfolioInfo();
  };

  useEffect(() => {
    if (portfolioInfo) {
      checkSubmitAble();
    }
  }, [portfolioInfo]);

  useEffect(() => {
    init();
  }, []);

  return [
    portfolioInfo,
    allIndustrialTech,
    allUser,
    isSubmitAble,
    errorMSGArr,
    isLoading,
    onChangeData,
    onSubmit,
    checkSubmitAble,
    onClickCancel,
  ] as const;
};

export default usePortfolioInfoModify;
