import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Channel,
  DBTotalSearchPortfolio,
  DPBPTotalSearch,
  IndustryTech,
  PortfolioPhaseProps,
  UserInfo,
} from "../../../../../../type/data";
import {
  IsAllSelectedType,
  SearchValueType,
} from "../../../organisms/FilterTotalSearch";
import {
  getDatabaseSearchData,
  getDatabaseTotalSearch,
} from "../../../../../../api/repository/database/DatabaseRepository";
import {
  getDatabaseHistory,
  setDatabaseHistory,
} from "../../../../../../common/pageHistoryUtil";
import { sectionMarginDefaults } from "docx";
import {
  getAllAcUser,
  getAllIndustrialTech,
} from "../../../../../../api/repository/accelerator/AcceleratorRepository";

const useTotalSearch = () => {
  const isSearching = useRef<boolean>(false);
  const currentPortfolio = useRef<DBTotalSearchPortfolio | undefined>(
    undefined
  );

  const countPerPage = 30;
  const [totalCount, updateTotalCount] = useState<number>(0);
  const [totalPage, updateTotalPage] = useState<number>(0);
  const [resultPortfolios, updateResultPortfolios] = useState<
    DPBPTotalSearch | undefined
  >(undefined);
  const [searchObject, setSearchObject] = useState<SearchValueType>({});
  const [openPortfolioModal, updatePortfolioModal] = useState<boolean>(false);

  const [industrialTechs, setIndustrialTechs] = useState<IndustryTech[]>([]);
  const [phaseDictionary, setPhaseDictionary] = useState<PortfolioPhaseProps[]>(
    []
  );
  const [participants, setParticipants] = useState<UserInfo[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);

  const isAllSelectedObject = useRef<IsAllSelectedType>({
    industry: false,
    phase: false,
    participant: false,
    excavationChannel: false,
  });
  const onPortfolioModal = (portfolio: DBTotalSearchPortfolio) => {
    console.log("clicn portfolio modal");
    currentPortfolio.current = portfolio;
    updatePortfolioModal(true);
  };

  const getTotalSearchPortfolioList = async (page?: number) => {
    if (isSearching.current) return;

    try {
      isSearching.current = true;
      const result = await getDatabaseTotalSearch({
        pageIndex: searchObject.curPage || 1,
        dataPerPage: countPerPage,
        searchWord: searchObject.keyword,
        acIndustrialTech:
          searchObject.industry!.length === industrialTechs.length ||
          searchObject.industry!.length === 0 // 전체선택은 params에 포함시키지 않는다
            ? undefined
            : searchObject.industry,
        acUser:
          searchObject.participant!.length === participants.length ||
          searchObject.participant!.length === 0
            ? undefined
            : searchObject.participant,
        acChannel:
          searchObject.excavationChannel!.length === channels.length ||
          searchObject.excavationChannel!.length === 0
            ? undefined
            : searchObject.excavationChannel,
        pfPhase:
          searchObject.phase!.length === phaseDictionary.length ||
          searchObject.phase!.length === 0
            ? undefined
            : searchObject.phase,
      });
      setDatabaseHistory({
        page: searchObject.curPage || 1,
        search: {
          keyword: searchObject.keyword,
          acIndustrialTechId: searchObject.industry,
          acUser: searchObject.participant,
          acChannel: searchObject.excavationChannel,
          pfPhase: searchObject.phase,
        },
      });

      if (result.success) {
        updateTotalPage(result.data?.totalPage || 1);
        updateTotalCount(result.data?.totalCount || 0);
        updateResultPortfolios(result.data);
      }
      isSearching.current = false;
    } catch (error) {
      isSearching.current = false;
    }
  };

  const init = async () => {
    const history = getDatabaseHistory();
    const searchData = await getDatabaseSearchData();
    const industrialResult = await getAllIndustrialTech();
    const allUserResult = await getAllAcUser("user");
    if (searchData) {
      setChannels(searchData.acChannel || []);
      setPhaseDictionary(searchData.pfPhase || []);
    }
    industrialResult && setIndustrialTechs(industrialResult);
    allUserResult && setParticipants(allUserResult);

    setSearchObject({
      keyword: history?.search?.keyword || "",
      industry: history?.search?.acIndustrialTechId || [],
      phase: history?.search?.pfPhase || [],
      participant: history?.search?.acUser || [],
      excavationChannel: history?.search?.acChannel || [],
      curPage: history?.page || 1,
    });
  };

  useEffect(() => {
    if (
      industrialTechs.length > 0 &&
      channels.length > 0 &&
      participants.length > 0 &&
      phaseDictionary.length > 0
    )
      getTotalSearchPortfolioList();
  }, [searchObject, industrialTechs, channels, participants, phaseDictionary]);

  useEffect(() => {
    if (!openPortfolioModal) {
      currentPortfolio.current = undefined;
    }
  }, [openPortfolioModal]);

  useEffect(() => {
    init();
  }, []);

  return [
    countPerPage,
    channels,
    currentPortfolio,
    isAllSelectedObject,
    industrialTechs,
    openPortfolioModal,
    participants,
    phaseDictionary,
    resultPortfolios,
    searchObject,
    totalCount,
    totalPage,
    onPortfolioModal,
    setSearchObject,
    updatePortfolioModal,
  ] as const;
};

export default useTotalSearch;
