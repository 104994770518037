import React, { useEffect } from "react";
import styled from "styled-components";

import DefaultSearch from "../../../components-2.0v/search/DefaultSearch";
import FilterView from "./organism/FilterView";
import { DealFlowPortfolioCard } from "./organism/DealFlowPortfolioCard";
import useDealFlow from "../hook/useDealFlow";
import { ReactComponent as FileIcon } from "../../../assets/image/icon_open_folder.svg";

const MobileDealFlow = () => {
  const [
    rootRef,
    initCompleted,
    myInfo,
    filterList,
    searchKeyword,
    sortingItem,
    filterItem,
    portfolioList,
    updateSearchKeyword,
    updateSortingItem,
    updateFilterItem,
    getFilterPortfolio,
    getCount,
    initFilter,
    goToPortfolio,
  ] = useDealFlow();
  if (!portfolioList) return null;

  return (
    <Container ref={rootRef}>
      <DealFlowContainer>
        <ContentsHeader>
          <span className="heading-20-sb" style={{ color: "var(--text-01)" }}>
            딜플로우
          </span>
          <DefaultSearch
            value={searchKeyword}
            placeholder="기업명, 대표명 검색"
            width={200}
            transfromPixel={(n) => n}
            onChangeValue={(value) => {
              updateSearchKeyword(value);
              if (initCompleted) initFilter();
            }}
            onPressEnter={(value) => {
              updateSearchKeyword(value);
            }}
          />
        </ContentsHeader>
        <ContentsBody>
          <FilterView
            myInfo={myInfo}
            count={getCount()}
            filterList={filterList || []}
            filterItem={filterItem}
            sortingItem={sortingItem}
            updateSortingItem={updateSortingItem}
            updateFilterItem={updateFilterItem}
          />
          {getFilterPortfolio().length === 0 ? (
            <EmptyContainer>
              <FileIcon />
              <div
                className="heading-18-md"
                style={{
                  textAlign: "center",
                  marginTop: 20,
                  marginBottom: 30,
                  color: "var(--text-04)",
                }}
              >
                검색된 데이터가 없습니다.
              </div>
            </EmptyContainer>
          ) : (
            <DealflowList>
              {getFilterPortfolio().map((item, index) => {
                return (
                  <DealFlowPortfolioCard
                    key={index}
                    portfolio={item}
                    onClickCard={() => {
                      goToPortfolio(item.portfolioId);
                    }}
                  />
                );
              })}
            </DealflowList>
          )}
        </ContentsBody>
      </DealFlowContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const DealFlowContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 62px;
  min-height: 62px;
  max-height: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  z-index: 10;

  border-bottom: 1px solid var(--divider-200);
  background: var(--background-header);
`;

const ContentsBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  margin: 0 auto;
`;
const DealflowList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  padding: 24px;
`;
const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default MobileDealFlow;
