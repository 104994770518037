import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  InvestmentPerformanceProps,
  InvestmentPerformanceResponse,
} from "../../interface/FundAccountDetail.interface";
import {
  getCashDateFlow,
  getExitCashDateFlow,
  getNewDate,
  getStockRateCashDateFlow,
  getYMD,
  xirr,
} from "../../../../../common/commonUtil";

import { ReactComponent as Down } from "../../../../../assets/image/icon_download.svg";
import { XIRRDataProps } from "../../../../../type/data";
import { getInvestmentPerformanceSummary } from "../../../../../api/repository/accelerator/DashboardRepository";
import { exportExcelByInvestmentPerformanceTable } from "../../../DashboardTableService";
import { PortfolioPhase } from "../../../../portfolio/portfolio-detail/interface/PortfolioNavigation.interface";
import { TableHeader } from "../component/TableHeader";
import { TableRow } from "../component/TableRow";

interface Props {
  initDefaultData?: InvestmentPerformanceResponse[];
  headerSticky?: boolean;
  selectedFundAccountId?: number[];
  structureId?: number;
  selectedUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}

export const InvestmentPerformanceTable: React.FC<Props> = (props) => {
  const {
    initDefaultData,
    headerSticky = false,
    selectedFundAccountId,
    structureId,
    selectedUserId,
    excavation,
    investor,
    charger,
    assistant,
  } = props;
  const [tableData, setTableData] = useState<InvestmentPerformanceProps[]>([]);

  const setData = (data: InvestmentPerformanceResponse[]) => {
    const validationError = (item: InvestmentPerformanceResponse) => {
      const totalInvestment = item.investmentAccount
        .map((invest) => invest.investmentAmount)
        .reduce((p, c) => p + c, 0);
      const totalOriginExitAmount = item.investmentExit
        ? item.investmentExit
            .map((exit) => exit.principal)
            .reduce((p, c) => p + c, 0)
        : 0;
      const investedValue =
        item.investmentInfo && item.investmentInfo.length > 0
          ? item.investmentInfo[0].issuingPrice * item.investmentInfo[0].number
          : 0; //지분가치

      if (
        totalInvestment - totalOriginExitAmount !== 0 &&
        investedValue === 0
      ) {
        //에러, 지분가치원금이 0이 아닐때 지분가치금액은 0일 수 없다.
      }

      if (totalInvestment < 1000000) {
        //에러, 투자금이 너무 작다.
      }

      if (totalInvestment - totalOriginExitAmount < 0) {
        //지분가치 원금은 0보다 작을 수 없다.
      }
    };
    const newData: InvestmentPerformanceProps[] = data.map((item) => {
      const originExitAmount = item.investmentExit
        ? item.investmentExit
            .map((exit) => exit.principal)
            .reduce((p, c) => p + c, 0)
        : 0;
      const exitAmount = item.investmentExit
        ? item.investmentExit
            .map((exit) => exit.amount)
            .reduce((p, c) => p + c, 0)
        : 0; //회수금
      const originInvestmentAmount =
        item.investmentAccount
          .map((invest) => invest.investmentAmount)
          .reduce((p, c) => p + c, 0) - originExitAmount;

      const investedValue =
        item.damagedAt !== null ||
        item.pfPhase.type === "CLOSURE" ||
        !item.investmentInfo ||
        item.investmentInfo.length === 0
          ? 0
          : item.investmentInfo[0].issuingPrice * item.investmentInfo[0].number;
      const phase: PortfolioPhase =
        item.damagedAt !== null ? "DAMAGE" : item.pfPhase.type;

      let exitIRR = 0;
      let investedIRR = 0;
      let resultIRR = 0;

      //회수 성과 IRR
      if (item.investmentExit && item.investmentAccount) {
        const exitIRRData: XIRRDataProps[] = [];

        item.investmentAccount.forEach((account) => {
          exitIRRData.push({
            date: getNewDate(account.paymentDate),
            type: 1,
            originAmount: account.investmentAmount,
            leftAmount: account.investmentAmount,
          });
        });

        item.investmentExit.forEach((exit) => {
          exitIRRData.push({
            date: getNewDate(exit.date),
            type: 2,
            exitAmount: exit.amount,
            originAmount: exit.principal,
          });
        });

        const exitCDFlow = getExitCashDateFlow(exitIRRData);
        exitIRR =
          xirr(
            exitCDFlow.map((item) => item.cash),
            exitCDFlow.map((item) => item.date)
          ) || 0;
      }

      //지분평가 성과 IRR
      if (item.investmentInfo && investedValue > 0) {
        const stockRateIRRData: XIRRDataProps[] = [];
        item.investmentAccount &&
          item.investmentAccount.forEach((account) => {
            stockRateIRRData.push({
              date: getNewDate(account.paymentDate),
              type: 1,
              originAmount: account.investmentAmount,
              leftAmount: account.investmentAmount,
            });
          });

        item.investmentExit?.forEach((exit) => {
          stockRateIRRData.push({
            date: getNewDate(exit.date),
            type: 2,
            exitAmount: exit.amount,
            originAmount: exit.principal,
          });
        });
        const stockRateCDFLow = getStockRateCashDateFlow(
          stockRateIRRData,
          investedValue
        );

        investedIRR =
          xirr(
            stockRateCDFLow.map((item) => item.cash),
            stockRateCDFLow.map((item) => item.date)
          ) || 0;
      }

      //전체 성과 IRR
      if (item.investmentInfo) {
        const IRRData: XIRRDataProps[] = [];
        item.investmentAccount.forEach((account) => {
          IRRData.push({
            date: getNewDate(account.paymentDate),
            type: 1,
            originAmount: account.investmentAmount,
          });
        });
        item.investmentExit?.forEach((exit) => {
          IRRData.push({
            date: getNewDate(exit.date),
            type: 2,
            originAmount: exit.principal,
            exitAmount: exit.amount,
          });
        });

        const CDFLow = getCashDateFlow(IRRData, investedValue);
        item.portfolioId === 40 &&
          console.log(
            CDFLow.map((item) => ({ ...item, dateKo: getYMD(item.date) }))
          );

        resultIRR =
          xirr(
            CDFLow.map((item) => item.cash),
            CDFLow.map((item) => item.date)
          ) || 0;
      }

      return {
        phase: phase,
        portfolioId: item.portfolioId,
        companyName: item.companyName,
        firstPaymentDate: getYMD(item.firstPaymentDate),
        mainParticipant: item.mainParticipant.name,
        riskStatus: item.riskStatus, // 포트폴리오 평가 상태
        originExitAmount: originExitAmount, //회수 원금
        originInvestmentAmount: originInvestmentAmount, // 지분가치 원금
        exitAmount: exitAmount, //회수금
        investedValue: investedValue,
        exitMOIC: exitAmount / originExitAmount, //회수 MOIC
        exitIRR: exitIRR || 0, //회수 IRR
        investedMOIC: investedValue / originInvestmentAmount, //지분가치 MOIC
        investedIRR: investedIRR || 0, //지분가치 IRR
        resultMOIC:
          (investedValue + exitAmount) /
          (originInvestmentAmount + originExitAmount), //평가가치 MOIC
        resultIRR: resultIRR || 0, //평가가치 IRR
      };
    });
    setTableData(newData);
  };

  const getTableData = async () => {
    const investmentPerformanceResult = await getInvestmentPerformanceSummary({
      acFundAccountId: selectedFundAccountId,
      acStructureId: structureId,
      acUserId: selectedUserId,
      excavation: excavation,
      investor: investor,
      charger: charger,
      assistant: assistant,
    });
    if (!investmentPerformanceResult) return;
    setData(investmentPerformanceResult);
  };

  useEffect(() => {
    if (initDefaultData) setData(initDefaultData);
  }, [initDefaultData]);

  useEffect(() => {
    if (!initDefaultData) getTableData();
  }, [
    selectedFundAccountId,
    selectedUserId,
    excavation,
    investor,
    charger,
    assistant,
  ]);
  return (
    <Container>
      <TitleContainer className="heading-18-sb">
        <span>포트폴리오별 투자 성과 요약</span>
        <ExcelDownloadBtn
          className={`caption-12-md ${
            tableData.length === 0 ? "disabled" : ""
          }`}
          onClick={() => {
            tableData.length > 0 &&
              exportExcelByInvestmentPerformanceTable(tableData);
          }}
        >
          엑셀 다운로드
          <Down />
        </ExcelDownloadBtn>
      </TitleContainer>
      <BodyContainer>
        <TableHeader headerSticky={headerSticky} />
        {tableData
          // .filter((item) => item.companyName.includes("아론"))
          .map((item, index) => {
            return <TableRow key={index} index={index} data={item} />;
          })}
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ExcelDownloadBtn = styled.div`
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  color: var(--text-01);
  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  background: var(--background-contents-01, #fff);
  transition: all 0.2s ease;
  path {
    fill: var(--text-01);
  }

  cursor: pointer;

  :hover {
    background: var(--hover-100);
  }

  &.disabled {
    border-color: var(--gray-200);
    color: var(--gray-200);
    cursor: default;
    path {
      fill: var(--gray-200);
    }
    :hover {
      background: var(--background-contents-01, #fff);
    }
  }
`;
