import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../components/navigation/NavigationLayout";
import MobileDealFlow from "./mobile/MobileDealFlow";
import DeskDealFlow from "./desktop/DeskDealFlow";

const DealFlow = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobileDealFlow /> : <DeskDealFlow />}
    </NavigationLayout>
  );
};
export default DealFlow;
