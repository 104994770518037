import axios from "axios";
import * as api from "../../api";
import {
  ChannelAdminProps,
  ChannelProps,
} from "../../../router/admin/router/channel/interface/Channel.interface";

import {
  AcInvestmentOrganization,
  IndustryTech,
  Position,
  ResponseProps,
  Responsibility,
  UserInfo,
} from "../../../type/data";
import { getNewDate, getYMD } from "../../../common/commonUtil";
import { StructureInfo } from "../../../router/admin/router/structure/interface/Structure.interface";
import {
  FundAccount,
  LPInfoProps,
} from "../../../router/admin/router/fund_account/interface/FundAccount.interface";
import {
  CreateBuyerProps,
  ExitProcessProps,
  FairValueAdminProps,
  FairValueAmountExportProps,
  FairValueAmountProps,
  MAIN_PARTICIPANT,
  PortfolioAdminProps,
  PortfolioDetailAdminProps,
} from "../../../router/admin/router/portfolio/interface/Portfolio.interface";
import {
  IndustryTechAdminItem,
  IndustryTechProps,
} from "../../../router/admin/router/industry_tech/interface/IndustrialTech.interface";

export const getAcChannel = async (): Promise<ChannelProps | undefined> => {
  const result = await axios.get(api.getAdminAllChannel());
  if (result.status === 200) {
    const allChannel: ChannelAdminProps[] = result.data.acChannel.map(
      (item: any): ChannelAdminProps => {
        return { isNew: false, isChange: false, ...item };
      }
    );

    const activeChannel = allChannel
      .filter((item) => item.isActive)
      .sort((a, b) => (a.order || 0) - (b.order || 0));
    const deactiveChannel = allChannel
      .filter((item) => !item.isActive)
      .sort((a, b) => (a.order || 0) - (b.order || 0));
    return new Promise((resolve) =>
      resolve({
        activeChannel: activeChannel,
        deactiveChannel: deactiveChannel,
      })
    );
  }
  return new Promise((reject) => reject(undefined));
};

export const upsertAcChannel = async (
  data: ChannelAdminProps[]
): Promise<{ success: boolean; message?: string } | undefined> => {
  const body = data;
  const result = await axios.post(api.upsertAdminChannel(), body);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const getAcIndustrialTech = async (): Promise<
  IndustryTechProps | undefined
> => {
  const result = await axios.get(api.getAcAdminIndustrialTech());

  if (result.status === 200) {
    const newData: IndustryTechAdminItem[] = result.data.acIndustrialTech.map(
      (item: any): IndustryTechAdminItem => {
        console.log(item);
        return {
          isNew: false,
          isChange: false,
          isActive: item.isActive,
          acIndustrialTechId: item.acIndustrialTechId,
          name: item.name,
          order: item.order,
        };
      }
    );

    const active = newData
      .filter((item) => item.isActive)
      .sort((a, b) => (a.order || 0) - (b.order || 0));
    const deactive = newData
      .filter((item) => !item.isActive)
      .sort((a, b) => (a.order || 0) - (b.order || 0));
    return new Promise((resolve) =>
      resolve({
        active: active,
        deactive: deactive,
      })
    );
  }

  return new Promise((reject) => reject(undefined));
};

export const upsertIndustrialTech = async (
  data: IndustryTechAdminItem[]
): Promise<ResponseProps | undefined> => {
  const body = data;
  const result = await axios.post(api.upsertAcIndustrialTech(), body);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const getAcStructure = async (): Promise<
  StructureInfo[] | undefined
> => {
  const result = await axios.get(api.getAcAdminStructure());

  if (result.status === 200) {
    const newData = result.data.acStructure
      .map(
        (item: any): StructureInfo => ({
          ...item,
          isNew: false,
          isChange: false,
          isDelete: false,
        })
      )
      .sort((a: any, b: any) => a.structureIndex - b.structureIndex);
    return new Promise((resolve) => resolve(newData));
  }

  return new Promise((reject) => reject(undefined));
};
export const updateAcStructure = async (
  body: any
): Promise<ResponseProps | undefined> => {
  const result = await axios.post(api.upsertAcStructure(), body);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }

  return new Promise((reject) => reject(undefined));
};

export const getAcUser = async (): Promise<UserInfo[] | undefined> => {
  const result = await axios.get(api.getAcAdminUsers());
  if (result.status === 200) {
    const newData = result.data.acUserList.map((item: any): UserInfo => {
      return {
        ...item,
        isNew: false,
        isChange: false,
        isDelete: false,
        id: item.acUserId,
        type: "AC_USER",
        acStructureId: item.acStructure ? item.acStructure.acStructureId : 0,
        acResponsibilityId: item.acResponsibility
          ? item.acResponsibility.acResponsibilityId
          : undefined,
        acPositionId: item.acPosition
          ? item.acPosition.acPositionId
          : undefined,
        email: item.email,
        dateOfEntry: getNewDate(item.dateOfEntry),
      };
    });

    return new Promise((resolve) => resolve(newData));
  }
  return new Promise((reject) => reject(undefined));
};

export const getAcIndustryTech = async (): Promise<
  IndustryTech[] | undefined
> => {
  const result = await axios.get(api.getAcAdminIndustrialTech());
  if (result.status === 200) {
    const newData: IndustryTech[] = result.data.acIndustrialTech.map(
      (item: any): IndustryTech => {
        return item;
      }
    );

    return new Promise((resolve) => resolve(newData));
  }
  return new Promise((reject) => reject(undefined));
};

export const getAcResponsibility = async (): Promise<
  Responsibility[] | undefined
> => {
  const result = await axios.get(api.getAcAdminResponsibility());
  if (result.status === 200) {
    const newData: Responsibility[] = result.data.acResponsibility.map(
      (item: any): Responsibility => {
        return item;
      }
    );

    return new Promise((resolve) => resolve(newData));
  }
  return new Promise((reject) => reject(undefined));
};

export const resigiteResponsibility = async (
  name: string
): Promise<boolean | undefined> => {
  const body = { name: name };
  const result = await axios.post(api.registeResponsibility(), body);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(undefined));
};

export const removeResponsibility = async (
  acResponsibilityId: number
): Promise<boolean | undefined> => {
  const result = await axios.delete(
    api.removeResponsibility(acResponsibilityId)
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(undefined));
};

export const getAcPosition = async (): Promise<Position[] | undefined> => {
  const result = await axios.get(api.getAcAdminPosition());
  if (result.status === 200) {
    const newData: Position[] = result.data.acPositionList.map(
      (item: any): Position => {
        return item;
      }
    );

    return new Promise((resolve) => resolve(newData));
  }
  return new Promise((reject) => reject(undefined));
};

export const resigitePosition = async (
  name: string
): Promise<boolean | undefined> => {
  const body = { name: name };
  const result = await axios.post(api.registePosition(), body);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(undefined));
};

export const removePosition = async (
  acPositoinId: number
): Promise<boolean | undefined> => {
  const result = await axios.delete(api.removePosition(acPositoinId));
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(undefined));
};

export const getAllFundaccount = async (): Promise<
  FundAccount[] | undefined
> => {
  const result = await axios.get(api.getAcAdminFundAccount());
  if (result.status === 200) {
    const newData: FundAccount[] = result.data.acFundAccountList.map(
      (item: any): FundAccount => {
        return {
          ...item,
          isNew: false,
          isChange: false,
          investmentStartDate: getYMD(item.investmentStartDate),
          investmentEndDate: getYMD(item.investmentEndDate),
          operatingStartDate: getYMD(item.operatingStartDate),
          operatingEndDate: getYMD(item.operatingEndDate),
        };
      }
    );
    return new Promise((resolve) => resolve(newData));
  }
  return new Promise((reject) => reject(undefined));
};

export const getAllInvestmentOrganization = async (): Promise<
  AcInvestmentOrganization[] | undefined
> => {
  const result = await axios.get(api.getAcInvestmentOrganization());
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) =>
      resolve(result.data.acInvestmentOrganization)
    );
  }
  return new Promise((reject) => reject(undefined));
};

export const registeInvestmentOrganization = async (
  data: LPInfoProps
): Promise<ResponseProps | undefined> => {
  const result = await axios.post(api.registeInvestmentOrganization(), data);
  if (result.status === 200 || result.status === 409) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const editInvestmentOrganization = async (
  data: LPInfoProps
): Promise<ResponseProps | undefined> => {
  const result = await axios.put(api.registeInvestmentOrganization(), data);
  if (result.status === 200 || result.status === 409) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const upsertAcFundAccount = async (
  data: FundAccount[]
): Promise<ResponseProps | undefined> => {
  const result = await axios.post(api.upsertAcFundAccount(), data);
  if (result.status === 200 || result.status === 409) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const getAllPortfolio = async (): Promise<
  PortfolioAdminProps[] | undefined
> => {
  const result = await axios.get(api.getAcAdminPortfolio());
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.portfolio));
  }
  return new Promise((reject) => reject(undefined));
};

export const getPortfolioById = async (
  portfolioId: number
): Promise<PortfolioDetailAdminProps | undefined> => {
  const result = await axios.get(api.getAcAdminPortfolioById(portfolioId));
  console.log(result);
  if (result.status === 200) {
    const fairValue: FairValueAdminProps[] = [];
    result.data.portfolio.pfFairValue &&
      result.data.portfolio.pfFairValue.map((item: any) => {
        const target = fairValue.find((data) => data.year === item.year);
        if (target) {
          target.fairValue.push({
            id: item.id,
            acFundAccount: item.acFundAccount,
            amount: item.amount,
          });
        } else {
          fairValue.push({
            year: item.year,
            fairValue: [
              {
                id: item.id,
                acFundAccount: item.acFundAccount,
                amount: item.amount,
              },
            ],
          });
        }
      });

    return new Promise((resolve) =>
      resolve({ ...result.data.portfolio, pfFairValue: fairValue })
    );
  }
  return new Promise((reject) => reject(undefined));
};

export const changePortfolioParticipant = async (
  portfolioId: number,
  comment: string,
  acUserId: number
): Promise<ResponseProps | undefined> => {
  const body = {
    portfolioId: portfolioId,
    comment: comment,
    pfParticipant: [
      {
        screenRole: {
          screenRoleId: MAIN_PARTICIPANT,
        },
        acUser: {
          acUserId: acUserId,
        },
      },
    ],
  };
  const result = await axios.post(
    api.modifyAcAdminPortfolioParticiapnt(),
    body
  );
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const changePortfolioExcavator = async (
  portfolioId: number,
  acUserId: number
): Promise<ResponseProps | undefined> => {
  const body = {
    portfolioId: portfolioId,
    acUserId: acUserId,
  };
  const result = await axios.post(api.modifyAcAdminPortfolioExcavator(), body);
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

/**
 * 포트폴리오 상태 변경
 */

export const changePortfolioStatusDamage = async (
  portfolioId: number,
  damagedAt: string
): Promise<ResponseProps | undefined> => {
  const body = {
    damagedAt,
  };
  const result = await axios.patch(
    api.changeAcAdminPortfolioStatusDamage(portfolioId),
    body
  );
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const changePortfolioStatusClosure = async (
  portfolioId: number,
  damagedAt: string
): Promise<ResponseProps | undefined> => {
  const body = {
    damagedAt,
  };
  const result = await axios.patch(
    api.changeAcAdminPortfolioStatusClosure(portfolioId),
    body
  );
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const changePortfolioStatusUndo = async (
  portfolioId: number
): Promise<ResponseProps | undefined> => {
  const result = await axios.patch(
    api.changeAcAdminPortfolioStatusUndo(portfolioId)
  );
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const exitPortfolio = async (
  data: ExitProcessProps[]
): Promise<ResponseProps | undefined> => {
  const body: any[] = [];
  data.forEach((item) => {
    item.exitInfo.forEach((info) => {
      const newData = {
        portfolio: {
          portfolioId: info.portfolio?.portfolioId,
        },
        acFundAccount: {
          acFundAccountId: info.acFundAccount?.acFundAccountId,
        },
        stockType: {
          stockTypeId: info.stockType?.stockTypeId,
        },
        date: info.date,
        stock: info.stock,
        stockPrice: info.stockPrice,
        amount: info.amount,
        principal: info.principal,
        companyValue: info.companyValue,
        acInvestmentOrganization: {
          acInvestmentOrganizationId:
            info.acInvestmentOrganization.acInvestmentOrganizationId,
        },
      };
      body.push(newData);
    });
  });
  const result = await axios.post(api.acAdminPortfolioExit(), body);
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const exitCancelPortfolio = async (
  portfolioId: number,
  id: number
): Promise<ResponseProps | undefined> => {
  const result = await axios.delete(
    api.acAdminPortfolioIdExitId(portfolioId, id)
  );
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const getBuyer = async (): Promise<
  AcInvestmentOrganization[] | undefined
> => {
  const result = await axios.get(api.acAdminBuyer());
  if (result.status === 200) {
    return new Promise((resolve) =>
      resolve(result.data.acInvestmentOrganization)
    );
  }
  return new Promise((reject) => reject(undefined));
};

export const createBuyer = async (
  newBuyer: CreateBuyerProps
): Promise<ResponseProps | undefined> => {
  const body = newBuyer;
  const result = await axios.post(api.acAdminBuyer(), body);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const getFairValueList = async (
  year: number
): Promise<FairValueAmountProps[] | undefined> => {
  const result = await axios.get(api.acAdminPortfolioFairValue(), {
    params: { year },
  });
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }
  return new Promise((reject) => reject(undefined));
};

export const upsertFairValueList = async (
  data: FairValueAmountExportProps[]
): Promise<ResponseProps | undefined> => {
  const body = data.map((item) => {
    return {
      portfolio: {
        portfolioId: parseInt(item.portfolioId),
      },
      acFundAccount: {
        acFundAccountId: parseInt(item.acFundAccountId),
        type: parseInt(item.type),
      },
      year: parseInt(item.year),
      amount: parseInt(item.amount),
    };
  });
  const result = await axios.post(api.acAdminPortfolioFairValue(), body);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const getFairValueByPortfolioId = async (
  portfolioId: number,
  year: number
): Promise<FairValueAmountProps | undefined> => {
  const result = await axios.get(
    api.acAdminPortfolioFairValueByPortfolioId(portfolioId),
    {
      params: { year },
    }
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }
  return new Promise((reject) => reject(undefined));
};

export const upsertFairValueByPortfolioId = async (
  portfolioId: number,
  data: FairValueAmountExportProps[]
): Promise<ResponseProps | undefined> => {
  const body = data.map((item) => {
    return {
      portfolio: {
        portfolioId: parseInt(item.portfolioId),
      },
      acFundAccount: {
        acFundAccountId: parseInt(item.acFundAccountId),
        type: parseInt(item.type),
      },
      year: parseInt(item.year),
      amount: parseInt(item.amount),
    };
  });
  const result = await axios.post(
    api.acAdminPortfolioFairValueByPortfolioId(portfolioId),
    body
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};
