import React from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartOptions,
} from "chart.js";
import { Tooltip as HelpTooltip } from "react-tooltip";
import { Chart } from "react-chartjs-2";
import { FundAccountDetailInfo } from "../../interface/FundAccountDetail.interface";
import { ReactComponent as Help } from "../../../../assets/image/icon_tooltip.svg";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  data: FundAccountDetailInfo;
}
export const FundOperatingStatus: React.FC<Props> = (props) => {
  const originInvestedAmount =
    (props.data.totalOriginalInvestment || 0) -
    (props.data.totalOriginalExit || 0);
  const originExitAmount = props.data.totalOriginalExit || 0;
  const gpFee = props.data.gpFee || 0;
  const availableUseAmount =
    props.data.totalAmount - props.data.totalOriginalInvestment - gpFee; // 마지막 GP 수수료

  console.log(props.data);
  const options: ChartOptions = {
    responsive: false,
    indexAxis: "y" as const,
    plugins: {
      legend: {
        position: "bottom",
        align: "end",
        labels: {
          boxWidth: 12,
        },
        onClick: (e) => {},
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
    scales: {
      x: {
        display: false,
        stacked: true,
        min: 0,
        max:
          originInvestedAmount + originExitAmount + gpFee + availableUseAmount,
      },

      y: {
        display: false,
        stacked: true,
      },
    },
  };

  const labels = [""];

  const data = {
    labels: [""],
    datasets: [
      {
        label: "지분가치에 대한 원금",
        data: [originInvestedAmount],
        backgroundColor: "rgba(69, 132, 255, 1)",
      },
      {
        label: "기회수금에 대한 원금",
        data: [originExitAmount],
        backgroundColor: "rgba(59, 214, 153, 1)",
      },
      {
        label: "사용 가능 금액",
        data: [availableUseAmount],
        backgroundColor: "rgba(237, 238, 239, 1)",
      },
      {
        label: "GP 수수료",
        data: [gpFee],
        backgroundColor: "rgba(190, 192, 194, 1)",
      },
    ],
  };
  return (
    <Container>
      <TitleContainer className="heading-18-sb">펀드운용 현황</TitleContainer>
      <BodyContainer>
        <FlexRow style={{ gap: 20 }}>
          <FlexColumn>
            <div className="body-14-sb" style={{ color: "var(--text-04)" }}>
              약정총액
            </div>
            <div className="heading-20-b" style={{ color: "var(--blue-700)" }}>
              {(props.data.totalAmount / 100000000).toFixed(0)}억
            </div>
          </FlexColumn>
          <Divider />
          <FlexColumn>
            <div className="body-14-sb" style={{ color: "var(--text-04)" }}>
              투자총액
            </div>
            <div className="heading-20-b" style={{ color: "var(--blue-500)" }}>
              {(props.data.totalOriginalInvestment / 100000000).toFixed(0)}억
            </div>
          </FlexColumn>
          <Divider />
          <FlexColumn>
            <FlexRow
              className="body-14-sb"
              style={{ color: "var(--text-04)", alignItems: "center", gap: 4 }}
            >
              사용 가능한 금액{" "}
              {/* <Help
                className={"fundOperatingStatus_useableAmount"}
                data-tooltip-delay-hide={2000}
                style={{ outline: "none", marginBottom: 1 }}
              /> */}
            </FlexRow>
            <div className="heading-20-b" style={{ color: "var(--blue-500)" }}>
              {availableUseAmount < 0
                ? 0
                : (availableUseAmount / 100000000).toFixed(0)}
              억
            </div>
          </FlexColumn>
        </FlexRow>
        <div style={{ marginTop: 40 }}>
          <Chart
            type="bar"
            options={options}
            data={data}
            width={530}
            height={70}
          />
        </div>
        {/* <HelpTooltip
          className="body-14-rg"
          anchorSelect=".fundOperatingStatus_useableAmount"
          place="top"
          border={0}
          style={{ zIndex: 99999999, borderRadius: 4 }}
        >
          사용 예정 금액과 GP수수료를 포함한 금액을 의미합니다.
        </HelpTooltip> */}
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--gray-300);
`;
