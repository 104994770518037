import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  RMEDContentsProps,
  RMEDDictionary,
  RMEDPortfolioInfoProps,
} from "../../../interface/RMED.interface";
import { RMEDSector } from "../RMEDSector";

import { AutoResizableTextarea } from "../AutoResizableTextarea";
import { RMEDSectorDescription } from "../RMEDSectorDescription";
import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import { CashflowStatement } from "../CashflowStatement";
import { ClosedCompanyFile } from "./organism/ClosedCompanyFile";
import { Selector } from "../../../../../../components-2.0v/selector/Selector";
import { DefaultButton } from "lattice_core";
import ModalLayout from "../../../../../../components/modal/ModalLayout";
import SignatureModal from "../../../../../task/evaluation/desktop/organism/SignatureModal";
import SignatureImage from "../../../../../mytask/organisms/SignatureImage";

import { ReactComponent as Edit } from "../../../../../../assets/image/signature__edit.svg";
import { SignatureInfo, UserInfo } from "../../../../../../type/data";
import { getMyInfo } from "../../../../../../common/commonUtil";
import { getSignature } from "../../../../../../api/repository/accelerator/AcceleratorRepository";
import ConfirmModal from "../../../../../../2.0lattice/components/modal/ConfirmModal";

interface Props {
  isReadOnly: boolean;
  closedCompany?: boolean;
  data?: RMEDContentsProps;
  basicDictionary?: RMEDDictionary;
  portfolioInfo?: RMEDPortfolioInfoProps;
  isParticipant?: boolean;
  mainParticipant?: UserInfo;
  onChangeData?: (data: RMEDContentsProps) => void;
}

export const RMEDOpinion: React.FC<Props> = (props: Props) => {
  const {
    isReadOnly,
    closedCompany,
    data,
    portfolioInfo,
    isParticipant,
    basicDictionary,
    mainParticipant,
    onChangeData,
  } = props;

  const signData = useRef<SignatureInfo | undefined>(undefined);
  const [signModal, updateSignModal] = useState<boolean>(false);
  const [existSignData, updateExistSignData] = useState<boolean>(false);

  if (!data) return null;

  return (
    <Container>
      {closedCompany && (
        <RMEDSector title="폐업관련 담당 심사역 의견" id="opinion1">
          <FlexColumn style={{ gap: 34 }}>
            <FlexColumn style={{ gap: 16 }}>
              <div className="heading-20-sb">폐업진행</div>
              <FlexColumn style={{ gap: 8 }}>
                <RMEDSectorDescription
                  text={`경영진 문제, 마일스톤 이슈, 자본잠식 등의 사유를 포함하여 폐업이 진행되는 사유와 현재의 진행상황을 기재해주세요.`}
                />
                <OponionWrapper>
                  <RowTitleBox
                    title="상세 설명"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="이슈 해결 방안에 대해 작성해주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 7
                        )?.comment
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 7
                        );
                        if (issue && onChangeData) {
                          issue.comment = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            </FlexColumn>
            <FlexColumn style={{ gap: 8 }}>
              <RMEDSectorDescription
                text={`폐업 관련 증빙자료를 제출해 주세요.`}
              />
              <ClosedCompanyFile
                portfolioId={data.portfolio.portfolioId}
                riskIssueFile={
                  data.riskIssueList.find((item) => item.riskIssue.id === 7)
                    ?.riskIssueFile
                }
                onDataChange={(fileData) => {
                  const issue = data.riskIssueList.find(
                    (item) => item.riskIssue.id === 7
                  );
                  if (issue && onChangeData) {
                    issue.riskIssueFile = fileData;
                    onChangeData(data);
                  }
                }}
              />
            </FlexColumn>
          </FlexColumn>
        </RMEDSector>
      )}

      {!closedCompany && (
        <RMEDSector title="이슈 및 해결 방안" id="opinion1">
          <FlexColumn style={{ gap: 34 }}>
            {data.riskIssueList.filter(
              (item) => item.result !== undefined && item.result
            ).length === 0 && (
              <div
                className="heading-20-sb"
                style={{
                  width: "100%",
                  height: 100,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "var(--gray-500)",
                }}
              >
                해당하는 리스크 이슈가 없습니다.
              </div>
            )}

            {data.riskIssueList.find((item) => item.riskIssue.id === 1)
              ?.result && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-20-sb">핵심 인력 이탈</div>
                <OponionWrapper>
                  <RowTitleBox
                    title="이슈 상세 설명"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="리스크 평가 대상 회사의 지정 사유를 작성해 주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 1
                        )?.comment
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 1
                        );
                        if (issue && onChangeData) {
                          issue.comment = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="이슈 해결 방안"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="이슈 해결 방안에 대해 작성해주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 1
                        )?.solution
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 1
                        );
                        if (issue && onChangeData) {
                          issue.solution = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            )}

            {data.riskIssueList.find((item) => item.riskIssue.id === 2)
              ?.result && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-20-sb">경영진 이슈</div>
                <OponionWrapper>
                  <RowTitleBox
                    title="이슈 상세 설명"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="리스크 평가 대상 회사의 지정 사유를 작성해 주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 2
                        )?.comment
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 2
                        );
                        if (issue && onChangeData) {
                          issue.comment = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="이슈 해결 방안"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="이슈 해결 방안에 대해 작성해주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 2
                        )?.solution
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 2
                        );
                        if (issue && onChangeData) {
                          issue.solution = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            )}

            {data.riskIssueList.find((item) => item.riskIssue.id === 3)
              ?.result && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-20-sb">임직원 급감</div>
                <OponionWrapper>
                  <RowTitleBox
                    title="이슈 상세 설명"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="리스크 평가 대상 회사의 지정 사유를 작성해 주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 3
                        )?.comment
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 3
                        );
                        if (issue && onChangeData) {
                          issue.comment = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="이슈 해결 방안"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="이슈 해결 방안에 대해 작성해주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 3
                        )?.solution
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 3
                        );
                        if (issue && onChangeData) {
                          issue.solution = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            )}

            {data.riskIssueList.find((item) => item.riskIssue.id === 4)
              ?.result && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-20-sb">거시환경 영향</div>
                <OponionWrapper>
                  <RowTitleBox
                    title="이슈 상세 설명"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="리스크 평가 대상 회사의 지정 사유를 작성해 주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 4
                        )?.comment
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 4
                        );
                        if (issue && onChangeData) {
                          issue.comment = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="이슈 해결 방안"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="이슈 해결 방안에 대해 작성해주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 4
                        )?.solution
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 4
                        );
                        if (issue && onChangeData) {
                          issue.solution = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            )}

            {data.riskIssueList.find((item) => item.riskIssue.id === 5)
              ?.result && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-20-sb">경쟁사 영향</div>
                <OponionWrapper>
                  <RowTitleBox
                    title="이슈 상세 설명"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="리스크 평가 대상 회사의 지정 사유를 작성해 주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 5
                        )?.comment
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 5
                        );
                        if (issue && onChangeData) {
                          issue.comment = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="이슈 해결 방안"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="이슈 해결 방안에 대해 작성해주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 5
                        )?.solution
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 5
                        );
                        if (issue && onChangeData) {
                          issue.solution = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            )}

            {data.riskIssueList.find((item) => item.riskIssue.id === 6)
              ?.result && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-20-sb">Milestone 이슈</div>
                <OponionWrapper>
                  <RowTitleBox
                    title="이슈 상세 설명"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="리스크 평가 대상 회사의 지정 사유를 작성해 주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 6
                        )?.comment
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 6
                        );
                        if (issue && onChangeData) {
                          issue.comment = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="이슈 해결 방안"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="이슈 해결 방안에 대해 작성해주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 6
                        )?.solution
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 6
                        );
                        if (issue && onChangeData) {
                          issue.solution = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            )}

            {data.riskIssueList.find((item) => item.riskIssue.id === 8)
              ?.result && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-20-sb">완전 자본잠식</div>
                <OponionWrapper>
                  <RowTitleBox
                    title="이슈 상세 설명"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="리스크 평가 대상 회사의 지정 사유를 작성해 주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 8
                        )?.comment
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 8
                        );
                        if (issue && onChangeData) {
                          issue.comment = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="이슈 해결 방안"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="이슈 해결 방안에 대해 작성해주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 8
                        )?.solution
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 8
                        );
                        if (issue && onChangeData) {
                          issue.solution = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            )}

            {data.riskIssueList.find((item) => item.riskIssue.id === 9)
              ?.result && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-20-sb">현금흐름 이슈</div>
                <OponionWrapper>
                  <RowTitleBox
                    title="이슈 상세 설명"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="리스크 평가 대상 회사의 지정 사유를 작성해 주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 9
                        )?.comment
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 9
                        );
                        if (issue && onChangeData) {
                          issue.comment = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="이슈 해결 방안"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="이슈 해결 방안에 대해 작성해주세요."
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 9
                        )?.solution
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 9
                        );
                        if (issue && onChangeData) {
                          issue.solution = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            )}
          </FlexColumn>
        </RMEDSector>
      )}

      {!closedCompany && (
        <RMEDSector title="종합 의견 평가" id="issue4">
          <FlexColumn style={{ gap: 34 }}>
            <FlexColumn style={{ gap: 16 }}>
              <div className="heading-20-sb">사업적 성과 달성</div>
              <FlexColumn style={{ gap: 8 }}>
                <OponionWrapper>
                  <RowTitleBox
                    title="심사역 의견"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={true}
                      isShowWordCounter
                      placeholder="예상 매출액과 사업 성과 달성 계획을 바탕으로 의견을 작성해 주세요."
                      defaultData={
                        data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 1
                        )?.comment
                      }
                      onChangeData={(value) => {
                        const opinion = data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 1
                        );
                        if (opinion && onChangeData) {
                          opinion.comment = value;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="전망 평가"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <div className="body-14-rg" style={{ marginTop: 7 }}>
                      {data.riskOpinionList.find(
                        (item) => item.riskOpinion.id === 1
                      )?.result
                        ? "긍정"
                        : "우려"}
                    </div>
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            </FlexColumn>
            <FlexColumn style={{ gap: 16 }}>
              <div className="heading-20-sb">평가 가치 유지</div>
              <FlexColumn style={{ gap: 8 }}>
                <OponionWrapper>
                  <RowTitleBox
                    title="심사역 의견"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={true}
                      isShowWordCounter
                      placeholder="자금도달계획과 시장 환경을 바탕으로 의견을 작성해 주세요."
                      defaultData={
                        data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 2
                        )?.comment
                      }
                    />
                  </RowTitleBox>
                  <RowTitleBox
                    title="전망 평가"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <div className="body-14-rg" style={{ marginTop: 7 }}>
                      {data.riskOpinionList.find(
                        (item) => item.riskOpinion.id === 2
                      )?.result
                        ? "긍정"
                        : "우려"}
                    </div>
                  </RowTitleBox>
                </OponionWrapper>
              </FlexColumn>
            </FlexColumn>

            {portfolioInfo?.curSbpData && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-20-sb">현금 흐름</div>
                <FlexColumn style={{ gap: 8 }}>
                  <RMEDSectorDescription
                    text={`${data.year}년 ${
                      data.quarterNum === 2 ? "1-6" : "7-12"
                    }월에 해당하는 기초현금, 매출, 투자금, 지출액, 예상현금을 바탕으로 현금흐름을 나타냅니다.`}
                  />
                  <OponionWrapper
                    className="heading-14-md"
                    style={{ flexDirection: "row", color: "var(--gray-700)" }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {portfolioInfo.prevSbpData ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: 16,
                          }}
                        >
                          <CashflowStatement
                            sbpData={portfolioInfo.prevSbpData}
                            scale={portfolioInfo.maxScale}
                          />
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {data.year}년{" "}
                            {data.quarterNum === 2
                              ? "1월 ~ 12월"
                              : "7월 ~ 12월"}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {data.year}년{" "}
                          {data.quarterNum === 2 ? "1월 ~ 12월" : "7월 ~ 12월"}{" "}
                          자료가 없습니다.
                        </div>
                      )}
                    </div>
                    <Divider />
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {portfolioInfo.curSbpData ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: 16,
                          }}
                        >
                          <CashflowStatement
                            sbpData={portfolioInfo.curSbpData}
                            scale={portfolioInfo.maxScale}
                          />
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {data.quarterNum === 2 ? data.year : data.year + 1}
                            년{" "}
                            {data.quarterNum === 2
                              ? "7월 ~ 12월"
                              : "1월 ~ 12월"}
                          </div>
                        </div>
                      ) : (
                        <div>
                          {data.quarterNum === 2 ? data.year : data.year + 1}년{" "}
                          {data.quarterNum === 2 ? "7월 ~ 12월" : "1월 ~ 12월"}
                          자료가 없습니다.
                        </div>
                      )}
                    </div>
                  </OponionWrapper>
                </FlexColumn>
              </FlexColumn>
            )}
          </FlexColumn>
        </RMEDSector>
      )}

      <FlexColumn style={{ gap: 8 }}>
        {!closedCompany && (
          <RMEDSectorDescription
            text={`종합 의견 평가 기준|성장: 사업적 성과 달성과 평가 가치 유지 모두 긍정인 경유|관찰: 사업적 성과 달성은 우려, 평가 가치 유지는 긍정인 경우|우려: 사업적 성과 달성과 평가 가치 유지 모두 우려이지만, 현금흐름은 1년 이상인 경우|손상우려: 사업적 성과 달성과 평가 가치 유지 모두 우려이며, 현금흐름이 1년 미만인 경우|손상: 사업적 성과 달성과 평가 가치 유지 모두 우려이고, 현금흐름이 마이너스인 경우`}
          />
        )}
        <OponionWrapper style={{ flexDirection: "row" }}>
          <div style={{ flex: 2 }}>
            {!closedCompany && (
              <RowTitleBox
                title="종합 의견"
                textClassName="heading-14-md"
                titleWidth={89}
                transfromPixel={(n) => n}
              >
                <Selector
                  isReadOnly={false}
                  width={188}
                  placehoder="선택"
                  selectList={
                    basicDictionary
                      ? basicDictionary.status.slice(0, 5).map((item) => ({
                          id: item.id,
                          text: item.name,
                          data: item,
                        }))
                      : []
                  }
                  defaultData={
                    data.riskStatus
                      ? {
                          id: data.riskStatus.id,
                          text: data.riskStatus.name,
                          data: data.riskStatus,
                        }
                      : undefined
                  }
                  onChangeData={(status) => {
                    if (onChangeData) {
                      data.riskStatus = status.data;
                      onChangeData(data);
                    }
                  }}
                />
              </RowTitleBox>
            )}
          </div>
          <FlexRow style={{ flex: 1, gap: 24 }}>
            <RowTitleBox
              title="담당 심사역"
              textClassName="heading-14-md"
              titleWidth={103}
              transfromPixel={(n) => n}
            >
              <div style={{ paddingTop: 13 }}>
                {mainParticipant?.name || ""}
              </div>
            </RowTitleBox>
            <RowTitleBox
              title="서명"
              textClassName="heading-14-md"
              titleWidth={58}
              transfromPixel={(n) => n}
            >
              <div style={{ position: "relative" }}>
                {data.signatureTmp ? (
                  <Signature>
                    {data.signatureTmp.type === 0 && data.signatureTmp.url && (
                      <img src={data.signatureTmp.url} className="sign-img" />
                    )}
                    {data.signatureTmp.type === 1 && data.signatureTmp.path && (
                      <SignatureImage
                        signatureData={data.signatureTmp.path}
                        onLoaded={(index) => {}}
                        index={1}
                      />
                    )}
                    <SignatureOption className="signature__option">
                      <Edit
                        className="signature__option__icon"
                        onClick={() => {
                          updateSignModal(true);
                        }}
                      />
                    </SignatureOption>
                  </Signature>
                ) : (
                  <DefaultButton
                    className="heading-14-md"
                    buttonType="colored-outline"
                    sizeType="small"
                    borderColor="var(--gray-400)"
                    buttonColor="var(--text-01)"
                    text="서명하기"
                    disabled={!isParticipant}
                    onClick={async () => {
                      const myInfo = getMyInfo();
                      const signature = await getSignature(myInfo.id);
                      if (signature) {
                        signData.current = { ...signature, id: undefined };
                        updateExistSignData(true);
                      } else {
                        updateSignModal(true);
                      }
                    }}
                  />
                )}
              </div>
            </RowTitleBox>
          </FlexRow>
        </OponionWrapper>
      </FlexColumn>

      {existSignData && (
        <ModalLayout isOpen={existSignData} isFullScreen>
          <ConfirmModal
            type="confirm"
            contents={["기존 사인이 존재 합니다.", " 불러 올까요?"]}
            onClickNegativeBtn={() => {
              updateExistSignData(false);
              updateSignModal(true);
            }}
            onClickPositiveBtn={() => {
              if (signData.current) {
                data.signatureTmp = signData.current;
                onChangeData && onChangeData(data);
                updateExistSignData(false);
              }
            }}
          />
        </ModalLayout>
      )}

      {signModal && (
        <ModalLayout
          isOpen={signModal}
          isFullScreen
          toggle={() => {
            updateSignModal(false);
          }}
        >
          <SignatureModal
            toggle={() => {
              updateSignModal(false);
            }}
            callBackSignData={(originData, trimData) => {
              if (!data.signatureTmp) {
                data.signatureTmp = {
                  type: 1,
                  path: originData,
                };
              } else {
                data.signatureTmp.id = undefined;
                data.signatureTmp.url = undefined;
                data.signatureTmp.type = 1;
                data.signatureTmp.path = originData;
              }
              if (onChangeData) {
                onChangeData(data);
              }
              updateSignModal(false);
            }}
            callBackSignImg={(url) => {
              if (!data.signatureTmp) {
                data.signatureTmp = {
                  type: 0,
                  url: url,
                };
              } else {
                data.signatureTmp.id = undefined;
                data.signatureTmp.path = undefined;
                data.signatureTmp.type = 0;
                data.signatureTmp.url = url;
              }
              if (onChangeData) {
                onChangeData(data);
              }
              updateSignModal(false);
            }}
            canUploadImage={true}
            canSetDefault={true}
            portfolioId={data.portfolio.portfolioId}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 200px 0px;
  gap: 80px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const OponionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 28px;
  border-radius: 4px;
  background-color: var(--background-contents01);
  border: 1px solid var(--gray-300);
`;

const Signature = styled.div`
  position: absolute;
  width: 120px;
  aspect-ratio: 448/240;
  display: flex;
  top: -12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;

  .sign-img {
    width: 100%;
    height: 100%;
  }

  .signature__option {
    display: none;
    .signature__option__icon {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      cursor: pointer;

      :hover {
        path {
          fill: var(--white);
        }
      }
    }
  }

  :hover {
    background-color: var(--dim-100);
    .signature__option {
      display: flex;
    }
  }
`;
const SignatureOption = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
  flex-direction: row;
  gap: 4px;
`;

const Divider = styled.div`
  width: 1px;
  min-width: 1px;
  max-width: 1px;

  background-color: var(--gray-300);
`;
