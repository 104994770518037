import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGoogle from "../../../hooks/useGoogle";
import { useCookies } from "react-cookie";
import { LoginInfo } from "../../../type/data";
import useResize from "../../../2.0lattice/useResize";
import { initAllPageHistory } from "../../../common/pageHistoryUtil";
import axios from "axios";
import * as api from "../../../api/api";

const useLogin = () => {
  //가입 성공 했을때
  const signup = async () => {
    updateModalMessage("계정 생성에 성공하였습니다.");
    updateModalOpen(true);
  };

  //인증 실패시
  const notAuth = () => {
    updateModalMessage("인증되지 않은 계정입니다. 관리자에게 문의하세요.");
    updateModalOpen(true);
  };

  //로그인 성공시
  const loginSuccess = async (googleLoginInfo: LoginInfo) => {
    loginInfo.current = googleLoginInfo;

    setCookie("myInfo", loginInfo.current);
    setCookie("sessing", true);

    initAllPageHistory();
    navigate("/deal-flow", { replace: true });
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([
    "isAutoLogin",
    "bboxSessionKey",
    "myInfo",
    "sessing",
  ]);
  const [
    isGapiReady,
    isLoggedIn,
    handleSignInClick,
    handleSignOutClick,
    getPDFBlobFile,
    onLoginLattice,
    createFolder,
  ] = useGoogle(
    cookies.isAutoLogin,
    "latticeLogin",
    signup,
    notAuth,
    loginSuccess
  );

  const autoGoogleLogREf = useRef<HTMLAnchorElement>(null);
  const [autoGLogin, updateAutoGLogin] = useState<boolean>(false);

  const [email, updateEmail] = useState<string>("");
  const [password, updatePassword] = useState<string>("");

  const [warningMsg, setWarningMsg] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const loginInfo = useRef<LoginInfo>();

  const [modalOpen, updateModalOpen] = useState<boolean>(false);
  const [modalMessage, updateModalMessage] = useState<string>("");

  const [screenRate, transfromPixel] = useResize();
  const [loginErrorModal, setLoginErrorModal] = useState<boolean>(false);
  const [isLoginOtherDomain, setLoginOtherDomain] = useState<boolean>(false);

  const onChangeAutoLogin = () => {
    setIsChecked(!isChecked);
    setCookie("isAutoLogin", !isChecked);
  };

  const submitLogin = async () => {
    try {
      const param = {
        email: email,
        password: password,
        autoLogin: isChecked,
      };
      const result = await axios.post(api.loginLattice(), param, {
        withCredentials: true,
      });
      if (result.status === 200) {
        loginInfo.current = result.data.loginInfo;

        console.log(result);
        localStorage.removeItem("loginType");
        localStorage.setItem("loginType", "lattice");
        onSuccessLogin();
        initAllPageHistory();
      }
    } catch (error: any) {
      const {
        config,
        response: { status },
      } = error;
      if (status === 401) {
        setLoginErrorModal(true);
      } else if (status === 500) {
        if (!email.includes("@bluepoint.ac")) {
          setLoginOtherDomain(true);
        }
      }
    }
  };

  const onSuccessLogin = () => {
    if (!loginInfo.current) return;
    setCookie("myInfo", loginInfo.current);
    setCookie("sessing", true);
    navigate("/deal-flow", { replace: true });
  };

  const autoLatticeLogin = async () => {
    try {
      navigate("/deal-flow", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(cookies);
    if (cookies.isAutoLogin === "true") {
      const loginType = localStorage.getItem("loginType");
      if (loginType === "google") {
        autoLatticeLogin();
      } else {
        autoLatticeLogin();
      }
    }
  }, []);

  useEffect(() => {
    if (autoGLogin) {
      setTimeout(() => {
        if (autoGoogleLogREf.current) {
          console.log("asdfsf");
          autoGoogleLogREf.current.click();
        }
      }, 1000);
    }
  }, [autoGLogin]);

  return [
    email,
    password,
    isChecked,
    modalOpen,
    isLoggedIn,
    modalMessage,
    loginErrorModal,
    isLoginOtherDomain,
    updateEmail,
    updatePassword,
    submitLogin,
    onChangeAutoLogin,
    onLoginLattice,
    handleSignInClick,
    updateModalOpen,
    setLoginErrorModal,
    setLoginOtherDomain,
  ] as const;
};

export default useLogin;
