import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MobileNavigation, LocationProps } from "./MobileNavigation";

import { ReactComponent as Hambuger } from "../../../assets/image/icon_hambuger.svg";
import { ReactComponent as Alarm } from "../../../assets/image/icon_alarm.svg";
import { getMyInfo } from "../../../common/commonUtil";

import { NavMenu } from "./routerMap";
import { useNavigate } from "react-router-dom";

import Notification from "../../../router/notification/Notification";

interface Props {}

export interface RouterAddress {
  addressName: string;
  pageName?: string;
  isDisabled?: boolean;
}
export interface NavigationMenu extends LocationProps {
  subMenu?: LocationProps[];
}

export const MobileNavigationLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const alarmIconRef = useRef<HTMLDivElement>(null);
  const rightModalRef = useRef<HTMLDivElement>(null);

  const [showMenu, updateShowMenu] = useState<boolean>(false);
  const [openRightModal, updateRightModal] = useState<boolean>(false);
  const [alarmNew, setAlarmNew] = useState<boolean>(false);

  const onMouseDown = (event: any) => {
    if (
      alarmIconRef.current?.contains(event.target) ||
      rightModalRef.current?.contains(event.target)
    ) {
      return;
    }

    updateRightModal(false);
  };

  useEffect(() => {
    window.addEventListener("mousedown", onMouseDown);
    return () => {
      window.removeEventListener("mousedown", onMouseDown);
    };
  }, []);

  if (!NavMenu) return null;

  return (
    <Container className="scroll__invisible">
      <Navigation
        className={`scroll__invisible ${showMenu ? "navigation__show" : ""}`}
      >
        <MobileNavigation
          menus={NavMenu}
          close={() => {
            updateShowMenu(false);
          }}
        />
      </Navigation>
      <Contents>
        <Header>
          <Hambuger
            width={24}
            height={24}
            onClick={() => {
              updateShowMenu(true);
            }}
          />
          <HeaderRight>
            <AlarmContainer
              ref={alarmIconRef}
              className={openRightModal ? "open" : "close"}
              onClick={() => {
                updateRightModal((pre) => !pre);
              }}
            >
              <Alarm className="alarm__icon" />
              {alarmNew && <AlarmNewDot />}
            </AlarmContainer>
            <UserInfoContainer>
              <span
                className="body-14-rg user__name"
                onClick={() => {
                  navigate("/mypage");
                }}
              >
                {getMyInfo().name}
              </span>
            </UserInfoContainer>
          </HeaderRight>
        </Header>
        <ContentChild>
          {children}
          <RightModal
            ref={rightModalRef}
            className={openRightModal ? "open" : ""}
          >
            <Notification
              onChangeNewNoti={(result) => {
                console.log(result);
                setAlarmNew(result);
              }}
              closeRightModal={() => {
                updateRightModal(false);
              }}
            />
          </RightModal>
        </ContentChild>
      </Contents>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-400);

  padding: 0 24px;
`;

const Navigation = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
  transition: 0.5s;
  overflow-y: scroll;
  left: -100%;
  top: 0;
  z-index: 30;
  &.navigation__show {
    left: 0;
  }
`;

const Contents = styled.div`
  position: relative;
  flex-grow: 1;
  height: calc(100%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const UserInfoContainer = styled.div`
  width: auto;
  height: auto;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const AlarmContainer = styled.div`
  position: relative;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 50%;
  cursor: pointer;

  .alarm__icon {
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
  }

  &.open {
    path {
      fill: var(--primary-blue);
    }
  }

  :hover {
    background-color: var(--hover-100);
  }
`;

const AlarmNewDot = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 6px;
  min-width: 6px;
  max-width: 6px;
  height: 6px;
  min-height: 6px;
  max-height: 6px;

  border-radius: 50%;
  background-color: var(--red-500);
`;

const ContentChild = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  background-color: var(--background-page);
  overflow: hidden;
`;
const RightModal = styled.div`
  position: absolute;
  width: 290px;
  height: calc(100% - 24px);

  top: 8px;
  right: -290px;

  background-color: white;
  z-index: 10;
  transition: all 0.2s ease;
  box-shadow: -2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  border-radius: 8px;
  &.open {
    right: 16px;
  }
`;
