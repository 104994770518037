import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  MinuteComponentProps,
  MinutePageProps,
} from "../interface/Minutes.interface";
import { Heading1 } from "./Heading1";
import { Heading2 } from "./Heading2";
import { Heading3 } from "./Heading3";
import { Description } from "./Description";
import { Label } from "./Label";
import { InfoDescription } from "./InfoDescription";

import { DCHeader } from "./damaged-company/DCHeader";
import { CMSFooter } from "./company-management-status/CMSFooter";
import { CMSRow } from "./company-management-status/CMSRow";
import { CMSHeader } from "./company-management-status/CMSHeader";
import { DCRow } from "./damaged-company/DCRow";
import { DCFooter } from "./damaged-company/DCFooter";
import { RMOHeader } from "./\brisk-management-overview/RMOHeader";
import { RMORow } from "./\brisk-management-overview/RMORow";
import { HeadingLabel1 } from "./HeadingLabel1";
import { SummaryFinancialInfo } from "./SummaryFinancialInfo";
import { ReasonSolution } from "./ReasonSolution";
import { RiskOpinion } from "./RiskOpinion";
import { ExaminerSignature } from "./ExaminerSignature";
import PDFPage from "./PDFPage";
import { Reason } from "./Reason";
import html2canvas from "html2canvas";

interface Props {
  id?: string;
  pageNum: number;
  totalPageNum: number;
  data: MinutePageProps;
  year: number;
  quaterNum: number;
  fileDonwloading?: boolean;
  onLoadedCanvas?: (canvas: HTMLCanvasElement) => void;
}
export const MinutePage: React.FC<Props> = React.memo((props) => {
  const { id, data, pageNum, totalPageNum, year, quaterNum } = props;
  const pageRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const getComponents = (data: MinuteComponentProps, index: number) => {
    switch (data.type) {
      case "heading":
        return (
          <Heading1
            key={index}
            header={data.data?.header || ""}
            style={data.style}
          />
        );
      case "heading2":
        return (
          <Heading2
            key={index}
            header={data.data?.header || ""}
            style={data.style}
          />
        );
      case "heading3":
        return (
          <Heading3
            key={index}
            header={data.data?.header || ""}
            style={data.style}
          />
        );
      case "heading_label_1":
        return (
          <HeadingLabel1
            key={index}
            header={data.data?.header || ""}
            label={data.data?.label || ""}
            style={data.style}
          />
        );
      case "description":
        return (
          <Description
            key={index}
            description={data.data?.description || ""}
            style={data.style}
          />
        );
      case "label":
        return (
          <Label
            key={index}
            label={data.data?.label || ""}
            style={data.style}
          />
        );
      case "info_description":
        return (
          <InfoDescription
            key={index}
            description={data.data?.description || ""}
            style={data.style}
          />
        );
      case "company_management_status_header":
        return <CMSHeader key={index} style={data.style} />;
      case "company_management_status_row":
        return (
          <CMSRow
            key={index}
            companyManagementStatus={data.data?.companyManagementStatus}
            style={data.style}
          />
        );
      case "company_management_status_footer_sum":
        return (
          <CMSFooter
            key={index}
            companyManagementStatusSum={data.data?.companyManagementStatusSum}
            style={data.style}
          />
        );
      case "damaged_company_header":
        return <DCHeader key={index} style={data.style} />;
      case "damaged_company_row":
        return (
          <DCRow
            key={index}
            damagedCompany={data.data?.damagedCompany}
            style={data.style}
          />
        );
      case "damaged_company_footer_sum":
        return (
          <DCFooter
            key={index}
            damagedCompanyFinancialStatementValuationSum={
              data.data?.damagedCompanyFinancialStatementValuationSum
            }
            style={data.style}
          />
        );
      case "risk_management_overview_header":
        return (
          <RMOHeader
            key={index}
            style={data.style}
            riskManagementOverviewHeader={
              data.data?.riskManagementOverviewHeader
            }
          />
        );
      case "risk_management_overview_row":
        return (
          <RMORow
            key={index}
            riskManagementOverview={data.data?.riskManagementOverview}
            style={data.style}
          />
        );
      case "summary_financial_info":
        return (
          <SummaryFinancialInfo
            key={index}
            data={data.data?.summaryFinancialInfo}
            style={data.style}
          />
        );
      case "reason_solution":
        return (
          <ReasonSolution
            key={index}
            reasonSolution={data.data?.reasonSolution}
            style={data.style}
          />
        );
      case "risk_opinion":
        return (
          <RiskOpinion
            key={index}
            riskOpinion={data.data?.riskOpinion}
            style={data.style}
          />
        );
      case "examiner_signature":
        return (
          <ExaminerSignature
            key={index}
            signature={data.data?.signature}
            style={data.style}
            onLoaded={() => {
              console.log("asifaslkfjalsfjdalsjfalkjfla");
            }}
          />
        );
      case "pdf_page":
        return (
          <PDFPage
            key={index}
            file={data.data?.pdfPage?.blobFile}
            pdfImage={data.data?.pdfPage?.pageImage}
            pageNum={data.data?.pdfPage?.pageNum}
            isError={data.data?.pdfPage?.isError}
            onLoaded={() => {}}
          />
        );
      case "reason":
        return (
          <Reason
            key={index}
            reasonSolution={data.data?.reasonSolution}
            style={data.style}
          />
        );
    }

    return null;
  };

  return (
    <Container id={id} ref={pageRef}>
      <Header className="caption-12-md">
        <span>리스크 평가</span>
        <div className="header__divider" />
        <span>
          {year}년 {quaterNum}분기
        </span>
      </Header>
      <Body>
        {data.componentsData.map((item, index) => {
          return getComponents(item, index);
        })}
      </Body>
      <Footer className="caption-10-md">
        <span>
          {pageNum}/{totalPageNum}페이지
        </span>
      </Footer>
    </Container>
  );
});

const Container = styled.div`
  width: 210mm;
  min-width: 210mm;
  max-width: 210mm;
  height: calc(297mm - 1px);
  min-height: calc(297mm - 1px);
  max-height: calc(297mm - 1px);
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Header = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 24px 6px 24px;
  color: var(--text01, #1f2022);
  gap: 12px;

  .header__divider {
    flex-grow: 1;
    height: 1px;
    background: var(--gray700, #64676d);
  }
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 102px);
`;

const Footer = styled.div`
  width: 100%;
  height: 52px;
  min-height: 52px;
  max-height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 24px 24px 24px;
  color: var(--text01, #1f2022);
`;
