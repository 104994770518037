import React, { RefObject, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Radio } from "../../../../../../../../../assets/image/icon_radio.svg";
import { SBPSelectorProps } from "../../../interface/SBP.interface";

interface Props {
  list: SBPSelectorProps[];
  selectedItem?: string;
  disabled?: boolean;
  isVertical?: boolean;
  style?: React.CSSProperties;
  onChangeItem?: (data: SBPSelectorProps) => void;
}

const ChoiseSingle: React.FC<Props> = ({
  disabled,
  list,
  selectedItem,
  isVertical = true,
  style,
  onChangeItem,
}) => {
  return (
    <Container
      style={{ ...style, flexDirection: isVertical ? "column" : "row" }}
    >
      {list.map((item, index) => {
        return (
          <RowItem
            className={`body-14-md ${
              item.text === selectedItem ? "radio__selected" : ""
            }`}
            style={{ cursor: disabled ? "default" : "pointer" }}
            onClick={() => onChangeItem && onChangeItem(item)}
          >
            <Radio className="icon" />
            {item.text}
          </RowItem>
        );
      })}
    </Container>
  );
};

const Container = styled.ul`
  display: flex;
  gap: 8px;
`;
const RowItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &.radio__selected {
    path {
      fill: var(--primary-blue);
    }
  }

  .icon {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    margin-top: 2px;
  }
  .title {
    flex-grow: 1;
  }
`;

export default ChoiseSingle;
