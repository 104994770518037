import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as FileUpload } from "../../../../2.0lattice/assets/image/icon-file_upload-32.svg";
import useDND from "../../../../2.0lattice/router/reporting/reporting-detail/components/stock-change-history/useDND";
import { ExpertFileProps } from "../../../../type/data";
import { ExpertFileCard } from "./ExpertFileCard";

interface Props {
  reportFile?: ExpertFileProps[];
  onChangeFile: (file: File[]) => void;
  onDeleteFile: (index: number) => void;
}

export const ExpertFileUpload: React.FC<Props> = (props) => {
  const { reportFile = [], onChangeFile, onDeleteFile } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const onDrop = useCallback((e: any) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    onChangeFile(droppedFiles as File[]);
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const fileList = Array.from(e.target.files);
      onChangeFile(fileList);
    }
  };
  const [isDragging, dragRef] = useDND(onDrop);

  useEffect(() => {
    console.log(reportFile);
  }, []);

  return (
    <Container>
      <FileUploadContainer
        className={`body-14-rg ${isDragging ? "onDrag" : ""}`}
        ref={dragRef}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <FileUpload />
        <div>이곳으로 마우스로 파일을 끌어오거나 클릭해서 업로드하세요</div>
        <input ref={inputRef} type="file" multiple onChange={onChange} />
      </FileUploadContainer>
      <FileContainer>
        {reportFile &&
          reportFile.map((item, index) => {
            return (
              <ExpertFileCard
                file={item}
                onClickDeleteFile={() => {
                  onDeleteFile(index);
                }}
              />
            );
          })}
      </FileContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const FileUploadContainer = styled.div`
  width: 100%;
  border: 2px dashed var(--gray-600);
  padding: 40px 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: var(--gray-600);

  path {
    fill: var(--gray-600);
  }

  &.onDrag {
    border-color: var(--primary-blue);
    color: var(--primary-blue);
    path {
      fill: var(--primary-blue);
    }
  }
  :hover {
    border-color: #333;
    color: #333;
    path {
      fill: #333;
    }
  }
  input {
    display: none;
  }
`;

const FileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 12px;

  margin-top: 24px;
`;
