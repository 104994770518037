import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { TextareaBox } from "../../../../../components-2.0v/input-box/TextareaBox";
import {
  PitchingChangeDataType,
  PrePitchingData,
} from "../../interface/PrePitchingDetail.interface";

interface Props {
  isEditable: boolean;
  pitchingData: PrePitchingData;
  onChangeData: (data: { type: PitchingChangeDataType; data: any }) => void;
}
export const PitchingInfoInvestmentAndCompany: React.FC<Props> = (props) => {
  const { isEditable, pitchingData, onChangeData } = props;
  const [investmentInfo, updateInvestmentInfo] = useState<string>("");
  const [companyInfo, updateCompanyInfo] = useState<string>("");

  useEffect(() => {
    if (
      pitchingData &&
      pitchingData.preEvaluationData &&
      pitchingData.preEvaluationData[0]
    ) {
      updateInvestmentInfo(
        pitchingData.preEvaluationData[0].investmentInfo || ""
      );
      updateCompanyInfo(pitchingData.preEvaluationData[0].companyInfo || "");
    }
  }, [pitchingData]);
  return (
    <Container>
      <RowTitleBox title="투자 정보" titleWidth={128}>
        <TextBox className={`body-14-rg`}>
          <TextareaBox
            isReadOnly={!isEditable}
            defaultData={investmentInfo}
            placeholder={
              isEditable
                ? "투자 라운드(Seed, Pre-A 등), 투자 재원(본계정, 삼증2호 등), N억 희망, Pre N억, Post N억 등의 내용을 작성해 주세요."
                : "내용 없음"
            }
            isShowLength={isEditable}
            maxHeight={200}
            maxLength={300}
            onChangeDataRealTime={(data) => {
              onChangeData({
                type: "investmentInfo",
                data: data,
              });
            }}
          />
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="기업 정보" titleWidth={128}>
        <TextBox className={`body-14-rg`}>
          <TextareaBox
            isReadOnly={!isEditable}
            defaultData={companyInfo}
            placeholder={
              isEditable
                ? "팀(00기업 출신 경영진, 청창사 15기 선발팀), 투자 포인트, 해결한 문제점과 해결 방법, 최근 실적 등의 내용을 작성해 주세요."
                : "내용 없음"
            }
            isShowLength={isEditable}
            maxHeight={200}
            maxLength={300}
            onChangeDataRealTime={(data) => {
              onChangeData({
                type: "companyInfo",
                data: data,
              });
            }}
          />
        </TextBox>
      </RowTitleBox>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const TextBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-01, #1f2022);

  &.is__empty {
    color: var(--text-05);
  }
`;
