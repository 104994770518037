import React from "react";
import styled from "styled-components";
import { Expectation } from "../../organism/Expectation";
import { SurvivalRate } from "../../organism/SurvivalRate";

interface Props {
  selectedUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}
export const ExpectationManagementSection: React.FC<Props> = (props) => {
  const { selectedUserId, excavation, investor, charger, assistant } = props;

  return (
    <Container>
      <SectionHeader className="heading-20-sb">
        <span>전망관리</span>
      </SectionHeader>
      <FlexRow style={{ gap: 16 }}>
        <div style={{ flex: 5, height: "100%" }}>
          <Expectation
            selectedUserId={selectedUserId}
            excavation={excavation}
            investor={investor}
            charger={charger}
            assistant={assistant}
          />
        </div>
        <div style={{ flex: 3, height: "100%" }}>
          <SurvivalRate
            selectedUserId={selectedUserId}
            excavation={excavation}
            investor={investor}
            charger={charger}
            assistant={assistant}
          />
        </div>
      </FlexRow>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
