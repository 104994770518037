import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DefaultButton from "../../../components/button/DefaultButton";
import { ReactComponent as Close } from "../../../assets/image/modal_close.svg";
import SignatureCanvas from "react-signature-canvas";
import { ImageRun } from "docx";
interface Props {
  index: number;
  signatureData: any;
  onLoaded: (index: number) => void;
}

const SignatureImage: React.FC<Props> = (props) => {
  const canvasRef = useRef<any>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.fromData(props.signatureData);
      if (imgRef.current) {
        const imgURL = canvasRef.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
        imgRef.current.src = imgURL;
        imgRef.current.style.opacity = "0";
      }
    }
  }, [props.signatureData]);

  return (
    <Container>
      <img
        className="sign__image"
        ref={imgRef}
        alt="sign"
        onLoad={() => {
          if (imgRef.current && imgRef.current.complete) {
            console.log(imgRef.current.complete);

            if (imgRef.current.clientWidth > imgRef.current.clientHeight) {
              imgRef.current.style.width = "90%";
              imgRef.current.style.height = "auto";
              imgRef.current.style.opacity = "1";
            } else {
              imgRef.current.style.width = "auto";
              imgRef.current.style.height = "90%";
              imgRef.current.style.opacity = "1";
            }
            props.onLoaded(props.index);
          }
        }}
      />
      <SignatureCanvas
        ref={canvasRef}
        canvasProps={{
          className: "sigCanvas",
          width: 448,
          height: 240,
        }}
        clearOnResize={false}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .sign__image {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }
  .sigCanvas {
    position: absolute;
    opacity: 0;
  }
`;
export default SignatureImage;
