import { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PrePitchingCard } from "../components/PrePitchingCard";
import { CreatePrePitchingCard } from "../components/CreatePrePitchingCard";
import {
  PrePichtingResult,
  PrePitchingData,
} from "../../interface/PrePitching.interface";
import { getYMD, getYMDHM } from "../../../../../common/commonUtil";
import { PitchingEmpty } from "./PitchingEmpty";
interface Props {
  sectionTitle: string;
  showCreateCard: boolean;
  createAble: boolean;
  pitchingList: PrePitchingData[];
  onClickCard: (preEvaluationId: number) => void;
  onClickCreatePitching?: () => void;
}
export const PrePitchingSection: React.FC<Props> = (props) => {
  const {
    sectionTitle,
    showCreateCard,
    createAble,
    pitchingList,
    onClickCard,
    onClickCreatePitching,
  } = props;
  useEffect(() => {}, []);

  const getCardTitle = (date?: string) => {
    if (!date) return "피칭심사";
    const transformDate = getYMD(date).replaceAll("-", "").slice(2);
    return `${transformDate} 피칭심사`;
  };
  const getInviteUserText = (data: PrePichtingResult[]) => {
    if (data.length === 0) return "미정";
    return data.map((item) => item.acUser.name).join(", ");
  };
  return (
    <Container>
      <SectionTitle className="heading-18-md">{sectionTitle}</SectionTitle>
      <CardList>
        {!showCreateCard && pitchingList.length === 0 && (
          <PitchingEmpty msg={["열린 피칭 심사가 없어요."]} />
        )}
        {showCreateCard && (
          <CreatePrePitchingCard
            onClickCard={() => {
              createAble && onClickCreatePitching && onClickCreatePitching();
            }}
            disabled={!createAble}
          />
        )}
        {pitchingList.map((item, key) => {
          return (
            <PrePitchingCard
              key={key}
              cardTitle={getCardTitle(item.createdAt)}
              pitchingStatus={item.status}
              inviteUser={getInviteUserText(item.pfPreEvaluationResult)}
              date={
                item.preEvaluationDate
                  ? getYMDHM(item.preEvaluationDate)
                  : "미정"
              }
              onClickCard={() => {
                if (item.preEvaluationId !== undefined) {
                  onClickCard(item.preEvaluationId);
                }
              }}
            />
          );
        })}
      </CardList>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 32px 0px 24px 0px;
`;

const SectionTitle = styled.div`
  color: var(--text-01);
`;

const CardList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
