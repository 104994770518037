import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import {
  DBAcceleratingPortfolio,
  DPBPAccelerating,
} from "../../../../../../type/data";
import { SearchValueType } from "../../../organisms/FilterAccelerating";

import PortfolioParticipant from "../../PortfolioParticipant";
import { TableGridHeaderProps } from "../../table/TableGrid";
import { TableVirtuoso, VirtuosoHandle } from "react-virtuoso";
import useResize from "../../../../../../2.0lattice/useResize";
import TableHeader from "../../table/TableHeader";
import TableRow from "../../table/TableRow";
interface Props {
  resultList: DPBPAccelerating;
  selectedData: any[];
  searchObject?: SearchValueType;
  onPortfolioModal: (portfolio: DBAcceleratingPortfolio) => void;
  updateSelectedData: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        identificationNumber: number;
        data: DBAcceleratingPortfolio;
      }[]
    >
  >;
  sortByKey: (sortItem: string) => void;
}

const AcceleratingDatabaseGrid: React.FC<Props> = (props) => {
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [screenRate, transfromPixel] = useResize();

  const header: TableGridHeaderProps[] = [
    {
      isRequired: true,
      header: "기업명",
      key: "companyName",
      width: 240,
      customCell: (data) => {
        if (!data) return <span style={{ textAlign: "center" }}>-</span>;
        return <span>{data}</span>;
      },
    },
    {
      isRequired: true,
      header: "투자계정",
      key: "acFundAccount",
      width: 200,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {data
              .map(
                (item: any) => `${item.nickName ? item.nickName : item.nameKo}`
              )
              .join(", ")}
          </span>
        );
      },
    },
    {
      isRequired: true,
      header: "빈티지",
      key: "investmentDate",
      width: 80,
      sortable: true,
      sortType:
        props.searchObject && props.searchObject.sortItem === "investmentYear"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return <span style={{ textAlign: "center" }}>{data.slice(0, 4)}</span>;
      },
      onCellClicked: () => {
        props.sortByKey("investmentYear");
      },
    },
    {
      isRequired: true,
      header: "투자금",
      key: "purchasePrincipal",
      width: 100,
      sortable: true,
      sortType:
        props.searchObject &&
        props.searchObject.sortItem === "purchasePrincipal"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span style={{ textAlign: "end" }}>
            {(data / 100000000).toFixed(1)}억원
          </span>
        );
      },
      onCellClicked: () => {
        props.sortByKey("purchasePrincipal");
      },
    },
    {
      isRequired: true,
      header: "기업가치",
      key: "companyValue",
      width: 100,
      sortable: true,
      sortType:
        props.searchObject && props.searchObject.sortItem === "companyValue"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span style={{ textAlign: "end" }}>
            {(data / 100000000).toFixed(1)}억원
          </span>
        );
      },
      onCellClicked: () => {
        props.sortByKey("companyValue");
      },
    },
    {
      isRequired: true,
      header: "지분율",
      key: "ownershipPercentage",
      width: 100,
      sortable: true,
      sortType:
        props.searchObject &&
        props.searchObject.sortItem === "ownershipPercentage"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return <span style={{ textAlign: "end" }}>{data}%</span>;
      },
      onCellClicked: () => {
        props.sortByKey("ownershipPercentage");
      },
    },
    {
      isRequired: true,
      header: "실현 멀티플",
      key: "realizedMultiple",
      width: 120,
      sortable: true,
      sortType:
        props.searchObject && props.searchObject.sortItem === "realizedMultiple"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span style={{ textAlign: "center" }}>-</span>;
        return <span style={{ textAlign: "end" }}>{data.toFixed(2)}X</span>;
      },
      onCellClicked: () => {
        props.sortByKey("realizedMultiple");
      },
    },
    {
      isRequired: true,
      header: "미실현 멀티플",
      key: "unrealizedMultiple",
      width: 120,
      sortable: true,
      sortType:
        props.searchObject &&
        props.searchObject.sortItem === "unrealizedMultiple"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span style={{ textAlign: "end", paddingRight: 20 }}>
            {data.toFixed(2)}X
          </span>
        );
      },
      onCellClicked: () => {
        props.sortByKey("unrealizedMultiple");
      },
    },
    {
      isRequired: true,
      header: "담당자(조력자)",
      key: "pfParticipant",
      width: 190,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <PortfolioParticipant showParticipantNum={2} participants={data} />
        );
      },
    },
    {
      isRequired: false,
      isChecked: true,
      header: "산업기술분류",
      key: "pfIndustrialTech",
      width: 300,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span>
            {data
              .map((item: any) => `#${item.acIndustrialTech.name}`)
              .join(", ")}
          </span>
        );
      },
    },
    {
      isRequired: false,
      isChecked: true,
      header: "매출",
      key: "salesAmount",
      width: 100,
      customCell: (data) => {
        if (!data) return <span style={{ textAlign: "end" }}>-</span>;
        return (
          <span style={{ textAlign: "end" }}>
            {(data / 100000000).toFixed(1)}억원
          </span>
        );
      },
    },
    {
      isRequired: false,
      isChecked: true,
      header: "라운드",
      key: "round",
      width: 100,
      customCell: (data) => {
        return (
          <span style={{ textAlign: "center" }}>{!data ? "-" : data.name}</span>
        );
      },
    },
    {
      isRequired: false,
      isChecked: true,
      header: "아이템 요약",
      key: "description",
      width: 350,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {data.replaceAll("\n", "")}
          </span>
        );
      },
    },
    {
      isRequired: false,
      isChecked: true,
      header: "소재지",
      key: "addr1",
      width: 80,
      customCell: (data) => {
        return (
          <span style={{ textAlign: "center" }}>{!data ? "-" : data}</span>
        );
      },
    },
  ];

  const getIsAllChecked = () => {
    const allData = props.resultList.portfolio.map((item, index) => {
      return item.portfolioId || 0;
    });
    const checkedDataIds = props.selectedData.map(
      (item) => item.identificationNumber
    );
    const isAllChecked =
      allData.filter((item) => !checkedDataIds.includes(item)).length === 0;

    return isAllChecked;
  };

  return (
    <Container className="scroll__invisible">
      <TableVirtuoso
        ref={virtuoso}
        className={`on-scrollbar`}
        height={"100%"}
        onScroll={(e) => {
          console.log("scrolling 중~~~");
        }}
        data={props.resultList.portfolio.map((item, index) => {
          return {
            id: index,
            identificationNumber: item.portfolioId || 0,
            data: item,
          };
        })}
        fixedHeaderContent={() => {
          return (
            <TableHeader
              headers={header}
              transfromPixel={transfromPixel}
              checkable={true}
              isChecked={getIsAllChecked()}
              onCheckData={() => {
                if (getIsAllChecked()) {
                  const allData = props.resultList.portfolio.map(
                    (item, index) => {
                      return item.portfolioId || 0;
                    }
                  );
                  const newData = props.selectedData.filter(
                    (item) => !allData.includes(item.identificationNumber)
                  );

                  props.updateSelectedData([...newData]);
                } else {
                  const selectedData = props.selectedData.map((item, index) => {
                    return item.identificationNumber || 0;
                  });

                  const filterData = props.resultList.portfolio
                    .filter((item) => !selectedData.includes(item.portfolioId))
                    .map((item, index) => ({
                      id: index,
                      identificationNumber: item.portfolioId || 0,
                      data: item,
                    }));

                  const newData: any[] = [...props.selectedData, ...filterData];

                  props.updateSelectedData([...newData]);
                }
              }}
            />
          );
        }}
        itemContent={(index, data) => {
          return (
            <TableRow
              key={index}
              headerData={header}
              rowData={data}
              transfromPixel={transfromPixel}
              checkable={true}
              isChecking={props.selectedData.length > 0}
              isChecked={props.selectedData
                .map((item) => item.identificationNumber)
                .includes(data.identificationNumber)}
              isCheckedDataNotZero={props.selectedData.length > 0}
              onCheckData={(data) => {
                console.log(data);

                const _selectedData = [...props.selectedData];
                console.log([..._selectedData]);

                const checkedIndex = _selectedData.findIndex(
                  (item: any) =>
                    item.identificationNumber === data.identificationNumber
                );
                if (checkedIndex !== -1) {
                  _selectedData.splice(checkedIndex, 1);
                } else {
                  _selectedData.push(data);
                }

                console.log([..._selectedData]);
                props.updateSelectedData([..._selectedData]);
              }}
              onClickRow={() => {
                props.onPortfolioModal(data.data);
              }}
            />
          );
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export default AcceleratingDatabaseGrid;
