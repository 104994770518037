import React from "react";
import styled from "styled-components";

import {
  AddComma,
  RemoveComma,
  addCommasToIntegerPart,
  getYMD,
} from "../../../../../../common/commonUtil";
import {
  FairValueAmountExportProps,
  INVEST,
  InvestmentHistoryAdminProps,
} from "../../interface/Portfolio.interface";
import { InputBox } from "../../../../../../components-2.0v/input-box/InputBox";

interface Props {
  data: FairValueAmountExportProps;
  onChangeRowData: (rowData: FairValueAmountExportProps) => void;
}
export const FairValueAmountRow: React.FC<Props> = ({
  data,
  onChangeRowData,
}) => {
  return (
    <Container className={`body-14-rg`}>
      <CellConatiner width={250 - 24} style={{ justifyContent: "flex-start" }}>
        {data.companyName}
      </CellConatiner>
      <CellConatiner width={400 - 24} style={{ justifyContent: "flex-start" }}>
        {data.fundName}
      </CellConatiner>
      <CellConatiner
        width={150}
        style={{
          justifyContent: "flex-start",
          borderRight: "0.5px solid transparent",
        }}
      >
        <InputBox
          textType="numberWithComma"
          height={32}
          textAlign="end"
          defaultValue={data.amount}
          onBlur={(value) => {
            const removedCommaValue = RemoveComma(value);
            const newData = structuredClone(data);
            newData.amount = removedCommaValue;
            onChangeRowData(newData);
          }}
        />
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  background-color: var(--background-contents01, #fff);
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};

  min-height: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-01);
  border-right: 1px solid var(--divider-300, #e3e4e5);
  border-bottom: 1px solid var(--divider-300, #e3e4e5);
  padding: 8px 10px;
`;
