import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { DefaultButton } from "lattice_core";
import {
  StepComponent,
  StepProps,
} from "../../../../../../components-2.0v/steps/StepComponent";
import {
  ExitProcessProps,
  ExitStep,
  PortfolioDetailAdminProps,
  STEP_FIRST,
  STEP_SECOND,
  STEP_THIRD,
} from "../../interface/Portfolio.interface";
import { ExitFirst } from "./ExitFirst";
import { ExitSecond } from "./ExitSecond";
import { ExitThird } from "./ExitThird";
import { showToast } from "../../../../../../common/commonUtil";
import { rootStyle } from "../../../../../../style/ThemeStyles";
import { Bounce } from "react-toastify";

interface Props {
  selectedPortfolioDetail: PortfolioDetailAdminProps;
  close: () => void;
  exit: (data: ExitProcessProps[]) => void;
}

export const InvestmentExit: React.FC<Props> = (props) => {
  const { selectedPortfolioDetail, close, exit } = props;

  const [stepList, setStepList] = useState<StepProps[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(STEP_FIRST);

  const [exitProcessInfo, updateExitProcessInfo] = useState<ExitProcessProps[]>(
    []
  );

  const firstStepValidation = () => {
    return exitProcessInfo.length > 0;
  };

  const SecondStepValidation = () => {
    if (exitProcessInfo.length === 0) return false;

    const isExitInfoZero =
      exitProcessInfo.filter((item) => item.exitInfo.length === 0).length === 0;

    return isExitInfoZero;
  };

  const ThirdStepValidation = () => {
    if (exitProcessInfo.length === 0) return false;
    if (exitProcessInfo.filter((item) => item.exitInfo.length === 0).length > 0)
      return false;

    return (
      exitProcessInfo.filter((item) => {
        return (
          item.exitInfo.filter((info) => {
            return (
              !info.amount ||
              !info.companyValue ||
              !info.date ||
              !info.principal ||
              !info.stock ||
              !info.stockPrice
            );
          }).length > 0
        );
      }).length === 0
    );
  };

  const setStepData = () => {
    const newStepData = ExitStep.map((item) => {
      let validation = false;
      switch (item.step) {
        case STEP_FIRST:
          validation = firstStepValidation();
          break;
        case STEP_SECOND:
          validation = SecondStepValidation();
          break;
        case STEP_THIRD:
          validation = ThirdStepValidation();
          break;
      }
      return { ...item, isValidate: validation };
    });
    setStepList(newStepData);
  };

  useEffect(() => {
    setStepData();
  }, [exitProcessInfo]);

  return (
    <Container>
      <Header>
        <span className="heading-18-sb">회수</span>
      </Header>

      <BodyContainer>
        <FlexRow
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <StepComponent
            steps={stepList}
            currentStep={currentStep}
            onClickStep={(step) => {
              if (step === STEP_SECOND) {
                if (exitProcessInfo.length === 0) {
                  return showToast({
                    customElement: (
                      <span className="heading-16-sb">
                        회수 하실 재원을 선택해주세요.
                      </span>
                    ),
                    backgroundColor: rootStyle.getPropertyValue("--red-500"),
                    color: rootStyle.getPropertyValue("--text-02"),
                    width: 274,
                    height: 48,
                    milliSec: 1500,
                    transition: Bounce,
                    position: "bottom-center",
                    toastId: new Date().toString(),
                  });
                }
              } else if (step === STEP_THIRD) {
                if (
                  exitProcessInfo.filter((item) => item.exitInfo.length === 0)
                    .length > 0
                ) {
                  return showToast({
                    customElement: (
                      <span className="heading-16-sb">
                        구매자 정보가 없는 재원이 존재합니다.
                      </span>
                    ),
                    backgroundColor: rootStyle.getPropertyValue("--red-500"),
                    color: rootStyle.getPropertyValue("--text-02"),
                    width: 274,
                    height: 48,
                    milliSec: 1500,
                    transition: Bounce,
                    position: "bottom-center",
                    toastId: new Date().toString(),
                  });
                }
              }

              setCurrentStep(step);
            }}
          />
        </FlexRow>
        <div
          style={{ height: "calc(100% - 28px - 48px - 24px)", marginTop: 24 }}
        >
          {currentStep === 1 && (
            <ExitFirst
              selectedPortfolioDetail={selectedPortfolioDetail}
              exitProcessInfo={exitProcessInfo}
              onChangeData={(newData) => {
                updateExitProcessInfo([...newData]);
              }}
            />
          )}
          {currentStep === 2 && (
            <ExitSecond
              selectedPortfolioDetail={selectedPortfolioDetail}
              exitProcessInfo={exitProcessInfo}
              onChangeData={(newData) => {
                updateExitProcessInfo([...newData]);
              }}
            />
          )}
          {currentStep === 3 && (
            <ExitThird
              selectedPortfolioDetail={selectedPortfolioDetail}
              exitProcessInfo={exitProcessInfo}
              onChangeData={(newData) => {
                updateExitProcessInfo([...newData]);
              }}
            />
          )}
        </div>
      </BodyContainer>

      <ButtonContainer>
        <DefaultButton
          className="heading-16-md"
          sizeType="small"
          buttonType="text"
          text="취소"
          onClick={close}
        />
        <DefaultButton
          className="heading-16-md"
          sizeType="small"
          text={"회수하기"}
          buttonColor="var(--primary-purple)"
          hoverColor="var(--purple-700)"
          disabled={
            !firstStepValidation() ||
            !SecondStepValidation() ||
            !ThirdStepValidation()
          }
          onClick={() => {
            console.log(exitProcessInfo);
            exit(exitProcessInfo);
          }}
        />
      </ButtonContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 80vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div(
  () => css`
    width: 100%;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    border-bottom: 1px solid var(--gray-300);

    .warnning {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ffc800;
    }
  `
);

const BodyContainer = styled.div`
  width: 100%;
  height: calc(90vh - 56px - 75px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid var(--grey_20);

    width: 100%;
    height: 75px;
    min-height: 75px;
    max-height: 75px;
    padding-right: 20px;
    gap: 24px;
    button {
      padding: 0 24px;
      height: 42px;
      border: 0;
      min-width: 120px;
      cursor: pointer;
      :hover {
        filter: brightness(1.2);
      }
    }
    .submit {
      background: var(--primary);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      color: var(--white);
    }
    .cancel {
      background: transparent;
      color: var(--grey_50);
    }
  `
);
