import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

import { ReactComponent as CheckT } from "../../../assets/image/new_check_box_true.svg";
import { ReactComponent as CheckF } from "../../../assets/image/new_check_box_false.svg";
import { AddComma } from "../../../../../common/commonUtil";

interface Props {
  riskManagementOverview?: {
    no?: number;
    companyName?: string;
    mainParticipant?: string;
    firstInvestmentData?: string;
    capitalImpairment?: {
      twoYearsAgo?: boolean;
      oneYearsAgo?: boolean;
    };
    damageIssue?: string;
    isDamaged?: boolean;
    isPrevDamaged?: boolean;
  };
  style?: React.CSSProperties;
}

export const RMORow: React.FC<Props> = (props) => {
  const HEIGHT = 32;
  const getBackgroundColor = () => {
    if (props.riskManagementOverview?.isPrevDamaged) {
      return "var(--gray-300)";
    }

    if (props.riskManagementOverview?.isDamaged) {
      return "var(--red-500)";
    }

    return "var(--background-contents-01)";
  };

  return (
    <Container
      style={{
        ...props.style,
        backgroundColor: getBackgroundColor(),
      }}
    >
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 50,
          minWidth: 50,
          minHeight: HEIGHT,
          justifyContent: "center",
        }}
      >
        {props.riskManagementOverview?.no || ""}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 150,
          minWidth: 150,
          minHeight: HEIGHT,
        }}
      >
        {props.riskManagementOverview?.companyName || "-"}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 80,
          minWidth: 80,
          minHeight: HEIGHT,
          justifyContent: "center",
        }}
      >
        {props.riskManagementOverview?.mainParticipant || "-"}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 100,
          minWidth: 100,
          minHeight: HEIGHT,
          justifyContent: "center",
        }}
      >
        {props.riskManagementOverview?.firstInvestmentData
          ?.replaceAll("-", ".")
          .slice(2) || "-"}
      </CellConatiner>

      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 36,
          minWidth: 36,
          minHeight: HEIGHT,
          justifyContent: "center",
        }}
      >
        {props.riskManagementOverview?.capitalImpairment?.twoYearsAgo ===
          undefined || props.riskManagementOverview.isPrevDamaged
          ? ""
          : props.riskManagementOverview?.capitalImpairment?.twoYearsAgo
          ? "O"
          : "X"}
      </CellConatiner>

      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 36,
          minWidth: 36,
          minHeight: HEIGHT,
          justifyContent: "center",
        }}
      >
        {props.riskManagementOverview?.capitalImpairment?.oneYearsAgo ===
          undefined || props.riskManagementOverview.isPrevDamaged
          ? ""
          : props.riskManagementOverview?.capitalImpairment?.oneYearsAgo
          ? "O"
          : "X"}
      </CellConatiner>

      <CellConatiner
        className={`caption-12-rg`}
        style={{
          flexGrow: 1,
          minHeight: HEIGHT,
        }}
      >
        {props.riskManagementOverview?.isPrevDamaged
          ? ""
          : props.riskManagementOverview?.damageIssue || "-"}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 34,
          minWidth: 34,
          minHeight: HEIGHT,
          justifyContent: "center",
        }}
      >
        {props.riskManagementOverview?.isPrevDamaged
          ? ""
          : props.riskManagementOverview?.isDamaged
          ? "O"
          : "X"}
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.div`
  width: 100%;
  cursor: default;

  border-right: 2px solid #000;
  border-bottom: 2px solid #000;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 2px 8px;

  color: #000;

  &:nth-child(1) {
    border-left: 2px solid #000;
  }
`;
