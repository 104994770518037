import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { getMyInfo, getYMD } from "../../../common/commonUtil";
import axios from "axios";
import * as api from "../../../api/api";
import { ProfileUpload } from "../../admin/router/structure/organisms/components/ProfileUpload";
import { colorPicker, IndustryTech, UserInfo } from "../../../type/data";
import { NotionInput } from "../../../components/input/NotionInput";
import { IndustrialTechSector } from "./organism/IndustrialTechSector";
import DefaultButton from "../../../components/button/DefaultButton";
import ModalLayout from "../../../components/modal/ModalLayout";
import ConfirmModal from "../../../components/modal/ConfirmModal";

import useGoogle from "../../../hooks/useGoogle";
import { Logout } from "../../login/desktop/DeskLogin";
import { NavigationLayout } from "../../../components/navigation/NavigationLayout";
import RowTitleBox from "../../../components/box/RowTitleBox";

interface StructureInfo {
  acStructureId?: number | string;
  name: string;
  parent?: number | string;
  structureIndex?: number;
}

const DeskMyPage = () => {
  const TITLE_WIDTH = 80;
  const myInfo = getMyInfo();
  const navigate = useNavigate();
  const [
    isGapiReady,
    isLoggedIn,
    handleSignInClick,
    handleSignOutClick,
    getPDFBlobFile,
    onLoginLattice,
    createFolder,
  ] = useGoogle();
  const originUserInfo = useRef<UserInfo | undefined>(undefined);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [isLoaded, updateLoaded] = useState<boolean>(false);
  const [userInfo, updateUserInfo] = useState<UserInfo | undefined>(undefined);
  const [userStructure, updateUserStructure] = useState<string[]>([]);

  const setUserStructure = async (user: UserInfo) => {
    try {
      if (!user.acStructure) return;

      const acStructureList: StructureInfo[] = (
        await axios.get(api.getAcStructure())
      ).data.acStructure;

      const _userStructure: string[] = [];
      let currentStructure = acStructureList.find(
        (item) => item.acStructureId === user.acStructure?.acStructureId
      );
      while (currentStructure) {
        _userStructure.push(currentStructure.name);
        currentStructure = acStructureList.find(
          (item) => item.acStructureId === currentStructure?.parent
        );
      }
      _userStructure.reverse();
      updateUserStructure([..._userStructure]);
    } catch (error) {}
  };

  const getUserInfo = async () => {
    try {
      const acUserList: UserInfo[] = (
        await axios.get(api.getAcUsers())
      ).data.acUserList.map((item: any): UserInfo => {
        return { ...item, type: "AC_USER", id: item.acUserId };
      });
      const _userInfo = acUserList.find((item) => item.id === myInfo.id);

      console.log(_userInfo);
      if (_userInfo) {
        setUserStructure(_userInfo);
        updateUserInfo({ ..._userInfo });
        originUserInfo.current = { ..._userInfo };
      }
      updateLoaded(true);
    } catch (error) {}
  };

  const onChangeUserShowProfile = (value: boolean) => {
    if (!userInfo) return;

    userInfo.isShowProfile = value;

    updateUserInfo({ ...userInfo });
  };

  const onChangeUserProfile = (url: string, key: string) => {
    if (!userInfo) return;

    userInfo.profileImgUrl = url;
    userInfo.profileImgKey = key;

    updateUserInfo({ ...userInfo });
  };

  const onDeleteProfile = () => {
    if (!userInfo) return;

    userInfo.profileImgKey = undefined;
    userInfo.profileImgUrl = undefined;

    updateUserInfo({ ...userInfo });
  };

  const onChangeColor = (color: string) => {
    if (!userInfo) return;

    userInfo.color = color;
    updateUserInfo({ ...userInfo });
  };

  const onChangeIndustrialTech = (industrialTech: IndustryTech[]) => {
    if (!userInfo) return;

    userInfo.acIndustrialTech = [...industrialTech];
    updateUserInfo({ ...userInfo });
  };

  const logout = async () => {
    try {
      const result = await axios.get(api.logout());
      console.log(result);
      if (result.status === 200) {
        handleSignOutClick();
        Logout();
      }
    } catch (error) {}
  };

  const avaliableChagedUserInfo = (): boolean => {
    if (!originUserInfo.current || !userInfo) return true;

    let originIndustryIds = 0;
    let chagedIndustryIds = 0;
    originUserInfo.current.acIndustrialTech?.forEach(
      (item) => (originIndustryIds += parseInt(`${item.acIndustrialTechId}`))
    );
    userInfo.acIndustrialTech?.forEach(
      (item) => (chagedIndustryIds += parseInt(`${item.acIndustrialTechId}`))
    );

    if (originUserInfo.current.isShowProfile !== userInfo.isShowProfile)
      return false;
    if (originUserInfo.current.color !== userInfo.color) return false;
    if (originUserInfo.current.profileImgKey !== userInfo.profileImgKey)
      return false;
    if (originUserInfo.current.profileImgUrl !== userInfo.profileImgUrl)
      return false;
    if (originIndustryIds !== chagedIndustryIds) return false;

    return true;
  };

  const onChangeMyInfo = async () => {
    try {
      const requestBody = {
        acUserId: myInfo.id,
        profileImgUrl: userInfo?.profileImgUrl,
        profileImgKey: userInfo?.profileImgKey,
        isShowProfile: userInfo?.isShowProfile,
        color: userInfo?.color,
        acIndustrialTech: userInfo?.acIndustrialTech,
      };
      console.log(requestBody);
      const result = await axios.put(api.updateMyInfo(), requestBody);
      if (result.status === 200) {
        setUpdateModal(false);
        logout();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  if (!isLoaded) return null;

  //Todo 권한에 따라 섹터 구분
  return (
    <Container className="scroll__invisible">
      <Contents className="scroll__invisible">
        <span className="heading-20-sb">내 정보</span>
        <UserProfileContainer>
          <ProfileUpload
            profile={userInfo?.profileImgUrl}
            callback={onChangeUserProfile}
            deleteImage={onDeleteProfile}
          />
        </UserProfileContainer>
        <RowTitleBox title="이메일" titleWidth={TITLE_WIDTH}>
          <div className="body-16-rg" style={{ marginTop: 9 }}>
            {userInfo?.email}
          </div>
        </RowTitleBox>
        <RowTitleBox title="이름" titleWidth={TITLE_WIDTH}>
          <div className="body-16-rg" style={{ marginTop: 9 }}>
            {userInfo?.name}
          </div>
        </RowTitleBox>
        <RowTitleBox title="직무" titleWidth={TITLE_WIDTH}>
          <div className="body-16-rg" style={{ marginTop: 9 }}>
            {userInfo?.authorityId === 1
              ? "심사역"
              : userInfo?.authorityId === 2
              ? "매니저"
              : "일반(기타)"}
          </div>
        </RowTitleBox>
        <RowTitleBox title="직책" titleWidth={TITLE_WIDTH}>
          <div className="body-16-rg" style={{ marginTop: 9 }}>
            {userInfo?.acResponsibility?.name || "없음"}
          </div>
        </RowTitleBox>
        <RowTitleBox title="직급" titleWidth={TITLE_WIDTH}>
          <div className="body-16-rg" style={{ marginTop: 9 }}>
            {userInfo?.acPosition?.name || "없음"}
          </div>
        </RowTitleBox>
        <RowTitleBox title="소속" titleWidth={TITLE_WIDTH}>
          <div className="body-16-rg" style={{ marginTop: 9 }}>
            {userStructure.join(" > ")}
          </div>
        </RowTitleBox>

        <RowTitleBox title="입사일" titleWidth={TITLE_WIDTH}>
          <div className="body-16-rg" style={{ marginTop: 9 }}>
            {getYMD(userInfo?.dateOfEntry)}
          </div>
        </RowTitleBox>

        <IndustrialTechSector
          label="전문 섹터"
          labelWidth={TITLE_WIDTH}
          onChange={(e) => {
            onChangeIndustrialTech(e);
          }}
          selectedIndustrialTech={userInfo?.acIndustrialTech}
        />
      </Contents>
      <ButtonContainer>
        <DefaultButton type={"danger"} onClick={logout}>
          로그아웃
        </DefaultButton>
        <div className="button__wrapper">
          <DefaultButton
            type={"text"}
            onClick={() => {
              navigate(-1);
            }}
          >
            취소하기
          </DefaultButton>
          <DefaultButton
            type={"submit"}
            disabled={avaliableChagedUserInfo()}
            onClick={() => {
              setUpdateModal(true);
            }}
          >
            {"수정하기"}
          </DefaultButton>
        </div>
      </ButtonContainer>
      <ModalLayout
        isOpen={updateModal}
        toggle={() => {
          setUpdateModal(false);
        }}
      >
        <ConfirmModal
          type="confirm"
          message={`내 정보를 수정 하면 다시 로그인 해야 합니다.\n내 정보를 수정하시겠습니까?`}
          negativeTitle="취소"
          onClickNegativeBtn={() => {
            setUpdateModal(false);
          }}
          positiveTitle="확인"
          onClickPositiveBtn={onChangeMyInfo}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  background: var(--white);
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  gap: 8px;
  padding: 24px;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    .single__item {
      flex: 1;
    }
    .double__item {
      flex: 2;
    }
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--grey_20);

  width: 100%;
  height: 75px;
  min-height: 75px;
  max-height: 75px;
  padding-right: 20px;
  padding-left: 20px;
  gap: 24px;

  .button__wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
`;

const UserProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .toggle__container {
      position: relative;
      width: 336px;
      height: 36px;
      display: flex;
      flex-direction: row;
      border-radius: 10px;
      overflow: hidden;
      .item {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        cursor: pointer;
      }

      .item.left {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .item.right {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .item.on {
        background: var(--primary);

        border: 1px solid var(--primary);
        color: var(--white);
      }

      .item.off {
        background: var(--white);
        border: 1px solid var(--grey_50);
        color: var(--grey_50);
      }
    }
  }
  .color__picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    .color__item {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
    }
    .color__item.selected {
      border: 2px solid white;
      box-shadow: 0px 0px 8px #2196f3;
    }
  }
  .profile__img {
  }
`;

export default DeskMyPage;
