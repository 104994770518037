import React, { useRef } from "react";

const useFileUpload = () => {
  const fileRef = useRef<HTMLInputElement>(null);

  const onClickFileRef = () => {
    if (!fileRef.current) return;
    fileRef.current.value = "";
    fileRef.current.click();
  };

  return [fileRef, onClickFileRef] as const;
};

export default useFileUpload;
