import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Calendar } from "../../../../assets/image/icon_calendar.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale"; //한국어 설정
import { getNewDate, getYMD } from "../../../../common/commonUtil";

interface Props {
  width?: number;
  defaultDate: string;
  onChangeDate: (date: Date) => void;
}
export const ExcavationDatePicker: React.FC<Props> = (props) => {
  const { width = 150, defaultDate, onChangeDate } = props;

  const selectorRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const datePicker = useRef<any>(null);
  const [selectedDate, updateDate] = useState<Date | undefined>(undefined);

  const [isOpen, updateIsOpen] = useState<boolean>(false);

  const onChange = (date: Date) => {
    updateDate(date);
    onChangeDate(date);
    updateIsOpen(false);
  };

  const handleMouseDown = (event: any) => {
    if (
      optionRef.current &&
      !optionRef.current.contains(event.target) &&
      selectorRef.current &&
      !selectorRef.current.contains(event.target)
    ) {
      updateIsOpen(false);
    }
  };

  const handleScroll = (e: any) => {
    updateIsOpen(false);
    console.log(e);
  };

  useEffect(() => {
    const root = window.document.querySelector("#participant_modal");

    window.addEventListener("mousedown", handleMouseDown);
    root && root.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      root && root.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(defaultDate);
    updateDate(defaultDate ? getNewDate(defaultDate) : undefined);
  }, [defaultDate]);

  return (
    <Container className={`body-14-rg`} style={{ width: width }}>
      <InputContainer
        className={`caption-12-md ${isOpen ? "selected" : ""}`}
        ref={selectorRef}
        onClick={() => updateIsOpen((prev) => !prev)}
      >
        <div>{selectedDate ? getYMD(selectedDate) : ""}</div>
        <Calendar />
      </InputContainer>

      {isOpen && (
        <div ref={optionRef}>
          <DatePicker
            selected={selectedDate}
            onChange={(date: Date) => {
              onChange(date);
            }}
            dateFormat={"yyyy.MM.dd"}
            locale={ko}
            showDisabledMonthNavigation
            inline
          />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  gap: 10px;

  cursor: pointer;

  transition: all 0.2s ease;
  color: var(--text-04);

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
    font-family: "SUIT Regular";
    font-size: 12px;
    letter-spacing: -1%;
  }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
    font-family: "SUIT Regular";
    font-size: 14px;
    letter-spacing: -1%;
  }

  .react-datepicker-time__header {
    display: none;
  }

  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-family: "SUIT SemiBold";
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -1%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: var(--blue-500);
    color: #fff;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--blue-500);
    color: white;
    font-weight: bold;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--dim-100);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: var(--white);

  border: 1px solid var(--gray-300);

  padding: 0 10px;

  gap: 12px;

  &.selected {
    border-color: var(--focus-field-blue);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
