import React from "react";
import styled from "styled-components";

interface Props {
  className?: string;
}
const MainTab: React.FC<Props> = (props) => {
  return <Container className={props.className}> {props.children}</Container>;
};

const Container = styled.div`
  width: 100%;
  height: 61px;
  min-height: 61px;
  max-height: 61px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 24px;
  /* light/background-header */
  background: #ffffff;
  /* light/divider100 */
  border-bottom: 1px solid #f3f4f4;
`;
export default MainTab;
