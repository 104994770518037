import React from "react";
import styled from "styled-components";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { BusinessInfoProps } from "../../interface/PortfolioInfo.interface";

interface Props {
  businessInfo: BusinessInfoProps;
}
export const BusinessDetail: React.FC<Props> = (props) => {
  const { businessInfo } = props;
  return (
    <Container id="business__detail">
      <RowTitleBox title="Problem" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.problem && businessInfo.problem.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {businessInfo.problem || "작성한 내용이 없어요."}
        </TextBox>
      </RowTitleBox>

      <RowTitleBox title="Solution" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.solution && businessInfo.solution.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {businessInfo.solution || "작성한 내용이 없어요."}
        </TextBox>
      </RowTitleBox>

      <RowTitleBox title="BM" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.businessModel && businessInfo.businessModel.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {businessInfo.businessModel || "작성한 내용이 없어요."}
        </TextBox>
      </RowTitleBox>

      <RowTitleBox title="Team" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.team && businessInfo.team.length > 0 ? "" : "is__empty"
          }`}
        >
          {businessInfo.team || "작성한 내용이 없어요."}
        </TextBox>
      </RowTitleBox>

      <RowTitleBox title="기타" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.other && businessInfo.other.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {businessInfo.other || "작성한 내용이 없어요."}
        </TextBox>
      </RowTitleBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 28px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TextBox = styled.pre`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  word-break: break-all;
  white-space: break-spaces;
  padding: 9px 0;

  color: var(--text-01, #1f2022);

  &.is__empty {
    color: var(--text-05);
  }
`;
