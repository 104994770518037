import React from "react";
import { useMediaQuery } from "react-responsive";
import MobileLogin from "./mobile/MobileLogin";
import DeskLogin from "./desktop/DeskLogin";

const RiskManagementEvaluation = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? <MobileLogin /> : <DeskLogin />;
};
export default RiskManagementEvaluation;
