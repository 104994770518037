import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Order } from "../../../assets/image/icon_order.svg";
import { SelectorProps } from "../../../type/data";

interface Props {
  defaultData?: string;
  selectList: SelectorProps[];
  onChangeData?: (data: SelectorProps) => void;
  optionDescription?: string;
  placeholder?: string;
}

export const OrderingBtn: React.FC<Props> = ({
  defaultData,
  selectList,
  onChangeData,
  optionDescription,
  placeholder = "선택",
}) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>("");
  const [showList, updateShowList] = useState<boolean>(false);

  useEffect(() => {
    if (defaultData) {
      const selectedData = selectList.find((item) => item.text === defaultData);
      setValue(selectedData?.text || "");
    }
  }, [defaultData]);

  const handleMouseDown = (event: any) => {
    if (
      optionRef.current &&
      selectorRef.current &&
      !optionRef.current.contains(event.target) &&
      !selectorRef.current.contains(event.target)
    ) {
      updateShowList(false);
    }
  };

  const rectPositoin = () => {
    const root = window.document.querySelector("#root__notification");
    if (selectorRef.current && root) {
      const targetRect = selectorRef.current.getBoundingClientRect();
      if (optionRef.current) {
        optionRef.current.style.width = `${targetRect.width}px`;
        optionRef.current.style.top = `${
          targetRect.top + targetRect.height + 4
        }px`;
        optionRef.current.style.left = `${targetRect.left}px`;
      }
      setTimeout(() => {
        if (optionRef.current) {
          optionRef.current.style.opacity = "1";
        }
      }, 100);
    }
  };

  const handleScroll = (e: any) => {
    updateShowList(false);
    console.log(e);
  };
  useEffect(() => {
    const root = window.document.querySelector("#root__notification");
    window.addEventListener("resize", handleScroll);
    window.addEventListener("mousedown", handleMouseDown);
    root && root.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleScroll);
      window.removeEventListener("mousedown", handleMouseDown);
      root && root.addEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showList) {
      rectPositoin();
    }
  }, [showList]);

  return (
    <Container className={`body-14-rg`}>
      <InputContainer
        ref={selectorRef}
        onClick={() => {
          updateShowList((prev) => !prev);
        }}
      >
        <Order />
        {value.length > 0 && (
          <div className="caption-12-md" style={{ color: "var(--text-04)" }}>
            {value}
          </div>
        )}

        {value.length === 0 && (
          <div className="caption-12-md" style={{ color: "var(--text-04)" }}>
            {placeholder}
          </div>
        )}
      </InputContainer>
      {showList && (
        <ListContainer ref={optionRef}>
          {optionDescription && (
            <DescriptionItem className="caption-10-md">
              {optionDescription}
            </DescriptionItem>
          )}
          {selectList.map((item) => {
            return (
              <ListItem
                className={`caption-10-md ${
                  item.text === value ? "selected" : ""
                }`}
                onClick={() => {
                  updateShowList(false);
                  setValue(item.text);
                  onChangeData && onChangeData(item);
                }}
              >
                {item.text}
              </ListItem>
            );
          })}
        </ListContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: fit-content;
  min-width: 104px;
  height: auto;
  display: flex;
  flex-direction: row;

  &.disabled {
    background-color: var(--gray-50);
  }
`;

const InputContainer = styled.div`
  width: 100%;
  height: 32px;
  border: 1px solid var(--gray-300);
  background-color: var(--background-contents01);

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 4px;

  cursor: pointer;
`;

const ListContainer = styled.div`
  position: fixed;
  width: 100%;
  max-height: 236px;
  display: flex;
  flex-direction: column;
  opacity: 0;

  z-index: 2;

  padding: 8px 0px;

  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  background-color: var(--background-contents02);
  overflow: scroll;
`;

const DescriptionItem = styled.div`
  width: 100%;
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 6px 8px;
  color: var(--text-06);
  cursor: default;
`;

const ListItem = styled.div`
  width: 100%;
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 6px 8px;
  color: var(--text-04);
  cursor: pointer;

  &.selected {
    background-color: var(--blue-100);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
