import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Del } from "../../../../../assets/image/chip_delete.svg";
import { DefaultButton } from "lattice_core";
import {
  FUND_STATUS_EXPIRED,
  FUND_STATUS_INVESTMENT,
  FUND_STATUS_OPERATING,
  FUND_STATUS_PAUSE,
  FUND_STATUS_WATING,
  FundType,
} from "../interface/FundAccount.interface";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";

interface Props {
  fundStatus?: number;
  onChangeStatus: (status: number) => void;
}

export const FundStatus: React.FC<Props> = (props) => {
  const { fundStatus, onChangeStatus } = props;
  const notSelected = {
    textColor: "var(--gray-600)",
    bgColor: "var(--gray-200)",
  };

  const getStatusStyle = (status: number) => {
    if (fundStatus === status) {
      switch (status) {
        case FUND_STATUS_INVESTMENT:
          return { bgColor: "var(--green-500)", textColor: "var(--text-02)" };
        case FUND_STATUS_OPERATING:
          return { bgColor: "var(--blue-400)", textColor: "var(--text-02)" };
        case FUND_STATUS_WATING:
          return { bgColor: "var(--yellow-500)", textColor: "var(--text-02)" };
        case FUND_STATUS_PAUSE:
          return { bgColor: "var(--gray-700)", textColor: "var(--text-02)" };
        default:
          return {
            bgColor: "var(--primary-purple)",
            textColor: "var(--text-02)",
          };
      }
    }
    return notSelected;
  };

  return (
    <Container>
      <CustomColorLabel
        className="label__btn caption-12-md"
        {...getStatusStyle(FUND_STATUS_INVESTMENT)}
        onClick={() => {
          onChangeStatus(FUND_STATUS_INVESTMENT);
        }}
      >
        투자가능
      </CustomColorLabel>
      <CustomColorLabel
        className="label__btn caption-12-md"
        {...getStatusStyle(FUND_STATUS_OPERATING)}
        onClick={() => {
          onChangeStatus(FUND_STATUS_OPERATING);
        }}
      >
        투자완료
      </CustomColorLabel>
      <CustomColorLabel
        className="label__btn caption-12-md"
        {...getStatusStyle(FUND_STATUS_WATING)}
        onClick={() => {
          onChangeStatus(FUND_STATUS_WATING);
        }}
      >
        투자대기
      </CustomColorLabel>
      <CustomColorLabel
        className="label__btn caption-12-md"
        {...getStatusStyle(FUND_STATUS_PAUSE)}
        onClick={() => {
          onChangeStatus(FUND_STATUS_PAUSE);
        }}
      >
        투자중지
      </CustomColorLabel>
      <CustomColorLabel
        className="label__btn caption-12-md"
        {...getStatusStyle(FUND_STATUS_EXPIRED)}
        onClick={() => {
          onChangeStatus(FUND_STATUS_EXPIRED);
        }}
      >
        펀드청산
      </CustomColorLabel>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 9px;
  .label__btn {
    cursor: pointer;
  }
`;
