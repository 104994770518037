import React, { forwardRef, useEffect, useRef, useState } from "react";
import Styled, { css } from "styled-components";
import { DataType } from "../Structure";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { UserCard } from "./components/UserCard";
import { UserInfo } from "../../../../../type/data";
import { StructureInfo } from "../interface/Structure.interface";
import DefaultSearch from "../../../../../components-2.0v/search/DefaultSearch";
import styled from "styled-components";

interface Props {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  filterUser?: UserInfo[];
  onClickUserCard?: (userInfo: UserInfo) => void;
}

export const StructureDetail: React.FC<Props> = (props) => {
  const {
    searchValue,
    setSearchValue,
    filterUser = [],
    onClickUserCard,
  } = props;

  return (
    <Container className="scroll__invisible">
      <SearchContainer>
        <DefaultSearch
          value={searchValue}
          width={350}
          onChangeValue={(value) => {
            setSearchValue(value);
          }}
          placeholder={"이름, 이메일, 연락처, 소속, 직책, 직급, 검색 가능"}
          transfromPixel={(n) => n}
        />
      </SearchContainer>
      <Body className="scroll__invisible">
        {filterUser.length === 0 ? (
          <EmptyContainer>구성원이 비었습니다.</EmptyContainer>
        ) : (
          <UserListContainer className="scroll__invisible">
            {filterUser.map((item, index) => {
              return (
                <UserCard
                  key={index}
                  userInfo={item}
                  isAuthorited={
                    item.userStatusId !== null &&
                    item.userStatusId !== undefined &&
                    item.userStatusId > 1
                  }
                  onClick={() => onClickUserCard && onClickUserCard(item)}
                />
              );
            })}
          </UserListContainer>
        )}
      </Body>
    </Container>
  );
};

const Container = Styled.div(
  () => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  `
);
const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 32px 32px 0 32px;
`;

const EmptyContainer = Styled.div(
  () => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

const Body = Styled.div(
  () => css`
    width: 100%;
    height: 100%;
    overflow: scroll;
  `
);
const UserListContainer = Styled.div(
  () => css`
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    padding: 32px 40px;
    padding-bottom: 96px;
  `
);
