import React, { useEffect, useRef, useState } from "react";
import { IAReportAccount, InvestmentAssessment } from "../../../../type/data";
import {
  cancelAssessment,
  cancelPublishEvaluation,
  confirmAssessment,
  getEvaluationList,
  getPortfolioEvaluation,
  getPortfolioInfo,
  getSpecialUserList,
  modifyEvaluation,
  publishEvaluation,
  requestExpertOpinion,
  sendRequireCompanyInfoEmail,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import {
  getMyInfo,
  getNewDate,
  getPortfolioIdInLocation,
  getYMD,
  transformObjectToBase64,
  trnasfromBase64ToObject,
} from "../../../../common/commonUtil";
import { useLocation } from "react-router-dom";

import {
  createReportDocument,
  isSuccessVote,
} from "../service/InvestmentAssessmentService";
import { Bounce } from "react-toastify";
import { exportFileToPDF } from "../../../../google/docs/GoogleDocsApi";
import { getContractDocumentByFundAccount } from "../../../../api/repository/accelerator/AcceleratorRepository";

import { ReactComponent as File } from "../../../../assets/image/icon_file_upload.svg";
import { ReactComponent as Complete } from "../../../../assets/image/icon_check.svg";
import { ReactComponent as Fail } from "../../../../assets/image/icon_check_red.svg";
import { ResponseInvestmentAssessment } from "../../evaluation/interface/Evaluation.interface";
import { DealFlowStatus } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import useGoogle from "../../../../hooks/useGoogle";
import config from "../../../../config";
import { showDeskToast } from "../../../../2.0lattice/toast";

const useEvaluationDetail = (
  isEditable: boolean,
  setPageName?: (name: string) => void
) => {
  const getInvestmentAssessmentIdByLocation = () => {
    const path = location.pathname.split("/");

    return trnasfromBase64ToObject(path[path.length - 1]).id;
  };
  const location = useLocation();
  const portfolioId = getPortfolioIdInLocation(location);

  const [
    isGapiReady,
    isLoggedIn,
    handleSignInClick,
    handleSignOutClick,
    getPDFBlobFile,
    onLoginLattice,
    createFolder,
  ] = useGoogle();
  const investmentAssessmentId = getInvestmentAssessmentIdByLocation();

  const [portfolioDealStatus, setPortfolioDealStatus] = useState<
    DealFlowStatus | undefined
  >(undefined);

  const [uploadMinuteDocument, setUploadMinuteDocument] =
    useState<boolean>(false);
  const [isPublishable, setIsPublishable] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [isVoteFinished, setIsVoteFinished] = useState<boolean>(false);

  const [evaluationDocument, updateEvaluationDocument] = useState<
    InvestmentAssessment | undefined
  >(undefined);

  const [openEvaluationModal, updateOpenEvaluationModal] =
    useState<boolean>(false);

  const [openPublishModal, updateOpenPublishModal] = useState<boolean>(false);
  const [isWrongInvestAmountModal, updateIsWrongInvestAmountModal] =
    useState<boolean>(false);

  const [openPublishCancelModal, updateOpenPublishCancelModal] =
    useState<boolean>(false);

  const [progressIcon, updateProgressIcon] = useState<
    React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined
  >(undefined);
  const [progressMsg, updateProgressMsg] = useState<string>("");
  const [openProgress, updateOpenProgress] = useState<boolean>(false);
  const [progress, updateProgress] = useState<number>(0);
  const [progressFail, updateProgressFail] = useState<boolean>(false);

  const [refreshModal, updateRefreshModal] = useState<boolean>(false);

  const [isSpecialUser, setIsSpecialUser] = useState<boolean>(false);
  const [openGoogleLoginModal, setOpenGoogleLoginModal] =
    useState<boolean>(false);

  const setSpecialUserList = async () => {
    const result = await getSpecialUserList();
    if (result) {
      const myInfo = getMyInfo();
      setIsSpecialUser(
        result.find((item) => item.acUserId === myInfo.id) !== undefined
      );
    }
  };

  /** 투자 심사 개최 조건
   *
   */
  const checkPublishable = () => {
    if (!evaluationDocument) return false;
    if (evaluationDocument.investmentAssessmentReportAccount.length === 0)
      return false;

    let result = true;

    evaluationDocument.investmentAssessmentReportAccount.forEach((item) => {
      if (!item.assessmentStartDate) return (result = false);
      if (!item.assessmentEndDate) return (result = false);
      if (!item.reportFile) return (result = false);
      if (!item.investmentAssessmentAccount) return (result = false);
      if (!item.requestFundManager) return (result = false);
      if (!item.fundManagerConfirm) return (result = false);

      if (
        !item.investmentAssessmentAccount.acFundAccount ||
        !item.investmentAssessmentAccount.accountName ||
        !item.investmentAssessmentAccount.stockType ||
        !item.investmentAssessmentAccount.newOldStock ||
        !item.investmentAssessmentAccount.quorumType
      )
        return (result = false);

      const account = item.investmentAssessmentAccount;
      if (item.investmentAssessmentAccount.newOldStock.newOldStockId === 1) {
        if (
          account.previousValue === undefined ||
          account.postCompanyValue === undefined ||
          account.totalInvestmentAmount === undefined ||
          account.postTotalStockNumber === undefined ||
          account.faceValue === undefined ||
          account.allocationWay === undefined ||
          account.investmentAmount === undefined ||
          account.stockNumber === undefined ||
          account.issuingPrice === undefined ||
          account.shareholdingRatio === undefined
        )
          return (result = false);
      } else {
        if (
          account.previousValue === undefined ||
          account.postCompanyValue === undefined ||
          account.totalInvestmentAmount === undefined ||
          account.postTotalStockNumber === undefined ||
          account.faceValue === undefined ||
          account.investmentAmount === undefined ||
          account.stockNumber === undefined ||
          account.buyCompany === undefined ||
          account.issuingPrice === undefined ||
          account.buyCompanyValue === undefined ||
          account.discount === undefined ||
          account.curNewIssuingPrice === undefined ||
          account.shareholdingRatio === undefined
        )
          return (result = false);
      }
    });

    return result;
  };

  const getEvaluationDocument = async () => {
    try {
      const result = await getPortfolioEvaluation(investmentAssessmentId);

      if (result) {
        updateEvaluationDocument({ ...result });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeEvaluationDocument = async (
    document: InvestmentAssessment,
    callback?: () => void
  ) => {
    try {
      const result = await modifyEvaluation(document);
      if (result) {
        await getEvaluationDocument();
        callback && callback();
      } else {
        updateRefreshModal(true);
      }
    } catch (error) {
      updateRefreshModal(true);
    }
  };

  const confirmInvestmentAssessment = async () => {
    try {
      if (!evaluationDocument) return;

      const result = await confirmAssessment(investmentAssessmentId, {
        ...evaluationDocument,
        investmentAssessmentReportAccount:
          evaluationDocument.investmentAssessmentReportAccount.map((item) => ({
            ...item,
            isAgreement: isSuccessVote(item),
          })),
      });
      if (result) {
        showDeskToast("success", "확정이 완료되었습니다.");

        setUploadMinuteDocument(false);
        await getEvaluationDocument();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelConfirmInvestmentAssessment = async () => {
    try {
      if (!evaluationDocument) return;

      const result = await cancelAssessment(
        portfolioId,
        evaluationDocument?.investmentAssessmentId
      );
      if (result) {
        showDeskToast("error", "확정이 취소되었습니다.");
        await getEvaluationDocument();
      }
    } catch (error) {}
  };
  //초안 다운로드 함수
  const downloadEvaluationDocument = async (reportAccount: IAReportAccount) => {
    if (!evaluationDocument || !evaluationDocument.folderId) return;
    if (
      !reportAccount.investmentAssessmentAccount.acFundAccount?.acFundAccountId
    )
      return;
    //템플릿 검색
    const templetFiles = await getContractDocumentByFundAccount(
      reportAccount.investmentAssessmentAccount.acFundAccount.acFundAccountId
    );
    const portfolioInfo = await getPortfolioInfo(
      evaluationDocument.portfolio.portfolioId
    );

    if (templetFiles) {
      updateOpenProgress(true);
      updateProgress(0);
      updateProgressIcon(File);
      const reportTemplate = templetFiles.find((file: any, index) => {
        return (
          file.type === "REPORT" &&
          file.newOldStock.newOldStockId ===
            reportAccount.investmentAssessmentAccount.newOldStock
              ?.newOldStockId &&
          file.isNew === !evaluationDocument.isNext
        );
      });

      if (reportTemplate) {
        updateProgress(70);

        updateProgressMsg("투자심사 보고서 생성 중...");
        const result = await createReportDocument(
          reportTemplate.docsFileId,
          evaluationDocument.folderId,
          evaluationDocument,
          reportAccount,
          `투자심사보고서_${reportAccount.investmentAssessmentAccount.accountName}`,
          undefined,
          portfolioInfo
        );

        if (result && result.success && result.data) {
          const seconData = Math.floor(Math.random() * (90 - 70) + 70);
          updateProgress(seconData);
          console.log(result);
          updateProgressMsg("투자심사 보고서 다운로드 중...");
          exportFileToPDF(
            result.data.fileId,
            `투자심사보고서_${portfolioInfo?.companyName}_${getYMD(
              `${getNewDate()}`
            ).replaceAll("-", "")}`,
            downloadSuccess,
            downloadFail
          );
        } else if (result && !result.success && result.errorCode === 401) {
          //login 필요
          setOpenGoogleLoginModal(true);
          updateOpenProgress(false);
        } else {
          downloadFail();
        }
      }
    }
  };

  const sendExportLinkEmail = async () => {
    if (!evaluationDocument) return;

    const portfolioId = evaluationDocument?.portfolio.portfolioId || 0;
    const investmentAssessmentId = evaluationDocument?.investmentAssessmentId;
    const url =
      config.clientUrl +
        "portfolio/external_company_info/" +
        evaluationDocument?.exportLink || "";

    const result = await sendRequireCompanyInfoEmail({
      portfolioId: portfolioId,
      investmentAssessmentId: investmentAssessmentId || 0,
      exportLink: url,
    });

    if (result) {
      const newData: InvestmentAssessment = {
        ...evaluationDocument,
        exportLinkHistory: evaluationDocument?.exportLinkHistory
          ? [result.createdAt, ...evaluationDocument.exportLinkHistory]
          : [result.createdAt],
      };
      onChangeEvaluationDocument(newData, () => {
        showDeskToast("success", "이메일로 작성이 요청되었습니다.");
      });
    }
  };

  const onPublishEvaluation = async () => {
    if (!evaluationDocument || !checkInvestmentAmount()) return;

    const result = await publishEvaluation(
      portfolioId,
      investmentAssessmentId,
      evaluationDocument.updatedAt
    );
    if (result) {
      await getEvaluationDocument();
    }
    return result;
  };
  const onCancelPublishEvaluation = async () => {
    if (!evaluationDocument || investmentAssessmentId === undefined) return;

    const result = await cancelPublishEvaluation(
      portfolioId,
      investmentAssessmentId
    );
    if (result) {
      await getEvaluationDocument();
    }
    return result;
  };

  const checkInvestmentAmount = () => {
    if (evaluationDocument) {
      const thisRoundIssuedStock = evaluationDocument
        .investmentAssessmentReportAccount[0]
        ? evaluationDocument.investmentAssessmentReportAccount[0]
            .investmentAssessmentAccount.postTotalStockNumber || 0
        : 0;
      const thisRoundPreTotalStock = evaluationDocument
        .investmentAssessmentReportAccount[0]
        ? evaluationDocument.investmentAssessmentReportAccount[0]
            .investmentAssessmentAccount.previousTotalStockNumber || 0
        : 0;

      const totalIssuedStock =
        evaluationDocument.investmentAssessmentReportAccount
          .filter(
            (item) =>
              item.investmentAssessmentAccount.newOldStock?.newOldStockId === 1
          )
          .map((item) => item.investmentAssessmentAccount.stockNumber || 0)
          .reduce((prev, cur) => prev + cur, 0);

      const totalStock = evaluationDocument.investmentAssessmentReportAccount
        .filter(
          (item) =>
            item.investmentAssessmentAccount.newOldStock?.newOldStockId === 2
        )
        .map((item) => item.investmentAssessmentAccount.stockNumber || 0)
        .reduce((prev, cur) => prev + cur, 0);

      return (
        thisRoundIssuedStock >= totalIssuedStock &&
        thisRoundPreTotalStock >= totalStock
      );
    }

    return false;
  };

  const downloadSuccess = () => {
    updateProgressMsg("투자심사 보고서 다운로드 성공");
    setTimeout(() => {
      updateProgress(100);
      updateProgressIcon(Complete);
    }, 1000);
    setTimeout(() => {
      updateProgress(0);
      updateOpenProgress(false);
      updateProgressFail(false);
    }, 3000);
  };

  const downloadFail = () => {
    updateProgressMsg("투자심사 보고서 다운로드 실패");
    updateProgressFail(true);

    setTimeout(() => {
      updateProgress(100);
      updateProgressIcon(Fail);
    }, 1000);

    setTimeout(() => {
      updateProgress(0);
      updateOpenProgress(false);
      updateProgressFail(false);
    }, 3000);
  };

  const sendExpertEmail = async () => {
    if (!evaluationDocument) return;

    if (
      evaluationDocument.expertInfo &&
      evaluationDocument.expertInfo.isRequestExpert
    ) {
      const exportLink =
        config.clientUrl +
        "portfolio/expert/" +
        (evaluationDocument.exportLink || "");

      const result = await requestExpertOpinion(
        portfolioId,
        investmentAssessmentId,
        exportLink,
        evaluationDocument.expertInfo.expertTitle || "",
        evaluationDocument.expertInfo.expertEmail || ""
      );

      if (result && result.success) {
        showDeskToast("success", "전문가 의견 요청 메일을 보냈습니다.");
      }
    }
  };

  const getDuplicateNameCount = (
    investmentAssessment: ResponseInvestmentAssessment[],
    index: number
  ) => {
    const diffTitle = getYMD(investmentAssessment[index].createdAt);

    const result = investmentAssessment.slice(index + 1).filter((item) => {
      const title = getYMD(item.createdAt);
      return title === diffTitle;
    });

    return `-${result.length + 1}`;
  };

  //TODO 페이지 이름 넣어주는 다른 방도 찾아봐야함
  const setEvaluationTitle = async () => {
    if (!setPageName) return;

    const result = await getEvaluationList(portfolioId);
    if (result) {
      const data = result.find((item) =>
        item.investmentAssessment.find(
          (assessment) =>
            assessment.investmentAssessmentId ===
            parseInt(`${investmentAssessmentId}`)
        )
      );

      if (data) {
        setPortfolioDealStatus(data.status);
        const targetIdx = data.investmentAssessment.findIndex(
          (assessment) =>
            assessment.investmentAssessmentId ===
            parseInt(`${investmentAssessmentId}`)
        );

        if (targetIdx !== -1) {
          const name = `${getYMD(
            data.investmentAssessment[targetIdx].createdAt
          ).replaceAll("-", "")}${getDuplicateNameCount(
            data.investmentAssessment,
            targetIdx
          )}`;

          setPageName(name);
        }
      }
    }
  };

  const init = async () => {
    await getEvaluationDocument();
    await setSpecialUserList();
  };

  useEffect(() => {
    if (!evaluationDocument) return;

    if (
      isEditable &&
      !evaluationDocument.isConfirmed &&
      evaluationDocument.exportLink === undefined
    ) {
      const _code = transformObjectToBase64({
        portfolioId: portfolioId,
        investmentAssessmentId: investmentAssessmentId,
      });
      onChangeEvaluationDocument({ ...evaluationDocument, exportLink: _code });
      return;
    }

    setIsPublishable(checkPublishable());

    setIsPublished(
      evaluationDocument.investmentAssessmentReportAccount.filter(
        (item) => !item.isPublished
      ).length === 0
    );

    setIsVoteFinished(
      evaluationDocument.investmentAssessmentReportAccount.filter((item) => {
        const finish =
          item.investmentAssessmentInvite.filter(
            (user) =>
              user.isAgreement === undefined || user.isAgreement === null
          ).length === 0;
        return !finish;
      }).length === 0
    );

    setEvaluationTitle();
  }, [evaluationDocument]);

  useEffect(() => {
    init();
  }, []);

  return [
    portfolioId,
    progressMsg,
    progressIcon,
    openProgress,
    progress,
    progressFail,
    portfolioDealStatus,
    evaluationDocument,
    openEvaluationModal,
    openPublishModal,
    openPublishCancelModal,
    isPublishable,
    isPublished,
    isVoteFinished,
    isSpecialUser,
    isWrongInvestAmountModal,
    openGoogleLoginModal,
    updateOpenProgress,
    updateProgress,
    uploadMinuteDocument,
    updateOpenEvaluationModal,
    updateOpenPublishModal,
    updateOpenPublishCancelModal,
    onChangeEvaluationDocument,
    getEvaluationDocument,
    onCancelPublishEvaluation,
    confirmInvestmentAssessment,
    cancelConfirmInvestmentAssessment,
    setUploadMinuteDocument,
    downloadEvaluationDocument,
    refreshModal,
    updateIsWrongInvestAmountModal,
    checkInvestmentAmount,
    sendExportLinkEmail,
    onPublishEvaluation,
    sendExpertEmail,
    setOpenGoogleLoginModal,
    handleSignInClick,
  ] as const;
};

export default useEvaluationDetail;
