import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { CapitalGain } from "../../organism/CapitalGain";
import { NextInvestment } from "../../organism/NextInvestment";

import {
  getNewDate,
  getTransformDateToYearQuarter,
} from "../../../../../../common/commonUtil";
import { getInvestmentPerformanceValue } from "../../../../../../api/repository/accelerator/DashboardRepository";
import {
  PerformanceExitProps,
  PerformanceValueProps,
} from "../../../interface/PerformanceMetrics.interface";
import { ExitGain } from "../../organism/ExitGain";
import { DateRangeProps, SelectRange } from "../../component/SelectRange";
import { ToggleQY } from "../../component/ToggleQY";

interface Props {
  defaultRangeDate?: DateRangeProps;
  structureId?: number;
  selectedFundAccountId?: number[];
}
export const PerformanceManageSection: React.FC<Props> = (props) => {
  const {
    defaultRangeDate = {
      startYear: "2014",
      startQuarter: "1",
      endYear: `${getNewDate().getFullYear()}`,
      endQuarter: "4",
    },
    structureId,
    selectedFundAccountId,
  } = props;

  const isNetworking = useRef<boolean>(false);
  const [unit, updateUnit] = useState<"Y" | "N">("N");
  const [dateStart, setDateStart] = useState<string | undefined>(
    getTransformDateToYearQuarter(
      true,
      defaultRangeDate.startYear,
      defaultRangeDate.startQuarter
    )
  );
  const [dateEnd, setDateEnd] = useState<string | undefined>(
    getTransformDateToYearQuarter(
      false,
      defaultRangeDate.endYear,
      defaultRangeDate.endQuarter
    )
  );
  const [valueData, setValueData] = useState<PerformanceValueProps[]>([]);
  const [exitData, setExitData] = useState<PerformanceExitProps[]>([]);

  const getData = async () => {
    isNetworking.current = true;
    const result = await getInvestmentPerformanceValue({
      dateStart: dateStart,
      dateEnd: dateEnd,
      acFundAccountId: selectedFundAccountId,
      acStructureId: structureId,
    });

    if (result) {
      setValueData(result.value);
      setExitData(result.exit);
    }

    isNetworking.current = false;
  };

  useEffect(() => {
    // if (isNetworking.current) return;
    getData();
  }, [structureId, selectedFundAccountId, dateEnd, dateStart]);

  return (
    <Container>
      <FilterConainer>
        <ToggleQY
          unit={unit}
          trueString="연도"
          falseString="분기"
          onClickUnit={(unit) => updateUnit(unit)}
        />
        <SelectRange
          defaultRangeDate={defaultRangeDate}
          onChangeRnage={(data) => {
            const sDate = getTransformDateToYearQuarter(
              true,
              data.startYear,
              data.startQuarter
            );
            const eDate = getTransformDateToYearQuarter(
              false,
              data.endYear,
              data.endQuarter
            );
            if (sDate && eDate) {
              setDateStart(sDate);
              setDateEnd(eDate);
            }
          }}
        />
      </FilterConainer>
      <NextInvestment
        unit={unit}
        valueData={valueData}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />

      <CapitalGain
        unit={unit}
        valueData={valueData}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
      <ExitGain
        unit={unit}
        exitData={exitData}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 16px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 24px;
`;

const FilterConainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
