import React from "react";
import styled from "styled-components";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { BusinessInfoProps } from "../../interface/PortfolioInfo.interface";
import { PortfolioPhase } from "../../../portfolio-detail/interface/PortfolioNavigation.interface";

import { viewPdfPopup } from "../../../../../common/commonUtil";
import { FileViewerBtn } from "../../../info-modify/desktop/components/FileViewerBtn";

interface Props {
  businessInfo: BusinessInfoProps;
  portfolioPhase: PortfolioPhase;
  emptyData?: string[];
}
export const BusinessSummary: React.FC<Props> = (props) => {
  const { businessInfo, portfolioPhase, emptyData = [] } = props;
  return (
    <Container id="business__summary">
      <RowTitleBox title="한줄 요약" titleWidth={128}>
        <TextBox className="body-14-rg">
          {businessInfo.description && businessInfo.description?.length
            ? businessInfo.description
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="산업 기술" titleWidth={128}>
        <TextBox className="body-14-rg">
          {businessInfo.industrialTechToText &&
          businessInfo.industrialTechToText?.length
            ? businessInfo.industrialTechToText
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="상세 IR자료" titleWidth={128}>
        <TextBox className="body-14-rg">
          {portfolioPhase === "EXCAVATION" ? (
            <FileViewerBtn
              file={businessInfo.businessPlanFile}
              width={400}
              isDanger={emptyData.includes("businessPlanFile")}
              onClickFile={(file) => viewPdfPopup(file.key)}
            />
          ) : (
            <FileViewerBtn
              file={
                businessInfo.detailProposal
                  ? {
                      fileName: businessInfo.detailProposal.fileName,
                      fileType: businessInfo.detailProposal.fileType,
                      key: businessInfo.detailProposal.s3Key,
                      fileSize: 0,
                    }
                  : businessInfo.businessPlanFile || undefined
              }
              width={400}
              onClickFile={(file) => viewPdfPopup(file.key)}
            />
          )}
        </TextBox>
      </RowTitleBox>
      {portfolioPhase === "MANAGE" && (
        <RowTitleBox title="요약 IR자료" titleWidth={128}>
          <TextBox className="body-14-rg">
            <FileViewerBtn
              file={
                businessInfo.summaryProposal
                  ? {
                      fileName: businessInfo.summaryProposal.fileName,
                      fileType: businessInfo.summaryProposal.fileType,
                      key: businessInfo.summaryProposal.s3Key,
                      fileSize: 0,
                    }
                  : undefined
              }
              width={400}
              onClickFile={(file) => viewPdfPopup(file.key)}
            />
          </TextBox>
        </RowTitleBox>
      )}
      {portfolioPhase === "MANAGE" && (
        <RowTitleBox title="실무자 정보" titleWidth={128}>
          {!businessInfo.companyContactEmail &&
          !businessInfo.companyContactName &&
          !businessInfo.companyContactNumber ? (
            <TextBox className="body-14-rg" style={{ color: "var(--text-05)" }}>
              실무자 정보가 없어요.
            </TextBox>
          ) : (
            <TextBox className="body-14-rg">
              {[
                businessInfo.companyContactName,
                businessInfo.companyContactNumber,
                businessInfo.companyContactEmail,
              ]
                .filter((item) => item !== undefined)
                .join(" , ")}
            </TextBox>
          )}
        </RowTitleBox>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 28px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TextBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-01, #1f2022);
`;
