import React from "react";
import styled from "styled-components";
import { Heading3 } from "./Heading3";

interface Props {
  reasonSolution?: { header?: string; reason?: string; solution?: string };
  style?: React.CSSProperties;
}

export const ReasonSolution: React.FC<Props> = (props) => {
  return (
    <Wrapper style={props.style}>
      <Heading3 header={props.reasonSolution?.header || ""} />
      <Container className="caption-12-rg">
        <div className="title__contents">
          <div className="title__container">지정사유 상세설명</div>
          <div className="contents__container">
            {props.reasonSolution?.reason || ""}
          </div>
        </div>

        <div className="title__contents">
          <div className="title__container">리스크 해결 방안</div>
          <div className="contents__container">
            {props.reasonSolution?.solution || ""}
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  padding: 16px 26px 16px 16px;
  gap: 12px;

  border: 2px solid #000;

  .title__contents {
    display: flex;
    flex-direction: row;
    .title__container {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      color: var(--gray-700);
    }

    .contents__container {
      flex-grow: 1;
      color: #000;
    }
  }
`;
