import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { DefaultButton } from "lattice_core";
import {
  FundAccontDetailStep,
  FundAccount,
  FundPurpose,
  FundUser,
  InvestmentAssociationProps,
  STEP_FIRST,
  STEP_SECOND,
  STEP_THIRD,
} from "../interface/FundAccount.interface";
import {
  StepComponent,
  StepProps,
} from "../../../../../components-2.0v/steps/StepComponent";
import { StepFirst } from "./fundaccount_detail/StepFirst";
import { StepSecond } from "./fundaccount_detail/StepSecond";
import { StepThird } from "./fundaccount_detail/StepThird";
import { AcInvestmentOrganization } from "../../../../../type/data";

interface Props {
  allUser: FundUser[];
  allInvestmentOrganization: AcInvestmentOrganization[];
  allFundAccount: FundAccount[];
  close: () => void;
  submit: (newData: FundAccount) => void;
  selectedFundAccount?: FundAccount;
}

export const FundAccountDetail: React.FC<Props> = (props) => {
  const {
    selectedFundAccount,
    allFundAccount,
    allInvestmentOrganization,
    allUser,
    close,
  } = props;

  const [stepList, setStepList] = useState<StepProps[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [fundAccountInfo, setFundAccountInfo] = useState<
    FundAccount | undefined
  >(undefined);
  const [gpData, setGpData] = useState<AcInvestmentOrganization | undefined>(
    undefined
  );
  const [isCreate, setIsCreate] = useState<boolean>(false);

  const onChangeFundAccountInfo = (newData: FundAccount) => {
    setFundAccountInfo({
      ...newData,
      totalAmount: newData.investmentAssociation
        .map((item: InvestmentAssociationProps) => item.investmentAmount || 0)
        .reduce((p: number, c: number) => p + c, 0),
    });
  };

  const firstStepValidation = (fundAccount: FundAccount) => {
    const serialNumberDuplicate =
      allFundAccount
        .filter((item) => item.acFundAccountId !== fundAccount.acFundAccountId)
        .find((item) => item.serialNumber === fundAccount.serialNumber) !==
      undefined;
    if (
      !fundAccount.status ||
      !fundAccount.serialNumber ||
      serialNumberDuplicate ||
      !fundAccount.nameKo ||
      !fundAccount.nameEn ||
      !fundAccount.investmentStartDate ||
      !fundAccount.investmentEndDate ||
      !fundAccount.operatingStartDate ||
      !fundAccount.operatingEndDate ||
      !fundAccount.gpFee ||
      !fundAccount.baseRate ||
      !fundAccount.quorum ||
      !fundAccount.fundManageUser ||
      fundAccount.fundManageUser.length === 0 ||
      !fundAccount.coreOperationalUser ||
      fundAccount.coreOperationalUser.length === 0 ||
      !fundAccount.terms
    ) {
      return false;
    }
    return true;
  };

  const SecondStepValidation = (fundAccount: FundAccount) => {
    if (
      fundAccount.investmentAssociation === undefined ||
      fundAccount.investmentAssociation.length === 0 ||
      fundAccount.investmentAssociation.filter((item) => !item.investmentAmount)
        .length > 0
    ) {
      return false;
    }

    return true;
  };

  const ThirdStepValidation = (fundAccount: FundAccount) => {
    if (
      !SecondStepValidation(fundAccount) ||
      fundAccount.fundPurpose === undefined ||
      fundAccount.fundPurpose.length === 0 ||
      fundAccount.fundPurpose.find((item) => item.isMain) === undefined ||
      fundAccount.fundPurpose.find(
        (item) => (item.amount || 0) > (fundAccount.totalAmount || 0)
      ) !== undefined
    ) {
      return false;
    }
    return true;
  };

  const setStepData = (fundAccount: FundAccount) => {
    const newStepData = FundAccontDetailStep.map((item) => {
      let validation = false;
      switch (item.step) {
        case STEP_FIRST:
          validation = firstStepValidation(fundAccount);
          break;
        case STEP_SECOND:
          validation = SecondStepValidation(fundAccount);
          break;
        case STEP_THIRD:
          validation = ThirdStepValidation(fundAccount);
          break;
      }
      return { ...item, isValidate: validation };
    });
    setStepList(newStepData);
  };

  const getLastID = () => {
    const lastId =
      allFundAccount.length === 0
        ? 0
        : Math.max.apply(
            null,
            allFundAccount.map((item) => item.acFundAccountId || 0)
          );

    return lastId;
  };

  useEffect(() => {
    if (fundAccountInfo) {
      setStepData(fundAccountInfo);
    }
  }, [fundAccountInfo]);

  useEffect(() => {
    const gp = allInvestmentOrganization.find((item) => item.type === 0);
    const gpAssociation = gp
      ? {
          acInvestmentOrganization: {
            acInvestmentOrganizationId: gp.acInvestmentOrganizationId || 0,
            name: gp.name || "",
          },
        }
      : undefined;
    if (selectedFundAccount === undefined) {
      setFundAccountInfo({
        isNew: true,
        isChange: false,
        acFundAccountId: getLastID() + 1,
        type: 2,
        isActive: true,
        fundManageUser: [],
        coreOperationalUser: [],
        fundPurpose: [],
        totalAmount: 0,
        investmentOrganizationCount: gpAssociation ? 1 : 0,
        investmentAssociation: gpAssociation ? [gpAssociation] : [],
      });
      setIsCreate(true);
    } else {
      const fundAccount = structuredClone(selectedFundAccount);
      const totalAmount = fundAccount.investmentAssociation
        .map((item: InvestmentAssociationProps) => item.investmentAmount || 0)
        .reduce((p: number, c: number) => p + c, 0);
      const association = fundAccount.investmentAssociation.find(
        (item: any) => {
          return (
            item.acInvestmentOrganization.acInvestmentOrganizationId ===
            gp?.acInvestmentOrganizationId
          );
        }
      );

      fundAccount.fundPurpose.forEach((item: FundPurpose, index: number) => {
        item.idx = index;
        item.amount = (totalAmount * (item.percent || 0)) / 100;
      });

      setIsCreate(false);
      setFundAccountInfo({
        ...fundAccount,
        isChange: true,
        totalAmount: totalAmount,
        investmentOrganizationCount: fundAccount.investmentAssociation.length,
        investmentAssociation: association
          ? fundAccount.investmentAssociation
          : gpAssociation
          ? [gpAssociation, ...fundAccount.investmentAssociation]
          : fundAccount.investmentAssociation,
      });
    }

    setGpData(gp);
  }, []);

  if (!fundAccountInfo) return null;

  return (
    <Container>
      <Header>
        <span className="heading-18-sb">
          {isCreate ? "펀드 생성" : "펀드 정보 수정"}
        </span>
      </Header>

      <BodyContainer>
        <FlexRow
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <StepComponent
            steps={stepList}
            currentStep={currentStep}
            onClickStep={(step) => {
              setCurrentStep(step);
            }}
          />
        </FlexRow>
        <div
          style={{ height: "calc(100% - 28px - 48px - 24px)", marginTop: 24 }}
        >
          {currentStep === 1 && (
            <StepFirst
              fundAccount={fundAccountInfo}
              allFundAcocunt={allFundAccount}
              allUser={allUser}
              onChangeFundAccountInfo={onChangeFundAccountInfo}
            />
          )}
          {currentStep === 2 && (
            <StepSecond
              gpData={gpData}
              fundAccount={fundAccountInfo}
              allInvestmentOrganization={allInvestmentOrganization}
              onChangeFundAccountInfo={onChangeFundAccountInfo}
            />
          )}
          {currentStep === 3 && (
            <StepThird
              fundAccount={fundAccountInfo}
              onChangeFundAccountInfo={onChangeFundAccountInfo}
            />
          )}
        </div>
      </BodyContainer>

      <ButtonContainer>
        <DefaultButton
          className="heading-16-md"
          sizeType="small"
          buttonType="text"
          text="취소"
          onClick={close}
        />
        <DefaultButton
          className="heading-16-md"
          sizeType="small"
          text={isCreate ? "생성" : "수정"}
          buttonColor="var(--primary-purple)"
          hoverColor="var(--purple-700)"
          disabled={
            !firstStepValidation(fundAccountInfo) ||
            !SecondStepValidation(fundAccountInfo) ||
            !ThirdStepValidation(fundAccountInfo)
          }
          onClick={() => {
            console.log(fundAccountInfo);
            props.submit(fundAccountInfo);
            props.close();
          }}
        />
      </ButtonContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 80vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div(
  () => css`
    width: 100%;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    border-bottom: 1px solid var(--gray-300);

    .warnning {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ffc800;
    }
  `
);

const BodyContainer = styled.div`
  width: 100%;
  height: calc(90vh - 56px - 75px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid var(--grey_20);

    width: 100%;
    height: 75px;
    min-height: 75px;
    max-height: 75px;
    padding-right: 20px;
    gap: 24px;
    button {
      padding: 0 24px;
      height: 42px;
      border: 0;
      min-width: 120px;
      cursor: pointer;
      :hover {
        filter: brightness(1.2);
      }
    }
    .submit {
      background: var(--primary);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      color: var(--white);
    }
    .cancel {
      background: transparent;
      color: var(--grey_50);
    }
  `
);
