import React, { useEffect, useRef, useState } from "react";
import Styled, { css } from "styled-components";

import { useMediaQuery } from "react-responsive";
import TaskLayout from "./mobile/organism/TaskLayout";
import MobilePrePitchingTask from "./mobile/MobilePrePitchingTask";
import DeskPrePitchingTask from "./desktop/DeskPrePitchingTask";

const PrePitchingTask = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;

  return (
    <TaskLayout title="피칭심사">
      {isMobile ? <MobilePrePitchingTask /> : <DeskPrePitchingTask />}
    </TaskLayout>
  );
};

export default PrePitchingTask;
