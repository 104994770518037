import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { AssetPage } from "./AssetPage";
import { DebtCapitalPage } from "./DebtCapitalPage";
import { IncomeStatementPage } from "./IncomeStatementPage";
import { FinancialStatementFullProps } from "../../../../../../../type/data";

interface Props {
  data: FinancialStatementFullProps;
  currency: string;
  onChangeData: (data: FinancialStatementFullProps) => void;
  validationAsset: () => boolean;
  validationDebtCapital: () => boolean;
  validationIncomeStatement: () => boolean;
}
export const TabLayout: React.FC<Props> = (props) => {
  const {
    data,
    onChangeData,
    currency,
    validationAsset,
    validationDebtCapital,
    validationIncomeStatement,
  } = props;
  const [tabIndex, updateTabIndex] = useState<number>(1);
  return (
    <Container>
      <TabContainer>
        <TabItem
          className={tabIndex === 1 ? "selected" : ""}
          onClick={() => updateTabIndex(1)}
        >
          <div className="heading-16-sb">1. 자산</div>
          <Chip
            className={`caption-10-md ${validationAsset() ? "completed" : ""}`}
          >
            {validationAsset() ? "작성완료" : "작성필요"}
          </Chip>
        </TabItem>
        <TabItem
          className={tabIndex === 2 ? "selected" : ""}
          onClick={() => updateTabIndex(2)}
        >
          <div className="heading-16-sb">2. 부채 / 자본</div>
          <Chip
            className={`caption-10-md ${
              validationDebtCapital() ? "completed" : ""
            }`}
          >
            {validationDebtCapital() ? "작성완료" : "작성필요"}
          </Chip>
        </TabItem>
        <TabItem
          className={tabIndex === 3 ? "selected" : ""}
          onClick={() => updateTabIndex(3)}
        >
          <div className="heading-16-sb">3. 손익계산서</div>
          <Chip
            className={`caption-10-md ${
              validationIncomeStatement() ? "completed" : ""
            }`}
          >
            {validationIncomeStatement() ? "작성완료" : "작성필요"}
          </Chip>
        </TabItem>
      </TabContainer>
      {tabIndex === 1 && (
        <AssetPage
          data={data}
          onChangeData={onChangeData}
          currency={currency}
        />
      )}
      {tabIndex === 2 && (
        <DebtCapitalPage
          data={data}
          onChangeData={onChangeData}
          currency={currency}
        />
      )}
      {tabIndex === 3 && (
        <IncomeStatementPage
          data={data}
          onChangeData={onChangeData}
          currency={currency}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;

const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  background-color: var(--background-pagebutton);
  padding: 6px;

  margin-bottom: 8px;
`;

const TabItem = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px 20px;
  border-radius: 8px;

  gap: 4px;

  transition: all 0.2s ease;

  cursor: pointer;

  &.selected {
    background: var(--background-contents01);
    box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.1);
    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    background-color: var(--gray-300);
  }
`;

const Chip = styled.span`
  width: fit-content;
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 20px;

  transition: all 0.2s ease;
  background-color: var(--gray-400);
  color: var(--text-02);

  &.completed {
    background-color: var(--blue-400);
  }
`;
