import React from "react";

import { NavigationLayout } from "../../components/navigation/NavigationLayout";
import { useMediaQuery } from "react-responsive";
import MobileExcavation from "./mobile/MobileExcavation";
import DeskExcavation from "./desktop/DeskExcavation";

const Excavation = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobileExcavation /> : <DeskExcavation />}
    </NavigationLayout>
  );
};
export default Excavation;
