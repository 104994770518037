import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../../components/navigation/NavigationLayout";
import MobileRiskManagementTotalEvaluation from "./mobile/MobileRiskManagementTotalEvaluation";
import DeskRiskManagementTotalEvaluation from "./desktop/DeskRiskManagementTotalEvaluation";

const RiskManagementTotalEvaluation = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? (
        <MobileRiskManagementTotalEvaluation />
      ) : (
        <DeskRiskManagementTotalEvaluation />
      )}
    </NavigationLayout>
  );
};
export default RiskManagementTotalEvaluation;
