import React, { useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as SearchIcon } from "../../assets/image/new_icon_search.svg";
import { ReactComponent as DeleteIcon } from "../../assets/image/new_icon_delete.svg";

interface Props {
  isInitValue?: boolean;
  value: string;
  onChangeValue: (value: string) => void;
  width?: number | string;
  placeholder?: string;
  defaultValue?: string;
  onPressEnter?: (value: string) => void;
  transfromPixel: (value: number) => number;
}
const DefaultSearch: React.FC<Props> = (props) => {
  const {
    isInitValue,
    value,
    placeholder,
    width = 282,
    defaultValue,
    onChangeValue,
    onPressEnter,
    transfromPixel,
  } = props;
  const isEnteredRef = useRef<boolean>(false);
  const prevText = useRef<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [focurs, updateFocurs] = useState<boolean>(false);

  return (
    <Container
      className={`${focurs ? "focursed" : ""}`}
      style={{ width: width }}
      transfromPixel={transfromPixel}
    >
      <SearchIcon style={{ minWidth: 16, minHeight: 16 }} />
      <input
        ref={inputRef}
        className="search__input body-14-rg"
        type={"text"}
        style={{ fontSize: transfromPixel(14) }}
        placeholder={placeholder || "검색어 입력"}
        value={value}
        onChange={(e) => {
          onChangeValue(e.target.value);
        }}
        onFocus={() => updateFocurs(true)}
        onBlur={() => updateFocurs(false)}
        onKeyDown={(e) => {
          if (
            e.code === "Enter" &&
            e.currentTarget.value.length &&
            isEnteredRef.current === false
          ) {
            isEnteredRef.current = true;
            prevText.current = e.currentTarget.value;
            onPressEnter && onPressEnter(value);
            if (isInitValue) {
              e.currentTarget.value = "";
              onChangeValue("");
            }
            e.currentTarget.blur();
          } else if (isEnteredRef.current === true) {
            isEnteredRef.current = false;

            if (!isInitValue && prevText.current !== e.currentTarget.value) {
              e.currentTarget.value = prevText.current;
              onChangeValue(prevText.current);
            }

            if (isInitValue) {
              e.currentTarget.value = "";
              onChangeValue("");
            }
            e.currentTarget.blur();
          }
        }}
      />
      {value.length > 0 && (
        <DeleteIcon
          width={transfromPixel(16)}
          height={transfromPixel(16)}
          style={{ cursor: "pointer" }}
          onClick={() => {
            onChangeValue("");
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  height: ${(props) => props.transfromPixel(36)}px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.transfromPixel(10)}px;

  /* light/background-contents01 */
  background: #ffffff;
  /* light/gray200 */
  border: ${(props) => props.transfromPixel(1)}px solid #edeeef;
  border-radius: ${(props) => props.transfromPixel(4)}px;

  padding: 0 ${(props) => props.transfromPixel(10)}px;

  transition: all 0.2s ease;
  overflow: hidden;

  .search__input {
    width: calc(100% - 32px);
    border: none;
    color: #1f2022; // text01
    ::placeholder {
      color: #BEC0C2//text06;
    }
    :focus {
      outline: none;
    }
  }

  &.focursed {
    border: ${(props) => props.transfromPixel(1)}px solid #a2c7ff;
  }

  :hover {
    /* border: ${(props) => props.transfromPixel(1)}px solid #a2c7ff; */
  }
`;

export default DefaultSearch;
