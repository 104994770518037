import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EvaluationContactRequestProps } from "../../../evaluation/interface/Evaluation.interface";
import { ReactComponent as Radio } from "../../../../../assets/image/icon_radio.svg";
import { AddComma } from "../../../../../common/commonUtil";

interface Props {
  isSelected: boolean;
  card: EvaluationContactRequestProps;
  onClick: () => void;
}

export const ContractEvaluationCard: React.FC<Props> = (props) => {
  const { isSelected, card, onClick } = props;
  return (
    <Container className={isSelected ? "selected" : ""} onClick={onClick}>
      <Header>
        <Radio />
        <div className="heading-16-md">{card.title}</div>
      </Header>
      {card.reportAccountInfo.map((item, key) => {
        return (
          <ReportAccountContainer>
            <Divider />
            <FlexColumn>
              <Title className="caption-12-rg">투자 재원</Title>
              <Contents className="caption-12-md">{item.accountName}</Contents>
            </FlexColumn>
            <FlexColumn>
              <Title className="caption-12-rg">당사 투자 금액</Title>
              <Contents className="caption-12-md">
                {AddComma(item.investmentAmount)}원
              </Contents>
            </FlexColumn>
            <FlexColumn>
              <Title className="caption-12-rg">투자심사위원</Title>
              <Contents className="caption-12-md">
                {item.voteInviteUsers} ({item.voteInviteUserCount}명)
              </Contents>
            </FlexColumn>
          </ReportAccountContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 280px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);
  cursor: pointer;

  &.selected {
    border-color: var(--blue-400);
    path {
      fill: var(--primary-blue);
    }
  }
`;

const Header = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ReportAccountContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--divider-100, #f3f4f4);
  margin-top: 12px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  color: var(--text-06);
`;

const Contents = styled.div`
  color: var(--text-01);
`;
