import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { DefaultButton } from "lattice_core";

import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import { InputBox } from "../../../../../../components-2.0v/input-box/InputBox";
import {
  ExitProcessProps,
  PortfolioDetailAdminProps,
} from "../../interface/Portfolio.interface";
import { ExitTable } from "./ExitTable";

interface Props {
  selectedPortfolioDetail: PortfolioDetailAdminProps;
  exitProcessInfo: ExitProcessProps[];
  onChangeData: (newData: ExitProcessProps[]) => void;
}

export const ExitThird: React.FC<Props> = (props) => {
  const { selectedPortfolioDetail, exitProcessInfo, onChangeData } = props;

  return (
    <Container>
      <FlexColumn style={{ gap: 44 }}>
        {exitProcessInfo.map((item) => {
          return (
            <ExitTable
              exitProcessInfo={item}
              onChangeData={(data) => {
                const newData = exitProcessInfo.map((item) => {
                  if (item.id === data.id) {
                    return data;
                  }
                  return item;
                });
                onChangeData(newData);
              }}
            />
          );
        })}
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px solid var(--gray-400);
  padding: 24px;
  overflow: scroll;
`;

const InvestmentOrganizationList = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
`;
const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--text-04);
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const PurposeConditionContainer = styled.ul`
  margin-top: 8px;
  list-style-type: circle;

  li {
    padding: 2px 0px;
    margin-left: 32px;
  }
`;
