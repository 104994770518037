import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
  ChartOptions,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import {
  EmployeeHistoryProps,
  SummaryFinanceInfoProps,
} from "../../../pre-pitching-detail/interface/PrePitchingDetail.interface";
import { FInancialStatementBarChart } from "../component/FInancialStatementBarChart";
import styled from "styled-components";
import useSBP from "../../../../../2.0lattice/router/reporting/reporting-detail/summary-business-plan/v1/hook/useSBP";
import { getNewDate } from "../../../../../common/commonUtil";
import { ChartContainer } from "../component/ChartContainer";
import { EmployeeHistoryLineBarChart } from "../component/EmployeeHistoryLineBarChart";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  serverTime: Date;
  employeeHistory: EmployeeHistoryProps[];
}

export const EmployeeHistory: React.FC<Props> = (props) => {
  const { serverTime, employeeHistory } = props;

  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "end",
        labels: {
          boxWidth: 12,
        },
        onClick: (e) => {},
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },

      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            console.log(tooltipItem);
            if ([0, 1, 2].includes(tooltipItem.datasetIndex)) {
              return `${tooltipItem.dataset.label} : ${tooltipItem.formattedValue}명`;
            }

            if (tooltipItem.datasetIndex === 3) {
              const data = findHistory(tooltipItem.label);
              if (data) {
                return `${tooltipItem.dataset.label} : ${data.directorEntry
                  ?.split("||")
                  .join(",")}`;
              }
            }

            if (tooltipItem.datasetIndex === 4) {
              const data = findHistory(tooltipItem.label);
              if (data) {
                return `${
                  tooltipItem.dataset.label
                } : ${data.directorResignation?.split("||").join(",")}`;
              }
            }
          },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        suggestedMin: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  const findHistory = (label: string) => {
    const data = employeeHistory.find((history) => {
      const splitLabel = label.split("-");
      const period = splitLabel[1] === "H" ? 2 : 4;
      return (
        `${history.year}` === splitLabel[0] && history.quarterNum === period
      );
    });
    return data;
  };

  useEffect(() => {
    const labels: string[] = [];
    const year = getNewDate().getFullYear();
    const todayYear = serverTime.getFullYear();
    const todayMonth = serverTime.getMonth() + 1;

    for (let i = year - 4; i <= year; i++) {
      if (i === todayYear) {
        if (todayMonth >= 6) {
          labels.push(`${i}-H`);
        } else if (todayMonth === 12) {
          labels.push(`${i}-F`);
        }
      } else {
        labels.push(`${i}-H`);
        labels.push(`${i}-F`);
      }
    }

    const dataset: any = [
      // {
      //   type: "line" as const,
      //   label: "총원",
      //   borderColor: "rgba(23, 58, 255, 1)",
      //   backgroundColor: "rgba(23, 58, 255, 1)",
      //   borderWidth: 2,
      //   fill: true,
      //   data: labels.map((item) => {
      //     const data = findHistory(item);
      //     const value = data?.total ? data.total : 0;
      //     return value;
      //   }),
      // },
      {
        label: "총원",
        data: labels.map((item) => {
          const data = findHistory(item);
          const value = data?.total ? data.total : 0;
          return value;
        }),
        backgroundColor: "rgba(23, 58, 255, 1)",
      },
      {
        label: "입사자",
        data: labels.map((item) => {
          const data = findHistory(item);
          const value = data?.entry ? data.entry : 0;
          return value;
        }),
        backgroundColor: "rgba(69, 132, 255, 1)",
      },
      {
        label: "퇴사자",
        data: labels.map((item) => {
          const data = findHistory(item);
          const value = data?.resignation ? data.resignation : 0;
          return value;
        }),
        backgroundColor: "rgba(227, 228, 229, 1)",
      },
      {
        label: "경영진 입사",
        data: labels.map((item) => {
          const data = findHistory(item);
          const value = data?.directorEntry
            ? data.directorEntry.split("||").length
            : 0;
          return value;
        }),
        textData: "asdfsaf",
        backgroundColor: "rgba(246, 68, 79, 1)",
      },
      {
        label: "경영진 퇴사",
        data: labels.map((item) => {
          const data = findHistory(item);
          const value = data?.directorResignation
            ? data.directorResignation.split("||").length
            : 0;
          return value;
        }),
        textData: "asdfsaf",
        backgroundColor: "rgba(255, 202, 205, 1)",
      },
    ];
    setChartData({ labels: labels, datasets: dataset });
  }, [employeeHistory]);

  if (!chartData) return null;
  return (
    <ChartContainer
      title="인사 변동"
      description="최근 5년 동안의 입퇴사자 정보, 경영진(C-Level) 변동 내역, 총원증감률을 담고 있습니다."
    >
      <EmployeeHistoryLineBarChart data={chartData} options={options} />
    </ChartContainer>
  );
};
