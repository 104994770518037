import React from "react";

import styled from "styled-components";

import { ReactComponent as CheckT } from "../../../../assets/image/new_check_box_true.svg";
import { ReactComponent as CheckF } from "../../../../assets/image/new_check_box_false.svg";
import CustomCell from "./CustomCell";
import { GridDataProps, GridHeaderProps } from "./grid/LatticeGrid";
import { CheckCellConatiner } from "./TableHeader";

interface Props {
  headerData: GridHeaderProps[];
  rowData: GridDataProps;
  transfromPixel: (value: number) => number;
  isChecking?: boolean;
  checkable?: boolean;
  isChecked?: boolean;
  isCheckedDataNotZero?: boolean;
  onClickRow?: () => void;
  onCheckData?: (data: GridDataProps) => void;
  fileDownloadCallback?: () => void;
}

const TableRow: React.FC<Props> = ({
  headerData,
  checkable,
  rowData,
  isChecking,
  isChecked,
  isCheckedDataNotZero,
  onCheckData,
  onClickRow,
  transfromPixel,
  fileDownloadCallback,
}) => {
  console.log();
  return (
    <Container
      className={`body-14-rg ${isChecked ? "checked__row" : ""}`}
      style={{
        cursor: checkable && isCheckedDataNotZero ? "pointer" : "default",
        height: transfromPixel(50),
        minHeight: transfromPixel(50),
        maxHeight: transfromPixel(50),
      }}
      onClick={() => {
        if (isCheckedDataNotZero) {
          if (rowData.data.isOpen) return;
          onCheckData && onCheckData(rowData);
        } else {
          onClickRow && onClickRow();
        }
      }}
    >
      {checkable && (
        <CheckCellConatiner
          className={rowData.data.isOpen ? "opened" : ""}
          style={{
            width: transfromPixel(56),
            minWidth: transfromPixel(56),
            maxWidth: transfromPixel(56),
            borderBottom: `1px solid #edeeef`,
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (rowData.data.isOpen) return;

            onCheckData && onCheckData(rowData);
          }}
        >
          {isChecked || rowData.data.isOpen ? (
            <CheckT width={transfromPixel(16)} height={transfromPixel(16)} />
          ) : (
            <CheckF width={transfromPixel(16)} height={transfromPixel(16)} />
          )}
        </CheckCellConatiner>
      )}
      {headerData.map((item, idx) => {
        if (item.key === "phase") {
          console.log(rowData.data[item.key]);
        }
        return (
          <CellContainer
            key={idx}
            style={{
              width: item.width || 100,
              minWidth: item.width || 100,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <CustomCell
              key={idx}
              data={
                item.key === "phase"
                  ? rowData.data[item.key] === 5
                    ? "관리"
                    : "손상"
                  : rowData.data[item.key]
              }
              isDisabledColor={
                item.key === "portfolioName" &&
                !rowData.data.isOpen &&
                !isChecked
              }
              isChecking={isChecking}
              transfromPixel={transfromPixel}
              fileDownloadCallback={fileDownloadCallback}
            />
          </CellContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.td`
  position: relative;

  display: flex;
  flex-direction: row;
  transform: all 0.2s ease;
  background-color: #ffffff;
  &.checked__row {
    background-color: #fafafb;
  }
`;

const CellContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid #edeeef;
`;
export default React.memo(TableRow);
