import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { AddComma } from "../../../../../common/commonUtil";
import {
  FundAccountDetailInfo,
  FundAccountPurpose,
} from "../../interface/FundAccountDetail.interface";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  data: FundAccountDetailInfo;
}
export const FundMainPurpose: React.FC<Props> = (props) => {
  const [mainPurpose, setMainPurpose] = useState<
    FundAccountPurpose | undefined
  >(undefined);

  const centerTextPlugin = {
    id: "centerTextPlugin",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      ctx.save();
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = "10px SUIT";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#7E8086";
      ctx.fillText("단위 : 백만원", centerX, centerY);
      ctx.restore();
    },
  };

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },

    cutout: 40,
  };
  const data = {
    labels: ["달성금액", "남은금액"],
    datasets: [
      {
        label: "",
        data: [
          mainPurpose?.purposeAmount || 0,
          (mainPurpose?.amount || 0) - (mainPurpose?.purposeAmount || 0),
        ],
        backgroundColor: ["rgba(69, 132, 255, 1)", "rgba(237, 238, 239, 1)"],
        borderColor: ["rgba(69, 132, 255, 1)", "rgba(237, 238, 239, 1)"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const purpose = props.data.investmentPurpose.find((item) => item.isMain);
    setMainPurpose(purpose);
  }, [props.data]);

  if (!mainPurpose) return null;

  return (
    <Container>
      <TitleContainer className="heading-18-sb">
        <span>주목적</span>
        <CustomColorLabel bgColor="var(--blue-50)" textColor="var(--blue-400)">
          {mainPurpose.percent}% 이상 투자
        </CustomColorLabel>
      </TitleContainer>
      <DescriptionContainer className="body-14-md">
        {mainPurpose.target}
      </DescriptionContainer>
      {mainPurpose.purposeAmount > 0 && (
        <BodyContainer>
          <FlexRow>
            <FlexColumn style={{ flex: 1, gap: 14 }}>
              <FlexColumn>
                <div className="body-14-sb" style={{ color: "var(--text-04)" }}>
                  달성률
                </div>
                <div
                  className="heading-20-b"
                  style={{ color: "var(--blue-500)" }}
                >
                  {(
                    (mainPurpose.purposeAmount / mainPurpose.amount) *
                    100
                  ).toFixed(0)}
                  %
                </div>
              </FlexColumn>
              <FlexColumn>
                <div className="body-14-sb" style={{ color: "var(--text-04)" }}>
                  달성까지 남은 금액
                </div>
                <div
                  className="heading-20-b"
                  style={{ color: "var(--blue-500)" }}
                >
                  {AddComma(
                    (
                      (mainPurpose.amount - mainPurpose.purposeAmount) /
                      1000000
                    ).toFixed(0)
                  )}{" "}
                  백만원
                </div>
              </FlexColumn>
            </FlexColumn>
            <div style={{ flex: 2, height: 140 }}>
              <Doughnut
                style={{ display: "inline-block" }}
                data={data}
                options={options}
                width={140}
                height={140}
                plugins={[centerTextPlugin]}
              />
            </div>
          </FlexRow>
        </BodyContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;

  background: var(--background-contents01, #fff);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text-05);
  margin-top: 4px;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--gray-300);
`;
