import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  unit: "Y" | "N";
  trueString: string;
  falseString: string;
  onClickUnit: (unit: "Y" | "N") => void;
}

export const ToggleQY: React.FC<Props> = ({
  unit,
  trueString,
  falseString,
  onClickUnit,
}) => {
  return (
    <Toggle className="caption-12-md">
      <div
        className="toggle__select"
        style={{ left: unit === "N" ? 0 : "50%" }}
      />
      <div
        className={`toggle__item ${unit === "N" ? "selected__item" : ""}`}
        onClick={() => {
          onClickUnit("N");
        }}
      >
        {falseString}
      </div>
      <div
        className={`toggle__item ${unit === "Y" ? "selected__item" : ""}`}
        onClick={() => {
          onClickUnit("Y");
        }}
      >
        {trueString}
      </div>
    </Toggle>
  );
};

const Toggle = styled.div`
  position: relative;
  min-width: 96px;
  height: 28px;
  display: flex;
  flex-direction: row;

  background-color: var(--gray-400);
  border-radius: 4px;

  .toggle__item {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;

    transition-delay: 0.2s;
    transition: color 0.2s ease;
    color: var(--text-03, #5b5d61);
  }

  .selected__item {
    transition-delay: 0.2s;
    transition: color 0.2s ease;
    color: var(--primary-blue);
  }

  .toggle__select {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0px;
    top: 0px;
    border-radius: 4px;
    border: 1px solid var(--gray-400);
    background: var(--light-background-contents-01, #fff);
    transition: left 0.2s ease-in;
  }
`;
