import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import { getNewDate, getYMD, getYMDHMS } from "../common/commonUtil";
import {
  FairValueAmountExportProps,
  FairValueAmountProps,
} from "../router/admin/router/portfolio/interface/Portfolio.interface";

const TempletWord = "래티스 공정거래 평가금액 템플릿";
const getEmptyCell = (): FairValueAmountExportProps => {
  return {
    year: "",
    acFundAccountId: "",
    type: "",
    portfolioId: "",
    companyName: "",
    fundName: "",
    amount: "",
  };
};

const getCellText = (cell: any) => {
  switch (cell.model.type) {
    case 2:
    case 3:
    case 4:
      return `${cell.model.value}`;
    case 6:
      return `${cell.model.result}`;
    case 8:
      return `${cell.model.value.richText
        .map((item: any) => item.text)
        .join("")}`;
    default:
      return "";
  }
};

export const excelDownloadFairValueAmount = async (
  year: number,
  data: FairValueAmountProps[]
) => {
  //포트폴리오 id, year 값으로 데이터 불러오기
  const transformData: FairValueAmountExportProps[] = [];
  data.forEach((item, index) => {
    item.acFundAccount.forEach((fund, index) => {
      transformData.push({
        year: `${year}`,
        acFundAccountId: `${fund.acFundAccountId}`,
        type: `${fund.type}`,
        portfolioId: `${item.portfolioId}`,
        companyName: item.companyName,
        fundName: fund.nameKo,
        amount: fund.amount ? `${fund.amount}` : "",
      });
    });
  });

  if (transformData.length === 0) {
    for (let i = 0; i < 31; i++) {
      transformData.push({ ...getEmptyCell() });
    }
  }

  const defaultAlignment: Partial<ExcelJS.Alignment> = {
    vertical: "middle",
    horizontal: "center",
    shrinkToFit: true,
    wrapText: true,
  };

  const defaultBorder: Partial<ExcelJS.Borders> = {
    top: { color: { argb: "ff000000" }, style: "thin" },
    bottom: { color: { argb: "ff000000" }, style: "thin" },
    left: { color: { argb: "ff000000" }, style: "thin" },
    right: { color: { argb: "ff000000" }, style: "thin" },
  };

  const defaultFont: Partial<ExcelJS.Font> = {
    size: 12,
    color: { theme: 1 },
    name: "Malgun Gothic",
  };

  const dataStyle: Partial<ExcelJS.Style> = {
    font: defaultFont,
    alignment: defaultAlignment,
    border: defaultBorder,
    fill: {
      type: "pattern",
      pattern: "none",
    },
  };

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("공정거래 평가금액"); // sheet 이름이 My Sheet
  // sheet 데이터 설정

  worksheet.views = [{ showGridLines: false }];

  worksheet.columns = [
    { width: 10, key: "year" },
    { width: 10, key: "acFundAccountId" },
    { width: 10, key: "type" },
    { width: 10, key: "portfolioId" },
    { width: 34, key: "companyName" },
    { width: 60, key: "fundName" },
    { width: 20, key: "amount" },
  ];

  worksheet.mergeCells("D1:G1");
  worksheet.getRow(1).height = 70;
  worksheet.getRow(3).height = 36;
  worksheet.getColumn(1).hidden = true;
  worksheet.getColumn(2).hidden = true;
  worksheet.getColumn(3).hidden = true;

  worksheet.getCell("A1").value = TempletWord;
  const descriptionCell = worksheet.getCell("D1");

  descriptionCell.value = {
    richText: [
      {
        text: "공정 가치 평가 평가금액 입력 템플릿입니다.\n",
        font: { bold: true },
      }, // 일반 텍스트
      {
        text: "템플릿에 변화를 주면 오류가 발생",
        font: { color: { argb: "FFFF0000" }, bold: true }, // 빨간색, 볼드체
      },
      { text: "할 수 있습니다.\n", font: { bold: true } },
      { text: "변경하지 마세요.", font: { bold: true } }, // 일반 텍스트
    ],
  };

  descriptionCell.style = {
    alignment: {
      horizontal: "center",
      shrinkToFit: true,
      vertical: "middle",
      wrapText: true,
    },

    font: {
      bold: true,
      color: { argb: "FF000000" },
      name: "Malgun Gothic",
      size: 12,
    },
  };

  worksheet.getCell("A3").value = "연도";
  worksheet.getCell("B3").value = "펀드ID";
  worksheet.getCell("C3").value = "계정유형";
  worksheet.getCell("D3").value = "식별ID";
  worksheet.getCell("E3").value = "회사";
  worksheet.getCell("F3").value = "재원";
  worksheet.getCell("G3").value = "평가 금액";

  transformData.forEach((item, index) => {
    worksheet.insertRow(index + 4, { ...item });
  });

  worksheet.eachRow((row, rowNum) => {
    row.eachCell((cell, colNumber) => {
      if (colNumber > 3) {
        if (rowNum === 1) {
          if (colNumber === 4) {
            cell.style.border = {
              top: { ...defaultBorder.top, style: "medium" },
              bottom: { ...defaultBorder.bottom, style: "medium" },
              left: { ...defaultBorder.left, style: "medium" },
              right: { ...defaultBorder.right, style: "thin" },
            };
          } else if (colNumber === 7) {
            cell.style.border = {
              top: { ...defaultBorder.top, style: "medium" },
              bottom: { ...defaultBorder.bottom, style: "medium" },
              left: { ...defaultBorder.left, style: "thin" },
              right: { ...defaultBorder.right, style: "medium" },
            };
          } else {
            cell.style.border = {
              top: { ...defaultBorder.top, style: "medium" },
              bottom: { ...defaultBorder.bottom, style: "medium" },
              left: { ...defaultBorder.left, style: "thin" },
              right: { ...defaultBorder.right, style: "thin" },
            };
          }
        }
        if (rowNum === 3 && [4, 5, 6, 7].includes(colNumber)) {
          cell.style = {
            alignment: {
              horizontal: "center",
              shrinkToFit: true,
              vertical: "middle",
              wrapText: true,
            },
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FFE8F0FF" },
              fgColor: { argb: "FFE8F0FF" },
            },
            font: {
              bold: true,
              color: { argb: "FF000000" },
              name: "Malgun Gothic",
              size: 12,
            },
          };
          if (colNumber === 4) {
            cell.style.border = {
              top: { ...defaultBorder.top, style: "medium" },
              bottom: { ...defaultBorder.bottom, style: "medium" },
              left: { ...defaultBorder.left, style: "medium" },
              right: { ...defaultBorder.right, style: "thin" },
            };
          } else if (colNumber === 7) {
            cell.style.border = {
              top: { ...defaultBorder.top, style: "medium" },
              bottom: { ...defaultBorder.bottom, style: "medium" },
              left: { ...defaultBorder.left, style: "thin" },
              right: { ...defaultBorder.right, style: "medium" },
            };
          } else {
            cell.style.border = {
              top: { ...defaultBorder.top, style: "medium" },
              bottom: { ...defaultBorder.bottom, style: "medium" },
              left: { ...defaultBorder.left, style: "thin" },
              right: { ...defaultBorder.right, style: "thin" },
            };
          }
        }
        if (rowNum > 3) {
          if (colNumber === 4) {
            cell.style.border = {
              top: { ...defaultBorder.top, style: "thin" },
              bottom: { ...defaultBorder.bottom, style: "thin" },
              left: { ...defaultBorder.left, style: "medium" },
              right: { ...defaultBorder.right, style: "thin" },
            };
          } else if (colNumber === 7) {
            cell.style.border = {
              top: { ...defaultBorder.top, style: "thin" },
              bottom: { ...defaultBorder.bottom, style: "thin" },
              left: { ...defaultBorder.left, style: "thin" },
              right: { ...defaultBorder.right, style: "medium" },
            };
          } else {
            cell.style.border = { ...defaultBorder };
          }
        }
      }
    });
  });

  worksheet.lastRow?.eachCell((cell, colNumber) => {
    if (colNumber > 3) {
      if (colNumber === 4) {
        cell.style.border = {
          top: { ...defaultBorder.top, style: "thin" },
          bottom: { ...defaultBorder.bottom, style: "medium" },
          left: { ...defaultBorder.left, style: "medium" },
          right: { ...defaultBorder.right, style: "thin" },
        };
      } else if (colNumber === 7) {
        cell.style.border = {
          top: { ...defaultBorder.top, style: "thin" },
          bottom: { ...defaultBorder.bottom, style: "medium" },
          left: { ...defaultBorder.left, style: "thin" },
          right: { ...defaultBorder.right, style: "medium" },
        };
      } else {
        cell.style.border = {
          ...defaultBorder,
          bottom: { ...defaultBorder.bottom, style: "medium" },
        };
      }
    }
  });

  // 다운로드
  const mimeType = {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], mimeType);

  saveAs(blob, `${year}년 공정거래 평가금액_${getYMDHMS(getNewDate())}.xlsx`);
};

// 공정거래 데이터 읽기
export const readFairValueAmountExcelFile = (
  file: File,
  callback: ({
    isReadSucess,
    data,
    isFileChanged,
  }: {
    isReadSucess: boolean;
    data: FairValueAmountExportProps[];
    isFileChanged: boolean;
  }) => void
) => {
  if (!file) return;

  const workbook = new ExcelJS.Workbook();
  const reader = new FileReader();

  reader.readAsArrayBuffer(file);
  reader.onload = async () => {
    const rowData: FairValueAmountExportProps[] = [];
    const buffer: any = reader.result;

    if (buffer === null) return [];

    const result = await workbook.xlsx.load(buffer);
    const sheet = result.worksheets[0];

    if (!sheet) {
      return callback({ isReadSucess: false, data: [], isFileChanged: true });
    }
    if (sheet.getCell("A1").value !== TempletWord) {
      return callback({ isReadSucess: false, data: [], isFileChanged: true });
    }

    sheet.eachRow((row, rowNum) => {
      if (rowNum > 3) {
        const cellDatasToString: string[] = [];
        row.eachCell((cell, colNum) => {
          const originData = getCellText(cell);
          cellDatasToString[colNum - 1] = originData;
        });

        const newData: FairValueAmountExportProps = {
          year: cellDatasToString[0],
          acFundAccountId: cellDatasToString[1],
          type: cellDatasToString[2],
          portfolioId: cellDatasToString[3],
          companyName: cellDatasToString[4],
          fundName: cellDatasToString[5],
          amount: cellDatasToString[6],
        };

        rowData.push(newData);
      }
    });

    console.log(rowData);
    callback({ isReadSucess: true, data: rowData, isFileChanged: false });
  };
};
