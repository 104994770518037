import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Arrow } from "../../assets/image/new_icon_arrow.svg";

interface Props {
  data: RollingSelectorDataProps[];
  textAlign?: "rolling_start" | "rolling_middle" | "rolling_end";
  textWidth?: number | "auto";
  className?: string;
  isInfinityRolling?: boolean;
  defaultPosition?: number;
  paused?: boolean;
  onChangeData?: (data: RollingSelectorDataProps, index?: number) => void;
  transfromPixel: (value: number) => number;
}

export interface RollingSelectorDataProps {
  id: number;
  text: string;
  data: any;
}

const RollingSelector: React.FC<Props> = (props) => {
  const {
    data,
    className,
    textAlign = "rolling_end",
    textWidth = "auto",
    isInfinityRolling = false,
    defaultPosition,
    paused = false,
    onChangeData,
    transfromPixel,
  } = props;
  const isDefaultPosition = useRef<boolean>(false);
  const [currentPosition, updateCurrentPosition] = useState<number>(-1);
  const [value, updateValue] = useState<RollingSelectorDataProps | undefined>(
    undefined
  );
  const [prevAble, updatePrevAble] = useState<boolean>(false);
  const [nextAble, updateNextAble] = useState<boolean>(false);

  const onClickPrev = () => {
    if (data.length === 0) return;

    isDefaultPosition.current = false;
    if (isInfinityRolling && currentPosition === 0) {
      updateCurrentPosition(data.length - 1);
      updatePrevAble(true);
    } else {
      updateCurrentPosition(currentPosition - 1);
      updatePrevAble(currentPosition - 1 !== 0);
    }
    updateNextAble(true);
  };

  const onClickNext = () => {
    if (data.length === 0) return;

    isDefaultPosition.current = false;
    if (isInfinityRolling) {
      updateCurrentPosition((currentPosition + 1) % data.length);
      updateNextAble(true);
    } else {
      updateCurrentPosition(currentPosition + 1);
      updateNextAble(currentPosition + 1 !== data.length - 1);
    }
    updatePrevAble(true);
  };

  useEffect(() => {
    if (data.length > 0 && !isDefaultPosition.current) {
      onChangeData && onChangeData(data[currentPosition], currentPosition);
    }
  }, [value]);

  useEffect(() => {
    if (data.length > 0) {
      updateValue(data[currentPosition]);
    }
  }, [currentPosition]);

  useEffect(() => {
    if (defaultPosition !== undefined) {
      console.log("변화하는 인덱스 : " + defaultPosition);
      isDefaultPosition.current = true;
      updateCurrentPosition(defaultPosition);
      updatePrevAble(isInfinityRolling || defaultPosition !== 0);
      updateNextAble(isInfinityRolling || defaultPosition !== data.length - 1);
    } else if (data.length > 0) {
      updateCurrentPosition(0);
      updatePrevAble(isInfinityRolling);
      updateNextAble(true);
    }
  }, [defaultPosition]);
  return (
    <Container className={className} transfromPixel={transfromPixel}>
      {textAlign === "rolling_start" && (
        <span
          className="heading-20-sb rolling__text"
          style={{
            fontSize: transfromPixel(20),
            marginRight: transfromPixel(6),
            width: textWidth,
          }}
        >
          {value ? value.text : ""}
        </span>
      )}
      <div
        className={`prev arrow__icon ${
          props.data.length === 0 || paused || !prevAble ? "disabled" : ""
        }`}
        onClick={() => {
          !paused && prevAble && onClickPrev();
        }}
      >
        <Arrow width={transfromPixel(16)} height={transfromPixel(16)} />
      </div>
      {textAlign === "rolling_middle" && (
        <span
          className="heading-20-sb rolling__text"
          style={{
            fontSize: transfromPixel(20),
            width: textWidth,
          }}
        >
          {value ? value.text : ""}
        </span>
      )}
      <div
        className={`next arrow__icon ${
          props.data.length === 0 || paused || !nextAble ? "disabled" : ""
        }`}
        onClick={() => {
          !paused && nextAble && onClickNext();
        }}
      >
        <Arrow width={transfromPixel(16)} height={transfromPixel(16)} />
      </div>
      {textAlign === "rolling_end" && (
        <span
          className="heading-20-sb rolling__text"
          style={{
            fontSize: transfromPixel(20),
            marginLeft: transfromPixel(6),
            width: textWidth,
          }}
        >
          {value ? value.text : ""}
        </span>
      )}
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  width: fit-content;
  height: ${(props) => props.transfromPixel(30)}px;
  min-height: ${(props) => props.transfromPixel(30)}px;
  max-height: ${(props) => props.transfromPixel(30)}px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.transfromPixel(10)}px;

  .rolling__text {
    min-width: ${(props) => props.transfromPixel(30)}px;
    text-align: center;
  }

  .arrow__icon {
    width: ${(props) => props.transfromPixel(32)}px;
    min-width: ${(props) => props.transfromPixel(32)}px;
    max-width: ${(props) => props.transfromPixel(32)}px;
    height: ${(props) => props.transfromPixel(32)}px;
    min-height: ${(props) => props.transfromPixel(32)}px;
    max-height: ${(props) => props.transfromPixel(32)}px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: ${(props) => props.transfromPixel(4)}px;
    border: 1px solid #d5d6d8;

    transition: all 0.2s ease;
    cursor: pointer;
    path {
      fill: #1f2022; // icon01
    }
    &.disabled {
      path {
        fill: #bec0c2;
      }
      :hover {
        background-color: rgba(0, 0, 0, 0);
      }
    }

    :hover {
      background-color: #f3f4f4;
    }
  }

  .prev {
  }

  .next {
    transform: rotate(180deg);
  }
`;
export default RollingSelector;
