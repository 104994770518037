import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import { getNewDate, getYMDHMS } from "../../../common/commonUtil";

export interface SubmitStatusProps {
  portfolioId: number;
  companyName: string;
  participants: string;
  submitStatus: string;
  riskStatus: string;
  riskIssue: string;
}

const headerDefaultStyle: Partial<ExcelJS.Style> = {
  font: {
    size: 8,
    bold: true,
  },
  alignment: {
    vertical: "middle",
    horizontal: "center",
  },
};
const portfolioId: Partial<ExcelJS.Column> = {
  // header: "기업번호",
  key: "portfolioId",
  width: 10,
  style: { ...headerDefaultStyle },
};
const portfolioName: Partial<ExcelJS.Column> = {
  // header: "기업명",
  key: "companyName",
  width: 40,
  style: { ...headerDefaultStyle },
};
const participants: Partial<ExcelJS.Column> = {
  // header: "담당자명",
  key: "participants",
  width: 20,
  style: { ...headerDefaultStyle },
};
const submitStatus: Partial<ExcelJS.Column> = {
  // header: "투자일",
  key: "submitStatus",
  width: 15,
  style: { ...headerDefaultStyle },
};
const riskStatus: Partial<ExcelJS.Column> = {
  // header: "지분가치원금",
  key: "riskStatus",
  width: 20,
  style: { ...headerDefaultStyle },
};
const riskIssue: Partial<ExcelJS.Column> = {
  // header: "회수원금",
  key: "riskIssue",
  width: 50,
  style: { ...headerDefaultStyle },
};

export const exportExcelByRiskmanagementSubmitStatus = async (
  data?: SubmitStatusProps[]
) => {
  if (!data || data.length === 0) return;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("제출현황"); // sheet 이름이 My Sheet
  // sheet 데이터 설정

  const columnsArr = [
    portfolioId,
    portfolioName,
    participants,
    submitStatus,
    riskStatus,
    riskIssue,
  ];

  worksheet.columns = [...columnsArr];

  worksheet.getCell("A1").value = "기업번호";
  worksheet.getCell("B1").value = "기업명";
  worksheet.getCell("C1").value = "담당자,조력자";
  worksheet.getCell("D1").value = "제출상태";
  worksheet.getCell("E1").value = "평가상태";
  worksheet.getCell("F1").value = "리스크 이슈";

  data.forEach((item, index) => {
    worksheet.insertRow(index + 2, { ...item });
  });

  // 다운로드
  const mimeType = {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], mimeType);

  saveAs(blob, `리스크 평가 제출 현황_${getYMDHMS(getNewDate())}.xlsx`);
};
