import React from "react";

import { useMediaQuery } from "react-responsive";

import TaskLayout from "../pre-pitching/mobile/organism/TaskLayout";
import MobileInnerContractTask from "./mobile/MobileInnerContractTask";
import DeskInnerContractTask from "./desktop/DeskInnerContractTask";

const InnerContractTask = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;

  return (
    <TaskLayout title="후속 투자계약">
      {isMobile ? <MobileInnerContractTask /> : <DeskInnerContractTask />}
    </TaskLayout>
  );
};

export default InnerContractTask;
