import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Del } from "../../../../../assets/image/chip_delete.svg";
import { DefaultButton } from "lattice_core";

interface Props {
  terms?: string;
  onChangeTerms: (newTerms: string) => void;
}

export const FundTerms: React.FC<Props> = (props) => {
  const { terms = "", onChangeTerms } = props;
  const [termsList, setTermsList] = useState<string[]>([]);
  const [value, updateValue] = useState<string>("");

  const addItem = () => {
    if (value.length === 0) return;

    const newTermsList = [...termsList, value];
    setTermsList(newTermsList);
    updateValue("");
    onChangeTerms(newTermsList.join("||"));
  };
  const deleteItem = (index: number) => {
    termsList.splice(index, 1);
    setTermsList([...termsList]);
    onChangeTerms(termsList.join("||"));
  };

  useEffect(() => {
    console.log(terms);
    if (terms && terms.length > 0) {
      setTermsList(terms.split("||"));
    }
  }, []);
  return (
    <Container>
      <InputContainer>
        <InputComponent
          className={`body-14-md`}
          type="text"
          placeholder={"조건을 입력해주세요."}
          onKeyUpCapture={(e) => {
            if (e.key === "Enter") {
              addItem();
            }
          }}
          onChange={(e) => {
            const data = e.target.value;
            updateValue(data);
          }}
          value={value}
        />
        <DefaultButton
          sizeType="small"
          buttonColor="var(--primary-purple)"
          hoverColor="var(--purple-700)"
          disabled={value.length === 0}
          onClick={addItem}
          text="추가"
        />
      </InputContainer>
      <SelectUserList>
        {termsList.length === 0 && (
          <EmptyContainer className="body-14-md">
            펀드 조건을 추가 해주세요.
            <br />
            예) 본계정공동 (가능), 구주인수 (가능), 본계정후행 클럽딜 (총회결의
            필요), <br />
            본계정후행 단독 (불가능), 해외기업 (가능)
          </EmptyContainer>
        )}
        {termsList.map((item, index) => (
          <FundTag key={index} className="body-14-md">
            {item}
            <Del
              className="icon"
              onClick={() => {
                deleteItem(index);
              }}
            />
          </FundTag>
        ))}
      </SelectUserList>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  background: var(--background-contents01);
  padding-bottom: 24px;
`;

const InputContainer = styled.div`
  position: relative;
  width: 332px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;
const InputComponent = styled.input`
  flex-grow: 1;
  height: 100%;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  transition: 0.2s;
  padding: 6px 12px;
  background: var(--background-contents01);

  ::placeholder {
    color: var(--text-inactive);
  }

  :read-only {
    padding: 6px 0px;
    border: 1px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  :disabled {
    border: 1px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &.show__msg {
    border: 1px solid var(--red-500);
    animation: shake 200ms;

    :focus {
      border: 1px solid var(--red-500);
    }

    :hover {
      border-color: var(--red-500);
    }
  }

  :focus {
    outline: none;
    border-color: var(--focus-field-blue);

    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    border-color: var(--focus-field-blue);
  }

  @keyframes shake {
    25% {
      transform: translateX(2px);
    }
    50% {
      transform: translateX(-2px);
    }
    75% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(-2px);
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const SelectUserList = styled.div`
  width: 100%;
  height: auto;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 12px;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-04);
`;

const FundTag = styled.div`
  width: fit-content;
  height: max-content;
  padding: 6px 14px;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  color: var(--text-04);

  .icon {
    cursor: pointer;
  }
`;
