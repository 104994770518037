import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../assets/image/modal_close.svg";
import { DefaultButton } from "lattice_core";
import { Channel, IndustryTech, UserDetailInfo } from "../../../../type/data";
import {
  ExcavationFilterData,
  ExcavationFilterType,
} from "../../interface/Excavation.interface";
import CheckedText from "../../../../components-2.0v/check-box/CheckedText";

interface Props {
  allIndustyTech: IndustryTech[];
  allChannel: Channel[];
  filterData: ExcavationFilterData;
  onChangeFilterData: (type: ExcavationFilterType, data: any) => void;
  myIndustrialTech: number[];
  myHeadQuarterIndustrialTech: number[];
  close: () => void;
  myInfo?: UserDetailInfo;
}

export const FilterModal: React.FC<Props> = (props) => {
  const {
    allIndustyTech,
    allChannel,
    filterData,
    myHeadQuarterIndustrialTech,
    myIndustrialTech,
    onChangeFilterData,
    close,
  } = props;

  const [mFilterData, updateMFilterData] = useState<
    ExcavationFilterData | undefined
  >(undefined);

  useEffect(() => {
    updateMFilterData({ ...filterData });
  }, []);

  if (!mFilterData) return null;

  const isActiveMyIndustry = () => {
    const activeIndustry = mFilterData.industry;
    if (activeIndustry.length !== myIndustrialTech.length) return false;
    if (
      activeIndustry.filter((item) => !myIndustrialTech.includes(item)).length >
      0
    )
      return false;

    return true;
  };

  const isActiveMyHeadquarterIndustry = () => {
    const activeIndustry = mFilterData.industry;
    if (activeIndustry.length !== myHeadQuarterIndustrialTech.length)
      return false;
    if (
      activeIndustry.filter(
        (item) => !myHeadQuarterIndustrialTech.includes(item)
      ).length > 0
    )
      return false;

    return true;
  };

  const onClickIndustry = (item: number) => {
    const selectedIndustry = [...mFilterData.industry];
    const targetIndex = selectedIndustry.findIndex((data) => data === item);
    if (targetIndex === -1) {
      updateMFilterData({
        ...mFilterData,
        industry: [...selectedIndustry, item],
      });
    } else {
      selectedIndustry.splice(targetIndex, 1);
      updateMFilterData({ ...mFilterData, industry: [...selectedIndustry] });
    }
  };

  const onClickChannel = (item: number) => {
    const selectedChannel = [...mFilterData.channel];
    const targetIndex = selectedChannel.findIndex((data) => data === item);
    if (targetIndex === -1) {
      updateMFilterData({
        ...mFilterData,
        channel: [...selectedChannel, item],
      });
    } else {
      selectedChannel.splice(targetIndex, 1);
      updateMFilterData({
        ...mFilterData,
        channel: [...selectedChannel],
      });
    }
  };

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          필터
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        <FlexColumn>
          <div className="heading-14-md">산업기술</div>
          <FlexColumn style={{ gap: 12, marginTop: 24 }}>
            <FlexRow style={{ gap: 20 }}>
              <CheckedText
                isChecked={isActiveMyIndustry()}
                onClick={() => {
                  updateMFilterData({
                    ...mFilterData,
                    industry: myIndustrialTech,
                  });
                }}
              >
                마이 산업기술
              </CheckedText>
              <CheckedText
                isChecked={isActiveMyHeadquarterIndustry()}
                onClick={() => {
                  updateMFilterData({
                    ...mFilterData,
                    industry: myHeadQuarterIndustrialTech,
                  });
                }}
              >
                본부 산업기술
              </CheckedText>
            </FlexRow>
            <FilterWrapper>
              {allIndustyTech.map((item, key) => {
                return (
                  <CheckedText
                    key={key}
                    isChecked={mFilterData.industry.includes(
                      parseInt(`${item.acIndustrialTechId}`)
                    )}
                    onClick={() => {
                      onClickIndustry(parseInt(`${item.acIndustrialTechId}`));
                    }}
                  >
                    {item.name}
                  </CheckedText>
                );
              })}
            </FilterWrapper>
          </FlexColumn>
        </FlexColumn>

        <FlexColumn>
          <div className="heading-14-md">채널</div>
          <FlexColumn style={{ gap: 12, marginTop: 24 }}>
            <FilterWrapper>
              {allChannel.map((item, key) => {
                return (
                  <CheckedText
                    key={key}
                    isChecked={mFilterData.channel.includes(
                      parseInt(`${item.acChannelId}`)
                    )}
                    onClick={() => {
                      onClickChannel(parseInt(`${item.acChannelId}`));
                    }}
                  >
                    {item.name}
                  </CheckedText>
                );
              })}
            </FilterWrapper>
          </FlexColumn>
        </FlexColumn>
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={() => {
              close();
            }}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            disabled={false}
            text={"확인"}
            onClick={() => {
              onChangeFilterData("all", mFilterData);
              close();
            }}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 80vw;
  max-width: 428px;

  max-height: 95vh;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  padding: 20px 36px;
  gap: 32px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
