import React from "react";
import styled from "styled-components";

import DefaultSearch from "../../../../components-2.0v/search/DefaultSearch";
import { AccordionTitle } from "./component/AccordionTitle";
import { ReportingWaitCard } from "./component/ReportingWaitCard";
import { EvaluationWaitCard } from "./component/EvaluationWaitCard";
import { EvaluationCompletedCard } from "./component/EvaluationCompletedCard";
import { useNavigate } from "react-router-dom";

import useRiskManagementEvaluation from "../hook/useRiskManagementEvaluation";
import ModalLayout from "../../../../components/modal/ModalLayout";
import ConfirmModal from "../../../../2.0lattice/components/modal/ConfirmModal";
import { ReactComponent as Lock } from "../../../../2.0lattice/assets/image/new_icon_lock.svg";
import { NavigationLayout } from "../../../../components/navigation/NavigationLayout";

const DeskRiskManagementEvaluation = () => {
  const navigate = useNavigate();
  const [
    year,
    quarterNum,
    riskManagements,
    keyword,
    noDataEvaluation,
    updateKeyword,
    getFilteredRiskManagement,
    goNextDetail,
    goNextDetailNodata,
    closeNoDataModal,
  ] = useRiskManagementEvaluation();

  if (riskManagements === undefined) return null;
  return (
    <Container>
      <EvaluationContainer>
        <ContentsHeader>
          <span className="heading-20-sb" style={{ color: "var(--text-01)" }}>
            {year && quarterNum ? `${year}년 ${quarterNum}분기` : ""}
          </span>
          <DefaultSearch
            value={keyword}
            placeholder="기업명, 대표명, 키워드로 검색해 주세요."
            transfromPixel={(n) => n}
            onChangeValue={(value) => {
              updateKeyword(value);
            }}
            onPressEnter={(value) => {
              updateKeyword(value);
            }}
          />
        </ContentsHeader>
        {riskManagements.length > 0 ? (
          <ContentsBody>
            <AccordionTitle
              title="평가하기"
              count={getFilteredRiskManagement([2, 3]).length}
              initOpen={true}
            >
              <ListContainer>
                {getFilteredRiskManagement([2, 3]).map((item, index) => {
                  return (
                    <EvaluationWaitCard
                      key={`evaluation_${index}`}
                      data={item}
                      onClick={() => {
                        goNextDetail(item);
                      }}
                    />
                  );
                })}
              </ListContainer>
            </AccordionTitle>

            <AccordionTitle
              title="평가완료"
              count={getFilteredRiskManagement([4]).length}
              initOpen={true}
            >
              <ListContainer>
                {getFilteredRiskManagement([4]).map((item, index) => {
                  return (
                    <EvaluationCompletedCard
                      key={`evaluation_completed_${index}`}
                      data={item}
                      onClick={() => {
                        goNextDetail(item);
                      }}
                    />
                  );
                })}
              </ListContainer>
            </AccordionTitle>

            <AccordionTitle
              title="보고대기"
              count={getFilteredRiskManagement([1]).length}
              initOpen={true}
            >
              <ListContainer>
                {getFilteredRiskManagement([1]).map((item, index) => {
                  return (
                    <ReportingWaitCard
                      key={`waiting_${index}`}
                      data={item}
                      onClick={() => {
                        goNextDetail(item);
                      }}
                    />
                  );
                })}
              </ListContainer>
            </AccordionTitle>
          </ContentsBody>
        ) : (
          <ContentsBody
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Lock width={60} height={60} />
            <div
              className="heading-18-md"
              style={{
                color: "#7E8086",
              }}
            >
              리스크 평가 대상 기업이 없습니다.
            </div>
          </ContentsBody>
        )}
      </EvaluationContainer>
      {noDataEvaluation && (
        <ModalLayout
          isOpen={noDataEvaluation}
          isFullScreen
          toggle={closeNoDataModal}
        >
          <ConfirmModal
            type="confirm"
            title="확인"
            contents={[
              "필요한 분기보고 데이터가 확정 되지 않았습니다.",
              "이대로 시작하면 모든 항목의 리스크를 평가해야 합니다.",
              "진행하시겠습니까 ?",
            ]}
            positiveTitle="진행"
            negativeTitle="취소"
            onClickPositiveBtn={goNextDetailNodata}
            onClickNegativeBtn={closeNoDataModal}
            onCloseModal={closeNoDataModal}
          />
        </ModalLayout>
      )}
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const EvaluationContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 1320px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  width: 100%;
  height: 62px;
  min-height: 62px;
  max-height: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  z-index: 10;

  border-bottom: 1px solid var(--divider-200);
  background: var(--background-header);
`;

const ContentsBody = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  margin: 0 auto;
  gap: 40px;
  overflow: scroll;
`;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 24px;
`;
export default DeskRiskManagementEvaluation;
