import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartOptions,
  ChartData,
  ChartDataset,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { PerformanceValueProps } from "../../interface/PerformanceMetrics.interface";
import {
  AddComma,
  RemoveComma,
  getNewDate,
} from "../../../../../common/commonUtil";
import { Gradation } from "../component/Gradation";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  unit?: "Y" | "N";
  isTotal?: boolean;
  valueData?: PerformanceValueProps[];
  dateStart?: string;
  dateEnd?: string;
}
export const CapitalGain: React.FC<Props> = (props) => {
  const {
    unit = "N",
    isTotal = false,
    valueData = [],
    dateStart,
    dateEnd,
  } = props;
  const X_WIDTH = 50;
  const DIVISION_VALUE = 100000000;

  const TotalColor = "rgba(97, 223, 174, 1)";
  const DefaultColor = [
    "rgba(240, 247, 255, 1)",
    "rgba(232, 240, 255, 1)",
    "rgba(209, 227, 255, 1)",
    "rgba(186, 213, 255, 1)",
    "rgba(156, 195, 255, 1)",
    "rgba(156, 195, 255, 1)",
    "rgba(87, 144, 255, 1)",
    "rgba(29, 106, 255, 1)",
    "rgba(0, 86, 252, 1)",
    "rgba(0, 73, 214, 1)",
    "rgba(247, 245, 255, 1)",
    "rgba(239, 237, 255, 1)",
    "rgba(222, 217, 255, 1)",
    "rgba(201, 193, 255, 1)",
    "rgba(174, 161, 255, 1)",
    "rgba(142, 125, 250, 1)",
    "rgba(126, 106, 254, 1)",
    "rgba(106, 85, 244, 1)",
    "rgba(82, 63, 207, 1)",
    "rgba(64, 50, 158, 1)",
    "rgba(239, 249, 245, 1)",
    "rgba(216, 248, 235, 1)",
    "rgba(189, 242, 221, 1)",
    "rgba(122, 224, 184, 1)",
    "rgba(59, 214, 153, 1)",
    "rgba(40, 190, 131, 1)",
    "rgba(24, 158, 105, 1)",
    "rgba(18, 129, 86, 1)",
    "rgba(15, 103, 68, 1)",
    "rgba(15, 103, 68, 1)",
  ];

  const arr = useRef<
    {
      year: number;
      quarter?: number;
      totalValue: number;
      totalCount: number;
    }[]
  >([]);

  const [labelRange, setLabelRange] = useState<
    {
      year: number;
      quarter?: number;
    }[]
  >([]);
  const [totalIncreaseValue, setTotalIncreaseValue] = useState<number>(0);
  const [barData, setBarData] = useState<ChartDataset<"bar">[]>([]);

  const setQuarterUnit = () => {
    arr.current = [];

    const sDate = getNewDate(dateStart);
    const eDate = getNewDate(dateEnd);

    let startY = sDate.getFullYear();
    let quarter = Math.floor(sDate.getMonth() / 3) + 1;
    let increaseValue = 0;

    const endY = eDate.getFullYear();
    const endQuarter = Math.floor(eDate.getMonth() / 3) + 1;

    const dataArr: ChartDataset<"bar">[] = [];

    for (; startY <= endY; startY++) {
      while (quarter <= 4) {
        arr.current.push({
          year: startY,
          quarter: quarter,
          totalValue: 0,
          totalCount: 0,
        });
        if (startY === endY && quarter === endQuarter) {
          break;
        }
        quarter++;
      }
      quarter = 1;
    }

    valueData.forEach((item) => {
      const companyName = item.companyName;
      item.pfValueHistory.forEach((history) => {
        const preHistory = history.preValueHistory;
        if (history.totalStock && preHistory) {
          const date = getNewDate(history.date);
          const y = date.getFullYear();
          const q = Math.floor(date.getMonth() / 3) + 1;
          const cStock = history.pfValueAccount
            .map((item) => item.stock)
            .reduce((p, c) => p + c, 0);

          const pStock = preHistory.pfValueAccount
            .map((item) => item.stock)
            .reduce((p, c) => p + c, 0);

          const totalCstock = history.totalStock || 0;
          const totalPstock = preHistory.totalStock || 0;

          const curValue = history.value * (cStock / totalCstock);
          const preValue = preHistory.value * (pStock / totalPstock);

          if (curValue - preValue !== 0) {
            increaseValue += curValue - preValue;
            const range = arr.current.map((item, idx) => {
              if (item.year === y && item.quarter === q) {
                arr.current[idx].totalValue += curValue - preValue;
                arr.current[idx].totalCount++;
                return curValue - preValue;
              }
              return 0;
            });

            if (!isTotal) {
              dataArr.push({
                label: companyName,
                data: range,
              });
            }
          }
        }
      });
    });
    if (isTotal) {
      dataArr.push({
        label: "",
        data: arr.current.map((item) => item.totalValue),
      });
    }
    setTotalIncreaseValue(increaseValue);
    setLabelRange(arr.current);
    setBarData(
      dataArr
        .sort((a, b) => {
          const aValue = a.data.findIndex((num: any) => num > 0);
          const bValue = b.data.findIndex((num: any) => num > 0);

          return aValue - bValue;
        })
        .map((item, index) => ({
          ...item,
          backgroundColor: isTotal
            ? TotalColor
            : DefaultColor[index % DefaultColor.length],
        }))
    );
    console.log(dataArr);
    console.log(arr.current);
  };

  const setYearUnit = () => {
    arr.current = [];

    const sDate = getNewDate(dateStart);
    const eDate = getNewDate(dateEnd);

    let startY = sDate.getFullYear();
    let increaseValue = 0;

    const endY = eDate.getFullYear();
    const endQuarter = Math.floor(eDate.getMonth() / 3) + 1;

    const dataArr: ChartDataset<"bar">[] = [];

    for (; startY <= endY; startY++) {
      arr.current.push({
        year: startY,
        totalValue: 0,
        totalCount: 0,
      });
    }

    valueData.forEach((item) => {
      const companyName = item.companyName;
      item.pfValueHistory.forEach((history) => {
        const preHistory = history.preValueHistory;
        if (history.totalStock && preHistory) {
          const date = getNewDate(history.date);
          const y = date.getFullYear();
          const cStock = history.pfValueAccount
            .map((item) => item.stock)
            .reduce((p, c) => p + c, 0);

          const pStock = preHistory.pfValueAccount
            .map((item) => item.stock)
            .reduce((p, c) => p + c, 0);

          const totalCstock = history.totalStock || 0;
          const totalPstock = preHistory.totalStock || 0;

          const curValue = history.value * (cStock / totalCstock);
          const preValue = preHistory.value * (pStock / totalPstock);

          if (curValue - preValue !== 0) {
            increaseValue += curValue - preValue;
            const range = arr.current.map((item, idx) => {
              if (item.year === y) {
                arr.current[idx].totalValue += curValue - preValue;
                arr.current[idx].totalCount++;
                return curValue - preValue;
              }
              return 0;
            });

            if (!isTotal) {
              dataArr.push({
                label: companyName,
                data: range,
              });
            }
          }
        }
      });
    });
    if (isTotal) {
      dataArr.push({
        label: "",
        data: arr.current.map((item) => item.totalValue),
      });
    }
    setTotalIncreaseValue(increaseValue);
    setLabelRange(arr.current);
    setBarData(
      dataArr
        .sort((a, b) => {
          const aValue = a.data.findIndex((num: any) => num > 0);
          const bValue = b.data.findIndex((num: any) => num > 0);

          return aValue - bValue;
        })
        .map((item, index) => ({
          ...item,
          backgroundColor: isTotal
            ? TotalColor
            : DefaultColor[index % DefaultColor.length],
        }))
    );
    console.log(dataArr);
    console.log(arr.current);
  };

  useEffect(() => {
    if (!dateStart || !dateEnd) return;

    if (unit === "N") {
      setQuarterUnit();
    } else {
      setYearUnit();
    }
  }, [unit, dateStart, dateEnd, valueData]);

  return (
    <Container>
      <TitleContainer className="heading-18-sb">지분가치 증분</TitleContainer>
      <BodyContainer>
        <FlexColumn style={{ width: "100%", gap: 8 }}>
          <FlexRow
            className="body__left"
            style={{
              width: "100%",
              gap: 36,
            }}
          >
            <TextItem textColor="var(--blue-500)">
              <div className="heading-14-sb sub__title">합계 </div>
              <div className="heading-20-b">
                {AddComma((totalIncreaseValue / DIVISION_VALUE).toFixed(0))}억
              </div>
            </TextItem>
          </FlexRow>
          <div className="body__right" style={{ height: "100%" }}>
            <ChartContainer>
              <div
                style={{
                  width:
                    labelRange.length > 12
                      ? 895 + (labelRange.length - 7) * X_WIDTH
                      : undefined,
                  height: 129,
                }}
              >
                <Bar
                  style={{ display: "inline-block" }}
                  options={{
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: props.isTotal
                        ? {
                            callbacks: {
                              label: (tooltipItem) => {
                                console.log(tooltipItem);
                                const tooltip = `지분가치증분 : ${(
                                  parseInt(
                                    RemoveComma(tooltipItem.formattedValue)
                                  ) / 100000000
                                ).toFixed(2)}억 (${
                                  arr.current[tooltipItem.dataIndex].totalCount
                                }건) `;
                                return tooltip;
                              },
                            },
                          }
                        : undefined,
                    },
                    datasets: {
                      bar: {
                        barPercentage: 0.6,
                        categoryPercentage: 0.5,
                      },
                    },
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                        beginAtZero: true,
                        ticks: {
                          callback: (value, index, ticks) => {
                            if (barData.length === 0) return undefined;
                            const dataValue = parseInt(`${value}`);
                            if (index === 0) {
                              return `${dataValue / DIVISION_VALUE} (억)`;
                            }
                            return AddComma(dataValue / DIVISION_VALUE);
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    labels: labelRange.map((item) =>
                      unit === "N"
                        ? `${item.year % 2000}.${item.quarter}Q`
                        : `${item.year}년`
                    ),
                    datasets: barData,
                  }}
                  plugins={[
                    {
                      id: "columnTotal",
                      afterDatasetsDraw: (chart) => {
                        if (chart.data.datasets.length === 0) return;
                        const ctx = chart.ctx;
                        chart.data.labels?.forEach((label, i) => {
                          let total = 0;
                          chart.data.datasets.forEach((dataset) => {
                            const data = dataset.data[i];
                            total += typeof data === "number" ? data : 0;
                          });
                          const meta = chart.getDatasetMeta(0);
                          const bar = meta.data[i];
                          if (total !== 0) {
                            ctx.fillText(
                              `${Math.floor(total / DIVISION_VALUE)}`,
                              bar.x - 10,
                              meta.yScale?.top || 10
                            );
                          }
                        });
                      },
                    },
                  ]}
                />
              </div>
            </ChartContainer>
            {labelRange.length > 12 && <Gradation />}
          </div>
        </FlexColumn>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .body__left {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }

  .body__right {
    position: relative;
    width: 100%;
    overflow: scroll;
  }
`;

const ChartContainer = styled.div`
  position: relative;
  width: 895px;
  max-width: 895px;
  overflow-x: scroll;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--gray-300);
`;

const TextItem = styled.div<{ textColor: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.textColor};

  .sub__title {
    color: var(--text-04);
  }
`;
