import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  IAReportAccount,
  InvestmentAssessment,
} from "../../../../../../type/data";
import { CommonInvestInfo } from "../../components/CommonInvestInfo";
import { InvestFundAccount } from "../../components/InvestFundAccount";
import { ReactComponent as Plus } from "../../../../../../assets/image/plus.svg";
import ModalLayout from "../../../../../../components/modal/ModalLayout";
import EvaluationInvestFundAccountModal from "../modal/EvaluationInvestFundAccountModal";
import {
  showToast,
  transformObjectToBase64,
} from "../../../../../../common/commonUtil";
import { Bounce } from "react-toastify";

interface Props {
  portfolioId: number;
  ceoEmail?: string;
  document: InvestmentAssessment;
  isUpdatedAuth: boolean;
  isVoteFinished: boolean;
  onChangeEvaluationDocument: (
    document: InvestmentAssessment,
    callback?: () => void
  ) => void;
  downloadEvaluationDocument: (reportAccount: IAReportAccount) => void;
  transfromPixel: (value: number) => number;
  isSpecialUser: boolean;
  sendExportLinkEmail?: () => void;
  sendExpertEmail?: () => void;
}
const EvaluationAssessment: React.FC<Props> = (props) => {
  const {
    portfolioId,
    document,
    isUpdatedAuth,
    isVoteFinished,
    onChangeEvaluationDocument,
    downloadEvaluationDocument,
    transfromPixel,
    isSpecialUser,
    sendExportLinkEmail,
    sendExpertEmail,
  } = props;

  const [addAccountModal, updateAddAccountModal] = useState<boolean>(false);

  const checkDuplicateReportAccount = (reportAccount: IAReportAccount) => {
    const target = document.investmentAssessmentReportAccount.find(
      (item) =>
        item.investmentAssessmentReportAccountId !==
          reportAccount.investmentAssessmentReportAccountId &&
        item.investmentAssessmentAccount.acFundAccount?.acFundAccountId ===
          reportAccount.investmentAssessmentAccount.acFundAccount
            ?.acFundAccountId &&
        item.investmentAssessmentAccount.newOldStock?.newOldStockId ===
          reportAccount.investmentAssessmentAccount.newOldStock
            ?.newOldStockId &&
        item.investmentAssessmentAccount.stockType?.stockTypeId ===
          reportAccount.investmentAssessmentAccount.stockType?.stockTypeId
    );

    return target === undefined;
  };

  useEffect(() => {
    console.log(document);

    const isExistValueIsFundManager =
      document.investmentAssessmentReportAccount.filter(
        (item) =>
          item.investmentAssessmentInvite.filter(
            (invite) => invite.isFundManager !== undefined
          ).length > 0 &&
          item.investmentAssessmentInvite.filter(
            (invite) => invite.priorityInfo === undefined
          ).length > 0
      ).length > 0;

    console.log(isExistValueIsFundManager);

    if (isUpdatedAuth && !document.exportLink) {
      const _code = transformObjectToBase64({
        portfolioId: portfolioId,
        investmentAssessmentId: document.investmentAssessmentId,
      });
      onChangeEvaluationDocument({ ...document, exportLink: _code });
    }

    if (isUpdatedAuth && isExistValueIsFundManager) {
      const newData = document.investmentAssessmentReportAccount.map((item) => {
        const newInvite = item.investmentAssessmentInvite.map((invite) => {
          if (invite.isFundManager) {
            return {
              ...invite,
              isFundManager: undefined,
              priorityInfo: { priorityType: 1, priorityCount: 2 },
            };
          } else {
            if (
              invite.priorityInfo === undefined ||
              invite.priorityInfo === null
            )
              return {
                ...invite,
                isFundManager: undefined,
                priorityInfo: { priorityType: 3, priorityCount: 1 },
              };
          }
          return invite;
        });
        return { ...item, investmentAssessmentInvite: newInvite };
      });
      onChangeEvaluationDocument({
        ...document,
        investmentAssessmentReportAccount: newData,
      });
    }
  }, []);

  return (
    <Container style={{ maxWidth: transfromPixel(1120) }}>
      <CommonInvestInfo
        portfolioId={portfolioId}
        document={document}
        isUpdateAuth={isUpdatedAuth}
        transfromPixel={transfromPixel}
        onChangeDocument={(data) => {
          onChangeEvaluationDocument(data);
        }}
        sendExportLinkEmail={sendExportLinkEmail}
        sendExpertEmail={sendExpertEmail}
      />
      {document.investmentAssessmentReportAccount.map((item, index) => {
        return (
          <InvestFundAccount
            portfolioId={portfolioId}
            key={index}
            document={document}
            isDeletable={document.investmentAssessmentReportAccount.length > 1}
            reportAccount={item}
            reportAccountIdx={index}
            isUpdateAuth={isUpdatedAuth}
            isVoteFinished={isVoteFinished}
            isSpecialUser={isSpecialUser}
            transfromPixel={transfromPixel}
            downloadEvaluationDocument={downloadEvaluationDocument}
            onChangeReportAccount={(reportAccount, index, callback) => {
              if (checkDuplicateReportAccount(reportAccount)) {
                const newDocument: InvestmentAssessment = {
                  ...structuredClone(document),
                };
                newDocument.investmentAssessmentReportAccount[index] =
                  reportAccount;

                console.log(newDocument);
                onChangeEvaluationDocument(newDocument, callback);
              } else {
                showToast({
                  customElement: (
                    <div
                      className="heading-16-sb"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>
                        동일한 조건의 투자 재원이 <br /> 이미 존재합니다.
                      </span>
                    </div>
                  ),
                  backgroundColor: "var(--red-500)",
                  color: "var(--text-white)",
                  width: transfromPixel(274),
                  milliSec: 1500,
                  transition: Bounce,
                  position: "bottom-center",
                  toastId: new Date().toString(),
                });
              }
            }}
            onDeleteReportAccount={(reportAccount, index) => {
              const newDocument: InvestmentAssessment = {
                ...structuredClone(document),
              };
              newDocument.investmentAssessmentReportAccount.splice(index, 1);
              onChangeEvaluationDocument(newDocument);
            }}
          />
        );
      })}
      {isUpdatedAuth && (
        <AddAccount
          onClick={() => {
            updateAddAccountModal(true);
          }}
        >
          <Plus />
          <span
            className="caption-12-rg"
            style={{ lineHeight: 1, paddingTop: 2 }}
          >
            재원 추가
          </span>
        </AddAccount>
      )}

      {addAccountModal && (
        <ModalLayout
          isFullScreen
          isOpen={addAccountModal}
          toggle={() => {
            updateAddAccountModal(false);
          }}
        >
          <EvaluationInvestFundAccountModal
            isUpdateAuth={isUpdatedAuth}
            document={document}
            transfromPixel={transfromPixel}
            close={() => {
              updateAddAccountModal(false);
            }}
            onChangeReportAccount={(data, idx) => {
              console.log(data);
            }}
            onCreateReportAccount={(data) => {
              if (checkDuplicateReportAccount(data)) {
                const firstReportAccount =
                  document.investmentAssessmentReportAccount[0];
                let maxId = -1; // Report Account 아이디 구하기 위함
                document.investmentAssessmentReportAccount.forEach((item) => {
                  if (
                    typeof item.investmentAssessmentReportAccountId === "string"
                  ) {
                    const id = parseInt(
                      item.investmentAssessmentReportAccountId.slice(
                        item.investmentAssessmentReportAccountId.length - 1
                      )
                    );
                    if (maxId < id) {
                      maxId = id;
                    }
                  }
                });
                data.investmentAssessmentReportAccountId = `newReportAccount-${
                  maxId + 1
                }`;
                data.investmentAssessmentAccount.previousValue =
                  firstReportAccount.investmentAssessmentAccount.previousValue;
                data.investmentAssessmentAccount.previousTotalStockNumber =
                  firstReportAccount.investmentAssessmentAccount.previousTotalStockNumber;
                data.investmentAssessmentAccount.postCompanyValue =
                  firstReportAccount.investmentAssessmentAccount.postCompanyValue;
                data.investmentAssessmentAccount.totalInvestmentAmount =
                  firstReportAccount.investmentAssessmentAccount.totalInvestmentAmount;
                data.investmentAssessmentAccount.postTotalStockNumber =
                  firstReportAccount.investmentAssessmentAccount.postTotalStockNumber;
                data.investmentAssessmentAccount.faceValue =
                  firstReportAccount.investmentAssessmentAccount.faceValue;

                const newDocument: InvestmentAssessment = {
                  ...structuredClone(document),
                };
                newDocument.investmentAssessmentReportAccount.push(data);
                console.log(newDocument);
                onChangeEvaluationDocument(newDocument);
                updateAddAccountModal(false);
              } else {
                showToast({
                  customElement: (
                    <div
                      className="heading-16-sb"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>
                        동일한 조건의 투자 재원이 <br /> 이미 존재합니다.
                      </span>
                    </div>
                  ),
                  backgroundColor: "var(--red-500)",
                  color: "var(--text-white)",
                  width: transfromPixel(274),
                  milliSec: 1500,
                  transition: Bounce,
                  position: "bottom-center",
                  toastId: new Date().toString(),
                });
              }
            }}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  margin: 32px auto;

  gap: 24px;

  overflow: scroll;
`;

const AddAccount = styled.div`
  width: 100%;
  height: 34px;
  min-height: 34px;
  max-height: 34px;

  margin-bottom: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-400);
  border-radius: 4px;
  color: var(--text-01);

  transition: all 0.2s ease;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
  path {
    fill: #1f2022;
  }
`;

export const Label = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: 2px;
  color: var(--text-white);
  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 1;
  padding: 4px 6px;
`;
export default EvaluationAssessment;
