import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import axios from "axios";
import * as api from "../../../../../api/api";
import { showErrorToast, getYMDHM } from "../../../../../common/commonUtil";

import { toast } from "react-toastify";

import { BlueCardInfo, PortfolioInfoType } from "../../../../../type/data";
import ModalLayout from "../../../../../components/modal/ModalLayout";

import { ReactComponent as DocumentDownload } from "../../../../assets/image/ic_download.svg";
import SpinnerModal from "../../../../../components/modal/SpinnerModal";
import BluecardPrint from "../../../../database/desktop/component/BluecardPrint";
import Tooltip from "../../../../../components/tooltip/Tooltip";

import { BluecardFilterProps } from "../../../../database/desktop/organisms/PreviewBlueCard";
import BlueCardPDFViewNew from "./BlueCardPDFViewNew";
import { DefaultButton } from "lattice_core";
import { useForkRef } from "@mui/material";

export interface BluecardObjectProps {
  itemSurmmary: string;
  companyDescription: string;
  investmentHighlight: string;
  problem: string;
  solutionBusinessModel: string;
  technology: string;
  productStatus: string;
}

interface Props {
  isEditable: boolean;
  isUpdateAuth: boolean;
  portfolioBluecard: BlueCardInfo;
  bluecardFilter?: BluecardFilterProps;
  originBluecard?: BluecardObjectProps;
  suggestBluecard?: BluecardObjectProps;
  isSuggested: boolean;
  saveBluecard: (data: BlueCardInfo) => void;
}
const BlueCardNew: React.FC<Props> = ({
  // TODO header를 분리하는 것이 좋을듯하다 => 포트폴리오 정보를 상단에서 호출하도록
  isEditable = true,
  isUpdateAuth,
  portfolioBluecard,
  originBluecard,
  suggestBluecard,
  isSuggested,
  saveBluecard,
  bluecardFilter = {
    companyValue: true,
    investmentAmount: true,

    userName: true,
    userEmail: true,
    userPhone: true,

    investmentHighlight: true,
    problem: true,
    solutionBusinessModel: true,
    technology: true,
    productStatus: true,
  },
}) => {
  const MAX_LINE = 18;
  const portfolioInfo = useRef<PortfolioInfoType>({});
  const [bluecard, setBluecard] = useState<BlueCardInfo>(portfolioBluecard);
  const [preBluecard, setPreBluecard] =
    useState<BlueCardInfo>(portfolioBluecard);

  const textareaRefs = useRef<any[]>([]);

  const [textareaFontSize, setTextareaFontSize] = useState<number>(
    (10 * 780) / 550
  );

  const [toggle, updateToggle] = useState<boolean>(false);
  const [openBackgroundModal, setOpenBackgroundModal] =
    useState<boolean>(false);

  const [contentsLines, setContentsLines] = useState<number>(0);
  const isChangeBluecard = useRef<boolean>(false);
  const [loadingModal, updateLoadingModal] = useState<boolean>(false);
  const previewRef = useRef<HTMLDivElement>(null);

  const [rootWidth, updateRootWidth] = useState<number>(334);

  const resizeHandle = (e?: any) => {
    if (previewRef.current) {
      console.log(previewRef.current.clientWidth);
      updateRootWidth(previewRef.current.clientWidth);
    }
  };

  const printPDF = () => {
    console.log("open transparent");
    setOpenBackgroundModal(true);
  };

  const onChangeBluecardSummary = (value: string) => {
    isChangeBluecard.current = true;
    setBluecard((pre) => {
      return {
        ...pre,
        itemSurmmary: value,
      };
    });
  };
  const onChangeBluecard = (value: string, key: string) => {
    /* 현재 블루카드 백업 */
    /* 분량 초과시 pre 값으로 다시 돌아오기 위함 */
    if (bluecard) {
      setPreBluecard(bluecard);
    }

    /* 새로 들어온 value를 setBluecard */
    isChangeBluecard.current = true;

    switch (key) {
      case "itemSurmmary": {
        if (toggle) {
          if (suggestBluecard) {
            suggestBluecard.itemSurmmary = value;
          }
        } else {
          if (originBluecard) {
            originBluecard.itemSurmmary = value;
          }
        }
        break;
      }
      case "investmentHighlight": {
        if (toggle) {
          if (suggestBluecard) {
            suggestBluecard.investmentHighlight = value;
          }
        } else {
          if (originBluecard) {
            originBluecard.investmentHighlight = value;
          }
        }
        break;
      }
      case "problem": {
        if (toggle) {
          if (suggestBluecard) {
            suggestBluecard.problem = value;
          }
        } else {
          if (originBluecard) {
            originBluecard.problem = value;
          }
        }
        break;
      }
      case "solutionBusinessModel": {
        if (toggle) {
          if (suggestBluecard) {
            suggestBluecard.solutionBusinessModel = value;
          }
        } else {
          if (originBluecard) {
            originBluecard.solutionBusinessModel = value;
          }
        }
        break;
      }
      case "technology": {
        if (toggle) {
          if (suggestBluecard) {
            suggestBluecard.technology = value;
          }
        } else {
          if (originBluecard) {
            originBluecard.technology = value;
          }
        }
        break;
      }
      case "productStatus": {
        if (toggle) {
          if (suggestBluecard) {
            suggestBluecard.productStatus = value;
          }
        } else {
          if (originBluecard) {
            originBluecard.productStatus = value;
          }
        }
        break;
      }
    }

    setBluecard((pre) => {
      return {
        ...pre,
        [key]: value,
      };
    });
  };

  const onBlurBluecard = () => {
    if (!isChangeBluecard.current) return;
    isChangeBluecard.current = false;
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandle);
    return () => {
      window.removeEventListener("resize", resizeHandle);
    };
  }, []);

  useEffect(() => {
    console.log(bluecard);
    bluecard && saveBluecard(bluecard);
  }, [bluecard]);

  useEffect(() => {
    if (!toggle && originBluecard) {
      setBluecard((prev) => {
        return {
          ...prev,
          ...originBluecard,
        };
      });
    }

    if (toggle && suggestBluecard) {
      setBluecard((prev) => {
        return {
          ...prev,
          ...suggestBluecard,
        };
      });
    }
  }, [toggle]);

  return (
    <Container>
      <BlueCardContainer>
        <BluecardTitle>
          <div className="label">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span className="heading-18-md" style={{ marginRight: 4 }}>
                블루카드
              </span>
              <Tooltip>
                <div
                  className="font-regular-12"
                  style={{
                    width: 340,
                    padding: "8px 10px",
                    color: "var(--white)",
                    backgroundColor: "var(--grey_60)",
                    borderRadius: 4,
                    whiteSpace: "break-spaces",
                  }}
                >
                  블루카드의 1장 요약본을 위하여 Business Summary를 제외한
                  항목의 글자/라인수가 제한됩니다.
                </div>
              </Tooltip>
              <span style={{ color: "grey", fontSize: "12px", marginLeft: 12 }}>
                {bluecard.updatedAt &&
                  `마지막 업데이트 : ${getYMDHM(portfolioBluecard.updatedAt)}`}
              </span>
            </div>

            {isSuggested && !isEditable && (
              <PortfolioSuggestLabel>포트폴리오사 요청</PortfolioSuggestLabel>
            )}

            {isSuggested && isEditable && (
              <SuggestToggleContainer>
                <span>포트폴리오사 요청보기</span>
                <Toggle
                  className={toggle ? "toggle__on" : ""}
                  onClick={() => {
                    updateToggle((prev) => !prev);
                  }}
                >
                  <div
                    className={`toggle__ball ${
                      toggle ? "toggle__ball__on" : ""
                    }`}
                  />
                </Toggle>
              </SuggestToggleContainer>
            )}
          </div>
        </BluecardTitle>

        <BluecardBody editable={isEditable}>
          {[
            {
              label: "Business Summary",
              key: "itemSurmmary",
              placeholder: `사업 요약을 50자 이내로 입력해주세요. 사업 요약 내용은 라인수에 포함되지 않습니다.`,
              defaultValue:
                bluecard && bluecard.itemSurmmary ? bluecard.itemSurmmary : "",
            },
            {
              label: "Investment Highlight",
              key: "investmentHighlight",
              placeholder:
                `- 시장 현황(규모, 금액, 전년 대비 성장 비율 등 구체적인 숫자 언급)\n` +
                `- 시장 전망(트렌드 관점 혹은 이벤트, 특별한 성장세 등)\n` +
                `- 투자한 이유(이 팀의 차별화 포인트)\n`,
              defaultValue:
                bluecard && bluecard.investmentHighlight
                  ? bluecard.investmentHighlight
                  : "",
            },
            {
              label: "Problem",
              key: "problem",
              placeholder:
                `- 공급자 관점에서 기존 시장의 문제점\n` +
                `- 소비자 관점에서 기존 시장의 문제점`,
              defaultValue:
                bluecard && bluecard.problem ? bluecard.problem : "",
            },
            {
              label: "Solution & Business Model",
              key: "solutionBusinessModel",
              placeholder:
                `- 위 문제를 해결하기 위한 팀의 솔루션 및 접근 방식\n` +
                `- 해당 솔루션의 비즈니스 모델`,
              defaultValue:
                bluecard && bluecard.solutionBusinessModel
                  ? bluecard.solutionBusinessModel
                  : "",
            },
            {
              label: "Technology",
              key: "technology",
              placeholder:
                `- 위 솔루션을 구현하기 위한 팀의 기술\n` +
                `- 위 기술의 장점과 이 팀이 해당 기술을 구현하기 위한 적합도가 높은 팀이라는 근거(차별화 포인트)`,
              defaultValue:
                bluecard && bluecard.technology ? bluecard.technology : "",
            },
            {
              label: "Product Status",
              key: "productStatus",
              placeholder:
                `- 디벨롭 단계 \n` + `- 향후(1년 내) 계획 및 펀딩 계획 등`,
              defaultValue:
                bluecard && bluecard.productStatus
                  ? bluecard.productStatus
                  : "",
            },
          ].map((section, index) => {
            return (
              <div className="section" key={`bluecard-section-${index}`}>
                <div className="label">{section.label}</div>
                <div
                  className="border"
                  style={{
                    backgroundColor: isEditable ? "transparent" : "#DCDCDC",
                  }}
                />
                <BluecardTextareaContainer
                  fontSize={textareaFontSize}
                  isEditable={isEditable}
                  className="scroll__invisible"
                  onClick={() => {
                    if (!isEditable && isUpdateAuth) {
                      showErrorToast("수정하기 버튼을 눌러주세요");
                    }
                  }}
                >
                  {isEditable ? (
                    <textarea
                      className="scroll__invisible"
                      spellCheck={false}
                      disabled={!isEditable}
                      defaultValue={section.defaultValue}
                      value={section.defaultValue}
                      placeholder={section.placeholder}
                      maxLength={index === 0 ? 50 : undefined}
                      onChange={(event) =>
                        onChangeBluecard(event.target.value, section.key)
                      }
                      onBlur={onBlurBluecard}
                      ref={(el) => (textareaRefs.current[index] = el)}
                      onInput={(e) => {
                        if (!textareaRefs || !textareaRefs.current[index])
                          return;
                        textareaRefs.current[index].style.height = "auto";
                        textareaRefs.current[index].style.height =
                          textareaRefs.current[index]?.scrollHeight + "px";
                      }}
                    />
                  ) : (
                    <TextBox
                      className={`body-14-rg ${
                        section.defaultValue && section.defaultValue.length > 0
                          ? ""
                          : "is__empty"
                      }`}
                    >
                      {section.defaultValue || "작성한 내용이 없어요."}
                    </TextBox>
                  )}
                </BluecardTextareaContainer>
              </div>
            );
          })}
        </BluecardBody>
      </BlueCardContainer>
      <BlueCardPreviewContainer ref={previewRef}>
        <BluecardTitle>
          <div className="label">
            <span className="heading-18-md">미리보기</span>
          </div>
        </BluecardTitle>
        <Wrapper width={rootWidth}>
          <BlueCardPreview width={rootWidth}>
            <BluecardPrint
              portfolioBluecard={portfolioBluecard}
              onChangeLink={(line) => {
                resizeHandle();
                setContentsLines(line);
                if (line > MAX_LINE) {
                  toast.error("최대 라인 수를 초과하였습니다.", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "out-of-range",
                    autoClose: 1500,
                  });
                  setBluecard(preBluecard);
                }
              }}
              bluecardFilter={bluecardFilter}
            />
          </BlueCardPreview>
        </Wrapper>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 24,
          }}
        >
          <div className="font-regula-14">
            <span style={{ color: "#828282" }}>라인 수 </span>
            <span style={{ color: "#000000" }}>({contentsLines} / 18)</span>
          </div>
          <DefaultButton
            className="font-medium-12"
            buttonType="colored-outline"
            sizeType="xxsmall"
            borderColor="#24364A"
            buttonColor="#24364A"
            text="다운로드"
            onClick={(e) => {
              printPDF();
            }}
          />
        </div>
      </BlueCardPreviewContainer>

      <ModalLayout
        isOpen={openBackgroundModal}
        toggle={() => {
          setOpenBackgroundModal(false);
        }}
        borderRadius={0}
        isTransparent={true}
        zIndex={-1000}
      >
        <BlueCardPDFViewNew
          portfolioBluecard={portfolioBluecard}
          bluecardFilter={bluecardFilter}
          closeBlueCardPDFView={() => {
            setOpenBackgroundModal(false);
          }}
          onStartDownload={() => {
            updateLoadingModal(true);
          }}
          onFinishDownload={() => {
            updateLoadingModal(false);
          }}
        />
      </ModalLayout>

      {loadingModal && (
        <SpinnerModal open={loadingModal} message={"블루카드 다운로드 중..."} />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const BlueCardContainer = styled.div`
  width: 848px;
  min-width: 848px;
  max-width: 848px;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const BlueCardPreviewContainer = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  min-width: 334px;
  max-width: 574px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Wrapper = styled.div(
  ({ width }: { width: number }) => css`
    width: 100%;
    height: calc(${width}px * 297 / 210);
    overflow: hidden;

    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--gray-300);
  `
);
const BlueCardPreview = styled.div(
  ({ width }: { width: number }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    transform: scale(calc(${width} / 630));
    transform-origin: top left;
  `
);

const BluecardTitle = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .label {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 11px;

      .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3px;
        cursor: pointer;

        span {
          font-size: 12px;
          padding-top: 2px;
        }

        :hover {
          background-color: var(--grey_10);
          border-radius: 4px;
          cursor: pointer;
        }
      }

      .download-bluecard {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: var(--primary);
      }
      .preview {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #3d3d3d;
      }
    }
  `
);

const BluecardBody = styled.div(
  ({ editable }: { editable: boolean }) => css`
    --bbox-blue: #2196f3;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 24px;
    gap: 20px;

    border-radius: 4px;
    border: 1px solid var(--gray-300);

    .section {
      background-color: var(--white);
      display: flex;
      flex-direction: column;
    }

    .label {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    .border {
      height: 1px;
      margin: 4px 0 6px 0;
    }

    .input {
      background-color: #fbfbfb;
      border-radius: 4px;
      height: 160px;
      padding: 10px 10px 10px 12px;
      border: transparent;
      font-size: 14px;
      line-height: 24px;
      resize: none;
      min-height: 160px;

      :disabled {
        background-color: transparent;
      }
      :hover {
        ${editable && "background-color: var(--grey_10)"};
      }
      :focus {
        outline: none;
        border: 1px solid var(--grey_20);
        :hover {
          ${editable && "background-color: var(--white)"};
        }
      }
      ::placeholder {
        color: var(--grey_20);
      }
    }
  `
);

const BluecardTextareaContainer = styled.div(
  ({ fontSize, isEditable }: { fontSize: number; isEditable: boolean }) => css`
    input,
    textarea {
      width: 100%;
      min-width: 800px;
      max-width: 800px;

      border: transparent;
      background: transparent;

      padding: 10px;
      background-color: "#ffffff";
      font-family: "Bpp Regular";
      font-style: normal;
      font-size: ${fontSize}px;
      line-height: 25px;
      letter-spacing: calc((-0.1px) * (${fontSize} / 10));
      border: 1px solid ${isEditable ? "#DCDCDC" : "transparent"};

      resize: none;
      min-height: 160px;

      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;

      transition: all 0.2s ease;
      border-radius: 10px;

      color: #000000;

      :focus {
        outline: none;
        border: 1px solid #344e6d;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
      }

      ::placeholder {
        color: var(--grey_20);
      }
    }
  `
);

const TextBox = styled.pre`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  word-break: break-all;
  white-space: break-spaces;
  padding: 10px;

  color: var(--text-01, #1f2022);

  &.is__empty {
    color: var(--text-05);
  }
`;

const PortfolioSuggestLabel = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 4px 12px;
  border-radius: 30px;
  background: #ffffff;
  border: 1px solid #24364a;
  color: #24364a;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

const SuggestToggleContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: right;
  letter-spacing: 0.1px;

  /* black */

  color: #000000;
`;

const Toggle = styled.div`
  position: relative;
  width: 32px;
  height: 18px;
  background: #c4c4c4;
  border-radius: 9px;

  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s ease;

  cursor: pointer;

  .toggle__ball {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ffffff;
    left: 3px;
    transition: all 0.2s ease;
  }

  &.toggle__on {
    background-color: #24364a;
  }

  .toggle__ball__on {
    left: 17px;
  }
`;

export default BlueCardNew;
