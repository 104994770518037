import React from "react";
import styled from "styled-components";

interface Props {
  date: string;
  transfromPixel: (value: number) => number;
}
const ChatDivider: React.FC<Props> = (props) => {
  const containerStyle = {
    paddingLeft: props.transfromPixel(24),
    paddingRight: props.transfromPixel(8),
    gap: props.transfromPixel(4),
    paddingBottom: props.transfromPixel(16),
  };
  const lineStyle = {
    flexGrow: 1,
    height: props.transfromPixel(1),
    backgroundColor: `#E3E4E5`,
  };
  const textStyle = {
    color: "#5B5D61",
    paddingLeft: props.transfromPixel(7),
    paddingRight: props.transfromPixel(7),
  };
  return (
    <Container style={containerStyle}>
      <div style={lineStyle} />
      <span className="caption-12-rg" style={textStyle}>
        {props.date}
      </span>
      <div style={lineStyle} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default ChatDivider;
