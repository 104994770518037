import { PortfolioPhaseProps, SelectorProps } from "../../../type/data";
import { GridDataProps } from "../desktop/components/grid/LatticeGrid";

export interface ReportingUIData {
  type:
    | "MAIN_TAB"
    | "SUB_TAB"
    | "TABLE_UP_AREA"
    | "TABLE_HEADER"
    | "TABLE_DATA";
  data?: GridDataProps;
}

export interface ReportSearchParamProps {
  year: number;
  quarterNum: number;
  search: string;
  sortItem?: number;
  sortType?: number;
}

export interface AcceleratorReportRangeProps {
  current: { year: number; quarter: number };
  acceleratorRange: {
    start: {
      year: number;
      quarter: number;
    };
    end: {
      year: number;
      quarter: number;
    };
  };
  portfolioRange?: {
    start: {
      year: number;
      quarter: number;
    };
    end: {
      year: number;
      quarter: number;
    };
  };
}

export interface PortfolioReportDocumentListProps {
  acPfDocumentId: number;
  name: string;
  extension: "excel" | "pdf" | "file";
  required: boolean;
}
export interface PortfolioQuarterReportProps {
  quarterId: number;
  isOpen: boolean;
  portfolio: PortfolioInfo;
  pfReportDocument: PortfolioReportDocumentProps[];
}
export interface PortfolioInfo {
  portfolioId: number;
  companyName: string;
  pfParticipant: {
    pfParticipantId: number;
    acUser: {
      acUserId: number;
      name: string;
    };
    screenRole: {
      screenRoleId: number;
    };
  }[];
  pfPhase: PortfolioPhaseProps;
}
export interface PortfolioReportDocumentProps {
  pfReportDocumentId: number;
  acPfDocument: {
    acPfDocumentId: number;
  };
  uploadStatus: {
    uploadStatusId: number;
    name: string;
  };
  pfReportDocumentData?: {
    id: number;
  };
  documentFile?: {
    fileName: string;
    fileType: string;
    key: string;
  };
}

export interface PortfolioReportProps {
  portfolioId: number;
  portfolioName: string;
  participantMain: string;
  reports: ReportProps[];
}
export interface ReportProps {
  acPfDocumentId: number;
  name: string;
  required: boolean;
  extension: "excel" | "pdf" | "file";
  tag?: string;
  acPfDocumentPopup?: DocumentPopup[];
  file?: ReportFileProps;
}

export interface DocumentPopup {
  type: "LIST" | "TEXT";
  text: string;
}

export interface ReportFileProps {
  pfReportDocumentId: number;
  documentFile: {
    key: string;
    fileName: string;
    fileType: string;
  };
  isValidation: boolean;
  comment?: string;
  acPfDocument: AcPfDocumentProps;
  uploadStatus: UploadStatusProps;
}
export interface AcPfDocumentProps {
  acPfDocumentId: number;
}
/**
 * uploadStatusId
 *  1 : 제출
 *  2 : 재제출
 *  3 : 확인중
 *  4 : 반려
 *  5 : 확정
 */
export interface UploadStatusProps {
  uploadStatusId: 1 | 2 | 3 | 4 | 5;
  name: string;
}

export const ReportingPrtfolioStatus = {
  ALL: 1,
  MANAGE: 2,
  DAMAGE: 3,
};

export const reportingPortfolioFilterList: SelectorProps[] = [
  { id: ReportingPrtfolioStatus.ALL, text: "전체", data: "전체" },
  { id: ReportingPrtfolioStatus.MANAGE, text: "관리", data: "관리" },
  { id: ReportingPrtfolioStatus.DAMAGE, text: "손상", data: "손상" },
];
