import React, { useEffect, useState } from "react";

const MOBILE = 765;
const useDevice = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const resizeHandler = () => {
    setIsMobile(window.innerWidth <= MOBILE);
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return [isMobile] as const;
};

export default useDevice;
