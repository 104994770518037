import React from "react";
import { useMediaQuery } from "react-responsive";

import MobileRiskManagementEvaluationDetailViewer from "./mobile/MobileRiskManagementEvaluationDetailViewer";
import DeskRiskManagementEvaluationDetailViewer from "./desktop/DeskRiskManagementEvaluationDetailViewer";

const RiskManagementEvaluationDetailViewer = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobileRiskManagementEvaluationDetailViewer />
  ) : (
    <DeskRiskManagementEvaluationDetailViewer />
  );
};
export default RiskManagementEvaluationDetailViewer;
