import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bounce } from "react-toastify";

import { rootStyle } from "../../../../style/ThemeStyles";
import {
  onDownload,
  showToast,
  trnasfromBase64ToObject,
} from "../../../../common/commonUtil";
import {
  ParticipantInfoForExpert,
  SectionTabProps,
} from "../interface/RequestExpert.interface";
import { FileType, InvestmentAssessment } from "../../../../type/data";
import axios from "axios";
import * as api from "../../../../api/api";
import {
  deleteFile,
  uploadFile,
} from "../../../../api/repository/common/CommonRepository";
import { saveAs } from "file-saver";
import {
  getInvestmentAssessmentDataForExpert,
  submitExpertOpinion,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import { PitchingResultType } from "../../pre-pitching-detail/interface/PrePitchingDetail.interface";
import { resolve } from "dns";

const getIds = (location: any, type: "portfolio" | "investmentAssessment") => {
  const code = location.pathname.split("/")[3];
  const { portfolioId, investmentAssessmentId } = trnasfromBase64ToObject(code);

  if (type === "portfolio") {
    return portfolioId;
  } else {
    return investmentAssessmentId;
  }
};

const useExpertOpinion = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isViewer = useRef<boolean>(false);
  const isLoaded = useRef<boolean>(false);
  const initSBP = useRef<boolean>(false);

  const [tabList, updateTabList] = useState<SectionTabProps[]>([]);
  const validationCheckBox = useRef<HTMLDivElement>(null);
  const [isFolded, updateFolded] = useState<boolean>(false);
  const [selectedTabId, updateSelectedTabId] = useState<number>(1);

  const portfolioId = useRef<number>(getIds(location, "portfolio"));
  const investmentAssessmentId = useRef<number>(
    getIds(location, "investmentAssessment")
  );

  const businessPlanFile = useRef<FileType | undefined>(undefined);
  const pitchingResult = useRef<PitchingResultType[] | undefined>(undefined);
  const mainParticipant = useRef<ParticipantInfoForExpert | undefined>(
    undefined
  );
  const document = useRef<InvestmentAssessment | undefined>(undefined);
  const originDocument = useRef<InvestmentAssessment | undefined>(undefined);

  const [tempSaving, updateTempSaving] = useState<boolean>(false);

  const [render, updateRender] = useState<boolean>(false);

  const [isPublished, updatePublished] = useState<boolean | undefined>(
    undefined
  );
  const [isSubmitable, updateSubmitable] = useState<boolean>(false);
  const [submitComplete, updateSubmitComplete] = useState<boolean>(false);

  const reRender = () => updateRender((prev) => !prev);

  const handleResize = () => {
    if (validationCheckBox.current) {
      validationCheckBox.current.style.display = "flex";
      if (window.innerWidth > 1200 + 232 + 232 + 24) {
        const marginleft = (window.innerWidth - 1200) / 2;

        validationCheckBox.current.style.left = `${1200 + marginleft + 24}px`;
      } else if (window.innerWidth < 1200) {
        validationCheckBox.current.style.display = "none";
      } else {
        validationCheckBox.current.style.left = "";
        validationCheckBox.current.style.right = "40px";
      }
    }
  };

  const goToTop = () => {
    const root = window.document.querySelector("#root__component");

    if (root) {
      root.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const onTargetIntoView = (id: string) => {
    const root = window.document.querySelector("#root__component");
    const target = window.document.querySelector(`#${id}`);

    if (root && target) {
      const targetRect = target.getBoundingClientRect();
      const headerOffset = 250;
      const elementPosition = targetRect.top;
      const offsetPosition = elementPosition + root.scrollTop - headerOffset;

      root.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const validationSection5_all = (data: InvestmentAssessment) => {
    return validationSection5_1(data);
  };

  const validationSection5_1 = (data: InvestmentAssessment) => {
    if (!data.expertInfo) return false;
    if (!data.expertInfo.expertOpinion) return false;
    if (!data.expertInfo.expertOpinion.opinion) return false;
    return data.expertInfo.expertOpinion.opinion.length > 0;
  };

  const checkTabValidation = (data: InvestmentAssessment) => {
    const section1: SectionTabProps = {
      id: 1,
      name: "기본 정보",
      isComplete: true,
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "기업 개요",
          isComplete: true,
          selectId: "Section1_1",
        },
        {
          index: 2,
          sectionTitle: "주요 연혁",
          isComplete: true,
          selectId: "Section1_2",
        },
      ],
    };

    const section2: SectionTabProps = {
      id: 2,
      name: "인력",
      isComplete: true,
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "경영진 현황",
          isComplete: true,
          selectId: "Section2_1",
        },
        {
          index: 2,
          sectionTitle: "임직원 현황",
          isComplete: true,
          selectId: "Section2_2",
        },
      ],
    };

    const section3: SectionTabProps = {
      id: 3,
      name: "사업 진행",
      isComplete: true,
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "계약 현황",
          isComplete: true,
          selectId: "Section5_1",
        },
        {
          index: 2,
          sectionTitle: "출원 및 등록 현황",
          isComplete: true,
          selectId: "Section5_2",
        },
        {
          index: 3,
          sectionTitle: "국내외 관련기업 현황",
          isComplete: true,
          selectId: "Section5_3",
        },
      ],
    };
    const section4: SectionTabProps = {
      id: 4,
      name: "피칭 & IR",
      isComplete: true,
      tabSectionList: [],
    };
    const section5: SectionTabProps = {
      id: 5,
      name: "전문가 의견",
      isComplete: validationSection5_all(data),
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "의견",
          isComplete: validationSection5_1(data),
          selectId: "Section5_1",
        },
      ],
    };
    updateTabList([section1, section2, section3, section4, section5]);
  };

  const initEvaluationInfo = async () => {
    try {
      const result = await getInvestmentAssessmentDataForExpert(
        portfolioId.current,
        investmentAssessmentId.current
      );
      if (result) {
        //이 투자 심사 보고서가 배포가 되었는가?
        businessPlanFile.current = result.businessPlanFile;
        pitchingResult.current = result.pitchingResult;
        mainParticipant.current = result.participant.find(
          (item) => item.screenRoleId === 1
        );
        document.current = { ...result.investmentAssessment };
        originDocument.current = structuredClone(document.current);
        if (
          document.current &&
          document.current.investmentAssessmentReportAccount &&
          document.current.investmentAssessmentReportAccount.length > 0
        ) {
          updatePublished(
            document.current.investmentAssessmentReportAccount[0].isPublished
          );
        }
        if (document.current) {
          if (document.current.expertInfo?.expertSubmitComplete !== undefined) {
            updateSubmitComplete(
              document.current.expertInfo?.expertSubmitComplete
            );
          }

          checkTabValidation(document.current);
        }
      }
    } catch (error) {}
  };

  const init = async () => {
    if (initSBP.current) return;

    initSBP.current = true;
    isLoaded.current = false;

    await initEvaluationInfo();
  };

  const areJSONsEqual = (json1: any, json2: any): boolean => {
    const stringifiedJSON1 = JSON.stringify(json1);
    const stringifiedJSON2 = JSON.stringify(json2);

    return stringifiedJSON1 === stringifiedJSON2;
  };

  //
  const onChangeData = async (data: InvestmentAssessment) => {
    if (areJSONsEqual(originDocument.current, data)) return;

    console.log(originDocument);
    console.log(document);

    checkTabValidation(data);
    document.current = data;
    originDocument.current = structuredClone(document.current);
    updateTempSaving(true);
    reRender();
  };

  const fileUpload = async () => {
    if (!document.current) return;
    if (!document.current.expertInfo) return;
    if (!document.current.expertInfo.expertOpinion) return;
    if (!document.current.expertInfo.expertOpinion.reportFile) return;

    const localFiles =
      document.current.expertInfo.expertOpinion.reportFile.filter(
        (item) => item.key === "localFile"
      );
    if (localFiles.length === 0) return;

    const fileUploadPromise =
      document.current.expertInfo.expertOpinion.reportFile.map(
        (item): Promise<{ sucess: boolean; file?: FileType }> => {
          return new Promise(async (resolve, reject) => {
            if (item.key === "localFile") {
              const result = await uploadFile(
                item.localFile,
                "expertReport",
                portfolioId.current
              );
              console.log(result);
              if (result) {
                return resolve({ sucess: true, file: result });
              } else {
                return resolve({ sucess: false });
              }
            }
            return resolve({ sucess: false });
          });
        }
      );

    const result = await Promise.all(fileUploadPromise);
    document.current.expertInfo.expertOpinion.reportFile.forEach(
      (item, index) => {
        if (item.key === "localFile") {
          item.key = "serverFile";
          item.serverFile = result[index].file;
          item.localFile = undefined;
        }
      }
    );
  };

  const tempSavingData = async () => {
    await fileUpload();
    console.log(document.current);
    const result = await axios.put(
      api.portfolioUpdateAssessmentExpert(),
      document.current
    );
    if (result.status === 200) {
      showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>임시저장을 완료하였습니다.</span>
          </div>
        ),
        backgroundColor: "var(--green-500)",
        color: "var(--text-white)",
        width: 274,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      updateTempSaving(false);
    }
  };

  const submitInvestmentAssessment = async () => {
    if (!document.current || !document.current.expertInfo) return;

    await fileUpload();
    document.current.expertInfo.expertSubmitComplete = true;
    const result = await submitExpertOpinion(
      portfolioId.current,
      investmentAssessmentId.current,
      document.current.expertInfo
    );
    if (result && result.success) {
      showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>제출하였습니다.</span>
          </div>
        ),
        backgroundColor: "var(--green-500)",
        color: "var(--text-white)",
        width: "100%",
        milliSec: 2000,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      updateSubmitComplete(true);
    }
  };

  const setModifiableState = () => {
    updateSubmitComplete(false);
  };

  useEffect(() => {
    if (!isPublished) handleResize();
    if (!submitComplete) handleResize();
  }, [isPublished, submitComplete]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [
    document.current,
    businessPlanFile.current,
    pitchingResult.current,
    mainParticipant.current,
    isPublished,
    submitComplete,
    tabList,
    validationCheckBox,
    selectedTabId,
    isFolded,
    tempSaving,
    goToTop,
    onTargetIntoView,
    updateFolded,
    updateSelectedTabId,
    onChangeData,
    tempSavingData,
    submitInvestmentAssessment,
    setModifiableState,
  ] as const;
};

export default useExpertOpinion;
