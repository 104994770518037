import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartOptions,
  ChartData,
  ChartDataset,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { PerformanceTrendProps } from "../../interface/PerformanceMetrics.interface";
import { AddComma, getNewDate } from "../../../../../common/commonUtil";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  performanceTrend?: PerformanceTrendProps;
  showExcavation?: boolean;
}
export const InvestmentProgressByDepartment: React.FC<Props> = (props) => {
  const { performanceTrend, showExcavation } = props;
  const [barData, setBarData] = useState<ChartDataset<"bar">[]>([]);
  const [lineData, setLineData] = useState<ChartDataset<"line">[]>([]);

  const quaterData = (data: { date: string }[]) => {
    const dataCountByQuarter = [0, 0, 0, 0];
    const newData = data.map((item) => getNewDate(item.date));
    newData.forEach(
      (item) => dataCountByQuarter[Math.floor(item.getMonth() / 3)]++
    );
    return dataCountByQuarter;
  };

  useEffect(() => {
    if (!performanceTrend) return;
    setBarData([
      {
        label: "미팅·검토",
        data: [performanceTrend ? performanceTrend.meeting.length : 0],
        backgroundColor: "rgba(254, 144, 65, 1)",
      },
      {
        label: "피칭",
        data: [performanceTrend ? performanceTrend.pitching.length : 0],
        backgroundColor: "rgba(255, 216, 79, 1)",
      },
      {
        label: "투심",
        data: [performanceTrend ? performanceTrend.assessment.length : 0],
        backgroundColor: "rgba(59, 214, 153, 1)",
      },
      {
        label: "투자",
        data: [performanceTrend ? performanceTrend.contract.length : 0],
        backgroundColor: "rgba(39, 151, 255, 1)",
      },
    ]);

    setLineData([
      {
        label: "미팅·검토",
        data: quaterData(performanceTrend.meeting),
        borderColor: "rgba(254, 144, 65, 1)",
        backgroundColor: "white",
      },
      {
        label: "피칭",
        data: quaterData(performanceTrend.pitching),
        borderColor: "rgba(255, 216, 79, 1)",
        backgroundColor: "white",
      },
      {
        label: "투심",
        data: quaterData(performanceTrend.assessment),
        borderColor: "rgba(59, 214, 153, 1)",
        backgroundColor: "white",
      },
      {
        label: "투자",
        data: quaterData(performanceTrend.contract),
        borderColor: "rgba(39, 151, 255, 1)",
        backgroundColor: "white",
      },
    ]);
  }, [performanceTrend]);

  return (
    <Container>
      <TitleContainer className="heading-18-sb">투자 집행 추이</TitleContainer>
      <DescriptionContainer className="body-14-md">
        1년에 해당하는 미팅·검토, 피칭, 투심, 투자건들의 추이를 담고 있습니다.
      </DescriptionContainer>
      <BodyContainer>
        <FlexColumn style={{ paddingLeft: 20 }}>
          <FlexRow style={{ flexWrap: "wrap", gap: 20 }}>
            {showExcavation && (
              <ProgressItem textColor="var(--red-500)">
                <div className="caption-12-sb sub__title">발굴</div>
                <div className="heading-14-b">
                  {performanceTrend ? AddComma(performanceTrend.portfolio) : 0}
                  건
                </div>
              </ProgressItem>
            )}

            <ProgressItem textColor="var(--orange-500)">
              <div className="caption-12-sb sub__title">미팅·검토</div>
              <div className="heading-14-b">
                {performanceTrend
                  ? AddComma(performanceTrend.meeting.length)
                  : 0}
                건
              </div>
            </ProgressItem>

            <ProgressItem textColor="var(--yellow-500)">
              <div className="caption-12-sb sub__title">피칭</div>
              <div className="heading-14-b">
                {performanceTrend
                  ? AddComma(performanceTrend.pitching.length)
                  : 0}
                건
              </div>
            </ProgressItem>

            <ProgressItem textColor="var(--green-700)">
              <div className="caption-12-sb sub__title">투심</div>
              <div className="heading-14-b">
                {performanceTrend
                  ? AddComma(performanceTrend.assessment.length)
                  : 0}
                건
              </div>
            </ProgressItem>

            <ProgressItem textColor="var(--skyblue-500)">
              <div className="caption-12-sb sub__title">투자</div>
              <div className="heading-14-b">
                {performanceTrend
                  ? AddComma(performanceTrend.contract.length)
                  : 0}
                건
              </div>
            </ProgressItem>
          </FlexRow>
          <div style={{ marginTop: 16 }}>
            <Bar
              style={{ display: "inline-block" }}
              options={{
                responsive: true,
                indexAxis: "y" as const,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                devicePixelRatio: 2,
                scales: {
                  x: {
                    display: false,
                    stacked: true,
                  },
                  y: {
                    display: false,
                    stacked: true,
                  },
                },
              }}
              data={{ labels: [""], datasets: barData }}
              height={40}
            />
          </div>

          <div style={{ marginTop: 40 }}>
            <Line
              style={{ display: "inline-block" }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        return `${tooltipItem.dataset.label} : ${tooltipItem.formattedValue}건`;
                      },
                    },
                  },
                },
                scales: {
                  y: {
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
              data={{
                labels: ["1분기", "2분기", "3분기", "4분기"],
                datasets: lineData,
              }}
            />
          </div>
        </FlexColumn>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--gray-300);
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text-05);
  margin-top: 2px;
`;

const ProgressItem = styled.div<{ textColor: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  color: ${(props) => props.textColor};

  .sub__title {
    color: var(--text-04);
  }

  ::before {
    position: absolute;
    content: " ";
    width: 4px;
    min-width: 4px;
    max-width: 4px;
    height: calc(100% - 10px);
    left: 0;
    top: 4px;
    background-color: ${(props) => props.textColor};
  }
`;
