import React, { useRef } from "react";
import Styled, { css } from "styled-components";

interface Props {
  className?: string;
  type?: "submit" | "danger" | "outline" | "text" | "excel";
  width?: number;
  height?: number;
  disabled?: boolean;
  onClick?: () => void;
  disabledOnClick?: () => void;
  id?: string;
}
const DefaultButton: React.FC<Props> = (props) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  return (
    <Container
      ref={btnRef}
      className={`${props.className ? props.className : "font-medium-14"} ${
        props.type || "submit"
      }`}
      isDisabled={props.disabled || false}
      onClick={() => {
        console.log("asdfdsf");
        if (props.disabled) {
          if (props.disabledOnClick) props.disabledOnClick();
          else return;
        } else {
          props.onClick && props.onClick();
        }
      }}
      style={{ height: props.height || 42, width: props.width || "auto" }}
      id={props.id || ""}
    >
      {props.children}
    </Container>
  );
};

const Container = Styled.button(
  ({ isDisabled }: { isDisabled: boolean }) => css`
    min-width: 80px;
    padding: 0 16px;
    box-sizing: border-box;
    border: 0;
    border-radius: 8px;
    line-height: 1;
    padding-top: 2px;
    cursor: pointer;

    &.submit {
      ${isDisabled
        ? `
      background-color: var(--grey_20);
      color: var(--white);
    `
        : `
      background-color: var(--primary-blue);
      color: var(--white);
    `}
    }

    &.danger {
      ${isDisabled
        ? `
      background-color: var(--white);
      color: var(--grey_20);
      border: 1px solid var(--grey_20);
    `
        : `
      background-color: var(--white);
      color: var(--red);
      border: 1px solid var(--red);
    `}
      :hover {
        ${!isDisabled && `background-color: var(--hover-100)`};
      }
    }

    &.outline {
      ${isDisabled
        ? `
      background-color: var(--white);
      color: var(--grey_20);
      border: 1px solid var(--grey_20);
    `
        : `
      background-color: var(--white);
      color: var(--primary);
      border: 1px solid var(--primary);
     
    `}

      :hover {
        ${!isDisabled && `background-color: var(--hover-100)`};
      }
    }

    &.text {
      ${isDisabled
        ? `
      background-color: transparent;
      color: var(--grey_20);
    `
        : `
      background-color: transparent;
      color: var(--grey_50);
    `}
      :hover {
        ${!isDisabled && `background-color: var(--hover-100)`};
      }
    }

    &.excel {
      ${isDisabled
        ? `
      background-color: var(--white);
      color: var(--grey_20);
      border: 1px solid var(--grey_20);
    `
        : `
      background-color: var(--white);
      color: var(--black);
      border: 1px solid #107c41;
      
    `}

      :hover {
        ${!isDisabled && `background-color: var(--hover-100)`};
      }
    }
  `
);

export default DefaultButton;
