import React, {
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {
  FileType,
  IAReportAccount,
  IAReportUser,
} from "../../../../../../type/data";
import {
  AddComma,
  getKoreanMoneyUnit,
  getNewDate,
  getYMDHMKo,
} from "../../../../../../common/commonUtil";
import {
  getPortfolioInfo,
  registePortfolioDocument,
} from "../../../../../../api/repository/portfolio/PortfolioRepository";
import { uploadFile } from "../../../../../../api/repository/common/CommonRepository";
import { MinuteDocumentFundManagerPrint } from "../../components/MinuteDocumentFundManagerPrint";
import { MinuteDocumentPrint } from "../../components/MinuteDocumentPrint";
import { isSuccessVote } from "../../../service/InvestmentAssessmentService";

import { ReactComponent as FileUpload } from "../../../../../../assets/image/icon_file_upload.svg";

import { ReactComponent as Fail } from "../../../../../../assets/image/icon_check_red.svg";

import ModalLayout from "../../../../../../components/modal/ModalLayout";
import ProgressModalStatic from "../../../../../../components-2.0v/progress/ProgressModalStatic";

import {
  LOGO_SMALL,
  getLogo,
} from "../../../../../../api/repository/accelerator/AcceleratorRepository";
import { MinutesProps } from "../../../../../task/evaluation/desktop/DeskEvaluationTask";

interface Props {
  portfolioId: number;
  reportAccount: IAReportAccount[];
  investmentAssessmentId?: number | string;
  callback: () => void;
  onFaileCallback: () => void;
}

interface MinuteDocumentProps {
  id: string | number;
  reportId?: string;
  acFundAccountId?: number;
  fundAccountName?: string;
  investmentAssessment?: number;
  file?: FileType;

  data: MinutesProps[];
  refArray: React.RefObject<HTMLDivElement>[];
}

export const makeDocumentData = (
  isFundManager: boolean,
  companyName: string,
  reportAccount: IAReportAccount,
  inviteItem: IAReportUser,
  inviteUser: number,
  totalVotingRight: number,
  agree: number,
  opposite: number,
  votingResult: string,
  totalTradeAmount?: number,
  totalTradeStockNumber?: Number
) => {
  const date = getNewDate(
    `${reportAccount.assessmentEndDate?.date} ${reportAccount.assessmentEndDate?.time}`
  );

  return {
    isFundManager: isFundManager,
    fundAccount: {
      acfundAccountId:
        reportAccount.investmentAssessmentAccount.acFundAccount
          ?.acFundAccountId || 0,
      accountName: reportAccount.investmentAssessmentAccount.accountName || "",
      newOldStock: reportAccount.investmentAssessmentAccount.newOldStock,
    },
    companyName: companyName,
    date: getYMDHMKo(date),
    stockType: reportAccount.investmentAssessmentAccount.stockType?.name,
    totalTradeAmount: totalTradeAmount
      ? `금 ${getKoreanMoneyUnit(totalTradeAmount).trimEnd()} 원 (${AddComma(
          totalTradeAmount
        )} 원)`
      : undefined,
    totalInvestmentAmount: reportAccount.investmentAssessmentAccount
      .totalInvestmentAmount
      ? `금 ${getKoreanMoneyUnit(
          reportAccount.investmentAssessmentAccount.totalInvestmentAmount
        ).trimEnd()} 원 (${AddComma(
          reportAccount.investmentAssessmentAccount.totalInvestmentAmount
        )} 원)`
      : `금 ${getKoreanMoneyUnit(
          reportAccount.investmentAssessmentAccount.investmentAmount || ""
        ).trimEnd()} 원 (${AddComma(
          reportAccount.investmentAssessmentAccount.investmentAmount
        )} 원)`,
    investmentAmount: reportAccount.investmentAssessmentAccount.investmentAmount
      ? `금 ${getKoreanMoneyUnit(
          reportAccount.investmentAssessmentAccount.investmentAmount || ""
        ).trimEnd()} 원 (${AddComma(
          reportAccount.investmentAssessmentAccount.investmentAmount
        )} 원)`
      : undefined,
    issuingPrice:
      reportAccount.investmentAssessmentAccount.issuingPrice &&
      reportAccount.investmentAssessmentAccount.faceValue
        ? `${AddComma(
            reportAccount.investmentAssessmentAccount.issuingPrice
          )} 원 ${
            reportAccount.investmentAssessmentAccount.newOldStock
              ?.newOldStockId === 1
              ? `(액면가 ${AddComma(
                  reportAccount.investmentAssessmentAccount.faceValue
                )} 원)`
              : ""
          } `
        : undefined,
    curNewIssuingPrice: reportAccount.investmentAssessmentAccount
      .curNewIssuingPrice
      ? `${AddComma(
          reportAccount.investmentAssessmentAccount.curNewIssuingPrice
        )} 원 (액면가 ${AddComma(
          reportAccount.investmentAssessmentAccount.faceValue
        )} 원)`
      : undefined,
    postTotalStockNumber: reportAccount.investmentAssessmentAccount
      .postTotalStockNumber
      ? `${AddComma(
          reportAccount.investmentAssessmentAccount.postTotalStockNumber
        )}주`
      : `${AddComma(reportAccount.investmentAssessmentAccount.stockNumber)}주`,
    stockNumber: reportAccount.investmentAssessmentAccount.stockNumber
      ? `${AddComma(reportAccount.investmentAssessmentAccount.stockNumber)}주`
      : undefined,
    shareholdingRatio:
      reportAccount.investmentAssessmentAccount.shareholdingRatio &&
      reportAccount.investmentAssessmentAccount.postCompanyValue
        ? `${reportAccount.investmentAssessmentAccount.shareholdingRatio}%${
            reportAccount.investmentAssessmentAccount.newOldStock
              ?.newOldStockId === 1
              ? `(투자 후 기업 가치 ${AddComma(
                  reportAccount.investmentAssessmentAccount.postCompanyValue
                )} 원)`
              : ""
          }`
        : undefined,
    acUserName: inviteItem.acUser.name,
    totalTradeStockNumber: totalTradeStockNumber,
    buyCompany: reportAccount.investmentAssessmentAccount.buyCompany,
    buyCompanyValue: reportAccount.investmentAssessmentAccount.buyCompanyValue
      ? `${AddComma(
          reportAccount.investmentAssessmentAccount.buyCompanyValue
        )}원`
      : undefined,
    discount: reportAccount.investmentAssessmentAccount.discount
      ? `${reportAccount.investmentAssessmentAccount.discount}%`
      : undefined,
    comment: inviteItem.comment,
    isAgreement: inviteItem.isAgreement,
    signature: inviteItem.signatureTmp,

    totalVotingRight: totalVotingRight,
    totalInviteUserNum: inviteUser,
    agreementNum: agree,
    oppositionNum: opposite,
    quorumType: reportAccount.investmentAssessmentAccount.quorumType,
    votingResult: votingResult,
  };
};

const MinuteDocumentModal: React.FC<Props> = (props) => {
  const [logoImage, setLogoImage] = useState<string | undefined>(undefined);
  const [arrayRef, updateArrayRef] = useState<MinuteDocumentProps[]>([]);
  const [loadedPages, updateLoadedPages] = useState<number[]>([]);
  const totalPage = useRef<number>(0);
  const [completePage, updateCompltePage] = useState<number[]>([]);

  const [progressIcon, updateProgressIcon] = useState<
    React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined
  >(undefined);
  const [progressMsg, updateProgressMsg] = useState<string>("");
  const [openProgress, updateOpenProgress] = useState<boolean>(false);
  const [progress, updateProgress] = useState<number>(0);
  const [progressFail, updateProgressFail] = useState<boolean>(false);

  const getArrayRange = (num: number) => {
    const arr: number[] = [];
    for (let i = 0; i < num; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const isExistFundManager = (reportAccount: IAReportAccount) => {
    return (
      reportAccount.investmentAssessmentInvite.find(
        (item) => item.isFundManager || item.priorityInfo?.priorityType === 1
      ) !== undefined
    );
  };

  const getMinuteData = (
    companyName: string,
    reportAccount: IAReportAccount,
    totalTradeAmount?: number,
    totalTradeStockNUmbeR?: Number
  ) => {
    const fundManager = reportAccount.investmentAssessmentInvite.find(
      (item) => item.isFundManager || item.priorityInfo?.priorityType === 1
    );

    const inveteUsers = reportAccount.investmentAssessmentInvite.length;
    const totalVotingRight = reportAccount.investmentAssessmentInvite
      .map((item) => item.priorityInfo?.priorityCount || 0)
      .reduce((prev, cur) => prev + cur, 0);

    const agree = reportAccount.investmentAssessmentInvite
      .filter((item) => item.isAgreement)
      .map((item) => {
        return item.priorityInfo?.priorityCount || 0;
      })
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);

    const opposite = reportAccount.investmentAssessmentInvite
      .filter((item) => item.isAgreement)
      .map((item) => {
        return item.priorityInfo?.priorityCount || 0;
      })
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);

    let fundManagerDocumentData: MinutesProps[] = [];

    if (fundManager) {
      fundManagerDocumentData = [
        makeDocumentData(
          true,
          companyName,
          reportAccount,
          fundManager,
          inveteUsers,
          totalVotingRight,
          agree,
          opposite,
          isSuccessVote(reportAccount) ? "가결" : "부결",
          totalTradeAmount,
          totalTradeStockNUmbeR
        ),
      ];
      totalPage.current += 1;
    }

    const documentDatas = reportAccount.investmentAssessmentInvite.map(
      (item) => {
        const documentData: MinutesProps = makeDocumentData(
          false,
          companyName,
          reportAccount,
          item,
          inveteUsers,
          totalVotingRight,
          agree,
          opposite,
          isSuccessVote(reportAccount) ? "가결" : "부결",
          totalTradeAmount,
          totalTradeStockNUmbeR
        );
        totalPage.current += 1;
        return documentData;
      }
    );

    return fundManager
      ? [...fundManagerDocumentData, ...documentDatas]
      : [...documentDatas];
  };

  const init = async () => {
    const logoResult = await getLogo(LOGO_SMALL);
    const companyInfo = await getPortfolioInfo(props.portfolioId);

    if (companyInfo) {
      const oldReportAccount = props.reportAccount.filter(
        (item) =>
          item.investmentAssessmentAccount.newOldStock?.newOldStockId === 2
      );
      const totalTradeAmount =
        oldReportAccount.length > 0
          ? oldReportAccount
              .map(
                (item) => item.investmentAssessmentAccount.investmentAmount || 0
              )
              .reduce((prev, cur) => prev + cur, 0)
          : undefined;
      const totalTradeStockNumber =
        oldReportAccount.length > 0
          ? oldReportAccount
              .map((item) => item.investmentAssessmentAccount.stockNumber || 0)
              .reduce((prev, cur) => prev + cur, 0)
          : undefined;

      setLogoImage(logoResult);
      updateArrayRef(
        props.reportAccount.map((i) => {
          const minuteData: MinuteDocumentProps = {
            id: i.investmentAssessmentReportAccountId,
            reportId: i.reportFileId,
            acFundAccountId:
              i.investmentAssessmentAccount.acFundAccount?.acFundAccountId,
            fundAccountName: i.investmentAssessmentAccount.accountName,
            file: i.reportFile,
            data: getMinuteData(
              companyInfo.companyName || "",
              i,
              totalTradeAmount,
              totalTradeStockNumber
            ),
            refArray: getArrayRange(
              i.investmentAssessmentInvite.length +
                (isExistFundManager(i) ? 1 : 0)
            ).map((item) => createRef<HTMLDivElement>()),
          };
          return minuteData;
        })
      );
    }
  };

  useLayoutEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (
      loadedPages.length !== 0 &&
      totalPage.current !== 0 &&
      loadedPages.length === totalPage.current
    ) {
      uploadPdfFile();
    }
  }, [loadedPages]);

  useEffect(() => {
    if (completePage.length > 0)
      updateProgressMsg(
        `투자 심사 의결서 ${completePage.length}/${totalPage.current} 완료`
      );
  }, [completePage]);

  const onSccess = () => {
    updateProgressMsg("완료");
    setTimeout(() => {
      updateProgress(100);
    }, 100);
    setTimeout(() => {
      updateProgress(0);
      updateOpenProgress(false);
      updateProgressFail(false);
    }, 1000);
  };

  const onFail = () => {
    updateProgressMsg("실패 ");
    updateProgressFail(true);

    setTimeout(() => {
      updateProgress(100);
      updateProgressIcon(Fail);
    }, 100);

    setTimeout(() => {
      updateProgress(0);
      updateOpenProgress(false);
      updateProgressFail(false);
    }, 1000);
  };

  const uploadPdfFile = async () => {
    try {
      updateOpenProgress(true);
      updateProgress(1);
      updateProgressMsg("투자 심사 의결서 생성중....");
      updateProgressIcon(FileUpload);

      const promiseArr = arrayRef.map(
        (item, index): Promise<{ isSucessed: boolean; data?: any }> => {
          return new Promise(async (resolve, reject) => {
            console.log("1");
            if (!item.refArray[0].current)
              return resolve({
                isSucessed: false,
              });
            console.log("2");
            if (item.acFundAccountId === undefined)
              return resolve({
                isSucessed: false,
              });
            console.log("3");
            if (item.file === undefined || item.file.id === undefined)
              return resolve({
                isSucessed: false,
              });
            console.log("4");
            if (props.investmentAssessmentId === undefined)
              return resolve({
                isSucessed: false,
              });
            console.log("5");
            console.log(item.refArray[0].current);
            const firstCanvas = await html2canvas(item.refArray[0].current, {
              scale: 2,
              allowTaint: true,
              useCORS: true,
              logging: true,
              imageTimeout: 15000,
              removeContainer: true,
            });
            console.log("6");
            const firstData = firstCanvas.toDataURL("image/png", 1.0);
            console.log("7");
            const coverPdf = new jsPDF("p", "mm", "a4");
            const originpdf = new jsPDF("p", "mm", "a4");
            console.log("8");
            coverPdf.addImage({
              imageData: firstData,
              format: "PNG",
              x: 0,
              y: 0,
              width: 210,
              height: 297,
              compression: "FAST",
            });
            console.log("9");
            originpdf.addImage({
              imageData: firstData,
              format: "PNG",
              x: 0,
              y: 0,
              width: 210,
              height: 297,
              compression: "FAST",
            });
            console.log("10");
            updateCompltePage((prev) => [...prev, 1]);
            for (let i = 1; i < item.refArray.length; i++) {
              const element = item.refArray[i].current;
              if (element) {
                const canvas = await html2canvas(element, {
                  scale: 2,
                  allowTaint: true,
                  useCORS: true,
                  logging: true,
                  imageTimeout: 15000,
                  removeContainer: true,
                });
                const data = canvas.toDataURL("image/png", 1.0);
                originpdf.addPage();
                originpdf.addImage({
                  imageData: data,
                  format: "PNG",
                  x: 0,
                  y: 0,
                  width: 210,
                  height: 297,
                  compression: "FAST",
                });
              }
              updateCompltePage((prev) => [...prev, 1]);
            }
            console.log("11");

            const coverData = coverPdf.output("blob");
            const originData = originpdf.output("blob");
            console.log("12");
            //투자심의위원회 의사록 업로드
            const coverMinuteFile = new File(
              [coverData],
              `${item.fundAccountName}_투자심의위원회_의사록.pdf`
            );
            console.log("13");
            const originMinuteFile = new File(
              [originData],
              `${item.fundAccountName}_투자심의위원회_의사록.pdf`
            );
            console.log("14");
            const coverFileResult = await uploadFile(
              coverMinuteFile,
              "investment",
              props.portfolioId
            );

            const originFileResult = await uploadFile(
              originMinuteFile,
              "investment",
              props.portfolioId
            );
            console.log("15");
            if (
              coverFileResult?.id === undefined ||
              originFileResult?.id === undefined
            ) {
              return resolve({
                isSucessed: false,
              });
            }
            console.log("16");
            return resolve({
              isSucessed: true,
              data: {
                documentData: [
                  {
                    documentType: "2",
                    acFundAccount: item.acFundAccountId,
                    investmentAssessment: props.investmentAssessmentId,
                    file: item.file?.id,
                    investmentAssessmentReportAccountId: item.id,
                  },
                  {
                    documentType: "50",
                    acFundAccount: item.acFundAccountId,
                    investmentAssessment: props.investmentAssessmentId,
                    file: coverFileResult?.id || -1,
                    investmentAssessmentReportAccountId: item.id,
                  },
                  {
                    documentType: "3",
                    acFundAccount: item.acFundAccountId,
                    investmentAssessment: props.investmentAssessmentId,
                    file: originFileResult?.id || -1,
                    investmentAssessmentReportAccountId: item.id,
                  },
                ],
              },
            });
          });
        }
      );
      const result = await Promise.all(promiseArr);
      if (result.filter((item) => item.isSucessed).length === result.length) {
        const documentData: any[] = [];
        result.forEach((item) => {
          item.data.documentData.forEach((data: any) => {
            documentData.push(data);
          });
        });
        await onSccess();
        await registePortfolioDocument(props.portfolioId, documentData);
        props.callback();
      } else {
        await onFail();
        props.onFaileCallback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PDFViewer>
      <Body className="scroll__invisible">
        {arrayRef.map((item) => {
          return item.data.map((data, idx) => {
            return (
              <div key={idx} ref={item.refArray[idx]}>
                {data.isFundManager ? (
                  <MinuteDocumentFundManagerPrint
                    minutesDocument={data}
                    logoImage={logoImage}
                    onLoaded={(index: number) => {
                      updateLoadedPages((prev) => [...prev, 1]);
                    }}
                    index={idx}
                  />
                ) : (
                  <MinuteDocumentPrint
                    minutesDocument={data}
                    logoImage={logoImage}
                    onLoaded={(index: any) => {
                      updateLoadedPages((prev) => [...prev, 1]);
                    }}
                    index={idx}
                  />
                )}
              </div>
            );
          });
        })}
      </Body>

      {openProgress && (
        <ModalLayout isOpen={openProgress} isFullScreen>
          <ProgressModalStatic
            SvgIcon={progressIcon}
            progress={(completePage.length / totalPage.current) * 100}
            message={progressMsg}
            progressFail={progressFail}
            transfromPixel={(n) => n}
          />
        </ModalLayout>
      )}
    </PDFViewer>
  );
};

const PDFViewer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  z-index: -1000;
`;

const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  background: #c4c4c4;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  gap: 10px;
`;
export default MinuteDocumentModal;
