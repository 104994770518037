import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Sector } from "../Sector";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { SectorDescription } from "../SectorDescription";
import { InvestmentAssessment } from "../../../../../type/data";
import InputBox from "../InputBox";
import { CustomDate } from "../table/custom-cell/CustomDate";
import { RemoveComma } from "../../../../../common/commonUtil";
import { CompanyHistoryTable } from "./organism/CompanyHistoryTable";
import { CompanyMemberTable } from "./organism/CompanyMemberTable";
import { EmployeeTable } from "./organism/EmployeeTable";

interface Props {
  isReadOnly: boolean;
  data?: InvestmentAssessment;
  onChangeData?: (data: InvestmentAssessment) => void;
}

export const Section2: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;

  if (!data) return null;

  return (
    <Container>
      <Sector title="경영진 현황" id="Section2_1">
        <FlexColumn style={{ gap: 8 }}>
          <SectorDescription text={`지분율 미입력시, 0%로 자동처리됩니다.`} />
          <CompanyMemberTable
            isReadOnly={props.isReadOnly}
            disabled={data.investmentAssessmentCompanyMember?.isChecked}
            dataList={data.investmentAssessmentCompanyMember?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentCompanyMember: {
                    ...data.investmentAssessmentCompanyMember,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
        </FlexColumn>
      </Sector>

      <Sector title="임직원 현황" id="Section2_2">
        <FlexColumn style={{ gap: 8 }}>
          <SectorDescription
            text={`경영진을 제외한 임직원에 대해 작성해 주세요. 전체 임직원 수가 많을 경우, 주요 임직원에 대해서만 작성해 주세요. 지분율 미입력시, 0%로 자동처리됩니다.`}
          />
          <EmployeeTable
            isReadOnly={props.isReadOnly}
            disabled={data.investmentAssessmentKeyManagement?.isChecked}
            dataList={data.investmentAssessmentKeyManagement?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentKeyManagement: {
                    ...data.investmentAssessmentKeyManagement,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
        </FlexColumn>
      </Sector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--gray-700);
        stroke: var(--gray-700);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--gray-700);
        }
      }
    }
  }
`;
