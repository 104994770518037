import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { DefaultButton } from "lattice_core";

import ReportFileDownload from "../../../reporting/desktop/components/ReportFileDownload";

import { ReactComponent as CheckChip } from "../../../../assets/image/new_step_chip.svg";
import { ReactComponent as Close } from "../../../../assets/image/new_close_button.svg";
import { ReactComponent as Lock } from "../../../../assets/image/new_icon_lock.svg";
import { ReactComponent as Loading } from "../../../../assets/image/loading.svg";
import { ReactComponent as Download } from "../../../../assets/image/new_icon_download.svg";
import { ReactComponent as Spread } from "../../../../assets/image/icon_spread_btn.svg";
import { ReactComponent as Fold } from "../../../../assets/image/icon_fold_btn.svg";

import StatusBall from "../../../reporting/desktop/components/StatusBall";

import { ChatingSpeachBubbleProps } from "./chat/useChating";
import { readReport } from "../../../reporting/repository/ReportingRepository";

import SummaryBusinessPlanViewer from "../organism/summary-business-plan/SummaryBusinessPlanViewer";
import { PDFViewer } from "../../../../components-2.0v/pdf-viewer/PDFViewer";
import RollingSelector, {
  RollingSelectorDataProps,
} from "../../../../components-2.0v/rolling-selector/RollingSelector";
import { getMyInfo } from "../../../../common/commonUtil";
import { FinancialStatementViewer } from "../../../reporting/desktop/components/financial-statements/organism/FinancialStatementViewer";
import { ReportingFile } from "../../interface/ReportingDetail.interface";

import ChatingDetail from "./chat/ChatingDetail";

interface Props {
  isOpen: boolean;
  isManager: boolean;
  portfolioName: string;
  selectedReport?: ReportingFile;
  files: ReportingFile[];
  portfolioId: number;
  year: number;
  quarterNum: number;
  firstIndex: number;
  isOpenChating: boolean;
  SBChatingMsg: ChatingSpeachBubbleProps[];
  currentReadChatItemId: number;
  chatingFocusing: boolean;
  updateChatingFocusing: React.Dispatch<React.SetStateAction<boolean>>;
  nextPageChat: () => void;
  clearUnreadMsgCount: () => void;
  sendChating: (text: string) => void;

  close: () => void;

  transfromPixel: (value: number) => number;
  getPortfolioReportDocumentByRefData: () => void;

  confirm: (
    documentId: number,
    documentData:
      | {
          acPfDocumentId: number;
          pfReportDocumentDataId?: number;
        }
      | undefined
  ) => void;
  reject: (documentId: number) => void;
  confirmCancel: (documentId: number) => void;
  rejectCancel: (documentId: number) => void;
}

const ViewerPortfolioDetailModal = (props: Props) => {
  const [selectedReport, updateSelectedReport] = useState<
    ReportingFile | undefined
  >(props.selectedReport);
  const [requiredDocument, updateRequiredDocument] = useState<ReportingFile[]>(
    []
  );
  const [optionDocument, updateOptionDocument] = useState<ReportingFile[]>([]);
  const [rollingData, updateRollingData] = useState<RollingSelectorDataProps[]>(
    []
  );
  const defaultRollingDataIndex = useRef<number>(
    props.files.findIndex(
      (item) => item.acPfDocumentId === props.selectedReport?.acPfDocumentId
    )
  );
  const [requiredDocumentCount, updateRequiredDocumentCount] =
    useState<number>(0);
  const [uploadedRequiredDocumentCount, updateUploadedRequiredDocumentCount] =
    useState<number>(0);

  const [isDocumentListFolded, updateIsDocumentListFolded] =
    useState<boolean>(false);

  const [documentLoaded, updateDocumentLoaded] = useState<boolean>(false);

  const getGoolgeUrl = (documentId: string, extension: string) => {
    return `https://drive.google.com/file/d/${documentId}/preview`;
    // switch (extension) {
    //   case "excel":
    //     return `https://docs.google.com/spreadsheets/d/${documentId}/edit?usp=sharing&ouid=112598283255709341659&rtpof=true&sd=true`;
    //   default:
    //     return `https://drive.google.com/file/d/${documentId}/preview`;
    //   // return `https://drive.google.com/file/d/${documentId}/view`;
    // }
  };

  const onReadDocument = async () => {
    try {
      const myInfo = getMyInfo();
      if (
        myInfo.authorityId === 2 &&
        selectedReport &&
        selectedReport.file &&
        [1, 2].includes(selectedReport.file.uploadStatus.uploadStatusId)
      ) {
        await readReport(selectedReport.file.pfReportDocumentId);
        await props.getPortfolioReportDocumentByRefData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getViewerComponent = () => {
    if (
      selectedReport === undefined ||
      selectedReport.file === undefined ||
      selectedReport.file === null ||
      selectedReport.file.documentFile === undefined
    ) {
      return (
        <div className="heading-18-md empty__container">
          <Lock />
          <div>제출된 서류가 없습니다.</div>
        </div>
      );
    }

    if (selectedReport.extension === "web") {
      return (
        <div className="heading-18-md empty__container">
          <SummaryBusinessPlanViewer file={selectedReport.file} />
        </div>
      );
    }

    if ([1].includes(selectedReport.acPfDocumentId)) {
      return (
        <div className="viewer__iframe__wrapper">
          <iframe
            className="viewer__iframe"
            src={getGoolgeUrl(
              selectedReport.file.documentFile?.key || "",
              selectedReport.extension
            )}
            onLoad={() => {
              updateDocumentLoaded(true);
            }}
          />

          {!documentLoaded && (
            <div className="viewer__iframe__load__container">
              <Loading
                className="rotating"
                width={props.transfromPixel(30)}
                height={props.transfromPixel(30)}
              />
            </div>
          )}
        </div>
      );
    }

    if ([7, 8].includes(selectedReport.acPfDocumentId)) {
      return (
        <div className="heading-18-md empty__container">
          <FinancialStatementViewer
            portfolioId={props.portfolioId}
            year={props.year}
            quarterNum={props.quarterNum}
            data={selectedReport}
          />
        </div>
      );
    }

    return (
      <PDFViewer
        type={selectedReport.file.type}
        pdfKey={selectedReport.file.documentFile.key}
      />
    );
  };

  useEffect(() => {
    console.log(selectedReport);
    updateDocumentLoaded(false);
    onReadDocument();
  }, [selectedReport]);

  useEffect(() => {
    const required = props.files.filter((item) => item.required);
    const option = props.files.filter((item) => !item.required);
    updateRollingData(
      props.files.map((item, index): RollingSelectorDataProps => {
        return {
          id: index,
          text: item.name,
          data: item,
        };
      })
    );
    updateRequiredDocument(required);
    updateSelectedReport(
      required.find(
        (item) => item.acPfDocumentId === selectedReport?.acPfDocumentId
      )
    );
    updateOptionDocument(option);
    updateRequiredDocumentCount(required.length);
    updateUploadedRequiredDocumentCount(
      required.filter((item) => item.file !== undefined).length
    );
  }, [props.files]);

  if (rollingData.length === 0) return null;

  return (
    <Conainer>
      <Header transfromPixel={props.transfromPixel}>
        <span
          className="heading-18-sb"
          style={{ paddingTop: props.transfromPixel(14) }}
        >
          {props.portfolioName}
        </span>
        <div className="close__btn" onClick={props.close}>
          <Close />
        </div>
      </Header>
      <Body transfromPixel={props.transfromPixel}>
        <DocumentListContainer
          className={`scroll__invisible ${
            isDocumentListFolded ? "folded" : ""
          }`}
          transfromPixel={props.transfromPixel}
        >
          <div className="document__list__upload__status">
            <span className="heading-18-md">제출서류</span>
            <span className="heading-14-md" style={{ color: "#96999F" }}>
              {requiredDocumentCount}건 중 {uploadedRequiredDocumentCount} 건
              제출
            </span>
          </div>
          <DocumentList
            transfromPixel={props.transfromPixel}
            style={{ marginTop: props.transfromPixel(24) }}
          >
            <div className="heading-14-md document__list__gorup__title">
              필수
            </div>
            {requiredDocument.map((item, index) => {
              const isSelected =
                selectedReport?.acPfDocumentId === item.acPfDocumentId;
              return (
                <div
                  className={`document__item ${isSelected ? "selected" : ""}`}
                  onClick={() => {
                    if (selectedReport?.acPfDocumentId === item.acPfDocumentId)
                      return;
                    defaultRollingDataIndex.current = index;
                    updateSelectedReport(item);
                  }}
                >
                  <div className="document__item__title">
                    {item.file ? (
                      <div
                        className={`document__check__chip ${
                          isSelected ? "selected" : ""
                        }`}
                      >
                        <CheckChip
                          width={props.transfromPixel(22)}
                          height={props.transfromPixel(22)}
                        />
                      </div>
                    ) : (
                      <div
                        className={`body-14-md document__number ${
                          isSelected ? "selected" : ""
                        }`}
                      >
                        {index + 1}
                      </div>
                    )}
                    <div
                      className={
                        item.file?.uploadStatus.uploadStatusId &&
                        [1, 2].includes(item.file.uploadStatus.uploadStatusId)
                          ? "heading-14-sb"
                          : "body-14-rg"
                      }
                      style={{ flexGrow: 1 }}
                    >
                      {item.name}
                    </div>
                    {item.file &&
                      [3, 4, 5].includes(
                        item.file.uploadStatus.uploadStatusId
                      ) && (
                        <StatusBall
                          uploadStatusId={item.file.uploadStatus.uploadStatusId}
                          uploadStatusName={item.file.uploadStatus.name}
                          transfromPixel={props.transfromPixel}
                        />
                      )}
                  </div>
                  {props.isOpen &&
                    props.isManager &&
                    isSelected &&
                    item.file && (
                      <div className="document__item__button__container">
                        {[1, 2, 3].includes(
                          item.file.uploadStatus.uploadStatusId
                        ) && (
                          <DefaultButton
                            className="caption-12-md"
                            buttonType="colored-outline"
                            sizeType="xxsmall"
                            buttonColor={"#1F2022"}
                            borderColor={"#D5D6D8"}
                            text="반려"
                            transfromPixel={props.transfromPixel}
                            onClick={() =>
                              item.file?.pfReportDocumentId &&
                              props.reject(item.file.pfReportDocumentId)
                            }
                          />
                        )}

                        {[1, 2, 3].includes(
                          item.file.uploadStatus.uploadStatusId
                        ) && (
                          <DefaultButton
                            className="caption-12-md"
                            buttonType="colored-outline"
                            sizeType="xxsmall"
                            buttonColor={"#1F2022"}
                            borderColor={"#D5D6D8"}
                            text="확정"
                            transfromPixel={props.transfromPixel}
                            onClick={() => {
                              if (item.file?.pfReportDocumentId) {
                                props.confirm(item.file.pfReportDocumentId, {
                                  acPfDocumentId: item.acPfDocumentId,
                                  pfReportDocumentDataId:
                                    item.file.pfReportDocumentData !==
                                      undefined &&
                                    item.file.pfReportDocumentData !== null
                                      ? item.file.pfReportDocumentData.id
                                      : undefined,
                                });
                              }
                            }}
                          />
                        )}

                        {item.file.uploadStatus.uploadStatusId === 4 && (
                          <DefaultButton
                            className="caption-12-md"
                            buttonType="colored-outline"
                            sizeType="xxsmall"
                            buttonColor={"#1F2022"}
                            borderColor={"#D5D6D8"}
                            text="반려취소"
                            transfromPixel={props.transfromPixel}
                            onClick={() =>
                              item.file?.pfReportDocumentId &&
                              props.rejectCancel(item.file.pfReportDocumentId)
                            }
                          />
                        )}

                        {item.file.uploadStatus.uploadStatusId === 5 && (
                          <DefaultButton
                            className="caption-12-md"
                            buttonType="colored-outline"
                            sizeType="xxsmall"
                            buttonColor={"#1F2022"}
                            borderColor={"#D5D6D8"}
                            text="확정취소"
                            transfromPixel={props.transfromPixel}
                            onClick={() =>
                              item.file?.pfReportDocumentId &&
                              props.confirmCancel(item.file.pfReportDocumentId)
                            }
                          />
                        )}
                      </div>
                    )}
                </div>
              );
            })}
          </DocumentList>
          <DocumentList
            transfromPixel={props.transfromPixel}
            style={{ marginTop: props.transfromPixel(16) }}
          >
            <div className="heading-14-md document__list__gorup__title">
              옵션
            </div>
            {optionDocument.map((item, index) => {
              const isSelected =
                selectedReport?.acPfDocumentId === item.acPfDocumentId;
              return (
                <div
                  className={`document__item ${isSelected ? "selected" : ""}`}
                  onClick={() => {
                    if (selectedReport?.acPfDocumentId === item.acPfDocumentId)
                      return;

                    defaultRollingDataIndex.current =
                      requiredDocument.length + index;
                    updateSelectedReport(item);
                  }}
                >
                  <div className="document__item__title">
                    {item.file ? (
                      <div
                        className={`document__check__chip ${
                          isSelected ? "selected" : ""
                        }`}
                      >
                        <CheckChip
                          width={props.transfromPixel(22)}
                          height={props.transfromPixel(22)}
                        />
                      </div>
                    ) : (
                      <div
                        className={`body-14-md document__number ${
                          isSelected ? "selected" : ""
                        }`}
                      >
                        {requiredDocument.length + index + 1}
                      </div>
                    )}
                    <div
                      className={
                        item.file?.uploadStatus.uploadStatusId &&
                        [1, 2].includes(item.file.uploadStatus.uploadStatusId)
                          ? "heading-14-sb"
                          : "body-14-rg"
                      }
                      style={{ flexGrow: 1 }}
                    >
                      {item.name}
                    </div>
                    {item.file &&
                      [3, 4, 5].includes(
                        item.file.uploadStatus.uploadStatusId
                      ) && (
                        <StatusBall
                          uploadStatusId={item.file.uploadStatus.uploadStatusId}
                          uploadStatusName={item.file.uploadStatus.name}
                          transfromPixel={props.transfromPixel}
                        />
                      )}
                  </div>
                  {props.isOpen &&
                    props.isManager &&
                    isSelected &&
                    item.file && (
                      <div className="document__item__button__container">
                        {[1, 2, 3].includes(
                          item.file.uploadStatus.uploadStatusId
                        ) && (
                          <DefaultButton
                            className="caption-12-md"
                            buttonType="colored-outline"
                            sizeType="xxsmall"
                            buttonColor={"#1F2022"}
                            borderColor={"#D5D6D8"}
                            text="반려"
                            transfromPixel={props.transfromPixel}
                            onClick={() =>
                              item.file?.pfReportDocumentId &&
                              props.reject(item.file.pfReportDocumentId)
                            }
                          />
                        )}

                        {[1, 2, 3].includes(
                          item.file.uploadStatus.uploadStatusId
                        ) && (
                          <DefaultButton
                            className="caption-12-md"
                            buttonType="colored-outline"
                            sizeType="xxsmall"
                            buttonColor={"#1F2022"}
                            borderColor={"#D5D6D8"}
                            text="확정"
                            transfromPixel={props.transfromPixel}
                            onClick={() => {
                              if (item.file?.pfReportDocumentId) {
                                props.confirm(item.file.pfReportDocumentId, {
                                  acPfDocumentId: item.acPfDocumentId,
                                  pfReportDocumentDataId:
                                    item.file.pfReportDocumentData !==
                                      undefined &&
                                    item.file.pfReportDocumentData !== null
                                      ? item.file.pfReportDocumentData.id
                                      : undefined,
                                });
                              }
                            }}
                          />
                        )}

                        {item.file.uploadStatus.uploadStatusId === 4 && (
                          <DefaultButton
                            className="caption-12-md"
                            buttonType="colored-outline"
                            sizeType="xxsmall"
                            buttonColor={"#1F2022"}
                            borderColor={"#D5D6D8"}
                            text="반려취소"
                            transfromPixel={props.transfromPixel}
                            onClick={() =>
                              item.file?.pfReportDocumentId &&
                              props.rejectCancel(item.file.pfReportDocumentId)
                            }
                          />
                        )}

                        {item.file.uploadStatus.uploadStatusId === 5 && (
                          <DefaultButton
                            className="caption-12-md"
                            buttonType="colored-outline"
                            sizeType="xxsmall"
                            buttonColor={"#1F2022"}
                            borderColor={"#D5D6D8"}
                            text="확정취소"
                            transfromPixel={props.transfromPixel}
                            onClick={() =>
                              item.file?.pfReportDocumentId &&
                              props.confirmCancel(item.file.pfReportDocumentId)
                            }
                          />
                        )}
                      </div>
                    )}
                </div>
              );
            })}
          </DocumentList>
        </DocumentListContainer>
        <ViewerContainer
          className={isDocumentListFolded ? "spread" : ""}
          transfromPixel={props.transfromPixel}
        >
          <div className="viewer__header">
            {props.isOpen &&
              props.isManager &&
              isDocumentListFolded &&
              selectedReport &&
              selectedReport.file && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: props.transfromPixel(6),
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingLeft: props.transfromPixel(32),
                  }}
                >
                  {[1, 2, 3].includes(
                    selectedReport.file.uploadStatus.uploadStatusId
                  ) && (
                    <DefaultButton
                      className="caption-12-md"
                      buttonType="colored-outline"
                      sizeType="xxsmall"
                      buttonColor={"#1F2022"}
                      borderColor={"#D5D6D8"}
                      text="반려"
                      transfromPixel={props.transfromPixel}
                      onClick={() =>
                        selectedReport.file?.pfReportDocumentId &&
                        props.reject(selectedReport.file.pfReportDocumentId)
                      }
                    />
                  )}

                  {[1, 2, 3].includes(
                    selectedReport.file.uploadStatus.uploadStatusId
                  ) && (
                    <DefaultButton
                      className="caption-12-md"
                      buttonType="colored-outline"
                      sizeType="xxsmall"
                      buttonColor={"#1F2022"}
                      borderColor={"#D5D6D8"}
                      text="확정"
                      transfromPixel={props.transfromPixel}
                      onClick={() => {
                        if (selectedReport.file?.pfReportDocumentId) {
                          props.confirm(
                            selectedReport.file.pfReportDocumentId,
                            {
                              acPfDocumentId: selectedReport.acPfDocumentId,
                              pfReportDocumentDataId:
                                selectedReport.file.pfReportDocumentData !==
                                  undefined &&
                                selectedReport.file.pfReportDocumentData !==
                                  null
                                  ? selectedReport.file.pfReportDocumentData.id
                                  : undefined,
                            }
                          );
                        }
                      }}
                    />
                  )}

                  {selectedReport.file.uploadStatus.uploadStatusId === 4 && (
                    <DefaultButton
                      className="caption-12-md"
                      buttonType="colored-outline"
                      sizeType="xxsmall"
                      buttonColor={"#1F2022"}
                      borderColor={"#D5D6D8"}
                      text="반려취소"
                      transfromPixel={props.transfromPixel}
                      onClick={() =>
                        selectedReport.file?.pfReportDocumentId &&
                        props.rejectCancel(
                          selectedReport.file.pfReportDocumentId
                        )
                      }
                    />
                  )}

                  {selectedReport.file.uploadStatus.uploadStatusId === 5 && (
                    <DefaultButton
                      className="caption-12-md"
                      buttonType="colored-outline"
                      sizeType="xxsmall"
                      buttonColor={"#1F2022"}
                      borderColor={"#D5D6D8"}
                      text="확정취소"
                      transfromPixel={props.transfromPixel}
                      onClick={() =>
                        selectedReport.file?.pfReportDocumentId &&
                        props.confirmCancel(
                          selectedReport.file.pfReportDocumentId
                        )
                      }
                    />
                  )}
                </div>
              )}
            {!isDocumentListFolded && (
              <FileDownload
                transfromPixel={props.transfromPixel}
                style={{ userSelect: "none", opacity: 0, cursor: "default" }}
              >
                다운로드
              </FileDownload>
            )}
            {isDocumentListFolded && !props.isManager && (
              <FileDownload
                transfromPixel={props.transfromPixel}
                style={{ userSelect: "none", opacity: 0, cursor: "default" }}
              >
                다운로드
              </FileDownload>
            )}
            <RollingSelector
              isInfinityRolling
              data={rollingData}
              textAlign={"rolling_middle"}
              textWidth={props.transfromPixel(200)}
              defaultPosition={defaultRollingDataIndex.current}
              transfromPixel={props.transfromPixel}
              onChangeData={(data, index) => {
                console.log(data);
                if (
                  !selectedReport ||
                  !data ||
                  selectedReport?.acPfDocumentId === data.data.acPfDocumentId
                )
                  return;

                if (index !== undefined) {
                  defaultRollingDataIndex.current = index;
                }
                updateSelectedReport(data.data);
              }}
            />
            {selectedReport &&
            selectedReport.extension !== "web" &&
            selectedReport.file ? (
              <ReportFileDownload
                docucmentInfo={{
                  fileId: selectedReport.file.pfReportDocumentId,
                  extension: selectedReport.extension,
                  fileName:
                    selectedReport.file.documentFile?.fileName || "untitled",
                }}
                transfromPixel={props.transfromPixel}
                callbackFn={props.getPortfolioReportDocumentByRefData}
              >
                <FileDownload
                  className="body-14-rg"
                  transfromPixel={props.transfromPixel}
                >
                  다운로드
                  <Download
                    width={props.transfromPixel(16)}
                    height={props.transfromPixel(16)}
                  />
                </FileDownload>
              </ReportFileDownload>
            ) : (
              <FileDownload
                transfromPixel={props.transfromPixel}
                style={{ userSelect: "none", opacity: 0, cursor: "default" }}
              >
                다운로드
              </FileDownload>
            )}
            <FoldSpreadBtn
              transfromPixel={props.transfromPixel}
              onClick={() => updateIsDocumentListFolded((prev) => !prev)}
            >
              {isDocumentListFolded ? (
                <Fold className={`arrow`} />
              ) : (
                <Spread className={`arrow`} />
              )}
            </FoldSpreadBtn>
          </div>
          <div className="viewer">{getViewerComponent()}</div>
        </ViewerContainer>
        <ChatingContainer
          className={isDocumentListFolded ? "folded" : ""}
          transfromPixel={props.transfromPixel}
        >
          <ChatingDetail
            firstIndex={props.firstIndex}
            isOpenChating={props.isOpenChating}
            SBChatingMsg={props.SBChatingMsg}
            currentReadChatItemId={props.currentReadChatItemId}
            nextPageChat={props.nextPageChat}
            chatingFocusing={props.chatingFocusing}
            updateChatingFocusing={props.updateChatingFocusing}
            sendChating={props.sendChating}
            transfromPixel={props.transfromPixel}
            clearUnreadMsgCount={props.clearUnreadMsgCount}
          />
        </ChatingContainer>
      </Body>
    </Conainer>
  );
};

const Conainer = styled.div(
  () => css`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `
);

const Header = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: 100%;
    height: ${transfromPixel(66)}px;
    min-height: ${transfromPixel(66)}px;
    max-height: ${transfromPixel(66)}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${transfromPixel(1)}px solid #edeeef;
    padding-left: ${transfromPixel(24)}px;
    padding-right: ${transfromPixel(20)}px;

    .close__btn {
      width: ${transfromPixel(32)}px;
      height: ${transfromPixel(32)}px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: ${transfromPixel(4)}px;
      transition: background-color 0.2s ease;

      margin-top: ${transfromPixel(3)}px;
      cursor: pointer;
      :hover {
        background-color: #f3f4f4;
      }
    }
  `
);

const Body = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: 100%;
    height: calc(100% - ${transfromPixel(66)}px);
    display: flex;
    flex-direction: row;
  `
);

const DocumentListContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: ${transfromPixel(356)}px;
    min-width: ${transfromPixel(356)}px;
    max-width: ${transfromPixel(356)}px;
    height: 100%;

    padding: ${transfromPixel(24)}px ${transfromPixel(24)}px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow-y: scroll;

    transition-property: width, min-width, max-width, padding-left,
      padding-right;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    .document__list__upload__status {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: ${transfromPixel(8)}px;
    }

    &.folded {
      width: ${transfromPixel(0)}px;
      min-width: ${transfromPixel(0)}px;
      max-width: ${transfromPixel(0)}px;
      padding: 0px;
      opacity: 0;
    }
  `
);

const DocumentList = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: ${transfromPixel(8)}px;

    .document__list__gorup__title {
      color: #5b5d61;
    }

    .document__item {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: ${transfromPixel(12)}px ${transfromPixel(16)}px;
      color: #5b5d61;

      background: #fafafb;
      border: ${transfromPixel(1)}px solid #edeeef;
      border-radius: ${transfromPixel(4)}px;

      gap: ${transfromPixel(24)}px;

      cursor: pointer;
      transition: all 0.2s ease;

      &.selected {
        background-color: #f6faff;
        border-color: #a2c7ff;
      }

      .document__item__title {
        width: 100%;
        height: ${transfromPixel(22)}px;
        min-height: ${transfromPixel(22)}px;
        max-height: ${transfromPixel(22)}px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: ${transfromPixel(10)}px;

        color: #5b5d61;

        .document__check__chip {
          height: ${transfromPixel(22)}px;
          min-height: ${transfromPixel(22)}px;
          max-height: ${transfromPixel(22)}px;

          &.selected {
            rect {
              fill: #173aff;
            }
            path {
              fill: #ffffff;
            }
          }
        }

        .document__number {
          width: ${transfromPixel(22)}px;
          min-width: ${transfromPixel(22)}px;
          max-width: ${transfromPixel(22)}px;
          height: ${transfromPixel(22)}px;
          min-height: ${transfromPixel(22)}px;
          max-height: ${transfromPixel(22)}px;
          border-radius: ${transfromPixel(4)}px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #f3f4f4;
          color: #bec0c2;

          &.selected {
            background-color: #173aff;
            color: #ffffff;
          }
        }
      }

      .document__item__button__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: ${transfromPixel(6)}px;
      }

      :hover {
        border-color: #a2c7ff;
      }
    }
  `
);

const ViewerContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: calc(100vw - ${transfromPixel(356)}px - ${transfromPixel(380)}px);
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: ${transfromPixel(1)}px solid #edeeef;
    border-right: ${transfromPixel(1)}px solid #edeeef;

    &.spread {
      width: calc(100vw);
    }

    .viewer__header {
      position: relative;
      width: 100%;
      height: ${transfromPixel(60)}px;
      min-height: ${transfromPixel(60)}px;
      max-height: ${transfromPixel(60)}px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: ${transfromPixel(1)}px solid #edeeef;

      padding: 0 ${transfromPixel(24)}px;
    }

    .viewer {
      width: 100%;
      height: calc(100% - 60px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-bottom: ${transfromPixel(1)}px solid #edeeef;

      .viewer__iframe__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .viewer__iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }

        .viewer__iframe__load__container {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .empty__container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${transfromPixel(20)}px;
        background: #fbfcfd;
        color: #7e8086;
      }
    }

    /* Safari and Chrome */
    @-webkit-keyframes rotating {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    .rotating {
      -webkit-animation: rotating 1s linear infinite;
      -moz-animation: rotating 1s linear infinite;
      -ms-animation: rotating 1s linear infinite;
      -o-animation: rotating 1s linear infinite;
      animation: rotating 1s linear infinite;
    }
  `
);

const FoldSpreadBtn = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;
    width: ${transfromPixel(32)}px;
    height: ${transfromPixel(32)}px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff; //background-header

    z-index: 2;

    :hover {
      background-color: #f3f4f4; //button-disabled
    }

    cursor: pointer;

    .arrow {
      transition: all 0.2s ease;
      :hover {
        rect:nth-child(1) {
          fill: var(--hover-100);
        }
      }
    }
  `
);

const FileDownload = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: ${transfromPixel(93)}px;
    height: ${transfromPixel(34)}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${transfromPixel(4)}px;

    background-color: #ffffff;
    border: ${transfromPixel(1)}px solid #d5d6d8;
    border-radius: ${transfromPixel(4)}px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    path {
      fill: #1f2022;
    }

    :hover {
      background-color: #f3f4f4;
    }

    &.disabled {
      background-color: #ffffff;
      border-color: #f3f4f4;
      color: #bec0c2;
      cursor: default;
      path {
        fill: #bec0c2;
      }
      :hover {
        background-color: #ffffff;
      }
    }
  `
);

const ChatingContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: ${transfromPixel(380)}px;
    min-width: ${transfromPixel(380)}px;
    max-width: ${transfromPixel(380)}px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.folded {
      width: ${transfromPixel(0)}px;
      min-width: ${transfromPixel(0)}px;
      max-width: ${transfromPixel(0)}px;
      padding: 0px;
      opacity: 0;
    }
  `
);

export default ViewerPortfolioDetailModal;
