import React from "react";
import styled from "styled-components";

import { IAReportUser } from "../../../../../type/data";

import { VoteResultListHeader } from "./VoteResultListHeader";
import { VoteResultListRow } from "./VoteResultListRow";

interface Props {
  reportUsers: IAReportUser[];
  isVoteFinished: boolean;
}
export const VoteResultList: React.FC<Props> = (props) => {
  const { reportUsers, isVoteFinished } = props;
  return (
    <Container>
      <VoteResultListHeader isFinished={isVoteFinished} />
      {reportUsers.map((item, key) => {
        return (
          <VoteResultListRow
            key={key}
            reportUser={item}
            isFinished={isVoteFinished}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
