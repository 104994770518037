import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFundAccountDetailForDashboard,
  getFundAccountManagersForDashboard,
} from "../../../../api/repository/accelerator/AcceleratorRepository";
import { trnasfromBase64ToObject } from "../../../../common/commonUtil";
import {
  FundAccountDetailInfo,
  FundManagerProps,
  InvestmentPerformanceProps,
  InvestmentPerformanceResponse,
} from "../interface/FundAccountDetail.interface";
import { AccountType } from "../../fund-account-info/interface/FundAccountInfo.interface";
import { CustomColorLabel } from "../../../../components-2.0v/label/CustomColorLabel";
import { getToday } from "../../../../common/commonApiData";
import { getInvestmentPerformanceSummary } from "../../../../api/repository/accelerator/DashboardRepository";

const useFundAccountDetail = () => {
  const navigate = useNavigate();
  const isUniqueAccount = useRef<boolean>(false);
  const [fundAccountId, setFundAccountId] = useState<number>(0);
  const [fundAccountInfo, setFundAccountInfo] = useState<
    FundAccountDetailInfo | undefined
  >(undefined);
  const [fundManager, setFundManager] = useState<FundManagerProps[]>([]);
  const [investmentPerformance, setInvestmentPerformance] = useState<
    InvestmentPerformanceResponse[]
  >([]);
  const [toDay, setToday] = useState<string>("");

  const goToBack = () => {
    navigate(-1);
  };

  const init = async () => {
    const path = window.location.pathname.split("/");
    const data = trnasfromBase64ToObject(path[path.length - 1]);
    setFundAccountId(data.fundAccountId);

    const result = await getFundAccountDetailForDashboard(data.fundAccountId);
    isUniqueAccount.current = data.fundAccountId === 1;
    setFundAccountInfo(result);

    const investmentPerformanceResult = await getInvestmentPerformanceSummary({
      acFundAccountId: [data.fundAccountId],
    });
    investmentPerformanceResult &&
      setInvestmentPerformance(investmentPerformanceResult);

    const fundManagerResult = await getFundAccountManagersForDashboard(
      data.fundAccountId
    );
    const todayResult = await getToday();
    fundManagerResult && setFundManager(fundManagerResult);
    todayResult && setToday(todayResult);
  };

  const getLabel = () => {
    if (!fundAccountInfo) return null;

    switch (fundAccountInfo.status) {
      case AccountType.INVESTMENT:
        return (
          <CustomColorLabel
            className="caption-11-rg"
            bgColor="var(--green-500)"
            textColor="var(--text-02)"
            style={{ height: "fit-content", borderRadius: 2 }}
          >
            투자가능
          </CustomColorLabel>
        );
      case AccountType.OPERATING:
        return (
          <CustomColorLabel
            className="caption-11-rg"
            bgColor="var(--blue-400)"
            textColor="var(--text-02)"
            style={{ height: "fit-content", borderRadius: 2 }}
          >
            운영중
          </CustomColorLabel>
        );
      case AccountType.WATING:
        return (
          <CustomColorLabel
            className="caption-11-rg"
            bgColor="var(--yellow-500)"
            textColor="var(--text-02)"
            style={{ height: "fit-content", borderRadius: 2 }}
          >
            대기중
          </CustomColorLabel>
        );
      case AccountType.PAUSE:
        return (
          <CustomColorLabel
            className="caption-11-rg"
            bgColor="var(--gray-700)"
            textColor="var(--text-02)"
            style={{ height: "fit-content", borderRadius: 2 }}
          >
            일시중지
          </CustomColorLabel>
        );
      case AccountType.EXPIRED:
        return (
          <CustomColorLabel
            className="caption-11-rg"
            bgColor="var(--gray-500)"
            textColor="var(--text-02)"
            style={{ height: "fit-content", borderRadius: 2 }}
          >
            기간만료
          </CustomColorLabel>
        );
    }
  };
  useEffect(() => {
    init();
  }, []);

  return [
    isUniqueAccount.current,
    fundAccountId,
    fundAccountInfo,
    toDay,
    fundManager,
    investmentPerformance,
    getLabel,
    goToBack,
  ] as const;
};

export default useFundAccountDetail;
