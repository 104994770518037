import React from "react";
import { useMediaQuery } from "react-responsive";

import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";
import MobilePortfolioReporting from "./mobile/MobilePortfolioReporting";
import DeskPortfolioReporting from "./desktop/DeskPortfolioReporting";

const PortfolioReporting: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobilePortfolioReporting {...props} />
  ) : (
    <DeskPortfolioReporting {...props} />
  );
};
export default PortfolioReporting;
