import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FilterView from "./FilterView";
import { NewInvestmentSection } from "./NewInvestmentSection";
import { PerformanceSection } from "./PerformanceSection";
import { ExpectationManagementSection } from "./ExpectationManagementSection";
import { OperationStatusManagementSection } from "./OperationStatusManagementSection";
import { FundAccountLight } from "../../../../../../type/data";

interface Props {
  structureId?: number;
  fundAccount: FundAccountLight[];
}
export const DepartmentPerformance: React.FC<Props> = (props) => {
  const { structureId, fundAccount } = props;
  const [selectedFundAccount, updateSelectedFundAccount] = useState<
    FundAccountLight[]
  >([...fundAccount]);

  const onChangeFundAccount = (
    type: "init" | "click",
    account?: FundAccountLight
  ) => {
    if (type === "init") {
      return updateSelectedFundAccount([...fundAccount]);
    }

    if (!account) return;
    const index = selectedFundAccount.findIndex(
      (item) => item.acFundAccountId === account.acFundAccountId
    );
    if (index !== -1) {
      if (selectedFundAccount.length === fundAccount.length) {
        updateSelectedFundAccount([account]);
      } else {
        selectedFundAccount.splice(index, 1);
        updateSelectedFundAccount([...selectedFundAccount]);
      }
    } else {
      updateSelectedFundAccount([...selectedFundAccount, account]);
    }
  };

  useEffect(() => {
    if (fundAccount.length > 0 && selectedFundAccount.length === 0) {
      onChangeFundAccount("init");
    }
  }, [selectedFundAccount]);

  return (
    <Container>
      <FilterView
        fundAccount={fundAccount}
        selectedFundAccount={selectedFundAccount}
        onChangeFundAccount={onChangeFundAccount}
      />

      <div style={{ marginTop: 36 }}>
        <NewInvestmentSection
          structureId={structureId}
          selectedFundAccountId={selectedFundAccount.map(
            (item) => item.acFundAccountId
          )}
        />
      </div>

      <div style={{ marginTop: 36 }}>
        <PerformanceSection
          structureId={structureId}
          selectedFundAccount={selectedFundAccount}
        />
      </div>

      <div style={{ marginTop: 36 }}>
        <ExpectationManagementSection
          structureId={structureId}
          selectedFundAccount={selectedFundAccount}
        />
      </div>

      <div style={{ marginTop: 36 }}>
        <OperationStatusManagementSection
          structureId={structureId}
          selectedFundAccount={selectedFundAccount}
        />
      </div>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
`;
