import React, { useEffect, useRef, useState } from "react";
import Styled from "styled-components";

import CheckedText from "../../../../../../components-2.0v/check-box/CheckedText";
import { RadioBox } from "../../../../../../components-2.0v/radio-box/RadioBox";

import { ReactComponent as Radio } from "../../../../../../assets/image/icon_radio.svg";

interface Props {
  className?: string;
  defaultValue?: number;
  onChange?: (userStatus: number) => void;
}

export const UserStatus: React.FC<Props> = (props) => {
  const [userStatus, setUserStatus] = useState<number>(props.defaultValue || 1);

  const onChangeUserStatus = (value: number) => {
    setUserStatus(value);
    props.onChange && props.onChange(value);
  };
  useEffect(() => {
    setUserStatus(props.defaultValue || 2);
  }, [props.defaultValue]);
  return (
    <UserStatusorityRadioContainer className="body-14-rg">
      <div
        className="userStatus__item"
        onClick={() => {
          onChangeUserStatus(2);
        }}
      >
        <Radio className={userStatus === 2 ? "checked" : ""} />
        <label>재직</label>
      </div>

      <div
        className="userStatus__item"
        onClick={() => {
          onChangeUserStatus(3);
        }}
      >
        <Radio className={userStatus === 3 ? "checked" : ""} />
        <label>휴직</label>
      </div>

      <div
        className="userStatus__item"
        onClick={() => {
          onChangeUserStatus(4);
        }}
      >
        <Radio className={userStatus === 4 ? "checked" : ""} />
        <label>퇴직</label>
      </div>
    </UserStatusorityRadioContainer>
  );
};

const UserStatusorityRadioContainer = Styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 20px;
  
  .userStatus__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:8px;
    
    .checked { 
      path {
        fill: var(--primary-purple);
      }
    }
  }
  
`;
