import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Sector } from "../Sector";

import { SectorDescription } from "../SectorDescription";
import { InvestmentAssessment } from "../../../../../type/data";

import { ContractStatusTable } from "./organism/ContractStatusTable";
import { IprsProcessTable } from "./organism/IprsProcessTable";
import NothingChecked from "../table/NothingChecked";
import { SimilarBusinessTable } from "./organism/SimilarBusinessTable";

interface Props {
  isReadOnly: boolean;
  data?: InvestmentAssessment;
  onChangeData?: (data: InvestmentAssessment) => void;
}

export const Section5: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;

  const [IAContractStatusCheck, updateIAContractSttusCheck] = useState<
    boolean | undefined
  >(false);
  const [IAIprsCheck, updateIAIprsCheck] = useState<boolean | undefined>(false);
  const [IASimilarBusinessCheck, updateIASimilarBusinessCheck] = useState<
    boolean | undefined
  >(false);

  useEffect(() => {
    if (!data) return;

    if (data.investmentAssessmentContractStatus) {
      updateIAContractSttusCheck(
        data.investmentAssessmentContractStatus.isChecked
      );
    }

    if (data.investmentAssessmentIprsProcess) {
      updateIAIprsCheck(data.investmentAssessmentIprsProcess.isChecked);
    }

    if (data.investmentAssessmentSimilarBusiness) {
      updateIASimilarBusinessCheck(
        data.investmentAssessmentSimilarBusiness.isChecked
      );
    }

    console.log(data);
  }, [data]);

  if (!data) return null;
  return (
    <Container>
      <Sector title="계약 현황" id="Section5_1">
        <FlexColumn style={{ gap: 8 }}>
          <ContractStatusTable
            isReadOnly={props.isReadOnly}
            disabled={IAContractStatusCheck}
            dataList={data.investmentAssessmentContractStatus?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentContractStatus: {
                    ...data.investmentAssessmentContractStatus,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
          <NothingChecked
            defaultValue={data.investmentAssessmentContractStatus?.isChecked}
            onChangeData={(checked) => {
              if (
                onChangeData &&
                data.investmentAssessmentContractStatus !== undefined
              ) {
                updateIAContractSttusCheck(checked);
                data.investmentAssessmentContractStatus.isChecked = checked;
                onChangeData(data);
              }
            }}
          />
        </FlexColumn>
      </Sector>

      <Sector title="출원 및 등록 현황" id="Section5_2">
        <FlexColumn style={{ gap: 8 }}>
          <SectorDescription
            text={`출원까지만 진행된 건에 대해서는 특허번호/등록번호, 등록일은 빈 칸으로 유지해 주세요.`}
          />
          <IprsProcessTable
            isReadOnly={props.isReadOnly}
            disabled={IAIprsCheck}
            dataList={data.investmentAssessmentIprsProcess?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentIprsProcess: {
                    ...data.investmentAssessmentIprsProcess,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
          <NothingChecked
            defaultValue={data.investmentAssessmentIprsProcess?.isChecked}
            onChangeData={(checked) => {
              if (
                onChangeData &&
                data.investmentAssessmentIprsProcess !== undefined
              ) {
                updateIAIprsCheck(checked);
                data.investmentAssessmentIprsProcess.isChecked = checked;
                onChangeData(data);
              }
            }}
          />
        </FlexColumn>
      </Sector>

      <Sector title="국내외 관련기업(경쟁사, 유사사업) 현황" id="Section5_3">
        <FlexColumn style={{ gap: 8 }}>
          <SectorDescription
            text={`국내외 경쟁사 기업 최소 2군대 이상 분석해 주세요.`}
          />
          <SimilarBusinessTable
            isReadOnly={props.isReadOnly}
            disabled={IASimilarBusinessCheck}
            dataList={data.investmentAssessmentSimilarBusiness?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentSimilarBusiness: {
                    ...data.investmentAssessmentSimilarBusiness,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
          <NothingChecked
            defaultValue={data.investmentAssessmentSimilarBusiness?.isChecked}
            onChangeData={(checked) => {
              if (
                onChangeData &&
                data.investmentAssessmentSimilarBusiness !== undefined
              ) {
                updateIASimilarBusinessCheck(checked);
                data.investmentAssessmentSimilarBusiness.isChecked = checked;
                onChangeData(data);
              }
            }}
          />
        </FlexColumn>
      </Sector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--gray-700);
        stroke: var(--gray-700);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--gray-700);
        }
      }
    }
  }
`;
