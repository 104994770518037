import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BubbleLabel } from "../../../../components-2.0v/label/BubbleLabel";

import { ReactComponent as Reply } from "../../../../assets/image/icon_reply.svg";
import { ReactComponent as More } from "../../../../assets/image/icon_more.svg";
import { ReactComponent as Link } from "../../../../assets/image/icon_arrow_top_right.svg";
import { ExcavationPortfolioProps } from "../../interface/Excavation.interface";
import { getYMDKo } from "../../../../common/commonUtil";
import { PortfolioInfoProps } from "../../../portfolio/info/interface/PortfolioInfo.interface";

interface Props {
  id?: string;
  portfolio: ExcavationPortfolioProps;
  isSelected: boolean;
  isShowDetail?: boolean;
  portfolioInfo?: PortfolioInfoProps;
  onClickCard?: () => void;
  onClickOptionClick: () => void;
  onClickRegisteParticipant: () => void;
  onClickOpenIRProposal: () => void;
  onClickDrop: () => void;
}
export const ExcavationPortfolioCard: React.FC<Props> = (props) => {
  const {
    id,
    portfolio,
    isSelected,
    isShowDetail,
    portfolioInfo,
    onClickCard,
    onClickOptionClick,
    onClickRegisteParticipant,
    onClickOpenIRProposal,
    onClickDrop,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const dotRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const [showList, updateShowList] = useState<boolean>(false);
  const getIndustrialTag = () => {
    const industrialTech = portfolio.pfIndustrialTech
      ?.map((item) => "#" + item.acIndustrialTech.name)
      .join(" ");
    return (
      <IndustrialTech className="caption-12-rg">
        {industrialTech}
      </IndustrialTech>
    );
  };

  const handleMouseDown = (event: any) => {
    if (
      optionRef.current &&
      dotRef.current &&
      !optionRef.current.contains(event.target) &&
      !dotRef.current.contains(event.target)
    ) {
      updateShowList(false);
    }
  };

  const rectPositoin = () => {
    const root = window.document.querySelector("#body__component");

    if (dotRef.current && root) {
      const targetRect = dotRef.current.getBoundingClientRect();
      if (optionRef.current) {
        optionRef.current.style.top = `${
          targetRect.top + targetRect.height + 10
        }px`;
        optionRef.current.style.left = `${targetRect.left}px`;
      }
    }
  };
  const handleScroll = (e: any) => {
    updateShowList(false);
  };

  useEffect(() => {
    const root = window.document.querySelector("#body__component");

    window.addEventListener("mousedown", handleMouseDown);
    root && root.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      root && root.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showList) {
      rectPositoin();
    }
  }, [showList]);
  return (
    <Container
      id={id}
      className={`${isShowDetail ? "is__show__Detail" : ""} ${
        isSelected ? "is__selected" : ""
      }`}
      ref={containerRef}
      onClick={onClickCard}
    >
      {getIndustrialTag()}
      <PortfolioName className="heading-18-sb">
        {portfolio.companyName}
      </PortfolioName>

      <Description className="caption-12-rg">
        {portfolio.description}
      </Description>

      <FlexRow style={{ marginTop: 6, gap: 6 }}>
        <BubbleLabel>{portfolio.acChannel.name}</BubbleLabel>
      </FlexRow>

      <FlexRow
        style={{
          width: "100%",
          marginTop: 24,
          justifyContent: "space-between",
        }}
      >
        <span className="caption-12-rg" style={{ color: "var(--text-06)" }}>
          {getYMDKo(portfolio.createdAt)}
        </span>
        <FlexRow style={{ alignItems: "center", gap: 4 }}>
          <Reply />
          <span className="caption-12-rg" style={{ color: "var(--text-06)" }}>
            {portfolio.pfComment.length}
          </span>
        </FlexRow>
      </FlexRow>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 24px 24px 24px 16px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  cursor: pointer;

  &.is__show__Detail {
    height: 106px;
    min-height: 106px;
    max-height: 106px;
  }

  &.is__selected {
    border: 1px solid var(--focus-field-blue, #a2c7ff);
    background-color: var(--blue-50);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
const IndustrialTech = styled.span`
  width: calc(100% - 20px);
  color: var(--primary-blue);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const PortfolioName = styled.div`
  width: 100%;
  margin-top: 6px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  width: 100%;
  min-height: 40px;
  margin-top: 12px;
  color: var(--text-05);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const MoreWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;

  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;

  border-radius: 4px;
  transition: all 0.2 ease;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: var(--hover-200);
  }
`;

const ListContainer = styled.div`
  position: fixed;
  width: 164px;
  max-height: 236px;
  display: flex;
  flex-direction: column;

  z-index: 2;

  padding: 8px 0px;

  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  background-color: var(--background-contents02);
  overflow: scroll;
`;

const ListItem = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 9px 16px;

  cursor: pointer;

  &.selected {
    background-color: var(--blue-100);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
