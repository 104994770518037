import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CapitalAcquisitionPlan } from "../../../../interface/SBP.interface";
import { TableGrid, TableGridHeaderProps } from "../grid/TableGrid";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  quarter?: number;
  capitalDataList: CapitalAcquisitionPlan[];
}

export const CapitalAcquisitionPlanComponent: React.FC<Props> = (props) => {
  const headers: TableGridHeaderProps[] = [
    {
      header: "자금 조달 방법",
      key: "solutionCapitalAcquisition",
      width: 228 * (764 / 1200),
    },
    {
      header: "시기 (분기)",
      key: "capitalRaiseDate",
      width: (228 - 100) * (764 / 1200),
    },
    {
      header: "금액 (원)",
      key: "capitalAmount",
      type: "number",
      aligin: "end",
      width: 178 * (764 / 1200),
    },
    {
      header: "내용",
      key: "contents",
      width: (492 + 120) * (764 / 1200),
    },
  ];

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={
          props.capitalDataList.map((item, index) => {
            return {
              id: index,
              identificationNumber: index,
              data: item,
            };
          }) || []
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;
