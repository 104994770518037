import React from "react";
import styled from "styled-components";
import { ReactComponent as DefaultUser } from "../../../../../assets/image/default_user_img.svg";
import { ResultUserInfo } from "../../interface/PrePitchingDetail.interface";
import ColumnTitleBox from "../../../../../components/box/ColumnTitleBox";
interface Props {
  type: "long" | "short";
  textAnswer: {
    acUser: ResultUserInfo;
    text: string;
  };
  userInfoHidden?: boolean;
}
export const TextListRow: React.FC<Props> = (props) => {
  const { type, textAnswer, userInfoHidden } = props;
  return (
    <Container className="body-14-rg">
      {!userInfoHidden && (
        <UserContainer>
          <Contents>
            <Avatar>
              <AvatarContainer>
                {textAnswer.acUser.profileImgUrl ? (
                  <img
                    className="avatar"
                    src={textAnswer.acUser.profileImgUrl}
                    loading="lazy"
                    alt={"profile img"}
                  />
                ) : (
                  <DefaultUser className="avatar" />
                )}
              </AvatarContainer>
              <span>{textAnswer.acUser.name}</span>
            </Avatar>
          </Contents>
        </UserContainer>
      )}
      <ResultContainer>
        <Contents style={{ paddingBottom: 21 }}>
          <ColumnTitleBox title={"의견"}>
            {type === "long" ? (
              <pre
                className="body-14-rg"
                style={{
                  height: "fit-contents",
                  whiteSpace: "break-spaces",
                  wordBreak: "break-all",
                }}
              >
                {textAnswer.text}
              </pre>
            ) : (
              <div className="body-14-rg">{textAnswer.text}</div>
            )}
          </ColumnTitleBox>
        </Contents>
      </ResultContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  color: var(--text-01);
  border-bottom: 1px solid var(--gray-400);
  padding: 10px 0px;
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const Avatar = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  height: 38px;
  min-height: 38px;
  max-height: 38px;

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    border: 1px solid var(--gray-200);
    border-radius: 50%;
  }
`;

const UserContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-left: 10px;
`;

const ResultContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

const FlexColum = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
