import React from "react";
import styled from "styled-components";

import { ReactComponent as SortArrow } from "../../../../../assets/image/sort_arrow.svg";

interface Props {
  title: string;
  sortable?: boolean;
  sortType?: "asc" | "desc";
  transfromPixel: (value: number) => number;
  onClick?: () => void;
}

const CustomHeader: React.FC<Props> = (props) => {
  const { title, sortable = false, sortType, transfromPixel, onClick } = props;

  return (
    <Container
      className="body-14-rg"
      style={{
        fontSize: transfromPixel(12),
        padding: `0 ${transfromPixel(20)}px`,
        gap: transfromPixel(2),
        cursor: sortable ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      {title}
      {sortable && (
        <SortArrowGroup>
          <SortArrow className={sortType === "asc" ? "on" : ""} />
          <SortArrow
            className={sortType === "desc" ? "on" : ""}
            style={{ rotate: "180deg" }}
          />
        </SortArrowGroup>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: #96999f; //text05
  display: flex;
  flex-direction: row;
  align-items: center;

  .header__sorting {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    transition: all 0.2s ease;
    cursor: pointer;

    &.selected__sorting {
      path {
        fill: #4584ff;
      }
    }

    &.sorting__option__show {
      path {
        fill: #4584ff;
      }
    }

    :hover {
      path {
        fill: #4584ff;
      }
    }
  }
`;

const SortArrowGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  svg.on {
    path {
      fill: var(--grey_50);
    }
  }
`;

export default CustomHeader;
