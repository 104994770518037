import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";

import { IssueFileProps } from "../../../../interface/RMED.interface";
import { CustomFile } from "../../table/custom-cell/CustomFile";

interface Props {
  portfolioId: number;
  isReadOnly?: boolean;
  disabled?: boolean;
  riskIssueFile?: IssueFileProps[];
  onDataChange: (data: IssueFileProps[]) => void;
}

export const ClosedCompanyFile: React.FC<Props> = (props) => {
  const [riskIssueFile, updateRiskIssueFile] = useState<
    IssueFileProps[] | undefined
  >(undefined);
  const headers: TableGridHeaderProps[] = [
    {
      header: "증빙자료(PDF 파일만 업로드)",
      key: "file",
      width: 1126,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomFile
            portfolioId={props.portfolioId}
            disabled={disabled}
            isReadOnly={isReadOnly}
            defaultData={data}
            onChangeData={(file) => {
              if (!riskIssueFile) return;
              allData.file = file;
              updateRiskIssueFile([...riskIssueFile]);
            }}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newDate: IssueFileProps = {};
    const newfundData: IssueFileProps[] = [...(riskIssueFile || []), newDate];
    updateRiskIssueFile(newfundData);
  };

  const onClickDeleteBtn = (id: number) => {
    if (!riskIssueFile) return;
    const newfundData = riskIssueFile.filter((item, index) => index !== id);
    updateRiskIssueFile([...newfundData]);
  };

  useEffect(() => {
    console.log(props.riskIssueFile);
    updateRiskIssueFile(props.riskIssueFile);
  }, []);

  useEffect(() => {
    if (riskIssueFile) props.onDataChange([...riskIssueFile]);
  }, [riskIssueFile]);

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={riskIssueFile?.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;
