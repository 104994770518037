import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { SBPContentsPropsV2 } from "../../../interface/SBP.interface";

import RowTitleBox from "../../RowTitleBox";
import { AutoResizableTextarea } from "./AutoResizableTextarea";
import { AddComma } from "../../../../../../../../../../common/commonUtil";

interface Props {
  index: number;
  sbpDocument: SBPContentsPropsV2;
  onLoaded: (index: number) => void;
}

export const Plan3: React.FC<Props> = (props) => {
  const loadedRef = useRef<boolean[]>([]);
  const onLoaded = () => {
    loadedRef.current.push(true);
    console.log(`call plan: ${props.index}`);
    if (loadedRef.current.length === 2) {
      props.onLoaded(props.index);
      loadedRef.current = [];
    }
  };

  const getYear = (year?: number) => {
    if (!year) return "YYYY";
    return `${year}`;
  };
  const getMonth = (quater?: number) => {
    if (!quater) return "Error";
    return quater === 2 ? "6" : "12";
  };

  const getTotalCapitalAmount = () => {
    return props.sbpDocument.businessPlan.capitalAcquisitionPlan
      .map((item) => item.capitalAmount || 0)
      .reduce((prev, cur) => prev + cur, 0);
  };

  const getCalculateCashFlow = () => {
    const cashFlow = props.sbpDocument.businessPlan.estimatedCashFlow;
    const a = cashFlow.govBalance || 0;
    const b = cashFlow.cashOnHand || 0;
    const c = cashFlow.expectedCashInflow || 0;
    const d = getTotalCapitalAmount();
    const e = cashFlow.estimatedGovFundingUsage || 0;
    const f = cashFlow.totalExpectedExpense || 0;
    return a + b + c + d - e - f;
  };

  return (
    <FlexColumn style={{ gap: 8 }}>
      <div className="body-16-md" style={{ color: "var(--text-03)" }}>
        0{props.index}. 현금 흐름 추정
      </div>
      <FlexColumn
        style={{
          padding: 14,
          borderRadius: 4,
          backgroundColor: "var(--background-contents01)",
          border: "1px solid var(--gray-300)",
        }}
      >
        <RowTitleBox
          textClassName="caption-12-md"
          title={`${getYear(
            props.sbpDocument.quarter === 2
              ? props.sbpDocument.year
              : props.sbpDocument.year - 1
          )}년 ${getMonth(props.sbpDocument.quarter)}월말 정부지원금 잔액`}
          titleWidth={172}
          transfromPixel={(n) => n}
        >
          <span
            className="caption-12-rg"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 9,
            }}
          >
            {AddComma(
              props.sbpDocument.businessPlan.estimatedCashFlow.govBalance
            )}{" "}
            원
          </span>
        </RowTitleBox>

        <RowTitleBox
          textClassName="caption-12-md"
          title={`${getYear(
            props.sbpDocument.quarter === 2
              ? props.sbpDocument.year
              : props.sbpDocument.year - 1
          )}년 ${getMonth(props.sbpDocument.quarter)}월말 법인자금 잔액`}
          titleWidth={172}
          transfromPixel={(n) => n}
        >
          <span
            className="caption-12-rg"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 9,
            }}
          >
            {AddComma(
              props.sbpDocument.businessPlan.estimatedCashFlow.cashOnHand
            )}{" "}
            원
          </span>
        </RowTitleBox>

        <RowTitleBox
          textClassName="caption-12-md"
          title="예상 매출액"
          titleWidth={172}
          transfromPixel={(n) => n}
        >
          <span
            className="caption-12-rg"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 9,
            }}
          >
            {AddComma(
              props.sbpDocument.businessPlan.estimatedCashFlow
                .expectedCashInflow
            )}{" "}
            원
          </span>
        </RowTitleBox>
        <FlexRow style={{ width: "100%" }}>
          <RowTitleBox
            textClassName="caption-12-md"
            title="예상 매출액 산출 근거"
            titleWidth={172}
            style={{ width: "100%" }}
            transfromPixel={(n) => n}
          >
            <AutoResizableTextarea
              isReadOnly
              textClassName="caption-12-rg"
              placeholder="입력한 내용이 없습니다."
              defaultData={
                props.sbpDocument.businessPlan.estimatedCashFlow
                  .reasonExpectedCashInflow
              }
              onLoaded={onLoaded}
            />
          </RowTitleBox>
        </FlexRow>
        <RowTitleBox
          textClassName="caption-12-md"
          title="예상 자금 조달 총액"
          titleWidth={172}
          transfromPixel={(n) => n}
        >
          <span
            className="caption-12-rg"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 9,
            }}
          >
            {AddComma(getTotalCapitalAmount())} 원
          </span>
        </RowTitleBox>

        <RowTitleBox
          textClassName="caption-12-md"
          title="정부지원금 지출 예정액"
          titleWidth={172}
          transfromPixel={(n) => n}
        >
          <span
            className="caption-12-rg"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 9,
            }}
          >
            {AddComma(
              props.sbpDocument.businessPlan.estimatedCashFlow
                .estimatedGovFundingUsage
            )}{" "}
            원
          </span>
        </RowTitleBox>

        <RowTitleBox
          textClassName="caption-12-md"
          title="법인자금 지출 예정액"
          titleWidth={172}
          transfromPixel={(n) => n}
        >
          <span
            className="caption-12-rg"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 9,
            }}
          >
            {AddComma(
              props.sbpDocument.businessPlan.estimatedCashFlow
                .totalExpectedExpense
            )}{" "}
            원
          </span>
        </RowTitleBox>

        <FlexRow>
          <RowTitleBox
            textClassName="caption-12-md"
            title="기타 매출 관련 이슈사항"
            titleWidth={172}
            style={{ width: "100%" }}
            transfromPixel={(n) => n}
          >
            <AutoResizableTextarea
              isReadOnly
              textClassName="caption-12-rg"
              placeholder="입력한 내용이 없습니다."
              defaultData={
                props.sbpDocument.businessPlan.estimatedCashFlow.salesIssue
              }
              onLoaded={onLoaded}
            />
          </RowTitleBox>
        </FlexRow>
      </FlexColumn>
      <FlexRow
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 12,
        }}
      >
        <span className="caption-12-md" style={{ color: "var(--text-04)" }}>
          예상 기말 현금
        </span>
        <span className="caption-12-md" style={{ color: "var(--text-01)" }}>
          {`${AddComma(getCalculateCashFlow())} 원`}
        </span>
      </FlexRow>
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
