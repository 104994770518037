import { FileType } from "../../../type/data";

export type ExcavationPortfolioCreateValueType =
  | "companyName"
  | "description"
  | "ceoName"
  | "ceoTel"
  | "ceoEmail"
  | "acChannel"
  | "excavator"
  | "businessPlanFile"
  | "problem"
  | "solution"
  | "businessModel"
  | "team"
  | "other"
  | "excavationDate"
  | "mainPfIndustrialTech"
  | "subPfIndustrialTech";

export const DefaultExtraInfo = [
  { key: "businessModel", contents: "" },
  { key: "team", contents: "" },
  { key: "other", contents: "" },
];

export interface ExcavationPortfolioChangeDataProps {
  type: ExcavationPortfolioCreateValueType;
  value: any;
}

export interface ExcavationPortfolioCreateProps {
  portfolio?: EPInfoProps;
  pfIndustrialTech?: {
    isMain: boolean;
    acIndustrialTech: {
      acIndustrialTechId: number;
    };
  }[];
  pfExtraInfo?: {
    content: {
      key: string;
      contents?: string;
    }[];
  };
}

export interface EPInfoProps {
  companyName?: string;
  description?: string;
  ceoName?: string;
  ceoTell?: string;
  ceoEmail?: string;
  acChannel?: {
    acChannelId: number;
  };
  excavator?: {
    acUserId: number;
  };
  excavationDate?: string;
  businessPlanFile?: FileType | any;
  problem?: string;
  solution?: string;
}
