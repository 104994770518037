import React from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import styled from "styled-components";
import { ChannelAdminProps } from "../interface/Channel.interface";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";

interface Props {
  item: ChannelAdminProps;
  index: number;
  isActive: boolean;
  onClickItem: (acChannelId: string) => void;
}

export const ChannelItem: React.FC<Props> = (props) => {
  const getBgColor = () =>
    props.isActive ? "var(--green-500)" : "var(--gray-700)";
  return (
    <Draggable draggableId={`${props.item.acChannelId}`} index={props.index}>
      {(provided: DraggableProvided) => {
        return (
          <Container
            className="body-16-sb"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => props.onClickItem(`${props.item.acChannelId}`)}
          >
            <CustomColorLabel
              className="caption-10-rg"
              bgColor={getBgColor()}
              textColor={"var(--white)"}
            >
              {props.isActive ? "사용중" : "사용 중지"}
            </CustomColorLabel>
            <span className="heading-18-sb industrial__tech__name">
              {props.item.name}
            </span>
          </Container>
        );
      }}
    </Draggable>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);

  border-radius: 4px;

  cursor: pointer;

  .industrial__tech__name {
    margin-top: 8px;
  }
`;
