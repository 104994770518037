import React from "react";
import styled from "styled-components";

import { BoxComponent } from "../../../../components-2.0v/box/BoxComponent";
import { ExcavationPortfolioProps } from "../../interface/Excavation.interface";
import TitleQuote from "../components/TitleQuote";

import { getMyInfo, getYMDKo } from "../../../../common/commonUtil";
import { PortfolioInfoProps } from "../../../portfolio/info/interface/PortfolioInfo.interface";

import RowTitleBox from "../../../../components/box/RowTitleBox";
import { BusinessDetail } from "./BusinessDetail";
import { BusinessComment } from "../../../portfolio/info/desk/organism/BusinessComment";

interface Props {
  portfolio: ExcavationPortfolioProps;
  portfolioInfo: PortfolioInfoProps;
  sendComment: (data: string, callback: () => void) => void;
  updateComment: (pfCommentId: number, data: string) => void;
  deleteComment: (pfCommentId: number) => void;
  updateCommentId?: number;
  setUpdateCommentId?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ExcavationPortfolioDetail: React.FC<Props> = (props) => {
  const {
    portfolio,
    portfolioInfo,
    sendComment,
    updateComment,
    deleteComment,
    updateCommentId,
    setUpdateCommentId,
  } = props;
  const myInfo = getMyInfo();

  const getIndustrialTag = () => {
    const industrialTech = portfolio.pfIndustrialTech
      ?.map((item) => "#" + item.acIndustrialTech.name)
      .join(" ");
    return (
      <IndustrialTech className="caption-12-rg">
        {industrialTech}
      </IndustrialTech>
    );
  };

  if (!portfolioInfo) return null;

  return (
    <Container>
      <BoxComponent style={{ gap: 4 }}>
        {getIndustrialTag()}
        <PortfolioName className="heading-24-sb">
          {portfolioInfo.startupInfo.companyName}
        </PortfolioName>
        <Description className="body-16-rg">
          {portfolioInfo.businessInfo.description}
        </Description>
        <FlexColumn style={{ marginTop: 20, gap: 14 }}>
          <div style={{ flex: 1 }}>
            <TitleQuote title="연락 정보">
              <FlexColumn>
                <RowTitleBox
                  title="대표 이사"
                  titleWidth={84}
                  textClassName="heading-14-md"
                >
                  <div
                    className="caption-14-rg"
                    style={{
                      paddingTop: 9,
                      color: portfolioInfo.startupInfo.ceoName
                        ? "var(--text-01)"
                        : "var(--text-05)",
                    }}
                  >
                    {portfolioInfo.startupInfo.ceoName || "입력값이 없어요."}
                  </div>
                </RowTitleBox>
                <RowTitleBox title="전화 번호" titleWidth={84}>
                  <div
                    className="caption-14-rg"
                    style={{
                      paddingTop: 9,
                      color: portfolioInfo.startupInfo.ceoTel
                        ? "var(--text-01)"
                        : "var(--text-05)",
                    }}
                  >
                    {portfolioInfo.startupInfo.ceoTel || "입력값이 없어요."}
                  </div>
                </RowTitleBox>
                <RowTitleBox title="이메일" titleWidth={84}>
                  <div
                    className="caption-14-rg"
                    style={{
                      paddingTop: 9,
                      color: portfolioInfo.startupInfo.ceoEmail
                        ? "var(--text-01)"
                        : "var(--text-05)",
                    }}
                  >
                    {portfolioInfo.startupInfo.ceoEmail || "입력값이 없어요."}
                  </div>
                </RowTitleBox>
              </FlexColumn>
            </TitleQuote>
          </div>

          <div style={{ flex: 1 }}>
            <TitleQuote title="발굴 정보">
              <FlexColumn>
                <RowTitleBox title="발굴자" titleWidth={84}>
                  <div
                    className="caption-14-rg"
                    style={{
                      paddingTop: 9,
                      color: portfolioInfo.participantInfo.excavator
                        ? "var(--text-01)"
                        : "var(--text-05)",
                    }}
                  >
                    {portfolioInfo.participantInfo.excavator || "미정"}
                  </div>
                </RowTitleBox>
                <RowTitleBox title="발굴 채널" titleWidth={84}>
                  <div
                    className="caption-14-rg"
                    style={{
                      paddingTop: 9,
                      color: portfolio.acChannel
                        ? "var(--text-01)"
                        : "var(--text-05)",
                    }}
                  >
                    {portfolio.acChannel.name || "입력값이 없어요."}
                  </div>
                </RowTitleBox>
                <RowTitleBox title="발굴 일자" titleWidth={84}>
                  <div
                    className="caption-14-rg"
                    style={{
                      paddingTop: 9,
                      color: portfolio.excavationDate
                        ? "var(--text-01)"
                        : "var(--text-05)",
                    }}
                  >
                    {portfolio.excavationDate
                      ? getYMDKo(portfolio.excavationDate)
                      : "입력된 내용이 없습니다."}
                  </div>
                </RowTitleBox>
              </FlexColumn>
            </TitleQuote>
          </div>
        </FlexColumn>
      </BoxComponent>
      <BoxComponent style={{ padding: 0, gap: 0 }}>
        <div
          className="heading-18-sb"
          style={{ paddingTop: 28, paddingLeft: 28, marginBottom: -12 }}
        >
          사업 정보
        </div>
        <BusinessDetail
          businessPlanFile={portfolioInfo.businessInfo.businessPlanFile}
          businessInfo={portfolioInfo.businessInfo}
        />
      </BoxComponent>

      <BoxComponent style={{ padding: 0 }}>
        <div
          className="heading-18-sb"
          style={{ paddingTop: 28, paddingLeft: 28 }}
        >
          코멘트
        </div>
        <BusinessComment
          myInfo={myInfo}
          businessInfo={portfolioInfo.businessInfo}
          sendComment={sendComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
          updateCommentId={updateCommentId}
          setUpdateCommentId={setUpdateCommentId}
        />
      </BoxComponent>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const IndustrialTech = styled.span`
  width: calc(100%);
  color: var(--primary-blue);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const PortfolioName = styled.div`
  width: 100%;
  margin-bottom: 4px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  width: 100%;
  margin-top: 8px;
  color: var(--text-05);
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export default ExcavationPortfolioDetail;
