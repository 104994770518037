import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Close } from "../../../assets/image/modal_close.svg";
import { CapitalRaisingProps } from "../../portfolio-viewer-stock-change-history/interface/type";
import {
  addCommasToIntegerPart,
  getNewDate,
  getYMD,
} from "../../../common/commonUtil";
import RowTitleBox from "../../../components/box/RowTitleBox";
import { StakeHolderType } from "../../../type/data";

interface Props {
  data?: CapitalRaisingProps;
  close: () => void;
  currency: string;
}
export const CapitalRaisingDetail: React.FC<Props> = (props) => {
  const TITLE_WIDTH = 120;
  const { data, close, currency } = props;
  if (!data) return null;
  return (
    <Container>
      <Header>
        <div className="heading-18-sb">
          {getYMD(getNewDate(data.date))} {data.capitalRaisingType?.name}(
          {data.capitalRaisingType?.acronym})
        </div>
        <Close className="close" onClick={close} />
      </Header>
      <Body>
        <ContentsContainer>
          <RowTitleBox titleWidth={TITLE_WIDTH} title="자본조달 금액">
            <div className="row__item body-14-rg">
              {addCommasToIntegerPart(data.amount)} {currency}
            </div>
          </RowTitleBox>

          <RowTitleBox titleWidth={TITLE_WIDTH} title="라운드">
            <div className="row__item body-14-rg">{data.round.name}</div>
          </RowTitleBox>

          {data.capitalRaisingType!.id === 1 && data.discountRate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="할인율">
              <div className="row__item body-14-rg">{data.discountRate} %</div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType!.id === 1 && data.valuationCap && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="기업가치 상한(캡)">
              <div className="row__item body-14-rg">
                {addCommasToIntegerPart(data.valuationCap)} {currency}
              </div>
            </RowTitleBox>
          )}

          {data.capitalRaisingType?.id === 2 && data.interestRate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="표면 이율">
              <div className="row__item body-14-rg">{data.interestRate} %</div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType?.id === 2 &&
            data.maturityGuaranteedYield && (
              <RowTitleBox titleWidth={TITLE_WIDTH} title="만기보장 수익률">
                <div className="row__item body-14-rg">
                  {data.maturityGuaranteedYield} %
                </div>
              </RowTitleBox>
            )}
          {data.capitalRaisingType?.id === 2 && data.issueDate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="전환사채 발행일">
              <div className="row__item body-14-rg">
                {getYMD(getNewDate(data.issueDate))}
              </div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType?.id === 2 && data.expireDate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="전환사채 만기일">
              <div className="row__item body-14-rg">
                {getYMD(getNewDate(data.expireDate))}
              </div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType?.id === 2 && data.changeRate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="전환 비율">
              <div className="row__item body-14-rg">{data.changeRate} %</div>
            </RowTitleBox>
          )}

          {data.capitalRaisingType?.id === 2 && data.strikePrice && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="전환가액">
              <div className="row__item body-14-rg">
                {addCommasToIntegerPart(data.strikePrice)} {currency}
              </div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType?.id === 3 && data.interestRate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="표면 이율">
              <div className="row__item body-14-rg">{data.interestRate} %</div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType?.id === 3 &&
            data.maturityGuaranteedYield && (
              <RowTitleBox titleWidth={TITLE_WIDTH} title="만기보장 수익률">
                <div className="row__item body-14-rg">
                  {data.maturityGuaranteedYield} %
                </div>
              </RowTitleBox>
            )}
          {data.capitalRaisingType?.id === 3 && data.issueDate && (
            <RowTitleBox
              titleWidth={TITLE_WIDTH}
              title="신주인수권부사채 발행일"
            >
              <div className="row__item body-14-rg">
                {getYMD(getNewDate(data.issueDate))}
              </div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType?.id === 3 && data.expireDate && (
            <RowTitleBox
              titleWidth={TITLE_WIDTH}
              title="신주인수권부사채 만기일"
            >
              <div className="row__item body-14-rg">
                {getYMD(getNewDate(data.expireDate))}
              </div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType?.id === 3 && data.changeRate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="행사 비율">
              <div className="row__item body-14-rg">{data.changeRate} %</div>
            </RowTitleBox>
          )}

          {data.capitalRaisingType?.id === 3 && data.strikePrice && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="행사가격">
              <div className="row__item body-14-rg">
                {addCommasToIntegerPart(data.strikePrice)} {currency}
              </div>
            </RowTitleBox>
          )}

          {data.capitalRaisingType?.id === 4 && data.discountRate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="할인율">
              <div className="row__item body-14-rg">{data.discountRate} %</div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType?.id === 4 && data.interestRate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="이자율">
              <div className="row__item body-14-rg">
                {addCommasToIntegerPart(data.interestRate)}
              </div>
            </RowTitleBox>
          )}

          {data.capitalRaisingType?.id === 4 && data.interestDate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="이자지급일">
              <div className="row__item body-14-rg">
                {getYMD(getNewDate(data.interestDate))}
              </div>
            </RowTitleBox>
          )}

          {data.capitalRaisingType?.id === 5 && data.investOptionAmount && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="투자옵션금액">
              <div className="row__item body-14-rg">
                {addCommasToIntegerPart(data.investOptionAmount)} {currency}
              </div>
            </RowTitleBox>
          )}
          {data.capitalRaisingType?.id === 5 && data.investOptionDate && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="투자옵션만료일">
              <div className="row__item body-14-rg">
                {getYMD(getNewDate(data.investOptionDate))}
              </div>
            </RowTitleBox>
          )}
          <RowTitleBox titleWidth={TITLE_WIDTH} title="투자자">
            <div className="row__item body-14-rg">
              {data.pfStakeholder!.name}
            </div>
          </RowTitleBox>
          <RowTitleBox titleWidth={TITLE_WIDTH} title="관계">
            <div className="row__item body-14-rg">
              {data.pfStakeholder!.relationship.name}
            </div>
          </RowTitleBox>
          <RowTitleBox titleWidth={TITLE_WIDTH} title="국적">
            <div className="row__item body-14-rg">
              {data.pfStakeholder!.stakeHolder.country!.name}
            </div>
          </RowTitleBox>
          {data.pfStakeholder!.stakeHolder.type ===
            StakeHolderType.INDIVIDUAL && (
            <FlexColumn>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="이메일">
                <div className="row__item body-14-rg">
                  {data.pfStakeholder!.email || "-"}
                </div>
              </RowTitleBox>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="연락처">
                <div className="row__item body-14-rg">
                  {data.pfStakeholder!.phone || "-"}
                </div>
              </RowTitleBox>
            </FlexColumn>
          )}
          {[StakeHolderType.CORPORATION, StakeHolderType.FUND].includes(
            data.pfStakeholder!.stakeHolder.type
          ) && (
            <FlexColumn>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="담당자 이름">
                <div className="row__item body-14-rg">
                  {data.pfStakeholder!.managerName || "-"}
                </div>
              </RowTitleBox>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="담당자 이메일">
                <div className="row__item body-14-rg">
                  {data.pfStakeholder!.managerEmail || "-"}
                </div>
              </RowTitleBox>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="담당자 연락처">
                <div className="row__item body-14-rg">
                  {data.pfStakeholder!.managerPhone || "-"}
                </div>
              </RowTitleBox>
              {data.pfStakeholder!.stakeHolder.type ===
                StakeHolderType.FUND && (
                <FlexColumn>
                  <RowTitleBox titleWidth={TITLE_WIDTH} title="GP">
                    <div className="row__item body-14-rg">
                      {data.pfStakeholder?.stakeHolder.gp?.name || "-"}
                    </div>
                  </RowTitleBox>
                </FlexColumn>
              )}
            </FlexColumn>
          )}
        </ContentsContainer>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  border-bottom: 1px solid var(--gray-300);

  .close {
    cursor: pointer;
  }
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  overflow: auto;

  .row__item {
    margin-top: 9px;
  }
`;

const ContentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  padding: 24px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;
