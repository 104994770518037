import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { DefaultButton } from "lattice_core";

import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";
import { OnlyNum, getNewDate } from "../../../../../common/commonUtil";
import { DateRangeProps } from "./SelectRange";

interface Props {
  isOnlyYear?: boolean;
  defaultValue?: DateRangeProps;
  onChangeRange: (data: DateRangeProps) => void;
  close: () => void;
}
export const RangeQuaterModal: React.FC<Props> = (props) => {
  const { isOnlyYear, defaultValue, close, onChangeRange } = props;
  const MIN_YEAR = 2014;
  const MAX_YEAR = getNewDate().getFullYear();

  const [startYear, updateStartYear] = useState<string>("");
  const [startQuarter, updateStartQuarter] = useState<string>("");
  const [endYear, updateEndYear] = useState<string>("");
  const [endQuarter, updateEndQuarter] = useState<string>("");

  const onChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = OnlyNum(e.target.value);
    return v.slice(0, isOnlyYear ? 4 : 2);
  };
  const onBlurYear = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const minYear = isOnlyYear ? MIN_YEAR : MIN_YEAR % 2000;
    const maxYear = isOnlyYear ? MAX_YEAR : MAX_YEAR % 2000;
    const v = OnlyNum(e.target.value);
    if (parseInt(v) >= minYear && parseInt(v) <= maxYear) {
      return v;
    } else if (parseInt(v) < minYear) {
      return `${minYear}`;
    } else {
      return `${maxYear}`;
    }
  };

  const onChangeQuarter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = OnlyNum(e.target.value);
    return v.slice(0, 1);
  };
  const onBlurQuarter = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const minQuarter = 1;
    const maxQuarter = 4;
    const v = OnlyNum(e.target.value);
    if (parseInt(v) >= minQuarter && parseInt(v) <= maxQuarter) {
      return v;
    } else if (parseInt(v) < minQuarter) {
      return `${minQuarter}`;
    } else {
      return `${maxQuarter}`;
    }
  };
  useEffect(() => {
    if (defaultValue) {
      updateStartYear(
        isOnlyYear ? defaultValue.startYear : defaultValue.startYear.slice(2)
      );
      updateEndYear(
        isOnlyYear ? defaultValue.endYear : defaultValue.endYear.slice(2)
      );
      updateStartQuarter(isOnlyYear ? "1" : defaultValue.startQuarter);
      updateEndQuarter(isOnlyYear ? "4" : defaultValue.endQuarter);
    }
  }, [defaultValue]);
  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          기간 선택
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body>
        <div className="caption-12-rg" style={{ color: "var(--text-03)" }}>
          투자 성과를 보고 싶은 연도를 입력해 주세요.
          <br />
          <span style={{ color: "var(--blue-400)" }}>
            창립연도(2014년)부터 현재연도
          </span>
          까지 입력할 수 있어요.
        </div>
        {isOnlyYear ? (
          <DateRangeContainer>
            <InputBox
              style={{ width: 100, height: 42, paddingLeft: 12 }}
              value={startYear}
              onChange={(e) => {
                updateStartYear(onChangeYear(e));
              }}
              onBlur={(e) => {
                updateStartYear(onBlurYear(e));
              }}
            />
            <span>~</span>
            <InputBox
              style={{ width: 100, height: 42, paddingLeft: 12 }}
              value={endYear}
              onChange={(e) => {
                updateEndYear(onChangeYear(e));
              }}
              onBlur={(e) => {
                updateEndYear(onBlurYear(e));
              }}
            />
          </DateRangeContainer>
        ) : (
          <DateRangeContainer
            className="body-14-rg"
            style={{ color: "var(--text-05)" }}
          >
            <InputWrapperBox>
              <InputBox
                style={{ width: 30, height: 22, paddingLeft: 6 }}
                value={startYear}
                onChange={(e) => {
                  updateStartYear(onChangeYear(e));
                }}
                onBlur={(e) => {
                  updateStartYear(onBlurYear(e));
                }}
              />
              <span>년</span>
              <InputBox
                style={{ width: 30, height: 22, marginLeft: 2, paddingLeft: 6 }}
                value={startQuarter}
                onChange={(e) => {
                  updateStartQuarter(onChangeQuarter(e));
                }}
                onBlur={(e) => {
                  updateStartQuarter(onBlurQuarter(e));
                }}
              />
              <span>분기</span>
            </InputWrapperBox>
            <span>~</span>
            <InputWrapperBox>
              <InputBox
                style={{ width: 30, height: 22, paddingLeft: 6 }}
                value={endYear}
                onChange={(e) => {
                  updateEndYear(onChangeYear(e));
                }}
                onBlur={(e) => {
                  updateEndYear(onBlurYear(e));
                }}
              />
              <span>년</span>
              <InputBox
                style={{ width: 30, height: 22, marginLeft: 2, paddingLeft: 6 }}
                value={endQuarter}
                onChange={(e) => {
                  updateEndQuarter(onChangeQuarter(e));
                }}
                onBlur={(e) => {
                  updateEndQuarter(onBlurQuarter(e));
                }}
              />
              <span>분기</span>
            </InputWrapperBox>
          </DateRangeContainer>
        )}
      </Body>
      <FooterContainer>
        <div>
          <DefaultButton
            className="heading-14-md"
            sizeType="xsmall"
            text="완료"
            buttonColor="var(--primary-blue)"
            hoverColor="var(--blue-600)"
            onClick={() => {
              const data: DateRangeProps = {
                startYear:
                  startYear.length === 2 ? `20` + startYear : startYear,
                startQuarter: startQuarter,
                endYear: endYear.length === 2 ? `20` + endYear : endYear,
                endQuarter: endQuarter,
              };
              onChangeRange(data);
              close();
            }}
          />
        </div>
      </FooterContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 90vw;
  height: 242px;
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 44px;
  min-height: 44px;
  max-height: 44px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`;

const FooterContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
`;

const DateRangeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 16px;
`;

const InputWrapperBox = styled.div`
  width: 156px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  background: var(--background-contents01, #fff);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 12px;
  gap: 4px;
`;

const InputBox = styled.input`
  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  background: var(--background-contents01, #fff);
  transition: all 0.2s ease;
  :focus {
    outline: none;
    border: 1px solid var(--focus-field-blue, #a2c7ff);
  }
`;
