import axios from "axios";
import { Cookies } from "react-cookie";
import { getNewDate } from "../common/commonUtil";

import { GoogleToken, PortfolioMenuType } from "../type/data";
import { Logout } from "../router/login/desktop/DeskLogin";
import config from "../config";

//Router
const USER = "/user";
const AUTH = "/auth";
const PORTFOLIO = "/portfolio";
const ACCELERATOR = "/accelerator";
const COMMON = "/common";
const DASHBOARD = "/dashboard";
const REPORT = "/report";
const DATABASE = "/accelerator/portfolio";
const RISK = "/risk";

const TEMP_STORE = `${COMMON}/tempStore`;
const FILE_UP_LOAD = `${COMMON}/file`;
const FILE_DELETE = `${COMMON}/file`;
const IMAGE_UPLOAD_AND_GET_URL = `${COMMON}/uploadAndGetUrl`;
const GOOGLE_DOCS_DOWNLOAD = `${COMMON}/google/docs`;

const ACCELERATOR_ADMIN = `${ACCELERATOR}/administrator`;

const AUTH_LOGOUT = `${AUTH}/logout`;
const AUTH_LOGIN_ACUSER = `${AUTH}/login/ac-user`;

const AC_STRUCTURE = `${ACCELERATOR}/structure`;
const AC_INDUSTRIAL_TECH = `${ACCELERATOR}/industrialTech`;
const AC_USERS = `${ACCELERATOR}/acUser`;

const AC_CHANNEL = `${ACCELERATOR}/acChannel`;
const AC_FUND_ACCOUNT = `${ACCELERATOR}/fundAccount`;

const AC_ADMIN_STRUCTURE = `${ACCELERATOR_ADMIN}/structure`;
const AC_ADMIN_INDUSTRIAL_TECH = `${ACCELERATOR_ADMIN}/industrialTech`;
const AC_ADMIN_FUND_ACCOUN = `${ACCELERATOR_ADMIN}/fundAccount`;
const AC_ADMIN_RESPONSIBILITY = `${ACCELERATOR_ADMIN}/responsibility`;
const AC_ADMIN_POSITION = `${ACCELERATOR_ADMIN}/position`;

const AC_ADMIN_LOGIN = `${AUTH}/login/masterUser`;

const AC_STRUCTURE_ADMIN = `${ACCELERATOR}/administrator/structure`;
const AC_INDUSTRIAL_TECH_ADMIN = `${ACCELERATOR}/administrator/industrialTech`;
const AC_USERS_ADMIN = `${ACCELERATOR}/administrator/acUser`;

const AC_CHANNEL_ADMIN = `${ACCELERATOR}/administrator/acChannel`;
const AC_FUND_ACCOUNT_ADMIN = `${ACCELERATOR}/administrator/fundAccount`;

const CHAT = `${REPORT}/chat`;

export const interceptor = () => {
  axios.interceptors.request.use(async (config) => {
    config.withCredentials = config.url?.includes(
      "https://api.odcloud.kr/api/nts-businessman"
    )
      ? false
      : true;
    return config;
  });
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {
        config,
        response: { status },
      } = error;

      if (status === 401) {
        const cookie = new Cookies();
        console.log(cookie.get("sessing"));
        if (
          cookie.get("sessing") &&
          error.response.data.message === "notVerifiedSessionInfo"
        ) {
          alert(
            "한 시간동안 반응이 없어 세션이 만료되었습니다. 다시 로그인 해주시기 바랍니다."
          );
          cookie.remove("sessing", {
            path: "/",
          });
          Logout();
        } else if (cookie.get("sessing") === undefined) {
          Logout();
        }
      }
      return Promise.reject(error);
    }
  );
};

export const hasRefreshTokenAndNotExpired = () => {
  const rToken = localStorage.getItem("refreshToken");
  const rTokenExpiredDate = localStorage.getItem("refreshTokenExpired");

  if (rToken && rTokenExpiredDate) {
    const expireDate = JSON.parse(rTokenExpiredDate).expireDate;
    const now = getNewDate().getTime();
    console.log(`refreshToken expired ${expireDate > now}`);
    return expireDate > now;
  }

  return false;
};

export const removeAccesTokenToken = () => {
  localStorage.removeItem("accessTokenInfo");
  localStorage.removeItem("accessToken");
};

export const removeAllLoginToken = () => {
  localStorage.removeItem("accessTokenInfo");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("refreshTokenExpired");
};

export const saveToken = (token: any) => {
  const accessTokenInfo = JSON.stringify({
    expireDate: getNewDate().getTime() + 50 * 60 * 1000,
  });
  localStorage.setItem("accessTokenInfo", accessTokenInfo);
  localStorage.setItem("accessToken", token.tokenInfo.accessToken);
  if (token.tokenInfo.refreshToken) {
    const refreshTokenExpired = JSON.stringify({
      expireDate: getNewDate().getTime() + 180 * 24 * 60 * 60 * 1000,
    });
    localStorage.setItem("refreshToken", token.tokenInfo.refreshToken);
    localStorage.setItem("refreshTokenExpired", refreshTokenExpired);
  }
};

export const googleLogin = async (
  code: any
): Promise<GoogleToken | undefined> => {
  try {
    const result = await axios.post(`${AUTH}/google`, { code });
    return new Promise((resolve) => {
      resolve(result.data);
    });
  } catch (error) {}
};

export const setInitAxioSetting = () => {
  const domain = config.apiUrl;
  axios.defaults.baseURL = domain;
  console.log(domain);
  interceptor();
};

/**
 *
 * Admin Api Section
 */
export const getAdminAllChannel = (): string => AC_CHANNEL_ADMIN;
export const upsertAdminChannel = (): string => AC_CHANNEL_ADMIN;

//Admin Api Section

export const login = (): string => AUTH_LOGIN_ACUSER;
export const loginLattice = (): string => `/auth/login/general`;
export const acceleratingLogo = (): string => `${ACCELERATOR}/logo`;
export const logout = (): string => AUTH_LOGOUT;

export const updateMyInfo = (): string => `${USER}/acUser/info`;
export const getMyInfo = (): string => `${USER}/myInfo`;

export const getAcStructure = (): string => AC_STRUCTURE;
export const getAcUsers = (): string => AC_USERS;
export const getAcChannel = (): string => AC_CHANNEL;
export const getAcIndustrialTech = (): string => AC_INDUSTRIAL_TECH;

export const getAcFundAccount = (): string => AC_FUND_ACCOUNT;
export const getAcActiveFundAccount = (): string =>
  `${ACCELERATOR}/activeFundaccount`;

export const getResponsibility = (): string => `${ACCELERATOR}/responsibility`;

export const isAccessibleMenu = (
  menuType: PortfolioMenuType,
  portfolioId: number
): string => `${AUTH}/access/${menuType}/portfolio/${portfolioId}`;

export const getDealflowPortfolioList = (): string => `${PORTFOLIO}/dealflow`;
export const getExcavationPortfolioList = (): string =>
  `${PORTFOLIO}/excavation`;
export const getAcceleratingPortfolioList = (): string =>
  `${PORTFOLIO}/accelerating`;
export const getPortfolioTerms = (id: number): string =>
  `${PORTFOLIO}/${id}/terms`;

export const postPortfolioComment = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/comment`;
export const patchPortfolioComment = (pfCommentId: number): string =>
  `${PORTFOLIO}/comment/${pfCommentId}`;
export const deletePortfolioComment = (pfCommentId: number): string =>
  `${PORTFOLIO}/comment/${pfCommentId}`;

export const sendRequireCompanyInfoEmail = (): string =>
  `${PORTFOLIO}/companyInfo`;
export const agreeTerms = (): string => `${PORTFOLIO}/pfTermsAgree`;
export const getPortfolioDetail = (id: number): string =>
  `${PORTFOLIO}/${id}/info`;
export const getPortfolio = (id: number): string => `${PORTFOLIO}/${id}`;
export const getPortfolioFolder = (id: number): string =>
  `${PORTFOLIO}/${id}/folder`;
export const getPortfolioForNavigation = (id: number): string =>
  `${PORTFOLIO}/${id}/navigation`;

export const registePortfolio = (): string => PORTFOLIO;

export const saveChangedPortfolioInfo = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/info`;
export const registePortfolioParticipant = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/participant`;
export const registePortfolioDealflow = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/dealFlow`;

export const getPortfolioParticipant = (id: number) =>
  `${PORTFOLIO}/${id}/participant`;

export const getPitchingList = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/pitching`;
export const getPitchingTemplete = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/preEvaluationTemplate`;
export const createPitching = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/pitching`;
export const moidfyPitching = (): string => `${PORTFOLIO}/preEvaluation`;
export const getPitchingById = (pfPreEvaluationId: number): string =>
  `${PORTFOLIO}/pitching/${pfPreEvaluationId}`;
export const publishPitchingById = (pfPreEvaluationId: number): string =>
  `${PORTFOLIO}/pitching/${pfPreEvaluationId}/publish`;
export const cancelPitchingById = (pfPreEvaluationId: number): string =>
  `${PORTFOLIO}/pitching/${pfPreEvaluationId}/cancel`;
export const finishPitchingById = (pfPreEvaluationId: number): string =>
  `${PORTFOLIO}/pitching/${pfPreEvaluationId}/finish`;
export const getPitchingResult = (pfPreEvaluationId: number): string =>
  `${PORTFOLIO}/pitching/${pfPreEvaluationId}/result`;

export const relasePreEvaluationResult = (): string =>
  `${PORTFOLIO}/preEvaluation/vote`;

export const getEvaluation = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/evaluation`;

export const doContract = (): string => `${PORTFOLIO}/contract`;

export const rejectContract = (): string => `${PORTFOLIO}/rejectContract`;
export const getBlueCardAndServiceInfo = (): string =>
  `${PORTFOLIO}/bluecardAndServiceInfo`;

export const getSuggestBlueCard = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/bluecardSuggest`;

export const portfolioInfoDuplicateCheck = (): string =>
  `${PORTFOLIO}/duplicateCheck`;

//포트폴리오사에 투자중인 투자계정 불러오기
export const getACFundAccountByPf = (id: number): string =>
  `${PORTFOLIO}/${id}/acFundAccount`;

//** 투자 정보 */
//현재 투자계정 투자정보 가져오기
export const getPortfolioInvestmentInfo = (id: number): string =>
  `${PORTFOLIO}/${id}/investmentInfo`;

//리얼 데이터 가져오기
export const getRealStockTransaction = (id: number): string =>
  `${PORTFOLIO}/${id}/realStockTransaction`;
//예상 데이터 가져오기
export const getExpectationStockTransaction = (id: number): string =>
  `${PORTFOLIO}/${id}/expectationStockTransaction`;
//예상 데이터 등록하기
export const registeExpectationStockTransaction = (): string =>
  `${PORTFOLIO}/expectationStockTransaction`;
//예상 데이터 수정하기
export const updateExpectationStockTransaction = (): string =>
  `${PORTFOLIO}/expectationStockTransaction`;
//예상 데이터 삭제하기
export const deleteExpectationStockTransaction = (id: number): string =>
  `${PORTFOLIO}/expectationStockTransaction/${id}`;

//** 투자 정보 */

export const isExistTempStore = (type: string): string =>
  `${TEMP_STORE}/${type}`;

export const getDocsFile = (): string => GOOGLE_DOCS_DOWNLOAD;
export const uploadFile = (): string => FILE_UP_LOAD;
export const deleteFile = (): string => FILE_DELETE;
export const uploadAndGetUrl = (): string => IMAGE_UPLOAD_AND_GET_URL;

/** 공통 */
export const getTerms = (): string => `${COMMON}/pfTerms`;
export const getDictionary = (): string => `${COMMON}/dictionary`;
export const getNewOldStock = (): string => `${COMMON}/newOldStock`;
export const getFile = (): string => `${COMMON}/file`;
export const getServerTime = (): string => `${COMMON}/serverTime`;
export const getIndustryCode = (): string => `${COMMON}/industryCode`;
export const getStructure = (): string => `${COMMON}/acStructure`;

/** 투자 심사 */
//딜플로우별 투자심사 가져오기
export const getEvaluationListByDealFlow = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/investment`;

// 투자심사 하나 가져오기
export const getEvaluationByAssessmentId = (
  investemtnAssessmentId: number
): string => `${PORTFOLIO}/assessment/${investemtnAssessmentId}`;

//포트폴리오사 정보 입력 코드 검증
export const checkPortfolioInfoAuthCode = (): string =>
  `${PORTFOLIO}/companyInfo/code`;

//투자 심사 확정하기;
export const confirmEvaluation = (investemtnAssessmentId: number) =>
  `${PORTFOLIO}/assessment/${investemtnAssessmentId}/confirm`;
// 투자심사 개최
export const publishEvaluation = (investemtnAssessmentId: number) =>
  `${PORTFOLIO}/assessment/${investemtnAssessmentId}/publish`;
// 투자심사 개최 취소
export const cancelPublishEvaluation = (investemtnAssessmentId: number) =>
  `${PORTFOLIO}/assessment/${investemtnAssessmentId}/cancel`;

//투자 심사 보고서 등록하기
export const registeInvestmentAssessment = (): string =>
  `${PORTFOLIO}/registerAssessment`;

//투자 심사 보고서 수정하기
export const updateInvestmentAssessment = (): string =>
  `${PORTFOLIO}/updateAssessment`;

//투자 심사 보고서 내용 가져오기
export const getInvestmentAssessmentDocument = (
  portfolioId: number,
  investmentAssessmentId: number
): string =>
  `${PORTFOLIO}/${portfolioId}/investmentAssessment/${investmentAssessmentId}`;

//투자 심사 보고서 투표 하기
export const doingVote = () => `${PORTFOLIO}/assessment/vote`;

//투자 심사 보고서 파일 등록하기
export const registeInvestmentAssessmentDocument = (id: number) =>
  `${PORTFOLIO}/${id}/document`;

//엑셀러레이팅 기업정보 수정하기
export const updatePortfolioInfoForAccelerating = (id: number) =>
  `${PORTFOLIO}/${id}/info`;

//admin api
export const upsertAcStructure = (): string => AC_ADMIN_STRUCTURE;
export const upsertAcIndustrialTech = (): string => AC_ADMIN_INDUSTRIAL_TECH;
export const upsertAcFundAccount = (): string => AC_ADMIN_FUND_ACCOUN;
export const registeResponsibility = (): string => AC_ADMIN_RESPONSIBILITY;
export const removeResponsibility = (acResponsibilityId: number): string =>
  `${AC_ADMIN_RESPONSIBILITY}/${acResponsibilityId}`;
export const registePosition = (): string => AC_ADMIN_POSITION;
export const removePosition = (acPositionId: number): string =>
  `${AC_ADMIN_POSITION}/${acPositionId}`;
export const loginMaster = (): string => AC_ADMIN_LOGIN;
export const getAcAdminStructure = (): string => AC_STRUCTURE_ADMIN;
export const getAcAdminUsers = (): string => AC_USERS_ADMIN;
export const getAcAdminIndustrialTech = (): string => AC_INDUSTRIAL_TECH_ADMIN;

export const getAcAdminFundAccount = (): string => AC_FUND_ACCOUNT_ADMIN;
export const getAcAdminResponsibility = (): string =>
  `${ACCELERATOR_ADMIN}/responsibility`;
export const getAcAdminPosition = (): string =>
  `${ACCELERATOR_ADMIN}/acPosition`;

export const getAcInvestmentOrganization = (): string =>
  `${ACCELERATOR_ADMIN}/investmentOrganization`;
export const registeInvestmentOrganization = (): string =>
  `${ACCELERATOR_ADMIN}/investmentOrganization`;

export const getAcAdminPortfolio = (): string =>
  `${ACCELERATOR_ADMIN}/portfolio`;

export const getAcAdminPortfolioById = (portfolioId: number): string =>
  `${ACCELERATOR_ADMIN}/portfolio/${portfolioId}`;
export const modifyAcAdminPortfolioParticiapnt = (): string =>
  `${ACCELERATOR_ADMIN}/portfolio/participant`;

export const modifyAcAdminPortfolioExcavator = (): string =>
  `${ACCELERATOR_ADMIN}/portfolio/excavator`;

export const changeAcAdminPortfolioStatusDamage = (
  portfolioId: number
): string => `${ACCELERATOR_ADMIN}/portfolio/${portfolioId}/damage`;
export const changeAcAdminPortfolioStatusClosure = (
  portfolioId: number
): string => `${ACCELERATOR_ADMIN}/portfolio/${portfolioId}/closure`;
export const changeAcAdminPortfolioStatusUndo = (portfolioId: number): string =>
  `${ACCELERATOR_ADMIN}/portfolio/${portfolioId}/undo`;

export const acAdminBuyer = (): string => `${ACCELERATOR_ADMIN}/buyer`;
export const acAdminPortfolioExit = (): string =>
  `${ACCELERATOR_ADMIN}/portfolio/exit`;
export const acAdminPortfolioIdExitId = (
  portfolioId: number,
  investmentExitId: number
): string =>
  `${ACCELERATOR_ADMIN}/portfolio/${portfolioId}/exit/${investmentExitId}`;

export const acAdminPortfolioFairValue = () =>
  `${ACCELERATOR_ADMIN}/portfolio/fairValue`;
export const acAdminPortfolioFairValueByPortfolioId = (portfolioId: number) =>
  `${ACCELERATOR_ADMIN}/portfolio/${portfolioId}/fairValue`;

/* drop 관련 api */
export const getDroppedPortfolio = (): string => `${PORTFOLIO}/drop`;
export const restoreDroppedPortfolio = (): string => `${PORTFOLIO}/restore`;
export const dropPortfolio = (): string => `${PORTFOLIO}/drop`;

export const getContractDocumentByAcFundAccountId = (
  id: number | string
): string => `${PORTFOLIO}/${id}/contractDocumentTemplate`;

//외부 투자심사 보고서 회사 정보
export const getExternalInvestmentAssesmentDocument = (
  portfolioId: number | string,
  investmentAssessmentId: number | string
): string =>
  `${PORTFOLIO}/${portfolioId}/investmentAssessment/${investmentAssessmentId}/portfolio`;
export const updateExternalInvestmentAssessmentDocument = (): string =>
  `${PORTFOLIO}/updateAssessment/portfolio`;

export const portfolioUpdateAssessmentExpert = (): string =>
  `${PORTFOLIO}/updateAssessment/expert`;

/** 데이터베이스 */

//통합
export const getDatabaseTotalSearch = (): string => `${DATABASE}/totalSearch`;
//관리
export const getDatabaseAccelerating = (): string => `${DATABASE}/accelerating`;
//Alumni
export const getDatabaseAlumni = (): string => `${DATABASE}/alumni`;
//손상/폐업,드롭
export const getDatabaseDamaged = (): string => `${DATABASE}/damaged`;

//get search data
export const getDatabaseSearchData = (): string => `${ACCELERATOR}/searchData`;

// 투자이력관리 파일 다운로드
export const getInvestmentHistoryExcel = (): string =>
  `${ACCELERATOR}/investmentHistory/excel`;

/* 리스크 관리 */

export const getStockHolderList = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/stockHolderList`;

export const updateDamagedToClosure = (): string =>
  `${PORTFOLIO}/phase/closure`;
export const updateClosureToDamaged = (): string => `${PORTFOLIO}/phase/damage`;

/* 액셀러레이팅 > 기업 담당자 */
export const getPfUser = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/pfUser`;

export const getPreEvaluationTask = (
  portfolioId: number,
  preEvaluationId: number
): string => `${PORTFOLIO}/${portfolioId}/preEvaluation/${preEvaluationId}`;
/* 서명 이미지  업로드 */
export const postSignature = (acUserId: number): string =>
  `${USER}/${acUserId}/signature`;
export const getSignature = (acUserId: number): string =>
  `${USER}/${acUserId}/signature`;

export const getNotifications = (): string => `${USER}/notification`;
export const deleteNotiById = (id: number): string =>
  `${USER}/notification/${id}/delete`;
export const deleteAllReadedNoti = (): string =>
  `${USER}/notification/deleteRead`;
export const readNotificationById = (id: number): string =>
  `${USER}/notification/${id}/read`;

/** New reporting */
export const openQuarter = (): string => `${REPORT}/quarter`;
export const openSelectPortfolioQuarter = (): string => `${REPORT}/open`;
export const closeQuarter = (): string => `${REPORT}/close`;

export const getOpenedQuarter = (): string => `${REPORT}/openedQuarter`;
export const getReportRangeByAccelerator = (): string => `${REPORT}/range`;
export const getReportListByAccelerator = (): string => `${REPORT}`;
export const getReportingFile = (id: number): string => `${REPORT}/file/${id}`;
export const getallAcceleratedPortfolio = (): string =>
  `${REPORT}/accelerating`;

export const getPortfolioReportDetail = (portfolioId: number): string =>
  `${REPORT}/${portfolioId}`;

export const updateConfirmStockChangeListReport = (): string =>
  `${REPORT}/confirm/stock`;
export const updateConfirmReport = (): string => `${REPORT}/confirm`;
export const updateRejectReport = (): string => `${REPORT}/reject`;
export const updateCancelReport = (): string => `${REPORT}/cancel`;
export const updateReadReport = (): string => `${REPORT}/read`;

//파일 올리기
export const postReportingFile = (): string => `${REPORT}/document`;
//파일 교체하기
export const changeReportingFile = (pfReportDoumentId: number): string =>
  `${REPORT}/document/${pfReportDoumentId}/change`;

export const postRealStockTransaction = (): string =>
  `${REPORT}/realStockTransaction`;

// 단일 요약사업계획서 데이터 조회
export const getSBPData = (pfReportDocumentDataId: number): string =>
  `${REPORT}/data/${pfReportDocumentDataId}`;

// export const updateRejectReport = (): string => `${REPORT}/confirm`;

/**
 * 채팅
 */
// 채팅 메세지 보내기
export const sendChatingMsg = (portfolioId: number): string =>
  `${CHAT}/${portfolioId}`;

// 채팅 보기 - 열때
export const getChatingMsg = (portfolioId: number): string =>
  `${CHAT}/open/${portfolioId}`;

// 채팅 보기 - 열려 있을때
export const getNewChatingMsg = (portfolioId: number): string =>
  `${CHAT}/ongoing/${portfolioId}`;

// 채팅 보기 - 스크롤 올렸을때, 페이지 네이션
export const getPageChatingMsg = (
  roomId: number,
  portfolioId: number
): string => `${CHAT}/upper/${roomId}/${portfolioId}`;

// 채팅 닫혀있을 때 안읽은 메세지 수
export const getUnReadMsg = (portfolioId: number): string =>
  `${CHAT}/unread/count/${portfolioId}`;

// 투심 확정 취소
export const postCancelAssessment = (): string =>
  `${PORTFOLIO}/assessment/cancel`;

// 의사록 원본 유저 리스트
export const getDownloadOriginal = (): string => `${USER}/downloadOriginal`;
// 블루카드 링크 생성
export const createBluecardLink = (): string => `${PORTFOLIO}/bluecardLink`;

// 블루카드 링크 정보 검색
export const getBluecardInfoBypfLinkId = (pfLinkId: string): string =>
  `${COMMON}/bluecardLink/${pfLinkId}`;

// 블루카드 링크 방문자 정보 등록
export const registeVisitorInfo = (pfLinkId: string): string =>
  `${COMMON}/bluecardLink/visitor/${pfLinkId}`;

// IR자료 등록
export const registeIrProposalDocument = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/pfIrProposal`;

// 포트폴리오 드롭
export const portfolioDrop = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/drop`;

// 포트폴리오 계약 요청
export const requestPortfolioContract = (): string =>
  `${PORTFOLIO}/assessment/request`;

// 포트폴리오 계약 요청 취소
export const cancelRequestPortfolioContract = (dealflowId: number): string =>
  `${PORTFOLIO}/dealFlow/${dealflowId}/cancelRequest`;

// 포트폴리오 데이터 중복 및 빈값 검사
export const portfolioDataEmptyAndDuplicate = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/emptyItem`;

// 드랍 사유 리스트
export const getDropType = (): string => `${COMMON}/dropType`;

// 주식변동내역 확정 정보
export const getPortfolioStockChangeListInfo = (
  reportDocumentId: number
): string => `${REPORT}/stock/${reportDocumentId}`;

// 아이디로 딜플로우 상태 확인하기
export const getDealflowStatus = (
  id: number,
  type: "pitching" | "investmentAssessment"
): string => `${PORTFOLIO}/dealFlow/status/${id}?type=${type}`;

// 포트폴리오 요약재무 내역
export const getSummaryFinanceChangeHistory = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/finance`;

// 포트폴리오 인력변동내역
export const getEmployeeChangeHistory = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/employee`;

// 포트폴리오 기업가치변동 내역
export const getCompanyChangeHistory = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/value`;

// 포트폴리오 최근 확정된 요약사업계획서
export const getSummaryBusinessPlanLatest = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/data/latest`;

/** 새롤운 대시보드 재원 정보 가져오기*/
export const getFundAccountsForDashboard = (): string => `${DASHBOARD}/fund`;

/** 새롤운 대시보드 재원 상세 정보 가져오기*/
export const getFundAccountDetailForDashboard = (
  fundAccountId: number
): string => `${DASHBOARD}/fund/${fundAccountId}`;

/** 재원 펀드 매니저 정보*/
export const getFundAccountFundManagerForDashboard = (
  fundAccountId: number
): string => `${DASHBOARD}/fund/${fundAccountId}/fundManager`;

/**
 * 설명 : 투자성과 요약 가져오기
 * id: acFundAccountId
 * */
export const getInvestmentPerformanceSummary = (): string =>
  `${DASHBOARD}/performance/summary`;

export const getInvestmentPerformanceValue = (): string =>
  `${DASHBOARD}/performance/value`;

export const getPerformanceTrendForDashboard = (): string =>
  `${DASHBOARD}/performance/trend`;

export const getPerformanceNewInvestmentForDashboard = (): string =>
  `${DASHBOARD}/performance/new`;

export const getPerformanceTipsInvestmentForDashboard = (): string =>
  `${DASHBOARD}/performance/tips`;

export const getPerformanceSurvivalForDashboard = (): string =>
  `${DASHBOARD}/performance/survival`;

export const getPerformanceOutlookForDashboard = (): string =>
  `${DASHBOARD}/performance/outlook`;

export const getInvestmentPerformanceOperatingFund = (): string =>
  `${DASHBOARD}/performance/fund`;
export const getInvestmentPerformanceOperatingIndustry = (): string =>
  `${DASHBOARD}/performance/industry`;

export const getInvestmentTotalPerformance = (): string =>
  `${DASHBOARD}/performance/total`;

export const getAcceleratorStructureDetail = (): string => AC_STRUCTURE;

export const getFundAccountLight = (): string => `${COMMON}/account`;

/** 리스크 관리 */

//리스크 관리 종료
export const closeRiskmanagement = (): string => `${RISK}/close`;
//리스크 관리 종료
export const partiallyRiskmanagement = (): string => `${RISK}/open`;

//리스크 관리 기본 조회
export const getRiskManagementBasic = (): string => `${RISK}/dictionary`;

//리스크 평가 수정
export const putRiskManagementEvaluation = (): string => `${RISK}/evaluation`;

//리스크 평가 단일 조회
export const getRiskManagementData = (riskManagementId: number): string =>
  `${RISK}/evaluation/${riskManagementId}`;

//리스크 평가 리스트 조회
export const getRiskManagementList = (): string => `${RISK}/evaluation`;

/**
 * 단일 요약사업계획서 데이터 조회
 * param : portfolioId
 * */
export const getSBPDataByInfo = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/summary`;

/**
 * 단일 재무제표 데이터 조회
 * param : portfolioId
 * */
export const getFinanceDataByInfo = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/finance`;

/**
 * 단일 주식변동내역 데이터 조회
 * param : portfolioId
 
 * */
export const getStockTransactionByInfo = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/stockTransaction`;

/**
 * 단일 보고 문서 파일 조회
 * */
export const getReportDocumentByInfo = (): string => `${REPORT}/document`;

/**
 * 리스크평가 출력용 데이터 조회
 * */
export const getRiskManagementMinute = (): string => `${RISK}/report`;

/** 리스크 평가 시작 끝 범위 */
export const getRiskmanagementRange = (): string => `${RISK}/range`;

/** 종합리뷰 리스트 조회 */
export const getRiskmanagementTotalEvaluation = (): string => `${RISK}/review`;

/**
 *  재무제표 임시 조회
 */

export const getFinanceDataEmpty = (): string => `${REPORT}/temp/financial`;

export const registeFinanceDataEmpty = (): string => `${REPORT}/temp/financial`;

// 투자 심사 전문가 의견 요청
export const requestExpertOpinion = (): string => `${PORTFOLIO}/expertInfo`;

// 기업 수정 시 전문가 의견 재요청
export const reRequestExpertOpinion = (): string =>
  `${PORTFOLIO}/expertInfo/edit`;

// 전문가의견시 데이터 조회
export const getExpertInfoForExpert = (
  portfolioId: number,
  investmentAssessmentId: number
): string =>
  `${PORTFOLIO}/${portfolioId}/investmentAssessment/${investmentAssessmentId}/expert`;

// 전문가 의견 제출
export const submitExpertOpinion = (): string =>
  `${PORTFOLIO}/updateAssessment/expert`;

export const portfolioIDStockChange = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/stockChange`;

export const portfolioIDCapitalraising = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/capitalRaising`;

export const commonDictionaryStock = (): string => `${COMMON}/dictionary/stock`;

export const reportPortfolioIdFinance = (portfolioId: number): string =>
  `${REPORT}/${portfolioId}/finance`;

export const reportData = (): string => `${REPORT}/data`;

export const portfolioIdFaceValue = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/faceValue`;

export const portfolioIdCurrency = (portfolioId: number): string =>
  `${PORTFOLIO}/${portfolioId}/currency`;
