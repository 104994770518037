import React, { useState } from "react";
import styled from "styled-components";

import { DeskNavigationMenu } from "./DeskNavigationMenu";
import { ReactComponent as Logo } from "../../../2.0lattice/assets/image/new_lattice_logo.svg";
import { NavigationMenu } from "./DeskNavigationLayout";

interface Props {
  menus: NavigationMenu[];
  transfromPixel: (value: number) => number;
}

export interface LocationProps {
  pageAddress: string;
  addressName: string;
  pageName: string;
  type: "active" | "deactive";
  disabled?: boolean;
  validationAuth?: () => boolean;
}

export const DeskNavigation: React.FC<Props> = ({ menus, transfromPixel }) => {
  const [spreadIndex, updateSpreadIndex] = useState<number | undefined>(
    menus.findIndex(
      (menu) =>
        menu.subMenu &&
        menu.subMenu.find((submenu) =>
          window.location.pathname.split("/").includes(submenu.addressName)
        )
    )
  );

  return (
    <Container>
      <LogoContainer transfromPixel={transfromPixel}>
        <Logo width={transfromPixel(82)} height={transfromPixel(20)} />
      </LogoContainer>
      <MenuContainer transfromPixel={transfromPixel}>
        {menus.map((item, index) => {
          if (item.validationAuth === undefined || item.validationAuth()) {
            return (
              <DeskNavigationMenu
                key={index}
                index={index}
                item={item}
                isSpread={index === spreadIndex}
                onChangeSpreadItem={(idx) => {
                  updateSpreadIndex(idx !== spreadIndex ? idx : undefined);
                }}
                transfromPixel={transfromPixel}
              />
            );
          }

          return null;
        })}
      </MenuContainer>
      <NavDivitder transfromPixel={transfromPixel} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LogoContainer = styled.div<{ transfromPixel: (value: number) => number }>`
  width: fit-content;
  height: fit-content;
  padding: ${(props) => props.transfromPixel(20)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${(props) => props.transfromPixel(24)}px auto
    ${(props) => props.transfromPixel(82)}px auto;

  cursor: pointer;
`;
const MenuContainer = styled.ul<{ transfromPixel: (value: number) => number }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.transfromPixel(8)}px;
`;
export const NavDivitder = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: calc(100% - ${(props) => props.transfromPixel(48)}px);
  height: ${(props) => props.transfromPixel(1)}px;
  margin: 0 auto;
  background-color: #ffffff;
  opacity: 0.1;
`;
