import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FundsBorrowed } from "../../../../interface/SBP.interface";
import { TableGrid, TableGridHeaderProps } from "../grid/TableGrid";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  fundDataList?: FundsBorrowed[];
}

export const FundBorrowComponent: React.FC<Props> = (props) => {
  const headers: TableGridHeaderProps[] = [
    {
      header: "기관",
      key: "borrowingAgency",
      width: (220 + 16) * (764 / 1200),
    },
    {
      header: "시작 일자",
      key: "borrowingDate",
      width: (146 + 20) * (764 / 1200),
    },
    {
      header: "상환 기한",
      key: "repaymentDue",
      width: (146 + 20) * (764 / 1200),
    },
    {
      header: "자금 목적",
      key: "fundPurpose",
      width: (186 + 36) * (764 / 1200),
    },
    {
      header: "차입 금액 (원)",
      key: "borrowedAmount",
      type: "number",
      aligin: "end",
      width: 178 * (764 / 1200),
    },
    {
      header: "상환 후 잔액 (원)",
      key: "outstandingBalance",
      type: "number",
      aligin: "end",
      width: 178 * (764 / 1200),
    },
  ];

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={
          props.fundDataList
            ? props.fundDataList.map((item, index) => {
                return {
                  id: index,
                  identificationNumber: index,
                  data: item,
                };
              })
            : []
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;
