import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import { SBPSelectorProps } from "../../../interface/SBP.interface";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  defaultData?: string;
  selectList: SBPSelectorProps[];
  placehoder?: string;
  onChangeData?: (data: string) => void;
}

export const CustomSelector: React.FC<Props> = ({
  isReadOnly,
  disabled,
  defaultData,
  selectList,
  placehoder,
  onChangeData,
}) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    setValue(defaultData || "");
  }, [defaultData]);

  return (
    <Container className={`body-14-rg ${disabled ? "disabled" : ""}`}>
      <InputContainer
        ref={selectorRef}
        className={`${disabled || isReadOnly ? "disabled" : ""}`}
      >
        {value.length === 0 && (
          <div style={{ color: "var(--text-inactive)" }}>{placehoder}</div>
        )}
        {value.length > 0 && (
          <div style={{ color: "var(--text-01)" }}>{value}</div>
        )}
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 8px 20px;

  display: flex;
  flex-direction: row;

  &.disabled {
    background-color: var(--gray-50);
  }
`;

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray-200);

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  &.disabled {
    padding: 6px 0px;
    cursor: default;
    border-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .arrow {
    path {
      fill: var(--icon-02);
    }
  }

  :hover {
    border: 1px solid var(--blue-200);
  }
  :focus {
    border: 1px solid var(--blue-200);
    outline: none;
  }
`;

const ListContainer = styled.div`
  position: fixed;
  max-height: 256px;
  display: flex;
  flex-direction: column;

  z-index: 2;

  padding: 8px 0px;

  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  background-color: var(--background-contents02);
`;

const ListItem = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 9px 16px;

  &.selected {
    background-color: var(--blue-100);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
