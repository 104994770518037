import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FinancialStatementProps } from "../interface/FinancialStatement.interface";

import { ReactComponent as Lock } from "../../../../../../assets/image/new_icon_lock.svg";
import {
  getFinanceDataByReporting,
  getSBPData,
} from "../../../../repository/ReportingRepository";
import { addCommasToIntegerPart } from "../../../../../../common/commonUtil";
import { FinanceDataProps } from "../../../../../risk-management/evaluation-detail/interface/RMED.interface";
import { getFinanceDataByInfo } from "../../../../../../api/repository/portfolio/PortfolioRepository";
import { PDFViewer } from "../../../../../../components-2.0v/pdf-viewer/PDFViewer";
import { ReportingFile } from "../../../../../reporting-detail/interface/ReportingDetail.interface";
import { FinancialStatementFullProps } from "../../../../../../type/data";
import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import { HeightRule } from "docx";

interface Props {
  portfolioId: number;
  year: number;
  quarterNum: number;
  data: ReportingFile;
  currency?: string;
}
export const FinancialStatementViewer: React.FC<Props> = (props) => {
  const { portfolioId, quarterNum, year, data, currency = "KRW" } = props;
  const WIDTH = 180;
  const [financialStatement, updateFinancialStatement] =
    useState<FinancialStatementFullProps>({});
  const getData = async () => {
    if (data.file?.uploadStatus.uploadStatusId === 5) {
      const financeData: FinanceDataProps = await getFinanceDataByReporting(
        portfolioId,
        year,
        quarterNum
      );
      if (financeData) {
        updateFinancialStatement({
          ...financeData,
        });
      }
    } else {
      if (!data.file?.pfReportDocumentData?.id) return;
      const res = await getSBPData(data.file?.pfReportDocumentData.id);
      if (res) {
        updateFinancialStatement({ ...res.data });
      }
    }
  };

  const validationData = (data: number | undefined | null) => {
    return data !== undefined && data !== null;
  };

  useEffect(() => {
    getData();
  }, [data]);
  return (
    <Container>
      <FinancialStatementContainer style={{ color: "var(--text-01)" }}>
        <div
          className="heading-16-sb"
          style={{ textAlign: "center", marginBottom: 24 }}
        >
          요약 재무 정보 ({currency})
        </div>
        <FlexColumn>
          {validationData(financialStatement.currentAssets) && (
            <RowTitleBox
              title="유동자산"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor="var(--primary-blue)"
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.currentAssets)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.cashAssets) && (
            <RowTitleBox
              title="당좌자산"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.cashAssets)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.inventoryAssets) && (
            <RowTitleBox
              title="재고자산"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.inventoryAssets)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.otherCurrentAssets) && (
            <RowTitleBox
              title="기타 유동자산"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.otherCurrentAssets)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.nonCurrentAssets) && (
            <RowTitleBox
              title="비유동자산"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor="var(--primary-blue)"
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.nonCurrentAssets)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.investmentAssets) && (
            <RowTitleBox
              title="투자자산"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.investmentAssets)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.tangibleAssets) && (
            <RowTitleBox
              title="유형자산"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.tangibleAssets)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.intangibleAssets) && (
            <RowTitleBox
              title="무형자산"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.intangibleAssets)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.otherNonCurrentAssets) && (
            <RowTitleBox
              title="기타 비유동자산"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(
                  financialStatement.otherNonCurrentAssets
                )}
              </div>
            </RowTitleBox>
          )}

          {validationData(financialStatement.totalAssets) && (
            <RowTitleBox
              title="자산총계"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor="var(--text-01)"
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.totalAssets)}
              </div>
            </RowTitleBox>
          )}

          <div style={{ height: 24 }}></div>

          {validationData(financialStatement.totalLiabilities) && (
            <RowTitleBox
              title="부채총계"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor="var(--primary-blue)"
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.totalLiabilities)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.currentLiabilities) && (
            <RowTitleBox
              title="유동부채"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.currentLiabilities)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.nonCurrentLiabilities) && (
            <RowTitleBox
              title="비유동부채"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(
                  financialStatement.nonCurrentLiabilities
                )}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.totalEquity) && (
            <RowTitleBox
              title="자본총계"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor={
                financialStatement.totalEquity &&
                financialStatement.totalEquity > 0
                  ? "var(--primary-blue)"
                  : "var(--red-500)"
              }
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.totalEquity)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.capital) && (
            <RowTitleBox
              title="자본금"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.capital)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.capitalSurplus) && (
            <RowTitleBox
              title="자본잉여금"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.capitalSurplus)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.capitalAdjustment) && (
            <RowTitleBox
              title="자본조정"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.capitalAdjustment)}
              </div>
            </RowTitleBox>
          )}
          {validationData(
            financialStatement.accumulatedOtherComprehensiveIncome
          ) && (
            <RowTitleBox
              title="기타포괄손익누계액"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(
                  financialStatement.accumulatedOtherComprehensiveIncome
                )}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.retainedEarnings) &&
            financialStatement.retainedEarnings !== undefined &&
            financialStatement.retainedEarnings !== null &&
            financialStatement.retainedEarnings > 0 && (
              <RowTitleBox
                title={`이익잉여금`}
                titleWidth={WIDTH}
                minHeight={24}
                titleColor={"var(--primary-blue)"}
                tabCount={1}
              >
                <div className="body-14-md row__item">
                  {addCommasToIntegerPart(financialStatement.retainedEarnings)}
                </div>
              </RowTitleBox>
            )}

          {validationData(financialStatement.accumulatedDeficit) &&
            financialStatement.accumulatedDeficit !== undefined &&
            financialStatement.accumulatedDeficit !== null &&
            financialStatement.accumulatedDeficit > 0 && (
              <RowTitleBox
                title={`결손금`}
                titleWidth={WIDTH}
                minHeight={24}
                titleColor={"var(--red-500)"}
                tabCount={1}
              >
                <div className="body-14-md row__item">
                  {addCommasToIntegerPart(
                    financialStatement.accumulatedDeficit * -1
                  )}
                </div>
              </RowTitleBox>
            )}

          {validationData(financialStatement.totalLiabilitiesEquity) && (
            <RowTitleBox
              title="부채와자본총계"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor="var(--text-01)"
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(
                  financialStatement.totalLiabilitiesEquity
                )}
              </div>
            </RowTitleBox>
          )}

          <div style={{ height: 24 }}></div>

          {validationData(financialStatement.grossProfit) && (
            <RowTitleBox
              title="매출이익(손실)"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor={
                financialStatement.grossProfit &&
                financialStatement.grossProfit > 0
                  ? "var(--primary-blue)"
                  : "var(--red-500)"
              }
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.grossProfit || 0)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.revenue) && (
            <RowTitleBox
              title="매출액"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.revenue)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.costOfSales) && (
            <RowTitleBox
              title="매출원가"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.costOfSales)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.operatingProfit) && (
            <RowTitleBox
              title="영업이익(손실)"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor={
                financialStatement.operatingProfit &&
                financialStatement.operatingProfit > 0
                  ? "var(--primary-blue)"
                  : "var(--red-500)"
              }
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(
                  financialStatement.operatingProfit || 0
                )}
              </div>
            </RowTitleBox>
          )}
          {validationData(
            financialStatement.sellingGeneralAdministrativeExpenses
          ) && (
            <RowTitleBox
              title="판매비 및 일반관리비"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(
                  financialStatement.sellingGeneralAdministrativeExpenses
                )}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.profitBeforeTaxExpense) && (
            <RowTitleBox
              title="법인세비용차감전순이익(손실)"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor={
                financialStatement.profitBeforeTaxExpense &&
                financialStatement.profitBeforeTaxExpense > 0
                  ? "var(--primary-blue)"
                  : "var(--red-500)"
              }
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(
                  financialStatement.profitBeforeTaxExpense || 0
                )}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.nonOperatingIncome) && (
            <RowTitleBox
              title="영업외수익"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.nonOperatingIncome)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.nonOperatingExpenses) && (
            <RowTitleBox
              title="영업외비용"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(
                  financialStatement.nonOperatingExpenses
                )}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.incomeTaxExpense) && (
            <RowTitleBox
              title="법인세비용"
              titleWidth={WIDTH}
              minHeight={24}
              tabCount={1}
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.incomeTaxExpense)}
              </div>
            </RowTitleBox>
          )}
          {validationData(financialStatement.netIncome) && (
            <RowTitleBox
              title="당기순이익(손실)"
              titleWidth={WIDTH}
              minHeight={24}
              titleColor={
                financialStatement.netIncome && financialStatement.netIncome > 0
                  ? "var(--primary-blue)"
                  : "var(--red-500)"
              }
            >
              <div className="body-14-md row__item">
                {addCommasToIntegerPart(financialStatement.netIncome || 0)}
              </div>
            </RowTitleBox>
          )}
        </FlexColumn>
      </FinancialStatementContainer>
      <div className="pdf__viewer">
        {data?.file ? (
          <PDFViewer
            type={data.file.type}
            pdfKey={data.file.documentFile?.key || ""}
          />
        ) : (
          <div className="heading-18-md empty__container">
            <Lock />
            <div>제출된 서류가 없습니다.</div>
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  overflow: visible;

  background: var(--white);

  .pdf__viewer {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #edeeef;

    .viewer__iframe__wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .viewer__iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }

      .viewer__iframe__load__container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .empty__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      background: #fbfcfd;
      color: #7e8086;
    }
  }

  /* Safari and Chrome */
  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }
`;

const FinancialStatementContainer = styled.div`
  min-width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: scroll;

  .row__item {
    margin-top: 9px;
    text-align: end;
  }
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
