import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

interface Props {}

export const InvestmentInfoHeader: React.FC<Props> = ({}) => {
  const height = 32;
  return (
    <Container className={`caption-12-rg`}>
      <CellConatiner
        width={278}
        height={height}
        style={{ justifyContent: "center" }}
      >
        재원
      </CellConatiner>

      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        주식종류
      </CellConatiner>

      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        주식수
      </CellConatiner>

      <CellConatiner
        width={200}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        투자원금
      </CellConatiner>
      <CellConatiner
        width={80}
        height={height}
        style={{
          justifyContent: "flex-start",
          borderRight: "0.5px solid transparent",
        }}
      >
        지분율
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;

  :nth-child(1) {
    border-top-left-radius: 4px;
  }
  :nth-last-child(1) {
    border-top-right-radius: 4px;
  }
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height || 32}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: var(--text-04);
  background-color: #f6faff;
  border-right: 0.5px solid var(--divider-300, #e3e4e5);
  border-bottom: 0.5px solid var(--divider-300, #e3e4e5);
  padding: 0 10px;
`;
