import React from "react";

import styled from "styled-components";
import { StockChangeListProps } from "../../interface/type";
import {
  addCommasToIntegerPart,
  getNewDate,
  getYMD,
} from "../../../../common/commonUtil";
import ColumnTitleBox from "../../../../components/box/ColumnTitleBox";

interface Props {
  index?: number;
  isSelected: boolean;
  emptyKey?: string[];
  data: StockChangeListProps;
  allStockChangeSubType: { id: number; name: string }[];
  onClick?: () => void;
}
export const StockChangeRow: React.FC<Props> = ({
  index = 0,
  isSelected,
  emptyKey,
  data,
  allStockChangeSubType,
  onClick,
}) => {
  const height = 40;
  const getSubType = () => {
    const subType = allStockChangeSubType.find(
      (item) => item.id === data.stockChangeSubType
    );
    return subType ? subType.name : "-";
  };
  return (
    <Container
      className={`body-14-rg ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <CellConatiner
        width={60}
        height={height}
        style={{ justifyContent: "center" }}
      >
        {index + 1}
      </CellConatiner>
      <CellConatiner
        width={120}
        height={height}
        style={{ justifyContent: "center" }}
      >
        {getYMD(getNewDate(data.date))}
      </CellConatiner>

      <CellConatiner
        width={120}
        height={height}
        style={{ justifyContent: "center" }}
      >
        {data.stockChangeType?.name}
      </CellConatiner>

      <CellConatiner
        width={250}
        height={height}
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        {data.buyer?.name}
      </CellConatiner>

      <CellConatiner
        width={120}
        height={height}
        style={{ justifyContent: "center" }}
      >
        {data.stockType?.name}
      </CellConatiner>
      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "flex-end" }}
      >
        {addCommasToIntegerPart(data.stockNumber)}
      </CellConatiner>
      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "flex-end" }}
      >
        {addCommasToIntegerPart(data.stockPrice)}
      </CellConatiner>
      <CellConatiner
        width={150}
        height={height}
        style={{ justifyContent: "flex-end" }}
      >
        {addCommasToIntegerPart(
          (data.stockPrice || 0) * (data.stockNumber || 0)
        )}
      </CellConatiner>
      <CellConatiner
        width={360}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        <FlexRow
          style={{ width: "100%", rowGap: 10, columnGap: 24, flexWrap: "wrap" }}
        >
          {[2].includes(data.stockChangeType!.id) && (
            <ColumnTitleBox
              title="라운드"
              titleWidth={80}
              headerTextcolor={
                emptyKey?.includes("round") ? "var(--red-500)" : undefined
              }
              style={{
                width: "max-content",
                color: emptyKey?.includes("round")
                  ? "var(--red-500)"
                  : "var(--text-01)",
              }}
            >
              <div>{data.round ? data.round.name : "-"}</div>
            </ColumnTitleBox>
          )}
          {[2, 3].includes(data.stockChangeType!.id) && (
            <ColumnTitleBox
              title={data.stockChangeType!.id === 2 ? "배정방식" : "거래방식"}
              titleWidth={80}
              headerTextcolor={
                emptyKey?.includes("stockChangeSubType")
                  ? "var(--red-500)"
                  : undefined
              }
              style={{
                width: "max-content",
                color: emptyKey?.includes("stockChangeSubType")
                  ? "var(--red-500)"
                  : "var(--text-01)",
              }}
            >
              <div>{getSubType()}</div>
            </ColumnTitleBox>
          )}
          {[3].includes(data.stockChangeType!.id) && (
            <ColumnTitleBox
              title="매도자"
              titleWidth={80}
              headerTextcolor={
                emptyKey?.includes("seller") ? "var(--red-500)" : undefined
              }
              style={{
                width: "max-content",
                color: emptyKey?.includes("seller")
                  ? "var(--red-500)"
                  : "var(--text-01)",
              }}
            >
              <div>{data.seller ? data.seller.name : "-"}</div>
            </ColumnTitleBox>
          )}
          {[12].includes(data.stockChangeType!.id) && (
            <ColumnTitleBox
              title="전환주식"
              titleWidth={80}
              headerTextcolor={
                emptyKey?.includes("pfConvertTransaction")
                  ? "var(--red-500)"
                  : undefined
              }
              style={{
                width: "max-content",
                color: emptyKey?.includes("pfConvertTransaction")
                  ? "var(--red-500)"
                  : "var(--text-01)",
              }}
            >
              {data.pfConvertTransaction ? (
                <div>
                  {data.pfConvertTransaction.stockType.name}(
                  {addCommasToIntegerPart(
                    data.pfConvertTransaction.stockNumber
                  )}
                  )
                </div>
              ) : (
                <div>-</div>
              )}
            </ColumnTitleBox>
          )}
          {[5, 6, 7, 8].includes(data.stockChangeType!.id) && (
            <ColumnTitleBox
              title="비율"
              titleWidth={80}
              headerTextcolor={
                emptyKey?.includes("pfStockBatchInfo")
                  ? "var(--red-500)"
                  : undefined
              }
              style={{
                width: "max-content",
                color: emptyKey?.includes("pfStockBatchInfo")
                  ? "var(--red-500)"
                  : "var(--text-01)",
              }}
            >
              {data.stockChangeType && data.pfStockBatchInfo ? (
                <div>
                  {[5, 7].includes(data.stockChangeType.id)
                    ? `${data.pfStockBatchInfo.numerator || 0}주당 ${
                        data.pfStockBatchInfo.denominator || 0
                      }주 배정`
                    : `${data.pfStockBatchInfo.numerator || 0}주를 ${
                        data.pfStockBatchInfo.denominator || 0
                      }주로 병합`}
                </div>
              ) : (
                <div>-</div>
              )}
            </ColumnTitleBox>
          )}
        </FlexRow>
      </CellConatiner>
      <CellConatiner
        width={200}
        height={height}
        style={{
          justifyContent: "flex-start",
          borderRight: "0.5px solid transparent",
        }}
      >
        {data.note}
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  width: max-content;
  height: 100%;
  height: max-content;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  background-color: var(--background-contents01, #fff);
  cursor: pointer;
  :hover {
    background-color: var(--hover-100);
  }

  &.selected {
    background-color: var(--blue-100);
    :hover {
      background-color: var(--blue-100);
    }
  }
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  min-height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  color: var(--text-01);
  border-right: 1px solid var(--divider-300, #e3e4e5);
  border-bottom: 1px solid var(--divider-300, #e3e4e5);
  padding: 8px 10px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  height: 100%;
`;
