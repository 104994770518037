import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useInput, { InputType } from "../../evaluation-detail/useInput";

interface Props {
  textType?: InputType;
  width?: number | string;
  className?: string;
  style?: React.CSSProperties;
  defaultValue?: string;
  isShowValidationMsg?: boolean;
  validationMsg?: string;
  placeholder?: string;
  unit?: string;
  readOnly?: boolean;
  textValue?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  onEnterPress?: (value: string) => void;
  transfromPixel: (value: number) => number;
}
const InputBox: React.FC<Props> = (props) => {
  const {
    textType = "text",
    width = "100%",
    className,
    style,
    defaultValue,
    isShowValidationMsg,
    validationMsg,
    placeholder,
    unit,
    readOnly,
    textValue,
    onChange,
    onBlur,
    onFocus,
    onEnterPress,
    transfromPixel,
  } = props;

  const [value, updateValue] = useState<string>(defaultValue || "");
  const [focussing, updateFocussing] = useState<boolean>(false);
  const [getValueByType] = useInput();

  useEffect(() => {
    console.log(defaultValue);
    const data = getValueByType(textType, defaultValue || "");
    updateValue(data);
  }, [defaultValue]);

  return (
    <Container
      className={className}
      style={{ ...style, width }}
      transfromPixel={transfromPixel}
    >
      <InputContainer transfromPixel={transfromPixel}>
        <InputComponent
          readOnly={readOnly}
          disabled={readOnly}
          transfromPixel={transfromPixel}
          className={`${isShowValidationMsg ? "show__msg" : ""} body-14-md`}
          type="text"
          placeholder={placeholder}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onEnterPress && onEnterPress(value);
            }
          }}
          onChange={(e) => {
            const data = getValueByType(textType, e.target.value);
            updateValue(data);
            onChange && onChange(data);
          }}
          onFocus={(e) => {
            updateFocussing(true);
            onFocus && onFocus(value);
          }}
          onBlur={(e) => {
            updateFocussing(false);
            onBlur && onBlur(value);
          }}
          value={textValue || value}
        />
        {unit && (
          <span className="body-14-rg" style={{ minWidth: "fit-content" }}>
            {unit}
          </span>
        )}
      </InputContainer>
      {validationMsg && isShowValidationMsg && (
        <span className="validation__msg body-14-md">{validationMsg}</span>
      )}
    </Container>
  );
};
const Container = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.transfromPixel(8)}px;
  .validation__msg {
    color: var(--red-500);
  }
`;

const InputContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  position: relative;
  width: 100%;
  height: ${(props) => props.transfromPixel(40)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow: hidden;
`;
const InputComponent = styled.input<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: ${(props) => props.transfromPixel(40)}px;
  padding: ${(props) => props.transfromPixel(12)}px
    ${(props) => props.transfromPixel(10)}px;

  border: ${(props) => props.transfromPixel(1)}px solid var(--gray-300);
  border-radius: ${(props) => props.transfromPixel(4)}px;
  transition: 0.2s;
  background: var(--background-contents01);

  ::placeholder {
    color: var(--text-06);
  }

  :disabled {
    border: ${(props) => props.transfromPixel(1)}px solid transparent;
    :hover {
      background-color: var(--background-contents01);
      border-color: transparent;
    }
  }
  :read-only {
    color: var(--text-01);
    padding: ${(props) => props.transfromPixel(12)}px
      ${(props) => props.transfromPixel(0)}px;
  }

  &.show__msg {
    border: ${(props) => props.transfromPixel(1)}px solid var(--red-500);
    animation: shake 200ms;
  }

  :focus {
    outline: none;
    border: ${(props) => props.transfromPixel(1)}px solid
      var(--focus-field-blue);

    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    border-color: var(--focus-field-blue);
  }

  @keyframes shake {
    25% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    50% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
    75% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    100% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export default InputBox;
