import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import html2canvas from "html2canvas";
import config from "../../../../config";

interface Props {
  year: number;
  quarterNum: number;
  logoImage?: string;
  fileDonwloading?: boolean;
  onLoadedCanvas?: (canvas: HTMLCanvasElement) => void;
}
export const CoverPage: React.FC<Props> = (props) => {
  return (
    <Container id="minute__cover">
      <div className="heading-24-b" style={{ marginTop: 82 }}>
        {config.companyName}
      </div>
      <div className="heading-24-b">투자 리스크 관리 위원회 의사록</div>
      <FlexRow className="body-16-sb" style={{ marginTop: 16, gap: 6 }}>
        <span style={{ color: "var(--gray-700)" }}>기준일자</span>
        <span>
          {props.year}년 {props.quarterNum === 2 ? "6월 30일" : "12월 31일"}
        </span>
      </FlexRow>
      <Box className="body-14-sb" style={{ marginTop: 140 }}>
        <div>
          당사 투자본부, 경영기획본부 각 본부장 책임 하에 담당 심사역이
          <br />
          포트폴리오사의 사업계획서 및 재무정보 등을 근거로 상기 별첨과 같이
          평가하였습니다.
        </div>
        <FlexRow>
          <span style={{ width: 125 }}>투자 본부장</span>
          <span style={{ width: 120 }}>이용관</span>
          <span style={{ color: "var(--gray-700)" }}>서명(인)</span>
        </FlexRow>
        <FlexRow>
          <span style={{ width: 125 }}>경영기획 본부장</span>
          <span style={{ width: 120 }}>김용건</span>
          <span style={{ color: "var(--gray-700)" }}>서명(인)</span>
        </FlexRow>
      </Box>
      <Box className="body-14-sb" style={{ marginTop: 40 }}>
        <div>
          투자본부/경영기획본부 각 본부장 승인을 득한 투자/육성본부의 리스크
          평가 결과를 근거로
          <br />
          당사 회계정책에 의거한 재무제표 영향을 충실히 검토하고
          <br />
          평가 결과를 공정하게 재무제표에 반영하였습니다.
        </div>
        <FlexRow>
          <span style={{ width: 125 }}>대표이사</span>
          <span style={{ width: 120 }}>이용관</span>
          <span style={{ color: "var(--gray-700)" }}>서명(인)</span>
        </FlexRow>
      </Box>
      {props.logoImage && (
        <img className="logo" src={props.logoImage} alt="logo" />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: calc(210mm);
  min-width: calc(210mm);
  max-width: calc(210mm);
  height: calc(297mm - 1px);
  min-height: calc(297mm - 1px);
  max-height: calc(297mm - 1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  color: #000;

  .logo {
    width: 200px;
    margin-top: 200px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Box = styled.div`
  width: calc(100% - 48px);
  padding: 16px;

  display: flex;
  flex-direction: column;

  gap: 20px;

  border-radius: 4px;
  border: 1px solid var(--gray-700);
  background: var(--gray-300);
`;
