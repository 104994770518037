import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useResize from "../../2.0lattice/useResize";

import { ReactComponent as Logo } from "../../2.0lattice/assets/image/new_lattice_logo.svg";

import useDevice from "../../useDevice";
import useStockChangeListViewer from "./hooks/useStockChangeListViewer";
import { StockChangeHeader } from "./components/table/StockChangeHeader";
import { StockChangeRow } from "./components/table/StockChangeRow";
import MainTab from "../../components-2.0v/tab/MainTab";
import { InvestmentSummary } from "./organism/InvestmentSummary";
import { StockDetail } from "./organism/StockDetail";
import { ReactComponent as Lock } from "../../assets/image/new_icon_lock.svg";
import { StockChangeFooter } from "./components/table/StockChangeFooter";
import { CapitalRaisingHeader } from "./components/capital-table/CapitalRaisingHeader";
import { CapitalRaisingRow } from "./components/capital-table/CapitalRaisingRow";
import { CapitalRaisingDetail } from "./organism/CapitalRaisingDetail";
import { DateTimePickerByMUI } from "../../components-2.0v/date-picker/DateTimePickerByMUI";
import { DatePickerMUI } from "./organism/DatePickerMUI";

const StockChangeListViewer = () => {
  const [screenRate, transfromPixel] = useResize();
  const [
    portfolioInfo,
    stockChangeList,
    stockChangeListResult,
    capitalRaisingList,
    allStockChangeSubType,
    currency,
    selectedStockChangeRow,
    selectedCapitalRaisingRow,
    totalStockNumber,
    timeStamp,
    minDate,
    maxDate,
    onChangeTimeStamp,
    updateSelectedStockChangeRow,
    updateSelectedCapitalRaisingRow,
  ] = useStockChangeListViewer();

  const [isMobile] = useDevice();

  if (!stockChangeList) return null;

  return (
    <Container className="scroll__invisible">
      <MainTab>
        <RowContainer style={{ justifyContent: "space-between" }}>
          <span className="heading-20-sb">
            {portfolioInfo?.startupInfo.companyName} 투자정보
          </span>
          <FlexRow style={{ gap: 10, alignItems: "center" }}>
            <span className="body-14-sb" style={{ color: "var(--text-04)" }}>
              타임스템프
            </span>

            <DatePickerMUI
              minDate={minDate}
              maxDate={maxDate}
              data={timeStamp}
              onChangeData={(date) => onChangeTimeStamp(date)}
            />
          </FlexRow>
        </RowContainer>
      </MainTab>

      <Body>
        <ContentWrapper>
          <ContentContainer>
            {stockChangeListResult && (
              <InvestmentSummary
                stockChangeListResult={stockChangeListResult}
                stockChangeList={stockChangeList}
                capitalRaisingList={capitalRaisingList}
                currency={currency}
              />
            )}
            {stockChangeListResult &&
              stockChangeListResult.emptyFiled &&
              stockChangeListResult.emptyFiled.length > 0 && (
                <div
                  className="caption-12-sb"
                  style={{ color: "var(--red-500)" }}
                >
                  * 주식변동내역에 비어있는 데이터는 붉은색 글씨로 표현되며
                  비어있는 데이터가 있을 시 정확한 값이 아닐 수 있습니다.
                </div>
              )}
            <TableContainer style={{}}>
              <div className="heading-18-sb" style={{ marginBottom: 10 }}>
                자본조달내역
              </div>
              {capitalRaisingList.length > 0 && (
                <CapitalRaisingHeader headerSticky currency={currency} />
              )}
              {capitalRaisingList.length > 0 &&
                capitalRaisingList.map((item, index) => {
                  return (
                    <CapitalRaisingRow
                      key={`capital__raising__list__row__${index + 1}`}
                      index={index}
                      isSelected={selectedCapitalRaisingRow?.id === item.id}
                      data={item}
                      onClick={() => {
                        updateSelectedCapitalRaisingRow(item);
                        updateSelectedStockChangeRow(undefined);
                      }}
                    />
                  );
                })}
              {capitalRaisingList.length === 0 && (
                <EmptyContainer>
                  <Lock className="icon" />
                  <span
                    className="heading-20-sb"
                    style={{ marginTop: 20, color: "var(--text-04)" }}
                  >
                    주식 변동에 영향을 미칠 수 있는 자본조달 이력이 없습니다
                  </span>
                </EmptyContainer>
              )}
            </TableContainer>

            <TableContainer>
              <div className="heading-18-sb" style={{ marginBottom: 10 }}>
                주식변동내역
              </div>
              {stockChangeList.length > 0 && (
                <StockChangeHeader currency={currency} />
              )}

              {stockChangeList.length > 0 &&
                stockChangeList.map((item, index) => {
                  return (
                    <StockChangeRow
                      key={`stock__change__list__row__${index + 1}`}
                      index={index}
                      emptyKey={
                        stockChangeListResult?.emptyFiled?.find(
                          (empty) => empty.id === item.id
                        )?.errorKey
                      }
                      isSelected={selectedStockChangeRow?.id === item.id}
                      data={item}
                      allStockChangeSubType={allStockChangeSubType}
                      onClick={() => {
                        updateSelectedStockChangeRow(item);
                        updateSelectedCapitalRaisingRow(undefined);
                      }}
                    />
                  );
                })}
              {stockChangeList.length > 0 && (
                <StockChangeFooter totalStockNum={totalStockNumber} />
              )}
              {stockChangeList.length === 0 && (
                <EmptyContainer>
                  <Lock className="icon" />
                  <span
                    className="heading-20-sb"
                    style={{ marginTop: 20, color: "var(--text-04)" }}
                  >
                    주식 변동 이력이 없습니다.
                  </span>
                </EmptyContainer>
              )}
            </TableContainer>
          </ContentContainer>
        </ContentWrapper>
        <RightModal
          className={
            selectedCapitalRaisingRow || selectedStockChangeRow ? "show" : ""
          }
        >
          {selectedCapitalRaisingRow && (
            <CapitalRaisingDetail
              close={() => updateSelectedCapitalRaisingRow(undefined)}
              data={selectedCapitalRaisingRow}
              currency={currency}
            />
          )}
          <StockDetail
            close={() => updateSelectedStockChangeRow(undefined)}
            allStockChangeSubType={allStockChangeSubType}
            data={selectedStockChangeRow}
          />
        </RightModal>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  overflow: scroll;
  background-color: var(--background-page);
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 61px);
  background: var(--background-page);
`;
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 24px;
  overflow: scroll;
`;

const ContentContainer = styled.div`
  width: max-content;
  min-width: 1230px;
  height: 100%;
  margin: 0 auto;

  padding: 32px 0px 32px 0px;
  overflow: scroll;
`;

const RightModal = styled.div`
  position: absolute;
  width: 400px;
  min-width: 400px;
  height: 100%;
  top: 0;
  right: 0;
  transform: translateX(400px);
  background-color: var(--background-contents01);
  border-left: 1px solid var(--divider-200);
  display: flex;
  flex-direction: column;

  transition: transform 0.2s ease-in;
  &.show {
    transform: translateX(0px);
    transition: transform 0.2s ease-out;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    path {
      fill: var(--gray-500);
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default StockChangeListViewer;
