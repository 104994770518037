import React from "react";
import { useMediaQuery } from "react-responsive";
import MobilePortfolioDashboard from "./mobile/MobilePortfolioDashboard";
import DeskPortfolioDashboard from "./desktop/DeskPortfolioDashboard";
import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";

const PortfolioDashboard: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobilePortfolioDashboard {...props} />
  ) : (
    <DeskPortfolioDashboard {...props} />
  );
};
export default PortfolioDashboard;
