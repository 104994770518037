import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { PortfolioNavigationProps } from "../../interface/PortfolioNavigation.interface";

import { getDealFlowPhaseToWord } from "../../../../deal-flow/interface/DealFlow.interface";
import PortfolioNavigationMenu from "../components/PortfolioNavigationMenu";
import { ReactComponent as Dashboard } from "../../../../../assets/image/portfolio_detail_dashboard.svg";
import { ReactComponent as Info } from "../../../../../assets/image/portfolio_detail_info.svg";
import { ReactComponent as Bluecard } from "../../../../../assets/image/portfolio_detail_bluecard.svg";
import { ReactComponent as InvestmentInfo } from "../../../../../assets/image/portfolio_detail_investment_info.svg";
import { ReactComponent as Pitching } from "../../../../../assets/image/portfolio_detail_pitching.svg";
import { ReactComponent as Evaluation } from "../../../../../assets/image/portfolio_detail_evaluation.svg";
import { ReactComponent as Reporting } from "../../../../../assets/image/portfolio_detail_reporting.svg";

import { ReactComponent as Drop } from "../../../../../assets/image/icon_drop_deal.svg";
import { ReactComponent as Next } from "../../../../../assets/image/icon_next.svg";
import { ReactComponent as Danger } from "../../../../../assets/image/icon_danger.svg";
import DefaultIndustry from "../../../../../assets/image/industry_default.png";
import { Tooltip } from "react-tooltip";

interface Props {
  isEditableUser?: boolean;
  portfolioInfo?: PortfolioNavigationProps;
  currentMenu: string;
  emptyData: string[];
  duplicateData: string[];
  goToMenu: (menu: string) => void;
  exitPortfolio: () => void;
  onClickDropButton?: () => void;
  onClickRequestContract?: () => void;
  onClickRequestContractCancel?: () => void;
  onClickNextDeal?: () => void;
}
const PortfolioNavigation: React.FC<Props> = (props) => {
  const {
    isEditableUser,
    portfolioInfo,
    currentMenu,
    emptyData,
    duplicateData,
    goToMenu,
    exitPortfolio,
    onClickDropButton,
    onClickRequestContract,
    onClickRequestContractCancel,
    onClickNextDeal,
  } = props;
  const INFO_LINE_TITLE_WIDTH = 64;
  if (!portfolioInfo) return null;
  const DangerTooltip = () => {
    if (emptyData.length === 0 && duplicateData.length === 0) return null;
    const dangerData: string[] = [];
    emptyData.forEach((item) => {
      switch (item) {
        case "companyName":
          dangerData.push("기업명을 입력해 주세요.");
          break;
        case "ceoName":
          dangerData.push("대표이사명 입력해 주세요.");
          break;
        case "ceoEmail":
          dangerData.push("이메일을 입력해 주세요.");
          break;
        case "ceoPhone":
          dangerData.push("전화번호를 입력해 주세요.");
          break;
        case "address":
          dangerData.push("주소를 입력해 주세요.");
          break;
        case "businessNumber":
          dangerData.push("사업자 등록번호를 입력해 주세요.");
          break;
        case "establishmentDay":
          dangerData.push("설립일을 입력해 주세요.");
          break;
        case "businessPlanFile":
          dangerData.push("상세 IR자료를 입력해 주세요.");
          break;
      }
    });
    duplicateData.forEach((item) => {
      switch (item) {
        case "companyName":
          dangerData.push("기업명이 중복되었어요.");
          break;
        case "ceoEmail":
          dangerData.push("이메일이 중복되었어요.");
          break;
        case "ceoPhone":
          dangerData.push("전화번호가 중복되었어요.");
          break;
        case "businessNumber":
          dangerData.push("사업자 등록번호가 중복되었어요.");
          break;
      }
    });

    return (
      <div style={{ marginTop: 2 }}>
        <Danger
          className="danger__bubble"
          // data-tooltip-delay-hide={2000}
          style={{ outline: "none" }}
        />
        <Tooltip
          className="body-14-rg"
          anchorSelect=".danger__bubble"
          place="bottom-start"
          border={0}
          style={{
            position: "fixed",
            zIndex: 99999999,
            lineHeight: 1.5,
            borderRadius: 4,
          }}
        >
          {dangerData.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </Tooltip>
      </div>
    );
  };

  return (
    <Container>
      <InfoContainer>
        {portfolioInfo.companyImgUrl ? (
          <PortfolioImg>
            <img
              className="logo__img"
              src={portfolioInfo.companyImgUrl}
              alt="logo img"
            />
          </PortfolioImg>
        ) : (
          <PortfolioImg style={{ backgroundColor: "var(--blue-100,#E8F0FF)" }}>
            <img
              className="default__img"
              src={DefaultIndustry}
              alt="logo img"
            />
          </PortfolioImg>
        )}
        <PortfolioName className="heading-20-b">
          {portfolioInfo.companyName}
        </PortfolioName>
        <PortfolioInfoContainer className="caption-12-md">
          {portfolioInfo.pfPhase.type === "DROP" ? (
            <div className="info__line">
              <span
                className="info__line__title"
                style={{ width: INFO_LINE_TITLE_WIDTH }}
              >
                기업상태
              </span>
              <span className="info__line__contents">드롭</span>
            </div>
          ) : (
            <div>
              <div className="info__line">
                <span
                  className="info__line__title"
                  style={{ width: INFO_LINE_TITLE_WIDTH }}
                >
                  담당자
                </span>
                <span className="info__line__contents">
                  {portfolioInfo.mainParticipant
                    ? portfolioInfo.mainParticipant.name
                    : "없음"}
                </span>
              </div>
              {portfolioInfo.phase && (
                <div className="info__line">
                  <span
                    className="info__line__title"
                    style={{ width: INFO_LINE_TITLE_WIDTH }}
                  >
                    진행 단계
                  </span>
                  <span className="info__line__contents">
                    {getDealFlowPhaseToWord(
                      portfolioInfo.phase,
                      portfolioInfo.pfPhase.type === "MANAGE"
                    )}
                  </span>
                </div>
              )}
              {portfolioInfo.riskStatus && (
                <div className="info__line">
                  <span
                    className="info__line__title"
                    style={{ width: INFO_LINE_TITLE_WIDTH }}
                  >
                    전망 평가
                  </span>
                  <span className="info__line__contents">
                    {portfolioInfo.riskStatus.name}
                  </span>
                </div>
              )}
            </div>
          )}
        </PortfolioInfoContainer>
      </InfoContainer>
      <MenuContainer>
        <div className="caption-12-md menu__section__title">스타트업 정보</div>

        {portfolioInfo.pfPhase.type !== "DROP" &&
          portfolioInfo.pfPhase.type !== "EXCAVATION" && (
            <PortfolioNavigationMenu
              isActive={currentMenu === "dashboard"}
              title="대시보드"
              SvgIcon={Dashboard}
              onClick={() => {
                goToMenu("dashboard");
              }}
            />
          )}
        <PortfolioNavigationMenu
          isActive={["info", "info-modify"].includes(currentMenu)}
          title="기본 정보"
          SvgIcon={Info}
          dangerTooltip={<DangerTooltip />}
          onClick={() => {
            goToMenu("info");
          }}
        />
        {portfolioInfo.pfPhase.type !== "DROP" &&
          portfolioInfo.pfPhase.type !== "EXCAVATION" && (
            <PortfolioNavigationMenu
              isActive={currentMenu === "bluecard"}
              title="블루 카드"
              SvgIcon={Bluecard}
              onClick={() => {
                goToMenu("bluecard");
              }}
            />
          )}
        {portfolioInfo.pfPhase.type !== "DROP" &&
          portfolioInfo.pfPhase.type !== "EXCAVATION" && (
            <PortfolioNavigationMenu
              isActive={currentMenu === "investment-info"}
              title="투자 정보"
              SvgIcon={InvestmentInfo}
              onClick={() => {
                goToMenu("investment-info");
              }}
            />
          )}
        {portfolioInfo.pfPhase.type !== "DROP" &&
          portfolioInfo.pfPhase.type !== "EXCAVATION" && (
            <PortfolioNavigationMenu
              isActive={currentMenu === "reporting"}
              title="분기 보고"
              SvgIcon={Reporting}
              onClick={() => {
                goToMenu("reporting");
              }}
            />
          )}
        {portfolioInfo.phase && (
          <div className="caption-12-md menu__section__title">딜 플로우</div>
        )}
        {(portfolioInfo.phase ||
          portfolioInfo.pfPhase.type !== "EXCAVATION") && (
          <PortfolioNavigationMenu
            isActive={["pitching-detail", "pitching"].includes(currentMenu)}
            title="피칭 심사"
            SvgIcon={Pitching}
            onClick={() => {
              goToMenu("pitching");
            }}
          />
        )}
        {(portfolioInfo.phase ||
          portfolioInfo.pfPhase.type !== "EXCAVATION") && (
          <PortfolioNavigationMenu
            isActive={["evaluation-detail", "evaluation"].includes(currentMenu)}
            title="투자 심사"
            SvgIcon={Evaluation}
            onClick={() => {
              goToMenu("evaluation");
            }}
          />
        )}
      </MenuContainer>
      <FooterContaiiner>
        <div
          style={{
            background: "var(--gray-200)",
            width: "calc(100% - 48px)",
            height: 1,
            margin: "0 auto 8px auto",
          }}
        />
        {isEditableUser &&
          portfolioInfo.phase &&
          portfolioInfo.phase === "investment" && (
            <EventBtn
              className="heading-14-md"
              onClick={onClickRequestContract}
            >
              <Next />
              <span>계약요청</span>
            </EventBtn>
          )}
        {isEditableUser &&
          portfolioInfo.phase &&
          portfolioInfo.phase === "contract" && (
            <EventBtn
              className="heading-14-md"
              onClick={onClickRequestContractCancel}
            >
              <Next style={{ transform: "rotate(180deg)" }} />
              <span>계약요청 취소</span>
            </EventBtn>
          )}
        {isEditableUser &&
          portfolioInfo.pfPhase.type === "MANAGE" &&
          !portfolioInfo.phase && (
            <EventBtn className="heading-14-md" onClick={onClickNextDeal}>
              <Next />
              <span>후행 검토 단계로</span>
            </EventBtn>
          )}
        {isEditableUser && portfolioInfo.phase && (
          <EventBtn className="heading-14-md" onClick={onClickDropButton}>
            <Drop />
            <span>드롭</span>
          </EventBtn>
        )}
        <ExitBtn onClick={exitPortfolio}>나가기</ExitBtn>
      </FooterContaiiner>
    </Container>
  );
};

const Container = styled.div`
  width: 216px;
  min-width: 216px;
  max-width: 216px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 32px 24px 4px 24px;
`;
const PortfolioImg = styled.div`
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  max-width: 52px;
  max-height: 52px;

  border-radius: 18px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo__img {
    width: 100%;
    height: 100%;
  }

  .default__img {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
  }
`;
const PortfolioName = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 12px;
  color: var(--primary-blue);
`;
const PortfolioInfoContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 14px 16px;
  border-radius: 4px;

  background-color: var(--gray-50);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .info__line {
    width: 100%;
    display: flex;
    flex-direction: row;
    .info__line__title {
      color: var(--text-05);
    }
    .info__line__contents {
      color: var(--text-01);
    }
  }
`;

const MenuContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 0px 32px;
  .menu__section__title {
    margin-top: 36px;
    margin-bottom: 8px;
    color: var(--text-05);
  }
`;

const FooterContaiiner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0px 45px 0px;
`;

const EventBtn = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  gap: 12px;
  color: var(--text-03);
  margin-top: 4px;

  transition: all 0.2s ease;

  cursor: pointer;

  :hover {
    /* background-color: var(--hover-200); */
    path {
      fill: var(--icon-01);
    }
    color: var(--text-01);
  }
`;

const ExitBtn = styled.button`
  width: calc(100% - 64px);
  height: 38px;
  border-radius: 4px;
  background: var(--background-pagebutton, #f4f5f6);
  border: 0;
  margin: 0 auto;
  margin-top: 20px;
  cursor: pointer;
`;
export default PortfolioNavigation;
