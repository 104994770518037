import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { SBPContentsProps } from "../../interface/SBP.interface";
import { SBPSector } from "../SBPSector";

import { AutoResizableTextarea } from "../AutoResizableTextarea";
import { SBPSectorDescription } from "../SBPSectorDescription";

import { SBPSectorFooter } from "../SBPSectorFooter";
import { GovermentTaskComponent } from "./organism/GovermentTaskComponent";
import { FundBorrowComponent } from "./organism/FundBorrowComponent";
import { CLevelChangeComponent } from "./organism/CLevelChangeComponent";

import { ReactComponent as Check } from "../../../../../../../../assets/image/icon_check.svg";
import RowTitleBox from "../RowTitleBox";
import InputBox from "../InputBox";
import { AddComma } from "../../../../../../../../common/commonUtil";
import config from "../../../../../../../../config";

interface Props {
  isReadOnly: boolean;
  data?: SBPContentsProps;
  onChangeData?: (data: SBPContentsProps) => void;
}

export const SBPStatus: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;
  const [isCheck, updateIsCheck] = useState<boolean>(false);
  const [noEventGovTask, updateNoEventGovTask] = useState<boolean>(false);
  const [noEventFundBorrowed, updateNoEventFundBorrowed] =
    useState<boolean>(false);

  const [totalGovFunding, updateTotalGovFunding] = useState<number>(0);
  const [totalBorrowedAmount, updateTotalBorrowedAmount] = useState<number>(0);
  const [totalOutstandingBalance, updateTotalOutstandingBalance] =
    useState<number>(0);

  const getYear = (year?: number, quater?: number) => {
    if (!year || !quater) return "YYYY";
    return quater === 2 ? `${year}` : `${year - 1}`;
  };

  const getMonthRange = (quater?: number) => {
    if (!quater) return "Error";
    return quater === 2 ? "1 - 6" : "1 - 12";
  };

  const getMonth = (quater?: number) => {
    if (!quater) return "Error";
    return quater === 2 ? "6" : "12";
  };

  useEffect(() => {
    if (data) {
      updateIsCheck(data.businessStatus.employeeTurnover.noEventClevel);
      updateNoEventFundBorrowed(data.businessStatus.noEventFundBorrowed);
      updateNoEventGovTask(data.businessStatus.noEventGovTask);

      updateTotalGovFunding(
        data.businessStatus.govTasks
          .map((item) => item.govFunding || 0)
          .reduce((prev, cur) => prev + cur, 0)
      );
      updateTotalBorrowedAmount(
        data.businessStatus.fundBorrowed
          .map((item) => item.borrowedAmount || 0)
          .reduce((prev, cur) => prev + cur, 0)
      );
      updateTotalOutstandingBalance(
        data.businessStatus.fundBorrowed
          .map((item) => item.outstandingBalance || 0)
          .reduce((prev, cur) => prev + cur, 0)
      );
    }
  }, [data]);

  if (!data) return null;
  return (
    <Container>
      <SBPSector title="사업진행" id="status1">
        <FlexColumn style={{ gap: 8 }}>
          {!props.isReadOnly && (
            <SBPSectorDescription
              text={`${getYear(data.year, data.quarter)}년 ${getMonthRange(
                data.quarter
              )}월에 해당하는 내용을 작성해 주세요.`}
            />
          )}
          <FlexColumn
            style={{
              padding: 28,
              borderRadius: 4,
              gap: 24,
              backgroundColor: "var(--background-contents01)",
              border: "1px solid var(--gray-300)",
            }}
          >
            <RowTitleBox
              title="솔루션, 개발관점"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                defaultData={data.businessStatus.solutionAndDevlopPoint}
              />
            </RowTitleBox>
            <RowTitleBox
              title="사업관점"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                defaultData={data.businessStatus.businessPoint}
              />
            </RowTitleBox>
          </FlexColumn>
        </FlexColumn>
      </SBPSector>
      {data.quarter === 4 && (
        <SBPSector title="매출 실적" id="status2">
          <FlexColumn style={{ gap: 8 }}>
            <FlexColumn
              style={{
                padding: 28,
                borderRadius: 4,
                gap: 24,
                backgroundColor: "var(--background-contents01)",
                border: "1px solid var(--gray-300)",
              }}
            >
              <RowTitleBox
                textClassName="heading-14-md"
                title={`${
                  data.businessStatus.salesPerformance[0].year - 1
                } 매출액`}
                titleWidth={208}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly}
                  transfromPixel={(n) => n}
                  unit="원"
                  width={250}
                />
              </RowTitleBox>
              <RowTitleBox
                textClassName="heading-14-md"
                title={`${
                  data.businessStatus.salesPerformance[0].year - 2
                } 매출액`}
                titleWidth={208}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly}
                  transfromPixel={(n) => n}
                  unit="원"
                  width={250}
                />
              </RowTitleBox>
              <RowTitleBox
                textClassName="heading-14-md"
                title={`${
                  data.businessStatus.salesPerformance[0].year - 3
                } 매출액`}
                titleWidth={208}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly}
                  transfromPixel={(n) => n}
                  unit="원"
                  width={250}
                />
              </RowTitleBox>
            </FlexColumn>
            <SBPSectorFooter>
              <FlexRow style={{ alignItems: "center", gap: 12, marginTop: 4 }}>
                <span
                  className="body-16-md"
                  style={{ color: "var(--text-04)" }}
                >
                  3개년 평균 매출액
                </span>
                <span
                  className="heading-16-md"
                  style={{ color: "var(--text-01)" }}
                >
                  {data.businessStatus.salesPerformance
                    .map((item) => item.amount || 0)
                    .reduce((prev, cur) => prev + cur, 0)}{" "}
                  원
                </span>
              </FlexRow>
            </SBPSectorFooter>
          </FlexColumn>
        </SBPSector>
      )}

      <SBPSector title="정부 지원 과제 참여" id="status3">
        <FlexColumn style={{ gap: 8 }}>
          {!props.isReadOnly && (
            <SBPSectorDescription
              text={`${getYear(data.year, data.quarter)}년 ${getMonthRange(
                data.quarter
              )}월 사이에 지원, 협약, 진행, 종료한 과제에 대해 작성해 주세요.`}
            />
          )}
          <GovermentTaskComponent
            isReadOnly={props.isReadOnly}
            disabled={noEventGovTask}
            govDataList={data.businessStatus.govTasks}
            onDataChange={(newGovData) => {}}
          />
          <SBPSectorFooter>
            <FlexRow
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {props.isReadOnly ? (
                <div />
              ) : (
                <FlexRow
                  style={{
                    gap: 12,
                    marginTop: 4,
                    paddingLeft: 20,
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    updateNoEventGovTask(!noEventGovTask);
                    if (onChangeData) {
                      data.businessStatus.noEventGovTask = !noEventGovTask;
                      onChangeData(data);
                    }
                  }}
                >
                  <CheckWrapper>
                    <Check
                      className={`check__box ${
                        noEventGovTask ? "checked" : ""
                      }`}
                    />
                  </CheckWrapper>
                  <span
                    className="body-14-rg"
                    style={{ color: "var(--text-03)" }}
                  >
                    정부 지원 과제 내역이 없어요.
                  </span>
                </FlexRow>
              )}
              <FlexRow style={{ alignItems: "center", gap: 12, marginTop: 4 }}>
                <span
                  className="body-16-md"
                  style={{ color: "var(--text-04)" }}
                >
                  총 정부 출연금
                </span>
                <span
                  className="heading-16-md"
                  style={{ color: "var(--text-01)" }}
                >
                  {noEventGovTask
                    ? "해당없음"
                    : `${AddComma(totalGovFunding)} 원`}
                </span>
              </FlexRow>
            </FlexRow>
          </SBPSectorFooter>
        </FlexColumn>
      </SBPSector>

      <SBPSector title="자금 차입" id="status4">
        <FlexColumn style={{ gap: 8 }}>
          {!props.isReadOnly && (
            <SBPSectorDescription
              text={`${getYear(data.year, data.quarter)}년 ${getMonth(
                data.quarter
              )}월말 기준으로 작석해 주세요.`}
            />
          )}
          <FundBorrowComponent
            isReadOnly={props.isReadOnly}
            disabled={noEventFundBorrowed}
            fundDataList={data.businessStatus.fundBorrowed}
            onDataChange={(newFundData) => {}}
          />

          <SBPSectorFooter>
            <FlexRow
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {props.isReadOnly ? (
                <div />
              ) : (
                <FlexRow
                  style={{
                    gap: 12,
                    marginTop: 4,
                    paddingLeft: 20,
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    updateNoEventFundBorrowed(!noEventFundBorrowed);
                    if (onChangeData) {
                      data.businessStatus.noEventFundBorrowed =
                        !noEventFundBorrowed;
                      onChangeData(data);
                    }
                  }}
                >
                  <CheckWrapper>
                    <Check
                      className={`check__box ${
                        noEventFundBorrowed ? "checked" : ""
                      }`}
                    />
                  </CheckWrapper>
                  <span
                    className="body-14-rg"
                    style={{ color: "var(--text-03)" }}
                  >
                    자금 차입 내역이 없어요.
                  </span>
                </FlexRow>
              )}
              <FlexRow style={{ alignItems: "center", gap: 24, marginTop: 4 }}>
                <FlexRow style={{ alignItems: "center", gap: 12 }}>
                  <span
                    className="body-16-md"
                    style={{ color: "var(--text-04)" }}
                  >
                    총 차입 금액
                  </span>
                  <span
                    className="heading-16-md"
                    style={{ color: "var(--text-01)" }}
                  >
                    {noEventFundBorrowed
                      ? "해당없음"
                      : `${AddComma(totalBorrowedAmount)} 원`}
                  </span>
                </FlexRow>
                <FlexRow style={{ alignItems: "center", gap: 12 }}>
                  <span
                    className="body-16-md"
                    style={{ color: "var(--text-04)" }}
                  >
                    총 상환 후 잔액
                  </span>
                  <span
                    className="heading-16-md"
                    style={{ color: "var(--text-01)" }}
                  >
                    {noEventFundBorrowed
                      ? "해당없음"
                      : `${AddComma(totalOutstandingBalance)} 원`}
                  </span>
                </FlexRow>
              </FlexRow>
            </FlexRow>
          </SBPSectorFooter>
        </FlexColumn>
      </SBPSector>

      <SBPSector title="인력변동" id="status5">
        <FlexColumn style={{ gap: 16 }}>
          <FlexColumn style={{ gap: 8 }}>
            {!props.isReadOnly && (
              <SBPSectorDescription text="임원 및 경영진을 포함한 전 임직원을 기준을 작성해 주세요." />
            )}
            <FlexColumn
              style={{
                padding: 28,
                borderRadius: 4,
                gap: 24,
                backgroundColor: "var(--background-contents01)",
                border: "1px solid var(--gray-300)",
              }}
            >
              <FlexColumn
                style={{
                  gap: 12,
                }}
              >
                <div
                  className="heading-16-sb"
                  style={{ color: "var(--text-03)" }}
                >{`${data.year ? data.year - 1 : "YYYY"}년 ${
                  data.quarter ? (data.quarter === 2 ? "12" : "6") : "Error"
                }월말`}</div>
                <RowTitleBox
                  textClassName="heading-14-md"
                  title="임직원 수"
                  titleWidth={108 + 50}
                  transfromPixel={(n) => n}
                >
                  <InputBox
                    textType="numberWithComma"
                    readOnly={props.isReadOnly}
                    transfromPixel={(n) => n}
                    unit="명"
                    width={77}
                    placeholder="0"
                    defaultValue={
                      data.businessStatus.employeeTurnover.curEmployee !==
                      undefined
                        ? `${data.businessStatus.employeeTurnover.curEmployee}`
                        : undefined
                    }
                  />
                </RowTitleBox>
              </FlexColumn>

              <FlexRow>
                <FlexColumn
                  style={{
                    flex: 1,
                    gap: 12,
                  }}
                >
                  <div
                    className="heading-16-sb"
                    style={{ color: "var(--text-03)" }}
                  >{`${
                    data.year && data.quarter
                      ? data.quarter === 2
                        ? data.year
                        : data.year - 1
                      : "YYYY"
                  }년 ${
                    data.quarter
                      ? data.quarter === 2
                        ? "1 - 6"
                        : "7 - 12"
                      : "Error"
                  }월`}</div>
                  <RowTitleBox
                    textClassName="heading-14-md"
                    title="입사자 수"
                    titleWidth={108 + 50}
                    transfromPixel={(n) => n}
                  >
                    <InputBox
                      textType="numberWithComma"
                      readOnly={props.isReadOnly}
                      transfromPixel={(n) => n}
                      unit="명"
                      width={77}
                      placeholder="0"
                      defaultValue={
                        data.businessStatus.employeeTurnover
                          .employeeOnboarding !== undefined
                          ? `${data.businessStatus.employeeTurnover.employeeOnboarding}`
                          : undefined
                      }
                    />
                  </RowTitleBox>
                </FlexColumn>
                <FlexColumn
                  style={{
                    flex: 1,
                    gap: 12,
                  }}
                >
                  <div
                    className="heading-16-sb"
                    style={{ opacity: 0, cursor: "default" }}
                  >{`{{N-1}}년 {7}-{12}월 `}</div>
                  <RowTitleBox
                    textClassName="heading-14-md"
                    title="퇴사자 수"
                    titleWidth={108 + 50}
                    transfromPixel={(n) => n}
                  >
                    <InputBox
                      textType="numberWithComma"
                      readOnly={props.isReadOnly}
                      transfromPixel={(n) => n}
                      unit="명"
                      width={77}
                      placeholder="0"
                      defaultValue={
                        data.businessStatus.employeeTurnover
                          .employeeAttrition !== undefined
                          ? `${data.businessStatus.employeeTurnover.employeeAttrition}`
                          : undefined
                      }
                    />
                  </RowTitleBox>
                </FlexColumn>
                <FlexColumn
                  style={{
                    flex: 1,
                    gap: 12,
                  }}
                >
                  <div
                    className="heading-16-sb"
                    style={{ color: "var(--text-03)" }}
                  >{`${
                    data.year && data.quarter
                      ? data.quarter === 2
                        ? data.year
                        : data.year - 1
                      : "YYYY"
                  }년 ${
                    data.quarter ? (data.quarter === 2 ? "6" : "12") : "Error"
                  }월말`}</div>
                  <RowTitleBox
                    textClassName="heading-14-md"
                    title="임직원 수"
                    titleWidth={108 + 50}
                    transfromPixel={(n) => n}
                  >
                    <InputBox
                      readOnly
                      textType="numberWithComma"
                      transfromPixel={(n) => n}
                      unit="명"
                      width={77}
                      textValue={`${
                        (data.businessStatus.employeeTurnover.curEmployee ||
                          0) +
                        (data.businessStatus.employeeTurnover
                          .employeeOnboarding || 0) -
                        (data.businessStatus.employeeTurnover
                          .employeeAttrition || 0)
                      }`}
                    />
                  </RowTitleBox>
                </FlexColumn>
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn style={{ gap: 8 }}>
            {!props.isReadOnly && (
              <SBPSectorDescription text="등기 임원 및 경영진(C레벨)에 한해서만 작성해 주세요." />
            )}

            <CLevelChangeComponent
              isReadOnly={props.isReadOnly}
              disabled={isCheck}
              cLevelDataList={
                data.businessStatus.employeeTurnover.cLevelChangeList || []
              }
              onDataChange={(newCLevelData) => {}}
            />
            {!props.isReadOnly && (
              <FlexRow
                style={{
                  gap: 12,
                  marginTop: 4,
                  paddingLeft: 20,
                  cursor: "pointer",
                  width: "fit-content",
                }}
                onClick={() => {
                  updateIsCheck(!isCheck);
                  if (onChangeData) {
                    data.businessStatus.employeeTurnover.noEventClevel =
                      !isCheck;
                    onChangeData(data);
                  }
                }}
              >
                <CheckWrapper>
                  <Check className={`check__box ${isCheck ? "checked" : ""}`} />
                </CheckWrapper>
                <span
                  className="body-14-rg"
                  style={{ color: "var(--text-03)" }}
                >
                  입사 혹은 퇴사를 한 등기임원 및 경영진이 없어요.
                </span>
              </FlexRow>
            )}
          </FlexColumn>
        </FlexColumn>
      </SBPSector>

      <SBPSector
        title={`${config.companyName} 투자 후 누적 출원 및 등록`}
        id="status6"
      >
        <FlexColumn style={{ gap: 8 }}>
          <FlexColumn
            style={{
              padding: 28,
              borderRadius: 4,
              gap: 24,
              backgroundColor: "var(--background-contents01)",
              border: "1px solid var(--gray-300)",
            }}
          >
            <FlexRow style={{ gap: 131 }}>
              <RowTitleBox
                textClassName="heading-14-md"
                title="국내 출원"
                titleWidth={108 + 50}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly}
                  transfromPixel={(n) => n}
                  unit="건"
                  width={77}
                  placeholder="0"
                  defaultValue={
                    data.businessStatus.patentInfo.domesticRegistration !==
                    undefined
                      ? `${data.businessStatus.patentInfo.domesticRegistration}`
                      : undefined
                  }
                />
              </RowTitleBox>
              <RowTitleBox
                textClassName="heading-14-md"
                title="국내 등록"
                titleWidth={108 + 50}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly}
                  transfromPixel={(n) => n}
                  unit="건"
                  width={77}
                  placeholder="0"
                  defaultValue={
                    data.businessStatus.patentInfo.domesticApplication !==
                    undefined
                      ? `${data.businessStatus.patentInfo.domesticApplication}`
                      : undefined
                  }
                />
              </RowTitleBox>
            </FlexRow>
            <FlexRow style={{ gap: 131 }}>
              <RowTitleBox
                textClassName="heading-14-md"
                title="해외 출원"
                titleWidth={108 + 50}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly}
                  transfromPixel={(n) => n}
                  unit="건"
                  width={77}
                  placeholder="0"
                  defaultValue={
                    data.businessStatus.patentInfo.internationalRegistration !==
                    undefined
                      ? `${data.businessStatus.patentInfo.internationalRegistration}`
                      : undefined
                  }
                />
              </RowTitleBox>
              <RowTitleBox
                textClassName="heading-14-md"
                title="해외 등록"
                titleWidth={108 + 50}
                transfromPixel={(n) => n}
              >
                <InputBox
                  textType="numberWithComma"
                  readOnly={props.isReadOnly}
                  transfromPixel={(n) => n}
                  unit="건"
                  width={77}
                  placeholder="0"
                  defaultValue={
                    data.businessStatus.patentInfo.internationalApplication !==
                    undefined
                      ? `${data.businessStatus.patentInfo.internationalApplication}`
                      : undefined
                  }
                />
              </RowTitleBox>
            </FlexRow>
            <FlexRow style={{ gap: 131 }}>
              <RowTitleBox
                textClassName="heading-14-md"
                title="비고"
                titleWidth={108 + 50}
                style={{ width: "100%" }}
                transfromPixel={(n) => n}
              >
                <AutoResizableTextarea
                  isReadOnly={props.isReadOnly}
                  isShowWordCounter
                  placeholder="입력한 내용이 없습니다."
                  defaultData={data.businessStatus.patentInfo.note}
                />
              </RowTitleBox>
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </SBPSector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--gray-700);
        stroke: var(--gray-700);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--gray-700);
        }
      }
    }
  }
`;
