import { FileType } from "../../../../../../../../type/data";

export interface SBPLocationProps {
  year: number;
  quarterNum: number;
}

export interface SBPTabProps {
  id: number;
  name: string;
  isComplete: boolean;
  tabSectionList: SectionValidationProps[];
}

export interface SectionValidationProps {
  index: number;
  isComplete: boolean;
  sectionTitle: string;
  selectId: string;
}

export interface SBPContentsProps {
  ver: string;
  year: number;
  quarter: number;

  businessStatus: SBPBusinessStatus;
  businessPlan: SBPBusinessPlan;
  requestAndEvaluation: RequestAndEvaluation;
}

export interface SBPBusinessStatus {
  solutionAndDevlopPoint: string;
  businessPoint: string;
  salesPerformance: SalesPerformance[];
  govTasks: GovermentTask[];
  noEventGovTask: boolean;
  fundBorrowed: FundsBorrowed[];
  noEventFundBorrowed: boolean;
  employeeTurnover: EmployeeTurnover;
  patentInfo: PatentInfo;
}

export interface SalesPerformance {
  year: number;
  amount?: number;
}

export interface GovermentTask {
  taskName?: string;
  responsibleAgency?: string;
  startDate?: string;
  endDate?: string;
  govFunding?: number;
  govStatus?: string;
}

export interface FundsBorrowed {
  borrowingAgency?: string;
  borrowingDate?: string;
  repaymentDue?: string;
  fundPurpose?: string;
  borrowedAmount?: number;
  outstandingBalance?: number;
}

export interface EmployeeTurnover {
  curEmployee?: number;
  employeeOnboarding?: number;
  employeeAttrition?: number;
  noEventClevel: boolean;
  cLevelChangeList?: CLevelEmployee[];
}

export interface CLevelEmployee {
  isOnboarding?: boolean;
  name?: string;
  position?: string;
  hasRegistration?: boolean;
  resume?: FileType;
}

export interface PatentInfo {
  domesticApplication?: number;
  internationalApplication?: number;
  domesticRegistration?: number;
  internationalRegistration?: number;
  note?: string;
}

// 사업계획
export interface SBPBusinessPlan {
  solutionAndDevlopPoint: string;
  businessPoint: string;

  capitalAcquisitionPlan: CapitalAcquisitionPlan[];
  noEventCapitalAcquisition: boolean;
  estimatedCashFlow: EstimatedCashFlow;
}

export interface CapitalAcquisitionPlan {
  solutionCapitalAcquisition?: string;
  capitalRaiseDate?: string;
  capitalAmount?: number;
  contents?: string;
}

export interface EstimatedCashFlow {
  govBalance?: number;
  cashOnHand?: number;
  expectedCashInflow?: number;
  reasonExpectedCashInflow?: string;
  estimatedGovFundingUsage?: number;
  totalExpectedExpense?: number;
  salesIssue?: string;
}

export interface RequestAndEvaluation {
  participant: ParticipantRequestAndEvaluation;
  bluepointPartners: BluepointPartnersRequestAndEvaluation;
}

export interface ParticipantRequestAndEvaluation {
  businessStrategyAndManagement?: number;
  IRDataAndInvestmentAttraction?: number;
  network?: number;
  requestContens?: string;
}

export interface BluepointPartnersRequestAndEvaluation {
  totalSatisfaction?: number;
  requestContens?: string;
}

export interface SBPSelectorProps {
  id: number;
  text: string;
  data: any;
}

export const govStatusList: SBPSelectorProps[] = [
  { id: 1, text: "지원중", data: "지원중" },
  { id: 2, text: "협약중", data: "협약중" },
  { id: 3, text: "진행중", data: "진행중" },
  { id: 4, text: "종료", data: "종료" },
];

export const onBoardingStatusList: SBPSelectorProps[] = [
  { id: 1, text: "입사자", data: "입사자" },
  { id: 2, text: "퇴사자", data: "퇴사자" },
];

export const registrationStatusList: SBPSelectorProps[] = [
  { id: 1, text: "등기", data: "등기" },
  { id: 2, text: "비등기", data: "비등기" },
];

export const capitalStatusList: SBPSelectorProps[] = [
  { id: 1, text: "신주발행", data: "신주발행" },
  { id: 2, text: "자금차입", data: "자금차입" },
  { id: 3, text: "정부지원금", data: "정부지원금" },
  { id: 4, text: "가수금", data: "가수금" },
  { id: 5, text: "기타", data: "기타" },
];

export const capitalQuarterStatusList: SBPSelectorProps[] = [
  { id: 1, text: "1분기", data: "1분기" },
  { id: 2, text: "2분기", data: "2분기" },
  { id: 3, text: "3분기", data: "3분기" },
  { id: 4, text: "4분기", data: "4분기" },
];

export const capitalHalfQuarterStatusList: SBPSelectorProps[] = [
  { id: 3, text: "3분기", data: "3분기" },
  { id: 4, text: "4분기", data: "4분기" },
];
