import React, { RefObject, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

interface Props {}
export const Short: React.FC<Props> = (props) => {
  return (
    <Container>
      <input
        className="font-regular-14"
        type="text"
        disabled={true}
        placeholder={"단답형 텍스트"}
      />
    </Container>
  );
};

const Container = styled.div(
  () => css`
    display: flex;

    .short {
      width: 100%;
      padding: 5px 10px;
    }
    input[type="text"] {
      width: 100%;
      padding: 5px 10px;
      border: 1px solid var(--grey_20);
      border-radius: 4px;
      :disabled {
        outline: none;
        border: 1px solid var(--grey_20);
        border-radius: 4px;
        background-color: var(--white);
      }
      ::placeholder {
        font-size: 14px;
      }
    }
  `
);
