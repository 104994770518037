import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { PrePitchingData } from "../../../pre-pitching/interface/PrePitching.interface";
import { TitleBox } from "../../../../../components-2.0v/box/TitleBox";
import { PitchingDefalutSetting } from "./PitchingDefalutSetting";
import { PitchingInfoInvestmentAndCompany } from "./PitchingInfoInvestmentAndCompany";
import { PitchingQuestions } from "./PitchingQuestions";
import { UserInfo } from "../../../../../type/data";
import { DefaultButton } from "lattice_core";
import {
  PitchingChangeDataType,
  PitchingStatus,
} from "../../interface/PrePitchingDetail.interface";
import ModalLayout from "../../../../../components/modal/ModalLayout";
import ConfirmModal from "../../../../../2.0lattice/components/modal/ConfirmModal";

interface Props {
  isEditable: boolean;
  pitchingData: PrePitchingData;
  allUser: UserInfo[];
  releaseAble: boolean;
  isModifyMode: boolean;
  alReadyPublish: boolean;
  setAlreadyPublish: Dispatch<SetStateAction<boolean>>;
  onChangeData: (data: { type: PitchingChangeDataType; data: any }) => void;
  updatePitchingData: () => void;
  publishPitching: () => void;
  cancelPitching: () => void;
  finishPitching: () => void;
  setModifyMode: () => void;
  publishingModify: () => void;
}

export const PitchingModify: React.FC<Props> = (props) => {
  const {
    isEditable,
    pitchingData,
    allUser,
    releaseAble,
    isModifyMode,
    alReadyPublish,
    setAlreadyPublish,
    onChangeData,
    updatePitchingData,
    publishPitching,
    cancelPitching,
    finishPitching,
    setModifyMode,
    publishingModify,
  } = props;

  return (
    <ContainerWarpper>
      <Container id={"root__component"} className="scroll__invisible">
        <SurveyContainer style={{ paddingBottom: isEditable ? 120 : 60 }}>
          <TitleBox title="피칭 기본 설정">
            <PitchingDefalutSetting
              isEditable={isEditable && isModifyMode}
              pitchingData={pitchingData}
              allUser={allUser}
              onChangeData={onChangeData}
            />
          </TitleBox>
          <TitleBox title="투자·기업 정보">
            <PitchingInfoInvestmentAndCompany
              isEditable={isEditable && isModifyMode}
              onChangeData={onChangeData}
              pitchingData={pitchingData}
            />
          </TitleBox>
          <TitleBox title="질문지">
            {pitchingData.preEvaluationData?.map((item, key) => {
              return <PitchingQuestions key={key} sectionItem={item} />;
            })}
          </TitleBox>
        </SurveyContainer>
      </Container>
      {isEditable && (
        <Footer>
          {isModifyMode ? (
            <DefaultButton
              className="heading-16-md"
              buttonType="filled"
              sizeType="small"
              buttonColor={"var(--primary-blue)"}
              hoverColor={"var(--blue-600)"}
              text="저장"
              onClick={(e) => {
                e && e.stopPropagation();
                setModifyMode();
                updatePitchingData();
              }}
            />
          ) : (
            <DefaultButton
              className="heading-16-md"
              buttonType="colored-outline"
              sizeType="small"
              buttonColor={"var(--text-01)"}
              borderColor={"var(--gray-400)"}
              text="수정"
              onClick={(e) => {
                e && e.stopPropagation();
                setModifyMode();
              }}
            />
          )}

          {!isModifyMode && pitchingData.status === PitchingStatus.OPEN && (
            <DefaultButton
              className="heading-16-md"
              buttonType="filled"
              sizeType="small"
              buttonColor={"var(--primary-blue)"}
              hoverColor={"var(--blue-600)"}
              disabled={!releaseAble}
              text="배포하기"
              onClick={(e) => {
                e && e.stopPropagation();
                //배포하기
                publishPitching();
              }}
            />
          )}
        </Footer>
      )}
      {alReadyPublish && (
        <ModalLayout
          isOpen={alReadyPublish}
          toggle={() => setAlreadyPublish(false)}
          isFullScreen
        >
          <ConfirmModal
            type="confirm"
            title="경고"
            contents={[
              "이미 배포가 된 피칭심사 입니다.",
              "수정을 하면 다시 배포 해야 합니다.",
              "수정하시겠습니까?",
            ]}
            onClickPositiveBtn={() => {
              publishingModify();
              setAlreadyPublish(false);
            }}
            onClickNegativeBtn={() => {
              setAlreadyPublish(false);
            }}
          />
        </ModalLayout>
      )}
    </ContainerWarpper>
  );
};

const ContainerWarpper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const SurveyContainer = styled.div`
  width: 100%;
  max-width: calc(1200px + 120px);
  display: flex;
  flex-direction: column;
  padding: 32px 60px;
  gap: 56px;
  margin: 0 auto;
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  bottom: 0px;
  padding: 0 60px;
  gap: 16px;

  border-top: 2px solid var(--divider-200, #edeeef);
  background: var(--background-header, #fff);
`;
