import React from "react";
import styled from "styled-components";

import { addCommasToIntegerPart } from "../../../../../common/commonUtil";

import { CurrentInvestmentInfoProps } from "../../interface/InvestmentInfo.interface";

interface Props {
  currentInvestmentInfo: CurrentInvestmentInfoProps;
  currency: string;
}

export const CurrentInvestmentInfo: React.FC<Props> = (props) => {
  return (
    <Container>
      <CardItem>
        <div className="body-14-md group">최초 투자 기업가치 (KRW)</div>
        <div className="heading-18-sb num">
          {addCommasToIntegerPart(
            props.currentInvestmentInfo.firstCompanyValue
          )}
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">기업가치 ({props.currency})</div>
        <div className="heading-18-sb num">
          {addCommasToIntegerPart(props.currentInvestmentInfo.companyValue)}
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">누적 후속투자 ({props.currency})</div>
        <div className="heading-18-sb num">
          {addCommasToIntegerPart(props.currentInvestmentInfo.followUpAmount)}
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">총 발행 주식수 (주)</div>
        <div className="heading-18-sb num">
          {addCommasToIntegerPart(props.currentInvestmentInfo.totalStock)}
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">
          현재 주당 단가 ({props.currency})
        </div>
        <div className="heading-18-sb num">
          {addCommasToIntegerPart(props.currentInvestmentInfo.issuingPrice)}
        </div>
      </CardItem>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 12px;
`;

const CardItem = styled.div`
  flex: 1;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid var(--light-divider-200, #edeeef);
  background: var(--light-background-contents-01, #fff);
  /* light/shdow250-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.05);

  color: var(--light-text-01, #1f2022);

  .group {
    display: flex;
    flex-direction: row;
    color: var(--light-text-04, #7e8086);
  }
  .num {
    width: 100%;
  }
`;
