import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PortfolioPhaseProps } from "../../../../type/data";

interface Props {
  phase: PortfolioPhaseProps;
}

const DBPortfolioPhase: React.FC<Props> = (props) => {
  const getPhaseStyle = (phaseId?: number): React.CSSProperties => {
    switch (phaseId) {
      case 1:
        return {
          border: "1px solid #2196F3",
          color: "#2196F3",
          backgroundColor: "#ffffff",
        };
      case 5:
        return {
          border: "1px solid #2196F3",
          color: "#ffffff",
          backgroundColor: "#2196F3",
        };
      case 6:
      case 9:
        return {
          border: "1px solid #FFCCD6",
          color: "#FF5274",
          backgroundColor: "#FFCCD6",
        };
      case 7:
        return {
          border: "1px solid var(--primary)",
          color: "#ffffff",
          backgroundColor: "var(--primary)",
        };
      case 8:
      case 10:
        return {
          border: "1px solid #828282",
          color: "#828282",
          backgroundColor: "#ffffff",
        };
      default:
        return {};
    }
  };
  return (
    <Container>
      <BubbleContainer
        className="font-regular-12"
        style={getPhaseStyle(props.phase.pfPhaseId)}
      >
        {props.phase.name || "없음"}
      </BubbleContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BubbleContainer = styled.div`
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 3px 10px 1px 10px;
  line-height: 1;

  font-size: 10px;
`;
export default DBPortfolioPhase;
