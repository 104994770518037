import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Arrow } from "../../../assets/image/icon_arrow_black.svg";

interface Props {
  title: string;
  subTitle?: string;
  children: JSX.Element;
}
const AccordionTab: React.FC<Props> = (props) => {
  const { title, subTitle, children } = props;
  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const [isCollapse, setIsCollapse] = useState<boolean>(true);

  const handleClickArrow = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (parentRef.current === null || childRef.current === null) return;

      if (parentRef.current.clientHeight > 0) {
        parentRef.current.style.height = "0";
      } else {
        parentRef.current.style.height = `${childRef.current.clientHeight}px`;
      }

      setIsCollapse((prev) => !prev);
    },
    [isCollapse]
  );

  useEffect(() => {
    if (parentRef.current === null || childRef.current === null) return;

    parentRef.current.style.height = `${childRef.current.clientHeight}px`;
  }, [children]);

  return (
    <Container>
      <Header onClick={handleClickArrow}>
        <div className="front" style={{ gap: 8 }}>
          <span className="heading-16-sb" style={{ color: "var(--text-01)" }}>
            {title}
          </span>
          {subTitle && (
            <span className="heading-14-md" style={{ color: "var(--text-06)" }}>
              {subTitle}
            </span>
          )}
        </div>
        <div className="end">
          <div className={`arrcordion__btn ${isCollapse ? "folded" : ""}`}>
            <Arrow />
          </div>
        </div>
      </Header>
      <ContentsWrapper ref={parentRef}>
        <Contents ref={childRef}>{children}</Contents>
      </ContentsWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 17px;
`;

const Header = styled.div`
  width: 100%;
  height: 26px;
  min-height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  .front {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .end {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .arrcordion__btn {
    width: 26px;
    min-width: 26px;
    max-width: 26px;
    height: 26px;
    min-height: 26px;
    max-height: 26px;
    border-radius: 50%;
    transition: all 0.2s ease;
    rotate: 180deg;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.folded {
      rotate: 0deg;
    }
    :hover {
      background-color: var(--hover-200);
    }
  }
`;

const ContentsWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  transition: height 0.35s ease;
`;

const Contents = styled.div``;

export default React.memo(AccordionTab);
