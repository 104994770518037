import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ModalLayout from "../../../../../../components/modal/ModalLayout";
import { DBAlumniPortfolio } from "../../../../../../type/data";
import {
  AddComma,
  getTransformedId,
  getYMD,
} from "../../../../../../common/commonUtil";
import config from "../../../../../../config";

import { ReactComponent as Arrow } from "../../../../../../assets/image/ic_arrow_go.svg";
import { ReactComponent as Close } from "../../../../../../assets/image/modal_close.svg";

interface Props {
  isOpen: boolean;
  close: () => void;
  portfolioInfo: DBAlumniPortfolio;
}

const AlumniModal: React.FC<Props> = (props) => {
  const portfolioLink =
    config.clientUrl +
    `portfolio/${getTransformedId(props.portfolioInfo.portfolioId)}/info`;
  const getPhaseStyle = (phaseId?: number): React.CSSProperties => {
    switch (phaseId) {
      case 1:
        return {
          border: "1px solid #2196F3",
          color: "#2196F3",
          backgroundColor: "#ffffff",
        };
      case 5:
        return {
          border: "1px solid #2196F3",
          color: "#ffffff",
          backgroundColor: "#2196F3",
        };
      case 6:
        return {
          border: "1px solid #FFCCD6",
          color: "#FF5274",
          backgroundColor: "#FFCCD6",
        };
      case 7:
        return {
          border: "1px solid var(--primary)",
          color: "#ffffff",
          backgroundColor: "var(--primary)",
        };
      case 8:
        return {
          border: "1px solid #828282",
          color: "#828282",
          backgroundColor: "#ffffff",
        };
      default:
        return {};
    }
  };
  return (
    <ModalLayout isOpen={props.isOpen} toggle={props.close}>
      <Container>
        <Header>
          <span className="heading-20-sb">회사정보</span>
          <Close className="close" onClick={props.close} />
        </Header>
        <Body>
          <TitleContainer>
            <div className="container__row" style={{ gap: 10 }}>
              <BubbleContainer
                className="caption-12-md"
                style={getPhaseStyle(props.portfolioInfo.pfPhase.pfPhaseId)}
              >
                {props.portfolioInfo.pfPhase.name || ""}
              </BubbleContainer>
              <span className="heading-20-sb" style={{ fontWeight: 600 }}>
                {props.portfolioInfo.companyName || ""}
              </span>
            </div>
            <a
              className="container__row"
              href={portfolioLink}
              target={"_blank"}
              style={{
                lineHeight: 1,
                gap: 4,
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <span
                className="body-14-sb"
                style={{ paddingTop: 2, color: "#2196F3" }}
              >
                스타트업 정보
              </span>
              <Arrow />
            </a>
          </TitleContainer>
          <Table className="body-14-rg">
            <TitleCell style={{ gridArea: "industrialTitle" }}>
              산업기술분류
            </TitleCell>
            <ContentsCell style={{ gridArea: "industrial" }}>
              {props.portfolioInfo.pfIndustrialTech
                ? props.portfolioInfo.pfIndustrialTech
                    .map((item) => `#${item.acIndustrialTech.name}`)
                    .join(", ")
                : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "descriptionTitle" }}>
              아이템 요약
            </TitleCell>
            <ContentsCell style={{ gridArea: "description" }}>
              {props.portfolioInfo.description
                ? props.portfolioInfo.description
                : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "participantTitle" }}>
              담당자(조력자)
            </TitleCell>
            <ContentsCell style={{ gridArea: "participant" }}>
              {props.portfolioInfo.pfParticipant
                ? props.portfolioInfo.pfParticipant
                    .map((item) => `${item.acUser.name}`)
                    .join(", ")
                : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "fundAccountTitle" }}>
              투자계정
            </TitleCell>
            <ContentsCell style={{ gridArea: "fundAccount" }}>
              {props.portfolioInfo.acFundAccount
                ? props.portfolioInfo.acFundAccount
                    .map((item) =>
                      item.nickName ? item.nickName : item.nameKo
                    )
                    .join(", ")
                : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "stageTitle" }}>라운드</TitleCell>
            <ContentsCell style={{ gridArea: "stage" }}>
              {props.portfolioInfo.round ? props.portfolioInfo.round.name : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "exitDateTitle" }}>
              엑싯날짜
            </TitleCell>
            <ContentsCell style={{ gridArea: "exitDate" }}>
              {props.portfolioInfo.exitDate
                ? getYMD(props.portfolioInfo.exitDate)
                : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "areaTitle" }}>소재지</TitleCell>
            <ContentsCell style={{ gridArea: "area" }}>
              {props.portfolioInfo.addr1 ? props.portfolioInfo.addr1 : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "investmentDateTitle" }}>
              빈티지
            </TitleCell>
            <ContentsCell style={{ gridArea: "investmentDate" }}>
              {props.portfolioInfo.investmentDate
                ? props.portfolioInfo.investmentDate.slice(0, 4)
                : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "purchasePrincipalTitle" }}>
              투자금
            </TitleCell>
            <ContentsCell style={{ gridArea: "purchasePrincipal" }}>
              {props.portfolioInfo.purchasePrincipal
                ? AddComma(props.portfolioInfo.purchasePrincipal) + "원"
                : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "sellPrincipalTitle" }}>
              회수금
            </TitleCell>
            <ContentsCell style={{ gridArea: "sellPrincipal" }}>
              {props.portfolioInfo.sellPrincipal
                ? AddComma(props.portfolioInfo.sellPrincipal) + "원"
                : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "realizedMultipleTitle" }}>
              실현 멀티플
            </TitleCell>
            <ContentsCell style={{ gridArea: "realizedMultiple" }}>
              {props.portfolioInfo.realizedMultiple
                ? props.portfolioInfo.realizedMultiple + "X"
                : "-"}
            </ContentsCell>

            <TitleCell style={{ gridArea: "salesAmountTitle" }}>매출</TitleCell>
            <ContentsCell style={{ gridArea: "salesAmount" }}>
              {props.portfolioInfo.salesAmount
                ? AddComma(props.portfolioInfo.salesAmount) + "원"
                : "-"}
            </ContentsCell>
          </Table>
        </Body>
      </Container>
    </ModalLayout>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  .container__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .close {
    cursor: pointer;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid #c4c4c4;
`;
const Body = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const BubbleContainer = styled.div`
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 3px 10px 1px 10px;
  line-height: 1;

  font-size: 10px;
`;

const Table = styled.div`
  display: grid;
  background-color: #f0f0f0;
  grid-template-columns: 160px 200px 160px 200px;
  grid-auto-rows: minmax(56px, auto);
  grid-row-gap: 1px;
  grid-template-areas:
    "industrialTitle industrial industrial industrial"
    "descriptionTitle description description description"
    "participantTitle participant participant participant"
    "fundAccountTitle fundAccount fundAccount fundAccount"
    "stageTitle stage exitDateTitle exitDate"
    "areaTitle area investmentDateTitle investmentDate"
    "purchasePrincipalTitle purchasePrincipal sellPrincipalTitle sellPrincipal"
    "realizedMultipleTitle realizedMultiple salesAmountTitle salesAmount";
`;

const TitleCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  background-color: #fbfbfb;
  color: #828282;
`;

const ContentsCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background-color: #ffffff;
  color: #000000;
`;
export default AlumniModal;
