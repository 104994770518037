import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import useMinutes from "./hooks/useMinutes";

import { ReactComponent as FileDownload } from "../../../assets/image/icon_download.svg";
import { MinutePage } from "./components/MinutePage";
import useGoogle from "../../../hooks/useGoogle";
import PDFDocument from "./components/PDFDocument";
import { CoverPage } from "./components/CoverPage";
import ModalLayout from "../../../components/modal/ModalLayout";

import SpinnerModal from "../../../components/modal/SpinnerModal";
import { ReactComponent as BackArrow } from "../../../assets/image/icon_back_arrow.svg";
import { ReactComponent as Down } from "../../../assets/image/icon_download.svg";
import { useSearchParams } from "react-router-dom";
import ProgressModalStatic from "../../../components-2.0v/progress/ProgressModalStatic";
import ReactToPrint from "react-to-print";
import { DefaultButton } from "lattice_core";

export const MinutesPreview = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const year = parseInt(searchParams.get("year") || "0");
  const quarterNum = parseInt(searchParams.get("quarterNum") || "0");
  const ref = useRef<HTMLDivElement>(null);
  const [
    isGapiReady,
    isLoggedIn,
    handleSignInClick,
    handleSignOutClick,
    getPDFBlobFile,
    onLoginLattice,
    createFolder,
  ] = useGoogle();
  const [
    logoImage,
    refArray,
    pdfData,
    pdfDataWidthPageNum,
    selectedLoadPdf,
    componentData,
    pageData,
    progressIcon,
    openProgress,
    completePage,
    progressFail,
    progressMsg,
    fileDonwloading,
    selectedMinutePage,
    readyForCreatePdf,
    pdfDownloading,
    onClickExit,
    getComponents,
    onLoaedPDF,
    onClickDownloadPdf,
    onLoadedCanvas,
    setPdfDownloading,
  ] = useMinutes(year, quarterNum);

  useEffect(() => {
    window.document.title = `투자 리스크 관리 위원회 의사록 ${year}_${quarterNum}Q`;
  }, []);

  if (componentData.length === 0) {
    return (
      <Wrapper>
        <Container>
          <div className="tab_layout_header">
            <FlexRow>
              <BackArrow className="back__btn" onClick={onClickExit} />
              <span
                className="font-medium-20"
                style={{ color: "var(--text-01)", paddingTop: 4 }}
              >
                리스크 관리 위원회 의사록
              </span>
            </FlexRow>
          </div>
          <LoadingContainer>
            <ModalLayout isOpen isFullScreen>
              <ProgressModalStatic
                SvgIcon={FileDownload}
                progress={
                  pdfData === undefined
                    ? pdfDataWidthPageNum.length === 0
                      ? 0
                      : 100
                    : (pdfDataWidthPageNum.length /
                        (pdfData ? pdfData.length : 1)) *
                      100
                }
                message={`리스크 관리 의사록 구성에 필요한 PDF파일 다운로드...`}
                progressFail={progressFail}
                transfromPixel={(n) => n}
              />
            </ModalLayout>
            <div style={{ opacity: 0 }}>
              {selectedLoadPdf !== undefined && (
                <PDFDocument
                  portfolioId={selectedLoadPdf.portfolioId}
                  file={selectedLoadPdf.blobFile}
                  onLoaded={(pageNum, images) => {
                    onLoaedPDF({
                      ...selectedLoadPdf,
                      blobFile: undefined,
                      isLoaded: true,
                      totalPage: pageNum,
                      pages: images,
                    });
                  }}
                  onError={() => {
                    onLoaedPDF({
                      ...selectedLoadPdf,
                      blobFile: undefined,
                      isLoaded: true,
                      totalPage: 1,
                      isError: true,
                    });
                  }}
                />
              )}
            </div>
          </LoadingContainer>
        </Container>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Container>
          <div className="tab_layout_header">
            <FlexRow>
              <BackArrow className="back__btn" onClick={onClickExit} />
              <span
                className="font-medium-20"
                style={{ color: "var(--text-01)", paddingTop: 4 }}
              >
                리스크 관리 위원회 의사록
              </span>
            </FlexRow>

            <ReactToPrint
              trigger={() => (
                <DefaultButton
                  disabled={pageData.length === 0}
                  sizeType="small"
                  buttonColor="var(--primary-blue)"
                  hoverColor="var(--blue-600)"
                  text="PDF 내보내기"
                />
              )}
              content={() => ref.current}
              onBeforeGetContent={() => {
                if (pageData.length === 0) return;
                setPdfDownloading(true);
                return new Promise((resolve) => {
                  setTimeout(resolve, 10);
                });
              }}
              onAfterPrint={() => setPdfDownloading(false)}
            />
          </div>
          <div className="contents scroll__invisible">
            {pageData.length === 0 && (
              <LongPage>
                {componentData.map((item, index) => {
                  return getComponents(item, index);
                })}
              </LongPage>
            )}
            <PageContainer>
              <MinuteList ref={ref} style={{ gap: pdfDownloading ? 0 : 16 }}>
                {pageData.length > 0 && (
                  <div id={`minute__page__0`} className="minute__pager">
                    <CoverPage
                      year={year}
                      quarterNum={quarterNum}
                      logoImage={logoImage}
                    />
                  </div>
                )}
                {pageData.map((item, index) => {
                  return (
                    <div
                      id={`minute__page__${index + 1}`}
                      className="minute__pager"
                    >
                      <MinutePage
                        key={index}
                        id={"minute__page"}
                        pageNum={index + 1}
                        totalPageNum={pageData.length}
                        data={item}
                        year={year}
                        quaterNum={quarterNum}
                      />
                    </div>
                  );
                })}
              </MinuteList>
            </PageContainer>
          </div>
        </Container>
        {pdfDownloading && (
          <SpinnerModal open={pdfDownloading} message="PDF 내보내기 준비..." />
        )}
      </Wrapper>
    );
  }
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: row;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;

  border-left: 1px solid var(--gray-300);
  border-right: 1px solid var(--gray-300);
  .tab_layout_header {
    width: 100%;
    height: 60px;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;

    padding: 0 24px;
    border-bottom: 1px solid var(--gray-300);

    .back__btn {
      cursor: pointer;
    }

    .exit__btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      cursor: pointer;

      .exit__icon {
        width: 18px;
        height: 18px;
      }
      span {
        color: var(--text-01);
      }
    }
  }
  .contents {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: gray;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    margin: 0 24px;
  }

  @media print {
    @page {
      size: 210mm 297mm;
      margin: 0;
    }
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const LongPage = styled.div`
  position: absolute;
  opacity: 0;
  width: 210mm;
  min-width: 210mm;
  max-width: 210mm;
  height: max-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: -10;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: max-content;
  display: flex;
  flex-direction: column;

  padding-top: 24px;
  padding-bottom: 24px;

  align-items: center;
  .element__hide {
    display: none;
  }
`;

const MinuteList = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
`;
