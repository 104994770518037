import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { File } from "./file";

interface Props {
  file?: any;
  fileName?: string;
  customIcon?: JSX.Element;
  className?: string;
  width?: number;
  isDeletable?: boolean; // 삭제 icon 유무
  isEditable?: boolean; // 파일 수정 권한
  errorMsg?: string;
  onChangeFile?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickFile?: () => void;
  onDelete?: () => void;
  checkError?: (file: any) => boolean;
  transfromPixel?: (value: number) => number;
}
const FileUploaderSingle: React.FC<Props> = (props) => {
  const { transfromPixel = (value: number) => value } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [isError, setIsError] = useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    console.log(e.target.files[0]);

    props.onChangeFile && props.onChangeFile(e);

    setIsError(
      props.checkError && props.checkError(e.target.files[0]) ? true : false
    );

    e.target.value = "";
  };

  return (
    <Container
      width={props.width}
      className={props.className || ""}
      transfromPixel={transfromPixel}
    >
      <InputContainer
        style={{
          border: `${transfromPixel(1)}px solid ${
            isError ? "#F6444F" : "#edeeef"
          }`,
        }}
        transfromPixel={transfromPixel}
      >
        {props.file === undefined ? (
          <Default
            onClick={() =>
              props.isEditable && inputRef.current && inputRef.current.click()
            }
            transfromPixel={transfromPixel}
          >
            <div className="svg-container">
              <svg
                width="16"
                height="16"
                viewBox={`0 0 16 16`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.69 7.59039H8.41002V3.31039C8.41002 3.08039 8.23002 2.90039 8.00002 2.90039C7.77002 2.90039 7.59002 3.08039 7.59002 3.31039V7.59039H3.31002C3.08002 7.59039 2.90002 7.77039 2.90002 8.00039C2.90002 8.23039 3.08002 8.41039 3.31002 8.41039H7.59002V12.6904C7.59002 12.9204 7.77002 13.1004 8.00002 13.1004C8.23002 13.1004 8.41002 12.9204 8.41002 12.6904V8.41039H12.69C12.92 8.41039 13.1 8.23039 13.1 8.00039C13.1 7.77039 12.92 7.59039 12.69 7.59039Z"
                  fill="#1F2022"
                />
              </svg>
            </div>
            <span>파일 업로드</span>
          </Default>
        ) : (
          <Uploaded
            width={props.width}
            isDeletable={
              props.isDeletable === undefined ? true : props.isDeletable
            }
            transfromPixel={transfromPixel}
          >
            <div className="svg-container">
              {props.customIcon ? props.customIcon : <File />}
            </div>

            <div
              className="file-name"
              style={{
                cursor: props.onClickFile ? "pointer" : "default",
              }}
              onClick={(e) => {
                e.stopPropagation();
                props.onClickFile && props.onClickFile();
              }}
            >
              <span>{props.fileName || ""}</span>
            </div>
            <div
              className="svg-container"
              style={{
                display: props.isDeletable && props.isEditable ? "" : "none",
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox={`0 0 16 16`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="delete-icon"
                onClick={() => {
                  props.onDelete && props.onDelete();
                  setIsError(false);
                }}
              >
                <g clip-path="url(#clip0_2627_7895)">
                  <path
                    d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                    fill="#EDEEEF"
                  />
                  <path
                    d="M10.4499 9.98952L8.4599 7.99952L10.4199 6.02952C10.5499 5.89952 10.5499 5.68952 10.4199 5.55952C10.3599 5.49952 10.2799 5.45952 10.1899 5.45952C10.0999 5.43952 10.0199 5.48952 9.9499 5.55952L7.9899 7.52952L6.0299 5.55952C5.9099 5.43952 5.6899 5.43952 5.5599 5.55952C5.4299 5.68952 5.4299 5.89952 5.5599 6.02952L7.5299 7.99952L5.5799 9.95952C5.5099 10.0195 5.4699 10.0995 5.4699 10.1795C5.4699 10.2595 5.4899 10.3495 5.5499 10.4195C5.6099 10.4895 5.6899 10.5295 5.7699 10.5295C5.8599 10.5295 5.9399 10.5095 5.9999 10.4595L7.9999 8.45952L9.9499 10.4195C10.0099 10.4895 10.0899 10.5295 10.1799 10.5295H10.1999C10.2799 10.5295 10.3599 10.4995 10.4099 10.4495C10.4799 10.3895 10.5199 10.3095 10.5299 10.2295C10.5299 10.1395 10.5099 10.0595 10.4499 9.98952Z"
                    fill="#9C9FA3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2627_7895">
                    <rect
                      width="14"
                      height="14"
                      fill="white"
                      transform="translate(1 1)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </Uploaded>
        )}
        <input
          ref={inputRef}
          type="file"
          disabled={!props.isEditable}
          onChange={onChange}
          accept="*"
        />
      </InputContainer>

      <div
        className="error-msg"
        style={{
          display: isError ? "block" : "none",
        }}
      >
        {props.errorMsg}
      </div>
    </Container>
  );
};

const Container = styled.div(
  ({
    width,
    transfromPixel,
  }: {
    width?: number;
    transfromPixel: (value: number) => number;
  }) => css`
    width: ${width === undefined ? "100%" : `${transfromPixel(width)}px`};
    background: #ffffff;
    border-radius: ${transfromPixel(4)}px;

    .error-msg {
      margin-top: ${transfromPixel(8)}px;
      color: #f6444f;
      text-align: start;
    }
  `
);

const InputContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    padding: ${transfromPixel(9)}px ${transfromPixel(12)}px;

    border-radius: ${transfromPixel(4)}px;

    position: relative;

    .svg-container {
      width: 24px;
      height: 24px;
      transform: scale(calc(${transfromPixel(24)} / 24));
    }

    svg {
      width: 100%;
      height: 100%;
    }

    input {
      display: none;
    }

    :hover {
      background: #f3f4f4;
    }
  `
);

const Default = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${transfromPixel(4)}px;
    :hover {
      cursor: pointer;
    }
  `
);

const Uploaded = styled.div(
  ({
    width,
    isDeletable,
    transfromPixel,
  }: {
    width?: number;
    isDeletable?: boolean;
    transfromPixel: (value: number) => number;
  }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    .file-name {
      flex: 1;
      margin: 0 ${transfromPixel(6)}px 0 ${transfromPixel(4)}px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: ${transfromPixel(width || 332) -
      transfromPixel(24) -
      transfromPixel(6) -
      transfromPixel(4) -
      (isDeletable ? transfromPixel(24) + 10 : 0) -
      transfromPixel(12) -
      transfromPixel(12)}px;
      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .delete-icon {
      :hover {
        cursor: pointer;
        // TODO 이중 hover
      }
    }
  `
);

export default FileUploaderSingle;
