import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import {
  FundAccountDetailInfo,
  FundAccountPurpose,
} from "../../interface/FundAccountDetail.interface";
import { AddComma } from "../../../../../common/commonUtil";
import { OrderingBtn } from "../component/OrderingBtn";
import { SelectorProps } from "../../../../../type/data";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  data: FundAccountDetailInfo;
}
export const FundSubPurpose: React.FC<Props> = (props) => {
  const [subPurposeList, setSubPurposeList] = useState<
    FundAccountPurpose[] | undefined
  >(undefined);
  const [subPurpose, setSubPurpose] = useState<FundAccountPurpose | undefined>(
    undefined
  );
  const [selectList, setSelectList] = useState<SelectorProps[]>([]);
  const [selectedItem, setSelectedItem] = useState<SelectorProps | undefined>(
    undefined
  );

  const centerTextPlugin = {
    id: "centerTextPlugin",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      ctx.save();
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = "10px SUIT";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#7E8086";
      ctx.fillText("단위 : 백만원", centerX, centerY);
      ctx.restore();
    },
  };

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },

    cutout: 40,
  };
  const data = {
    labels: ["달성금액", "남은금액"],
    datasets: [
      {
        label: "",
        data: [
          subPurpose?.purposeAmount || 0,
          (subPurpose?.amount || 0) - (subPurpose?.purposeAmount || 0),
        ],
        backgroundColor: ["rgba(106, 85, 244, 1)", "rgba(237, 238, 239, 1)"],
        borderColor: ["rgba(106, 85, 244, 1)", "rgba(237, 238, 239, 1)"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const purpose = props.data.investmentPurpose.filter((item) => !item.isMain);
    const selectedList = purpose.map((item, index): SelectorProps => {
      return { id: index + 1, text: `특수목적 ${index + 1}`, data: item };
    });
    setSubPurposeList(purpose);
    setSelectList(selectedList);
    setSelectedItem(selectedList[0]);
  }, [props.data]);

  useEffect(() => {
    if (selectedItem) {
      setSubPurpose(selectedItem.data);
    }
  }, [selectedItem]);

  return (
    <Container>
      <TitleContainer className="heading-18-sb">
        <span>특수목적 {selectedItem?.id}</span>
        {subPurpose && (
          <CustomColorLabel
            bgColor="var(--purple-50)"
            textColor="var(--purple-400)"
          >
            {subPurpose.percent}% 이상 투자
          </CustomColorLabel>
        )}
      </TitleContainer>
      <DescriptionContainer className="body-14-md">
        {subPurpose ? subPurpose.target : "특수 목적이 없습니다."}
      </DescriptionContainer>

      {subPurpose && subPurpose.purposeAmount > 0 && (
        <BodyContainer>
          <FlexRow>
            <FlexColumn style={{ flex: 1, gap: 14 }}>
              <FlexColumn>
                <div className="body-14-sb" style={{ color: "var(--text-04)" }}>
                  달성률
                </div>
                <div
                  className="heading-20-b"
                  style={{ color: "var(--purple-500)" }}
                >
                  {(
                    (subPurpose.purposeAmount / subPurpose.amount) *
                    100
                  ).toFixed(0)}
                  %
                </div>
              </FlexColumn>
              <FlexColumn>
                <div className="body-14-sb" style={{ color: "var(--text-04)" }}>
                  달성까지 남은 금액
                </div>
                <div
                  className="heading-20-b"
                  style={{ color: "var(--purple-500)" }}
                >
                  {AddComma(
                    (
                      (subPurpose.amount - subPurpose.purposeAmount) /
                      1000000
                    ).toFixed(0)
                  )}{" "}
                  백만원
                </div>
              </FlexColumn>
            </FlexColumn>
            <div style={{ flex: 2, height: 140 }}>
              <Doughnut
                style={{ display: "inline-block" }}
                data={data}
                options={options}
                width={140}
                height={140}
                plugins={[centerTextPlugin]}
              />
            </div>
          </FlexRow>
        </BodyContainer>
      )}

      {subPurposeList && subPurposeList.length > 0 && (
        <div className="purpose__list">
          <OrderingBtn
            selectList={selectList}
            defaultData={
              selectedItem ? `특수목적 ${selectedItem.id}` : undefined
            }
            onChangeData={(data) => {
              setSelectedItem(data);
            }}
          />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;

  border-bottom: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);

  .purpose__list {
    position: absolute;
    top: 20px;
    right: 30px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: var(--text-05);
  margin-top: 4px;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--gray-300);
`;
