import React from "react";
import styled from "styled-components";
import useFundAccountInfo from "../hook/useFundAccountDetail";
import { FundOperatingStatus } from "./organism/FundOperatingStatus";
import { FundDuration } from "./organism/FundDuration";
import { FundMainPurpose } from "./organism/FundMainPurpose";
import { FundSubPurpose } from "./organism/FundSubPurpose";
import { FundConditions } from "./organism/FundConditions";
import { FundManager } from "./organism/FundManager";

import { UniqueFundInfo } from "./organism/UniqueFundInfo";

import { ReactComponent as Arrow } from "../../../../assets/image/new_icon_arrow.svg";
import { InvestmentPerformanceReview } from "./organism/InvestmentPerformanceReview";
import { ExpectationManagementSection } from "./organism/ExpectationManagementSection";

const MobileFundAccountDetail = () => {
  const [
    isUniqueAccount,
    fundAccountId,
    fundAccountInfo,
    toDay,
    fundManager,
    investmentPerformance,
    getLabel,
    goToBack,
  ] = useFundAccountInfo();
  if (!fundAccountInfo) return null;
  return (
    <Container>
      <FundAccountDetailContainer>
        <ContentsHeader>
          <FlexColumn
            style={{
              gap: 2,
              padding: "8px 16px",
              borderBottom: "1px solid var(--gray-200)",
            }}
          >
            <FlexRow style={{ gap: 10, alignItems: "center" }}>
              <Arrow className="back__btn" onClick={goToBack} />
              <span
                className="heading-18-sb"
                style={{ color: "var(--text-01)" }}
              >
                {fundAccountInfo.nameKo}
              </span>
              {getLabel()}
            </FlexRow>
            <FlexRow
              className="body-14-sb"
              style={{ color: "var(--text-05)", marginLeft: 34 }}
            >
              {fundAccountInfo.nameEn}
            </FlexRow>
          </FlexColumn>
          <FlexRow
            style={{
              flexWrap: "wrap",
              columnGap: 12,
              padding: "8px 24px",
              borderBottom: "1px solid var(--gray-200)",
            }}
          >
            {fundAccountInfo.concept && (
              <TitleContents className="body-14-md">
                <span className="title">컨셉</span>
                <span>{fundAccountInfo.concept}</span>
              </TitleContents>
            )}
            {fundAccountInfo.investmentOrganizationCount > 0 && (
              <TitleContents className="body-14-md">
                <span className="title">LP수</span>
                <span>{fundAccountInfo.investmentOrganizationCount}개</span>
              </TitleContents>
            )}
            {fundAccountInfo.baseRate && (
              <TitleContents className="body-14-md">
                <span className="title">기준수익률</span>
                <span>{fundAccountInfo.baseRate}%</span>
              </TitleContents>
            )}
            <TitleContents className="body-14-md">
              <span className="title">시리얼</span>
              <span>{fundAccountInfo.serialNumber}</span>
            </TitleContents>
          </FlexRow>
        </ContentsHeader>
        <ContentsBody>
          {isUniqueAccount && <UniqueFundInfo />}

          {!isUniqueAccount && <FundOperatingStatus data={fundAccountInfo} />}
          {!isUniqueAccount && (
            <FundDuration data={fundAccountInfo} toDate={toDay} />
          )}

          {!isUniqueAccount && <FundMainPurpose data={fundAccountInfo} />}
          {!isUniqueAccount && <FundSubPurpose data={fundAccountInfo} />}

          {!isUniqueAccount && <FundConditions data={fundAccountInfo} />}
          {!isUniqueAccount && <FundManager data={fundManager} />}

          <InvestmentPerformanceReview
            showTable
            headerSticky
            selectedFundAccountId={[fundAccountId]}
          />
          <ExpectationManagementSection
            selectedFundAccountId={[fundAccountId]}
          />
        </ContentsBody>
      </FundAccountDetailContainer>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const FundAccountDetailContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;

  display: flex;
  flex-direction: column;

  z-index: 10;
  background: var(--background-header);
  .back__btn {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    path {
      fill: var(--text-01);
    }
  }
`;

const ContentsBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  .flex__1 {
    flex: 1;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TitleContents = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: var(--text-01);
  .title {
    color: var(--text-06);
  }
`;

export default MobileFundAccountDetail;
