import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import SignatureImage from "./SignatureImage";
import SignatureModal from "./SignatureModal";

import { ReactComponent as Edit } from "../../../../../assets/image/signature__edit.svg";
import { ReactComponent as Del } from "../../../../../assets/image/signature__del.svg";

import { SignatureInfo } from "../../../../../type/data";
import { MinutesProps } from "../MobileEvaluationTask";
import DefaultButton from "../../../../../components/button/DefaultButton";
import { AutoHeightTextarea } from "../../../../mytask/organisms/AutoHeightTextarea";
import DatabaseDropdownRadio from "../../../../database/desktop/component/DatabaseDropdownRadio";
import ModalLayout from "../../../../../components/modal/ModalLayout";
import config from "../../../../../config";
import ColumnTitleBox from "../../../../../components/box/ColumnTitleBox";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { Selector } from "../../../../../components-2.0v/selector/Selector";

interface Props {
  minutesDocument: MinutesProps;
  signatureData?: SignatureInfo;
  onChangeData: (data: MinutesProps) => void;
  onChangeSignature: (data?: SignatureInfo) => void;
  portfolioId: number;
  logoImage?: string;
}
export const InvestmentDeliberationCommitteeMinutes: React.FC<Props> = (
  props
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [signData, updateSignData] = useState<SignatureInfo | undefined>(
    props.signatureData
  );

  const [signModal, updateSignModal] = useState<boolean>(false);
  const [trueFalse, updateTruefalse] = useState<boolean | undefined>(
    props.minutesDocument.isAgreement
  );
  const [contents, updateContents] = useState<string>(
    props.minutesDocument.comment || ""
  );

  const getNewStockContents = () => {
    const arr: { title: string; contents: string }[] = [];
    if (props.minutesDocument.fundAccount) {
      arr.push({
        title: "투자재원",
        contents: props.minutesDocument.fundAccount.accountName,
      });
    }
    if (props.minutesDocument.companyName) {
      arr.push({
        title: "업체명",
        contents: props.minutesDocument.companyName,
      });
    }
    if (props.minutesDocument.date) {
      arr.push({ title: "개최 일시", contents: props.minutesDocument.date });
    }
    if (props.minutesDocument.stockType) {
      arr.push({
        title: "주식의 종류",
        contents: props.minutesDocument.stockType,
      });
    }
    if (props.minutesDocument.totalInvestmentAmount) {
      arr.push({
        title: "총 투자금액(동일 라운드)",
        contents: props.minutesDocument.totalInvestmentAmount,
      });
    }

    if (props.minutesDocument.investmentAmount) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.acfundAccountId === 1
            ? "당사 투자 금액" // 본계정인 경우 "당사 투자금액"
            : "당 조합 투자 금액",
        contents: props.minutesDocument.investmentAmount,
      });
    }

    if (props.minutesDocument.issuingPrice) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.newOldStock?.newOldStockId === 1
            ? "신주 발행가"
            : "구주 매입가",
        contents: props.minutesDocument.issuingPrice,
      });
    }

    if (
      props.minutesDocument.fundAccount?.newOldStock?.newOldStockId === 2 &&
      props.minutesDocument.curNewIssuingPrice
    ) {
      arr.push({
        title: "최근 신주 발행가",
        contents: props.minutesDocument.curNewIssuingPrice,
      });
    }

    if (props.minutesDocument.postTotalStockNumber) {
      arr.push({
        title: "총 발행 주식 수(동일 라운드)",
        contents: props.minutesDocument.postTotalStockNumber,
      });
    }
    if (props.minutesDocument.stockNumber) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.acfundAccountId === 1
            ? "당사 배정 주식 수" // 본계정인 경우 "당사 배정 주식 수"
            : "당 조합 배정 주식 수",
        contents: props.minutesDocument.stockNumber,
      });
    }
    if (props.minutesDocument.shareholdingRatio) {
      arr.push({
        title: "투자 후 지분율",
        contents: props.minutesDocument.shareholdingRatio,
      });
    }
    return arr;
  };

  const getOldStockContents = () => {
    const arr: { title: string; contents: string }[] = [];
    if (props.minutesDocument.fundAccount) {
      arr.push({
        title: "투자재원",
        contents: props.minutesDocument.fundAccount.accountName,
      });
    }
    if (props.minutesDocument.companyName) {
      arr.push({
        title: "업체명",
        contents: props.minutesDocument.companyName,
      });
    }

    if (props.minutesDocument.buyCompany) {
      arr.push({
        title: "매입처",
        contents: props.minutesDocument.buyCompany,
      });
    }

    if (props.minutesDocument.date) {
      arr.push({ title: "개최 일시", contents: props.minutesDocument.date });
    }
    if (props.minutesDocument.stockType) {
      arr.push({
        title: "주식의 종류",
        contents: props.minutesDocument.stockType,
      });
    }

    if (props.minutesDocument.totalTradeAmount) {
      arr.push({
        title: "총 거래 금액",
        contents: props.minutesDocument.totalTradeAmount,
      });
    }

    if (props.minutesDocument.investmentAmount) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.acfundAccountId === 1
            ? "당사 거래 금액" // 본계정인 경우 "당사 투자금액"
            : "당 조합 거래 금액",
        contents: props.minutesDocument.investmentAmount,
      });
    }

    if (props.minutesDocument.issuingPrice) {
      arr.push({
        title: "구주 매입가",
        contents: props.minutesDocument.issuingPrice,
      });
    }
    if (props.minutesDocument.curNewIssuingPrice) {
      arr.push({
        title: "최근 신주 발행가",
        contents: props.minutesDocument.curNewIssuingPrice,
      });
    }

    if (props.minutesDocument.discount) {
      arr.push({
        title: "할인율",
        contents: props.minutesDocument.discount,
      });
    }

    if (props.minutesDocument.totalTradeStock) {
      arr.push({
        title: "총 배정 주식 수",
        contents: props.minutesDocument.totalTradeStock,
      });
    }

    if (props.minutesDocument.stockNumber) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.acfundAccountId === 1
            ? "당사 배정 주식 수" // 본계정인 경우 "당사 배정 주식 수"
            : "당 조합 배정 주식 수",
        contents: props.minutesDocument.stockNumber,
      });
    }

    if (props.minutesDocument.shareholdingRatio) {
      arr.push({
        title: "투자 후 지분율",
        contents: props.minutesDocument.shareholdingRatio,
      });
    }

    if (props.minutesDocument.buyCompanyValue) {
      arr.push({
        title: "구주 매입가 기준 기업 가치",
        contents: props.minutesDocument.buyCompanyValue,
      });
    }

    return arr;
  };

  const getRowItemsForAllDocs = () => {
    if (props.minutesDocument.fundAccount?.newOldStock?.newOldStockId === 1) {
      return getNewStockContents();
    }

    if (props.minutesDocument.fundAccount?.newOldStock?.newOldStockId === 2) {
      return getOldStockContents();
    }

    return [];
  };

  const onChangeData = () => {
    const newData = { ...props.minutesDocument };
    newData.isAgreement = trueFalse;
    newData.comment = contents;
    // newData.signature = signData;

    props.onChangeData(newData);
  };

  useEffect(() => {
    onChangeData();
  }, [trueFalse, contents]);

  useEffect(() => {
    console.log(signData);

    props.onChangeSignature(signData);
  }, [signData]);

  return (
    <Container id="root__component" ref={containerRef}>
      <SubTitle className="font-medium-14">
        {props.minutesDocument.companyName} 투자 건에 대하여 <br />
        아래와 같이 투자심의위원회를 개최함.
      </SubTitle>

      <ContentsTable className="font-regular-14">
        {getRowItemsForAllDocs().map((item, index) => {
          return (
            <RowItem key={index}>
              <span className="row__item__title">{`${index + 1}. ${
                item.title
              }`}</span>
              <span className="row__item__contents">{item.contents}</span>
            </RowItem>
          );
        })}
      </ContentsTable>

      <RowItem
        className="font-regular-14"
        style={{ marginTop: 12, paddingLeft: 24 }}
      >
        <span className="row__item__title">
          {getRowItemsForAllDocs().length + 1}. 심의위원 의견
        </span>
      </RowItem>
      <div style={{ width: "100%", padding: "0px 24px", marginTop: 6 }}>
        <AutoHeightTextarea
          placeholder="의견을 20자 이상 적어주세요."
          maxLine={10}
          maxLength={200}
          defaultValue={contents}
          onChange={(data) => {
            updateContents(data);
          }}
          onBlur={(data) => {
            const contents = data.target.value;
            updateContents(contents);
          }}
        />
      </div>

      <FlexRow
        style={{ padding: 24, justifyContent: "space-between", gap: 24 }}
      >
        <ColumnTitleBox title="가/부">
          <Selector
            placehoder="선택"
            selectList={[
              { id: 1, text: "가:O", data: true },
              { id: 2, text: "부:X", data: false },
            ]}
            defaultData={
              trueFalse === undefined
                ? undefined
                : trueFalse
                ? { id: 1, text: "가:O", data: true }
                : { id: 2, text: "부:X", data: false }
            }
            onChangeData={(data) => {
              updateTruefalse(data.data);
            }}
          />
        </ColumnTitleBox>

        <RowTitleBox title="서명" titleWidth={50}>
          {signData ? (
            <Signature>
              {signData.type === 0 && signData.url && (
                <img
                  src={signData.url}
                  className="sign-img"
                  onClick={() => {
                    updateSignModal(true);
                  }}
                />
              )}
              {signData.type === 1 && signData.path && (
                <SignatureImage
                  signatureData={signData.path}
                  onClick={() => {
                    updateSignModal(true);
                  }}
                  onLoaded={(index) => {}}
                  index={1}
                />
              )}
            </Signature>
          ) : (
            <div style={{ marginTop: 6 }}>
              <DefaultButton
                className="font-medium-12"
                type="outline"
                width={70}
                height={32}
                onClick={() => updateSignModal(true)}
              >
                서명하기
              </DefaultButton>
            </div>
          )}
        </RowTitleBox>
      </FlexRow>

      <ModalLayout
        isOpen={signModal}
        isFullScreen
        toggle={() => {
          updateSignModal(false);
        }}
      >
        <SignatureModal
          toggle={() => {
            updateSignModal(false);
          }}
          callBackSignData={(originData, trimData) => {
            console.log(originData);
            const newData = { ...signData };

            newData.type = 1;
            newData.path = [...originData];

            updateSignData(newData);
            updateSignModal(false);
          }}
          callBackSignImg={(url) => {
            console.log(url);
            const newData = { ...signData };

            newData.type = 0;
            newData.url = url;

            updateSignData(newData);
            updateSignModal(false);
          }}
          canUploadImage={true}
          canSetDefault={true}
          portfolioId={props.portfolioId}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* transform: scale(0.8); */
  overflow: scroll;
  background-color: #ffffff;
`;
const SubTitle = styled.div<{ marginTop?: number }>`
  width: 100%;
  white-space: break-spaces;
  padding: 24px;
`;

const ContentsTable = styled.div<{ marginTop?: number }>`
  width: 100%;
  height: auto;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : "0px")};

  display: flex;
  flex-direction: column;
  padding: 0 24px;

  gap: 6px;
`;
const RowItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  line-height: 24px;
  letter-spacing: 0.1px;

  .row__item__title {
    color: #828282;
    width: 100%;
  }

  .row__item__contents {
    color: #000000;
    width: 100%;
    padding-left: 14px;
  }
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Signature = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  .sign-img {
    width: 100%;
  }

  .signature__option {
    display: none;
    .signature__option__icon {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
      cursor: pointer;

      :hover {
        path {
          fill: var(--primary);
        }
      }
    }
  }

  :hover {
    .signature__option {
      display: flex;
    }
  }
`;
