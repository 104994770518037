import React, { useEffect, useRef, useState } from "react";
import Styled from "styled-components";
import { ReactComponent as Radio } from "../../../../../assets/image/icon_radio.svg";

interface Props {
  className?: string;
  defaultValue?: number;
  editable?: boolean;
  onChange?: (auth: number) => void;
}

export const JobAuthority: React.FC<Props> = (props) => {
  const [auth, setAuth] = useState<number>(props.defaultValue || 1);
  const editable = useRef<boolean>(
    props.editable === undefined ? true : props.editable
  );

  const onChangeAuth = (value: number) => {
    setAuth(value);
    props.onChange && props.onChange(value);
  };
  useEffect(() => {
    setAuth(props.defaultValue || 1);
  }, [props.defaultValue]);
  return (
    <JobAuthorityRadioContainer className="body-14-rg">
      <div
        className="job__auth__item"
        onClick={() => {
          onChangeAuth(1);
        }}
      >
        <Radio className={auth === 1 ? "checked" : ""} />
        <label htmlFor="accelerator">심사역</label>
      </div>

      <div
        className="job__auth__item"
        onClick={() => {
          onChangeAuth(2);
        }}
      >
        <Radio className={auth === 2 ? "checked" : ""} />
        <label htmlFor="manager">매니저</label>
      </div>

      <div
        className="job__auth__item"
        onClick={() => {
          onChangeAuth(3);
        }}
      >
        <Radio className={auth === 3 ? "checked" : ""} />
        <label htmlFor="normal">일반(기타)</label>
      </div>
    </JobAuthorityRadioContainer>
  );
};

const JobAuthorityRadioContainer = Styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 20px;

  .job__auth__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:8px;

    .checked { 
      path {
        fill: var(--primary-purple);
      }
    }
  }
  
`;
