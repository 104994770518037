import { CSSProperties, useRef, useState } from "react";
import styled, { css } from "styled-components";
import DefaultSearch from "../../../../components-2.0v/search/DefaultSearch";
import { SearchSelectorItemProps } from "../components/SearchSelector";
import { ReactComponent as Close } from "../../../../assets/image/modal_close.svg";

interface Props {
  data: SearchSelectorItemProps[];
  disabled?: boolean;
  close: () => void;
  onSelectItem: (data: SearchSelectorItemProps) => void;
}

export const SearchReportedPortfolio = (props: Props) => {
  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  return (
    <Container>
      <HeaderContainer>
        <DefaultSearch
          isInitValue
          value={searchKeyword}
          width={`calc(100% - 36px)`}
          placeholder={"기업명 검색"}
          transfromPixel={(n) => n}
          onChangeValue={(value) => updateSearchKeyword(value)}
          onPressEnter={(value) => {
            const items = props.data.filter((item) =>
              item.text.includes(value)
            );
            if (items.length !== 1) return;

            updateSearchKeyword("");
            props.onSelectItem(items[0]);
          }}
        />
        <Close onClick={props.close} />
      </HeaderContainer>
      {searchKeyword.length > 0 && (
        <div className="body-14-sb" style={{ marginTop: 10 }}>
          검색 결과 (
          {
            props.data.filter((item) => item.text.includes(searchKeyword))
              .length
          }
          )
        </div>
      )}
      {searchKeyword.length > 0 &&
        props.data.filter((item) => item.text.includes(searchKeyword)).length >
          0 && (
          <SelectListContainer>
            {props.data
              .filter((item) => item.text.includes(searchKeyword))
              .map((item) => {
                return (
                  <SelectListItem
                    className="body-14-rg"
                    onClick={() => {
                      updateSearchKeyword("");
                      props.onSelectItem(item);
                    }}
                  >
                    {item.text}
                  </SelectListItem>
                );
              })}
          </SelectListContainer>
        )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  background-color: var(--white);
`;
const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const SelectListContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

const SelectListItem = styled.div`
  width: 100%;
  padding: 12px 0px;
  display: flex;
  flex-direction: row;

  cursor: pointer;

  :hover {
    background-color: #f3f4f4;
  }
`;
