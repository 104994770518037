import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";

import { DefaultButton } from "lattice_core";
import { PortfolioTargetData } from "../../../../../type/data";
import {
  ContractReportAccount,
  EvaluationContactRequestProps,
  InvestmentStatus,
} from "../../../evaluation/interface/Evaluation.interface";
import { getEvaluationList } from "../../../../../api/repository/portfolio/PortfolioRepository";

import { ContractEvaluationRadio } from "./ContractEvaluationRadio";
import { showToast } from "../../../../../common/commonUtil";
import { Bounce } from "react-toastify";

interface Props {
  targetData: PortfolioTargetData;
  close: () => void;
  callback: (id: number) => void;
}

export const ContractRequestModal: React.FC<Props> = (props) => {
  const { targetData, close, callback } = props;
  const { companyName, portfolioId, dealFlowId } = targetData;
  const [evaluationList, setEvaluationList] = useState<
    EvaluationContactRequestProps[] | undefined
  >(undefined);

  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

  const settingEvaluationList = async () => {
    const result = await getEvaluationList(portfolioId);

    if (result) {
      const openDealEvaluation = result.find((item) => item.id === dealFlowId);
      if (openDealEvaluation) {
        const transformData = openDealEvaluation.investmentAssessment.map(
          (item, index): EvaluationContactRequestProps => {
            const reportAccounts =
              item.assessmentTmp.assessment.investmentAssessmentReportAccount;
            const raInfo = reportAccounts.map(
              (account): ContractReportAccount => {
                const accountName =
                  account.investmentAssessmentAccount.accountName || "";
                const investmentAmount =
                  account.investmentAssessmentAccount.investmentAmount || 0;
                const voteInviteUsers = account.investmentAssessmentInvite
                  .map((user) => user.acUser.name)
                  .join(",");
                const voteInviteUserCount =
                  account.investmentAssessmentInvite.length;
                return {
                  isAgreement: account.isAgreement
                    ? account.isAgreement
                    : false,
                  accountName,
                  investmentAmount,
                  voteInviteUsers,
                  voteInviteUserCount,
                };
              }
            );
            return {
              title: `${
                openDealEvaluation.investmentAssessment.length - index
              }차 투자심사`,
              investmentAssessmentId: item.investmentAssessmentId,
              reportAccountInfo: raInfo,
              status: item.status,
              isRequestAble:
                raInfo.filter((account) => !account.isAgreement).length === 0,
            };
          }
        );
        setEvaluationList([
          ...transformData.filter(
            (item) =>
              item.status === InvestmentStatus.CONFIRM && item.isRequestAble
          ),
        ]);
      }
    }
  };

  useEffect(() => {
    settingEvaluationList();
  }, [targetData]);

  useEffect(() => {
    if (!evaluationList) return;

    if (evaluationList.length === 0) {
      close();
      showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span>계약 요청할 수 있는 투자심사건이 없습니다.</span>
          </div>
        ),
        backgroundColor: "var(--red-500)",
        color: "var(--text-02)",
        width: 274,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
    }

    if (evaluationList.length === 1) {
      setSelectedId(evaluationList[0].investmentAssessmentId);
    }
  }, [evaluationList]);

  if (!evaluationList) return null;

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          계약 요청
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        <div className="heading-14-md">{`계약을 요청할 투자심사건을 선택해 주세요.`}</div>
        <ContractEvaluationRadio
          cards={evaluationList}
          selectedId={selectedId}
          selectCard={(id) => {
            setSelectedId(id);
          }}
        />
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={() => {
              close();
            }}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            disabled={selectedId === undefined}
            text={"요청하기"}
            onClick={() => {
              selectedId && callback(selectedId);
              close();
            }}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  max-width: 950px;
  max-height: 95vh;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  padding: 20px 36px;
  gap: 32px;
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
