import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

interface Props {
  title: string;
  titleClassName?: string;
}
export const TitleSection: React.FC<Props> = (props) => {
  const { title, titleClassName = "heading-18-sb", children } = props;
  return (
    <Container>
      <div className={titleClassName}>{title}</div>
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
