import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import useChannel from "./hook/useChannel";
import { DragDropContext } from "react-beautiful-dnd";
import ModalLayout from "../../../../components/modal/ModalLayout";
import { IndustrialTechDetailModal } from "./component/IndustrialTechDetailModal";
import { Header } from "../structure/Structure";
import NewNavigationLayout from "../../navigation/NewNavigationLayout";
import { DefaultButton } from "lattice_core";
import { IndustrialTechColumn } from "./component/IndustrialTechColumn";
import useIndustrialTech from "./hook/useIndustrialTech";

const IndustrialTech = () => {
  const [
    enabled,
    industrialTech,
    selectedIndustrialTech,
    industrialTechModal,
    isChanged,
    onDragEnd,
    onClickItem,
    closeModal,
    addIndustryTech,
    modifyIndustryTech,
    onClickNewIndustryTech,
    isChange,
    updateIndustrialTech,
  ] = useIndustrialTech();

  if (!enabled) {
    return null;
  }

  return (
    <NewNavigationLayout>
      <Container>
        <Header>
          <span className="heading-20-sb">산업기술 관리</span>
          <FlexRow style={{ gap: 10 }}>
            <DefaultButton
              className="heading-16-b"
              sizeType="xxsmall"
              buttonType="text"
              buttonColor={"var(--text-03)"}
              onClick={onClickNewIndustryTech}
              text="산업기술 생성"
            />

            <DefaultButton
              className="heading-16-b"
              sizeType="small"
              buttonType="filled"
              buttonColor="var(--primary-purple)"
              hoverColor="var(--purple-700)"
              disabled={!isChanged}
              onClick={updateIndustrialTech}
              text="현재 정보 저장"
            />
          </FlexRow>
        </Header>
        <DragDropContext onDragEnd={onDragEnd}>
          <FlexColumn
            style={{
              position: "relative",
              width: "100%",
              maxWidth: 900,
              margin: "0 auto",
            }}
          >
            <FlexRow style={{ gap: 10 }}>
              <ColumnWrapper>
                <ColumnHeader>
                  <div className="heading-18-b">사용중</div>
                  <div
                    className="caption-14-rg"
                    style={{ color: "var(--text-04)" }}
                  >
                    래티스 클라이언트에 노출됩니다.
                    <br />
                    노출시키지 않으려면 채널을 끌어다 사용중지 영역에 놓으세요.
                  </div>
                </ColumnHeader>
                <IndustrialTechColumn
                  droppableId={"active"}
                  items={industrialTech?.active || []}
                  isActive={true}
                  onClickItem={onClickItem}
                />
              </ColumnWrapper>
              <ColumnWrapper>
                <ColumnHeader>
                  <div className="heading-18-b">사용중지</div>
                  <div
                    className="caption-14-rg"
                    style={{ color: "var(--text-04)" }}
                  >
                    래티스 클라이언트에 노출지 않습니다.
                    <br />
                    노출시키려면 채널을 끌어다 사용중 영역에 놓으세요.
                  </div>
                </ColumnHeader>
                <IndustrialTechColumn
                  droppableId={"deactive"}
                  items={industrialTech?.deactive || []}
                  isActive={false}
                  onClickItem={onClickItem}
                />
              </ColumnWrapper>
            </FlexRow>
          </FlexColumn>
        </DragDropContext>
        {industrialTech && industrialTechModal && (
          <ModalLayout
            isOpen={industrialTechModal}
            isFullScreen
            toggle={closeModal}
          >
            <IndustrialTechDetailModal
              newCount={0}
              industrialTech={industrialTech}
              selectedIndustryTech={selectedIndustrialTech}
              cancel={closeModal}
              resultChannel={(data, isAdd) => {
                isAdd ? addIndustryTech(data) : modifyIndustryTech(data);
              }}
            />
          </ModalLayout>
        )}
      </Container>
    </NewNavigationLayout>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .sector__contents {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 24px;
    overflow: hidden;
    padding: 24px 24px 24px 24px;
  }
  .submit {
    position: absolute;
    top: 36px;
    right: 0;
    min-width: 120px;
    padding: 8px 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 0px;
    cursor: pointer;

    background-color: var(--primary);
    color: var(--white);
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-height: calc(100vh - 72px - 30px);
  overflow: scroll;

  background-color: var(--white);
  border-left: 1px solid var(--gray-400);
  border-right: 1px solid var(--gray-400);
`;

const ColumnHeader = styled.div`
  width: 100%;
  height: max-content;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--gray-400);
  padding: 16px;
`;
export default IndustrialTech;
