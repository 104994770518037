import {
  AddComma,
  getKoreanMoneyUnit,
  getNewDate,
  getYM,
  getYMD,
  RemoveComma,
} from "../../../../common/commonUtil";
import {
  copyFileToTargetFolder,
  deleteEmpty,
  replaceFile,
} from "../../../../google/docs/GoogleDocsApi";
import {
  IAFinancialStatements2,
  IAReportAccount,
  InvestmentAssessment,
  PortfolioInfoType,
  TABLE_FORM_TYPE,
} from "../../../../type/data";

export const isSuccessVote = (reportAccount: IAReportAccount) => {
  const totalCount = reportAccount.investmentAssessmentInvite
    .map((item) => item.priorityInfo?.priorityCount || 0)
    .reduce((prev, cur) => prev + cur, 0);

  const successVoteCount = Math.round(totalCount * (2 / 3));
  const agreeCount = reportAccount.investmentAssessmentInvite
    .filter((item) => item.isAgreement !== undefined && item.isAgreement)
    .map((item) => item.priorityInfo?.priorityCount || 0)
    .reduce((prev, cur) => prev + cur, 0);

  return agreeCount >= successVoteCount;
};

const getReplaceData = (replaceText?: string, matchText?: string) => {
  return {
    replaceAllText: {
      replaceText: replaceText || "",
      containsText: {
        matchCase: true,
        text: matchText || "",
      },
    },
  };
};

const getBaseReplaceData = (
  document: InvestmentAssessment,
  reportAccount: IAReportAccount,
  portfolioInfo?: PortfolioInfoType
) => {
  const requests: any = [];

  const participantMain = document.participantMain || "";

  const participantSub =
    document.participantSub?.length === 0
      ? " "
      : document.participantSub?.join(",");

  const companyInfo = document.investmentAssessmentCompanyInfo;
  const account = reportAccount.investmentAssessmentAccount;

  const companyAddress = `${
    companyInfo.addr1 ? companyInfo.addr1 : portfolioInfo?.addr1 || ""
  } ${companyInfo.addr2 ? companyInfo.addr2 : portfolioInfo?.addr2 || ""} ${
    companyInfo.addr3 ? companyInfo.addr3 : portfolioInfo?.addr3 || ""
  } ${
    companyInfo.addrDetail
      ? companyInfo.addrDetail
      : portfolioInfo?.addrDetail || ""
  }`;

  const discount =
    //표지 ----------------------------------------------------------------------------------------------------
    requests.push(
      getReplaceData(
        companyInfo.companyName
          ? companyInfo.companyName
          : portfolioInfo?.companyName || "",
        "{{company_name}}"
      )
    );

  requests.push(
    getReplaceData(account.accountName, "{{ac_fund_account_name}}")
  );

  requests.push(
    getReplaceData(account.allocationWay || "", "{{allocation_way}}")
  );

  requests.push(getReplaceData(account.stockType?.name, "{{stock_type}}"));

  requests.push(
    getReplaceData(
      `${AddComma(account.investmentAmount)}원`,
      "{{investment_amount}}"
    )
  );

  requests.push(
    getReplaceData(
      getYMD(reportAccount.assessmentDate || ""),
      "{{assessment_date}}"
    )
  );

  requests.push(getReplaceData(participantMain, "{{participant_main}}"));
  requests.push(getReplaceData(participantSub, "{{participant_sub}}"));
  //표지 ----------------------------------------------------------------------------------------------------

  //투자 개요 ------------------------------------------------------------------------------------------------
  requests.push(
    getReplaceData(`${account.stockType?.name || ""}`, "{{stock_type}}")
  );
  requests.push(
    getReplaceData(`${account.investmentAmount || ""}`, "{{investment_amount}}")
  );
  requests.push(
    getReplaceData(`${account.stockType?.name || ""}`, "{{stock_type}}")
  );
  // requests.push(
  //   getReplaceData(account.allocationWay || "", "{{allocation_way}}")
  // );
  requests.push(
    getReplaceData(`${AddComma(account.stockNumber)}주`, "{{stock_number}}")
  );
  requests.push(
    getReplaceData(`${AddComma(account.issuingPrice)}원`, "{{issuing_price}}")
  );
  requests.push(
    getReplaceData(`${account.shareholdingRatio}%`, "{{percentage}}")
  );
  requests.push(
    getReplaceData(
      `${AddComma(account.postCompanyValue)}원`,
      "{{post_company_value}}"
    )
  );
  requests.push(
    getReplaceData(
      `${AddComma(account.buyCompanyValue)}원`,
      "{{buy_company_value}}"
    )
  );
  requests.push(getReplaceData(account.buyCompany, "{{buy_company}}"));

  requests.push(
    getReplaceData(
      `${AddComma(account.oldStockPurchasePrice)}원 (최근 신주가 ${AddComma(
        account.curNewIssuingPrice
      )}원, 최근 신주 대비 거래가 ${(
        ((account.oldStockPurchasePrice || 0) /
          (account.curNewIssuingPrice || 1)) *
        100
      ).toFixed(0)}%)`,
      "{{discount_rate}}"
    )
  );
  requests.push(
    getReplaceData(
      account.curNewIssuingPrice ? AddComma(account.curNewIssuingPrice) : "",
      "{{cur_new_issuing_price}}"
    )
  );
  //투자 개요 ------------------------------------------------------------------------------------------------

  //기업 현황 ------------------------------------------------------------------------------------------------

  const currentFinanceState =
    document.investmentAssessmentFinancialStatement2 &&
    document.investmentAssessmentFinancialStatement2.data.length > 0
      ? document.investmentAssessmentFinancialStatement2.data[0]
      : undefined;

  const previousTotalStockNumber =
    document.investmentAssessmentStakeStatement &&
    document.investmentAssessmentStakeStatement.data.length > 0
      ? document.investmentAssessmentStakeStatement.data
          .map((item) => item.previousStockNumber || 0)
          .reduce((prev, cur) => prev + cur, 0)
      : undefined;

  console.log(currentFinanceState);

  const exports =
    currentFinanceState && currentFinanceState.salesAmount
      ? AddComma(`${currentFinanceState.salesAmount}000000`)
      : "";
  const capital =
    currentFinanceState && currentFinanceState.capital
      ? AddComma(`${currentFinanceState.capital}000000`)
      : "";
  const totalAssets =
    currentFinanceState && currentFinanceState.totalAssets
      ? AddComma(`${currentFinanceState.totalAssets}000000`)
      : "";

  //{{company_name}}
  requests.push(
    getReplaceData(
      companyInfo.ceoName ? companyInfo.ceoName : portfolioInfo?.ceoName || "",
      "{{ceo_name}}"
    )
  );
  requests.push(getReplaceData(companyAddress, "{{company_address}}"));
  requests.push(getReplaceData(companyInfo.tel || "", "{{tel}}"));
  requests.push(getReplaceData(companyInfo.faxTel || "", "{{fax_tel}}"));
  requests.push(getReplaceData(companyInfo.industryType, "{{industry_type}}"));
  requests.push(getReplaceData(companyInfo.industryCode, "{{industry_code}}"));
  requests.push(getReplaceData(companyInfo.majorProduct, "{{major_product}}"));
  requests.push(getReplaceData(`${exports}원`, "{{exports}}"));
  requests.push(getReplaceData(`${capital}원`, "{{capital}}"));
  requests.push(
    getReplaceData(`${AddComma(account.faceValue)}원`, "{{face_value}}")
  );
  requests.push(
    getReplaceData(
      `${AddComma(previousTotalStockNumber)}주`,
      "{{previous_total_stock_number}}"
    )
  );
  requests.push(getReplaceData(`${totalAssets}원`, "{{total_asset}}"));
  requests.push(
    getReplaceData(
      getYMD(
        companyInfo.establishmentDay
          ? companyInfo.establishmentDay
          : portfolioInfo?.establishmentDay || ""
      ),
      "{{establishment_day}}"
    )
  );
  requests.push(
    getReplaceData(
      `${AddComma(companyInfo.employeeNumber)}명`,
      "{{employee_number}}"
    )
  );
  requests.push(
    getReplaceData(
      `${AddComma(companyInfo.accountingFirm)}`,
      "{{accounting_firm}}"
    )
  );
  requests.push(
    getReplaceData(
      `${AddComma(companyInfo.settlementMonth)}월`,
      "{{settlement_month}}"
    )
  );
  requests.push(
    getReplaceData(
      companyInfo.businessNumber
        ? companyInfo.businessNumber
        : portfolioInfo?.businessNumber || "",
      "{{business_number}}"
    )
  );
  requests.push(
    getReplaceData(companyInfo.corporationNumber, "{{corporation_number}}")
  );

  //기업 현황 ------------------------------------------------------------------------------------------------
  return requests;
};

//기여율
const getContributionReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];

  if (document.contributionUsers && document.contributionUsers.data) {
    const excavatorData = document.contributionUsers.data.excavator?.map(
      (item) => {
        return `${item.acUser?.name} (${item.contributionRatio?.toFixed(2)})`;
      }
    );

    requests.push(
      getReplaceData(excavatorData?.join("\n"), "{{excavationUsers}}")
    );
    const judgerData = document.contributionUsers.data.judge?.map((item) => {
      return `${item.acUser?.name} (${item.contributionRatio?.toFixed(2)})`;
    });
    requests.push(getReplaceData(judgerData?.join("\n"), "{{inviteUsers}}"));
    const postManagerData = document.contributionUsers.data.postManager?.map(
      (item) => {
        return `${item.acUser?.name} (${item.contributionRatio?.toFixed(2)})`;
      }
    );
    requests.push(
      getReplaceData(postManagerData?.join("\n"), "{{participanUserst}}")
    );
  }
  console.log(requests);

  return requests;
};

//주식 변동내역, 지분내역
const getStakeStatementReplaceData = (
  document: InvestmentAssessment,
  reportAccount: IAReportAccount
) => {
  const requests: any = [];
  const account = reportAccount.investmentAssessmentAccount;
  const preTotalStockNumber =
    document.investmentAssessmentStakeStatement?.data
      .map((item) => parseInt(RemoveComma(item.previousStockNumber || 0)))
      .reduce((preValue, curValue) => (preValue || 0) + (curValue || 0), 0) ||
    0;
  const totalStockNumber =
    document.investmentAssessmentStakeStatement?.data
      .map((item) => parseInt(RemoveComma(item.stockNumber || 0)))
      .reduce((preValue, curValue) => (preValue || 0) + (curValue || 0), 0) ||
    0;

  if (document.investmentAssessmentStakeStatement) {
    document.investmentAssessmentStakeStatement.data.map((item, index) => {
      const stockholderName = item.stockholderName;
      const previousStockNumber =
        item.previousStockNumber === undefined
          ? ""
          : AddComma(item.previousStockNumber);
      const previousShareholdingPercentage =
        previousStockNumber.length === 0
          ? ""
          : `${item.previousShareholdingPercentage?.toFixed(2)}%`;
      const stockNumber =
        item.stockNumber === undefined ? "" : AddComma(item.stockNumber);
      const shareholdingPercentage =
        stockNumber.length === 0
          ? ""
          : `${item.shareholdingPercentage?.toFixed(2)}%`;
      const note = item.note || "";
      const prevFaceValue =
        previousStockNumber.length === 0
          ? ""
          : `${AddComma(account.faceValue)}원`;
      const faceValue =
        stockNumber.length === 0 ? "" : `${AddComma(account.faceValue)}원`;

      requests.push(
        getReplaceData(stockholderName, `{{stake_${index + 1}_1}}`)
      );
      requests.push(
        getReplaceData(previousStockNumber, `{{stake_${index + 1}_2}}`)
      );
      requests.push(
        getReplaceData(
          previousShareholdingPercentage,
          `{{stake_${index + 1}_3}}`
        )
      );
      requests.push(getReplaceData(stockNumber, `{{stake_${index + 1}_4}}`));
      requests.push(
        getReplaceData(shareholdingPercentage, `{{stake_${index + 1}_5}}`)
      );
      requests.push(getReplaceData(note, `{{stake_${index + 1}_6}}`));
    });

    requests.push(
      getReplaceData(AddComma(preTotalStockNumber) + "주", "{{sum_pre_stake}}")
    );
    requests.push(
      getReplaceData(AddComma(totalStockNumber) + "주", "{{sum_post_stake}}")
    );
  }
  return requests;
};

//주요 회사 연혁
const getCompanyHistoryReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];

  // 회사 연혁 - 필수 아님
  if (document.investmentAssessmentCompanyHistory) {
    const data = document.investmentAssessmentCompanyHistory.data;
    if (
      data.length === 1 &&
      data[0].date === undefined &&
      data[0].contents === undefined &&
      data[0].note === undefined
    ) {
      requests.push(getReplaceData(" ", `{{history_1_1}}`));
      requests.push(getReplaceData(" ", `{{history_1_2}}`));
      requests.push(getReplaceData(" ", `{{history_1_3}}`));
    } else {
      document.investmentAssessmentCompanyHistory.data?.map((item, index) => {
        const date = item.date || "";
        const contents = item.contents || "";
        const note = item.note || "";

        requests.push(
          getReplaceData(getYM(date), `{{history_${index + 1}_1}}`)
        );
        requests.push(getReplaceData(contents, `{{history_${index + 1}_2}}`));
        requests.push(getReplaceData(note, `{{history_${index + 1}_3}}`));
      });
    }
  }
  return requests;
};

// 경영진 현황
const getCompanyMemberReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  if (document.investmentAssessmentCompanyMember) {
    const data = document.investmentAssessmentCompanyMember.data;
    if (
      data.length === 1 &&
      data[0].name === undefined &&
      data[0].position === undefined &&
      data[0].birthYear === undefined &&
      data[0].careerOrEducation === undefined &&
      data[0].fullTimeStatus === undefined &&
      data[0].currentStake === undefined &&
      data[0].relationship === undefined &&
      data[0].registrationStatus === undefined
    ) {
      requests.push(getReplaceData(" ", `{{management_1_1}}`));
      requests.push(getReplaceData(" ", `{{management_1_2}}`));
      requests.push(getReplaceData(" ", `{{management_1_3}}`));
      requests.push(getReplaceData(" ", `{{management_1_4}}`));
    } else {
      document.investmentAssessmentCompanyMember.data?.map((item, index) => {
        const name = item.name;
        const position = item.position;
        const birthYear = item.birthYear;
        const careerOrEducation = item.careerOrEducation;
        const fullTimeStatus = item.fullTimeStatus === "여" ? "상근" : "비상근";
        const currentStake = item.currentStake || 0;
        const relationship = item.relationship;
        const registrationStatus =
          item.registrationStatus === "여" ? "등기" : "비등기";

        requests.push(
          getReplaceData(
            `${position}\n${relationship}`,
            `{{management_${index + 1}_1}}`
          )
        );
        requests.push(
          getReplaceData(
            `${name}\n${birthYear}`,
            `{{management_${index + 1}_2}}`
          )
        );

        requests.push(
          getReplaceData(careerOrEducation, `{{management_${index + 1}_3}}`)
        );
        requests.push(
          getReplaceData(
            `${registrationStatus}\n${fullTimeStatus}\n${currentStake}%`,
            `{{management_${index + 1}_4}}`
          )
        );
      });
    }
  }
  return requests;
};

// 주요 직원 현황
const getKeyManagementReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  // 주요 직원 현황
  if (document.investmentAssessmentKeyManagement) {
    const data = document.investmentAssessmentKeyManagement.data;
    if (
      data.length === 1 &&
      data[0].name === undefined &&
      data[0].position === undefined &&
      data[0].birthYear === undefined &&
      data[0].careerOrEducation === undefined &&
      data[0].fullTimeStatus === undefined &&
      data[0].currentStake === undefined &&
      data[0].relationship === undefined
    ) {
      requests.push(getReplaceData(" ", `{{member_1_1}}`));
      requests.push(getReplaceData(" ", `{{member_1_2}}`));
      requests.push(getReplaceData(" ", `{{member_1_3}}`));
      requests.push(getReplaceData(" ", `{{member_1_4}}`));
    } else {
      document.investmentAssessmentKeyManagement.data?.map((item, index) => {
        const name = item.name;
        const position = item.position;
        const birthYear = item.birthYear;
        const careerOrEducation = item.careerOrEducation;
        const fullTimeStatus = item.fullTimeStatus === "여" ? "상근" : "비상근";
        const currentStake = item.currentStake || 0;
        const relationship = item.relationship;

        requests.push(
          getReplaceData(
            `${position}\n${relationship}`,
            `{{member_${index + 1}_1}}`
          )
        );
        requests.push(
          getReplaceData(`${name}\n${birthYear}`, `{{member_${index + 1}_2}}`)
        );

        requests.push(
          getReplaceData(careerOrEducation, `{{member_${index + 1}_3}}`)
        );
        requests.push(
          getReplaceData(
            `${fullTimeStatus}\n${currentStake}%`,
            `{{member_${index + 1}_4}}`
          )
        );
      });
    }
  }
  return requests;
};

//주요 재무 현황B
const getFinancialStatementBReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  if (document.investmentAssessmentFinancialStatement2) {
    const data = document.investmentAssessmentFinancialStatement2.data;
    if (
      data.length === 1 &&
      data[0].year === undefined &&
      data[0].salesAmount === undefined &&
      data[0].operatingProfitLoss === undefined &&
      data[0].netProfitLoss === undefined &&
      data[0].totalAssets === undefined &&
      data[0].totalLiabilities === undefined &&
      data[0].totalOwnershipInterest === undefined &&
      data[0].capital === undefined
    ) {
      requests.push(getReplaceData(" ", `{{financialb_1_1}}`));
      requests.push(getReplaceData(" ", `{{financialb_1_2}}`));
      requests.push(getReplaceData(" ", `{{financialb_1_3}}`));
      requests.push(getReplaceData(" ", `{{financialb_1_4}}`));
      requests.push(getReplaceData(" ", `{{financialb_1_5}}`));
      requests.push(getReplaceData(" ", `{{financialb_1_6}}`));
      requests.push(getReplaceData(" ", `{{financialb_1_7}}`));
      requests.push(getReplaceData(" ", `{{financialb_1_8}}`));
    } else {
      document.investmentAssessmentFinancialStatement2.data?.map(
        (item: any, index: number) => {
          const year = item.year;
          const salesAmount = AddComma(item.salesAmount);
          const operatingProfitLoss = AddComma(item.operatingProfitLoss);
          const netProfitLoss = AddComma(item.netProfitLoss);
          const totalAssets = AddComma(item.totalAssets);
          const totalLiabilities = AddComma(item.totalLiabilities);
          const totalOwnershipInterest = AddComma(item.totalOwnershipInterest);
          const capital = AddComma(item.capital);

          requests.push(
            getReplaceData(year + "년", `{{financialb_${index + 1}_1}}`)
          );
          requests.push(
            getReplaceData(salesAmount, `{{financialb_${index + 1}_2}}`)
          );
          requests.push(
            getReplaceData(operatingProfitLoss, `{{financialb_${index + 1}_3}}`)
          );
          requests.push(
            getReplaceData(netProfitLoss, `{{financialb_${index + 1}_4}}`)
          );
          requests.push(
            getReplaceData(totalAssets, `{{financialb_${index + 1}_5}}`)
          );
          requests.push(
            getReplaceData(totalLiabilities, `{{financialb_${index + 1}_6}}`)
          );
          requests.push(
            getReplaceData(
              totalOwnershipInterest,
              `{{financialb_${index + 1}_7}}`
            )
          );
          requests.push(
            getReplaceData(capital, `{{financialb_${index + 1}_8}}`)
          );
        }
      );
    }
  }
  return requests;
};

// 자본금 증자 연혁
const getCapitalIncreaseHistoryReplaceData = (
  document: InvestmentAssessment
) => {
  const requests: any = [];
  if (document.investmentAssessmentCapitalIncreaseHistory) {
    const data = document.investmentAssessmentCapitalIncreaseHistory.data;
    const isChecked =
      document.investmentAssessmentCapitalIncreaseHistory.isChecked;
    if (
      isChecked ||
      (data.length === 1 &&
        data[0].date === undefined &&
        data[0].cause === undefined &&
        data[0].issuanceType === undefined &&
        data[0].amount === undefined &&
        data[0].issuingPrice === undefined &&
        data[0].capital === undefined)
    ) {
      requests.push(getReplaceData(" ", `{{capital_1_1}}`));
      requests.push(getReplaceData(" ", `{{capital_1_2}}`));
      requests.push(getReplaceData(" ", `{{capital_1_3}}`));
      requests.push(getReplaceData(" ", `{{capital_1_4}}`));
      requests.push(getReplaceData(" ", `{{capital_1_5}}`));
      requests.push(getReplaceData(" ", `{{capital_1_6}}`));
      requests.push(getReplaceData(" ", `{{capital_1_7}}`));
    } else {
      document.investmentAssessmentCapitalIncreaseHistory.data?.map(
        (item, index) => {
          const date = getYM(item.date || "");
          const issuanceType = item.issuanceType || "";
          const detailContents = item.detailContents || "";
          const amount = AddComma(item.amount);
          const issuingPrice = AddComma(item.issuingPrice);
          const capital = AddComma(item.capital);
          const note = item.note;

          requests.push(getReplaceData(date, `{{capital_${index + 1}_1}}`));
          requests.push(
            getReplaceData(issuanceType, `{{capital_${index + 1}_2}}`)
          );
          requests.push(
            getReplaceData(detailContents, `{{capital_${index + 1}_3}}`)
          );
          requests.push(getReplaceData(amount, `{{capital_${index + 1}_4}}`));
          requests.push(
            getReplaceData(issuingPrice, `{{capital_${index + 1}_5}}`)
          );
          requests.push(getReplaceData(capital, `{{capital_${index + 1}_6}}`));
          requests.push(getReplaceData(note, `{{capital_${index + 1}_7}}`));
        }
      );
    }
  }
  return requests;
};

// 차입금 현황
const getDebtReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  if (document.investmentAssessmentDebt) {
    const data = document.investmentAssessmentDebt.data;
    const isChecked = document.investmentAssessmentDebt.isChecked;
    if (
      isChecked ||
      (data.length === 1 &&
        data[0].lender === undefined &&
        data[0].lenderType === undefined &&
        data[0].interestRate === undefined &&
        data[0].amount === undefined &&
        data[0].repaymentPlan === undefined &&
        data[0].note === undefined)
    ) {
      requests.push(getReplaceData(" ", `{{borrow_1_1}}`));
      requests.push(getReplaceData(" ", `{{borrow_1_2}}`));
      requests.push(getReplaceData(" ", `{{borrow_1_3}}`));
      requests.push(getReplaceData(" ", `{{borrow_1_4}}`));
      requests.push(getReplaceData(" ", `{{borrow_1_5}}`));
      requests.push(getReplaceData(" ", `{{borrow_1_6}}`));
      requests.push(getReplaceData(" ", `{{borrow_1_7}}`));
    } else {
      document.investmentAssessmentDebt.data?.map((item, index) => {
        const lender = item.lender || "";
        const lenderType = item.lenderType || "";
        const interestRate = item.interestRate;
        const amount = AddComma(item.amount);
        const dateBorrowed = getYM(item.dateBorrowed || "");
        const expirationDate = getYM(item.expirationDate || "");
        const note = item.note;

        requests.push(getReplaceData(lender, `{{borrow_${index + 1}_1}}`));
        requests.push(getReplaceData(lenderType, `{{borrow_${index + 1}_2}}`));
        requests.push(
          getReplaceData(`${interestRate}`, `{{borrow_${index + 1}_3}}`)
        );
        requests.push(getReplaceData(amount, `{{borrow_${index + 1}_4}}`));
        requests.push(
          getReplaceData(dateBorrowed, `{{borrow_${index + 1}_5}}`)
        );
        requests.push(
          getReplaceData(expirationDate, `{{borrow_${index + 1}_6}}`)
        );
        requests.push(getReplaceData(note, `{{borrow_${index + 1}_7}}`));
      });
    }
  }
  return requests;
};

// 정부지원사업 현황
const getGovernmentProjectReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  if (document.investmentAssessmentGovernmentProject) {
    const data = document.investmentAssessmentGovernmentProject.data;
    const isChecked = document.investmentAssessmentGovernmentProject.isChecked;
    if (
      isChecked ||
      (data.length === 1 &&
        data[0].businessName === undefined &&
        data[0].organizationName === undefined &&
        data[0].taskName === undefined &&
        data[0].governmentDonation === undefined &&
        data[0].supportPeriod === undefined &&
        data[0].startDate === undefined)
    ) {
      requests.push(getReplaceData(" ", `{{government_1_1}}`));
      requests.push(getReplaceData(" ", `{{government_1_2}}`));
      requests.push(getReplaceData(" ", `{{government_1_3}}`));
      requests.push(getReplaceData(" ", `{{government_1_4}}`));
      requests.push(getReplaceData(" ", `{{government_1_5}}`));
      requests.push(getReplaceData(" ", `{{government_1_6}}`));
    } else {
      document.investmentAssessmentGovernmentProject.data?.map(
        (item, index) => {
          const businessName = item.businessName || "";
          const organizationName = item.organizationName || "";
          const taskName = item.taskName;
          const governmentDonation = AddComma(item.governmentDonation);
          const supportPeriod = item.supportPeriod;
          const startDate = getYM(item.startDate || "");

          requests.push(
            getReplaceData(businessName, `{{government_${index + 1}_1}}`)
          );
          requests.push(
            getReplaceData(organizationName, `{{government_${index + 1}_2}}`)
          );
          requests.push(
            getReplaceData(taskName, `{{government_${index + 1}_3}}`)
          );
          requests.push(
            getReplaceData(governmentDonation, `{{government_${index + 1}_4}}`)
          );
          requests.push(
            getReplaceData(supportPeriod, `{{government_${index + 1}_5}}`)
          );
          requests.push(
            getReplaceData(startDate, `{{government_${index + 1}_6}}`)
          );
        }
      );
    }
  }
  return requests;
};

//(Y+2) 자금 소요 계획
const getCostEstimationPlanReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  if (document.investmentAssessmentCostEstimationPlan) {
    const data = document.investmentAssessmentCostEstimationPlan.data;
    const isChecked = document.investmentAssessmentCostEstimationPlan.isChecked;

    const totalPrice = data
      .map((item) => item.costEstimationPrice || 0)
      .reduce((prev, cur) => prev + cur, 0);
    if (
      isChecked ||
      (data.length === 1 &&
        data[0].costEstimationContents === undefined &&
        data[0].costEstimationPrice === undefined)
    ) {
      requests.push(getReplaceData(" ", `{{funds_1_1}}`));
      requests.push(getReplaceData(" ", `{{funds_1_2}}`));
    } else {
      document.investmentAssessmentCostEstimationPlan.data?.map(
        (item, index) => {
          const contents = item.costEstimationContents || "";
          const price = AddComma(item.costEstimationPrice);

          requests.push(getReplaceData(contents, `{{funds_${index + 1}_1}}`));
          requests.push(getReplaceData(price, `{{funds_${index + 1}_2}}`));
        }
      );
    }

    requests.push(getReplaceData(AddComma(totalPrice), `{{sum_funds_2}}`));
  }
  return requests;
};

//(Y+2) 자금 조달 계획
const getFinancialSourcingPlanReplaceData = (
  document: InvestmentAssessment
) => {
  const requests: any = [];
  if (document.investmentAssessmentFinancialSourcingPlan) {
    const data = document.investmentAssessmentFinancialSourcingPlan.data;
    const isChecked =
      document.investmentAssessmentFinancialSourcingPlan.isChecked;

    const totalPrice = data
      .map((item) => item.financialSourcingPrice || 0)
      .reduce((prev, cur) => prev + cur, 0);
    if (
      isChecked ||
      (data.length === 1 &&
        data[0].financialSourcingPlan === undefined &&
        data[0].financialSourcingPrice === undefined)
    ) {
      requests.push(getReplaceData(" ", `{{funds_1_3}}`));
      requests.push(getReplaceData(" ", `{{funds_1_4}}`));
    } else {
      document.investmentAssessmentFinancialSourcingPlan.data?.map(
        (item, index) => {
          const contents = item.financialSourcingPlan || "";
          const price = AddComma(item.financialSourcingPrice);

          requests.push(getReplaceData(contents, `{{funds_${index + 1}_3}}`));
          requests.push(getReplaceData(price, `{{funds_${index + 1}_4}}`));
        }
      );
    }

    requests.push(getReplaceData(AddComma(totalPrice), `{{sum_funds_4}}`));
  }
  return requests;
};

//계약 현황
const getContractStatusReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  if (document.investmentAssessmentContractStatus) {
    const data = document.investmentAssessmentContractStatus.data;
    const isChecked = document.investmentAssessmentContractStatus.isChecked;
    if (
      isChecked ||
      (data.length === 1 &&
        data[0].date === undefined &&
        data[0].contents === undefined &&
        data[0].contractAmount === undefined &&
        data[0].contractor === undefined)
    ) {
      requests.push(getReplaceData(" ", `{{contract_1_1}}`));
      requests.push(getReplaceData(" ", `{{contract_1_2}}`));
      requests.push(getReplaceData(" ", `{{contract_1_3}}`));
      requests.push(getReplaceData(" ", `{{contract_1_4}}`));
    } else {
      document.investmentAssessmentContractStatus.data?.map((item, index) => {
        const date = getYM(item.date || "");
        const contents = item.contents || "";
        const contractAmount = AddComma(item.contractAmount || "");
        const contractor = item.contractor || "";

        requests.push(getReplaceData(date, `{{contract_${index + 1}_1}}`));
        requests.push(getReplaceData(contents, `{{contract_${index + 1}_2}}`));
        requests.push(
          getReplaceData(contractAmount, `{{contract_${index + 1}_3}}`)
        );
        requests.push(
          getReplaceData(contractor, `{{contract_${index + 1}_4}}`)
        );
      });
    }
  }

  return requests;
};

//지식재산권 보유 현황
const getIprsProcessReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  if (document.investmentAssessmentIprsProcess) {
    const data = document.investmentAssessmentIprsProcess.data;
    const isChecked = document.investmentAssessmentIprsProcess.isChecked;
    if (
      isChecked ||
      (data.length === 1 &&
        !data[0].applicationNumber &&
        !data[0].applicationDate &&
        !data[0].title &&
        !data[0].applicant &&
        !data[0].registrationDate &&
        !data[0].registrationNumber &&
        !data[0].iprsContents)
    ) {
      requests.push(getReplaceData(" ", `{{iprs_1_1}}`));
      requests.push(getReplaceData(" ", `{{iprs_1_2}}`));
      requests.push(getReplaceData(" ", `{{iprs_1_3}}`));
      requests.push(getReplaceData(" ", `{{iprs_1_4}}`));
      requests.push(getReplaceData(" ", `{{iprs_1_5}}`));
      requests.push(getReplaceData(" ", `{{iprs_1_6}}`));
    } else {
      document.investmentAssessmentIprsProcess.data?.map((item, index) => {
        const applicationNumber = item.applicationNumber || "";
        const applicationDate = item.applicationDate || "";
        const title = item.title || "";
        const applicant = item.applicant || "";
        const registrationDate = item.registrationDate || "";
        const registrationNumber = item.registrationNumber || "";

        requests.push(
          getReplaceData(applicationNumber, `{{iprs_${index + 1}_1}}`)
        );
        requests.push(
          getReplaceData(applicationDate, `{{iprs_${index + 1}_2}}`)
        );
        requests.push(
          getReplaceData(registrationNumber, `{{iprs_${index + 1}_3}}`)
        );
        requests.push(
          getReplaceData(registrationDate, `{{iprs_${index + 1}_4}}`)
        );
        requests.push(getReplaceData(title, `{{iprs_${index + 1}_5}}`));
        requests.push(getReplaceData(applicant, `{{iprs_${index + 1}_6}}`));
      });
    }
  }
  return requests;
};

// 국내외 관련기업
const getSimilarBusinessReplaceData = (
  document: InvestmentAssessment,
  currentHeaderNumber: number
) => {
  const requests: any = [];

  if (
    document.investmentAssessmentSimilarBusiness &&
    !document.investmentAssessmentSimilarBusiness.isChecked
  ) {
    requests.push(
      getReplaceData(
        `${currentHeaderNumber}. 국내외 관련기업(경쟁사, 유사사업) 현황`,
        `{{title_similar}}`
      )
    );

    const data = document.investmentAssessmentSimilarBusiness.data;
    if (
      data.length === 1 &&
      !data[0].compnayName &&
      !data[0].businessSummary &&
      !data[0].coreCustomers &&
      !data[0].clientCompany &&
      !data[0].salesAmount &&
      !data[0].cumulativeInvestment &&
      !data[0].majorInvestor
    ) {
      requests.push(getReplaceData(" ", `{{similar_1_1}}`));
      requests.push(getReplaceData(" ", `{{similar_2_1}}`));
      requests.push(getReplaceData(" ", `{{similar_3_1}}`));
      requests.push(getReplaceData(" ", `{{similar_4_1}}`));
      requests.push(getReplaceData(" ", `{{similar_5_1}}`));
      requests.push(getReplaceData(" ", `{{similar_6_1}}`));
      requests.push(getReplaceData(" ", `{{similar_7_1}}`));
    } else {
      document.investmentAssessmentSimilarBusiness.data?.map((item, index) => {
        const compnayName = item.compnayName;
        const businessSummary = item.businessSummary;
        const coreCustomers = item.coreCustomers;
        const clientCompany = item.clientCompany;
        const salesAmount = AddComma(item.salesAmount);
        const cumulativeInvestment = AddComma(item.cumulativeInvestment);
        const majorInvestor = item.majorInvestor;

        requests.push(getReplaceData(compnayName, `{{similar_1_1}}`));
        requests.push(getReplaceData(businessSummary, `{{similar_2_1}}`));
        requests.push(getReplaceData(coreCustomers, `{{similar_3_1}}`));
        requests.push(getReplaceData(clientCompany, `{{similar_4_1}}`));
        requests.push(getReplaceData(salesAmount, `{{similar_5_1}}`));
        requests.push(getReplaceData(cumulativeInvestment, `{{similar_6_1}}`));
        requests.push(getReplaceData(majorInvestor, `{{similar_7_1}}`));
      });
    }
  }
  return requests;
};

// 매출/인력계획
const getSalesManpowerPlanReplaceData = (
  document: InvestmentAssessment,
  currentHeaderNumber: number
) => {
  const requests: any = [];

  if (
    document.investmentAssessmentSalesManpowerPlan &&
    !document.investmentAssessmentSalesManpowerPlan.isChecked
  ) {
    requests.push(
      getReplaceData(`${currentHeaderNumber}. 매출/인력계획`, `{{title_sales}}`)
    );

    const data = document.investmentAssessmentSalesManpowerPlan.data;
    if (
      data.length === 0 ||
      (data.length === 1 &&
        !data[0].year &&
        !data[0].manpowerPlanning &&
        (data[0].operatingProfitLoss === undefined ||
          data[0].operatingProfitLoss === null) &&
        (data[0].salesAmount === undefined || data[0].salesAmount === null) &&
        (data[0].salesTotalProfit === undefined ||
          data[0].salesTotalProfit === null) &&
        (data[0].sellingGeneralAdministativeExpenses === undefined ||
          data[0].sellingGeneralAdministativeExpenses === null))
    ) {
      requests.push(getReplaceData(" ", `{{sales_1_1}}`));
      requests.push(getReplaceData(" ", `{{sales_1_2}}`));
      requests.push(getReplaceData(" ", `{{sales_1_3}}`));
      requests.push(getReplaceData(" ", `{{sales_1_4}}`));
      requests.push(getReplaceData(" ", `{{sales_1_5}}`));
      requests.push(getReplaceData(" ", `{{sales_1_6}}`));
    } else {
      document.investmentAssessmentSalesManpowerPlan.data?.map(
        (item: any, index: number) => {
          const _year = item.year.split(" ");
          const year =
            _year.length > 1 ? `${_year[0]}년 ${_year[1]}Q` : `${_year[0]}년`;

          const manpowerPlanning = AddComma(item.manpowerPlanning);
          const operatingProfitLoss = AddComma(item.operatingProfitLoss);
          const salesAmount = AddComma(item.salesAmount);
          const salesTotalProfit = AddComma(item.salesTotalProfit);
          const sellingGeneralAdministativeExpenses = AddComma(
            item.sellingGeneralAdministativeExpenses
          );

          requests.push(getReplaceData(year, `{{sales_${index + 1}_1}}`));
          requests.push(
            getReplaceData(manpowerPlanning, `{{sales_${index + 1}_2}}`)
          );
          requests.push(
            getReplaceData(salesAmount, `{{sales_${index + 1}_3}}`)
          );
          requests.push(
            getReplaceData(salesTotalProfit, `{{sales_${index + 1}_4}}`)
          );
          requests.push(
            getReplaceData(
              sellingGeneralAdministativeExpenses,
              `{{sales_${index + 1}_5}}`
            )
          );
          requests.push(
            getReplaceData(operatingProfitLoss, `{{sales_${index + 1}_6}}`)
          );
        }
      );
    }
  }
  return requests;
};

// 마일스톤별 주요 KPI (단기 Y+2)
const getMilestoneKPIReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];

  if (
    document.investmentAssessmentMilestoneKPI &&
    !document.investmentAssessmentMilestoneKPI.isChecked
  ) {
    const data = document.investmentAssessmentMilestoneKPI.data;
    const isChecked = document.investmentAssessmentMilestoneKPI.isChecked;

    if (
      isChecked ||
      (data.length === 1 &&
        !data[0].kpiStartDate &&
        !data[0].kpiEndDate &&
        !data[0].investmentStage &&
        !data[0].costEstimationAmount &&
        !data[0].peopleNum &&
        !data[0].mileston &&
        !data[0].risk &&
        !data[0].kpi)
    ) {
      requests.push(getReplaceData(" ", `{{milestone_1_1}}`));
      requests.push(getReplaceData(" ", `{{milestone_1_2}}`));
      requests.push(getReplaceData(" ", `{{milestone_1_3}}`));
      requests.push(getReplaceData(" ", `{{milestone_1_4}}`));
      requests.push(getReplaceData(" ", `{{milestone_1_5}}`));
    } else {
      document.investmentAssessmentMilestoneKPI.data?.map((item, index) => {
        const year = item.kpiStartDate
          ? `${getNewDate(item.kpiStartDate).getFullYear()}년`
          : "";
        const investmentStage = item.investmentStage || "";
        const costEstimationAmount = AddComma(item.costEstimationAmount || "");
        const peopleNum = AddComma(item.peopleNum || "");
        const mileston = item.mileston || "";
        const risk = item.risk || "";
        const kpi = item.kpi || "";
        const startDate = getYM(item.kpiStartDate || "");
        const endDate = getYM(item.kpiEndDate || "");

        const milestonAndRisk = `[마일스톤]\n${mileston}\n\n[리스크]\n${risk}`;
        const kpiContents = `[${startDate}~${endDate}]\n${kpi}`;

        requests.push(
          getReplaceData(
            `${year}${
              investmentStage.length > 0 ? `\n${investmentStage}` : ""
            }`,
            `{{milestone_${index + 1}_1}}`
          )
        );
        requests.push(
          getReplaceData(costEstimationAmount, `{{milestone_${index + 1}_2}}`)
        );
        requests.push(
          getReplaceData(peopleNum, `{{milestone_${index + 1}_3}}`)
        );
        requests.push(
          getReplaceData(milestonAndRisk, `{{milestone_${index + 1}_4}}`)
        );
        requests.push(
          getReplaceData(kpiContents, `{{milestone_${index + 1}_5}}`)
        );
      });
    }
  }
  return requests;
};

// 자금 조달 계획
const getFinancingPlanReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  if (document.investmentAssessmentFinancingPlan) {
    const data = document.investmentAssessmentFinancingPlan.data;
    const isChecked = document.investmentAssessmentFinancingPlan.isChecked;
    if (
      isChecked ||
      (data.length === 1 &&
        data[0].year === undefined &&
        data[0].halfType === undefined &&
        data[0].capitalIncreaseType === undefined &&
        data[0].capitalIncreaseAmount === undefined &&
        data[0].note === undefined)
    ) {
      requests.push(getReplaceData(" ", `{{financing_1_1}}`));
      requests.push(getReplaceData(" ", `{{financing_1_2}}`));
      requests.push(getReplaceData(" ", `{{financing_1_3}}`));
      requests.push(getReplaceData(" ", `{{financing_1_4}}`));
      requests.push(getReplaceData(" ", `{{financing_1_5}}`));
    } else {
      document.investmentAssessmentFinancingPlan.data?.map((item, index) => {
        const year = item.year ? `${item.year}년` : "";
        const halfType = item.halfType || "";
        const capitalIncreaseType = item.capitalIncreaseType || "";
        const capitalIncreaseAmount = AddComma(item.capitalIncreaseAmount);
        const note = item.note || "";

        requests.push(getReplaceData(year, `{{financing_${index + 1}_1}}`));

        requests.push(getReplaceData(halfType, `{{financing_${index + 1}_2}}`));
        requests.push(
          getReplaceData(capitalIncreaseType, `{{financing_${index + 1}_3}}`)
        );
        requests.push(
          getReplaceData(capitalIncreaseAmount, `{{financing_${index + 1}_4}}`)
        );
        requests.push(getReplaceData(note, `{{financing_${index + 1}_5}}`));
      });
    }
  }

  return requests;
};

//지식재산권 보유 현황
const getExpertInfoReplaceData = (document: InvestmentAssessment) => {
  const requests: any = [];
  if (
    document.expertInfo &&
    document.expertInfo.isRequestExpert &&
    document.expertInfo.expertOpinion &&
    document.expertInfo.expertOpinion.opinion
  ) {
    //초기화
    requests.push(
      getReplaceData(
        `${document.expertInfo.expertTitle} 종합의견`,
        "{{expert_info_title}}"
      )
    );
    requests.push(
      getReplaceData(
        document.expertInfo.expertOpinion.opinion,
        "{{expert_info_content}}"
      )
    );
  } else {
    requests.push(getReplaceData("", "{{expert_info_title}}"));
    requests.push(getReplaceData("", "{{expert_info_content}}"));
  }
  return requests;
};

export const getTypeReplaceData = (
  document: InvestmentAssessment,
  reportAccount: IAReportAccount,

  portfolioInfo?: PortfolioInfoType
) => {
  let requests = [];
  requests = [...getBaseReplaceData(document, reportAccount, portfolioInfo)];
  console.log(document);

  //주식 변동내역, 지분내역
  requests = [
    ...requests,
    ...getStakeStatementReplaceData(document, reportAccount),
  ];

  //주요 회사 연혁
  requests = [...requests, ...getCompanyHistoryReplaceData(document)];

  //경영진 현황
  requests = [...requests, ...getCompanyMemberReplaceData(document)];

  //주요 경영진 현황
  requests = [...requests, ...getKeyManagementReplaceData(document)];

  //주요 재무 현황B
  requests = [...requests, ...getFinancialStatementBReplaceData(document)];

  //자본금 증자 연혁
  requests = [...requests, ...getCapitalIncreaseHistoryReplaceData(document)];

  //차입금 현황
  requests = [...requests, ...getDebtReplaceData(document)];

  //정부 사업
  requests = [...requests, ...getGovernmentProjectReplaceData(document)];

  //(Y+2) 자금 소요 계획
  requests = [...requests, ...getCostEstimationPlanReplaceData(document)];

  //(Y+2) 자금 조달 계획
  requests = [...requests, ...getFinancialSourcingPlanReplaceData(document)];

  //(Y+2) 투자 단계별 마일스톤 및 주요 KPI
  requests = [...requests, ...getMilestoneKPIReplaceData(document)];

  // 자금 조달 계획
  requests = [...requests, ...getFinancingPlanReplaceData(document)];

  //계약 현황
  requests = [...requests, ...getContractStatusReplaceData(document)];

  //지식재산권 출원 및 등록 현황
  requests = [...requests, ...getIprsProcessReplaceData(document)];

  //전문가 의견
  requests = [...requests, ...getExpertInfoReplaceData(document)];

  requests = [...requests, initReplaceData()];

  return requests;
};

export const getAccountReplaceData = (
  reportAccount: IAReportAccount,
  document: InvestmentAssessment
) => {
  const participantMain = document.participantMain || "";
  const companyInfo = document.investmentAssessmentCompanyInfo;
  const account = reportAccount.investmentAssessmentAccount;
  const preTotalStockNumber =
    document.investmentAssessmentStakeStatement?.data
      .map((item) => parseInt(RemoveComma(item.previousStockNumber || 0)))
      .reduce((preValue, curValue) => (preValue || 0) + (curValue || 0), 0) ||
    0;
  const totalStockNumber =
    document.investmentAssessmentStakeStatement?.data
      .map((item) => parseInt(RemoveComma(item.stockNumber || 0)))
      .reduce((preValue, curValue) => (preValue || 0) + (curValue || 0), 0) ||
    0;
  const issuingPrice = account.issuingPrice || 0;
  const totalInvestAmount =
    (totalStockNumber - preTotalStockNumber) * issuingPrice;
  const postCompanyValue = totalStockNumber * issuingPrice;

  const _titleInvestmentInfo = getReplaceData(
    `1. 투자 개요`,
    "{{title_investment_info}}"
  );

  const _companyName = getReplaceData(
    companyInfo.companyName,
    "{{company_name}}"
  );

  const _accountName = getReplaceData(account.accountName, "{{account_name}}");

  const _allocationWay = getReplaceData(
    account.allocationWay,
    "{{allocation_way}}"
  );

  const _stockType = getReplaceData(account.stockType?.name, "{{stock_type}}");

  const _investmentAmount = getReplaceData(
    `${AddComma(account.investmentAmount)}원`,
    "{{investment_amount}}"
  );

  const _assessmentDate = getReplaceData(
    reportAccount.assessmentDate,
    "{{assessment_date}}"
  );

  const _participantMain = getReplaceData(
    participantMain,
    "{{participant_main}}"
  );

  const _totalInvestmentAmount = getReplaceData(
    `${AddComma(totalInvestAmount)}원`,
    "{{total_investment_amount}}"
  );

  const _issuingPrice = getReplaceData(
    `${AddComma(account.issuingPrice)}원`,
    "{{issuing_price}}"
  );

  const _totalStockNumber = getReplaceData(
    `${AddComma(totalStockNumber)}주`,
    "{{total_stock_number}}"
  );

  const _stockNumber = getReplaceData(
    `${AddComma(account.stockNumber)}주`,
    "{{stock_number}}"
  );

  const _percentage = getReplaceData(
    `${(((account.stockNumber || 0) / totalStockNumber) * 100).toFixed(2)}%`,
    "{{percentage}}"
  );

  const _postCompanyValue = getReplaceData(
    `${AddComma(account.postCompanyValue)}원`,
    "{{post_company_value}}"
  );
  const _investmentNote = getReplaceData(account.note, "{{investment_note}}");

  // const _buyCompanyValue = getReplaceData(
  //   AddComma(account.buyCompanyValue),
  //   "{{buy_company_value}}"
  // );
  // const _buyCompany = getReplaceData(account.buyCompany, "{{buy_company}}");
  // const _discountRate = getReplaceData(
  //   `기존 ${account.initialIssuingPrice}원, ${account.discount}% 할인율 적용`,
  //   "{{discount_rate}}"
  // );

  const _ceoName = getReplaceData(companyInfo.ceoName, "{{ceo_name}}");

  const _companyAddress = getReplaceData(
    `${companyInfo.addr1} ${companyInfo.addr2} ${companyInfo.addr3} ${companyInfo.addrDetail}`,
    "{{company_address}}"
  );

  const _tel = getReplaceData(companyInfo.tel, "{{tel}}");
  const _faxTel = getReplaceData(companyInfo.faxTel, "{{fax_tel}}");

  const _industryType = getReplaceData(
    companyInfo.industryType,
    "{{industry_type}}"
  );
  const _industryCode = getReplaceData(
    companyInfo.industryCode,
    "{{industry_code}}"
  );
  const _faceValue = getReplaceData(
    `${AddComma(account.faceValue)}원`,
    "{{face_value}}"
  );

  const _majorProduct = getReplaceData(
    companyInfo.majorProduct,
    "{{major_product}}"
  );

  const _totalAsset = getReplaceData(
    `${AddComma(companyInfo.totalAsset)}원`,
    "{{total_asset}}"
  );

  const _capital = getReplaceData(
    `${AddComma(companyInfo.capital)}원`,
    "{{capital}}"
  );

  const _employeeNumber = getReplaceData(
    `${AddComma(companyInfo.employeeNumber)}명`,
    "{{employee_number}}"
  );

  const _previousTotalStockNumber = getReplaceData(
    `${AddComma(companyInfo.previousTotalStockNumber)}주`,
    "{{previous_total_stock_number}}"
  );
  const _settlementMonth = getReplaceData(
    `${AddComma(companyInfo.settlementMonth)}월`,
    "{{settlement_month}}"
  );

  const _establishmentDay = getReplaceData(
    getYMD(companyInfo.establishmentDay || ""),
    "{{establishment_day}}"
  );

  const _corporationNumber = getReplaceData(
    companyInfo.corporationNumber,
    "{{corporation_number}}"
  );

  const _businessNumber = getReplaceData(
    companyInfo.businessNumber,
    "{{business_number}}"
  );

  let requests = [
    _titleInvestmentInfo,
    _accountName,
    _companyName,
    _assessmentDate,
    _participantMain,
    _allocationWay,
    _stockType,
    _investmentAmount,
    _stockNumber,
    _issuingPrice,
    _percentage,
    _totalStockNumber,
    _totalInvestmentAmount,
    _postCompanyValue,
    _investmentNote,
    _ceoName,
    _companyAddress,
    _tel,
    _faxTel,
    _industryType,
    _industryCode,
    _faceValue,
    _majorProduct,
    _totalAsset,
    _capital,
    _employeeNumber,
    _previousTotalStockNumber,
    _settlementMonth,
    _establishmentDay,
    _corporationNumber,
    _businessNumber,
    // _buyCompanyValue,
    // _buyCompany,
    // _discountRate,
  ];

  //지분 변동 내역 헤더 필요 없음
  if (document.investmentAssessmentStakeStatement) {
    console.log("asgasfasfasf");

    const stakeStatementTitle = getReplaceData(
      `2. 지분 변동 내역`,
      `{{title_stake_histoy}}`
    );

    const arr: any[] = [];
    document.investmentAssessmentStakeStatement.data.map((item, index) => {
      const stockholderName = item.stockholderName;
      const previousStockNumber = !item.previousStockNumber
        ? ""
        : AddComma(item.previousStockNumber);
      const previousShareholdingPercentage =
        previousStockNumber.length === 0
          ? ""
          : `${item.previousShareholdingPercentage?.toFixed(2)}%`;
      const stockNumber = !item.stockNumber ? "" : AddComma(item.stockNumber);
      const shareholdingPercentage =
        stockNumber.length === 0
          ? ""
          : `${item.shareholdingPercentage?.toFixed(2)}%`;
      const note = item.note || "";
      const prevFaceValue =
        previousStockNumber.length === 0
          ? ""
          : `${AddComma(account.faceValue)}원`;
      const faceValue =
        stockNumber.length === 0 ? "" : `${AddComma(account.faceValue)}원`;

      arr.push(getReplaceData(stockholderName, `{{stake_${index + 1}_1}}`));
      arr.push(getReplaceData(previousStockNumber, `{{stake_${index + 1}_2}}`));
      arr.push(
        getReplaceData(
          previousShareholdingPercentage,
          `{{stake_${index + 1}_3}}`
        )
      );
      arr.push(getReplaceData(stockNumber, `{{stake_${index + 1}_4}}`));
      arr.push(
        getReplaceData(shareholdingPercentage, `{{stake_${index + 1}_5}}`)
      );
      arr.push(getReplaceData(note, `{{stake_${index + 1}_6}}`));
      arr.push(getReplaceData(prevFaceValue, `{{stake_${index + 1}_7}}`));
      arr.push(getReplaceData(faceValue, `{{stake_${index + 1}_8}}`));
    });

    arr.push(
      getReplaceData(AddComma(preTotalStockNumber) + "주", "{{sum_pre_stake}}")
    );
    arr.push(
      getReplaceData(AddComma(totalStockNumber) + "주", "{{sum_post_stake}}")
    );
    requests = [...requests, stakeStatementTitle, ...arr];
  }

  let currentHeaderNumber = 1;

  const companyInfoTitle = getReplaceData(
    `${currentHeaderNumber}. 회사개요`,
    "{{title_companyinfo}}"
  );
  currentHeaderNumber++;
  requests = [...requests, companyInfoTitle];

  // 회사 연혁 - 필수 아님
  if (
    document.investmentAssessmentCompanyHistory &&
    !document.investmentAssessmentCompanyHistory.isChecked
  ) {
    const historyTitle = getReplaceData(
      `${currentHeaderNumber}. 주요연혁`,
      `{{title_history}}`
    );
    const arr: any[] = [];
    document.investmentAssessmentCompanyHistory.data?.map((item, index) => {
      const date = item.date || "";
      const contents = item.contents || "";
      const note = item.note || "";

      arr.push(getReplaceData(date, `{{history_${index + 1}_1}}`));
      arr.push(getReplaceData(contents, `{{history_${index + 1}_2}}`));
      arr.push(getReplaceData(note, `{{history_${index + 1}_3}}`));
    });

    if (arr.length === 0) {
      arr.push(getReplaceData(" ", `{{history_1_1}}`));
      arr.push(getReplaceData(" ", `{{history_1_2}}`));
      arr.push(getReplaceData(" ", `{{history_1_3}}`));
    }

    requests = [...requests, historyTitle, ...arr];
    currentHeaderNumber++;
  }

  // 주요 경영진 현황
  if (
    document.investmentAssessmentKeyManagement &&
    !document.investmentAssessmentKeyManagement.isChecked
  ) {
    const keyManagementTitle = getReplaceData(
      `${currentHeaderNumber}. 주요 경영진 현황`,
      `{{title_management_organization}}`
    );
    const arr: any[] = [];
    document.investmentAssessmentKeyManagement.data?.map((item, index) => {
      const position = item.position || "";
      const relationship = item.relationship || "";
      const name = item.name || "";
      const age = item.age || "";
      const education = item.education || "";
      const career = item.career || "";

      arr.push(getReplaceData(position, `{{management_${index + 1}_1}}`));
      arr.push(getReplaceData(name, `{{management_${index + 1}_2}}`));
      arr.push(getReplaceData(age, `{{management_${index + 1}_3}}`));
      arr.push(getReplaceData(education, `{{management_${index + 1}_4}}`));
      arr.push(getReplaceData(career, `{{management_${index + 1}_5}}`));
      arr.push(getReplaceData(relationship, `{{management_${index + 1}_6}}`));
    });

    if (arr.length === 0) {
      arr.push(getReplaceData(" ", `{{management_1_1}}`));
      arr.push(getReplaceData(" ", `{{management_1_2}}`));
      arr.push(getReplaceData(" ", `{{management_1_3}}`));
      arr.push(getReplaceData(" ", `{{management_1_4}}`));
      arr.push(getReplaceData(" ", `{{management_1_5}}`));
      arr.push(getReplaceData(" ", `{{management_1_6}}`));
    }

    requests = [...requests, keyManagementTitle, ...arr];
    currentHeaderNumber++;
  }

  // 자본금 증자 연혁 - 필수 아님
  if (
    document.investmentAssessmentCapitalIncreaseHistory &&
    !document.investmentAssessmentCapitalIncreaseHistory.isChecked
  ) {
    const capitalTitle = getReplaceData(
      `${currentHeaderNumber}. 자본금 증자 연혁`,
      `{{title_capital}}`
    );
    const arr: any[] = [];
    document.investmentAssessmentCapitalIncreaseHistory.data?.map(
      (item, index) => {
        const date = item.date || "";
        const cause = item.cause || "";
        const amount = AddComma(item.amount);
        const issuingPrice = AddComma(item.issuingPrice);
        const capital = AddComma(item.capital);
        const note = item.note;

        arr.push(getReplaceData(date, `{{capital_${index + 1}_1}}`));
        arr.push(getReplaceData(cause, `{{capital_${index + 1}_2}}`));
        arr.push(getReplaceData(amount, `{{capital_${index + 1}_3}}`));
        arr.push(getReplaceData(issuingPrice, `{{capital_${index + 1}_4}}`));
        arr.push(getReplaceData(capital, `{{capital_${index + 1}_5}}`));
        arr.push(getReplaceData(cause, `{{capital_${index + 1}_6}}`));
      }
    );

    if (arr.length === 0) {
      arr.push(getReplaceData(" ", `{{capital_1_1}}`));
      arr.push(getReplaceData(" ", `{{capital_1_2}}`));
      arr.push(getReplaceData(" ", `{{capital_1_3}}`));
      arr.push(getReplaceData(" ", `{{capital_1_4}}`));
      arr.push(getReplaceData(" ", `{{capital_1_5}}`));
      arr.push(getReplaceData(" ", `{{capital_1_6}}`));
    }

    requests = [...requests, capitalTitle, ...arr];
    currentHeaderNumber++;
  }

  // 지식재산권 보유 현황 - 필수 아님
  if (
    document.investmentAssessmentIprsProcess &&
    !document.investmentAssessmentIprsProcess.isChecked
  ) {
    const iprsTitle = getReplaceData(
      `${currentHeaderNumber}. 지식재산권 보유 현황`,
      `{{title_iprs}}`
    );
    const arr: any[] = [];
    document.investmentAssessmentIprsProcess.data?.map((item, index) => {
      const no = `${index + 1}`;
      const applicationNumber = item.applicationNumber || "";
      const applicationDate = item.applicationDate || "";
      const title = item.title || "";
      const applicant = item.applicant || "";
      const registrationDate = item.registrationDate || "";
      const registrationNumber = item.registrationNumber || "";

      arr.push(getReplaceData(no, `{{iprs_${index + 1}_1}}`));
      arr.push(getReplaceData(applicationNumber, `{{iprs_${index + 1}_2}}`));
      arr.push(getReplaceData(applicationDate, `{{iprs_${index + 1}_3}}`));
      arr.push(getReplaceData(title, `{{iprs_${index + 1}_4}}`));
      arr.push(getReplaceData(applicant, `{{iprs_${index + 1}_5}}`));
      arr.push(getReplaceData(registrationDate, `{{iprs_${index + 1}_6}}`));
      arr.push(getReplaceData(registrationNumber, `{{iprs_${index + 1}_7}}`));
    });

    if (arr.length === 0) {
      arr.push(getReplaceData(" ", `{{iprs_1_1}}`));
      arr.push(getReplaceData(" ", `{{iprs_1_2}}`));
      arr.push(getReplaceData(" ", `{{iprs_1_3}}`));
      arr.push(getReplaceData(" ", `{{iprs_1_4}}`));
      arr.push(getReplaceData(" ", `{{iprs_1_5}}`));
      arr.push(getReplaceData(" ", `{{iprs_1_6}}`));
      arr.push(getReplaceData(" ", `{{iprs_1_7}}`));
    }

    requests = [...requests, iprsTitle, ...arr];
    currentHeaderNumber++;
  }

  if (document.investmentAssessmentFinancialStatement2) {
    const financialTitle = getReplaceData(
      `${currentHeaderNumber}. 주요 재무 현황`,
      `{{title_financialb}}`
    );
    const arr: any[] = [];
    document.investmentAssessmentFinancialStatement2.data?.map(
      (item: any, index: number) => {
        if (
          !item.year ||
          !item.salesAmount ||
          !item.operatingProfitLoss ||
          !item.netProfitLoss ||
          !item.totalAssets ||
          !item.totalLiabilities ||
          !item.totalOwnershipInterest ||
          !item.capital
        )
          return;

        const _year = item.year.split(" ");
        const year =
          _year.length > 1 ? `${_year[0]}년 ${_year[1]}Q` : `${_year[0]}년`;
        const salesAmount = AddComma(item.salesAmount);
        const operatingProfitLoss = AddComma(item.operatingProfitLoss);
        const netProfitLoss = AddComma(item.netProfitLoss);
        const totalAssets = AddComma(item.totalAssets);
        const totalLiabilities = AddComma(item.totalLiabilities);
        const totalOwnershipInterest = AddComma(item.totalOwnershipInterest);
        const capital = AddComma(item.capital);

        arr.push(getReplaceData(year, `{{financialb_${index + 1}_1}}`));
        arr.push(getReplaceData(salesAmount, `{{financialb_${index + 1}_2}}`));
        arr.push(
          getReplaceData(operatingProfitLoss, `{{financialb_${index + 1}_3}}`)
        );
        arr.push(
          getReplaceData(netProfitLoss, `{{financialb_${index + 1}_4}}`)
        );
        arr.push(getReplaceData(totalAssets, `{{financialb_${index + 1}_5}}`));
        arr.push(
          getReplaceData(totalLiabilities, `{{financialb_${index + 1}_6}}`)
        );
        arr.push(
          getReplaceData(
            totalOwnershipInterest,
            `{{financialb_${index + 1}_7}}`
          )
        );
        arr.push(getReplaceData(capital, `{{financialb_${index + 1}_8}}`));
      }
    );

    requests = [...requests, financialTitle, ...arr];
    currentHeaderNumber++;
  }

  requests = [...requests, ...initReplaceData()];
  return requests;
};

const initReplaceData = () => {
  const arr: any[] = [];
  arr.push(getReplaceData("", "{{company_name}}"));
  arr.push(getReplaceData("", "{{account_name}}"));
  arr.push(getReplaceData("", "{{allocation_way}}"));
  arr.push(getReplaceData("", "{{stock_type}}"));
  arr.push(getReplaceData("", "{{investment_amount}}"));
  arr.push(getReplaceData("", "{{assessment_date}}"));
  arr.push(getReplaceData("", "{{participant_main}}"));
  arr.push(getReplaceData("", "{{total_investment_amount}}"));
  arr.push(getReplaceData("", "{{issuing_price}}"));
  arr.push(getReplaceData("", "{{total_stock_number}}"));
  arr.push(getReplaceData("", "{{stock_number}}"));
  arr.push(getReplaceData("", "{{percentage}}"));
  arr.push(getReplaceData("", "{{post_company_value}}"));
  arr.push(getReplaceData("", "{{investment_note}}"));
  arr.push(getReplaceData("", "{{ceo_name}}"));
  arr.push(getReplaceData("", "{{company_address}}"));
  arr.push(getReplaceData("", "{{tel}}"));
  arr.push(getReplaceData("", "{{fax_tel}}"));
  arr.push(getReplaceData("", "{{industry_type}}"));
  arr.push(getReplaceData("", "{{industry_code}}"));
  arr.push(getReplaceData("", "{{face_value}}"));
  arr.push(getReplaceData("", "{{major_product}}"));
  arr.push(getReplaceData("", "{{total_asset}}"));
  arr.push(getReplaceData("", "{{capital}}"));
  arr.push(getReplaceData("", "{{employee_number}}"));
  arr.push(getReplaceData("", "{{previous_total_stock_number}}"));
  arr.push(getReplaceData("", "{{settlement_month}}"));
  arr.push(getReplaceData("", "{{establishment_day}}"));
  arr.push(getReplaceData("", "{{corporation_number}}"));
  arr.push(getReplaceData("", "{{business_number}}"));
  arr.push(getReplaceData("", "{{excavationUsers}}"));
  arr.push(getReplaceData("", "{{inviteUsers}}"));
  arr.push(getReplaceData("", "{{participanUserst}}"));

  for (let i = 1; i <= 30; i++) {
    for (let j = 1; j <= 8; j++) {
      arr.push(getReplaceData("", `{{stake_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{history_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{member_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{management_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{financialb_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{capital_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{borrow_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{government_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{contract_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{iprs_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{similar_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{sales_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{funds_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{milestone_${i}_${j}}}`));
      arr.push(getReplaceData("", `{{financing_${i}_${j}}}`));
    }
  }

  arr.push(getReplaceData("", `{{sum_pre_stake}}`));
  arr.push(getReplaceData("", `{{sum_percentage}}`));
  arr.push(getReplaceData("", `{{sum_stock_number}}`));

  return arr;
};

export const createFundAccountDocs = async (
  fileId: string,
  folderId: string,
  document: InvestmentAssessment
) => {
  try {
    const promiseArr = document.investmentAssessmentReportAccount.map(
      (item, index) => {
        return new Promise(async (resolve, reject) => {
          const newFile = await copyFileToTargetFolder(
            fileId,
            folderId,
            "투자계정파일"
          );
          const newFileId = newFile?.data.id;
          const result = await replaceFile(
            newFileId,
            getAccountReplaceData(item, document)
          );
          if (result && result.status === 200) {
            resolve({
              success: true,
              data: { reportIndex: index, fileId: newFileId },
            });
          }
        });
      }
    );

    const result = await Promise.all(promiseArr);
    return result;
  } catch (error) {}
};

export const createReportDocument = async (
  fileId: string,
  folderId: string,
  document: InvestmentAssessment,
  account: IAReportAccount,
  title: string,
  preDocument: InvestmentAssessment | undefined,
  portfolioInfo?: PortfolioInfoType
) => {
  try {
    const newFileResult = await copyFileToTargetFolder(fileId, folderId, title);

    console.log(newFileResult);

    if (newFileResult?.status === 401) {
      return {
        success: false,
        errorCode: 401,
      };
    } else {
      const newFileId = newFileResult?.data.id;
      const result = await replaceFile(
        newFileId,
        getTypeReplaceData(document, account, portfolioInfo)
      );
      await deleteEmpty(newFileId);
      console.log(result);
      if (result && result.status === 200) {
        return {
          success: true,
          data: { fileId: newFileId },
        };
      }
    }
  } catch (error) {
    console.log(error);
  }
};
