import axios from "axios";
import * as api from "../../../../../api/api";

const CHATING_UPPER_LIMIT = 50;

export interface ChatItemProps {
  reportingChatRoomItemId: number;
  type: 1 | 2 | 3; // 1 : SYSTEM_OPEN, 2: SYSTEM_MESSAGE, 3: USER_MESSAGE;
  text: string;
  user?: ChatUserProps;
  createdAt: string;
  boldText?: string[];
}

export interface ChatUserProps {
  id: number;
  type: "A" | "P";
  name: string;
  isShowProfile: boolean;
  profileImgUrl?: string;
  color?: string;
}

// 채팅 닫혀있을 때 안읽은 메세지 수
export const getUnReadMsgCount = async (
  portfolioId: number
): Promise<number | undefined> => {
  try {
    const { data } = await axios.get(api.getUnReadMsg(portfolioId));
    return new Promise((resolve) => resolve(data.count));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

// 채팅 메세지 보내기
export const sendChatMsg = async (
  portfolioId: number,
  text: string
): Promise<ChatItemProps[] | undefined> => {
  try {
    const { status, data } = await axios.post(api.sendChatingMsg(portfolioId), {
      text,
    });
    if (status === 200) {
      const chatItems = data.reportingChatRoomItem || [];
      return new Promise((resolve) => resolve(chatItems));
    }
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

// 채팅 보기 - 열때
export const getChatMsg = async (
  portfolioId: number
): Promise<{ lastIndexId: number; chatItems: ChatItemProps[] } | undefined> => {
  try {
    const { status, data } = await axios.get(api.getChatingMsg(portfolioId), {
      params: { upperLimit: CHATING_UPPER_LIMIT },
    });
    if (status === 200) {
      const lastIndexId = data.lastIndex;
      const chatItems = data.reportingChatRoomItem || [];
      return new Promise((resolve) => resolve({ lastIndexId, chatItems }));
    }
    return new Promise((rejects) => rejects(undefined));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

// 채팅 보기 - 열려 있을때
export const getNewChatMsg = async (
  portfolioId: number
): Promise<ChatItemProps[] | undefined> => {
  try {
    const { status, data } = await axios.get(api.getNewChatingMsg(portfolioId));
    if (status === 200) {
      const chatItems = data.reportingChatRoomItem || [];
      return new Promise((resolve) => resolve(chatItems));
    }
    return new Promise((rejects) => rejects(undefined));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

// 채팅 보기 - 스크롤 올렸을때, 페이지 네이션
export const getPageChatMsg = async (
  portfolioId: number,
  roomId: number
): Promise<ChatItemProps[] | undefined> => {
  try {
    const { status, data } = await axios.get(
      api.getPageChatingMsg(roomId, portfolioId),
      {
        params: { upperLimit: CHATING_UPPER_LIMIT },
      }
    );
    if (status === 200) {
      const chatItems = data.reportingChatRoomItem || [];
      return new Promise((resolve) => resolve(chatItems));
    }
    return new Promise((rejects) => rejects(undefined));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};
