import React from "react";
import styled from "styled-components";

import { ReactComponent as Excel } from "../../../assets/image/new_icon_xlsx.svg";
import { ReactComponent as Pdf } from "../../../assets/image/new_icon_pdf.svg";
import ReportFileDownload from "./ReportFileDownload";

interface Props {
  data: string | any;
  isChecking?: boolean;
  isDisabledColor?: boolean;
  transfromPixel: (value: number) => number;
  fileDownloadCallback?: () => void;
}

const CustomCell: React.FC<Props> = (props) => {
  const {
    data,
    isChecking,
    isDisabledColor,
    transfromPixel,
    fileDownloadCallback,
  } = props;

  const getCell = (data: any) => {
    if (data && data.file) {
      return (
        <CellContainer
          className="body-14-rg"
          style={{
            gap: transfromPixel(5),
            padding: `${transfromPixel(4)}px ${transfromPixel(8)}px`,
          }}
        >
          <Ball
            style={{
              width: transfromPixel(6),
              height: transfromPixel(6),
              backgroundColor:
                data.file.uploadStatusId === 1 || data.file.uploadStatusId === 2
                  ? "var(--green-400)"
                  : data.file.uploadStatusId === 3
                  ? "#FFD84F"
                  : data.file.uploadStatusId === 4
                  ? "#F6444F"
                  : data.file.uploadStatusId === 5
                  ? "#4584FF"
                  : "transparent",
            }}
          />
          <span className="body-14-rg">{data.file.uploadStatusName}</span>
        </CellContainer>
      );
    } else {
      return (
        <CellContainer
          className="body-14-rg"
          style={{ padding: `${transfromPixel(4)}px ${transfromPixel(10)}px` }}
        >
          X
        </CellContainer>
      );
    }
  };
  return (
    <Container
      className="body-14-rg"
      style={{
        fontSize: transfromPixel(12),
        padding: `0 ${transfromPixel(10)}px`,
        gap: transfromPixel(2),
      }}
    >
      {typeof data === "string" ? (
        <span
          className="body-14-rg"
          style={{
            padding: `0 ${transfromPixel(10)}px`,
            color: isDisabledColor ? "#7E8086" : "#1f2022",
          }}
        >
          {data}
        </span>
      ) : (
        getCell(data)
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  color: #1f2022; //text01
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CellContainer = styled.div`
  width: fit-content;
  height: fit-content;
  color: #1f2022; //text01
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;

  &.download__btn {
    transition: all 0.2s ease;
    cursor: pointer;
    :hover {
      background: #f3f4f4;
      border-radius: 4px;
    }
  }
`;

const Ball = styled.div`
  border-radius: 50%;
`;

export default CustomCell;
