import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AddComma } from "../../../../../common/commonUtil";
import { OperatingIndustryProps } from "../../interface/PerformanceMetrics.interface";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  industryData?: OperatingIndustryProps[];
}
interface IndustriTechDataProps {
  text: string;
  color: string;
  count: number;
  amount: number;
}
export const IndustriTechRate: React.FC<Props> = (props) => {
  const { industryData = [] } = props;

  const totalCount = useRef<number>(0);
  const [data, setData] = useState<IndustriTechDataProps[]>([]);

  const DefaultColor = [
    "rgba(85, 172, 253, 1)",
    "rgba(255, 159, 90, 1)",
    "rgba(142, 125, 250, 1)",
    "rgba(255, 223, 107, 1)",
    "rgba(104, 122, 225, 1)",
    "rgba(85, 172, 253, 1)",
    "rgba(97, 223, 174, 1)",
    "rgba(92, 221, 239, 1)",
    "rgba(227, 228, 229, 1)",
  ];

  const centerTextPlugin = {
    id: "centerTextPlugin",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      ctx.save();
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = "10px SUIT";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#7E8086";
      ctx.fillText("단위 : 건수", centerX, centerY);
      ctx.restore();
    },
  };

  useEffect(() => {
    const newData = industryData.map((item, index): IndustriTechDataProps => {
      return {
        text: item.name,
        color: DefaultColor[index],
        count: item.investment.length,
        amount: item.investment.reduce((p, c) => p + c, 0),
      };
    });

    totalCount.current = newData
      .map((item) => item.count)
      .reduce((p, c) => p + c, 0);
    setData([...newData].sort((a, b) => b.count - a.count));
  }, [industryData]);

  return (
    <Container>
      <TitleContainer className="heading-18-sb">산업</TitleContainer>
      <BodyContainer>
        <FlexRow style={{ flex: 1, flexWrap: "wrap", columnGap: 6 }}>
          {data.map((item, index) => {
            return (
              <LegendItem
                key={`label_${index}`}
                className="caption-10-md"
                boxColor={item.color}
              >
                {item.text}
              </LegendItem>
            );
          })}
        </FlexRow>
        <FlexRow style={{ flex: 1 }}>
          <div style={{ width: 130, height: 130 }}>
            <Doughnut
              style={{ display: "inline-block" }}
              data={{
                labels: data.map((item) => item.text),
                datasets: [
                  {
                    label: "",
                    data: data.map((item) => item.count),
                    backgroundColor: data.map((item) => item.color),
                    borderColor: data.map((item) => item.color),
                    borderWidth: 1,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: (tooltipItem) => {
                        const count = parseInt(tooltipItem.formattedValue);
                        const amount = data[tooltipItem.dataIndex].amount;
                        return `${((count / totalCount.current) * 100).toFixed(
                          0
                        )}% (${AddComma(count)}건, ${(
                          amount / 100000000
                        ).toFixed(2)}억)`;
                      },
                    },
                  },
                },
                devicePixelRatio: 2,
                cutout: 40,
              }}
              plugins={[centerTextPlugin]}
            />
          </div>
        </FlexRow>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 278px;
  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--gray-300);
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text-05);
  margin-top: 4px;
`;

const LegendItem = styled.div<{ boxColor: string }>`
  min-width: 80px;
  position: relative;
  padding-left: 13px;
  color: var(--gray-700);

  ::before {
    position: absolute;
    content: " ";
    width: 9px;
    height: 9px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${(props) => props.boxColor};
  }
`;
