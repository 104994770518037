import React, { useState } from "react";
import styled from "styled-components";

import { MobileNavigationMenu } from "./MobileNavigationMenu";

import { ReactComponent as Logo } from "../../../2.0lattice/assets/image/new_lattice_logo.svg";
import { ReactComponent as Close } from "../../../assets/image/modal_close.svg";
import { NavigationMenu } from "./MobileNavigationLayout";

interface Props {
  menus: NavigationMenu[];
  close: () => void;
}

export interface LocationProps {
  pageAddress: string;
  addressName: string;
  pageName: string;
  type: "active" | "deactive";
  disabled?: boolean;
  validationAuth?: () => boolean;
}

export const MobileNavigation: React.FC<Props> = ({ menus, close }) => {
  const [spreadIndex, updateSpreadIndex] = useState<number | undefined>(
    menus.findIndex(
      (menu) =>
        menu.subMenu &&
        menu.subMenu.find((submenu) =>
          window.location.pathname.split("/").includes(submenu.addressName)
        )
    )
  );

  return (
    <Container>
      <LogoContainer>
        <Close className="close__icon" width={24} height={24} onClick={close} />
        <Logo width={82} height={20} />
        <div style={{ width: 24 }} />
      </LogoContainer>
      <MenuContainer>
        {menus.map((item, index) => {
          if (item.validationAuth === undefined || item.validationAuth()) {
            return (
              <MobileNavigationMenu
                key={index}
                index={index}
                item={item}
                isSpread={index === spreadIndex}
                onChangeSpreadItem={(idx) => {
                  updateSpreadIndex(idx !== spreadIndex ? idx : undefined);
                }}
                transfromPixel={(n) => n}
              />
            );
          }

          return null;
        })}
      </MenuContainer>
      <NavDivitder />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LogoContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 40px;
  .close__icon {
    path {
      fill: var(--text-01);
    }
  }
`;
const MenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const NavDivitder = styled.div`
  width: calc(100% - 48px);
  height: 1px;
  margin: 0 auto;
  background-color: #ffffff;
  opacity: 0.1;
`;
