import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ExpertInfoProps } from "../../../../../type/data";
import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import { DefaultButton } from "lattice_core";
import { checkEmailForm } from "../../../../../common/commonUtil";

interface Props {
  expertInfo?: ExpertInfoProps;
  close: () => void;
  submit: (title: string, email: string) => void;
}

export const ExpertInfoModal: React.FC<Props> = (props) => {
  const { expertInfo, close, submit } = props;
  const [expertTitle, updateExpertTitle] =
    useState<string>("특허법인 아이피에스");
  const [expertEmail, updateExpertEmail] = useState<string>("cwhan@ipspat.com");
  const [checkedEmailForm, updateCheckedEmailForm] = useState<boolean>(true);

  const validationCheck = () => {
    return (
      expertTitle.length === 0 ||
      expertEmail.length === 0 ||
      !checkEmailForm(expertEmail)
    );
  };

  useEffect(() => {
    if (expertInfo) {
      updateExpertTitle(expertInfo.expertTitle || "");
      updateExpertEmail(expertInfo.expertEmail || "");
    }
  }, [expertInfo]);
  return (
    <Container onClick={() => {}}>
      <Header>
        <span className="heading-18-sb">전문가 정보</span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body>
        <RowTitleBox title="기관명">
          <InputBox
            placeholder="전문가 기관명을 입력해주세요."
            defaultValue={expertTitle}
            onChange={(value) => {
              updateExpertTitle(value);
            }}
          />
        </RowTitleBox>
        <RowTitleBox title="기관 이메일">
          <FlexColumn>
            <InputBox
              placeholder="lattice@lattice.com"
              defaultValue={expertEmail}
              onChange={(value) => {
                updateExpertEmail(value);
                updateCheckedEmailForm(checkEmailForm(value));
              }}
            />
            <WarnningMsg className="caption-12-rg">
              {checkedEmailForm ? "" : "이메일 형식이 아닙니다."}
            </WarnningMsg>
          </FlexColumn>
        </RowTitleBox>
      </Body>
      <Footer>
        <DefaultButton
          text="확인"
          disabled={validationCheck()}
          buttonColor="var(--primary-blue)"
          hoverColor="var(--blue-700)"
          onClick={() => {
            submit(expertTitle, expertEmail);
            close();
          }}
        />
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 407.5px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 59px;
  min-height: 59px;
  max-height: 59px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  border-bottom: 1px solid var(--gray-300);
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 14px 24px;
  gap: 12px;

  height: 60px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const WarnningMsg = styled.div`
  height: 20px;
  padding-left: 10px;
  color: var(--red-500);
`;
