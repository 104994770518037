import React from "react";
import { useMediaQuery } from "react-responsive";
import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";
import MobilePortfolioInfo from "./mobile/MobilePortfolioInfo";
import DeskPortfolioInfo from "./desk/DeskPortfolioInfo";

const PortfolioInfo: React.FC<PortfolioNavigationDefaultProps> = (props) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobilePortfolioInfo {...props} />
  ) : (
    <DeskPortfolioInfo {...props} />
  );
};
export default PortfolioInfo;
