import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { SummaryFinanceInfoProps } from "../../../pre-pitching-detail/interface/PrePitchingDetail.interface";
import { FInancialStatementBarChart } from "../component/FInancialStatementBarChart";
import styled from "styled-components";
import useSBP from "../../../../../2.0lattice/router/reporting/reporting-detail/summary-business-plan/v1/hook/useSBP";
import { getNewDate } from "../../../../../common/commonUtil";
import { ChartContainer } from "../component/ChartContainer";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  serverTime: Date;
  financeHistory: SummaryFinanceInfoProps[];
}

export const FinancialStatement: React.FC<Props> = (props) => {
  const { serverTime, financeHistory } = props;

  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);

  useEffect(() => {
    const labels: string[] = [];
    const year = getNewDate().getFullYear();
    const todayYear = serverTime.getFullYear();
    const todayMonth = serverTime.getMonth() + 1;

    const findHistory = (label: string) => {
      const data = financeHistory.find((history) => {
        const splitLabel = label.split("-");
        const period = splitLabel[1] === "H" ? 2 : 4;
        return (
          `${history.year}` === splitLabel[0] && history.quarterNum === period
        );
      });
      return data;
    };

    for (let i = year - 4; i <= year; i++) {
      if (i === todayYear) {
        if (todayMonth >= 6) {
          labels.push(`${i}-H`);
        } else if (todayMonth === 12) {
          labels.push(`${i}-F`);
        }
      } else {
        labels.push(`${i}-H`);
        labels.push(`${i}-F`);
      }
    }

    const dataset = [
      {
        label: "자산총계",
        data: labels.map((item) => {
          const data = findHistory(item);
          const value = data?.totalAssets ? data.totalAssets : 0;
          return value;
        }),
        backgroundColor: "#3BD699",
      },
      {
        label: "부채총계",
        data: labels.map((item) => {
          const data = findHistory(item);
          const value = data?.totalLiabilities ? data.totalLiabilities : 0;
          return value;
        }),
        backgroundColor: "#FFD84F",
      },
      {
        label: "자본총계",
        data: labels.map((item) => {
          const data = findHistory(item);
          const value = data?.totalEquity ? data.totalEquity : 0;
          return value;
        }),
        backgroundColor: "#FE9041",
      },
    ];
    setChartData({ labels: labels, datasets: dataset });
  }, [financeHistory]);

  if (!chartData) return null;
  return (
    <ChartContainer
      title="기업 재무"
      description="최근 5년에 해당하는 자산총계, 부채총계, 자본총계의 추세를 담고 있습니다. "
    >
      <FInancialStatementBarChart data={chartData} />
    </ChartContainer>
  );
};
