import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Help } from "../../../../../assets/image/icon_tooltip.svg";

interface Props {
  title: string;
  tooltipId?: string;
  borderLeftHide?: boolean;
  transfromPixel: (value: number) => number;
}

const CustomHeader: React.FC<Props> = (props) => {
  const { title, tooltipId, borderLeftHide, transfromPixel } = props;

  return (
    <Container
      className="body-14-rg"
      style={{
        fontSize: transfromPixel(12),
        padding: `0 ${transfromPixel(20)}px`,
        gap: transfromPixel(2),
        borderLeft: `${
          borderLeftHide
            ? "1px solid transparent"
            : "1px solid var(--divider-200)"
        }`,
        borderBottom: "1px solid var(--divider-200)",
      }}
    >
      {title}
      {tooltipId && (
        <Help
          className={tooltipId}
          data-tooltip-delay-hide={2000}
          style={{ outline: "none" }}
          tabIndex={-1}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: #96999f; //text05
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export default CustomHeader;
