import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  IAReportAccount,
  InvestmentAssessment,
} from "../../../../../type/data";
import {
  AddComma,
  getMyInfo,
  showToast,
} from "../../../../../common/commonUtil";
import ModalLayout from "../../../../../components/modal/ModalLayout";
import EvaluationInvestFundAccountModal from "../organisms/modal/EvaluationInvestFundAccountModal";
import { InvitedUser } from "./InvitedUser";
import { DefaultButton } from "lattice_core";
import { EvaluationInviteUserModal } from "../organisms/modal/EvaluationInviteUserModal";
import { DatePickerComponent } from "./DatePickerComponent";
import { ReactComponent as DropDown } from "../../../../../assets/image/btn_dropdown.svg";
import { ReactComponent as Trash } from "../../../../../assets/image/icon_trash.svg";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import ConfirmModal from "../../../../../2.0lattice/components/modal/ConfirmModal";
import { Bounce } from "react-toastify";
import { Label } from "../organisms/assessment/EvaluationAssessment";
import { EvaluationDocumentFileUpload } from "./EvaluationDocumentFileUpload";
import { VoteResultList } from "./VoteResultList";
import { LabelDefault } from "../../../../../components/label/LabelDefault";
import { isSuccessVote } from "../../service/InvestmentAssessmentService";

interface Props {
  portfolioId: number;
  document: InvestmentAssessment;
  isDeletable: boolean;
  reportAccountIdx: number;
  reportAccount: IAReportAccount;
  isUpdateAuth: boolean;
  isVoteFinished: boolean;
  isSpecialUser: boolean;
  transfromPixel: (value: number) => number;
  downloadEvaluationDocument: (reportAccount: IAReportAccount) => void;
  onChangeReportAccount: (
    item: IAReportAccount,
    index: number,
    callback?: () => void
  ) => void;
  onDeleteReportAccount: (item: IAReportAccount, index: number) => void;
}

export const InvestFundAccount: React.FC<Props> = (props) => {
  const {
    portfolioId,
    document,
    isDeletable,
    reportAccountIdx,
    reportAccount,
    isUpdateAuth,
    isVoteFinished,
    isSpecialUser,
    transfromPixel,
    downloadEvaluationDocument,
    onChangeReportAccount,
    onDeleteReportAccount,
  } = props;
  const [isFundManager, updateIsFundManager] = useState<boolean>(false);
  const [isSpread, updateSpread] = useState<boolean>(true);
  const [deleteModal, updateDeleteModal] = useState<boolean>(false);
  const [investInfoModal, updateInvestInfoModal] = useState<boolean>(false);
  const [inviteUserModal, updateInviteUserModal] = useState<boolean>(false);
  const [confirmCancelModal, updateConfirmCancelModal] =
    useState<boolean>(false);

  const [modifyInviteUserModal1, updateModifyInviteUserModal1] =
    useState<boolean>(false);
  const [modifyInviteUserModal2, updateModifyInviteUserModal2] =
    useState<boolean>(false);

  const isFinishVote = () => {
    return (
      reportAccount.investmentAssessmentInvite.filter(
        (item) => item.isAgreement === undefined || item.isAgreement === null
      ).length === 0
    );
  };

  const getLabel = () => {
    const finishVote = isFinishVote();
    if (finishVote) {
      if (isSuccessVote(reportAccount)) {
        return <LabelDefault text="가결" backgroundColor="var(--blue-500)" />;
      } else {
        return <LabelDefault text="부결" backgroundColor="var(--red-500)" />;
      }
    } else {
      return <LabelDefault text="진행중" backgroundColor="var(--green-500)" />;
    }
  };

  const getInvestmentMinute = (
    reportAccount: IAReportAccount,
    isSpread: boolean
  ) => {
    const minuteDocumentFile = isSpecialUser
      ? reportAccount.documentManagement?.filter(
          (item) => item.documentType && item.documentType.id === 3
        )
      : reportAccount.documentManagement?.filter(
          (item) => item.documentType && item.documentType.id === 50
        );

    if (minuteDocumentFile === undefined || minuteDocumentFile.length === 0)
      return null;

    if (isSpread) {
      return (
        <BoxContainer transfromPixel={transfromPixel}>
          <div
            className="heading-18-md"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 12,
            }}
          >
            <div>투자 심사 의사록 </div>
          </div>
          <EvaluationDocumentFileUpload
            portfolioId={portfolioId}
            editable={false}
            file={minuteDocumentFile[minuteDocumentFile.length - 1].file}
            reportAccount={reportAccount}
            transfromPixel={transfromPixel}
            downloadEvaluationDocument={() => {}}
            onChangeFile={(file) => {}}
          />
        </BoxContainer>
      );
    } else {
      return (
        <FlexColumnBox style={{ flex: 1, gap: 17 }}>
          <div
            className="heading-14-md"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 12,
            }}
          >
            <div>투자 심사 의사록</div>
          </div>
          <EvaluationDocumentFileUpload
            editable={false}
            portfolioId={portfolioId}
            file={minuteDocumentFile[minuteDocumentFile.length - 1].file}
            reportAccount={reportAccount}
            transfromPixel={transfromPixel}
            downloadEvaluationDocument={() => {}}
            onChangeFile={(file) => {}}
          />
        </FlexColumnBox>
      );
    }
  };

  useEffect(() => {
    const myInfo = getMyInfo();
    const fundManager = reportAccount.investmentAssessmentInvite.find(
      (item) => item.priorityInfo?.priorityType === 1 || item.isFundManager
    );
    updateIsFundManager(fundManager?.acUser.acUserId === myInfo.id);
  }, [reportAccount]);

  return (
    <Container>
      <AccountHeader
        style={{
          paddingLeft: 28,
          paddingRight: 28,
          backgroundColor: reportAccount.isPublished
            ? isFinishVote()
              ? isSuccessVote(reportAccount)
                ? "var(--skyblue-50)"
                : "var(--red-50)"
              : "var(--green-50)"
            : "var(--white)",
        }}
        onClick={() => {
          updateSpread((prev) => !prev);
        }}
      >
        <HeaderFront className="heading-18-md">
          <DropDown
            className={`arrow ${isSpread ? "" : "spreaded"}`}
            width={16}
            height={16}
          />{" "}
          {reportAccount.investmentAssessmentAccount.accountName}
        </HeaderFront>
        {isUpdateAuth && isDeletable && (
          <TrashBtn
            onClick={(e) => {
              e.stopPropagation();
              updateDeleteModal(true);
            }}
          >
            <Trash />
          </TrashBtn>
        )}
        {reportAccount.isPublished && getLabel()}
      </AccountHeader>
      {isSpread ? (
        <FlexColumnBox>
          <InvestInfoContainer transfromPixel={transfromPixel}>
            <div className="heading-18-md">투자 개요</div>
            <InvestInfo
              className="body-14-rg"
              onClick={() => {
                updateInvestInfoModal(true);
              }}
            >
              <InfoBox>
                <span className="info__box__title">주식종류</span>
                <span>
                  {reportAccount.investmentAssessmentAccount.stockType?.name}
                </span>
              </InfoBox>
              <InfoBox>
                <span className="info__box__title">
                  {reportAccount.investmentAssessmentAccount.acFundAccount
                    ?.acFundAccountId === 1
                    ? "당사 투자 금액"
                    : "당 조합 투자 금액"}
                </span>
                <span>
                  {AddComma(
                    reportAccount.investmentAssessmentAccount.investmentAmount
                  )}
                  원
                </span>
              </InfoBox>
              <InfoBox>
                <span className="info__box__title">
                  {reportAccount.investmentAssessmentAccount.acFundAccount
                    ?.acFundAccountId === 1
                    ? "당사 배정 주식 수"
                    : "당 조합 배정 주식 수"}
                </span>
                <span>
                  {AddComma(
                    reportAccount.investmentAssessmentAccount.stockNumber
                  )}
                  주
                </span>
              </InfoBox>
              <InfoBox>
                <span className="info__box__title">투자 후 지분율</span>
                <span>
                  {reportAccount.investmentAssessmentAccount.shareholdingRatio}%
                </span>
              </InfoBox>
            </InvestInfo>
          </InvestInfoContainer>
          <BoxContainer transfromPixel={transfromPixel}>
            <div className="heading-18-md">투자 심사 보고서 PDF</div>
            <EvaluationDocumentFileUpload
              isView
              portfolioId={portfolioId}
              editable={isUpdateAuth}
              file={
                document.isConfirmed
                  ? reportAccount.documentManagement?.find(
                      (item) => item.documentType && item.documentType.id === 2
                    )?.file
                  : reportAccount.reportFile
              }
              reportAccount={reportAccount}
              transfromPixel={transfromPixel}
              downloadEvaluationDocument={downloadEvaluationDocument}
              onChangeFile={(file) => {
                onChangeReportAccount(
                  {
                    ...reportAccount,
                    reportFile: file,
                  },
                  reportAccountIdx
                );
              }}
            />
          </BoxContainer>
          {true ? (
            <FlexColumnBox>
              <BoxContainer transfromPixel={transfromPixel}>
                <div
                  className="heading-18-md"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FlexRowBox style={{ alignItems: "center" }}>
                    <span>투자 심사 위원</span>
                    {!reportAccount.isPublished &&
                      isUpdateAuth &&
                      reportAccount.requestFundManager &&
                      reportAccount.fundManagerConfirm === undefined && (
                        <Label
                          className="caption-11-rg"
                          style={{
                            backgroundColor: "var(--green-500)",
                            marginLeft: 10,
                          }}
                        >
                          요청중
                        </Label>
                      )}
                    {!reportAccount.isPublished &&
                      (isUpdateAuth || isFundManager) &&
                      reportAccount.fundManagerConfirm !== undefined && (
                        <Label
                          className="caption-11-rg"
                          style={{
                            backgroundColor: "var(--blue-800)",
                            marginLeft: 10,
                          }}
                        >
                          확정완료
                        </Label>
                      )}
                  </FlexRowBox>
                  {isUpdateAuth && !reportAccount.requestFundManager && (
                    <div>
                      <DefaultButton
                        buttonType="filled"
                        buttonColor={"var(--primary-blue)"}
                        className="caption-12-md"
                        disabled={
                          reportAccount.investmentAssessmentInvite.length <
                          (reportAccount.investmentAssessmentAccount?.quorumType
                            ?.number || 0)
                        }
                        sizeType="xxsmall"
                        hoverColor={"var(--blue-600)"}
                        text="확정요청"
                        transfromPixel={transfromPixel}
                        onClick={(e) => {
                          e && e.stopPropagation();
                          onChangeReportAccount(
                            {
                              ...reportAccount,
                              requestFundManager: true,
                            },
                            reportAccountIdx
                          );

                          showToast({
                            customElement: (
                              <div
                                className="heading-16-sb"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>대표펀드매니저에게</span>
                                <span>확정 요청이 전송되었습니다.</span>
                              </div>
                            ),
                            backgroundColor: "var(--green-400)",
                            color: "var(--text-02)",
                            width: props.transfromPixel(274),
                            milliSec: 1500,
                            transition: Bounce,
                            position: "bottom-center",
                            toastId: new Date().toString(),
                          });
                        }}
                      />
                    </div>
                  )}
                  {!reportAccount.isPublished &&
                    isFundManager &&
                    reportAccount.requestFundManager &&
                    !reportAccount.fundManagerConfirm && (
                      <div>
                        <DefaultButton
                          buttonType="filled"
                          buttonColor={"var(--primary-blue)"}
                          className="caption-12-md"
                          disabled={
                            reportAccount.investmentAssessmentInvite.length <
                            (reportAccount.investmentAssessmentAccount
                              ?.quorumType?.number || 0)
                          }
                          sizeType="xxsmall"
                          hoverColor={"var(--blue-600)"}
                          text="확정하기"
                          transfromPixel={transfromPixel}
                          onClick={(e) => {
                            e && e.stopPropagation();
                            onChangeReportAccount(
                              {
                                ...reportAccount,
                                fundManagerConfirm: true,
                              },
                              reportAccountIdx,
                              () => {
                                showToast({
                                  customElement: (
                                    <div
                                      className="heading-16-sb"
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span>
                                        현재 구성원으로 확정 처리 되었습니다.
                                      </span>
                                    </div>
                                  ),
                                  backgroundColor: "var(--green-400)",
                                  color: "var(--text-02)",
                                  width: props.transfromPixel(274),
                                  milliSec: 1500,
                                  transition: Bounce,
                                  position: "bottom-center",
                                  toastId: new Date().toString(),
                                });
                              }
                            );
                          }}
                        />
                      </div>
                    )}
                  {!reportAccount.isPublished &&
                    isFundManager &&
                    reportAccount.requestFundManager &&
                    reportAccount.fundManagerConfirm && (
                      <div>
                        <DefaultButton
                          buttonType="colored-outline"
                          buttonColor={"var(--text-01)"}
                          borderColor={"var(--gray-400)"}
                          className="caption-12-md"
                          disabled={
                            reportAccount.investmentAssessmentInvite.length <
                            (reportAccount.investmentAssessmentAccount
                              ?.quorumType?.number || 0)
                          }
                          sizeType="xxsmall"
                          text="확정취소"
                          transfromPixel={transfromPixel}
                          onClick={(e) => {
                            e && e.stopPropagation();
                            updateConfirmCancelModal(true);
                          }}
                        />
                      </div>
                    )}
                </div>
                <EvaluationInviteUserContainer>
                  {!reportAccount.isPublished &&
                    reportAccount.investmentAssessmentInvite.map((item) => {
                      return <InvitedUser user={item} />;
                    })}
                  {reportAccount.isPublished && (
                    <VoteResultList
                      reportUsers={reportAccount.investmentAssessmentInvite}
                      isVoteFinished={isVoteFinished}
                    />
                  )}
                </EvaluationInviteUserContainer>
                {!reportAccount.isPublished &&
                  (isUpdateAuth ||
                    (isFundManager && !reportAccount.fundManagerConfirm)) && (
                    <div>
                      <DefaultButton
                        className="caption-12-md"
                        buttonType="colored-outline"
                        sizeType="xxsmall"
                        buttonColor={"var(--text-01)"}
                        borderColor={"var(--gray-400)"}
                        text={
                          reportAccount.investmentAssessmentInvite.length <= 1
                            ? "추가하기"
                            : "편집하기"
                        }
                        transfromPixel={transfromPixel}
                        onClick={(e) => {
                          e && e.stopPropagation();

                          if (
                            !isFundManager &&
                            reportAccount.requestFundManager &&
                            !reportAccount.fundManagerConfirm
                          ) {
                            updateModifyInviteUserModal1(true);
                          } else if (
                            !isFundManager &&
                            reportAccount.requestFundManager &&
                            reportAccount.fundManagerConfirm
                          ) {
                            updateModifyInviteUserModal2(true);
                          } else {
                            updateInviteUserModal(true);
                          }
                        }}
                      />
                    </div>
                  )}
              </BoxContainer>
              <BoxContainer transfromPixel={transfromPixel}>
                <div className="heading-18-md">투자 심사 일자</div>
                <FlexRowBox style={{ gap: 118 }}>
                  <FlexColumnBox style={{ gap: 8 }}>
                    <div
                      className="heading-14-md"
                      style={{ color: "var(--text-04)" }}
                    >
                      실제 개최일시
                    </div>
                    <DatePickerComponent
                      disabled={!isUpdateAuth}
                      date={reportAccount.assessmentStartDate}
                      onChangeDate={(date) => {
                        onChangeReportAccount(
                          {
                            ...reportAccount,
                            assessmentDate: date.date,
                            assessmentStartDate: date,
                          },
                          reportAccountIdx
                        );
                      }}
                    />
                  </FlexColumnBox>
                  <FlexColumnBox style={{ gap: 8 }}>
                    <div
                      className="heading-14-md"
                      style={{ color: "var(--text-04)" }}
                    >
                      의사록 표기일시
                    </div>
                    <DatePickerComponent
                      disabled={!isUpdateAuth}
                      date={reportAccount.assessmentEndDate}
                      onChangeDate={(date) => {
                        onChangeReportAccount(
                          {
                            ...reportAccount,
                            assessmentEndDate: date,
                          },
                          reportAccountIdx
                        );
                      }}
                    />
                  </FlexColumnBox>
                </FlexRowBox>
              </BoxContainer>
              {getInvestmentMinute(reportAccount, true)}
            </FlexColumnBox>
          ) : (
            <FlexColumnBox>
              {getInvestmentMinute(reportAccount, true)}
              <BoxContainer
                className="heading-18-md"
                transfromPixel={(n) => n}
                style={{ textAlign: "center", color: "var(--text-04)" }}
              >
                이관된 데이터 입니다.
              </BoxContainer>
            </FlexColumnBox>
          )}
        </FlexColumnBox>
      ) : (
        <BoxContainer transfromPixel={transfromPixel} style={{ border: 0 }}>
          <FlexRowBox>
            <FlexColumnBox style={{ flex: 1, gap: 17 }}>
              <div className="heading-14-md">투자 개요</div>
              <SurmmaryBox>
                <RowTitleBox
                  title="투자 종류"
                  titleWidth={132}
                  transfromPixel={transfromPixel}
                >
                  <SurmmaryItem className="body-14-rg">
                    {reportAccount.investmentAssessmentAccount.stockType?.name}
                  </SurmmaryItem>
                </RowTitleBox>
                <RowTitleBox
                  title={
                    reportAccount.investmentAssessmentAccount.acFundAccount
                      ?.acFundAccountId === 1
                      ? "당사 투자 금액"
                      : "당 조합 투자 금액"
                  }
                  titleWidth={132}
                  transfromPixel={transfromPixel}
                >
                  <SurmmaryItem className="body-14-rg">
                    {AddComma(
                      reportAccount.investmentAssessmentAccount.investmentAmount
                    )}
                    원
                  </SurmmaryItem>
                </RowTitleBox>
                <RowTitleBox
                  title={
                    reportAccount.investmentAssessmentAccount.acFundAccount
                      ?.acFundAccountId === 1
                      ? "당사 배정 주식 수"
                      : "당 조합 배정 주식 수"
                  }
                  titleWidth={132}
                  transfromPixel={transfromPixel}
                >
                  <SurmmaryItem className="body-14-rg">
                    {AddComma(
                      reportAccount.investmentAssessmentAccount.stockNumber
                    )}
                    주
                  </SurmmaryItem>
                </RowTitleBox>
                <RowTitleBox
                  title="투자 후 지분율"
                  titleWidth={132}
                  transfromPixel={transfromPixel}
                >
                  <SurmmaryItem className="body-14-rg">
                    {
                      reportAccount.investmentAssessmentAccount
                        .shareholdingRatio
                    }
                    %
                  </SurmmaryItem>
                </RowTitleBox>
              </SurmmaryBox>
            </FlexColumnBox>
            <FlexColumnBox style={{ flex: 1, gap: 17 }}>
              <div className="heading-14-md">심사 개요</div>
              <SurmmaryBox>
                <RowTitleBox
                  title="투자심사보고서"
                  titleWidth={132}
                  transfromPixel={transfromPixel}
                >
                  <SurmmaryItem className="body-14-rg">
                    {reportAccount.reportFile ? (
                      <span style={{ color: "var(--text-01)" }}>
                        업로드 완료
                      </span>
                    ) : (
                      <span style={{ color: "var(--text-06)" }}>미업로드</span>
                    )}
                  </SurmmaryItem>
                </RowTitleBox>
                <RowTitleBox
                  title="대표펀드매니저"
                  titleWidth={132}
                  transfromPixel={transfromPixel}
                >
                  <SurmmaryItem className="body-14-rg">
                    {reportAccount.investmentAssessmentInvite.filter(
                      (item) =>
                        item.priorityInfo?.priorityType === 1 ||
                        item.isFundManager
                    ).length > 0 ? (
                      <span style={{ color: "var(--text-01)" }}>
                        {
                          reportAccount.investmentAssessmentInvite.filter(
                            (item) =>
                              item.priorityInfo?.priorityType === 1 ||
                              item?.isFundManager
                          )[0].acUser.name
                        }
                      </span>
                    ) : (
                      <span style={{ color: "var(--text-06)" }}>미정</span>
                    )}
                  </SurmmaryItem>
                </RowTitleBox>
                <RowTitleBox
                  title="투자심사위원"
                  titleWidth={132}
                  transfromPixel={transfromPixel}
                >
                  <SurmmaryItem className="body-14-rg">
                    {reportAccount.investmentAssessmentInvite.filter(
                      (item) =>
                        item.priorityInfo?.priorityType !== 1 &&
                        !item?.isFundManager
                    ).length > 0 ? (
                      <span style={{ color: "var(--text-01)" }}>
                        {reportAccount.investmentAssessmentInvite
                          .filter(
                            (item) =>
                              item.priorityInfo?.priorityType !== 1 &&
                              !item?.isFundManager
                          )
                          .map((item) => item.acUser.name)
                          .join(", ")}
                      </span>
                    ) : (
                      <span style={{ color: "var(--text-06)" }}>미정</span>
                    )}
                  </SurmmaryItem>
                </RowTitleBox>
                <RowTitleBox
                  title="투자심사일자"
                  titleWidth={132}
                  transfromPixel={transfromPixel}
                >
                  <SurmmaryItem className="body-14-rg">
                    {reportAccount.assessmentStartDate?.date &&
                    reportAccount.assessmentStartDate?.time &&
                    reportAccount.assessmentEndDate?.date &&
                    reportAccount.assessmentEndDate?.time ? (
                      <span style={{ color: "var(--text-01)" }}>
                        {`${reportAccount.assessmentStartDate.date} ${reportAccount.assessmentStartDate.time} ~ ${reportAccount.assessmentEndDate.date} ${reportAccount.assessmentEndDate.time}`}
                      </span>
                    ) : (
                      <span style={{ color: "var(--text-06)" }}>미정</span>
                    )}
                  </SurmmaryItem>
                </RowTitleBox>
              </SurmmaryBox>
            </FlexColumnBox>
          </FlexRowBox>
          {getInvestmentMinute(reportAccount, false)}
        </BoxContainer>
      )}

      {deleteModal && (
        <ModalLayout
          isOpen={deleteModal}
          isFullScreen
          toggle={() => {
            updateDeleteModal(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"재원 삭제"}
            contents={[
              `재원을 삭제하면 입력한 모든 정보가 사라집니다.`,
              `해당 재원을 삭제하시겠습니까?`,
            ]}
            positiveTitle={"삭제"}
            negativeTitle={"아니오"}
            onClickNegativeBtn={() => {
              updateDeleteModal(false);
            }}
            onClickPositiveBtn={() => {
              updateDeleteModal(false);
              onDeleteReportAccount(reportAccount, reportAccountIdx);
            }}
          />
        </ModalLayout>
      )}

      {confirmCancelModal && (
        <ModalLayout
          isOpen={confirmCancelModal}
          isFullScreen
          toggle={() => {
            updateConfirmCancelModal(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"확정 취소"}
            contents={[`확정을 취소하시겠습니까?`]}
            positiveTitle={"예"}
            negativeTitle={"아니오"}
            onClickNegativeBtn={() => {
              updateConfirmCancelModal(false);
            }}
            onClickPositiveBtn={() => {
              updateConfirmCancelModal(false);
              onChangeReportAccount(
                {
                  ...reportAccount,
                  fundManagerConfirm: undefined,
                },
                reportAccountIdx
              );

              showToast({
                customElement: (
                  <div
                    className="heading-16-sb"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>확정이 취소되었습니다.</span>
                  </div>
                ),
                backgroundColor: "var(--green-400)",
                color: "var(--text-02)",
                width: props.transfromPixel(274),
                milliSec: 1500,
                transition: Bounce,
                position: "bottom-center",
                toastId: new Date().toString(),
              });
            }}
          />
        </ModalLayout>
      )}

      {modifyInviteUserModal1 && (
        <ModalLayout
          isOpen={modifyInviteUserModal1}
          isFullScreen
          toggle={() => {
            updateModifyInviteUserModal1(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"확정 요청 취소"}
            contents={[
              `편집을 하시면 대표펀드매니저에게`,
              `다시 확정 요청을 보내야 합니다.`,
              `편집하시겠습니까?`,
            ]}
            positiveTitle={"편집"}
            negativeTitle={"아니오"}
            onClickNegativeBtn={() => {
              updateModifyInviteUserModal1(false);
            }}
            onClickPositiveBtn={() => {
              updateModifyInviteUserModal1(false);
              onChangeReportAccount(
                {
                  ...reportAccount,
                  requestFundManager: undefined,
                  fundManagerConfirm: undefined,
                },
                reportAccountIdx,
                () => {
                  updateInviteUserModal(true);
                }
              );
            }}
          />
        </ModalLayout>
      )}

      {modifyInviteUserModal2 && (
        <ModalLayout
          isOpen={modifyInviteUserModal2}
          isFullScreen
          toggle={() => {
            updateModifyInviteUserModal2(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"확정 요청 취소"}
            contents={[
              `편집을 하시면 대표펀드매니저의 확정이 취소됩니다.`,
              `편집하시겠습니까?`,
            ]}
            positiveTitle={"편집"}
            negativeTitle={"아니오"}
            onClickNegativeBtn={() => {
              updateModifyInviteUserModal2(false);
            }}
            onClickPositiveBtn={() => {
              updateModifyInviteUserModal2(false);
              onChangeReportAccount(
                {
                  ...reportAccount,
                  requestFundManager: undefined,
                  fundManagerConfirm: undefined,
                },
                reportAccountIdx,
                () => {
                  updateInviteUserModal(true);
                }
              );
            }}
          />
        </ModalLayout>
      )}

      {investInfoModal && (
        <ModalLayout
          isOpen={investInfoModal}
          isFullScreen
          toggle={() => {
            updateInvestInfoModal(false);
          }}
        >
          <EvaluationInvestFundAccountModal
            document={document}
            reportAccountIndex={reportAccountIdx}
            reportAccount={reportAccount}
            isUpdateAuth={isUpdateAuth}
            transfromPixel={transfromPixel}
            close={() => {
              updateInvestInfoModal(false);
            }}
            onChangeReportAccount={(data, idx) => {
              console.log(data);
              onChangeReportAccount(data, idx, () => {
                updateInvestInfoModal(false);
              });
            }}
            onCreateReportAccount={(data) => {}}
          />
        </ModalLayout>
      )}

      {inviteUserModal && (
        <ModalLayout
          isOpen={inviteUserModal}
          isFullScreen
          toggle={() => {
            updateInviteUserModal(false);
          }}
        >
          <EvaluationInviteUserModal
            quorumType={reportAccount.investmentAssessmentAccount.quorumType}
            fundManagerModifiable={
              reportAccount.investmentAssessmentAccount.acFundAccount
                ?.acFundAccountId === 1
            }
            selectedUser={reportAccount.investmentAssessmentInvite}
            close={() => {
              updateInviteUserModal(false);
            }}
            setValue={(selectedUsers) => {
              onChangeReportAccount(
                { ...reportAccount, investmentAssessmentInvite: selectedUsers },
                reportAccountIdx
              );
            }}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: var(--background-contents01);
  border-radius: 4px;
  border: 1px solid var(--gray-300);

  overflow: hidden;
`;

const AccountHeader = styled.div`
  width: 100%;
  height: 64px;
  min-height: 64px;
  max-height: 64px;

  border-bottom: 1px solid var(--gray-300);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderFront = styled.div`
  flex-grow: 1;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;

  cursor: pointer;

  path {
    stroke: var(--icon-01);
  }

  .arrow {
    transition: all 0.2s ease;
    transform: rotate(180deg);
  }
  .spreaded {
    transform: rotate(0deg);
  }
`;

const TrashBtn = styled.div`
  width: clamp(36px, 100%, 36px);
  height: clamp(36px, 100%, 36px);
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid var(--gray-400);
  border-radius: 4px;
  transition: all 0.2s ease;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const InvestInfoContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  padding: ${(props) => props.transfromPixel(24)}px
    ${(props) => props.transfromPixel(28)}px;

  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const InvestInfo = styled.div`
  width: 100%;
  height: 54px;
  min-height: 54px;
  max-height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  border-radius: 4px;
  border: 1px solid var(--gray-200);

  transition: all 0.2s ease;
  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const InfoBox = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: var(--text-01);

  .info__box__title {
    color: var(--text-05);
  }
`;

const BoxContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  padding: ${(props) => props.transfromPixel(24)}px
    ${(props) => props.transfromPixel(28)}px;

  display: flex;
  flex-direction: column;

  gap: 16px;

  border-top: 1px solid var(--gray-300);
`;

const EvaluationInviteUserContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
`;

const FlexRowBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const SurmmaryBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  ::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    background-color: var(--gray-300);
    left: 0;
  }
`;

const SurmmaryItem = styled.div`
  width: 100%;
  height: auto;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
