import { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { DefaultButton } from "lattice_core";
import {
  DealFlowStatus,
  PortfolioNavigationDefaultProps,
} from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import { PrePitchingSection } from "./organisms/PrePitchingSection";
import usePrePitching from "../hook/usePrePitching";
import { PitchingStatus } from "../interface/PrePitching.interface";
import { PitchingEmpty } from "./organisms/PitchingEmpty";

const MobilePrePitching: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const { portfolioNavInfo, init, isEditable = false } = props;
  const [
    firstLoading,
    pitchings,
    createPitching,
    goToPrepitchingDetail,
    prePitchingInit,
  ] = usePrePitching();

  useEffect(() => {
    if (!firstLoading) prePitchingInit();
  }, [portfolioNavInfo]);

  return (
    <Container>
      <BodyWrapper>
        <Body>
          {pitchings.length === 0 && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PitchingEmpty msg={["열린 피칭 심사가 없어요."]} />;
            </div>
          )}
          {pitchings
            .filter((item) =>
              ["DROP"].includes(portfolioNavInfo.pfPhase.type)
                ? true
                : item.status !== DealFlowStatus.CANCEL
            )
            .map((item, index) => {
              if (
                !isEditable &&
                pitchings.length === 1 &&
                item.pfPreEvaluation.length === 0
              ) {
                return <PitchingEmpty msg={["열린 피칭 심사가 없어요."]} />;
              }

              if (index === 0) {
                return (
                  <PrePitchingSection
                    key={index}
                    sectionTitle={item.title}
                    showCreateCard={
                      isEditable &&
                      portfolioNavInfo.phase !== undefined &&
                      portfolioNavInfo.phase !== "contract"
                    }
                    createAble={
                      item.status === DealFlowStatus.OPEN &&
                      item.pfPreEvaluation.filter((pitching) =>
                        [
                          PitchingStatus.OPEN,
                          PitchingStatus.PITCHING,
                          PitchingStatus.PUBLISH,
                        ].includes(pitching.status)
                      ).length === 0 &&
                      isEditable
                    }
                    pitchingList={item.pfPreEvaluation}
                    onClickCard={(id) => {
                      goToPrepitchingDetail(id);
                    }}
                    onClickCreatePitching={createPitching}
                  />
                );
              } else {
                return (
                  <div key={index}>
                    <SectionDivider />
                    <PrePitchingSection
                      sectionTitle={item.title}
                      showCreateCard={false}
                      createAble={false}
                      pitchingList={item.pfPreEvaluation}
                      onClickCard={goToPrepitchingDetail}
                    />
                  </div>
                );
              }
            })}
        </Body>
      </BodyWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;

  height: calc(100%);
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: scroll;
`;

const Body = styled.div(
  () => css`
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  `
);

const SectionDivider = styled.div`
  width: 100%;
  height: 8px;
  min-height: 8px;
  background-color: #f3f6fb;
`;
export default MobilePrePitching;
