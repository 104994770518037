import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AddComma } from "../../../../../common/commonUtil";

import { ExpectationRateProps } from "../../interface/PerformanceMetrics.interface";
import { getPerformanceOutlook } from "../../../../../api/repository/accelerator/DashboardRepository";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  structureId?: number;
  selectedFundAccountId?: number[];
  selectedUserId?: number;
  selectedIndustrialTechId?: number[];
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}
interface OutlookTotalValueProps {
  totalInvestment: number;
  totalInvestInfo: number;
}
export const Expectation: React.FC<Props> = (props) => {
  const {
    structureId,
    selectedFundAccountId,
    selectedUserId,
    selectedIndustrialTechId,
    excavation,
    investor,
    charger,
    assistant,
  } = props;

  const isNetworking = useRef<boolean>(false);
  const [totalValue, setTotalValue] = useState<OutlookTotalValueProps>({
    totalInvestInfo: 0,
    totalInvestment: 0,
  });
  const [outLookData, setOutLookData] = useState<ExpectationRateProps[]>([]);

  const getData = async () => {
    isNetworking.current = true;
    const outlookResult = await getPerformanceOutlook({
      acStructureId: structureId,
      acFundAccountId: selectedFundAccountId,
      acIndustrialTechId: selectedIndustrialTechId,
      acUserId: selectedUserId,
      excavation: excavation,
      investor: investor,
      charger: charger,
      assistant: assistant,
    });

    if (outlookResult) {
      const totalInvestment = outlookResult
        .map((item) => item.totalInvestment)
        .reduce((prev, cur) => prev + cur, 0);
      const totalInvestInfo = outlookResult
        .map((item) => item.totalInvestInfo)
        .reduce((prev, cur) => prev + cur, 0);

      setTotalValue({
        totalInvestInfo: totalInvestInfo,
        totalInvestment: totalInvestment,
      });
      setOutLookData(
        outlookResult.map((item) => {
          let color = "rgba(237, 238, 239, 1)";
          switch (item.riskStatusId) {
            case 0:
              color = "rgba(237, 238, 239, 1)";
              break;
            case 1:
              color = "rgba(69, 132, 255, 1)";
              break;
            case 2:
              color = "rgba(59, 214, 153, 1)";
              break;
            case 3:
              color = "rgba(255, 216, 79, 1)";
              break;
            case 4:
              color = "rgba(254, 144, 65, 1)";
              break;
            case 5:
              color = "rgba(255, 114, 123, 1)";
              break;
          }
          return { ...item, color: color };
        })
      );
    }
    isNetworking.current = false;
  };

  useEffect(() => {
    getData();
  }, [
    structureId,
    selectedFundAccountId,
    selectedUserId,
    selectedIndustrialTechId,
    excavation,
    investor,
    charger,
    assistant,
  ]);

  return (
    <Container>
      <TitleContainer className="heading-18-sb">전망</TitleContainer>
      <BodyContainer>
        <FlexColumn style={{ width: "100%", paddingLeft: 20 }}>
          <FlexRow style={{ gap: 12 }}>
            {outLookData.map((item) => {
              return (
                <ProgressItem textColor={item.color || ""}>
                  <div className="heading-14-sb sub__title">{item.name}</div>
                </ProgressItem>
              );
            })}
          </FlexRow>
        </FlexColumn>
        <FlexRow style={{ gap: 88 }}>
          <FlexRow
            style={{
              alignItems: "center",
              justifyContent: "center",
              gap: 20,
              height: 160,
            }}
          >
            <div style={{ width: 130, height: 130 }}>
              <Doughnut
                style={{ display: "inline-block" }}
                data={{
                  labels: outLookData.map((item) => item.name),
                  datasets: [
                    {
                      label: "",
                      data: outLookData.map((item) => item.totalInvestment),
                      backgroundColor: [
                        "rgba(69, 132, 255, 1)",
                        "rgba(59, 214, 153, 1)",
                        "rgba(255, 216, 79, 1)",
                        "rgba(254, 144, 65, 1)",
                        "rgba(255, 114, 123, 1)",
                        "rgba(237, 238, 239, 1)",
                      ],
                      borderColor: [
                        "rgba(69, 132, 255, 1)",
                        "rgba(59, 214, 153, 1)",
                        "rgba(255, 216, 79, 1)",
                        "rgba(254, 144, 65, 1)",
                        "rgba(255, 114, 123, 1)",
                        "rgba(237, 238, 239, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                      text: "Chart.js Bar Chart",
                    },
                    tooltip: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return `${tooltipItem.dataset.label} : ${AddComma(
                            (tooltipItem.parsed / 100000000).toFixed(2)
                          )}억원(${(
                            (tooltipItem.parsed / totalValue.totalInvestment) *
                            100
                          ).toFixed(0)}%)`;
                        },
                      },
                    },
                  },
                  devicePixelRatio: 2,
                  cutout: 40,
                }}
              />
            </div>
            <ChartText>
              <span
                className="heading-14-sb"
                style={{ color: "var(--text-04)" }}
              >
                전체 투자 원금
              </span>
              <span
                className="heading-20-b"
                style={{ color: "var(--blue-500)" }}
              >
                {AddComma((totalValue.totalInvestment / 100000000).toFixed(0))}
                억
              </span>
            </ChartText>
          </FlexRow>
          <FlexRow
            style={{
              alignItems: "center",
              justifyContent: "center",
              gap: 20,
              height: 160,
            }}
          >
            <div style={{ width: 130, height: 130 }}>
              <Doughnut
                style={{ display: "inline-block" }}
                data={{
                  labels: outLookData.map((item) => item.name),
                  datasets: [
                    {
                      label: "",
                      data: outLookData.map((item) => item.totalInvestInfo),
                      backgroundColor: [
                        "rgba(69, 132, 255, 1)",
                        "rgba(59, 214, 153, 1)",
                        "rgba(255, 216, 79, 1)",
                        "rgba(254, 144, 65, 1)",
                        "rgba(255, 114, 123, 1)",
                        "rgba(237, 238, 239, 1)",
                      ],
                      borderColor: [
                        "rgba(69, 132, 255, 1)",
                        "rgba(59, 214, 153, 1)",
                        "rgba(255, 216, 79, 1)",
                        "rgba(254, 144, 65, 1)",
                        "rgba(255, 114, 123, 1)",
                        "rgba(237, 238, 239, 1)",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                      text: "Chart.js Bar Chart",
                    },
                    tooltip: {
                      callbacks: {
                        label: (tooltipItem) => {
                          return `${tooltipItem.dataset.label} : ${AddComma(
                            (tooltipItem.parsed / 100000000).toFixed(2)
                          )}억원(${(
                            (tooltipItem.parsed / totalValue.totalInvestInfo) *
                            100
                          ).toFixed(0)}%)`;
                        },
                      },
                    },
                  },
                  devicePixelRatio: 2,
                  cutout: 40,
                }}
              />
            </div>
            <ChartText>
              <span
                className="heading-14-sb"
                style={{ color: "var(--text-04)" }}
              >
                전체 지분 가치
              </span>
              <span
                className="heading-20-b"
                style={{ color: "var(--blue-500)" }}
              >
                {AddComma((totalValue.totalInvestInfo / 100000000).toFixed(0))}
                억
              </span>
            </ChartText>
          </FlexRow>
        </FlexRow>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BodyContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProgressItem = styled.div<{ textColor: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  color: ${(props) => props.textColor};

  .sub__title {
    color: var(--text-04);
  }

  ::before {
    position: absolute;
    content: " ";
    width: 9px;
    min-width: 9px;
    max-width: 9px;
    height: 9px;
    min-height: 9px;
    max-height: 9px;
    left: 0;
    top: 6px;
    background-color: ${(props) => props.textColor};
  }
`;
const ChartText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: default;
  user-select: none;
`;
