import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IAContractStatus } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";
import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { RemoveComma, getYMD } from "../../../../../../common/commonUtil";
import { CustomTextArea } from "../../table/custom-cell/CustomTextArea";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IAContractStatus[];
  onDataChange: (data: IAContractStatus[]) => void;
}

export const ContractStatusTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<IAContractStatus[] | undefined>(
    undefined
  );
  const headers: TableGridHeaderProps[] = [
    {
      header: "계약일자",
      key: "date",
      width: 182,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            isMonth
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultDate={data}
            onChangeDate={(date) => {
              if (!dataList) return;
              rowData.date = getYMD(date);
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "내용",
      key: "contents",
      width: 560,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="내용을 입력해주세요."
            onBlur={(value) => {
              if (!dataList) return;
              rowData.contents = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "계약 금액(백만 원)",
      key: "contractAmount",
      width: 160,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1,000"
            onBlur={(value) => {
              if (!dataList) return;
              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.contractAmount = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "계약 당사자",
      key: "contractor",
      width: 224,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="OO회사, 김블루"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.contractor = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newData: IAContractStatus = {};
    const newDataList: IAContractStatus[] = [...(dataList || []), newData];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    updateDataList(props.dataList);
  }, [props.dataList]);

  useEffect(() => {
    if (dataList) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
