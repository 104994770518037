import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

interface Props {
  isReadOnly?: boolean;
  width?: number | string;
  placeholder?: string;
  textClassName?: string;
  maxLength?: number;
  maxHeight?: number;
  defaultData?: string;
  isShowLength?: boolean;
  onChangeData?: (data: string) => void;
  onChangeDataRealTime?: (data: string) => void;
}

export const TextareaBox: React.FC<Props> = (props) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  const {
    isReadOnly,
    width = "100%",
    placeholder,
    textClassName = "body-14-rg",
    maxLength = 1000,
    maxHeight,
    defaultData,
    isShowLength,
    onChangeData,
    onChangeDataRealTime,
  } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaValue, setTextareaValue] = useState<string>("");

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    setTextareaValue(defaultData || "");
  }, [defaultData]);

  useEffect(() => {
    adjustTextareaHeight();
    onChangeDataRealTime && onChangeDataRealTime(textareaValue);
  }, [textareaValue]);

  return (
    <Container
      style={{ width: width, flexDirection: isMobile ? "column" : "row" }}
    >
      <TextArea
        className={`${textClassName} ${isReadOnly ? "read__only" : ""}`}
        ref={textareaRef}
        value={textareaValue}
        readOnly={props.isReadOnly}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(e) => {
          !isReadOnly && handleTextareaChange(e);
        }}
        onBlur={() =>
          !isReadOnly && onChangeData && onChangeData(textareaValue)
        }
        style={{ resize: "none", overflow: "hidden", maxHeight: maxHeight }}
      />
      {isShowLength && (
        <span
          className="caption-12-md"
          style={{ color: "var(--text-06)", width: 50 }}
        >
          ({textareaValue.length}
          {maxLength ? `/${maxLength}` : ""})
        </span>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;
const TextArea = styled.textarea`
  width: 100%;
  min-height: 30px;
  border: 1px solid var(--gray-300);
  padding: 6px 12px;

  border-radius: 4px;

  &.read__only {
    border: 1px solid transparent;
    padding: 9px 0px;

    :focus {
      outline: none;
      border: 1px solid transparent;
    }
  }
  ::placeholder {
    color: var(--text-inactive);
  }
  :focus {
    outline: none;
    border: 1px solid var(--blue-300);
  }
`;
