import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../SummaryBusinessPlanV2Download";
import RowTitleBox from "../../RowTitleBox";
import { AutoResizableTextarea } from "./AutoResizableTextarea";
import { SBPContentsPropsV2 } from "../../../interface/SBP.interface";

interface Props {
  index: number;
  sbpDocument: SBPContentsPropsV2;
  onLoaded: (index: number) => void;
}

export const Status1: React.FC<Props> = (props) => {
  const loadedRef = useRef<boolean[]>([]);
  const onLoaded = () => {
    console.log(`call status: ${props.index}`);
    loadedRef.current.push(true);

    if (loadedRef.current.length === 2) {
      props.onLoaded(props.index);
      loadedRef.current = [];
    }
  };
  return (
    <FlexColumn style={{ gap: 8 }}>
      <div className="body-16-md" style={{ color: "var(--text-03)" }}>
        0{props.index}. 사업 진행
      </div>
      <FlexColumn
        style={{
          padding: `5px 14px`,
          borderRadius: `${convertPixelToCm(4)}px`,
          gap: `5px`,
          backgroundColor: "var(--background-contents01)",
          border: "1px solid var(--gray-300)",
        }}
      >
        <RowTitleBox
          title="솔루션, 개발관점"
          textClassName="caption-12-md"
          titleWidth={convertPixelToCm(180)}
          style={{ width: "100%" }}
          transfromPixel={(n) => n}
        >
          <AutoResizableTextarea
            isReadOnly
            textClassName="caption-12-rg"
            defaultData={
              props.sbpDocument.businessStatus.solutionAndDevlopPoint
            }
            onLoaded={onLoaded}
          />
        </RowTitleBox>
        <RowTitleBox
          title="사업관점"
          textClassName="caption-12-md"
          titleWidth={convertPixelToCm(180)}
          style={{ width: "100%" }}
          transfromPixel={(n) => n}
        >
          <AutoResizableTextarea
            isReadOnly
            textClassName="caption-12-rg"
            defaultData={props.sbpDocument.businessStatus.businessPoint}
            onLoaded={onLoaded}
          />
        </RowTitleBox>
      </FlexColumn>
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
