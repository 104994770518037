import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  isActive?: boolean;
  onClick?: () => void;
}

const BubbleCheck: React.FC<Props> = (props) => {
  const { isActive, onClick, children } = props;
  return (
    <Container className={isActive ? "actived" : ""} onClick={onClick}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  border-radius: 20px;

  border: 1px solid var(--gray-300, #e3e4e5);
  color: var(--text-04, #7e8086);
  background: var(--background-contents01, #fff);

  cursor: pointer;

  &.actived {
    background: var(--blue-50, #f6faff);
    border: 1px solid var(--blue-400, #4584ff);
    color: var(--blue-400, #4584ff);
  }
`;
export default BubbleCheck;
