import { DefaultButton } from "lattice_core";
import React, { createElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  setModifiableState: () => void;
}
const SubmitedPage: React.FC<Props> = (props) => {
  return (
    <Container>
      <Logo
        className={"logo-20-hv"}
        style={{ textAlign: "center", color: "var(--primary-blue)" }}
      >
        Lattice
      </Logo>

      <AuthCodeContainer>
        <div className="heading-24-sb">제출이 완료되었습니다 🎉 </div>
        <div
          className="body-16-md"
          style={{
            color: "var(--text-05)",
            marginTop: 8,
            width: "100%",
          }}
        >
          작성하신 내용을 담당 심사역에게 전달하였습니다.
        </div>

        <div
          style={{
            marginTop: 60,
            width: 332,
          }}
        >
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="large"
            buttonColor="var(--primary-blue)"
            hoverColor="var(--blue-600)"
            borderColor="var(--gray-400)"
            text="나가기"
            onClick={() => {
              window.close();
            }}
          />
        </div>

        <div
          style={{
            marginTop: 10,
            width: 332,
          }}
        >
          <DefaultButton
            className="heading-16-md"
            buttonType="outline"
            sizeType="large"
            buttonColor="var(--primary-blue)"
            hoverColor="var(--blue-600)"
            borderColor="var(--gray-400)"
            text="수정하기"
            onClick={() => {
              props.setModifiableState();
            }}
          />
        </div>
      </AuthCodeContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Logo = styled.div`
  position: fixed;

  top: 45px;
  left: 50px;
`;

const AuthCodeContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CodeBox = styled.div`
  position: relative;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;

  border-radius: 4px;

  background-color: var(--background-content01);
  border: 1px solid var(--gray-300);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  text-transform: uppercase;

  &.fill_code {
    border: 1px solid var(--focus-field-blue);
  }

  &.current_code {
    border: 1px solid var(--focus-field-blue);
    ::after {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% - 24px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: black;

      animation: show_hide 1s steps(2) infinite;
    }
  }

  &.success_code {
    border: 1px solid var(--green-500);
  }

  &.fail_code {
    border: 1px solid var(--red-500);
    animation: shake 200ms;
  }

  @keyframes show_hide {
    0% {
      opacity: 0;
    }
  }

  @keyframes shake {
    25% {
      transform: translateX(2px);
    }
    50% {
      transform: translateX(-2px);
    }
    75% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(-2px);
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default SubmitedPage;
