import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IAStakeStatement } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";

import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { CustomTextArea } from "../../table/custom-cell/CustomTextArea";
import { RemoveComma } from "../../../../../../common/commonUtil";
import {
  fullTimeStatusList,
  relationshipList,
} from "../../../interface/REI.interface";
import GroupHeader from "../../table/GroupHeader";
import SumRow from "../../table/SumRow";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IAStakeStatement[];
  onDataChange: (data: IAStakeStatement[]) => void;
}

export const StakeStatementTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<IAStakeStatement[] | undefined>(
    undefined
  );
  const headers: TableGridHeaderProps[] = [
    {
      header: "주주명",
      key: "stockholderName",
      width: 220,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="김블루"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.stockholderName = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "주식수 (주)",
      key: "previousStockNumber",
      width: 186,
      customHeaderCell: (headerInfo) => {
        return (
          <GroupHeader
            gorupTitle="투자 전"
            title={headerInfo.header}
            transfromPixel={(n) => n}
          />
        );
      },
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1,000"
            onBlur={(value) => {
              if (!dataList) return;

              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.previousStockNumber = numValue;
              const sumPrevStockNumber = allData
                ?.map((item) => item.data["previousStockNumber"] || 0)
                .reduce((prev, cur) => prev + cur, 0);

              allData?.forEach((item) => {
                item.data["previousShareholdingPercentage"] = parseFloat(
                  (
                    (item.data["previousStockNumber"] / sumPrevStockNumber) *
                    100
                  ).toFixed(2)
                );
              });
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "지분율",
      key: "previousShareholdingPercentage",
      width: 186,
      customHeaderCell: (headerInfo) => {
        return (
          <GroupHeader
            gorupTitle=""
            title={headerInfo.header}
            transfromPixel={(n) => n}
            groupStyle={{ borderLeft: "1px solid transparent" }}
          />
        );
      },
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="percent"
            defaultValue={
              data !== undefined ? parseFloat(`${data}`).toFixed(2) : undefined
            }
            transfromPixel={(n) => n}
            placeholder="0.00"
            disabled={true}
            isReadOnly={true}
          />
        );
      },
    },

    {
      header: "주식수 (주)",
      key: "stockNumber",
      width: 186,
      customHeaderCell: (headerInfo) => {
        return (
          <GroupHeader
            gorupTitle="투자 후"
            title={headerInfo.header}
            transfromPixel={(n) => n}
          />
        );
      },
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1,000"
            onBlur={(value) => {
              if (!dataList) return;

              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.stockNumber = numValue;
              const sumStockNumber = allData
                ?.map((item) => item.data["stockNumber"] || 0)
                .reduce((prev, cur) => prev + cur, 0);

              allData?.forEach((item) => {
                item.data["shareholdingPercentage"] = parseFloat(
                  ((item.data["stockNumber"] / sumStockNumber) * 100).toFixed(2)
                );
              });

              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "지분율",
      key: "shareholdingPercentage",
      width: 186,
      customHeaderCell: (headerInfo) => {
        return (
          <GroupHeader
            gorupTitle=""
            title={headerInfo.header}
            transfromPixel={(n) => n}
            groupStyle={{ borderLeft: "1px solid transparent" }}
          />
        );
      },
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="percent"
            defaultValue={
              data !== undefined ? parseFloat(`${data}`).toFixed(2) : undefined
            }
            transfromPixel={(n) => n}
            placeholder="0.00"
            disabled={true}
            isReadOnly={true}
          />
        );
      },
    },
    {
      header: "비고",
      key: "note",
      width: 162,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            onBlur={(value) => {
              if (!dataList) return;
              rowData.note = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newData: IAStakeStatement = {};
    const newDataList: IAStakeStatement[] = [...(dataList || []), newData];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    updateDataList(props.dataList);
  }, [props.dataList]);

  useEffect(() => {
    if (dataList) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        addCustomFooterRow={(headerData, allData) => (
          <SumRow
            headerData={headerData}
            allRowData={allData}
            transfromPixel={(n) => n}
          />
        )}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
