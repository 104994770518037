import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Pdf } from "../../assets/image/large_pdf.svg";
import { DefaultButton } from "lattice_core";
import ModalLayout from "../../components/modal/ModalLayout";
import ConfirmModal from "../../2.0lattice/components/modal/ConfirmModal";
import { FileType } from "../../type/data";
import { viewPdfPopup } from "../../common/commonUtil";

interface Props {
  defaultData?: FileType;
  width?: number | string;
  height?: number | string;
}
export const PdfFileReadBtn: React.FC<Props> = (props) => {
  const { defaultData, width = "100%", height = "100%" } = props;

  const onClickFile = () => {
    if (defaultData) {
      viewPdfPopup(defaultData.key, 1000);
    }
  };

  return (
    <Container style={{ width: width, height: height }}>
      <FileWrapper className="body-14-rg">
        <FlexRow
          style={{
            gap: 6,
            alignItems: "center",
          }}
          onClick={onClickFile}
        >
          <Pdf width={24} height={24} />
          <FileName style={{ lineHeight: 1 }}>{defaultData?.fileName}</FileName>
        </FlexRow>
      </FileWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border: 1px solid var(--gray-200);
  border-radius: 4px;

  padding: 12px 24px;

  color: var(--text-01);
`;

const FileName = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
