import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { DefaultButton } from "lattice_core";
import {
  FundAccount,
  InvestmentAssociationTableProps,
} from "../../interface/FundAccount.interface";
import { AssociationTableComponent } from "../../component/AssociationTableComponent";
import { PurposeTableComponent } from "../../component/PurposeTableComponent";
import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import { InputBox } from "../../../../../../components-2.0v/input-box/InputBox";

interface Props {
  fundAccount: FundAccount;
  onChangeFundAccountInfo: (newData: FundAccount) => void;
}

export const StepThird: React.FC<Props> = (props) => {
  const { fundAccount, onChangeFundAccountInfo } = props;

  return (
    <Container>
      <FlexColumn style={{ flex: 3, borderRight: "1px solid var(--gray-400)" }}>
        <div
          className="heading-16-sb"
          style={{ marginLeft: 16, marginTop: 16 }}
        >
          기본 정보
        </div>
        <InvestmentOrganizationList>
          <RowTitleBox title="약정 총액" titleWidth={95}>
            <InputBox
              isReadOnly
              textType={"numberWithComma"}
              defaultValue={`${fundAccount.totalAmount || "0"}`}
            />
          </RowTitleBox>

          <FlexColumn>
            <div className="heading-14-sb" style={{ color: "var(--text-04)" }}>
              조건
            </div>
            <PurposeConditionContainer className="body-14-rg">
              <li>목표 금액은 약정총액을 넘길 수 없습니다.</li>
              <li>주목적은 필수로 선택해야 합니다.</li>
            </PurposeConditionContainer>
          </FlexColumn>
        </InvestmentOrganizationList>
      </FlexColumn>

      <FlexColumn style={{ flex: 7 }}>
        {fundAccount.investmentAssociation.filter(
          (item) => !item.investmentAmount
        ).length > 0 ? (
          <EmptyContainer className="body-14-md">
            <div>출자 정보가 없습니다.</div>
            <div>출자 정보를 입력 해주세요.</div>
          </EmptyContainer>
        ) : (
          <PurposeTableComponent
            totalAmount={fundAccount.totalAmount || 0}
            purposeList={fundAccount.fundPurpose || []}
            onDataChange={(data) => {
              console.log(data);
              fundAccount.fundPurpose = [...data];
              onChangeFundAccountInfo(fundAccount);
            }}
          />
        )}
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  border-top: 1px solid var(--gray-400);
`;

const InvestmentOrganizationList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
`;
const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--text-04);
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const PurposeConditionContainer = styled.ul`
  margin-top: 8px;
  list-style-type: circle;

  li {
    padding: 2px 0px;
    margin-left: 32px;
  }
`;
