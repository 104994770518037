import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

import { ReactComponent as CheckT } from "../../../assets/image/new_check_box_true.svg";
import { ReactComponent as CheckF } from "../../../assets/image/new_check_box_false.svg";
import { AddComma } from "../../../../../common/commonUtil";

interface Props {
  damagedCompanyFinancialStatementValuationSum?: number;
  style?: React.CSSProperties;
}

export const DCFooter: React.FC<Props> = (props) => {
  const HEIGHT = 32;
  return (
    <Container style={props.style}>
      <CellConatiner
        className={`caption-10-sb`}
        style={{
          flexGrow: 1,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        합계
      </CellConatiner>

      <CellConatiner
        className={`caption-10-sb`}
        style={{
          width: 120,
          minWidth: 120,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
          justifyContent: "flex-end",
        }}
      >
        {props.damagedCompanyFinancialStatementValuationSum
          ? AddComma(props.damagedCompanyFinancialStatementValuationSum)
          : "-"}
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.div`
  width: 100%;
  cursor: default;

  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  background: var(--gray-500);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 8px;

  color: #000;

  &:nth-child(1) {
    border-left: 2px solid #000;
  }
`;
