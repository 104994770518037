import React from "react";
import styled from "styled-components";

import {
  AddComma,
  addCommasToIntegerPart,
  getYMD,
} from "../../../../../../common/commonUtil";
import {
  INVEST,
  InvestmentHistoryAdminProps,
} from "../../interface/Portfolio.interface";

interface Props {
  data: InvestmentHistoryAdminProps;
  onClick: () => void;
}
export const InvestExitHistoryRow: React.FC<Props> = ({ data, onClick }) => {
  return (
    <Container className={`caption-12-rg`} onClick={onClick}>
      <CellConatiner width={80} style={{ justifyContent: "center" }}>
        {data.type === INVEST ? "투자" : "판매"}
      </CellConatiner>
      <CellConatiner width={100} style={{ justifyContent: "flex-start" }}>
        {getYMD(data.date)}
      </CellConatiner>
      <CellConatiner width={100} style={{ justifyContent: "flex-start" }}>
        {data.fundAccount}
      </CellConatiner>
      <CellConatiner width={100} style={{ justifyContent: "flex-start" }}>
        {data.stockType}
      </CellConatiner>
      <CellConatiner width={100} style={{ justifyContent: "flex-end" }}>
        {AddComma(data.stock)}주
      </CellConatiner>
      <CellConatiner width={100} style={{ justifyContent: "flex-end" }}>
        {addCommasToIntegerPart(data.stockPrice)}원
      </CellConatiner>
      <CellConatiner width={120} style={{ justifyContent: "flex-end" }}>
        {addCommasToIntegerPart(data.amount)}원
      </CellConatiner>
      <CellConatiner
        width={200}
        style={{
          justifyContent: "flex-start",
          borderRight: "0.5px solid transparent",
        }}
      >
        {data.target}
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  width: max-content;
  height: max-content;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  background-color: var(--background-contents01, #fff);
  cursor: pointer;

  &.error {
    background-color: var(--red-50);
  }
  :hover {
    background-color: var(--hover-100);
  }
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  min-height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  color: var(--text-01);
  border-right: 0.5px solid var(--divider-300, #e3e4e5);
  border-bottom: 0.5px solid var(--divider-300, #e3e4e5);
  padding: 8px 10px;
`;
