import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./fonts/SUIT.css";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import config from "./config";

console.log(`current env is ${config.env}`);
console.log(`current version is ${config.ver}`);

if (config.env === "production") console.log = () => {};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
