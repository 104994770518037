import React from "react";
import styled from "styled-components";

import { useMediaQuery } from "react-responsive";

interface Props {
  SvgIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  progress: number;
  message?: string;
  progressFail: boolean;
  transfromPixel: (value: number) => number;
}
const ProgressModalStatic: React.FC<Props> = (props) => {
  const { message = "", progress, SvgIcon, progressFail } = props;
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <Container style={{ width: isMobile ? "90vw" : 506 }}>
      <ProgressContainer>
        <ProgressCircle progress={progress} progressFail={progressFail}>
          {SvgIcon && <SvgIcon className="icon" />}
        </ProgressCircle>
        <ProgressBarContainer>
          <TextContainer>
            <span className="heading-18-b">{progress.toFixed(0)}%</span>
            <span
              className="caption-12-md"
              style={{ color: "var(--gray-600)", paddingBottom: 4 }}
            >
              {message}
            </span>
          </TextContainer>
          <ProgressBar progress={progress} progressFail={progressFail} />
        </ProgressBarContainer>
      </ProgressContainer>
    </Container>
  );
};

const Container = styled.div`
  width: calc(24px + 48px + 10px + 400px + 24px);

  display: flex;
  flex-direction: column;
  padding: 36px 24px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ProgressCircle = styled.div<{ progress: number; progressFail: boolean }>`
  position: relative;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  border-radius: 50%;

  background: conic-gradient(
    ${(props) =>
        props.progressFail ? "var(--red-500)" : "var(--primary-blue)"}
      calc(${(props) => (props.progress / 100) * 360}deg),
    var(--gray-200) 0deg
  );
  overflow: hidden;

  transition: all 0.2s ease;

  .icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  ::after {
    content: "";
    position: absolute;
    inset: 3px;
    border-radius: 50%;
    background-color: var(--white);
  }
`;

const ProgressBarContainer = styled.div`
  width: 400px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-end;
`;

const ProgressBar = styled.div<{ progress: number; progressFail: boolean }>`
  position: relative;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 9px;
  min-height: 9px;
  max-height: 9px;
  background-color: var(--gray-300);
  transition: all 0.2s ease;
  border-radius: 6px;

  margin-bottom: 6px;

  ::before {
    content: " ";
    position: absolute;
    left: 0;
    width: calc(100% * ${(props) => props.progress / 100});
    height: 100%;
    background-color: ${(props) =>
      props.progressFail ? "var(--red-500)" : "var(--primary-blue)"};
    border-radius: 6px;
    transition: all 0.2s ease;
  }
`;
export default ProgressModalStatic;
