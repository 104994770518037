import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Radio } from "../../../../../../assets/image/icon_radio.svg";
import { OtherAuthority } from "../interface/Structure.interface";
import CheckedText from "../../../../../components-2.0v/check-box/CheckedText";

interface Props {
  className?: string;
  defaultValue?: OtherAuthority[];
  onChange?: (auth: OtherAuthority[]) => void;
}

export const Authority: React.FC<Props> = (props) => {
  const [auth, setAuth] = useState<OtherAuthority[]>([]);

  const onChangeAuth = (value: OtherAuthority) => {
    const newData = structuredClone(auth);
    const index = newData.findIndex((item: any) => item === value);
    if (index !== -1) {
      newData.splice(index, 1);
    } else {
      newData.push(value);
    }
    setAuth(newData);
    props.onChange && props.onChange(newData);
  };

  useEffect(() => {
    setAuth(props.defaultValue || []);
  }, [props.defaultValue]);
  return (
    <JobAuthorityRadioContainer className="body-14-rg">
      <div className="auth__item">
        <CheckedText
          checkedColor="var(--primary-purple)"
          isChecked={auth.includes("BoardMember")}
          onClick={() => onChangeAuth("BoardMember")}
        >
          경영진 권한 (분기보고 전체 포트폴리오 접속 권한)
        </CheckedText>
      </div>

      <div className="auth__item">
        <CheckedText
          checkedColor="var(--primary-purple)"
          isChecked={auth.includes("ReportManager")}
          onClick={() => onChangeAuth("ReportManager")}
        >
          분기보고 담당자 권한 (분기보고 관련 알람 발송. 메일, 알림, 슬랙 등)
        </CheckedText>
      </div>
      <div className="auth__item">
        <CheckedText
          checkedColor="var(--primary-purple)"
          isChecked={auth.includes("DownloadOriginal")}
          onClick={() => onChangeAuth("DownloadOriginal")}
        >
          의사록 원본 다운로드 권한 (투자심의의사록 원본을 다운로드 할 수 있는
          권한)
        </CheckedText>
      </div>
    </JobAuthorityRadioContainer>
  );
};

const JobAuthorityRadioContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .auth__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .checked {
      path {
        fill: var(--primary-purple);
      }
    }
  }
`;
