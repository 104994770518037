import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as RadioOn } from "../../../../../assets/image/radio_on.svg";
import { ReactComponent as RadioOff } from "../../../../../assets/image/radio_off.svg";

interface Props {
  className?: string;
  showEmpty?: boolean;
  isRequired?: boolean;
  defaultValue?: string;
  editable: boolean;
  data?: NewOldStockRadioProps[];
  onChange?: (item: NewOldStockRadioProps) => void;
}

export interface NewOldStockRadioProps {
  id: string | number;
  text: string;
  data?: any;
}

export const NewOldStockRadio: React.FC<Props> = (props) => {
  const { editable } = props;
  const [value, updateValue] = useState<string>("");
  const onChangeValue = (item: NewOldStockRadioProps) => {
    updateValue(item.text);
    props.onChange && props.onChange(item);
  };

  useEffect(() => {
    updateValue(props.defaultValue || "");
  }, [props.defaultValue]);

  return (
    <Container className={props.className || ""}>
      <RadioBoxContainer
        className={`body-14-rg ${
          props.isRequired && value.length === 0 && props.showEmpty
            ? "empty"
            : ""
        }`}
      >
        {editable &&
          props.data?.map((item) => {
            return (
              <RadioItem
                key={item.id}
                className={value === item.text ? "selected" : ""}
                onClick={() => {
                  onChangeValue(item);
                }}
              >
                <RadioOn />
                {item.text}
              </RadioItem>
            );
          })}
        {!editable && (
          <div className="body-14-rg" style={{ paddingLeft: 10 }}>
            {value}
          </div>
        )}
      </RadioBoxContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  gap: 4px;
`;

const RadioBoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  &.empty {
    border: 1px solid red;
    border-radius: 4px;

    input {
      border-color: transparent;
    }
    animation: shake 300ms;
  }

  @keyframes shake {
    25% {
      transform: translateX(4px);
    }
    50% {
      transform: translateX(-4px);
    }
    75% {
      transform: translateX(4px);
    }
  }
`;

const RadioItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  line-height: 1;

  cursor: pointer;

  circle {
    stroke-width: 1;
  }

  circle:nth-child(1) {
    stroke: var(--gray-400);
  }

  circle:nth-child(2) {
    stroke: var(--gray-400);
    fill: var(--gray-400);
  }

  &.selected {
    circle:nth-child(1) {
      stroke: var(--primary-blue);
    }

    circle:nth-child(2) {
      stroke: var(--primary-blue);
      fill: var(--primary-blue);
    }
  }
`;
