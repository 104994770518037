import { StepProps } from "../../../../../components-2.0v/steps/StepComponent";
import {
  PortfolioPhaseType,
  StakeHolderProps,
  TinyUserInfo,
} from "../../../../../type/data";

export const STEP_FIRST = 1;
export const STEP_SECOND = 2;
export const STEP_THIRD = 3;

export const MAIN_PARTICIPANT = 1;
export const SUB_PARTICIPANT = 2;

export const INVEST = 1;
export const EXIT = 2;

export const NEW_STOCK = 1;
export const OLD_STOCK = 2;

export const ORIGIN_ACCOUNT = 1;
export const FUNDING_ACCOUNT = 2;

export const PHASE_EXCAVATION = 1;
export const PHASE_REVIEW = 2;
export const PHASE_GROWTH = 3;
export const PHASE_CONTRACT = 4;
export const PHASE_MANAGE = 5;
export const PHASE_DAMAGE = 6;
export const PHASE_EXIT = 7;
export const PHASE_DROP = 8;
export const PHASE_CLOSURE = 9;
export const PHASE_WITHDRAW = 10;

export interface PortfolioAdminProps {
  portfolioId: number;
  companyName: string;
  description?: string | null;
  firstPaymentDate: string;
  pfPhase: {
    pfPhaseId: number;
    type: PortfolioPhaseType;
    name: string;
  };
  pfParticipant: ParticipantAdminProps[];
}

export interface PortfolioDetailAdminProps {
  portfolioId: number;
  companyName: string;
  description: string;
  damagedAt?: string | null;
  firstPaymentDate?: string | null;
  childCompany: boolean;
  excavator: TinyUserInfo;
  pfPhase: {
    pfPhaseId: number;
    type: PortfolioPhaseType;
    name: string;
  };
  pfParticipant: ParticipantAdminProps[];
  pfFairValue?: FairValueAdminProps[];
  investmentInfo: InvestemntInfoAdminProps[];
  investmentAccount: InvestmentAccountAdminProps[];
  investmentExit: InvestmentExitAdminProps[];
  pfParticipantHistory: ParticipantHisotryAdminProps[];
}

export interface FairValueAdminProps {
  year: number;
  fairValue: {
    id: number;
    acFundAccount: { nameKo: string; nickName: string };
    amount: number;
  }[];
}

export interface ParticipantHisotryAdminProps {
  id: number;
  date: string;
  acUser: TinyUserInfo;
}

export interface ParticipantAdminProps {
  pfParticipantId: number;
  screenRole: {
    screenRoleId: number;
  };
  acUser: TinyUserInfo;
}

export interface InvestmentHistoryAdminProps {
  id: number;
  type: number;
  date: string;
  fundAccount: string;
  stockType: string;
  stock: number;
  stockPrice: number;
  amount: number;
  target: string;
}

export interface InvestemntInfoAdminProps {
  id: number;
  number: number;
  percentage: number;
  principal: number;
  acFundAccount: {
    acFundAccountId: number;
    nameKo: string;
  };
  stockType: {
    stockTypeId: number;
    name: string;
  } | null;
}

export interface InvestmentAccountAdminProps {
  investmentAccountId: number;
  issuingPrice: number;
  stockNumber: number;
  investmentAmount: number;
  paymentDate: string;
  acFundAccount: {
    acFundAccountId: number;
    nameKo: string;
  };
  newOldStock: {
    newOldStockId: number;
  };
  stockType: {
    stockTypeId: number;
    name: string;
  };
}

export interface InvestmentExitAdminProps {
  id: number;
  date: string;
  stock: number;
  stockPrice: number;
  amount: number;
  principal: number;
  buyer: string;
  gpName?: string | null;
  acFundAccount: {
    acFundAccountId: number;
    nameKo: string;
  };
  stockType: {
    stockTypeId: number;
    name: string;
  };
  acInvestmentOrganization: {
    acInvestmentOrganizationId: number;
    name: string;
    stakeHolder: StakeHolderProps;
  };
}

export interface ExitProcessProps {
  id: number;
  portfolioId: number;
  acFundAccount: {
    acFundAccountId: number;
    nameKo: string;
  };
  stockType?: {
    stockTypeId: number;
    name: string;
  };
  currentPrincipal: number;
  currentStock: number;

  currentPercentage: number;
  exitInfo: ExitInfoProps[];
}

export interface ExitInfoProps {
  portfolio?: {
    portfolioId: number;
  };
  acFundAccount?: {
    acFundAccountId: number;
    nameKo: string;
  };
  stockType?: {
    stockTypeId: number;
    name: string;
  };
  date?: string;
  stock?: number; // 주식수
  stockPrice?: number; // 주당가격
  amount?: number; // 회수금액
  principal?: number; // 회수원금
  companyValue?: number; // 기업가치
  acInvestmentOrganization: {
    acInvestmentOrganizationId: number;
    name?: string;
  };
}

export interface CreateBuyerProps {
  stakeType?: number; // 1: 개인, 2: 법인, 3: 펀드
  name?: string;
  uniqueNumber?: string;
  managerName?: string;
  managerEmail?: string;
  managerPhone?: string;
  gpStakeType?: number; // 1: 개인, 2: 법인
  gpName?: string;
  gpUniqueNumber?: string;
}

export interface FairValueAmountExportProps {
  year: string;
  acFundAccountId: string;
  type: string;
  portfolioId: string;
  companyName: string;
  fundName: string;
  amount: string;
}

export interface FairValueAmountProps {
  portfolioId: number;
  companyName: string;
  acFundAccount: [
    {
      acFundAccountId: number;
      nameKo: string;
      amount?: number;
      type: number;
    }
  ];
}

export const ExitStep: StepProps[] = [
  { step: STEP_FIRST, text: "주식 선택", isValidate: false },
  { step: STEP_SECOND, text: "이해관계자 선택", isValidate: false },
  { step: STEP_THIRD, text: "주식정보 입력", isValidate: false },
];
