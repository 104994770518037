import React from "react";

import styled from "styled-components";
import { TableGridHeaderProps } from "./TableGrid";
import CustomHeader from "./CustomHeader";
import { convertPixelToCm } from "../../SummaryBusinessPlanV2Download";

interface Props {
  isReadOnly?: boolean;
  headers: TableGridHeaderProps[];
}

const TableHeader: React.FC<Props> = ({ isReadOnly, headers }) => {
  return (
    <Container>
      {headers.map((item, index) => {
        return (
          <CellConatiner
            key={index}
            className={`caption-12-rg ${index === 0 ? "first" : ""} ${
              index === headers.length - 1 ? "last" : ""
            }`}
            asdf={item.width}
            height={34 * (764 / 1200)}
            convertPixelToCm={convertPixelToCm}
            style={{
              ...item.style,
            }}
          >
            {item.customHeaderCell ? (
              item.customHeaderCell()
            ) : (
              <CustomHeader title={item.header} />
            )}
          </CellConatiner>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0px;
  width: auto;
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.div<{
  convertPixelToCm: (n: number) => number;
  asdf: number;
  height: number;
}>`
  width: 100%;
  cursor: default;
  color: var(--primary);
  background-color: #f6faff;
  border-left: 1px solid var(--divider-200);
  border-bottom: 1px solid var(--divider-200);

  width: ${(props) => props.asdf}px;
  min-width: ${(props) => props.asdf}px;
  height: ${(props) => props.height}px;
  min-height: ${(props) => props.height}px;
  max-height: ${(props) => props.height}px;

  &:nth-child(1) {
    border-left: 1px solid transparent;
  }
`;

export const CheckCellConatiner = styled.div`
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.opened {
    path {
      fill: #d1e3ff;
    }
  }
`;

export default TableHeader;
