import React from "react";
import styled from "styled-components";

import { FundManagerProps } from "../../interface/FundAccountDetail.interface";

import { ReactComponent as DefaultUser } from "../../../../../assets/image/default_user_img.svg";
import { ReactComponent as Crown } from "../../../../../assets/image/crown.svg";

interface Props {
  data: FundManagerProps[];
}
export const FundManager: React.FC<Props> = (props) => {
  return (
    <Container>
      <TitleContainer className="heading-18-sb">
        <span>운용 인력</span>
      </TitleContainer>
      <BodyContainer>
        <FlexRow className="body-14-md" style={{ gap: 12, flexWrap: "wrap" }}>
          {props.data.map((item) => (
            <Avatar>
              <AvatarContainer>
                {item.profileImgUrl ? (
                  <img
                    className="avatar"
                    src={item.profileImgUrl}
                    loading="lazy"
                    alt={"profile img"}
                  />
                ) : (
                  <DefaultUser className="avatar" />
                )}
                {item.isMain && <Crown className={"crown__fund__manager"} />}
              </AvatarContainer>
              <span>{item.name}</span>
            </Avatar>
          ))}
        </FlexRow>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Avatar = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  height: 38px;
  min-height: 38px;
  max-height: 38px;

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    border: 1px solid var(--gray-200);
    border-radius: 50%;
  }

  .crown__fund__manager {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    path:nth-of-type(1) {
      fill: var(--primary-blue);
    }
  }

  .crown__expert__manager {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    path:nth-of-type(1) {
      fill: var(--blue-300);
    }
  }
`;
