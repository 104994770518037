import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FinancialStatementInput } from "../FinancialStatementInput";
import { FinancialStatementFullProps } from "../../../../../../../type/data";
import { RemoveComma } from "../../../../../../../common/commonUtil";

interface Props {
  data: FinancialStatementFullProps;
  currency: string;
  onChangeData: (data: FinancialStatementFullProps) => void;
}
export const IncomeStatementPage: React.FC<Props> = (props) => {
  const { data, onChangeData, currency } = props;
  return (
    <Container>
      <FinancialStatementInput
        readonly
        title="매출이익/손실"
        value={
          data.grossProfit !== undefined ? `${data.grossProfit}` : undefined
        }
        currency={currency}
      />
      <FinancialStatementInput
        title="매출액"
        value={data.revenue !== undefined ? `${data.revenue}` : undefined}
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, revenue: transValue });
        }}
      />
      <FinancialStatementInput
        title="매출원가"
        value={
          data.costOfSales !== undefined ? `${data.costOfSales}` : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, costOfSales: transValue });
        }}
      />
      <FinancialStatementInput
        readonly
        title="영업이익/손실"
        value={
          data.operatingProfit !== undefined
            ? `${data.operatingProfit}`
            : undefined
        }
        currency={currency}
      />
      <FinancialStatementInput
        title="판매비 및 일반관리비"
        value={
          data.sellingGeneralAdministrativeExpenses !== undefined
            ? `${data.sellingGeneralAdministrativeExpenses}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({
            ...data,
            sellingGeneralAdministrativeExpenses: transValue,
          });
        }}
      />
      <FinancialStatementInput
        readonly
        title="법인세비용차감전순이익/손실"
        value={
          data.profitBeforeTaxExpense !== undefined
            ? `${data.profitBeforeTaxExpense}`
            : undefined
        }
        currency={currency}
      />
      <FinancialStatementInput
        title="영업외수익"
        value={
          data.nonOperatingIncome !== undefined
            ? `${data.nonOperatingIncome}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, nonOperatingIncome: transValue });
        }}
      />
      <FinancialStatementInput
        title="영업외비용"
        value={
          data.nonOperatingExpenses !== undefined
            ? `${data.nonOperatingExpenses}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, nonOperatingExpenses: transValue });
        }}
      />
      <FinancialStatementInput
        title="법인세비용"
        value={
          data.incomeTaxExpense !== undefined
            ? `${data.incomeTaxExpense}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, incomeTaxExpense: transValue });
        }}
      />
      <FinancialStatementInput
        title="당기순이익/손실"
        readonly
        value={data.netIncome !== undefined ? `${data.netIncome}` : undefined}
        currency={currency}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--gray-500);
  background: var(--light-background-contents-01, #fff);
  gap: 8px;
`;
