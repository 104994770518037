import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Sector } from "../Sector";

import {
  ExpertFileProps,
  InvestmentAssessment,
} from "../../../../../type/data";
import { TextareaBox } from "../../../../../components-2.0v/input-box/TextareaBox";
import { ExpertFileUpload } from "../ExpertFileUpload";
import { ExpertFileCard } from "../ExpertFileCard";

interface Props {
  isReadOnly: boolean;
  data?: InvestmentAssessment;
  onChangeData?: (data: InvestmentAssessment) => void;
}

export const Section5: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;

  if (!data) return null;
  useEffect(() => {
    console.log(data);
  });
  return (
    <Container>
      <Sector title="의견" id="Section5_1">
        <FlexColumn style={{ gap: 8 }}>
          <TextareaBox
            placeholder={`종합적인 의견을 작성해주세요.`}
            defaultData={data.expertInfo?.expertOpinion?.opinion}
            onChangeData={(value) => {
              if (!data.expertInfo) return;

              console.log(value);
              if (data.expertInfo.expertOpinion) {
                data.expertInfo.expertOpinion.opinion = value;
              } else {
                data.expertInfo.expertOpinion = { opinion: value };
              }
              console.log(data);
              onChangeData && onChangeData(data);
            }}
          />
        </FlexColumn>
      </Sector>

      <Sector title="리포트 첨부 파일" id="Section5_2">
        <ExpertFileUpload
          reportFile={data.expertInfo?.expertOpinion?.reportFile}
          onChangeFile={(files) => {
            if (!data.expertInfo) return;

            const newFile = files.map((item): ExpertFileProps => {
              return { key: "localFile", localFile: item };
            });
            if (data.expertInfo.expertOpinion) {
              data.expertInfo.expertOpinion.reportFile = data.expertInfo
                .expertOpinion.reportFile
                ? [...data.expertInfo.expertOpinion.reportFile, ...newFile]
                : [...newFile];
            } else {
              data.expertInfo.expertOpinion = { reportFile: [...newFile] };
            }
            onChangeData && onChangeData(data);
          }}
          onDeleteFile={(index) => {
            if (!data.expertInfo) return;
            if (!data.expertInfo.expertOpinion) return;
            if (!data.expertInfo.expertOpinion.reportFile) return;

            data.expertInfo.expertOpinion.reportFile.splice(index, 1);
            onChangeData && onChangeData(data);
          }}
        />
      </Sector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--gray-700);
        stroke: var(--gray-700);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--gray-700);
        }
      }
    }
  }
`;
