import React from "react";
import styled from "styled-components";

interface Props {
  dangerTooltip?: JSX.Element;
  title: string;
  isActive: boolean;
  onClick: () => void;
}
const PortfolioNavigationMenu: React.FC<Props> = ({
  dangerTooltip,
  title,
  isActive,
  onClick,
}) => {
  return (
    <Container
      className={`heading-14-md ${isActive ? "selected" : ""}`}
      onClick={onClick}
    >
      <span>{title}</span>
      {dangerTooltip ? dangerTooltip : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: max-content;
  min-width: max-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  color: var(--text-03);

  cursor: pointer;

  &.selected {
    color: var(--primary-blue);
    border-bottom: 2px solid var(--primary-blue);
    .icon__container {
      path {
        stroke: var(--primary-blue);
      }
    }

    :hover {
      color: var(--primary-blue);
      .icon__container {
        path {
          stroke: var(--primary-blue);
        }
      }
    }
  }

  .icon__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
      max-width: 18px;
      max-height: 18px;
    }
  }

  :hover {
    color: var(--blue-400);
    .icon__container {
      path {
        stroke: var(--blue-400);
      }
    }
  }
`;
export default PortfolioNavigationMenu;
