import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CustomColorLabel } from "../../../../components-2.0v/label/CustomColorLabel";
import { CircleUserList } from "./CircleUserList";
import { AcceleratingPortfolioProps } from "../../interface/Accelerating.interface";
import { addCommasToIntegerPart, getYMD } from "../../../../common/commonUtil";

interface Props {
  portfolio: AcceleratingPortfolioProps;
  onClickCard?: () => void;
}
export const AcceleratingPortfolioCard: React.FC<Props> = (props) => {
  const { portfolio, onClickCard } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const getRiskStatusLabel = () => {
    if (!portfolio.riskStatus) {
      return (
        <CustomColorLabel textColor="var(--text-05)" bgColor="var(--gray-200)">
          평가없음
        </CustomColorLabel>
      );
    }
    switch (portfolio.riskStatus?.id) {
      case 1:
        return (
          <CustomColorLabel
            textColor="var(--blue500, #173AFF)"
            bgColor="var(--blue100, #E8F0FF)"
          >
            성장
          </CustomColorLabel>
        );
      case 2:
        return (
          <CustomColorLabel
            textColor="var(--green-700, #28BE83)"
            bgColor="#E9F7F1"
          >
            관찰
          </CustomColorLabel>
        );
      case 3:
        return (
          <CustomColorLabel textColor="#F5BF00" bgColor="#FFF8E0">
            우려
          </CustomColorLabel>
        );
      case 4:
        return (
          <CustomColorLabel
            textColor="var(--orange-500, #FE9041)"
            bgColor="var(--orange-50, #FFF5EE)"
          >
            손상우려
          </CustomColorLabel>
        );
      case 5:
        return (
          <CustomColorLabel textColor="var(--red-500)" bgColor="var(--red-50)">
            손상
          </CustomColorLabel>
        );
      case 6:
        return (
          <CustomColorLabel
            textColor="var(--text-05, #96999F)"
            bgColor="var(--gray-200, #EDEEEF)"
          >
            폐업진행
          </CustomColorLabel>
        );
    }

    return null;
  };
  const getLabel = () => {
    switch (portfolio.isTips) {
      case "NO":
        return null;
      case "EXPECTED":
        return (
          <CustomColorLabel
            className={`caption-12-sb`}
            style={{
              borderRadius: 20,
              lineHeight: 1,
              // padding: "4px 6px 4px 6px",
            }}
            // bgColor="var(--blue-100)"
            // textColor="var(--text-05)"
            bgColor="transparent"
            textColor="var(--text-04)"
          >
            TIPS 희망
          </CustomColorLabel>
        );
      case "FIXED":
        return (
          <CustomColorLabel
            className={`caption-12-sb`}
            style={{
              borderRadius: 20,
              lineHeight: 1,
              // padding: "4px 6px 4px 6px",
            }}
            // bgColor="var(--blue-400)"
            // textColor="var(--text-white)"
            bgColor="transparent"
            textColor="var(--blue-400)"
          >
            TIPS 확정
          </CustomColorLabel>
        );
    }
  };
  return (
    <Container ref={containerRef} onClick={onClickCard}>
      {getRiskStatusLabel()}
      <PortfolioName className="heading-18-sb" style={{ marginTop: 6 }}>
        {portfolio.companyName}
      </PortfolioName>
      <Description className="caption-12-rg">
        {portfolio.description}
      </Description>
      <FlexRow style={{ width: "100%", marginTop: 19, gap: 8 }}>
        <span className="caption-12-rg" style={{ color: "var(--text-05)" }}>
          투자재원
        </span>
        <span className="caption-12-rg" style={{ color: "var(--text-01)" }}>
          {portfolio.investmentAccount
            .map(
              (item) => item.acFundAccount.nickName || item.acFundAccount.nameKo
            )
            .join(", ")}
        </span>
      </FlexRow>
      <FlexRow style={{ width: "100%", marginTop: 4, gap: 10 }}>
        <FlexRow style={{ flex: 6, gap: 8 }}>
          <span className="caption-12-rg" style={{ color: "var(--text-05)" }}>
            추정기업가치
          </span>
          <span className="caption-12-rg" style={{ color: "var(--text-01)" }}>
            {portfolio.investInfo
              ? addCommasToIntegerPart(
                  (portfolio.investInfo.companyValue / 100000000).toFixed(
                    portfolio.investInfo.companyValue / 100000000 > 1 ? 0 : 2
                  )
                )
              : "-"}
            억원
          </span>
        </FlexRow>

        <FlexRow style={{ flex: 4, gap: 8 }}>
          <span className="caption-12-rg" style={{ color: "var(--text-05)" }}>
            멀티플
          </span>
          <span className="caption-12-rg" style={{ color: "var(--text-01)" }}>
            {portfolio.investInfo
              ? portfolio.investInfo.averageMultiple.slice(0, 4)
              : "-"}
            X
          </span>
        </FlexRow>
      </FlexRow>

      <FlexRow
        style={{
          width: "100%",
          marginTop: 20,
          justifyContent: "space-between",
        }}
      >
        <FlexRow style={{ alignItems: "center", gap: 12 }}>
          <CircleUserList
            userList={portfolio.pfParticipant.map((item) => ({
              profileImg: item.acUser.profileImgUrl,
              userName: item.acUser.name,
            }))}
            maxShowUserCount={2}
          />
          <VerticalDivder />
          <span className="caption-12-rg" style={{ color: "var(--text-06)" }}>
            {getYMD(portfolio.firstPaymentDate).replaceAll("-", ".")}
          </span>
        </FlexRow>
        <FlexRow style={{ alignItems: "center", gap: 4 }}>{getLabel()}</FlexRow>
      </FlexRow>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  flex-grow: 0;
  flex-basis: 282px;

  padding: 24px 24px 24px 16px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const PortfolioName = styled.div`
  width: 100%;
  margin-top: 6px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  width: 100%;
  min-height: 40px;
  margin-top: 12px;
  color: var(--text-05);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const VerticalDivder = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-200, #edeeef);
`;
