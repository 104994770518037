import React, { useState } from "react";
import styled from "styled-components";
import { animated, useSpring } from "@react-spring/web";
import { ReactComponent as ChatIcon } from "../../../../../assets/image/icon_chat.svg";
import { ReactComponent as Loading } from "../../../../../assets/image/loading.svg";

import ChatingDetail from "./ChatingDetail";
import { ChatingSpeachBubbleProps } from "./useChating";
import UnreadTooltipBouncer from "./UnreadTooltipBouncer";

interface Props {
  unreadMsgCount: number;
  isOpenChating: boolean;
  showUnreadTooltip: boolean;
  chatingFocusing: boolean;
  firstIndex: number;
  SBChatingMsg: ChatingSpeachBubbleProps[];
  currentReadChatItemId: number;

  updateChatingFocusing: React.Dispatch<React.SetStateAction<boolean>>;
  closeChating: () => void;
  nextPageChat: () => void;
  openChating: () => void;
  sendChating: (text: string) => void;
  clearUnreadMsgCount: () => void;
  transfromPixel: (value: number) => number;
}

const ChatingFloat: React.FC<Props> = (props) => {
  const {
    unreadMsgCount,
    showUnreadTooltip,
    SBChatingMsg,
    firstIndex,
    currentReadChatItemId,
    isOpenChating,
    chatingFocusing,
    updateChatingFocusing,
    closeChating,
    openChating,
    nextPageChat,
    sendChating,
    clearUnreadMsgCount,
    transfromPixel,
  } = props;

  const [animatedEnd, updateAnimatedEnd] = useState<boolean>(false);

  const floatingBtnStyles = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isOpenChating ? 0 : 1,
      scale: isOpenChating ? 0 : 1,
    },
    onResolve(result, ctrl, item) {
      updateAnimatedEnd(isOpenChating);
    },
  });

  const chatingStyles = useSpring({
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: isOpenChating ? 1 : 0,
      scale: isOpenChating ? 1 : 0,
    },
  });

  return (
    <Container
      style={{
        right: isOpenChating ? transfromPixel(16) : transfromPixel(30),
        bottom: isOpenChating ? transfromPixel(16) : transfromPixel(30),
      }}
    >
      <animated.div style={floatingBtnStyles}>
        <FloatingChatBtnContainer
          style={{
            width: transfromPixel(60),
            height: transfromPixel(60),
            display: isOpenChating ? "none" : "flex",
          }}
          onClick={openChating}
        >
          <ChatIcon />
          {unreadMsgCount > 0 && (
            <span
              className="unread__count caption-12-md"
              style={{
                height: transfromPixel(20),
                paddingLeft: transfromPixel(8),
                paddingRight: transfromPixel(8),
                top: transfromPixel(4),
                right: transfromPixel(4),
              }}
            >
              {`${unreadMsgCount > 99 ? 99 : unreadMsgCount}${
                unreadMsgCount > 99 ? "+" : ""
              }`}
            </span>
          )}
          {showUnreadTooltip && (
            <UnreadTooltipBouncer
              text="알림을 확인해 주세요!"
              transfromPixel={transfromPixel}
            />
          )}
        </FloatingChatBtnContainer>
      </animated.div>

      <animated.div style={chatingStyles}>
        <ChatingContainer
          style={{
            width: "90vw",
            height: "90vh",
            display: isOpenChating ? "block" : "none",
          }}
        >
          {animatedEnd ? (
            <ChatingDetail
              firstIndex={firstIndex}
              isOpenChating={isOpenChating}
              SBChatingMsg={SBChatingMsg}
              currentReadChatItemId={currentReadChatItemId}
              nextPageChat={nextPageChat}
              cancel={closeChating}
              chatingFocusing={chatingFocusing}
              updateChatingFocusing={updateChatingFocusing}
              sendChating={sendChating}
              transfromPixel={transfromPixel}
              clearUnreadMsgCount={clearUnreadMsgCount}
            />
          ) : (
            <div className="loading__view">
              <Loading className="rotating" />
            </div>
          )}
        </ChatingContainer>
      </animated.div>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  width: auto;
  height: auto;
`;
const FloatingChatBtnContainer = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  filter: drop-shadow(2px 2px 10px rgba(100, 103, 109, 0.3));
  transition: all 0.2s ease;

  .unread__count {
    position: absolute;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #f6444f;
    border-radius: 50px;
  }

  :hover {
    background-color: #f3f4f4;
  }
`;

const ChatingContainer = styled.div`
  background: #ffffff;
  filter: drop-shadow(2px 2px 10px rgba(100, 103, 109, 0.3));
  border-radius: 8px;

  .loading__view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Safari and Chrome */
    @-webkit-keyframes rotating {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    .rotating {
      -webkit-animation: rotating 1s linear infinite;
      -moz-animation: rotating 1s linear infinite;
      -ms-animation: rotating 1s linear infinite;
      -o-animation: rotating 1s linear infinite;
      animation: rotating 1s linear infinite;
    }
  }
`;

export default ChatingFloat;
