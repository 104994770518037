import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import axios from "axios";
import * as api from "../../../../api/api";
import { IndustryTech } from "../../../../type/data";
import BubbleDefault from "../../../../components/bubble_label/BubbleDefault";
import { SectorLabel } from "./SectorLabel";

interface Props {
  label?: string;
  labelWidth?: number;
  className?: string;
  isPropData?: boolean;
  sectorData?: IndustryTech[];
  selectedIndustrialTech?: IndustryTech[];
  onChange?: (selected: IndustryTech[]) => void;
}

interface SectorItem {
  data: IndustryTech;
  isChecked: boolean;
}

export const IndustrialTechSector: React.FC<Props> = (props) => {
  const [sector, setSector] = useState<SectorItem[]>([]);
  const [isAllChecked, updateAllChecked] = useState<boolean>(false);

  const onSectorClick = (item: SectorItem) => {
    item.isChecked = !item.isChecked;
    const selectedSector: IndustryTech[] = sector
      .filter((item) => item.isChecked)
      .map((item) => {
        return item.data;
      });

    updateAllChecked(sector.filter((item) => !item.isChecked).length === 0);
    setSector([...sector]);
    props.onChange && props.onChange([...selectedSector]);
  };

  const onSectorAllCheck = () => {
    sector.map((item) => {
      item.isChecked = !isAllChecked;
    });
    const selectedSector: IndustryTech[] = sector
      .filter((item) => item.isChecked)
      .map((item) => {
        return item.data;
      });
    setSector([...sector]);
    updateAllChecked((prevState) => !prevState);
    props.onChange && props.onChange([...selectedSector]);
  };

  const init = async () => {
    try {
      const industrys: SectorItem[] = [];
      const _selectedIndustrialTechId: (number | string)[] =
        props.selectedIndustrialTech
          ? props.selectedIndustrialTech.map((data) => data.acIndustrialTechId)
          : [];

      const result = (await axios.get(api.getAcIndustrialTech())).data
        .acIndustrialTech;
      result
        .filter((item: any) => item.isActive)
        .map((item: any) => {
          industrys.push({
            data: {
              acIndustrialTechId: item.acIndustrialTechId,
              name: item.name,
            },
            isChecked:
              _selectedIndustrialTechId.includes(item.acIndustrialTechId) ||
              false,
          });
        });
      setSector([...industrys]);
    } catch (error) {}
  };

  useEffect(() => {
    if (sector.length === 0) return;

    const _selectedIndustrialTechId: (number | string)[] =
      props.selectedIndustrialTech
        ? props.selectedIndustrialTech.map((data) => data.acIndustrialTechId)
        : [];
    sector.map((item) => {
      item.isChecked =
        _selectedIndustrialTechId.includes(item.data.acIndustrialTechId) ||
        false;
    });
    const _isAllChecked = sector.filter((item) => !item.isChecked);

    updateAllChecked(_isAllChecked.length === 0);
    setSector([...sector]);
  }, [props.selectedIndustrialTech]);

  useEffect(() => {
    if (props.isPropData) {
      const industrys: SectorItem[] = [];
      const _selectedIndustrialTechId: (number | string)[] =
        props.selectedIndustrialTech
          ? props.selectedIndustrialTech.map((data) => data.acIndustrialTechId)
          : [];
      props.sectorData &&
        props.sectorData
          .filter((item: any) => item.isActive)
          .map((item: any) => {
            industrys.push({
              data: {
                acIndustrialTechId: item.acIndustrialTechId,
                name: item.name,
              },
              isChecked:
                _selectedIndustrialTechId.includes(item.acIndustrialTechId) ||
                false,
            });
          });
      setSector([...industrys]);
    } else {
      init();
    }
  }, []);

  return (
    <Container className={props.className || ""}>
      {props.label && (
        <TitleContainer>
          <div
            className="heading-14-sb"
            style={{ width: props.labelWidth || 128, color: "var(--text-04)" }}
          >
            {props.label}
          </div>
          <SectorLabel isChecked={isAllChecked} onClick={onSectorAllCheck}>
            All
          </SectorLabel>
        </TitleContainer>
      )}
      <SectorContainer>
        {sector.map((item, index) => {
          return (
            <SectorLabel
              key={index}
              isChecked={item.isChecked}
              onClick={() => {
                onSectorClick(item);
              }}
            >
              {" "}
              {item.data.name}
            </SectorLabel>
          );
        })}
      </SectorContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;

  user-select: none;
`;

const TitleContainer = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `
);

const SectorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  gap: 12px;
`;
