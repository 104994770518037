import axios from "axios";
import * as api from "../../../api/api";
import {
  AuthrityDataProps,
  BluecardInfoInBluelink,
  BluecardLinkProps,
} from "../type";
import { getYMD } from "../../../common/commonUtil";

export const createBluecardLink = async (
  param: any
): Promise<{ success: boolean; pfLinkId: string } | undefined> => {
  const result = await axios.post(api.createBluecardLink(), param);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((resolve) => resolve(undefined));
};

export const registeAuthritoryData = async (
  param: AuthrityDataProps,
  pfLinkId: string
): Promise<boolean | undefined> => {
  const result = await axios.post(api.registeVisitorInfo(pfLinkId), param);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((resolve) => resolve(false));
};

export const getBluecardInfoBypfLinkId = async (
  pfLinkId: string
): Promise<BluecardLinkProps | undefined> => {
  const getBluecardValue = (data: any) => {
    if (Array.isArray(data)) {
      if (data.length > 0) {
        return data[0].value;
      }
    }
    return "";
  };

  const result = await axios.get(api.getBluecardInfoBypfLinkId(pfLinkId));
  if (result.status === 200) {
    console.log(result);
    const linkInfo = result.data.linkInfo.info.map((item: any) => {
      const newData: BluecardInfoInBluelink = {
        ...item,
        bluecardInfo: {
          ...item.bluecardInfo,
          establishmentDay: getYMD(item.bluecardInfo.establishmentDay),
          investmentHighlight: getBluecardValue(
            item.bluecardInfo.investmentHighlight
          ),
          problem: getBluecardValue(item.bluecardInfo.problem),
          technology: getBluecardValue(item.bluecardInfo.technology),
          productStatus: getBluecardValue(item.bluecardInfo.productStatus),
          solutionBusinessModel: getBluecardValue(
            item.bluecardInfo.solutionBusinessModel
          ),
        },
      };
      return newData;
    });
    return new Promise((resolve) =>
      resolve({ pfLinkId: pfLinkId, info: linkInfo })
    );
  }
  return new Promise((resolve) => resolve(undefined));
};
