import { CSSProperties, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as IconExcel } from "../../../../../assets/image/icon-xlsx.svg";
import { ReactComponent as IconPdf } from "../../../../../assets/image/icon-pdf.svg";
import { ReactComponent as IconFile } from "../../../../../assets/image/icon-file.svg";
import { ReactComponent as IconJpg } from "../../../../../assets/image/icon-jpg.svg";
import { ReactComponent as IconPng } from "../../../../../assets/image/icon-png.svg";
import { ReactComponent as IconWeb } from "../../../../../assets/image/icon-web.svg";
import StockChangeUploadBtn from "./stock-change-history/StockChangeUploadBtn";

import { DefaultButton } from "lattice_core";
import { getMyInfo } from "../../../../../common/commonUtil";
import { DictionaryType } from "../../../../../type/data";
import {
  ReportingFile,
  ReportingFileBody,
} from "../../interface/ReportingDetail.interface";

interface Props {
  portfolioId: number;
  isFileUploadable?: boolean;
  data: ReportingFile;
  isOpen: boolean;
  index: number;
  dictionary?: DictionaryType;
  isAuthority?: boolean;
  onClickCard?: () => void;
  transfromPixel: (value: number) => number;
  isHoveredOrDragged: boolean;
  setHoveredOrDraggedId: (id: number | undefined) => void;
  onFileUpload?: (param: ReportingFileBody) => void;
}

export const getCustomIcon = (
  extension: string,
  style: CSSProperties,
  type?: string
): JSX.Element => {
  switch (extension) {
    case "excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return <IconExcel style={style} />;
    case "pdf":
    case "application/pdf":
      return <IconPdf style={style} />;
    case "web":
      return <IconWeb style={style} />;
    default:
  }

  if (extension === "file") {
    switch (type) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <IconExcel style={style} />;
      case "application/pdf":
        return <IconPdf style={style} />;
      case "image/jpg":
      case "image/jpeg":
        return <IconJpg style={style} />;
      case "image/png":
        return <IconPng style={style} />;
      default:
        return <IconFile style={style} />;
    }
  }
  return <IconFile style={style} />;
};

const DefaultCard = (props: Props) => {
  const myInfo = getMyInfo();
  return (
    <StyledCard
      isOpen={props.isOpen}
      onMouseEnter={() => {
        props.setHoveredOrDraggedId && props.setHoveredOrDraggedId(props.index);
      }}
      onMouseLeave={() => {
        props.setHoveredOrDraggedId && props.setHoveredOrDraggedId(undefined);
      }}
      onClick={props.onClickCard}
      transfromPixel={props.transfromPixel}
    >
      <NotHovered transfromPixel={props.transfromPixel}>
        <div className="required-container">
          <div
            className={`chip caption-11-rg ${
              props.data?.required ? "required" : "option"
            }`}
          >
            {props.data?.required ? "필수" : "변경 시 제출"}
          </div>
          {props.data?.tag && (
            <div className="required-file chip caption-11-rg">
              {props.data?.tag}
            </div>
          )}
        </div>
        <div className="file-name heading-20-sb">
          <span className={props.isOpen ? "" : "closed"}>
            {props.data?.name}
          </span>
        </div>
        {props.data.extension !== "web" &&
          props.isFileUploadable &&
          props.isAuthority &&
          props.isOpen && (
            <div
              className="file__upload__btn"
              onClick={(e) => e.stopPropagation()}
            >
              <StockChangeUploadBtn
                portfolioId={props.portfolioId}
                screenWidthRate={1}
                dictionary={props.dictionary}
                isJustfileUpload={props.data.extension !== "excel"}
                onSuccessStockChangeList={(file, isValidation = true) => {
                  console.log("성공");
                  if (!props.isFileUploadable) return;
                  console.log("파일올리수 있어?성공");
                  props.onFileUpload &&
                    props.onFileUpload({
                      portfolioId: props.portfolioId,
                      documentFile: file,
                      acPfDocumentId: props.data?.acPfDocumentId,
                      isValidation,
                      index: props.index,
                    });
                }}
                onDragging={(isDragging) => {
                  if (!props.isFileUploadable) return;
                }}
                transfromPixel={props.transfromPixel}
              >
                <DefaultButton
                  className="caption-12-md"
                  buttonType="colored-outline"
                  sizeType="xxsmall"
                  buttonColor={"#1F2022"}
                  borderColor={"#D5D6D8"}
                  disabled={!props.isOpen}
                  text="파일 업로드"
                  transfromPixel={props.transfromPixel}
                />
              </StockChangeUploadBtn>
            </div>
          )}
        <div className="file-number reporting-card-font">
          {`${props.index + 1}`.padStart(2, "0")}
        </div>
        {getCustomIcon(props.data?.extension || "", {
          width: props.transfromPixel(60),
          height: props.transfromPixel(60),
        })}
      </NotHovered>
    </StyledCard>
  );
};

const StyledCard = styled.div<{
  transfromPixel: (value: number) => number;
  isOpen: boolean;
}>`
  position: relative;
  width: ${(props) => props.transfromPixel(300)}px;
  height: ${(props) => props.transfromPixel(274)}px;
  cursor: default;

  .file__upload__btn {
    margin-top: 16px;
  }

  :hover {
    ${(props) => (props.isOpen ? "outline: 1px solid #a2c7ff" : "")};
  }
`;

const NotHovered = styled.div<{ transfromPixel: (value: number) => number }>`
  height: 100%;
  background: #ffffff;
  padding: ${(props) => props.transfromPixel(24)}px
    ${(props) => props.transfromPixel(32)}px;
  .required-container {
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.transfromPixel(4)}px;
    .chip {
      padding: ${(props) => props.transfromPixel(2)}px
        ${(props) => props.transfromPixel(6)}px;
      border-radius: ${(props) => props.transfromPixel(2)}px;
    }

    .required {
      background: #060f40;
      color: #ffffff;
    }
    .option {
      background: #ffffff;
      color: #5b5d61;
      border: ${(props) => props.transfromPixel(1)}px solid #e3e4e5;
    }
    .required-file {
      background: #e3e4e5;
      color: #5b5d61;
    }
  }

  .file-name {
    margin-top: ${(props) => props.transfromPixel(8)}px;
    .closed {
      color: #bec0c2;
    }
  }

  .template-download {
    margin-top: ${(props) => props.transfromPixel(16)}px;
    padding: ${(props) => props.transfromPixel(4)}px
      ${(props) => props.transfromPixel(16)}px;
  }

  .file-number {
    position: absolute;
    font-family: SUIT;
    font-style: normal;
    top: ${(props) => props.transfromPixel(220)}px;
    left: ${(props) => props.transfromPixel(32)}px;
    font-size: ${(props) => props.transfromPixel(34)}px;
    line-height: ${(props) => props.transfromPixel(30)}px;
    color: #e3e4e5;
  }

  svg {
    position: absolute;
    top: ${(props) => props.transfromPixel(190)}px;
    right: ${(props) => props.transfromPixel(32)}px;
  }
`;

export default DefaultCard;
