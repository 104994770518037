import React, { useState } from "react";
import styled from "styled-components";
import SignatureImage from "../../../task/evaluation/desktop/organism/SignatureImage";
import { SignatureInfo } from "../../../../type/data";
import { getNewDate } from "../../../../common/commonUtil";

interface Props {
  signature?: {
    status?: string;
    participant?: string;
    signatureTmp?: SignatureInfo;
    signUrl?: string;
  };
  style?: React.CSSProperties;
  onLoaded?: (signUrl: string) => void;
}

export const ExaminerSignature: React.FC<Props> = (props) => {
  return (
    <Container className="caption-12-rg" style={props.style}>
      <div className="header">담당 심사역 서명</div>
      <div className="signature">
        <FlexRow>
          <span className="gray__text">종합 의견</span>
          <span>{props.signature?.status}</span>
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <FlexRow>
            <span className="gray__text">담당 심사역</span>
            <span>{props.signature?.participant}</span>
          </FlexRow>
          <FlexRow style={{ alignItems: "center" }}>
            <span className="gray__text">서명</span>
            <div
              style={{
                position: "relative",
                width: 42,
                aspectRatio: "448/240",
              }}
            >
              {props.signature && props.signature.signUrl && (
                <Signature>
                  <img
                    src={`${props.signature.signUrl}${
                      props.signature.signatureTmp?.type === 0
                        ? `?${getNewDate().getTime()}`
                        : ""
                    }`}
                    className="sign-img"
                    crossOrigin="anonymous"
                  />
                </Signature>
              )}
              {props.signature &&
                props.signature.signUrl === undefined &&
                props.signature.signatureTmp &&
                props.signature.signatureTmp.type === 1 && (
                  <Signature>
                    <SignatureImage
                      signatureData={props.signature.signatureTmp.path}
                      onLoaded={(index) => {}}
                      onLoadedSignUrl={props.onLoaded}
                      index={1}
                    />
                  </Signature>
                )}
            </div>
          </FlexRow>
        </FlexRow>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;

  border: 2px solid #000;

  .header {
    padding: 4px 16px;
    color: #000;
    border-bottom: 2px solid #000;
    background-color: var(--gray-500);
  }
  .signature {
    display: flex;
    flex-direction: row;
    padding: 20px 47px 20px 16px;
    align-items: center;
    justify-content: space-between;
  }

  .gray__text {
    color: var(--gary-700);
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const Signature = styled.div`
  position: absolute;
  width: 64px;
  aspect-ratio: 448/240;
  display: flex;
  top: -8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;

  .sign-img {
    width: 100%;
    height: 100%;
  }
`;
