import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import BlueCardNew from "./organisms/BlueCardNew";
import { DefaultButton } from "lattice_core";

import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import useBluecardManage from "../hook/useBluecardManage";

const DeskBluecardManagePage: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const { portfolioId, init, isEditable = false } = props;
  const [
    portfolioInfoData,
    isUpdating,
    originBluecard,
    suggestBluecard,
    isSuggested,
    updatePortfolioInfoData,
    getPortfolioInfoData,
    onSavePortfolioInfo,
    setUpdating,
  ] = useBluecardManage();

  if (!portfolioInfoData) return null;

  return (
    <Container>
      <BluecardContainer style={{ paddingBottom: isEditable ? 114 : 24 }}>
        <BlueCardNew
          isEditable={isUpdating}
          isUpdateAuth={isEditable}
          portfolioBluecard={portfolioInfoData.portfolioBlueCardInfo}
          originBluecard={originBluecard.current}
          suggestBluecard={suggestBluecard.current}
          isSuggested={isSuggested.current}
          bluecardFilter={{
            companyValue: true,
            investmentAmount: true,

            userName: true,
            userEmail: true,
            userPhone: true,

            investmentHighlight: true,
            problem: true,
            solutionBusinessModel: true,
            technology: true,
            productStatus: true,
          }}
          saveBluecard={(data) => {
            console.log(data);

            const ih = data.investmentHighlight ? data.investmentHighlight : "";

            const p = data.problem ? data.problem : "";
            const ps = data.productStatus ? data.productStatus : "";
            const sbm = data.solutionBusinessModel
              ? data.solutionBusinessModel
              : "";
            const t = data.technology ? data.technology : "";
            const s = data.itemSurmmary ? data.itemSurmmary : "";

            updatePortfolioInfoData({
              ...getPortfolioInfoData(
                portfolioInfoData.portfolioInfo,
                {
                  ...portfolioInfoData.portfolioBlueCardInfo,
                  investmentHighlight: ih,
                  problem: p,
                  productStatus: ps,
                  solutionBusinessModel: sbm,
                  technology: t,
                  itemSurmmary: s,
                },
                portfolioInfoData.portfolioUser,
                portfolioInfoData.portfolioMainParticipant,
                portfolioInfoData.portfolioSubParticipant
              ),
            });
          }}
        />
      </BluecardContainer>
      {isEditable && (
        <FooterContainer>
          {isUpdating && (
            <DefaultButton
              className="heading-16-md"
              buttonType="colored-outline"
              sizeType="small"
              buttonColor="var(--text-01)"
              borderColor="var(--gray-400)"
              onClick={() => {
                window.location.reload();
              }}
              text="취소"
            />
          )}
          <DefaultButton
            className="heading-16-md"
            buttonType={isUpdating ? "filled" : "colored-outline"}
            sizeType="small"
            buttonColor={isUpdating ? "var(--primary-blue)" : "var(--text-01)"}
            hoverColor="var(--blue-600)"
            borderColor="var(--gray-400)"
            text={isUpdating ? "저장" : "수정하기"}
            onClick={() => {
              if (isUpdating) {
                //저장
                onSavePortfolioInfo();
              } else {
                setUpdating(true);
              }
            }}
          />
        </FooterContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const BluecardContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1240px;
  max-width: 1480px;
  overflow: auto;
  margin: 0 auto;
  padding: 24px 24px 0px 24px;
`;

const FooterContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  border-top: 2px solid var(--divider-200);
  background-color: var(--white);

  padding: 0 24px;
  gap: 12px;
`;
export default DeskBluecardManagePage;
