import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Sector } from "../Sector";

import { SectorDescription } from "../SectorDescription";
import { InvestmentAssessment } from "../../../../../type/data";

import { ContractStatusTable } from "./organism/ContractStatusTable";
import { IprsProcessTable } from "./organism/IprsProcessTable";
import { SimilarBusinessTable } from "./organism/SimilarBusinessTable";

interface Props {
  isReadOnly: boolean;
  data?: InvestmentAssessment;
  onChangeData?: (data: InvestmentAssessment) => void;
}

export const Section3: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;

  if (!data) return null;
  useEffect(() => {
    console.log(data);
  });
  return (
    <Container>
      <Sector title="계약 현황" id="Section5_1">
        <FlexColumn style={{ gap: 8 }}>
          <ContractStatusTable
            isReadOnly={props.isReadOnly}
            disabled={data.investmentAssessmentContractStatus?.isChecked}
            dataList={data.investmentAssessmentContractStatus?.data}
          />
        </FlexColumn>
      </Sector>

      <Sector title="출원 및 등록 현황" id="Section5_2">
        <FlexColumn style={{ gap: 8 }}>
          <IprsProcessTable
            isReadOnly={props.isReadOnly}
            disabled={data.investmentAssessmentIprsProcess?.isChecked}
            dataList={data.investmentAssessmentIprsProcess?.data}
          />
        </FlexColumn>
      </Sector>

      <Sector title="국내외 관련기업(경쟁사, 유사사업) 현황" id="Section5_3">
        <FlexColumn style={{ gap: 8 }}>
          <SimilarBusinessTable
            isReadOnly={props.isReadOnly}
            disabled={data.investmentAssessmentSimilarBusiness?.isChecked}
            dataList={data.investmentAssessmentSimilarBusiness?.data}
          />
        </FlexColumn>
      </Sector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--gray-700);
        stroke: var(--gray-700);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--gray-700);
        }
      }
    }
  }
`;
