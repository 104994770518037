import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartDataset,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getPerformanceSurvival } from "../../../../../api/repository/accelerator/DashboardRepository";
import { SurvivalRateProps } from "../../interface/PerformanceMetrics.interface";
import { AddComma } from "../../../../../common/commonUtil";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  structureId?: number;
  selectedFundAccountId?: number[];
  selectedUserId?: number;
  selectedIndustrialTechId?: number[];
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}
export const SurvivalRate: React.FC<Props> = (props) => {
  const {
    structureId,
    selectedFundAccountId,
    selectedUserId,
    selectedIndustrialTechId,
    excavation,
    investor,
    charger,
    assistant,
  } = props;

  const isNetworking = useRef<boolean>(false);
  const [survivalData, setSurvivalData] = useState<SurvivalRateProps>({
    survival: 0,
    closure: 0,
  });
  const [doughnutData, setDoughnutData] = useState<ChartDataset<"doughnut">[]>(
    []
  );

  const centerTextPlugin = {
    id: "centerTextPlugin",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      ctx.save();
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = "10px SUIT";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#7E8086";
      ctx.fillText("단위 : 건수", centerX, centerY);
      ctx.restore();
    },
  };
  const getData = async () => {
    isNetworking.current = true;
    const survivalResult = await getPerformanceSurvival({
      acStructureId: structureId,
      acFundAccountId: selectedFundAccountId,
      acIndustrialTechId: selectedIndustrialTechId,
      acUserId: selectedUserId,
      excavation: excavation,
      investor: investor,
      charger: charger,
      assistant: assistant,
    });

    if (survivalResult) {
      setSurvivalData(survivalResult);
      setDoughnutData([
        {
          label: "",
          data: [survivalResult.survival, survivalResult.closure],
          backgroundColor: ["rgba(69, 132, 255, 1)", "rgba(213, 214, 216, 1)"],
          borderColor: ["rgba(69, 132, 255, 1)", "rgba(213, 214, 216, 1)"],
          borderWidth: 1,
        },
      ]);
    }
    isNetworking.current = false;
  };

  useEffect(() => {
    // if (isNetworking.current) return;
    getData();
  }, [
    structureId,
    selectedFundAccountId,
    selectedUserId,
    selectedIndustrialTechId,
    excavation,
    investor,
    charger,
    assistant,
  ]);

  return (
    <Container>
      <TitleContainer className="heading-18-sb">생존율</TitleContainer>
      <BodyContainer>
        <FlexColumn style={{ width: "100%" }}>
          <FlexRow style={{ gap: 40 }}>
            <ProgressItem textColor={"rgba(69, 132, 255, 1)"}>
              <div className="heading-14-sb sub__title">생존</div>
              <div className="heading-20-b">
                {survivalData.survival > 0 && survivalData.survival > 0
                  ? (
                      (survivalData.survival /
                        (survivalData.survival + survivalData.closure)) *
                      100
                    ).toFixed(0)
                  : 0}
                %
              </div>
            </ProgressItem>
            <ProgressItem textColor={"rgba(213, 214, 216, 1)"}>
              <div className="heading-14-sb sub__title">폐업</div>
              <div className="heading-20-b">
                {survivalData.survival > 0 && survivalData.survival > 0
                  ? (
                      (survivalData.closure /
                        (survivalData.survival + survivalData.closure)) *
                      100
                    ).toFixed(0)
                  : 0}
                %
              </div>
            </ProgressItem>
          </FlexRow>
        </FlexColumn>
        <div style={{ width: 130, height: 130 }}>
          <Doughnut
            style={{ display: "inline-block" }}
            data={{
              labels: ["생존", "폐업"],
              datasets: doughnutData,
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => {
                      return `${tooltipItem.dataset.label} : ${AddComma(
                        tooltipItem.formattedValue
                      )}건`;
                    },
                  },
                },
              },
              devicePixelRatio: 2,
              cutout: 40,
            }}
            plugins={[centerTextPlugin]}
          />
        </div>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--gray-300);
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text-05);
  margin-top: 4px;
`;

const ProgressItem = styled.div<{ textColor: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  color: ${(props) => props.textColor};

  .sub__title {
    color: var(--text-04);
  }

  ::before {
    position: absolute;
    content: " ";
    width: 4px;
    min-width: 4px;
    max-width: 4px;
    height: calc(100% - 10px);
    left: 0;
    top: 4px;
    background-color: ${(props) => props.textColor};
  }
`;
