import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IACompanyHistory } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";
import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { getYMD } from "../../../../../../common/commonUtil";
import { CustomTextArea } from "../../table/custom-cell/CustomTextArea";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IACompanyHistory[];
  onDataChange?: (data: IACompanyHistory[]) => void;
}

export const CompanyHistoryTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<IACompanyHistory[] | undefined>(
    undefined
  );
  const headers: TableGridHeaderProps[] = [
    {
      header: "연월",
      key: "date",
      width: 182,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            isMonth
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultDate={data}
            onChangeDate={(date) => {
              if (!dataList) return;
              rowData.date = getYMD(date);
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "내용",
      key: "contents",
      width: 780,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="내용을 입력해주세요."
            onBlur={(value) => {
              if (!dataList) return;
              rowData.contents = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "비고",
      key: "note",
      width: 164,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            onBlur={(value) => {
              if (!dataList) return;
              rowData.note = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newData: IACompanyHistory = {};
    const newDataList: IACompanyHistory[] = [...(dataList || []), newData];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    updateDataList(props.dataList);
  }, [props.dataList]);

  useEffect(() => {
    if (dataList && props.onDataChange) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
