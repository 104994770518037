import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  LinearScale,
  ArcElement,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartOptions,
  ChartDataset,
  Plugin,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  ProgramDetailType,
  ProgramStatus,
  TipsInvestmentProps,
} from "../../interface/PerformanceMetrics.interface";
import { getNewDate } from "../../../../../common/commonUtil";
import { NotOpen } from "../component/NotOpen";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement
);

interface Props {
  tipsInvestment?: TipsInvestmentProps[];
}
export const TipsInvestment: React.FC<Props> = (props) => {
  const { tipsInvestment = [] } = props;

  const generalProgramSuccessColor = "rgba(69, 132, 255, 1)";
  const deepTechProgramSuccessColor = "rgba(17, 44, 191, 1)";
  const generalProgramFailColor = "rgba(59, 214, 153, 1)";
  const deepTechProgramFailColor = "rgba(34, 131, 93, 1)";

  const [barData, setBarData] = useState<ChartDataset<"bar">[]>([]);
  const [doughnutData, setDoughnutData] = useState<ChartDataset<"doughnut">[]>(
    []
  );
  const centerTextPlugin = {
    id: "centerTextPlugin",
    afterDraw: (chart: any) => {
      const ctx = chart.ctx;
      ctx.save();
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = "14px SUIT";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#7E8086";
      ctx.fillText("단위 : 건", centerX, centerY);

      ctx.restore();
    },
  };

  const initDoughnutData = () => {
    const generalProgramSuccess = tipsInvestment.filter(
      (item) =>
        item.pfProgram.detailType === ProgramDetailType.GENERAL &&
        item.pfProgram.status === ProgramStatus.SUCCESS
    ).length;
    const deepTechProgramSuccess = tipsInvestment.filter(
      (item) =>
        item.pfProgram.detailType === ProgramDetailType.DEEP_TECH &&
        item.pfProgram.status === ProgramStatus.SUCCESS
    ).length;
    const generalProgramFail = tipsInvestment.filter(
      (item) =>
        item.pfProgram.detailType === ProgramDetailType.GENERAL &&
        item.pfProgram.status === ProgramStatus.FAIL
    ).length;
    const deepTechProgramFail = tipsInvestment.filter(
      (item) =>
        item.pfProgram.detailType === ProgramDetailType.DEEP_TECH &&
        item.pfProgram.status === ProgramStatus.FAIL
    ).length;

    console.log(tipsInvestment);
    setDoughnutData([
      {
        label: "",
        data: [
          generalProgramSuccess,
          deepTechProgramSuccess,
          generalProgramFail,
          deepTechProgramFail,
        ],
        backgroundColor: [
          generalProgramSuccessColor,
          deepTechProgramSuccessColor,
          generalProgramFailColor,
          deepTechProgramFailColor,
        ],
        borderColor: [
          generalProgramSuccessColor,
          deepTechProgramSuccessColor,
          generalProgramFailColor,
          deepTechProgramFailColor,
        ],
        borderWidth: 1,
      },
    ]);
  };

  const initBarData = () => {
    const getQuarterData = (amount: number, date: string) => {
      const quarterData = [0, 0, 0, 0];
      quarterData[Math.floor(getNewDate(date).getMonth() / 3)] = amount;
      return quarterData;
    };

    const getProgramColor = (data: TipsInvestmentProps) => {
      if (
        data.pfProgram.detailType === ProgramDetailType.GENERAL &&
        data.pfProgram.status === ProgramStatus.SUCCESS
      ) {
        return generalProgramSuccessColor;
      }
      if (
        data.pfProgram.detailType === ProgramDetailType.DEEP_TECH &&
        data.pfProgram.status === ProgramStatus.SUCCESS
      ) {
        return deepTechProgramSuccessColor;
      }
      if (
        data.pfProgram.detailType === ProgramDetailType.GENERAL &&
        data.pfProgram.status === ProgramStatus.FAIL
      ) {
        return generalProgramFailColor;
      }
      if (
        data.pfProgram.detailType === ProgramDetailType.DEEP_TECH &&
        data.pfProgram.status === ProgramStatus.FAIL
      ) {
        return deepTechProgramFailColor;
      }
    };

    const dataset = tipsInvestment.map((tips) => {
      return {
        label: tips.companyName,
        data: getQuarterData(1, tips.pfProgram.applyDate),
        backgroundColor: getProgramColor(tips),
      };
    });
    setBarData(dataset);
  };

  useEffect(() => {
    initDoughnutData();
    initBarData();
  }, [tipsInvestment]);

  return (
    <Container>
      <TitleContainer className="heading-18-sb">TIPS</TitleContainer>
      <BodyContainer>
        <FlexRow style={{ height: "100%", alignItems: "center" }}>
          <FlexColumn style={{ flex: 1, height: 90 }}>
            <Doughnut
              style={{ display: "inline-block" }}
              data={{
                labels: ["일반", "딥테크", "일반탈락", "딥테크탈락"],
                datasets: doughnutData,
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                    text: "Chart.js Bar Chart",
                  },
                },
                devicePixelRatio: 2,

                cutout: 35,
              }}
              width={90}
              height={90}
              plugins={[centerTextPlugin]}
            />
          </FlexColumn>
          <div style={{ flex: 3, height: "100%" }}>
            <FlexRow style={{ justifyContent: "flex-end" }}>
              <LegendItem
                className="caption-10-md"
                boxColor={generalProgramSuccessColor}
              >
                일반 선정
              </LegendItem>
              <LegendItem
                className="caption-10-md"
                boxColor={generalProgramFailColor}
              >
                일반 탈락
              </LegendItem>
              <LegendItem
                className="caption-10-md"
                boxColor={deepTechProgramSuccessColor}
              >
                딥테크 선정
              </LegendItem>
              <LegendItem
                className="caption-10-md"
                boxColor={deepTechProgramFailColor}
              >
                딥테크 탈락
              </LegendItem>
            </FlexRow>
            <div style={{ height: "100%" }}>
              <Bar
                style={{ display: "inline-block" }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  devicePixelRatio: 2,
                  datasets: {
                    bar: {
                      barPercentage: 0.6,
                      categoryPercentage: 0.5,
                    },
                  },

                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      suggestedMax: 10,
                      stacked: true,
                      grid: {
                        display: false,
                      },

                      ticks: {
                        stepSize: 1,
                        callback: (value, index, ticks) => {
                          if (barData.length === 0) return undefined;
                          const dataValue = parseInt(`${value}`);
                          if (index === 0) {
                            return `${dataValue} (억)`;
                          }
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: ["1분기", "2분기", "3분기", "4분기"],
                  datasets: barData,
                }}
                height={125}
              />
            </div>
          </div>
        </FlexRow>
        <NotOpen />
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 279px;
  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const LegendItem = styled.div<{ boxColor: string }>`
  min-width: 70px;
  position: relative;
  padding-left: 13px;
  color: var(--gray-700);

  ::before {
    position: absolute;
    content: " ";
    width: 9px;
    height: 9px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${(props) => props.boxColor};
  }
`;
