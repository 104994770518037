import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CountingBtn from "../components/CountingBtn";
import { OrderingBtn } from "../components/OrderingBtn";
import UserCircle, {
  CircleUserProfileProps,
} from "../../../components-2.0v/user-item/UserCircle";

interface Props {
  userList: CircleUserProfileProps[];
  maxShowUserCount?: number;
  circleWidth?: number;
}
export const CircleUserList: React.FC<Props> = (props) => {
  const { userList, maxShowUserCount = 2, circleWidth = 32 } = props;
  return (
    <Container>
      {userList.slice(0, maxShowUserCount).map((item, index) => {
        return (
          <div key={index} className={index > 0 ? "not__first" : ""}>
            <UserCircle userInfo={item} width={circleWidth} />
          </div>
        );
      })}

      {userList.length > maxShowUserCount && (
        <div className="not__first" style={{ zIndex: 2 }}>
          <OverUserCircle
            className="caption-10-md"
            style={{
              width: circleWidth,
              height: circleWidth,
              minWidth: circleWidth,
              minHeight: circleWidth,
              maxWidth: circleWidth,
              maxHeight: circleWidth,
            }}
          >
            +{userList.length - maxShowUserCount}
          </OverUserCircle>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: auto;
  display: flex;
  flex-direction: row;

  .not__first {
    margin-left: -8px;
  }
`;

const OverUserCircle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: 1px solid var(--divider-100, #f3f4f4);
  background: var(--divider-50, #fafafb);

  color: var(--text-06, #bec0c2);
`;
