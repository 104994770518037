import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRiskManagementList } from "../../../../api/repository/portfolio/PortfolioRepository";
import { RiskManagementListProps } from "../interface/RME.interface";
import { transformObjectToBase64 } from "../../../../common/commonUtil";

const useRiskManagementEvaluation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [year, setYear] = useState<number | undefined>(undefined);
  const [quarterNum, setQuarterNum] = useState<number | undefined>(undefined);
  const [keyword, updateKeyword] = useState<string>("");
  const [riskManagements, setRiskManagements] = useState<
    RiskManagementListProps[] | undefined
  >(undefined);
  const [noDataEvaluation, setNoDataEvaluation] = useState<boolean>(false);
  const noDataEvaluationItem = useRef<RiskManagementListProps | undefined>(
    undefined
  );

  const getRMList = async () => {
    const result = await getRiskManagementList();
    if (result) {
      setYear(result.date.year);
      setQuarterNum(result.date.quarterNum);
      setRiskManagements(result.riskManagement);
    }
  };

  const getFilteredRiskManagement = (statusId: number[]) => {
    if (riskManagements === undefined) return [];

    return riskManagements
      .filter((item) => {
        return (
          item.portfolio.companyName.includes(keyword) ||
          item.portfolio.description.includes(keyword)
        );
      })
      .filter((item) => statusId.includes(item.status));
  };

  const closeNoDataModal = () => setNoDataEvaluation(false);

  const goNextDetail = (item: RiskManagementListProps) => {
    if ([2, 3].includes(item.status)) {
      const code = transformObjectToBase64({
        riskManagementId: item.id,
      });
      navigate(`/quarter-management/risk-management/detail/${code}`);
    }

    if ([4].includes(item.status)) {
      const code = transformObjectToBase64({
        riskManagementId: item.id,
      });
      navigate(`/quarter-management/risk-management/detail/${code}/viewer`);
    }

    if ([1].includes(item.status)) {
      noDataEvaluationItem.current = item;
      setNoDataEvaluation(true);
    }
  };

  const goNextDetailNodata = () => {
    if (!noDataEvaluationItem.current) return;

    const code = transformObjectToBase64({
      riskManagementId: noDataEvaluationItem.current.id,
    });
    navigate(`/quarter-management/risk-management/detail/${code}`);
  };
  useEffect(() => {
    getRMList();
  }, []);

  return [
    year,
    quarterNum,
    riskManagements,
    keyword,
    noDataEvaluation,
    updateKeyword,
    getFilteredRiskManagement,
    goNextDetail,
    goNextDetailNodata,
    closeNoDataModal,
  ] as const;
};

export default useRiskManagementEvaluation;
