import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BubbleCheck from "./BubbleCheck";
import { ReactComponent as ArrowDown } from "../../../../assets/image/btn_dropdown.svg";

interface Props {
  contentsString: string;
  hideArrow?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
}

const CheckedChip: React.FC<Props> = (props) => {
  const { contentsString, hideArrow, isActive, isSelected, onClick } = props;
  return (
    <BubbleCheck isActive={isActive} onClick={onClick}>
      <Container
        className={`caption-12-md ${isActive ? "is__active" : ""} ${
          isSelected ? "is__selected" : ""
        }`}
      >
        {contentsString}
        {!hideArrow && <ArrowDown className="arrow__icon" />}
      </Container>
    </BubbleCheck>
  );
};

const Container = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  &.is__active {
    path {
      stroke: var(--blue-400);
    }
  }

  &.is__selected {
    .arrow__icon {
      transform: rotate(180deg);
    }
  }
`;
export default CheckedChip;
