import React from "react";
import styled from "styled-components";

import DefaultSearch from "../../../../components-2.0v/search/DefaultSearch";
import useRiskManagementEvaluation from "../hook/useRiskManagementTotalEvaluation";

import { DefaultButton } from "lattice_core";
import FilterView from "./organism/FilterView";
import { EvaluationCompletedCard } from "./component/EvaluationCompletedCard";
import { ReactComponent as Down } from "../../../../assets/image/icon_download.svg";
import { ReactComponent as List } from "../../../../assets/image/new_icon_list.svg";
import { ReactComponent as Close } from "../../../../assets/image/new_close_button.svg";
import { ReactComponent as CheckT } from "../../../../assets/image/new_check_box_true.svg";
import { ReactComponent as Lock } from "../../../../assets/image/new_icon_lock.svg";
import MainTab from "../../../reporting/mobile/components/MainTab";
import SubTab from "../../../reporting/mobile/components/SubTab";
import RollingSelector from "../../../../components-2.0v/rolling-selector/RollingSelector";

const MobileRiskManagementTotalEvaluation = () => {
  const [
    rootRef,
    openType,
    myInfo,
    keyword,
    paused,
    listData,
    riskmanagementRange,
    yearSelectorList,
    selectedYear,
    selectedQuarter,
    basicDictionary,
    teamData,
    filterData,
    checkedData,
    showCheckedDataList,
    notSubmittedData,
    countInfo,
    onChangeYear,
    onChangeQuarter,
    getFilterPortfolio,
    onChangeFilterData,
    onChangeKeyword,
    goNextDetail,
    onCheckedPortfolio,
    onClickShowCheckedDataList,
    onInitPortfolio,
    goToMinutePreview,
    onClickCloseRiskManagement,
    onClickPartiallydOpen,
    excelDownloadSubmitStauts,
    onClickExistNotSubmit,
  ] = useRiskManagementEvaluation();

  return (
    <Container>
      <MainTab>
        <RollingSelector
          data={yearSelectorList}
          defaultPosition={yearSelectorList.findIndex(
            (item) => item.id === selectedYear
          )}
          paused={paused}
          transfromPixel={(n) => n}
          onChangeData={(data) => {
            onChangeYear(data.id);
          }}
        />
        <DefaultSearch
          value={keyword}
          width={200}
          transfromPixel={(n) => n}
          onChangeValue={onChangeKeyword}
          onPressEnter={onChangeKeyword}
        />
      </MainTab>
      <SubTab className="top__sticky">
        <QuarterContainer className="heading-16-md" style={{ fontSize: 16 }}>
          <QuarterItem
            className={`${selectedQuarter === 2 ? "selected" : ""}`}
            onClick={() => {
              onChangeQuarter(2);
            }}
          >
            상반기
          </QuarterItem>
          <QuarterItem
            className={`${selectedQuarter === 4 ? "selected" : ""}`}
            onClick={() => {
              onChangeQuarter(4);
            }}
          >
            하반기
          </QuarterItem>
        </QuarterContainer>
        {myInfo.authorityId === 2 &&
          ["open", "partiallydOpen"].includes(openType) && (
            <DefaultButton
              className="caption-12-md"
              buttonType="colored-outline"
              sizeType="xsmall"
              buttonColor={"#1F2022"}
              borderColor={"#D5D6D8"}
              text={"리스크평가 종료"}
              disabled={countInfo.submitedCount !== countInfo.totalCount}
              disabledOnClick={onClickExistNotSubmit}
              onClick={() => {
                onClickCloseRiskManagement();
              }}
            />
          )}
      </SubTab>
      <ContentsBody ref={rootRef}>
        <FilterView
          allIssue={basicDictionary?.issue || []}
          allRiskStatus={basicDictionary?.status || []}
          allStructure={teamData}
          filterData={filterData}
          onChangeFilterData={onChangeFilterData}
        />
        <ListTopContainer>
          <FlexRow style={{ gap: 16, alignItems: "center" }}>
            <span className="heading-14-md" style={{ color: "var(--text-04)" }}>
              {openType === "open"
                ? `리스크 관리 제출 기업 ${countInfo.submitedCount}/${countInfo.totalCount}`
                : `리스크 관리 열린 기업 ${countInfo.openPortfolioCount}/${countInfo.totalCount}`}
            </span>
          </FlexRow>

          <FlexRow style={{ gap: 6 }}>
            {myInfo.authorityId === 2 &&
              openType === "open" &&
              countInfo.submitedCount !== countInfo.totalCount && (
                <FileDownloadBtn
                  className={`caption-12-md`}
                  onClick={() => {
                    excelDownloadSubmitStauts();
                  }}
                >
                  제출 현황 다운로드
                  <Down />
                </FileDownloadBtn>
              )}
          </FlexRow>
        </ListTopContainer>
        {listData.length === 0 ? (
          <ListEmptyContainer>
            <Lock width={60} height={60} />
            <div
              className="heading-18-md"
              style={{
                color: "#7E8086",
              }}
            >
              제출한 리스크 평가서가 없습니다.
            </div>
          </ListEmptyContainer>
        ) : (
          <ListContainer>
            {getFilterPortfolio().map((item, index) => {
              return (
                <EvaluationCompletedCard
                  key={index}
                  data={item}
                  showCheckable={
                    myInfo.authorityId === 2 &&
                    ["openAbleClosed", "partiallydOpen"].includes(openType) &&
                    item.status === 5
                  }
                  isChecked={checkedData
                    .map((data) => data.id)
                    .includes(item.id)}
                  onClick={() => {
                    goNextDetail(item);
                  }}
                  onClickChecked={() => {
                    onCheckedPortfolio(item);
                  }}
                />
              );
            })}
          </ListContainer>
        )}
      </ContentsBody>
      {myInfo.authorityId === 2 &&
        ["openAbleClosed", "partiallydOpen"].includes(openType) && (
          <CheckedPortfolioControlContainerWrapper
            className={checkedData.length > 0 ? "show" : ""}
          >
            <CheckedPortfolioControlContainer>
              <div
                className="checked__portfolio__control__btn"
                onClick={() => {
                  onClickShowCheckedDataList();
                }}
              >
                <List />
                {checkedData.length > 0 && showCheckedDataList && (
                  <CheckedPortfolioListContainer
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="checked__portfolio__list__header">
                      <div
                        className="close__btn"
                        onClick={() => {
                          onClickShowCheckedDataList();
                        }}
                      >
                        <Close />
                      </div>
                    </div>
                    <div className="checked__portfolio__list">
                      {checkedData.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="checked__portfolio__list__item"
                            onClick={() => {
                              onCheckedPortfolio(item);
                            }}
                          >
                            <CheckT />
                            <div>{item.portfolio.companyName}</div>
                          </div>
                        );
                      })}
                    </div>
                  </CheckedPortfolioListContainer>
                )}
              </div>
              <div
                className="heading-16-md checked__portfolio__open__btn"
                onClick={() => {
                  onClickPartiallydOpen();
                }}
              >
                선택된 기업 리스크평가 열기 ({checkedData.length})
              </div>
              <div
                className="checked__portfolio__control__btn"
                onClick={() => {
                  onInitPortfolio();
                }}
              >
                <Close />
              </div>
            </CheckedPortfolioControlContainer>
          </CheckedPortfolioControlContainerWrapper>
        )}
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ContentsBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 0 auto;
  overflow: scroll;
`;
const ListEmptyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
const ListContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 8px;
`;

const QuarterContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QuarterItem = styled.div`
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  color: #bec0c2;
  cursor: pointer;

  &.selected {
    color: #1f2022;
    :hover {
      color: #1f2022;
      background-color: transparent;
    }
  }

  :hover {
    color: #bec0c2;
    background-color: #f3f4f4;
  }
`;
const ListTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const FileDownloadBtn = styled.div`
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  color: var(--text-01);
  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  background: var(--background-contents-01, #fff);
  transition: all 0.2s ease;
  path {
    fill: var(--text-01);
  }

  cursor: pointer;

  :hover {
    background: var(--hover-100);
  }

  &.disabled {
    border-color: var(--gray-200);
    color: var(--gray-200);
    cursor: default;
    path {
      fill: var(--gray-200);
    }
    :hover {
      background: var(--background-contents-01, #fff);
    }
  }
`;

const CheckedPortfolioControlContainerWrapper = styled.div`
  position: absolute;
  width: fit-content;
  height: 44px;

  transition: all 0.2s ease;
  left: 50%;
  bottom: -50px;
  transform: translateX(-50%);
  &.show {
    bottom: 30px;
  }
`;
const CheckedPortfolioControlContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .checked__portfolio__control__btn {
    position: relative;
    width: 56px;
    min-width: 56px;
    max-width: 56px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #d5d6d8;
    box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
    border-radius: 4px;

    transition: all 0.2s ease;

    cursor: pointer;

    :hover {
      background-color: #f3f4f4;
    }
  }

  .checked__portfolio__open__btn {
    width: 254px;
    min-width: 254px;
    max-width: 254px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #173aff;
    box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
    border-radius: 4px;
    color: #ffffff;
    transition: all 0.2s ease;
    cursor: pointer;

    :hover {
      background-color: #112cbf;
    }
  }
`;

const CheckedPortfolioListContainer = styled.div`
  position: absolute;

  left: 0;
  bottom: calc(100% + 24px);
  width: 382px;
  height: max-content;
  max-height: 520px;
  background-color: #ffffff;
  filter: drop-shadow(2px 2px 10px rgba(100, 103, 109, 0.3));
  border-radius: 8px;

  padding-bottom: 20px;

  ::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 10%;
    width: 0;
    height: 0;
    border: 14px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
    margin-left: -14px;
    margin-bottom: -14px;
  }

  .checked__portfolio__list__header {
    width: 100%;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 12px;

    .close__btn {
      width: 32px;
      min-width: 32px;
      max-width: 32px;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
      transition: all 0.2s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      :hover {
        background: #f3f4f4;
        border-radius: 4px;
      }
    }
  }
  .checked__portfolio__list {
    width: 100%;
    height: calc(520px - 64px);
    min-height: calc(520px - 64px);
    max-height: calc(520px - 64px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 22px;
    overflow: scroll;
  }

  .checked__portfolio__list__item {
    width: 100%;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #edeeef;
    gap: 40px;
    padding: 0 20px;

    cursor: pointer;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export default MobileRiskManagementTotalEvaluation;
