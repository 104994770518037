import React from "react";
import styled from "styled-components";

interface Props {
  fitContent?: boolean;
  required?: boolean;
  className?: string;
  textClassName?: string;
  style?: React.CSSProperties;
  title: string;
  titleWidth?: number;
  titleColor?: string;
  minHeight?: number;
  tabCount?: number;
  children: React.ReactNode;
  transfromPixel?: (value: number) => number;
}
const RowTitleBox: React.FC<Props> = (props) => {
  const {
    fitContent,
    required,
    className,
    textClassName = "heading-14-sb",
    style,
    title,
    titleWidth = 120,
    minHeight = 40,
    titleColor = "var(--text-04)",
    tabCount = 0,
    children,
    transfromPixel = (n) => n,
  } = props;
  return (
    <Container
      className={className}
      transfromPixel={transfromPixel}
      style={{
        ...style,
        width: fitContent ? "fit-content" : "100%",
        minHeight: transfromPixel(minHeight),
      }}
    >
      <Header
        className={textClassName}
        transfromPixel={transfromPixel}
        style={{
          paddingLeft: 12 * tabCount,
          color: titleColor,
          width: transfromPixel(titleWidth),
          minWidth: transfromPixel(titleWidth),
          maxWidth: transfromPixel(titleWidth),
        }}
      >
        <span>{title}</span>
        {required && <span style={{ color: "var(--primary-blue)" }}>﹡</span>}
      </Header>
      <Body transfromPixel={transfromPixel}>{children}</Body>
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const Header = styled.div<{ transfromPixel: (value: number) => number }>`
  display: flex;
  flex-direction: row;

  margin-top: 9px;
`;
const Body = styled.div<{ transfromPixel: (value: number) => number }>`
  flex-grow: 1;
  height: fit-content;
`;
export default RowTitleBox;
