import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as File } from "../../../../assets/image/large_file.svg";
import { ReactComponent as Del } from "../../../../assets/image/chip_delete.svg";
import { ExpertFileProps } from "../../../../type/data";
import { saveAs } from "file-saver";
import { onDownload } from "../../../../common/commonUtil";

interface Props {
  file: ExpertFileProps;
  onClickDeleteFile: () => void;
}

export const ExpertFileCard: React.FC<Props> = (props) => {
  const { file, onClickDeleteFile } = props;
  const [fileName, setFileName] = useState<string>("");

  const onDownloadReportFile = () => {
    if (file.key === "localFile") {
      file.localFile && saveAs(file.localFile);
    } else {
      file.serverFile &&
        onDownload(file.serverFile.key, file.serverFile.fileName);
    }
  };

  useEffect(() => {
    file.key === "localFile"
      ? setFileName(file.localFile?.name || "untitled")
      : setFileName(file.serverFile?.fileName || "untitled");
  }, [file]);

  return (
    <Container>
      <FlexRow>
        <FileContainer className={`body-14-rg`} onClick={onDownloadReportFile}>
          <div className="row__flex" style={{ gap: 6 }}>
            <File width={24} height={24} />
            {fileName}
          </div>
          <DeleteBtn
            onClick={(e) => {
              e.stopPropagation();
              onClickDeleteFile();
            }}
          >
            <Del />
          </DeleteBtn>
        </FileContainer>
      </FlexRow>
    </Container>
  );
};

const Container = styled.div(
  () => css`
    width: max-content;
    height: auto;
    display: flex;
    flex-direction: column;
  `
);

const FileContainer = styled.div`
  width: max-content;
  height: 100%;
  min-height: 40px;
  max-height: 40px;
  gap: 36px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid var(--gray-300);
  border-radius: 4px;

  transition: all 0.2s ease;

  padding: 8px 12px;
  cursor: pointer;

  background-color: var(--white);

  &.disabled {
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }

  :hover {
    background-color: var(--hover-100);
  }

  .row__flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeleteBtn = styled.div`
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: var(--gray-400);
  }
`;
