import React, { useEffect, useRef, useState } from "react";
import {
  ExitProcessProps,
  FairValueAdminProps,
  InvestmentHistoryAdminProps,
  MAIN_PARTICIPANT,
  PHASE_CLOSURE,
  PHASE_DAMAGE,
  PortfolioAdminProps,
  PortfolioDetailAdminProps,
} from "../interface/Portfolio.interface";
import {
  changePortfolioExcavator,
  changePortfolioParticipant,
  changePortfolioStatusClosure,
  changePortfolioStatusDamage,
  changePortfolioStatusUndo,
  exitCancelPortfolio,
  exitPortfolio,
  getAcUser,
  getAllPortfolio,
  getPortfolioById,
} from "../../../../../api/repository/admin/AcceleratorRepository";
import { TinyUserInfo, UserInfo } from "../../../../../type/data";
import { showToast } from "../../../../../common/commonUtil";
import { rootStyle } from "../../../../../style/ThemeStyles";
import { Bounce } from "react-toastify";

const usePortfolio = () => {
  const initCompleted = useRef<boolean>(false);
  const contentsBodyRef = useRef<HTMLDivElement>(null);

  const selectedFairValue = useRef<FairValueAdminProps | undefined>(undefined);
  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [allPortfolio, setAllPortfolio] = useState<
    PortfolioAdminProps[] | undefined
  >(undefined);
  const [allUser, setAllUser] = useState<TinyUserInfo[]>([]);

  const [selectedPortfolio, updateSelectedPortfolio] = useState<
    PortfolioAdminProps | undefined
  >(undefined);
  const [selectedPortfolioDetail, updateSelectedPortfolioDetail] = useState<
    PortfolioDetailAdminProps | undefined
  >(undefined);
  const [isDetailShow, updateDetailShow] = useState<boolean>(false);
  const [openChangeParticipantModal, updateOpenChangeParticipantModal] =
    useState<boolean>(false);

  const [openExitModal, updateOpenExitModal] = useState<boolean>(false);
  const [openChangeExcavatorModal, updateOpenChangeExcavatorModal] =
    useState<boolean>(false);
  const [openChangePhaseDamage, updateOpenChangePhaseDamage] =
    useState<boolean>(false);
  const [openChangePhaseClosure, updateOpenChangePhaseClosure] =
    useState<boolean>(false);

  const [investExitData, setInvestExitData] = useState<
    InvestmentHistoryAdminProps | undefined
  >(undefined);

  const [importFairValueDataModal, setImportFairValueDataModal] =
    useState<boolean>(false);

  const [portfolioFairValueDataModal, setPortfolioFairValueDataModal] =
    useState<boolean>(false);

  const onClickExit = () => {
    updateOpenExitModal(true);
  };

  const closeExit = () => {
    updateOpenExitModal(false);
  };

  const onClickParticipantChange = () => {
    updateOpenChangeParticipantModal(true);
  };

  const closeParticipantChange = () => {
    updateOpenChangeParticipantModal(false);
  };

  const onClickExcavatorChange = () => {
    updateOpenChangeExcavatorModal(true);
  };

  const closeExcavatorChange = () => {
    updateOpenChangeExcavatorModal(false);
  };

  const onClickInvestExitHistoryRow = (target: InvestmentHistoryAdminProps) => {
    setInvestExitData(target);
  };
  const closeInvestExitModal = () => {
    setInvestExitData(undefined);
  };

  const onClickPortfolioStatus = (phase: number) => {
    if (phase === PHASE_DAMAGE) updateOpenChangePhaseDamage(true);
    if (phase === PHASE_CLOSURE) updateOpenChangePhaseClosure(true);
  };

  const closePortfolioStatus = (phase: number) => {
    if (phase === PHASE_DAMAGE) updateOpenChangePhaseDamage(false);
    if (phase === PHASE_CLOSURE) updateOpenChangePhaseClosure(false);
  };

  const onClickPortfolio = (portfolio: PortfolioAdminProps) => {
    updateSelectedPortfolio(portfolio);
  };

  const closeDetail = () => {
    updateDetailShow(false);
    updateSelectedPortfolio(undefined);
  };

  const onClickImportFairValueData = () => {
    setImportFairValueDataModal(true);
  };

  const closeImportFairValueDataModal = () => {
    setImportFairValueDataModal(false);
  };

  const onClickPortfolioFairValueData = (target?: FairValueAdminProps) => {
    selectedFairValue.current = target;
    setPortfolioFairValueDataModal(true);
  };

  const closePortfolioFairValueDataModal = () => {
    selectedFairValue.current = undefined;
    setPortfolioFairValueDataModal(false);
  };

  const goToScroll = () => {
    if (selectedPortfolio && contentsBodyRef.current) {
      const querySelectTarget = document.querySelector(
        `#EP_${selectedPortfolio.portfolioId}`
      );
      if (querySelectTarget) {
        const targetRect = querySelectTarget.getBoundingClientRect();
        const headerOffset = 106;
        const elementPosition = targetRect.top;
        const offsetPosition =
          elementPosition + contentsBodyRef.current.scrollTop - headerOffset;
        contentsBodyRef.current.scrollTo({
          top: offsetPosition,
          behavior: "auto",
        });
      }
    }
  };

  const getFilterPortfolio = () => {
    if (!allPortfolio) return [];
    const filterPortfolio = allPortfolio.filter((item) => {
      return (
        item.companyName &&
        item.companyName.toUpperCase().includes(searchKeyword.toUpperCase())
      );
    });

    return filterPortfolio;
  };

  const changeParticipant = async (newUser: TinyUserInfo, comment: string) => {
    if (!selectedPortfolio) return;
    const preParticipant = selectedPortfolio.pfParticipant.find(
      (item) => item.screenRole.screenRoleId === MAIN_PARTICIPANT
    )?.acUser.name;

    const result = await changePortfolioParticipant(
      selectedPortfolio.portfolioId,
      comment,
      newUser.acUserId
    );
    if (result && result.success) {
      showToast({
        customElement: (
          <span className="heading-16-sb">
            {preParticipant
              ? `담당자가 ${preParticipant}에서 ${newUser.name}으로 변경되었습니다.`
              : `${newUser.name}가 담당자가 되었습니다.`}
          </span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--green-400"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,

        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      selectedPortfolio?.pfParticipant.forEach((item) => {
        if (item.screenRole.screenRoleId === MAIN_PARTICIPANT) {
          item.acUser = newUser;
        }
      });
      await getPortfolio();
      await getPortfolioDetail();
    }
  };

  const changeExcavator = async (newUser: TinyUserInfo) => {
    if (!selectedPortfolioDetail) return;

    const preParticipant = selectedPortfolioDetail.excavator.name;

    const result = await changePortfolioExcavator(
      selectedPortfolioDetail.portfolioId,
      newUser.acUserId
    );
    if (result && result.success) {
      showToast({
        customElement: (
          <span className="heading-16-sb">
            {preParticipant
              ? `발굴자가 ${preParticipant}에서 ${newUser.name}으로 변경되었습니다.`
              : `${newUser.name}가 발굴자가 되었습니다.`}
          </span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--green-400"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,

        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      await getPortfolio();
      await getPortfolioDetail();
    }
  };

  const chagneStatusDamage = async (date: string) => {
    if (!selectedPortfolio) return;
    const result = await changePortfolioStatusDamage(
      selectedPortfolio.portfolioId,
      date
    );
    if (result && result.success) {
      showToast({
        customElement: (
          <span className="heading-16-sb">손상 처리가 완료되었습니다.</span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--green-400"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,

        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      await getPortfolio();
    }
  };

  const chagneStatusClosure = async (date: string) => {
    if (!selectedPortfolio) return;
    const result = await changePortfolioStatusClosure(
      selectedPortfolio.portfolioId,
      date
    );
    if (result && result.success) {
      showToast({
        customElement: (
          <span className="heading-16-sb">폐업 처리가 완료되었습니다.</span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--green-400"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,

        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      await getPortfolio();
    }
  };

  const chagneStatusUndo = async () => {
    if (!selectedPortfolio) return;
    const result = await changePortfolioStatusUndo(
      selectedPortfolio.portfolioId
    );
    if (result && result.success) {
      const statusName =
        selectedPortfolio.pfPhase.pfPhaseId === PHASE_DAMAGE
          ? "손상"
          : selectedPortfolio.pfPhase.pfPhaseId === PHASE_CLOSURE
          ? "폐업"
          : "철회";

      showToast({
        customElement: (
          <span className="heading-16-sb">
            {statusName} 처리가 취소되었습니다.
          </span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--green-400"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,

        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      await getPortfolio();
    }
  };

  const onExitPortfolio = async (data: ExitProcessProps[]) => {
    if (!selectedPortfolioDetail) return;

    const result = await exitPortfolio(data);
    if (result && result.success) {
      await getPortfolio();
      closeExit();
    }
  };
  const onExitCancel = async (id: number) => {
    if (!investExitData) return;
    if (!selectedPortfolioDetail) return;

    const result = await exitCancelPortfolio(
      selectedPortfolioDetail.portfolioId,
      id
    );
    if (result) {
      await getPortfolio();
      closeInvestExitModal();
    }
  };

  const getPortfolio = async () => {
    const result = await getAllPortfolio();
    setAllPortfolio(result ? result : []);
  };

  const getPortfolioDetail = async () => {
    if (!selectedPortfolio) return;

    const result = await getPortfolioById(selectedPortfolio.portfolioId);
    updateSelectedPortfolioDetail(result ? { ...result } : undefined);
  };

  const getAllUser = async () => {
    const userResult = await getAcUser();
    if (userResult) {
      const newData: TinyUserInfo[] = userResult
        .filter(
          (item) =>
            item.employmentStatusId && [2, 3].includes(item.employmentStatusId)
        )
        .map((item, index): TinyUserInfo => {
          return {
            acUserId: parseInt(`${item.acUserId}`),
            name: item.name,
            profileImgKey: item.profileImgKey,
            profileImgUrl: item.profileImgUrl,
          };
        });
      setAllUser(newData || []);
    } else {
      setAllUser([]);
    }
  };

  useEffect(() => {
    if (selectedPortfolioDetail) updateDetailShow(true);
  }, [selectedPortfolioDetail]);

  useEffect(() => {
    if (selectedPortfolio) getPortfolioDetail();
  }, [selectedPortfolio]);

  useEffect(() => {
    if (allPortfolio && selectedPortfolio) {
      const index = allPortfolio.findIndex(
        (item) => item.portfolioId === selectedPortfolio.portfolioId
      );
      if (index !== -1) updateSelectedPortfolio({ ...allPortfolio[index] });
    }
  }, [allPortfolio]);

  useEffect(() => {
    if (isDetailShow && selectedPortfolio && contentsBodyRef.current) {
      setTimeout(() => {
        goToScroll();
      }, 200);
    }
  }, [isDetailShow]);

  useEffect(() => {
    getPortfolio();
    getAllUser();
  }, []);

  return [
    contentsBodyRef,
    selectedFairValue.current,
    searchKeyword,
    allUser,
    selectedPortfolio,
    selectedPortfolioDetail,
    investExitData,
    isDetailShow,
    openChangeParticipantModal,
    openChangeExcavatorModal,
    openChangePhaseDamage,
    openChangePhaseClosure,
    openExitModal,
    importFairValueDataModal,
    portfolioFairValueDataModal,
    updateSearchKeyword,
    getFilterPortfolio,
    onClickPortfolio,
    closeDetail,
    onClickParticipantChange,
    closeParticipantChange,
    changeParticipant,
    onClickExcavatorChange,
    closeExcavatorChange,
    changeExcavator,
    chagneStatusDamage,
    chagneStatusClosure,
    chagneStatusUndo,
    onClickPortfolioStatus,
    closePortfolioStatus,
    onClickExit,
    closeExit,
    onExitPortfolio,
    onClickInvestExitHistoryRow,
    closeInvestExitModal,
    onExitCancel,
    onClickImportFairValueData,
    closeImportFairValueDataModal,
    getPortfolio,
    onClickPortfolioFairValueData,
    closePortfolioFairValueDataModal,
  ] as const;
};

export default usePortfolio;
