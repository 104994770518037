import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Check } from "../../../../../assets/image/icon_check_new.svg";

interface Props {
  contents?: string;
  defaultValue?: boolean;
  onChangeData?: (checked: boolean) => void;
}

const CustomCheckBox: React.FC<Props> = (props) => {
  const {
    contents = "해당 내용이 없어요.",
    defaultValue,
    onChangeData,
  } = props;
  const [checked, updateChecked] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValue !== undefined) {
      updateChecked(defaultValue);
    }
  }, [defaultValue]);
  return (
    <Container
      onClick={() => {
        updateChecked(!checked);
        onChangeData && onChangeData(!checked);
      }}
    >
      <CheckWrapper>
        <Check className={`check__box ${checked ? "checked" : ""}`} />
      </CheckWrapper>
      <span className="body-14-md" style={{ color: "var(--text-01)" }}>
        {contents}
      </span>
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 10px;
`;

const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--blue-500);
        stroke: var(--blue-500);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--blue-500);
        }
      }
    }
  }
`;

export default CustomCheckBox;
