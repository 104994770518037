import { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { DefaultButton } from "lattice_core";
import {
  DealFlowStatus,
  PortfolioNavigationDefaultProps,
} from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import { EvaluationSection } from "./organisms/EvaluationSection";
import useEvaluation from "../hook/useEvaluation";
import { EvaluationEmpty } from "./organisms/EvaluationEmpty";
import { InvestmentStatus } from "../interface/Evaluation.interface";
import ModalLayout from "../../../../components/modal/ModalLayout";
import EvaluationOpenModal from "./organisms/EvaluationOpenModal";
import ConfirmModal from "../../../../2.0lattice/components/modal/ConfirmModal";
import SpinnerModal from "../../../../components/modal/SpinnerModal";

const MobileEvaluation: React.FC<PortfolioNavigationDefaultProps> = (props) => {
  const { portfolioNavInfo, init, isEditable = false } = props;
  const [
    portfolioId,
    firstLoading,
    evaluations,
    openDealflowId,
    isNext,
    preEvaluationData,
    evaluationOpenModal,
    askCloneEvaluationModal,
    loadingModal,
    updateAskCloneEvaluationModal,
    onClickCreatedEvaluation,
    closeCreatedEvaluationModal,
    goToEvaluationDetail,
    onCloneEvaluation,
    evaluationInit,
  ] = useEvaluation();
  useEffect(() => {
    if (!firstLoading) evaluationInit();
  }, [portfolioNavInfo]);
  return (
    <Container>
      <BodyWrapper>
        <Body>
          {evaluations.length === 0 && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EvaluationEmpty msg={["열린 투자 심사가 없어요."]} />;
            </div>
          )}
          {evaluations
            .filter((item) =>
              ["DROP"].includes(portfolioNavInfo.pfPhase.type)
                ? true
                : item.status !== DealFlowStatus.CANCEL
            )
            .map((item, index) => {
              if (
                !isEditable &&
                evaluations.length === 1 &&
                item.investmentAssessment.length === 0
              ) {
                return <EvaluationEmpty msg={["열린 투자 심사가 없어요."]} />;
              }

              if (index === 0) {
                return (
                  <EvaluationSection
                    key={index}
                    sectionTitle={item.title}
                    showCreateCard={
                      isEditable &&
                      portfolioNavInfo.phase !== undefined &&
                      portfolioNavInfo.phase !== "contract"
                    }
                    createAble={
                      item.status === DealFlowStatus.OPEN &&
                      item.investmentAssessment.filter((evaluation) =>
                        [
                          InvestmentStatus.OPEN,
                          InvestmentStatus.PUBLISH,
                          InvestmentStatus.VOTING,
                          InvestmentStatus.REQUEST,
                          InvestmentStatus.CONTRACT,
                        ].includes(evaluation.status)
                      ).length === 0 &&
                      isEditable
                    }
                    evaluationList={item.investmentAssessment}
                    onClickCreateEvaluation={onClickCreatedEvaluation}
                    onClickCard={(id) => {
                      goToEvaluationDetail(id);
                    }}
                  />
                );
              } else {
                return (
                  <div key={index}>
                    <SectionDivider />
                    <EvaluationSection
                      sectionTitle={item.title}
                      showCreateCard={false}
                      createAble={false}
                      evaluationList={item.investmentAssessment}
                      onClickCard={(id) => {
                        goToEvaluationDetail(id);
                      }}
                    />
                  </div>
                );
              }
            })}
        </Body>
        {askCloneEvaluationModal && (
          <ModalLayout
            isOpen={askCloneEvaluationModal}
            toggle={() => {
              updateAskCloneEvaluationModal(false);
            }}
            isFullScreen
          >
            <ConfirmModal
              type="confirm"
              contents={[
                "이전에 진행한 투자 심사가 있습니다.",
                "해당 내용을 붙여넣을까요?",
              ]}
              negativeTitle="새로쓰기"
              positiveTitle="붙여넣기"
              onClickNegativeBtn={() => {
                onCloneEvaluation(false);
                updateAskCloneEvaluationModal(false);
              }}
              onClickPositiveBtn={() => {
                onCloneEvaluation(true);
                updateAskCloneEvaluationModal(false);
              }}
            />
          </ModalLayout>
        )}
        {openDealflowId !== undefined && evaluationOpenModal && (
          <ModalLayout
            isOpen={evaluationOpenModal}
            toggle={closeCreatedEvaluationModal}
            isFullScreen
          >
            <EvaluationOpenModal
              portfolioId={portfolioId}
              dealflowId={openDealflowId}
              isNext={isNext}
              close={closeCreatedEvaluationModal}
              callbackData={(investmentAssessmentId) => {
                console.log(investmentAssessmentId);
                init();
                goToEvaluationDetail(investmentAssessmentId);
                closeCreatedEvaluationModal();
              }}
            />
          </ModalLayout>
        )}
        {loadingModal && <SpinnerModal open={loadingModal} />}
      </BodyWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;

  height: calc(100%);
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: scroll;
`;

const Body = styled.div(
  () => css`
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
  `
);

const SectionDivider = styled.div`
  width: 100%;
  height: 8px;
  min-height: 8px;
  background-color: #f3f6fb;
`;
export default MobileEvaluation;
