import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

interface Props {}

export const FundAccountDetailHeader: React.FC<Props> = ({}) => {
  const height = 32;
  return (
    <Container className={`caption-12-rg`}>
      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "center" }}
      >
        투자/회수
      </CellConatiner>

      <CellConatiner
        width={150}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        일자
      </CellConatiner>

      <CellConatiner
        width={200}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        주식수
      </CellConatiner>

      <CellConatiner
        width={200}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        단가
      </CellConatiner>
      <CellConatiner
        width={200}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        금액
      </CellConatiner>
      <CellConatiner
        width={1200 - 200 - 200 - 200 - 250 - 32}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        구매/판매처
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: #f6faff;

  :nth-child(1) {
    border-top-left-radius: 4px;
  }
  :nth-last-child(1) {
    border-top-right-radius: 4px;
  }
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height || 32}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: var(--text-04);
  border-right: 0.5px solid var(--divider-300, #e3e4e5);
  border-bottom: 0.5px solid var(--divider-300, #e3e4e5);
  padding: 0 10px;
`;
