import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Styled, { css } from "styled-components";
import { getNewDate, getYMD } from "../../../../../common/commonUtil";

interface Props {
  defaultDate?: string;
  onChange?: (e: string) => void;
}

export const StructureDate: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string>("");

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    setValue(value);
    props.onChange && props.onChange(value);
  };

  useEffect(() => {
    const date = props.defaultDate ? getYMD(getNewDate(props.defaultDate)) : "";
    setValue(date);
  }, [props.defaultDate]);

  return (
    <Container>
      <InputComponent type="date" value={value} onChange={onChangeInput} />
    </Container>
  );
};

const Container = Styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

const InputComponent = styled.input`
  width: 175px;
  height: 40px;

  border: 1px solid var(--gray-300);
  border-radius: 4px;
  transition: 0.2s;
  padding: 6px 12px;
  background: var(--background-contents01);

  ::placeholder {
    color: var(--text-inactive);
  }

  :read-only {
    padding: 6px 0px;
    border: 1px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  :disabled {
    border: 1px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &.show__msg {
    border: 1px solid var(--red-500);
    animation: shake 200ms;

    :focus {
      border: 1px solid var(--red-500);
    }

    :hover {
      border-color: var(--red-500);
    }
  }

  :focus {
    outline: none;
    border-color: var(--focus-field-blue);

    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    border-color: var(--focus-field-blue);
  }

  @keyframes shake {
    25% {
      transform: translateX(2px);
    }
    50% {
      transform: translateX(-2px);
    }
    75% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(-2px);
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;
