import { type } from "os";
import {
  DealFlowFilterProps,
  DealFlowPhase,
} from "../router/deal-flow/interface/DealFlow.interface";
import { PortfolioPhase } from "../router/portfolio/portfolio-detail/interface/PortfolioNavigation.interface";
import { RoundProps } from "../router/portfolio-viewer-stock-change-history/interface/type";

export type PortfolioPhaseType =
  | "EXCAVATION"
  | "REVIEW"
  | "GROWTH"
  | "CONTRACT"
  | "MANAGE"
  | "DAMAGE"
  | "EXIT"
  | "DROP"
  | "CLOSURE"
  | "WITHDRAW";

export type TABLE_FORM_TYPE =
  | "INVESTMENT_INFO_A"
  | "CONTRIBUTION_RATE"
  | "COMPANY_INFO_A"
  | "STAKE_STATEMENT_C"
  | "COMPANY_HISTORY"
  | "COMPANY_MEMBER"
  | "KEY_MANAGEMENT"
  | "FINANCIAL_STATEMENT_B"
  | "CAPITAL_INCREASE_HISTORY"
  | "BORROWING_STATUS"
  | "GOVERMENT_PROJECT"
  | "CONTRACT_STATUS"
  | "IPRS_PROCESS_B"
  | "SIMILAR_BUSINESS"
  | "FINANCING_PLAN"
  | "SALES_MANPOWER_PLAN"
  | "FUNDS_REQUIRED_PLAN"
  | "MILESTONE_KPI";

export const colorPicker: string[] = [
  "#ED0917",
  "#F26522",
  "#F7941E",
  "#FFF200",
  "#CBDB2A",
  "#8CC63F",
  "#39B54A",
  "#3FDABE",
  "#7CE0FF",
  "#24D6EE",
  "#04C3FF",
  "#00AEEF",
  "#0085FF",
  "#3A41EA",
  "#B466E3",
  "#B466E3",
  "#C4C4C4",
];

export const graphColorPalette: string[] = [
  "var(--primary)",
  "#4c6ef5",
  "#228be6",
  "#15aabf",
  "#12b886",
  "#40c057",
  "#82c91e",
  "#fab005",
  "#fd7e14",
  "#fa5252",
  "#91a7ff",
  "#74c0fc",
  "#66d9e8",
  "#63e6be",
  "#8ce99a",
  "#c0eb75",
  "#ffe066",
  "#ffa8a8",
  "#faa2c1",
  "#d0dcfc",
  "#c5ecff",
  "#aef4db",
  "#c1f4c6",
  "#ddf2aa",
  "#f4e49f",
  "#f4d0a8",
  "#f4cece",
  "#e8c7fe",
];

export enum PortfolioMenuType {
  // 딜플로우
  SERVICE_INFO = "service_info", // 서비스 정보
  MINUTES = "minutes", // 회의록
  PRE_PITCHING = "pre_pitching", // 예비피칭
  EVALUATION = "evaluation", // 투자심사
  BLUECARD = "bluecard", // 블루카드
  CONTRACT_DOCUMENT = "contract_document", // 서류관리

  // 엑셀러레이팅
  PORTFOLIO_INFO = "portfolio_info", // 포트폴리오 정보d
  PERFORM_MANAGE = "perform_manage", // 미팅노트
  FINANCIAL_INFO = "financial_info", // 재무정보
  INVESTMENT_INFO = "investment_info", // 투자정보
  NEXT_INVSTMENT = "next_investment", // 후행투자
  CONTRACT_DOCUMENT_ACCELEARATING = "contract_document_accelerating", // 서류관리-엑셀러레이팅
}

export type FontClass =
  | "font-regular-12"
  | "font-regular-14"
  | "font-medium-12"
  | "font-medium-14"
  | "font-medium-20"
  | "font-bold-14"
  | "font-bold-40";

export type TIPS = "NO" | "EXPECTED" | "FIXED";
export interface TipsProps {
  type: TIPS;
  name: string;
}

export const selectTipsData: TipsProps[] = [
  { type: "EXPECTED", name: "희망" },
  { type: "FIXED", name: "확정" },
  { type: "NO", name: "해당없음" },
];

export interface LoginResponse {
  message: string;
  userStatus: number;
  loginInfo: LoginInfo;
}

export interface StructureDetailProps {
  acStructureId: number;
  name: string;
  nickName: string;
  parent: number;
  structureIndex: number;
  headquarterId?: number;
  childrenId?: number[];
  children?: StructureDetailProps[];
}

export interface GoogleToken {
  success: boolean;
  tokenInfo: {
    accessToken: string;
    refreshToken: string;
    expiryDate: number;
    tokenType: string;
    email: string;
    name: string;
  };
}

export interface GoogleTokenImplict {
  success: boolean;
  tokenInfo: {
    email: string;
    name: string;
    accessToken: string;
  };
}

export interface LoginInfo {
  id: number;
  acceleratorId: number;
  email: string;
  name: string;
  isShowProfile: boolean;
  profileImgUrl: string;
  profileImgKey: string;
  menuOrdering: number[];
  authorityId: number;
  isBoardMember: boolean;
  checkBasicSetting: {
    acStructure: boolean;
    acIndustrialTech: boolean;
    acFundAccount: boolean;
  };
}

export interface DateTime {
  date?: string;
  time?: string;
}

export interface TokenType {
  accessToken: string;
  refreshToken: string;
}

export interface ScheduleType {
  acUserScheduleId?: number;
  contentsHtml: string;
  date: string;
}

export interface UserOption {
  value: User;
  label: string;
}

export interface MemoType {
  acUserMemoId: number;
  title: string;
  contentsHtml: string;
  acMemoCategory: MemoCategoryType;
}

export interface MemoCategoryType {
  acMemoCategoryId: number;
  name: string;
}

export interface UserDetailInfo {
  acUserId: number;
  acPosition?: {
    acPositionId: number;
    name: string;
  };
  acResponsibility?: {
    acResponsibilityId: number;
    name: string;
  };
  acStructureId: number;
  acUserIndustrialTech: { acIndustrialTechId: number; name: string }[];
  authorityId: number;
  dateOfEntry: string;
  email: string;
  employmentStatusId: number;
  name: string;
  profileImgKey: string;
  profileImgUrl: string;
  UserStatusId: 2;
}

export interface TinyUserInfo {
  acUserId: number;
  name: string;
  profileImgKey?: string;
  profileImgUrl?: string;
}

export interface UserInfo {
  type?: "AC_USER" | "PF_USER";
  id: number | string;
  name: string;
  color: string;
  isShowProfile: boolean;
  isNew?: boolean;
  isChange?: boolean;
  isDelete?: boolean;
  email?: string;
  userStatusId?: number;
  employmentStatusId?: number;
  acStructureId?: number | string;
  acStructure?: Structure;
  authorityId?: number;
  profileImgUrl?: string;
  profileImgKey?: string;
  acResponsibility?: Responsibility;
  acResponsibilityId?: number;
  acPosition?: Position;
  acPositionId?: number;
  acIndustrialTech?: IndustryTech[];
  dateOfEntry?: any;
  acUserId?: number | string;
  phoneNumber?: string;
  isBoardMember?: boolean;
  isReportManager?: boolean;
  downloadOriginal?: boolean;
}

export interface PortfolioUserInfo {
  pfUserId: number | string;
  name: string;
  color: string;
  isShowProfile: boolean;

  isNew?: boolean;
  isChange?: boolean;
  isDelete?: boolean;

  email?: string;
  pfUserStatusId?: number;
  employmentStatusId?: number;

  pfStructureId?: number | string;
  pfStructure?: PFStructure;

  profileImgUrl?: string;
  profileImgKey?: string;

  pfResponsibility?: string;
  pfPosition?: string;

  dateOfEntry?: any;

  phoneNumber?: string;
}

export interface PFStructure {
  pfStructureId: number | string;
  name: string;
}

export interface Structure {
  acStructureId: number | string;
  name: string;
}

export interface FileType {
  key: string;
  fileName: string;
  fileSize: number;
  fileType: string;
  id?: number;
  updatedAt?: string;
}
export interface FileTypeRead extends FileType {
  storage?: "AWS_S3" | "GOOGLE_DRIVE";
}

export interface User {
  isChange: boolean;
  acUserId: number;
  name: string;
  profileImg: string;
  acStructureId?: number | undefined;
  teamName?: string;
  userPosition?: Position;
  color?: string;
  authority: Authority[];
  acUserMajor: IndustryTech[];
  acUserAuthority?: number[];
}

export interface Position {
  acPositionId: number;
  name: string;
  order?: number;
}
export interface Responsibility {
  acResponsibilityId: number;
  name: string;
  order?: number;
}

export interface Goal {
  isNew?: boolean;
  goalId: number;
  content: string;
}

export interface Users {
  id: number;
  name: string;
}

export interface Authority {
  id: number;
  name: string;
}
export interface Channel {
  isNew?: boolean;
  isDelete?: boolean;
  acChannelId: number | string;
  name: string;
}
export interface ExcavationArea {
  excavationAreaId: number | string;
  name: string;
}

export interface Area {
  excavationAreaId: number;
  name: string;
}
export interface Address {
  addr1?: string;
  addr2?: string;
  addr3?: string;
  fullAddr?: string;
  addrDetail?: string;
}

export interface IndustryTechType {
  industrialTechTypeId: number;
  name: string;
  list: IndustryTech[];
}

export interface IndustryTech {
  acIndustrialTechId: number | string;
  name: string;
  keyword?: KeywordType[];
  isActive?: boolean;
}

export interface IndustryType {
  industryType: string;
  industryCode: string;
  parents?: string;
}

export interface KeywordType {
  keywordId: number | string;
  name: string;
}

export interface FundAccountType {
  //수정 가능
  status?:
    | "expected_investment"
    | "before_investment"
    | "investing"
    | "out_of_financial_resources"
    | "operation"
    | "end_of_operation";
  isNew?: boolean;
  isChange?: boolean;
  isUsed?: boolean;
  isActive?: boolean;
  acFundAccountId?: number | string;
  nameKo?: string;
  nameEn?: string;
  totalAmount?: number;
  totalExistAmount?: number;
  totalInvestmentAmount?: number;
  investmentOrganizationCount?: number;
  acInvestmentOrganization?: OrganizationAssociation[];
  avaliableAmount?: number;
  coreOperationalUser?: UserInfo[];
  fundManagerUser?: UserInfo[];
  exitPortfolio?: PortfolioType[];
  investmentPortfolio?: PortfolioType[];
  investmentStartDate?: string;
  investmentEndDate?: string;
  operatingStartDate?: string;
  operatingEndDate?: string;
  totalExitStock?: PortfolioExitStock[]; //총 회수 주식
  goal?: string;
  serialNumber?: string;
  quorum?: number;
}

export interface ActiveFundAccountType {
  acFundAccountId: number;
  nameKo: string;
  nameEn: string;
  totalAmount: number;
  investmentStartDate: string;
  investmentEndDate: string;
  operatingStartDate: string;
  operatingEndDate: string;
  investmentOrganizationCount: number;
  avaliableAmount: number;
  goal: string;
  serialNumber: string;
  createdAt: string;
  updatedAt: string;
  tableForm: TableFormType[];
  quorum?: number;
}

export interface TableFormType {
  id: number;
  type: TABLE_FORM_TYPE;
  title: string;
  template?: string | null;
  required: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface PortfolioExitStock {
  //TODO 총 회수 주식
  portfolioId: number; //포트폴리오 id식별
  alloted: number; //몇개의 주식을 exit했는가?
}
export interface PortfolioType {
  //포트폴리오 간략한 데이터 타입
  portfolioId: number;
  companyName: string;
  allotedStock: number;
  price: number;
  amount: number; //price * allotedStock
  type: string;
}

export interface Organization {
  acInvestmentOrganizationId: number;
  name: string;
  managerName?: string;
  managerEmail?: string;
  managerPhon?: string;
}

export interface OrganizationAssociation {
  acFundAccountId?: number | string;
  acInvestmentOrganizationId?: number | string;
  acInvestmentOrganizationName?: string;
  investmentAmount?: number;
}
export interface ParticipantType {
  acFundAccountId?: number | string;
  estimatedValue?: number;
  estimatedPostValue?: number;
  expectedValue?: number;
  pfParticipant?: Participant[];
  portfolioId?: number;
}

export interface PortfolioFolders {
  folderId: string;
  noteFolderId: string;
  reportFolderId: string;
  contractFolderId: string;
}

export interface PortfolioPhaseProps {
  pfPhaseId?: number;
  type?: PortfolioPhase;
  name?: string;
}

export interface SimilarPortfolioType {
  portfolioId: number;
  companyName: string;
  description: string;
  pfPhase: PortfolioPhaseProps;
  pfIndustrialTech?: {
    acIndustrialTech: IndustryTech[];
    isMain: boolean;
    pfIndustrialTechId: number;
  };
  pfParticipant?: string;
}
export interface Participant {
  acUser: UserInfo;
  screenRoleId: number;
}

export interface NewOldStockType {
  newOldStockId: number;
  name: string;
}

export interface InvestmentPhaseType {
  pfInvetmentPhaseId: number;
  name: string;
}

export interface SellerType {
  sellerId?: number;
  name?: string;
  isOutside?: boolean;
  account?: SellerAccount[];
}
export interface SellerAccount {
  id: number | string;
  name: string;
  avgPrice?: number;
  existStock?: number;
}

export interface EvaluationType {
  pfEvaluationId?: number;
  portfolioId?: number;
  contentsHtml?: string;
  evaluationDate?: string;
  isConfirmed?: boolean;
  isTips?: TIPS;
  pfInvestment?: InvestmentSpecType[];
  pfEvaluationInvite?: EvaluationInviteType[];
}
export interface InvestmentSpecType {
  pfInvestmentId?: number | string;
  investmentStageId?: number;
  acFundAccountId?: number | string;
  acFundAccount?: FundAccountType;
  stockTypeId?: number;
  stockType?: { stockTypeId: number; name: string };
  newOldStockId?: number;
  newOldStock?: { newOldStockId: number; name: string };
  faceValue?: number;
  previousValue?: number;
  totalStock?: number;
  allotedStock?: number;
  newStockAmount?: number;
  investmentAmount?: number;
}

export interface EvaluationInviteType {
  pfEvaluationInviteId?: number;
  acUserId?: number | string;
  isAgreement?: boolean | null;
  opinion?: string;
}

export interface InvestmentForIndustrialTech {
  name: string;
  count: number;
  investmentAmount: number;
  valueAmount: number;
}

export interface RegionRatioType {
  region: MainRegion;
  count: number;
  portfolioList: { companyName: string; portfolioId: number }[];
}

export interface ExternalInvestmentOrganizationType {
  externalInvestmentOrganizationId: number | string;
  name: string;
  externalFundAccount: ExternalOrganizationAccountType[];
  externalFundManageUser: ExternalOrganizationManagerType[];
}

export interface ExternalOrganizationAccountType {
  externalFundAccountId: number | string;
  name: string;
}

export interface ExternalOrganizationManagerType {
  externalFundManageUserId: number | string;
  name: string;
  phoneNumber?: string;
  email?: string;
}

export type MainRegion =
  | "경기"
  | "서울"
  | "인천"
  | "강원"
  | "충남"
  | "충북"
  | "세종"
  | "전북"
  | "광주"
  | "전남"
  | "경북"
  | "대전"
  | "대구"
  | "울산"
  | "경남"
  | "부산"
  | "제주";

export interface AccountIndustryType {
  acFundAccountId: number;
  nameKo: string;
  pfIndustrialTech: {
    acIndustrialTechId: number;
    name: string;
    amount: number;
    count: number;
  }[];
}

export interface YearGoalInfoType {
  acFundAccountId: number;
  nameKo: string;
  amount: number;
  count: number;
}

export interface InputHitType {
  date: Date;
  yearMonth?: string;
  excavationCount: number;
  manageCount: number;
}

export type PhaseType = {
  [key: string]: PhaseCount;
};

export interface PhaseCount {
  count: number;
  compareToPreviousMonth: number;
}

export interface AccountAnalysisType {
  acFundAccountId?: number;
  availableAmount: number;
  totalInvestmentAmount: number;
  nameKo: string;
  investmentAmount: AverageAnalysisType;
  followupInvestmentAmount: AverageAnalysisType;
  externalFollowupInvestmentArr?: FollowupInvestmentType[];
  multiple: AverageAnalysisType;
  stakingRates: AverageAnalysisType;
  totalExternalAmount?: number;
}

export interface AverageAnalysisType {
  count?: number;
  maxValue: number;
  minValue: number;
  totalValue?: number;
  averageValue?: number;
}

export interface FollowupInvestmentType {
  id: number;
  name: string;
  amount: number;
}

export interface MultipleType {
  acIndustrialTechId: number;
  name: string;
  portfolio: PortfolioValueType[];
}

export interface PortfolioValueType {
  companyName: string;
  firstValue: number;
  holdingStock: number;
  investmentAmount: number;
  lastValue: number;
  portfolioId: number;
}

export interface InvestmentAssessmentContractDocument {
  docsFileId: string;
  contractDocumentTemplate: {
    type: string;
  };
}

export interface ContractDocumentTemplate {
  id: number;
  type: string;
  docsFileId: string;
  createdAt: string;
  updatedAt: string;
}

export interface InvestmentAssessmentReportTemplates {
  investmentAssessmentReportAccountId: number;
  contractDocumentTemplate: ContractDocumentTemplate[];
}

export interface ContractRequiredDataType {
  accountName: string;
  companyName: string;
  ceoName: string;
  initialInvestAmount: number;
  totalStock: number;
  faceValue: number;
  stockAmount: number;
  allocatedStock: number;
  newOldStock: string;
  stockType: string;
  contractDate: string[];
  previousValue?: number;
  investorName?: string;
  companyAddress?: string;
  investmentAssessmentReportAccountId?: number | string;
  investmentAssessmentInvite?: IAReportUser[];
  templates?: InvestmentAssessmentReportTemplates; // 필요한 템플릿들
  investmentAssessmentContractDocument?: InvestmentAssessmentContractDocument[]; // 이미 생성된 문서들
}

export interface ReportDocsType {
  acPfDocumentId: number;
  name: string;
  hasOriginalCheck?: boolean;
  hasRequiredCheck?: boolean;
  templateFile: string;
  pfDocumentType: any;
}

export interface ReportListType {
  quarterId: number;
  year: number;
  quarterNum: number;
  name?: string;
  pfParticipant: ReportUserType;
  portfolio: ReportPortfolioType;
  pfReportDocument: ReportDocumentListType[];
}

export interface ReportUserType {
  acUserId: number;
  name: string;
  color: string;
  isShowProfile: boolean;
  profileImgKey: string;
  profileImgUrl: string;
}

export interface ReportPortfolioType {
  portfolioId: number;
  companyName: string;
}

export interface ReportDocumentListType {
  pfReportDocumentId?: number;
  documentFile?: FileType;
  uploadStatusId: number;
  uploadStatus: { uploadStatusId: number; name: string };
  comment?: string;
  acPfDocumentId: number;
}

export type ReportFileType = "파일" | "피드백" | "반려" | "확인" | "대기" | "-";

export interface ReportPostType {
  portfolioId?: number;
  pfReportDocumentId?: number;
  statusId: number;
  year?: number;
  quarterNum?: number;
  documentFile?: FileType;
  comment?: string; // 모달 용
}

export interface PortfolioInfoType {
  acceleratorId?: number;
  portfolioId?: number;
  companyName?: string;
  description?: string;
  pfIndustrialTech?: IndustryTech[];
  pfKeyword?: KeywordType[];
  businessPlanFile?: FileType | any;
  businessPlanFileLocal?: File | any;
  explanatoryUrl?: string;
  problem?: string;
  solution?: string;
  excavationDate?: string;
  acChannel?: Channel;
  excavator?: UserInfo;
  excavationArea?: ExcavationArea;
  companyAddress?: Address;
  addr1?: string;
  addr2?: string;
  addr3?: string;
  addrDetail?: string;
  ceoName?: string;
  ceoEmail?: string;
  ceoTel?: string;
  establishmentDay?: string;
  businessNumber?: string;
  additionalInformation?: string;
  companyImgUrl?: string; // 로고
  companyImgKey?: string;
  companyImgFile?: { file: File | any; fileUrl: string; category: string };
  homepageUrl?: string;
  thumbnailImgUrl?: string;
  thumbnailImgKey?: string;
  thumbnailImgFile?: { file: File | any; fileUrl: string; category: string };
  stageType?: StageType;

  droppedPortfolio?: {
    droppedPortfolioId?: number;
    comment?: string;
    acUser?: {
      acUserId: number;
      email: string;
      name: string;
    };
  };
}

export interface PortfolioInfo extends PortfolioInfoType {
  participantMain?: string;
  participantMainStructureName?: string;
  participantMainTeamName?: string;
  participantSub?: string[];
}

//투자정보
export interface InvestmentInfoProps {
  id?: number;
  acFundAccount?: {
    acFundAccountId: number;
    nameKo: string;
    serialNumber: string;
  };
  purchasePrincipal?: number;
  purchaseStockNumber?: number;
  sellPrincipal?: number;
  sellStockNumber?: number;
  ownershipPercentage?: number;
  realizedMultiple?: number;
  unrealizedMultiple?: number;
  companyValue?: number;
  stockNumber?: number;
}

//리얼 데이터 타입
export interface RealDataProps {
  realStockTransactionId: number;
  date?: string;
  stockChangeType?: StockChangeType | string;
  stageType?: StageType | string;
  stockholderName?: string;
  registrationNumber?: string;
  relationship?: RelationshipType | string;
  country?: string;
  stockType?: StockType | string;
  fluctuateStockNumber?: number | string;
  faceValue?: number | string;
  issuingPrice?: number | string;
  seller?: string;
  sellerRegistrationNumber?: string;
  note?: string;
  updatedAt?: string;
}

//예상 데이터 타입
export interface ExpectDataProps {
  portfolio?: number;
  expectationStockTransactionId?: number;
  investmentProgress: number | string;
  date: string;
  investmentAmount: number | string;
  companyValue?: number | string;
  previousCompanyValue: number | string;
  previousCompanyValuePost?: number | string;
  acFundAccount?: number | string;
  exitAmount?: number | string;
  exitRate?: number | string;
  investmentOriginAmount?: number | string;
  expectationRealizedIncome?: number | string;
  expectationUnrealizedIncome?: number | string;
  type: number;
}

//사후관리 데이터 타입
export interface ExpectTotalDataProps {
  expectationStockTransactionId: number;
  previousCompanyValuePost?: number;
  expectationUnrealizedIncome: number;
  expectationRealizedIncome: number;
  totalRate: number | null;
  date: number;
  investmentAmount: number;
  previousCompanyValue: number;
  companyValue: number;
  exitAmount: number | null;
  exitRate: number | null;
  exitOriginAmount: number | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  investmentOrganization: string | null;
  sellingOrganization: string | null;
  isConfirm: boolean;
  isFundingSupport: boolean;
  type: 0 | 1;
  acFundAccount: {
    acFundAccountId: number;
    nameKo: string;
    nameEn: string;
  } | null;
  investmentProgress: {
    investmentProgressId: number;
    name: string;
  };
}

//사후롼리 예상 후속투자 타입
export interface ExpectNextInvestmentProps {
  expectationStockTransactionId?: number;
  portfolio?: number;
  type: 0 | 1;
  totalRate?: number | null;
  previousCompanyValue?: number;
  investmentAmount?: number;
  companyValue?: number; // 금번 라운드 기업가치(Pre)
  investmentProgress?: number;
  date?: number;
  investmentOrganization?: string | null;
  expectationUnrealizedIncome?: number;
  isConfirm: boolean;
  isFundingSupport: boolean;
}

//사후관리 예상 회수 타입
export interface ExpectExitProps {
  expectationStockTransactionId?: number;
  portfolio?: number;
  type: 0 | 1;
  totalRate?: number | null;
  previousCompanyValue?: number;
  exitAmount?: number | null;
  companyValue?: number; // 금번 라운드 기업가치(Pre)
  exitRate?: number | null;
  exitOriginAmount?: number | null;
  sellingOrganization?: string | null;
  investmentProgress?: number;
  date?: number;
  acFundAccount?: number;
  expectationUnrealizedIncome?: number;
  expectationRealizedIncome?: number;
  isConfirm: boolean;
  isFundingSupport: boolean;
}

/** 공통 */
export interface DictionaryType {
  stockType: StockType[];
  stockChangeType: StockChangeType[];
  stageType: StageType[];
  relationship: RelationshipType[];
  investmentProgress: ProgressType[];
  quorumType: QuorumType[];
  fundManager?: FundManager[];
  acFundAccount?: {
    acFundAccountId: number;
    nameKo: string;
    nickName?: string;
  }[];
}

export interface ProgressType {
  investmentProgressId: number;
  name: string;
}

export interface StockType {
  stockTypeId: number;
  name: string;
}

export interface StockChangeType {
  id: number;
  name: string;
}

export interface StageType {
  id: number;
  name: string;
}

export interface RelationshipType {
  relationshipId: number;
  name: string;
}

export interface QuorumType {
  id?: number; // 본계정일 경우 1: "팀", 2: "본부", 3: "경영위"
  name?: string; // 본계정일 경우 "팀","본부","경영위"
  number?: number; // 정족수
}

export interface FundManager {
  fundManageUserId?: number;
  acFundAccount?: {
    acFundAccountId?: number;
    nameKo?: string;
  };
  acUser?: FundManagerAcUser;
}

export interface FundManagerAcUser {
  acUserId?: number;
  name?: string;
}

export interface ExpertInfoProps {
  isRequestExpert?: boolean;
  expertSubmitComplete?: boolean;
  expertTitle?: string;
  expertEmail?: string;
  expertOpinion?: ExpertOpinionProps;
  exportLink?: string;
}

export interface ExpertOpinionProps {
  opinion?: string;
  reportFile?: ExpertFileProps[];
}

export interface ExpertFileProps {
  key: "serverFile" | "localFile";
  localFile?: File;
  serverFile?: FileType;
}

export interface InvestmentAssessment {
  investmentAssessmentId: number | string;
  portfolio: {
    portfolioId: number;
  };
  isTips: TIPS;
  isNext?: boolean;
  isConfirmed: boolean;
  expertInfo?: ExpertInfoProps;
  exportLink?: string;
  exportLinkHistory?: string[];
  isSubmissionComplete?: boolean;
  folderId?: string;
  businessFeasibilityReviewDocument?: string;
  participantMain?: string;
  participantMainStructureName?: string;
  participantMainTeamName?: string;
  participantSub?: string[];
  updatedAt?: string;
  contributionUsers?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: {
      excavator?: IAContribution[];
      judge?: IAContribution[];
      postManager?: IAContribution[];
    };
  };
  investmentAssessmentReportAccount: IAReportAccount[];
  investmentAssessmentCompanyInfo: IACompanyInfo;

  preTotalStockNumber?: number;
  postTotalStockNumber?: number;

  //지분 내역
  investmentAssessmentStakeStatement?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAStakeStatement[];
  };

  //지분 내역
  investmentAssessmentStake?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAStakeStatement[];
  };

  //주요 연혁
  investmentAssessmentCompanyHistory?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IACompanyHistory[];
  };

  //경영진 현황
  investmentAssessmentCompanyMember?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IACompanyMember[];
  };

  //임직원 현황
  investmentAssessmentKeyManagement?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAEmployee[];
  };

  //중요 재무 현황 1
  investmentAssessmentFinancialStatement1?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAFinancialStatements1;
  };
  //중요 재무 현황 2
  investmentAssessmentFinancialStatement2?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAFinancialStatements2[];
  };

  //차입금 현황
  investmentAssessmentDebt?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IADebt[];
  };

  //정부지원사업 수행현황
  investmentAssessmentGovernmentProject?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAGovernmentProject[];
  };

  //계약 현황
  investmentAssessmentContractStatus?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAContractStatus[];
  };

  //국내외 관련기업(경쟁사, 유사기업)현황
  investmentAssessmentSimilarBusiness?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IASimilarBusiness[];
  };

  //매출/인력계획
  investmentAssessmentSalesManpowerPlan?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IASalesManpowerPlan[];
  };

  //자금소요 계획 (단기 Y+2)
  investmentAssessmentCostEstimationPlan?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IACostEstimationPlan[];
  };
  //자금조달 계획 (단기 Y+2)
  investmentAssessmentFinancialSourcingPlan?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAFinancialSourcingPlan[];
  };

  //마일스톤별 주요 KPI (단기 Y+2)
  investmentAssessmentMilestoneKPI?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAMilestoneKPI[];
  };

  //지식재산권 보유 현황
  investmentAssessmentIprsProcess?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAIprsProcess[];
  };

  //자본금 증자 연혁
  investmentAssessmentCapitalIncreaseHistory?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IACapitalIncreaseHistory[];
  };
  //자금 조달 계획
  investmentAssessmentFinancingPlan?: {
    isRequired?: boolean;
    isChecked?: boolean;
    data: IAFinancingPlan[];
  };
}

//투자 조건
export interface IAAccount {
  accountName?: string; //계정명
  acFundAccount?: {
    //투자계정
    acFundAccountId: number; //투자계정 아이디
  };
  allocationWay?: string; //신주 배정 방식
  buyCompanyValue?: number; // 매입 시 기업가치
  buyCompany?: string; //매입처
  curNewIssuingPrice?: number; //최근 신주 발행가 - 구주 일 경우 입력
  discount?: number; //할인률
  faceValue?: number; //액면가
  investmentAmount?: number; // 본계정 : 당사 투자 금액 | 펀드 : 당 조합 투자 금액 *
  issuingPrice?: number; //신주 발행가
  oldStockPurchasePrice?: number; //구주 매입가
  isExistQuorum?: boolean; //정족수가 있었는가?
  initialIssuingPrice?: number; //기존 주당가격
  newOldStock?: NewOldStockType; // 신주/구주  *
  note?: string; //기타 조건
  postCompanyValue?: number; // 투자 후 기업가치
  previousValue?: number; //투자 전 기업가치 | 기업가치
  previousTotalStockNumber?: number; //투자 전 총 발행 주식수
  postTotalStockNumber?: number; //투자 후 총 발행 주식수
  stockType?: StockType; // 주식의 종류  *
  stockNumber?: number; //본계정 : 당사 배정 주식 수 | 펀드 : 당 조합 배정 주식 수 *
  shareholdingRatio?: number; //취득 지분율
  stage?: string; //스테이지
  totalInvestmentAmount?: number; //총 투자 금액
  quorumType?: QuorumType;
}

// 회사 개요
export interface IACompanyInfo {
  investmentAssessmentCompanyInfoId: string | number; //아이디
  industrialTech?: string[]; // 산업기술

  companyName?: string; //회사명 A B
  ceoName?: string; //대표이사 A B
  addr1?: string; //시,도 A B
  addr2?: string; //동 A B
  addr3?: string; //.. A B
  addrDetail?: string; //상세 주소 A B
  majorProduct?: string; //주요제품 A B

  tel?: string; //전화 번호 A B
  faxTel?: string; //팩스 번호 A B
  industryType?: string; //업종 A B
  industryCode?: string; //업종코드 A B

  corporationNumber?: string; //법인 번호 A B
  businessNumber?: string; //사업자 번호 A B
  establishmentDay?: string; //설립 일시 A B
  accountingFirm?: string; //회계 법인 A

  employeeNumber?: number; //종업원 수 A B
  settlementMonth?: number; //결산 월 A B
  capital?: number; //자본 금 A B
  exportAmount?: number; // 매출 액 A

  faceValue?: number; //액면가 A B
  previousTotalStockNumber?: number; //주식 수 A B
  totalAsset?: number; //자산 총계 A B

  previousValue?: number; //투자 전 기업가치
}

//투자심사 보고서
export interface IAReportAccount {
  investmentAssessmentReportAccountId: number | string; //아이디
  isAgreement?: boolean; // 찬성 반대 상태
  isCompleted: boolean; //작성 상태
  isPublished: boolean; //배포 상태
  investmentAssessmentInvite: IAReportUser[]; //초대자 리스트
  investmentAssessmentAccount: IAAccount;
  reportFileId?: string;
  reportFile?: FileType; // s3업로드 파일
  sendInviteCalendar?: boolean;
  assessmentDate?: string; //배포 날짜
  assessmentStartDate?: DateTime; //실제 투심 일시
  assessmentEndDate?: DateTime; //의사록 표기 일시
  investmentAssessmentContractDocument?: InvestmentAssessmentContractDocument[]; // 생성된 계약서 리스트
  requiredTables: TableFormType[]; // 선택된 account 필수 테이블

  requestFundManager?: boolean;
  fundManagerConfirm?: boolean;

  documentManagement?: DocumentManagement[];
}

export interface DocumentManagement {
  id: number;
  acFundAccount?: { acFundAccountId: number };
  docsFileId: string | null;
  documentType: { id: number };
  extension: string | null;
  name: string | null;
  object?: File;
  updatedAt: string;
  file: FileTypeRead;
}

export interface IAContribution {
  acUser?: { acUserId: number; name: string };
  contributionRatio?: number;
}

//지분 내역
export interface IAStakeStatement {
  stockholderName?: string; //주주명
  previousStockNumber?: number; //투자 전 주식 수
  previousFaceValue?: number; // 투자 전 액면가
  previousShareholdingPercentage?: number; //투자 전 지분율
  stockNumber?: number; //투자 후 주식 수
  faceValue?: number; // 투자 후 액면가
  shareholdingPercentage?: number; //투자 후 지분율
  note?: string; //비고
}

//주요연혁
export interface IACompanyHistory {
  date?: string; //일자
  contents?: string; //연혁
  note?: string; //비고
}

//경영진 현황
export interface IACompanyMember {
  name?: string; //이름
  position?: string; //역할
  birthYear?: string; //출생 연도
  careerOrEducation?: string; // 학력 및 경력
  fullTimeStatus?: string; //풀타임 여부
  currentStake?: number; //지분율
  relationship?: string; // 관계
  registrationStatus?: string; // 등기여부

  career?: string; //주요 경력
  education?: string; //학력
  age?: string; //나이
}

// 임직원 현황
export interface IAEmployee {
  name?: string; //성명
  position?: string; //직위
  birthYear?: string; //출생 연도
  careerOrEducation?: string; // 학력 및 경력
  fullTimeStatus?: string; //풀타임 여부
  currentStake?: number; //지분 현황
  relationship?: string; // 관계

  age?: string; //나이
  education?: string; //학력
  career?: string; //주요 경력
}

//주요 재무 현황 1
export interface IAFinancialStatements1 {
  year?: number; //기준연도
  quarterNum?: string; //분기
  totalAssets?: number; //자산총계
  totalLiabilities?: number; //부채총계
  totalOwnershipInterest?: number; // 자본총계
  liabilitiesRates?: number; // 부채비율
  salesAmount?: number; // 매출액
  salesTotalProfit?: number; // 매출 총 이익
  netProfitLoss?: number; // 당기순익
  netProfitLossRatio?: number; //순이익율
}
//주요 재무 현황 2
export interface IAFinancialStatements2 {
  year?: number; //기준연도
  quarterNum?: number; //분기
  salesAmount?: number; // 매출액
  operatingProfitLoss?: number; //영업이익(손실)
  netProfitLoss?: number; // 당기 순익 = 당기순이익(손실)
  totalAssets?: number; // 자산총계
  totalLiabilities?: number; // 부채총계
  totalOwnershipInterest?: number; // 자본총계
  capital?: number; // 자본금
}

//차입금 현황
export interface IADebt {
  lender?: string; //기관
  lenderType?: string; //차입 종류
  interestRate?: number; //이자율 (%)
  amount?: number; //차입 금액 (백만 원)
  dateBorrowed?: string; // 차입 일자
  expirationDate?: string; // 만기 일자
  note?: string; //비고

  repaymentPlan?: string; //연도별 상환예정
}

//정부지원사업 수행현황
export interface IAGovernmentProject {
  businessName?: string; //사업명
  organizationName?: string; //담당 기관
  taskName?: string; //과제명
  governmentDonation?: number; //정부출연금
  supportPeriod?: string; //지원기간
  startDate?: string; //시작년월
}

//계약현황
export interface IAContractStatus {
  date?: string; //계약 일자
  contents?: string; // 내용
  contractAmount?: number; // 계약 금액 (백만 원)
  contractor?: string; //계약 당사자
}

//국내외 관련기업(경쟁사, 유사사업) 현황
export interface IASimilarBusiness {
  type?: "domestic" | "international"; // 국내 / 해외
  compnayName?: string; // 회사명
  businessSummary?: string; //사업요약
  coreCustomers?: string; // 핵심고객 정의
  clientCompany?: string; // 고객사
  salesAmount?: number; // 매출액
  cumulativeInvestment?: number; // 누적투자
  majorInvestor?: string; // 주요 투자자
}

//매출/인력계획
export interface IASalesManpowerPlan {
  year?: number; //기준연도
  quarterNum?: string; //분기
  manpowerPlanning?: number; // 인력계획
  salesAmount?: number; // 매출액
  salesTotalProfit?: number; // 매출 이익
  sellingGeneralAdministativeExpenses?: number; // 판관비
  operatingProfitLoss?: number; //영업이익(손실)
}

//자금소요 계획(단기 Y+2)
export interface IAFundsRequiredPlan {
  requiredFundsContents?: string;
  requiredFundsPrice?: number;
  procurementPlan?: string;
  procurementPrice?: number;
}

//자금소요 계획(단기 Y+2)
export interface IACostEstimationPlan {
  costEstimationContents?: string;
  costEstimationPrice?: number;
}
//자금조달 계획(단기 Y+2)
export interface IAFinancialSourcingPlan {
  financialSourcingPlan?: string;
  financialSourcingPrice?: number;
}

//마일스톤별 주요 KPI (단기 Y+2)
export interface IAMilestoneKPI {
  kpiStartDate?: string; //kpi 시작 월
  kpiEndDate?: string; //kpi 끝 월
  investmentStage?: string; // 투자 단계
  costEstimationAmount?: number; //
  peopleNum?: number;
  mileston?: string;
  risk?: string;
  kpi?: string;

  date?: string;
  activitiesAndRisks?: string;
}

//출원 및 등록 현황
export interface IAIprsProcess {
  applicationNumber?: string; //출원번호
  applicationDate?: string; //출원일
  registrationNumber?: string; //특허번호/등록번호
  registrationDate?: string; //등록일
  title?: string; //발명의 명칭/대상이 되는 물품
  applicant?: string; //특허권자/상표권자 등
  iprsContents?: string; //지식 재산권 내용 A (안씀)
}

//자본금 증자 연혁
export interface IACapitalIncreaseHistory {
  date?: string; //일자
  issuanceType?: string; //발행형태
  detailContents?: string; //세부내역
  amount?: number; //주식수 (주)
  issuingPrice?: number; //발행가액 (원)
  capital?: number; //증자 후 자본금 (백만 원)
  note?: string; //비고

  cause?: string; //원인
}

//자금 조달 계획
export interface IAFinancingPlan {
  year?: number; // 연도
  halfType?: string; //상반기/하반기
  capitalIncreaseType?: string; // 자금 조달 방법
  capitalIncreaseAmount?: number; // 금액 (억 원)
  note?: string; // 비고 (옵션)

  date?: string; //시기
}

// 초대자
export interface IAReportUser {
  acUser: {
    acUserId: number;
    name: string;
    isShowProfile: boolean;
    email: string;
    profileImgUrl?: string;
  };
  isFundManager?: boolean;
  isAgreement?: boolean;
  comment?: string;
  signatureTmp?: {
    type: number;
    url?: string;
    path?: any[];
  };

  priorityInfo?: {
    priorityType: number; //1: 대펀, 2: 전문투심의원, 3: 투심의원
    priorityCount: number;
  };
}

export interface Participants {
  acUser: {
    acUserId: number;
  };
  isActive: boolean;
  pfParticipantId: number;
  screenRole: { screenRoleId: number; name: string };
}

export interface DataBaseSearchParam {
  pageIndex: number;
  dataPerPage: number;

  //메인 검색
  searchWord?: string;
  acIndustrialTechId?: string;
  acUserId?: string;
  acFundAccountId?: string;
}

export interface DBSPTotalSearch extends DataBaseSearchParam {
  //메인 검색
  acChannelId?: string;
  pfPhaseId?: string;
}
export interface DBSPAccelerating extends DataBaseSearchParam {
  //상세 검색
  roundId?: string; //스테이지
  addr1?: string; //소재지
  minInvestmentYear?: string; //최소 빈티지 연도
  maxInvestmentYear?: string; // 최대 빈티지 연도
  minInvestmentQuarter?: string; //최소 빈티지 분기
  maxInvestmentQuarter?: string; // 최대 빈티지 분기
  minPurchasePrincipal?: string; // 최소 투자금
  maxPurchasePrincipal?: string; // 최대 투자금
  minCompanyValue?: string; // 최소 기업가치
  maxCompanyValue?: string; // 최대 기업가치
  minSalesAmount?: string; // 최소 매출
  maxSalesAmount?: string; // 최대 매출
  minOwnershipPercentage?: string; // 최소 지분율
  maxOwnershipPercentage?: string; // 최대 지분율
  minRealizedMultiple?: string; // 최소 실현 멀티플
  maxRealizedMultiple?: string; // 최대 실현 멀티플
  minUnrealizedMultiple?: string; // 최소 미실현 멀티플
  maxUnrealizedMultiple?: string; // 최대 미실현 멀티플
  sortItem?: string; // 정렬 컬럼
  sortType?: string; // "asc", "desc"
}

export interface DBSPAlumni extends DataBaseSearchParam {
  //상세 검색
  roundId?: string; //스테이지
  addr1?: string; //소재지
  minInvestmentYear?: string; //최소 빈티지 연도
  maxInvestmentYear?: string; // 최대 빈티지 연도
  minInvestmentQuarter?: string; //최소 빈티지 분기
  maxInvestmentQuarter?: string; // 최대 빈티지 분기
  minPurchasePrincipal?: string; // 최소 투자금
  maxPurchasePrincipal?: string; // 최대 투자금
  minSellPrincipal?: string; //최소 회수금
  maxSellPrincipal?: string; //최대 회수금
  minRealizedMultiple?: string; // 최소 실현 멀티플
  maxRealizedMultiple?: string; // 최대 실현 멀티플
  minExitDate?: string; //최소 엑싯 날짜
  maxExitDate?: string; //최대 엑식 날짜
  sortItem?: string; // 정렬 컬럼
  sortType?: string; // "asc", "desc"
}

export interface DBSPDamaged extends DataBaseSearchParam {
  //상세 검색
  roundId?: string; // 스테이지
  addr1?: string; // 소재지
  minInvestmentYear?: string; //최소 빈티지 연도
  maxInvestmentYear?: string; // 최대 빈티지 연도
  minInvestmentQuarter?: string; //최소 빈티지 분기
  maxInvestmentQuarter?: string; // 최대 빈티지 분기
  minPurchasePrincipal?: string; // 최소 투자금
  maxPurchasePrincipal?: string; // 최대 투자금
  periodType?: "all" | "damage" | "drop"; // 기간 타입
  minPeriod?: string; // 최소 기간
  maxPeriod?: string; // 최대 기간
}

export interface DataBaseBaseParam {
  pageIndex: number;
  dataPerPage: number;

  //메인 검색
  searchWord?: string;
  acIndustrialTech?: IndustryTech[];
  acUser?: UserInfo[];
  acFundAccount?: FundAccountType[];
}
export interface DBBPTotalSearch extends DataBaseBaseParam {
  //메인 검색
  acChannel?: Channel[];
  pfPhase?: PortfolioPhaseProps[];
}

export interface DBBPAccelerating extends DataBaseBaseParam {
  //상세 검색
  round?: RoundProps[]; //스테이지
  addr1?: string[]; //소재지
  minInvestmentYear?: number; //최소 빈티지 연도
  maxInvestmentYear?: number; // 최대 빈티지 연도
  minInvestmentQuarter?: number; //최소 빈티지 분기
  maxInvestmentQuarter?: number; // 최대 빈티지 분기
  minPurchasePrincipal?: number; // 최소 투자금
  maxPurchasePrincipal?: number; // 최대 투자금
  minCompanyValue?: number; // 최소 기업가치
  maxCompanyValue?: number; // 최대 기업가치
  minSalesAmount?: number; // 최소 매출
  maxSalesAmount?: number; // 최대 매출
  minOwnershipPercentage?: number; // 최소 지분율
  maxOwnershipPercentage?: number; // 최대 지분율
  minRealizedMultiple?: number; // 최소 실현 멀티플
  maxRealizedMultiple?: number; // 최대 실현 멀티플
  minUnrealizedMultiple?: number; // 최소 미실현 멀티플
  maxUnrealizedMultiple?: number; // 최대 미실현 멀티플
  sortItem?: string; // 정렬 컬럼
  sortType?: string; // "asc", "desc"
}

export interface DBBPAlumni extends DataBaseBaseParam {
  //상세 검색
  round?: RoundProps[]; //스테이지
  addr1?: string[]; //소재지
  minInvestmentYear?: number; //최소 빈티지 연도
  maxInvestmentYear?: number; // 최대 빈티지 연도
  minInvestmentQuarter?: number; //최소 빈티지 분기
  maxInvestmentQuarter?: number; // 최대 빈티지 분기
  minPurchasePrincipal?: number; // 최소 투자금
  maxPurchasePrincipal?: number; // 최대 투자금
  minSellPrincipal?: number; //최소 회수금
  maxSellPrincipal?: number; //최대 회수금
  minRealizedMultiple?: number; // 최소 실현 멀티플
  maxRealizedMultiple?: number; // 최대 실현 멀티플
  minExitDate?: string; //최소 엑싯 날짜
  maxExitDate?: string; //최대 엑식 날짜
  sortItem?: string; // 정렬 컬럼
  sortType?: string; // "asc", "desc"
}

export interface DBBPDamaged extends DataBaseBaseParam {
  //상세 검색
  round?: RoundProps[]; // 스테이지
  addr1?: string[]; // 소재지
  minInvestmentYear?: number; //최소 빈티지 연도
  maxInvestmentYear?: number; // 최대 빈티지 연도
  minInvestmentQuarter?: number; //최소 빈티지 분기
  maxInvestmentQuarter?: number; // 최대 빈티지 분기
  minPurchasePrincipal?: number; // 최소 투자금
  maxPurchasePrincipal?: number; // 최대 투자금
  periodType?: "all" | "damage" | "drop"; // 기간 타입
  minPeriod?: string; // 최소 기간
  maxPeriod?: string; // 최대 기간
}

/**
 * 데이타베이스 포트폴리오리스트 기본 타입
 */
export interface DatabasePortfolioBaseProp {
  totalCount: number;
  totalPage: number;
  pageIndex: number;
  dataPerPage: number;
}

/**
 * 데이타베이스 포트폴리오리스트 통합검색 타입
 */
export interface DPBPTotalSearch extends DatabasePortfolioBaseProp {
  portfolio: DBTotalSearchPortfolio[];
}
export interface DBTotalSearchPortfolio {
  portfolioId: number;
  companyName: string;
  addr1?: string;
  description: string;
  establishmentDay?: string;
  excavationDate?: string | null;
  pfPhase: PortfolioPhaseProps;
  phase?: DealFlowPhase;
  pfIndustrialTech: {
    pfIndustrialTechId: number;
    acIndustrialTech: IndustryTech;
  }[];
  pfParticipant?: {
    pfParticipantId: number;
    acUser: {
      acUserId: number;
      name: string;
    };
  }[];
  pfKeyword?: KeywordType[];
  acChannel: Channel;
  excavator?: {
    acUserId: number;
    name: string;
  } | null;
}

/**
 * 데이타베이스 포트폴리오리스트 관리 타입
 */
export interface DPBPAccelerating extends DatabasePortfolioBaseProp {
  portfolio: DBAcceleratingPortfolio[];
}
export interface DBAcceleratingPortfolio {
  portfolioId: number;
  companyName: string;
  addr1: string;
  description: string;
  pfPhase: PortfolioPhaseProps;
  pfIndustrialTech: {
    pfIndustrialTechId: number;
    acIndustrialTech: IndustryTech;
  }[];
  pfParticipant: {
    pfParticipantId: number;
    acUser: {
      acUserId: number;
      name: string;
    };
  }[];
  round: RoundProps;
  acFundAccount: {
    acFundAccountId: number;
    nameKo: string;
    nickName?: string;
    serialNumber: string;
  }[];
  pfKeyword?: KeywordType[];
  investmentDate: string; //투자일(빈티지)
  purchasePrincipal: number; //투자금
  companyValue: number; // 기업가치
  ownershipPercentage: number; // 지분율
  realizedMultiple: number; // 실현 멀티플
  unrealizedMultiple: number; // 미실현 멀티플
  salesAmount: number; // 매출
  sellPrincipal: number; // 회수원금
}
/**
 * 데이타베이스 포트폴리오리스트 Alumni 타입
 */
export interface DPBPAlumni extends DatabasePortfolioBaseProp {
  portfolio: DBAlumniPortfolio[];
}
export interface DBAlumniPortfolio {
  portfolioId: number;
  companyName: string;
  addr1: string;
  description: string;
  pfPhase: PortfolioPhaseProps;
  pfIndustrialTech: {
    pfIndustrialTechId: number;
    acIndustrialTech: IndustryTech;
  }[];
  pfParticipant: {
    pfParticipantId: number;
    acUser: {
      acUserId: number;
      name: string;
    };
  }[];
  round: RoundProps;
  acFundAccount: {
    acFundAccountId: number;
    nameKo: string;
    nickName?: string;
    serialNumber: string;
  }[];
  pfKeyword?: KeywordType[];
  investmentDate: string; // 투자일(빈티지)
  purchasePrincipal: number; // 투자금
  sellPrincipal: number; // 회수 원금
  companyValue: number; // 기업가치
  ownershipPercentage: number; // 지분율
  realizedMultiple: number; // 실현 멀티플
  unrealizedMultiple: number; // 미실현 멀티플
  salesAmount: number; // 매출
  exitDate: string; // 엑싯 날짜
}
/**
 * 데이타베이스 포트폴리오리스트 손상/드롭 타입
 */
export interface DPBPDamaged extends DatabasePortfolioBaseProp {
  portfolio: DBDamagedPortfolio[];
}
export interface DBDamagedPortfolio {
  portfolioId: number;
  pfPhase: PortfolioPhaseProps;
  companyName: string;
  addr1: string | null;
  description: string;
  pfKeyword?: KeywordType[];
  pfIndustrialTech: {
    pfIndustrialTechId: number;
    acIndustrialTech: IndustryTech;
  }[];
  pfParticipant?: {
    pfParticipantId: number;
    acUser: {
      acUserId: number;
      name: string;
    };
  }[];
  round: RoundProps;
  acFundAccount?: {
    acFundAccountId: number;
    nameKo: string;
    serialNumber: string;
  }[];
  investmentDate?: string | null; // 투자일(빈티지)
  purchasePrincipal?: number; // 투자금
  sellPrincipal?: number; // 회수 원금
  companyValue?: number; // 기업가치
  ownershipPercentage?: number; // 지분율
  realizedMultiple?: number; // 실현 멀티플
  unrealizedMultiple?: number; // 미실현 멀티플
  salesAmount?: number; // 매출
  droppedAt: string; // 손상/폐업, 드랍 날짜
}

//블루카드 정보
export interface BlueCardInfo {
  portfolioId: number;
  companyName: string;

  ceoName: string;
  companyAddressAddr1: string;
  homepageUrl?: string | null;
  companyImgUrl?: string | null;
  companyImgKey?: string | null;
  thumbnailImgUrl?: string | null;
  thumbnailImgKey?: string | null;

  establishmentDay: string;
  industrialTechText: string;

  itemSurmmary: string;
  companyDescription: string;

  investmentHighlight?: string;
  problem?: string;
  solutionBusinessModel?: string;
  technology?: string;
  productStatus?: string;

  userEmail: string;
  userName: string;
  userPhone: string;

  investmentAmount: number;
  companyValue: number;

  updatedAt?: string;
}

//대시보드
export interface DashboardDataType {
  cumulativeData: CumulativeDataType;
  acFundAccount: {
    id: number;
    name: string;
  }[];
  acTeam: {
    id: number;
    name: string;
  }[];
  charts: ChartData;
}

export interface CumulativeDataType {
  excavation: {
    accumulate: number;
    increase: number;
  };
  investorRelations: {
    accumulate: number;
    increase: number;
  };
  investment: {
    accumulate: number;
    increase: number;
  };
  hitRatio: number;
  accumlateTips: number;
}

export interface ChartData {
  investmentCompareToIR: {
    //총 IR 건수 대비 투자 건수
    date: string | string[]; // label
    irNumber: number; // x
    investmentNumber: number; // y
  }[];
  investmentCaseByIndustrialTech: {
    //총 산업기술별 투자 비중(건)
    industrialTechName: string; // 산업기술 이름
    investmentCaseNumber: number; // 산업기술 누적 건 수
    investmentCaseRatio: number; // 산업기술 누적 건 비율
  }[];
  investmentAmountByIndustrialTech: {
    //총 산업기술별 투자 비중(금액)
    industrialTechName: string; // 산업기술 이름
    investmentAmount: number; // 산업기술 누적 금액
    investmentAmountRatio: number; //산업기술 누적 금액 비율
  }[];
  avgStakeRateByIndustrialTech: {
    industrialTechName: string; // 산업기술 이름
    stakeRate: number; // 평균 보유 지분율
  }[];
  avgRealizedMultipleByIndustrialTech: {
    industrialTechName: string; // 산업기술 이름
    multiple: number; // 평균 멀티플
  }[];
  avgUnrealizedMultipleByIndustrialTech: {
    industrialTechName: string; // 산업기술 이름
    multiple: number; // 평균 멀티플
  }[];
  followUpInvestmentByIndustrialTech: {
    // 누적 후속 투자 금액
    industrialTechName: string; // 산업기술 이름
    followUpCaseNumber: number; // 후속투자 건 수
    followUpAmount: number; // 누적 후속투자 금액
  }[];
  exitInvestmentByIndustrialTech: {
    // 누적 회수 금액
    industrialTechName: string; // 산업기술 이름
    exitCaseNumber: number; // 회수 건 수
    exitAmount: number; // 누적 회수 금액
  }[];
  companyValueRank: {
    // 기업가치 랭킹
    companyName: string; // 기업 이름
    companyValue: number; // 기업 가치
    investmentAmount: number; // 투자 원금
    stockRatio: number; // 지분율
  }[];
  vintageRank: {
    // 빈티지 랭킹
    year: string; // 연도
    attractAmount: number; // 유치 금액
    attractCompanyCount: number; // 유치 기업 수
  }[];
}

export interface YearQuarter {
  year: number;
  quarterNum: number[];
}

export interface YearHalfBox {
  year: number;
  half: string[];
}

export interface RiskResult {
  acSurveyPageId: number;
  acSurveyQuestion: AcSurveyQuestion[];
}

export interface AcSurveyQuestion {
  acRiskType: AcRiskType;
  acSurveyQuestionId: number;
  content: string;
}

export interface AcRiskType {
  acRiskTypeId: number;
  name: string;
}

export interface StockHolder {
  country: string;
  faceValue: number;
  id: number;
  name: string;
  percentage: string;
  registrationNumber: string;
  relationship: { relationshipId: number; name: string };
  stockNumber: number;
  stockType: { stockTypeId: number; name: string };
}

export interface PfFinancialStatement {
  year?: number | null;
  quarterNum?: number | null;
  pfFinancialStatementId?: number | null; //재무제표 아이디
  currentAssets?: number | null; //유동자산
  cashCashableAssets?: number | null; //현금 및 현금성 자산
  nonCurrentAssets?: number | null; //비유동자산
  totalAssets?: number | null; //자산총계
  currentLiabilities?: number | null; //유동부채
  shortTermBorrowing?: number | null; //단기차입금
  nonCurrentLiabilities?: number | null; //비유동부채
  longTermBorrowing?: number | null; //장기차입금
  totalLiabilities?: number | null; //부채총계
  capital?: number | null; //자본금
  capitalSurplus?: number | null; //자본잉여금
  capitalAdjustment?: number | null; //자본조정
  accumulatedOtherComprehensiveProfits?: number | null; //기타포관손익누계액
  profitSurplus?: number | null; //이익잉여금
  totalCapital?: number | null; //자본총계
  totalLiabilityCapital?: number | null; //부채와 자본총계
}

export interface PfProfitLossStatement {
  year?: number | null;
  quarterNum?: number | null;
  pfProfitLossStatement?: number | null;
  salesAmount?: number | null; //매출액
  salesCost?: number | null; //매출원가
  grossProfitLossRevenue?: number | null; //매출총손익
  salesAdministrativeExpenses?: number | null; //판매비와 관리비
  operatingProfitLoss?: number | null; //영업손익
  nonOperatingRevenue?: number | null; //영업최수익
  nonOperatingExpenses?: number | null; //영업외비용
  profitLossBeforeTaxExpenses?: number | null; //법인세 비용 차감 전 손익
  corporateTaxExpenses?: number | null; //법인세비용
  netProfitLoss?: number | null; //당기순이익
}

export interface InvestmentDamagedInfo {
  investmentDamagedInfoId: number;
  lossAmount?: number;
  riskReasonDetail?: string;
  riskSolution?: string;
  signImgKey?: string;
}

export interface SignatureInfo {
  id?: number;
  type?: number; // 0: url, 1: path
  url?: string;
  path?: any[];
}

export interface PageHistory {
  dealFlow?: DealFlowHistory;
  accelerating?: AcceleratingHistory;
  reporting?: ReportingHistory;
  dashboard?: DashboardHistory;
  database?: DatabaseHistory;
  totalHistory?: TotalEvaluationHistory;
}

export interface NotificationHistory {
  myTask?: {
    order?: string;
  };
  noti?: {
    order?: number;
  };
}

export interface DealFlowHistory {
  search?: {
    keyword?: string; // 검색 키워드
    filter?: DealFlowFilterProps; // 필터
    sort?: string; // 정렬
    scrollTop?: number; // 스크롤 탑
  };
}

export interface AcceleratingHistory {
  search?: {
    keyword?: string; // 검색 키워드
    filter?: DealFlowFilterProps; // 필터
    sort?: string; // 정렬
    scrollTop?: number; // 스크롤 탑
  };
}

export interface ReportingHistory {
  search?: {
    year?: number; // 연도
    quarter?: number; // 분기
    keyword?: string; // 검색 키워드
    sortItem?: number;
    sortType?: number;
    scrollTop?: number;
  };
}

export interface TotalEvaluationHistory {
  search?: {
    year?: number;
    quarter?: number;
    riskIssue?: number[];
    riskStatus?: number[];
    structure?: number[];
    keyword?: string; // 검색 키워드
    scrollTop?: number; // 스크롤 탑
  };
}

export interface DashboardHistory {
  fundAccount?: { id?: number; name?: string }; // 투자계정 id
  team?: {
    acStructureId?: number; // undefined : 팀 전체
    name?: string;
    acUser?: { acUserId?: number; name?: string }[];
  }; // 팀 id, acStructureId
  user?: {
    acUserId?: number;
    name?: string;
  }; // 담당자 id, acUserId
}

export interface DatabaseHistory {
  tab?: number; // 0: 통합검색, 1: 관리, 2: Alumni, 3: 손상/폐업, 드롭
  search?: {
    keyword?: string;
    acIndustrialTechId?: IndustryTech[]; // industry
    pfPhase?: PortfolioPhaseProps[]; // phase
    acUser?: UserInfo[]; // participant
    acChannel?: Channel[]; // excavation channel

    acFundAccountId?: FundAccountType[]; // fund account
    roundId?: RoundProps[];
    addr1?: string[];
    minInvestmentYear?: number;
    maxInvestmentYear?: number;
    minInvestmentQuarter?: number;
    maxInvestmentQuarter?: number;
    minPurchasePrincipal?: number;
    maxPurchasePrincipal?: number;
    minCompanyValue?: number;
    maxCompanyValue?: number;
    minSalesAmount?: number;
    maxSalesAmount?: number;
    minOwnershipPercentage?: number;
    maxOwnershipPercentage?: number;
    minRealizedMultiple?: number;
    maxRealizedMultiple?: number;
    minUnrealizedMultiple?: number;
    maxUnrealizedMultiple?: number;

    minSellPrincipal?: number;
    maxSellPrincipal?: number;
    minExitDate?: string; // "2020-02-02"
    maxExitDate?: string;

    periodType?: "all" | "damage" | "drop" | "";
    minPeriod?: string;
    maxPeriod?: string;

    sortItem?: string; // 정렬 컬럼
    sortType?: "asc" | "desc" | ""; // 오름차순, 내림차순
  };
  page?: number; // 검색결과 페이지네이션
  detailOpen?: boolean; // 상세 검색 펼치기
}

export interface IrDocumentProps {
  pfIrProposalId?: number;
  fileName: string;
  type: 1 | 2; // 1 : 요약, 2: 상세
  fileType: string;
  s3Key?: string;
  googleDriveKey?: string;
  createdAt: string;
  updatedAt: string;
}

export interface IRProposal {
  pfIrProposalId: number;
  fileName: string;
  type: number;
  fileType: string;
  s3Key?: string;
  googleDriveKey?: string;
  createdAt: string;
  updatedAt: string;
}

export interface DocumentProps {
  investmentAssessmentId: number;
  assessmentDate: string;
  documentType: DocumentTypeProps[];
}
export interface DocumentTypeProps {
  id: number;
  name: string;
  type: string;
  createdAt: string;
  documentManagement?: {
    id: number;
    name: string | null;
    docsFileId: null | string;
    documentType: { id: number };
    extension: null | string;
    updatedAt: string;
    file: FileTypeRead;
  };
}

//개인정보 처리 방침 약관
export interface Term {
  id: number;
  target: number;
  type: "privacy_policy" | "personal_information";
  content: "";
  startDate?: string;
  createdAt?: string;
}

export interface SelectorProps {
  id: number;
  text: string;
  data: any;
}

export interface PfIndustrialTech {
  pfIndustrialTechId: number;
  isMain: boolean;
  acIndustrialTech: {
    acIndustrialTechId: number;
    name: string;
  };
}
export interface AcStructureListProps {
  team: AcStructureProps[];
  headquarter: AcStructureProps[];
}

export interface AcStructureProps {
  acStructureId: number;
  name: string;
  nickName: string;
  users: number[];
  acIndustrialTech: {
    acIndustrialTechId: number;
    name: string;
  }[];
}

export interface PortfolioTargetData {
  companyName: string;
  portfolioId: number;
  dealFlowId?: number;
}

export interface FundAccountLight {
  acFundAccountId: number;
  nameKo: string;
  nickName: string;
  serialNumber: string;
}

export interface XIRRDataProps {
  date: Date;
  type: 1 | 2; // 1: 투자 , 2: 회수
  originAmount?: number; // 원금
  leftAmount?: number; // 계산시 필요한 금액
  exitAmount?: number; // 회수 금
}

export interface CashDateFlowProps {
  cash: number;
  date: Date;
}

export interface ReportDocumentProps {
  pfReportDocumentId: number;
  documentFile: {
    key: string;
    fileName: string;
    fileType: string;
  };
}

export interface ResponseProps {
  success: boolean;
  message?: string;
  id?: number;
}

export const StakeHolderType = {
  INDIVIDUAL: 1,
  CORPORATION: 2,
  FUND: 3,
};
export const OrganizationType = {
  GP: 0,
  LP: 1,
  BUYER: 2,
};

type InvestmentOrganizationType = number;
type StakeHolderType = number;

export interface AcInvestmentOrganization {
  acInvestmentOrganizationId?: number;
  type?: InvestmentOrganizationType;
  isActive?: boolean;
  name?: string;
  managerName?: string;
  managerEmail?: string;
  managerPhone?: string;
  stakeHolder?: StakeHolderProps;

  isUsed?: boolean;
}

export interface StakeHolderProps {
  id: number;
  type: StakeHolderType;
  uniqueNumber: string;
  name?: string;
  gp?: StakeHolderProps;
}

export interface FinancialStatementFullProps {
  currentAssets?: number; // 유동자산
  cashAssets?: number; // 당좌자산 - 입력
  inventoryAssets?: number; // 재고자산 - 입력
  otherCurrentAssets?: number; // 기타 유동자산 - 입력
  nonCurrentAssets?: number; // 비유동자산
  investmentAssets?: number; // 투자자산 - 입력
  tangibleAssets?: number; // 유형자산 - 입력
  intangibleAssets?: number; // 무형자산 - 입력
  otherNonCurrentAssets?: number; // 기타 비유동자산 - 입력
  totalAssets?: number; // 자산총계

  totalLiabilities?: number; // 부채총계
  currentLiabilities?: number; // 유동부채 - 입력
  nonCurrentLiabilities?: number; // 비유동부채 - 입력
  totalEquity?: number; // 자본총계
  capital?: number; // 자본금 - 입력
  capitalSurplus?: number; // 자본잉여금 - 입력
  capitalAdjustment?: number; // 자본조정 - 입력;
  accumulatedOtherComprehensiveIncome?: number; // 기타포괄손익누계액 - 입력
  retainedEarnings?: number; // 이익잉여금 - 입력
  accumulatedDeficit?: number; // 결손금 - 입력
  totalLiabilitiesEquity?: number; // 부채와자본총계

  grossProfit?: number; // 매출이익/손실
  revenue?: number; // 매출액 - 입력
  costOfSales?: number; // 매출원가 - 입력
  operatingProfit?: number; // 영업이익/손실
  sellingGeneralAdministrativeExpenses?: number; // 판관비(판매비및일반관리비) - 입력
  profitBeforeTaxExpense?: number; // 법인세비용차감전순이익/손실
  nonOperatingIncome?: number; // 영업외수익 - 입력
  nonOperatingExpenses?: number; // 영업외비용 - 입력
  incomeTaxExpense?: number; // 법인세비용 - 입력
  netIncome?: number; // 당기순이익/손실
}
