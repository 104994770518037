import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useInput, { InputType } from "../../../../evaluation-detail/useInput";

interface Props {
  textType?: InputType;
  width?: number | string;
  className?: string;
  style?: React.CSSProperties;
  defaultValue?: string;
  isShowValidationMsg?: boolean;
  validationMsg?: string;
  placeholder?: string;
  isReadOnly?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  onEnterPress?: (value: string) => void;
  transfromPixel: (value: number) => number;
}
export const CustomInput: React.FC<Props> = (props) => {
  const {
    textType = "text",
    width = "100%",
    className,
    style,
    defaultValue,
    isShowValidationMsg,
    validationMsg,
    placeholder,
    isReadOnly,
    disabled,
    onChange,
    onBlur,
    onFocus,
    onEnterPress,
    transfromPixel,
  } = props;

  const [value, updateValue] = useState<string>(defaultValue || "");
  const [focussing, updateFocussing] = useState<boolean>(false);
  const [getValueByType] = useInput();

  useEffect(() => {
    updateValue(
      defaultValue !== undefined ? getValueByType(textType, defaultValue) : ""
    );
  }, [defaultValue]);

  return (
    <Container
      className={`${disabled ? "disabled" : ""} ${className}`}
      style={{ ...style, width }}
      transfromPixel={transfromPixel}
    >
      <InputContainer transfromPixel={transfromPixel}>
        <InputComponent
          className={`${isShowValidationMsg ? "show__msg" : ""} body-14-md`}
          readOnly={isReadOnly}
          disabled={isReadOnly}
          transfromPixel={transfromPixel}
          type="text"
          placeholder={placeholder}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onEnterPress && onEnterPress(value);
            }
          }}
          onChange={(e) => {
            const data = getValueByType(textType, e.target.value);
            console.log(data);
            updateValue(data);
            onChange && onChange(data);
          }}
          onFocus={(e) => {
            updateFocussing(true);
            onFocus && onFocus(value);
          }}
          onBlur={(e) => {
            updateFocussing(false);
            onBlur && onBlur(value);
          }}
          value={value}
        />
      </InputContainer>
      {validationMsg && isShowValidationMsg && (
        <span className="validation__msg body-14-md">{validationMsg}</span>
      )}
    </Container>
  );
};
const Container = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  .validation__msg {
    color: var(--red-500);
  }

  &.disabled {
    background-color: var(--gray-50);
  }
`;

const InputContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  position: relative;
  width: 100%;
  height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow: hidden;
`;
const InputComponent = styled.input<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: 100%;
  border: ${(props) => props.transfromPixel(1)}px solid var(--gray-300);
  border-radius: ${(props) => props.transfromPixel(4)}px;
  transition: 0.2s;
  padding: 6px 12px;
  background: var(--background-contents01);

  ::placeholder {
    color: var(--text-inactive);
  }

  :read-only {
    padding: 6px 0px;
    border: ${(props) => props.transfromPixel(1)}px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  :disabled {
    border: ${(props) => props.transfromPixel(1)}px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &.show__msg {
    border: ${(props) => props.transfromPixel(1)}px solid var(--red-500);
    animation: shake 200ms;
  }

  :focus {
    outline: none;
    border: ${(props) => props.transfromPixel(1)}px solid
      var(--focus-field-blue);

    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    border-color: var(--focus-field-blue);
  }

  @keyframes shake {
    25% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    50% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
    75% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    100% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;
