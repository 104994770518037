import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Header } from "../structure/Structure";
import NewNavigationLayout from "../../navigation/NewNavigationLayout";
import { DefaultButton } from "lattice_core";
import useFundAccount from "./hook/useFundAccount";
import { FundAccountCard } from "./component/FundAccountCard";
import ModalLayout from "../../../../components/modal/ModalLayout";
import { FundAccountDetail } from "./organism/FundAccountDetail";
import { InvestmentOrganizationModal } from "./organism/InvestmentOrganizationModal";
import { FundAccountCreateCard } from "./component/FundAccountCreateCard";

const FundAccount = () => {
  const [
    allUser,
    allFundAccount,
    allInvestmentOrganization,
    selectedFundAccount,
    showFundAccountDetailModal,
    showLPManageModal,
    isChange,
    onClickFundAccountCard,
    onClickFundAccountCreateCard,
    closeFundAccountDetailModal,
    onClickLPManage,
    closeLPManage,
    createInvestmentOrganization,
    modifyInvestmentOrganization,
    deleteInvestmentOrganization,
    isUsedOrganizationStatus,
    submitFundAccount,
    onSaveFundAccount,
    onClickDelete,
    onClickDeleteCancel,
  ] = useFundAccount();

  if (!allFundAccount) return null;

  return (
    <NewNavigationLayout>
      <Container>
        <Header>
          <span className="heading-20-sb">펀드 관리</span>
          <FlexRow style={{ gap: 10 }}>
            <DefaultButton
              className="heading-16-b"
              sizeType="xxsmall"
              buttonType="text"
              buttonColor={
                showLPManageModal ? "var(--primary-purple)" : "var(--text-03)"
              }
              onClick={() => {
                showLPManageModal ? closeLPManage() : onClickLPManage();
              }}
              text="LP관리"
            />

            <DefaultButton
              className="heading-16-b"
              sizeType="small"
              buttonType="filled"
              buttonColor="var(--primary-purple)"
              hoverColor="var(--purple-700)"
              disabled={!isChange}
              onClick={() => {
                // onUpdateStructure();
                // upsertFundAccount();
                onSaveFundAccount();
              }}
              text="현재 정보 저장"
            />
          </FlexRow>
        </Header>
        <FundAccountContainer>
          {allFundAccount.length === 0 ? (
            <EmptyAccountContainer className="heading-18-md">
              <div style={{ textAlign: "center" }}>
                운용중인 펀드가 없어요.
                <br />
                펀드를 생성해주세요.
              </div>
              <DefaultButton
                className="heading-16-b"
                sizeType="small"
                buttonType="filled"
                buttonColor="var(--primary-purple)"
                hoverColor="var(--purple-700)"
                onClick={() => {
                  onClickFundAccountCreateCard();
                }}
                text="펀드 생성하기"
              />
            </EmptyAccountContainer>
          ) : (
            <FundAccountListWrapper id="body__component">
              <FundAccountList>
                <FundAccountCreateCard
                  onClickCard={onClickFundAccountCreateCard}
                />
                {allFundAccount.map((item, index) => {
                  return (
                    <FundAccountCard
                      key={`fundAccount_${index}`}
                      fundAccount={item}
                      onClickCard={() => {
                        onClickFundAccountCard(item);
                      }}
                      onClickDelete={onClickDelete}
                      onClickDeleteCancel={onClickDeleteCancel}
                    />
                  );
                })}
              </FundAccountList>
            </FundAccountListWrapper>
          )}
          <RightModal className={showLPManageModal ? "open" : ""}>
            {
              <InvestmentOrganizationModal
                investmentOrganizations={allInvestmentOrganization || []}
                createInvestmentOrganization={createInvestmentOrganization}
                modifyInvestmentOrganization={modifyInvestmentOrganization}
                isUsedOrganizationStatus={isUsedOrganizationStatus}
                deleteInvestmentOrganization={deleteInvestmentOrganization}
              />
            }
          </RightModal>
        </FundAccountContainer>
        {showFundAccountDetailModal && (
          <ModalLayout
            isFullScreen
            isOpen={showFundAccountDetailModal}
            toggle={closeFundAccountDetailModal}
          >
            <FundAccountDetail
              allInvestmentOrganization={allInvestmentOrganization || []}
              allUser={allUser || []}
              allFundAccount={allFundAccount || []}
              selectedFundAccount={selectedFundAccount}
              close={closeFundAccountDetailModal}
              submit={submitFundAccount}
            />
          </ModalLayout>
        )}
      </Container>
    </NewNavigationLayout>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FundAccountContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 62px);
`;

const EmptyAccountContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 16px;

  color: var(--text-04);
`;

const FundAccountListWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 32px 0 32px 0;
  margin: 0 auto;
  gap: 32px;
  overflow: scroll;
`;

const FundAccountList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
`;

const RightModal = styled.div`
  position: absolute;
  right: -610px;
  bottom: 0;
  width: 600px;
  height: calc(100%);
  background-color: var(--white);
  box-shadow: -5px 0 5px -5px rgba(100, 103, 109, 0.1);
  transition: all 0.25s ease;
  &.open {
    right: 0;
  }
`;

export default FundAccount;
