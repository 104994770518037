import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Search } from "../../../../assets/image/search.svg";
import { ReactComponent as Close } from "../../../../assets/image/modal_close.svg";
import { ReactComponent as InitSearch } from "../../../../assets/image/search_init.svg";

import axios from "axios";
import { IndustryType } from "../../../../type/data";
import * as api from "../../../../api/api";
import { DefaultButton } from "lattice_core";
import { HighLightText } from "../../../../components/text/HighLightText";

interface DaumModalProps {
  setValue: (value: IndustryType) => void;
  toggle: () => void;
}
export const IndustryTypeModal: React.FC<DaumModalProps> = ({
  setValue,
  toggle,
}) => {
  const [industryType, updateIndustryType] = useState<IndustryType[]>([]);
  const [keyword, updateKeyword] = useState<string>("");
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const inputFocusRef = useRef<boolean>(false);
  const [searched, updateSearched] = useState<boolean>(false);
  const [selectedIndustryType, updateSelectedIndustryType] = useState<
    IndustryType | undefined
  >(undefined);

  const onSearchKeyword = async () => {
    try {
      const result = await axios.get(api.getIndustryCode(), {
        params: { name: keyword },
      });

      console.log(result);

      if (result.status === 200) {
        updateSearched(true);
        updateIndustryType(
          result.data.industryCode.map((item: any) => {
            return {
              industryType: item.name,
              industryCode: item.code,
              parents: item.parents,
            };
          })
        );
      }
    } catch (error) {}
  };

  const onSubmit = () => {
    if (!selectedIndustryType) return;

    setValue(selectedIndustryType);
    toggle();
  };

  const init = () => {
    updateSearched(false);
    updateIndustryType([]);
    updateKeyword("");
    updateSelectedIndustryType(undefined);
    onSearchKeyword();
  };

  const onKeyDown = (event: any) => {
    event.stopPropagation();
    if (event.key === "Enter" && !inputFocusRef.current) {
      document.getElementById("industry-type-modal-apply-button")?.click(); // disable되어 있으면 click 안 됨
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  useEffect(() => {
    init();
  }, []);

  return (
    <Container>
      <div className="industry__type__header">
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          업종 코드 조회
        </span>
        <Close
          className="close"
          onClick={() => {
            toggle();
          }}
        />
      </div>
      <div className="industry__type__body scroll__invisible">
        <div className={`search__container ${inputFocus ? "focused" : ""}`}>
          <Search
            className="search__control__menu__icon"
            onClick={() => {
              onSearchKeyword();
            }}
          />
          <input
            className="search__input font-regular-14"
            type="text"
            placeholder="업종명으로 검색하세요."
            value={keyword}
            onFocus={() => {
              setInputFocus(true);
              inputFocusRef.current = true;
            }}
            onBlur={() => {
              setInputFocus(false);
              inputFocusRef.current = false;
            }}
            onKeyPress={(e) => {
              console.log("KEY PRESS");

              if (e.key === "Enter") {
                onSearchKeyword();
              }
            }}
            onChange={(e) => updateKeyword(e.target.value)}
          />
          {keyword.length > 0 && (
            <InitSearch
              className=""
              onClick={() => {
                init();
              }}
            />
          )}
        </div>
        {searched && industryType.length === 0 && (
          <EmptyMsg className="font-regular-14">
            <div>
              <span style={{ color: "#2196F3" }}>'{keyword}'</span>에 대한 검색
              결과가 없습니다.
            </div>
            <div>업종명을 다시한번 확인해주세요.</div>
          </EmptyMsg>
        )}
        {industryType.length > 0 && (
          <IndustryTypeList>
            <div className="list__header body-14-rg">
              <div className="list__header__item code">업종코드</div>
              <div className="list__header__item industry__name">업종명</div>

              <div className="list__header__item industry__parents">
                상위 분류
              </div>
            </div>
            <div className="list__body">
              {industryType.map((item, index) => {
                return (
                  <IndustryTypeItem
                    key={index}
                    className={`font-regular-14 ${
                      selectedIndustryType === item ? "selected" : ""
                    }`}
                    onClick={() => {
                      updateSelectedIndustryType(item);
                    }}
                  >
                    <div className="list__item code">
                      <HighLightText className="" text={item.industryCode} />
                    </div>
                    <div className="list__item industry__name">
                      <HighLightText
                        className=""
                        text={item.industryType}
                        query={keyword}
                      />
                    </div>
                    <div className="list__item industry__parents">
                      <HighLightText
                        className=""
                        text={item.parents || ""}
                        query={keyword}
                      />
                    </div>
                  </IndustryTypeItem>
                );
              })}
            </div>
          </IndustryTypeList>
        )}
      </div>
      <div className="industry__type__footer">
        <div>
          <DefaultButton
            id="industry-type-modal-apply-button"
            buttonType="filled"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            className="heading-14-md"
            disabled={selectedIndustryType === undefined}
            sizeType="xsmall"
            text="완료"
            transfromPixel={(n) => n}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  width: 960px;
  height: 762px;
  display: flex;
  flex-direction: column;
  .industry__type__header {
    width: 100%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    .close {
      cursor: pointer;
    }
  }
  .industry__type__body {
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .search__container {
      width: 486px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      min-height: 40px;
      max-height: 40px;
      background-color: #fff;
      border-radius: 4px;
      padding: 0 12px;
      margin: 16px 24px;
      border: 1px solid var(--gray-300);
      overflow: hidden;

      &.focused {
        border: 1px solid var(--focus-field-blue);
      }

      .search__input {
        flex-grow: 1;
        padding: 0 10px;
        height: 100%;
        border: 0;

        :focus {
          outline: none;
        }
      }

      .search__control__menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 12px;

        cursor: pointer;
        .search__control__menu__icon {
          width: 16px;
          height: 16px;
        }
        .divider {
          width: 1px;
          height: 14px;
          margin-left: 8px;
          margin-right: 8px;
          background-color: #dcdcdc;
        }
      }
    }
    .search__result__name {
      width: 100%;
      padding: 24px 24px 12px 24px;
    }
  }
  .industry__type__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px;
    gap: 24px;
    height: 74px;
    max-height: 74px;
    min-height: 74px;
  }
`;

const EmptyMsg = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  gap: 12px;
`;

const IndustryTypeList = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 12px 24px;
  .code {
    width: 92px;
    min-width: 92px;
    max-width: 92px;
    text-align: left;

    border-bottom: 1px solid var(--divider-300);
    border-right: 1px solid var(--divider-300);
  }

  .industry__name {
    width: 282px;
    min-width: 282px;
    max-width: 282px;
    text-align: left;
    border-bottom: 1px solid var(--divider-300);
    border-right: 1px solid var(--divider-300);
  }

  .industry__parents {
    width: 538px;
    min-width: 538px;
    max-width: 538px;
    text-align: left;
    border-bottom: 1px solid var(--divider-300);
  }

  .list__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .list__header__item {
      height: 34px;
      min-height: 34px;
      max-height: 34px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 20px;
      background-color: var(--blue-50);
      color: var(--text-04);
    }
  }

  .list__body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
`;

const IndustryTypeItem = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .list__item {
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    color: var(--text-01);
  }

  &.selected {
    background-color: var(--blue-100);
    :hover {
      background-color: var(--blue-100);
    }
  }

  :hover {
    background-color: #e5f1fa;
  }
`;
