import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getAcceleratingPortfolio } from "../../../api/repository/portfolio/PortfolioRepository";
import {
  getTransformedId,
  registeEntryPointToPortfolio,
} from "../../../common/commonUtil";
import { UserInfo } from "../../../type/data";
import {
  getAcceleratingHistory,
  setAcceleratingHistory,
} from "../../../common/pageHistoryUtil";
import { getMyInfo } from "../../../common/commonApiData";
import {
  AcceleratingCountProps,
  AcceleratingFilterProps,
  AcceleratingPortfolioProps,
  AcceleratingStructureFilterProps,
} from "../interface/Accelerating.interface";

const useAccelerating = () => {
  const navigate = useNavigate();

  const rootRef = useRef<HTMLDivElement>(null);
  const initCompleted = useRef<boolean>(false);
  const myInfo = useRef<UserInfo | undefined>(undefined);
  const [portfolioList, updatePortfolioList] = useState<
    AcceleratingPortfolioProps[] | undefined
  >(undefined);

  const [filterList, updateFilterList] = useState<
    AcceleratingStructureFilterProps[] | undefined
  >(undefined);
  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [sortingItem, updateSortingItem] = useState<string>("최신순");
  const [filterItem, updateFilterItem] = useState<
    AcceleratingFilterProps | undefined
  >(undefined);

  const portfolioSort = (
    compareA: AcceleratingPortfolioProps,
    compareB: AcceleratingPortfolioProps
  ) => {
    switch (sortingItem) {
      case "오래된순":
        return compareText(
          compareA.firstPaymentDate,
          compareB.firstPaymentDate
        );
      default:
        return (
          compareText(compareA.firstPaymentDate, compareB.firstPaymentDate) * -1
        );
    }
  };

  const compareText = (a: string, b: string) => {
    if (!a || !b) return 0;

    var textA = a.toUpperCase();
    var textB = b.toUpperCase();
    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }
    return 0;
  };

  const compareNumber = (a: number, b: number) => {
    return a - b;
  };

  const goToPortfolio = (portfolioId: number) => {
    const to = `/portfolio/${getTransformedId(portfolioId)}/dashboard`;
    navigate(to);
  };

  const getCount = (): AcceleratingCountProps => {
    const filterPortfolio = getFilterPortfolio();
    if (filterPortfolio.length === 0)
      return {
        riskStatus0: 0,
        riskStatus1: 0,
        riskStatus2: 0,
        riskStatus3: 0,
        riskStatus4: 0,
        riskStatus5: 0,
        riskStatus6: 0,
        tipsExpected: 0,
        tipsFixed: 0,
        tipsNo: 0,
        total: 0,
      };

    const riskStatus0 = filterPortfolio.filter(
      (item) => item.riskStatus === null
    ).length;
    const riskStatus1 = filterPortfolio.filter(
      (item) => item.riskStatus && item.riskStatus.id === 1
    ).length;
    const riskStatus2 = filterPortfolio.filter(
      (item) => item.riskStatus && item.riskStatus.id === 2
    ).length;
    const riskStatus3 = filterPortfolio.filter(
      (item) => item.riskStatus && item.riskStatus.id === 3
    ).length;
    const riskStatus4 = filterPortfolio.filter(
      (item) => item.riskStatus && item.riskStatus.id === 4
    ).length;
    const riskStatus5 = filterPortfolio.filter(
      (item) => item.riskStatus && item.riskStatus.id === 5
    ).length;
    const riskStatus6 = filterPortfolio.filter(
      (item) => item.riskStatus && item.riskStatus.id === 6
    ).length;

    const tipsExpected = filterPortfolio.filter(
      (item) => item.isTips === "EXPECTED"
    ).length;

    const tipsFixed = filterPortfolio.filter(
      (item) => item.isTips === "FIXED"
    ).length;

    const tipsNo = filterPortfolio.filter(
      (item) => item.isTips === "NO"
    ).length;

    const total = filterPortfolio.length;

    return {
      riskStatus0: riskStatus0,
      riskStatus1: riskStatus1,
      riskStatus2: riskStatus2,
      riskStatus3: riskStatus3,
      riskStatus4: riskStatus4,
      riskStatus5: riskStatus5,
      riskStatus6: riskStatus6,
      tipsExpected: tipsExpected,
      tipsFixed: tipsFixed,
      tipsNo: tipsNo,
      total: total,
    };
  };

  const initFilter = () => {
    updateFilterItem(undefined);
    updateSortingItem("최신순");
  };

  const getFilterPortfolio = () => {
    if (!portfolioList) return [];

    const filterPortfolio = portfolioList
      .sort(portfolioSort)
      .filter((item) => {
        const particpantNameString = item.pfParticipant
          .map((user) => user.acUser.name)
          .join(",");
        return (
          (item.companyName &&
            item.companyName
              .toUpperCase()
              .includes(searchKeyword.toUpperCase())) ||
          (item.description &&
            item.description
              .toUpperCase()
              .includes(searchKeyword.toUpperCase())) ||
          (item.ceoName &&
            item.ceoName.toUpperCase().includes(searchKeyword.toUpperCase())) ||
          particpantNameString
            .toUpperCase()
            .includes(searchKeyword.toUpperCase())
        );
      })
      .filter((item) => {
        if (filterItem === undefined) return true;

        if (filterItem.type === "my_deal") {
          return item.pfParticipant
            .map((user) => user.acUser.acUserId)
            .includes(filterItem.id);
        }

        if (filterItem.type === "structure_deal") {
          const headquarter = item.pfParticipant.map(
            (user) => user.acUser.headquarter?.acStructureId
          );
          const team = item.pfParticipant.map(
            (user) => user.acUser.team?.acStructureId
          );
          return [...headquarter, ...team].includes(filterItem.id);
        }

        return true;
      });
    return filterPortfolio;
  };

  const isExistMyPortfolio = (
    list: AcceleratingPortfolioProps[],
    myid: number
  ) => {
    if (!list) return false;
    return (
      list.filter((item) => {
        return item.pfParticipant
          .map((user) => user.acUser.acUserId)
          .includes(myid);
      }).length > 0
    );
  };

  const init = async () => {
    const acceleratingHistory = getAcceleratingHistory();
    await registeEntryPointToPortfolio("accelerating");
    const result = await getAcceleratingPortfolio();

    myInfo.current = await getMyInfo();
    let existMyPortfolio = true;

    if (result) {
      updatePortfolioList(result.portfolioList);
      updateFilterList(result.filterList);
      if (myInfo.current) {
        existMyPortfolio = isExistMyPortfolio(
          result.portfolioList,
          parseInt(`${myInfo.current.id}`)
        );
      }
    }

    if (acceleratingHistory?.search) {
      console.log("history");
      acceleratingHistory.search?.keyword &&
        updateSearchKeyword(acceleratingHistory.search?.keyword);
      acceleratingHistory.search?.sort &&
        updateSortingItem(acceleratingHistory.search?.sort);
      acceleratingHistory.search?.filter &&
        updateFilterItem(acceleratingHistory.search?.filter);
    } else {
      updateSearchKeyword("");
      updateSortingItem("최신순");
      updateFilterItem(
        existMyPortfolio
          ? {
              id: parseInt(`${myInfo.current?.id || 0}`),
              type: "my_deal",
            }
          : undefined
      );
    }
    setTimeout(() => {
      initCompleted.current = true;
    }, 500);
  };

  const onScrollHandler = (e: any) => {
    setAcceleratingHistory({
      search: {
        scrollTop: e.target.scrollTop,
      },
    });
  };

  useEffect(() => {
    if (!initCompleted.current) return;
    setAcceleratingHistory({
      search: {
        sort: sortingItem,
      },
    });
  }, [sortingItem]);

  useEffect(() => {
    if (!initCompleted.current) return;
    setAcceleratingHistory({
      search: {
        filter: filterItem,
      },
    });
  }, [filterItem]);

  useEffect(() => {
    if (!initCompleted.current) return;
    setAcceleratingHistory({
      search: {
        keyword: searchKeyword,
      },
    });
  }, [searchKeyword]);

  useEffect(() => {
    const dealflowHistory = getAcceleratingHistory();
    if (
      rootRef.current &&
      dealflowHistory &&
      dealflowHistory.search &&
      dealflowHistory.search.scrollTop
    ) {
      rootRef.current.scrollTo({ top: dealflowHistory.search.scrollTop });
    }
  }, [portfolioList]);

  useEffect(() => {
    init();
    if (rootRef.current) {
      rootRef.current.addEventListener("scroll", onScrollHandler);
    }
    return () => {
      if (rootRef.current) {
        rootRef.current.removeEventListener("scroll", onScrollHandler);
      }
    };
  }, []);

  return [
    rootRef,
    initCompleted.current,
    myInfo.current,
    filterList,
    searchKeyword,
    sortingItem,
    filterItem,
    updateSearchKeyword,
    updateSortingItem,
    updateFilterItem,
    getFilterPortfolio,
    getCount,
    initFilter,
    goToPortfolio,
  ] as const;
};

export default useAccelerating;
