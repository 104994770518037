import React, { RefObject, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Radio } from "../../../../../assets/image/icon_radio.svg";
import { ReactComponent as Check } from "../../../../../assets/image/icon_check_new.svg";

import {
  ColumnType,
  OtherOpinion,
  QuestionItemType,
  QuestionType,
} from "../../../../portfolio/pre-pitching-detail/interface/PrePitchingDetail.interface";

import { PrePitchingTaskChangeType } from "../../interface/PrePitchingTask.interface";

interface Props {
  item: QuestionItemType;
  dataIndex: number;
  onChangeData: (
    type: PrePitchingTaskChangeType,
    dataIndex: number,
    data: any
  ) => void;
}

const ViewerChoise: React.FC<Props> = ({ item, dataIndex, onChangeData }) => {
  const [selectedIndex, updateSelectedIndex] = useState<number[]>([]);
  const [otherValue, updateOtherValue] = useState<OtherOpinion | undefined>(
    item.otherOpinion
  );

  const onClickRow = (index: number) => {
    if (item.type === "single") {
      updateSelectedIndex([index]);
      updateOtherValue({ opinion: "", isSelected: false });
      onChangeData(item.type, dataIndex, [index]);
      onChangeData("other", dataIndex, { opinion: "", isSelected: false });
    }

    if (item.type === "multiple") {
      const findIndex = selectedIndex.findIndex((item) => index === item);
      if (findIndex === -1) {
        const data = [...selectedIndex, index];
        updateSelectedIndex(data);
        onChangeData(item.type, dataIndex, data);
      } else {
        selectedIndex.splice(findIndex, 1);
        updateSelectedIndex([...selectedIndex]);
        onChangeData(item.type, dataIndex, [...selectedIndex]);
      }
    }
  };

  useEffect(() => {
    if (!otherValue) return;
  }, [otherValue]);

  useEffect(() => {
    if (item.column) {
      const selected: number[] = [];
      item.column.forEach((item, index) => {
        if (item.isSelected) {
          selected.push(index);
        }
      });
      updateSelectedIndex(selected);
    }
  }, []);

  const getChoiseComponents = (
    type: QuestionType,
    isSelected: boolean,
    columnIndex: number,
    item: ColumnType
  ) => {
    return (
      <RowItem
        className={`body-14-md ${
          isSelected
            ? type === "multiple"
              ? "check__selected"
              : type === "single"
              ? "radio__selected"
              : ""
            : ""
        }`}
        onClick={() => {
          console.log("ifneifneifjeifjeijfeifj");
          onClickRow(columnIndex);
        }}
      >
        {type === "single" ? (
          <Radio className={`icon`} />
        ) : (
          <Check className={`icon`} />
        )}
        {item.title}
      </RowItem>
    );
  };

  const getOtherComponents = (type: QuestionType, isSelected: boolean) => {
    return (
      <RowItem
        className={`body-14-md ${
          isSelected
            ? type === "multiple"
              ? "check__selected"
              : type === "single"
              ? "radio__selected"
              : ""
            : ""
        }`}
      >
        {type === "single" ? (
          <Radio className={`icon`} />
        ) : (
          <Check className={`icon`} />
        )}
        <OtherOpinionInput
          className="body-14-md"
          type="text"
          value={otherValue?.opinion}
          placeholder="기타"
          onChange={(e) => {
            const data = {
              opinion: e.target.value,
              isSelected: e.target.value.length > 0,
            };
            updateOtherValue(data);
            onChangeData("other", dataIndex, data);
            if (item.type === "single") {
              updateSelectedIndex([]);
              onChangeData(item.type, dataIndex, []);
            }
          }}
        />
      </RowItem>
    );
  };

  return (
    <Container>
      {item.column?.map((data, index) => {
        return getChoiseComponents(
          item.type,
          selectedIndex.includes(index),
          index,
          data
        );
      })}
      {item.hasOtherOpinion &&
        getOtherComponents(
          item.type,
          otherValue ? otherValue.opinion.length > 0 : false
        )}
    </Container>
  );
};

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 38px 28px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);
`;
const RowItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &.radio__selected {
    path {
      fill: var(--primary-blue);
    }
  }

  &.check__selected {
    path {
      stroke: var(--primary-blue);
      stroke-width: 2px;
    }
    rect {
      stroke: var(--primary-blue);
      stroke-width: 2px;
    }
  }

  .icon {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    margin-top: 2px;
  }
  .title {
    flex-grow: 1;
  }
`;

const OtherOpinionInput = styled.input`
  height: 22px;

  border: 0;

  cursor: pointer;
  :focus {
    outline: none;
    border-bottom: 1px solid var(--gray-300);
  }

  :hover {
    border-bottom: 1px solid var(--gray-300);
  }
`;
export default ViewerChoise;
