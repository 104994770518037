import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../SummaryBusinessPlanV2Download";

interface Props {
  isReadOnly: boolean;
  placeholder?: string;
  isShowWordCounter?: boolean;
  textClassName?: string;
  maxLength?: number;
  defaultData?: string;
  onLoaded: () => void;
}

export const AutoResizableTextarea: React.FC<Props> = (props) => {
  const {
    isReadOnly,
    placeholder,
    isShowWordCounter,
    textClassName = "body-14-rg",
    maxLength = 1000,
    defaultData,
    onLoaded,
  } = props;

  useEffect(() => {
    onLoaded();
  }, []);
  return (
    <Container convertPixelToCm={convertPixelToCm}>
      <pre
        className="caption-12-rg"
        style={{
          width: "100%",
          padding: "9px 0px",
          maxWidth: 584,
          wordBreak: "break-all",
          whiteSpace: "break-spaces",
          color:
            defaultData === undefined || defaultData.length === 0
              ? "var(--text-04)"
              : "var(--text-01)",
        }}
      >
        {defaultData || placeholder}
      </pre>
    </Container>
  );
};

const Container = styled.div<{ convertPixelToCm: (n: number) => number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
