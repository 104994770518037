import React from "react";
import { useMediaQuery } from "react-responsive";

import { MobileNavigationLayout } from "./mobile/MobileNavigationLayout";
import { DeskNavigationLayout } from "./desktop/DeskNavigationLayout";

interface Props {}
export const NavigationLayout: React.FC<Props> = ({ children }) => {
  // const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <>
      {isMobile ? (
        <MobileNavigationLayout>{children}</MobileNavigationLayout>
      ) : (
        <DeskNavigationLayout>{children}</DeskNavigationLayout>
      )}
    </>
  );
};
