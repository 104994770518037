import { API_KEY, checkLogin, FOLDER_ID } from "../login/GoogleLoginUtil";
import axios from "axios";
import * as api from "../../api/api";
import { IAReportAccount, PortfolioInfo } from "../../type/data";
import { saveAs } from "file-saver";
import config from "../../config";

export const TEMPLET_DOCUMENT_ID = config.gReportTemplet;

// 투심이 없을 때도 부를 수 있어야 함
export const getReplaceRequestWithoutIA = async (
  portfolioInfo: PortfolioInfo,
  accountInfo?: IAReportAccount
  // inviteList?: IAReportUser[],
  // document?: InvestmentAssessment
) => {
  const requestList = [];

  /* Term Sheet */
  const _company_name = {
    replaceAllText: {
      replaceText: portfolioInfo.companyName,
      containsText: {
        matchCase: true,
        text: "{{company_name}}",
      },
    },
  };

  const _ceo_name = {
    replaceAllText: {
      replaceText: portfolioInfo.ceoName,
      containsText: {
        matchCase: true,
        text: "{{ceo_name}}",
      },
    },
  };

  const _account_name = {
    replaceAllText: {
      replaceText: accountInfo?.investmentAssessmentAccount.accountName || "",
      containsText: {
        matchCase: true,
        text: "{{account_name}}",
      },
    },
  };

  requestList.push(_company_name, _ceo_name, _account_name);

  return requestList;
};

export const replaceFile = async (fileId: string, requests: any[]) => {
  try {
    const auth = await checkLogin();
    const accessToken = auth;

    const requestBody = {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      url: `https://docs.googleapis.com/v1/documents/${fileId}:batchUpdate?key=${API_KEY}`,
      body: {
        requests,
      },
    };

    const result = await axios.post("/common/cors", requestBody);
    return result;
  } catch (error) {}
};

//파일 삭제
export const removeFile = async (fileId: string) => {
  try {
    const auth = await checkLogin();
    const accessToken = auth;

    const requestBody = {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      url: `https://www.googleapis.com/drive/v3/files/${fileId}?key=${API_KEY}`,
      body: {},
    };

    const result = await axios.delete("/common/cors", { data: requestBody });
    return result;
  } catch (error) {}
};

//구글 드라이브 파일 다운로드
export const getDocumentFromDrive = async (fileId: string) => {
  try {
    const auth = await checkLogin();
    const accessToken = auth;

    const result = await axios.get(api.getDocsFile(), {
      params: {
        fileId: fileId,
      },
    });
    console.log(result);
    return result.data.response.data;
  } catch (error) {
    return false;
  }
};

//구글 드라이브 파일 다운로드 To PDF
export const exportFileToPDF = async (
  fileId?: string,
  exportFileName?: string,
  successCallback?: () => void,
  failCallback?: () => void
) => {
  const saveByteArray = (byte: any) => {
    const blob = new Blob([new Uint8Array([...byte]).buffer], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    saveAs(blob, exportFileName);
    successCallback && successCallback();
  };

  try {
    if (!fileId) return;
    const documentArrayBuffer = await getDocumentFromDrive(fileId);
    saveByteArray(documentArrayBuffer);
  } catch (error) {
    console.log(error);
    failCallback && failCallback();
  }
};

//구글 드라이브 타겟 폴더에 파일 복사
export const copyFileToTargetFolder = async (
  fileId: string,
  folderId: string,
  title: string
) => {
  try {
    const auth = await checkLogin();
    const accessToken = auth;
    const requestBody = {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      url: `https://www.googleapis.com/drive/v3/files/${fileId}/copy?key=${API_KEY}`,
      body: {
        name: title,
        parents: [folderId],
      },
    };
    const result = await axios.post("/common/cors", requestBody);
    if (result.data.success) {
      return { status: result.status, data: result.data.response.body };
    } else {
      return {
        status: result.data.response.body.error.code,
        msg: result.data.response.body.error.message,
      };
    }
  } catch (error) {}
};

//구글 드라이브 타겟 폴더에 파일 복사
export const deleteEmpty = async (fileId: string) => {
  try {
    const result = await axios.post("/common/google/file/deleteEmpty", {
      fileId: fileId,
    });

    return result.data.success;
  } catch (error) {}
};

// 구글 드라이브 폴더 생성
export const createFolderToServer = async (
  title: string,
  folderId?: string
) => {
  const _folderId = folderId || FOLDER_ID;

  const auth = await checkLogin();
  const accessToken = auth;
  const requestBody = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    url: `https://www.googleapis.com/drive/v3/files?key=${API_KEY}`,
    body: {
      mimeType: "application/vnd.google-apps.folder",
      name: title,
      parents: [_folderId],
    },
  };
  const result = await axios.post("/common/cors", requestBody);
  console.log(result);

  if (result.status === 200 && result.data.response.statusCode === 200) {
    return result.data.response.body;
  }
  return false;

  // return result;
};

// 구글 드라이브 파일 생성 - 래티스 서버 통함
export const createFileToServer = async (title: string, folderId?: string) => {
  const _folderId = folderId || FOLDER_ID;

  const auth = await checkLogin();
  const accessToken = auth;
  const requestBody = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    url: `https://www.googleapis.com/drive/v3/files?key=${API_KEY}`,
    body: {
      mimeType: "application/vnd.google-apps.document",
      name: title,
      parents: [_folderId],
    },
  };
  const result = await axios.post("/common/cors", requestBody);
  console.log(result);

  if (result.status === 200 && result.data.response.statusCode === 200) {
    return result.data.response.body;
  }
  return false;

  // return result;
};

// 구글 드라이브 파일명 변경 - 래티스 서버 통함
export const updateFileNameToServer = async (
  newFileName: string,
  fileId: string
) => {
  const result = await axios.patch("/common/google/file/name", {
    fileId: fileId,
    name: newFileName,
  });
  console.log(result);

  if (result.status === 200 && result.data.success) {
    return true;
  }
  return false;

  // return result;
};
