import React from "react";
import { useMediaQuery } from "react-responsive";

import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";
import { MobileInvestmentInfo } from "./mobile/MobileInvestmentInfo";
import { DeskInvestmentInfo } from "./desktop/DeskInvestmentInfo";

const InvestmentInfo: React.FC<PortfolioNavigationDefaultProps> = (props) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobileInvestmentInfo {...props} />
  ) : (
    <DeskInvestmentInfo {...props} />
  );
};
export default InvestmentInfo;
