import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../../assets/image/modal_close.svg";

import {
  IAReportUser,
  QuorumType,
  UserInfo,
} from "../../../../../../type/data";
import useResize from "../../../../../../2.0lattice/useResize";
import { getAllAcUser } from "../../../../../../api/repository/accelerator/AcceleratorRepository";

import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import { ChipsSelector } from "../../components/ChipsSelector";
import { DefaultButton } from "lattice_core";

interface Props {
  fundManagerModifiable: boolean;
  quorumType?: QuorumType;
  selectedUser?: IAReportUser[];
  close: () => void;
  setValue: (selectedUsers: IAReportUser[]) => void;
}

export const EvaluationInviteUserModal: React.FC<Props> = (props) => {
  const {
    fundManagerModifiable,
    quorumType,
    selectedUser = [],
    close,
    setValue,
  } = props;
  const isInit = useRef<boolean>(false);
  const [curPage, updatePage] = useState<number>(1);
  const [screenRate, transfromPixel] = useResize();
  const [loaded, updateLoaded] = useState<boolean>(false);

  const [allUser, updateAllUser] = useState<UserInfo[]>([]);
  const [fundManager, updateFundManager] = useState<IAReportUser[]>([]);
  const [selectedManager, updateSelectedManager] = useState<IAReportUser[]>([]);

  const init = async () => {
    try {
      const _allUser = (await getAllAcUser("user"))?.filter((item) => {
        return item.authorityId === 1;
      });

      isInit.current = false;
      if (_allUser) {
        updateAllUser(_allUser);

        const _fundManager = selectedUser.filter(
          (item) => item.priorityInfo?.priorityType === 1 || item.isFundManager
        );

        const _selectedManager = selectedUser.filter(
          (item) => item.priorityInfo?.priorityType !== 1 && !item.isFundManager
        );

        updateFundManager([..._fundManager]);
        updateSelectedManager([..._selectedManager]);

        updateLoaded(true);
      }
    } catch (error) {}
  };

  const validationCheckPage1 = () => {
    const selectedUserCount = fundManager.length + selectedManager.length;
    const quorumCount = quorumType?.number || 0;
    return fundManager.length !== 0 && selectedUserCount >= quorumCount;
  };

  const validationCheckPage2 = () => {
    const limitExpertCount =
      Math.floor((fundManager.length + selectedManager.length) / 2) - 1;
    const expertCount = selectedManager.filter(
      (item) => item.priorityInfo?.priorityType === 2
    ).length;

    return validationCheckPage1() && limitExpertCount >= expertCount;
  };

  const getSearchUser = (type: 1 | 2 | 3) => {
    let filterUser = allUser.map((item, index) => ({
      id: item.id,
      text: item.name,
      data: item,
    }));

    if (type !== 1) {
      const fund = fundManager.map((item) => item.acUser.name);
      filterUser = filterUser.filter((item) => !fund.includes(item.text));
    }

    if (type !== 3) {
      const normal = selectedManager.map((item) => item.acUser.name);
      filterUser = filterUser.filter((item) => !normal.includes(item.text));
    }

    return filterUser.map((item, index) => ({
      ...item,
      id: parseInt(`${item.id}`, 10),
    }));
  };

  const onClickSubmit = () => {
    setValue([...fundManager, ...selectedManager]);
    close();
  };

  const selectedExpertUser = (
    userId: number,
    type: "selectedManager" | "fundManager"
  ) => {
    if (type === "selectedManager") {
      onChangeSelectedManager(userId);
    } else {
      onChangeFundManager(userId);
    }
  };

  const onChangeSelectedManager = (userId: number) => {
    const targetUser = selectedManager.find(
      (item) => item.acUser.acUserId === userId
    );
    const limitExpertCount = Math.floor(
      (fundManager.length + selectedManager.length) / 2
    );

    const expertCount =
      selectedManager.filter((item) => item.priorityInfo?.priorityCount === 2)
        .length +
      fundManager.filter((item) => item.priorityInfo?.priorityCount === 2)
        .length;

    console.log(targetUser);

    console.log(limitExpertCount);
    console.log(expertCount);
    if (!targetUser) return;
    if (
      targetUser.priorityInfo?.priorityCount === 2 ||
      limitExpertCount > expertCount
    ) {
      const newData = selectedManager.map((item): any => {
        if (item.acUser.acUserId === userId) {
          return {
            ...item,
            priorityInfo: {
              priorityType: item.priorityInfo?.priorityCount === 2 ? 3 : 2,
              priorityCount: item.priorityInfo?.priorityCount === 2 ? 1 : 2,
            },
          };
        }
        return item;
      });
      updateSelectedManager([...newData]);
    }
  };

  const onChangeFundManager = (userId: number) => {
    const targetUser = fundManager.find(
      (item) => item.acUser.acUserId === userId
    );
    const limitExpertCount = Math.floor(
      (fundManager.length + selectedManager.length) / 2
    );

    const expertCount =
      selectedManager.filter((item) => item.priorityInfo?.priorityType === 2)
        .length +
      fundManager.filter((item) => item.priorityInfo?.priorityType === 2)
        .length;

    if (!targetUser) return;
    if (
      targetUser.priorityInfo?.priorityType === 1 ||
      limitExpertCount > expertCount
    ) {
      const newData = fundManager.map((item): any => {
        if (item.acUser.acUserId === userId) {
          return {
            ...item,
            priorityInfo: {
              priorityType: 1,
              priorityCount: item.priorityInfo?.priorityCount === 1 ? 2 : 1,
            },
          };
        }
        return item;
      });
      updateFundManager([...newData]);
    }
  };

  const getReportUser = (
    user: UserInfo,
    priorityType: number
  ): IAReportUser => {
    const priority = {
      priorityType: priorityType,
      priorityCount: [1, 2].includes(priorityType) ? 2 : 1,
    };
    return {
      acUser: {
        acUserId: parseInt(`${user.id}`),
        name: user.name,
        isShowProfile: user.isShowProfile,
        profileImgUrl: user.profileImgUrl,
        email: user.email || "",
      },
      priorityInfo: priority,
    };
  };

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.defaultPrevented) return;

    if (event.key === "Enter") {
      event.preventDefault();
      document
        .getElementById("select-user-with-GCalendar-apply-button")
        ?.click();
    }
  };
  useEffect(() => {
    window.addEventListener("keypress", onKeyPress);
    return () => {
      window.removeEventListener("keypress", onKeyPress);
    };
  }, []);

  useEffect(() => {
    init();
  }, []);

  const getQurumTypeName = () => {
    if (!quorumType) return "알수없는 값";

    if (quorumType.id === 1) return "팀투심";
    if (quorumType.id === 2) return "본부투심";
    if (quorumType.id === 3) return "경영위투심";

    return "당 조합투심";
  };
  if (!loaded) return null;

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          투자 심사 위원 {curPage}/2
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        <PageWrapper
          style={{
            transform: `translateX(${curPage === 1 ? "0px" : "-100%"})`,
          }}
        >
          <PageContainer style={{ gap: 16 }}>
            <div className="body-14-rg" style={{ color: "var(--text-03)" }}>
              해당 투자는 {getQurumTypeName()}으로 , 대표펀드매니저 포함{" "}
              <span style={{ textDecoration: "underline" }}>
                최소 {quorumType?.number}명
              </span>
              의 투자심사위원이 참석해야 합니다.
            </div>
            <RowTitleBox
              title="대표펀드 매니저"
              titleWidth={128}
              transfromPixel={transfromPixel}
            >
              <ChipsSelector
                type="single"
                searchList={getSearchUser(1)}
                disabled={!fundManagerModifiable}
                selectedData={
                  fundManager
                    ? [
                        ...fundManager.map((item) => ({
                          id: parseInt(`${item.acUser.acUserId}`, 10),
                          text: item.acUser.name,
                          data: item,
                        })),
                      ]
                    : []
                }
                onChangeUser={(users) => {
                  const usersId = users.map((item) => `${item.id}`);
                  updateFundManager([
                    ...allUser
                      .filter((item) => usersId.includes(`${item.id}`))
                      .map((item) => {
                        return getReportUser(item, 1);
                      }),
                  ]);
                }}
              />
            </RowTitleBox>
            <RowTitleBox
              title="투자심사위원"
              titleWidth={128}
              transfromPixel={transfromPixel}
            >
              <ChipsSelector
                searchList={getSearchUser(3)}
                selectedData={
                  selectedManager
                    ? [
                        ...selectedManager.map((item) => {
                          console.log(item);
                          return {
                            id: parseInt(`${item.acUser.acUserId}`, 10),
                            text: item.acUser.name,
                            data: item,
                          };
                        }),
                      ]
                    : []
                }
                onChangeUser={(users) => {
                  const usersId = users.map((item) => `${item.id}`);
                  updateSelectedManager([
                    ...allUser
                      .filter((item) => usersId.includes(`${item.id}`))
                      .map((item) => {
                        const target = selectedManager.find(
                          (user) => user.acUser.acUserId === item.id
                        );
                        return target ? target : getReportUser(item, 3);
                      }),
                  ]);
                }}
              />
            </RowTitleBox>
          </PageContainer>
          <PageContainer style={{ gap: 16 }}>
            <div className="body-14-rg">
              <div style={{ color: "var(--text-03)" }}>
                총 {fundManager.length + selectedManager.length}명이 참석할
                계획이므로,{" "}
                <span style={{ textDecoration: "underline" }}>
                  최대{" "}
                  {Math.floor(
                    (fundManager.length + selectedManager.length) / 2
                  )}
                  명
                </span>
                까지 전문투자심사위원으로 지정 가능합니다.
              </div>
              <div style={{ color: "var(--text-01)" }}>
                선택 가능한 전문투자심사위원 :{" "}
                {Math.floor((fundManager.length + selectedManager.length) / 2) -
                  fundManager.filter(
                    (item) => item.priorityInfo?.priorityCount === 2
                  ).length -
                  selectedManager.filter(
                    (item) => item.priorityInfo?.priorityCount === 2
                  ).length}
                명
              </div>
            </div>
            <RowTitleBox
              title="대표펀드 매니저"
              titleWidth={128}
              transfromPixel={transfromPixel}
            >
              <ChipListContainer>
                {fundManager.map((item) => {
                  return (
                    <UserChip
                      className={`caption-12-rg ${
                        item.priorityInfo &&
                        item.priorityInfo.priorityCount === 2
                          ? "expert"
                          : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectedExpertUser(item.acUser.acUserId, "fundManager");
                      }}
                    >
                      {item.acUser.name || ""}
                    </UserChip>
                  );
                })}
              </ChipListContainer>
            </RowTitleBox>
            <RowTitleBox
              title="투자심사위원"
              titleWidth={128}
              transfromPixel={transfromPixel}
            >
              <ChipListContainer>
                {selectedManager.map((item) => {
                  return (
                    <UserChip
                      className={`caption-12-rg ${
                        item.priorityInfo &&
                        item.priorityInfo.priorityCount === 2
                          ? "expert"
                          : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectedExpertUser(
                          item.acUser.acUserId,
                          "selectedManager"
                        );
                      }}
                    >
                      {item.acUser.name || ""}
                    </UserChip>
                  );
                })}
              </ChipListContainer>
            </RowTitleBox>
          </PageContainer>
        </PageWrapper>
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={curPage === 1 ? "취소" : "이전"}
            onClick={() => {
              if (curPage === 1) {
                close();
              } else {
                updatePage(1);
              }
            }}
            transfromPixel={transfromPixel}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            disabled={
              curPage === 1 ? !validationCheckPage1() : !validationCheckPage2()
            }
            text={curPage === 1 ? "다음" : "완료"}
            onClick={() => {
              if (curPage === 1) {
                updatePage(2);
              } else {
                onClickSubmit();
              }
            }}
            transfromPixel={transfromPixel}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 80vw;
  max-width: 640px;
  height: 70vh;
  max-height: 600px;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  gap: 36px;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease;
`;

const PageContainer = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 24px 0 24px;
`;

const ChipListContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 6px 12px;
  row-gap: 6px;
  column-gap: 8px;

  border-radius: 4px;
`;

const UserChip = styled.div`
  width: auto;
  height: 28px;
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  line-height: 1;

  background-color: var(--blue-100);
  border-radius: 14px;

  transition: all 0.2s ease;

  cursor: pointer;

  &.expert {
    background-color: var(--blue-300);
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
