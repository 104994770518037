import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as IconTooltip } from "../../assets/image/ico_tooltip.svg";
interface Props {
  width?: number;
  height?: number;
}
const Tooltip: React.FC<Props> = ({ width = 18, height = 18, children }) => {
  const [isShowTooltip, updateShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    if (isShowTooltip) {
      setTimeout(() => {
        updateShowTooltip(false);
      }, 3000);
    }
  }, [isShowTooltip]);
  return (
    <Container>
      <IconTooltip
        style={{ cursor: "pointer" }}
        width={width}
        height={height}
        onClick={() => updateShowTooltip((prev) => !prev)}
      />
      <div className={`tooltip__container ${isShowTooltip ? "show" : ""}`}>
        {children}
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .tooltip__container {
    position: absolute;
    top: calc(100% + 4px);
    transform: scale(0);
    transform-origin: top left;
    transition: all 0.2s ease-in;
  }

  .show {
    transform: scale(1);
  }
`;

export default Tooltip;
