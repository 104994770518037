import React, { CSSProperties, useEffect, useRef, useState } from "react";
import Styled, { css } from "styled-components";

interface Props {
  label?: string;
  showEmpty?: boolean;
  isRequired?: boolean;
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  validationMsg?: string;
  value?: string;
  min?: string;
  max?: string;
  editable: boolean;

  onChange?: (e: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const InputDate: React.FC<Props> = (props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(e.target.value);
  };

  return (
    <Container className={props.className || ""}>
      {props.label && (
        <div className={`font-medium-14`}>
          <span>{props.label}</span>
          {props.isRequired && (
            <span
              className="font-medium-14"
              style={{ color: "var(--red)", marginLeft: 8 }}
            >
              *
            </span>
          )}
        </div>
      )}
      <TextContainer className={`font-medium-14`} editable={props.editable}>
        {
          <input
            ref={inputRef}
            className="font-medium-14"
            autoComplete="off"
            type={"date"}
            value={props.value || ""}
            disabled={!props.editable}
            max={"9999-12-31"}
            placeholder={props.placeholder}
            onChange={(e) => onChangeInput(e)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                inputRef.current && inputRef.current.blur();
              }
            }}
          />
        }
      </TextContainer>
    </Container>
  );
};

const Container = Styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

const TextContainer = Styled.div(
  ({ editable }: { editable: boolean }) => css`
    position: relative;
    width: 100%;

    input {
      width: 100%;
      padding: 5px 10px;
      border-radius: 4px;
      background: white;
      border: 1px solid transparent;

      :hover {
        ${editable && "background-color: var(--grey_10)"};
      }

      :focus {
        outline: none;
        border: 1px solid var(--grey_20);
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
        :hover {
          ${editable && "background-color: var(--white)"};
        }
      }

      ::placeholder {
        color: var(--grey_20);
      }
    }

    input[type="date"] {
      ::placeholder {
        color: var(--grey_20);
      }
      :invalid {
      }
    }

    &.empty {
      input {
        border: 1px solid red;
        animation: shake 300ms;
      }
    }

    @keyframes shake {
      25% {
        transform: translateX(4px);
      }
      50% {
        transform: translateX(-4px);
      }
      75% {
        transform: translateX(4px);
      }
    }
  `
);
