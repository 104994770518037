import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  cancelRequestContract,
  emptyDuplicateCheck,
  getPortfolioNavigationInfo,
  registePortfolioDealFlow,
  requestContract,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import {
  getEntryPointToPortfolio,
  getMyInfo,
  getPortfolioIdInLocation,
  showToast,
} from "../../../../common/commonUtil";
import {
  PortfolioNavigationProps,
  PortfolioRoutePath,
} from "../interface/PortfolioNavigation.interface";

import PortfolioInfo from "../../info/PortfolioInfo";

import PortfolioInfoModify from "../../info-modify/PortfolioInfoModify";
import PrePitching from "../../pre-pitching/PrePitching";
import PrePitchingDetail from "../../pre-pitching-detail/PrePitchingDetail";
import Evaluation from "../../evaluation/Evaluation";
import EvaluationDetail from "../../evaluation-detail/EvaluationDetail";

import { PortfolioTargetData } from "../../../../type/data";
import { Bounce } from "react-toastify";
import BluecardManagePage from "../../bluecard-manage/BluecardManagePage";
import PortfolioDashboard from "../../dashboard/PortfolioDashboard";
import InvestmentInfo from "../../investment-info/InvestmentInfo";
import PortfolioReporting from "../../reporting/PortfolioReporting";

const usePortfolioDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const myinfo = getMyInfo();

  const portfolioId = useRef<number>(getPortfolioIdInLocation(location));

  const alarmIconRef = useRef<HTMLDivElement>(null);
  const rightModalRef = useRef<HTMLDivElement>(null);

  const [openRightModal, updateRightModal] = useState<boolean>(false);
  const [alarmNew, setAlarmNew] = useState<boolean>(false);

  const [portfolioNavInfo, setPortfolioNavInfo] = useState<
    PortfolioNavigationProps | undefined
  >(undefined);
  const [currentMenu, updateCurrentMenu] = useState<string>("");
  const [loadingModal, updateLoadingModal] = useState<boolean>(false);

  const [isEditableUser, setIsEditableUser] = useState<boolean | undefined>(
    undefined
  );

  const [dropTargetPortfolio, setDropTargetPortfolio] = useState<
    PortfolioTargetData | undefined
  >(undefined);

  const [requestContractPortfolio, setRequestContractPortfolio] = useState<
    PortfolioTargetData | undefined
  >(undefined);

  const [cancelRequestContractModal, setCancelRequestContractModal] =
    useState<boolean>(false);

  const [nextDealModal, setNextDealModal] = useState<boolean>(false);

  const [emptyData, updateEmptyData] = useState<string[]>([]);
  const [duplicateData, updateDuplicateData] = useState<string[]>([]);

  const [pathRoute, updatePathRoute] = useState<PortfolioRoutePath[]>([]);

  const navigationBack = () => {
    navigate(-1);
  };

  const exitPortfolio = () => {
    const entryPoint = getEntryPointToPortfolio();
    switch (entryPoint) {
      case "deal_flow":
        return navigate("/deal-flow");
      case "accelerating":
        return navigate("/accelerating");
      case "database":
        return navigate("/database");
      default:
        return navigate("/deal-flow");
    }
  };
  const goToPage = (path: string) => {
    navigate(path);
  };
  const goToMenu = (menu: string) => {
    const path =
      "/" + location.pathname.split("/").slice(1, 3).join("/") + "/" + menu;
    navigate(path);
  };

  const setCurrentPageName = (name: string) => {
    if (pathRoute.length === 0) return;
    const route = pathRoute.map((item, index): PortfolioRoutePath => {
      if (index === pathRoute.length - 1) {
        return {
          ...item,
          pageName: name,
        };
      }
      return item;
    });
    updatePathRoute([...route]);
  };

  const initPortfolioNavigationInfo = async () => {
    const result = await getPortfolioNavigationInfo(portfolioId.current);
    if (result) {
      setPortfolioNavInfo({ ...result });
      setIsEditableUser(
        myinfo.authorityId === 1 &&
          (result.mainParticipant?.id === myinfo.id ||
            result.subParticipant?.find((item) => item.id === myinfo.id) !==
              undefined)
      );

      if (
        ["MANAGE", "EXIT"].includes(result.pfPhase.type) ||
        (result.phase && ["investment", "contract"].includes(result.phase))
      ) {
        checkEmptyDuplicateData();
      }
    }
  };

  const getContents = () => {
    const path = location.pathname.split("/");

    const menu = path[3];

    if (!portfolioNavInfo) return null;

    switch (menu) {
      case "dashboard":
        return (
          <PortfolioDashboard
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            emptyData={emptyData}
            duplicateData={duplicateData}
            init={init}
          />
        );
      case "info":
        return (
          <PortfolioInfo
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            emptyData={emptyData}
            duplicateData={duplicateData}
            init={init}
          />
        );
      case "info-modify":
        return (
          <PortfolioInfoModify
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            init={init}
          />
        );
      case "bluecard":
        return (
          <BluecardManagePage
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            init={init}
          />
        );

      case "investment-info":
        return (
          <InvestmentInfo
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            init={init}
          />
        );

      case "pitching":
        return (
          <PrePitching
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            init={init}
          />
        );
      case "pitching-detail":
        return (
          <PrePitchingDetail
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            init={init}
            setCurrentPageName={setCurrentPageName}
          />
        );

      case "evaluation":
        return (
          <Evaluation
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            init={init}
          />
        );

      case "evaluation-detail":
        return (
          <EvaluationDetail
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            init={init}
            setCurrentPageName={setCurrentPageName}
          />
        );
      case "reporting":
        return (
          <PortfolioReporting
            portfolioId={portfolioId.current}
            portfolioNavInfo={portfolioNavInfo}
            isEditable={isEditableUser}
            init={init}
            setCurrentPageName={setCurrentPageName}
          />
        );
      default:
        return null;
    }
  };

  const onClickDropButton = () => {
    if (!portfolioNavInfo || !portfolioNavInfo.dealFlowId) return;
    const targetData: PortfolioTargetData = {
      companyName: portfolioNavInfo.companyName,
      portfolioId: portfolioId.current,
      dealFlowId: portfolioNavInfo.dealFlowId,
    };
    setDropTargetPortfolio(targetData);
  };

  const dropComplete = () => {
    init();
    showToast({
      customElement: (
        <div
          className="heading-16-sb"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span>드롭되었습니다.</span>
        </div>
      ),
      backgroundColor: "var(--green-400)",
      color: "var(--text-02)",
      width: 274,
      milliSec: 1500,
      transition: Bounce,
      position: "bottom-center",
      toastId: new Date().toString(),
    });
  };

  //사업자 등록 번호가 있는가? 있으면 사업자 등록번호
  const checkEmptyDuplicateData = async () => {
    const result = await emptyDuplicateCheck(portfolioId.current);
    if (result) {
      updateEmptyData([...result.empty]);
      updateDuplicateData([...result.duplicate]);
      return result.empty.length === 0 && result.duplicate.length === 0;
    }
    return false;
  };

  const onClickRequestContract = async () => {
    if (
      !portfolioNavInfo ||
      !portfolioNavInfo.dealFlowId ||
      !(await checkEmptyDuplicateData())
    )
      return;

    const targetData: PortfolioTargetData = {
      companyName: portfolioNavInfo.companyName,
      portfolioId: portfolioId.current,
      dealFlowId: portfolioNavInfo.dealFlowId,
    };
    setRequestContractPortfolio(targetData);
  };

  const onRequestContract = async (investmentAssessmentId: number) => {
    if (!portfolioNavInfo || !portfolioNavInfo.dealFlowId) return;
    const result = await requestContract(
      portfolioId.current,
      portfolioNavInfo.dealFlowId,
      investmentAssessmentId
    );
    if (result) {
      init();
      showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span>계약 요청이 완료되었습니다.</span>
          </div>
        ),
        backgroundColor: "var(--green-400)",
        color: "var(--text-02)",
        width: 274,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
    }
  };

  const onClickRequestContractCancel = async () => {
    if (!portfolioNavInfo || !portfolioNavInfo.dealFlowId) return;

    setCancelRequestContractModal(true);
  };

  const onRequestContractCancel = async () => {
    if (!portfolioNavInfo || !portfolioNavInfo.dealFlowId) return;
    const result = await cancelRequestContract(
      portfolioId.current,
      portfolioNavInfo.dealFlowId
    );

    if (result) {
      init();
      showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span>계약 요청이 취소되었습니다.</span>
          </div>
        ),
        backgroundColor: "var(--green-400)",
        color: "var(--text-02)",
        width: 274,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
    }
  };

  const onClickNextDeal = async () => {
    // Todo api 가 없음
    if (!portfolioNavInfo) return;
    setNextDealModal(true);
  };

  const onNextDeal = async () => {
    // Todo api 가 없음
    const dealflowResult = await registePortfolioDealFlow(portfolioId.current);
    if (dealflowResult) {
      init();
      showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span>후행 검토가 시작되었습니다.</span>
          </div>
        ),
        backgroundColor: "var(--green-400)",
        color: "var(--text-02)",
        width: 274,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
    }
  };

  const splitPath = (pathname: string) => {
    const route: PortfolioRoutePath[] = [];
    const pathSplit = pathname.split("/");
    const defaultUrl = `/${pathSplit[1]}/${pathSplit[2]}`;

    if (pathSplit[3] === undefined) return;

    switch (pathSplit[3]) {
      case "dashboard": {
        route.push({ pageName: "대시보드", pageAddress: pathname });
        break;
      }
      case "info": {
        route.push({ pageName: "기본정보", pageAddress: pathname });
        break;
      }
      case "info-modify": {
        route.push({
          pageName: "기본정보",
          pageAddress: `${defaultUrl}/info`,
        });
        route.push({ pageName: "수정", pageAddress: pathname });
        break;
      }
      case "bluecard": {
        route.push({ pageName: "블루카드", pageAddress: pathname });
        break;
      }
      case "investment-info": {
        route.push({ pageName: "투자정보", pageAddress: pathname });
        break;
      }
      case "pitching": {
        route.push({ pageName: "피칭심사", pageAddress: pathname });
        break;
      }

      case "pitching-detail": {
        route.push({
          pageName: "피칭심사",
          pageAddress: `${defaultUrl}/pitching`,
        });
        route.push({ pageName: "", pageAddress: pathname });
        break;
      }

      case "evaluation": {
        route.push({ pageName: "투자심사", pageAddress: pathname });
        break;
      }

      case "evaluation-detail": {
        route.push({
          pageName: "투자심사",
          pageAddress: `${defaultUrl}/evaluation`,
        });
        route.push({ pageName: "", pageAddress: pathname });
        break;
      }
    }

    updatePathRoute([...route]);
  };
  const onMouseDown = (event: any) => {
    if (
      alarmIconRef.current?.contains(event.target) ||
      rightModalRef.current?.contains(event.target)
    ) {
      return;
    }

    updateRightModal(false);
  };

  const init = async () => {
    await initPortfolioNavigationInfo();
  };

  useEffect(() => {
    window.addEventListener("mousedown", onMouseDown);
    return () => {
      window.removeEventListener("mousedown", onMouseDown);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname.split("/")[3];
    portfolioId.current = getPortfolioIdInLocation(location);
    updateCurrentMenu(path);
    splitPath(location.pathname);
    init();
  }, [location.pathname]);

  return [
    alarmIconRef,
    rightModalRef,
    openRightModal,
    alarmNew,
    myinfo,
    pathRoute,
    isEditableUser,
    portfolioNavInfo,
    currentMenu,
    dropTargetPortfolio,
    requestContractPortfolio,
    emptyData,
    duplicateData,
    cancelRequestContractModal,
    nextDealModal,
    setNextDealModal,
    onClickNextDeal,
    onNextDeal,
    setCancelRequestContractModal,
    setRequestContractPortfolio,
    setDropTargetPortfolio,
    getContents,
    goToMenu,
    exitPortfolio,
    onClickDropButton,
    dropComplete,
    onClickRequestContract,
    onRequestContract,
    goToPage,
    onClickRequestContractCancel,
    onRequestContractCancel,
    updateRightModal,
    setAlarmNew,
    navigationBack,
  ] as const;
};

export default usePortfolioDetail;
