import React, { createRef, useEffect, useLayoutEffect, useState } from "react";
import styled, { css } from "styled-components";
import { DropdownItemType } from "./DatabaseDropdownCheckbox";

import { ReactComponent as ArrowUp } from "../../../../assets/image/ic_arrow_up.svg";

interface Props {
  width?: number;
  disabled?: boolean;
  allItemList: DropdownItemType[];
  selectedItem?: DropdownItemType;
  onSelectedData: (data: any | undefined) => void;
  placeholder?: string;
  isIncludesValue: (value: string, data: any) => boolean;
  canSelectAll?: boolean;

  /* dropdown 하나만 열리게 하기 위함 */
  dropdownId?: string; // 해당 드롭다운의 id
  selectedDropdownId?: string; // 선택된 드롭다운의 id
  setSelectedDropdownId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

interface DropdownItemProps {
  item: any;
  type: "all" | "item";
  onClickItem: (item: DropdownItemType) => void;
}

const DropdownItem = ({ item, type, onClickItem }: DropdownItemProps) => {
  return (
    <Item type={type} onClick={() => onClickItem(item)}>
      <span className="font-regular-12">{item.label}</span>
    </Item>
  );
};

const DatabaseDropdownRadio = ({
  width,
  disabled = false,
  allItemList,
  selectedItem,
  onSelectedData,
  placeholder,
  isIncludesValue,
  dropdownId,
  selectedDropdownId,
  setSelectedDropdownId,
  canSelectAll,
}: Props) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<DropdownItemType[]>([]);

  const selectedRef = createRef<HTMLDivElement>();
  const dropdownRef = createRef<HTMLDivElement>();

  const onClickItem = (item: DropdownItemType) => {
    onSelectedData(item.data);
    setShowOptions(false);
  };

  const onClickAll = () => {
    onSelectedData(undefined);
    setShowOptions(false);
  };
  const init = () => {
    setFilteredItems(allItemList);
  };

  useLayoutEffect(() => {
    if (selectedDropdownId === undefined) {
      setShowOptions(false);
    } else if (dropdownId === selectedDropdownId) {
      setShowOptions(true);
    }
  }, []);

  useEffect(() => {
    if (showOptions) {
      setSelectedDropdownId && setSelectedDropdownId(dropdownId);
    }
  }, [showOptions]);

  useEffect(() => {
    console.log(`${selectedDropdownId} ${dropdownId}`);

    if (selectedDropdownId === undefined || dropdownId !== selectedDropdownId) {
      setShowOptions(false);
    } else if (dropdownId === selectedDropdownId) {
      setShowOptions(true);
    }
  }, [selectedDropdownId]);

  useEffect(() => {
    setFilteredItems(allItemList);
  }, [allItemList]);

  useEffect(() => {
    setFilteredItems(
      allItemList.filter((item) => isIncludesValue(value, item.data))
    );
  }, [value]);

  useEffect(() => {
    // dropdown 위치를 input 아래로 설정
    if (
      selectedRef &&
      selectedRef.current &&
      dropdownRef &&
      dropdownRef.current
    ) {
      dropdownRef.current.style.top = `${
        selectedRef.current?.clientHeight + 4
      }px`;
      console.log(dropdownRef.current.style.top);
    }
  }, [dropdownRef]);

  useEffect(() => {
    init();
    window.addEventListener("click", () => {
      setValue("");
      setShowOptions(false);
      setSelectedDropdownId && setSelectedDropdownId(undefined);
    });
    return () => {
      window.removeEventListener("click", () => {
        setValue("");
        setShowOptions(false);
        setSelectedDropdownId && setSelectedDropdownId(undefined);
      });
    };
  }, []);

  return (
    <Container
      width={width}
      isFocused={showOptions}
      disabled={disabled}
      isSelected={selectedItem && !disabled ? true : false}
    >
      <div
        className="input-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={selectedRef}
      >
        <input
          placeholder={selectedItem?.label || placeholder || "선택"}
          className="font-regular-12"
          onFocus={() => setShowOptions(true)}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          disabled={disabled}
        />
        <div className="arrow-container">
          <ArrowUp
            onClick={() => !disabled && setShowOptions((prev) => !prev)}
            style={{
              transform: `${showOptions ? "" : "rotate(180deg)"}`,
            }}
          />
        </div>
      </div>
      {showOptions && (
        <div
          className="dropdown-container scroll__invisible"
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={dropdownRef}
        >
          {canSelectAll && (
            <div className="select-all">
              <DropdownItem
                item={{
                  data: "전체",
                  label: "전체",
                }}
                type="all"
                onClickItem={() => onClickAll()}
              />
            </div>
          )}
          <div className="select-list">
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <DropdownItem
                  item={item}
                  type="item"
                  onClickItem={() => onClickItem(item)}
                  key={item.label}
                />
              ))
            ) : (
              <div className="no-list font-regular-12">
                검색 결과가
                {width && width < 130 ? <br /> : " "}
                없습니다.
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div(
  ({
    width,
    isFocused,
    disabled,
    isSelected,
  }: {
    width?: number;
    isFocused: boolean;
    disabled: boolean;
    isSelected: boolean;
  }) => css`
    width: ${width ? `${width}px` : "100%"};

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    .input-container {
      border: 1px solid
        ${!isFocused && !isSelected ? "var(--grey_10)" : "var(--primary)"};
      border-radius: 4px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 8px;
      height: 32px;
      background: ${disabled ? "var(--grey_10)" : "white"};

      input {
        border: transparent;
        background: transparent;
        width: 100%;
        margin: 8px;
        :focus {
          outline: none;
        }

        ::placeholder {
          font-family: "BPP Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: ${isFocused || isSelected
            ? "var(--primary)"
            : "var(--grey_20)"};
        }
      }

      .selected-value {
        border: transparent;
        background: transparent;
        width: 100%;
        margin: 8px;

        span {
          color: ${!isFocused && !isSelected
            ? "var(--grey_20)"
            : "var(--primary)"};
        }
      }

      .arrow-container {
        width: 16px;
        height: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          cursor: ${!disabled && "pointer"};
        }
        path {
          stroke: ${!isFocused && !isSelected
            ? "var(--grey_20)"
            : "var(--primary)"};
        }
      }

      :hover {
        background: ${disabled ? "var(--grey_10)" : "#fafcff"};
        border-radius: 4px;
        height: calc(inherit - 1px);
      }
    }

    .dropdown-container {
      width: inherit;
      border: 1px solid var(--grey_10);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-top: 4px;
      max-height: 324px;
      background: white;
      overflow: scroll;

      position: absolute;
      z-index: 1000;

      .select-list {
        gap: 4px;
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        .no-list {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-top: 4px;
          color: var(--grey_50);
        }
      }
    }
  `
);

const Item = styled.div(
  ({ type }: { type: string }) => css`
    height: ${type === "all" ? "35px" : "24px"};

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    padding: 4px 10px;

    cursor: default;

    border-bottom: ${type === "all" ? "1px solid var(--grey_20)" : ""};

    :hover {
      background: #f0f0f0;
    }
  `
);

export default DatabaseDropdownRadio;
