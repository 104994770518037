import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

// import { getShareholderList } from "../../common/service/StockManagementService";
import { CapitalRaisingProps, StockChangeListProps } from "../interface/type";
import { ShareHolderConfirmProps } from "../../../common/StockChangeListService";
import { addCommasToIntegerPart, getNewDate } from "../../../common/commonUtil";

interface Props {
  stockChangeListResult: ShareHolderConfirmProps;
  stockChangeList: StockChangeListProps[];
  capitalRaisingList: CapitalRaisingProps[];
  currency: string;
}
interface InvestmentSummaryDataProps {
  totalStockNumber: number;
  ordinaryStock: number;
  preferredStock: number;
  curStockPrice: number;
  curFaceValue: number;
  capitalRaisingAmount: number;
  paidInCapitalIncreaseAmount: number;
  oldStockTransactionAmount: number;
  currentRound: string;
}
export const InvestmentSummary: React.FC<Props> = (props) => {
  const {
    stockChangeListResult,
    stockChangeList,
    capitalRaisingList,
    currency,
  } = props;

  const [investmentSummary, setInvestmentSummary] = useState<
    InvestmentSummaryDataProps | undefined
  >(undefined);

  const initData = async () => {
    let totalCapitalRaisigAmount = 0;
    const curRound = { date: "", round: "" };
    const summaryData: InvestmentSummaryDataProps = {
      totalStockNumber: stockChangeListResult.totalStockNumber,
      ordinaryStock: stockChangeListResult.shareHolder
        .filter((item) => item.stockType.stockTypeId === 1)
        .map((item) => item.stockNumber)
        .reduce((p, c) => p + c, 0),
      preferredStock: stockChangeListResult.shareHolder
        .filter((item) => item.stockType.stockTypeId !== 1)
        .map((item) => item.stockNumber)
        .reduce((p, c) => p + c, 0),
      curStockPrice: stockChangeListResult.curStockPrice,
      curFaceValue: stockChangeListResult.faceValue,
      paidInCapitalIncreaseAmount: 0,
      oldStockTransactionAmount: 0,
      capitalRaisingAmount: 0,
      currentRound: "정보없음",
    };

    if (stockChangeList) {
      summaryData.paidInCapitalIncreaseAmount = stockChangeList
        .filter(
          (item) =>
            item.stockChangeType?.id && [1, 2].includes(item.stockChangeType.id)
        )
        .map((item) => (item.stockNumber || 0) * (item.stockPrice || 0))
        .reduce((p, c) => p + c, 0);

      summaryData.oldStockTransactionAmount = stockChangeList
        .filter((item) => item.stockChangeType?.id === 3)
        .map((item) => (item.stockNumber || 0) * (item.stockPrice || 0))
        .reduce((p, c) => p + c, 0);

      const roundList = stockChangeList.filter(
        (item) => item.stockChangeType?.id === 2
      );
      if (roundList && roundList.length > 0) {
        curRound.date = roundList[roundList.length - 1].date;
        curRound.round = roundList[roundList.length - 1].round?.name || "";
      }
      totalCapitalRaisigAmount += summaryData.paidInCapitalIncreaseAmount;
    }

    if (capitalRaisingList && capitalRaisingList.length > 0) {
      totalCapitalRaisigAmount += capitalRaisingList
        .map((item) => item.amount)
        .reduce((p, c) => p + c, 0);
      if (curRound.date.length > 0) {
        const curRoundDate = getNewDate(curRound.date).getTime();
        const lastCpitalRaisingDate = getNewDate(
          capitalRaisingList[capitalRaisingList.length - 1].date
        ).getTime();

        if (curRoundDate <= lastCpitalRaisingDate) {
          curRound.date =
            capitalRaisingList[capitalRaisingList.length - 1].date;
          curRound.round =
            capitalRaisingList[capitalRaisingList.length - 1].round.name;
        }
      }
    }

    summaryData.capitalRaisingAmount = totalCapitalRaisigAmount;
    summaryData.currentRound = curRound.round;

    setInvestmentSummary(summaryData);
  };

  const mathRoundSecend = (value: number) => Math.round(value * 100) / 100;

  useEffect(() => {
    initData();
  }, [stockChangeListResult]);

  if (!investmentSummary) return null;

  return (
    <Container>
      <CardItem>
        <div className="body-14-md group">총 발행 주식수 (주)</div>
        <div className="heading-18-sb num">
          {addCommasToIntegerPart(investmentSummary.totalStockNumber)}
        </div>
        <FlexColumn className="caption-12-rg">
          <div>
            보통주 : {addCommasToIntegerPart(investmentSummary.ordinaryStock)} (
            {mathRoundSecend(
              (investmentSummary.ordinaryStock /
                investmentSummary.totalStockNumber) *
                100
            )}
            %)
          </div>
          <div>
            우선주 : {addCommasToIntegerPart(investmentSummary.preferredStock)}{" "}
            (
            {mathRoundSecend(
              (investmentSummary.preferredStock /
                investmentSummary.totalStockNumber) *
                100
            )}
            %)
          </div>
        </FlexColumn>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">주당 금액 ({currency})</div>
        <div className="heading-18-sb num">
          {stockChangeListResult.emptyFiled?.length === 0 &&
          !stockChangeListResult.errorData
            ? addCommasToIntegerPart(
                mathRoundSecend(investmentSummary.curStockPrice)
              )
            : "???"}
        </div>
        <div className="caption-12-rg">
          ( 액면가 :{" "}
          {addCommasToIntegerPart(
            mathRoundSecend(investmentSummary.curFaceValue)
          )}
          )
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">POST 기업가치 ({currency})</div>
        <div className="heading-18-sb num">
          {stockChangeListResult.emptyFiled?.length === 0 &&
          !stockChangeListResult.errorData
            ? addCommasToIntegerPart(
                mathRoundSecend(
                  investmentSummary.totalStockNumber *
                    investmentSummary.curStockPrice
                )
              )
            : "???"}
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">총 자본 조달 ({currency})</div>
        <div className="heading-18-sb num">
          {addCommasToIntegerPart(
            mathRoundSecend(investmentSummary.capitalRaisingAmount)
          )}
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">누적 유상증자 ({currency})</div>
        <div className="heading-18-sb num">
          {addCommasToIntegerPart(
            mathRoundSecend(investmentSummary.paidInCapitalIncreaseAmount)
          )}
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">누적 구주거래 ({currency})</div>
        <div className="heading-18-sb num">
          {addCommasToIntegerPart(
            mathRoundSecend(investmentSummary.oldStockTransactionAmount)
          )}
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">최근 라운드</div>
        <div className="heading-18-sb num">
          {investmentSummary.currentRound}
        </div>
      </CardItem>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 12px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardItem = styled.div`
  flex: 1;
  height: 103px;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid var(--light-divider-200, #edeeef);
  background: var(--light-background-contents-01, #fff);
  /* light/shdow250-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.05);

  color: var(--light-text-01, #1f2022);

  .group {
    display: flex;
    flex-direction: row;
    color: var(--light-text-04, #7e8086);
  }
  .num {
    width: 100%;
  }
`;
