import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getTotalPerformance } from "../../../../../../api/repository/accelerator/DashboardRepository";
import { TotalPerformanceProps } from "../../../interface/PerformanceMetrics.interface";
import { AddComma } from "../../../../../../common/commonUtil";

interface Props {
  selectedFundAccountId: number[];
  selectedIndustryId: number[];
}
export const TotalPerformance: React.FC<Props> = (props) => {
  const { selectedFundAccountId, selectedIndustryId } = props;

  const isNetworking = useRef<boolean>(false);
  const [totalPerformanceData, setTotalPerformanceData] = useState<
    TotalPerformanceProps | undefined
  >(undefined);

  const getData = async () => {
    isNetworking.current = true;
    const result = await getTotalPerformance({
      acFundAccountId: selectedFundAccountId,
      acIndustrialTechId: selectedIndustryId,
    });
    setTotalPerformanceData(result);
    isNetworking.current = false;
  };

  useEffect(() => {
    if (isNetworking.current) return;
    getData();
  }, [selectedFundAccountId, selectedIndustryId]);

  return (
    <Container>
      <CardItem>
        <div className="body-14-md group">매출</div>
        <div className="heading-18-sb num">
          {totalPerformanceData
            ? AddComma((totalPerformanceData.revenue / 100000000).toFixed(0))
            : 0}
          억
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">총 임직원 수</div>
        <div className="heading-18-sb num">
          {totalPerformanceData ? AddComma(totalPerformanceData.employee) : 0}명
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">후속투자 유치금액</div>
        <div className="heading-18-sb num">
          {totalPerformanceData
            ? AddComma((totalPerformanceData.investment / 100000000).toFixed(0))
            : 0}
          억
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">특허 출원</div>
        <div className="heading-18-sb num">
          {totalPerformanceData
            ? AddComma(totalPerformanceData.patentApplication)
            : 0}
          건
        </div>
      </CardItem>
      <CardItem>
        <div className="body-14-md group">특허 등록</div>
        <div className="heading-18-sb num">
          {totalPerformanceData
            ? AddComma(totalPerformanceData.patentRegistration)
            : 0}
          건
        </div>
      </CardItem>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  gap: 12px;
`;

const CardItem = styled.div`
  flex: 1;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid var(--light-divider-200, #edeeef);
  background: var(--light-background-contents-01, #fff);
  /* light/shdow250-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.05);

  color: var(--light-text-01, #1f2022);

  .group {
    display: flex;
    flex-direction: row;
    color: var(--light-text-04, #7e8086);
  }
  .num {
    width: 100%;
  }
`;
