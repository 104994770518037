import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FilterView from "./FilterView";
import { NewInvestmentSection } from "./NewInvestmentSection";
import { PerformanceSection } from "./PerformanceSection";
import { ExpectationManagementSection } from "./ExpectationManagementSection";
import { OperationStatusManagementSection } from "./OperationStatusManagementSection";
import { UserInfo } from "../../../../../../type/data";
import {
  PersonalSearchList,
  PersonalSearchProps,
} from "../../../interface/PerformanceMetrics.interface";

interface Props {
  selectedUser?: UserInfo;
}

export const PersonPerformance: React.FC<Props> = (props) => {
  const { selectedUser } = props;

  if (!selectedUser) return null;

  const [selectedChip, updateSelectedChip] = useState<"main" | "excavation">(
    "main"
  );
  const [selectedMainSubChip, updateSelectedMainSubChip] = useState<
    PersonalSearchProps[]
  >([]);

  const [excavation, setExcavation] = useState<boolean>(true);
  const [investor, setInvestor] = useState<boolean>(true);
  const [charger, setCharger] = useState<boolean>(true);
  const [assistant, setAssistant] = useState<boolean>(true);

  const onChangeMainChip = (chip: "main" | "excavation") => {
    updateSelectedChip(chip);
  };

  const onChangeFilter = (filter: PersonalSearchProps) => {
    if (filter.isDefault) return;

    const index = selectedMainSubChip.findIndex(
      (item) => item.type === filter.type
    );
    if (index !== -1) {
      selectedMainSubChip.splice(index, 1);
      updateSelectedMainSubChip([...selectedMainSubChip]);
    } else {
      updateSelectedMainSubChip([...selectedMainSubChip, filter]);
    }
  };

  useEffect(() => {
    setExcavation(
      selectedMainSubChip.length === 0 ||
        selectedMainSubChip.find((item) => item.type === "excavation") !==
          undefined
    );
    setInvestor(
      selectedMainSubChip.length === 0 ||
        selectedMainSubChip.find((item) => item.type === "investor") !==
          undefined
    );
    setCharger(
      selectedMainSubChip.length === 0 ||
        selectedMainSubChip.find((item) => item.type === "charger") !==
          undefined
    );
    setAssistant(
      selectedMainSubChip.length === 0 ||
        selectedMainSubChip.find((item) => item.type === "assistant") !==
          undefined
    );
  }, [selectedMainSubChip]);

  return (
    <Container>
      <FilterView
        selectedChip={selectedChip}
        selectedMainSubChip={selectedMainSubChip}
        onChangeMainChip={onChangeMainChip}
        onChangeFilter={onChangeFilter}
      />
      <div style={{ marginTop: 36 }}>
        <NewInvestmentSection
          selectedUserId={
            selectedUser ? parseInt(`${selectedUser.id}`) : undefined
          }
          excavation={excavation}
          investor={investor}
          charger={charger}
          assistant={assistant}
        />
      </div>

      <div style={{ marginTop: 36 }}>
        <PerformanceSection
          selectedUserId={
            selectedUser ? parseInt(`${selectedUser.id}`) : undefined
          }
          excavation={excavation}
          investor={investor}
          charger={charger}
          assistant={assistant}
        />
      </div>

      <div style={{ marginTop: 36 }}>
        <ExpectationManagementSection
          selectedUserId={
            selectedUser ? parseInt(`${selectedUser.id}`) : undefined
          }
          excavation={excavation}
          investor={investor}
          charger={charger}
          assistant={assistant}
        />
      </div>

      <div style={{ marginTop: 36 }}>
        <OperationStatusManagementSection
          selectedUserId={
            selectedUser ? parseInt(`${selectedUser.id}`) : undefined
          }
          excavation={excavation}
          investor={investor}
          charger={charger}
          assistant={assistant}
        />
      </div>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
`;
