import React, { useRef } from "react";
import styled from "styled-components";

interface Props {
  onClickCard?: () => void;
}
export const CreateExcavationPortfolioCard: React.FC<Props> = (props) => {
  const { onClickCard } = props;
  return (
    <Container className="heading-14-md" onClick={onClickCard}>
      +{"  "}새로운 기업 추가하기
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-grow: 0;
  flex-basis: 282px;

  height: 224px;
  min-height: 224px;
  max-height: 224px;

  padding: 24px 24px 24px 16px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  cursor: pointer;

  color: var(--text-05, #96999f);

  :hover {
    background-color: var(--hover-100);
  }
`;
