import React, { useState, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../../../assets/image/icon_arrow.svg";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { RiskManagementListProps } from "../../interface/RME.interface";
import { getYMDKo } from "../../../../../common/commonUtil";

interface Props {
  data: RiskManagementListProps;
  onClick?: () => void;
}

export const EvaluationCompletedCard: React.FC<Props> = (props) => {
  const { data, onClick } = props;
  const riskLabels: string[] = [
    // "핵심인력 이탈",
    // "경영진 이슈",
    // "임직원 급감",
    // "거시환경 영향",
    // "시장상황 변동",
    // "Milestone 이슈",
    // "폐업 진행",
    // "완전자본 잠식",
    // "현금흐름 이슈",
  ];
  const getLabel = () => {
    switch (data.riskStatus.id) {
      case 1:
        return (
          <CustomColorLabel
            textColor="var(--blue500, #173AFF)"
            bgColor="var(--blue100, #E8F0FF)"
          >
            성장
          </CustomColorLabel>
        );
      case 2:
        return (
          <CustomColorLabel
            textColor="var(--green-700, #28BE83)"
            bgColor="#E9F7F1"
          >
            관찰
          </CustomColorLabel>
        );
      case 3:
        return (
          <CustomColorLabel textColor="#F5BF00" bgColor="#FFF8E0">
            우려
          </CustomColorLabel>
        );
      case 4:
        return (
          <CustomColorLabel
            textColor="var(--orange-500, #FE9041)"
            bgColor="var(--orange-50, #FFF5EE)"
          >
            손상우려
          </CustomColorLabel>
        );
      case 5:
        return (
          <CustomColorLabel textColor="var(--red-500)" bgColor="var(--red-50)">
            손상
          </CustomColorLabel>
        );
      case 6:
        return (
          <CustomColorLabel
            textColor="var(--text-05, #96999F)"
            bgColor="var(--gray-200, #EDEEEF)"
          >
            폐업진행
          </CustomColorLabel>
        );
    }

    return null;
  };
  return (
    <Container onClick={onClick}>
      {getLabel()}
      <Title className="heading-18-sb">{data.portfolio.companyName}</Title>
      <Description className="caption-12-rg">
        {data.portfolio.description}
      </Description>
      <RiskLabelContainer>
        <span className="caption-12-sb" style={{ color: "var(--text-05)" }}>
          리스크
        </span>
        {data.riskIssueList.length === 0 && (
          <LabelContainer
            className="caption-10-md"
            style={{ background: "var(--gray-500)", color: "var(--gray-200)" }}
          >
            없음
          </LabelContainer>
        )}
        {data.riskIssueList.slice(0, 2).map((item) => {
          return (
            <LabelContainer className="caption-10-md">
              {item.riskIssue.name}
            </LabelContainer>
          );
        })}
        {data.riskIssueList.length > 2 && (
          <LabelContainer
            className="caption-10-md"
            style={{ background: "var(--gray-300)", color: "var(--text-04)" }}
          >
            +{data.riskIssueList.length - 2}
          </LabelContainer>
        )}
      </RiskLabelContainer>
      <CompleteDateContainer className="caption-12-rg">
        {getYMDKo(data.updatedAt)}
      </CompleteDateContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 282px;
  height: 246px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  border-radius: 4px;
  border: 1px solid var(--gray200, #edeeef);
  background: var(--background-contents-01, #fff);

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const Title = styled.div`
  color: var(--text01, #1f2022);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 6px;
`;
const Description = styled.div`
  display: -webkit-box;
  margin-top: 12px;
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text05, #96999f);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const RiskLabelContainer = styled.div`
  width: 100%;
  max-height: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 12px;
  gap: 6px;
`;

const LabelContainer = styled.div`
  width: max-content;
  padding: 2px 8px;
  border-radius: 15px;
  background: var(--gray-700, #64676d);
  color: var(--gray-100, #f3f4f4);
`;

const CompleteDateContainer = styled.div`
  margin-top: 30px;
  color: var(--text-06, #bec0c2);
`;
