import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { ResponsibilityProps } from "../interface/Structure.interface";
import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import { DefaultButton } from "lattice_core";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { ReactComponent as Trash } from "../../../../../assets/image/icon_trash.svg";
import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";
import ModalLayout from "../../../../../components/modal/ModalLayout";
import ConfirmModal from "../../../../../2.0lattice/components/modal/ConfirmModal";

interface Props {
  responsibility: ResponsibilityProps[];
  createResponsibility: (name: string) => void;
  deleteResponsibility: (target: ResponsibilityProps) => void;
}

export const ResponsibilityModal: React.FC<Props> = (props) => {
  const { responsibility } = props;

  const selectedTarget = useRef<ResponsibilityProps | undefined>(undefined);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [newName, updateNewName] = useState<string>("");

  const ItemComponent = (item: ResponsibilityProps, index: number) => {
    return (
      <CardContainer key={index}>
        <div className="body-14-rg text__wrapper">
          {item.responsibility.name}
          <CustomColorLabel
            bgColor={item.isUsed ? "var(--primary-purple)" : "var(--gray-700)"}
            textColor={item.isUsed ? "var(--white)" : "var(--white)"}
          >
            {item.isUsed ? "사용중" : "사용안함"}
          </CustomColorLabel>
        </div>
        <div className="icon__wrapper">
          <Trash
            onClick={() => {
              selectedTarget.current = item;
              setDeleteModal(true);
            }}
          />
        </div>
      </CardContainer>
    );
  };

  return (
    <Container>
      <Header>
        <span className="heading-18-sb">직책 관리</span>
        <DefaultButton
          className="heading-14-sb"
          sizeType="xxsmall"
          buttonType="text"
          buttonColor={createModal ? "var(--primary-purple)" : "var(--text-03)"}
          onClick={() => {
            updateNewName("");
            setCreateModal((prev) => !prev);
          }}
          text="생성하기"
        />
      </Header>
      <Contents>
        <FlexColumn>
          {responsibility.map((item, index) => {
            return ItemComponent(item, index);
          })}
        </FlexColumn>
      </Contents>
      <CreateContainer className={createModal ? "open" : ""}>
        <div className="close">
          <Close
            className="close__icon"
            onClick={() => {
              setCreateModal(false);
            }}
          />
        </div>
        <div className="body-14-rg description">
          중복 되지 않은 이름을 작성해주세요
        </div>
        {createModal && (
          <InputBox
            className="input__box"
            width={332}
            placeholder="직책 이름을 입력해주세요."
            onChange={(value) => {
              updateNewName(value);
            }}
          />
        )}
        <DefaultButton
          className="heading-16-md"
          sizeType="small"
          text="생성"
          buttonColor="var(--primary-purple)"
          hoverColor="var(--purple-700)"
          disabled={newName.length === 0}
          onClick={() => {
            if (
              responsibility.filter(
                (item) => item.responsibility.name === newName
              ).length === 0
            ) {
              props.createResponsibility(newName);
              setCreateModal(false);
            } else {
              alert("중복됨");
            }
          }}
        />
      </CreateContainer>
      {deleteModal && selectedTarget.current && (
        <ModalLayout
          isOpen={deleteModal}
          toggle={() => {
            setDeleteModal(false);
            selectedTarget.current = undefined;
          }}
        >
          <ConfirmModal
            type="confirm"
            contents={
              selectedTarget.current.isUsed
                ? [
                    "사용 중인 직책입니다.",
                    "삭제하시면 일부 유저들의 직책이 사랍집니다.",
                    "삭제하시겠습니까?",
                  ]
                : ["사용하지 않는 직책입니다.", "삭제하시겠습니까?"]
            }
            onClickPositiveBtn={() => {
              if (selectedTarget.current) {
                props.deleteResponsibility(selectedTarget.current);
                setDeleteModal(false);
              }
            }}
            onClickNegativeBtn={() => {
              setDeleteModal(false);
              selectedTarget.current = undefined;
            }}
          />
        </ModalLayout>
      )}
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div(
  () => css`
    width: 100%;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    border-bottom: 1px solid var(--gray-300);

    .warnning {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ffc800;
    }
  `
);

const Contents = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  gap: 14px;

  padding: 28px 40px;

  .group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    .single__item {
      flex: 1;
    }
    .double__item {
      flex: 2;
    }
  }
`;

const CardContainer = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .text__wrapper {
    flex: 9;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    border-radius: 4px;

    border: 1px solid var(--gray-300);
  }

  .icon__wrapper {
    flex: 1;
    cursor: pointer;
  }
`;

const CreateContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 250px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  bottom: -250px;
  left: 0;

  display: flex;
  flex-direction: column;
  transition: all 0.25s ease;

  background-color: var(--white);
  border-top: 1px solid var(--gary-200);

  box-shadow: 0 -5px 5px -5px rgba(100, 103, 109, 0.4);

  padding: 24px;

  &.open {
    bottom: 0;
  }

  .close {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .close__icon {
      cursor: pointer;
    }
  }
  .description {
    margin-top: 10px;
  }

  .input__box {
    margin-top: 20px;
  }

  .submit {
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
