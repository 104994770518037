import React, {
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";

import BluecardPrint from "../component/BluecardPrint";
import { BlueCardInfo } from "../../../../type/data";
import SpinnerModal from "../../../../components/modal/SpinnerModal";

import { createBluecardLink } from "../../../bluecard-link/repository/BluecardRepository";

import { ReactComponent as Pdf } from "../../../../assets/image/icon_file_pdf.svg";
import { ReactComponent as Close } from "../../../../assets/image/modal_close.svg";
import { ReactComponent as CheckBoxChecked } from "../../../../assets/image/checkbox_checked.svg";
import { ReactComponent as CheckBox } from "../../../../assets/image/checkbox_off.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DefaultButton } from "lattice_core";
import ModalLayout from "../../../../components/modal/ModalLayout";
import ProgressModalStatic from "../../../../components-2.0v/progress/ProgressModalStatic";
import {
  getNewDate,
  getYMDHMS,
  getYMDHMSNoDelimiter,
} from "../../../../common/commonUtil";

interface Props {
  portfolioBlueCard?: BlueCardInfo[];
  closeBlueCardPDFView?: () => void;
  callback: (uuid: string) => void;
}
export type BluecardCheckFilterType =
  | "all"
  | "companyInfo"
  | "companyValue"
  | "investmentAmount"
  | "participantInfo"
  | "userName"
  | "userEmail"
  | "userPhone"
  | "bluecardInfo"
  | "investmentHighlight"
  | "problem"
  | "solutionBusinessModel"
  | "technology"
  | "productStatus"
  | "irProposal"
  | "irProposalSurmmary"
  | "irProposalDetail";

export interface BluecardFilterProps {
  companyValue: boolean;
  investmentAmount: boolean;

  userName: boolean;
  userEmail: boolean;
  userPhone: boolean;

  investmentHighlight: boolean;
  problem: boolean;
  solutionBusinessModel: boolean;
  technology: boolean;
  productStatus: boolean;

  irProposalSurmmary: boolean;
  irProposalDetail: boolean;
}

const PreviewBlueCardLink: React.FC<Props> = (props) => {
  const bluecardPrevRef = useRef<HTMLDivElement>(null);
  const [arrayRef, updateArrayRef] = useState<
    React.RefObject<HTMLDivElement>[]
  >([]);
  const [showSpinnerModal, updateShowSpinnerModal] = useState<boolean>(false);
  const [curPage, updateCurPage] = useState<number>(0);
  const [progress, updateProgress] = useState<number>(0);
  const [bluecardFilter, updateBluecardFilter] = useState<BluecardFilterProps>({
    companyValue: false,
    investmentAmount: false,
    userName: false,
    userEmail: false,
    userPhone: false,
    investmentHighlight: true,
    problem: true,
    solutionBusinessModel: true,
    productStatus: true,
    technology: true,
    irProposalSurmmary: false,
    irProposalDetail: false,
  });

  const checkedFilter = (filterType: BluecardCheckFilterType) => {
    const currentState = bluecardFilter;
    switch (filterType) {
      case "all": {
        const allCheckStatus =
          bluecardFilter.companyValue &&
          bluecardFilter.investmentAmount &&
          bluecardFilter.userName &&
          bluecardFilter.userEmail &&
          bluecardFilter.userPhone &&
          bluecardFilter.investmentHighlight &&
          bluecardFilter.problem &&
          bluecardFilter.solutionBusinessModel &&
          bluecardFilter.technology &&
          bluecardFilter.productStatus &&
          bluecardFilter.irProposalSurmmary &&
          bluecardFilter.irProposalDetail;

        bluecardFilter.companyValue = !allCheckStatus;
        bluecardFilter.investmentAmount = !allCheckStatus;
        bluecardFilter.userName = !allCheckStatus;
        bluecardFilter.userEmail = !allCheckStatus;
        bluecardFilter.userPhone = !allCheckStatus;
        bluecardFilter.investmentHighlight = !allCheckStatus;
        bluecardFilter.problem = !allCheckStatus;
        bluecardFilter.solutionBusinessModel = !allCheckStatus;
        bluecardFilter.technology = !allCheckStatus;
        bluecardFilter.productStatus = !allCheckStatus;
        bluecardFilter.irProposalSurmmary = !allCheckStatus;
        bluecardFilter.irProposalDetail = !allCheckStatus;
        break;
      }
      case "companyInfo": {
        const allCheckStatus =
          bluecardFilter.companyValue && bluecardFilter.investmentAmount;
        bluecardFilter.companyValue = !allCheckStatus;
        bluecardFilter.investmentAmount = !allCheckStatus;
        break;
      }
      case "participantInfo": {
        const allCheckStatus =
          bluecardFilter.userName &&
          bluecardFilter.userEmail &&
          bluecardFilter.userPhone;

        bluecardFilter.userName = !allCheckStatus;
        bluecardFilter.userEmail = !allCheckStatus;
        bluecardFilter.userPhone = !allCheckStatus;
        break;
      }
      case "bluecardInfo": {
        const allCheckStatus =
          bluecardFilter.investmentHighlight &&
          bluecardFilter.problem &&
          bluecardFilter.solutionBusinessModel &&
          bluecardFilter.technology &&
          bluecardFilter.productStatus;

        bluecardFilter.investmentHighlight = !allCheckStatus;
        bluecardFilter.problem = !allCheckStatus;
        bluecardFilter.solutionBusinessModel = !allCheckStatus;
        bluecardFilter.technology = !allCheckStatus;
        bluecardFilter.productStatus = !allCheckStatus;
        break;
      }
      case "irProposal": {
        const allCheckStatus =
          bluecardFilter.irProposalSurmmary && bluecardFilter.irProposalDetail;
        bluecardFilter.irProposalSurmmary = !allCheckStatus;
        bluecardFilter.irProposalDetail = !allCheckStatus;
        break;
      }

      case "companyValue":
        currentState.companyValue = !currentState.companyValue;
        break;
      case "investmentAmount":
        currentState.investmentAmount = !currentState.investmentAmount;
        break;
      case "userName":
        currentState.userName = !currentState.userName;
        break;
      case "userEmail":
        currentState.userEmail = !currentState.userEmail;
        break;
      case "userPhone":
        currentState.userPhone = !currentState.userPhone;
        break;
      case "investmentHighlight":
        currentState.investmentHighlight = !currentState.investmentHighlight;
        break;
      case "problem":
        currentState.problem = !currentState.problem;
        break;
      case "solutionBusinessModel":
        currentState.solutionBusinessModel =
          !currentState.solutionBusinessModel;
        break;
      case "productStatus":
        currentState.productStatus = !currentState.productStatus;
        break;
      case "technology":
        currentState.technology = !currentState.technology;
        break;

      case "irProposalSurmmary":
        currentState.irProposalSurmmary = !currentState.irProposalSurmmary;
        break;

      case "irProposalDetail":
        currentState.irProposalDetail = !currentState.irProposalDetail;
        break;
    }
    updateBluecardFilter({ ...currentState });
  };

  const addPdfPage = (page: number, totalPage: number) => {
    const percentage = Math.round((page / totalPage) * 100);
    updateProgress(percentage >= 100 ? 100 : percentage);
  };

  const handleDownloadPdf = async () => {
    try {
      if (!arrayRef[0].current) return;

      updateShowSpinnerModal(true);
      const firstCanvas = await html2canvas(arrayRef[0].current, {
        scale: 2,
        allowTaint: true,
        useCORS: true,
        logging: true,
      });
      const firstData = firstCanvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF("p", "mm", "a4");

      pdf.addImage({
        imageData: firstData,
        format: "PNG",
        x: 0,
        y: 0,
        width: 210,
        height: 297,
        compression: "FAST",
      });
      addPdfPage(1, arrayRef.length);
      for (let i = 1; i < arrayRef.length; i++) {
        const element = arrayRef[i].current;
        if (element) {
          const canvas = await html2canvas(element, {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            logging: true,
          });
          const data = canvas.toDataURL("image/png", 1.0);
          pdf.addPage();
          pdf.addImage({
            imageData: data,
            format: "PNG",
            x: 0,
            y: 0,
            width: 210,
            height: 297,
            compression: "FAST",
          });
          addPdfPage(i + 1, arrayRef.length);
        }
      }
      const fileNAme = `블루카드_${getYMDHMSNoDelimiter(getNewDate())}.pdf`;
      pdf.save(fileNAme, { returnPromise: true }).then((response) => {
        updateShowSpinnerModal(false);
        updateProgress(0);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (arrayRef && arrayRef.length > 0) {
      arrayRef[curPage].current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [curPage]);

  useLayoutEffect(() => {
    if (!props.portfolioBlueCard || props.portfolioBlueCard.length === 0)
      return;
    updateArrayRef(
      props.portfolioBlueCard.map((i) => {
        return createRef<HTMLDivElement>();
      })
    );
  }, []);

  return (
    <PDFViewer>
      <Header>
        <span className="font-medium-20">블루카드 미리보기</span>
        <Close className="close" onClick={props.closeBlueCardPDFView} />
      </Header>
      <Body className="scroll__invisible">
        <BluecardPreviewContainer>
          <div
            ref={bluecardPrevRef}
            className="scroll__invisible bluecard__preview__container"
            onScroll={(e) => {
              if (bluecardPrevRef.current) {
                if (bluecardPrevRef.current.scrollLeft % 630 === 0) {
                  updateCurPage(bluecardPrevRef.current.scrollLeft / 630);
                }
              }
            }}
          >
            {props.portfolioBlueCard?.map((item, index) => {
              return (
                <div
                  key={index}
                  ref={arrayRef[index]}
                  style={{ height: "max-content", scrollSnapAlign: "start" }}
                >
                  <BluecardPrint
                    portfolioBluecard={item}
                    bluecardFilter={bluecardFilter}
                  />
                </div>
              );
            })}
          </div>
          {props.portfolioBlueCard &&
            props.portfolioBlueCard.length > 1 &&
            curPage !== 0 && (
              <div
                className="bluecard__page__btn prev"
                onClick={() => {
                  updateCurPage((prev) => prev - 1);
                }}
              />
            )}
          {props.portfolioBlueCard &&
            props.portfolioBlueCard.length > 1 &&
            curPage !== props.portfolioBlueCard.length - 1 && (
              <div
                className="bluecard__page__btn next"
                onClick={() => {
                  updateCurPage((prev) => prev + 1);
                }}
              />
            )}
          {props.portfolioBlueCard && props.portfolioBlueCard.length > 1 && (
            <div className="bluecard__page__navigation font-regular-14">
              {`${curPage + 1}/${props.portfolioBlueCard.length}`}
            </div>
          )}
        </BluecardPreviewContainer>
        <FilterMenuContainer>
          <FilterMenu>
            <div className="filter__group" style={{ marginTop: 28 }}>
              <div className="filter__check__item">
                {bluecardFilter.companyValue &&
                bluecardFilter.investmentAmount &&
                bluecardFilter.userName &&
                bluecardFilter.userEmail &&
                bluecardFilter.userPhone &&
                bluecardFilter.investmentHighlight &&
                bluecardFilter.problem &&
                bluecardFilter.solutionBusinessModel &&
                bluecardFilter.technology &&
                bluecardFilter.productStatus ? (
                  <CheckBoxChecked
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("all");
                    }}
                  />
                ) : (
                  <CheckBox
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("all");
                    }}
                  />
                )}
                <span
                  className="font-medium-14 pointer"
                  onClick={() => {
                    checkedFilter("all");
                  }}
                >
                  전체항목 보여주기
                </span>
              </div>
            </div>
            <div className="divider" />

            <div className="filter__group" style={{ marginTop: 16 }}>
              <div className="filter__check__item" style={{ marginBottom: 2 }}>
                {bluecardFilter.companyValue &&
                bluecardFilter.investmentAmount ? (
                  <CheckBoxChecked
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("companyInfo");
                    }}
                  />
                ) : (
                  <CheckBox
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("companyInfo");
                    }}
                  />
                )}
                <span
                  className="font-medium-14 pointer"
                  onClick={() => {
                    checkedFilter("companyInfo");
                  }}
                >
                  기업정보
                </span>
              </div>
              <div className="filter__group__items">
                <div className="filter__check__item">
                  {bluecardFilter.companyValue ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("companyValue");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("companyValue");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("companyValue");
                    }}
                  >
                    기업가치
                  </span>
                </div>
                <div className="filter__check__item">
                  {bluecardFilter.investmentAmount ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("investmentAmount");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("investmentAmount");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("investmentAmount");
                    }}
                  >
                    투자금
                  </span>
                </div>
              </div>
            </div>

            <div className="filter__group">
              <div className="filter__check__item" style={{ marginBottom: 2 }}>
                {bluecardFilter.userName &&
                bluecardFilter.userEmail &&
                bluecardFilter.userPhone ? (
                  <CheckBoxChecked
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("participantInfo");
                    }}
                  />
                ) : (
                  <CheckBox
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("participantInfo");
                    }}
                  />
                )}
                <span
                  className="font-medium-14 pointer"
                  onClick={() => {
                    checkedFilter("participantInfo");
                  }}
                >
                  담당자 정보
                </span>
              </div>
              <div className="filter__group__items">
                <div className="filter__check__item">
                  {bluecardFilter.userName ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("userName");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("userName");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("userName");
                    }}
                  >
                    담당자명
                  </span>
                </div>
                <div className="filter__check__item">
                  {bluecardFilter.userPhone ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("userPhone");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("userPhone");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("userPhone");
                    }}
                  >
                    담당자 전화번호
                  </span>
                </div>
              </div>
              <div className="filter__group__items">
                <div className="filter__check__item">
                  {bluecardFilter.userEmail ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("userEmail");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("userEmail");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("userEmail");
                    }}
                  >
                    담당자 이메일
                  </span>
                </div>
              </div>
            </div>

            <div className="filter__group">
              <div className="filter__check__item" style={{ marginBottom: 2 }}>
                {bluecardFilter.investmentHighlight &&
                bluecardFilter.problem &&
                bluecardFilter.solutionBusinessModel &&
                bluecardFilter.technology &&
                bluecardFilter.productStatus ? (
                  <CheckBoxChecked
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("bluecardInfo");
                    }}
                  />
                ) : (
                  <CheckBox
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("bluecardInfo");
                    }}
                  />
                )}
                <span
                  className="font-medium-14 pointer"
                  onClick={() => {
                    checkedFilter("bluecardInfo");
                  }}
                >
                  블루카드
                </span>
              </div>
              <div className="filter__group__items">
                <div className="filter__check__item">
                  {bluecardFilter.investmentHighlight ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("investmentHighlight");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("investmentHighlight");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("investmentHighlight");
                    }}
                  >{`Investment Highlight`}</span>
                </div>
              </div>
              <div className="filter__group__items">
                <div className="filter__check__item">
                  {bluecardFilter.problem ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("problem");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("problem");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("problem");
                    }}
                  >{`Problem`}</span>
                </div>
              </div>
              <div className="filter__group__items">
                <div className="filter__check__item">
                  {bluecardFilter.solutionBusinessModel ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("solutionBusinessModel");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("solutionBusinessModel");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("solutionBusinessModel");
                    }}
                  >{`Solution & Business Model`}</span>
                </div>
              </div>
              <div className="filter__group__items">
                <div className="filter__check__item">
                  {bluecardFilter.technology ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("technology");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("technology");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("technology");
                    }}
                  >{`Technology`}</span>
                </div>
              </div>
              <div className="filter__group__items">
                <div className="filter__check__item">
                  {bluecardFilter.productStatus ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("productStatus");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("productStatus");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("productStatus");
                    }}
                  >{`Product Status`}</span>
                </div>
              </div>
            </div>
            <div className="filter__group" style={{ marginTop: 16 }}>
              <div className="filter__check__item" style={{ marginBottom: 2 }}>
                {bluecardFilter.irProposalSurmmary &&
                bluecardFilter.irProposalDetail ? (
                  <CheckBoxChecked
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("irProposal");
                    }}
                  />
                ) : (
                  <CheckBox
                    className="parent__icon"
                    onClick={() => {
                      checkedFilter("irProposal");
                    }}
                  />
                )}
                <span
                  className="font-medium-14 pointer"
                  onClick={() => {
                    checkedFilter("irProposal");
                  }}
                >
                  IR
                </span>
              </div>
              <div className="filter__group__items">
                <div className="filter__check__item">
                  {bluecardFilter.irProposalSurmmary ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("irProposalSurmmary");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("irProposalSurmmary");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("irProposalSurmmary");
                    }}
                  >
                    요약 IR
                  </span>
                </div>
                <div className="filter__check__item">
                  {bluecardFilter.irProposalDetail ? (
                    <CheckBoxChecked
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("irProposalDetail");
                      }}
                    />
                  ) : (
                    <CheckBox
                      className="child__icon"
                      onClick={() => {
                        checkedFilter("irProposalDetail");
                      }}
                    />
                  )}
                  <span
                    className="font-regular-12 pointer"
                    onClick={() => {
                      checkedFilter("irProposalDetail");
                    }}
                  >
                    상세 IR
                  </span>
                </div>
              </div>
            </div>
          </FilterMenu>
          <DownloadWrapper>
            <DefaultButton
              className="body-14-sb"
              buttonType="filled"
              sizeType="small"
              buttonColor="var(--primary-blue)"
              hoverColor="var(--blue-600)"
              text="다운로드"
              onClick={handleDownloadPdf}
            />

            <DefaultButton
              className="body-14-sb"
              buttonType="filled"
              sizeType="small"
              buttonColor="var(--primary-blue)"
              hoverColor="var(--blue-600)"
              text="링크생성"
              onClick={async () => {
                if (!props.portfolioBlueCard) return;

                const checkedData: string[] = [];
                if (bluecardFilter.companyValue) {
                  checkedData.push("companyValue");
                }
                if (bluecardFilter.investmentAmount) {
                  checkedData.push("investmentAmount");
                }
                if (bluecardFilter.userName) {
                  checkedData.push("userName");
                }
                if (bluecardFilter.userEmail) {
                  checkedData.push("userEmail");
                }
                if (bluecardFilter.userPhone) {
                  checkedData.push("userPhone");
                }
                if (bluecardFilter.investmentHighlight) {
                  checkedData.push("investmentHighlight");
                }
                if (bluecardFilter.problem) {
                  checkedData.push("problem");
                }
                if (bluecardFilter.solutionBusinessModel) {
                  checkedData.push("solutionBusinessModel");
                }
                if (bluecardFilter.productStatus) {
                  checkedData.push("productStatus");
                }
                if (bluecardFilter.technology) {
                  checkedData.push("technology");
                }
                if (bluecardFilter.irProposalSurmmary) {
                  checkedData.push("irProposalSurmmary");
                }
                if (bluecardFilter.irProposalDetail) {
                  checkedData.push("irProposalDetail");
                }

                const result = props.portfolioBlueCard.map((item) => {
                  const resultData = {
                    portfolioId: item.portfolioId,
                    activeItem: checkedData,
                  };
                  return resultData;
                });

                const blueLinkResult = await createBluecardLink(result);

                if (blueLinkResult && blueLinkResult.success) {
                  props.callback(blueLinkResult.pfLinkId);
                }
              }}
            />
          </DownloadWrapper>
        </FilterMenuContainer>
      </Body>

      {showSpinnerModal && (
        <ModalLayout isOpen={showSpinnerModal} isFullScreen>
          <ProgressModalStatic
            SvgIcon={Pdf}
            progress={progress}
            message={"블루카드 PDF파일 생성중..."}
            progressFail={false}
            transfromPixel={(n) => n}
          />
        </ModalLayout>
      )}
    </PDFViewer>
  );
};

const PDFViewer = styled.div`
  width: max-content;
  height: 90vh;
  max-height: calc(56px + (297px * 3) + 48px);

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid #c4c4c4;
  .close {
    cursor: pointer;
  }
`;

const Body = styled.div`
  width: max-content;
  height: calc(100% - 56px);
  padding: 24px 24px 30px 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow: scroll;
`;

const BluecardPreviewContainer = styled.div`
  position: relative;
  width: calc(210px * 3);
  min-width: calc(210px * 3);
  max-width: calc(210px * 3);
  height: 100%;
  max-height: calc(297px * 3);

  .bluecard__preview__container {
    width: calc(210px * 3);
    min-width: calc(210px * 3);
    max-width: calc(210px * 3);
    height: 100%;
    max-height: calc(297px * 3);
    overflow: scroll;
    display: flex;
    flex-direction: row;
    border: 1px solid #dcdcdc;
    scroll-snap-type: x mandatory;
  }

  .bluecard__page__navigation {
    position: absolute;

    bottom: 24px;
    right: 24px;

    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 45px;
    min-height: 20px;
    background: rgba(0, 0, 0);
    border-radius: 8px;
    padding: 8px 16px;
    color: #ffffff;
    opacity: 0.4;

    font-size: 14px;
  }

  .bluecard__page__btn {
    position: absolute;
    display: none;
    width: 48px;
    height: 48px;
    background: #000000;
    opacity: 0.2;

    cursor: pointer;

    &.prev {
      top: 50%;
      left: 10px;
      ::after {
        position: absolute;
        top: 16px;
        left: 18px;
        content: "";
        width: 16px;
        height: 16px;
        border-top: 1.5px solid #ffffff;
        border-left: 1.5px solid #ffffff;
        transform: rotate(-45deg);
      }
    }

    &.next {
      top: 50%;

      right: 10px;
      transform: rotate(180deg);
      ::after {
        position: absolute;
        top: 14px;
        left: 18px;
        content: "";
        width: 16px;
        height: 16px;
        border-top: 1.5px solid #ffffff;
        border-left: 1.5px solid #ffffff;
        transform: rotate(-45deg);
      }
    }
  }

  :hover {
    .bluecard__page__navigation {
      display: flex;
    }
    .bluecard__page__btn {
      display: block;
    }
  }
`;

const FilterMenuContainer = styled.div`
  width: 100%;
  min-width: 300px;
  max-width: 352px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const DownloadWrapper = styled.div`
  width: 100%;
  height: 42px;
  min-height: 42px;
  max-height: 42px;

  gap: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const FilterMenu = styled.div`
  width: 100%;
  height: auto;
  background: #fbfbfb;

  display: flex;
  flex-direction: column;

  padding: 0 24px 24px 24px;

  .divider {
    width: 100%;
    height: 1px;
    background-color: #dcdcdc;
    margin-top: 12px;
  }

  .filter__group {
    width: auto;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 10px;

    .filter__check__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      .parent__icon {
        width: 18px;
        min-width: 18px;
        max-width: 18px;
        height: 18px;
        min-height: 18px;
        max-height: 18px;
        cursor: pointer;
      }
      .pointer {
        cursor: pointer;
      }
      .child__icon {
        width: 12px;
        min-width: 12px;
        max-width: 12px;
        height: 12px;
        min-height: 12px;
        max-height: 12px;
        cursor: pointer;
      }
    }

    .filter__group__items {
      display: flex;
      flex-direction: row;
      padding-left: 24px;
      gap: 16px;
    }
  }
`;
export default PreviewBlueCardLink;
