import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TotalSearchTab from "./component/tab/total-search/TotalSearchTab";

import {
  Channel,
  FundAccountType,
  PortfolioPhaseProps,
  StageType,
} from "../../../type/data";
import { getDatabaseHistory } from "../../../common/pageHistoryUtil";

export interface DatabaseSearchData {
  acChannel?: Channel[];
  acFundAccount?: FundAccountType[];
  addr1?: { addr1: string }[];
  pfPhase?: PortfolioPhaseProps[];
  stageType?: StageType[];
}

const MobileDatabase = () => {
  const history = getDatabaseHistory();
  return (
    <Container className="scroll__invisible">
      <div className="filter-container scroll__invisible">
        <TotalSearchTab history={history} />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  .filter-container {
    height: 100%;
  }
`;
export default MobileDatabase;
