import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FinancialStatementInput } from "../FinancialStatementInput";

import { FinancialStatementFullProps } from "../../../../../../../type/data";
import { RemoveComma } from "../../../../../../../common/commonUtil";

interface Props {
  data: FinancialStatementFullProps;
  currency: string;
  onChangeData: (data: FinancialStatementFullProps) => void;
}
export const AssetPage: React.FC<Props> = (props) => {
  const { data, onChangeData, currency } = props;
  return (
    <Container>
      <FinancialStatementInput
        readonly
        title="유동자산"
        value={
          data.currentAssets !== undefined ? `${data.currentAssets}` : undefined
        }
        currency={currency}
      />
      <FinancialStatementInput
        title="당좌자산"
        value={data.cashAssets !== undefined ? `${data.cashAssets}` : undefined}
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, cashAssets: transValue });
        }}
      />
      <FinancialStatementInput
        title="재고자산"
        value={
          data.inventoryAssets !== undefined
            ? `${data.inventoryAssets}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, inventoryAssets: transValue });
        }}
      />
      <FinancialStatementInput
        title="기타 유동자산"
        value={
          data.otherCurrentAssets !== undefined
            ? `${data.otherCurrentAssets}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, otherCurrentAssets: transValue });
        }}
      />
      <FinancialStatementInput
        title="비유동자산"
        readonly
        value={
          data.nonCurrentAssets !== undefined
            ? `${data.nonCurrentAssets}`
            : undefined
        }
        currency={currency}
      />
      <FinancialStatementInput
        title="투자자산"
        value={
          data.investmentAssets !== undefined
            ? `${data.investmentAssets}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, investmentAssets: transValue });
        }}
      />
      <FinancialStatementInput
        title="유형자산"
        value={
          data.tangibleAssets !== undefined
            ? `${data.tangibleAssets}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, tangibleAssets: transValue });
        }}
      />
      <FinancialStatementInput
        title="무형자산"
        value={
          data.intangibleAssets !== undefined
            ? `${data.intangibleAssets}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, intangibleAssets: transValue });
        }}
      />
      <FinancialStatementInput
        title="기타 비유동자산"
        value={
          data.otherNonCurrentAssets !== undefined
            ? `${data.otherNonCurrentAssets}`
            : undefined
        }
        currency={currency}
        onChangeData={(value) => {
          const transValue = parseFloat(RemoveComma(value));
          onChangeData({ ...data, otherNonCurrentAssets: transValue });
        }}
      />
      <FinancialStatementInput
        title="자산총계"
        readonly
        value={
          data.totalAssets !== undefined ? `${data.totalAssets}` : undefined
        }
        currency={currency}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--gray-500);
  background: var(--light-background-contents-01, #fff);
  gap: 8px;
`;
