import React from "react";
import styled from "styled-components";
import { FinanceCard } from "./component/FinanceCard";
import { FinancialStatementModal } from "./component/FinancialStatementModal";
import useFinanceDataInpu from "./hook/useFinanceDataInpu";
import { ReactComponent as BackArrow } from "../../assets/image/icon_back_arrow.svg";
import useGoogle from "../../hooks/useGoogle";
import DefaultButton from "../../components/button/DefaultButton";

const FinanceDataInput = () => {
  const [
    isGapiReady,
    isLoggedIn,
    handleSignInClick,
    handleSignOutClick,
    getPDFBlobFile,
    onLoginLattice,
    createFolder,
  ] = useGoogle(undefined, "googleLogin", undefined, undefined, undefined);
  const [
    fileLoading,
    data,
    selectedData,
    financialStatement,
    onChangeFinancialStatement,
    onSubmit,
    onSubmitAble,
    validationAssets,
    onClickBackBtn,
    onClickItem,
  ] = useFinanceDataInpu(isLoggedIn);

  if (isLoggedIn === undefined) return null;

  if (!isLoggedIn) {
    return (
      <Container>
        <Header>
          <FlexRow style={{ alignItems: "center", gap: 16 }}>
            <BackArrow
              className="back__btn"
              onClick={() => {
                onClickBackBtn();
              }}
            />
            <span className="heading-18-sb title">재무정보 입력</span>
          </FlexRow>
        </Header>
        <Body
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 30,
          }}
        >
          <div className="heading-24-sb">
            구글 로그인이 필요합니다. 로그인 먼저 해주세요.
          </div>
          <DefaultButton
            width={408}
            type="outline"
            onClick={() => {
              handleSignInClick();
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img
                src="https://www.svgrepo.com/show/355037/google.svg"
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
              구글 로그인
            </div>
          </DefaultButton>
        </Body>
      </Container>
    );
  } else {
    return (
      <Container>
        <Header>
          <FlexRow style={{ alignItems: "center", gap: 16 }}>
            <BackArrow
              className="back__btn"
              onClick={() => {
                onClickBackBtn();
              }}
            />
            <span className="heading-18-sb title">재무정보 입력</span>
          </FlexRow>
        </Header>
        <Body>
          <BodyLeft>
            {data.map((item, index) => {
              return (
                <FinanceCard
                  title={item.companyName}
                  description={`${item.year}년 ${item.quarterNum}분기 ${
                    item.quarterNum === 1 ? "직전년도 결산" : "가결산"
                  } 재무정보를 입력해주세요.`}
                  isSelected={
                    item.portfolioId === selectedData?.portfolioId &&
                    item.year === selectedData?.year &&
                    item.quarterNum === selectedData?.quarterNum
                  }
                  onClickItem={() => {
                    onClickItem(item);
                  }}
                />
              );
            })}
          </BodyLeft>
          <BodyRight>
            {isGapiReady && selectedData && (
              <FinancialStatementModal
                isGapiReady={isGapiReady}
                year={selectedData.year}
                quarter={selectedData.quarterNum}
                type={selectedData.cloudType}
                pdfKey={selectedData.pdfKey}
                financialStatement={financialStatement}
                onChangeFinancialStatement={onChangeFinancialStatement}
                submit={() => {
                  onSubmit();
                }}
                onSubmitAble={onSubmitAble}
                validationAssets={validationAssets}
                getPDFBlobFile={getPDFBlobFile}
              />
            )}
          </BodyRight>
        </Body>
      </Container>
    );
  }
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Header = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  gap: 16px;

  border-bottom: 1px solid var(--divider-200);

  .title {
    color: var(--text-01);
  }

  .back__btn {
    cursor: pointer;
  }
`;
const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: var(--background-page);
`;

const BodyLeft = styled.div`
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  border-right: 1px solid var(--gray-500);
  background-color: var(--gray-300);
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const BodyRight = styled.div`
  width: calc(100% - 300px);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Empty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default FinanceDataInput;
