import React from "react";
import styled from "styled-components";

import { FundAccountInfoForDashboard } from "../../../fund-account-info/interface/FundAccountInfo.interface";
import { Expectation } from "../../../performance-metrics/mobile/organism/Expectation";
import { SurvivalRate } from "../../../performance-metrics/mobile/organism/SurvivalRate";

interface Props {
  selectedFundAccountId: number[];
  selectedIndustryId?: number[];
}

export const ExpectationManagementSection: React.FC<Props> = (props) => {
  const { selectedFundAccountId, selectedIndustryId } = props;

  return (
    <Container>
      <SectionHeader className="heading-20-b">
        <span>전망관리</span>
      </SectionHeader>
      <FlexColumn style={{ gap: 16 }}>
        <Expectation
          selectedFundAccountId={selectedFundAccountId}
          selectedIndustrialTechId={selectedIndustryId}
        />
        <SurvivalRate
          selectedFundAccountId={selectedFundAccountId}
          selectedIndustrialTechId={selectedIndustryId}
        />
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 20px;

  border-bottom: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
