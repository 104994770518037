import React from "react";
import styled from "styled-components";
import { ReactComponent as DefaultUser } from "../../../../../assets/image/default_user_img.svg";
import { ResultUserInfo } from "../../interface/PrePitchingDetail.interface";
interface Props {
  type: "long" | "short";
  textAnswer: {
    acUser: ResultUserInfo;
    text: string;
  };
  userInfoHidden?: boolean;
}
export const TextListRow: React.FC<Props> = (props) => {
  const { type, textAnswer, userInfoHidden } = props;
  return (
    <Container className="body-14-rg">
      {!userInfoHidden && (
        <UserContainer>
          <Contents style={{ paddingTop: 13 }}>
            <Avatar>
              <AvatarContainer>
                {textAnswer.acUser.profileImgUrl ? (
                  <img
                    className="avatar"
                    src={textAnswer.acUser.profileImgUrl}
                    loading="lazy"
                    alt={"profile img"}
                  />
                ) : (
                  <DefaultUser className="avatar" />
                )}
              </AvatarContainer>
              <span>{textAnswer.acUser.name}</span>
            </Avatar>
          </Contents>
        </UserContainer>
      )}
      <ResultContainer>
        <Contents style={{ paddingTop: 21, paddingBottom: 21 }}>
          {type === "long" ? (
            <pre
              className="body-14-rg"
              style={{
                height: "fit-contents",
                whiteSpace: "break-spaces",
                wordBreak: "break-all",
              }}
            >
              {textAnswer.text}
            </pre>
          ) : (
            <div className="body-14-rg">{textAnswer.text}</div>
          )}
        </Contents>
      </ResultContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  min-height: 64px;

  border-bottom: 1px solid var(--divider-300);

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  color: var(--text-01);
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const Avatar = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  height: 38px;
  min-height: 38px;
  max-height: 38px;

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    border: 1px solid var(--gray-200);
    border-radius: 50%;
  }

  .crown__fund__manager {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    path:nth-of-type(1) {
      fill: var(--primary-blue);
    }
  }

  .crown__expert__manager {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    path:nth-of-type(1) {
      fill: var(--blue-300);
    }
  }
`;

const UserContainer = styled.div`
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  height: 100%;

  padding-left: 20px;
`;

const ResultContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;
