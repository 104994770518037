import React, { useEffect, useRef, useState } from "react";
import {
  PerformanceMetricsFilterType,
  PerformanceMetricsTabType,
} from "../interface/PerformanceMetrics.interface";
import {
  FundAccountLight,
  IndustryTech,
  StructureDetailProps,
  UserInfo,
} from "../../../../type/data";
import {
  getAllAcUser,
  getAllIndustrialTech,
  getAllStructureDetail,
} from "../../../../api/repository/accelerator/AcceleratorRepository";

import { getMyDetailInfo } from "../../../../common/commonApiData";

import { getAllFundAccountLight } from "../../../../api/repository/accelerator/DashboardRepository";

const usePerformanceMetrics = () => {
  const [selectedTab, updateSelectedTap] = useState<
    PerformanceMetricsTabType | undefined
  >(PerformanceMetricsTabType.COMPANY);

  const [allStructure, setAllStructure] = useState<
    StructureDetailProps[] | undefined
  >(undefined);

  const [industry, setIndustry] = useState<IndustryTech[] | undefined>(
    undefined
  );
  const [structureId, setStructureId] = useState<number | undefined>(undefined);
  const [allUser, setAllUser] = useState<UserInfo[] | undefined>(undefined);
  const [selectedUser, setSelectedUser] = useState<UserInfo | undefined>(
    undefined
  );
  const [fundAccount, setFundAccount] = useState<
    FundAccountLight[] | undefined
  >(undefined);

  const [loaded, setLoaded] = useState<boolean>(false);

  const onChangeTab = (tab: PerformanceMetricsTabType) => {
    updateSelectedTap(tab);
  };

  const onChangeStructure = (id: number) => {
    setStructureId(id);
  };

  const onChangeUser = (user: UserInfo) => {
    setSelectedUser(user);
  };

  const init = async () => {
    const fundAccountResult = await getAllFundAccountLight();
    const industryResult = await getAllIndustrialTech();
    const userResult = await getAllAcUser("user");
    const allStructure = await getAllStructureDetail();
    const myInfo = await getMyDetailInfo();

    industryResult && setIndustry(industryResult);
    fundAccountResult && setFundAccount(fundAccountResult);
    userResult && setAllUser(userResult);

    if (myInfo && userResult) {
      setSelectedUser(userResult.find((item) => item.id === myInfo.acUserId));
    }

    if (allStructure) {
      const myHeadquarter = allStructure.find(
        (item) => item.acStructureId === myInfo?.acStructureId
      );

      const filterMyHeadquarter = allStructure.filter(
        (item) => item.headquarterId === myHeadquarter?.headquarterId
      );
      const filterOtherHeadquarter = allStructure.filter(
        (item) => item.headquarterId !== myHeadquarter?.headquarterId
      );

      const filteringHeadquarter = [
        ...(filterMyHeadquarter || []),
        ...(filterOtherHeadquarter || []),
      ];
      console.log(filteringHeadquarter);
      setStructureId(myHeadquarter?.headquarterId);
      setAllStructure(filteringHeadquarter);
    }
  };

  useEffect(() => {
    setLoaded(allUser !== undefined && fundAccount !== undefined);
  }, [allUser, fundAccount]);

  useEffect(() => {
    init();
  }, []);
  return [
    loaded,
    allUser,
    fundAccount,
    industry,
    selectedTab,
    selectedUser,
    allStructure,
    structureId,
    onChangeTab,
    onChangeStructure,
    onChangeUser,
  ] as const;
};

export default usePerformanceMetrics;
