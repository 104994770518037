import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import { FileType, IAReportAccount } from "../../../../../type/data";
import { ReactComponent as Plus } from "../../../../../assets/image/plus.svg";
import { DefaultButton } from "lattice_core";
import {
  uploadFile,
  deleteFile,
} from "../../../../../api/repository/common/CommonRepository";
import {
  getYMD,
  onDownload,
  showToast,
  viewPdfPopup,
} from "../../../../../common/commonUtil";
import { ReactComponent as Pdf } from "../../../../../assets/image/large_pdf.svg";

import { Bounce } from "react-toastify";
import { showDeskToast } from "../../../../../2.0lattice/toast";

interface Props {
  portfolioId: number;
  isView?: boolean;
  editable?: boolean;
  width?: number | string;
  file?: FileType;
  reportAccount: IAReportAccount;
  downloadEvaluationDocument: (reportAccount: IAReportAccount) => void;
  onChangeFile: (fileInfo: FileType) => void;
  transfromPixel: (value: number) => number;
}

export const EvaluationDocumentFileUpload: React.FC<Props> = (props) => {
  const {
    portfolioId,
    isView,
    editable,
    width = 524,
    file,
    onChangeFile,
    transfromPixel,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const targetFile = e.target.files[0];
    if (targetFile.type !== "application/pdf") {
      showDeskToast("error", "PDF 파일만 업로드 가능합니다.");
    }

    //upload 파일
    const uploadResult = await uploadFile(
      targetFile,
      "investment",
      portfolioId
    );

    //성공시 기존파일 있으면 삭제
    if (uploadResult && file) {
      await deleteFile(file.key);
    }

    //onChnagefile 함수 태우기
    if (uploadResult) {
      onChangeFile(uploadResult);
    }
    e.target.value = "";
  };

  return (
    <Container
      style={{
        width: width,
      }}
    >
      {file ? (
        <FileContainer
          className={`body-14-rg`}
          onClick={() => {
            // console.log(file);
            if (isView) {
              viewPdfPopup(file.key, 1000);
            } else {
              onDownload(file.key, file.fileName);
            }
          }}
        >
          <div className="row__flex" style={{ gap: 6 }}>
            <Pdf width={24} height={24} />
            {file.fileName}
          </div>
        </FileContainer>
      ) : (
        <FileContainer
          className={`body-14-rg ${editable ? "" : "disabled"}`}
          style={{ borderColor: editable ? "var(--gray-300)" : "transparent" }}
          onClick={() => {
            editable && inputRef.current && inputRef.current.click();
          }}
        >
          {editable ? (
            <div className="row__flex" style={{ gap: 2 }}>
              <Plus width={16} height={16} />
              <span style={{ paddingTop: 2 }}>파일 업로드</span>
            </div>
          ) : (
            <div style={{ color: "var(--gray-500)" }}>파일 미업로드</div>
          )}
        </FileContainer>
      )}
      <FileContainer
        className="disabled"
        style={{ padding: 0, borderColor: "transparent" }}
      >
        <div className="row__flex" style={{ gap: 8 }}>
          {editable && (
            <div>
              <DefaultButton
                buttonType="colored-outline"
                buttonColor={"var(--text-01)"}
                borderColor={"var(--gray-400)"}
                className="caption-12-md"
                sizeType="xxsmall"
                text="템플릿 다운로드"
                transfromPixel={transfromPixel}
                onClick={(e) => {
                  e && e.stopPropagation();
                  props.downloadEvaluationDocument(props.reportAccount);
                }}
              />
            </div>
          )}
          {editable && file && (
            <div>
              <DefaultButton
                buttonType="colored-outline"
                buttonColor={"var(--text-01)"}
                borderColor={"var(--gray-400)"}
                className="caption-12-md"
                sizeType="xxsmall"
                text="파일 교체"
                transfromPixel={transfromPixel}
                onClick={(e) => {
                  e && e.stopPropagation();
                  editable && inputRef.current && inputRef.current.click();
                }}
              />
            </div>
          )}
        </div>
        {file && (
          <span className="caption-12-rg" style={{ color: "var(--text-05)" }}>
            업데이트일 : {getYMD(file.updatedAt)}
          </span>
        )}
      </FileContainer>

      <input
        ref={inputRef}
        type="file"
        onChange={onChange}
        disabled={!editable}
        accept="application/pdf"
      />
    </Container>
  );
};

const Container = styled.div(
  () => css`
    height: auto;
    display: flex;
    flex-direction: column;

    input {
      display: none;
    }
  `
);

const FileContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 40px;
  max-height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid var(--gray-300);
  border-radius: 4px;

  transition: all 0.2s ease;

  padding: 8px 12px;

  cursor: pointer;

  &.disabled {
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }

  :hover {
    background-color: var(--hover-100);
  }

  .row__flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
