import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Channel, SelectorProps, UserInfo } from "../../../../type/data";
import { BoxComponent } from "../../../../components-2.0v/box/BoxComponent";
import RowTitleBox from "../../../../components/box/RowTitleBox";
import { ChipsSelector } from "../../../../components-2.0v/selector/ChipsSelector";
import { ExcavationPortfolioChangeDataProps } from "../../interface/ExcavationCreate.interface";
import { Selector } from "../../../../components-2.0v/selector/Selector";
import { PickerDate } from "../../../../components-2.0v/date-picker/PickerDate";
import { getNewDate, getYMD } from "../../../../common/commonUtil";

interface Props {
  allUser: UserInfo[];
  allChannel: Channel[];
  onChangeData: (data: ExcavationPortfolioChangeDataProps) => void;
}
export const CompanyExcavation: React.FC<Props> = (props) => {
  const { allUser, allChannel, onChangeData } = props;
  const [selectedExcavator, updateSelectedExcavator] = useState<
    SelectorProps[]
  >([]);

  const [selectedChannel, updateSelectedChannel] = useState<
    SelectorProps | undefined
  >(undefined);
  const [allSelectorUser, updateAllSelectorUser] = useState<SelectorProps[]>(
    allUser.map((item) => ({
      id: parseInt(`${item.id}`, 10),
      text: item.name,
      data: item,
    }))
  );

  const [allSelectorChannel, updateAllSelectorChannel] = useState<
    SelectorProps[]
  >(
    allChannel.map((item) => ({
      id: parseInt(`${item.acChannelId}`, 10),
      text: item.name,
      data: item,
    }))
  );

  useEffect(() => {
    updateAllSelectorUser(
      allUser.map((item) => ({
        id: parseInt(`${item.id}`, 10),
        text: item.name,
        data: item,
      }))
    );
  }, [allUser]);

  useEffect(() => {
    updateAllSelectorChannel(
      allChannel.map((item) => ({
        id: parseInt(`${item.acChannelId}`, 10),
        text: item.name,
        data: item,
      }))
    );
  }, [allChannel]);

  return (
    <BoxComponent>
      <RowTitleBox title="발굴 채널" required titleWidth={128}>
        <Selector
          selectList={allSelectorChannel}
          width={409}
          placehoder="선택해주세요."
          defaultData={selectedChannel}
          onChangeData={(data) => {
            updateSelectedChannel(data);
            onChangeData({
              type: "acChannel",
              value: {
                acChannelId: data.id,
              },
            });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="발굴 일자" required titleWidth={128}>
        <PickerDate
          defaultDate={getYMD(getNewDate())}
          onChangeDate={(date) => {
            onChangeData({ type: "excavationDate", value: getYMD(date) });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="발굴자" titleWidth={128}>
        <ChipsSelector
          type="single"
          width={409}
          maxHeight={200}
          searchList={allSelectorUser.filter(
            (item) =>
              selectedExcavator.length === 0 ||
              item.id !== selectedExcavator[0].id
          )}
          selectedData={selectedExcavator}
          onChangeUser={(users) => {
            updateSelectedExcavator([...users]);
            onChangeData({
              type: "excavator",
              value:
                users.length > 0
                  ? {
                      acUserId: users[0].id,
                    }
                  : undefined,
            });
          }}
        />
      </RowTitleBox>
    </BoxComponent>
  );
};

const TextBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-01, #1f2022);
  &.is__empty {
    color: var(--text-05);
  }
`;

const TempBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 4px;

  border: 1px solid var(--gray-300);

  padding: 0 12px;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const Chip = styled.div`
  width: auto;
  height: 28px;
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  line-height: 1;

  background: #e8f0ff;
  border-radius: 14px;

  cursor: pointer;

  .chip__delete__btn {
    :hover {
      path {
        fill: black;
      }
    }
  }
`;
