import React from "react";
import styled from "styled-components";
import { ColumnType } from "../../../interface/PrePitchingDetail.interface";

import { ReactComponent as Check } from "../../../../../../assets/image/icon_check_new.svg";

interface Props {
  columns: ColumnType[];
}
export const Multi: React.FC<Props> = (props) => {
  const { columns } = props;
  return (
    <Container>
      {columns.map((item, index) => {
        return (
          <RowItem key={index}>
            <Check className="icon__radio" />
            <TitleContainer>{item.title}</TitleContainer>
          </RowItem>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const RowItem = styled.div`
  position: relative;
  width: fit-content;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  .icon__radio {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
  }

  &.checked {
    path {
      fill: var(--primary-blue);
    }
  }
`;
const TitleContainer = styled.div`
  width: 100%;
  color: var(--text-01);
  line-height: 1;
  padding-top: 2px;
`;
