import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IAFinancialSourcingPlan } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";

import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { CustomTextArea } from "../../table/custom-cell/CustomTextArea";
import { AddComma, RemoveComma } from "../../../../../../common/commonUtil";
import {
  fullTimeStatusList,
  relationshipList,
} from "../../../interface/REI.interface";
import GroupHeader from "../../table/GroupHeader";
import SumRow from "../../table/SumRow";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IAFinancialSourcingPlan[];
  onDataChange: (data: IAFinancialSourcingPlan[]) => void;
}

export const FinancialSourchingPlanTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<
    IAFinancialSourcingPlan[] | undefined
  >(undefined);
  const headers: TableGridHeaderProps[] = [
    {
      header: "자금조달 계획",
      key: "financialSourcingPlan",
      width: 596 - 74 - 160,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomTextArea
            isReadOnly={isReadOnly}
            defaultData={data}
            placeholder={`신주발행\n정부지원금 등`}
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.financialSourcingPlan = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },

    {
      header: "금액 (백만 원)",
      key: "financialSourcingPrice",
      width: 160,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1,000"
            onBlur={(value) => {
              if (!dataList) return;

              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.financialSourcingPrice = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];

  const addRowData = () => {
    const newData: IAFinancialSourcingPlan = {};
    const newDataList: IAFinancialSourcingPlan[] = [
      ...(dataList || []),
      newData,
    ];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    if (props.dataList === undefined) {
      updateDataList([{}]);
    } else {
      updateDataList(props.dataList);
    }
  }, [props.dataList]);

  useEffect(() => {
    if (dataList) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
      <FlexRow className="body-16-md">
        <span style={{ color: "var(--text-04)" }}>총 자금조달 금액</span>
        <span style={{ color: "var(--text-01)" }}>
          {AddComma(
            dataList
              .map((item) => item.financialSourcingPrice || 0)
              .reduce((prev, cur) => prev + cur, 0)
          )}{" "}
          백만원
        </span>
      </FlexRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
  gap: 12px;
  padding-right: 20px;
`;
