import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useResize from "../../../../2.0lattice/useResize";

import ModalLayout from "../../../../components/modal/ModalLayout";
import useEvaluationDetail from "../hook/useEvaluationDetail";
import EvaluationAssessment from "./organisms/assessment/EvaluationAssessment";
import { DefaultButton } from "lattice_core";
import { showToast } from "../../../../common/commonUtil";
import { Bounce } from "react-toastify";

import MinuteDocumentModal from "./organisms/modal/MinuteDocumentModal";
import EvaluationPublishLastCheckModal from "./organisms/modal/EvaluationPublishLastCheckModal";
import ProgressModal from "./organisms/modal/ProgressModal";
import ConfirmModal from "../../../../2.0lattice/components/modal/ConfirmModal";

import DiffInvestmentModal from "./organisms/modal/DiffInvestmentModal";
import { getPortfolioInfo } from "../../../../api/repository/portfolio/PortfolioRepository";
import {
  DealFlowStatus,
  PortfolioNavigationDefaultProps,
} from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import { EvaluationGoogleLoginModal } from "./organisms/modal/EvaluationGoogleLoginModal";
import { showMobileToast } from "../../../../2.0lattice/toast";

export const MobileEvaluationDetail: React.FC<
  PortfolioNavigationDefaultProps
> = (props) => {
  const {
    portfolioNavInfo,
    init,
    isEditable = false,
    setCurrentPageName,
  } = props;
  const [screenRate, transfromPixel] = useResize();
  const [
    portfolioId,
    progressMsg,
    progressIcon,
    openProgress,
    progress,
    progressFail,
    portfolioDealStatus,
    evaluationDocument,
    openEvaluationModal,
    openPublishModal,
    openPublishCancelModal,
    isPublishable,
    isPublished,
    isVoteFinished,
    isSpecialUser,
    isWrongInvestAmountModal,
    openGoogleLoginModal,
    updateOpenProgress,
    updateProgress,
    uploadMinuteDocument,
    updateOpenEvaluationModal,
    updateOpenPublishModal,
    updateOpenPublishCancelModal,
    onChangeEvaluationDocument,
    getEvaluationDocument,
    onCancelPublishEvaluation,
    confirmInvestmentAssessment,
    cancelConfirmInvestmentAssessment,
    setUploadMinuteDocument,
    downloadEvaluationDocument,
    refreshModal,
    updateIsWrongInvestAmountModal,
    checkInvestmentAmount,
    sendExportLinkEmail,
    onPublishEvaluation,
    sendExpertEmail,
    setOpenGoogleLoginModal,
    handleSignInClick,
  ] = useEvaluationDetail(isEditable, setCurrentPageName);

  if (!evaluationDocument) return null;
  return (
    <Container>
      <Wrapper>
        <EvaluationAssessment
          portfolioId={portfolioId}
          document={evaluationDocument}
          isVoteFinished={evaluationDocument.isConfirmed}
          isUpdatedAuth={
            portfolioDealStatus === DealFlowStatus.OPEN &&
            isEditable &&
            !isPublished
          }
          onChangeEvaluationDocument={onChangeEvaluationDocument}
          downloadEvaluationDocument={downloadEvaluationDocument}
          transfromPixel={transfromPixel}
          isSpecialUser={isSpecialUser}
          sendExportLinkEmail={sendExportLinkEmail}
          sendExpertEmail={sendExpertEmail}
        />
        {portfolioDealStatus === DealFlowStatus.OPEN &&
          isEditable &&
          evaluationDocument &&
          !evaluationDocument.isConfirmed && (
            <Footer>
              <FooterWrapper>
                {!isPublished ? (
                  <div>
                    <DefaultButton
                      buttonType="filled"
                      buttonColor={"var(--primary-blue)"}
                      className="heading-16-md"
                      disabled={!isPublishable}
                      sizeType="small"
                      hoverColor={"var(--blue-600)"}
                      text="개최"
                      transfromPixel={transfromPixel}
                      onClick={async (e) => {
                        e && e.stopPropagation();
                        if (!checkInvestmentAmount())
                          return updateIsWrongInvestAmountModal(true);

                        if (
                          evaluationDocument.investmentAssessmentCompanyInfo
                            .companyName === undefined
                        ) {
                          const portfolioInfo = await getPortfolioInfo(
                            portfolioId
                          );
                          if (portfolioInfo) {
                            await onChangeEvaluationDocument({
                              ...evaluationDocument,
                              investmentAssessmentCompanyInfo: {
                                ...evaluationDocument.investmentAssessmentCompanyInfo,
                                companyName: portfolioInfo.companyName,
                              },
                            });
                            updateOpenPublishModal(true);
                          }
                        } else {
                          updateOpenPublishModal(true);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <FlexRow style={{ gap: 12 }}>
                    {isPublished && !evaluationDocument.isConfirmed && (
                      <div>
                        <DefaultButton
                          buttonType="outline"
                          buttonColor={"var(--red-500)"}
                          className="heading-16-md"
                          disabled={!isPublishable}
                          sizeType="small"
                          text="개최 취소"
                          transfromPixel={transfromPixel}
                          onClick={(e) => {
                            e && e.stopPropagation();
                            updateOpenPublishCancelModal(true);
                          }}
                        />
                      </div>
                    )}
                    {!evaluationDocument.isConfirmed && (
                      <div>
                        <DefaultButton
                          buttonType="filled"
                          buttonColor={"var(--primary-blue)"}
                          className="heading-16-md"
                          disabled={!isVoteFinished}
                          sizeType="small"
                          hoverColor={"var(--blue-600)"}
                          text={"투심 확정"}
                          transfromPixel={transfromPixel}
                          onClick={async (e) => {
                            e && e.stopPropagation();
                            setUploadMinuteDocument(true);
                          }}
                        />
                      </div>
                    )}
                  </FlexRow>
                )}
              </FooterWrapper>
            </Footer>
          )}
      </Wrapper>
      {openProgress && (
        <ModalLayout isOpen={openProgress} isFullScreen>
          <ProgressModal
            SvgIcon={progressIcon}
            progress={progress}
            message={progressMsg}
            progressFail={progressFail}
            transfromPixel={transfromPixel}
          />
        </ModalLayout>
      )}

      {uploadMinuteDocument && evaluationDocument && (
        <ModalLayout
          isFullScreen
          // isTransparent
          isOpen={uploadMinuteDocument && evaluationDocument !== undefined}
        >
          <MinuteDocumentModal
            portfolioId={portfolioId}
            reportAccount={evaluationDocument.investmentAssessmentReportAccount}
            investmentAssessmentId={evaluationDocument.investmentAssessmentId}
            onFaileCallback={() => {
              showToast({
                customElement: (
                  <div
                    className="heading-16-sb"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span>투자심사 확정에 실패하였습니다.</span>
                  </div>
                ),
                backgroundColor: "var(--red-500)",
                color: "var(--text-white)",
                width: transfromPixel(274),
                milliSec: 1500,
                transition: Bounce,
                position: "bottom-center",
                toastId: new Date().toString(),
              });
            }}
            callback={() => {
              confirmInvestmentAssessment();
            }}
          />
        </ModalLayout>
      )}

      {openPublishModal && evaluationDocument && (
        <ModalLayout
          isOpen={openPublishModal}
          isFullScreen
          toggle={() => {
            updateOpenPublishModal(false);
          }}
        >
          <EvaluationPublishLastCheckModal
            portfolioId={portfolioId}
            document={evaluationDocument}
            close={() => {
              updateOpenPublishModal(false);
            }}
            callbackData={async () => {
              if (!evaluationDocument) return;

              const result = await onPublishEvaluation();
              if (result) {
                showMobileToast("success", "투자심사를 개최하였습니다.");
              }
            }}
            transfromPixel={transfromPixel}
          />
        </ModalLayout>
      )}

      {openPublishCancelModal && (
        <ModalLayout
          isFullScreen
          isOpen={openPublishCancelModal}
          toggle={() => {
            updateOpenPublishCancelModal(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"개최 취소"}
            contents={[
              `지금 개최를 취소하시면`,
              `투자 심사 위원에 다시 투표를 요청해야 합니다.`,
              `개최를 취소하시겠습니까?`,
            ]}
            positiveTitle={"예"}
            negativeTitle={"아니오"}
            onClickNegativeBtn={() => {
              updateOpenPublishCancelModal(false);
            }}
            onClickPositiveBtn={async () => {
              if (!evaluationDocument) return;
              const result = await onCancelPublishEvaluation();
              if (result) {
                showMobileToast("success", "개최취소를 완료하였습니다.");
              }
              updateOpenPublishCancelModal(false);
            }}
          />
        </ModalLayout>
      )}

      {refreshModal && (
        <ModalLayout isFullScreen isOpen={refreshModal}>
          <ConfirmModal
            type="alert"
            transfromPixel={transfromPixel}
            title={"작업 실패"}
            contents={[
              `동시 작업으로 인해 요청하신 작업을 수행하지 못하였습니다.`,
              `다시 시도 해주세요.`,
            ]}
            positiveTitle={"확인"}
            onClickPositiveBtn={() => {
              location.reload();
            }}
          />
        </ModalLayout>
      )}
      {isWrongInvestAmountModal && evaluationDocument && (
        <ModalLayout isFullScreen isOpen={isWrongInvestAmountModal}>
          <DiffInvestmentModal
            document={evaluationDocument}
            close={() => {
              updateIsWrongInvestAmountModal(false);
            }}
          />
        </ModalLayout>
      )}
      {openGoogleLoginModal && (
        <ModalLayout
          isFullScreen
          isOpen={openGoogleLoginModal}
          toggle={() => {
            setOpenGoogleLoginModal(false);
          }}
        >
          <EvaluationGoogleLoginModal
            handleSignInClick={handleSignInClick}
            close={() => {
              setOpenGoogleLoginModal(false);
            }}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  position: sticky;
  bottom: 0px;
  width: 100%;

  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--background-contents01);
  border-top: 2px solid var(--divider-100);
`;

const FooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
