import React from "react";
import styled from "styled-components";

interface Props {
  isFinished: boolean;
}
export const VoteResultListHeader: React.FC<Props> = (props) => {
  const { isFinished } = props;
  return (
    <Container className="body-14-rg">
      <UserContainer>
        <Contents>심사역</Contents>
      </UserContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 34px;
  min-height: 34px;
  max-height: 34px;

  border-bottom: 1px solid var(--divider-300);
  background-color: var(--blue-50);

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  color: var(--text-05);
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 20px;
`;

export const ResultContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  padding-left: 20px;
`;
