import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import PortfolioNavigation from "./organism/PortfolioNavigation";
import usePortfolioDetail from "../hook/usePortfolioDetail";
import ModalLayout from "../../../../components/modal/ModalLayout";
import { PortfolioDropModal } from "../../../../components-2.0v/modal/PortfolioDropModal";
import { ContractRequestModal } from "./organism/ContractRequestModal";
import { ReactComponent as Arrow } from "../../../../2.0lattice/assets/image/new_icon_arrow.svg";
import { ReactComponent as Alarm } from "../../../../assets/image/icon_alarm.svg";

import ConfirmModal from "../../../../2.0lattice/components/modal/ConfirmModal";
import Notification from "../../../notification/Notification";

const DeskPortfolioDetail = () => {
  const [
    alarmIconRef,
    rightModalRef,
    openRightModal,
    alarmNew,
    myinfo,
    pathRoute,
    isEditableUser,
    portfolioNavInfo,
    currentMenu,
    dropTargetPortfolio,
    requestContractPortfolio,
    emptyData,
    duplicateData,
    cancelRequestContractModal,
    nextDealModal,
    setNextDealModal,
    onClickNextDeal,
    onNextDeal,
    setCancelRequestContractModal,
    setRequestContractPortfolio,
    setDropTargetPortfolio,
    getContents,
    goToMenu,
    exitPortfolio,
    onClickDropButton,
    dropComplete,
    onClickRequestContract,
    onRequestContract,
    goToPage,
    onClickRequestContractCancel,
    onRequestContractCancel,
    updateRightModal,
    setAlarmNew,
    navigationBack,
  ] = usePortfolioDetail();

  return (
    <Container>
      <Navigation className="scroll__invisible">
        <PortfolioNavigation
          isEditableUser={isEditableUser}
          portfolioInfo={portfolioNavInfo}
          emptyData={emptyData}
          duplicateData={duplicateData}
          goToMenu={goToMenu}
          currentMenu={currentMenu}
          exitPortfolio={exitPortfolio}
          onClickDropButton={onClickDropButton}
          onClickRequestContract={onClickRequestContract}
          onClickRequestContractCancel={onClickRequestContractCancel}
          onClickNextDeal={onClickNextDeal}
        />
      </Navigation>
      <ContentBody>
        <ContentsHeader>
          <LocationPathContainer>
            {pathRoute.map((item, index) => {
              return index < pathRoute.length - 1 ? (
                <div
                  key={index}
                  className="prev__path__container"
                  onClick={() => {
                    console.log(item);
                    goToPage(item.pageAddress);
                  }}
                >
                  <span
                    className="caption-12-rg prev__path__item"
                    style={{ fontSize: 12 }}
                  >
                    {item.pageName}
                  </span>
                  <Arrow className="path__arrow" />
                </div>
              ) : (
                <span
                  key={index}
                  className="caption-12-rg current_path"
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    console.log(item);
                    goToPage(item.pageAddress);
                  }}
                >
                  {item.pageName}
                </span>
              );
            })}
          </LocationPathContainer>
          <HeaderRight>
            <AlarmContainer
              ref={alarmIconRef}
              className={openRightModal ? "open" : "close"}
              transfromPixel={(n) => n}
              onClick={() => {
                updateRightModal((pre) => !pre);
              }}
            >
              <Alarm className="alarm__icon" />
              {alarmNew && <AlarmNewDot transfromPixel={(n) => n} />}
            </AlarmContainer>
            <UserInfoContainer>
              <span
                className="body-14-rg user__name"
                style={{ fontSize: 14 }}
                onClick={() => {
                  goToPage("/mypage");
                }}
              >
                {myinfo.name}
              </span>
              {/* <div className="user__option__btn">
              <Arrow
                className="user__option__icon"
                width={transfromPixel(16)}
                height={transfromPixel(16)}
              />
            </div> */}
            </UserInfoContainer>
          </HeaderRight>
        </ContentsHeader>
        <Contents className="scroll__invisible">
          {getContents()}
          <RightModal
            ref={rightModalRef}
            className={openRightModal ? "open" : ""}
          >
            <Notification
              onChangeNewNoti={(result) => {
                setAlarmNew(result);
              }}
              closeRightModal={() => {
                updateRightModal(false);
              }}
            />
          </RightModal>
        </Contents>
      </ContentBody>

      {nextDealModal && (
        <ModalLayout
          isFullScreen
          isOpen={nextDealModal}
          toggle={() => {
            setNextDealModal(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            title="후행 투자 검토"
            contents={["후행 투자 검토 단계로 넘어가시겠습니까?"]}
            onClickPositiveBtn={() => {
              setNextDealModal(false);
              onNextDeal();
            }}
            onClickNegativeBtn={() => {
              setNextDealModal(false);
            }}
          />
        </ModalLayout>
      )}

      {cancelRequestContractModal && (
        <ModalLayout
          isFullScreen
          isOpen={cancelRequestContractModal}
          toggle={() => {
            setCancelRequestContractModal(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            title="계약요청 취소"
            contents={["계약 요청을 취소하시겠습니까?"]}
            onClickPositiveBtn={() => {
              setCancelRequestContractModal(false);
              onRequestContractCancel();
            }}
            onClickNegativeBtn={() => {
              setCancelRequestContractModal(false);
            }}
          />
        </ModalLayout>
      )}

      {dropTargetPortfolio && (
        <ModalLayout
          isFullScreen
          isOpen={dropTargetPortfolio !== undefined}
          toggle={() => {
            setDropTargetPortfolio(undefined);
          }}
        >
          <PortfolioDropModal
            targetData={dropTargetPortfolio}
            close={() => setDropTargetPortfolio(undefined)}
            callback={() => {
              dropComplete();
            }}
          />
        </ModalLayout>
      )}

      {requestContractPortfolio && (
        <ModalLayout
          isFullScreen
          isOpen={requestContractPortfolio !== undefined}
          toggle={() => {
            setRequestContractPortfolio(undefined);
          }}
        >
          <ContractRequestModal
            targetData={requestContractPortfolio}
            close={() => setRequestContractPortfolio(undefined)}
            callback={(id) => {
              onRequestContract(id);
            }}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  overflow: hidden;
  background: #ffffff;
`;

const Navigation = styled.div`
  width: 218px;
  min-width: 218px;
  max-width: 218px;
  background: #ffffff;
  border-right: 2px solid #f3f4f4;
  transition: 0.5s;
  overflow-y: scroll;
`;

const ContentBody = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
  background-color: var(--background-page);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
  background: #ffffff;
  border-bottom: 1px solid #f3f4f4;
`;

const LocationPathContainer = styled.div`
  width: fit-content;
  height: 20px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;

  .prev__path__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    color: #1f2022; // text01

    .prev__path__item {
      transition: all 0.2s ease;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
  .current_path {
    color: #bec0c2; // text-disabled
    cursor: default;
  }
  .path__arrow {
    transform: rotate(180deg);
    path {
      fill: #1f2022; // icon01
    }
  }
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const AlarmContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  position: relative;
  width: ${(props) => props.transfromPixel(32)}px;
  min-width: ${(props) => props.transfromPixel(32)}px;
  max-width: ${(props) => props.transfromPixel(32)}px;
  height: ${(props) => props.transfromPixel(32)}px;
  min-height: ${(props) => props.transfromPixel(32)}px;
  max-height: ${(props) => props.transfromPixel(32)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 50%;
  cursor: pointer;

  .alarm__icon {
    width: ${(props) => props.transfromPixel(20)}px;
    min-width: ${(props) => props.transfromPixel(20)}px;
    max-width: ${(props) => props.transfromPixel(20)}px;
    height: ${(props) => props.transfromPixel(20)}px;
    min-height: ${(props) => props.transfromPixel(20)}px;
    max-height: ${(props) => props.transfromPixel(20)}px;
  }

  &.open {
    path {
      fill: var(--primary-blue);
    }
  }

  :hover {
    background-color: var(--hover-100);
  }
`;

const AlarmNewDot = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  position: absolute;
  top: 5px;
  right: 5px;
  width: ${(props) => props.transfromPixel(6)}px;
  min-width: ${(props) => props.transfromPixel(6)}px;
  max-width: ${(props) => props.transfromPixel(6)}px;
  height: ${(props) => props.transfromPixel(6)}px;
  min-height: ${(props) => props.transfromPixel(6)}px;
  max-height: ${(props) => props.transfromPixel(6)}px;

  border-radius: 50%;
  background-color: var(--red-500);
`;

const UserInfoContainer = styled.div`
  width: auto;
  height: auto;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .user__name {
    color: #1f2022;
    padding: 6px 8px;
  }

  .user__option__btn {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.2s ease;
    cursor: pointer;

    .user__option__icon {
      transform: rotate(-90deg);
      path {
        fill: #1f2022; // icon01
      }
    }

    :hover {
      background-color: #f4f5f7;
    }
  }
`;

const Contents = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  background-color: var(--background-page);
  overflow: hidden;
`;

const RightModal = styled.div`
  position: absolute;
  width: 290px;
  height: calc(100% - 24px);

  top: 8px;
  right: -290px;

  background-color: white;
  z-index: 10;
  transition: all 0.2s ease;
  box-shadow: -2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  border-radius: 8px;
  &.open {
    right: 16px;
  }
`;

export default DeskPortfolioDetail;
