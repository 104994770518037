import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  getFinanceDataEmptyList,
  registeFinanceData,
} from "../../../api/repository/portfolio/PortfolioRepository";
import { FinanaceDataListProps } from "../interface/FinanceDataInput.interface";
import {
  OnlyFloat,
  RemoveComma,
  getNewDate,
  showToast,
} from "../../../common/commonUtil";
import {
  FinancialStatementProps,
  FinancialStatementType,
} from "../../../2.0lattice/router/reporting/components/financial-statements/interface/FinancialStatement.interface";
import { convertToObject } from "typescript";
import { Bounce } from "react-toastify";
import { gapi } from "gapi-script";
import { useForkRef } from "@mui/material";

const useFinanceDataInpu = (isGoogleLogin?: boolean) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [fileLoading, updateFileLoading] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<
    FinanaceDataListProps | undefined
  >(undefined);
  const [data, setData] = useState<FinanaceDataListProps[]>([]);

  const [financialStatement, updateFinancialStatement] =
    useState<FinancialStatementProps>({});

  const onClickBackBtn = () => {
    navigate("/deal-flow", { replace: true });
  };

  const onClickItem = (item: FinanaceDataListProps) => {
    setSelectedData(item);
    onChangeFinancialStatement("INIT", "");
  };

  const getData = async () => {
    updateFileLoading(true);
    const result = await getFinanceDataEmptyList();
    const arr: FinanaceDataListProps[] = [];

    if (result) {
      result.forEach((item) => {
        item.quarter.forEach((quater) => {
          if (quater.quarterNum === 1) {
            const document = quater.pfReportDocument.find(
              (doc) => doc.acPfDocument.acPfDocumentId === 7
            );
            if (document) {
              arr.push({
                portfolioId: item.portfolioId,
                companyName: item.companyName,
                year: quater.year,
                quarterNum: quater.quarterNum,
                pdfKey: document.documentFile.key,
                cloudType:
                  getNewDate(document.createdAt).getTime() <
                  getNewDate("2023-03-01").getTime()
                    ? "s3"
                    : "google",
              });
            }
          }
          if (quater.quarterNum === 2) {
            const document = quater.pfReportDocument.find(
              (doc) => doc.acPfDocument.acPfDocumentId === 8
            );
            if (document) {
              arr.push({
                portfolioId: item.portfolioId,
                companyName: item.companyName,
                year: quater.year,
                quarterNum: quater.quarterNum,
                pdfKey: document.documentFile.key,
                cloudType:
                  getNewDate(document.createdAt).getTime() <
                  getNewDate("2023-03-01").getTime()
                    ? "s3"
                    : "google",
              });
            }
          }
          if (quater.quarterNum === 4) {
            const document = quater.pfReportDocument.find(
              (doc) => doc.acPfDocument.acPfDocumentId === 8
            );
            if (document) {
              arr.push({
                portfolioId: item.portfolioId,
                companyName: item.companyName,
                year: quater.year,
                quarterNum: quater.quarterNum,
                pdfKey: document.documentFile.key,
                cloudType:
                  getNewDate(document.createdAt).getTime() <
                  getNewDate("2023-03-01").getTime()
                    ? "s3"
                    : "google",
              });
            }
          }
        });
      });
    }

    updateFileLoading(false);

    if (arr.length === 0) {
      showToast({
        customElement: (
          <span className="heading-16-sb">
            재무정보를 모두 입력 하였습니다.
            <br />
            감사합니다. :)
          </span>
        ),
        backgroundColor: "var(--green-400)",
        color: "var(--text-02)",
        width: 274,
        height: 78,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      setTimeout(() => {
        navigate("/deal-flow");
      }, 1000);
    } else {
      setData([...arr]);
      setSelectedData(arr[0]);
    }
  };

  const onChangeFinancialStatement = (
    type: FinancialStatementType,
    value: string
  ) => {
    const transValue = value.length === 0 ? undefined : OnlyFloat(value);
    switch (type) {
      case "SALES":
        updateFinancialStatement({
          ...financialStatement,
          revenue: transValue,
        });
        break;
      case "OPERATING_PROFIT":
        updateFinancialStatement({
          ...financialStatement,
          operatingProfit: transValue,
        });
        break;
      case "NET_PROFIT":
        updateFinancialStatement({
          ...financialStatement,
          netIncome: transValue,
        });
        break;
      case "TOTAL_ASSETS":
        updateFinancialStatement({
          ...financialStatement,
          totalAssets: transValue,
        });
        break;
      case "TOTAL_LIABILITIES":
        updateFinancialStatement({
          ...financialStatement,
          totalLiabilities: transValue,
        });
        break;
      case "TOTAL_EQUITY":
        updateFinancialStatement({
          ...financialStatement,
          totalEquity: transValue,
        });
        break;
      case "INIT":
        updateFinancialStatement({});
        break;
      default:
        break;
    }
  };

  const validationAssets = () => {
    if (
      financialStatement.totalAssets !== undefined &&
      financialStatement.totalAssets.length > 0 &&
      financialStatement.totalLiabilities !== undefined &&
      financialStatement.totalLiabilities.length > 0 &&
      financialStatement.totalEquity !== undefined &&
      financialStatement.totalEquity.length > 0
    ) {
      const tAssets = parseFloat(RemoveComma(financialStatement.totalAssets));
      const tLiabilities = parseFloat(
        RemoveComma(financialStatement.totalLiabilities)
      );
      const tEquity = parseFloat(RemoveComma(financialStatement.totalEquity));
      return tAssets === tLiabilities + tEquity;
    }

    return undefined;
  };

  const onSubmitAble = () => {
    return (
      financialStatement.revenue !== undefined &&
      financialStatement.revenue.length > 0 &&
      financialStatement.operatingProfit !== undefined &&
      financialStatement.operatingProfit.length > 0 &&
      financialStatement.netIncome !== undefined &&
      financialStatement.netIncome.length > 0 &&
      validationAssets()
    );
  };

  const onSubmit = async () => {
    if (!selectedData) return;

    const body = {
      portfolio: {
        portfolioId: selectedData.portfolioId,
      },
      year: selectedData.year,
      quarterNum: selectedData.quarterNum,
      revenue: parseInt(RemoveComma(financialStatement.revenue || 0)),
      operatingProfit: parseInt(
        RemoveComma(financialStatement.operatingProfit || 0)
      ),
      netIncome: parseInt(RemoveComma(financialStatement.netIncome || 0)),
      totalAssets: parseInt(RemoveComma(financialStatement.totalAssets || 0)),
      totalLiabilities: parseInt(
        RemoveComma(financialStatement.totalLiabilities || 0)
      ),
      totalEquity: parseInt(RemoveComma(financialStatement.totalEquity || 0)),
    };
    const result = await registeFinanceData(body);
    if (result) {
      showToast({
        customElement: (
          <span className="heading-16-sb">
            {selectedData.year}년 {selectedData.quarterNum}분기 재무정보 입력
            완료 :)
          </span>
        ),
        backgroundColor: "var(--green-400)",
        color: "var(--text-02)",
        width: 274,
        height: 48,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });

      updateFinancialStatement({});
      getData();
    }
  };

  useEffect(() => {
    if (isGoogleLogin) {
      getData();
    }
  }, [isGoogleLogin]);

  useEffect(() => {
    console.log(selectedData);
  });

  return [
    fileLoading,
    data,
    selectedData,
    financialStatement,
    onChangeFinancialStatement,
    onSubmit,
    onSubmitAble,
    validationAssets,
    onClickBackBtn,
    onClickItem,
  ] as const;
};
export default useFinanceDataInpu;
