import React, { useState } from "react";
import styled from "styled-components";

import ModalLayout from "../../../../../components/modal/ModalLayout";

import { ExpertInfoProps } from "../../../../../type/data";

import { DefaultButton } from "lattice_core";
import { ExpertInfoModal } from "./ExpertInfoModal";

import { ReactComponent as Info } from "../../../../../assets/image/icon_info.svg";

interface Props {
  isRequestExpert?: boolean;
  expertInfo?: ExpertInfoProps;
  cancelRequest: () => void;
  submit: (isRequestExpert: boolean, title: string, email: string) => void;
  sendExpertEmail?: () => void;
}

export const EvaluationExpertOpinion: React.FC<Props> = (props) => {
  const {
    isRequestExpert,
    expertInfo,
    submit,
    cancelRequest,
    sendExpertEmail,
  } = props;
  const [expertInfoModal, setExpertInfoModal] = useState<boolean>(false);

  return (
    <Container>
      <FlexColumn style={{ gap: 8 }}>
        {isRequestExpert && (
          <DescriptionContainer className="caption-12-rg">
            <Info />
            <span>
              이메일로 전문가 의견을 작성할 수 있는 링크와 비밀번호가
              전송됩니다. 전문가가 제출을 완료하면 보고서에서 확인할 수 있어요.
            </span>
          </DescriptionContainer>
        )}
        <FlexRow style={{ gap: 10 }}>
          {isRequestExpert && expertInfo && (
            <DefaultButton
              className="caption-12-md"
              buttonType="colored-outline"
              buttonColor={"var(--blue-400)"}
              borderColor={"var(--gray-400)"}
              text={`전문가 ${expertInfo.expertTitle}(${expertInfo.expertEmail}) 정보수정`}
              onClick={() => setExpertInfoModal(true)}
            />
          )}
          {isRequestExpert && expertInfo && (
            <DefaultButton
              className="caption-12-md"
              buttonType="colored-outline"
              buttonColor={"var(--text-01)"}
              borderColor={"var(--gray-400)"}
              text={`요청 메일 다시보내기`}
              onClick={sendExpertEmail}
            />
          )}
          <div>
            <DefaultButton
              buttonType="colored-outline"
              buttonColor={
                isRequestExpert ? "var(--red-500)" : "var(--text-01)"
              }
              borderColor={"var(--gray-400)"}
              className="caption-12-md"
              text={
                isRequestExpert
                  ? "전문가 의견 요청취소"
                  : "전문가 의견 요청하기"
              }
              onClick={() => {
                if (isRequestExpert) {
                  cancelRequest();
                } else {
                  setExpertInfoModal(true);
                }
              }}
            />
          </div>
        </FlexRow>
      </FlexColumn>

      {expertInfoModal && (
        <ModalLayout
          isOpen={expertInfoModal}
          isFullScreen
          toggle={() => {
            setExpertInfoModal(false);
          }}
        >
          <ExpertInfoModal
            expertInfo={props.expertInfo}
            close={() => {
              setExpertInfoModal(false);
            }}
            submit={(title, email) => {
              submit(true, title, email);
            }}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const DescriptionContainer = styled.div`
  width: fit-content;
  min-width: 524px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 4px;

  margin-top: 8px;

  background-color: var(--gray-50);
  color: var(--text-04);
  border-radius: 4px;
`;
