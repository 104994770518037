import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as Delete } from "../../../../assets/image/chip_delete.svg";

interface Props {
  type:
    | "keyword-general"
    | "keyword-detail"
    | "industry-unselected"
    | "industry-selected";
  data: any;
  label: string;
  onClickDelete?: (data: any) => void;
  onClickChip?: (data: any) => void;
}
const DatabaseChip = ({
  type,
  data,
  label,
  onClickDelete = () => {},
  onClickChip = () => {},
}: Props) => {
  return (
    <Container
      type={type}
      className="font-regular-10"
      onClick={() => onClickChip(data)}
    >
      <span>{label}</span>
      {(type === "keyword-general" || type === "keyword-detail") && (
        <Delete onClick={() => onClickDelete(data)} />
      )}
    </Container>
  );
};

const Container = styled.div(
  ({ type }: { type: string }) => css`
    height: 24px;
    padding: ${type === "keyword-general" || type === "keyword-detail"
      ? "4px 8px"
      : "4px 10px"};
    background: ${type === "keyword-detail"
      ? "var(--BPP_blue)"
      : type === "industry-unselected"
      ? "#F2F8FE"
      : "var(--primary-blue)"};
    color: ${type === "industry-unselected" ? "#ABBBCD" : "white"};
    border-radius: 100px;
    width: fit-content;

    cursor: ${type === "industry-unselected" || type === "industry-selected"
      ? "pointer"
      : ""};

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    svg {
      cursor: pointer;
    }
  `
);

export default DatabaseChip;
