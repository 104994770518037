import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
  ChartOptions,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { AddComma, RemoveComma } from "../../../../../common/commonUtil";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
interface Props {
  data: ChartData;
}

export const FInancialStatementBarChart: React.FC<Props> = (props) => {
  const { data } = props;

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "end",
        labels: {
          boxWidth: 12,
        },
        onClick: (e) => {},
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },

      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const value = parseInt(RemoveComma(tooltipItem.formattedValue));
            console.log(tooltipItem);
            return `${tooltipItem.dataset.label} : ${AddComma(value)}원`;
          },
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: (value, index, ticks) => {
            const tickNum = parseInt(`${value}`) / 1000000;
            if (index === 0) {
              return `${AddComma(tickNum)} (백만)`;
            }
            return AddComma(tickNum);
          },
        },
      },
    },
  };

  return <Chart type="bar" options={options} data={data} />;
};
