import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { DefaultButton } from "lattice_core";
import { SelectorProps } from "../../../../../../type/data";
import { getTimeToServer } from "../../../../../../api/repository/common/CommonRepository";

import {
  excelDownloadFairValueAmount,
  readFairValueAmountExcelFile,
} from "../../../../../../excel-download/createFairValueAmount";
import { Selector } from "../../../structure/components/Selector";
import { FairValueAmountExportProps } from "../../interface/Portfolio.interface";

interface Props {
  selectedYear?: SelectorProps;
  updateSelectedYear: React.Dispatch<
    React.SetStateAction<SelectorProps | undefined>
  >;
  exportExcelTemplete: () => void;
  importFairValueData: (data: FairValueAmountExportProps[]) => void;
}

export const FairValueImport: React.FC<Props> = (props) => {
  const {
    selectedYear,
    updateSelectedYear,
    exportExcelTemplete,
    importFairValueData,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [years, setYears] = useState<SelectorProps[]>([]);
  const initYears = async () => {
    const serverTime = await getTimeToServer();
    if (serverTime) {
      const yearData: SelectorProps[] = [];
      for (let i = serverTime.getFullYear() - 1; i >= 2010; i--) {
        yearData.push({ id: i, text: `${i}`, data: i });
      }
      setYears(yearData);
    } else {
    }
  };

  useEffect(() => {
    initYears();
  }, []);

  return (
    <Container>
      <Description className="body-14-rg">
        <li>공정거래 평가금액 입력할 연도를 선택해주세요</li>
        <li>연도를 선택하셨다면 템플릿을 다운로드 받으세요.</li>
        <li>테플릿에는 해당연도에 입력해야하는 건들이 있습니다.</li>
        <li>템플릿은 수정하지 마십시오. 읽는 과정에 오류가 날 수 있습니다.</li>
        <li>값을 채우고 파일을 업로드 하시면 확인 과정으로 넘어갑니다.</li>
      </Description>
      <ButtonContainer>
        <FlexRow style={{ marginTop: 40, gap: 24 }}>
          <Selector
            selectList={years}
            placehoder="연도선택"
            width={200}
            defaultData={selectedYear}
            onChangeData={(data) =>
              updateSelectedYear({ ...data, id: parseInt(`${data.id}`) })
            }
          />
          <DefaultButton
            className="heading-16-md"
            sizeType="small"
            buttonColor={"var(--green-500)"}
            hoverColor={"var(--green-700)"}
            text={"템플릿 다운"}
            disabled={!selectedYear}
            onClick={exportExcelTemplete}
          />
        </FlexRow>
        <DefaultButton
          className="heading-16-md"
          sizeType="small"
          buttonColor={"var(--primary-purple)"}
          hoverColor={"var(--purple-600)"}
          text={"파일 업로드"}
          onClick={() => {
            inputRef.current?.click();
          }}
        />
        <input
          ref={inputRef}
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              readFairValueAmountExcelFile(e.target.files[0], (res) => {
                console.log(res);
                if (res.isReadSucess) {
                  const emptyData =
                    res.data.filter(
                      (item) =>
                        !item.year ||
                        !item.acFundAccountId ||
                        !item.type ||
                        !item.portfolioId ||
                        !item.companyName ||
                        !item.fundName
                    ).length > 0;

                  if (emptyData) {
                    alert(
                      "파일이 변형되었습니다. 빠진곳이 없는지 다시 한번 확인 하고 재 업로드 해주세요."
                    );
                  } else {
                    importFairValueData(res.data);
                  }
                }
              });
            }
            e.target.value = "";
          }}
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 406px;

  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 0 auto;
  input {
    display: none;
  }
`;

const Description = styled.ol`
  margin-top: 60px;
`;

const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
