import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Help } from "../../../../../../assets/image/icon_tooltip.svg";

interface Props {
  title: string;
  tooltipId?: string;
  transfromPixel: (value: number) => number;
}

const CustomHeader: React.FC<Props> = (props) => {
  const { title, tooltipId, transfromPixel } = props;

  return (
    <Container
      className="body-14-rg"
      style={{
        fontSize: transfromPixel(12),
        padding: `0 ${transfromPixel(20)}px`,
        gap: transfromPixel(2),
      }}
    >
      {title}
      {tooltipId && (
        <Help
          className={tooltipId}
          data-tooltip-delay-hide={2000}
          style={{ outline: "none" }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: #96999f; //text05
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .header__sorting {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    transition: all 0.2s ease;
    cursor: pointer;

    &.selected__sorting {
      path {
        fill: #4584ff;
      }
    }

    &.sorting__option__show {
      path {
        fill: #4584ff;
      }
    }

    :hover {
      path {
        fill: #4584ff;
      }
    }
  }
`;

export default CustomHeader;
