import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { transformObjectToBase64 } from "../../../../common/commonUtil";
import { getFundAccountForDashboard } from "../../../../api/repository/accelerator/AcceleratorRepository";
import { FundAccountInfoForDashboard } from "../interface/FundAccountInfo.interface";
import { getToday } from "../../../../common/commonApiData";

const useFundAccountInfo = () => {
  const navigate = useNavigate();

  const rootRef = useRef<HTMLDivElement>(null);
  const initCompleted = useRef<boolean>(false);
  const [fundAccountList, updateFundAccountList] = useState<
    FundAccountInfoForDashboard[] | undefined
  >(undefined);

  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [toDay, setToday] = useState<string>("");

  const goToFundAccountDetail = (fundAccountId: number, pathName: string) => {
    const data = transformObjectToBase64({
      pageName: pathName,
      fundAccountId: fundAccountId,
    });

    const to = `/dashboard/fund-account/detail/${data}`;
    navigate(to);
  };

  const compareNumber = (a: number, b: number) => {
    return a - b;
  };

  const getFilterFundAccount = () => {
    if (!fundAccountList) return [];

    const filterFundAccount = fundAccountList
      .sort((comp1, comp2) => {
        return compareNumber(comp1.type, comp2.type);
      })
      .filter((item) => {
        return (
          (item.nickName &&
            item.nickName
              .toUpperCase()
              .includes(searchKeyword.toUpperCase())) ||
          (item.serialNumber &&
            item.serialNumber
              .toUpperCase()
              .includes(searchKeyword.toUpperCase()))
        );
      });
    return filterFundAccount;
  };

  const init = async () => {
    const result = await getFundAccountForDashboard();
    const todayResult = await getToday();
    if (todayResult) {
      setToday(todayResult);
    }
    if (result) {
      updateFundAccountList(result);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return [
    rootRef,
    initCompleted.current,
    searchKeyword,
    toDay,
    updateSearchKeyword,
    getFilterFundAccount,
    goToFundAccountDetail,
  ] as const;
};

export default useFundAccountInfo;
