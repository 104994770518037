import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BoxComponent } from "../../../../../components-2.0v/box/BoxComponent";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { ParticipantInfoProps } from "../../../info/interface/PortfolioInfo.interface";
import {
  SelectorProps,
  UserInfo,
  selectTipsData,
} from "../../../../../type/data";
import ModalLayout from "../../../../../components/modal/ModalLayout";
import { ChangeParticipantModal } from "./ChangeParticipantModal";
import { ChipsSelector } from "../../../../../components-2.0v/selector/ChipsSelector";
import { Radio } from "../../../../../components-2.0v/radio-box/Radio";
import { ChangeDataProps } from "../../hook/usePortfolioInfoModify";
import { getYMD } from "../../../../../common/commonUtil";

interface Props {
  participantInfo: ParticipantInfoProps;
  allUser: UserInfo[];
  onChangeData: (data: ChangeDataProps) => void;
}
export const ParticipantInfo: React.FC<Props> = (props) => {
  const { participantInfo, allUser, onChangeData } = props;
  const [mainParticipant, setMainParticipant] = useState<SelectorProps[]>([]);
  const [changeReason, updateChangeReason] = useState<string>("");
  const [subParticipant, setSubParticipant] = useState<SelectorProps[]>([]);

  const [allSelectorUser, updateAllSelectorUser] = useState<SelectorProps[]>(
    allUser.map((item) => ({
      id: parseInt(`${item.id}`, 10),
      text: item.name,
      data: item,
    }))
  );
  const [openChangeParticipantModal, updateOpenChangeParticipantModal] =
    useState<boolean>(false);

  useEffect(() => {
    const mainId = participantInfo.mainParticipant?.acUser.acUserId;
    const subIds = participantInfo.subParticipant?.map(
      (item) => item.acUser.acUserId
    );
    const main = allSelectorUser.filter(
      (item) => parseInt(`${item.id}`) === mainId
    );
    const sub = allSelectorUser.filter((item) =>
      subIds?.includes(parseInt(`${item.id}`))
    );

    console.log(main);
    if (main) setMainParticipant(main);
    if (sub) setSubParticipant(sub);
  }, [allSelectorUser]);

  useEffect(() => {
    updateAllSelectorUser(
      allUser.map((item) => ({
        id: parseInt(`${item.id}`, 10),
        text: item.name,
        data: item,
      }))
    );
  }, [allUser]);

  return (
    <BoxComponent>
      <RowTitleBox title="담당 팀" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            mainParticipant.length === 0 ? "is__empty" : ""
          }`}
        >
          {(mainParticipant &&
            mainParticipant.length > 0 &&
            mainParticipant[0].data.acStructure.name) ||
            "없음"}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="담당자" required titleWidth={128}>
        <TempBox
          onClick={() => {
            updateOpenChangeParticipantModal(true);
          }}
        >
          {mainParticipant && mainParticipant.length > 0 && (
            <Chip className="caption-12-rg">
              {mainParticipant[0].text || ""}
            </Chip>
          )}
        </TempBox>
      </RowTitleBox>
      <RowTitleBox title="조력자" titleWidth={128}>
        <ChipsSelector
          type="multi"
          width={409}
          maxHeight={200}
          searchList={allSelectorUser.filter(
            (item) =>
              mainParticipant &&
              mainParticipant.length > 0 &&
              mainParticipant[0].text !== item.text &&
              !subParticipant.map((item) => item.id).includes(item.id)
          )}
          selectedData={subParticipant}
          onChangeUser={(users) => {
            setSubParticipant(users);
            onChangeData({
              type: "subParticipant",
              value: { user: users, reason: "" },
            });
          }}
        />
      </RowTitleBox>
      <RowTitleBox title="발굴자" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.excavator?.length === 0 ? "is__empty" : ""
          }`}
        >
          {participantInfo.excavator && participantInfo.excavator.length > 0
            ? participantInfo.excavator
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="발굴채널" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.channel?.length === 0 ? "is__empty" : ""
          }`}
        >
          {participantInfo.channel && participantInfo.channel.length > 0
            ? participantInfo.channel
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="발굴일자" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            participantInfo.excavationDate?.length === 0 ? "is__empty" : ""
          }`}
        >
          {participantInfo.excavationDate &&
          participantInfo.excavationDate.length > 0
            ? getYMD(participantInfo.excavationDate)
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="TIPS" required titleWidth={128}>
        <div style={{ marginTop: 10 }}>
          <Radio
            textClassName="caption-14-rg"
            defaultData={
              selectTipsData.find((item) => item.type === participantInfo.tips)
                ?.name
            }
            selectList={selectTipsData.map((item, index): SelectorProps => {
              return {
                id: index,
                text: item.name,
                data: item,
              };
            })}
            onChangeData={(data) => {
              onChangeData({
                type: "tips",
                value: data,
              });
            }}
          />
        </div>
      </RowTitleBox>
      {openChangeParticipantModal && (
        <ModalLayout
          isOpen={openChangeParticipantModal}
          isFullScreen
          toggle={() => updateOpenChangeParticipantModal(false)}
        >
          <ChangeParticipantModal
            allUserList={allSelectorUser}
            defaultUser={mainParticipant}
            close={() => updateOpenChangeParticipantModal(false)}
            setValue={(user, reason) => {
              const sameUser = subParticipant.findIndex(
                (item) => item.id === user.id
              );

              if (sameUser !== -1) {
                const filterSubParticipant = subParticipant.filter(
                  (item, index) => index !== sameUser
                );
                setSubParticipant([...filterSubParticipant]);
                onChangeData({
                  type: "subParticipant",
                  value: { user: filterSubParticipant, reason: "" },
                });
              }

              setMainParticipant([user]);
              updateChangeReason(reason);
              onChangeData({
                type: "mainParticipant",
                value: { user: user, reason: reason },
              });
            }}
          />
        </ModalLayout>
      )}
    </BoxComponent>
  );
};

const TextBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-01, #1f2022);
  &.is__empty {
    color: var(--text-05);
  }
`;

const TempBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 4px;

  border: 1px solid var(--gray-300);

  padding: 0 12px;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const Chip = styled.div`
  width: auto;
  height: 28px;
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  line-height: 1;

  background: #e8f0ff;
  border-radius: 14px;

  cursor: pointer;

  .chip__delete__btn {
    :hover {
      path {
        fill: black;
      }
    }
  }
`;
