import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SBPContentsPropsV2 } from "../../interface/SBP.interface";

import { getSBPData } from "../../../../../../../../reporting/repository/ReportingRepository";

import { Header } from "./components/Header";
import { CompanyTitle } from "./components/CompanyTitle";

import { PageTitle } from "./components/PageTitle";

import { Status1 } from "./components/Status1";
import { Status2 } from "./components/Status2";
import { Status3 } from "./components/Status3";
import { Status4 } from "./components/Status4";
import { Plan1 } from "./components/Plan1";
import { Plan2 } from "./components/Plan2";
import { Plan3 } from "./components/Plan3";
import { Status5 } from "./components/Status5";
import { Footer } from "./components/Footer";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DocumentFile } from "../../../../../../interface/ReportingDetail.interface";

interface Props {
  file: DocumentFile;
  companyName?: string;
  complete: () => void;
  completeProgress?: (current: number, total: number) => void;
}

type ComponentType =
  | "company_title"
  | "page_title"
  | "status_1"
  | "status_2"
  | "status_3"
  | "status_4"
  | "status_5"
  | "plan_1"
  | "plan_2"
  | "plan_3"
  | "divider";

export const convertPixelToCm = (pixels: number) => {
  return Math.round(pixels * (764 / 1200));
};

interface TT {
  type: ComponentType;
  isNextPage?: boolean;
  ref: React.RefObject<HTMLDivElement>;
  component: JSX.Element;
}

interface SBPPageProps {
  currentPage: number;
  totalPage: number;
  children: TT[];
  ref: React.RefObject<HTMLDivElement>;
}

export const getArrayRange = (num: number) => {
  const arr: number[] = [];
  for (let i = 0; i < num; i++) {
    arr.push(i + 1);
  }
  return arr;
};

const SummaryBusinessPlanV2Download: React.FC<Props> = (props: Props) => {
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);
  const ref4 = useRef<HTMLDivElement>(null);
  const ref5 = useRef<HTMLDivElement>(null);
  const ref6 = useRef<HTMLDivElement>(null);
  const ref7 = useRef<HTMLDivElement>(null);
  const ref8 = useRef<HTMLDivElement>(null);
  const ref9 = useRef<HTMLDivElement>(null);
  const ref10 = useRef<HTMLDivElement>(null);
  const ref11 = useRef<HTMLDivElement>(null);
  const ref12 = useRef<HTMLDivElement>(null);
  const ref13 = useRef<HTMLDivElement>(null);

  const loadedArr = useRef<number[]>([]);

  const eventRef = useRef<any>();
  const [sbpDocument, setSBPDocument] = useState<
    SBPContentsPropsV2 | undefined
  >(undefined);

  const [pageArr, setPageArr] = useState<SBPPageProps[] | undefined>(undefined);
  const [loadComplete, setLoadComplete] = useState<boolean>(false);

  const getSBP = async (pfReportDocumentData: number) => {
    const result = await getSBPData(pfReportDocumentData);
    if (result) {
      setSBPDocument(result.data);
    }
  };

  const getOrderData = (data: SBPContentsPropsV2) => {
    const sbpPage: SBPPageProps[] = [];
    const MAX_HEIGHT = 1020;
    let pageTotalHeight = 0;
    let currentPage = 1;

    sbpPage.push({
      currentPage: currentPage,
      children: [],
      totalPage: 0,
      ref: createRef<HTMLDivElement>(),
    });
    const orderDatas: TT[] = [
      {
        type: "company_title",
        ref: ref2,
        component: <CompanyTitle title={props.companyName || ""} />,
      },
      {
        type: "page_title",
        ref: ref3,
        component: (
          <PageTitle
            title={`1.${data.quarter === 2 ? "반기" : "온기"} 사업 진행`}
            description={`${data.quarter === 2 ? data.year : data.year}년 ${
              data.quarter === 2 ? "1월부터 6월까지의" : "1월부터 12월까지의"
            } 현황을 의미합니다.`}
          />
        ),
      },
      {
        type: "status_1",
        ref: ref4,
        component: <Status1 sbpDocument={data} index={1} onLoaded={() => {}} />,
      },
      {
        type: "status_2",
        ref: ref5,
        component: <Status2 sbpDocument={data} index={2} />,
      },
      {
        type: "status_3",
        ref: ref6,
        component: <Status3 sbpDocument={data} index={3} />,
      },
      {
        type: "status_4",
        ref: ref7,
        component: <Status4 sbpDocument={data} index={4} />,
      },
      {
        type: "status_5",
        ref: ref8,
        component: <Status5 sbpDocument={data} index={5} onLoaded={() => {}} />,
      },
      // { type: "divider", ref: ref1, component: <Divider /> },
      {
        type: "page_title",
        ref: ref9,
        isNextPage: true,
        component: (
          <PageTitle
            title={`2.${data.quarter === 2 ? "반기" : "온기"} 사업 계획`}
            description={`${data.quarter === 2 ? data.year : data.year + 1}년 ${
              data.quarter === 2 ? "7월부터 12월까지의" : "1월부터 12월까지의"
            } 계획을 의미합니다.`}
          />
        ),
      },
      {
        type: "plan_1",
        ref: ref10,
        component: <Plan1 sbpDocument={data} index={1} onLoaded={() => {}} />,
      },
      {
        type: "plan_2",
        ref: ref11,
        component: <Plan2 sbpDocument={data} index={2} />,
      },
      {
        type: "plan_3",
        ref: ref12,
        component: <Plan3 sbpDocument={data} index={3} onLoaded={() => {}} />,
      },
    ];

    orderDatas.forEach((item) => {
      console.log(item.ref.current?.clientHeight);
      if (
        pageTotalHeight + (item.ref.current?.clientHeight || 0) + 28 >
          MAX_HEIGHT ||
        item.isNextPage
      ) {
        pageTotalHeight = (item.ref.current?.clientHeight || 0) + 28;
        currentPage += 1;
        sbpPage.push({
          currentPage: currentPage,
          children: [],
          totalPage: 0,
          ref: createRef<HTMLDivElement>(),
        });
      } else {
        pageTotalHeight += (item.ref.current?.clientHeight || 0) + 28;
      }

      sbpPage[currentPage - 1].children.push(item);
    });

    const result = sbpPage.map((item) => ({
      ...item,
      totalPage: sbpPage.length,
    }));
    setPageArr(result);
  };

  const onLoaded = (index: number) => {
    loadedArr.current.push(index);

    console.log(index);

    if (loadedArr.current.length === 4 && sbpDocument) {
      console.log(ref4);
      clearTimeout(eventRef.current);
      eventRef.current = setTimeout(() => {
        getOrderData(sbpDocument);
      }, 1000);
      loadedArr.current = [];
    }
  };

  const downloadPdf = async () => {
    try {
      if (!pageArr || pageArr.length === 0 || !pageArr[0].ref.current) return;

      const firstCanvas = await html2canvas(pageArr[0].ref.current, {
        scale: 5,
        allowTaint: true,
        useCORS: true,
        logging: true,
      });

      const firstData = firstCanvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF("p", "mm", "a4");

      pdf.addImage({
        imageData: firstData,
        format: "PNG",
        x: 0,
        y: 0,
        width: 210,
        height: 297,
        compression: "FAST",
      });
      props.completeProgress && props.completeProgress(1, pageArr.length);
      for (let i = 1; i < pageArr.length; i++) {
        const element = pageArr[i].ref.current;
        if (element) {
          const canvas = await html2canvas(element, {
            scale: 2,
            allowTaint: true,
            useCORS: true,
            logging: true,
          });
          const data = canvas.toDataURL("image/png", 1.0);
          pdf.addPage();
          pdf.addImage({
            imageData: data,
            format: "PNG",
            x: 0,
            y: 0,
            width: 210,
            height: 297,
            compression: "FAST",
          });
          props.completeProgress &&
            props.completeProgress(i + 1, pageArr.length);
        }
      }
      pdf.save(
        `${props.companyName ? props.companyName + "_" : ""}요약사업계획서.pdf`
      );
      props.complete();
    } catch (error) {
      console.log(error);
      props.complete();
    }
  };

  useEffect(() => {
    getSBP(props.file.pfReportDocumentData?.id || 0);
  }, []);

  useEffect(() => {
    if (!pageArr || pageArr.length === 0 || !pageArr[0].ref.current) return;
    downloadPdf();
  }, [pageArr]);

  if (sbpDocument === undefined) return null;

  return (
    <Container>
      <CalculateContainer>
        <div ref={ref2}>
          <CompanyTitle title={props.companyName || ""} />
        </div>
        <div ref={ref3}>
          <PageTitle
            title="1.반기 사업 현황"
            description="2023년 1월부터 6월까지의 현황을 의미합니다."
          />
        </div>

        <div ref={ref4}>
          <Status1 sbpDocument={sbpDocument} index={1} onLoaded={onLoaded} />
        </div>
        <div ref={ref5}>
          <Status2 sbpDocument={sbpDocument} index={2} />
        </div>
        <div ref={ref6}>
          <Status3 sbpDocument={sbpDocument} index={3} />
        </div>
        <div ref={ref7}>
          <Status4 sbpDocument={sbpDocument} index={4} />
        </div>
        <div ref={ref8}>
          <Status5 sbpDocument={sbpDocument} index={5} onLoaded={onLoaded} />
        </div>
        {/* <div ref={ref1}>
          <Divider />
        </div> */}
        <div ref={ref9}>
          <PageTitle
            title="2.반기 사업 계획"
            description="2023년 7월부터 12월까지의 계획을 의미합니다."
          />
        </div>
        <div ref={ref10}>
          <Plan1 sbpDocument={sbpDocument} index={1} onLoaded={onLoaded} />
        </div>
        <div ref={ref11}>
          <Plan2 sbpDocument={sbpDocument} index={2} />
        </div>
        <div ref={ref12}>
          <Plan3 sbpDocument={sbpDocument} index={3} onLoaded={onLoaded} />
        </div>
      </CalculateContainer>
      {pageArr &&
        pageArr.map((item, index) => {
          return (
            <PageWrapper>
              <Page ref={item.ref} convertPixelToCm={convertPixelToCm}>
                <Header
                  front={"요약사업계획서"}
                  back={`${sbpDocument.year}년 ${sbpDocument.quarter}분기`}
                />
                <PageBody style={{ gap: 28 }}>
                  {item.children.map((child) => {
                    return child.component;
                  })}
                </PageBody>
                <Footer totalPage={pageArr.length} currentPage={index + 1} />
              </Page>
            </PageWrapper>
          );
        })}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  overflow: scroll;

  background: rgb(204, 204, 204);
`;

const PageWrapper = styled.div`
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  &:nth-child(1) {
    margin-top: 0.5cm;
  }
`;

const CalculateContainer = styled.div`
  position: absolute;
  width: 21cm;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  opacity: 0;
  z-index: -10000;
`;

const Page = styled.div<{ convertPixelToCm: (n: number) => number }>`
  position: relative;

  width: 21cm;
  height: 29.7cm;

  background: white;

  display: flex;
  flex-direction: column;
  padding: 32px 32px 27px 32px;
`;

const PageBody = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default SummaryBusinessPlanV2Download;
