import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

import { ReactComponent as CheckT } from "../../../assets/image/new_check_box_true.svg";
import { ReactComponent as CheckF } from "../../../assets/image/new_check_box_false.svg";

interface Props {
  style?: React.CSSProperties;
}

export const DCHeader: React.FC<Props> = (props) => {
  const HEIGHT = 40;
  return (
    <Container style={props.style}>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 50,
          minWidth: 50,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        No.
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 110,
          minWidth: 110,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        소속명
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 150,
          minWidth: 150,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        손상 기업
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          flexGrow: 1,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        손상 이슈
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 120,
          minWidth: 120,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        재무제표상 평가금액
      </CellConatiner>
    </Container>
  );
};

const Container = styled.tr`
  width: auto;
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.td`
  width: 100%;
  cursor: default;

  background-color: var(--gray-500);
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  border-top: 2px solid #000;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 8px;

  color: #000;

  &:nth-child(1) {
    border-left: 2px solid #000;
  }
`;
