import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as ArrowDown } from "../../../../../assets/image/icon_arrow.svg";

interface Props {
  editable: boolean;
  orientation?: "top" | "bottom";
  width?: number | string;
  className?: string;
  showEmpty?: boolean;
  isRequired?: boolean;
  defaultValue?: string | number;
  data?: SelectBoxData[];
  placeholder?: string;
  onChange?: (item: SelectBoxData) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export interface SelectBoxData {
  id?: number | string;
  text?: string;
  data?: any;
}

export const SelectBox: React.FC<Props> = (props) => {
  const { width = "100%", orientation = "bottom", editable } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focus, updateFocus] = useState<boolean>(false);
  const [value, updateValue] = useState<string>(`${props.defaultValue || ""}`);
  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateValue(e.target.value);
    updateSearchKeyword(e.target.value);
  };

  const onSelectData = (selectItem: SelectBoxData) => {
    updateValue(selectItem.text || "");
    updateFocus(false);
    props.onChange && props.onChange(selectItem);
  };
  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    updateFocus(true);
    props.onFocus && props.onFocus(e);
  };

  const handleMouseDown = (event: any) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      updateFocus(false);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const data = props.data?.filter((item) =>
        item.text?.includes(searchKeyword)
      );
      if (data && data.length === 1) {
        onSelectData(data[0]);
      }
      updateFocus(false);
    }
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Tab") {
      const data = props.data?.filter((item) =>
        item.text?.includes(searchKeyword)
      );
      if (data && data.length === 1) {
        onSelectData(data[0]);
      }
      updateFocus(false);
    }
  };

  const getOrientation = () => {
    switch (orientation) {
      case "top":
        return "-160px";
      default:
        return "110%";
    }
  };

  useEffect(() => {
    if (!focus) {
      inputRef.current && inputRef.current.blur();
    } else {
      if (!props.defaultValue || `${props.defaultValue}`.length === 0) {
        updateValue("");
      }
      updateValue(`${props.defaultValue || ""}`);
      updateSearchKeyword("");
    }
  }, [focus]);

  useEffect(() => {
    console.log("defaultValue");
    updateValue(`${props.defaultValue || ""}`);
  }, [props.defaultValue]);

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <Container
      ref={containerRef}
      className={props.className || ""}
      style={{ width }}
    >
      <SelectBoxContainer
        className={`${editable ? "" : "disabled"} ${
          editable && focus ? "focused" : ""
        } ${
          props.isRequired && value.length === 0 && props.showEmpty
            ? "empty"
            : ""
        }`}
      >
        <input
          className="body-14-rg"
          readOnly={!editable}
          ref={inputRef}
          onFocus={onFocus}
          value={value}
          placeholder={props.placeholder}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          onChange={onChangeInput}
        />
        {editable && (
          <ArrowDown
            className={`arrow__icon ${focus ? "focused" : ""}`}
            onClick={() => inputRef.current && inputRef.current.focus()}
          />
        )}
      </SelectBoxContainer>
      {editable && focus && (
        <DropDownList
          className="scroll__invisible"
          style={{ width, top: getOrientation() }}
        >
          {props.data?.filter((item) => item.text?.includes(searchKeyword))
            .length === 0 && <ListItem>검색 결과가 없습니다.</ListItem>}
          {props.data
            ?.filter((item) => item.text?.includes(searchKeyword))
            .map((item) => {
              return (
                <ListItem
                  key={item.id}
                  className={`body-14-rg ${
                    props.defaultValue === item.text ? "selected" : ""
                  }`}
                  onClick={() => {
                    onSelectData(item);
                  }}
                >
                  {item.text}
                </ListItem>
              );
            })}
        </DropDownList>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  gap: 4px;
`;

const SelectBoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  border-radius: 4px;
  background: white;
  border: 1px solid var(--gray-300);
  transition: all 0.3s ease;
  overflow: hidden;

  :hover {
    border: 1px solid var(--focus-field-blue);
  }

  &.focused {
    border-color: var(--focus-field-blue);
  }

  &.disabled {
    border-color: transparent;
    input {
      cursor: default;
    }
    :hover {
      border-color: transparent;
    }
  }

  input {
    background-color: transparent;
    flex-grow: 1;
    height: 40px;
    padding: 5px 10px 5px 10px;
    border: 0;

    cursor: pointer;

    :focus {
      outline: none;
    }

    ::placeholder {
      color: var(--grey_20);
    }

    :disabled {
      border: 1px solid transparent;
      background-color: white;
    }
  }

  &.empty {
    border: 1px solid red;
    border-radius: 4px;

    input {
      border-color: transparent;
    }
    animation: shake 300ms;
  }

  .arrow__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transition: all 0.2s ease;
    transform: translateY(-50%) rotate(-90deg);
    path {
      fill: var(--icon-01);
    }

    &.focused {
      transform: translateY(-50%) rotate(90deg);
      path {
        fill: var(--icon-01);
      }
    }
  }

  @keyframes shake {
    25% {
      transform: translateX(4px);
    }
    50% {
      transform: translateX(-4px);
    }
    75% {
      transform: translateX(4px);
    }
  }
`;

const DropDownList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 208px;
  top: 100%;
  z-index: 1;

  padding: 4px 0;

  background: #ffffff;

  box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
  border-radius: 4px;
  overflow-y: scroll;
`;
const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 40px;
  max-height: 40px;
  padding: 0 16px;
  transition: all 0.3s ease;

  cursor: pointer;

  &.selected {
    background-color: var(--blue-100);
    :hover {
      background-color: var(--blue-100);
    }
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
