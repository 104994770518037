import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IAIprsProcess } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";
import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { RemoveComma, getYMD } from "../../../../../../common/commonUtil";
import { CustomTextArea } from "../../table/custom-cell/CustomTextArea";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IAIprsProcess[];
  onDataChange?: (data: IAIprsProcess[]) => void;
}

export const IprsProcessTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<IAIprsProcess[] | undefined>(
    undefined
  );
  const headers: TableGridHeaderProps[] = [
    {
      header: "출원번호",
      key: "applicationNumber",
      width: 228,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="제 00-0000-0000000호"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.applicationNumber = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "출원일",
      key: "applicationDate",
      width: 168,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            isMonth
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultDate={data}
            onChangeDate={(date) => {
              if (!dataList) return;
              rowData.applicationDate = getYMD(date);
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "특허번호/등록번호",
      key: "registrationNumber",
      width: 190,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="제 00-0000000 호"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.registrationNumber = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "등록일",
      key: "registrationDate",
      width: 168,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            isMonth
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultDate={data}
            onChangeDate={(date) => {
              if (!dataList) return;
              rowData.registrationDate = getYMD(date);
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "별명의 명칭/대상이 되는 물품",
      key: "title",
      width: 204,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder=""
            onBlur={(value) => {
              if (!dataList) return;
              rowData.title = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "특허권자/상표권자 등",
      key: "applicant",
      width: 168,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder=""
            onBlur={(value) => {
              if (!dataList) return;
              rowData.applicant = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newData: IAIprsProcess = {};
    const newDataList: IAIprsProcess[] = [...(dataList || []), newData];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    updateDataList(props.dataList);
  }, [props.dataList]);

  useEffect(() => {
    if (dataList && props.onDataChange) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
