import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { DefaultButton } from "lattice_core";
import { ReportingFile } from "../../../2.0lattice/router/reporting/reporting-detail/ReportingDetail";
import {
  FinancialStatementProps,
  FinancialStatementType,
} from "../../../2.0lattice/router/reporting/components/financial-statements/interface/FinancialStatement.interface";
import { FinancialStatementInput } from "./FinancialStatementInput";
import PDFViewer from "../../pdf-viewer/PDFViewer";
import axios from "axios";
import * as api from "../../../api/api";
import useSBP from "../../../2.0lattice/router/reporting/reporting-detail/summary-business-plan/v1/hook/useSBP";
import { Description } from "../../../2.0lattice/components/description/Description";
import { saveAs } from "file-saver";
import SpinnerModal from "../../../components/modal/SpinnerModal";
interface Props {
  isGapiReady: boolean;
  year: number;
  quarter: number;
  type: "google" | "s3";
  pdfKey: string;
  financialStatement: FinancialStatementProps;
  onChangeFinancialStatement: (
    type: FinancialStatementType,
    value: string
  ) => void;
  submit: () => void;
  onSubmitAble: () => boolean | undefined;
  validationAssets: () => boolean | undefined;
  getPDFBlobFile: (fileId: string) => Promise<Blob | undefined>;
}
export const FinancialStatementModal: React.FC<Props> = (props) => {
  const {
    isGapiReady,
    year,
    quarter,
    type,
    pdfKey,
    financialStatement,
    onChangeFinancialStatement,
    submit,
    onSubmitAble,
    validationAssets,
    getPDFBlobFile,
  } = props;
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [fileLoding, setFileLoading] = useState<boolean>(false);

  const getGoolgeUrl = async () => {
    console.log(pdfKey, isGapiReady);
    if (pdfKey === undefined || !isGapiReady) return;
    setFileLoading(true);
    const blob = await getPDFBlobFile(pdfKey);
    if (blob) {
      const newBlob = new Blob([blob], { type: "application/pdf" });
      const newUrl = window.URL.createObjectURL(newBlob);
      setPdfUrl(newUrl);
    }
    setFileLoading(false);
  };

  const getPDFFile = async () => {
    if (pdfKey === undefined) return;
    setFileLoading(true);
    const fileUrl = (
      await axios.get(api.getFile(), {
        params: {
          key: pdfKey,
        },
      })
    ).data;

    fetch(fileUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf", // 이래야 브라우저가 pdf라고 인식하고
        "Content-Disposition": "inline", // 이래야  브라우저가 다운로드말고 화면에 비추도록 한다
      },
    })
      .then((res) => {
        console.log(res);
        return res.blob();
      })
      .then((res) => {
        console.log(res);
        const newBlob = new Blob([res], { type: "application/pdf" });
        const newUrl = window.URL.createObjectURL(newBlob);
        setPdfUrl(newUrl);
        setFileLoading(false);
      });
  };

  useEffect(() => {
    console.log(pdfKey);
    setPdfUrl("");
    if (type === "s3") {
      getPDFFile();
    }
  }, [pdfKey, type]);

  useEffect(() => {
    setPdfUrl("");
    if (type === "google" && isGapiReady) {
      getGoolgeUrl();
    }
  }, [pdfKey, type, isGapiReady]);

  return (
    <Container>
      <Body className="scroll__invisible">
        <BodyContainer style={{ flex: 4 }}>
          {pdfUrl.length > 0 && (
            <div className="viewer__iframe__wrapper">
              <object
                data={pdfUrl}
                type="application/pdf"
                width={"100%"}
                height={"100%"}
              >
                <embed
                  title="pdd-viewer"
                  className="viewer__iframe"
                  src={pdfUrl}
                  type="application/pdf"
                  width={"100%"}
                  height={"100%"}
                />
              </object>
            </div>
          )}
        </BodyContainer>
        <BodyContainer style={{ flex: 2 }}>
          {pdfUrl.length > 0 && (
            <FinancialStatementContainer>
              <div className="heading-16-sb">요약 재무 정보</div>
              <Description style={{ marginTop: 16 }}>
                <FlexColumn
                  className="body-14-md"
                  style={{ lineHeight: 1, gap: 6 }}
                >
                  <div>
                    {quarter === 1 ? year - 1 : year}년 1월부터{" "}
                    {quarter === 2 ? "6월" : "12월"}까지의 해당 정보를 작성해
                    주세요.
                  </div>
                  <div>
                    <span style={{ color: "var(--red-500)" }}>
                      손실에 해당하는 값의 경우 마이너스 (-)를 입력
                    </span>
                    해 주세요.
                  </div>
                  <div>현지통화로 입력해 주세요.</div>
                </FlexColumn>
              </Description>
              <FinancialStatementInputContainer>
                <FinancialStatementInput
                  title="자산총계"
                  value={financialStatement?.totalAssets}
                  onChangeValue={(e) => {
                    onChangeFinancialStatement &&
                      onChangeFinancialStatement(
                        "TOTAL_ASSETS",
                        e.target.value
                      );
                  }}
                />
                <FinancialStatementInput
                  title="부채총계"
                  value={financialStatement?.totalLiabilities}
                  onChangeValue={(e) => {
                    onChangeFinancialStatement &&
                      onChangeFinancialStatement(
                        "TOTAL_LIABILITIES",
                        e.target.value
                      );
                  }}
                />
                <FinancialStatementInput
                  title="자본총계"
                  value={financialStatement?.totalEquity}
                  onChangeValue={(e) => {
                    onChangeFinancialStatement &&
                      onChangeFinancialStatement(
                        "TOTAL_EQUITY",
                        e.target.value
                      );
                  }}
                />
                <FinancialStatementInput
                  title="매출액"
                  value={financialStatement?.revenue}
                  onChangeValue={(e) => {
                    console.log(e);
                    onChangeFinancialStatement &&
                      onChangeFinancialStatement("SALES", e.target.value);
                  }}
                />
                <FinancialStatementInput
                  title="영업이익 (손실)"
                  value={financialStatement?.operatingProfit}
                  onChangeValue={(e) => {
                    console.log(e);
                    onChangeFinancialStatement &&
                      onChangeFinancialStatement(
                        "OPERATING_PROFIT",
                        e.target.value
                      );
                  }}
                />
                <FinancialStatementInput
                  title="당기순이익 (손실)"
                  value={financialStatement?.netIncome}
                  onChangeValue={(e) => {
                    onChangeFinancialStatement &&
                      onChangeFinancialStatement("NET_PROFIT", e.target.value);
                  }}
                />
              </FinancialStatementInputContainer>
              <div
                className="body-14-sb"
                style={{ color: "var(--text-01)", marginTop: 10 }}
              >
                * 손실일 경우 ( - ) 를 꼭 붙여주세요!
              </div>
              {validationAssets() !== undefined && !validationAssets() && (
                <div
                  className="body-14-sb"
                  style={{ color: "var(--red-500)", marginTop: 10 }}
                >
                  * 자산총계 = 부채총계 + 자본총계 입니다.
                </div>
              )}
              {/* { && } */}
              <FooterContainer>
                <DefaultButton
                  className="heading-16-md"
                  buttonType="filled"
                  sizeType="small"
                  buttonColor={"var(--primary-blue)"}
                  hoverColor={"var(-blue-600)"}
                  disabled={!onSubmitAble()}
                  text={"제출하기"}
                  onClick={() => {
                    setPdfUrl("");
                    submit();
                  }}
                />
              </FooterContainer>
            </FinancialStatementContainer>
          )}
        </BodyContainer>
      </Body>
      {fileLoding && (
        <SpinnerModal
          open={fileLoding}
          isFullScreen
          message="파일 불러오는 중 ..."
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 90%;
  height: 90%;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  position: relative;
  flex: 1;
  height: 100%;

  .viewer__iframe__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .viewer__iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`;

const FinancialStatementContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FinancialStatementInputContainer = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--light-gray-200, #edeeef);
  background: var(--light-background-contents-01, #fff);
  gap: 14px;

  margin-top: 8px;
`;

const FooterContainer = styled.div`
  width: 100%;
  padding-top: 34px;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;
const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
