import React from "react";
import styled from "styled-components";

interface Props {
  style?: React.CSSProperties;
  isChecked?: boolean;
  onClick?: () => void;
}

export const SectorLabel: React.FC<Props> = ({
  style = {},
  isChecked = false,
  onClick,
  children,
}) => {
  return (
    <Container
      className={`body-14-rg ${isChecked ? "checked" : ""}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

const Container = styled.div`
  padding: 6px 12px;

  text-align: center;
  border-radius: 20px;

  cursor: pointer;

  background-color: var(--white);
  border: 1px solid var(--gray-300);
  color: var(--text-01);

  &.checked {
    background-color: var(--primary-blue);
    border: 1px solid var(--primary-blue);
    color: var(--white);
  }
`;
