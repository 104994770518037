import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

interface Props {
  riskManagementOverviewHeader?: {
    twoYearsAgo: string;
    oneYearsAgo: string;
  };
  style?: React.CSSProperties;
}

export const RMOHeader: React.FC<Props> = (props) => {
  const HEIGHT = 60;
  return (
    <Container style={props.style}>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 50,
          minWidth: 50,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        No.
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 150,
          minWidth: 150,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        업체 명
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 80,
          minWidth: 80,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        담당심사역
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 100,
          minWidth: 100,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        최초 투자일
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 72,
          minWidth: 72,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
          padding: 0,
          flexDirection: "column",
        }}
      >
        <CellConatiner
          className={`caption-12-rg`}
          style={{
            width: "100%",
            flex: 1,
            justifyContent: "center",
            borderRight: 0,
            borderTop: 0,
            borderLeft: 0,
          }}
        >
          자본 잠식
        </CellConatiner>
        <CellConatiner
          className={`caption-12-rg`}
          style={{
            width: "100%",
            padding: 0,
            flex: 1,
            border: 0,
          }}
        >
          <CellConatiner
            className={`caption-12-rg`}
            style={{
              flex: 1,
              height: "100%",
              padding: 0,
              justifyContent: "center",
              borderLeft: 0,
              borderTop: 0,
              borderBottom: 0,
            }}
          >
            {props.riskManagementOverviewHeader?.twoYearsAgo || "년"}
          </CellConatiner>
          <CellConatiner
            className={`caption-12-rg`}
            style={{
              flex: 1,
              height: "100%",
              padding: 0,
              justifyContent: "center",
              border: 0,
            }}
          >
            {props.riskManagementOverviewHeader?.oneYearsAgo || "년"}
          </CellConatiner>
        </CellConatiner>
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          flexGrow: 1,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        해당 이슈
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 34,
          minWidth: 34,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        손상
      </CellConatiner>
    </Container>
  );
};

const Container = styled.tr`
  width: auto;
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.td`
  width: 100%;
  cursor: default;

  background-color: var(--gray-500);
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  border-top: 2px solid #000;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 8px;

  color: #000;

  &:nth-child(1) {
    border-left: 2px solid #000;
  }
`;
