import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SelectorProps } from "../../../../../type/data";

interface Props {
  selectList: SelectorProps[];
  onChangeData: (data: SelectorProps) => void;
}

export const SearchUser: React.FC<Props> = ({ selectList, onChangeData }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>("");
  const [showList, updateShowList] = useState<boolean>(false);

  const handleMouseDown = (event: any) => {
    if (parentRef.current && !parentRef.current.contains(event.target)) {
      updateShowList(false);
    }
  };

  const onPressKey = () => {
    const filter = selectList.filter((item) => {
      return item.text.toUpperCase().includes(value.toUpperCase());
    });

    if (filter.length === 1) {
      inputRef.current && inputRef.current.blur();
      updateShowList(false);
      onChangeData(filter[0]);
    }
  };

  const getFilteringComponent = () => {
    const filter = selectList.filter((item) => {
      return item.text.toUpperCase().includes(value.toUpperCase());
    });

    if (filter.length === 0) {
      return <ListItem>검색 결과가 없습니다.</ListItem>;
    }

    return filter.map((item) => {
      return (
        <ListItem
          className={`body-14-rg ${item.text === value ? "selected" : ""}`}
          onClick={() => {
            updateShowList(false);
            inputRef.current && inputRef.current.blur();
            onChangeData(item);
          }}
        >
          {item.text}
        </ListItem>
      );
    });
  };

  useEffect(() => {
    if (!showList) {
      setValue("");
    }
  }, [showList]);

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <Container className={`body-14-rg`} ref={parentRef}>
      <InputContainer
        ref={inputRef}
        onFocus={() => {
          updateShowList(true);
        }}
        placeholder="이름을 검색해 주세요."
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onPressKey();
          }
        }}
      />
      {showList && <ListContainer>{getFilteringComponent()}</ListContainer>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 332px;

  &.disabled {
    background-color: var(--gray-50);
  }
`;

const InputContainer = styled.input`
  width: 100%;
  height: 40px;

  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  background: var(--background-contents01, #fff);

  padding: 9px 10px;
  :focus {
    outline: none;
    border: 1px solid var(--focus-field-blue, #a2c7ff);
  }
`;

const ListContainer = styled.div`
  position: absolute;
  width: 100%;
  max-height: 236px;
  display: flex;
  flex-direction: column;

  top: calc(100% + 8px);
  left: 0;

  z-index: 2;

  padding: 8px 0px;

  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  background-color: var(--background-contents02);
  overflow: scroll;
`;

const ListItem = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 6px 8px;
  color: var(--text-01, #1f2022);

  cursor: pointer;

  &.selected {
    background-color: var(--blue-100);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
