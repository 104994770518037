import React from "react";
import styled from "styled-components";
import { ResultContainer, UserContainer } from "./VoteResultListHeader";
import { IAReportUser } from "../../../../../type/data";

import { ReactComponent as DefaultUser } from "../../../../../assets/image/default_user_img.svg";
import { ReactComponent as Crown } from "../../../../../assets/image/crown.svg";
import ColumnTitleBox from "../../../../../components/box/ColumnTitleBox";

interface Props {
  reportUser: IAReportUser;
  isFinished: boolean;
}
export const VoteResultListRow: React.FC<Props> = (props) => {
  const { reportUser, isFinished } = props;
  return (
    <Container className="body-14-rg">
      <UserContainer>
        <Contents style={{ paddingTop: 13 }}>
          <Avatar>
            <AvatarContainer>
              {reportUser.acUser.profileImgUrl ? (
                <img
                  className="avatar"
                  src={reportUser.acUser.profileImgUrl}
                  loading="lazy"
                  alt={"profile img"}
                />
              ) : (
                <DefaultUser className="avatar" />
              )}
              {(reportUser.isFundManager ||
                (reportUser.priorityInfo &&
                  [1, 2].includes(reportUser.priorityInfo.priorityType))) && (
                <Crown
                  className={
                    reportUser.priorityInfo?.priorityType === 1 ||
                    reportUser.isFundManager
                      ? "crown__fund__manager"
                      : "crown__expert__manager"
                  }
                />
              )}
            </AvatarContainer>
            <FlexColum>
              <span>{reportUser.acUser.name}</span>
              <Contents>
                {reportUser.priorityInfo?.priorityType === 1
                  ? "대표펀드매니저"
                  : reportUser.priorityInfo?.priorityType === 2
                  ? "전문투자심사위원 "
                  : "일반투자심사위원"}
              </Contents>
            </FlexColum>
          </Avatar>
        </Contents>
      </UserContainer>
      <ResultContainer>
        <Contents style={{ paddingBottom: 21 }}>
          <ColumnTitleBox title={isFinished ? "의견" : "진행여부"}>
            {isFinished ? (
              <pre
                className="body-14-rg"
                style={{
                  height: "fit-contents",
                  whiteSpace: "break-spaces",
                  wordBreak: "break-all",
                }}
              >
                {reportUser.comment}
              </pre>
            ) : (
              <div>
                {reportUser.isAgreement === undefined ||
                reportUser.isAgreement === null ? (
                  <span style={{ color: "var(--text-04)" }}>투표중</span>
                ) : (
                  <span>투표 완료</span>
                )}
              </div>
            )}
          </ColumnTitleBox>
        </Contents>
      </ResultContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid var(--divider-300);

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  color: var(--text-01);
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const Avatar = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 38px;
  min-width: 38px;
  max-width: 38px;
  height: 38px;
  min-height: 38px;
  max-height: 38px;

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    border: 1px solid var(--gray-200);
    border-radius: 50%;
  }

  .crown__fund__manager {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    path:nth-of-type(1) {
      fill: var(--primary-blue);
    }
  }

  .crown__expert__manager {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    path:nth-of-type(1) {
      fill: var(--blue-300);
    }
  }
`;

const FlexColum = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
