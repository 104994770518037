import React from "react";
import styled from "styled-components";
import { DefaultButton } from "lattice_core";

import { ReactComponent as BackArrow } from "../../../../assets/image/new_icon_arrow.svg";

import { ReactComponent as Fold } from "../../../../assets/image/icon_fold.svg";
import { ReactComponent as Step } from "../../../../assets/image/step_chip.svg";
import { TabLayout } from "./components/TabLayout";

import useRiskManagementEvaluationDetail from "../hook/useRiskManagementEvaluationDetail";
import { RMEDIssue } from "./components/page/RMEDIssue";
import {
  getYMDHM,
  referenceDocumentPopup,
} from "../../../../common/commonUtil";
import { RMEDOpinion } from "./components/page/RMEDOpinion";

const MobileRiskManagementEvaluationDetail = () => {
  const [
    isViewer,
    year,
    quarter,
    document,
    necessaryInfo,
    portfolioInfo,
    updatedAt,
    validationCheckBox,
    tabList,
    selectedTabId,
    isFolded,
    loadCompleted,
    isFullData,
    closedCompany,
    basicDictionary,
    isParticipant,
    mainParticipant,
    updateSelectedTabId,
    updateFolded,
    onChangeRMEDData,
    submitRMEDData,
    onClickBackBtn,
    onTargetIntoView,
    goToTop,
  ] = useRiskManagementEvaluationDetail();

  return (
    <Container>
      <Header>
        <FlexRow style={{ alignItems: "center", gap: 16 }}>
          <BackArrow
            className="back__btn"
            onClick={() => {
              onClickBackBtn();
            }}
          />
          <span className="heading-18-sb title">
            {document?.portfolio.companyName || ""}
          </span>
        </FlexRow>
        <FlexColumn style={{ gap: 6 }}>
          <DefaultButton
            className="body-12-md"
            buttonType="colored-outline"
            sizeType="xxsmall"
            buttonColor="var(--text-01)"
            borderColor="var(--gray-400)"
            disabled={
              portfolioInfo === undefined ||
              portfolioInfo.curSbpData === undefined
            }
            onClick={() => {
              if (portfolioInfo && portfolioInfo.curSbpData) {
                referenceDocumentPopup({
                  type: "sbp",
                  portfolioId: portfolioInfo.portfolioId,
                  year: document?.year || 0,
                  quarter: document?.quarterNum || 0,
                });
              }
            }}
            text="요약사업계획서"
          />
          <DefaultButton
            className="body-12-md"
            buttonType="colored-outline"
            sizeType="xxsmall"
            buttonColor="var(--text-01)"
            borderColor="var(--gray-400)"
            disabled={
              portfolioInfo === undefined ||
              portfolioInfo.financeData === undefined
            }
            onClick={() => {
              if (portfolioInfo && portfolioInfo.financeData) {
                referenceDocumentPopup({
                  type: "google_pdf",
                  portfolioId: portfolioInfo.portfolioId,
                  year: document?.year || 0,
                  quarter: document?.quarterNum || 0,
                  acPfDocumentId: 8,
                });
              }
            }}
            text="재무제표"
          />
        </FlexColumn>
      </Header>
      <Body className={isViewer ? "viewer" : ""} id="root__component">
        {document && (
          <ContentsContainer>
            <TabLayout
              isReadOnly={isViewer.current}
              tabList={tabList}
              selectedTabId={selectedTabId}
              lastUpdate={getYMDHM(updatedAt)}
              onClickTab={(id) => {
                goToTop();
                if (id === 2) {
                  if (tabList[0].isComplete) {
                    updateSelectedTabId(id);
                  } else {
                    alert("이슈 평가를 모두 완료해야 넘어갈 수 있음");
                  }
                } else {
                  updateSelectedTabId(id);
                }
              }}
            />
            {selectedTabId === 1 && (
              <RMEDIssue
                isReadOnly={isViewer.current}
                isFullData={isFullData}
                // isFullData={false}
                closedCompany={closedCompany}
                data={document}
                necessaryInfo={necessaryInfo}
                onChangeData={(data) => {
                  onChangeRMEDData(data);
                }}
              />
            )}

            {selectedTabId === 2 && (
              <RMEDOpinion
                isReadOnly={isViewer.current}
                closedCompany={closedCompany}
                basicDictionary={basicDictionary}
                data={document}
                isParticipant={isParticipant}
                portfolioInfo={portfolioInfo}
                mainParticipant={mainParticipant}
                onChangeData={(data) => {
                  onChangeRMEDData(data);
                }}
              />
            )}
          </ContentsContainer>
        )}
      </Body>
      {!isViewer.current && (
        <Footer>
          <div className="max__width__footer">
            <FlexRow />
            <FlexRow style={{ gap: 12 }}>
              {document && [1, 2, 3].includes(document.status) && (
                <DefaultButton
                  className="body-14-md"
                  buttonType="colored-outline"
                  sizeType="small"
                  buttonColor="var(--text-01)"
                  borderColor="var(--gray-400)"
                  onClick={() => {
                    if (document) {
                      submitRMEDData(true);
                    }
                  }}
                  text="임시저장"
                />
              )}
              {selectedTabId === 1 && (
                <DefaultButton
                  className="heading-16-md"
                  buttonType="filled"
                  sizeType="small"
                  disabled={tabList.length > 0 && !tabList[0].isComplete}
                  buttonColor="var(--primary-blue)"
                  hoverColor="var(--blue-600)"
                  borderColor="var(--gray-400)"
                  text={"다음"}
                  onClick={() => {
                    updateSelectedTabId(2);
                    goToTop();
                  }}
                />
              )}
              {selectedTabId === 2 && isParticipant && (
                <DefaultButton
                  className="heading-16-md"
                  buttonType="filled"
                  sizeType="small"
                  disabled={
                    tabList.filter((item) => !item.isComplete).length > 0
                  }
                  buttonColor="var(--primary-blue)"
                  hoverColor="var(--blue-600)"
                  borderColor="var(--gray-400)"
                  text={document?.status === 4 ? "수정" : "제출"}
                  onClick={() => {
                    submitRMEDData(false);
                  }}
                />
              )}
            </FlexRow>
          </div>
        </Footer>
      )}

      {!isViewer.current && (
        <SectionListComponent
          ref={validationCheckBox}
          className={isFolded ? "folded" : ""}
        >
          <FlexRow
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span className="heading-16-md show__hide">작성 내용</span>
            <Fold
              className={`fold__btn ${isFolded ? "fold__btn__folded" : ""}`}
              onClick={() => {
                updateFolded((prev) => !prev);
              }}
            />
          </FlexRow>
          {tabList
            .find((item) => item.id === selectedTabId)
            ?.tabSectionList.map((item) => {
              return (
                <FlexRow
                  className="show__hide"
                  style={{ alignItems: "center", gap: 10, cursor: "pointer" }}
                  onClick={() => {
                    onTargetIntoView(item.selectId);
                  }}
                >
                  <Step className={item.isComplete ? "completed" : ""} />
                  <span
                    className="body-14-rg"
                    style={{ color: "var(--text-03)" }}
                  >
                    {item.sectionTitle}
                  </span>
                </FlexRow>
              );
            })}
        </SectionListComponent>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Header = styled.div`
  width: 100%;
  height: 78px;
  min-height: 78px;
  max-height: 78px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  border-bottom: 1px solid var(--divider-200);

  .title {
    color: var(--text-01);
  }

  .back__btn {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    path {
      fill: var(--text-01);
    }
  }
`;
const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 78px - 66px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  background-color: var(--background-page);

  &.viewer {
    height: calc(100vh - 78px);
  }
`;

const ContentsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  width: 100%;
  height: 66px;
  min-height: 66px;
  max-height: 66px;

  border-top: 2px solid var(--divider-200);
  padding: 24px;

  .max__width__footer {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const SectionListComponent = styled.div`
  position: fixed;
  width: 232px;
  max-height: 300px;

  z-index: 2000;

  top: 224px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
  align-items: flex-start;
  gap: 10px;

  border-radius: 8px;
  background: var(--background-contents02);
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);

  overflow: hidden;
  object-position: top right;

  transition: all 0.125s ease-in;

  .completed {
    rect {
      fill: var(--blue-400);
    }

    path {
      fill: var(--icon-04);
    }
  }

  .show__hide {
  }

  .fold__btn {
    cursor: pointer;
    transition: all 0.125s ease;
    transform: rotate(180deg);
    &.fold__btn__folded {
      transform: rotate(360deg);
    }
  }

  &.folded {
    width: 60px;
    height: 60px;
    .show__hide {
      display: none;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export default MobileRiskManagementEvaluationDetail;
