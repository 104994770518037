import React from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../../assets/image/modal_close.svg";
import { DefaultButton } from "lattice_core";

import { InvestmentAssessment } from "../../../../../../type/data";
import { AddComma } from "../../../../../../common/commonUtil";
import config from "../../../../../../config";

interface Props {
  document: InvestmentAssessment;
  close: () => void;
}
const DiffInvestmentModal: React.FC<Props> = (props) => {
  const { document, close } = props;
  const getTotalInvestmentAmount = () => {
    return document?.investmentAssessmentReportAccount
      .filter(
        (item) =>
          item.investmentAssessmentAccount.newOldStock?.newOldStockId === 1
      )
      .map((item) => item.investmentAssessmentAccount.investmentAmount || 0)
      .reduce((prev, cur) => prev + cur, 0);
  };

  const getTotalOldStockNumber = () => {
    return document?.investmentAssessmentReportAccount
      .filter(
        (item) =>
          item.investmentAssessmentAccount.newOldStock?.newOldStockId === 2
      )
      .map((item) => item.investmentAssessmentAccount.stockNumber || 0)
      .reduce((prev, cur) => prev + cur, 0);
  };

  const getRoundTotalInvestmentAmount = () => {
    if (!document) return 0;
    if (!document.investmentAssessmentReportAccount[0]) return 0;

    return document.investmentAssessmentReportAccount[0]
      .investmentAssessmentAccount.totalInvestmentAmount;
  };

  const getRoundTotalOldStockNumver = () => {
    if (!document) return 0;
    if (!document.investmentAssessmentReportAccount[0]) return 0;

    return document.investmentAssessmentReportAccount[0]
      .investmentAssessmentAccount.previousTotalStockNumber;
  };

  const checkInvestment = () => {
    if (document) {
      const thisRoundIssuedStock = document.investmentAssessmentReportAccount[0]
        ? document.investmentAssessmentReportAccount[0]
            .investmentAssessmentAccount.postTotalStockNumber || 0
        : 0;
      const thisRoundPreTotalStock = document
        .investmentAssessmentReportAccount[0]
        ? document.investmentAssessmentReportAccount[0]
            .investmentAssessmentAccount.previousTotalStockNumber || 0
        : 0;

      const totalIssuedStock = document.investmentAssessmentReportAccount
        .filter(
          (item) =>
            item.investmentAssessmentAccount.newOldStock?.newOldStockId === 1
        )
        .map((item) => item.investmentAssessmentAccount.stockNumber || 0)
        .reduce((prev, cur) => prev + cur, 0);

      const totalStock = document.investmentAssessmentReportAccount
        .filter(
          (item) =>
            item.investmentAssessmentAccount.newOldStock?.newOldStockId === 2
        )
        .map((item) => item.investmentAssessmentAccount.stockNumber || 0)
        .reduce((prev, cur) => prev + cur, 0);

      if (thisRoundIssuedStock < totalIssuedStock) {
        return getNewStockComponent();
      }
      if (thisRoundPreTotalStock < totalStock) {
        return getOldStockComponent();
      }
    }

    return false;
  };

  const getNewStockComponent = () => {
    return (
      <PageContainer>
        <div className="body-14-rg">
          {config.companyName}의 총 투자 금액은 이번 라운드 총 투자 금액보다
          작거나 동일 해야 합니다. 정확한 값으로 수정한 뒤 개최해 주세요.
        </div>
        <FlexColumn
          style={{
            borderRadius: 4,
            backgroundColor: "var(--red-50)",
            padding: "12px 16px",
            marginTop: 24,
          }}
        >
          <FlexColumn style={{ gap: 4 }}>
            {document?.investmentAssessmentReportAccount
              .filter(
                (item) =>
                  item.investmentAssessmentAccount.newOldStock
                    ?.newOldStockId === 1
              )
              .map((item) => {
                return (
                  <FlexRow style={{ justifyContent: "space-between" }}>
                    <FlexRow
                      className="body-14-md"
                      style={{ color: "var(--text-04)" }}
                    >
                      <EllipsisText>
                        {item.investmentAssessmentAccount.accountName || ""}
                      </EllipsisText>
                      /
                      <span>
                        {item.investmentAssessmentAccount.stockType?.name}
                      </span>
                      /
                      <span>
                        {item.investmentAssessmentAccount.newOldStock?.name}
                      </span>
                    </FlexRow>
                    <span className="body-14-rg">
                      {AddComma(
                        item.investmentAssessmentAccount.investmentAmount
                      )}{" "}
                      원
                    </span>
                  </FlexRow>
                );
              })}
          </FlexColumn>
          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          <FlexRow
            className="body-14-md"
            style={{ justifyContent: "space-between" }}
          >
            <span style={{ color: "var(--text-04)" }}>
              {config.companyName}의 총 투자 금액
            </span>
            <span style={{ color: "var(--red-500)" }}>
              {AddComma(getTotalInvestmentAmount())}원
            </span>
          </FlexRow>
        </FlexColumn>

        <FlexColumn
          className="body-14-md"
          style={{
            borderRadius: 4,
            backgroundColor: "var(--red-50)",
            padding: "12px 16px",
            marginTop: 8,
          }}
        >
          <FlexRow style={{ justifyContent: "space-between" }}>
            <span style={{ color: "var(--text-04)" }}>
              이번 라운드 총 투자 금액
            </span>
            <span style={{ color: "var(--red-500)" }}>
              {AddComma(getRoundTotalInvestmentAmount())}원
            </span>
          </FlexRow>
        </FlexColumn>
      </PageContainer>
    );
  };

  const getOldStockComponent = () => {
    return (
      <PageContainer>
        <div className="body-14-rg">
          {config.companyName}의 구주 매입 주식수는 기업의 기 발행 주식수보다
          작거나 동일 해야 합니다. 정확한 값으로 수정한 뒤 개최해 주세요.
        </div>
        <FlexColumn
          style={{
            borderRadius: 4,
            backgroundColor: "var(--red-50)",
            padding: "12px 16px",
            marginTop: 24,
          }}
        >
          <FlexColumn style={{ gap: 4 }}>
            {document?.investmentAssessmentReportAccount
              .filter(
                (item) =>
                  item.investmentAssessmentAccount.newOldStock
                    ?.newOldStockId === 2
              )
              .map((item) => {
                return (
                  <FlexRow style={{ justifyContent: "space-between" }}>
                    <FlexRow
                      className="body-14-md"
                      style={{ color: "var(--text-04)" }}
                    >
                      <EllipsisText>
                        {item.investmentAssessmentAccount.accountName || ""}
                      </EllipsisText>
                      /
                      <span>
                        {item.investmentAssessmentAccount.stockType?.name}
                      </span>
                      /
                      <span>
                        {item.investmentAssessmentAccount.newOldStock?.name}
                      </span>
                    </FlexRow>
                    <span className="body-14-rg">
                      {AddComma(item.investmentAssessmentAccount.stockNumber)}{" "}
                      주
                    </span>
                  </FlexRow>
                );
              })}
          </FlexColumn>
          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          <FlexRow
            className="body-14-md"
            style={{ justifyContent: "space-between" }}
          >
            <span style={{ color: "var(--text-04)" }}>
              {config.companyName}의 총 구주매입 주식수
            </span>
            <span style={{ color: "var(--red-500)" }}>
              {AddComma(getTotalOldStockNumber())}원
            </span>
          </FlexRow>
        </FlexColumn>

        <FlexColumn
          className="body-14-md"
          style={{
            borderRadius: 4,
            backgroundColor: "var(--red-50)",
            padding: "12px 16px",
            marginTop: 8,
          }}
        >
          <FlexRow style={{ justifyContent: "space-between" }}>
            <span style={{ color: "var(--text-04)" }}>
              기업의 기 발행 주식 수
            </span>
            <span style={{ color: "var(--red-500)" }}>
              {AddComma(getRoundTotalOldStockNumver())}원
            </span>
          </FlexRow>
        </FlexColumn>
      </PageContainer>
    );
  };
  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          투자 금액 오류
        </span>
        <div className="close">
          <Close onClick={close} />
        </div>
      </Header>
      <Body>{checkInvestment()}</Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            text={"확인"}
            onClick={() => {
              close();
            }}
            transfromPixel={(n) => n}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 480px;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

const PageContainer = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  min-height: 72px;
  max-height: 72px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 24px 0px 0px;
  gap: 12px;
`;

const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-300);
`;

const EllipsisText = styled.div`
  width: 116px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default DiffInvestmentModal;
