import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Order } from "../../../../assets/image/icon_order.svg";
import { SelectorProps } from "../../interface/DealFlow.interface";

interface Props {
  defaultData?: string;
  selectList: SelectorProps[];
  onChangeData?: (data: SelectorProps) => void;
}

export const OrderingBtn: React.FC<Props> = ({
  defaultData,
  selectList,
  onChangeData,
}) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>("");
  const [showList, updateShowList] = useState<boolean>(false);

  useEffect(() => {
    if (defaultData) {
      const selectedData = selectList.find((item) => item.text === defaultData);
      setValue(selectedData?.text || "");
    }
  }, [defaultData]);

  const handleMouseDown = (event: any) => {
    if (optionRef.current && !optionRef.current.contains(event.target)) {
      updateShowList(false);
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  if (value.length === 0) return null;

  return (
    <Container className={`body-14-rg`}>
      <InputContainer
        ref={selectorRef}
        onClick={() => {
          updateShowList((prev) => !prev);
        }}
      >
        <Order />
        {value.length > 0 && (
          <div className="caption-12-md" style={{ color: "var(--text-04)" }}>
            {value}
          </div>
        )}
      </InputContainer>
      {showList && (
        <ListContainer ref={optionRef}>
          {selectList.map((item) => {
            return (
              <ListItem
                className={`caption-10-md ${
                  item.text === value ? "selected" : ""
                }`}
                onClick={() => {
                  updateShowList(false);
                  setValue(item.text);
                  onChangeData && onChangeData(item);
                }}
              >
                {item.text}
              </ListItem>
            );
          })}
        </ListContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: row;

  &.disabled {
    background-color: var(--gray-50);
  }
`;

const InputContainer = styled.div`
  width: 100%;
  height: 32px;
  border: 1px solid var(--gray-300);
  background-color: var(--background-contents01);

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 4px;

  cursor: pointer;
`;

const ListContainer = styled.div`
  position: absolute;
  width: 100%;
  max-height: 236px;
  display: flex;
  flex-direction: column;

  top: calc(100% + 8px);
  left: 0;

  z-index: 2;

  padding: 8px 0px;

  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  background-color: var(--background-contents02);
  overflow: scroll;
`;

const ListItem = styled.div`
  width: 100%;
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 6px 8px;
  color: var(--text-04);
  cursor: pointer;

  &.selected {
    background-color: var(--blue-100);
  }
`;
