import React from "react";
import styled from "styled-components";

import { AddComma } from "../../../../common/commonUtil";

import ModalLayout from "../../../../components/modal/ModalLayout";

import { DefaultButton } from "lattice_core";

import useContractTask from "../hook/useContractTask";
import { TitleSection } from "../../../portfolio/info/desk/components/TitleSection";
import { StartupInfo } from "../../../portfolio/info/mobile/organism/StartupInfo";
import { ParticipantInfo } from "../../../portfolio/info/mobile/organism/ParticipantInfo";
import { BoxComponent } from "../../../../components-2.0v/box/BoxComponent";

import { DateTimePickerByMUI } from "../../../../components-2.0v/date-picker/DateTimePickerByMUI";
import ColumnTitleBox from "../../../../components/box/ColumnTitleBox";
import ConfirmModal from "../../../../2.0lattice/components/modal/ConfirmModal";

const MobileContractTask = () => {
  const [
    portfolioInfo,
    participantMain,
    participantSub,
    reportAccount,
    inviteUsers,
    selectedReportAccount,
    contractModal,
    warnningMsg,
    rejactModal,
    doContract,
    doRejectContract,
    updateReportAccount,
    updateSelectedReportAccount,
    setRejactModal,
    isContractAble,
    setWarnningMsg,
    setContractModal,
  ] = useContractTask();

  if (!portfolioInfo) return null;

  return (
    <Container className="scroll__invisible">
      <FlexColumn style={{ padding: 24, gap: 24 }}>
        <TitleSection title="스타트업 개요">
          <StartupInfo startupInfo={portfolioInfo.startupInfo} />
        </TitleSection>
        <TitleSection title="심사역 개요">
          <ParticipantInfo participantInfo={portfolioInfo.participantInfo} />
        </TitleSection>

        <TitleSection title="투자 계정">
          <FlexColumn style={{ gap: 12 }}>
            {reportAccount.map((item) => {
              return (
                <BoxComponent>
                  <FlexColumn style={{ gap: 10 }}>
                    <ColumnTitleBox className="body-14-rg" title="투자 재원">
                      <RowTitleBody>{item?.accountName}</RowTitleBody>
                    </ColumnTitleBox>
                    <ColumnTitleBox className="body-14-rg" title="투자금">
                      <RowTitleBody>
                        {item?.investmentAmount
                          ? AddComma(parseInt(`${item.investmentAmount}`))
                          : "-"}
                        원
                      </RowTitleBody>
                    </ColumnTitleBox>
                    <ColumnTitleBox className="body-14-rg" title="주식 종류">
                      <RowTitleBody>{item?.stockType?.name}</RowTitleBody>
                    </ColumnTitleBox>
                    <ColumnTitleBox className="body-14-rg" title="배정 주식 수">
                      <RowTitleBody>
                        {item?.stockNumber ? AddComma(item.stockNumber) : "-"}주
                      </RowTitleBody>
                    </ColumnTitleBox>
                    <ColumnTitleBox className="body-14-rg" title="신주 발행가">
                      <RowTitleBody>
                        {item?.issuingPrice
                          ? AddComma(parseInt(`${item.issuingPrice}`))
                          : "-"}
                        원
                      </RowTitleBody>
                    </ColumnTitleBox>
                    <ColumnTitleBox className="body-14-rg" title="지분율">
                      <RowTitleBody>{item?.shareholdingRatio}%</RowTitleBody>
                    </ColumnTitleBox>
                    <ColumnTitleBox className="body-14-rg" title="참여자">
                      <ParticipantList>
                        {item?.investmentAssessmentInvite.map((item) => {
                          return (
                            <UserContainer
                              className="body-14-rg"
                              key={item.acUser.acUserId}
                            >
                              {item.acUser.profileImgUrl ? (
                                <img
                                  className="user__profile__img"
                                  src={item.acUser.profileImgUrl}
                                  alt="프로필 이미지"
                                />
                              ) : (
                                <span className="font-medium-10 user__profile__text">
                                  {item.acUser.name.slice(0, 2)}
                                </span>
                              )}
                              {item.acUser.name}
                            </UserContainer>
                          );
                        })}
                      </ParticipantList>
                    </ColumnTitleBox>
                    <ColumnTitleBox
                      required
                      className="body-14-rg"
                      title="계약일"
                    >
                      <DateTimePickerByMUI
                        type="date"
                        defaultValue={item?.contractDate}
                        disabled={item === undefined}
                        placeholder="YYYY-MM-DD"
                        onChangeDate={(date) => {
                          if (item) {
                            const data = reportAccount.map((item) => {
                              if (
                                item.reportAccountId === item.reportAccountId
                              ) {
                                const newData = { ...item, contractDate: date };
                                updateSelectedReportAccount(newData);
                                return newData;
                              }
                              return { ...item };
                            });
                            updateReportAccount([...data]);
                          }
                        }}
                      />
                    </ColumnTitleBox>
                    <ColumnTitleBox
                      required
                      className="body-14-rg"
                      title="납입일"
                    >
                      <DateTimePickerByMUI
                        type="date"
                        defaultValue={item?.paymentDate}
                        disabled={item === undefined}
                        placeholder="YYYY-MM-DD"
                        onChangeDate={(date) => {
                          if (item) {
                            const data = reportAccount.map((item) => {
                              if (
                                item.reportAccountId === item.reportAccountId
                              ) {
                                const newData = { ...item, paymentDate: date };
                                updateSelectedReportAccount(newData);
                                return newData;
                              }
                              return { ...item };
                            });
                            updateReportAccount([...data]);
                          }
                        }}
                      />
                    </ColumnTitleBox>
                  </FlexColumn>
                </BoxComponent>
              );
            })}
          </FlexColumn>
        </TitleSection>
      </FlexColumn>
      <ButtonContainer>
        <DefaultButton
          className="heading-16-md"
          buttonType="outline"
          sizeType="small"
          buttonColor="var(--red-500)"
          onClick={() => {
            setRejactModal(true);
          }}
          text="투자 반려하기"
        />

        <DefaultButton
          className="heading-16-md"
          buttonType={"filled"}
          sizeType="small"
          buttonColor={"var(--primary-blue)"}
          hoverColor="var(--blue-600)"
          borderColor="var(--gray-400)"
          text={"투자 확정하기"}
          disabled={!isContractAble()}
          disabledOnClick={() => {
            setWarnningMsg(true);
          }}
          onClick={() => setContractModal(true)}
        />
      </ButtonContainer>
      <ModalLayout
        isOpen={contractModal}
        isTransparent={true}
        isFullScreen
        toggle={() => {
          setContractModal(false);
        }}
      >
        <ConfirmModal
          type="confirm"
          title="투자 확정하기"
          contents={["해당 스타트업의 투자를 확정하시겠습니까?"]}
          positiveTitle="확정하기"
          negativeTitle="아니오"
          onClickPositiveBtn={doContract}
          onClickNegativeBtn={() => {
            setContractModal(false);
          }}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={warnningMsg}
        isTransparent={true}
        isFullScreen
        toggle={() => {
          setWarnningMsg(false);
        }}
      >
        <ConfirmModal
          type="alert"
          title="투자 확정하기"
          contents={["모든 투자계정의 계약일, 납입일을 입력해주세요."]}
          positiveTitle="확인"
          onClickPositiveBtn={() => {
            setWarnningMsg(false);
          }}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={rejactModal}
        isTransparent={true}
        isFullScreen
        toggle={() => {
          setRejactModal(false);
        }}
      >
        <ConfirmModal
          type="confirm"
          title="투자 반려하기"
          contents={["해당 스타트업의 투자를 반려하시겠습니까?"]}
          positiveTitle="반려하기"
          negativeTitle="아니오"
          onClickPositiveBtn={doRejectContract}
          onClickNegativeBtn={() => {
            setRejactModal(false);
          }}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;

  gap: 24px;

  .invest_invite__info__container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 0 24px;
  }
`;

const ButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 66px;
  min-height: 66px;
  max-height: 66px;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;
  margin-top: auto;
  border-top: 2px solid var(--divider-200);

  gap: 12px;
`;

const ParticipantList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 9px;
`;

const UserContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  line-height: 1;
  .user__profile__img {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: 1px solid #f0f0f0;
  }
  .user__profile__text {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: 1px solid #f0f0f0;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FunAccountList = styled.div`
  width: 100%;
  height: 100%;
  max-height: 338px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents-01, #fff);
  padding: 16px;
  gap: 16px;
`;

const RowTitleBody = styled.div`
  margin-top: 9px;
`;
export default MobileContractTask;
