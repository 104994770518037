import React from "react";
import styled from "styled-components";
import { ReactComponent as Pdf } from "../../../../../assets/image/large_pdf.svg";

import { FileType } from "../../../../../type/data";

interface Props {
  isDanger?: boolean;
  file?: FileType;
  width?: number | string;
  onClickFile: (file: FileType) => void;
}
export const FileViewerBtn: React.FC<Props> = (props) => {
  const { isDanger, file, width = "100%", onClickFile } = props;
  return (
    <Container
      className={file ? "exist__file" : ""}
      style={{ width: width }}
      onClick={() => {
        file && onClickFile(file);
      }}
    >
      {file ? (
        <FileWrapper className="body-14-rg">
          <FlexRow
            style={{
              gap: 6,
              alignItems: "center",
            }}
          >
            <Pdf width={24} height={24} />
            <FileName style={{ lineHeight: 1 }}>{file?.fileName}</FileName>
          </FlexRow>
        </FileWrapper>
      ) : (
        <div
          className="body-14-rg"
          style={{ color: isDanger ? "var(--red-500)" : "var(--text-05)" }}
        >
          업로드된 파일이 없어요.
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  margin-left: -6px;

  &.exist__file {
    cursor: pointer;
    :hover {
      background-color: var(--hover-100);
    }
  }
`;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  color: var(--text-01);
`;

const FileName = styled.div`
  width: 100%;
  height: 100%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
