import React from "react";
import styled from "styled-components";
import { ReactComponent as ProfileDefault } from "../../../../../../../assets/image/icon_profile_default.svg";
import { ReactComponent as LatticeBotProfile } from "../../../../../../../assets/image/lattice_bot_profile.svg";

interface Props {
  isLatticeBot?: boolean;
  visible?: boolean;
  avatarImg?: string;
  transfromPixel: (value: number) => number;
}
const Avatar: React.FC<Props> = (props) => {
  return (
    <Container
      transfromPixel={props.transfromPixel}
      style={{
        border: props.visible
          ? `${props.transfromPixel(1)}px solid #EDEEEF`
          : "0px",
        backgroundImage: props.avatarImg ? `url(${props.avatarImg})` : "none",
      }}
    >
      {props.visible && props.isLatticeBot && (
        <LatticeBotProfile
          width={props.transfromPixel(32)}
          height={props.transfromPixel(32)}
        />
      )}
      {props.visible &&
        !props.isLatticeBot &&
        props.avatarImg === undefined && (
          <ProfileDefault
            width={props.transfromPixel(32)}
            height={props.transfromPixel(32)}
          />
        )}
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  width: ${(props) => props.transfromPixel(32)}px;
  min-width: ${(props) => props.transfromPixel(32)}px;
  height: ${(props) => props.transfromPixel(32)}px;
  min-height: ${(props) => props.transfromPixel(32)}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export default Avatar;
