import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { trnasfromBase64ToObject } from "../../../../common/commonUtil";
import { SectionTabProps } from "../interface/REI.interface";
import { InvestmentAssessment } from "../../../../type/data";
import axios from "axios";
import * as api from "../../../../api/api";
import {
  reRequestExpertOpinion,
  requestExpertOpinion,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import config from "../../../../config";
import { showDeskToast } from "../../../../2.0lattice/toast";

const getIds = (location: any, type: "portfolio" | "investmentAssessment") => {
  const code = location.pathname.split("/")[3];
  const { portfolioId, investmentAssessmentId } = trnasfromBase64ToObject(code);

  if (type === "portfolio") {
    return portfolioId;
  } else {
    return investmentAssessmentId;
  }
};

const useEvaluationStartupInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isViewer = useRef<boolean>(false);
  const isLoaded = useRef<boolean>(false);
  const initSBP = useRef<boolean>(false);

  const [tabList, updateTabList] = useState<SectionTabProps[]>([]);
  const validationCheckBox = useRef<HTMLDivElement>(null);
  const [isFolded, updateFolded] = useState<boolean>(false);
  const [selectedTabId, updateSelectedTabId] = useState<number>(1);

  const portfolioId = useRef<number>(getIds(location, "portfolio"));
  const investmentAssessmentId = useRef<number>(
    getIds(location, "investmentAssessment")
  );

  const document = useRef<InvestmentAssessment | undefined>(undefined);
  const originDocument = useRef<InvestmentAssessment | undefined>(undefined);

  const [tempSaving, updateTempSaving] = useState<boolean>(false);

  const [render, updateRender] = useState<boolean>(false);

  const [isPublished, updatePublished] = useState<boolean | undefined>(
    undefined
  );

  const [submitComplete, updateSubmitComplete] = useState<boolean>(false);

  const reRender = () => updateRender((prev) => !prev);

  const handleResize = () => {
    if (validationCheckBox.current) {
      validationCheckBox.current.style.display = "flex";
      if (window.innerWidth > 1200 + 232 + 232 + 24) {
        const marginleft = (window.innerWidth - 1200) / 2;

        validationCheckBox.current.style.left = `${1200 + marginleft + 24}px`;
      } else if (window.innerWidth < 1200) {
        validationCheckBox.current.style.display = "none";
      } else {
        validationCheckBox.current.style.left = "";
        validationCheckBox.current.style.right = "40px";
      }
    }
  };

  const goToTop = () => {
    const root = window.document.querySelector("#root__component");

    if (root) {
      root.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const onTargetIntoView = (id: string) => {
    const root = window.document.querySelector("#root__component");
    const target = window.document.querySelector(`#${id}`);

    if (root && target) {
      const targetRect = target.getBoundingClientRect();
      const headerOffset = 250;
      const elementPosition = targetRect.top;
      const offsetPosition = elementPosition + root.scrollTop - headerOffset;

      root.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const validationSection1_all = (data: InvestmentAssessment) => {
    return validationSection1_1(data) && validationSection1_2(data);
  };
  const validationSection1_1 = (data: InvestmentAssessment) => {
    const companyInfo = data.investmentAssessmentCompanyInfo;
    console.log(companyInfo);
    return (
      companyInfo.companyName !== undefined &&
      companyInfo.companyName.length !== 0 &&
      companyInfo.ceoName !== undefined &&
      companyInfo.ceoName.length !== 0 &&
      companyInfo.businessNumber !== undefined &&
      companyInfo.businessNumber.length !== 0 &&
      companyInfo.corporationNumber !== undefined &&
      companyInfo.corporationNumber.length !== 0 &&
      companyInfo.addr1 !== undefined &&
      companyInfo.addr1.length !== 0 &&
      companyInfo.addr2 !== undefined &&
      companyInfo.addr2.length !== 0 &&
      companyInfo.tel !== undefined &&
      companyInfo.tel.length !== 0 &&
      companyInfo.industryType !== undefined &&
      companyInfo.industryType.length !== 0 &&
      companyInfo.industryCode !== undefined &&
      companyInfo.industryCode.length !== 0 &&
      companyInfo.majorProduct !== undefined &&
      companyInfo.majorProduct.length !== 0 &&
      companyInfo.establishmentDay !== undefined &&
      companyInfo.establishmentDay.length !== 0 &&
      companyInfo.employeeNumber !== undefined &&
      companyInfo.settlementMonth !== undefined &&
      companyInfo.accountingFirm !== undefined &&
      companyInfo.accountingFirm.length !== 0
    );
  };

  const validationSection1_2 = (data: InvestmentAssessment) => {
    const companyHistory = data.investmentAssessmentCompanyHistory;

    return (
      companyHistory?.data.filter((item) => {
        return (
          item.date === undefined ||
          item.date.length === 0 ||
          item.contents === undefined ||
          item.contents.length === 0
        );
      }).length === 0
    );
  };

  const validationSection2_all = (data: InvestmentAssessment) => {
    return validationSection2_1(data) && validationSection2_2(data);
  };

  const validationSection2_1 = (data: InvestmentAssessment) => {
    const companyMember = data.investmentAssessmentCompanyMember;

    if (companyMember?.data.length === 0) return false;
    return (
      companyMember?.data.filter((item) => {
        return (
          item.name === undefined ||
          item.name.length === 0 ||
          item.position === undefined ||
          item.position.length === 0 ||
          item.birthYear === undefined ||
          item.birthYear.length === 0 ||
          item.careerOrEducation === undefined ||
          item.careerOrEducation.length === 0 ||
          item.fullTimeStatus === undefined ||
          item.fullTimeStatus.length === 0 ||
          item.relationship === undefined ||
          item.relationship.length === 0 ||
          item.registrationStatus === undefined ||
          item.registrationStatus.length === 0
        );
      }).length === 0
    );
  };

  const validationSection2_2 = (data: InvestmentAssessment) => {
    const employee = data.investmentAssessmentKeyManagement;

    if (employee?.data.length === 0) return false;
    return (
      employee?.data.filter((item) => {
        return (
          item.name === undefined ||
          item.name.length === 0 ||
          item.position === undefined ||
          item.position.length === 0 ||
          item.birthYear === undefined ||
          item.birthYear.length === 0 ||
          item.careerOrEducation === undefined ||
          item.careerOrEducation.length === 0 ||
          item.fullTimeStatus === undefined ||
          item.fullTimeStatus.length === 0 ||
          item.relationship === undefined ||
          item.relationship.length === 0
        );
      }).length === 0
    );
  };

  const validationSection3_all = (data: InvestmentAssessment) => {
    return (
      validationSection3_1(data) &&
      validationSection3_2(data) &&
      validationSection3_3(data) &&
      validationSection3_4(data)
    );
  };

  const validationSection3_1 = (data: InvestmentAssessment) => {
    const stakeStatement = data.investmentAssessmentStakeStatement;
    if (stakeStatement?.data.length === 0) return false;
    return (
      stakeStatement?.data.filter((item) => {
        return (
          item.stockholderName === undefined ||
          item.stockholderName.length === 0
        );
      }).length === 0
    );
  };

  const validationSection3_2 = (data: InvestmentAssessment) => {
    const capitalIncreaseHistory =
      data.investmentAssessmentCapitalIncreaseHistory;

    if (
      capitalIncreaseHistory?.isChecked &&
      capitalIncreaseHistory.isChecked === true
    )
      return true;

    if (capitalIncreaseHistory?.data.length === 0) return false;

    return (
      capitalIncreaseHistory?.isChecked ||
      capitalIncreaseHistory?.data.filter((item) => {
        return (
          item.date === undefined ||
          item.date.length === 0 ||
          item.issuanceType === undefined ||
          item.issuanceType.length === 0 ||
          item.detailContents === undefined ||
          item.detailContents.length === 0 ||
          item.amount === undefined ||
          item.issuingPrice === undefined ||
          item.capital === undefined
        );
      }).length === 0
    );
  };

  const validationSection3_3 = (data: InvestmentAssessment) => {
    const financialStatement = data.investmentAssessmentFinancialStatement2;
    if (financialStatement?.data.length === 0) return false;
    return (
      financialStatement?.data.filter((item) => {
        return (
          item.year === undefined ||
          item.salesAmount === undefined ||
          item.operatingProfitLoss === undefined ||
          item.netProfitLoss === undefined ||
          item.totalAssets === undefined ||
          item.totalLiabilities === undefined ||
          item.totalOwnershipInterest === undefined ||
          item.capital === undefined
        );
      }).length === 0
    );
  };

  const validationSection3_4 = (data: InvestmentAssessment) => {
    const debt = data.investmentAssessmentDebt;
    if (debt?.isChecked !== undefined && debt.isChecked === true) return true;

    if (debt?.data.length === 0) return false;

    return (
      debt?.data.filter((item) => {
        return (
          item.lender === undefined ||
          item.lender.length === 0 ||
          item.lenderType === undefined ||
          item.lenderType.length === 0 ||
          item.interestRate === undefined ||
          item.amount === undefined ||
          item.dateBorrowed === undefined ||
          item.dateBorrowed.length === 0 ||
          item.expirationDate === undefined ||
          item.expirationDate.length === 0
        );
      }).length === 0
    );
  };

  const validationSection4_all = (data: InvestmentAssessment) => {
    return (
      validationSection4_1(data) &&
      validationSection4_2_1(data) &&
      validationSection4_2_2(data) &&
      validationSection4_3(data) &&
      validationSection4_4(data)
    );
  };

  const validationSection4_1 = (data: InvestmentAssessment) => {
    const governmentProject = data.investmentAssessmentGovernmentProject;
    if (
      governmentProject?.isChecked !== undefined &&
      governmentProject.isChecked === true
    )
      return true;

    if (governmentProject?.data.length === 0) return false;

    return (
      governmentProject?.data.filter((item) => {
        return (
          item.businessName === undefined ||
          item.businessName.length === 0 ||
          item.organizationName === undefined ||
          item.organizationName.length === 0 ||
          item.taskName === undefined ||
          item.taskName.length === 0 ||
          item.supportPeriod === undefined ||
          item.supportPeriod.length === 0 ||
          item.startDate === undefined ||
          item.startDate.length === 0 ||
          item.governmentDonation === undefined
        );
      }).length === 0
    );
  };

  const validationSection4_2_1 = (data: InvestmentAssessment) => {
    const costEstimation = data.investmentAssessmentCostEstimationPlan;
    if (
      costEstimation?.isChecked !== undefined &&
      costEstimation.isChecked === true
    )
      return true;

    if (costEstimation?.data.length === 0) return false;

    return (
      costEstimation?.data.filter((item) => {
        return (
          item.costEstimationContents === undefined ||
          item.costEstimationContents.length === 0 ||
          item.costEstimationPrice === undefined
        );
      }).length === 0
    );
  };
  const validationSection4_2_2 = (data: InvestmentAssessment) => {
    const financialSourcing = data.investmentAssessmentFinancialSourcingPlan;
    if (
      financialSourcing?.isChecked !== undefined &&
      financialSourcing.isChecked === true
    )
      return true;

    if (financialSourcing?.data.length === 0) return false;

    return (
      financialSourcing?.data.filter((item) => {
        return (
          item.financialSourcingPlan === undefined ||
          item.financialSourcingPlan.length === 0 ||
          item.financialSourcingPrice === undefined
        );
      }).length === 0
    );
  };

  const validationSection4_3 = (data: InvestmentAssessment) => {
    const milestoneKpi = data.investmentAssessmentMilestoneKPI;
    if (
      milestoneKpi?.isChecked !== undefined &&
      milestoneKpi.isChecked === true
    )
      return true;

    if (milestoneKpi?.data.length === 0) return false;

    return (
      milestoneKpi?.data.filter((item) => {
        return (
          item.kpiStartDate === undefined ||
          item.kpiStartDate.length === 0 ||
          item.kpiEndDate === undefined ||
          item.kpiEndDate.length === 0 ||
          item.investmentStage === undefined ||
          item.investmentStage.length === 0 ||
          item.costEstimationAmount === undefined ||
          item.peopleNum === undefined ||
          item.mileston === undefined ||
          item.mileston.length === 0 ||
          item.risk === undefined ||
          item.risk.length === 0 ||
          item.kpi === undefined ||
          item.kpi.length === 0
        );
      }).length === 0
    );
  };

  const validationSection4_4 = (data: InvestmentAssessment) => {
    const financingPlan = data.investmentAssessmentFinancingPlan;
    if (
      financingPlan?.isChecked !== undefined &&
      financingPlan.isChecked === true
    )
      return true;

    if (financingPlan?.data.length === 0) return false;

    return (
      financingPlan?.data.filter((item) => {
        return (
          item.year === undefined ||
          item.halfType === undefined ||
          item.halfType.length === 0 ||
          item.capitalIncreaseType === undefined ||
          item.capitalIncreaseType.length === 0 ||
          item.capitalIncreaseAmount === undefined
        );
      }).length === 0
    );
  };

  const validationSection5_all = (data: InvestmentAssessment) => {
    return (
      validationSection5_1(data) &&
      validationSection5_2(data) &&
      validationSection5_3(data)
    );
  };

  const validationSection5_1 = (data: InvestmentAssessment) => {
    const contractStatus = data.investmentAssessmentContractStatus;
    if (
      contractStatus?.isChecked !== undefined &&
      contractStatus.isChecked === true
    )
      return true;

    if (contractStatus?.data.length === 0) return false;

    return (
      contractStatus?.data.filter((item) => {
        return (
          item.date === undefined ||
          item.date.length === 0 ||
          item.contents === undefined ||
          item.contents.length === 0 ||
          item.contractor === undefined ||
          item.contractor.length === 0
        );
      }).length === 0
    );
  };
  const validationSection5_2 = (data: InvestmentAssessment) => {
    const iprsProcess = data.investmentAssessmentIprsProcess;
    if (iprsProcess?.isChecked !== undefined && iprsProcess.isChecked === true)
      return true;

    if (iprsProcess?.data.length === 0) return false;

    return (
      iprsProcess?.data.filter((item) => {
        return (
          item.applicationNumber === undefined ||
          item.applicationNumber.length === 0 ||
          item.applicationDate === undefined ||
          item.applicationDate.length === 0 ||
          item.title === undefined ||
          item.title.length === 0 ||
          item.applicant === undefined ||
          item.applicant.length === 0
        );
      }).length === 0
    );
  };

  const validationSection5_3 = (data: InvestmentAssessment) => {
    const similarBusiness = data.investmentAssessmentSimilarBusiness;
    if (
      similarBusiness?.isChecked !== undefined &&
      similarBusiness.isChecked === true
    )
      return true;

    if (similarBusiness?.data.length === 0) return false;

    return (
      similarBusiness?.data.filter((item) => {
        console.log(item);
        return (
          !item.type ||
          !item.compnayName ||
          !item.businessSummary ||
          !item.clientCompany ||
          item.salesAmount === undefined ||
          item.cumulativeInvestment === undefined ||
          !item.majorInvestor
        );
      }).length === 0
    );
  };

  const checkTabValidation = (data: InvestmentAssessment) => {
    const section1: SectionTabProps = {
      id: 1,
      name: "기본",
      isComplete: validationSection1_all(data),
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "기업 개요",
          isComplete: validationSection1_1(data),
          selectId: "Section1_1",
        },
        {
          index: 2,
          sectionTitle: "주요 연혁",
          isComplete: validationSection1_2(data),
          selectId: "Section1_2",
        },
      ],
    };

    const section2: SectionTabProps = {
      id: 2,
      name: "인력",
      isComplete: validationSection2_all(data),
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "경영진 현황",
          isComplete: validationSection2_1(data),
          selectId: "Section2_1",
        },
        {
          index: 2,
          sectionTitle: "임직원 현황",
          isComplete: validationSection2_2(data),
          selectId: "Section2_2",
        },
      ],
    };

    const section3: SectionTabProps = {
      id: 3,
      name: "재무·투자",
      isComplete: validationSection3_all(data),
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "지분 변동 내역",
          isComplete: validationSection3_1(data),
          selectId: "Section3_1",
        },
        {
          index: 3,
          sectionTitle: "자본금 증자 연혁",
          isComplete: validationSection3_2(data),
          selectId: "Section3_2",
        },
        {
          index: 2,
          sectionTitle: "재무 현황",
          isComplete: validationSection3_3(data),
          selectId: "Section3_3",
        },

        {
          index: 4,
          sectionTitle: "자금 차입 현황",
          isComplete: validationSection3_4(data),
          selectId: "Section3_4",
        },
      ],
    };

    const section4: SectionTabProps = {
      id: 4,
      name: "자금 계획",
      isComplete: validationSection4_all(data),
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "정부 지원 과제",
          isComplete: validationSection4_1(data),
          selectId: "Section4_1",
        },

        {
          index: 2,
          sectionTitle: "(Y+2) 자금 소요 계획",
          isComplete:
            validationSection4_2_1(data) && validationSection4_2_2(data),
          selectId: "Section4_2",
        },
        {
          index: 3,
          sectionTitle: "(Y+2) 투자 단계별 마일스톤 및 주요 KPI",
          isComplete: validationSection4_3(data),
          selectId: "Section4_3",
        },
        {
          index: 4,
          sectionTitle: "자금 조달 계획",
          isComplete: validationSection4_4(data),
          selectId: "Section4_4",
        },
      ],
    };

    const section5: SectionTabProps = {
      id: 5,
      name: "사업 진행",
      isComplete: validationSection5_all(data),
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "계약 현황",
          isComplete: validationSection5_1(data),
          selectId: "Section5_1",
        },
        {
          index: 2,
          sectionTitle: "출원 및 등록 현황",
          isComplete: validationSection5_2(data),
          selectId: "Section5_2",
        },
        {
          index: 3,
          sectionTitle: "국내외 관련기업 현황",
          isComplete: validationSection5_3(data),
          selectId: "Section5_3",
        },
      ],
    };
    updateTabList([section1, section2, section3, section4, section5]);
  };

  const initEvaluationInfo = async () => {
    try {
      const result = await axios.get(
        api.getExternalInvestmentAssesmentDocument(
          portfolioId.current,
          investmentAssessmentId.current
        )
      );

      if (result.status === 200) {
        //이 투자 심사 보고서가 배포가 되었는가?
        document.current = { ...result.data.investmentAssessment };
        originDocument.current = structuredClone(document.current);
        if (
          document.current &&
          document.current.investmentAssessmentReportAccount &&
          document.current.investmentAssessmentReportAccount.length > 0
        ) {
          updatePublished(
            document.current.investmentAssessmentReportAccount[0].isPublished
          );
        }
        if (document.current) {
          if (document.current.isSubmissionComplete !== undefined) {
            updateSubmitComplete(document.current.isSubmissionComplete);
          }

          checkTabValidation(document.current);
        }
      }
    } catch (error) {}
  };

  const init = async () => {
    if (initSBP.current) return;

    initSBP.current = true;
    isLoaded.current = false;

    await initEvaluationInfo();
  };

  const areJSONsEqual = (json1: any, json2: any): boolean => {
    const stringifiedJSON1 = JSON.stringify(json1);
    const stringifiedJSON2 = JSON.stringify(json2);

    return stringifiedJSON1 === stringifiedJSON2;
  };

  //
  const onChangeData = async (data: InvestmentAssessment) => {
    if (areJSONsEqual(originDocument.current, data)) return;

    console.log(originDocument);
    console.log(document);

    checkTabValidation(data);
    document.current = data;
    originDocument.current = structuredClone(document.current);
    updateTempSaving(true);
    reRender();
  };

  const tempSavingData = async () => {
    const result = await axios.put(
      api.updateExternalInvestmentAssessmentDocument(),
      document.current
    );
    if (result.status === 200) {
      showDeskToast("success", "임시저장을 완료하였습니다.");
      updateTempSaving(false);
    }
  };

  const submitInvestmentAssessment = async () => {
    if (!document.current) return;

    if (
      document.current.expertInfo &&
      document.current.expertInfo.isRequestExpert
    ) {
      const exportLink =
        config.clientUrl +
        "portfolio/expert/" +
        (document.current.exportLink || "");

      if (document.current.isSubmissionComplete) {
        //수정 메일
        await reRequestExpertOpinion(
          portfolioId.current,
          investmentAssessmentId.current,
          exportLink,
          document.current.expertInfo.expertTitle || "",
          document.current.expertInfo.expertEmail || ""
        );
      } else {
        //제출 메일
        await requestExpertOpinion(
          portfolioId.current,
          investmentAssessmentId.current,
          exportLink,
          document.current.expertInfo.expertTitle || "",
          document.current.expertInfo.expertEmail || ""
        );
      }
    }

    document.current.isSubmissionComplete = true;
    const result = await axios.put(
      api.updateExternalInvestmentAssessmentDocument(),
      document.current
    );
    if (result.status === 200) {
      showDeskToast("success", "제출하였습니다.");

      updateSubmitComplete(true);
    }
  };

  const setModifiableState = () => {
    updateSubmitComplete(false);
  };

  useEffect(() => {
    if (!isPublished) handleResize();
    if (!submitComplete) handleResize();
  }, [isPublished, submitComplete]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [
    document.current,
    isPublished,
    submitComplete,
    tabList,
    validationCheckBox,
    selectedTabId,
    isFolded,
    tempSaving,
    goToTop,
    onTargetIntoView,
    updateFolded,
    updateSelectedTabId,
    onChangeData,
    tempSavingData,
    submitInvestmentAssessment,
    setModifiableState,
  ] as const;
};

export default useEvaluationStartupInfo;
