import React from "react";
import styled from "styled-components";
import { PortfolioStockHolderList } from "./organism/PortfolioStockHolderList";
import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import useInvestmentInfo from "../hook/useInvestmentInfo";
import { CurrentInvestmentInfo } from "./component/CurrentInvestmentInfo";
import { InvestedFundAccountInfo } from "./component/InvestedFundAccountInfo";

import { ReactComponent as Down } from "../../../../assets/image/icon_download.svg";

export const DeskInvestmentInfo: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const { portfolioId, init, isEditable = false } = props;
  const [
    currentInvestmentInfo,
    stockHolderList,
    investmentInfo,
    currency,
    viewStockChangeList,
    viewCapitalRaisingList,
  ] = useInvestmentInfo();

  return (
    <Container>
      <InvestmentDetailContaienr>
        <div className="detail__container">
          <CurrentInvestmentInfo
            currentInvestmentInfo={currentInvestmentInfo}
            currency={currency}
          />
        </div>
        <div className="detail__container">
          <span className="heading-20-md">투자 재원 정보</span>
          <FlexColumn style={{ padding: "0", gap: 16 }}>
            {investmentInfo.map((item, index) => {
              return <InvestedFundAccountInfo investmentInfo={item} />;
            })}
          </FlexColumn>
        </div>
        <div className="detail__container">
          <div className="title__wrapper">
            <span className="heading-20-md">주주명부</span>

            <FlexRow style={{ gap: 10 }}>
              <ExcelDownloadBtn
                className={`caption-12-md`}
                onClick={() => {
                  viewStockChangeList();
                }}
              >
                투자정보 보기
              </ExcelDownloadBtn>
            </FlexRow>
          </div>
          <div className="box">
            <PortfolioStockHolderList list={stockHolderList} />
          </div>
        </div>
      </InvestmentDetailContaienr>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--underline);
    margin: 0 24px;

    .front__wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--gap-triple);
    }
  }
  .grid__container {
    flex-grow: 1;
    width: 100%;
    padding: 0px 24px;
    padding-bottom: 24px;
  }
`;

const InvestmentDetailContaienr = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  padding: 32px 0px;

  gap: 60px;
  .detail__container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title__wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .box {
    background: #ffffff;

    border-radius: 4px;
    border: 1px solid var(--gray-300);
    overflow: hidden;
  }
`;

const ExcelDownloadBtn = styled.div`
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  color: var(--text-01);
  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  background: var(--background-contents-01, #fff);
  transition: all 0.2s ease;
  path {
    fill: var(--text-01);
  }

  cursor: pointer;

  :hover {
    background: var(--hover-100);
  }

  &.disabled {
    border-color: var(--gray-200);
    color: var(--gray-200);
    cursor: default;
    path {
      fill: var(--gray-200);
    }
    :hover {
      background: var(--background-contents-01, #fff);
    }
  }
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
