import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  PitchingResultType,
  PitchingStatus,
  PrePitchingData,
  ResultType,
} from "../../interface/PrePitchingDetail.interface";

import ResultItem from "../components/result/ResultItem";
import { getYMD } from "../../../../../common/commonUtil";
import { TitleBox } from "../../../../../components-2.0v/box/TitleBox";

import { DefaultButton } from "lattice_core";
import ColumnTitleBox from "../../../../../components/box/ColumnTitleBox";

interface Props {
  isEditable: boolean;
  pitchingData: PrePitchingData;
  prePitchingResult: PitchingResultType[];
  finishPitching: () => void;
}
const PitchingResult: React.FC<Props> = (props) => {
  const { isEditable, pitchingData, prePitchingResult, finishPitching } = props;

  const [data, updateData] = useState<ResultType[]>([]);
  const getTitle = (date?: string) => {
    if (!date) return "피칭심사 결과";
    const transformDate = getYMD(date).replaceAll("-", "").slice(2);
    return `${transformDate} 피칭심사 결과`;
  };

  useEffect(() => {
    if (prePitchingResult && prePitchingResult.length > 0) {
      if (prePitchingResult[0].data) {
        const target = prePitchingResult[0].data.find(
          (item) => item.title === "피칭 종합 의견"
        );
        const other = prePitchingResult[0].data.filter(
          (item) => item.title !== "피칭 종합 의견"
        );
        if (target) {
          updateData([target, ...other]);
        }
      }
    }
  }, [prePitchingResult]);

  return (
    <ContainerWarpper>
      <Container className="scroll__invisible">
        <ResultContainer
          style={{
            paddingBottom:
              pitchingData.status !== PitchingStatus.FINISH && isEditable
                ? 120
                : 60,
          }}
        >
          <TitleBox
            title={getTitle(pitchingData.createdAt)}
            boxStyle={{ padding: 0 }}
          >
            {prePitchingResult.map((item, index) => {
              return (
                <FlexColumn key={index}>
                  <TitleContainer>
                    <Title className="heading-18-md">{item.title}</Title>
                    {item.description && (
                      <ColumnTitleBox
                        title="설명"
                        style={{ padding: "0 16px" }}
                      >
                        <Description className="body-14-rg">
                          {item.description}
                        </Description>
                      </ColumnTitleBox>
                    )}
                    {item.investmentInfo && (
                      <ColumnTitleBox
                        title="투자 정보"
                        style={{ padding: "0 16px" }}
                      >
                        <Description className="body-14-rg">
                          {item.investmentInfo}
                        </Description>
                      </ColumnTitleBox>
                    )}
                    {item.companyInfo && (
                      <ColumnTitleBox
                        title="기업 정보"
                        style={{ padding: "0 16px" }}
                      >
                        <Description className="body-14-rg">
                          {item.companyInfo}
                        </Description>
                      </ColumnTitleBox>
                    )}
                  </TitleContainer>
                  {data.map(
                    (data, idx) => data && <ResultItem key={idx} item={data} />
                  )}
                </FlexColumn>
              );
            })}
          </TitleBox>
        </ResultContainer>
      </Container>
      {pitchingData.status !== PitchingStatus.FINISH && isEditable && (
        <Footer>
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            text="확정하기"
            onClick={(e) => {
              e && e.stopPropagation();
              finishPitching();
            }}
          />
        </Footer>
      )}
    </ContainerWarpper>
  );
};

const ContainerWarpper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const ResultContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 0 auto;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
  border-bottom: 1px solid var(--gray-200);
`;

const Title = styled.div`
  width: 100%;
  padding: 14px 16px;
  border-bottom: 1px solid var(--gray-200);
  margin-bottom: 19px;
`;

const Description = styled.pre`
  width: 100%;
  height: auto;

  padding-top: 9px;

  word-break: break-all;
  white-space: break-spaces;

  color: var(--text-01, #1f2022);
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  bottom: 0px;
  padding: 0 24px;
  gap: 16px;

  border-top: 2px solid var(--divider-200, #edeeef);
  background: var(--background-header, #fff);
`;

export default PitchingResult;
