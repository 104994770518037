import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { BusinessInfoProps } from "../../../portfolio/info/interface/PortfolioInfo.interface";
import ColumnTitleBox from "../../../../components/box/ColumnTitleBox";
import { PdfFileReadBtn } from "../../../../components-2.0v/file/PdfFileReadBtn";
import { FileType } from "../../../../type/data";

interface Props {
  businessPlanFile?: FileType;
  businessInfo: BusinessInfoProps;
}
export const BusinessDetail: React.FC<Props> = (props) => {
  const { businessPlanFile, businessInfo } = props;
  return (
    <Container id="business__detail">
      <ColumnTitleBox title="사업계획서" titleWidth={128}>
        {businessPlanFile ? (
          <PdfFileReadBtn defaultData={businessPlanFile} />
        ) : (
          <TextBox className={`body-14-rg is__empty`}>파일이 없습니다.</TextBox>
        )}
      </ColumnTitleBox>
      <ColumnTitleBox title="Problem" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.problem && businessInfo.problem.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {businessInfo.problem || "작성한 내용이 없어요."}
        </TextBox>
      </ColumnTitleBox>

      <ColumnTitleBox title="Solution" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.solution && businessInfo.solution.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {businessInfo.solution || "작성한 내용이 없어요."}
        </TextBox>
      </ColumnTitleBox>

      <ColumnTitleBox title="BM" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.businessModel && businessInfo.businessModel.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {businessInfo.businessModel || "작성한 내용이 없어요."}
        </TextBox>
      </ColumnTitleBox>

      <ColumnTitleBox title="Team" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.team && businessInfo.team.length > 0 ? "" : "is__empty"
          }`}
        >
          {businessInfo.team || "작성한 내용이 없어요."}
        </TextBox>
      </ColumnTitleBox>

      <ColumnTitleBox title="기타" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            businessInfo.other && businessInfo.other.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {businessInfo.other || "작성한 내용이 없어요."}
        </TextBox>
      </ColumnTitleBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 28px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TextBox = styled.pre`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  word-break: break-all;
  white-space: break-spaces;
  padding: 9px 0;

  color: var(--text-01, #1f2022);

  &.is__empty {
    color: var(--text-05);
  }
`;
