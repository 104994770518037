import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import CheckedText from "../../../../../../components-2.0v/check-box/CheckedText";
import { FundAccountLight } from "../../../../../../type/data";
import CheckedChip from "../../../../../excavation/desktop/components/CheckedChip";
import RefreshChip from "../../../../../excavation/desktop/components/RefreshChip";

interface Props {
  fundAccount: FundAccountLight[];
  selectedFundAccount: FundAccountLight[];
  onChangeFundAccount: (
    type: "init" | "click",
    account?: FundAccountLight
  ) => void;
}

const FilterView: React.FC<Props> = (props) => {
  const { fundAccount, selectedFundAccount, onChangeFundAccount } = props;
  const [isShowFilter, updateShowFilter] = useState<boolean>(false);
  const [openFilter, updateOpenFilter] = useState<boolean>(false);

  return (
    <Container>
      <FelxRow
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FelxRow style={{ gap: 12, flexWrap: "wrap" }}>
          <CheckedChip
            isSelected={openFilter}
            isActive={selectedFundAccount.length > 0}
            contentsString={`투자계정`}
            onClick={() => {
              updateOpenFilter((prev) => !prev);
            }}
          />
          <RefreshChip
            onClick={() => {
              onChangeFundAccount("init");
            }}
          />
        </FelxRow>
      </FelxRow>
      <FilterContainer className={openFilter ? "show__filter" : ""}>
        <Divider style={{ marginBottom: 0 }} />
        {openFilter ? (
          <FilterWrapper>
            {fundAccount.map((item) => {
              return (
                <CheckedText
                  isChecked={
                    selectedFundAccount.find(
                      (account) =>
                        account.acFundAccountId === item.acFundAccountId
                    ) !== undefined
                  }
                  onClick={() => {
                    onChangeFundAccount("click", item);
                  }}
                >
                  {item.nickName}
                </CheckedText>
              );
            })}
          </FilterWrapper>
        ) : null}

        <Divider style={{ marginTop: 0 }} />
      </FilterContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 0px;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-contents01);
  overflow: hidden;

  transition: all 0.2s ease;

  &.show__filter {
    height: fit-content;
    min-height: 92px;
    margin-top: 20px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-300);
`;
const FilterWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
  padding: 10px 16px;
`;

const FelxRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FelxColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export default FilterView;
