import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

interface Props {
  isReadOnly: boolean;
  placeholder?: string;
  isShowWordCounter?: boolean;
  textClassName?: string;
  maxLength?: number;
  defaultData?: string;
  onChangeData?: (data: string) => void;
}

export const AutoResizableTextarea: React.FC<Props> = (props) => {
  const {
    isReadOnly,
    placeholder,
    isShowWordCounter,
    textClassName = "body-14-rg",
    maxLength = 1000,
    defaultData,
    onChangeData,
  } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaValue, setTextareaValue] = useState<string>("");

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    setTextareaValue(defaultData || "");
  }, [defaultData]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [textareaValue]);

  return (
    <Container>
      <TextArea
        className={`${textClassName} ${isReadOnly ? "read__only" : ""}`}
        ref={textareaRef}
        value={textareaValue}
        readOnly={props.isReadOnly}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(e) => {
          !isReadOnly && handleTextareaChange(e);
        }}
        onBlur={() =>
          !isReadOnly && onChangeData && onChangeData(textareaValue)
        }
        style={{ resize: "none", overflow: "hidden" }}
      />
      {!isReadOnly && isShowWordCounter && (
        <span
          className="caption-12-md"
          style={{ color: "var(--text-inactive)" }}
        >
          ({textareaValue.length}/{maxLength})
        </span>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;
const TextArea = styled.textarea`
  flex-grow: 1;
  max-width: 820px;
  min-height: 30px;
  border: 1px solid var(--gray-300);
  padding: 9px 12px;

  border-radius: 4px;

  &.read__only {
    border: 1px solid transparent;
    padding: 9px 0px;

    :focus {
      outline: none;
      border: 1px solid transparent;
    }
  }

  :focus {
    outline: none;
    border: 1px solid var(--blue-300);
  }
`;
