import React from "react";
import styled, { css } from "styled-components";

import { UserInfo } from "../../../../type/data";

import { UserCreate } from "./organisms/UserCreate";
import { StructureDetail } from "./organisms/StructureDetail";
import NewNavigationLayout from "../../navigation/NewNavigationLayout";

import useStructure from "./hook/useStructure";
import { DefaultButton } from "lattice_core";
import { ResponsibilityModal } from "./organisms/ResponsibilityModal";
import { PositionModal } from "./organisms/PositionModal";
import ModalLayout from "../../../../components/modal/ModalLayout";
import StructureModifyModal from "./organisms/StructureModifyModal";
import { StructureNew } from "./organisms/StructureNew";
import { MoveEmployeeModal } from "./organisms/MoveEmployeeModal";

export interface DataType {
  isStruct: boolean;
  name: string;
  structInfo?: StructureInfo;
}

interface StructureInfo {
  isNew?: boolean;
  isChange?: boolean;
  isDelete?: boolean;
  acStructureId?: number | string;
  name: string;
  parent?: number | string;
  structureIndex?: number;
}

const AcceleratorStructure2 = () => {
  const [
    structureData,
    headerList,
    selectedHeader,
    selectedStructure,
    allUser,
    allIndustryTech,
    allResponsibility,
    allPosition,
    filterUser,
    selectedUserInfo,
    toggleUserCrete,
    toggleResponsibilityManage,
    togglePositionManage,
    toggleMovedEmployee,
    toggleStructureManage,
    isChange,
    searchValue,
    setSearchValue,
    onClickStructure,
    transformStructure,
    filteringUser,
    onClickUserCard,
    closeUserCreate,
    openClickResponsibilityManage,
    closeClickResponsibilityManage,
    openClickPositionManage,
    closeClickPositionManage,
    openClickMovedEmployee,
    closeClickMovedEmployee,
    openClickStructureManage,
    closeClickStructureManage,
    createResponsibility,
    deleteResponsibility,
    createPositoin,
    deletePosition,
    onClickStructureHeader,
    onModifyUserInfo,
    onUpdateStructure,
    isCheckDataChagne,
    onMoveStructure,
  ] = useStructure();

  if (!structureData) return null;

  return (
    <NewNavigationLayout>
      <Container>
        <StructureContainer>
          <FlexRow
            style={{
              justifyContent: "space-between",
              padding: "40px 24px 0px 24px",
            }}
          >
            <span className="heading-20-sb">조직도</span>
            <span
              className="caption-12-md"
              style={{ color: "var(--text-05)", cursor: "pointer" }}
              onClick={openClickStructureManage}
            >
              편집
            </span>
          </FlexRow>
          <StructureNew
            structureData={structureData.filter((item) => !item.data?.isDelete)}
            selectedNode={selectedStructure}
            headerList={headerList}
            selectedHeader={selectedHeader}
            onClickStructure={onClickStructure}
            onClickStructureHeader={onClickStructureHeader}
          />
        </StructureContainer>
        <SelectedContentsContainer className="scroll__invisible">
          <Header>
            <span className="heading-20-sb">조직원 목록</span>
            <FlexRow style={{ gap: 10 }}>
              <DefaultButton
                className="heading-16-b"
                sizeType="xxsmall"
                buttonType="text"
                buttonColor={
                  toggleResponsibilityManage
                    ? "var(--primary-purple)"
                    : "var(--text-03)"
                }
                onClick={() => {
                  toggleResponsibilityManage
                    ? closeClickResponsibilityManage()
                    : openClickResponsibilityManage();
                }}
                text="직책 관리"
              />
              <DefaultButton
                className="heading-16-b"
                sizeType="xxsmall"
                buttonType="text"
                buttonColor={
                  togglePositionManage
                    ? "var(--primary-purple)"
                    : "var(--text-03)"
                }
                onClick={() => {
                  togglePositionManage
                    ? closeClickPositionManage()
                    : openClickPositionManage();
                }}
                text="직급 관리"
              />
              {selectedStructure && (
                <DefaultButton
                  className="heading-16-b"
                  sizeType="xxsmall"
                  buttonType="text"
                  buttonColor={
                    toggleMovedEmployee
                      ? "var(--primary-purple)"
                      : "var(--text-03)"
                  }
                  onClick={() => {
                    toggleMovedEmployee
                      ? closeClickMovedEmployee()
                      : openClickMovedEmployee();
                  }}
                  text="직원 이동"
                />
              )}

              <DefaultButton
                className="heading-16-b"
                sizeType="small"
                buttonType="filled"
                buttonColor="var(--primary-purple)"
                hoverColor="var(--purple-700)"
                disabled={!isChange}
                onClick={() => {
                  onUpdateStructure();
                }}
                text="현재 정보 저장"
              />
            </FlexRow>
          </Header>
          <StructureDetail
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filterUser={filterUser}
            onClickUserCard={onClickUserCard}
          />
          <RightModal className={toggleResponsibilityManage ? "open" : ""}>
            {toggleResponsibilityManage && (
              <ResponsibilityModal
                responsibility={allResponsibility || []}
                createResponsibility={createResponsibility}
                deleteResponsibility={deleteResponsibility}
              />
            )}
          </RightModal>
          <RightModal className={togglePositionManage ? "open" : ""}>
            {togglePositionManage && (
              <PositionModal
                position={allPosition || []}
                createPosition={createPositoin}
                deletePosition={deletePosition}
              />
            )}
          </RightModal>

          <RightModal className={toggleMovedEmployee ? "open" : ""}>
            {toggleMovedEmployee && (
              <MoveEmployeeModal
                allUser={allUser}
                onMoveStructure={onMoveStructure}
              />
            )}
          </RightModal>
        </SelectedContentsContainer>

        {toggleUserCrete && selectedUserInfo && (
          <ModalLayout
            isFullScreen
            isOpen={toggleUserCrete}
            overlayColor={"rgba(0, 0, 0, 0.4)"}
            toggle={closeUserCreate}
          >
            <ModalContainer>
              <UserCreate
                structureData={structureData}
                userInfo={selectedUserInfo}
                allIndustryTech={allIndustryTech}
                onUserCreate={(newUserInfo: UserInfo) => {
                  const newData: StructureInfo[] = [];
                  const newUsers: UserInfo[] | undefined =
                    onModifyUserInfo(newUserInfo);
                  structureData.forEach(
                    (item) => item.data && newData.push(item.data)
                  );
                  if (newData && newUsers) {
                    console.log("asdfdsafdasf");
                    transformStructure(newData, newUsers || []);
                    filteringUser(newUsers);
                    closeUserCreate();
                    isCheckDataChagne();
                  }
                }}
                cancelCreate={closeUserCreate}
              />
            </ModalContainer>
          </ModalLayout>
        )}

        {toggleStructureManage && (
          <ModalLayout
            isFullScreen
            isOpen={toggleStructureManage}
            toggle={closeClickStructureManage}
          >
            <StructureModifyModal
              structureData={structureData.filter(
                (item) => !item.data?.isDelete
              )}
              onSubmit={(data) => {
                const newData: StructureInfo[] = [];
                data.forEach((item) => item.data && newData.push(item.data));
                if (newData) {
                  transformStructure(newData, allUser || []);
                  onClickStructure(undefined);
                  closeClickStructureManage();
                  isCheckDataChagne();
                }
              }}
            />
          </ModalLayout>
        )}
      </Container>
    </NewNavigationLayout>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StructureContainer = styled.div`
  position: relative;
  width: 305px;
  min-width: 305px;
  max-width: 305px;
  display: flex;
  flex-direction: column;
  background-color: var(--background-contents02);
  border-right: 1px solid var(--divider-200);
  overflow: hidden;
`;

export const Header = styled.div(
  () => css`
    width: 100%;
    height: 62px;
    min-height: 62px;
    max-height: 62px;
    padding: 0px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    border-bottom: 1px solid var(--divider-200);

    .update__btn {
      width: 192px;
      height: 42px;
      min-height: 42px;
      max-height: 42px;
      border-radius: 10px;
      border: 0;
      cursor: pointer;
      background-color: var(--grey_20);
      color: var(--white);
    }
    .active {
      background-color: var(--primary);
    }
  `
);

const SelectedContentsContainer = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const RightModal = styled.div`
  position: absolute;
  right: -390px;
  bottom: 0;
  width: 385px;
  height: calc(100% - 62px);
  background-color: var(--white);
  box-shadow: -5px 0 5px -5px rgba(100, 103, 109, 0.1);
  transition: all 0.25s ease;
  &.open {
    right: 0;
  }
`;

const ModalContainer = styled.div(
  () => css`
    width: 768px;
    height: 85vh;
    overflow: hidden;
  `
);

export default AcceleratorStructure2;
