import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AcceleratingCountProps } from "../../interface/Accelerating.interface";

interface Props {
  count: AcceleratingCountProps;
}
const CountingBtn: React.FC<Props> = (props) => {
  const { count } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [hovering, updateHovering] = useState<boolean>(false);
  const [selected, updateSelected] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleMouseDown = (event: any) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      updateSelected(false);
    }
  };

  useEffect(() => {
    setShowTooltip(hovering || selected);
  }, [hovering, selected]);

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);
  return (
    <Container
      ref={containerRef}
      className={`caption-12-md ${selected ? "selected" : ""}`}
      onMouseEnter={() => {
        updateHovering(true);
      }}
      onMouseLeave={() => {
        updateHovering(false);
      }}
      onClick={() => {
        updateSelected(true);
      }}
    >
      카운팅
      {showTooltip && (
        <TooltipContainer className="body-14-rg">
          {count.riskStatus0 > 0 && (
            <TooltipRow>평가없음 : {count.riskStatus0}개</TooltipRow>
          )}
          {count.riskStatus1 > 0 && (
            <TooltipRow>성장 : {count.riskStatus1}개</TooltipRow>
          )}
          {count.riskStatus2 > 0 && (
            <TooltipRow>관찰 : {count.riskStatus2}개</TooltipRow>
          )}
          {count.riskStatus3 > 0 && (
            <TooltipRow>우려 : {count.riskStatus3}개</TooltipRow>
          )}
          {count.riskStatus4 > 0 && (
            <TooltipRow>손상우려 : {count.riskStatus4}개</TooltipRow>
          )}
          {count.riskStatus5 > 0 && (
            <TooltipRow>손상 : {count.riskStatus5}개</TooltipRow>
          )}
          {count.riskStatus6 > 0 && (
            <TooltipRow>폐업진행 : {count.riskStatus6}개</TooltipRow>
          )}
          <TooltipDivier />
          <TooltipRow>전체 : {count.total}개</TooltipRow>
          {count.tipsExpected > 0 && (
            <TooltipRow>TIPS 희망 : {count.tipsExpected}개</TooltipRow>
          )}
          {count.tipsFixed > 0 && (
            <TooltipRow>TIPS 확정 : {count.tipsFixed}개</TooltipRow>
          )}
        </TooltipContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  width: auto;
  height: auto;

  min-width: 48px;

  padding: 6px 8px;
  border-radius: 4px;
  color: var(--text-05);

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }

  &.selected {
    background-color: var(--hover-100);
    :hover {
      background-color: var(--hover-100);
    }
  }
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  top: calc(100% + 8px);

  padding: 12px 20px;
  background-color: var(--dim-800);
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 4px;
  z-index: 2;
`;
const TooltipRow = styled.span`
  width: max-content;
  color: var(--text-white);
`;

const TooltipDivier = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--divider-200);
`;

export default CountingBtn;
