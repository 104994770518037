import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GovermentTask, govStatusList } from "../../../interface/SBP.interface";
import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";

import { CustomSelector } from "../../table/custom-cell/CustomSelector";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  govDataList?: GovermentTask[];
  onDataChange: (data: GovermentTask[]) => void;
}

export const GovermentTaskComponent: React.FC<Props> = (props) => {
  const [govDataList, updateGovDataList] = useState<
    GovermentTask[] | undefined
  >(undefined);
  const headers: TableGridHeaderProps[] = [
    {
      header: "사업명",
      key: "taskName",
      width: 220,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            transfromPixel={(n) => n}
            placeholder="TIPS프로그램"
            defaultValue={data}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "담당 기관",
      key: "responsibleAgency",
      width: 220,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="중소벤처기업부"
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "시작일",
      key: "startDate",
      width: 182,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultDate={data}
            onChangeDate={(date) => console.log(date)}
          />
        );
      },
    },
    {
      header: "종료일",
      key: "endDate",
      width: 182,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultDate={data}
            onChangeDate={(date) => {
              console.log(date);
            }}
          />
        );
      },
    },
    {
      header: "정부출연금 (원)",
      key: "govFunding",
      width: 178,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            transfromPixel={(n) => n}
            placeholder="100,000,000"
            defaultValue={data}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "현황",
      key: "govStatus",
      width: 144,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            defaultData={data}
            selectList={govStatusList}
            placehoder="선택"
            onChangeData={(value) => {
              if (!govDataList) return;
              allData.govStatus = value;
              updateGovDataList([...govDataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newDate: GovermentTask = {};
    const newGovData: GovermentTask[] = [...(govDataList || []), newDate];
    updateGovDataList(newGovData);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!govDataList) return;

    const newGovData = govDataList.filter((item, index) => index !== id);
    updateGovDataList([...newGovData]);
  };

  useEffect(() => {
    updateGovDataList(props.govDataList);
  }, [props.govDataList]);

  useEffect(() => {
    if (govDataList) props.onDataChange([...govDataList]);
  }, [govDataList]);

  if (!govDataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={govDataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
