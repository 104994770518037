import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Help } from "../../../../../assets/image/icon_tooltip.svg";

interface Props {
  gorupTitle: string;
  title: string;
  groupStyle?: React.CSSProperties;
  tooltipId?: string;
  transfromPixel: (value: number) => number;
}

const GroupHeader: React.FC<Props> = (props) => {
  const { gorupTitle, groupStyle, title, tooltipId, transfromPixel } = props;

  return (
    <Wrapper>
      <Container
        className="body-14-rg"
        style={{
          ...groupStyle,
          fontSize: transfromPixel(12),
          padding: `0 ${transfromPixel(20)}px`,
          gap: transfromPixel(2),
        }}
      >
        {gorupTitle || ""}
      </Container>
      <Container
        className="body-14-rg"
        style={{
          fontSize: transfromPixel(12),
          padding: `0 ${transfromPixel(20)}px`,
          gap: transfromPixel(2),
        }}
      >
        {title}
        {tooltipId && (
          <Help
            className={tooltipId}
            data-tooltip-delay-hide={2000}
            style={{ outline: "none" }}
            tabIndex={-1}
          />
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 32px;
  color: #96999f; //text05
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border-left: 1px solid var(--divider-200);
  border-bottom: 1px solid var(--divider-200);
`;

export default GroupHeader;
