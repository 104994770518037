import React from "react";
import styled from "styled-components";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  title: string;
  titleWidth?: number;
  children: React.ReactNode;
  transfromPixel: (value: number) => number;
}
const RowTitleBox: React.FC<Props> = (props) => {
  const {
    className,
    style,
    title,
    titleWidth = 120,
    children,
    transfromPixel,
  } = props;
  return (
    <Container
      className={className}
      transfromPixel={transfromPixel}
      style={style}
    >
      <Header
        className="heading-14-sb"
        transfromPixel={transfromPixel}
        style={{ width: transfromPixel(titleWidth) }}
      >
        {title}
      </Header>
      <Body transfromPixel={transfromPixel}>{children}</Body>
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Header = styled.div<{ transfromPixel: (value: number) => number }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: fit-content;
  min-height: ${(props) => props.transfromPixel(40)}px;

  padding: ${(props) => props.transfromPixel(9)}px
    ${(props) => props.transfromPixel(4)}px;

  color: var(--text-04);
`;
const Body = styled.div<{ transfromPixel: (value: number) => number }>`
  flex-grow: 1;
  height: fit-content;
`;
export default RowTitleBox;
