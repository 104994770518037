import React, { useRef } from "react";
import styled from "styled-components";
import { PrePitchingData } from "../../../pre-pitching/interface/PrePitching.interface";
import { TitleBox } from "../../../../../components-2.0v/box/TitleBox";
import { UserInfo } from "../../../../../type/data";
import { DefaultButton } from "lattice_core";
import { PitchingProgressStatus } from "./PitchingProgressStatus";
import { getYMD } from "../../../../../common/commonUtil";

interface Props {
  isEditable: boolean;
  pitchingData: PrePitchingData;
  allUser: UserInfo[];

  publishingModify: () => void;
}

export const PitchingProgress: React.FC<Props> = (props) => {
  const { isEditable, pitchingData, allUser, publishingModify } = props;
  const isNotComplete = useRef<boolean>(false);
  const isResponseZero = useRef<boolean>(false);

  const getCardTitle = (date?: string) => {
    if (!date) return "피칭심사";
    const transformDate = getYMD(date).replaceAll("-", "").slice(2);
    return `${transformDate} 피칭심사`;
  };

  return (
    <ContainerWarpper>
      <Container id={"root__component"} className="scroll__invisible">
        <SurveyContainer style={{ paddingBottom: isEditable ? 120 : 60 }}>
          <TitleBox title={getCardTitle(pitchingData.createdAt)}>
            <PitchingProgressStatus
              allUser={allUser}
              isEditable={isEditable}
              pitchingData={pitchingData}
              statusLoaded={(complete, reponseZero) => {
                console.log(complete);
                console.log(reponseZero);
                isNotComplete.current = complete;
                isResponseZero.current = reponseZero;
              }}
            />
          </TitleBox>
        </SurveyContainer>
      </Container>
      {isEditable && (
        <Footer>
          <DefaultButton
            className="body-14-md"
            buttonType="filled"
            buttonColor="var(--primary-blue)"
            hoverColor="var(--blue-600)"
            sizeType="small"
            text="수정하기"
            onClick={() => {
              publishingModify();
            }}
          />
        </Footer>
      )}
    </ContainerWarpper>
  );
};

const ContainerWarpper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const SurveyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;
const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  bottom: 0px;
  padding: 0 24px;
  gap: 16px;

  border-top: 2px solid var(--divider-200, #edeeef);
  background: var(--background-header, #fff);
`;
