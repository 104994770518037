import React, { useEffect, useRef, useState } from "react";
import {
  IACompanyInfo,
  IAReportAccount,
  InvestmentAssessment,
  SignatureInfo,
} from "../../../../type/data";
import axios from "axios";
import * as api from "../../../../api/api";
import { useLocation, useNavigate } from "react-router-dom";

import {
  AddComma,
  getInvestmentAssessmentIdInLocation,
  getReportAccountIdInLocation,
  getKoreanMoneyUnit,
  getMyInfo,
  getPortfolioIdInLocation,
  getYMDHMKo,
  getNewDate,
  showToast,
  trnasfromBase64ToObject,
  historyBack,
} from "../../../../common/commonUtil";
import { Bounce, toast } from "react-toastify";
import {
  LOGO_SMALL,
  getLogo,
  getSignature,
} from "../../../../api/repository/accelerator/AcceleratorRepository";
import { MinutesProps } from "../desktop/DeskEvaluationTask";
import { createBrowserHistory } from "history";
import { useMediaQuery } from "react-responsive";
import { showDeskToast, showMobileToast } from "../../../../2.0lattice/toast";

const getIds = (location: any, type: "portfolio" | "investmentAssessment") => {
  const code = location.pathname.split("/")[3];
  const { portfolioId, investmentAssessmentId } = trnasfromBase64ToObject(code);

  if (type === "portfolio") {
    return portfolioId;
  } else {
    return investmentAssessmentId;
  }
};

interface VoteProps {
  portfolio: { portfolioId: number };
  investmentAssessmentInvite: {
    isAgreement: boolean;
    comment: string;
    investmentAssessmentReportAccount: {
      investmentAssessmentReportAccountId: string;
      investmentAssessment: {
        investmentAssessmentId: number;
      };
    };
    signatureTmp: {
      type: number;
      url?: string;
      path?: any[];
    };
  };
}

const useEvaluationTask = () => {
  const history = createBrowserHistory();
  const navigate = useNavigate();
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  const portfolioId = useRef<number>(getPortfolioIdInLocation(location));
  const investmentAssessmentId = useRef<number>(
    getInvestmentAssessmentIdInLocation(location)
  );
  const reportId = useRef<number | string>(
    getReportAccountIdInLocation(location)
  );

  const acFundAccountId = useRef<number | undefined>(undefined);
  const [targetDocument, updateTargetDocument] = useState<
    MinutesProps | undefined
  >(undefined);

  const [logoImage, setLogoImage] = useState<string | undefined>(undefined);
  const [isVoteDone, updateVoteDone] = useState<boolean>(false);

  const [signatureData, setSignatureData] = useState<SignatureInfo>();

  const [isInAssessmentDate, setIsInAssessmentDate] = useState<boolean>(false);

  const onVote = async () => {
    try {
      console.log(targetDocument);

      if (
        targetDocument &&
        targetDocument.isAgreement !== undefined &&
        targetDocument.isAgreement !== null &&
        targetDocument.comment &&
        signatureData
      ) {
        const params: VoteProps = {
          portfolio: { portfolioId: portfolioId.current },
          investmentAssessmentInvite: {
            isAgreement: targetDocument.isAgreement,
            comment: targetDocument.comment,
            investmentAssessmentReportAccount: {
              investmentAssessmentReportAccountId: `${reportId.current}`,
              investmentAssessment: {
                investmentAssessmentId: investmentAssessmentId.current,
              },
            },
            signatureTmp: {
              type: signatureData.type || 0,
              url: signatureData.type === 0 ? signatureData.url : undefined,
              path: signatureData.type === 1 ? signatureData.path : undefined,
            },
          },
        };
        const result = await axios.patch(api.doingVote(), params);
        if (result.status === 200) {
          notifyToast(isVoteDone ? "completeUpdate" : "completeSubmit");
          historyBack(() => {
            navigate("/deal-flow");
          });
        }
      }
    } catch (error) {
      isMobile
        ? showMobileToast("error", "제출이 실패하였습니다.")
        : showDeskToast("error", "제출이 실패하였습니다.");
    }
  };

  const notifyToast = (
    type: "alreadyConfirm" | "completeSubmit" | "completeUpdate"
  ) => {
    switch (type) {
      case "alreadyConfirm": {
        break;
      }
      case "completeSubmit": {
        isMobile
          ? showMobileToast("success", "제출 하였습니다.")
          : showDeskToast("success", "제출 하였습니다.");
        break;
      }
      case "completeUpdate": {
        isMobile
          ? showMobileToast("success", "수정 후 제출 하였습니다.")
          : showDeskToast("success", "수정 후 제출 하였습니다.");

        break;
      }
    }
  };

  const initMinutes = async () => {
    try {
      //투자심사 보고서 정보 가져오기
      const logoResult = await getLogo(LOGO_SMALL);
      const _document = await axios.get(
        api.getInvestmentAssessmentDocument(
          portfolioId.current,
          investmentAssessmentId.current
        )
      );

      if (_document.status === 200) {
        console.log(_document);

        if (_document.data.isConfirmed) {
          notifyToast("alreadyConfirm");
          navigate("/mytask", { replace: true });
        }

        const totalTradeAmount =
          _document.data.investmentAssessmentReportAccount
            .filter(
              (item: IAReportAccount) =>
                item.investmentAssessmentAccount.newOldStock?.newOldStockId ===
                2
            )
            .map(
              (item: IAReportAccount) =>
                item.investmentAssessmentAccount.investmentAmount || 0
            )
            .reduce((prev: number, cur: number) => prev + cur, 0);

        const totalTradeStock = _document.data.investmentAssessmentReportAccount
          .filter(
            (item: IAReportAccount) =>
              item.investmentAssessmentAccount.newOldStock?.newOldStockId === 2
          )
          .map(
            (item: IAReportAccount) =>
              item.investmentAssessmentAccount.stockNumber || 0
          )
          .reduce((prev: number, cur: number) => prev + cur, 0);
        const companyInfo: IACompanyInfo =
          _document.data.investmentAssessmentCompanyInfo;
        const reportAccount: IAReportAccount | undefined =
          _document.data.investmentAssessmentReportAccount.find((item: any) => {
            console.log(
              item.investmentAssessmentReportAccountId,
              reportId.current
            );

            return (
              item.investmentAssessmentReportAccountId === reportId.current
            );
          });

        console.log(companyInfo, reportAccount);
        const myInfo = getMyInfo();

        if (reportAccount && companyInfo) {
          acFundAccountId.current =
            reportAccount.investmentAssessmentAccount.acFundAccount?.acFundAccountId;
          const myOpinion = reportAccount.investmentAssessmentInvite.find(
            (item) => item.acUser.acUserId === myInfo.id
          );

          let date = getNewDate(
            `${reportAccount.assessmentEndDate?.date} ${reportAccount.assessmentEndDate?.time}`
          );

          const documentData: MinutesProps = {
            reportFile: reportAccount.reportFile,
            fundAccount: {
              acfundAccountId:
                reportAccount.investmentAssessmentAccount.acFundAccount
                  ?.acFundAccountId || 0,
              accountName:
                reportAccount.investmentAssessmentAccount.accountName || "",
              newOldStock:
                reportAccount.investmentAssessmentAccount.newOldStock,
            },
            companyName: companyInfo.companyName || "",
            date: getYMDHMKo(date), //Q. 날짜는 어떤걸 넣어야 하나?
            stockType:
              reportAccount.investmentAssessmentAccount.stockType?.name,
            totalTradeAmount: `금 ${getKoreanMoneyUnit(
              totalTradeAmount
            ).trimEnd()} 원 (${AddComma(totalTradeAmount)} 원)`,
            totalInvestmentAmount: reportAccount.investmentAssessmentAccount
              .totalInvestmentAmount
              ? `금 ${getKoreanMoneyUnit(
                  reportAccount.investmentAssessmentAccount
                    .totalInvestmentAmount
                ).trimEnd()} 원 (${AddComma(
                  reportAccount.investmentAssessmentAccount
                    .totalInvestmentAmount
                )} 원)`
              : `금 ${getKoreanMoneyUnit(
                  reportAccount.investmentAssessmentAccount.investmentAmount ||
                    ""
                ).trimEnd()} 원 (${AddComma(
                  reportAccount.investmentAssessmentAccount.investmentAmount
                )} 원)`,
            investmentAmount: reportAccount.investmentAssessmentAccount
              .investmentAmount
              ? `금 ${getKoreanMoneyUnit(
                  reportAccount.investmentAssessmentAccount.investmentAmount ||
                    ""
                ).trimEnd()} 원 (${AddComma(
                  reportAccount.investmentAssessmentAccount.investmentAmount
                )} 원)`
              : undefined,
            issuingPrice:
              reportAccount.investmentAssessmentAccount.issuingPrice &&
              reportAccount.investmentAssessmentAccount.faceValue
                ? `${AddComma(
                    reportAccount.investmentAssessmentAccount.issuingPrice
                  )} 원 ${
                    reportAccount.investmentAssessmentAccount.newOldStock
                      ?.newOldStockId === 1
                      ? `(액면가 ${AddComma(
                          reportAccount.investmentAssessmentAccount.faceValue
                        )} 원)`
                      : ""
                  }`
                : undefined,
            curNewIssuingPrice: reportAccount.investmentAssessmentAccount
              .curNewIssuingPrice
              ? `${AddComma(
                  reportAccount.investmentAssessmentAccount.curNewIssuingPrice
                )} 원 (액면가 ${AddComma(
                  reportAccount.investmentAssessmentAccount.faceValue
                )} 원)`
              : undefined,
            postTotalStockNumber: reportAccount.investmentAssessmentAccount
              .postTotalStockNumber
              ? `${AddComma(
                  reportAccount.investmentAssessmentAccount.postTotalStockNumber
                )}주`
              : `${AddComma(
                  reportAccount.investmentAssessmentAccount.stockNumber
                )}주`,
            stockNumber: reportAccount.investmentAssessmentAccount.stockNumber
              ? `${AddComma(
                  reportAccount.investmentAssessmentAccount.stockNumber
                )}주`
              : undefined,
            shareholdingRatio:
              reportAccount.investmentAssessmentAccount.shareholdingRatio &&
              reportAccount.investmentAssessmentAccount.postCompanyValue
                ? `${
                    reportAccount.investmentAssessmentAccount.shareholdingRatio
                  }% (투자 후 기업 가치 ${AddComma(
                    reportAccount.investmentAssessmentAccount.postCompanyValue
                  )} 원)`
                : undefined,
            acUserName: myInfo.name,
            totalTradeStock: `${AddComma(totalTradeStock)} 주`,
            buyCompany: reportAccount.investmentAssessmentAccount.buyCompany,
            buyCompanyValue: reportAccount.investmentAssessmentAccount
              .buyCompanyValue
              ? `${AddComma(
                  reportAccount.investmentAssessmentAccount.buyCompanyValue
                )}원`
              : undefined,
            discount: reportAccount.investmentAssessmentAccount.discount
              ? `${reportAccount.investmentAssessmentAccount.discount}%`
              : undefined,
          };
          console.log(documentData);
          if (myOpinion) {
            updateVoteDone(
              myOpinion.comment !== undefined &&
                myOpinion.comment !== null &&
                myOpinion.isAgreement !== undefined &&
                myOpinion.isAgreement !== null &&
                myOpinion.signatureTmp !== undefined &&
                myOpinion.signatureTmp !== null
            );
            documentData.comment =
              myOpinion.comment?.length === 0 ? undefined : myOpinion.comment;
            documentData.isAgreement =
              myOpinion.isAgreement === null
                ? undefined
                : myOpinion.isAgreement;
            console.log(myOpinion.signatureTmp);

            if (myOpinion.signatureTmp) {
              // documentData.signatureTmp = myOpinion.signatureTmp;
              setSignatureData(myOpinion.signatureTmp);
            } else {
              // signature 없으면 디폴트 서명
              const signature = await getSignature(myInfo.id); // TODO id 확인 필요
              console.log(signature);

              setSignatureData(signature);
            }
          }
          updateTargetDocument({ ...documentData });

          const serverTime = (await axios.get(api.getServerTime())).data.date;
          // new Date(마지막 Z 있음) => UTC 시간에서 +0900
          const serverDate = getNewDate(serverTime);
          // new Date(마지막 Z 없음) => 현지시간 그대로
          const startDate = getNewDate(
            `${reportAccount.assessmentStartDate?.date} ${reportAccount.assessmentStartDate?.time}`
          );

          setLogoImage(logoResult);
          setIsInAssessmentDate(serverDate >= startDate);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // initEvaluation();
    initMinutes();
  }, []);

  return [
    portfolioId.current,
    logoImage,
    navigate,
    targetDocument,
    signatureData,
    updateTargetDocument,
    setSignatureData,
    isVoteDone,
    isInAssessmentDate,
    onVote,
  ] as const;
};

export default useEvaluationTask;
