import { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Lock } from "../../../../../assets/image/icon_lock.svg";
interface Props {
  msg: string[];
}
export const PitchingEmpty: React.FC<Props> = (props) => {
  const { msg } = props;
  useEffect(() => {}, []);

  return (
    <Container>
      <Lock />
      {msg.map((item) => (
        <div
          className="heading-18-md"
          style={{ color: "var(--text-04)", marginTop: 20 }}
        >
          {item}
        </div>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
