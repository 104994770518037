import React from "react";
import { useMediaQuery } from "react-responsive";

import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";
import MobilePrePitching from "./mobile/MobilePrePitching";
import DeskPrePitching from "./desktop/DeskPrePitching";

const PrePitching: React.FC<PortfolioNavigationDefaultProps> = (props) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;

  return isMobile ? (
    <MobilePrePitching {...props} />
  ) : (
    <DeskPrePitching {...props} />
  );
};
export default PrePitching;
