import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AccountInvestmentInfoProps,
  CurrentInvestmentInfoProps,
  InvestmentHistoryProps,
  RealDataProps,
  StockHolderGrouping,
} from "../interface/InvestmentInfo.interface";
import {
  getInvestmentInfo,
  getPortfolioCurrency,
  getStockholderList,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import { checkAccessToMenu } from "../../../../common/commonApiData";
import { PortfolioMenuType, XIRRDataProps } from "../../../../type/data";
import {
  getCashDateFlow,
  getExitCashDateFlow,
  getNewDate,
  getPortfolioIdInLocation,
  getStockRateCashDateFlow,
  transformObjectToBase64,
  xirr,
} from "../../../../common/commonUtil";
import config from "../../../../config";

const useInvestmentInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const portfolioId = getPortfolioIdInLocation(location);

  const [currency, setCurrency] = useState<string>("");
  const [accessible, setAccessible] = useState<boolean>(false);
  const [currentInvestmentInfo, setCurrentInvestmentInfo] =
    useState<CurrentInvestmentInfoProps>({
      firstCompanyValue: 0,
      companyValue: 0,
      followUpAmount: 0,
      totalStock: 0,
      issuingPrice: 0,
    });
  const [stockHolderList, setStockHolderList] = useState<StockHolderGrouping[]>(
    []
  );
  const [investmentInfo, setInvestmentInfo] = useState<
    AccountInvestmentInfoProps[]
  >([]);
  const [realStockChangeData, setRealStockChangeData] = useState<
    RealDataProps[]
  >([]);

  const viewCapitalRaisingList = () => {
    const popupWidth = screen.width;
    const popupHeight = screen.height;
    const domainUrl = config.clientUrl || "";

    const pathParam = transformObjectToBase64({ portfolioId: portfolioId });
    const url = `${domainUrl}portfolio/${pathParam}/capitalRaisingListViewer`;
    const specs = `width=${popupWidth},height=${popupHeight},left=0,top=0,toolbar=no,menubar=no`;

    window.open(url, "_blank", specs);
  };

  const viewStockChangeList = () => {
    const popupWidth = screen.width;
    const popupHeight = screen.height;
    const domainUrl = config.clientUrl || "";

    const pathParam = transformObjectToBase64({ portfolioId: portfolioId });

    const url = `${domainUrl}portfolio/${pathParam}/portfolioInvestmentInfoViewer`;
    const specs = `width=${popupWidth},height=${popupHeight},left=0,top=0,toolbar=no,menubar=no`;

    window.open(url, "_blank", specs);
  };

  const getCurrency = async () => {
    const result = await getPortfolioCurrency(portfolioId);

    if (result !== undefined) {
      setCurrency(result);
    }
  };

  const calExitIRR = (data: InvestmentHistoryProps[]) => {
    const exitData = data.map((item): XIRRDataProps => {
      return {
        date: getNewDate(item.date),
        type: item.type === "INVEST" ? 1 : 2,
        originAmount: item.principal,
        leftAmount: item.type === "INVEST" ? item.amount : undefined,
        exitAmount: item.type === "EXIT" ? item.amount : undefined,
      };
    });
    const exitCDFlow = getExitCashDateFlow(exitData);
    return (
      xirr(
        exitCDFlow.map((item) => item.cash),
        exitCDFlow.map((item) => item.date)
      ) || 0
    );
  };
  const calRemainIRR = (
    data: InvestmentHistoryProps[],
    investedValue: number
  ) => {
    const remainData = data.map((item): XIRRDataProps => {
      return {
        date: getNewDate(item.date),
        type: item.type === "INVEST" ? 1 : 2,
        originAmount: item.principal,
        leftAmount: item.type === "INVEST" ? item.amount : undefined,
        exitAmount: item.type === "EXIT" ? item.amount : undefined,
      };
    });
    const remainCDFlow = getStockRateCashDateFlow(remainData, investedValue);
    return (
      xirr(
        remainCDFlow.map((item) => item.cash),
        remainCDFlow.map((item) => item.date)
      ) || 0
    );
  };
  const calInvestIRR = (
    data: InvestmentHistoryProps[],
    investedValue: number
  ) => {
    const investData = data.map((item): XIRRDataProps => {
      return {
        date: getNewDate(item.date),
        type: item.type === "INVEST" ? 1 : 2,
        originAmount: item.principal,
        leftAmount: item.type === "INVEST" ? item.amount : undefined,
        exitAmount: item.type === "EXIT" ? item.amount : undefined,
      };
    });
    const investCDFlow = getCashDateFlow(investData, investedValue);
    return (
      xirr(
        investCDFlow.map((item) => item.cash),
        investCDFlow.map((item) => item.date)
      ) || 0
    );
  };

  const init = async () => {
    const resultForInvestmentInfo = await getInvestmentInfo(portfolioId);
    const resultForStockholderList = await getStockholderList(portfolioId);

    console.log(resultForInvestmentInfo);
    if (resultForInvestmentInfo) {
      const newData = resultForInvestmentInfo.accountInfo.map(
        (item): AccountInvestmentInfoProps => {
          return {
            ...item,
            exitMOIC:
              item.exitPrincipal > 0 ? item.exitAmount / item.exitPrincipal : 0,
            exitIRR: calExitIRR(item.detail),
            remainMOIC: item.remainPrincipal
              ? item.shareValue / item.remainPrincipal
              : 0,
            remainIRR: calRemainIRR(item.detail, item.shareValue),
            investMOIC:
              (item.shareValue + item.exitAmount) /
              (item.remainPrincipal + item.exitPrincipal),
            investIRR: calInvestIRR(item.detail, item.shareValue),
          };
        }
      );
      setCurrentInvestmentInfo(resultForInvestmentInfo.total);
      setInvestmentInfo(newData);
    }

    if (resultForStockholderList) {
      console.log(resultForStockholderList);
      const stockHolderGroupingData: StockHolderGrouping[] = [];
      resultForStockholderList.forEach((stockholder) => {
        const targetGrouping = stockHolderGroupingData.find(
          (item) => item.id === stockholder.pfStakeholder.id
        );
        if (targetGrouping) {
          targetGrouping.totalStockNumber += stockholder.stockNumber;
          targetGrouping.totalAmount +=
            stockholder.stockNumber * stockholder.faceValue;
          targetGrouping.totalPercentage += parseFloat(stockholder.percentage);
          targetGrouping.stockData.push(stockholder);
        } else {
          stockHolderGroupingData.push({
            id: stockholder.pfStakeholder.id,
            stakeType: stockholder.pfStakeholder.stakeHolder.type,
            countryId: stockholder.pfStakeholder.stakeHolder.country?.id,
            name: stockholder.pfStakeholder.name,
            registrationNumber:
              stockholder.pfStakeholder.stakeHolder.uniqueNumber,
            totalStockNumber: stockholder.stockNumber,
            totalAmount: stockholder.stockNumber * stockholder.faceValue,
            totalPercentage: parseFloat(stockholder.percentage),
            stockData: [stockholder],
          });
        }
      });
      console.log(stockHolderGroupingData);
      setStockHolderList(stockHolderGroupingData);
    }
  };

  const checkIsAccessible = async () => {
    const isAccessible = await checkAccessToMenu(
      PortfolioMenuType.INVESTMENT_INFO,
      portfolioId
    );
    if (!isAccessible) {
      alert("현재 포트폴리오 상태에서 접근이 불가능한 페이지입니다.");
      navigate(-1);
    } else {
      setAccessible(true);
    }
  };

  useEffect(() => {
    if (accessible) {
      getCurrency();
      init();
    }
  }, [accessible]);

  useEffect(() => {
    checkIsAccessible();
  }, []);

  return [
    currentInvestmentInfo,
    stockHolderList,
    investmentInfo,
    currency,
    viewStockChangeList,
    viewCapitalRaisingList,
  ] as const;
};

export default useInvestmentInfo;
