import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Styled, { css } from "styled-components";
import { getNewDate, getYMD } from "../../common/commonUtil";

interface Props {
  readonly?: boolean;
  textClassName?: string;
  width?: number | string;
  defaultDate?: string;
  min?: string;
  onChange?: (e: string) => void;
}

export const InputDate: React.FC<Props> = (props) => {
  const {
    readonly,
    textClassName = "body-14-rg",
    width = 175,
    defaultDate,
    min,
    onChange,
  } = props;
  const [value, setValue] = useState<string>("");

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    setValue(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    const date = defaultDate ? getYMD(getNewDate(defaultDate)) : "";
    setValue(date);
  }, [defaultDate]);

  return (
    <Container style={{ width: width }}>
      <InputComponent
        className={textClassName}
        type="date"
        min={min}
        max={"9999-12-31"}
        value={value}
        readOnly={readonly}
        onChange={onChangeInput}
      />
    </Container>
  );
};

const Container = Styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
  gap: 4px;
`;

const InputComponent = styled.input`
  width: 100%;
  height: 40px;

  border: 1px solid var(--gray-300);
  border-radius: 4px;
  transition: 0.2s;
  padding: 6px 12px;
  background: var(--background-contents01);

  ::placeholder {
    color: var(--text-inactive);
  }

  :read-only {
    padding: 6px 12px;
    border: 1px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  :disabled {
    border: 1px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &.show__msg {
    border: 1px solid var(--red-500);
    animation: shake 200ms;

    :focus {
      border: 1px solid var(--red-500);
    }

    :hover {
      border-color: var(--red-500);
    }
  }

  :focus {
    outline: none;
    border-color: var(--focus-field-blue);

    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    border-color: var(--focus-field-blue);
  }

  @keyframes shake {
    25% {
      transform: translateX(2px);
    }
    50% {
      transform: translateX(-2px);
    }
    75% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(-2px);
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;
