import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../components/navigation/NavigationLayout";
import MobileAccelerating from "./mobile/MobileAccelerating";
import DeskAccelerating from "./desktop/DeskAccelerating";

const Accelerating = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobileAccelerating /> : <DeskAccelerating />}
    </NavigationLayout>
  );
};
export default Accelerating;
