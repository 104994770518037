import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";

import { TitleSection } from "./components/TitleSection";
import { ParticipantInfo } from "./organism/ParticipantInfo";
import { BusinessInfo } from "./organism/BusinessInfo";
import usePortfolioInfoModify from "../hook/usePortfolioInfoModify";

import { StartupInfoView } from "./organism/StartupInfoView";
import { StartupInfoEdit } from "./organism/StartupInfoEdit";
import { DefaultButton } from "lattice_core";
import SpinnerModal from "../../../../components/modal/SpinnerModal";

const DeskPortfolioInfoModify: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const { portfolioId, portfolioNavInfo, isEditable, init } = props;
  const [
    portfolioInfo,
    allIndustrialTech,
    allUser,
    isSubmitAble,
    errorMSGArr,
    isLoading,
    onChangeData,
    onSubmit,
    checkSubmitAble,
    onClickCancel,
  ] = usePortfolioInfoModify(
    portfolioId,
    portfolioNavInfo.pfPhase.type,
    init,
    portfolioNavInfo.phase
  );

  if (!portfolioInfo) return null;

  return (
    <Container>
      <ContentContainer>
        <ContentsWrapper>
          <FlexRow style={{ gap: 14 }}>
            <div style={{ flex: 1 }}>
              <TitleSection title="스타트업 개요">
                <StartupInfoEdit
                  startupInfo={portfolioInfo.startupInfo}
                  onChangeData={onChangeData}
                  phase={portfolioNavInfo.phase}
                  errorMSGArr={errorMSGArr}
                />
              </TitleSection>
            </div>
            <div style={{ flex: 1 }}>
              <TitleSection title="심사역 개요">
                <ParticipantInfo
                  participantInfo={portfolioInfo.participantInfo}
                  allUser={allUser}
                  onChangeData={onChangeData}
                />
              </TitleSection>
            </div>
          </FlexRow>
          <TitleSection title="사업 정보">
            <BusinessInfo
              allIndustrialTech={allIndustrialTech}
              portfolioPhase={portfolioNavInfo.pfPhase.type}
              phase={portfolioNavInfo.phase}
              businessInfo={portfolioInfo.businessInfo}
              onChangeData={onChangeData}
            />
          </TitleSection>
        </ContentsWrapper>
      </ContentContainer>
      <Footer>
        <DefaultButton
          className="heading-16-md"
          buttonType="colored-outline"
          sizeType="small"
          buttonColor={"var(--text-01)"}
          borderColor={"var(--gray-400)"}
          disabled={!isSubmitAble}
          text="취소"
          onClick={(e) => {
            e && e.stopPropagation();
            onClickCancel();
          }}
        />
        <DefaultButton
          className="heading-16-md"
          buttonType="filled"
          sizeType="small"
          buttonColor={"var(--primary-blue)"}
          hoverColor={"var(--blue-600)"}
          disabled={!isSubmitAble}
          text="완료"
          onClick={(e) => {
            e && e.stopPropagation();
            onSubmit();
          }}
        />
      </Footer>
      {isLoading && (
        <SpinnerModal
          open={isLoading}
          isFullScreen
          message="잠시만 기다려 주세요..."
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  padding-bottom: 98px;
  gap: 24px;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  bottom: 0px;
  padding: 0 60px;

  border-top: 2px solid var(--divider-200, #edeeef);
  background: var(--background-header, #fff);
  gap: 12px;
`;

export default DeskPortfolioInfoModify;
