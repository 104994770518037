import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";
import SignatureCanvas from "react-signature-canvas";

import axios from "axios";
import * as api from "../../../../../api/api";

import { ReactComponent as RadioOff } from "../../../../../assets/image/checkbox_off.svg";
import { ReactComponent as RadioOn } from "../../../../../assets/image/checkbox_checked.svg";
import FileUploaderLayout from "../../../../../components/upload/FileUploaderLayout";
import DefaultButton from "../../../../../components/button/DefaultButton";
import { getMyInfo } from "../../../../../common/commonApiData";

interface Props {
  callBackSignData: (originData: any, trimData: any) => void;
  callBackSignImg?: (url: string) => void;
  toggle?: () => void;

  canUploadImage?: boolean;
  canSetDefault?: boolean;
  portfolioId?: number;
}

const SignatureModal: React.FC<Props> = (props) => {
  const canvasRef = useRef<any>(null);
  const [signatureData, setSignatureData] = useState<string>("");
  const [isSigned, setIsSigned] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<"UPLOAD" | "DRAW">(
    props.canUploadImage ? "UPLOAD" : "DRAW"
  );

  const [uploadedImage, setUploadedImage] = useState<{
    url: string;
    key: string;
  }>();

  const [isDefault, setIsDefault] = useState<boolean>(false);

  return (
    <Container>
      <Header>
        <div className="font-medium-20">{"서명하기"}</div>
        <Close
          onClick={() => {
            props.toggle && props.toggle();
          }}
        />
      </Header>
      <Body>
        {props.canUploadImage && (
          <Tabs>
            <Tab
              isSelected={selectedTab === "UPLOAD"}
              onClick={() => {
                setSelectedTab("UPLOAD");
              }}
            >
              <span className="font-medium-14">업로드</span>
              <div className="underline" />
            </Tab>
            <Tab
              isSelected={selectedTab === "DRAW"}
              onClick={() => {
                setSelectedTab("DRAW");
              }}
            >
              <span className="font-medium-14">그리기</span>
              <div className="underline" />
            </Tab>
          </Tabs>
        )}
        {selectedTab === "UPLOAD" && (
          <UploadImageContainer>
            <FileUploaderLayout
              editable={true}
              hasFile={uploadedImage ? true : false}
              acceptTypes={[".jpg", ".png"]}
              defaultElement={(onClick) => {
                return (
                  <div
                    className="empty-image"
                    onClick={() => {
                      onClick();
                    }}
                  >
                    <span className="font-regular-14">이미지 업로드</span>
                    <span className="font-regular-12">
                      최대파일 크기 2MB, 파일형식 jpg, png
                    </span>
                  </div>
                );
              }}
              uploadedElement={(onUpdate, onDelete, onDownload) => {
                return (
                  <div className="uploaded-image">
                    {uploadedImage && uploadedImage.url ? (
                      <img src={uploadedImage.url} />
                    ) : (
                      <span className="error-msg font-regular-14">
                        이미지가 정상적으로 보이지 않습니다.
                      </span>
                    )}
                    <div
                      className="reupload font-medium-12"
                      onClick={() => {
                        onUpdate();
                      }}
                    >
                      재업로드하기
                    </div>
                  </div>
                );
              }}
              onChangeFile={async (e) => {
                console.log(e);
                try {
                  if (!e.target.files || e.target.files.length === 0) return;

                  const formData = new FormData();
                  formData.append("file", e.target.files[0]);

                  const result = (
                    await axios.post(api.uploadAndGetUrl(), formData)
                  ).data;

                  if (
                    result.urlKeyList.length > 0 &&
                    props.portfolioId !== undefined &&
                    props.portfolioId !== null
                  ) {
                    console.log(result);
                    setUploadedImage({
                      url: result.urlKeyList[0].url,
                      key: result.urlKeyList[0].key,
                    });
                  }
                } catch (e) {
                  console.log(e);
                }
              }}
              onDeleteFile={() => {
                console.log();
              }}
            />
          </UploadImageContainer>
        )}
        {selectedTab === "DRAW" && (
          <div className="sign__pad__warpper">
            {!isSigned && (
              <div className="sign__pad__placeholder">
                여기에 서명을 해주세요.
              </div>
            )}
            <div className="sign__pad">
              <SignatureCanvas
                ref={canvasRef}
                canvasProps={{
                  className: "sigCanvas",
                  width: 448,
                  height: 240,
                }}
                clearOnResize={false}
                onBegin={() => {
                  setIsSigned(true);
                }}
              />
              <div
                className="reset"
                onClick={() => {
                  canvasRef.current.clear(); // 리셋
                  setIsSigned(false);
                }}
              >
                <span className="font-medium-12">다시 그리기</span>
              </div>
            </div>
          </div>
        )}
        {props.canSetDefault && (
          <CheckboxContainer>
            {isDefault ? (
              <RadioOn
                onClick={() => {
                  setIsDefault((prev) => !prev);
                }}
              />
            ) : (
              <RadioOff
                onClick={() => {
                  setIsDefault((prev) => !prev);
                }}
              />
            )}
            <span
              className="font-regular-14"
              onClick={() => {
                setIsDefault((prev) => !prev);
              }}
            >
              다음에도 같은 서명 사용하기
            </span>
          </CheckboxContainer>
        )}
        <SubmitContainer>
          <DefaultButton
            width={120}
            disabled={
              (selectedTab === "DRAW" && !isSigned) ||
              (selectedTab === "UPLOAD" && !uploadedImage)
            }
            onClick={async () => {
              let body;
              if (selectedTab === "UPLOAD") {
                console.log("UPLOAD");

                /* 업로드 탭 */
                if (uploadedImage && props.callBackSignImg) {
                  console.log("callBackSignImg");

                  body = {
                    isDefault: isDefault,
                    signature: {
                      type: 0,
                      url: uploadedImage.url,
                      path: undefined,
                    },
                  };

                  props.callBackSignImg(uploadedImage.url);
                }
              } else if (selectedTab === "DRAW") {
                console.log("DRAW");
                /* 그리기 탭 */
                if (canvasRef.current) {
                  body = {
                    isDefault: isDefault,
                    signature: {
                      type: 1,
                      url: undefined,
                      path: [...canvasRef.current.toData()],
                    },
                  };

                  props.callBackSignData(
                    canvasRef.current.toData(),
                    canvasRef.current.getTrimmedCanvas().toDataURL()
                  );
                }
              }
              const myInfo = await getMyInfo();

              const res = await axios.post(
                api.postSignature(Number.parseInt(`${myInfo?.acUserId}`)),
                body
              );
              console.log(res);
            }}
          >
            입력하기
          </DefaultButton>
        </SubmitContainer>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.25);
`;

const Header = styled.div`
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;

  border-bottom: 1px solid var(--grey_20);

  svg {
    cursor: pointer;
  }
`;
const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;

  .clear__sign__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .clear__sign {
    }
  }

  .sign__pad__warpper {
    position: relative;
    width: 448px;
    height: 240px;
    background: #fbfbfb;
    border: 1px solid #dcdcdc;
    margin-top: 8px;

    .sign__pad__placeholder {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .sign__pad {
      position: absolute;
      width: 100%;
      height: 100%;
      .reset {
        position: absolute;
        top: 16px;
        right: 16px;
        color: var(--BPP_blue);
        :hover {
          cursor: pointer;
        }
      }
    }
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid var(--grey_0);
  gap: 7px;
  margin-bottom: 10px;
`;

const Tab = styled.div(
  ({ isSelected }: { isSelected: boolean }) => css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    min-width: 80px;
    align-items: center;

    .underline {
      height: 4px;
      border-radius: 2px;
      background: ${isSelected ? "var(--primary)" : "white"};
      transition: all 100ms ease;
      width: 100%;
    }
  `
);

const UploadImageContainer = styled.div`
  width: 448px;
  height: 240px;
  border: 1px solid var(--grey_10);
  border-radius: 8px;
  margin-top: 8px;

  .empty-image {
    width: 100%;
    height: 100%;
    color: var(--grey_20);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    cursor: pointer;
  }
  .uploaded-image {
    width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img {
      width: 384px;
      height: 188px;

      position: absolute;
      bottom: 16px;
    }

    .error-msg {
      color: var(--grey_20);
    }

    .reupload {
      position: absolute;
      top: 16px;
      right: 16px;
      color: var(--BPP_blue);
      cursor: pointer;
    }
  }
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  padding: 16px 0;
`;

const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
`;

export default SignatureModal;
