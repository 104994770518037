import { PropertyDescriptorParsingType } from "html2canvas/dist/types/css/IPropertyDescriptor";
import React from "react";
import { useNavigate } from "react-router-dom";
import Styled, { css } from "styled-components";

import { ReactComponent as Exit } from "../../../assets/image/ic_portfolio_exit.svg";
import { getTransformedId } from "../../../common/commonUtil";

interface Props {
  title: string;
  portfolioId?: number;
  isExport?: boolean;
  onClickExit?: () => void;
}
const DocumentLayout: React.FC<Props> = ({
  title,
  portfolioId,
  isExport,
  children,
  onClickExit,
}) => {
  const navigate = useNavigate();

  const clickExit = () => {
    if (isExport) {
      navigate(-1);
    } else {
      if (portfolioId)
        navigate(`/portfolio/${getTransformedId(portfolioId)}/evaluation`, {
          replace: true,
        });
    }
  };

  return (
    <Container>
      <div className="tab_layout_header">
        <span className="font-medium-20" style={{ color: "var(--text-01)" }}>
          {title}
        </span>
        <div
          className="exit__btn"
          onClick={onClickExit ? onClickExit : clickExit}
        >
          <Exit className="exit__icon" fill="var(--text-01)" />
          <span className="font-medium-14">나가기</span>
        </div>
      </div>
      <div className="contents">{children}</div>
    </Container>
  );
};

const Container = Styled.div(
  () => css`
    position: relative;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .tab_layout_header {
      width: 100%;
      padding: 0 24px;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: white;

      border-bottom: 1px solid var(--gray-300);

      .exit__btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        cursor: pointer;

        .exit__icon {
          width: 18px;
          height: 18px;
        }
        span {
          color: var(--text-01);
        }
      }
    }
    .contents {
      width: 100%;
      height: calc(100% - 60px);

      background-color: var(--background-page);
      overflow: hidden;
    }
    .tab_layout_footer {
      position: fixed;
      left: 24px;
      bottom: 16px;
      width: calc(100% - 48px);
    }
  `
);

export default DocumentLayout;
