import React from "react";
import styled from "styled-components";

import { TextListHeader } from "./TextListHeader";
import { TextListRow } from "./TextListRow";
import { TextAnswerProps } from "../../interface/PrePitchingDetail.interface";

interface Props {
  type: "long" | "short";
  answers: TextAnswerProps[];
  userInfoHidden?: boolean;
}
export const TextList: React.FC<Props> = (props) => {
  const { type, answers, userInfoHidden } = props;
  return (
    <Container>
      <TextListHeader userInfoHidden={userInfoHidden} />
      {answers.map((item, key) => {
        return (
          <TextListRow
            key={key}
            type={type}
            textAnswer={item}
            userInfoHidden={userInfoHidden}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
