import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../SummaryBusinessPlanV2Download";
import { SBPContentsPropsV2 } from "../../../interface/SBP.interface";

import { AddComma } from "../../../../../../../../../common/commonUtil";
import { CapitalAcquisitionPlanComponent } from "./table/CapitalAcquisitionPlanComponent";

interface Props {
  index: number;
  sbpDocument: SBPContentsPropsV2;
}

export const Plan2: React.FC<Props> = (props) => {
  const getTotalCapitalAmount = () => {
    return props.sbpDocument.businessPlan.capitalAcquisitionPlan
      .map((item) => item.capitalAmount || 0)
      .reduce((prev, cur) => prev + cur, 0);
  };
  return (
    <FlexColumn style={{ gap: `${convertPixelToCm(8)}px` }}>
      <div className="body-16-md" style={{ color: "var(--text-03)" }}>
        0{props.index}. 자금 조달 계획
      </div>
      <CapitalAcquisitionPlanComponent
        capitalDataList={props.sbpDocument.businessPlan.capitalAcquisitionPlan}
        disabled={props.sbpDocument.businessPlan.noEventCapitalAcquisition}
        isReadOnly
      />
      <FlexRow
        className="caption-12-md"
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 12,
        }}
      >
        <span style={{ color: "var(--text-04)" }}>총 예상 조달 금액</span>
        <span style={{ color: "var(--text-01)" }}>
          {props.sbpDocument.businessPlan.noEventCapitalAcquisition
            ? "해당없음"
            : `${AddComma(getTotalCapitalAmount())} 원`}
        </span>
      </FlexRow>
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
