import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";
import { GridHeaderProps } from "./StockHolderGrid";

interface Props {
  headers: GridHeaderProps[];
  headerHeight: number;
}

const StockHolderGridHeader: React.FC<Props> = ({ headers, headerHeight }) => {
  const headerRef = useRef<HTMLDivElement>(null);
  return (
    <Container ref={headerRef}>
      {headers.map((item, index) => {
        return (
          <CellConatiner
            key={index}
            className={`body-14-rg ${index === 0 ? "first" : ""} ${
              index === headers.length - 1 ? "last" : ""
            }`}
            style={{
              ...item.style,
              width: item.width || 100,
              minWidth: item.width || 100,
              height: headerHeight,
              minHeight: headerHeight,
              maxHeight: headerHeight,
            }}
          >
            {item.customHeaderCell ? (
              item.customHeaderCell()
            ) : (
              <div>
                {item.header.split("|").map((text, idx) => (
                  <span key={idx}>{text}</span>
                ))}
              </div>
            )}
          </CellConatiner>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0px;
  width: auto;
  display: flex;
  flex-direction: row;
  z-index: 10;
`;

const CellConatiner = styled.div`
  width: 100%;
  cursor: default;
  color: var(--primary);
  background-color: #f6faff;
  border-bottom: 1px solid #e3e4e5;

  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #e3e4e5;

  &.first {
    border-top-left-radius: 4px;
  }
  &.last {
    border-top-right-radius: 4px;
    border-right: 0;
  }
`;

export default StockHolderGridHeader;
