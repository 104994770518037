import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Bounce } from "react-toastify";

import { ReactComponent as PdfIcon } from "../../../../../../../../../assets/image/icon-pdf.svg";

import { FileType } from "../../../../../../../../../type/data";
import {
  onDownload,
  showToast,
} from "../../../../../../../../../common/commonUtil";
import { rootStyle } from "../../../../../../../../../style/ThemeStyles";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  defaultData?: FileType;
  onChangeData?: (data?: FileType) => void;
}

export const CustomFile: React.FC<Props> = ({
  isReadOnly,
  disabled,
  defaultData,
  onChangeData,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<FileType | undefined>();

  const downloadFile = async (s3Key: string, fileName: string) => {
    try {
      onDownload(s3Key, fileName);
      showToast({
        customElement: (
          <span className="heading-16-sb">파일 다운로드 완료 :)</span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--green-400"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,
        height: 48,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setFile(defaultData);
  }, [defaultData]);

  return (
    <Container className={`body-14-rg ${disabled ? "disabled" : ""}`}>
      {!file && (
        <InputContainer
          className={`${disabled || isReadOnly ? "disabled" : ""}`}
          style={{ color: "var(--text-04)" }}
          onClick={(e) => {
            e && e.stopPropagation();
            if (!isReadOnly && inputRef.current) {
              inputRef.current.click();
            }
          }}
        >
          <span>{isReadOnly ? "해당없음" : "파일 업로드"}</span>
        </InputContainer>
      )}
      {file && (
        <InputContainer
          className={`${disabled || isReadOnly ? "disabled" : ""}`}
          style={{ justifyContent: "space-between" }}
        >
          <div
            style={{
              flexGrow: 1,
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PdfIcon
              width={24}
              height={24}
              style={{
                cursor: "pointer",
              }}
            />
            <span
              style={{
                maxWidth: isReadOnly ? undefined : 259,
                overflow: "hidden",
                marginLeft: 4,
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e && e.stopPropagation();
                downloadFile(file.key, file.fileName);
              }}
            >
              {file.fileName}
            </span>
          </div>
        </InputContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 8px 20px;

  display: flex;
  flex-direction: row;

  &.disabled {
    background-color: var(--gray-50);
  }
`;

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray-200);

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  gap: 4px;

  &.disabled {
    padding: 6px 0px;
    cursor: default;
    border-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .arrow {
    path {
      fill: var(--icon-02);
    }
  }

  :hover {
    border: 1px solid var(--blue-200);
  }
  :focus {
    border: 1px solid var(--blue-200);
    outline: none;
  }
`;
