import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DefaultUser } from "../../../../../assets/image/default_user_img.svg";
import { TinyUserInfo } from "../../../../../type/data";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { AddComma, getYMD } from "../../../../../common/commonUtil";
import { InvestemntInfoAdminProps } from "../interface/Portfolio.interface";

interface Props {
  data: InvestemntInfoAdminProps;
  isSelected?: boolean;
  onClickFundAccount?: (target: InvestemntInfoAdminProps) => void;
}
export const FundAccountCard: React.FC<Props> = (props) => {
  const { data, isSelected, onClickFundAccount } = props;
  return (
    <CardContainer
      className={`${isSelected ? "selected" : ""}`}
      onClick={() => {
        onClickFundAccount && onClickFundAccount(data);
      }}
    >
      <FlexRow>
        <span className="heading-16-sb">{data.acFundAccount.nameKo}</span>
      </FlexRow>

      <FlexRow className="caption-14-rg">
        <span style={{ width: 80, color: "var(--text-04)" }}>주식 종류</span>
        <span className="">
          {data.stockType ? data.stockType.name : "정보없음"}
        </span>
      </FlexRow>

      <FlexRow className="caption-14-rg">
        <span style={{ width: 80, color: "var(--text-04)" }}>보유 주식수</span>
        <span className="">{AddComma(data.number)} 주</span>
      </FlexRow>

      <FlexRow className="caption-14-rg">
        <span style={{ width: 80, color: "var(--text-04)" }}>투자 원금</span>
        <span className="">{AddComma(data.principal)} 원</span>
      </FlexRow>
      <FlexRow className="caption-14-rg">
        <span style={{ width: 80, color: "var(--text-04)" }}>지분율</span>
        <span className="">{data.percentage} %</span>
      </FlexRow>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  width: 340px;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;

  &.selected {
    background-color: var(--purple-100);
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
