import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { BoxComponent } from "./BoxComponent";

interface Props {
  title: string;
  titleClassName?: string;
  boxStyle?: React.CSSProperties;
}
export const TitleBox: React.FC<Props> = (props) => {
  const { title, titleClassName = "heading-18-sb", boxStyle, children } = props;
  return (
    <Container>
      <div className={titleClassName}>{title}</div>
      <BoxComponent style={boxStyle}>{children}</BoxComponent>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
