import React from "react";
import styled from "styled-components";

import {
  AddComma,
  RemoveComma,
  addCommasToIntegerPart,
  getYMD,
} from "../../../../../../common/commonUtil";
import { FairValueAdminProps } from "../../interface/Portfolio.interface";

interface Props {
  data: FairValueAdminProps;
  onClick: () => void;
}
export const FairValueHistoryRow: React.FC<Props> = ({ data, onClick }) => {
  return (
    <Container className={`caption-12-rg`} onClick={onClick}>
      <CellConatiner width={100} style={{ justifyContent: "center" }}>
        {data.year}
      </CellConatiner>
      <FlexColumn>
        {data.fairValue.map((item) => {
          return (
            <FlexRow>
              <CellConatiner
                width={782 - 100 - 150 - 32}
                style={{ justifyContent: "flex-start" }}
              >
                {item.acFundAccount.nameKo}
              </CellConatiner>
              <CellConatiner
                width={150}
                style={{
                  justifyContent: "flex-end",
                  borderRight: "0.5px solid transparent",
                }}
              >
                {AddComma(item.amount)}
              </CellConatiner>
            </FlexRow>
          );
        })}
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  background-color: var(--background-contents01, #fff);

  cursor: pointer;
  :hover {
    background-color: var(--hover-100);
  }
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};

  min-height: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-01);
  border-right: 1px solid var(--divider-300, #e3e4e5);
  border-bottom: 1px solid var(--divider-300, #e3e4e5);
  padding: 8px 10px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
