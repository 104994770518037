import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FinanceDataProps } from "../../../evaluation-detail/interface/RMED.interface";
import {
  TableGrid,
  TableGridHeaderProps,
} from "../../../evaluation-detail/desktop/components/table/TableGrid";
import { AddComma } from "../../../../../common/commonUtil";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  financeData: FinanceDataProps;
}

//   year: number;
//   quarterNum: number;
//   revenue: number;
//   operatingProfit: number;
//   netIncome: number;
//   totalAssets: number;
//   totalLiabilities: number;
//   totalEquity: number;

export const FinanceStatement: React.FC<Props> = (props) => {
  const [financeData, updateFinanceData] = useState<
    FinanceDataProps[] | undefined
  >(undefined);
  const headers: TableGridHeaderProps[] = [
    {
      header: "매출액",
      key: "revenue",
      width: 1200 / 6,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <div style={{ padding: "0 20px", textAlign: "end" }}>
            {AddComma(data)}
          </div>
        );
      },
    },
    {
      header: "영업이익",
      key: "operatingProfit",
      width: 1200 / 6,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <div style={{ padding: "0 20px", textAlign: "end" }}>
            {AddComma(data)}
          </div>
        );
      },
    },
    {
      header: "당기순이익",
      key: "netIncome",
      width: 1200 / 6,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <div style={{ padding: "0 20px", textAlign: "end" }}>
            {AddComma(data)}
          </div>
        );
      },
    },
    {
      header: "자산총계",
      key: "totalAssets",
      width: 1200 / 6,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <div style={{ padding: "0 20px", textAlign: "end" }}>
            {AddComma(data)}
          </div>
        );
      },
    },
    {
      header: "부채총계",
      key: "totalLiabilities",
      width: 1200 / 6,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <div style={{ padding: "0 20px", textAlign: "end" }}>
            {AddComma(data)}
          </div>
        );
      },
    },
    {
      header: "자본총계",
      key: "totalEquity",
      width: 1200 / 6,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <div style={{ padding: "0 20px", textAlign: "end" }}>
            {AddComma(data)}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    updateFinanceData([props.financeData]);
  }, []);

  return (
    <Container>
      <TableGrid
        isReadOnly={true}
        disabled={props.disabled}
        header={headers}
        dataList={financeData?.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={() => {}}
        onClickDeleteBtn={() => {}}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;
