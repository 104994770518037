import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

interface Props {
  headerSticky?: boolean;
  currency: string;
}

export const StockChangeHeader: React.FC<Props> = ({
  headerSticky,
  currency,
}) => {
  const height = 48;
  return (
    <Container className="caption-12-rg" style={{}}>
      <CellConatiner
        width={60}
        height={height}
        style={{ justifyContent: "center" }}
      >
        No.
      </CellConatiner>
      <CellConatiner
        width={120}
        height={height}
        style={{ justifyContent: "center" }}
      >
        일자
      </CellConatiner>

      <CellConatiner
        width={120}
        height={height}
        style={{ justifyContent: "center" }}
      >
        변경유형
      </CellConatiner>

      <CellConatiner
        width={250}
        height={height}
        style={{ justifyContent: "center" }}
      >
        주주
      </CellConatiner>

      <CellConatiner
        width={120}
        height={height}
        style={{ justifyContent: "center" }}
      >
        주식종류
      </CellConatiner>
      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "center" }}
      >
        변동 주식수
      </CellConatiner>
      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "center" }}
      >
        주당 거래 금액 ({currency})
      </CellConatiner>
      <CellConatiner
        width={150}
        height={height}
        style={{ justifyContent: "center" }}
      >
        거래 금액 ({currency})
      </CellConatiner>
      <CellConatiner
        width={360}
        height={height}
        style={{ justifyContent: "center" }}
      >
        비고
      </CellConatiner>

      <CellConatiner
        width={200}
        height={height}
        style={{
          justifyContent: "center",
          borderRight: "0.5px solid transparent",
        }}
      >
        메모
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: -32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  :nth-child(1) {
    border-top-left-radius: 4px;
  }
  :nth-last-child(1) {
    border-top-right-radius: 4px;
  }
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height || 32}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: default;
  background-color: #f6faff;
  color: var(--text-04);
  border-right: 0.5px solid var(--divider-300, #e3e4e5);
  border-bottom: 0.5px solid var(--divider-300, #e3e4e5);
  padding: 0 10px;
`;
