import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DefaultButton } from "lattice_core";
import { ReactComponent as Fold } from "../../../assets/image/icon_fold.svg";
import { ReactComponent as Step } from "../../../assets/image/step_chip.svg";

import { TabLayout } from "./components/TabLayout";

import DoNotEditable from "./DoNotEditable";
import SubmitedPage from "./SubmitedPage";
import useExpertOpinion from "./hook/useExpertOpinion";

import { Section2 } from "./components/page/Section2";
import { Section1 } from "./components/page/Section1";
import { Section3 } from "./components/page/Section3";
import { Section4 } from "./components/page/Section4";
import { Section5 } from "./components/page/Section5";

interface Props {
  portfolioId: number;
  investmentAssessmentId: number;
}
export const ExpertOpinion: React.FC<Props> = ({
  portfolioId,
  investmentAssessmentId,
}) => {
  const [
    document,
    businessPlanFile,
    pitchingResult,
    mainParticipant,
    isPublished,
    submitComplete,
    tabList,
    validationCheckBox,
    selectedTabId,
    isFolded,
    tempSaving,
    goToTop,
    onTargetIntoView,
    updateFolded,
    updateSelectedTabId,
    onChangeData,
    tempSavingData,
    submitInvestmentAssessment,
    setModifiableState,
  ] = useExpertOpinion();

  if (isPublished === undefined) return null;
  return isPublished ? (
    <DoNotEditable />
  ) : submitComplete ? (
    <SubmitedPage setModifiableState={setModifiableState} />
  ) : (
    <Container>
      <Header>
        <Logo
          className={"logo-20-hv"}
          style={{ textAlign: "center", color: "var(--primary-blue)" }}
        >
          Lattice
        </Logo>
      </Header>
      <Body id="root__component">
        {document && (
          <ContentsContainer>
            <TabLayout
              isReadOnly={false}
              tabList={tabList}
              selectedTabId={selectedTabId}
              lastUpdate={""}
              onClickTab={(id) => {
                goToTop();
                updateSelectedTabId(id);
              }}
            />
            {selectedTabId === 1 && (
              <Section1
                isReadOnly={true}
                data={document}
                mainParticipant={mainParticipant}
              />
            )}
            {selectedTabId === 2 && (
              <Section2 isReadOnly={true} data={document} />
            )}
            {selectedTabId === 3 && (
              <Section3 isReadOnly={true} data={document} />
            )}
            {selectedTabId === 4 && (
              <Section4
                businessPlanFile={businessPlanFile}
                pitchingResult={pitchingResult}
              />
            )}
            {selectedTabId === 5 && (
              <Section5
                isReadOnly={false}
                data={document}
                onChangeData={onChangeData}
              />
            )}
          </ContentsContainer>
        )}
      </Body>

      <Footer>
        <div className="max__width__footer">
          <DefaultButton
            className="heading-16-md"
            buttonType="colored-outline"
            sizeType="small"
            buttonColor="var(--text-01)"
            borderColor="var(--gray-400)"
            disabled={!tempSaving}
            onClick={() => {
              tempSavingData();
            }}
            text="임시저장"
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            disabled={tabList.filter((item) => !item.isComplete).length > 0}
            buttonColor="var(--primary-blue)"
            hoverColor="var(--blue-600)"
            borderColor="var(--gray-400)"
            text="제출하기"
            onClick={submitInvestmentAssessment}
          />
        </div>
      </Footer>

      <SectionListComponent
        ref={validationCheckBox}
        className={isFolded ? "folded" : ""}
        style={{ opacity: selectedTabId === 5 ? 1 : 0 }}
      >
        <FlexRow
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            display: selectedTabId === 5 ? "flex" : "none",
          }}
        >
          <span className="heading-16-md show__hide">작성 내용</span>
          <Fold
            className={`fold__btn ${isFolded ? "fold__btn__folded" : ""}`}
            onClick={() => {
              updateFolded((prev) => !prev);
            }}
          />
        </FlexRow>
        {selectedTabId === 5 &&
          tabList
            .find((item) => item.id === selectedTabId)
            ?.tabSectionList.map((item) => {
              return (
                <FlexRow
                  className="show__hide"
                  style={{ alignItems: "center", gap: 10, cursor: "pointer" }}
                  onClick={() => {
                    onTargetIntoView(item.selectId);
                  }}
                >
                  <Step
                    className={item.isComplete ? "completed" : ""}
                    style={{
                      minWidth: 22,
                      minHeight: 22,
                    }}
                  />
                  <span
                    className="body-14-rg"
                    style={{ color: "var(--text-03)" }}
                  >
                    {item.sectionTitle}
                  </span>
                </FlexRow>
              );
            })}
      </SectionListComponent>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Header = styled.div`
  width: 100%;
  height: 78px;
  min-height: 78px;
  max-height: 78px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  gap: 16px;

  border-bottom: 1px solid var(--divider-200);

  .title {
    color: var(--text-01);
  }

  .back__btn {
    cursor: pointer;
  }
`;
const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 78px - 66px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  background-color: var(--background-page);

  &.viewer {
    height: calc(100vh - 78px);
  }
`;

const ContentsContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  width: 100%;
  height: 66px;
  min-height: 66px;
  max-height: 66px;

  border-top: 2px solid var(--divider-200);

  .max__width__footer {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
`;

const SectionListComponent = styled.div`
  position: fixed;
  width: 232px;
  max-height: 300px;

  top: 224px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
  align-items: flex-start;
  gap: 10px;

  border-radius: 8px;
  background: var(--background-contents02);
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);

  overflow: hidden;
  object-position: top right;

  transition: all 0.125s ease-in;

  .completed {
    rect {
      fill: var(--blue-400);
    }

    path {
      fill: var(--icon-04);
    }
  }

  .show__hide {
  }

  .fold__btn {
    cursor: pointer;
    transition: all 0.125s ease;
    transform: rotate(180deg);
    &.fold__btn__folded {
      transform: rotate(360deg);
    }
  }

  &.folded {
    width: 60px;
    height: 60px;
    .show__hide {
      display: none;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Logo = styled.div`
  margin-left: 45px;
  cursor: default;
`;
