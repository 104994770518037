import React from "react";
import { useMediaQuery } from "react-responsive";
import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";
import MobileEvaluation from "./mobile/MobileEvaluation";
import DeskEvaluation from "./desktop/DeskEvaluation";

const Evaluation: React.FC<PortfolioNavigationDefaultProps> = (props) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobileEvaluation {...props} />
  ) : (
    <DeskEvaluation {...props} />
  );
};
export default Evaluation;
