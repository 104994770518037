import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  header: string;
  style?: React.CSSProperties;
}
export const Heading1: React.FC<Props> = (props) => {
  return (
    <Container className="heading-24-b" style={props.style}>
      {props.header}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  color: #000;
`;
