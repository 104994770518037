import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

interface Props {
  headerSticky: boolean;
}

export const StockholderHeader: React.FC<Props> = ({ headerSticky }) => {
  const height = 32;
  return (
    <Container
      className={`caption-12-rg`}
      style={{ position: headerSticky ? "sticky" : "static" }}
    >
      <CellConatiner
        width={40}
        height={height}
        style={{ justifyContent: "center" }}
      >
        No
      </CellConatiner>

      <CellConatiner
        width={1200 - 40 - 150 - 100 - 100 - 60 - 120 - 80}
        height={height}
        style={{ justifyContent: "center" }}
      >
        주주명
      </CellConatiner>

      <CellConatiner
        width={150}
        height={height}
        style={{ justifyContent: "center" }}
      >
        (주민등록/사업자) 번호
      </CellConatiner>

      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "center" }}
      >
        주식종류
      </CellConatiner>
      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "center" }}
      >
        소유주식
      </CellConatiner>
      <CellConatiner
        width={60}
        height={height}
        style={{ justifyContent: "center" }}
      >
        액면가
      </CellConatiner>
      <CellConatiner
        width={120}
        height={height}
        style={{ justifyContent: "center" }}
      >
        주식 금액
      </CellConatiner>
      <CellConatiner
        width={80}
        height={height}
        style={{ justifyContent: "center" }}
      >
        지분율
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: #f6faff;

  :nth-child(1) {
    border-top-left-radius: 4px;
  }
  :nth-last-child(1) {
    border-top-right-radius: 4px;
  }
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height || 32}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: var(--text-04);
  border-right: 1px solid var(--divider-300, #e3e4e5);
  border-bottom: 1px solid var(--divider-300, #e3e4e5);
  padding: 0 10px;
`;
