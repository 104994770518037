import axios from "axios";
import { FileType, Term } from "../../../type/data";
import * as api from "../../api";
import { resolve } from "dns";
import { getNewDate } from "../../../common/commonUtil";
import { InvestDictionaryProps } from "../../../router/portfolio-viewer-stock-change-history/interface/type";

export type Directory =
  | "businessPlanFile"
  | "investment"
  | "contract"
  | "tips"
  | "dealflow"
  | "expertReport"
  | "riskManagement";

export const getTimeToServer = async (): Promise<Date | undefined> => {
  try {
    const result = await axios.get(api.getServerTime());
    if (result.status === 200) {
      return new Promise((resolve) => resolve(getNewDate(result.data.date)));
    }
    return new Promise((rejects) => rejects(undefined));
  } catch (error) {}
};

export const deleteFile = async (key: string): Promise<boolean | undefined> => {
  try {
    const deletefile = {
      key: key,
    };
    const result = await axios.delete(api.deleteFile(), {
      data: deletefile,
    });

    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((rejects) => rejects(undefined));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

export const uploadFile = async (
  file: File | any,
  type: Directory,
  portfolioId: number
): Promise<FileType | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    formData.append("portfolioId", `${portfolioId}`);

    const fileResult = await axios.post(api.uploadFile(), formData);
    if (fileResult.status === 200) {
      return new Promise((resolve) => resolve(fileResult.data.keyList[0]));
    }
    return new Promise((rejects) => rejects(undefined));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

export const uploadImgFile = async (
  file: File | any,
  portfolioId: number,
  category: string
): Promise<{ category: string; key: string; url: string } | undefined> => {
  try {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("portfolioId", `${portfolioId}`);
    formData.append("category", category);
    const result = await axios.post(api.uploadAndGetUrl(), formData);

    // .data
    //   .urlKeyList
    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve({
          category: category,
          key: result.data.urlKeyList[0].key,
          url: result.data.urlKeyList[0].url,
        })
      );
    }
    return new Promise((rejects) => rejects(undefined));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

//약관 동의 목록 가져오기
export const getTermsList = async (): Promise<
  | {
      privacy: Term;
      personal: Term;
    }
  | undefined
> => {
  const result = await axios.get(api.getTerms());
  if (result.status === 200) {
    return new Promise((resolve) =>
      resolve({
        privacy: result.data.pfTerms.privacy,
        personal: result.data.pfTerms.personal,
      })
    );
  }
  return new Promise((reject) => reject(undefined));
};

export const getInvestDictionary = async (): Promise<
  InvestDictionaryProps | undefined
> => {
  const result = await axios.get(api.commonDictionaryStock());
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }
  return new Promise((reject) => reject(undefined));
};
