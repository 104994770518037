import React from "react";
import styled from "styled-components";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { BusinessInfoProps } from "../../../info/interface/PortfolioInfo.interface";
import { UploadImage } from "../components/UploadImage";
import { ChangeDataProps } from "../../hook/usePortfolioInfoModify";

interface Props {
  businessInfo: BusinessInfoProps;
  onChangeData: (data: ChangeDataProps) => void;
}
export const BusinessLogo: React.FC<Props> = (props) => {
  const { businessInfo, onChangeData } = props;
  return (
    <Container>
      <RowTitleBox title="로고" titleWidth={128}>
        <UploadImage
          defaultData={businessInfo.companyImgUrl}
          imageWidth={168}
          imageHeight={168}
          maxSizeMB={0.5}
          maxWidthOrHeight={512}
          placeholder="1:1 비율의 이미지 권장"
          onChangeImage={(data) => {
            onChangeData({ type: "companyImgUrl", value: data });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="대표 이미지" titleWidth={128}>
        <UploadImage
          defaultData={businessInfo.thumbnailImgUrl}
          imageWidth={260}
          imageHeight={152}
          maxSizeMB={0.5}
          maxWidthOrHeight={1024}
          placeholder="16:9 비율의 이미지 권장"
          onChangeImage={(data) => {
            onChangeData({ type: "thumbnailImgUrl", value: data });
          }}
        />
      </RowTitleBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 28px;
`;
