import React, { useState } from "react";
import styled from "styled-components";

import CheckedText from "../../../../../../components-2.0v/check-box/CheckedText";

import { FundAccountLight, IndustryTech } from "../../../../../../type/data";
import CheckedChip from "../../../../../excavation/desktop/components/CheckedChip";
import RefreshChip from "../../../../../excavation/desktop/components/RefreshChip";

interface Props {
  fundAccount: FundAccountLight[];
  industry: IndustryTech[];
  selectedFundAccount: FundAccountLight[];
  selectedIndustry: IndustryTech[];
  onChangeFundAccount: (account: FundAccountLight) => void;
  onChangeIndustry: (industry: IndustryTech) => void;
  onClickInitBtn: () => void;
}

const FilterView: React.FC<Props> = (props) => {
  const {
    fundAccount,
    selectedFundAccount,
    onChangeFundAccount,
    industry,
    selectedIndustry,
    onChangeIndustry,
    onClickInitBtn,
  } = props;

  const [isShowFilter, updateShowFilter] = useState<boolean>(false);
  const [openFilter, updateOpenFilter] = useState<
    "industry" | "fundAccount" | undefined
  >(undefined);

  return (
    <Container>
      <FelxRow
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FelxRow style={{ gap: 12, flexWrap: "wrap" }}>
          <CheckedChip
            isSelected={openFilter === "industry"}
            isActive={selectedFundAccount.length > 0}
            contentsString={`산업기술`}
            onClick={() => {
              updateOpenFilter(
                openFilter === "industry" ? undefined : "industry"
              );
            }}
          />
          <CheckedChip
            isSelected={openFilter === "fundAccount"}
            isActive={selectedFundAccount.length > 0}
            contentsString={`투자계정`}
            onClick={() => {
              updateOpenFilter(
                openFilter === "fundAccount" ? undefined : "fundAccount"
              );
            }}
          />
          <RefreshChip
            onClick={() => {
              onClickInitBtn();
            }}
          />
        </FelxRow>
      </FelxRow>
      <FilterContainer
        className={openFilter !== undefined ? "show__filter" : ""}
      >
        <Divider style={{ marginBottom: 0 }} />
        {openFilter === "industry" ? (
          <FilterWrapper>
            {industry.map((item) => {
              return (
                <CheckedText
                  isChecked={
                    selectedIndustry.find(
                      (industryTech) =>
                        industryTech.acIndustrialTechId ===
                        item.acIndustrialTechId
                    ) !== undefined
                  }
                  onClick={() => {
                    onChangeIndustry(item);
                  }}
                >
                  {item.name}
                </CheckedText>
              );
            })}
          </FilterWrapper>
        ) : openFilter === "fundAccount" ? (
          <FilterWrapper>
            {fundAccount.map((item) => {
              return (
                <CheckedText
                  isChecked={
                    selectedFundAccount.find(
                      (account) =>
                        account.acFundAccountId === item.acFundAccountId
                    ) !== undefined
                  }
                  onClick={() => {
                    onChangeFundAccount(item);
                  }}
                >
                  {item.nickName}
                </CheckedText>
              );
            })}
          </FilterWrapper>
        ) : null}

        <Divider style={{ marginTop: 0 }} />
      </FilterContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 21px;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 0px;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-contents01);
  overflow: hidden;

  transition: all 0.2s ease;

  &.show__filter {
    height: fit-content;
    min-height: 92px;
    margin-top: 20px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-300);
`;
const FilterWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
  padding: 0 16px;
`;
const FilterDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-200);
`;

const RightContainer = styled.div`
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const FelxRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FelxColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export default FilterView;
