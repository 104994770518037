import React from "react";
import styled, { css } from "styled-components";
import { QuestionItemType } from "../../../../portfolio/pre-pitching-detail/interface/PrePitchingDetail.interface";
import ViewerText from "../components/ViewerText";
import ViewerChoise from "../components/ViewerChoise";
import { PrePitchingTaskChangeType } from "../../interface/PrePitchingTask.interface";

interface Props {
  no: number;
  item: QuestionItemType;
  dataIndex: number;
  onChangeData: (
    type: PrePitchingTaskChangeType,
    dataIndex: number,
    data: any
  ) => void;
}
const QuestionItem: React.FC<Props> = ({
  no,
  item,
  dataIndex,
  onChangeData,
}) => {
  return (
    <Container>
      <Header className="body-14-rg">
        <span>{no}.</span>
        <div>{item.title}</div>
      </Header>

      {
        item.type === "short" || item.type === "long" ? (
          <ViewerText
            type={item.type}
            item={item}
            dataIndex={dataIndex}
            onChangeData={onChangeData}
          />
        ) : item.type === "single" || item.type === "multiple" ? (
          <ViewerChoise
            item={item}
            dataIndex={dataIndex}
            onChangeData={onChangeData}
          />
        ) : item.type === "linear" ? null : null // <ViewerLinear mode={mode} item={item} onChangeData={onChangeData} />
      }
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--gray-50, #fafafb);
`;
export default QuestionItem;
