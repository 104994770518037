import React, { useState } from "react";
import styled from "styled-components";

import { FundAccountDetailHeader } from "./fundaccount-detail-table/FundAccountDetailHeader";
import { FundAccountDetailRow } from "./fundaccount-detail-table/FundAccountDetailRow";
import { AccountInvestmentInfoProps } from "../../interface/InvestmentInfo.interface";
import {
  AddComma,
  addCommasToIntegerPart,
} from "../../../../../common/commonUtil";

interface Props {
  investmentInfo: AccountInvestmentInfoProps;
}

export const InvestedFundAccountInfo: React.FC<Props> = (props) => {
  const { investmentInfo } = props;
  const [isSpread, updateIsSpread] = useState<boolean>(false);
  const DIVIDE_VALUE = 100000000;

  return (
    <Container>
      <TitleContainer className="heading-16-md">
        {investmentInfo.name}
      </TitleContainer>
      <CurrentStateContainer
        className="body-14-sb"
        style={{
          borderBottom: isSpread ? "1px solid var(--divider-200)" : undefined,
        }}
      >
        <FlexColumn style={{ flex: 1, padding: "16px" }}>
          <FlexRow style={{ alignItems: "center", gap: 16 }}>
            <div className="subject__title">지분평가 가치</div>
            <div className="heading-24-b" style={{ textAlign: "right" }}>
              {addCommasToIntegerPart(
                (investmentInfo.shareValue / DIVIDE_VALUE).toFixed(
                  investmentInfo.shareValue / DIVIDE_VALUE >= 100 ? 0 : 2
                )
              )}
              억
            </div>
          </FlexRow>
          <FlexRow className="caption-12-sb" style={{ gap: 10 }}>
            <FlexRow style={{ gap: 4 }}>
              <span className="subject__title">지분율</span>
              <span>{investmentInfo.percentage}%</span>
            </FlexRow>
            <FlexRow style={{ gap: 4 }}>
              <span className="subject__title">주식수</span>
              <span>{AddComma(investmentInfo.stock)}주</span>
            </FlexRow>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          style={{ flex: 4, borderLeft: "1px solid var(--divider-200)" }}
        >
          <FlexColumn
            style={{
              flex: 1,
              alignItems: "center",
              borderBottom: "1px solid var(--divider-200)",
            }}
          >
            <FlexRow
              className="subject__title"
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                background: "var(--blue-50)",
                borderRight: "1px solid var(--divider-200)",
              }}
            >
              미회수 정보
            </FlexRow>
            <FlexRow
              className="caption-12-sb"
              style={{
                flex: 5,
                alignItems: "center",
                gap: 16,
              }}
            >
              <FlexRow style={{ gap: 4, marginLeft: 16 }}>
                <span className="subject__title">MOIC</span>
                <span>{investmentInfo.remainMOIC?.toFixed(2)}X</span>
              </FlexRow>
              <FlexRow style={{ gap: 4 }}>
                <span className="subject__title">IRR</span>
                <span>
                  {!investmentInfo.remainIRR ||
                  investmentInfo.remainIRR === 0 ||
                  ["-0.0", "0.0"].includes(investmentInfo.remainIRR.toFixed(1))
                    ? "0.0"
                    : investmentInfo.remainIRR.toFixed(1)}
                  %
                </span>
              </FlexRow>
              <FlexRow style={{ gap: 4 }}>
                <span className="subject__title">잔여 원금</span>
                <span>
                  {AddComma(investmentInfo.remainPrincipal.toFixed(0))}원
                </span>
              </FlexRow>
            </FlexRow>
          </FlexColumn>
          <FlexColumn
            style={{
              flex: 1,
              alignItems: "center",
              borderBottom: "1px solid var(--divider-200)",
            }}
          >
            <FlexRow
              className="subject__title"
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                background: "var(--blue-50)",
                borderRight: "1px solid var(--divider-200)",
              }}
            >
              회수 정보
            </FlexRow>
            <FlexRow
              className="caption-12-sb"
              style={{
                flex: 5,
                alignItems: "center",
                gap: 16,
              }}
            >
              <FlexRow style={{ gap: 4, marginLeft: 16 }}>
                <span className="subject__title">MOIC</span>
                <span>{investmentInfo.exitMOIC?.toFixed(2)}X</span>
              </FlexRow>
              <FlexRow style={{ gap: 4 }}>
                <span className="subject__title">IRR</span>
                <span>
                  {!investmentInfo.exitIRR ||
                  investmentInfo.exitIRR === 0 ||
                  ["-0.0", "0.0"].includes(investmentInfo.exitIRR.toFixed(1))
                    ? "0.0"
                    : investmentInfo.exitIRR.toFixed(1)}
                  %
                </span>
              </FlexRow>
              <FlexRow style={{ gap: 4 }}>
                <span className="subject__title">회수 원금</span>
                <span>
                  {addCommasToIntegerPart(
                    investmentInfo.exitPrincipal.toFixed(0)
                  )}
                  원
                </span>
              </FlexRow>
              <FlexRow style={{ gap: 4 }}>
                <span className="subject__title">회수금</span>
                <span>
                  {addCommasToIntegerPart(investmentInfo.exitAmount.toFixed(0))}
                  원
                </span>
              </FlexRow>
            </FlexRow>
          </FlexColumn>
          <FlexColumn
            style={{
              flex: 1,
              alignItems: "center",
            }}
          >
            <FlexRow
              className="subject__title"
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                background: "var(--blue-50)",
                borderRight: "1px solid var(--divider-200)",
              }}
            >
              전체 성과
            </FlexRow>
            <FlexRow
              className="caption-12-sb"
              style={{
                flex: 5,
                alignItems: "center",
                gap: 16,
              }}
            >
              <FlexRow style={{ gap: 4, marginLeft: 16 }}>
                <span className="subject__title">MOIC</span>
                <span>{investmentInfo.investMOIC?.toFixed(2)}X</span>
              </FlexRow>
              <FlexRow style={{ gap: 4 }}>
                <span className="subject__title">IRR</span>
                <span>
                  {!investmentInfo.investIRR ||
                  investmentInfo.investIRR === 0 ||
                  ["-0.0", "0.0"].includes(investmentInfo.investIRR.toFixed(1))
                    ? "0.0"
                    : investmentInfo.investIRR.toFixed(1)}
                  %
                </span>
              </FlexRow>
              <FlexRow style={{ gap: 4 }}>
                <span className="subject__title">투자 원금</span>
                <span>
                  {addCommasToIntegerPart(
                    investmentInfo.investPrincipal.toFixed(0)
                  )}
                  원
                </span>
              </FlexRow>
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </CurrentStateContainer>
      {isSpread && (
        <InvestmentInfoHistoryContainer>
          {investmentInfo.detail.map((item, index) => {
            return <FundAccountDetailRow key={index} data={item} />;
          })}
        </InvestmentInfoHistoryContainer>
      )}
      <FooterContainer
        className="caption-12-md"
        onClick={() => updateIsSpread((prev) => !prev)}
      >
        {isSpread ? "재원 내역 접기" : "재원 내역 펼치기"}
      </FooterContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid var(--divider-200, #edeeef);
  background: var(--light-background-contents-01, #fff);

  .subject__title {
    color: var(--text-04);
  }
  .subject__value {
    margin-left: 12px;
    text-align: right;
    color: var(--text-01);
  }
`;

const TitleContainer = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid var(--light-divider-200, #edeeef);
`;
const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 0px;
  border-top: 1px solid var(--light-divider-200, #edeeef);
  color: var(--text-05);

  cursor: pointer;
`;

const CurrentStateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InvestmentInfoHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
