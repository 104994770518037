import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Writing } from "../../../../../assets/image/icon_writing.svg";

interface Props {
  onClickCard?: () => void;
}
export const FundAccountCreateCard: React.FC<Props> = (props) => {
  const { onClickCard } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Container ref={containerRef} onClick={onClickCard}>
      <Writing width={80} height={80} />
      <div className="heading-20-md">펀드 생성하기</div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-grow: 0;
  flex-basis: 384px;

  padding: 34px 24px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  color: var(--text-04);

  gap: 24px;

  cursor: pointer;

  .sub__title {
    color: var(--text-05, #96999f);
  }

  path {
    fill: var(--text-04);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
