import React from "react";
import styled from "styled-components";

interface Props {
  userInfoHidden?: boolean;
}
export const TextListHeader: React.FC<Props> = ({ userInfoHidden }) => {
  return (
    <Container className="body-14-rg">
      {!userInfoHidden && (
        <UserContainer>
          <Contents>심사역</Contents>
        </UserContainer>
      )}
      <ResultContainer>
        <Contents>의견</Contents>
      </ResultContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 34px;
  min-height: 34px;
  max-height: 34px;

  border-bottom: 1px solid var(--divider-300);
  background-color: var(--blue-50);

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  color: var(--text-05);
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserContainer = styled.div`
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  height: 100%;

  padding-left: 20px;
`;

const ResultContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  padding-left: 20px;
`;
