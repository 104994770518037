import { getMyInfo } from "../../../common/commonUtil";
import { NavigationMenu, RouterAddress } from "../desktop/DeskNavigationLayout";

const AUTHORITY_ID = {
  investmentAnalyst: 1,
  manager: 2,
  normal: 3,
};
type AUTHORITY_ID = (typeof AUTHORITY_ID)[keyof typeof AUTHORITY_ID];

export const NavMenu: NavigationMenu[] = [
  {
    pageAddress: "/excavation",
    addressName: "excavation",
    pageName: "발굴",
    type: "active",
  },
  {
    pageAddress: "/deal-flow",
    addressName: "deal-flow",
    pageName: "딜플로우",
    type: "active",
  },
  {
    pageAddress: "/accelerating",
    addressName: "accelerating",
    pageName: "관리",
    type: "active",
  },

  {
    pageAddress: "/quarter-management",
    addressName: "quarter-management",
    pageName: "분기별 관리",
    type: "active",
    subMenu: [
      {
        pageAddress: "/reporting",
        addressName: "reporting",
        pageName: "분기보고",
        type: "active",
      },
      {
        pageAddress: "/quarter-management/evaluation",
        addressName: "evaluation",
        pageName: "평가하기",
        type: "active",
        validationAuth: () => {
          return [AUTHORITY_ID.investmentAnalyst].includes(
            getMyInfo().authorityId
          );
        },
      },
      {
        pageAddress: "/quarter-management/total-evaluation",
        addressName: "total-evaluation",
        pageName: "종합리뷰",
        type: "active",
      },
    ],
  },

  {
    pageAddress: "/dashboard",
    addressName: "dashboard",
    pageName: "대시보드",
    type: "active",
    subMenu: [
      {
        pageAddress: "/dashboard/fund-account",
        addressName: "fund-account",
        pageName: "재원 대시보드",
        type: "active",
      },
      {
        pageAddress: "/dashboard/performance",
        addressName: "performance",
        pageName: "성과 대시보드",
        type: "active",
      },
    ],
  },
  {
    pageAddress: "/database",
    addressName: "database",
    pageName: "데이터베이스",
    type: "active",
  },
];

export const allRouterList: RouterAddress[] = [
  {
    addressName: "excavation",
    pageName: "발굴",
  },
  {
    addressName: "create",
    pageName: "수기등록",
  },
  {
    addressName: "deal-flow",
    pageName: "딜플로우",
  },
  {
    addressName: "accelerating",
    pageName: "관리",
  },
  {
    addressName: "dashboard",
    pageName: "대시보드",
    isDisabled: true,
  },
  {
    addressName: "database",
    pageName: "데이터베이스",
  },
  {
    addressName: "portfolioReport",
  },
  {
    addressName: "fund-account",
    pageName: "재원 대시보드",
  },
  {
    addressName: "performance",
    pageName: "성과 대시보드",
  },
  {
    addressName: "detail",
    pageName: "상세",
  },

  {
    addressName: "quarter-management",
    pageName: "분기별 관리",
    isDisabled: true,
  },
  {
    addressName: "reporting",
    pageName: "분기보고",
  },
  {
    addressName: "evaluation",
    pageName: "평가하기",
  },
  {
    addressName: "total-evaluation",
    pageName: "종합리뷰",
  },
];
