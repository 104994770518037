import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Document, Page, pdfjs } from "react-pdf";

import { ReactComponent as Pdf } from "../../assets/image/icon_file_pdf.svg";
import usePDFViewer from "./hooks/usePDFViewer";
import useDevice from "../../useDevice";
import ModalLayout from "../../components/modal/ModalLayout";

import { DefaultButton } from "lattice_core";
import { downloadFileFromUrl } from "../../common/commonUtil";
import ProgressModalStatic from "../../components-2.0v/progress/ProgressModalStatic";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewerPage = () => {
  const [
    rootRef,
    pdfUrl,
    pdfPages,
    pageNumber,
    downloadAble,
    refArr,
    sideRefArr,
    setPageNumber,
    setNumPages,
    onScrollHandler,
  ] = usePDFViewer();

  const [loaded, updateLoaded] = useState<boolean>(false);
  const [progress, updateProgress] = useState<number>(0);

  return (
    <Container>
      <Document
        className={`full__page ${loaded ? "visible" : ""}`}
        file={pdfUrl}
        options={{
          cMapUrl: "https://unpkg.com/pdfjs-dist@2.16.105/cmaps/",
          cMapPacked: true,
        }}
        onLoadSuccess={async (res) => {
          setNumPages(res.numPages);
        }}
        onLoadProgress={(data) => {
          const percent = parseInt(
            ((data.loaded / data.total) * 100).toFixed(0)
          );
          updateProgress(percent);
          if (data.loaded === data.total) {
            updateLoaded(true);
          }
        }}
      >
        <RightContainer ref={rootRef} onScroll={onScrollHandler}>
          {pdfPages.map((i, index) => {
            return (
              <div key={i} ref={refArr[index]}>
                <Page
                  pageNumber={i}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={(rootRef.current?.clientWidth || 0) - 40}
                  onLoadSuccess={(page) => {
                    console.log(page);
                  }}
                />
              </div>
            );
          })}
        </RightContainer>
      </Document>
      {!loaded && (
        <ModalLayout isOpen={!loaded} isFullScreen>
          <ProgressModalStatic
            SvgIcon={Pdf}
            progress={progress}
            message={"pdf 파일 로딩 중..."}
            progressFail={false}
            transfromPixel={(n) => n}
          />
        </ModalLayout>
      )}
      {downloadAble && loaded && (
        <Footer>
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor="#173AFF"
            hoverColor="#112CBF"
            text="다운로드"
            disabled={pdfUrl === undefined}
            onClick={async () => {
              if (pdfUrl) downloadFileFromUrl(pdfUrl);
            }}
          />
        </Footer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: white;

  .full__page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    opacity: 0;
  }
  .visible {
    opacity: 1;
  }
`;

const RightContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  gap: 10px;
  padding-top: 24px;
  padding-bottom: 84px;
  align-items: center;
  overflow: scroll;

  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.6px);
  -webkit-backdrop-filter: blur(5.6px);

  @media only screen and (max-width: 765px) {
    padding: 10px 0px;
  }
`;

const Footer = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  padding-right: 24px;
  border-top: 1px solid var(--divider-300);
`;

export default PDFViewerPage;
