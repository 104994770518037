import React, { useEffect, useState } from "react";

const useResize = () => {
  const [screenRate, updateScreenRate] = useState<number>(1);
  const MIN_SCREEN_WIDTH = 1280;
  const MAX_SCREEN_WIDTH = 1536;

  const resizeHandler = () => {
    requestAnimationFrame(() => {
      const screenWidth =
        window.innerWidth < MIN_SCREEN_WIDTH
          ? MIN_SCREEN_WIDTH
          : window.innerWidth > MAX_SCREEN_WIDTH
          ? MAX_SCREEN_WIDTH
          : window.innerWidth;

      updateScreenRate(screenWidth / 1536);
    });
  };

  const transfromPixel = (value: number) => {
    return value * screenRate;
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);
  return [screenRate, transfromPixel] as const;
};

export default useResize;
