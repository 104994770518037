import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { RemoveComma } from "../../../../../../common/commonUtil";
import ModalLayout from "../../../../../../components/modal/ModalLayout";

import { registeRealStockTransaction } from "../../../../../reporting/repository/ReportingRepository";

import {
  RealDataProps,
  StockChangeHistoryRealData,
} from "./interface/Stock.interface";
import {
  makeStockHolderList,
  readStockChangeHistoryExcelFile,
} from "./service/StockHolderService";

import StockChangeHistoryTableModal from "./table/StockChangeHistoryTableModal";

import useDND from "./useDND";
import useFileUpload from "./useFileUpload";
import { DictionaryType } from "../../../../../../type/data";
import ConfirmModal from "../../../../../../2.0lattice/components/modal/ConfirmModal";

interface Props {
  portfolioId: number;
  screenWidthRate: number;
  children: React.ReactNode;
  isJustfileUpload?: boolean;
  disabled?: boolean;
  template?: string;
  popupText?: string[];
  dictionary?: DictionaryType;
  onSuccessStockChangeList: (
    file: File,
    isValidation?: boolean,
    jsonData?: any
  ) => void;
  onDragging?: (isDragging: boolean) => void;
  checkFileType?: (file: File) => boolean;
  transfromPixel: (value: number) => number;
}

const StockChangeUploadBtn: React.FC<Props> = ({
  portfolioId,
  isJustfileUpload,
  disabled,
  screenWidthRate,
  onSuccessStockChangeList,
  children,
  onDragging,
  template,
  transfromPixel,
  popupText,
  dictionary,
  checkFileType = (file) => true,
}) => {
  const [fileRef, onClickFileRef] = useFileUpload();

  const [fileType, updateFileType] = useState<"file" | "dnd" | undefined>(
    undefined
  );
  const [fileData, updateFileData] = useState<File | undefined>(undefined);

  const [realData, updateReealData] = useState<
    StockChangeHistoryRealData[] | undefined
  >(undefined);

  const [modalOpen, updateModalOpen] = useState<boolean>(false);
  const [notTempletModalOpen, updateNotTempletModalOpen] =
    useState<boolean>(false);

  const [checkFilesModal, setCheckFilesModal] = useState<boolean>(false);
  const [errorFileTypeModal, setErrorFileTypeModal] = useState<boolean>(false);

  const dndFileChange = (e: any) => {
    try {
      if (disabled) return;

      console.log(e.dataTransfer.files);

      //파일만 업로드 하는 용도
      if (
        isJustfileUpload &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length > 0
      ) {
        onSuccessStockChangeList(e.dataTransfer.files[0]);
        return;
      }

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        updateFileData(e.dataTransfer.files[0]);
        updateFileType("dnd");
      }

      if (popupText) {
        setCheckFilesModal(true);
        return;
      }

      if (checkFileType(e.dataTransfer.files[0])) {
        readStockChangeHistoryExcelFile(e.dataTransfer.files[0], (data) => {
          if (data.isFileChanged) {
            updateModalOpen(false);
            updateNotTempletModalOpen(true);
          } else {
            checkAndUploadStockChangeList(data.realData).then((res) => {
              if (res && fileData) {
                onSuccessStockChangeList(fileData);
              }
            });
          }
        });
      } else {
        setErrorFileTypeModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [isDragging, dragRef] = useDND(dndFileChange);
  const checkAndUploadStockChangeList = async (
    data: StockChangeHistoryRealData[]
  ) => {
    try {
      const errorData = data.filter(
        (item) =>
          item.data.date === undefined ||
          item.data.date.state === "error" ||
          item.data.stockChangeType === undefined ||
          item.data.stockChangeType.state === "error" ||
          item.data.stageType === undefined ||
          item.data.stageType.state === "error" ||
          item.data.stockholderName === undefined ||
          item.data.stockholderName.state === "error" ||
          item.data.registrationNumber === undefined ||
          item.data.registrationNumber.state === "error" ||
          item.data.relationship === undefined ||
          item.data.relationship.state === "error" ||
          item.data.country === undefined ||
          item.data.country.state === "error" ||
          item.data.stockType === undefined ||
          item.data.stockType.state === "error" ||
          item.data.fluctuateStockNumber === undefined ||
          item.data.fluctuateStockNumber.state === "error" ||
          item.data.faceValue === undefined ||
          item.data.faceValue.state === "error" ||
          item.data.issuingPrice === undefined ||
          item.data.issuingPrice.state === "error" ||
          item.data.totalInvestmentAmount === undefined ||
          item.data.totalInvestmentAmount.state === "error" ||
          item.data.seller === undefined ||
          item.data.seller.state === "error" ||
          item.data.sellerRegistrationNumber === undefined ||
          item.data.sellerRegistrationNumber.state === "error" ||
          item.data.note === undefined ||
          item.data.note.state === "error"
      );

      const transData = data.map((item) => {
        const rowData = item.data;
        const newData: RealDataProps = {
          date: rowData.date.data,
          stockChangeType: rowData.stockChangeType.data,
          stageType: rowData.stageType.data,
          stockholderName: rowData.stockholderName.data,
          registrationNumber: rowData.registrationNumber.data,
          relationship: rowData.relationship.data,
          country: rowData.country.data,
          stockType: rowData.stockType.data,
          fluctuateStockNumber: parseFloat(rowData.fluctuateStockNumber.data),
          faceValue: parseFloat(rowData.faceValue.data),
          issuingPrice: parseFloat(rowData.issuingPrice.data),
          totalInvestmentAmount: parseFloat(rowData.totalInvestmentAmount.data),
          seller: rowData.seller ? rowData.seller.data : undefined,
          sellerRegistrationNumber: rowData.sellerRegistrationNumber
            ? rowData.sellerRegistrationNumber.data
            : undefined,
          note: item.data.note.data,
        };
        return newData;
      });

      console.log(transData);
      console.log("여기옴?");
      const result = makeStockHolderList(transData);

      if (errorData.length > 0 || !result.result) {
        updateReealData([...data]);
        updateModalOpen(true);
        console.log("실패~~");
      } else {
        console.log("성공");
        updateModalOpen(false);
        const postData = {
          portfolioId: portfolioId,
          realStockTransaction: transData.map((item) => {
            return {
              portfolio: portfolioId,
              date: item.date,
              stockChangeType: dictionary?.stockChangeType.find(
                (type) => type.name === item.stockChangeType
              )?.id,
              stageType: item.stageType,
              stockholderName: item.stockholderName,
              registrationNumber: item.registrationNumber,
              relationship: item.relationship,
              country: item.country,
              stockType: dictionary?.stockType.find(
                (type) => type.name === item.stockType
              )?.stockTypeId,
              fluctuateStockNumber:
                RemoveComma(item.fluctuateStockNumber || "").length === 0
                  ? 0
                  : RemoveComma(item.fluctuateStockNumber || ""),
              faceValue:
                RemoveComma(item.faceValue || "").length === 0
                  ? 0
                  : RemoveComma(item.faceValue || ""),
              issuingPrice:
                RemoveComma(item.issuingPrice || "").length === 0
                  ? 0
                  : RemoveComma(item.issuingPrice || ""),
              seller: item.seller ? item.seller.split("|")[0] : undefined,
              sellerRegistrationNumber: item?.sellerRegistrationNumber,
              note: item.note,
            };
          }),
          pfStockHolderList: result.data?.stockHolderList.map((item: any) => {
            return {
              portfolio: portfolioId,
              name: item.stockholderName,
              registrationNumber: item.registrationNumber,
              country: item.country || "",
              stockNumber: item.stocks,
              faceValue: result.data?.faceValue,
              percentage: item.percentage,
              stockType: dictionary?.stockType.find(
                (type) => type.name === item.stockType
              )?.stockTypeId,
              relationship: item.relationship,
            };
          }),
        };
        const registeResult = await registeRealStockTransaction(postData);
        return registeResult;
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!realData) return;

    updateModalOpen(realData.length > 0);
    if (realData.length === 0) {
      alert("파일을 열수 없습니다.");
    }
  }, [realData]);

  useEffect(() => {
    if (disabled) return;

    console.log(isDragging);
    onDragging && onDragging(isDragging);
  }, [isDragging]);

  return (
    <Container ref={dragRef}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (disabled) return;

          updateFileType("file");

          if (popupText) {
            setCheckFilesModal(true);
          } else {
            onClickFileRef();
          }
        }}
      >
        {children}
      </div>
      <input
        type="file"
        ref={fileRef}
        style={{ display: "none" }}
        onChange={(e) => {
          if (disabled) return;
          try {
            //파일만 업로드 하는 용도
            if (
              isJustfileUpload &&
              e.target.files &&
              e.target.files.length > 0
            ) {
              onSuccessStockChangeList(e.target.files[0]);
              return;
            }

            if (e.target.files && e.target.files.length > 0) {
              updateFileType("file");
              updateFileData(e.target.files[0]);

              if (checkFileType(e.target.files[0])) {
                readStockChangeHistoryExcelFile(e.target.files[0], (data) => {
                  if (data.isFileChanged) {
                    updateNotTempletModalOpen(true);
                  } else {
                    checkAndUploadStockChangeList(data.realData).then((res) => {
                      if (res && e.target.files && e.target.files[0]) {
                        onSuccessStockChangeList(e.target.files[0]);
                      }
                    });
                  }
                });
              } else {
                setErrorFileTypeModal(true);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }}
      />
      {!disabled && notTempletModalOpen && (
        <ModalLayout
          isOpen={notTempletModalOpen}
          isFullScreen
          toggle={() => updateNotTempletModalOpen(false)}
          overlayColor={"rgba(31, 32, 34, 0.2)"}
        >
          <ConfirmModal
            type="alert"
            title="제출서류 템플릿 확인"
            contents={["래티스에서 제공한 템플릿이 아닙니다."]}
            positiveTitle="확인"
            onClickPositiveBtn={() => {
              updateNotTempletModalOpen(false);
            }}
            transfromPixel={transfromPixel}
          />
        </ModalLayout>
      )}

      {!disabled && modalOpen && (
        <ModalLayout
          isOpen={modalOpen}
          isFullScreen
          overlayColor={"rgba(31, 32, 34, 0.2)"}
        >
          <StockChangeHistoryTableModal
            transfromPixel={transfromPixel}
            data={realData || []}
            screenRate={screenWidthRate}
            cancel={() => updateModalOpen(false)}
            changeFile={() => {
              onClickFileRef();
            }}
            dndFileChange={(e) => {
              try {
                console.log(e.dataTransfer.files);
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                  updateFileData(e.dataTransfer.files[0]);
                  updateFileType("dnd");
                }

                if (checkFileType(e.dataTransfer.files[0])) {
                  readStockChangeHistoryExcelFile(
                    e.dataTransfer.files[0],
                    (data) => {
                      if (data.isFileChanged) {
                        updateModalOpen(false);
                        updateNotTempletModalOpen(true);
                      } else {
                        checkAndUploadStockChangeList(data.realData).then(
                          (res) => {
                            if (res && fileData) {
                              onSuccessStockChangeList(fileData);
                            }
                          }
                        );
                      }
                    }
                  );
                } else {
                  setErrorFileTypeModal(true);
                }
              } catch (error) {
                console.log(error);
              }
            }}
          />
        </ModalLayout>
      )}
      {!disabled && errorFileTypeModal && (
        <ModalLayout
          isOpen={errorFileTypeModal}
          toggle={() => setErrorFileTypeModal(false)}
          isFullScreen
          overlayColor={"rgba(31, 32, 34, 0.2)"}
        >
          <ConfirmModal
            title="오류"
            contents={["파일 형식을 확인해주세요."]}
            type="alert"
            positiveTitle="확인"
            onClickPositiveBtn={() => setErrorFileTypeModal(false)}
            transfromPixel={transfromPixel}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  height: fit-content;
`;
export default StockChangeUploadBtn;
