import React from "react";
import styled from "styled-components";

import { addCommasToIntegerPart } from "../../../../../common/commonUtil";

import { CurrentInvestmentInfoProps } from "../../interface/InvestmentInfo.interface";
import { BoxComponent } from "../../../../../components-2.0v/box/BoxComponent";

interface Props {
  currentInvestmentInfo: CurrentInvestmentInfoProps;
}

export const CurrentInvestmentInfo: React.FC<Props> = (props) => {
  const DIVIDE_VALUE = 100000000;
  return (
    <BoxComponent style={{ padding: 0 }}>
      <Container className="body-14-md">
        <FlexRow>
          <CardItem className="flex_1">
            <div className="group">최초 투자 기업가치</div>
            <div className="num">
              {addCommasToIntegerPart(
                (
                  props.currentInvestmentInfo.firstCompanyValue / DIVIDE_VALUE
                ).toFixed(0)
              )}
              억
            </div>
          </CardItem>

          <CardItem className="flex_1">
            <div className=" group">기업가치</div>
            <div className=" num">
              {addCommasToIntegerPart(
                (
                  props.currentInvestmentInfo.companyValue / DIVIDE_VALUE
                ).toFixed(0)
              )}
              억
            </div>
          </CardItem>
        </FlexRow>
        <FlexRow>
          <CardItem className="flex_1">
            <div className=" group">총 발행 주식수</div>
            <div className=" num">
              {addCommasToIntegerPart(props.currentInvestmentInfo.totalStock)}주
            </div>
          </CardItem>
          <CardItem className="flex_1">
            <div className=" group">현재 주당 단가</div>
            <div className=" num">
              {addCommasToIntegerPart(
                props.currentInvestmentInfo.issuingPrice.toFixed(0)
              )}
              원
            </div>
          </CardItem>
        </FlexRow>
        <CardItem>
          <div className=" group">누적 후속투자</div>
          <div className=" num">
            {addCommasToIntegerPart(
              (
                props.currentInvestmentInfo.followUpAmount / DIVIDE_VALUE
              ).toFixed(0)
            )}
            억
          </div>
        </CardItem>
      </Container>
    </BoxComponent>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
`;
const CardItem = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  color: var(--light-text-01, #1f2022);

  .group {
    display: flex;
    flex-direction: row;
    color: var(--light-text-04, #7e8086);
  }
  .num {
    width: 100%;
  }
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .flex_1 {
    flex: 1;
  }
`;
