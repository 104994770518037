import React from "react";
import styled from "styled-components";
import { UserInfo } from "../../../../../type/data";
import defaultUserProfile from "../../../../../assets/image/default_user_profile.svg";
import { ReactComponent as Delete } from "../../../../../assets/image/icon_delete_user_card.svg";

interface Props {
  user: UserInfo;
  isEditable: boolean;
  deleteUser?: (user: UserInfo) => void;
}
export const UserItem: React.FC<Props> = (props) => {
  const { user, isEditable, deleteUser } = props;
  return (
    <Container>
      <Avatar>
        <Profile
          src={user.profileImgUrl ? user.profileImgUrl : defaultUserProfile}
        />
      </Avatar>
      <FlexColumn>
        <TextContainer className="heading-14-md">{user.name}</TextContainer>
        <TextContainer className="body-14-rg">
          {user.acStructure?.name}
        </TextContainer>
      </FlexColumn>
      {isEditable && (
        <Delete
          className="delete"
          onClick={() => {
            deleteUser && deleteUser(user);
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 196px;
  height: 72px;
  display: flex;
  flex-direction: row;
  padding: 12px 16px;

  border: 4px;
  gap: 10px;

  border: 1px solid var(--divider-200);

  .delete {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
  }
`;

const Avatar = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  border: 1px solid var(--gray-300);
  border-radius: 50%;
  overflow: hidden;
`;

const Profile = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const FlexColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  width: calc(100%);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;
