import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getYMDAAHMKo } from "../../../../../common/commonUtil";

import { ReactComponent as Mail } from "../../../../../assets/image/icon_mail_small.svg";

import ModalLayout from "../../../../../components/modal/ModalLayout";
import ConfirmModal from "../../../../../2.0lattice/components/modal/ConfirmModal";
import { getPortfolioInfo } from "../../../../../api/repository/portfolio/PortfolioRepository";

interface Props {
  portfolioId?: number;
  exportLinkHistory?: string[];
  sendAuthCodeEmail?: () => void;
}

export const EvaluationExportLink: React.FC<Props> = (props) => {
  const [isExistHistoryModal, setIsExistHistoryModal] =
    useState<boolean>(false);

  const [ceoEmail, updateCeoEmail] = useState<string>("");

  const getCeoEmail = async () => {
    if (!props.portfolioId) return;

    const portfolioInfo = await getPortfolioInfo(props.portfolioId);
    if (portfolioInfo) {
      updateCeoEmail(portfolioInfo.ceoEmail || "");
    }
  };

  useEffect(() => {
    getCeoEmail();
  }, [props.portfolioId]);

  return (
    <>
      <Container
        className="body-14-rg"
        onClick={() => {
          if (ceoEmail.length === 0) return;

          if (props.exportLinkHistory && props.exportLinkHistory.length > 0) {
            setIsExistHistoryModal(true);
          } else {
            props.sendAuthCodeEmail && props.sendAuthCodeEmail();
          }
        }}
      >
        <Mail className="icon" width={16} height={16} />
        {ceoEmail.length > 0 ? (
          <span>해당 기업({ceoEmail})에 작성 요청하기</span>
        ) : (
          <span style={{ color: "var(--text-04)" }}>
            대표자 이메일 정보가 없습니다.
          </span>
        )}
        <span></span>
      </Container>
      {isExistHistoryModal && (
        <ModalLayout
          isOpen={isExistHistoryModal}
          isFullScreen
          toggle={() => {
            setIsExistHistoryModal(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            title="작성 재요청"
            contents={[
              `${
                props.exportLinkHistory && props.exportLinkHistory.length > 0
                  ? getYMDAAHMKo(props.exportLinkHistory[0])
                  : ""
              }`,
              "재요청시 기존의 비밀번호는 사용이 불가능합니다.",
              "정말 재요청하시겠습니까?",
            ]}
            onClickPositiveBtn={() => {
              props.sendAuthCodeEmail && props.sendAuthCodeEmail();
              setIsExistHistoryModal(false);
            }}
            onClickNegativeBtn={() => {
              setIsExistHistoryModal(false);
            }}
          />
        </ModalLayout>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: max-content;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 4px;
  border: 1px solid var(--gray-300);

  padding: 8px 12px;
  gap: 12px;

  cursor: pointer;

  .icon {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
  }
`;

const LinkUrl = styled.div`
  flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
