import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Info } from "../../../../assets/image/icon_info.svg";

interface Props {
  description: string;
  style?: React.CSSProperties;
}
export const InfoDescription: React.FC<Props> = (props) => {
  return (
    <Container className="caption-12-rg" style={props.style}>
      <Info />
      {props.description}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
