import React from "react";
import styled from "styled-components";

import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { BusinessInfoProps } from "../../../info/interface/PortfolioInfo.interface";
import { TextareaBox } from "../../../../../components-2.0v/input-box/TextareaBox";
import { ChangeDataProps } from "../../hook/usePortfolioInfoModify";

interface Props {
  businessInfo: BusinessInfoProps;
  onChangeData: (data: ChangeDataProps) => void;
}
export const BusinessDetail: React.FC<Props> = (props) => {
  const { businessInfo, onChangeData } = props;
  const TEXTAREA_WIDTH = 900;

  if (!businessInfo) return null;

  return (
    <Container>
      <RowTitleBox title="Problem" titleWidth={128}>
        <TextareaBox
          textClassName="body-14-rg"
          placeholder="제품/서비스 완성도 등의 측면에서 작성해 주세요."
          defaultData={businessInfo.problem}
          isShowLength
          maxLength={500}
          width={TEXTAREA_WIDTH}
          onChangeDataRealTime={(value) => {
            onChangeData({ type: "problem", value: value });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="Solution" titleWidth={128}>
        <TextareaBox
          textClassName="body-14-rg"
          placeholder="문제점을 해결한 개발·사업화 전략과 경쟁력 확보 방안에 대해 작성해 주세요."
          defaultData={businessInfo.solution}
          isShowLength
          maxLength={500}
          width={TEXTAREA_WIDTH}
          onChangeDataRealTime={(value) => {
            onChangeData({ type: "solution", value: value });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="BM" titleWidth={128}>
        <TextareaBox
          textClassName="body-14-rg"
          placeholder="가치 전달을 통한 수익화 전략에 대해 작성해 주세요."
          defaultData={businessInfo.businessModel}
          isShowLength
          maxLength={500}
          width={TEXTAREA_WIDTH}
          onChangeDataRealTime={(value) => {
            onChangeData({ type: "businessModel", value: value });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="Team" titleWidth={128}>
        <TextareaBox
          textClassName="body-14-rg"
          placeholder="경영진과 팀원이 보유하고 있는 역량에 대해 작성해 주세요."
          defaultData={businessInfo.team}
          isShowLength
          maxLength={500}
          width={TEXTAREA_WIDTH}
          onChangeDataRealTime={(value) => {
            onChangeData({ type: "team", value: value });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="기타" titleWidth={128}>
        <TextareaBox
          textClassName="body-14-rg"
          placeholder="추가 정보가 있다면 작성해 주세요."
          defaultData={businessInfo.other}
          isShowLength
          maxLength={500}
          width={TEXTAREA_WIDTH}
          onChangeDataRealTime={(value) => {
            onChangeData({ type: "other", value: value });
          }}
        />
      </RowTitleBox>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 28px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TextBox = styled.pre`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  word-break: break-all;
  white-space: break-spaces;
  padding: 9px 0;

  color: var(--text-01, #1f2022);

  &.is__empty {
    color: var(--text-05);
  }
`;
