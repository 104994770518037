import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../../assets/image/modal_close.svg";
import { DefaultButton } from "lattice_core";
import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import {
  IAReportAccount,
  InvestmentAssessment,
} from "../../../../../../type/data";
import { AddComma, getYMDHMKo } from "../../../../../../common/commonUtil";
import { getPortfolioInfo } from "../../../../../../api/repository/portfolio/PortfolioRepository";
import SpinnerModal from "../../../../../../components/modal/SpinnerModal";
import { getKoreanMoneyUnit } from "../../../../../../common/commonUtil";

interface Props {
  portfolioId: number;
  document: InvestmentAssessment;
  close: () => void;
  callbackData: () => void;
  transfromPixel: (value: number) => number;
}
const EvaluationPublishLastCheckModal: React.FC<Props> = (props) => {
  const { portfolioId, document, close, callbackData, transfromPixel } = props;
  const [indicator, updateIndicator] = useState<boolean[]>([]);

  const [curPage, updatePage] = useState<number>(1);
  const [loadingModal, updateLoadingModal] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>("");

  const getIsOriginAccount = (reportAccount: IAReportAccount) => {
    return (
      reportAccount.investmentAssessmentAccount.acFundAccount
        ?.acFundAccountId === 1
    );
  };

  const isAllVisited = () => {
    return indicator.filter((item) => !item).length === 0;
  };

  const getNewStockMinutePate = (
    fundManager: string,
    expertManager: string[],
    normalManager: string[],
    reportAccount: IAReportAccount
  ) => {
    return (
      <PageContainer>
        <div className="heading-20-sb">
          {reportAccount.investmentAssessmentAccount.accountName}
        </div>

        <RowTitleBox
          title="대표펀드매니저"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 20 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {fundManager}
          </div>
        </RowTitleBox>

        {expertManager.length > 0 && (
          <RowTitleBox
            title="전문투자심사위원"
            titleWidth={182}
            minHeight={30}
            style={{ marginTop: 4 }}
            transfromPixel={transfromPixel}
          >
            <div className="body-14-rg" style={{ marginTop: 9 }}>
              {expertManager.join(", ")} ({expertManager.length}명)
            </div>
          </RowTitleBox>
        )}

        {normalManager.length > 0 && (
          <RowTitleBox
            title="일반투자심사위원"
            titleWidth={182}
            minHeight={30}
            style={{ marginTop: 4 }}
            transfromPixel={transfromPixel}
          >
            <div className="body-14-rg" style={{ marginTop: 9 }}>
              {normalManager.join(", ")}({normalManager.length}명)
            </div>
          </RowTitleBox>
        )}

        <RowTitleBox
          title="1.투자재원"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 16 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {reportAccount.investmentAssessmentAccount.accountName}
          </div>
        </RowTitleBox>
        <RowTitleBox
          title="2.업체명"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {companyName}
          </div>
        </RowTitleBox>
        <RowTitleBox
          title="3.개최 일시"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {getYMDHMKo(
              `${reportAccount.assessmentEndDate?.date} ${reportAccount.assessmentEndDate?.time}`
            )}
          </div>
        </RowTitleBox>

        <RowTitleBox
          title="4.주식의 종류"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {reportAccount.investmentAssessmentAccount.stockType?.name}
          </div>
        </RowTitleBox>

        <RowTitleBox
          title="5.총 투자금액"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            금{"  "}
            {getKoreanMoneyUnit(
              reportAccount.investmentAssessmentAccount.totalInvestmentAmount ||
                ""
            )}{" "}
            원 (
            {AddComma(
              reportAccount.investmentAssessmentAccount.totalInvestmentAmount
            )}{" "}
            원)
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`6.${
            getIsOriginAccount(reportAccount) ? "당사" : "당 조합"
          } 투자금액`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            금{"  "}
            {getKoreanMoneyUnit(
              reportAccount.investmentAssessmentAccount.investmentAmount || ""
            )}{" "}
            원 (
            {AddComma(
              reportAccount.investmentAssessmentAccount.investmentAmount
            )}{" "}
            원)
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`7.${reportAccount.investmentAssessmentAccount.newOldStock?.name} 발행가`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {AddComma(reportAccount.investmentAssessmentAccount.issuingPrice)}{" "}
            원 ({AddComma(reportAccount.investmentAssessmentAccount.faceValue)}{" "}
            원)
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`8.총 발행 주식 수`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {AddComma(
              reportAccount.investmentAssessmentAccount.postTotalStockNumber
            )}{" "}
            주
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`9.${
            getIsOriginAccount(reportAccount) ? "당사" : "당 조합"
          } 배정 주식 수`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {AddComma(reportAccount.investmentAssessmentAccount.stockNumber)} 주
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`10.투자 후 지분율`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {reportAccount.investmentAssessmentAccount.shareholdingRatio}% (투자
            후 기업 가치{" "}
            {AddComma(
              reportAccount.investmentAssessmentAccount.postCompanyValue
            )}{" "}
            원)
          </div>
        </RowTitleBox>
      </PageContainer>
    );
  };

  const getOldStockMinutePate = (
    fundManager: string,
    expertManager: string[],
    normalManager: string[],
    totalTrandeAmount: number,
    totalTrandeStockNumber: number,
    reportAccount: IAReportAccount
  ) => {
    return (
      <PageContainer>
        <div className="heading-20-sb">
          {reportAccount.investmentAssessmentAccount.accountName}
        </div>

        <RowTitleBox
          title="대표펀드매니저"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 20 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {fundManager}
          </div>
        </RowTitleBox>

        {expertManager.length > 0 && (
          <RowTitleBox
            title="전문투자심사위원"
            titleWidth={182}
            minHeight={30}
            style={{ marginTop: 4 }}
            transfromPixel={transfromPixel}
          >
            <div className="body-14-rg" style={{ marginTop: 9 }}>
              {expertManager.join(", ")} ({expertManager.length}명)
            </div>
          </RowTitleBox>
        )}

        {normalManager.length > 0 && (
          <RowTitleBox
            title="일반투자심사위원"
            titleWidth={182}
            minHeight={30}
            style={{ marginTop: 4 }}
            transfromPixel={transfromPixel}
          >
            <div className="body-14-rg" style={{ marginTop: 9 }}>
              {normalManager.join(", ")}({normalManager.length}명)
            </div>
          </RowTitleBox>
        )}

        <RowTitleBox
          title="1.투자재원"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 16 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {reportAccount.investmentAssessmentAccount.accountName}
          </div>
        </RowTitleBox>
        <RowTitleBox
          title="2.업체명"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {companyName}
          </div>
        </RowTitleBox>
        <RowTitleBox
          title="3.매입처"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {reportAccount.investmentAssessmentAccount.buyCompany}
          </div>
        </RowTitleBox>
        <RowTitleBox
          title="4.개최 일시"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {getYMDHMKo(
              `${reportAccount.assessmentEndDate?.date} ${reportAccount.assessmentEndDate?.time}`
            )}
          </div>
        </RowTitleBox>

        <RowTitleBox
          title="5.주식의 종류"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {reportAccount.investmentAssessmentAccount.stockType?.name}
          </div>
        </RowTitleBox>

        <RowTitleBox
          title="6.총 거래 금액"
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            금{"  "}
            {getKoreanMoneyUnit(totalTrandeAmount || "")} 원 (
            {AddComma(totalTrandeAmount)} 원)
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`7.${
            getIsOriginAccount(reportAccount) ? "당사" : "당 조합"
          } 거래 금액`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            금{"  "}
            {getKoreanMoneyUnit(
              reportAccount.investmentAssessmentAccount.investmentAmount || ""
            )}{" "}
            원 (
            {AddComma(
              reportAccount.investmentAssessmentAccount.investmentAmount
            )}{" "}
            원)
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`8.${reportAccount.investmentAssessmentAccount.newOldStock?.name} 발행가`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {AddComma(reportAccount.investmentAssessmentAccount.issuingPrice)}{" "}
            원
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`9.최근 신주 발행가`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {AddComma(
              reportAccount.investmentAssessmentAccount.curNewIssuingPrice
            )}{" "}
            원 (액면가 :{" "}
            {AddComma(reportAccount.investmentAssessmentAccount.faceValue)} 원)
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`10.할인율`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {reportAccount.investmentAssessmentAccount.discount}%
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`11.총 배정 주식 수`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {AddComma(totalTrandeStockNumber)} 주
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`12.${
            getIsOriginAccount(reportAccount) ? "당사" : "당 조합"
          } 배정 주식 수`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {AddComma(reportAccount.investmentAssessmentAccount.stockNumber)} 주
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`13.투자 후 지분율`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {reportAccount.investmentAssessmentAccount.shareholdingRatio}%
          </div>
        </RowTitleBox>

        <RowTitleBox
          title={`14.구주 매입가 기준 기업 가치`}
          titleWidth={182}
          minHeight={30}
          style={{ marginTop: 4 }}
          transfromPixel={transfromPixel}
        >
          <div className="body-14-rg" style={{ marginTop: 9 }}>
            {AddComma(
              reportAccount.investmentAssessmentAccount.buyCompanyValue
            )}{" "}
            원
          </div>
        </RowTitleBox>
      </PageContainer>
    );
  };

  useEffect(() => {
    if (indicator.length !== 0) {
      updateIndicator(
        indicator.map((item, index) => {
          if (index + 1 === curPage) return true;
          return item;
        })
      );
    }
  }, [curPage]);

  useEffect(() => {
    getPortfolioInfo(portfolioId).then((result) => {
      if (result) {
        setCompanyName(result.companyName || "");
      }
    });

    const indicatorData: boolean[] = [];
    for (
      let i = 0;
      i < document.investmentAssessmentReportAccount.length;
      i++
    ) {
      indicatorData.push(i === 0);
    }
    updateIndicator(indicatorData);
  }, []);

  return (
    <Container>
      <Header>
        <div className="heading-16-sb" style={{ marginTop: 10 }}>
          투자심사 개최
        </div>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body>
        <PageWrapper
          style={{
            transform: `translateX(-${(curPage - 1) * 100}%)`,
          }}
        >
          {document.investmentAssessmentReportAccount.map((item) => {
            const fundManager = item.investmentAssessmentInvite.find(
              (user) =>
                user.priorityInfo?.priorityType === 1 || user.isFundManager
            );
            const expertManager = item.investmentAssessmentInvite
              .filter((user) => user.priorityInfo?.priorityType === 2)
              .map((user) => user.acUser.name);
            const normalManager = item.investmentAssessmentInvite
              .filter((user) => user.priorityInfo?.priorityType === 3)
              .map((user) => user.acUser.name);

            if (
              item.investmentAssessmentAccount.newOldStock?.newOldStockId === 1
            ) {
              return getNewStockMinutePate(
                fundManager?.acUser.name || "",
                expertManager,
                normalManager,
                item
              );
            }

            if (
              item.investmentAssessmentAccount.newOldStock?.newOldStockId === 2
            ) {
              const oldReportAccount =
                document.investmentAssessmentReportAccount.filter(
                  (item) =>
                    item.investmentAssessmentAccount.newOldStock
                      ?.newOldStockId === 2
                );
              const totalTradeAmount = oldReportAccount
                .map(
                  (item) =>
                    item.investmentAssessmentAccount.investmentAmount || 0
                )
                .reduce((prev, cur) => prev + cur, 0);
              const totalTradeStock = oldReportAccount
                .map(
                  (item) => item.investmentAssessmentAccount.stockNumber || 0
                )
                .reduce((prev, cur) => prev + cur, 0);

              return getOldStockMinutePate(
                fundManager?.acUser.name || "",
                expertManager,
                normalManager,
                totalTradeAmount,
                totalTradeStock,
                item
              );
            }

            return null;
          })}
        </PageWrapper>
        <IndicatorContainer>
          {indicator.length > 1 &&
            indicator.map((visited, index) => {
              return (
                <Dot
                  className={`${
                    !visited
                      ? "disabled"
                      : index + 1 === curPage
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => {
                    if (visited) {
                      updatePage(index + 1);
                    }
                  }}
                />
              );
            })}
        </IndicatorContainer>
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={() => {
              close();
            }}
            transfromPixel={transfromPixel}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            text={isAllVisited() ? "개최하기" : "다음으로"}
            onClick={() => {
              if (!isAllVisited() && curPage < indicator.length) {
                updatePage(curPage + 1);
              }

              if (isAllVisited()) {
                callbackData();
                close();
              }
            }}
            transfromPixel={props.transfromPixel}
          />
        </ButtonContainer>
      </Footer>
      {loadingModal && <SpinnerModal open={loadingModal} />}
    </Container>
  );
};

const Container = styled.div`
  width: 80vw;
  max-width: 640px;
  height: 70vh;
  min-height: 804px;
  max-height: 804px;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease;
`;

const PageContainer = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 32px 0 32px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  min-height: 72px;
  max-height: 72px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 24px 0px 0px;
  gap: 12px;
`;

const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const IndicatorContainer = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding-bottom: 4px;
  gap: 8px;
`;
const Dot = styled.div`
  width: 12px;
  min-width: 12px;
  max-width: 12px;
  height: 12px;
  min-height: 12px;
  max-height: 12px;

  border-radius: 50%;
  background-color: var(--gray-200);
  cursor: pointer;

  &.disabled {
    background-color: var(--white);
    border: 1px solid var(--gray-400);
    cursor: default;
  }

  &.selected {
    background-color: var(--gray-500);
  }
`;
export default EvaluationPublishLastCheckModal;
