import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Calendar } from "../../../../assets/image/icon_calendar.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale"; //한국어 설정
import { getNewDate, getYMD } from "../../../../common/commonUtil";
import { DateTime } from "../../../../type/data";

interface Props {
  date?: {
    startDate?: DateTime;
    endDate?: DateTime;
  };
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  onChangeDate: (date: { startDate: DateTime; endDate: DateTime }) => void;
}
export const PickerDateRange: React.FC<Props> = (props) => {
  const { date, disabled = false, minDate, maxDate, onChangeDate } = props;

  const selectorRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const [startDate, updateStartDate] = useState<Date | null>(null);
  const [endDate, updateEndDate] = useState<Date | null>(null);

  const [isOpen, updateIsOpen] = useState<boolean>(false);

  const convertDateToDateTime = (value: Date | null): DateTime | undefined => {
    return value
      ? {
          date: getYMD(value),
        }
      : undefined;
  };

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const sDate = convertDateToDateTime(start);
    const eDate = convertDateToDateTime(end);
    updateStartDate(start);
    updateEndDate(end);
    if (start && end) {
      console.log("faisdfjdasldkfj");
      updateIsOpen(false);
    }
    if (sDate && eDate) {
      onChangeDate({ startDate: sDate, endDate: eDate });
    }
  };

  const handleMouseDown = (event: any) => {
    if (optionRef.current && !optionRef.current.contains(event.target)) {
      console.log("다른곳 클릭");
      updateIsOpen(false);
    }
  };

  const rectPositoin = () => {
    const root = window.document.querySelector("#body__component");
    if (selectorRef.current && root) {
      const targetRect = selectorRef.current.getBoundingClientRect();
      if (optionRef.current) {
        optionRef.current.style.width = `${targetRect.width}px`;
        optionRef.current.style.top = `${
          targetRect.top + targetRect.height + 4
        }px`;
        optionRef.current.style.left = `${targetRect.left}px`;
      }
    }
  };
  const handleScroll = (e: any) => {
    updateIsOpen(false);
    console.log(e);
  };

  useEffect(() => {
    const root = window.document.querySelector("#body__component");

    window.addEventListener("mousedown", handleMouseDown);
    root && root.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      root && root.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      rectPositoin();
    }
  }, [isOpen]);

  useEffect(() => {
    if (date) {
      const sDate = date.startDate
        ? getNewDate(`${date.startDate.date}`)
        : null;
      const eDate = date.endDate ? getNewDate(`${date.endDate.date}`) : null;

      updateStartDate(sDate);
      updateEndDate(eDate);
    }
  }, [date]);

  return (
    <Container
      className={`body-14-rg ${startDate || endDate ? "actived" : ""}`}
      ref={selectorRef}
    >
      <InputContainer
        className="caption-12-md"
        onClick={() => updateIsOpen((prev) => !prev)}
      >
        {!startDate && !endDate ? (
          <div>기간 선택</div>
        ) : (
          <div>
            {startDate ? getYMD(startDate).slice(5).replaceAll("-", ".") : "?"}{" "}
            - {endDate ? getYMD(endDate).slice(5).replaceAll("-", ".") : "?"}
          </div>
        )}
        <Calendar />
      </InputContainer>

      {isOpen && (
        <div ref={optionRef} style={{ position: "fixed", zIndex: 2 }}>
          <DatePicker
            selected={startDate}
            onChange={(date: [Date | null, Date | null]) => {
              onChange(date);
            }}
            locale={ko}
            minDate={minDate}
            maxDate={maxDate}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            showDisabledMonthNavigation
            inline
          />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: var(--white);

  border: 1px solid var(--gray-300);
  border-radius: 20px;
  padding: 0 10px;

  overflow: hidden;
  cursor: pointer;

  transition: all 0.2s ease;
  color: var(--text-04);

  &.actived {
    background: var(--blue-50, #f6faff);
    border: 1px solid var(--blue-400, #4584ff);
    color: var(--blue-400, #4584ff);
    path {
      fill: var(--blue-400);
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
    font-family: "SUIT Regular";
    font-size: 12px;
    letter-spacing: -1%;
  }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
    font-family: "SUIT Regular";
    font-size: 14px;
    letter-spacing: -1%;
  }

  .react-datepicker-time__header {
    display: none;
  }

  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-family: "SUIT SemiBold";
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -1%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: var(--blue-500);
    color: #fff;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--blue-500);
    color: white;
    font-weight: bold;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--dim-100);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  height: 34px;

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 12px;
`;
