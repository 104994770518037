import React, { useEffect, useRef, useState } from "react";
import {
  ChannelAdminProps,
  ChannelProps,
} from "../interface/Channel.interface";
import {
  getAcChannel,
  upsertAcChannel,
} from "../../../../../api/repository/admin/AcceleratorRepository";
import { DropResult } from "react-beautiful-dnd";
import { Bounce, toast } from "react-toastify";
import { showToast } from "../../../../../common/commonUtil";
import { rootStyle } from "../../../../../style/ThemeStyles";

const useChannel = () => {
  const selectedChannel = useRef<ChannelAdminProps | undefined>(undefined);
  const originChannel = useRef<ChannelProps | undefined>(undefined);
  const [channel, setChannel] = useState<ChannelProps | undefined>(undefined);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [channelModal, setChannelModal] = useState<boolean>(false);

  const closeModal = () => {
    setChannelModal(false);
    selectedChannel.current = undefined;
  };
  const onClickItem = (droppableId: string, acChannelId: string) => {
    if (!channel) return;

    if (droppableId === "active") {
      selectedChannel.current = channel.activeChannel.find(
        (item) => `${item.acChannelId}` === `${acChannelId}`
      );
    } else {
      selectedChannel.current = channel.deactiveChannel.find(
        (item) => `${item.acChannelId}` === `${acChannelId}`
      );
    }
    setChannelModal(true);
  };
  const onClickNewChannel = () => {
    setChannelModal(true);
  };

  const addChannel = (newData: ChannelAdminProps) => {
    if (!channel) return;

    const newChannel = structuredClone(channel);
    newChannel.activeChannel = [newData, ...newChannel.activeChannel];
    setChannel({ ...newChannel });
    closeModal();
  };

  const modifyChannel = (newData: ChannelAdminProps) => {
    if (!channel) return;

    const activeTarget = channel.activeChannel.findIndex(
      (item) => `${item.acChannelId}` === `${newData.acChannelId}`
    );
    const deactiveTarget = channel.deactiveChannel.findIndex(
      (item) => `${item.acChannelId}` === `${newData.acChannelId}`
    );

    if (activeTarget !== -1) {
      const newChannel = structuredClone(channel);
      newChannel.activeChannel[activeTarget] = newData;
      setChannel({ ...newChannel });
      closeModal();
      return;
    }

    if (deactiveTarget !== -1) {
      const newChannel = structuredClone(channel);
      newChannel.deactiveChannel[deactiveTarget] = newData;
      setChannel({ ...newChannel });
      closeModal();
      return;
    }
  };
  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination || !channel) {
      return;
    }

    console.log(source, destination);
    const sourceKey = source.droppableId;
    const destinationKey = destination.droppableId;

    const newChannel: ChannelProps = {
      activeChannel: channel.activeChannel.map((item) => ({
        ...item,
      })),
      deactiveChannel: channel.deactiveChannel.map((item) => ({
        ...item,
      })),
    };

    console.log(newChannel);
    const [targetItem] =
      sourceKey === "active"
        ? newChannel.activeChannel.splice(source.index, 1)
        : newChannel.deactiveChannel.splice(source.index, 1);
    destinationKey === "active"
      ? newChannel.activeChannel.splice(destination.index, 0, targetItem)
      : newChannel.deactiveChannel.splice(destination.index, 0, targetItem);

    console.log(newChannel);

    setChannel({ ...newChannel });
  };

  const getAllChannel = async () => {
    const channelResult = await getAcChannel();
    if (channelResult) {
      setChannel({ ...structuredClone(channelResult) });
      originChannel.current = structuredClone(channelResult);
    } else {
      setChannel({ activeChannel: [], deactiveChannel: [] });
    }
  };

  const isChangeData = () => {
    if (!channel) return setIsChanged(false);

    const compaireA = JSON.stringify(channel);
    const compaireB = JSON.stringify(originChannel.current);

    setIsChanged(compaireA !== compaireB);
  };

  const updateChannel = async () => {
    const activeData = channel?.activeChannel.map((item, index) => {
      return {
        ...item,
        order: index + 1,
        isActive: true,
        isChange: !item.isNew,
        acChannelId: item.isNew ? undefined : item.acChannelId,
      };
    });
    const deactiveData = channel?.deactiveChannel.map((item, index) => {
      return {
        ...item,
        order: index + 1,
        isActive: false,
        isChange: !item.isNew,
        acChannelId: item.isNew ? undefined : item.acChannelId,
      };
    });
    const result = await upsertAcChannel([
      ...(activeData || []),
      ...(deactiveData || []),
    ]);
    if (result && result.success) {
      showToast({
        customElement: (
          <span className="heading-16-sb">
            현재 정보를 저장 완료하였습니다.
          </span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--green-400"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,

        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      getAllChannel();
    }
  };

  useEffect(() => {
    isChangeData();
  }, [channel]);

  useEffect(() => {
    getAllChannel();
  }, []);

  // --- requestAnimationFrame 초기화
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  return [
    enabled,
    channel,
    selectedChannel.current,
    channelModal,
    isChanged,
    onDragEnd,
    onClickItem,
    closeModal,
    addChannel,
    modifyChannel,
    onClickNewChannel,
    updateChannel,
  ] as const;
};

export default useChannel;
