import React, { useEffect, useRef, useState } from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { BlueCardInfo } from "../../../../../type/data";
import BluecardPrint from "../../../../database/desktop/component/BluecardPrint";
import { BluecardFilterProps } from "../../../../database/desktop/organisms/PreviewBlueCard";

interface Props {
  portfolioBluecard: BlueCardInfo;
  bluecardFilter: BluecardFilterProps;
  closeBlueCardPDFView?: () => void;
  onStartDownload?: () => void;
  onFinishDownload?: () => void;
}

const BlueCardPDFViewNew = (props: Props) => {
  const {
    portfolioBluecard,
    closeBlueCardPDFView,
    onStartDownload,
    onFinishDownload,
    bluecardFilter,
  } = props;

  const rootRef = useRef<HTMLDivElement>(null);

  const capturePdf = async () => {
    try {
      if (!rootRef.current) return;
      onStartDownload && onStartDownload();

      const canvas = await html2canvas(rootRef.current, {
        scale: 3,
        allowTaint: true,
        useCORS: true,
        logging: true,
      });

      const imgData = canvas.toDataURL("image/png", 1.0);
      var imgWidth = 210;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;
      doc.addImage({
        imageData: imgData,
        format: "PNG",
        x: 0,
        y: position,
        width: imgWidth,
        height: imgHeight,
        compression: "FAST",
      });

      doc.save(`블루카드_${portfolioBluecard.companyName ?? "unnamed"}.pdf`);
      onFinishDownload && onFinishDownload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      ref={rootRef}
      style={{ height: "max-content", scrollSnapAlign: "start" }}
    >
      <BluecardPrint
        portfolioBluecard={portfolioBluecard}
        onImageLoaded={() => {
          capturePdf();
          closeBlueCardPDFView && closeBlueCardPDFView();
        }}
        bluecardFilter={bluecardFilter}
      />
    </div>
  );
};

export default BlueCardPDFViewNew;
