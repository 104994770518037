import React from "react";
import styled from "styled-components";

import { SBPContentsPropsV2 } from "../../../interface/SBP.interface";
import { GovermentTaskComponent } from "./table/GovermentTaskComponent";
import { AddComma } from "../../../../../../../../../common/commonUtil";

interface Props {
  index: number;
  sbpDocument: SBPContentsPropsV2;
}

export const Status2: React.FC<Props> = (props) => {
  const getTotalGovFunding = () => {
    return props.sbpDocument.businessStatus.govTasks
      .map((item) => item.govFunding || 0)
      .reduce((prev, cur) => prev + cur, 0);
  };
  return (
    <FlexColumn style={{ gap: 8 }}>
      <div className="body-16-md" style={{ color: "var(--text-03)" }}>
        0{props.index}. 정부 지원 과제 참여
      </div>
      <GovermentTaskComponent
        govDataList={props.sbpDocument.businessStatus.govTasks}
        disabled={props.sbpDocument.businessStatus.noEventGovTask}
        isReadOnly
      />
      <FlexRow
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 12,
        }}
      >
        <span className="caption-12-md" style={{ color: "var(--text-04)" }}>
          총 정부 출연금
        </span>
        <span className="caption-12-md" style={{ color: "var(--text-01)" }}>
          {props.sbpDocument.businessStatus.noEventGovTask
            ? "해당없음"
            : `${AddComma(getTotalGovFunding())} 원`}
        </span>
      </FlexRow>
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
