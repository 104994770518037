import React from "react";
import {
  DragDropContext,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import styled from "styled-components";
import { ChannelAdminProps } from "../interface/Channel.interface";
import { ChannelItem } from "./ChannelItem";

interface Props {
  items: ChannelAdminProps[];
  droppableId: string;
  isActive: boolean;
  onClickItem: (droppableId: string, acChannelId: string) => void;
}

export const ChannelColumn: React.FC<Props> = (props) => {
  return (
    <Droppable droppableId={props.droppableId}>
      {(provided: DroppableProvided) => (
        <Container ref={provided.innerRef} {...provided.droppableProps}>
          {props.items.map((item, index) => (
            <ChannelItem
              item={item}
              index={index}
              isActive={props.isActive}
              key={item.acChannelId}
              onClickItem={(acChannelId) => {
                props.onClickItem(props.droppableId, acChannelId);
              }}
            />
          ))}
          {provided.placeholder}
        </Container>
      )}
    </Droppable>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
`;
