import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { trnasfromBase64ToObject } from "../../../../common/commonUtil";
import { ReferenceDocumentInfo } from "../interface/ReferenceDocument.interface";
import {
  getReportDocumentByInfo,
  getSBPDataByInfo,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import SummaryBusinessPlanViewer from "../../../../2.0lattice/router/reporting/reporting-detail/summary-business-plan/SummaryBusinessPlanViewer";
import { ReportDocumentProps } from "../../../../type/data";
import { ReactComponent as Loading } from "../../../../assets/image/loading.svg";
import styled from "styled-components";

const useReferenceDocument = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [refDocumentInfo, setRefDocumentInfo] = useState<
    ReferenceDocumentInfo | undefined
  >(undefined);
  const [sbpData, setSbpData] = useState<any | undefined>(undefined);
  const [reportingDocument, setReportingDocument] = useState<
    ReportDocumentProps | undefined
  >(undefined);
  const [documentLoaded, updateDocumentLoaded] = useState<boolean>(false);

  const initSBPData = async (
    portfolioId: number,
    year: number,
    quarter: number
  ) => {
    const dataResult: any = await getSBPDataByInfo(portfolioId, year, quarter);

    console.log(dataResult);
    setSbpData(dataResult);
    setIsLoaded(true);
  };

  const initReportingDocument = async (
    portfolioId: number,
    year: number,
    quarter: number,
    acPfDocumentId: number
  ) => {
    const dataResult: any = await getReportDocumentByInfo(
      portfolioId,
      year,
      quarter,
      acPfDocumentId
    );

    console.log(dataResult);
    setReportingDocument(dataResult);
    setIsLoaded(true);
  };

  const getTitle = () => {
    if (refDocumentInfo === undefined) return "";

    if (refDocumentInfo.type === "sbp") return "요약사업계획서";

    if (reportingDocument === undefined) return "";

    return reportingDocument.documentFile.fileName;
  };

  const getBody = () => {
    if (!refDocumentInfo) return null;
    switch (refDocumentInfo.type) {
      case "sbp":
        return <SummaryBusinessPlanViewer sbpData={sbpData} />;
      case "google_pdf":
        console.log(reportingDocument);
        return reportingDocument ? (
          <PDFWrapper>
            <iframe
              className="viewer__iframe"
              style={{ width: "100%", height: "100%" }}
              src={`https://drive.google.com/file/d/${reportingDocument?.documentFile.key}/preview`}
              onLoad={() => {
                updateDocumentLoaded(true);
              }}
            />
            {!documentLoaded && (
              <div className="viewer__iframe__load__container">
                <Loading className="rotating" width={30} height={30} />
              </div>
            )}
          </PDFWrapper>
        ) : null;
    }
  };

  useEffect(() => {
    if (!refDocumentInfo) return;

    if (refDocumentInfo.type === "sbp") {
      initSBPData(
        refDocumentInfo.portfolioId,
        refDocumentInfo.year,
        refDocumentInfo.quarter
      );
    }

    if (
      refDocumentInfo.type === "google_pdf" &&
      refDocumentInfo.acPfDocumentId !== undefined
    ) {
      initReportingDocument(
        refDocumentInfo.portfolioId,
        refDocumentInfo.year,
        refDocumentInfo.quarter,
        refDocumentInfo.acPfDocumentId
      );
    }
  }, [refDocumentInfo]);

  useEffect(() => {
    const obj = location.pathname.split("/")[2];

    if (obj) {
      setRefDocumentInfo(trnasfromBase64ToObject(obj));
    }
  }, []);

  return [isLoaded, refDocumentInfo, getBody, getTitle] as const;
};

const PDFWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .viewer__iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  .viewer__iframe__load__container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Safari and Chrome */
  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }
`;

export default useReferenceDocument;
