import {
  RelationshipType,
  StageType,
  StockChangeType,
  StockType,
} from "../../../../type/data";

export const StakeHolderType = {
  INDIVIDUAL: 1,
  CORPORATION: 2,
  FUND: 3,
};

export interface StockHolder {
  country: number;
  faceValue: number;
  id: number;
  name: string;
  percentage: string;
  pfStakeholder: StakeHolderProps;
  registrationNumber: string;
  relationship: { relationshipId: number; name: string };
  stockNumber: number;
  stockType: { stockTypeId: number; name: string };
}

export interface StakeHolderProps {
  id: number;
  name: string;
  email?: string;
  phone?: string;
  managerName?: string;
  managerEmail?: string;
  managerPhone?: string;
  relationship: RelationshipProps;
  stakeHolder: StakeHolderDetailProps;

  isInUse: boolean;
  isStockholder: boolean;
}

export interface RelationshipProps {
  id: number;
  name: string;
  createdAt?: string;
}

export interface StakeHolderDetailProps {
  id: number;
  type: number; // 1: 개인, 2: 법인, 3: 펀드
  uniqueNumber: string;
  name: string;
  country?: CountryProps;
  gp?: GPProps;
}
export interface GPProps {
  id: number;
  type: number;
  uniqueNumber: string;
  name: string;
  country?: CountryProps;
}

export interface CountryProps {
  id: number;
  name: string;
}

export interface StockHolderGrouping {
  id?: number;
  name: string;
  stakeType: number;
  countryId?: number;
  registrationNumber: string;
  totalStockNumber: number;
  totalPercentage: number;
  totalAmount: number;
  stockData: StockHolder[];
}

//투자정보
export interface InvestmentInfoProps {
  id?: number;
  acFundAccount?: {
    acFundAccountId: number;
    nameKo: string;
    serialNumber: string;
  };
  purchasePrincipal?: number;
  purchaseStockNumber?: number;
  sellPrincipal?: number;
  sellStockNumber?: number;
  ownershipPercentage?: number;
  realizedMultiple?: number;
  unrealizedMultiple?: number;
  companyValue?: number;
}

//리얼 데이터 타입
export interface RealDataProps {
  realStockTransactionId: number;
  date?: string;
  stockChangeType?: StockChangeType | string;
  stageType?: StageType | string;
  stockholderName?: string;
  registrationNumber?: string;
  relationship?: RelationshipType | string;
  country?: string;
  stockType?: StockType | string;
  fluctuateStockNumber?: number | string;
  faceValue?: number | string;
  issuingPrice?: number | string;
  seller?: string;
  sellerRegistrationNumber?: string;
  note?: string;
  updatedAt?: string;
}

// 현재 정보
export interface CurrentInvestmentInfoProps {
  firstCompanyValue: number;
  companyValue: number;
  followUpAmount: number;
  totalStock: number;
  issuingPrice: number;
}

// 투자재원 정보
export interface AccountInvestmentInfoProps {
  acFundAccountId: number;
  name: string;
  stockType: {};
  shareValue: number; //지분가치
  percentage: number; //지분율
  stock: number; // 보유 주식 수
  remainPrincipal: number; // 잔여 원금
  exitPrincipal: number; // 회수 원금
  investPrincipal: number; // 투자 원금
  exitAmount: number; //회수 금
  remainMOIC?: number;
  remainIRR?: number;
  exitMOIC?: number;
  exitIRR?: number;
  investMOIC?: number;
  investIRR?: number;
  detail: InvestmentHistoryProps[];
}

export interface InvestmentHistoryProps {
  type: "INVEST" | "EXIT";
  date: string;
  stock: number;
  issuingPrice: number;
  amount: number;
  principal: number;
  target: string;
}
