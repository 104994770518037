import React, { useEffect, useRef, useState } from "react";
import {
  FundAccount,
  FundUser,
  LPInfoProps,
} from "../interface/FundAccount.interface";
import {
  editInvestmentOrganization,
  getAcUser,
  getAllFundaccount,
  getAllInvestmentOrganization,
  registeInvestmentOrganization,
  upsertAcFundAccount,
} from "../../../../../api/repository/admin/AcceleratorRepository";
import { showToast } from "../../../../../common/commonUtil";
import { Bounce } from "react-toastify";

import { AcInvestmentOrganization } from "../../../../../type/data";

const useFundAccount = () => {
  const originFundAccount = useRef<string>("");
  const [allFundAccount, setAllFundAccount] = useState<
    FundAccount[] | undefined
  >(undefined);

  const [allInvestmentOrganization, setAllInvestmentOrganization] = useState<
    AcInvestmentOrganization[] | undefined
  >(undefined);

  const [selectedFundAccount, setSelectedFundAccount] = useState<
    FundAccount | undefined
  >(undefined);
  const [allUser, setAllUser] = useState<FundUser[] | undefined>(undefined);
  const [showFundAccountDetailModal, setShowFundAccountDetailModal] =
    useState<boolean>(false);
  const [showLPManageModal, setShowLPManageModal] = useState<boolean>(false);
  const [isChange, updateIsChange] = useState<boolean>(false);

  const onClickFundAccountCreateCard = () => {
    setSelectedFundAccount(undefined);
    setShowFundAccountDetailModal(true);
  };

  const onClickFundAccountCard = (target: FundAccount) => {
    if (!target.isInvest && !target.isActive) {
      return showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>삭제 예정인 펀드는 수정할 수 없습니다.</span>
          </div>
        ),
        backgroundColor: "var(--red-500)",
        color: "var(--text-02)",
        width: 274,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
    }
    setSelectedFundAccount(target);
    setShowFundAccountDetailModal(true);
  };

  const closeFundAccountDetailModal = () => {
    setShowFundAccountDetailModal(false);
  };

  const onClickLPManage = () => {
    setShowLPManageModal(true);
  };

  const closeLPManage = () => {
    setShowLPManageModal(false);
  };

  const onClickDelete = (fundAccount: FundAccount) => {
    if (!allFundAccount) return;

    const newData = allFundAccount.map((item) => {
      if (item.acFundAccountId === fundAccount.acFundAccountId) {
        item.isChange = item.isNew ? false : true;
        item.isActive = false;
      }
      return item;
    });
    setAllFundAccount([...newData]);
  };

  const onClickDeleteCancel = (fundAccount: FundAccount) => {
    if (!allFundAccount) return;

    const newData = allFundAccount.map((item) => {
      if (item.acFundAccountId === fundAccount.acFundAccountId) {
        item.isActive = true;
      }
      return item;
    });
    setAllFundAccount([...newData]);
  };

  const getFundAccount = async () => {
    const fundAccountResult = await getAllFundaccount();
    if (fundAccountResult) {
      const allFund = fundAccountResult.filter((item) => item.type !== 1);
      setAllFundAccount([...allFund]);
      originFundAccount.current = JSON.stringify(allFund);
    } else {
      setAllFundAccount([]);
      originFundAccount.current = "";
    }
  };

  const checkDataChanged = () => {
    updateIsChange(
      originFundAccount.current !== JSON.stringify(allFundAccount)
    );
  };

  const getAllFundUser = async () => {
    const userResult = await getAcUser();
    if (userResult) {
      const newData: FundUser[] = userResult
        .filter(
          (item) =>
            item.employmentStatusId && [2, 3].includes(item.employmentStatusId)
        )
        .map((item, index): FundUser => {
          return {
            acUserId: parseInt(`${item.acUserId}`),
            name: item.name,
            profileImgKey: item.profileImgKey,
            profileImgUrl: item.profileImgUrl,
          };
        });
      setAllUser(newData || []);
    } else {
      setAllUser([]);
    }
  };

  const getAllOrganization = async () => {
    const organizationRestul = await getAllInvestmentOrganization();
    if (organizationRestul) {
      setAllInvestmentOrganization(organizationRestul);
    }
  };

  const createInvestmentOrganization = async (
    newData: LPInfoProps,
    callback: (result: { success: boolean; message?: string }) => void
  ) => {
    if (allInvestmentOrganization === undefined) return;
    const createResult = await registeInvestmentOrganization(newData);
    if (createResult) {
      callback(createResult);
      createResult.success && getAllOrganization();
    }
  };

  const modifyInvestmentOrganization = async (
    newData: LPInfoProps,
    callback: (result: { success: boolean; message?: string }) => void
  ) => {
    if (allInvestmentOrganization === undefined) return;

    const modifyResult = await editInvestmentOrganization(newData);
    if (modifyResult) {
      callback(modifyResult);
      modifyResult.success && getAllOrganization();
    }
  };

  const deleteInvestmentOrganization = async (
    newData: LPInfoProps,
    callback: (result: { success: boolean; message?: string }) => void
  ) => {
    if (allInvestmentOrganization === undefined) return;
    newData.isActive = false;
    const modifyResult = await editInvestmentOrganization(newData);
    if (modifyResult) {
      callback(modifyResult);
      modifyResult.success && getAllOrganization();
    }
  };

  const isUsedOrganizationStatus = (id: number) => {
    if (allFundAccount === undefined) return false;
    const usedOrganizationList = new Set<number>();

    allFundAccount.forEach((item) => {
      item.investmentAssociation.forEach((association) => {
        usedOrganizationList.add(
          association.acInvestmentOrganization.acInvestmentOrganizationId
        );
      });
    });

    return usedOrganizationList.has(id);
  };
  const submitFundAccount = (newData: FundAccount) => {
    if (!allFundAccount) return;

    const findIdx = allFundAccount.findIndex(
      (item) => item.acFundAccountId === newData.acFundAccountId
    );

    if (findIdx !== -1) {
      const newFundData = allFundAccount.map((item, index) => {
        if (index === findIdx) {
          return newData;
        }
        return item;
      });
      setAllFundAccount([...newFundData]);
    } else {
      setAllFundAccount([...allFundAccount, newData]);
    }
  };

  const onSaveFundAccount = async () => {
    if (!allFundAccount) return;
    const newData = allFundAccount
      .filter((item) => !(item.isNew && !item.isActive))
      .map((item) => {
        if (item.isNew) {
          item.acFundAccountId = undefined;
          item.fundPurpose.forEach((purpose) => (purpose.id = undefined));
        }
        return item;
      });
    const result = await upsertAcFundAccount(newData);
    if (result) {
      showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>정보가 성공적으로 저장되었습니다.</span>
          </div>
        ),
        backgroundColor: "var(--green-400)",
        color: "var(--text-02)",
        width: 274,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      getFundAccount();
    }
  };

  useEffect(() => {
    checkDataChanged();
  }, [allFundAccount, allUser, allInvestmentOrganization]);

  useEffect(() => {
    getFundAccount();
    getAllFundUser();
    getAllOrganization();
  }, []);

  return [
    allUser,
    allFundAccount,
    allInvestmentOrganization,
    selectedFundAccount,
    showFundAccountDetailModal,
    showLPManageModal,
    isChange,
    onClickFundAccountCard,
    onClickFundAccountCreateCard,
    closeFundAccountDetailModal,
    onClickLPManage,
    closeLPManage,
    createInvestmentOrganization,
    modifyInvestmentOrganization,
    deleteInvestmentOrganization,
    isUsedOrganizationStatus,
    submitFundAccount,
    onSaveFundAccount,
    onClickDelete,
    onClickDeleteCancel,
  ] as const;
};

export default useFundAccount;
