import React, { useEffect } from "react";
import { DefaultButton } from "lattice_core";
import styled from "styled-components";
import { ReactComponent as Close } from "../../../assets/image/modal_close.svg";
import { useMediaQuery } from "react-responsive";

interface Props {
  type: "alert" | "confirm";
  title?: string;
  contents?: string[];
  positiveTitle?: string;
  negativeTitle?: string;
  onClickPositiveBtn?: () => void;
  onClickNegativeBtn?: () => void;
  onCloseModal?: () => void;
  transfromPixel?: (value: number) => number;
}
const ConfirmModal = (props: Props) => {
  const { transfromPixel = (n) => n, onCloseModal } = props;
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;

  const onKeyDown = (event: KeyboardEvent) => {
    console.log("CONFIRM MODAL");

    event.preventDefault();
    if (event.key === "Enter") {
      props.onClickPositiveBtn && props.onClickPositiveBtn();
      props.onCloseModal && props.onCloseModal();
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <Container width={isMobile ? "90vw" : "408px"}>
      <Header className="body-14-sb">
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          {props.title || "확인하기"}
        </span>
        {onCloseModal && (
          <div className="close" onClick={onCloseModal}>
            <Close />
          </div>
        )}
      </Header>
      <Body className="body-14-md">
        {(props.contents || []).map((item) => (
          <span>{item}</span>
        ))}
      </Body>
      <Footer>
        {props.type === "confirm" && (
          <DefaultButton
            className="heading-14-md"
            buttonType="text"
            sizeType="xsmall"
            buttonColor={"#1F2022"}
            text={props.negativeTitle || "취소"}
            onClick={() => {
              props.onClickNegativeBtn && props.onClickNegativeBtn();
            }}
          />
        )}
        <DefaultButton
          className="heading-14-md"
          buttonType="filled"
          sizeType="xsmall"
          buttonColor={"#173AFF"}
          hoverColor={"#112CBF"}
          text={props.positiveTitle || "확인"}
          onClick={() => {
            props.onClickPositiveBtn && props.onClickPositiveBtn();
          }}
        />
      </Footer>
    </Container>
  );
};

const Container = styled.div<{ width: number | string }>`
  width: ${(props) => props.width};
  height: 230px;

  display: flex;
  flex-direction: column;
  position: relative;
`;
const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;
const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;
const Footer = styled.div`
  padding: 10px 24px 20px 24px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

export default ConfirmModal;
