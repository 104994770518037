import React, { useState } from "react";
import styled, { css } from "styled-components";
import { DefaultButton } from "lattice_core";

interface Props {
  transfromPixel: (value: number) => number;
  cancel: () => void;
  rejectSubmit: (reason: string) => void;
}

const RejectModal = (props: Props) => {
  const MAX_LENGTH = 200;
  const [value, updateValue] = useState<string>("");
  return (
    <Container>
      <div className="body-14-sb" style={{ color: "#1F2022" }}>
        반려하기
      </div>
      <div
        className="body-14-md"
        style={{
          color: "#1F2022",
          marginTop: props.transfromPixel(42),
          textAlign: "center",
        }}
      >
        이 파일을 반려할까요?
      </div>
      <RejectReasonContainer>
        <div
          className="body-14-md reject__reason__title"
          style={{ color: "#1F2022" }}
        >
          반려 사유
        </div>
        <textarea
          className="body-14-rg reject__reason"
          placeholder="반려 사유 입력"
          value={value}
          maxLength={MAX_LENGTH}
          onChange={(e) => {
            updateValue(e.target.value);
          }}
        />
        <div className=" caption-12-md reject__reason__text__count">{`(${value.length}/${MAX_LENGTH})`}</div>
      </RejectReasonContainer>
      <ButtonContainer transfromPixel={props.transfromPixel}>
        <div>
          <DefaultButton
            className="heading-14-md"
            buttonType="text"
            sizeType="xsmall"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={() => {
              props.cancel();
            }}
            transfromPixel={props.transfromPixel}
          />
          <DefaultButton
            className="heading-14-md"
            buttonType="filled"
            disabled={value.length === 0}
            sizeType="xsmall"
            buttonColor={"#F6444F"}
            hoverColor={"#CF3942"}
            text={"반려"}
            onClick={() => {
              props.rejectSubmit(value);
            }}
            transfromPixel={props.transfromPixel}
          />
        </div>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  background: var(--background-contents01);
  width: 90vw;
  height: 50vh;

  display: flex;
  flex-direction: column;

  padding: 24px 24px 0 24px;
`;

const RejectReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 20px;

  .reject__reason__title {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .reject__reason {
    width: 100%;
    height: 200px;
    resize: none;
    color: #1f2022;

    border: 1px solid #edeeef;
    border-radius: 4px;
    margin-top: 10px;

    padding: 12px;

    transition: all 0.2s ease;

    ::placeholder {
      color: #bec0c2;
    }

    :focus {
      border-color: #a2c7ff;
      outline: none;
    }
  }

  .reject__reason__text__count {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #bec0c2;
  }
`;

const ButtonContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    margin-top: auto;
    height: ${transfromPixel(60)}px;
    min-height: ${transfromPixel(60)}px;
    max-height: ${transfromPixel(60)}px;

    padding-top: ${transfromPixel(10)}px;

    .reject__reason__title {
      width: ${transfromPixel(52)}px;
      height: 100%;
      display: flex;
      flex-direction: row;
    }

    .reject__reason {
      flex-grow: 1;
      height: 100%;
    }

    .reject__reason__text__count {
      width: ${transfromPixel(58)}px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
  `
);

export default RejectModal;
