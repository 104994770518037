import React from "react";
import styled from "styled-components";
import { ReactComponent as Info } from "../../../../../assets/image/icon_info.svg";

interface Props {
  text: string;
}

export const RMEDSectorDescription: React.FC<Props> = (props: Props) => {
  return (
    <Container>
      <Info className="icon" style={{ marginTop: 3 }} />
      <TextWrapper>
        {props.text.split("|").map((item, index) => {
          return (
            <div className="body-14-rg" key={index}>
              {item}
            </div>
          );
        })}
      </TextWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  background-color: var(--gray-50);
  border-radius: 4px;
  gap: 8px;
  padding: 12px 16px;
  color: var(--text-01);

  border: 1px solid var(--gray-300);
  .icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
  }
`;

const TextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
