import React from "react";
import styled from "styled-components";

interface Props {
  transfromPixel: (value: number) => number;
}
const CurrentRead: React.FC<Props> = (props) => {
  const containerStyle = {
    paddingLeft: props.transfromPixel(24),
    paddingRight: props.transfromPixel(8),
    paddingBottom: props.transfromPixel(16),
  };

  const textStyle = {
    color: "#5B5D61",
    paddingTop: props.transfromPixel(6),
    paddingBottom: props.transfromPixel(6),
    paddingLeft: props.transfromPixel(16),
    paddingRight: props.transfromPixel(16),
  };
  return (
    <Container style={containerStyle}>
      <span className="caption-12-rg current__read__bubble" style={textStyle}>
        여기까지 읽음
      </span>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .current__read__bubble {
    background-color: #f3f4f4;
    border-radius: 50px;
  }
`;

export default CurrentRead;
