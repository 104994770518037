import React from "react";

import styled from "styled-components";
import { AddComma } from "../../../../../../common/commonUtil";

interface Props {
  totalStockNum: number;
  totalPercent: number;
  totalAmount: number;
}

export const StockholderFooter: React.FC<Props> = ({
  totalAmount,
  totalStockNum,
  totalPercent,
}) => {
  const height = 32;
  return (
    <Container className={`caption-12-b`}>
      <CellConatiner
        width={1200 - 100 - 60 - 120 - 80}
        height={height}
        style={{ justifyContent: "flex-start" }}
      >
        합계
      </CellConatiner>

      <CellConatiner
        width={100}
        height={height}
        style={{ justifyContent: "flex-end" }}
      >
        {AddComma(totalStockNum)}
      </CellConatiner>
      <CellConatiner
        width={60}
        height={height}
        style={{ justifyContent: "flex-end" }}
      ></CellConatiner>
      <CellConatiner
        width={120}
        height={height}
        style={{ justifyContent: "flex-end" }}
      >
        {AddComma(totalAmount)}
      </CellConatiner>
      <CellConatiner
        width={80}
        height={height}
        style={{ justifyContent: "flex-end" }}
      >
        {totalPercent.toFixed(0)}%
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  bottom: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height || 32}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: var(--text-01);
  border-right: 0.5px solid var(--divider-300, #e3e4e5);
  padding: 0 10px;
`;
