import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DealFlowCountProps,
  DealFlowFilterProps,
  DealFlowPortfolioProps,
  DealFlowStructureFilterProps,
} from "../interface/DealFlow.interface";
import { getDealFlowPortfolio } from "../../../api/repository/portfolio/PortfolioRepository";
import {
  getTransformedId,
  registeEntryPointToPortfolio,
} from "../../../common/commonUtil";
import { DealFlowHistory, UserInfo } from "../../../type/data";
import {
  getDealFlowHistory,
  setDealFlowHistory,
} from "../../../common/pageHistoryUtil";
import { getMyInfo } from "../../../common/commonApiData";

const useDealFlow = () => {
  const navigate = useNavigate();

  const rootRef = useRef<HTMLDivElement>(null);
  const initCompleted = useRef<boolean>(false);
  const myInfo = useRef<UserInfo | undefined>(undefined);
  const [portfolioList, updatePortfolioList] = useState<
    DealFlowPortfolioProps[] | undefined
  >(undefined);

  const [filterList, updateFilterList] = useState<
    DealFlowStructureFilterProps[] | undefined
  >(undefined);

  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [sortingItem, updateSortingItem] = useState<string>("단계순");
  const [filterItem, updateFilterItem] = useState<
    DealFlowFilterProps | undefined
  >(undefined);

  const portfolioSort = (
    compareA: DealFlowPortfolioProps,
    compareB: DealFlowPortfolioProps
  ) => {
    switch (sortingItem) {
      case "단계순":
        return compareNumber(compareA.phase.priority, compareB.phase.priority);
      case "오래된순":
        return compareText(compareA.createdAt, compareB.createdAt);
      case "최신순":
        return compareText(compareA.createdAt, compareB.createdAt) * -1;
      default:
        return compareText(compareA.acChannel, compareB.acChannel);
    }
  };

  const compareText = (a: string, b: string) => {
    var textA = a.toUpperCase();
    var textB = b.toUpperCase();
    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }
    return 0;
  };

  const compareNumber = (a: number, b: number) => {
    return a - b;
  };

  const goToPortfolio = (portfolioId: number) => {
    const to = `/portfolio/${getTransformedId(portfolioId)}/info`;
    navigate(to);
  };

  const getCount = () => {
    const filterPortfolio = getFilterPortfolio();
    if (filterPortfolio.length === 0)
      return {
        review: 0,
        pitching: 0,
        investment: 0,
        contract: 0,
        tipsExpected: 0,
        tipsFixed: 0,
        total: 0,
      };

    const review = filterPortfolio.filter(
      (item) => item.phase.type === "review"
    ).length;
    const pitching = filterPortfolio.filter(
      (item) => item.phase.type === "pitching"
    ).length;
    const investment = filterPortfolio.filter(
      (item) => item.phase.type === "investment"
    ).length;
    const contract = filterPortfolio.filter(
      (item) => item.phase.type === "contract"
    ).length;
    const tipsExpected = filterPortfolio.filter(
      (item) => item.isTips === "EXPECTED"
    ).length;

    const tipsFixed = filterPortfolio.filter(
      (item) => item.isTips === "FIXED"
    ).length;
    const total = review + pitching + investment + contract;

    return {
      review: review,
      pitching: pitching,
      investment: investment,
      contract: contract,
      tipsExpected: tipsExpected,
      tipsFixed: tipsFixed,
      total: total,
    };
  };

  const initFilter = () => {
    updateFilterItem(undefined);
    updateSortingItem("단계순");
  };

  const getFilterPortfolio = () => {
    if (!portfolioList) return [];

    const filterPortfolio = portfolioList
      .sort(portfolioSort)
      .filter((item) => {
        const particpantNameString = item.pfParticipant
          .map((user) => user.acUser.name)
          .join(",");
        return (
          (item.companyName &&
            item.companyName
              .toUpperCase()
              .includes(searchKeyword.toUpperCase())) ||
          (item.description &&
            item.description
              .toUpperCase()
              .includes(searchKeyword.toUpperCase())) ||
          (item.acChannel &&
            item.acChannel
              .toUpperCase()
              .includes(searchKeyword.toUpperCase())) ||
          (item.ceoName &&
            item.ceoName.toUpperCase().includes(searchKeyword.toUpperCase())) ||
          particpantNameString
            .toUpperCase()
            .includes(searchKeyword.toUpperCase())
        );
      })
      .filter((item) => {
        if (filterItem === undefined) return true;

        if (filterItem.type === "my_deal") {
          return item.pfParticipant
            .map((user) => user.acUser.acUserId)
            .includes(filterItem.id);
        }

        if (filterItem.type === "structure_deal") {
          const headquarter = item.pfParticipant.map(
            (user) => user.acUser.headquarter?.acStructureId
          );
          const team = item.pfParticipant.map(
            (user) => user.acUser.team?.acStructureId
          );
          return [...headquarter, ...team].includes(filterItem.id);
        }

        return true;
      });
    return filterPortfolio;
  };

  const isExistMyPortfolio = (list: DealFlowPortfolioProps[], myid: number) => {
    if (!list) return false;
    return (
      list.filter((item) => {
        return item.pfParticipant
          .map((user) => user.acUser.acUserId)
          .includes(myid);
      }).length > 0
    );
  };

  const init = async () => {
    const dealflowHistory = getDealFlowHistory();
    await registeEntryPointToPortfolio("deal_flow");
    const result = await getDealFlowPortfolio();

    myInfo.current = await getMyInfo();
    let existMyPortfolio = true;

    if (result) {
      updatePortfolioList(result.portfolioList);
      updateFilterList(result.filterList);
      if (myInfo.current) {
        existMyPortfolio = isExistMyPortfolio(
          result.portfolioList,
          parseInt(`${myInfo.current.id}`)
        );
      }
    }

    console.log(dealflowHistory);
    if (dealflowHistory?.search) {
      dealflowHistory.search?.keyword &&
        updateSearchKeyword(dealflowHistory.search.keyword);
      dealflowHistory.search?.sort &&
        updateSortingItem(dealflowHistory.search?.sort);
      dealflowHistory.search?.filter &&
        updateFilterItem(dealflowHistory.search?.filter);
    } else {
      updateSearchKeyword("");
      updateSortingItem("단계순");
      updateFilterItem(
        existMyPortfolio
          ? {
              id: parseInt(`${myInfo.current?.id || 0}`),
              type: "my_deal",
            }
          : undefined
      );
    }

    setTimeout(() => {
      initCompleted.current = true;
    }, 500);
  };

  const onScrollHandler = (e: any) => {
    setDealFlowHistory({
      search: {
        scrollTop: e.target.scrollTop,
      },
    });
  };

  useEffect(() => {
    if (!initCompleted.current) return;
    setDealFlowHistory({
      search: {
        sort: sortingItem,
      },
    });
  }, [sortingItem]);

  useEffect(() => {
    if (!initCompleted.current) return;
    setDealFlowHistory({
      search: {
        filter: filterItem,
      },
    });
  }, [filterItem]);

  useEffect(() => {
    if (!initCompleted.current) return;
    setDealFlowHistory({
      search: {
        keyword: searchKeyword,
      },
    });
  }, [searchKeyword]);

  useEffect(() => {
    const dealflowHistory = getDealFlowHistory();
    if (
      rootRef.current &&
      dealflowHistory &&
      dealflowHistory.search &&
      dealflowHistory.search.scrollTop
    ) {
      rootRef.current.scrollTo({ top: dealflowHistory.search.scrollTop });
    }
  }, [portfolioList]);

  useEffect(() => {
    init();
    if (rootRef.current) {
      rootRef.current.addEventListener("scroll", onScrollHandler);
    }
    return () => {
      if (rootRef.current) {
        rootRef.current.removeEventListener("scroll", onScrollHandler);
      }
    };
  }, []);

  return [
    rootRef,
    initCompleted.current,
    myInfo.current,
    filterList,
    searchKeyword,
    sortingItem,
    filterItem,
    portfolioList,
    updateSearchKeyword,
    updateSortingItem,
    updateFilterItem,
    getFilterPortfolio,
    getCount,
    initFilter,
    goToPortfolio,
  ] as const;
};

export default useDealFlow;
