import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import DealFlow from "./router/deal-flow/DealFlow";
import Accelerating from "./router/accelerating/Accelerating";

import { setInitAxioSetting } from "./api/api";

// 어드민 페이지
import AdminLogin from "./router/admin/router/login/AdminLogin";
import AcceleratorStructure2 from "./router/admin/router/structure/Structure";

import FundAccount from "./router/admin/router/fund_account/FundAccount";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InnerContractTask from "./router/task/inner-contract/InnerContractTask";

import MyPage from "./router/mypage/MyPage";

import { checkLogin, CLIENT_ID } from "./google/login/GoogleLoginUtil";

import React, { useEffect, useState } from "react";
import Database from "./router/database/Database";

import NotFound from "./router/not-found/NotFound";
import ViewTest from "./ViewTest";

import { Provider, atom } from "jotai";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { ThemeProvider } from "styled-components";
import { ThemeStyles } from "./style/ThemeStyles";
import BluecardLinkPage from "./router/bluecard-link/BluecardLinkPage";
import PDFViewerPage from "./router/pdf-viewer/PDFViewerPage";

import RequestEvaluation from "./router/portfolio/request-evaluation-info/RequestEvaluation";

import Excavation from "./router/excavation/Excavation";
import ExcavationCreate from "./router/excavation-create/ExcavationCreate";
import EvaluationTask from "./router/task/evaluation/EvaluationTask";
import PrePitchingTask from "./router/task/pre-pitching/PrePitchingTask";
import { EvaluationConfirmTask } from "./router/task/evaluation-confirm/EvaluationConfirmTask";
import FundAccountInfo from "./router/dashboard/fund-account-info/FundAccountInfo";
import FundAccountDetail from "./router/dashboard/fund-account-detail/FundAccountDetail";
import PerformanceMetrics from "./router/dashboard/performance-metrics/PerformanceMetrics";
import { MinutesPreview } from "./router/risk-management/minutes/MinutesPreview";
import RiskManagementEvaluation from "./router/risk-management/evaluation/RiskManagementEvaluation";
import RiskManagementEvaluationDetail from "./router/risk-management/evaluation-detail/RiskManagementEvaluationDetail";
import ReferenceDocument from "./router/risk-management/reference-document/ReferenceDocument";
import RiskManagementEvaluationDetailViewer from "./router/risk-management/evaluation-detail-viewer/RiskManagementEvaluationDetailViewer";
import FinanceDataInput from "./router/finance-data-input/FinanceDataInput";
import RiskManagementTotalEvaluation from "./router/risk-management/total-evaluation/RiskManagementTotalEvaluation";
import Channel from "./router/admin/router/channel/Channel";
import Portfolio from "./router/admin/router/portfolio/Portfolio";
import RequestExpert from "./router/portfolio/request-export-opinion/RequestExpert";
import IndustrialTech from "./router/admin/router/industry_tech/IndustrialTech";
import Login from "./router/login/Login";
import PortfolioDetail from "./router/portfolio/portfolio-detail/PortfolioDetail";
import Reporting from "./router/reporting/Reporting";
import ReportingDetail from "./router/reporting-detail/ReportingDetail";
import ContractTask from "./router/task/contract/ContractTask";
import StockChangeListViewer from "./router/portfolio-viewer-stock-change-history/StockChangeListViewer";

export const portfolioNameAtom = atom<string>("");
export const portfolioLogoAtom = atom<string>("");

const App = () => {
  const [theme, setTheme] = useState<string>("light");
  const [screenRate, setScreenRate] = useState<number>(1);

  setInitAxioSetting();
  checkLogin();

  const resizeHandler = (): void => {
    const screenWidth =
      window.innerWidth >= 1536
        ? 1536
        : window.innerWidth <= 1280
        ? 1280
        : window.innerWidth;

    setScreenRate(screenWidth / 1536);
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId={`${CLIENT_ID}`}>
      <Provider>
        <ThemeProvider
          theme={
            theme === "light"
              ? { type: "light", screenWidthRate: screenRate }
              : { type: "dark", screenWidthRate: screenRate }
          }
        >
          <ThemeStyles />
          <React.Fragment>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/mypage" element={<MyPage />} />
                <Route path="/excavation" element={<Excavation />} />
                <Route
                  path="/excavation/create"
                  element={<ExcavationCreate />}
                />
                <Route path="/deal-flow" element={<DealFlow />} />
                <Route path="/accelerating" element={<Accelerating />} />

                <Route path="/reporting" element={<Reporting />} />
                <Route
                  path="/reporting/portfolioReport/:id"
                  element={<ReportingDetail />}
                />
                <Route
                  path="/quarter-management/evaluation"
                  element={<RiskManagementEvaluation />}
                />
                <Route
                  path="/quarter-management/total-evaluation"
                  element={<RiskManagementTotalEvaluation />}
                />
                <Route
                  path="/quarter-management/risk-management/detail/:id"
                  element={<RiskManagementEvaluationDetail />}
                />
                <Route
                  path="/quarter-management/risk-management/detail/:id/viewer"
                  element={<RiskManagementEvaluationDetailViewer />}
                />

                <Route
                  path="/dashboard/fund-account"
                  element={<FundAccountInfo />}
                />
                <Route
                  path="/dashboard/fund-account/detail/:id"
                  element={<FundAccountDetail />}
                />
                <Route
                  path="/dashboard/performance"
                  element={<PerformanceMetrics />}
                />
                <Route path="/database" element={<Database />} />

                <Route path="/portfolio/:id/*" element={<PortfolioDetail />} />
                <Route
                  path="/portfolio/external_company_info/:id"
                  element={<RequestEvaluation />}
                />
                <Route
                  path="/portfolio/expert/:id"
                  element={<RequestExpert />}
                />
                <Route
                  path="/task/:id/pre-pitching/:id"
                  element={<PrePitchingTask />}
                />
                <Route
                  path="/task/:id/investment-assessment/:id/contract"
                  element={<ContractTask />}
                />
                <Route
                  path="/task/:id/investment-assessment/:id/report-account/:id"
                  element={<EvaluationTask />}
                />
                <Route
                  path="/task/:id/evaluation-confirm/:id/report-account/:id"
                  element={<EvaluationConfirmTask />}
                />

                <Route path="/task/:id/contract" element={<ContractTask />} />
                <Route
                  path="/task/:id/inner-followup-contract/:id/contract"
                  element={<InnerContractTask />}
                />
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route
                  path="/admin/company-structure"
                  element={<AcceleratorStructure2 />}
                />
                <Route
                  path="/admin/industry-tech"
                  element={<IndustrialTech />}
                />
                <Route path="/admin/fund-account" element={<FundAccount />} />
                <Route path="/admin/channel" element={<Channel />} />
                <Route path="/admin/portfolio" element={<Portfolio />} />

                <Route
                  path="/bluecard/link/:code"
                  element={<BluecardLinkPage />}
                />

                <Route path="/pdf-viewer/:key" element={<PDFViewerPage />} />
                <Route
                  path="/pdf-viewer/:key/share"
                  element={<PDFViewerPage />}
                />
                <Route
                  path="/risk-management-minute-preview"
                  element={<MinutesPreview />}
                />

                <Route
                  path="/reference-document/:key"
                  element={<ReferenceDocument />}
                />

                <Route
                  path="/finance-data-input"
                  element={<FinanceDataInput />}
                />

                <Route
                  path="/portfolio/:pathParam/portfolioInvestmentInfoViewer"
                  element={<StockChangeListViewer />}
                />

                {/* <Route path="/test-test" element={<ViewTest />} /> */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
            <ToastContainer />
          </React.Fragment>
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
};

export default App;
