import React, { useEffect, useRef, useState } from "react";
import { Bounce, toast } from "react-toastify";
import {
  IndustryTechAdminItem,
  IndustryTechProps,
} from "../interface/IndustrialTech.interface";
import { KeywordType } from "../../../../../type/data";
import {
  getAcIndustrialTech,
  upsertAcChannel,
  upsertIndustrialTech,
} from "../../../../../api/repository/admin/AcceleratorRepository";
import { DropResult } from "react-beautiful-dnd";
import { showToast } from "../../../../../common/commonUtil";
import { rootStyle } from "../../../../../style/ThemeStyles";

const useIndustrialTech = () => {
  const selectedIndustrialTech = useRef<IndustryTechAdminItem | undefined>(
    undefined
  );
  const originIndustrialTech = useRef<IndustryTechProps | undefined>(undefined);
  const [industrialTech, setIndustrialTech] = useState<
    IndustryTechProps | undefined
  >(undefined);

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [industrialTechModal, setIndustrialTechModal] =
    useState<boolean>(false);

  const closeModal = () => {
    setIndustrialTechModal(false);
    selectedIndustrialTech.current = undefined;
  };
  const onClickItem = (droppableId: string, acIndustrialTechId: string) => {
    if (!industrialTech) return;

    if (droppableId === "active") {
      selectedIndustrialTech.current = industrialTech.active.find(
        (item) => `${item.acIndustrialTechId}` === `${acIndustrialTechId}`
      );
    } else {
      selectedIndustrialTech.current = industrialTech.deactive.find(
        (item) => `${item.acIndustrialTechId}` === `${acIndustrialTechId}`
      );
    }
    setIndustrialTechModal(true);
  };
  const onClickNewIndustryTech = () => {
    setIndustrialTechModal(true);
  };

  const addIndustryTech = (newData: IndustryTechAdminItem) => {
    if (!industrialTech) return;

    const newIndustrialTech = structuredClone(industrialTech);
    newIndustrialTech.active = [newData, ...newIndustrialTech.active];
    setIndustrialTech({ ...newIndustrialTech });
    closeModal();
  };

  const modifyIndustryTech = (newData: IndustryTechAdminItem) => {
    if (!industrialTech) return;

    const activeTarget = industrialTech.active.findIndex(
      (item) => `${item.acIndustrialTechId}` === `${newData.acIndustrialTechId}`
    );
    const deactiveTarget = industrialTech.deactive.findIndex(
      (item) => `${item.acIndustrialTechId}` === `${newData.acIndustrialTechId}`
    );

    if (activeTarget !== -1) {
      const newIndustrialTech = structuredClone(industrialTech);
      newIndustrialTech.active[activeTarget] = newData;
      setIndustrialTech({ ...newIndustrialTech });
      closeModal();
      return;
    }

    if (deactiveTarget !== -1) {
      const newIndustrialTech = structuredClone(industrialTech);
      newIndustrialTech.deactive[deactiveTarget] = newData;
      setIndustrialTech({ ...newIndustrialTech });
      closeModal();
      return;
    }
  };
  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination || !industrialTech) {
      return;
    }

    console.log(source, destination);
    const sourceKey = source.droppableId;
    const destinationKey = destination.droppableId;

    const newIndustrialTech: IndustryTechProps = {
      active: industrialTech.active.map((item) => ({
        ...item,
      })),
      deactive: industrialTech.deactive.map((item) => ({
        ...item,
      })),
    };

    console.log(newIndustrialTech);
    const [targetItem] =
      sourceKey === "active"
        ? newIndustrialTech.active.splice(source.index, 1)
        : newIndustrialTech.deactive.splice(source.index, 1);
    destinationKey === "active"
      ? newIndustrialTech.active.splice(destination.index, 0, targetItem)
      : newIndustrialTech.deactive.splice(destination.index, 0, targetItem);

    console.log(newIndustrialTech);

    setIndustrialTech({ ...newIndustrialTech });
  };

  const getAllIndustrialTech = async () => {
    const industrialResult = await getAcIndustrialTech();
    if (industrialResult) {
      setIndustrialTech({ ...structuredClone(industrialResult) });
      originIndustrialTech.current = structuredClone(industrialResult);
    } else {
      setIndustrialTech({ active: [], deactive: [] });
    }
  };

  const isChange = () => {
    if (!industrialTech) return setIsChanged(false);

    const compaireA = JSON.stringify(industrialTech);
    const compaireB = JSON.stringify(originIndustrialTech.current);

    setIsChanged(compaireA !== compaireB);
  };

  const updateIndustrialTech = async () => {
    const activeData = industrialTech?.active.map((item, index) => {
      return {
        ...item,
        order: index + 1,
        isActive: true,
        isChange: !item.isNew,
        acIndustrialTechId: item.isNew ? undefined : item.acIndustrialTechId,
      };
    });
    const deactiveData = industrialTech?.deactive.map((item, index) => {
      return {
        ...item,
        order: index + 1,
        isActive: false,
        isChange: !item.isNew,
        acIndustrialTechId: item.isNew ? undefined : item.acIndustrialTechId,
      };
    });
    const result = await upsertIndustrialTech([
      ...(activeData || []),
      ...(deactiveData || []),
    ]);
    if (result && result.success) {
      showToast({
        customElement: (
          <span className="heading-16-sb">
            현재 정보를 저장 완료하였습니다.
          </span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--green-400"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,

        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      getAllIndustrialTech();
    }
  };

  useEffect(() => {
    isChange();
  }, [industrialTech]);

  useEffect(() => {
    getAllIndustrialTech();
  }, []);

  // --- requestAnimationFrame 초기화
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  return [
    enabled,
    industrialTech,
    selectedIndustrialTech.current,
    industrialTechModal,
    isChanged,
    onDragEnd,
    onClickItem,
    closeModal,
    addIndustryTech,
    modifyIndustryTech,
    onClickNewIndustryTech,
    isChange,
    updateIndustrialTech,
  ] as const;
};

export default useIndustrialTech;
