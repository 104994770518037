import { rejects } from "assert";
import axios from "axios";
import { resolve } from "dns";
import * as api from "../api/api";
import {
  DictionaryType,
  FundAccountType,
  IndustryTech,
  Participant,
  ParticipantType,
  PortfolioMenuType,
  UserDetailInfo,
  UserInfo,
} from "../type/data";
import { getNewDate, getYMD } from "./commonUtil";
import { getMyInfo as getUserInfo } from "../common/commonUtil";

export const getMyDetailInfo = async (): Promise<
  UserDetailInfo | undefined
> => {
  try {
    const result = await axios.get(api.getMyInfo());
    if (result.status === 200)
      return new Promise((resolve) => resolve(result.data.acUserInfo));
  } catch (error) {
    return new Promise((resolve) => resolve(undefined));
  }
};

export const getMyInfo = async (): Promise<UserInfo | undefined> => {
  try {
    const result = await axios.get(api.getMyInfo());
    const _userInfo: UserInfo = {
      ...result.data.acUserInfo,
      id: result.data.acUserInfo.acUserId,
      type: "AC_USER",
    };

    return new Promise((resolve) => resolve(_userInfo));
  } catch (error) {
    return new Promise((resolve) => resolve(undefined));
  }
};

export const getToday = async () => {
  try {
    return (await axios.get(api.getServerTime())).data.date;
  } catch (error) {}
};

export const getAllAcUser = async (type: "admin" | "user") => {
  try {
    let acUserList: any[] = [];
    if (type === "admin") {
      acUserList = (await axios.get(api.getAcAdminUsers())).data.acUserList;
    } else {
      acUserList = (await axios.get(api.getAcUsers())).data.acUserList;
    }
    const allUser =
      acUserList.map((item: any): UserInfo => {
        return { ...item, type: "AC_USER", id: item.acUserId };
      }) || [];
    return allUser;
  } catch (error) {}
};

// 모든 Fundaccount 가져오기
export const getAllFundaccount = async (
  type: "admin" | "user"
): Promise<FundAccountType[] | undefined> => {
  const today = getNewDate(await getToday());

  const getFundaccoutnStatus = (item: any) => {
    let status = "expected_investment";
    const invest_s = getNewDate(item.investmentStartDate);
    const invest_e = getNewDate(item.investmentEndDate);
    const operation_s = getNewDate(item.operatingStartDate);
    const operation_e = getNewDate(item.operatingEndDate);
    if (
      today.getTime() >= invest_s.getTime() &&
      today.getTime() <= invest_e.getTime()
    ) {
      if (item.totalAmount === item.avaliableAmount) {
        // 총 재원과 사용 가능금액이 같으면 미사용
        status = "before_investment";
      } else {
        status = "investing";
      }
    }

    if (item.avaliableAmount === 0) {
      status = "out_of_financial_resources";
    }

    if (
      today.getTime() >= operation_s.getTime() &&
      today.getTime() <= operation_e.getTime()
    ) {
      status = "operation";
    }

    if (today.getTime > operation_e.getTime) {
      status = "end_of_operation";
    }
    return status;
  };

  try {
    let data: any[] = [];
    if (type === "admin") {
      data = (await axios.get(api.getAcAdminFundAccount())).data
        .acFundAccountList;
    } else {
      data = (await axios.get(api.getAcFundAccount())).data.acFundAccountList;
    }
    const fundAccountList = data.map((item: any): FundAccountType => {
      return {
        ...item,
        status: getFundaccoutnStatus(item),
        investmentStartDate: getYMD(item.investmentStartDate),
        investmentEndDate: getYMD(item.investmentEndDate),
        operatingStartDate: getYMD(item.operatingStartDate),
        operatingEndDate: getYMD(item.operatingEndDate),
      };
    });
    return new Promise((resolve) => resolve(fundAccountList));
  } catch (error) {}
};

//Accelerator - StockType, StockChangeType, StageType, Relationship 가져오기
export const getAllDictionary = async (): Promise<
  DictionaryType | undefined
> => {
  try {
    const data = (await axios.get(api.getDictionary())).data;
    const dictionary: DictionaryType = { ...data };

    return new Promise((resolve) => resolve(dictionary));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

//Accelerator -
export const getInvestedAcFundAccount = async (
  id: number
): Promise<FundAccountType[] | undefined> => {
  try {
    const data = (await axios.get(api.getACFundAccountByPf(id))).data
      .acFundAccount;
    const fundAccount: FundAccountType[] = [...data];

    return new Promise((resolve) => resolve(fundAccount));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

export const checkAccessToMenu = async (
  menuType: PortfolioMenuType,
  portfolioId: number
) => {
  try {
    const result = await axios.get(api.isAccessibleMenu(menuType, portfolioId));
    return new Promise((resolve) =>
      resolve(result.status === 200 && result.data)
    );
  } catch (error) {
    console.log(error);

    return new Promise((rejects) => rejects(false));
  }
};
