import React from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../../SummaryBusinessPlanV2Download";

interface Props {
  title: string;
}

const CustomHeader: React.FC<Props> = (props) => {
  const { title } = props;

  return (
    <Container className="caption-11-rg" convertPixelToCm={convertPixelToCm}>
      {title}
    </Container>
  );
};

const Container = styled.div<{ convertPixelToCm: (n: number) => number }>`
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--text-05);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 0 calc(20px * (764 / 1200));
  gap: 2px;

  .header__sorting {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    transition: all 0.2s ease;
    cursor: pointer;

    &.selected__sorting {
      path {
        fill: #4584ff;
      }
    }

    &.sorting__option__show {
      path {
        fill: #4584ff;
      }
    }

    :hover {
      path {
        fill: #4584ff;
      }
    }
  }
`;

export default CustomHeader;
