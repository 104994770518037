import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getDatabaseBluecardInfo } from "../../../../api/repository/database/DatabaseRepository";
import ModalLayout from "../../../../components/modal/ModalLayout";
import { BlueCardInfo } from "../../../../type/data";
import PreviewBlueCardLink from "./PreviewBlueCardLink";
import BluecardLinkModal from "./BluecardLinkModal";

import { ReactComponent as Link } from "../../../../assets/image/icon_link.svg";

interface Props {
  portfolioIds?: number[];
}

const CreateBluecardLinkBtn: React.FC<Props> = (props) => {
  const [portfolioBlueCard, updatePortfolioBlueCard] = useState<BlueCardInfo[]>(
    []
  );
  const [openBluecardPreview, updateOpenBluecardPreview] =
    useState<boolean>(false);

  const [openBluecardLinkModal, updateOpenBluecardLinkModal] =
    useState<boolean>(false);

  const linkUUID = useRef<string | undefined>(undefined);

  const getBluecardInfo = async () => {
    try {
      if (!props.portfolioIds || props.portfolioIds.length === 0) return;

      const info = await getDatabaseBluecardInfo(props.portfolioIds);
      if (info.success && info.data) {
        updatePortfolioBlueCard(info.data);
        updateOpenBluecardPreview(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Container onClick={getBluecardInfo}>{props.children}</Container>
      {openBluecardPreview && (
        <ModalLayout
          isOpen={openBluecardPreview}
          toggle={() => updateOpenBluecardPreview(false)}
          overlayColor={"rgba(0,0,0,0.6)"}
        >
          <PreviewBlueCardLink
            portfolioBlueCard={portfolioBlueCard}
            closeBlueCardPDFView={() => updateOpenBluecardPreview(false)}
            callback={(uuid) => {
              linkUUID.current = uuid;
              updateOpenBluecardLinkModal(true);
              updateOpenBluecardPreview(false);
            }}
          />
        </ModalLayout>
      )}

      {linkUUID.current && openBluecardLinkModal && (
        <ModalLayout
          isOpen={openBluecardLinkModal}
          toggle={() => updateOpenBluecardLinkModal(false)}
          overlayColor={"rgba(0,0,0,0.6)"}
        >
          <BluecardLinkModal
            uuid={linkUUID.current}
            cancel={() => {
              updateOpenBluecardLinkModal(false);
            }}
          />
        </ModalLayout>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  user-select: none;
`;
export default CreateBluecardLinkBtn;
