import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import { CheckCellConatiner } from "./TableHeader";
import { TableGridHeaderProps, TableGridProps } from "./TableGrid";

import { ReactComponent as CheckT } from "../../../../../assets/image/new_check_box_true.svg";
import { ReactComponent as CheckF } from "../../../../../assets/image/new_check_box_false.svg";

interface Props {
  headerData: TableGridHeaderProps[];
  rootWidth?: number;
  rowData: TableGridProps;
  transfromPixel: (value: number) => number;
  isChecking?: boolean;
  checkable?: boolean;
  isChecked?: boolean;
  isCheckedDataNotZero?: boolean;
  onClickRow?: () => void;
  onCheckData?: (data: TableGridProps) => void;
  fileDownloadCallback?: () => void;
}

const TableRow: React.FC<Props> = ({
  rootWidth = 2000,
  headerData,
  checkable,
  rowData,
  isChecked,
  isCheckedDataNotZero,
  onCheckData,
  onClickRow,
  transfromPixel,
}) => {
  const getWidthAuto = () => {
    const width = headerData
      .filter((item) => item.width !== -1)
      .map((item) => item.width)
      .reduce((p, c) => p + c, 0);

    const autoWidth = rootWidth - width;
    return autoWidth;
  };
  return (
    <Container
      className={`body-14-rg ${isChecked ? "checked__row" : ""}`}
      style={{
        cursor: checkable && isCheckedDataNotZero ? "pointer" : "default",
        height: transfromPixel(50),
        minHeight: transfromPixel(50),
        maxHeight: transfromPixel(50),
      }}
      onClick={() => {
        onClickRow && onClickRow();
      }}
    >
      {checkable && (
        <CheckCellConatiner
          className={rowData.data.isOpen ? "opened" : ""}
          style={{
            width: transfromPixel(56),
            minWidth: transfromPixel(56),
            maxWidth: transfromPixel(56),
            borderBottom: `1px solid #edeeef`,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onCheckData && onCheckData(rowData);
          }}
        >
          {isChecked || rowData.data.isOpen ? (
            <CheckT width={transfromPixel(16)} height={transfromPixel(16)} />
          ) : (
            <CheckF width={transfromPixel(16)} height={transfromPixel(16)} />
          )}
        </CheckCellConatiner>
      )}
      {headerData.map((item, idx) => {
        return (
          <CellContainer
            key={idx}
            style={{
              width: item.width === -1 ? getWidthAuto() : item.width || 100,
              minWidth: item.width === -1 ? getWidthAuto() : item.width || 100,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item.customCell
              ? item.customCell(rowData.data[item.key], rowData.data)
              : null}
          </CellContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.tr`
  position: relative;
  display: flex;
  flex-direction: row;
  transform: all 0.2s ease;
  background-color: #ffffff;

  &.checked__row {
    background-color: #fafafb;
  }

  :hover {
    background-color: var(--gray-100);
  }
`;

const CellContainer = styled.td`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid #edeeef;

  overflow: hidden;

  cursor: pointer;
`;
export default React.memo(TableRow);
