import { TIPS } from "../../../type/data";
import { PortfolioPhase } from "../../portfolio/portfolio-detail/interface/PortfolioNavigation.interface";

export type DealFlowPhase = "review" | "pitching" | "investment" | "contract";

export const getDealFlowPhaseToWord = (
  phase: DealFlowPhase,
  isNext?: boolean
) => {
  switch (phase) {
    case "review":
      return (isNext ? "후행 " : "") + "미팅·검토";
    case "pitching":
      return (isNext ? "후행 " : "") + "피칭심사";
    case "investment":
      return (isNext ? "후행 " : "") + "투자심사";
    case "contract":
      return (isNext ? "후행 " : "") + "계약중";
  }
};

export interface DealFlowPortfolioProps {
  portfolioId: number;
  companyName: string;
  ceoName: string;
  description: string;
  isTips: TIPS;
  createdAt: string;
  acChannel: string;
  pfParticipant: DealFlowParticipantProps[];
  pfComment: string[];
  pfPhase: {
    type: PortfolioPhase;
  };
  phase: {
    type: DealFlowPhase;
    priority: number;
  };
}

export interface DealFlowParticipantProps {
  pfParticipantId: number;
  isCharger: boolean;
  acUser: {
    acUserId: number;
    name: string;
    profileImgUrl?: string;
    headquarter?: {
      acStructureId: number;
    };
    team?: {
      acStructureId: number;
    };
  };
}

export interface DealFlowStructureFilterProps {
  acStructureId: number;
  name: string;
}

export interface DealFlowCountProps {
  review: number;
  pitching: number;
  investment: number;
  contract: number;
  tipsExpected: number;
  tipsFixed: number;
  total: number;
}

export interface DealFlowFilterProps {
  type: "my_deal" | "structure_deal";
  id: number;
}

export interface SelectorProps {
  id: number;
  text: string;
  data: any;
}

export const sortingList: SelectorProps[] = [
  { id: 1, text: "단계순", data: "단계순" },
  { id: 2, text: "오래된순", data: "오래된순" },
  { id: 3, text: "최신순", data: "최신순" },
  { id: 4, text: "채널순", data: "채널순" },
];
