import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FinancialStatementProps } from "../interface/FinancialStatement.interface";

import { ReactComponent as Lock } from "../../../../../../assets/image/new_icon_lock.svg";
import { getSBPData } from "../../../../repository/ReportingRepository";
import { addCommasToIntegerPart } from "../../../../../../common/commonUtil";
import { FinanceDataProps } from "../../../../../risk-management/evaluation-detail/interface/RMED.interface";
import { getFinanceDataByInfo } from "../../../../../../api/repository/portfolio/PortfolioRepository";
import { PDFViewer } from "../../../../../../components-2.0v/pdf-viewer/PDFViewer";
import { ReportingFile } from "../../../../../reporting-detail/interface/ReportingDetail.interface";

interface Props {
  portfolioId: number;
  year: number;
  quarterNum: number;
  data: ReportingFile;
  currency?: string;
}
export const FinancialStatementViewer: React.FC<Props> = (props) => {
  const { portfolioId, quarterNum, year, data, currency = "KRW" } = props;
  const WIDTH = 200;
  const [financialStatement, updateFinancialStatement] =
    useState<FinancialStatementProps>({});
  const getData = async () => {
    if (data.file?.uploadStatus.uploadStatusId === 5) {
      const financeData: FinanceDataProps = await getFinanceDataByInfo(
        portfolioId,
        year,
        quarterNum
      );
      updateFinancialStatement({
        revenue: `${financeData.revenue}`,
        operatingProfit: `${financeData.operatingProfit}`,
        netIncome: `${financeData.netIncome}`,
        totalAssets: `${financeData.totalAssets}`,
        totalEquity: `${financeData.totalEquity}`,
        totalLiabilities: `${financeData.totalLiabilities}`,
      });
    } else {
      if (!data.file?.pfReportDocumentData?.id) return;

      const res = await getSBPData(data.file?.pfReportDocumentData.id);
      if (res) {
        updateFinancialStatement(res.data);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [data]);
  return (
    <Container>
      <FinancialStatementContainer style={{ color: "var(--text-01)" }}>
        <div
          className="heading-16-sb"
          style={{ textAlign: "center", marginBottom: 24 }}
        >
          요약 재무 정보
        </div>
        <FlexRow
          style={{
            paddingLeft: 10,
            rowGap: 10,
            columnGap: 34,
            flexWrap: "wrap",
          }}
        >
          <FlexColumn style={{ width: WIDTH }}>
            <FlexRow className="heading-14-sb" style={{ alignItems: "center" }}>
              자산총계
            </FlexRow>
            <FlexRow
              className="body-14-md"
              style={{
                marginLeft: 10,
                alignItems: "center",
              }}
            >
              {currency}{" "}
              {addCommasToIntegerPart(financialStatement.totalAssets || 0)}
            </FlexRow>
          </FlexColumn>
          <FlexColumn style={{ width: WIDTH }}>
            <FlexRow className="heading-14-sb" style={{ alignItems: "center" }}>
              부채총계
            </FlexRow>
            <FlexRow
              className="body-14-md"
              style={{
                marginLeft: 10,
                alignItems: "center",
              }}
            >
              {currency}{" "}
              {addCommasToIntegerPart(financialStatement.totalLiabilities || 0)}
            </FlexRow>
          </FlexColumn>
          <FlexColumn style={{ width: WIDTH }}>
            <FlexRow className="heading-14-sb" style={{ alignItems: "center" }}>
              자본총계
            </FlexRow>
            <FlexRow
              className="body-14-md"
              style={{
                marginLeft: 10,
                alignItems: "center",
              }}
            >
              {currency}{" "}
              {addCommasToIntegerPart(financialStatement.totalEquity || 0)}
            </FlexRow>
          </FlexColumn>
          <FlexColumn style={{ width: WIDTH }}>
            <FlexRow className="heading-14-sb" style={{ alignItems: "center" }}>
              매출액
            </FlexRow>
            <FlexRow
              className="body-14-md"
              style={{
                marginLeft: 10,
                alignItems: "center",
              }}
            >
              {currency}{" "}
              {addCommasToIntegerPart(financialStatement.revenue || 0)}
            </FlexRow>
          </FlexColumn>
          <FlexColumn style={{ width: WIDTH }}>
            <FlexRow className="heading-14-sb" style={{ alignItems: "center" }}>
              영업이익(손실)
            </FlexRow>
            <FlexRow
              className="body-14-md"
              style={{
                marginLeft: 10,
                alignItems: "center",
              }}
            >
              {currency}{" "}
              {addCommasToIntegerPart(financialStatement.operatingProfit || 0)}
            </FlexRow>
          </FlexColumn>
          <FlexColumn style={{ width: WIDTH }}>
            <FlexRow className="heading-14-sb" style={{ alignItems: "center" }}>
              당기순이익(손실)
            </FlexRow>
            <FlexRow
              className="body-14-md"
              style={{
                marginLeft: 10,
                alignItems: "center",
              }}
            >
              {currency}{" "}
              {addCommasToIntegerPart(financialStatement.netIncome || 0)}
            </FlexRow>
          </FlexColumn>
        </FlexRow>
      </FinancialStatementContainer>
      <div className="viewer">
        {data?.file ? (
          <PDFViewer
            type={data.file.type}
            pdfKey={data.file.documentFile?.key || ""}
          />
        ) : (
          <div className="heading-18-md empty__container">
            <Lock />
            <div>제출된 서류가 없습니다.</div>
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  overflow: visible;

  background: var(--white);

  .viewer {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #edeeef;

    .viewer__iframe__wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .viewer__iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }

      .viewer__iframe__load__container {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .empty__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      background: #fbfcfd;
      color: #7e8086;
    }
  }

  /* Safari and Chrome */
  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }
`;

const FinancialStatementContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
