import { SelectorProps } from "../../../type/data";

export const ASC = 1;
export const DESC = 2;

export enum NotificationStatus {
  PENDING = 1, // 대기중, 미열람
  IN_PROGRESS = 2, // 진행중
  COMPLETE = 3, // 완료, 열람
  DELETE = 4, // 취소, 삭제
}

export interface NotificationGroupProps {
  id: number;
  name: string;
}

export interface NotificationCategoryProps {
  id: number;
  title: string;
  description: string;
  group: NotificationGroupProps;
}

export interface NotificationCardProps {
  id: number;
  status: NotificationStatus;
  link: string;
  title: string | null;
  description: string | null;
  createdAt: string;
  category: NotificationCategoryProps;
  portfolio: {
    portfolioId: number;
    companyName: string;
  };
}

export interface NotificationResponse {
  group: NotificationGroupProps[];
  task: NotificationCardProps[];
  notification: NotificationCardProps[];
}

export const notiOrderList: SelectorProps[] = [
  { id: DESC, text: "최신순", data: "최신순" },
  { id: ASC, text: "오래된순", data: "오래된순" },
];
