import React from "react";
import styled from "styled-components";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  isChecked?: boolean;
  onClick?: () => void;
}

interface StyleProps {
  isChecked: boolean;
}

export const IndustryLabel: React.FC<Props> = ({
  className = "caption-11-rg",
  style = {},
  isChecked = false,
  onClick,
  children,
}) => {
  return (
    <Container
      className={className}
      style={style}
      isChecked={isChecked}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

const Container = styled.div<StyleProps>`
  width: max-content;
  padding: 4px 10px;

  font-size: var(--large);
  text-align: center;
  letter-spacing: 0.1px;
  border-radius: 20px;

  cursor: pointer;

  background-color: "var(--white)";

  border: 1px solid
    ${(props) =>
      props.isChecked ? "var(--primary-purple)" : "var(--gray-500)"};
  color: ${(props) =>
    props.isChecked ? "var(--primary-purple)" : "var(--gray-500)"};
`;
