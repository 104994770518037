import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import * as api from "../../api/api";
import { FileType } from "../../type/data";
interface Props {
  hasFile: boolean;
  defaultElement: (onClick: () => void) => JSX.Element;
  uploadedElement: (
    onUpdate: () => void,
    onDelete: () => void,
    onDownload: (file: FileType) => void
  ) => JSX.Element;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  editable?: boolean;
  acceptTypes?: string[];

  onClickDefaultElement?: () => void;
  onUpdateFile?: () => void;
  onDeleteFile: () => void;
}

const FileUploaderLayout = ({
  hasFile,
  defaultElement,
  uploadedElement,
  style,
  editable,
  acceptTypes,
  onChangeFile,

  onClickDefaultElement,
  onUpdateFile,
  onDeleteFile,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (!e.target.files || e.target.files.length === 0) return;

    await onChangeFile(e);

    e.target.value = "";
  };

  const onClick = () => {
    editable && inputRef.current && inputRef.current.click();
    editable && onClickDefaultElement && onClickDefaultElement();
  };

  const onUpdate = () => {
    editable && inputRef.current && inputRef.current.click();
    editable && onUpdateFile && onUpdateFile();
  };

  const onDelete = () => {
    editable && onDeleteFile && onDeleteFile();
  };

  const onDownload = async (file: FileType) => {
    try {
      if (!hasFile) return;

      const fileUrl = (
        await axios.get(api.getFile(), { params: { key: file?.key } })
      ).data;
      console.log(fileUrl);

      let link: HTMLAnchorElement | null = document.createElement("a");
      link.href = fileUrl;
      link.download = `${file.fileName}`;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link = null;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container style={style}>
      {hasFile
        ? uploadedElement(onUpdate, onDelete, onDownload)
        : defaultElement(onClick)}
      <input
        ref={inputRef}
        type="file"
        onChange={onChange}
        accept={acceptTypes?.join(",") || "*"}
        disabled={!editable}
      />
    </Container>
  );
};

const Container = styled.div(
  () => css`
    width: 100%;
    height: 100%;

    input {
      display: none;
    }
  `
);

export default FileUploaderLayout;
