import React, { useState } from "react";
import styled from "styled-components";
import { InvestmentAssessment } from "../../../../../type/data";
import { AddComma } from "../../../../../common/commonUtil";
import ModalLayout from "../../../../../components/modal/ModalLayout";
import EvaluationCommonInfoModal from "../organisms/modal/EvaluationCommonInfoModal";
import { Label } from "../organisms/assessment/EvaluationAssessment";
import { EvaluationExportLink } from "./EvaluationExportLink";

import { ReactComponent as Info } from "../../../../../assets/image/icon_info.svg";
import { EvaluationExpertOpinion } from "./EvaluationExpertOpinion";
import { EvaluationExpertOpinionResult } from "./EvaluationExpertOpinionResult";

interface Props {
  portfolioId?: number;
  document: InvestmentAssessment;
  isUpdateAuth: boolean;
  transfromPixel: (value: number) => number;
  onChangeDocument: (item: InvestmentAssessment) => void;
  sendExportLinkEmail?: () => void;
  sendExpertEmail?: () => void;
}

export const CommonInvestInfo: React.FC<Props> = (props) => {
  const {
    portfolioId,
    document,
    isUpdateAuth,
    transfromPixel,
    onChangeDocument,
    sendExportLinkEmail,
    sendExpertEmail,
  } = props;
  const [commonInfoModal, updateCommonInfoModal] = useState<boolean>(false);
  const firstAccount = document.investmentAssessmentReportAccount[0];

  return (
    <Container>
      <CommonInvestInfoContainer transfromPixel={transfromPixel}>
        <div className="heading-18-md">이번 라운드 투자 정보</div>
        <CommonInfo
          className="body-14-rg"
          onClick={() => updateCommonInfoModal(true)}
        >
          <FlexColumn style={{ width: "100%", gap: 8 }}>
            <FlexRow style={{ width: "100%" }}>
              <InfoBox style={{ flex: 1 }}>
                <span className="info__box__title">투자 전 기업 가치</span>
                <span>
                  {AddComma(
                    firstAccount.investmentAssessmentAccount.previousValue
                  )}
                  원
                </span>
              </InfoBox>
              <InfoBox style={{ flex: 1 }}>
                <span className="info__box__title">투자 후 기업 가치</span>
                <span>
                  {AddComma(
                    firstAccount.investmentAssessmentAccount.postCompanyValue
                  )}
                  원
                </span>
              </InfoBox>
            </FlexRow>
            <FlexRow style={{ width: "100%" }}>
              <InfoBox style={{ flex: 1 }}>
                <span className="info__box__title">총 투자 금액</span>
                <span>
                  {AddComma(
                    firstAccount.investmentAssessmentAccount
                      .totalInvestmentAmount
                  )}
                  원
                </span>
              </InfoBox>
              <InfoBox style={{ flex: 1 }}>
                <span className="info__box__title">총 발행 주식 수</span>
                <span>
                  {AddComma(
                    firstAccount.investmentAssessmentAccount
                      .postTotalStockNumber
                  )}
                  주
                </span>
              </InfoBox>
            </FlexRow>
          </FlexColumn>
        </CommonInfo>
      </CommonInvestInfoContainer>
      {isUpdateAuth && document.exportLink && (
        <ExternalLinkContainer transfromPixel={transfromPixel}>
          <div
            className="heading-18-md"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span>기업 정보</span>
            {document.isSubmissionComplete !== undefined &&
              document.isSubmissionComplete && (
                <Label
                  className="caption-11-rg"
                  style={{
                    backgroundColor: "var(--blue-800)",
                    marginLeft: 10,
                  }}
                >
                  기업정보 제출완료
                </Label>
              )}
            {document.expertInfo &&
              document.expertInfo.expertSubmitComplete && (
                <Label
                  className="caption-11-rg"
                  style={{
                    backgroundColor: "var(--green-700)",
                    marginLeft: 10,
                  }}
                >
                  전문가 의견 제출완료
                </Label>
              )}
          </div>
          <DescriptionExternalLinkContainer className="caption-12-rg">
            <Info style={{ minWidth: 16, minHeight: 16 }} />
            <div>
              이메일로 기업 정보를 작성할 수 있는 링크와 비밀번호가 전송됩니다.
              기업이 제출을 완료하면 보고서에서 확인할 수 있어요.
            </div>
          </DescriptionExternalLinkContainer>
          <EvaluationExportLink
            portfolioId={portfolioId}
            exportLinkHistory={document.exportLinkHistory}
            sendAuthCodeEmail={sendExportLinkEmail}
          />
          {!document.expertInfo?.expertSubmitComplete && (
            <EvaluationExpertOpinion
              isRequestExpert={document.expertInfo?.isRequestExpert}
              expertInfo={document.expertInfo}
              cancelRequest={() => {
                if (!document.expertInfo) return;

                document.expertInfo.isRequestExpert = false;
                onChangeDocument(document);
              }}
              submit={(isRequestExpert, title, email) => {
                if (document.expertInfo) {
                  document.expertInfo.isRequestExpert = isRequestExpert;
                  document.expertInfo.expertTitle = title;
                  document.expertInfo.expertEmail = email;
                  document.expertInfo.exportLink = document.exportLink || "";
                } else {
                  document.expertInfo = {
                    isRequestExpert: isRequestExpert,
                    expertEmail: email,
                    expertTitle: title,
                    exportLink: document.exportLink,
                  };
                }

                onChangeDocument(document);
              }}
              sendExpertEmail={sendExpertEmail}
            />
          )}
          {document.expertInfo && document.expertInfo.expertSubmitComplete && (
            <EvaluationExpertOpinionResult expertInfo={document.expertInfo} />
          )}
        </ExternalLinkContainer>
      )}

      {commonInfoModal && (
        <ModalLayout
          isOpen={commonInfoModal}
          isFullScreen
          toggle={() => {
            updateCommonInfoModal(false);
          }}
        >
          <EvaluationCommonInfoModal
            document={document}
            isUpdateAuth={isUpdateAuth}
            transfromPixel={transfromPixel}
            close={() => {
              updateCommonInfoModal(false);
            }}
            onChangeDocument={(data) => {
              onChangeDocument(data);
              updateCommonInfoModal(false);
            }}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: var(--background-contents01);
  border-radius: 4px;
  border: 1px solid var(--gray-300);

  overflow: hidden;
`;

const CommonInvestInfoContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  padding: ${(props) => props.transfromPixel(24)}px
    ${(props) => props.transfromPixel(28)}px;

  display: flex;
  flex-direction: column;

  gap: 16px;
`;

const CommonInfo = styled.div`
  width: 100%;
  height: max-content;
  min-height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  padding: 12px;

  border-radius: 4px;
  border: 1px solid var(--gray-200);

  transition: all 0.2s ease;
`;

const InfoBox = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;

  color: var(--text-01);

  .info__box__title {
    color: var(--text-05);
  }
`;

const ExternalLinkContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  padding: ${(props) => props.transfromPixel(24)}px
    ${(props) => props.transfromPixel(28)}px;

  display: flex;
  flex-direction: column;

  gap: 8px;

  border-top: 1px solid var(--gray-300);
`;

const DescriptionExternalLinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  padding: 8px;
  gap: 4px;

  margin-top: 8px;

  background-color: var(--gray-50);
  color: var(--text-04);
  border-radius: 4px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
