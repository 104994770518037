import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as File } from "../../../../../assets/image/large_file.svg";
import { ReactComponent as Del } from "../../../../../assets/image/chip_delete.svg";
import { ExpertFileProps } from "../../../../../type/data";
import { saveAs } from "file-saver";
import { onDownload } from "../../../../../common/commonUtil";

interface Props {
  file: ExpertFileProps;
}

export const ExpertFileCard: React.FC<Props> = (props) => {
  const { file } = props;
  const [fileName, setFileName] = useState<string>("");

  const onDownloadReportFile = () => {
    if (file.key === "localFile") {
      file.localFile && saveAs(file.localFile);
    } else {
      file.serverFile &&
        onDownload(file.serverFile.key, file.serverFile.fileName);
    }
  };

  useEffect(() => {
    file.key === "localFile"
      ? setFileName(file.localFile?.name || "untitled")
      : setFileName(file.serverFile?.fileName || "untitled");
  }, [file]);

  return (
    <Container>
      <FlexRow>
        <FileContainer className={`body-14-rg`} onClick={onDownloadReportFile}>
          <File className="file__icon" width={24} height={24} />
          <div className="title">{fileName}</div>
        </FileContainer>
      </FlexRow>
    </Container>
  );
};

const Container = styled.div(
  () => css`
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: column;
  `
);

const FileContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 40px;
  max-height: 40px;
  gap: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border: 1px solid var(--gray-300);
  border-radius: 4px;

  transition: all 0.2s ease;

  padding: 8px 12px;
  cursor: pointer;

  background-color: var(--white);

  &.disabled {
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }

  .file__icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
  }
  .title {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
