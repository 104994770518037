import { PropertyDescriptorParsingType } from "html2canvas/dist/types/css/IPropertyDescriptor";
import React, { useEffect, useRef, useState } from "react";

import styled, { css } from "styled-components";
import {
  DatabaseHistory,
  DBAcceleratingPortfolio,
  DPBPAccelerating,
  DPBPTotalSearch,
} from "../../../../../../type/data";
import AcceleratingDatabaseGrid from "./AcceleratingDatabaseGrid";
import DBResultListFunctionRow from "../../DBResultListFunctionRow";
import AcceleratingModal from "./AcceleratingModal";
import TotalSearchModal from "../total-search/TotalSearchModal";
import { getDatabaseAccelerating } from "../../../../../../api/repository/database/DatabaseRepository";

import DownloadBluecardBtn from "../../../organisms/DownloadBluecardBtn";

import FilterAccelerating, {
  InputSearchValueType,
  IsAllSelectedType,
  SearchValueType,
} from "../../../organisms/FilterAccelerating";
import DownloadInvestmentHistoryBtn from "../../../organisms/DownloadInvestmentHistoryBtn";
import { setDatabaseHistory } from "../../../../../../common/pageHistoryUtil";
import CreateBluecardLinkBtn from "../../../organisms/CreateBluecardLinkBtn";
import AcceleratingExcelDownload from "../../AcceleratingExcelDownload";
import useAcceleratingSearch from "./useAcceleratingSearch";

import { ReactComponent as List } from "../../../../../../assets/image/new_icon_list.svg";
import { ReactComponent as Close } from "../../../../../../assets/image/new_close_button.svg";
import { ReactComponent as CheckT } from "../../../../../../assets/image/new_check_box_true.svg";
import DatabaseInput from "../../DatabaseInput";
interface Props {
  history?: DatabaseHistory;
  isShowFilter: boolean;
}

/**
 * Accelerating 탭 화면
 *
 * 구성
 *  - Accelerating 검색 조건 컴포넌트
 *  - 결과 Grid
 */
const AcceleratingTab: React.FC<Props> = (props) => {
  const [
    countPerPage,
    round,
    addrList,
    investmentYears,
    currentPortfolio,
    isAllSelectedObject,
    industrialTechs,
    openPortfolioModal,
    participants,
    resultPortfolios,
    searchObject,
    totalCount,
    totalPage,
    selectedData,
    inputChipObject,
    fundAccountList,
    showSelectedDataList,
    updateShowSelectedDataList,
    setInputChipObject,
    updateSelectedData,
    onPortfolioModal,
    setSearchObject,
    updatePortfolioModal,
    getBodyBySearchObject,
  ] = useAcceleratingSearch();
  const [keyword, setKeyword] = useState<string>(
    props.history?.search?.keyword || ""
  );

  if (!searchObject) return null;
  return (
    <Container>
      <ResultContainer
        style={{
          paddingRight: props.isShowFilter ? 0 : 60,
          paddingTop: 24,
          gap: 8,
        }}
      >
        <DBResultListFunctionRow
          totalCount={totalCount}
          countPerPage={countPerPage}
          curPage={searchObject.curPage || 1}
          totalPage={totalPage}
          resultPortfolioListCount={
            resultPortfolios ? resultPortfolios.portfolio.length : 0
          }
          customFunctions={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
              }}
            >
              <FlexRow style={{ width: 600, alignItems: "center" }}>
                <span className="body-14-sb" style={{ width: 60 }}>
                  검색어
                </span>
                <DatabaseInput
                  type="search"
                  placeholder="기업명 또는 관련 키워드를 2글자 이상 입력해 주세요."
                  onClickSearch={(value) => {
                    if (typeof value === "string") {
                      setSearchObject({
                        ...searchObject,
                        keyword: keyword,
                        curPage: 1,
                      });
                    }
                  }}
                  onChangeValue={(value) => {
                    if (typeof value === "string") {
                      setKeyword(value);
                    }
                  }}
                  value={keyword || ""}
                />
              </FlexRow>

              <AcceleratingExcelDownload
                getBodyBySearchObject={getBodyBySearchObject}
                totalCount={totalCount}
              />
            </div>
          }
          updateCurPage={(updatedPage: number) => {
            setSearchObject((prev) => ({
              ...prev,
              curPage: updatedPage,
            }));
          }}
          searchKeyword={searchObject.keyword}
        />
        {resultPortfolios && (
          <AcceleratingDatabaseGrid
            resultList={resultPortfolios}
            selectedData={selectedData}
            onPortfolioModal={onPortfolioModal}
            updateSelectedData={updateSelectedData}
            searchObject={searchObject}
            sortByKey={(sortItem: string) => {
              console.log(sortItem);

              setSearchObject((prev) => {
                if (!prev) return;
                if (prev.sortItem === sortItem) {
                  let _sortItem: string | undefined = prev.sortItem;
                  let _sortType: "asc" | "desc" | undefined = undefined;
                  switch (prev.sortType) {
                    case "desc":
                      _sortType = "asc";
                      break;
                    case "asc":
                      _sortType = undefined;
                      _sortItem = undefined;
                      break;
                    default:
                      _sortType = "desc";
                      break;
                  }
                  return {
                    ...prev,
                    sortItem: _sortItem,
                    sortType: _sortType,
                    curPage: 1,
                  };
                } else {
                  return {
                    ...prev,
                    sortItem: sortItem,
                    sortType: "desc",
                    curPage: 1,
                  };
                }
              });
            }}
          />
        )}
      </ResultContainer>
      <FilterContainer className={props.isShowFilter ? "show__filter" : ""}>
        <FilterAccelerating
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          inputChipObject={inputChipObject}
          setInputChipObject={setInputChipObject}
          isAllSelectedObject={isAllSelectedObject}
          industrialTechs={industrialTechs}
          participants={participants}
          round={round}
          investmentYears={investmentYears}
          addrList={addrList}
          fundAccountList={fundAccountList}
        />
      </FilterContainer>
      {resultPortfolios && currentPortfolio.current && openPortfolioModal && (
        <AcceleratingModal
          isOpen={openPortfolioModal}
          close={() => {
            updatePortfolioModal(false);
          }}
          portfolioInfo={currentPortfolio.current}
        />
      )}

      <CheckedPortfolioControlContainerWrapper
        className={selectedData.length > 0 ? "show" : ""}
        transfromPixel={(n) => n}
      >
        <CheckedPortfolioControlContainer>
          <div
            className="checked__portfolio__control__btn"
            onClick={() => {
              updateShowSelectedDataList((prev) => !prev);
            }}
          >
            <List />
            {selectedData.length > 0 && showSelectedDataList && (
              <CheckedPortfolioListContainer
                transfromPixel={(n) => n}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="checked__portfolio__list__header">
                  <div
                    className="close__btn"
                    onClick={() => {
                      updateShowSelectedDataList((prev) => !prev);
                    }}
                  >
                    <Close />
                  </div>
                </div>
                <div className="checked__portfolio__list">
                  {selectedData.map((item, index) => {
                    return (
                      <div
                        className="checked__portfolio__list__item"
                        onClick={() => {
                          const checkedIndex = selectedData.findIndex(
                            (data) =>
                              data.identificationNumber ===
                              item.identificationNumber
                          );
                          if (checkedIndex !== -1) {
                            selectedData.splice(checkedIndex, 1);
                          } else {
                            selectedData.push(item);
                          }
                          updateSelectedData([...selectedData]);
                        }}
                      >
                        <CheckT />
                        <div>{item.data.companyName}</div>
                      </div>
                    );
                  })}
                </div>
              </CheckedPortfolioListContainer>
            )}
          </div>
          <CreateBluecardLinkBtn
            portfolioIds={selectedData.map((item) => item.identificationNumber)}
          >
            <div
              className="heading-16-md checked__portfolio__open__btn"
              onClick={() => {}}
            >
              블루카드 ({selectedData.length})
            </div>
          </CreateBluecardLinkBtn>
          <div
            className="checked__portfolio__control__btn"
            onClick={() => {
              updateSelectedData([]);
            }}
          >
            <Close />
          </div>
        </CheckedPortfolioControlContainer>
      </CheckedPortfolioControlContainerWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const ResultContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  gap: 4px;

  .function__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .function__row__group {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .per__page__item {
    cursor: pointer;
    color: #828282;
  }

  .per__page__item__selected {
    cursor: pointer;
    font-weight: 600;
    color: var(--primary);
  }

  .divider {
    width: 1px;
    height: 12px;
    background-color: #c4c4c4;
  }
`;

const FilterContainer = styled.div`
  width: 0px;
  height: 100%;
  transition: all 0.3s ease;
  border-left: 1px solid #f3f4f4;
  overflow: hidden;
  &.show__filter {
    width: 700px;
    transition: all 0.3s ease;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckedPortfolioControlContainerWrapper = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;
    width: fit-content;
    height: ${transfromPixel(44)}px;

    transition: all 0.2s ease;
    left: 50%;
    bottom: -${transfromPixel(50)}px;
    transform: translateX(-50%);
    &.show {
      bottom: ${transfromPixel(30)}px;
    }
  `
);
const CheckedPortfolioControlContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .checked__portfolio__control__btn {
    position: relative;
    width: 56px;
    min-width: 56px;
    max-width: 56px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #d5d6d8;
    box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
    border-radius: 4px;

    transition: all 0.2s ease;

    cursor: pointer;

    :hover {
      background-color: #f3f4f4;
    }
  }

  .checked__portfolio__open__btn {
    width: 254px;
    min-width: 254px;
    max-width: 254px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #173aff;
    box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
    border-radius: 4px;
    color: #ffffff;
    transition: all 0.2s ease;
    cursor: pointer;

    :hover {
      background-color: #112cbf;
    }
  }
`;

const CheckedPortfolioListContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;

    left: -${transfromPixel(163)}px;
    bottom: calc(100% + ${transfromPixel(24)}px);
    width: ${transfromPixel(382)}px;
    height: max-content;
    max-height: ${transfromPixel(520)}px;
    background-color: #ffffff;
    filter: drop-shadow(2px 2px 10px rgba(100, 103, 109, 0.3));
    border-radius: ${transfromPixel(8)}px;

    padding-bottom: ${transfromPixel(20)}px;

    ::after {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 50%;
      width: 0;
      height: 0;
      border: ${transfromPixel(14)}px solid transparent;
      border-top-color: #ffffff;
      border-bottom: 0;
      margin-left: -${transfromPixel(14)}px;
      margin-bottom: -${transfromPixel(14)}px;
    }

    .checked__portfolio__list__header {
      width: 100%;
      height: ${transfromPixel(44)}px;
      min-height: ${transfromPixel(44)}px;
      max-height: ${transfromPixel(44)}px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      padding-right: ${transfromPixel(12)}px;

      .close__btn {
        width: ${transfromPixel(32)}px;
        min-width: ${transfromPixel(32)}px;
        max-width: ${transfromPixel(32)}px;
        height: ${transfromPixel(32)}px;
        min-height: ${transfromPixel(32)}px;
        max-height: ${transfromPixel(32)}px;
        transition: all 0.2s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        :hover {
          background: #f3f4f4;
          border-radius: ${transfromPixel(4)}px;
        }
      }
    }
    .checked__portfolio__list {
      width: 100%;
      height: calc(${transfromPixel(520)}px - ${transfromPixel(64)}px);
      min-height: calc(${transfromPixel(520)}px - ${transfromPixel(64)}px);
      max-height: calc(${transfromPixel(520)}px - ${transfromPixel(64)}px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 ${transfromPixel(22)}px;
      overflow: scroll;
    }

    .checked__portfolio__list__item {
      width: 100%;
      height: ${transfromPixel(50)}px;
      min-height: ${transfromPixel(50)}px;
      max-height: ${transfromPixel(50)}px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #edeeef;
      gap: ${transfromPixel(40)}px;
      padding: 0 ${transfromPixel(20)}px;

      cursor: pointer;
    }
  `
);
export default AcceleratingTab;
