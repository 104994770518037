import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { getYMD } from "../../../../../../common/commonUtil";
import { DBAlumniPortfolio, DPBPAlumni } from "../../../../../../type/data";
import { SearchValueType } from "../../../organisms/FilterAlumni";

import DatabaseGrid, { DatabaseGridHeaderProps } from "../../grid/DatabaseGrid";
import PortfolioParticipant from "../../PortfolioParticipant";
import { TableVirtuoso, VirtuosoHandle } from "react-virtuoso";
import useResize from "../../../../../../2.0lattice/useResize";
import { TableGridHeaderProps } from "../../table/TableGrid";
import TableRow from "../../table/TableRow";
import TableHeader from "../../table/TableHeader";

interface Props {
  resultList: DPBPAlumni;
  searchObject?: SearchValueType;
  onPortfolioModal: (portfolio: DBAlumniPortfolio) => void;
  sortByKey: (sortItem: string) => void;
}

const AlumniDatabaseGrid: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const virtuoso = useRef<VirtuosoHandle>(null);
  const [screenRate, transfromPixel] = useResize();

  const header: TableGridHeaderProps[] = [
    {
      isRequired: true,
      header: "기업명",
      key: "companyName",
      width: 240,
      customCell: (data) => {
        if (!data) return <span style={{ textAlign: "center" }}>-</span>;
        return <span style={{ paddingLeft: 20 }}>{data}</span>;
      },
    },
    {
      isRequired: true,
      header: "투자계정",
      key: "acFundAccount",
      width: 200,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              paddingLeft: 20,
            }}
          >
            {data
              .map(
                (item: any) => `${item.nickName ? item.nickName : item.nameKo}`
              )
              .join(", ")}
          </span>
        );
      },
    },
    {
      isRequired: true,
      header: "빈티지",
      key: "investmentDate",
      width: 80,
      sortable: true,
      sortType:
        props.searchObject && props.searchObject.sortItem === "investmentYear"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return <span style={{ textAlign: "center" }}>{data.slice(0, 4)}</span>;
      },
      onCellClicked: () => {
        props.sortByKey("investmentYear");
      },
    },
    {
      isRequired: true,
      header: "투자금",
      key: "purchasePrincipal",
      width: 100,
      sortable: true,
      sortType:
        props.searchObject &&
        props.searchObject.sortItem === "purchasePrincipal"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span style={{ textAlign: "end", paddingLeft: 20 }}>
            {(data / 100000000).toFixed(1)}억원
          </span>
        );
      },
      onCellClicked: () => {
        props.sortByKey("purchasePrincipal");
      },
    },
    {
      isRequired: true,
      header: "회수금",
      key: "sellPrincipal",
      width: 100,
      sortable: true,
      sortType:
        props.searchObject && props.searchObject.sortItem === "sellPrincipal"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span style={{ textAlign: "end" }}>-</span>;
        return (
          <span style={{ textAlign: "end" }}>
            {(data / 100000000).toFixed(1)}억원
          </span>
        );
      },
      onCellClicked: () => {
        props.sortByKey("sellPrincipal");
      },
    },
    {
      isRequired: true,
      header: "실현 멀티플",
      key: "realizedMultiple",
      width: 140,
      sortable: true,
      sortType:
        props.searchObject && props.searchObject.sortItem === "realizedMultiple"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span style={{ textAlign: "end" }}>-</span>;
        return <span style={{ textAlign: "end" }}>{data.toFixed(2)}X</span>;
      },
      onCellClicked: () => {
        props.sortByKey("realizedMultiple");
      },
    },
    {
      isRequired: true,
      header: "엑싯 날짜",
      key: "exitDate",
      width: 150,
      sortable: true,
      sortType:
        props.searchObject && props.searchObject.sortItem === "exitDate"
          ? props.searchObject.sortType
          : undefined,
      customCell: (data) => {
        if (!data) return <span style={{ textAlign: "center" }}>-</span>;
        return <span style={{ textAlign: "center" }}>{getYMD(data)}</span>;
      },
      onCellClicked: () => {
        props.sortByKey("exitDate");
      },
    },
    {
      isRequired: true,
      header: "담당자(조력자)",
      key: "pfParticipant",
      width: 240,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return <PortfolioParticipant participants={data} />;
      },
    },
    {
      isRequired: false,
      isChecked: true,
      header: "산업기술분류",
      key: "pfIndustrialTech",
      width: 300,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span>
            {data
              .map((item: any) => `#${item.acIndustrialTech.name}`)
              .join(", ")}
          </span>
        );
      },
    },
    {
      isRequired: false,
      isChecked: true,
      header: "매출",
      key: "salesAmount",
      width: 100,
      customCell: (data) => {
        if (!data) return <span>-</span>;
        return (
          <span style={{ textAlign: "end" }}>
            {(data / 100000000).toFixed(1)}억원
          </span>
        );
      },
    },

    {
      isRequired: false,
      isChecked: true,
      header: "아이템 요약",
      key: "description",
      width: 350,
      customCell: (data) => {
        if (!data) return <span style={{ paddingLeft: 20 }}>-</span>;
        return (
          <span
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
              paddingLeft: 20,
            }}
          >
            {data}
          </span>
        );
      },
    },
    {
      isRequired: false,
      isChecked: true,
      header: "소재지",
      key: "addr1",
      width: 80,
      customCell: (data) => {
        if (!data) return <span style={{ paddingLeft: 20 }}>-</span>;
        return <span style={{ paddingLeft: 20 }}>{data}</span>;
      },
    },
    {
      isRequired: false,
      isChecked: true,
      header: "라운드",
      key: "round",
      width: 100,
      customCell: (data) => {
        if (!data) return <span style={{ paddingLeft: 20 }}>-</span>;
        return <span style={{ paddingLeft: 20 }}>{data.name}</span>;
      },
    },
  ];

  return (
    <Container className="scroll__invisible">
      <TableVirtuoso
        ref={virtuoso}
        className={`on-scrollbar`}
        height={"100%"}
        onScroll={(e) => {
          console.log("scrolling 중~~~");
        }}
        data={props.resultList.portfolio.map((item, index) => {
          return {
            id: index,
            identificationNumber: item.portfolioId || 0,
            data: item,
          };
        })}
        fixedHeaderContent={() => {
          return (
            <TableHeader headers={header} transfromPixel={transfromPixel} />
          );
        }}
        itemContent={(index, data) => {
          return (
            <TableRow
              key={index}
              headerData={header}
              rowData={data}
              transfromPixel={transfromPixel}
              onClickRow={() => {
                console.log(data);
                props.onPortfolioModal(data.data);
              }}
            />
          );
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export default AlumniDatabaseGrid;
