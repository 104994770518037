import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Tooltip } from "react-tooltip";
import {
  CapitalAcquisitionPlan,
  SBPContentsProps,
} from "../../interface/SBP.interface";
import { SBPSector } from "../SBPSector";

import { AutoResizableTextarea } from "../AutoResizableTextarea";
import { SBPSectorDescription } from "../SBPSectorDescription";

import { SBPSectorFooter } from "../SBPSectorFooter";
import { CapitalAcquisitionPlanComponent } from "./organism/CapitalAcquisitionPlanComponent";

import { ReactComponent as Check } from "../../../../../../../assets/image/icon_check.svg";
import "react-tooltip/dist/react-tooltip.css";
import {
  AddComma,
  RemoveComma,
} from "../../../../../../../../common/commonUtil";
import RowTitleBox from "../RowTitleBox";
import InputBox from "../InputBox";

interface Props {
  isReadOnly: boolean;
  data?: SBPContentsProps;
  onChangeData?: (data: SBPContentsProps) => void;
}

export const SBPPlan: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;

  const [totalCapitalAmount, updateTotalCapitalAmount] = useState<number>(0);
  const [totalFinalCash, updateTotalFinalCash] = useState<number>(0);
  const [noEventCapital, updateNoEventCapital] = useState<boolean>(false);

  const getYear = (year?: number) => {
    if (!year) return "YYYY";
    return `${year}`;
  };

  const getMonthRange = (quater?: number) => {
    if (!quater) return "Error";
    return quater === 2 ? "7 - 12" : "1 - 12";
  };

  const getMonth = (quater?: number) => {
    if (!quater) return "Error";
    return quater === 2 ? "6" : "12";
  };

  const calcuatingFinalCash = (valueData: SBPContentsProps) => {
    const a = valueData.businessPlan.estimatedCashFlow.govBalance || 0;
    const b = valueData.businessPlan.estimatedCashFlow.cashOnHand || 0;
    const c = valueData.businessPlan.estimatedCashFlow.expectedCashInflow || 0;
    const d = valueData.businessPlan.capitalAcquisitionPlan
      .map((item) => item.capitalAmount || 0)
      .reduce((prev, cur) => prev + cur, 0);
    const e =
      valueData.businessPlan.estimatedCashFlow.estimatedGovFundingUsage || 0;
    const f =
      valueData.businessPlan.estimatedCashFlow.totalExpectedExpense || 0;
    updateTotalFinalCash(a + b + c + d - e - f);
  };
  useEffect(() => {
    if (data) {
      updateNoEventCapital(data.businessPlan.noEventCapitalAcquisition);
      updateTotalCapitalAmount(
        data.businessPlan.capitalAcquisitionPlan
          .map((item) => item.capitalAmount || 0)
          .reduce((prev, cur) => prev + cur, 0)
      );
      calcuatingFinalCash(data);
    }
  }, [data]);

  if (!data) return null;

  return (
    <Container>
      <SBPSector title="사업계획" id="plan1">
        <FlexColumn style={{ gap: 8 }}>
          {!props.isReadOnly && (
            <SBPSectorDescription
              text={`${getYear(data?.year)}년 ${getMonthRange(
                data?.quarter
              )}월에 해당하는 내용을 작성해 주세요.`}
            />
          )}
          <FlexColumn
            style={{
              padding: 28,
              borderRadius: 4,
              gap: 24,
              backgroundColor: "var(--background-contents01)",
              border: "1px solid var(--gray-300)",
            }}
          >
            <RowTitleBox
              title="솔루션, 개발관점"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                defaultData={data.businessPlan.solutionAndDevlopPoint}
                onChangeData={(value) => {
                  if (onChangeData) {
                    data.businessPlan.solutionAndDevlopPoint = value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>
            <RowTitleBox
              title="사업관점"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                defaultData={data.businessPlan.businessPoint}
                onChangeData={(value) => {
                  if (onChangeData) {
                    data.businessPlan.businessPoint = value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>
          </FlexColumn>
        </FlexColumn>
      </SBPSector>

      <SBPSector title="자금 조달 계획" id="plan2">
        <FlexColumn style={{ gap: 8 }}>
          {!props.isReadOnly && (
            <SBPSectorDescription
              text={`${getYear(data?.year)}년 ${getMonthRange(
                data?.quarter
              )}월에 해당하는 내용을 작성해 주세요.`}
            />
          )}
          <CapitalAcquisitionPlanComponent
            isReadOnly={props.isReadOnly}
            disabled={noEventCapital}
            quarter={data.quarter}
            capitalDataList={data.businessPlan.capitalAcquisitionPlan}
            onDataChange={(newCapitalData) => {
              if (onChangeData) {
                const newData: SBPContentsProps = {
                  ...data,
                  businessPlan: {
                    ...data.businessPlan,
                    capitalAcquisitionPlan: newCapitalData,
                  },
                };

                updateTotalCapitalAmount(
                  newData.businessPlan.capitalAcquisitionPlan
                    .map((item) => item.capitalAmount || 0)
                    .reduce((prev, cur) => prev + cur, 0)
                );
                calcuatingFinalCash(newData);
                onChangeData(newData);
              }
            }}
          />
          <SBPSectorFooter>
            <FlexRow
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {props.isReadOnly ? (
                <div />
              ) : (
                <FlexRow
                  style={{
                    gap: 12,
                    marginTop: 4,
                    paddingLeft: 20,
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    updateNoEventCapital(!noEventCapital);
                    if (onChangeData) {
                      data.businessPlan.noEventCapitalAcquisition =
                        !noEventCapital;
                      onChangeData(data);
                    }
                  }}
                >
                  <CheckWrapper>
                    <Check
                      className={`check__box ${
                        noEventCapital ? "checked" : ""
                      }`}
                    />
                  </CheckWrapper>
                  <span
                    className="body-14-rg"
                    style={{ color: "var(--text-03)" }}
                  >
                    자금 조달 계획 내역이 없어요.
                  </span>
                </FlexRow>
              )}
              <FlexRow style={{ alignItems: "center", gap: 12, marginTop: 4 }}>
                <span
                  className="body-16-md"
                  style={{ color: "var(--text-04)" }}
                >
                  총 예상 조달 금액
                </span>
                <span
                  className="heading-16-md"
                  style={{ color: "var(--text-01)" }}
                >
                  {noEventCapital
                    ? "해당없음"
                    : `${AddComma(totalCapitalAmount)} 원`}
                </span>
              </FlexRow>
            </FlexRow>
          </SBPSectorFooter>
        </FlexColumn>
      </SBPSector>

      <SBPSector title="현금 흐름 추정" id="plan3">
        <FlexColumn style={{ gap: 8 }}>
          {!props.isReadOnly && (
            <SBPSectorDescription
              text={`${getYear(data.year)}년 ${getMonthRange(
                data?.quarter
              )}월에 해당하는 내용을 작성해 주세요.`}
            />
          )}
          <FlexColumn
            style={{
              padding: 28,
              borderRadius: 4,
              gap: 24,
              backgroundColor: "var(--background-contents01)",
              border: "1px solid var(--gray-300)",
            }}
          >
            <RowTitleBox
              textClassName="heading-14-md"
              title={`${getYear(
                data.quarter === 2 ? data.year : data.year - 1
              )}년 ${getMonth(data.quarter)}월말 정부지원금 잔액`}
              titleWidth={208}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="numberWithComma"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                unit="원"
                width={250}
                placeholder="0"
                defaultValue={
                  data.businessPlan.estimatedCashFlow.govBalance !== undefined
                    ? `${data.businessPlan.estimatedCashFlow.govBalance}`
                    : undefined
                }
              />
            </RowTitleBox>

            <RowTitleBox
              textClassName="heading-14-md"
              title={`${getYear(
                data.quarter === 2 ? data.year : data.year - 1
              )}년 ${getMonth(data.quarter)}월말 법인자금 잔액`}
              titleWidth={208}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="numberWithComma"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                unit="원"
                width={250}
                placeholder="0"
                defaultValue={
                  data.businessPlan.estimatedCashFlow.cashOnHand !== undefined
                    ? `${data.businessPlan.estimatedCashFlow.cashOnHand}`
                    : undefined
                }
              />
            </RowTitleBox>

            <RowTitleBox
              textClassName="heading-14-md"
              title="예상 매출액"
              titleWidth={208}
              transfromPixel={(n) => n}
              tooltipId="expectedCashInflow"
            >
              <Tooltip
                className="body-14-rg"
                anchorSelect=".expectedCashInflow"
                place="top"
                border={0}
                style={{ zIndex: 99999999, borderRadius: 4 }}
              >
                영업활동으로 인한 예상 현금 유입액을 작성해 주세요.
              </Tooltip>
              <InputBox
                textType="numberWithComma"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                unit="원"
                width={250}
                placeholder="0"
                defaultValue={
                  data.businessPlan.estimatedCashFlow.expectedCashInflow !==
                  undefined
                    ? `${data.businessPlan.estimatedCashFlow.expectedCashInflow}`
                    : undefined
                }
              />
            </RowTitleBox>

            <RowTitleBox
              textClassName="heading-14-md"
              title="예상 매출액 산출 근거"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                placeholder="제품 런칭 예정, 계약 협의 중, 양산 및 출시 등 매출 관련 근거가 되는 내용을 작성해 주세요."
                defaultData={
                  data.businessPlan.estimatedCashFlow.reasonExpectedCashInflow
                }
              />
            </RowTitleBox>

            <RowTitleBox
              textClassName="heading-14-md"
              title="예상 자금 조달 총액"
              titleWidth={208}
              transfromPixel={(n) => n}
            >
              <InputBox
                readOnly
                textType="numberWithComma"
                transfromPixel={(n) => n}
                unit="원"
                width={250}
                placeholder="0"
                textValue={AddComma(totalCapitalAmount)}
              />
            </RowTitleBox>

            <RowTitleBox
              textClassName="heading-14-md"
              title="정부지원금 지출 예정액"
              titleWidth={208}
              transfromPixel={(n) => n}
              tooltipId="estimatedGovFundingUsage"
            >
              <Tooltip
                className="body-14-rg"
                anchorSelect=".estimatedGovFundingUsage"
                place="top"
                border={0}
                style={{ zIndex: 99999999, lineHeight: 1.5, borderRadius: 4 }}
              >
                정부지원금을 활용할 인건비, 장비비 등의 지출 예정액을 작성해
                주세요.
              </Tooltip>
              <InputBox
                textType="numberWithComma"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                unit="원"
                width={250}
                placeholder="0"
                defaultValue={
                  data.businessPlan.estimatedCashFlow
                    .estimatedGovFundingUsage !== undefined
                    ? `${data.businessPlan.estimatedCashFlow.estimatedGovFundingUsage}`
                    : undefined
                }
              />
            </RowTitleBox>

            <RowTitleBox
              textClassName="heading-14-md"
              title="법인자금 지출 예정액"
              titleWidth={208}
              transfromPixel={(n) => n}
              tooltipId="totalExpectedExpense"
            >
              <Tooltip
                className="body-14-rg"
                anchorSelect=".totalExpectedExpense"
                place="top"
                border={0}
                style={{ zIndex: 99999999, lineHeight: 1.5, borderRadius: 4 }}
              >
                인건비, 운영비, 연구비, 광고비, 재료비, 장비비, 시설비, 건축비,
                <br />
                차입 이자 등의 지출 예정액을 작성해 주세요.
              </Tooltip>
              <InputBox
                textType="numberWithComma"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                unit="원"
                width={250}
                placeholder="0"
                defaultValue={
                  data.businessPlan.estimatedCashFlow.totalExpectedExpense !==
                  undefined
                    ? `${data.businessPlan.estimatedCashFlow.totalExpectedExpense}`
                    : undefined
                }
              />
            </RowTitleBox>

            <RowTitleBox
              textClassName="heading-14-md"
              title="예상 매출 관련 이슈사항"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                placeholder="입력한 내용이 없습니다."
                defaultData={data.businessPlan.estimatedCashFlow.salesIssue}
              />
            </RowTitleBox>
          </FlexColumn>
          <SBPSectorFooter>
            <FlexRow style={{ alignItems: "center", gap: 12, marginTop: 4 }}>
              <span className="body-16-md" style={{ color: "var(--text-04)" }}>
                예상 기말 현금
              </span>
              <span
                className="heading-16-md"
                style={{ color: "var(--text-01)" }}
              >
                {AddComma(totalFinalCash)} 원
              </span>
            </FlexRow>
          </SBPSectorFooter>
        </FlexColumn>
      </SBPSector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--gray-700);
        stroke: var(--gray-700);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--gray-700);
        }
      }
    }
  }
`;
