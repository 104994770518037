import axios from "axios";
import * as api from "../../api";
import { NotificationResponse } from "../../../router/notification/interface/Notification.interface";

export const getAllNotification = async (): Promise<
  NotificationResponse | undefined
> => {
  const result = await axios.get(api.getNotifications());
  if (result.status !== 200) return new Promise((reject) => reject(undefined));

  return new Promise((resolve) => resolve(result.data.result));
};

export const readedNotification = async (
  id: number
): Promise<boolean | undefined> => {
  const result = await axios.patch(api.readNotificationById(id));
  if (result.status !== 200) return new Promise((reject) => reject(undefined));

  return new Promise((resolve) => resolve(true));
};

export const deleteNotification = async (
  id: number
): Promise<boolean | undefined> => {
  const result = await axios.patch(api.deleteNotiById(id));
  if (result.status !== 200) return new Promise((reject) => reject(undefined));

  return new Promise((resolve) => resolve(true));
};

export const deleteAllreadedNotification = async (): Promise<
  boolean | undefined
> => {
  const result = await axios.patch(api.deleteAllReadedNoti());
  if (result.status !== 200) return new Promise((reject) => reject(undefined));

  return new Promise((resolve) => resolve(true));
};
