import React, { RefObject, useEffect, useRef, useState } from "react";
import Styled, { css } from "styled-components";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { ResultType } from "../../../interface/PrePitchingDetail.interface";

interface Props {
  item: ResultType;
}
const ResultSingle: React.FC<Props> = (props) => {
  const getData = () => {
    const column = props.item.answer?.map((item: any) => item.label);
    const _data = props.item.answer?.map(
      (item: any, index: number): number => item.value
    );
    if (props.item.hasOtherOpinion) {
      props.item.otherOpinion?.forEach((item) => {
        if (item.otherOpinion.isSelected) {
          column?.push(item.otherOpinion.opinion);
          _data?.push(1);
        }
      });
    }

    setLabel(column || []);
    setData(_data || []);
  };
  const [data, setData] = useState<number[]>([]);
  const [label, setLabel] = useState<string[]>([]);
  const color = [
    "#55ACFD",
    "#61DFAE",
    "#FFDF6B",
    "#FF9F5A",
    "#8E7DFA",
    "#CAD0F2",
    "#173AFF",
    "#D5D6D8",
    "#BC682A",
  ];
  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    getData();
  }, [props.item]);

  return (
    <Container className="body-14-rg ">
      {/* {props.item.answer && <span>응답 {props.item.answer.length}개</span>} */}
      <PieGraphContainer>
        <Pie
          width={150}
          height={150}
          data={{
            labels: label,
            datasets: [
              {
                data: data,
                backgroundColor: color,
              },
            ],
          }}
          options={{
            responsive: false,
            plugins: {
              legend: {
                display: false,
                position: "right",
              },
            },
          }}
        />
        <AnswerList>
          {label.map((item, index) => {
            return (
              <AnswerItem className="caption-10-rg" key={index}>
                <div
                  className="answer__circle"
                  style={{ backgroundColor: color[index] }}
                />
                {item}
              </AnswerItem>
            );
          })}
        </AnswerList>
      </PieGraphContainer>
    </Container>
  );
};

const Container = Styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PieGraphContainer = Styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
  `
);

const AnswerList = Styled.ul(
  () => css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  `
);

const AnswerItem = Styled.li(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    .answer__circle {
      width: 15px;
      height: 15px;
      min-width: 15px;
      min-height: 15px;
      max-width: 15px;
      max-height: 15px;
      border-radius: 50%;
    }
  `
);

export default ResultSingle;
