import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SelectorProps, UserInfo } from "../../../../../type/data";

import { UserItem } from "../components/UserItem";
import { SelectorSearch } from "../components/SelectorSearch";
import { PrePitchingData } from "../../interface/PrePitchingDetail.interface";

interface Props {
  isEditable: boolean;
  allUser: UserInfo[];
  pitchingData: PrePitchingData;
  onChangeUser: (users: UserInfo[]) => void;
}
export const PitchingInveteUser: React.FC<Props> = (props) => {
  const { allUser, isEditable, pitchingData, onChangeUser } = props;
  const [allUserSelector, updateAllUserSelector] = useState<SelectorProps[]>(
    []
  );
  const [selectedUsers, updateSelectedUser] = useState<UserInfo[]>([]);

  useEffect(() => {
    updateAllUserSelector(
      allUser.map((item) => ({
        id: parseInt(`${item.id}`),
        text: item.name,
        data: item,
      }))
    );
  }, [allUser]);

  useEffect(() => {
    if (pitchingData && pitchingData.pfPreEvaluationResult) {
      const users: UserInfo[] = [];
      pitchingData.pfPreEvaluationResult.forEach((item) => {
        const target = allUser.find(
          (user) => parseInt(`${user.id}`) === item.acUser.acUserId
        );
        if (target) {
          users.push(target);
        }
      });
      updateSelectedUser([...users]);
    }
  }, [pitchingData, allUser]);

  return (
    <Container>
      {isEditable && (
        <SelectorSearch
          width={400}
          height={40}
          placehoder="심사역 이름을 검색해 주세요."
          selectList={allUserSelector.filter(
            (item) =>
              !selectedUsers.map((user) => user.name).includes(item.text)
          )}
          onChangeData={(data) => {
            const newUser = [...selectedUsers, data.data];
            updateSelectedUser(newUser);
            onChangeUser(newUser);
          }}
        />
      )}
      <UserList>
        {selectedUsers.map((item) => {
          return (
            <UserItem
              user={item}
              isEditable={isEditable}
              deleteUser={(user) => {
                const newUser = [...selectedUsers];
                const idx = newUser.findIndex((item) => item.id === user.id);
                if (idx !== -1) {
                  newUser.splice(idx, 1);
                  updateSelectedUser([...newUser]);
                  onChangeUser(newUser);
                }
              }}
            />
          );
        })}
        {!isEditable && selectedUsers.length == 0 && (
          <div
            className="body-14-rg"
            style={{ paddingLeft: 10, color: "var(--text-06)" }}
          >
            심사 위원 미지정
          </div>
        )}
      </UserList>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const UserList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 18px;
`;
