import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import {
  DatabaseHistory,
  DBTotalSearchPortfolio,
  DPBPTotalSearch,
} from "../../../../../../type/data";
import DBResultListFunctionRow from "../../DBResultListFunctionRow";
import TotalSearchModal from "./TotalSearchModal";
import TotalSearchDatabaseGrid from "./TotalSearchDatabaseGrid";
import { getDatabaseTotalSearch } from "../../../../../../api/repository/database/DatabaseRepository";
import FilterTotalSearch, {
  IsAllSelectedType,
  SearchValueType,
} from "../../../organisms/FilterTotalSearch";
import { setDatabaseHistory } from "../../../../../../common/pageHistoryUtil";
import useTotalSearch from "./useTotalSearch";
import DatabaseInput from "../../DatabaseInput";
interface Props {
  isShowFilter: boolean;
  history?: DatabaseHistory;
}

/**
 * 통합 검색 탭 화면
 *
 * 구성
 *  - 통합검색 조건 컴포넌트
 *  - 결과 Grid
 */
const TotalSearchTab: React.FC<Props> = (props) => {
  const [
    countPerPage,
    channels,
    currentPortfolio,
    isAllSelectedObject,
    industrialTechs,
    openPortfolioModal,
    participants,
    phaseDictionary,
    resultPortfolios,
    searchObject,
    totalCount,
    totalPage,
    onPortfolioModal,
    setSearchObject,
    updatePortfolioModal,
  ] = useTotalSearch();

  const [keyword, setKeyword] = useState<string>(
    props.history?.search?.keyword || ""
  );
  return (
    <Container>
      <ResultContainer
        style={{
          paddingRight: props.isShowFilter ? 0 : 60,
          paddingTop: 24,
          gap: 8,
        }}
      >
        <DBResultListFunctionRow
          totalCount={totalCount}
          countPerPage={countPerPage}
          curPage={searchObject.curPage || 1}
          totalPage={totalPage}
          resultPortfolioListCount={
            resultPortfolios ? resultPortfolios.portfolio.length : 0
          }
          customFunctions={
            <FlexRow style={{ width: 600, alignItems: "center" }}>
              <span className="body-14-sb" style={{ width: 60 }}>
                검색어
              </span>
              <DatabaseInput
                type="search"
                placeholder="기업명 또는 관련 키워드를 2글자 이상 입력해 주세요."
                onClickSearch={(value) => {
                  if (typeof value === "string") {
                    setSearchObject({
                      ...searchObject,
                      keyword: keyword,
                      curPage: 1,
                    });
                  }
                }}
                onChangeValue={(value) => {
                  if (typeof value === "string") {
                    setKeyword(value);
                  }
                }}
                value={keyword || ""}
              />
            </FlexRow>
          }
          updateCurPage={(updatedPage: number) => {
            setSearchObject((prev) => ({
              ...prev,
              curPage: updatedPage,
            }));
          }}
          searchKeyword={searchObject.keyword}
        />
        <div style={{ height: "100%" }}>
          {resultPortfolios && (
            <TotalSearchDatabaseGrid
              resultList={resultPortfolios}
              onPortfolioModal={onPortfolioModal}
            />
          )}
        </div>
      </ResultContainer>
      <FilterContainer className={props.isShowFilter ? "show__filter" : ""}>
        <FilterTotalSearch
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          isAllSelectedObject={isAllSelectedObject}
          industrialTechs={industrialTechs}
          participants={participants}
          channels={channels}
          phaseDictionary={phaseDictionary}
        />
      </FilterContainer>
      {resultPortfolios && currentPortfolio.current && openPortfolioModal && (
        <TotalSearchModal
          isOpen={openPortfolioModal}
          close={() => {
            updatePortfolioModal(false);
          }}
          portfolioInfo={currentPortfolio.current}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const ResultContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  gap: 4px;
`;
const FilterContainer = styled.div`
  width: 0px;
  height: 100%;
  transition: all 0.3s ease;
  border-left: 1px solid #f3f4f4;
  overflow: hidden;
  &.show__filter {
    width: 700px;
    transition: all 0.3s ease;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export default TotalSearchTab;
