import React from "react";
import styled from "styled-components";

interface Props {
  style?: React.CSSProperties;
  children?: JSX.Element;
}

export const SBPSectorFooter: React.FC<Props> = (props: Props) => {
  return <Container style={props.style}>{props.children}</Container>;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
