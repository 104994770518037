import React from "react";

import styled from "styled-components";

interface Props {}
export const Gradation: React.FC<Props> = ({}) => {
  return <Container />;
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
`;
