import { PropertyDescriptorParsingType } from "html2canvas/dist/types/css/IPropertyDescriptor";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import AcceleratingTab from "./component/tab/accelerating/AcceleratingTab";
import TotalSearchTab from "./component/tab/total-search/TotalSearchTab";
import DatabaseTab from "./component/DatabaseTab";

import {
  Channel,
  FundAccountType,
  PortfolioPhaseProps,
  StageType,
} from "../../../type/data";

import AlumniTab from "./component/tab/alumni/AlumniTab";
import DamagedTab from "./component/tab/damaged/DamagedTab";
import { getDatabaseHistory } from "../../../common/pageHistoryUtil";
import { RoundProps } from "../../portfolio-viewer-stock-change-history/interface/type";

export interface DatabaseSearchData {
  acChannel?: Channel[];
  acFundAccount?: FundAccountType[];
  addr1?: { addr1: string }[];
  pfPhase?: PortfolioPhaseProps[];
  round?: RoundProps[];
}

const DeskDatabase = () => {
  const history = getDatabaseHistory();
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    history?.tab === undefined || history?.tab === null ? 0 : history.tab
  );
  const [isShowFilter, updateIsShowFilter] = useState<boolean>(false);
  useEffect(() => {});
  if (!isLoaded) return null;
  return (
    <Container className="scroll__invisible">
      <DatabaseTab
        isShowfilter={isShowFilter}
        selectedTabIndex={selectedTabIndex}
        setSelectedIndex={setSelectedTabIndex}
        updateIsShowFilter={updateIsShowFilter}
      />
      <div className="filter-container scroll__invisible">
        {selectedTabIndex === 0 && (
          <TotalSearchTab history={history} isShowFilter={isShowFilter} />
        )}
        {selectedTabIndex === 1 && (
          <AcceleratingTab history={history} isShowFilter={isShowFilter} />
        )}
        {selectedTabIndex === 2 && (
          <AlumniTab history={history} isShowFilter={isShowFilter} />
        )}
        {selectedTabIndex === 3 && (
          <DamagedTab history={history} isShowFilter={isShowFilter} />
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: scroll;

  .filter-container {
    height: calc(100% - 61px);
  }
`;
export default DeskDatabase;
