import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Sector } from "../Sector";

import { SectorDescription } from "../SectorDescription";
import { InvestmentAssessment } from "../../../../../type/data";

import { CompanyMemberTable } from "./organism/CompanyMemberTable";
import { StakeStatementTable } from "./organism/StakeStatementTable";
import { FinancialStatementTable } from "./organism/FinancialStatementTable";
import { CapitalIncreaseHistoryTable } from "./organism/CapitalIncreaseHistoryTable";
import { DebtTable } from "./organism/DebtTable";
import { FinancingPlanTable } from "./organism/FinancingPlanTable";
import { ReactComponent as Check } from "../../../../../assets/image/icon_check_new.svg";
import NothingChecked from "../table/NothingChecked";
import { GovernmentProjectTable } from "./organism/GovernmentProjectTable";

interface Props {
  isReadOnly: boolean;
  data?: InvestmentAssessment;
  onChangeData?: (data: InvestmentAssessment) => void;
}

export const Section3: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;
  const [IACIHCheck, updateIACIHCheck] = useState<boolean | undefined>(false);
  const [IADebtCheck, updateIADebtCheck] = useState<boolean | undefined>(false);
  const [IAFPCheck, updateIAFPCheck] = useState<boolean | undefined>(false);

  useEffect(() => {
    console.log(data);
  }, [
    data?.investmentAssessmentDebt,
    data?.investmentAssessmentDebt?.isChecked,
    data?.investmentAssessmentDebt?.data,
  ]);

  useEffect(() => {
    if (!data) return;

    if (data.investmentAssessmentCapitalIncreaseHistory) {
      updateIACIHCheck(
        data.investmentAssessmentCapitalIncreaseHistory?.isChecked
      );
    }

    if (data.investmentAssessmentDebt) {
      updateIADebtCheck(data.investmentAssessmentDebt?.isChecked);
    }

    if (data.investmentAssessmentFinancingPlan) {
      updateIAFPCheck(data.investmentAssessmentFinancingPlan?.isChecked);
    }

    console.log(data);
  }, [data]);

  if (!data) return null;
  return (
    <Container>
      <Sector title="지분 변동 내역" id="Section3_1">
        <FlexColumn style={{ gap: 8 }}>
          <SectorDescription
            text={`금번 라운드에 주주가 되는 경우, 투자전 주식수와 지분율은 입력하지 않아도 됩니다.`}
          />
          <StakeStatementTable
            isReadOnly={props.isReadOnly}
            disabled={false}
            dataList={data.investmentAssessmentStakeStatement?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentStakeStatement: {
                    ...data.investmentAssessmentStakeStatement,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
        </FlexColumn>
      </Sector>

      <Sector title="자본금 증자 연혁" id="Section3_2">
        <FlexColumn style={{ gap: 8 }}>
          <SectorDescription
            text={`유상증자의 경우 세부내역에 보통주, 전환우선주, 상환우선주 등의 주식의 종류에 대해 작성해 주세요. `}
          />
          <CapitalIncreaseHistoryTable
            isReadOnly={props.isReadOnly}
            disabled={IACIHCheck}
            dataList={data.investmentAssessmentCapitalIncreaseHistory?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentCapitalIncreaseHistory: {
                    ...data.investmentAssessmentCapitalIncreaseHistory,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
          <NothingChecked
            defaultValue={
              data.investmentAssessmentCapitalIncreaseHistory?.isChecked
            }
            onChangeData={(checked) => {
              if (
                onChangeData &&
                data.investmentAssessmentCapitalIncreaseHistory !== undefined
              ) {
                updateIACIHCheck(checked);
                data.investmentAssessmentCapitalIncreaseHistory.isChecked =
                  checked;
                onChangeData(data);
              }
            }}
          />
        </FlexColumn>
      </Sector>

      <Sector title="재무 현황" id="Section3_3">
        <FlexColumn style={{ gap: 8 }}>
          <SectorDescription
            text={`입력할 값이 없는 칸에는 0을 입력해 주세요.`}
          />
          <FinancialStatementTable
            isReadOnly={props.isReadOnly}
            disabled={false}
            dataList={data.investmentAssessmentFinancialStatement2?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentFinancialStatement2: {
                    ...data.investmentAssessmentFinancialStatement2,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
        </FlexColumn>
      </Sector>

      <Sector title="자금 차입 현황" id="Section3_4">
        <FlexColumn style={{ gap: 8 }}>
          <DebtTable
            isReadOnly={props.isReadOnly}
            disabled={IADebtCheck}
            dataList={data.investmentAssessmentDebt?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentDebt: {
                    ...data.investmentAssessmentDebt,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
          <NothingChecked
            defaultValue={data.investmentAssessmentDebt?.isChecked}
            onChangeData={(checked) => {
              if (onChangeData && data.investmentAssessmentDebt !== undefined) {
                console.log(data);
                updateIADebtCheck(checked);
                data.investmentAssessmentDebt.isChecked = checked;
                onChangeData(data);
              }
            }}
          />
        </FlexColumn>
      </Sector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
