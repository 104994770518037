import React, { useEffect, useRef, useState } from "react";

export const useScroll = (): [
  number,
  React.MutableRefObject<any>,
  boolean,
  boolean
] => {
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [scrollStart, setScrollStart] = useState<boolean>(true);
  const [scrollEnd, setScrollEnd] = useState<boolean>(false);
  const ref = useRef<any>();

  const onScroll = (e: any) => {
    requestAnimationFrame(() => {
      console.log(e);
      setScrollTop(e.target.scrollTop);
      setScrollStart(e.target.scrollLeft === 0);
      setScrollEnd(
        e.target.scrollLeft === e.target.scrollWidth - e.target.clientWidth
      );
    });
  };

  useEffect(() => {
    const scrollContainer = ref.current;

    scrollContainer.addEventListener("scroll", onScroll);
    setScrollTop(scrollContainer.scrollTop);
    setScrollStart(scrollContainer.scrollLeft === 0);
    setScrollEnd(
      scrollContainer.scrollLeft ===
        scrollContainer.scrollWidth - scrollContainer.clientWidth
    );
    return () => {
      scrollContainer.removeEventListener("scroll", onScroll);
    };
  }, []);

  return [scrollTop, ref, scrollStart, scrollEnd];
};
