import React from "react";
import styled from "styled-components";
import { RiskManagementListProps } from "../../interface/RME.interface";

interface Props {
  data: RiskManagementListProps;
  onClick?: () => void;
}

export const ReportingWaitCard: React.FC<Props> = (props) => {
  const { data, onClick } = props;

  return (
    <Container onClick={onClick}>
      <Title className="heading-18-sb">{data.portfolio.companyName}</Title>
      <Description className="caption-12-rg">
        {data.portfolio.description}
      </Description>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  border-radius: 4px;
  border: 1px solid var(--gray200, #edeeef);
  background: var(--background-contents-01, #fff);

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const Title = styled.div`
  color: var(--text01, #1f2022);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Description = styled.div`
  display: -webkit-box;
  margin-top: 12px;
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text05, #96999f);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
