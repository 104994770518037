import React from "react";
import styled, { css } from "styled-components";
import DatabaseInput from "../component/DatabaseInput";

interface Props {
    width?: number;
    inputWidth?: number;
    unit: string;
    minValue?: number;
    maxValue?: number;
    onChangeMin: (data?: number) => void;
    onChangeMax: (data?: number) => void;
}
const DatabaseRangeInput = ({
    width,
    inputWidth,
    unit,
    minValue,
    maxValue,
    onChangeMin,
    onChangeMax,
}: Props) => {
    return (
        <Container width={width}>
            <div className="input-container">
                <DatabaseInput
                    type="number"
                    placeholder="최소"
                    width={inputWidth || 100}
                    value={minValue}
                    onChangeValue={(value) => {
                        onChangeMin(
                            typeof value === "string" ? undefined : value
                        ); // number type인데 string으로 온다...? 빈 값일 것임,,
                    }}
                />
                <span className="font-regular-12">{unit}</span>
            </div>
            <span className="font-regular-12">~</span>

            <div className="input-container">
                <DatabaseInput
                    type="number"
                    placeholder="최대"
                    width={inputWidth || 100}
                    value={maxValue}
                    onChangeValue={(value) => {
                        onChangeMax(
                            typeof value === "string" ? undefined : value
                        ); // number type인데 string으로 온다...? 빈 값일 것임,,
                    }}
                />
                <span className="font-regular-12">{unit}</span>
            </div>
        </Container>
    );
};

const Container = styled.div(
    ({ width }: { width?: number }) => css`
        width: ${width ? `${width}px` : "100%"};
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .input-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2px;
            span {
                color: var(--grey_50);
            }
        }
    `
);

export default DatabaseRangeInput;
