import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";

interface Props {
  disabled?: boolean;
  onClickCard?: () => void;
}
export const CreatePrePitchingCard: React.FC<Props> = (props) => {
  const { disabled, onClickCard } = props;
  return (
    <Container
      className={`${disabled ? "disabled" : ""}`}
      onClick={onClickCard}
      style={{
        backgroundColor: disabled ? "" : "",
      }}
    >
      <div className="heading-14-md">+{"  "}새로 만들기 </div>
      {disabled && (
        <div className="caption-10-md" style={{ color: "var(--text-05)" }}>
          이전 심사를 확정해야 새로 만들 수 있어요.
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-grow: 0;
  flex-basis: 384px;

  height: 257px;
  min-height: 257px;
  max-height: 257px;

  padding: 28px 24px;
  border-radius: 4px;

  border: 1px solid var(--gray-300);
  background-color: var(--background-contents01);
  transition: all 0.2s ease;

  cursor: pointer;

  color: var(--text-04, #96999f);
  &.disabled {
    background-color: var(--gray-100);
    :hover {
      background-color: var(--gray-100);
    }
  }
  :hover {
    background-color: var(--hover-100);
  }
`;
