import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  IndustryTech,
  Position,
  Responsibility,
  UserInfo,
} from "../../../../../type/data";
import { DataType } from "../Structure";
import { NodeModel } from "@minoru/react-dnd-treeview";

import { JobAuthority } from "../components/JobAuthority";

import { toast } from "react-toastify";
import { IndustrialTechSector } from "../components/IndustrialTechSector";
import { ProfileUpload } from "./components/ProfileUpload";

import { ReactComponent as Warnning } from "../../../../../assets/image/ic_warning.svg";
import axios from "axios";
import * as api from "../../../../../api/api";
import { UserStatus } from "./components/UserStatus";
import { getNewDate, getYMD } from "../../../../../common/commonUtil";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import { Selector } from "../components/Selector";
import {
  OtherAuthority,
  SelectorProps,
  StructureInfo,
} from "../interface/Structure.interface";
import { StructureDate } from "../components/StructureDate";
import { Authority } from "../components/Authority";
import { DefaultButton } from "lattice_core";

interface Props {
  structureData?: NodeModel<StructureInfo>[];
  userInfo: UserInfo;
  allIndustryTech?: IndustryTech[];
  onUserCreate: (newUser: UserInfo) => void;
  cancelCreate: () => void;
}
export const UserCreate: React.FC<Props> = (props) => {
  const { structureData = [], allIndustryTech } = props;
  const userInfo = useRef<UserInfo | undefined>(undefined);

  const [responsibility, setResponsibility] = useState<SelectorProps[]>([]);
  const [selectedResponsibility, setSelectedResponsibility] = useState<
    SelectorProps | undefined
  >(undefined);

  const [position, setPosition] = useState<SelectorProps[]>([]);
  const [selectedPosition, setSelectedPosition] = useState<
    SelectorProps | undefined
  >(undefined);

  const [depth1, setDepth1] = useState<NodeModel<StructureInfo>[]>([]);
  const [selectedDepth1, setSelectedDept1] = useState<
    string | number | undefined
  >(undefined);

  const [depth2, setDepth2] = useState<NodeModel<StructureInfo>[]>([]);
  const [selectedDepth2, setSelectedDept2] = useState<
    string | number | undefined
  >(undefined);

  const [depth3, setDepth3] = useState<NodeModel<StructureInfo>[]>([]);
  const [selectedDepth3, setSelectedDept3] = useState<
    string | number | undefined
  >(undefined);

  const [render, setRender] = useState<boolean>(true);
  const reRender = () => setRender((prevState) => !prevState);

  const onChangeUserProfile = (url: string, key: string) => {
    if (!userInfo.current) return;

    userInfo.current.profileImgUrl = url;
    userInfo.current.profileImgKey = key;
    reRender();
  };

  const onChangeUserName = (name: string) => {
    if (!userInfo.current) return;
    userInfo.current.name = name;
    reRender();
  };
  const onChangeUserPhone = (phone: string) => {
    if (!userInfo.current) return;
    userInfo.current.phoneNumber = phone;
  };

  const onChangeUserResponsibility = (item: SelectorProps) => {
    if (!userInfo.current) return;
    userInfo.current.acResponsibility = item.data;
    setSelectedResponsibility(item);
  };

  const getUserOtherAuth = () => {
    if (!userInfo.current) return;

    const auth: OtherAuthority[] = [];
    if (userInfo.current.isBoardMember) {
      auth.push("BoardMember");
    }
    if (userInfo.current.isReportManager) {
      auth.push("ReportManager");
    }
    if (userInfo.current.downloadOriginal) {
      auth.push("DownloadOriginal");
    }

    return auth;
  };

  const setUserOtherAuth = (authData: OtherAuthority[]) => {
    if (!userInfo.current) return;

    userInfo.current.isBoardMember = authData.includes("BoardMember");
    userInfo.current.isReportManager = authData.includes("ReportManager");
    userInfo.current.downloadOriginal = authData.includes("DownloadOriginal");
  };

  const onChangeUserPosition = (item: SelectorProps) => {
    if (!userInfo.current) return;

    userInfo.current.acPosition = item.data;
    setSelectedPosition(item);
  };

  const onChangeUserAuthrity = (auth: number) => {
    if (!userInfo.current) return;
    userInfo.current.authorityId = auth;
  };

  const onChangeUserEmpoloymentStaus = (status: number) => {
    if (!userInfo.current) return;
    userInfo.current.employmentStatusId = status;
  };

  const onChangeIndustrialTechSelect = (industrialTech: IndustryTech[]) => {
    if (!userInfo.current) return;
    userInfo.current.acIndustrialTech = industrialTech;
  };

  const onChangeStructureDepth1 = (data: SelectorProps) => {
    if (!userInfo.current) return;

    setSelectedDept1(data.id);
    setSelectedDept2(undefined);
    setSelectedDept3(undefined);
    userInfo.current.acStructureId = data.id;
  };
  const onChangeStructureDepth2 = (data: SelectorProps) => {
    if (!userInfo.current) return;

    setSelectedDept2(data.id);
    setSelectedDept3(undefined);
    userInfo.current.acStructureId = data.id;
  };
  const onChangeStructureDepth3 = (data: SelectorProps) => {
    if (!userInfo.current) return;

    setSelectedDept3(data.id);
    userInfo.current.acStructureId = data.id;
  };

  const onDeleteProfile = () => {
    if (!userInfo.current) return;

    userInfo.current.profileImgKey = undefined;
    userInfo.current.profileImgUrl = undefined;
    reRender();
  };

  const submitUserCreate = () => {
    if (!userInfo.current) return;

    const toastOption = {
      position: toast.POSITION.TOP_RIGHT,
    };

    if (!userInfo.current.name || userInfo.current.name.length === 0)
      return toast.error("이름을 입력해주세요", toastOption);
    if (!userInfo.current.acStructureId)
      return toast.error("소속을 선택해주세요", toastOption);
    if (
      !userInfo.current.dateOfEntry ||
      userInfo.current.dateOfEntry.length === 0
    )
      return toast.error("입사일을 입력해주세요", toastOption);

    userInfo.current.isChange = !userInfo.current.isNew;
    props.onUserCreate(userInfo.current);
  };

  const getResponsibility = async () => {
    try {
      const result = (await axios.get(api.getAcAdminResponsibility())).data
        .acResponsibility;
      const _responsibility: SelectorProps[] = result.map(
        (item: Responsibility): SelectorProps => ({
          id: item.acResponsibilityId,
          text: item.name,
          data: item,
        })
      );
      setResponsibility([..._responsibility]);
      if (userInfo.current && userInfo.current.acResponsibility) {
        setSelectedResponsibility(
          _responsibility.find(
            (item) =>
              item.id === userInfo.current?.acResponsibility?.acResponsibilityId
          )
        );
      }

      return _responsibility;
    } catch (error) {}
  };
  const getPosition = async () => {
    try {
      const result = (await axios.get(api.getAcAdminPosition())).data
        .acPositionList;
      const _position: SelectorProps[] = result.map(
        (item: Position): SelectorProps => ({
          id: item.acPositionId,
          text: item.name,
          data: item,
        })
      );
      setPosition([..._position]);

      if (userInfo.current && userInfo.current.acPosition) {
        setSelectedPosition(
          _position.find(
            (item) => item.id === userInfo.current?.acPosition?.acPositionId
          )
        );
      }

      return _position;
    } catch (error) {}
  };

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      //
      submitUserCreate();
    }
  };

  const courseStructure = (
    targetId: number | string,
    result: (number | string)[] = []
  ) => {
    if (targetId === 0) return result;

    const target = structureData.find((item) => item.id === targetId);
    if (target) {
      console.log(result);
      result.push(target.id);
      courseStructure(target.parent, result);
    }

    return result;
  };

  useEffect(() => {
    const depth1Structure = structuredClone(
      structureData.filter((item) => item.parent === 0)
    );
    setDepth1(depth1Structure);
    userInfo.current = structuredClone(props.userInfo);

    if (userInfo.current && userInfo.current.acStructureId) {
      if (userInfo && userInfo.current.acStructureId) {
        const depthResult = courseStructure(
          userInfo.current.acStructureId
        ).reverse();
        console.log(depthResult);

        if (depthResult[0]) {
          setSelectedDept1(depthResult[0]);
        }

        if (depthResult[1]) {
          setSelectedDept2(depthResult[1]);
        }

        if (depthResult[2]) {
          setSelectedDept3(depthResult[2]);
        }
      }
    }
  }, [props.userInfo]);

  useEffect(() => {
    if (selectedDepth1) {
      console.log(selectedDepth1);
      const depth2Structure = structuredClone(
        structureData.filter((item) => item.parent === selectedDepth1)
      );
      setDepth2(depth2Structure);
    } else setDepth2([]);
  }, [selectedDepth1]);

  useEffect(() => {
    if (selectedDepth2) {
      console.log(selectedDepth2);
      const depth3Structure = structuredClone(
        structureData.filter((item) => item.parent === selectedDepth2)
      );
      setDepth3(depth3Structure);
    } else setDepth3([]);
  }, [selectedDepth2]);

  useEffect(() => {
    getResponsibility();
    getPosition();
  }, []);

  useEffect(() => {
    window.addEventListener("keypress", onKeyPress);
    return () => {
      window.removeEventListener("keypress", onKeyPress);
    };
  }, []);

  if (!userInfo.current) return null;

  return (
    <Container>
      <Header>
        <span className="heading-18-sb">조직원 정보 수정</span>
        {(!userInfo.current.userStatusId ||
          userInfo.current.userStatusId === 1) && (
          <div className="warnning font-regular-12">
            <Warnning />
            미인증{" "}
          </div>
        )}
      </Header>
      <Contents id={"root__component"} className="scroll__invisible">
        <UserProfileContainer>
          <ProfileUpload
            profile={userInfo.current.profileImgUrl}
            callback={onChangeUserProfile}
            deleteImage={onDeleteProfile}
          />
        </UserProfileContainer>

        <div className="group">
          <RowTitleBox title="이메일" titleWidth={118}>
            <InputBox
              width={332}
              isReadOnly
              defaultValue={userInfo.current.email}
            />
          </RowTitleBox>

          <RowTitleBox required title="이름" titleWidth={118}>
            <InputBox
              width={332}
              placeholder="이름 입력"
              defaultValue={userInfo.current.name}
              onChange={(value) => {
                onChangeUserName(value);
              }}
            />
          </RowTitleBox>
          <RowTitleBox required title="연락처" titleWidth={118}>
            <InputBox
              width={332}
              textType="phone"
              placeholder="010-xxxx-xxxx"
              defaultValue={userInfo.current.phoneNumber}
              onChange={(value) => {
                onChangeUserPhone(value);
              }}
            />
          </RowTitleBox>
        </div>

        <div className="group">
          <RowTitleBox title="재직상태" titleWidth={118}>
            <UserStatus
              defaultValue={userInfo.current.employmentStatusId}
              onChange={onChangeUserEmpoloymentStaus}
            />
          </RowTitleBox>
          <RowTitleBox title="직무" titleWidth={118}>
            <JobAuthority
              className="double__item"
              defaultValue={userInfo.current.authorityId}
              onChange={onChangeUserAuthrity}
            />
          </RowTitleBox>
          <RowTitleBox required title="소속" titleWidth={118}>
            {depth1.length > 0 && (
              <div className="row">
                <div className="single__item">
                  {depth1.length > 0 && (
                    <Selector
                      selectList={depth1.map(
                        (item): SelectorProps => ({
                          id: item.id,
                          text: item.text,
                          data: item,
                        })
                      )}
                      width={180}
                      placehoder="소속을 선택하세요."
                      defaultData={depth1
                        .map(
                          (item): SelectorProps => ({
                            id: item.id,
                            text: item.text,
                            data: item,
                          })
                        )
                        .find((item) => item.id === selectedDepth1)}
                      onChangeData={(data) => onChangeStructureDepth1(data)}
                    />
                  )}
                </div>
                <div className="single__item">
                  {depth2.length > 0 && (
                    <Selector
                      selectList={depth2.map(
                        (item): SelectorProps => ({
                          id: item.id,
                          text: item.text,
                          data: item,
                        })
                      )}
                      width={180}
                      placehoder="소속을 선택하세요."
                      defaultData={depth2
                        .map(
                          (item): SelectorProps => ({
                            id: item.id,
                            text: item.text,
                            data: item,
                          })
                        )
                        .find((item) => item.id === selectedDepth2)}
                      onChangeData={(data) => onChangeStructureDepth2(data)}
                    />
                  )}
                </div>
                <div className="single__item">
                  {depth3.length > 0 && (
                    <Selector
                      selectList={depth3.map(
                        (item): SelectorProps => ({
                          id: item.id,
                          text: item.text,
                          data: item,
                        })
                      )}
                      width={180}
                      placehoder="소속을 선택하세요."
                      defaultData={depth3
                        .map(
                          (item): SelectorProps => ({
                            id: item.id,
                            text: item.text,
                            data: item,
                          })
                        )
                        .find((item) => item.id === selectedDepth3)}
                      onChangeData={(data) => onChangeStructureDepth3(data)}
                    />
                  )}
                </div>
              </div>
            )}
          </RowTitleBox>

          <RowTitleBox title="직책" titleWidth={118}>
            <Selector
              selectList={responsibility}
              defaultData={selectedResponsibility}
              onChangeData={onChangeUserResponsibility}
              placehoder="직책을 선택하세요."
              width={200}
            />
          </RowTitleBox>

          <RowTitleBox title="직급" titleWidth={118}>
            <Selector
              selectList={position}
              defaultData={selectedPosition}
              onChangeData={onChangeUserPosition}
              placehoder="직급을 선택하세요."
              width={200}
            />
          </RowTitleBox>
          <RowTitleBox title="입사일" titleWidth={118}>
            <StructureDate
              defaultDate={userInfo.current.dateOfEntry}
              onChange={(date) => {
                if (!userInfo.current) return;
                userInfo.current.dateOfEntry = getYMD(getNewDate(date));
              }}
            />
          </RowTitleBox>
        </div>

        <div>
          <RowTitleBox title="전문 산업기술" titleWidth={118}>
            <IndustrialTechSector
              allIndustryTech={allIndustryTech || []}
              onChange={onChangeIndustrialTechSelect}
              defaultData={userInfo.current.acIndustrialTech}
            />
          </RowTitleBox>
        </div>

        <div>
          <RowTitleBox title="권한" titleWidth={118}>
            <Authority
              defaultValue={getUserOtherAuth()}
              onChange={setUserOtherAuth}
            />
          </RowTitleBox>
        </div>
      </Contents>
      <ButtonContainer>
        <DefaultButton
          className="heading-16-md"
          sizeType="small"
          buttonType="text"
          text="취소"
          onClick={props.cancelCreate}
        />
        <DefaultButton
          className="heading-16-md"
          sizeType="small"
          text="수정"
          buttonColor="var(--primary-purple)"
          hoverColor="var(--purple-700)"
          onClick={submitUserCreate}
        />
      </ButtonContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div(
  () => css`
    width: 100%;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    border-bottom: 1px solid var(--gray-300);

    .warnning {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ffc800;
    }
  `
);

const Contents = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  gap: 44px;
  padding: 28px 40px;

  .group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    .single__item {
      flex: 1;
    }
    .double__item {
      flex: 2;
    }
  }
`;
const ButtonContainer = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid var(--grey_20);

    width: 100%;
    height: 75px;
    min-height: 75px;
    max-height: 75px;
    padding-right: 20px;
    gap: 24px;
    button {
      padding: 0 24px;
      height: 42px;
      border: 0;
      min-width: 120px;
      cursor: pointer;
      :hover {
        filter: brightness(1.2);
      }
    }
    .submit {
      background: var(--primary);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      color: var(--white);
    }
    .cancel {
      background: transparent;
      color: var(--grey_50);
    }
  `
);

const UserProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .toggle__container {
      position: relative;
      width: 336px;
      height: 36px;
      display: flex;
      flex-direction: row;
      border-radius: 10px;
      overflow: hidden;
      .item {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        cursor: pointer;
      }

      .item.left {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .item.right {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .item.on {
        background: var(--primary);

        border: 1px solid var(--primary);
        color: var(--white);
      }

      .item.off {
        background: var(--white);
        border: 1px solid var(--grey_50);
        color: var(--grey_50);
      }
    }
  }
  .color__picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    .color__item {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
    }
    .color__item.selected {
      border: 2px solid white;
      box-shadow: 0px 0px 8px #2196f3;
    }
  }
  .profile__img {
  }
`;
