import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";

import { DefaultButton } from "lattice_core";
import { SelectorProps, TinyUserInfo } from "../../../../../type/data";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { ChipsSelector } from "../../../../../components-2.0v/selector/ChipsSelector";
import { TextareaBox } from "../../../../../components-2.0v/input-box/TextareaBox";
import {
  EXIT,
  INVEST,
  InvestmentHistoryAdminProps,
} from "../interface/Portfolio.interface";
import { AddComma, getYMD } from "../../../../../common/commonUtil";

interface Props {
  data: InvestmentHistoryAdminProps;
  close: () => void;
  onClickExitCancel: (id: number) => void;
}

export const InvestExitHistoryDetail: React.FC<Props> = (props) => {
  const { data, close, onClickExitCancel } = props;

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          투자 및 회수 내역 상세
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible body-14-sb">
        <FlexRow style={{ gap: 16 }}>
          <RowTitleBox className="flex_1" title="투자/회수" titleWidth={80}>
            <div style={{ marginTop: 9 }}>
              {data.type === INVEST ? "투자" : "판매"}
            </div>
          </RowTitleBox>
          <RowTitleBox className="flex_1" title="일자" titleWidth={80}>
            <div style={{ marginTop: 9 }}>{getYMD(data.date)}</div>
          </RowTitleBox>
        </FlexRow>
        <FlexRow style={{ gap: 16 }}>
          <RowTitleBox className="flex_1" title="투자재원" titleWidth={80}>
            <div style={{ marginTop: 9 }}>{data.fundAccount}</div>
          </RowTitleBox>
          <RowTitleBox className="flex_1" title="주식종류" titleWidth={80}>
            <div style={{ marginTop: 9 }}>{data.stockType}</div>
          </RowTitleBox>
        </FlexRow>
        <FlexRow style={{ gap: 16 }}>
          <RowTitleBox className="flex_1" title="주식수" titleWidth={80}>
            <div style={{ marginTop: 9 }}>{AddComma(data.stock)}주</div>
          </RowTitleBox>
          <RowTitleBox className="flex_1" title="단가" titleWidth={80}>
            <div style={{ marginTop: 9 }}>{AddComma(data.stockPrice)}원</div>
          </RowTitleBox>
        </FlexRow>
        <FlexRow style={{ gap: 16 }}>
          <RowTitleBox className="flex_1" title="금액" titleWidth={80}>
            <div style={{ marginTop: 9 }}>{AddComma(data.amount)}원</div>
          </RowTitleBox>
          <RowTitleBox className="flex_1" title="구매/판매처" titleWidth={80}>
            <div style={{ marginTop: 9 }}>{data.target}</div>
          </RowTitleBox>
        </FlexRow>
      </Body>
      <Footer>
        <div>
          {data.type === EXIT && (
            <DefaultButton
              className="heading-16-md"
              buttonType="text"
              sizeType="small"
              buttonColor={"var(--red-500)"}
              text={"판매 취소"}
              onClick={() => {
                onClickExitCancel(data.id);
              }}
            />
          )}
        </div>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-purple)"}
            hoverColor={"var(--purple-600)"}
            text={"확인"}
            onClick={close}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 480px;
  min-width: 480px;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  padding: 28px 36px;
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .flex_1 {
    flex: 1;
  }
`;
