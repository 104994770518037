import React from "react";

import styled from "styled-components";

import {
  StakeHolderType,
  StockHolderGrouping,
} from "../../../interface/InvestmentInfo.interface";
import {
  AddComma,
  residentRegistrationNumberMaskStar,
} from "../../../../../../common/commonUtil";

interface Props {
  index: number;
  data: StockHolderGrouping;
}
export const StockholderRow: React.FC<Props> = ({ index, data }) => {
  return (
    <Container className={`caption-12-rg`}>
      <CellConatiner style={{ justifyContent: "center" }} width={40}>
        {index + 1}
      </CellConatiner>
      <CellConatiner
        width={1200 - 40 - 150 - 100 - 100 - 60 - 120 - 80}
        style={{ justifyContent: "center" }}
      >
        {data.name}
      </CellConatiner>

      <CellConatiner
        width={150}
        style={{ justifyContent: "center", textAlign: "center" }}
      >
        {data.countryId === 30 && data.stakeType === StakeHolderType.INDIVIDUAL
          ? residentRegistrationNumberMaskStar(data.registrationNumber)
          : data.registrationNumber}
      </CellConatiner>
      <FlexColumn>
        {data.stockData.map((stock) => {
          return (
            <FlexRow>
              <CellConatiner width={100} style={{ justifyContent: "center" }}>
                {stock.stockType.name}
              </CellConatiner>
              <CellConatiner width={100} style={{ justifyContent: "flex-end" }}>
                {AddComma(stock.stockNumber)}
              </CellConatiner>
              <CellConatiner width={60} style={{ justifyContent: "flex-end" }}>
                {AddComma(stock.faceValue)}
              </CellConatiner>
              <CellConatiner width={120} style={{ justifyContent: "flex-end" }}>
                {AddComma(stock.faceValue * stock.stockNumber)}
              </CellConatiner>
              <CellConatiner width={80} style={{ justifyContent: "flex-end" }}>
                {stock.percentage}%
              </CellConatiner>
            </FlexRow>
          );
        })}
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  min-height: 36px;
  height: max-content;
  display: flex;
  flex-direction: row;
  background-color: var(--background-contents01, #fff);

  /* &.error {
    background-color: var(--red-50);
  }
  :hover {
    background-color: var(--hover-100);
  } */
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  min-height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: default;
  color: var(--text-01);
  border-right: 1px solid var(--divider-300, #e3e4e5);
  border-bottom: 1px solid var(--divider-300, #e3e4e5);
  padding: 8px 10px;

  white-space: break-spaces;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
