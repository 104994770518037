import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../components/navigation/NavigationLayout";
import MobileMyPage from "./mobile/MobileMyPage";
import DeskMyPage from "./desktop/DeskMyPage";

const MyPage = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobileMyPage /> : <DeskMyPage />}
    </NavigationLayout>
  );
};
export default MyPage;
