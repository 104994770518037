import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { ContractReportAccount } from "../../interface/ContractTask.interface";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { getYMD } from "../../../../../common/commonUtil";

interface Props {
  isSelected: boolean;
  fundAccount: ContractReportAccount;
  onClickCard: () => void;
}
export const FundAccountCard: React.FC<Props> = (props) => {
  const { isSelected, fundAccount, onClickCard } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Container
      ref={containerRef}
      className={isSelected ? "selected" : ""}
      onClick={onClickCard}
    >
      <div className="heading-16-sb">{fundAccount.accountName}</div>
      <FlexColumn>
        <FlexRow style={{ alignItems: "center" }}>
          <span
            className="heading-14-sb"
            style={{ width: 80, color: "var(--text-04)" }}
          >
            계약일
          </span>
          <span
            className="body-14-rg"
            style={{
              color: fundAccount.contractDate
                ? "var(--text-01)"
                : "var(--text-05)",
            }}
          >
            {fundAccount.contractDate
              ? getYMD(fundAccount.contractDate)
              : "입력해주세요."}
          </span>
        </FlexRow>

        <FlexRow style={{ alignItems: "center" }}>
          <span
            className="heading-14-sb"
            style={{ width: 80, color: "var(--text-04)" }}
          >
            납입일
          </span>
          <span
            className="body-14-rg"
            style={{
              color: fundAccount.paymentDate
                ? "var(--text-01)"
                : "var(--text-05)",
            }}
          >
            {fundAccount.paymentDate
              ? getYMD(fundAccount.paymentDate)
              : "입력해주세요."}
          </span>
        </FlexRow>
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 12px 24px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  cursor: pointer;

  &.selected {
    background-color: var(--blue-200);
    border: 1px solid var(--blue-200);
    :hover {
      background-color: var(--blue-200);
    }
  }

  :hover {
    background-color: var(--hover-100);
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
