import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { PitchingInveteUser } from "./PitchingInveteUser";
import { DateTime, UserInfo } from "../../../../../type/data";
import { PickerDateTime } from "../../../../../components-2.0v/date-picker/PickerDateTime";
import {
  PitchingChangeDataType,
  PrePitchingData,
} from "../../interface/PrePitchingDetail.interface";
import { getDateToDateTime, getYMDHM } from "../../../../../common/commonUtil";
import ColumnTitleBox from "../../../../../components/box/ColumnTitleBox";
import { InputDate } from "../../../../../components-2.0v/date-picker/InputDate";
import { DateTimePickerByMUI } from "../../../../../components-2.0v/date-picker/DateTimePickerByMUI";

interface Props {
  isEditable: boolean;
  pitchingData: PrePitchingData;
  allUser: UserInfo[];
  onChangeData: (data: { type: PitchingChangeDataType; data: any }) => void;
}
export const PitchingDefalutSetting: React.FC<Props> = (props) => {
  const { allUser, isEditable, pitchingData, onChangeData } = props;
  // const [date, updateDate] = useState<DateTime | undefined>(undefined);

  // useEffect(() => {
  //   if (pitchingData.preEvaluationDate) {
  //     updateDate(getDateToDateTime(pitchingData.preEvaluationDate));
  //   }
  // }, [pitchingData]);
  return (
    <Container>
      <ColumnTitleBox title="심사일자" titleWidth={128}>
        <TextBox className={`body-14-rg`}>
          <DateTimePickerByMUI
            disabled={!isEditable}
            defaultValue={pitchingData.preEvaluationDate}
            onChangeDate={(date) => {
              onChangeData({ type: "preEvaluationDate", data: date });
            }}
          />
          {/* <PickerDateTime
            date={date}
            onChangeDate={(date) => {
              const transformDate = getYMDHM(`${date.date} ${date.time}`);
              onChangeData({ type: "preEvaluationDate", data: transformDate });
              updateDate(date);
            }}
            disabled={!isEditable}
          /> */}
        </TextBox>
      </ColumnTitleBox>
      <ColumnTitleBox title="심사 위원" titleWidth={128}>
        <TextBox className={`body-14-rg`}>
          <PitchingInveteUser
            allUser={allUser}
            isEditable={isEditable}
            pitchingData={pitchingData}
            onChangeUser={(users) => {
              onChangeData({ type: "preEvaluationInviteUser", data: users });
            }}
          />
        </TextBox>
      </ColumnTitleBox>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const TextBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-01, #1f2022);

  &.is__empty {
    color: var(--text-05);
  }
`;
