import React, { useState, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../../../../assets/image/icon_arrow.svg";

interface Props {
  title: string;
  count: number;
  children: JSX.Element;
  initOpen?: boolean;
}

export const AccordionTitle: React.FC<Props> = (props) => {
  const { title, initOpen, count, children } = props;
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Header onClick={() => setIsOpen(true)}>
        <Arrow className={`icon ${isOpen ? "icon__open" : ""}`} />
        <span className="heading-16-md title">{title}</span>
        <span className="heading-14-md count">{count}</span>
      </Header>
      <ContentContainer ref={contentRef}>
        <div className="accordion-inner">{children}</div>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  cursor: pointer;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 12px 12px 12px 0 px;

  .icon {
    width: 16px;
    height: 16px;
    transform: rotate(-90deg);
    transition: all 0.3s ease-in;
    path {
      fill: var(--icon-01);
    }
  }
  .icon__open {
    transition: all 0.3s ease-in;
    transform: rotate(90deg);
  }
  .title {
    margin-left: 18px;
    color: var(--text01, #1f2022);
  }
  .count {
    margin-left: 8px;
    color: var(--text06, #bec0c2);
  }
`;

const ContentContainer = styled.div`
  transition: all 0.2s ease-out;
  overflow: hidden;
  padding-top: 24px;
  /* max-height: 0; Initial state */
`;
