import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DaumPostcode from "react-daum-postcode";
import { Address } from "../../type/data";
import ModalLayout from "./ModalLayout";

import { ReactComponent as Arrow } from "../../assets/image/icon_arrow.svg";
import { DefaultButton } from "lattice_core";

interface DaumModalProps {
  open: boolean;
  setValue: (value: Address) => void;
  toggle: () => void;
}
const DaumModal: React.FC<DaumModalProps> = ({ open, setValue, toggle }) => {
  const [address, setAddress] = useState<Address | undefined>(undefined);
  const [detailAddress, updateDetailAddress] = useState<string>("");
  const handleComplete = (data: any) => {
    console.log(data);

    if (data.sigungu.length > 0) {
      let fullAddress = data.address;
      let extraAddress = "";
      const addr3Index =
        fullAddress.indexOf(data.sigungu) + data.sigungu.length + 1;

      if (data.addressType === "R") {
        if (data.bname !== "") {
          extraAddress += data.bname;
        }
        if (data.buildingName !== "") {
          extraAddress +=
            extraAddress !== "" ? `${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== "" ? `(${extraAddress})` : "";
      }

      const addr: Address = {
        addr1: data.sido,
        addr2: data.sigungu,
        addr3: fullAddress.slice(addr3Index),
        fullAddr: fullAddress,
      };
      setAddress(addr);
    } else {
      const addr: Address = {
        addr1: data.address.split(" ")[0],
        addr2: data.address.split(" ")[1],
        addr3: data.address.split(" ").slice(2).join(" "),
        fullAddr: data.address,
      };
      setAddress(addr);
    }
  };

  const onSubmit = () => {
    console.log(detailAddress.trim());

    const addr: Address = { ...address, addrDetail: detailAddress.trim() };
    setValue(addr);
    toggle();
  };

  const onKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (address !== undefined && detailAddress.trim().length > 0) {
        onSubmit();
      }
    }
  };

  useEffect(() => {
    setAddress(undefined);
    updateDetailAddress("");
  }, [open]);

  return (
    <ModalLayout isOpen={open} toggle={toggle} isFullScreen>
      <Container>
        <div className="daum__header">
          <span className="heading-18-sb">주소입력</span>
        </div>
        <div className="daum__body">
          {address === undefined ? (
            <DaumPostcode
              onComplete={handleComplete}
              theme={{
                bgColor: "#FBFCFD", //바탕 배경색
                contentBgColor: "#FFFFFF", //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
                pageBgColor: "#FBFCFD", //페이지 배경색
                textColor: "#1F2022", //기본 글자색
                queryTextColor: "#1F2022", //검색창 글자색
                postcodeTextColor: "#173AFF", //우편번호 글자색
                emphTextColor: "#4584FF", //강조 글자색
                outlineColor: "#E3E4E5", //테두리
              }}
            />
          ) : (
            <div className="address__container">
              <span
                className="address__box disabled font-regular-14 "
                style={{ marginTop: 10, paddingLeft: 10 }}
              >{`${address.addr1} ${address.addr2} ${address.addr3}`}</span>
              <div className="flex__column">
                <input
                  className="address__box detail__address font-regular-14"
                  type="text"
                  placeholder="상세주소입력"
                  value={detailAddress}
                  onChange={(e) => {
                    updateDetailAddress(e.target.value);
                    console.log(e.target.value);
                  }}
                  onKeyDown={onKeyDown}
                />
                <span
                  style={{
                    height: "fit-content",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                    color: "var(--gray-600)",

                    gap: 4,
                  }}
                  onClick={() => {
                    setAddress(undefined);
                    updateDetailAddress("");
                  }}
                >
                  <span className="caption/12-md">다른 주소 검색</span>
                  <Arrow
                    style={{ transform: "rotate(180deg)" }}
                    width={16}
                    height={16}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="daum__footer">
          <div>
            <DefaultButton
              className="heading-16-md"
              buttonType="colored-outline"
              sizeType="small"
              buttonColor="var(--text-01)"
              borderColor="var(--gray-400)"
              onClick={toggle}
              text="취소"
            />
          </div>
          <div>
            <DefaultButton
              className="heading-16-md"
              buttonType="filled"
              sizeType="small"
              disabled={
                address === undefined || detailAddress.trim().length === 0
              }
              buttonColor="var(--primary-blue)"
              hoverColor="var(--blue-600)"
              borderColor="var(--gray-400)"
              text="완료"
              onClick={onSubmit}
            />
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
const Container = styled.div`
  width: 534px;
  height: 725px;
  display: flex;
  flex-direction: column;
  .daum__header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 66px;
    max-height: 66px;
    min-height: 66px;
    padding: 0 24px;
  }
  .daum__body {
    flex-grow: 1;
    .address__container {
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      gap: 11px;

      .flex__column {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        gap: 8px;
      }

      .address__box {
        width: 100%;
        height: 40px;
        padding: 9px 12px;
        border-radius: 4px;
      }

      .disabled {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid var(--gray-300);
        background: var(--hover-100);
        color: var(--text-06);
      }

      .detail__address {
        border: 1px solid var(--gray-300);
        :focus {
          border: 0;
          outline: 1px solid var(--blue-300);
        }
      }
    }
    div {
      height: 100% !important;
    }
  }
  .daum__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 14px 24px;
    gap: 12px;

    height: 74px;
    max-height: 74px;
    min-height: 74px;
  }
`;
export default DaumModal;
