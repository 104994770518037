import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FundAccount } from "../interface/FundAccount.interface";
import { AccountType } from "../../../../dashboard/fund-account-info/interface/FundAccountInfo.interface";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { AddComma, getYMD, showToast } from "../../../../../common/commonUtil";

import { ReactComponent as More } from "../../../../../assets/image/icon_more.svg";
import { Bounce } from "react-toastify";

interface Props {
  fundAccount: FundAccount;
  onClickCard?: () => void;
  onClickDelete?: (fundAccount: FundAccount) => void;
  onClickDeleteCancel?: (fundAccount: FundAccount) => void;
}
export const FundAccountCard: React.FC<Props> = (props) => {
  const { fundAccount, onClickCard, onClickDelete, onClickDeleteCancel } =
    props;
  const containerRef = useRef<HTMLDivElement>(null);
  const dotRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);

  const [showList, updateShowList] = useState<boolean>(false);

  const getLabel = () => {
    if (!fundAccount.isInvest && !fundAccount.isActive) {
      return (
        <CustomColorLabel bgColor="var(--red-500)" textColor="var(--text-02)">
          삭제예정
        </CustomColorLabel>
      );
    }

    switch (fundAccount.status) {
      case AccountType.INVESTMENT:
        return (
          <CustomColorLabel
            bgColor="var(--green-500)"
            textColor="var(--text-02)"
          >
            투자가능
          </CustomColorLabel>
        );
      case AccountType.OPERATING:
        return (
          <CustomColorLabel
            bgColor="var(--blue-400)"
            textColor="var(--text-02)"
          >
            투자완료
          </CustomColorLabel>
        );
      case AccountType.WATING:
        return (
          <CustomColorLabel
            bgColor="var(--yellow-500)"
            textColor="var(--text-02)"
          >
            투자대기
          </CustomColorLabel>
        );
      case AccountType.PAUSE:
        return (
          <CustomColorLabel
            bgColor="var(--gray-700)"
            textColor="var(--text-02)"
          >
            투자중지
          </CustomColorLabel>
        );
      case AccountType.EXPIRED:
        return (
          <CustomColorLabel
            bgColor="var(--primary-purple)"
            textColor="var(--text-02)"
          >
            펀드청산
          </CustomColorLabel>
        );
    }
  };

  const getLabelConainer = () => {
    return <FlexRow style={{ gap: 4 }}>{getLabel()}</FlexRow>;
  };

  const handleMouseDown = (event: any) => {
    if (
      optionRef.current &&
      dotRef.current &&
      !optionRef.current.contains(event.target) &&
      !dotRef.current.contains(event.target)
    ) {
      updateShowList(false);
    }
  };

  const rectPositoin = () => {
    const root = window.document.querySelector("#body__component");

    if (dotRef.current && root) {
      const targetRect = dotRef.current.getBoundingClientRect();
      if (optionRef.current) {
        optionRef.current.style.top = `${
          targetRect.top + targetRect.height + 10
        }px`;
        optionRef.current.style.left = `${
          targetRect.left - optionRef.current.clientWidth + targetRect.width
        }px`;
      }
    }
  };
  const handleScroll = (e: any) => {
    updateShowList(false);
  };

  useEffect(() => {
    const root = window.document.querySelector("#body__component");

    window.addEventListener("mousedown", handleMouseDown);
    root && root.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      root && root.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showList) {
      rectPositoin();
    }
  }, [showList]);

  return (
    <Container ref={containerRef} onClick={onClickCard}>
      {getLabelConainer()}
      <PortfolioName className="heading-18-sb">
        {fundAccount.nickName}
      </PortfolioName>
      <FlexColumn>
        <div className="caption-12-rg sub__title">
          {fundAccount.acFundAccountId === 1 ? "사용금액(원)" : "약정금액(원)"}
        </div>
        <FlexRow style={{ gap: 12 }}>
          <div className="heading-20-sb">
            {AddComma(
              fundAccount.type === 1 ? 9999999999 : fundAccount.totalAmount
            )}
          </div>
        </FlexRow>
      </FlexColumn>
      <Divder />
      <FlexColumn style={{ width: "100%", gap: 6 }}>
        <FlexColumn>
          <div className="caption-12-rg sub__title">투자 기간</div>
          <div className="caption-12-md">
            {getYMD(fundAccount.investmentStartDate)} ~{" "}
            {getYMD(fundAccount.investmentEndDate)}
          </div>
        </FlexColumn>

        <FlexColumn>
          <div className="caption-12-rg sub__title">운영 기간</div>
          <div className="caption-12-md">
            {getYMD(fundAccount.operatingStartDate)} ~{" "}
            {getYMD(fundAccount.operatingEndDate)}
          </div>
        </FlexColumn>

        <FlexColumn>
          <div className="caption-12-rg sub__title">LP수</div>
          <div className="caption-12-md">
            {fundAccount.investmentOrganizationCount}개
          </div>
        </FlexColumn>
      </FlexColumn>

      <MoreWrapper
        ref={dotRef}
        onClick={(e) => {
          e.stopPropagation();
          updateShowList((prev) => !prev);
        }}
      >
        <More />
      </MoreWrapper>

      {showList && (
        <ListContainer ref={optionRef} className="body-14-rg">
          <ListItem
            onClick={(e) => {
              e.stopPropagation();
              updateShowList(false);

              if (fundAccount.isInvest) {
                return showToast({
                  customElement: (
                    <div
                      className="heading-16-sb"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>투자 내역이 있어 삭제가 불가능합니다.</span>
                    </div>
                  ),
                  backgroundColor: "var(--red-500)",
                  color: "var(--text-02)",
                  width: 274,
                  milliSec: 1500,
                  transition: Bounce,
                  position: "bottom-center",
                  toastId: new Date().toString(),
                });
              }

              if (fundAccount.isActive) {
                onClickDelete && onClickDelete(fundAccount);
              } else {
                onClickDeleteCancel && onClickDeleteCancel(fundAccount);
              }
            }}
            style={{
              color: fundAccount.isInvest
                ? "var(--gray-400)"
                : "var(--red-500)",
            }}
          >
            {fundAccount.isActive ? "삭제하기" : "삭제 취소하기"}
          </ListItem>
        </ListContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  flex-grow: 0;
  flex-basis: 384px;

  padding: 34px 24px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  color: var(--text-01);

  cursor: pointer;

  .sub__title {
    color: var(--text-05, #96999f);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;

const PortfolioName = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 14px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divder = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: var(--gray-200, #edeeef);
`;

const MoreWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;

  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;

  border-radius: 4px;
  transition: all 0.2 ease;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: var(--gray-500);
  }
`;

const ListContainer = styled.div`
  position: fixed;
  width: 164px;
  max-height: 236px;
  display: flex;
  flex-direction: column;

  z-index: 2;

  padding: 8px 0px;

  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  background-color: var(--background-contents02);
  overflow: scroll;
`;

const ListItem = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 9px 16px;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;
