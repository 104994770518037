import React from "react";
import styled from "styled-components";

import { SBPContentsPropsV2 } from "../../../interface/SBP.interface";
import { FundBorrowComponent } from "./table/FundBorrowComponent";
import { AddComma } from "../../../../../../../../../common/commonUtil";

interface Props {
  index: number;
  sbpDocument: SBPContentsPropsV2;
}

export const Status3: React.FC<Props> = (props) => {
  const getTotalFundBorrowd = () => {
    return props.sbpDocument.businessStatus.fundBorrowed
      .map((item) => item.borrowedAmount || 0)
      .reduce((prev, cur) => prev + cur, 0);
  };

  const getTotalOutStandingBalance = () => {
    return props.sbpDocument.businessStatus.fundBorrowed
      .map((item) => item.outstandingBalance || 0)
      .reduce((prev, cur) => prev + cur, 0);
  };
  return (
    <FlexColumn style={{ gap: 8 }}>
      <div className="body-16-md" style={{ color: "var(--text-03)" }}>
        0{props.index}. 자금 차입
      </div>
      <FundBorrowComponent
        fundDataList={props.sbpDocument.businessStatus.fundBorrowed}
        disabled={props.sbpDocument.businessStatus.noEventFundBorrowed}
        isReadOnly
      />
      <FlexRow
        className="caption-12-md"
        style={{ justifyContent: "flex-end", gap: 24 }}
      >
        <FlexRow
          style={{
            width: "fit-content",
            alignItems: "center",
            gap: 12,
          }}
        >
          <span style={{ color: "var(--text-04)" }}>총 차입 금액</span>
          <span style={{ color: "var(--text-01)" }}>
            {props.sbpDocument.businessStatus.noEventFundBorrowed
              ? "해당없음"
              : `${AddComma(getTotalFundBorrowd())} 원`}
          </span>
        </FlexRow>
        <FlexRow
          style={{
            width: "fit-content",
            alignItems: "center",
            gap: 12,
          }}
        >
          <span style={{ color: "var(--text-04)" }}>총 상환 후 잔액</span>
          <span style={{ color: "var(--text-01)" }}>
            {props.sbpDocument.businessStatus.noEventFundBorrowed
              ? "해당없음"
              : `${AddComma(getTotalOutStandingBalance())} 원`}
          </span>
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
