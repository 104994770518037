import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Checked } from "../../assets/image/icon_check_new.svg";

interface Props {
  className?: string;
  isChecked?: boolean;
  checkedColor?: string;
  onClick?: () => void;
}

const CheckedText: React.FC<Props> = (props) => {
  const {
    className = "caption-12-rg",
    isChecked,
    checkedColor,
    onClick,
    children,
  } = props;
  return (
    <Container
      className={`${className} ${isChecked ? "checked" : ""}`}
      checkedColor={checkedColor}
      onClick={onClick}
    >
      <Checked className="icon" />
      <TitleContainer>{children}</TitleContainer>
    </Container>
  );
};

const Container = styled.div<{ checkedColor?: string }>`
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  .icon {
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
    max-width: 19px;
    max-height: 19px;
  }

  &.checked {
    path {
      stroke: ${(props) => (props.checkedColor ? "white" : `var(--blue-400)`)};
    }
    rect {
      fill: ${(props) =>
        props.checkedColor ? props.checkedColor : `var(--blue-50, #f6faff)`};
      stroke: ${(props) =>
        props.checkedColor ? props.checkedColor : `var(--blue-400)`};
    }
  }
`;
const TitleContainer = styled.div`
  width: 100%;
  color: var(--text-03);
`;
export default CheckedText;
