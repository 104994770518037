import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../assets/image/modal_close.svg";
import { DefaultButton } from "lattice_core";
import { SelectorProps, UserInfo } from "../../../../type/data";
import RowTitleBox from "../../../../components/box/RowTitleBox";
import { ChipsSelector } from "../../../../components-2.0v/selector/ChipsSelector";
import { getMyInfo, getNewDate, getYMD } from "../../../../common/commonUtil";
import { ExcavationRegisteParticipant } from "../../interface/Excavation.interface";
import { PickerDate } from "../../../../components-2.0v/date-picker/PickerDate";
import { ExcavationDatePicker } from "./ExcavationDatePicker";

interface Props {
  allUser: UserInfo[];
  hasExcavator: boolean;
  hasExcavationDate: boolean;
  submit: (data: ExcavationRegisteParticipant) => void;
  close: () => void;
}

export const RegisteParticipantModal: React.FC<Props> = (props) => {
  const { allUser, hasExcavator, hasExcavationDate, submit, close } = props;
  const myInfo = getMyInfo();
  const [allParticipantUser, setAllParticipantUser] = useState<SelectorProps[]>(
    []
  );
  const [allExcavatorUser, setAllExcavatorUser] = useState<SelectorProps[]>([]);

  const [selectedMainParticipant, setSelectedMainParticipant] = useState<
    SelectorProps | undefined
  >(undefined);
  const [selectedSubParticipant, setSelectedSubParticipant] = useState<
    SelectorProps[]
  >([]);
  const [selectedExcavator, setSelectedExcavator] = useState<
    SelectorProps | undefined
  >(undefined);

  const [selectedExcavationDate, setSelectedExcavationDate] = useState<string>(
    getYMD(getNewDate())
  );

  const onClickSubmit = () => {
    if (!selectedMainParticipant) return;
    if (!hasExcavator && !selectedExcavator) return;

    const data: ExcavationRegisteParticipant = {
      addParticipant: [
        ...selectedSubParticipant.map((item) => ({
          acUserId: item.id,
          screenRoleId: 2,
        })),
        {
          acUserId: selectedMainParticipant.id,
          screenRoleId: 1,
        },
      ],
      excavator: hasExcavator
        ? undefined
        : {
            acUserId: selectedExcavator?.id || 0,
          },
      excavationDate: hasExcavationDate ? undefined : selectedExcavationDate,
    };
    close();
    submit(data);
  };

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.defaultPrevented) return;

    if (event.key === "Enter") {
      event.preventDefault();
      document
        .getElementById("select-user-with-GCalendar-apply-button")
        ?.click();
    }
  };
  useEffect(() => {
    const defaultData = allUser.find((item) => item.id === myInfo.id);
    if (defaultData) {
      setSelectedMainParticipant({
        id: parseInt(`${defaultData.id}`),
        text: defaultData.name,
        data: defaultData,
      });
      if (!hasExcavator) {
        setSelectedExcavator({
          id: parseInt(`${defaultData.id}`),
          text: defaultData.name,
          data: defaultData,
        });
      }
    }
    setAllParticipantUser(
      allUser
        .filter((item) => item.authorityId === 1)
        .map(
          (item): SelectorProps => ({
            id: parseInt(`${item.id}`),
            text: item.name,
            data: item,
          })
        )
    );

    setAllExcavatorUser(
      allUser.map(
        (item): SelectorProps => ({
          id: parseInt(`${item.id}`),
          text: item.name,
          data: item,
        })
      )
    );
  }, [allUser]);

  useEffect(() => {
    window.addEventListener("keypress", onKeyPress);
    return () => {
      window.removeEventListener("keypress", onKeyPress);
    };
  }, []);
  return (
    <Container id="participant_modal">
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          담당자 등록
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        <PageContainer style={{ gap: 16 }}>
          <RowTitleBox title="담당자" required titleWidth={128}>
            <ChipsSelector
              type="single"
              searchList={allParticipantUser}
              selectedData={
                selectedMainParticipant ? [selectedMainParticipant] : undefined
              }
              onChangeUser={(users) => {
                const main = users[0];
                const hasMailInSubParticipant =
                  selectedSubParticipant.findIndex(
                    (item) => item.id === main.id
                  );
                if (hasMailInSubParticipant !== -1) {
                  selectedSubParticipant.splice(hasMailInSubParticipant, 1);
                  setSelectedSubParticipant([...selectedSubParticipant]);
                }

                setSelectedMainParticipant(main);
              }}
            />
          </RowTitleBox>
          <RowTitleBox title="조력자" titleWidth={128}>
            <ChipsSelector
              searchList={allParticipantUser
                .filter((item) => item.id !== selectedMainParticipant?.id)
                .filter(
                  (item) =>
                    !selectedSubParticipant
                      .map((sub) => sub.id)
                      .includes(item.id)
                )}
              selectedData={selectedSubParticipant}
              onChangeUser={(users) => {
                setSelectedSubParticipant([...users]);
              }}
            />
          </RowTitleBox>
          {!hasExcavator && (
            <RowTitleBox title="발굴자" required titleWidth={128}>
              <ChipsSelector
                type="single"
                searchList={allExcavatorUser}
                maxHeight={176}
                selectedData={
                  selectedExcavator ? [selectedExcavator] : undefined
                }
                onChangeUser={(users) => {
                  setSelectedExcavator(users[0]);
                }}
              />
            </RowTitleBox>
          )}
          {!hasExcavationDate && (
            <RowTitleBox title="발굴 일자" required titleWidth={128}>
              <ExcavationDatePicker
                defaultDate={getYMD(getNewDate())}
                onChangeDate={(date) => {
                  setSelectedExcavationDate(getYMD(date));
                }}
              />
            </RowTitleBox>
          )}
        </PageContainer>
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={() => {
              close();
            }}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            disabled={
              hasExcavator
                ? selectedMainParticipant === undefined
                : selectedMainParticipant === undefined ||
                  selectedExcavator === undefined
            }
            text={"등록"}
            onClick={onClickSubmit}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 80vw;
  max-width: 640px;
  height: 70vh;
  max-height: 650px;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  gap: 36px;
`;
const PageContainer = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 24px 0 24px;
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
