import React from "react";
import { useMediaQuery } from "react-responsive";
import MobilePortfolioDetail from "./mobile/MobilePortfolioDetail";
import DeskPortfolioDetail from "./desktop/DeskPortfolioDetail";

const PortfolioDetail = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? <MobilePortfolioDetail /> : <DeskPortfolioDetail />;
};
export default PortfolioDetail;
