import React from "react";

import styled from "styled-components";
import { ReactComponent as Lock } from "../../../../../assets/image/icon_lock.svg";

interface Props {
  placeholder?: string;
}
export const NotOpen: React.FC<Props> = ({
  placeholder = "데이터 준비중 입니다",
}) => {
  return (
    <Container className={`heading-18-md`}>
      <Lock width={60} height={60} />
      <div>{placeholder}</div>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-items: center;
  background: var(--light-background-contents-01, #fff);

  color: var(--text-04);
`;
