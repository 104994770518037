import React from "react";
import styled from "styled-components";

import { AddComma, getYMD } from "../../../../common/commonUtil";

import ModalLayout from "../../../../components/modal/ModalLayout";

import ConfirmModal from "../../../../components/modal/ConfirmModal";
import { NotionTextarea } from "../../../../components/input/NotionTextarea";
import { NotionInput } from "../../../../components/input/NotionInput";

import { ReactComponent as Won } from "../../../../assets/image/won.svg";
import { ReactComponent as Tooltip } from "../../../../assets/image/ic_tooltip.svg";
import { DefaultButton } from "lattice_core";
import TaskLayout from "../../pre-pitching/desktop/organism/TaskLayout";
import { InputDate } from "../../../mytask/organisms/InputDate";
import useContractTask from "../hook/useContractTask";
import { TitleSection } from "../../../portfolio/info/desk/components/TitleSection";
import { StartupInfo } from "../../../portfolio/info/desk/organism/StartupInfo";
import { ParticipantInfo } from "../../../portfolio/info/desk/organism/ParticipantInfo";
import { FundAccountCard } from "./component/FundAccountCard";
import { BoxComponent } from "../../../../components-2.0v/box/BoxComponent";
import RowTitleBox from "../../../../components/box/RowTitleBox";

const DeskInnerContractTask = () => {
  const [
    portfolioInfo,
    participantMain,
    participantSub,
    reportAccount,
    inviteUsers,
    selectedReportAccount,
    contractModal,
    warnningMsg,
    rejactModal,
    doContract,
    doRejectContract,
    updateReportAccount,
    updateSelectedReportAccount,
    setRejactModal,
    isContractAble,
    setWarnningMsg,
    setContractModal,
  ] = useContractTask();

  if (!portfolioInfo) return null;

  return (
    <Container className="scroll__invisible">
      <FlexRow style={{ gap: 14 }}>
        <div style={{ flex: 1 }}>
          <TitleSection title="스타트업 개요">
            <StartupInfo startupInfo={portfolioInfo.startupInfo} />
          </TitleSection>
        </div>
        <div style={{ flex: 1 }}>
          <TitleSection title="심사역 개요">
            <ParticipantInfo participantInfo={portfolioInfo.participantInfo} />
          </TitleSection>
        </div>
      </FlexRow>

      <TitleSection title="투자 계정">
        <FlexRow style={{ gap: 14 }}>
          <FunAccountList style={{ flex: 1 }}>
            {reportAccount.map((item, index) => {
              return (
                <FundAccountCard
                  fundAccount={item}
                  isSelected={
                    item.acFundAccount?.acFundAccountId ===
                    selectedReportAccount?.acFundAccount?.acFundAccountId
                  }
                  onClickCard={() => {}}
                />
              );
            })}
          </FunAccountList>
          <BoxComponent style={{ flex: 3 }}>
            {selectedReportAccount && (
              <FlexRow>
                <FlexColumn>
                  <RowTitleBox className="body-14-rg" title="투자 재원">
                    <RowTitleBody>
                      {selectedReportAccount?.accountName}
                    </RowTitleBody>
                  </RowTitleBox>
                  <RowTitleBox className="body-14-rg" title="투자금">
                    <RowTitleBody>
                      {selectedReportAccount?.investmentAmount
                        ? AddComma(
                            parseInt(
                              `${selectedReportAccount.investmentAmount}`
                            )
                          )
                        : "-"}
                      원
                    </RowTitleBody>
                  </RowTitleBox>
                  <RowTitleBox className="body-14-rg" title="주식 종류">
                    <RowTitleBody>
                      {selectedReportAccount?.stockType?.name}
                    </RowTitleBody>
                  </RowTitleBox>
                  <RowTitleBox className="body-14-rg" title="배정 주식 수">
                    <RowTitleBody>
                      {selectedReportAccount?.stockNumber
                        ? AddComma(selectedReportAccount.stockNumber)
                        : "-"}
                      주
                    </RowTitleBody>
                  </RowTitleBox>
                  <RowTitleBox className="body-14-rg" title="신주 발행가">
                    <RowTitleBody>
                      {selectedReportAccount?.issuingPrice
                        ? AddComma(
                            parseInt(`${selectedReportAccount.issuingPrice}`)
                          )
                        : "-"}
                      원
                    </RowTitleBody>
                  </RowTitleBox>
                  <RowTitleBox className="body-14-rg" title="지분율">
                    <RowTitleBody>
                      {selectedReportAccount?.shareholdingRatio}%
                    </RowTitleBody>
                  </RowTitleBox>
                  <RowTitleBox className="body-14-rg" title="참여자">
                    <ParticipantList>
                      {selectedReportAccount?.investmentAssessmentInvite.map(
                        (item) => {
                          return (
                            <UserContainer
                              className="body-14-rg"
                              key={item.acUser.acUserId}
                            >
                              {item.acUser.profileImgUrl ? (
                                <img
                                  className="user__profile__img"
                                  src={item.acUser.profileImgUrl}
                                  alt="프로필 이미지"
                                />
                              ) : (
                                <span className="font-medium-10 user__profile__text">
                                  {item.acUser.name.slice(0, 2)}
                                </span>
                              )}
                              {item.acUser.name}
                            </UserContainer>
                          );
                        }
                      )}
                    </ParticipantList>
                  </RowTitleBox>
                </FlexColumn>

                <FlexColumn>
                  <RowTitleBox required className="body-14-rg" title="계약일">
                    <InputDate
                      editable={selectedReportAccount !== undefined}
                      placeholder="YYYY-MM-DD"
                      value={selectedReportAccount?.contractDate}
                      onChange={(e) => {
                        if (selectedReportAccount) {
                          const data = reportAccount.map((item) => {
                            if (
                              item.reportAccountId ===
                              selectedReportAccount.reportAccountId
                            ) {
                              const newData = { ...item, contractDate: e };
                              updateSelectedReportAccount(newData);
                              return newData;
                            }
                            return { ...item };
                          });
                          updateReportAccount([...data]);
                        }
                      }}
                    />
                  </RowTitleBox>
                  <RowTitleBox required className="body-14-rg" title="납입일">
                    <InputDate
                      editable={selectedReportAccount !== undefined}
                      placeholder="YYYY-MM-DD"
                      value={selectedReportAccount?.paymentDate}
                      onChange={(e) => {
                        if (selectedReportAccount) {
                          const data = reportAccount.map((item) => {
                            if (
                              item.reportAccountId ===
                              selectedReportAccount.reportAccountId
                            ) {
                              const newData = { ...item, paymentDate: e };
                              updateSelectedReportAccount(newData);
                              return newData;
                            }
                            return { ...item };
                          });
                          updateReportAccount([...data]);
                        }
                      }}
                    />
                  </RowTitleBox>
                </FlexColumn>
              </FlexRow>
            )}
          </BoxComponent>
        </FlexRow>
      </TitleSection>

      <ButtonContainer>
        <DefaultButton
          className="heading-16-md"
          buttonType="outline"
          sizeType="small"
          buttonColor="var(--red-500)"
          onClick={() => {
            setRejactModal(true);
          }}
          text="투자 반려하기"
        />

        <DefaultButton
          className="heading-16-md"
          buttonType={"filled"}
          sizeType="small"
          buttonColor={"var(--primary-blue)"}
          hoverColor="var(--blue-600)"
          borderColor="var(--gray-400)"
          text={"투자 확정하기"}
          disabled={!isContractAble()}
          disabledOnClick={() => {
            setWarnningMsg(true);
          }}
          onClick={() => setContractModal(true)}
        />
      </ButtonContainer>
      <ModalLayout
        isOpen={contractModal}
        isTransparent={true}
        isFullScreen
        toggle={() => {
          setContractModal(false);
        }}
      >
        <ConfirmModal
          type="confirm"
          title="투자 확정하기"
          message="해당 스타트업의 투자를 확정하시겠습니까?"
          positiveTitle="확정하기"
          negativeTitle="아니오"
          onClickPositiveBtn={doContract}
          onClickNegativeBtn={() => {
            setContractModal(false);
          }}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={warnningMsg}
        isTransparent={true}
        isFullScreen
        toggle={() => {
          setWarnningMsg(false);
        }}
      >
        <ConfirmModal
          type="alert"
          title="투자 확정하기"
          message="모든 투자계정의 계약일, 납입일을 입력해주세요."
          positiveTitle="확인"
          onClickPositiveBtn={() => {
            setWarnningMsg(false);
          }}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={rejactModal}
        isTransparent={true}
        isFullScreen
        toggle={() => {
          setRejactModal(false);
        }}
      >
        <ConfirmModal
          type="confirm"
          title="투자 반려하기"
          message="해당 스타트업의 투자를 반려하시겠습니까?"
          positiveTitle="반려하기"
          negativeTitle="아니오"
          onClickPositiveBtn={doRejectContract}
          onClickNegativeBtn={() => {
            setRejactModal(false);
          }}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: scroll;
  display: flex;
  flex-direction: column;

  gap: 24px;

  .invest_invite__info__container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 0 24px;
  }
`;

const ButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 66px;
  min-height: 66px;
  max-height: 66px;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;
  margin-top: auto;
  border-top: 2px solid var(--divider-200);

  gap: 12px;
`;

const ParticipantList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 9px;
`;

const UserContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  line-height: 1;
  .user__profile__img {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: 1px solid #f0f0f0;
  }
  .user__profile__text {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: 1px solid #f0f0f0;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FunAccountList = styled.div`
  width: 100%;
  height: 100%;
  max-height: 338px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents-01, #fff);
  padding: 16px;
  gap: 16px;
`;

const RowTitleBody = styled.div`
  margin-top: 9px;
`;
export default DeskInnerContractTask;
