import React, { createRef, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as IconCalendar } from "../../../../assets/image/ic_calendar.svg";

interface Props {
  width?: number;
  disabled?: boolean;
  value?: string;
  onChangeValue: (value: string) => void;
}
const DatabaseDatepicker = ({
  width,
  disabled,
  value,
  onChangeValue,
}: Props) => {
  const inputRef = createRef<HTMLInputElement>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const [type, setType] = useState<string>("text");
  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.type = "date";
    setType("date");
    onChangeValue(e.target.value || "");
    setIsFocused(true);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.type = "date";
    setType("date");
    onChangeValue(e.target.value || "");
    setIsFocused(false);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = e.target.value;
    onChangeValue(targetValue);
  };

  useEffect(() => {
    console.log(value);
  }, [value]);

  return (
    <Container
      width={width}
      type={type}
      isEmpty={value === undefined || value.length === 0}
      isFocused={isFocused}
    >
      <input
        ref={inputRef}
        className="font-regular-12"
        type={"text"}
        value={value || ""}
        disabled={disabled}
        placeholder={"YYYY-MM-DD"}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChangeInput}
        min="0000-01-01"
        max="9999-12-31"
      />

      {type === "text" ? (
        <IconCalendar
          onClick={() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
          style={{
            width: "24px",
            height: "24px",
          }}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

const Container = styled.div(
  ({
    width,
    type,
    isEmpty,
    isFocused,
  }: {
    width?: number;
    type: string;
    isEmpty: boolean;
    isFocused: boolean;
  }) => css`
    width: ${width ? `${width}px` : "130px"};
    height: 32px;
    border: 1px solid
      ${!isFocused && isEmpty ? "var(--grey_10)" : "var(--primary)"};
    border-radius: 4px;
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: ${type === "text" ? "8px" : ""};

    background: white;

    path {
      fill: ${!isFocused && isEmpty ? "var(--grey_20)" : "var(--primary)"};
    }

    input {
      width: ${type === "text" ? "80px" : width ? `${width}px` : "130px"};
      border-radius: 4px;
      background: transparent;
      border: 1px solid transparent;
      padding-left: ${type === "date" ? "8px" : ""};
      padding-right: ${type === "date" ? "5px" : ""};

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      :focus {
        outline: none;
        background-color: var(--white);
      }

      color: ${isEmpty ? "var(--grey_20)" : ""};
      ::placeholder {
        color: var(--grey_20);
      }
    }
    input[type="date"]::-webkit-clear-button,
    input[type="date"]::-webkit-inner-spin-button {
      display: none;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      background-image: ${!isFocused && isEmpty
        ? "url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 3C7.22386 3 7 3.22386 7 3.5V5H6C4.89543 5 4 5.89543 4 7V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V7C20 5.89543 19.1046 5 18 5H17V3.5C17 3.22386 16.7761 3 16.5 3C16.2239 3 16 3.22386 16 3.5V5H8V3.5C8 3.22386 7.77614 3 7.5 3ZM7 6V7.5C7 7.77614 7.22386 8 7.5 8C7.77614 8 8 7.77614 8 7.5V6H16V7.5C16 7.77614 16.2239 8 16.5 8C16.7761 8 17 7.77614 17 7.5V6H18C18.5523 6 19 6.44772 19 7V10H5V7C5 6.44772 5.44772 6 6 6H7ZM5 11V17C5 17.5523 5.44772 18 6 18H18C18.5523 18 19 17.5523 19 17V11H5Z' fill='%23C4C4C4'/%3E%3C/svg%3E\")"
        : "url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 3C7.22386 3 7 3.22386 7 3.5V5H6C4.89543 5 4 5.89543 4 7V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V7C20 5.89543 19.1046 5 18 5H17V3.5C17 3.22386 16.7761 3 16.5 3C16.2239 3 16 3.22386 16 3.5V5H8V3.5C8 3.22386 7.77614 3 7.5 3ZM7 6V7.5C7 7.77614 7.22386 8 7.5 8C7.77614 8 8 7.77614 8 7.5V6H16V7.5C16 7.77614 16.2239 8 16.5 8C16.7761 8 17 7.77614 17 7.5V6H18C18.5523 6 19 6.44772 19 7V10H5V7C5 6.44772 5.44772 6 6 6H7ZM5 11V17C5 17.5523 5.44772 18 6 18H18C18.5523 18 19 17.5523 19 17V11H5Z' fill='%23174274'/%3E%3C/svg%3E\")"};
      z-index: 1;
      width: 24px;
      height: 24px;
    }
  `
);

export default DatabaseDatepicker;
