import React from "react";
import styled from "styled-components";
import { getHMOnlyNum } from "../../../../../../common/commonUtil";
import { ChatingSpeachBubbleProps } from "../useChating";

interface Props {
  msg: ChatingSpeachBubbleProps;
  transfromPixel: (value: number) => number;
}

const SBUserMe: React.FC<Props> = (props) => {
  const getText = (
    isLast: boolean,
    createAt: string,
    text: string,
    boldText?: string[]
  ) => {
    let resultText = text;
    const boldTextIndexArr = boldText?.map((item) => {
      resultText = resultText.replace(item, "/%s/");
      return item;
    });
    if (boldTextIndexArr) {
      const splitData = resultText.split("/");
      let boldIndex = -1;

      return (
        <div className="bubble__item__wrapper">
          {isLast && <span className="bubble__time">{createAt}</span>}
          <div className="bubble__item body-14-rg">
            {splitData.map((item) => {
              if (
                item === "%s" &&
                boldTextIndexArr &&
                boldTextIndexArr[boldIndex + 1]
              ) {
                boldIndex += 1;
                return (
                  <span style={{ fontWeight: "bold" }}>
                    {boldTextIndexArr[boldIndex]}
                  </span>
                );
              }
              return <span>{item}</span>;
            })}
          </div>
        </div>
      );
    }
    return (
      <div className="bubble__item__wrapper">
        {isLast && (
          <span className="bubble__time caption-12-rg">
            {getHMOnlyNum(createAt)}
          </span>
        )}
        <p className="bubble__item body-14-rg">{text}</p>
      </div>
    );
  };

  return (
    <Container
      transfromPixel={props.transfromPixel}
      style={{
        paddingBottom: props.msg.groupIsLast
          ? props.transfromPixel(16)
          : props.transfromPixel(4),
      }}
    >
      <BubbleContainer transfromPixel={props.transfromPixel}>
        <div className="bubble__list">
          {getText(
            props.msg.groupIsLast || false,
            props.msg.createAt,
            props.msg.msgs?.text || "",
            props.msg.msgs?.boldText
          )}
        </div>
      </BubbleContainer>
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;

  padding-left: ${(props) => props.transfromPixel(24)}px;
  padding-right: ${(props) => props.transfromPixel(6)}px;
`;

const BubbleContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: auto;
  display: flex;
  .bubble__list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    gap: ${(props) => props.transfromPixel(4)}px;

    .bubble__item__wrapper {
      width: 100%;
      max-width: ${(props) => props.transfromPixel(254)}px;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      gap: ${(props) => props.transfromPixel(4)}px;

      .bubble__item {
        width: fit-content;
        max-width: ${(props) => props.transfromPixel(254)}px;
        padding: ${(props) => props.transfromPixel(8)}px
          ${(props) => props.transfromPixel(12)}px;
        background: #8e7dfa;
        color: #ffffff;

        border-radius: ${(props) => props.transfromPixel(4)}px;

        overflow: hidden;
        word-break: break-all;
        white-space: break-spaces;
      }
      .bubble__time {
        color: #5b5d61;
        line-height: ${(props) => props.transfromPixel(20)}px;
      }
    }
  }
`;
export default SBUserMe;
