import React, { useEffect, useRef, useState } from "react";
import Styled, { css } from "styled-components";

import { useMediaQuery } from "react-responsive";

import TaskLayout from "../pre-pitching/mobile/organism/TaskLayout";
import MobileContractTask from "./mobile/MobileContractTask";
import DeskContractTask from "./desktop/DeskContractTask";

const ContractTask = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;

  return (
    <TaskLayout title="투자계약">
      {isMobile ? <MobileContractTask /> : <DeskContractTask />}
    </TaskLayout>
  );
};

export default ContractTask;
