import React, { useEffect, useRef, useState } from "react";
import Styled, { css } from "styled-components";

import { DefaultButton } from "lattice_core";

import usePrepitchingTask from "../hook/usePrepitchingTask";
import TaskLayout from "./organism/TaskLayout";
import SectionView from "./organism/SectionView";

const DeskPrePitchingTask = () => {
  const [
    sections,
    isUpdateAuth,
    submitAble,
    validationCheckPreEvaluation,
    releasePreEvaluationResult,
    onChangeData,
  ] = usePrepitchingTask();

  if (sections.length === 0) return null;

  return (
    <Container className="scroll__invisible">
      <SectionContainer>
        {sections &&
          sections.map((item, index) => (
            <SectionView sectionItem={item} onChangeData={onChangeData} />
          ))}
        <ButtonContainer>
          {isUpdateAuth && (
            <DefaultButton
              className="heading-14-md"
              buttonType="filled"
              sizeType="small"
              buttonColor={"var(--primary-blue)"}
              hoverColor={"var(--blue-600)"}
              text={"제출하기"}
              disabled={!submitAble}
              onClick={() => {
                releasePreEvaluationResult();
              }}
            />
          )}
        </ButtonContainer>
      </SectionContainer>
    </Container>
  );
};

const Container = Styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const SectionContainer = Styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  min-width: 500px;
  max-width: 960px;
  margin-top: 1rem ;
  margin-bottom: 6rem ;
  margin-left: auto;
  margin-right: auto;
`;

const ButtonContainer = Styled.div(
  () => css`
    width: 100%;

    button {
      padding: 0 24px;
      height: 42px;
      border: 0;
      min-width: 120px;
      cursor: pointer;
      :hover {
        filter: brightness(1.2);
      }
    }
    .submit {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      color: var(--white);
    }
    .cancel {
      background: transparent;
      color: var(--grey_50);
    }
  `
);
export default DeskPrePitchingTask;
