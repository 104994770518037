import { BarControllerDatasetOptions } from "chart.js";
import { AccountType } from "../../../../dashboard/fund-account-info/interface/FundAccountInfo.interface";
import { StepProps } from "../../../../../components-2.0v/steps/StepComponent";

export const STEP_FIRST = 1;
export const STEP_SECOND = 2;
export const STEP_THIRD = 3;

export const FUND_STATUS_INVESTMENT = 1;
export const FUND_STATUS_OPERATING = 2;
export const FUND_STATUS_WATING = 3;
export const FUND_STATUS_PAUSE = 4;
export const FUND_STATUS_EXPIRED = 5;

type OriginAccountType = 1;
type FundAccountType = 2;

export type FundType = OriginAccountType | FundAccountType;

export interface FundAccount {
  isNew?: boolean;
  isChange?: boolean;
  isActive?: boolean; // 필수
  isInvest?: boolean;
  acFundAccountId?: number;
  type: FundType; // 필수 타입 (1: 본계정, 2: 펀드)
  status?: number; // 필수 투자상태 (1: 투자중, 2: 운영중, 3: 대기중, 4: 일시정지, 5: 기간만료)
  nameKo?: string; // 필수 유니크
  nameEn?: string; // 필수 유니크
  totalAmount?: number; // 약정금액
  investmentStartDate?: string;
  investmentEndDate?: string;
  operatingStartDate?: string;
  operatingEndDate?: string;
  addOperatingEndDate?: string; // 펀드 청산 연장 시 종료일
  investmentOrganizationCount?: number;
  serialNumber?: string; // 필수 유니크
  quorum?: number; // 정족수
  nickName?: string;
  concept?: string; // 컨셉
  terms?: string; // 약관 || 로 구분하여 스트링으로 저장
  baseRate?: number; // 기준수익률
  gpFee?: number; // gp 수수료
  fundManageUser: FundManager[];
  coreOperationalUser: CoreOperationalUser[];
  fundPurpose: FundPurpose[];
  investmentAssociation: InvestmentAssociationProps[];
}

export interface InvestmentAssociationProps {
  investmentAssociationId?: number;
  investmentAmount?: number;
  acInvestmentOrganization: {
    acInvestmentOrganizationId: number;
    name: string;
  };
}

export interface InvestmentAssociationTableProps {
  IOId: number;
  IOName: string;
  IORegistrationNumber: string;
  investmentAmount?: number;
  amountRatio?: number;
}

export interface FundManager {
  fundManageUserId?: number;
  acUser: FundUser;
}
export interface CoreOperationalUser {
  coreOperationalUserId?: number;
  acUser: FundUser;
}

export interface FundUser {
  acUserId: number;
  name: string;
  profileImgKey?: string;
  profileImgUrl?: string;
}

export interface FundPurpose {
  idx: number;
  id?: number;
  isMain?: boolean;
  target?: string;
  amount?: number;
  percent?: number;
}

export interface LPInfoProps {
  acInvestmentOrganizationId?: number;
  stakeType?: number; // 1: 개인, 2: 법인, 3: 펀드
  type: number; // 0: GP, 1: LP
  name?: string;
  uniqueNumber?: string;
  managerName?: string;
  managerEmail?: string;
  managerPhone?: string;
  isActive?: boolean;
}

export const FundAccontDetailStep: StepProps[] = [
  { step: STEP_FIRST, text: "기본정보 입력", isValidate: false },
  { step: STEP_SECOND, text: "출자정보 입력", isValidate: false },
  { step: STEP_THIRD, text: "목적정보 입력", isValidate: false },
];
