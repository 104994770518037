import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Close } from "../../../assets/image/modal_close.svg";
import { StockChangeListProps } from "../interface/type";
import {
  addCommasToIntegerPart,
  getNewDate,
  getYMD,
} from "../../../common/commonUtil";
import RowTitleBox from "../../../components/box/RowTitleBox";
import { StakeHolderType } from "../../../type/data";

interface Props {
  data?: StockChangeListProps;
  allStockChangeSubType: { id: number; name: string }[];
  close: () => void;
}
export const StockDetail: React.FC<Props> = (props) => {
  const TITLE_WIDTH = 120;
  const { data, allStockChangeSubType, close } = props;
  if (!data) return null;
  return (
    <Container>
      <Header>
        <div className="heading-18-sb">
          {getYMD(getNewDate(data.date))} {data.stockChangeType?.name}
        </div>
        <Close className="close" onClick={close} />
      </Header>
      <Body>
        <ContentsContainer>
          <RowTitleBox titleWidth={TITLE_WIDTH} title="주주명">
            <div className="row__item body-14-rg">
              {data.buyer!.name || "-"}
            </div>
          </RowTitleBox>
          <RowTitleBox titleWidth={TITLE_WIDTH} title="관계">
            <div className="row__item body-14-rg">
              {data.buyer!.relationship ? data.buyer!.relationship.name : "-"}
            </div>
          </RowTitleBox>
          <RowTitleBox titleWidth={TITLE_WIDTH} title="국적">
            <div className="row__item body-14-rg">
              {data.buyer!.stakeHolder.country
                ? data.buyer!.stakeHolder.country!.name
                : "-"}
            </div>
          </RowTitleBox>
          {data.buyer!.stakeHolder.type === StakeHolderType.INDIVIDUAL && (
            <FlexColumn>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="이메일">
                <div className="row__item body-14-rg">
                  {data.buyer!.email || "-"}
                </div>
              </RowTitleBox>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="연락처">
                <div className="row__item body-14-rg">
                  {data.buyer!.phone || "-"}
                </div>
              </RowTitleBox>
            </FlexColumn>
          )}
          {[StakeHolderType.CORPORATION, StakeHolderType.FUND].includes(
            data.buyer!.stakeHolder.type
          ) && (
            <FlexColumn>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="담당자 이름">
                <div className="row__item body-14-rg">
                  {data.buyer!.managerName || "-"}
                </div>
              </RowTitleBox>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="담당자 이메일">
                <div className="row__item body-14-rg">
                  {data.buyer!.managerEmail || "-"}
                </div>
              </RowTitleBox>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="담당자 연락처">
                <div className="row__item body-14-rg">
                  {data.buyer!.managerPhone || "-"}
                </div>
              </RowTitleBox>
              {data.buyer!.stakeHolder.type === StakeHolderType.FUND && (
                <FlexColumn>
                  <RowTitleBox titleWidth={TITLE_WIDTH} title="GP">
                    <div className="row__item body-14-rg">
                      {data.buyer?.stakeHolder.gp?.name || "-"}
                    </div>
                  </RowTitleBox>
                </FlexColumn>
              )}
            </FlexColumn>
          )}
          {data.round && (
            <RowTitleBox titleWidth={TITLE_WIDTH} title="라운드">
              <div className="row__item body-14-rg">{data.round.name}</div>
            </RowTitleBox>
          )}
          <RowTitleBox titleWidth={TITLE_WIDTH} title="주식 종류">
            <div className="row__item body-14-rg">
              {data.stockChangeType!.name}
            </div>
          </RowTitleBox>
          {[2, 3].includes(data.stockChangeType!.id) && (
            <RowTitleBox
              titleWidth={TITLE_WIDTH}
              title={data.stockChangeType!.id === 2 ? "배정 방식" : "거래 방식"}
            >
              <div className="row__item body-14-rg">
                {
                  allStockChangeSubType.find(
                    (item) => item.id === data.stockChangeSubType
                  )?.name
                }
              </div>
            </RowTitleBox>
          )}

          <RowTitleBox titleWidth={TITLE_WIDTH} title="취득 주식">
            <div className="row__item body-14-rg">
              {addCommasToIntegerPart(data.stockNumber)}주
            </div>
          </RowTitleBox>

          <RowTitleBox titleWidth={TITLE_WIDTH} title="한 주당 금액">
            <div className="row__item body-14-rg">
              {addCommasToIntegerPart(data.stockPrice)}원
            </div>
          </RowTitleBox>

          <RowTitleBox titleWidth={TITLE_WIDTH} title="총 거래 금액">
            <div className="row__item body-14-rg">
              {addCommasToIntegerPart(
                (data.stockNumber || 0) * (data.stockPrice || 0)
              )}
              원
            </div>
          </RowTitleBox>

          {data.seller && (
            <FlexColumn>
              <RowTitleBox titleWidth={TITLE_WIDTH} title="매도자">
                <div className="row__item body-14-rg">
                  {data.seller!.name || "-"}
                </div>
              </RowTitleBox>
              {data.seller!.stakeHolder.type === StakeHolderType.INDIVIDUAL && (
                <FlexColumn>
                  <RowTitleBox titleWidth={TITLE_WIDTH} title="이메일">
                    <div className="row__item body-14-rg">
                      {data.seller!.email || "-"}
                    </div>
                  </RowTitleBox>
                  <RowTitleBox titleWidth={TITLE_WIDTH} title="연락처">
                    <div className="row__item body-14-rg">
                      {data.seller!.phone || "-"}
                    </div>
                  </RowTitleBox>
                </FlexColumn>
              )}
              {[StakeHolderType.CORPORATION, StakeHolderType.FUND].includes(
                data.seller!.stakeHolder.type
              ) && (
                <FlexColumn>
                  <RowTitleBox titleWidth={TITLE_WIDTH} title="담당자 이름">
                    <div className="row__item body-14-rg">
                      {data.seller!.managerName || "-"}
                    </div>
                  </RowTitleBox>
                  <RowTitleBox titleWidth={TITLE_WIDTH} title="담당자 이메일">
                    <div className="row__item body-14-rg">
                      {data.seller!.managerEmail || "-"}
                    </div>
                  </RowTitleBox>
                  <RowTitleBox titleWidth={TITLE_WIDTH} title="담당자 연락처">
                    <div className="row__item body-14-rg">
                      {data.seller!.managerPhone || "-"}
                    </div>
                  </RowTitleBox>
                  {data.seller!.stakeHolder.type === StakeHolderType.FUND && (
                    <FlexColumn>
                      <RowTitleBox titleWidth={TITLE_WIDTH} title="GP">
                        <div className="row__item body-14-rg">
                          {data.seller?.stakeHolder.gp?.name || "-"}
                        </div>
                      </RowTitleBox>
                    </FlexColumn>
                  )}
                </FlexColumn>
              )}
            </FlexColumn>
          )}
        </ContentsContainer>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  border-bottom: 1px solid var(--gray-300);

  .close {
    cursor: pointer;
  }
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  overflow: auto;
  .row__item {
    margin-top: 9px;
  }
`;

const ContentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
