import React from "react";

import { rootStyle } from "../../../../../style/ThemeStyles";
import BaseCard from "../BaseCard";
import useFinancialStatement from "./hook/useFinancialStatement";

import { FinancialStatementModal } from "./organism/FinancialStatementModal";

import ModalLayout from "../../../../../components/modal/ModalLayout";
import SpinnerModal from "../../../../../components/modal/SpinnerModal";
import { ReportingFile } from "../../../../reporting-detail/interface/ReportingDetail.interface";

interface Props {
  portfolioId: number;
  isAuthority: boolean;
  data: ReportingFile;
  isOpen: boolean;
  index: number;
  screenWidthRate: number;
  year: number;
  quarter: number;
  isHalf: boolean;
  onClickCard: () => void;
  onFileReupload?: () => void;
  transfromPixel: (value: number) => number;
  reject: () => void;
  rejectCancel: () => void;
  confirm: () => void;
  confirmCancel: () => void;
}

const FinancialStatementCard = (props: Props) => {
  const {
    portfolioId,
    isAuthority,
    data,
    isOpen,
    index,
    screenWidthRate,
    year,
    quarter,
    isHalf,
    onClickCard,
    onFileReupload,
    transfromPixel,
    reject,
    rejectCancel,
    confirm,
    confirmCancel,
  } = props;
  const [
    localPdfFile,
    financialStatement,
    uploadModal,
    uploading,
    onChangeFile,
    onChangeFileDND,
    setUploadModal,
    initFinanceData,
    onChangeFinancialStatement,
    onSubmitAble,
    submit,
    validationAsset,
    validationDebtCapital,
    validationIncomeStatement,
    validationEqualAssetAndDebtCapital,
  ] = useFinancialStatement({
    portfolioId,
    data,
    isOpen,
    fileUploadInfo: { year, quarter, index },
    onFileReupload,
  });

  return (
    <div>
      <div onClick={onClickCard}>
        <BaseCard
          isAuthority={isAuthority}
          dragAble={isOpen && data.file === undefined}
          isDragging={isOpen && data.file === undefined}
          data={data}
          isOpen={isOpen}
          index={index}
          transfromPixel={transfromPixel}
          buttonTitle={data.file ? "파일교체" : "작성하기"}
          onClickButton={() => {
            setUploadModal(true);
          }}
          reject={reject}
          rejectCancel={rejectCancel}
          confirm={confirm}
          confirmCancel={confirmCancel}
        />
      </div>

      {isOpen && uploadModal && (
        <ModalLayout
          isOpen={uploadModal}
          toggle={() => {
            initFinanceData();
            setUploadModal(false);
          }}
          isFullScreen
          overlayColor={rootStyle.getPropertyValue("--dim-100")}
        >
          <FinancialStatementModal
            financialStatement={financialStatement}
            onChangeFinancialStatement={onChangeFinancialStatement}
            data={data}
            year={year}
            quarter={quarter}
            isHalf={isHalf}
            localPdfFile={localPdfFile}
            onChangeFile={onChangeFile}
            onChangeFileDND={onChangeFileDND}
            close={() => {
              initFinanceData();
              setUploadModal(false);
            }}
            onSubmitAble={onSubmitAble}
            submit={submit}
            validationAsset={validationAsset}
            validationDebtCapital={validationDebtCapital}
            validationIncomeStatement={validationIncomeStatement}
            validationEqualAssetAndDebtCapital={
              validationEqualAssetAndDebtCapital
            }
          />
        </ModalLayout>
      )}
      {uploading && (
        <SpinnerModal
          open={uploading}
          message={`${data.name} 업로드 중 입니다....`}
        />
      )}
    </div>
  );
};

export default FinancialStatementCard;
