import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import {
  CapitalAcquisitionPlan,
  capitalHalfQuarterStatusList,
  capitalQuarterStatusList,
  capitalStatusList,
} from "../../../interface/SBP.interface";
import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomSelector } from "../../table/custom-cell/CustomSelector";

import "react-tooltip/dist/react-tooltip.css";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  quarter: number;
  capitalDataList: CapitalAcquisitionPlan[];
  onDataChange: (data: CapitalAcquisitionPlan[]) => void;
}

export const CapitalAcquisitionPlanComponent: React.FC<Props> = (props) => {
  const [capitalDataList, updateCapitalDataList] = useState<
    CapitalAcquisitionPlan[] | undefined
  >(undefined);

  const headers: TableGridHeaderProps[] = [
    {
      header: "자금 조달 방법",
      key: "solutionCapitalAcquisition",
      width: 228,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            defaultData={data}
            selectList={capitalStatusList}
            placehoder="선택"
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "시기 (분기)",
      key: "capitalRaiseDate",
      width: 228,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            defaultData={data}
            selectList={
              props.quarter === 2
                ? capitalHalfQuarterStatusList
                : capitalQuarterStatusList
            }
            placehoder="선택"
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "금액 (원)",
      key: "capitalAmount",
      width: 178,
      tooltipId: "capitalAmount",
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            transfromPixel={(n) => n}
            placeholder="100,000,000"
            defaultValue={data}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "내용",
      key: "contents",
      width: 492,
      tooltipId: "contents",
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            transfromPixel={(n) => n}
            placeholder={getPlaceholderText(allData)}
            defaultValue={data}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];

  const getPlaceholderText = (rowData: any) => {
    switch (rowData.solutionCapitalAcquisition) {
      case "신주발행":
        return "투자기관명을 작성해주세요.";
      case "자금차입":
        return "대출기관명(신용보증기금, 은행 등)을 작성해 주세요.";
      case "정부지원금":
        return "정부지원사업명을 작성해 주세요.";
      case "가수금":
        return "자세한 내용을 입력해주세요.";
      default:
        return "내용을 입력해주세요.";
    }
  };
  const addRowData = () => {
    const newDate: CapitalAcquisitionPlan = {};
    const newCapitalData: CapitalAcquisitionPlan[] = [
      ...(capitalDataList || []),
      newDate,
    ];
    updateCapitalDataList(newCapitalData);
  };

  const onClickDeleteBtn = (id: number) => {
    if (!capitalDataList) return;
    const newCapitalData = capitalDataList.filter(
      (item, index) => index !== id
    );
    updateCapitalDataList([...newCapitalData]);
  };

  useEffect(() => {
    updateCapitalDataList(props.capitalDataList);
  }, [props.capitalDataList]);

  useEffect(() => {
    if (!capitalDataList) return;
    props.onDataChange([...capitalDataList]);
  }, [capitalDataList]);

  if (!capitalDataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={capitalDataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
      <Tooltip
        className="body-14-rg"
        anchorSelect=".capitalAmount"
        place="top"
        border={0}
        style={{ zIndex: 99999999, borderRadius: 4 }}
      >
        정부지원금의 경우, 당해연도 사용 가능한 정부지원금을 작성해 주세요.
      </Tooltip>
      <Tooltip
        className="body-14-rg"
        anchorSelect=".contents"
        place="top"
        border={0}
        style={{ zIndex: 99999999, lineHeight: 1.5, borderRadius: 4 }}
      >
        신주발행의 경우, 투자기관명을 작성해 주세요.
        <br />
        자금차입의 경우, 대출기관명(신용보증기금, 은행 등)을 작성해 주세요.
        <br />
        정부지원금의 경우, 정부지원사업명을 작성해 주세요.
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;
