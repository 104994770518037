import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  value?: string;
  tabIndex?: number;
  maxLine?: number;
  maxLength?: number;
  onChange?: (e: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

export const AutoHeightTextarea: React.FC<Props> = (props) => {
  const { maxLine = 10000 } = props;
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [value, setValue] = useState<string>(props.defaultValue || "");
  const [preValue, setPreValue] = useState<string>("");
  const [isEdit, setEdit] = useState<boolean>(false);
  const [render, setRender] = useState<boolean>(true);

  const reRender = () => setRender((prevState) => !prevState);

  const onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setEdit(true);
  };
  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setEdit(false);
    props.onBlur && props.onBlur(e);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!textareaRef.current) return;

    console.log(textareaRef.current.clientHeight);

    console.log((textareaRef.current.clientHeight - 13) / 20);

    if ((textareaRef.current.clientHeight - 13) / 20 > maxLine)
      return setValue(preValue);

    setValue(e.target.value);
    setPreValue(e.target.value);
    props.onChange && props.onChange(e.target.value);
  };

  useEffect(() => {
    setValue(props.defaultValue || "");
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.value) setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (isEdit && textareaRef && textareaRef.current) {
      textareaRef.current.value = value || "";
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current?.scrollHeight + "px";
      textareaRef.current.focus();
    }
  }, [isEdit]);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.value = value || "";
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current?.scrollHeight + "px";
    }
  }, [value]);

  return (
    <Container className={props.className || ""} isFocusing={isEdit}>
      <TextContainer
        className={`font-medium-14`}
        tabIndex={props.tabIndex}
        onFocus={() => {
          setEdit(true);
        }}
      >
        <textarea
          ref={textareaRef}
          className="font-medium-14 scroll__invisible"
          style={{ color: "black" }}
          autoComplete="off"
          wrap="on"
          placeholder={props.placeholder || ""}
          value={value}
          onBlur={onBlur}
          maxLength={props.maxLength}
          onChange={(e) => onChangeInput(e)}
          onInput={(e) => {
            if (!textareaRef || !textareaRef.current) return;
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height =
              textareaRef.current?.scrollHeight + "px";
          }}
        />
      </TextContainer>
    </Container>
  );
};

const Container = styled.div<{ isFocusing: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid
    ${(props) => (props.isFocusing ? "var(--primary)" : "#dcdcdc")};
  border-radius: 4px;

  :hover {
    background-color: #fafcff;
  }
`;

const TextContainer = styled.div`
  position: relative;
  width: 100%;
  textarea {
    width: 100%;
    height: fit-content;
    padding: 5px 10px 7px 10px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid transparent;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    resize: none;
    appearance: none;
    :focus {
      outline: none;
    }
    ::placeholder {
      color: var(--grey_20);
    }
  }
`;
