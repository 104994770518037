import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const File = (props: Props) => (
  <svg
    width={60}
    height={60}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.75C15.4477 3.75 15 4.19772 15 4.75V55.25C15 55.8023 15.4477 56.25 16 56.25H51.5C52.0523 56.25 52.5 55.8023 52.5 55.25V18.75L45 11.25L37.5 3.75H16Z"
        fill="#EDEEEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5 18.75L52.5 18.75L45 11.25L37.5 3.75L37.5 17.75C37.5 18.3023 37.9477 18.75 38.5 18.75Z"
        fill="#D5D6D8"
      />
    </g>
    <g>
      <rect x={7.5} y={30} width={35} height={18.75} rx={1} fill="#64676D" />
      <g>
        <path
          d="M20.2637 35.4707H14.4925V43.9082H16.0112V40.4545H19.6112V38.9132H16.0112V36.967H20.2637V35.4707Z"
          fill="white"
        />
        <path
          d="M21.3375 35.4707V43.9082H22.8675V35.4707H21.3375Z"
          fill="white"
        />
        <path
          d="M25.9214 35.4707H24.3802V43.9082H29.9152V42.3895H25.9214V35.4707Z"
          fill="white"
        />
        <path
          d="M36.7872 35.4707H30.9034V43.9082H36.7872V42.4007H32.4222V40.3307H36.4159V38.812H32.4222V36.967H36.7872V35.4707Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

export { File };
