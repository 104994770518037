import React, { useState } from "react";
import Styled, { css } from "styled-components";

import ResultSingle from "./ResultSingle";
import ResultMultiple from "./ResultMultiple";
import ResultLinear from "./ResultLinear";
import {
  ResultType,
  TextAnswerProps,
} from "../../../interface/PrePitchingDetail.interface";

import { TextList } from "../TextList";

interface Props {
  item: ResultType;
  userInfoHidden?: boolean;
}
const ResultItem: React.FC<Props> = (props) => {
  const { item, userInfoHidden } = props;
  const [render, setRender] = useState<boolean>(true);
  const reRender = () => setRender((prevState) => !prevState);
  return (
    <Container>
      <span className="heading-16-md">{item.title}</span>
      {item.type === "short" || item.type === "long" ? (
        <TextList
          type={item.type}
          answers={
            item.answer?.map((item: any): TextAnswerProps => ({ ...item })) ||
            []
          }
          userInfoHidden={userInfoHidden}
        />
      ) : item.type === "single" ? (
        <ResultSingle item={item} />
      ) : item.type === "multiple" ? (
        <ResultMultiple type={item.type} item={item} />
      ) : item.type === "linear" ? (
        <ResultLinear type={item.type} item={item} />
      ) : null}
    </Container>
  );
};

const Container = Styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 20px;

    padding: 28px 28px;
    background: var(--white);
    border-bottom: 1px solid var(--gray-200);
  `
);

export default ResultItem;
