import React from "react";
import styled from "styled-components";

import { StartupInfo } from "./organism/StartupInfo";
import { ParticipantInfo } from "./organism/ParticipantInfo";
import { BusinessInfo } from "./organism/BusinessInfo";
import { DefaultButton } from "lattice_core";
import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import usePortfolioInfo from "../hook/usePortfolioInfo";
import { TitleSection } from "./components/TitleSection";

const MobilePortfolioInfo: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const {
    portfolioId,
    portfolioNavInfo,
    isEditable,
    init,
    emptyData = [],
    duplicateData = [],
  } = props;

  const [
    myInfo,
    portfolioInfo,
    updateCommentId,
    setUpdateCommentId,
    sendComment,
    updateComment,
    deleteComment,
    goToModify,
  ] = usePortfolioInfo();

  if (!portfolioInfo) return null;
  return (
    <Container>
      <ContentContainer id={"root__component"}>
        <ContentsWrapper style={{ paddingBottom: isEditable ? 98 : 32 }}>
          <TitleSection title="스타트업 개요">
            <StartupInfo
              startupInfo={portfolioInfo.startupInfo}
              emptyData={emptyData}
              duplicateData={duplicateData}
            />
          </TitleSection>
          <TitleSection title="심사역 개요">
            <ParticipantInfo participantInfo={portfolioInfo.participantInfo} />
          </TitleSection>

          <TitleSection title="사업 정보">
            <BusinessInfo
              myInfo={myInfo}
              portfolioPhase={portfolioNavInfo.pfPhase.type}
              businessInfo={portfolioInfo.businessInfo}
              updateCommentId={updateCommentId}
              emptyData={emptyData}
              sendComment={sendComment}
              updateComment={updateComment}
              deleteComment={deleteComment}
              setUpdateCommentId={setUpdateCommentId}
            />
          </TitleSection>
        </ContentsWrapper>
      </ContentContainer>
      {isEditable && (
        <Footer>
          <DefaultButton
            className="heading-16-md"
            buttonType="colored-outline"
            sizeType="small"
            buttonColor={"var(--text-01)"}
            borderColor={"var(--gray-400)"}
            text="수정"
            onClick={(e) => {
              e && e.stopPropagation();
              goToModify();
            }}
          />
        </Footer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  bottom: 0px;
  padding: 0 24px;

  border-top: 2px solid var(--divider-200, #edeeef);
  background: var(--background-header, #fff);
`;

export default MobilePortfolioInfo;
