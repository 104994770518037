import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  IAAccount,
  IACompanyInfo,
  IAReportAccount,
  InvestmentAssessment,
  Participant,
  PortfolioInfo,
  SignatureInfo,
  UserInfo,
} from "../../../../type/data";
import axios from "axios";
import * as api from "../../../../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getInvestmentAssessmentIdInLocation,
  getPortfolioIdInLocation,
} from "../../../../common/commonUtil";

import {
  getPortfolioDetailInfo,
  getPortfolioInfo,
  getPortfolioInvestmentAssessment,
  getPortfolioParticipant,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import { getAllAcUser } from "../../../../api/repository/accelerator/AcceleratorRepository";
import { PortfolioInfoProps } from "../../../portfolio/info/interface/PortfolioInfo.interface";
import { ContractReportAccount } from "../interface/ContractTask.interface";

const useContractTask = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const portfolioId = useRef<number>(getPortfolioIdInLocation(location));
  const investmentAssessmentId = useRef<number>(
    getInvestmentAssessmentIdInLocation(location)
  );

  const [document, updateDocument] = useState<InvestmentAssessment | undefined>(
    undefined
  );
  const totalStockNumber = useRef<number>(0);

  const [reportAccount, updateReportAccount] = useState<
    ContractReportAccount[]
  >([]);
  const [selectedReportAccount, updateSelectedReportAccount] = useState<
    ContractReportAccount | undefined
  >(undefined);

  const [isUpdateAuth, setIsUpdateAuth] = useState<boolean>(false);
  const [portfolioInfo, setPortfolioInfo] = useState<
    PortfolioInfoProps | undefined
  >(undefined);

  const [participantMain, setParticipantMain] = useState<
    Participant | undefined
  >();
  const [participantSub, setParticipantSub] = useState<Participant[]>([]);
  const [inviteUsers, setInviteUsers] = useState<UserInfo[]>([]);

  const [contractModal, setContractModal] = useState<boolean>(false);
  const [warnningMsg, setWarnningMsg] = useState<boolean>(false);
  const [rejactModal, setRejactModal] = useState<boolean>(false);

  const initResouse = async () => {
    const result = await getPortfolioDetailInfo(portfolioId.current);
    if (result) {
      setPortfolioInfo({ ...result });
    }

    // const _portfolioInfo = await getPortfolioInfo(portfolioId.current);
    const _portfolioParticipant = await getPortfolioParticipant(
      portfolioId.current
    );
    const _investmentAssessment = await getPortfolioInvestmentAssessment(
      portfolioId.current,
      investmentAssessmentId.current
    );
    const _allAcUser = await getAllAcUser("user");

    if (_portfolioParticipant) {
      setParticipantMain(
        _portfolioParticipant.find((item) => item.screenRoleId === 1)
      );
      setParticipantSub([
        ..._portfolioParticipant.filter((item) => item.screenRoleId !== 1),
      ]);
    }

    if (_investmentAssessment) {
      updateDocument(_investmentAssessment);
      updateReportAccount(
        _investmentAssessment.investmentAssessmentReportAccount.map(
          (item, index) => {
            return {
              ...item.investmentAssessmentAccount,
              reportAccountId: `account_${index}`,
              investmentAssessmentInvite: item.investmentAssessmentInvite,
            };
          }
        ) || []
      );
      if (_allAcUser) {
        const _inviteUser: UserInfo[] = [];
        _investmentAssessment.investmentAssessmentReportAccount.forEach(
          (item) => {
            item.investmentAssessmentInvite.forEach((invite) => {
              const user = _inviteUser.find(
                (item) => item.id === invite.acUser.acUserId
              );
              if (!user) {
                _inviteUser.push({
                  type: "AC_USER",
                  id: invite.acUser.acUserId,
                  name: invite.acUser.name,
                  color: "#000000",
                  isShowProfile: invite.acUser.isShowProfile,
                  profileImgUrl: invite.acUser.profileImgUrl,
                });
              }
            });
          }
        );
        setInviteUsers([..._inviteUser]);
      }
    }
  };

  const doContract = async () => {
    try {
      const param = {
        portfolio: {
          portfolioId: portfolioId.current,
        },
        investmentAssessment: {
          investmentAssessmentId: investmentAssessmentId.current,
        },
        investmentAccount: reportAccount.map((item) => {
          return {
            ...item,
          };
        }),
      };
      const result = await axios.post(api.doContract(), param);
      if (result.status === 200) {
        navigate(-1);
      }
    } catch (error) {}
  };

  const doRejectContract = async () => {
    try {
      const param = {
        investmentAssessmentId: investmentAssessmentId.current,
      };
      const result = await axios.post(api.rejectContract(), param);
      console.log(result);
      if (result.status === 200) {
        navigate(-1);
      }
    } catch (error) {}
  };

  const isContractAble = () => {
    return (
      reportAccount.filter(
        (item) =>
          item.paymentDate === undefined || item.contractDate === undefined
      ).length === 0
    );
  };

  const initContract = async () => {
    initResouse();
  };

  useEffect(() => {
    if (selectedReportAccount === undefined && reportAccount.length > 0) {
      updateSelectedReportAccount(reportAccount[0]);
    }
  }, [reportAccount]);

  useLayoutEffect(() => {
    initContract();
  }, []);

  return [
    portfolioInfo,
    participantMain,
    participantSub,
    reportAccount,
    inviteUsers,
    selectedReportAccount,
    contractModal,
    warnningMsg,
    rejactModal,
    doContract,
    doRejectContract,
    updateReportAccount,
    updateSelectedReportAccount,
    setRejactModal,
    isContractAble,
    setWarnningMsg,
    setContractModal,
  ] as const;
};

export default useContractTask;
