const config = {
  env: process.env.REACT_APP_ENV,
  ver: process.env.REACT_APP_CURRENT_VER,
  clientUrl: process.env.REACT_APP_CLIENT_URL,
  portfolioClientUrl: process.env.REACT_APP_PTYPE_CLIENT_URL,
  apiUrl: process.env.REACT_APP_API_DOMAIN,
  companyName: process.env.REACT_APP_COMPANY_NAME,
  gClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  gClientSecret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
  gApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  gScopes: process.env.REACT_APP_GOOGLE_SCOPES,
  gFolderId: process.env.REACT_APP_GOOGLE_FOLDER_ID,
  gDiscoveryDocs: process.env.REACT_APP_GOOGLE_DISCOVERY_DOCS,
  gReportTemplet: process.env.REACT_APP_GOOGLE_REPORT_TEMPLET,
  gReportBusiness: process.env.REACT_APP_GOOGLE_REPORT_BUSINESS,
};

export default config;
