import * as ExcelJS from "exceljs";
import saveAs from "file-saver";

const headerDefaultStyle: Partial<ExcelJS.Style> = {
  font: {
    size: 8,
    bold: true,
  },
  alignment: {
    vertical: "middle",
    horizontal: "center",
  },
};
const portfolioName: Partial<ExcelJS.Column> = {
  header: "기업명",
  key: "portfolioName",
  width: 30,
  style: { ...headerDefaultStyle },
};
const phase: Partial<ExcelJS.Column> = {
  header: "상태 ",
  key: "phase",
  width: 10,
  style: { ...headerDefaultStyle },
};
const participantMain: Partial<ExcelJS.Column> = {
  header: "담당자명",
  key: "participantMain",
  width: 10,
  style: { ...headerDefaultStyle },
};

const document1Column: Partial<ExcelJS.Column> = {
  header: "주식변동내역(엑셀)",
  key: "report-1",
  width: 15,
  style: { ...headerDefaultStyle },
};
const document2Column: Partial<ExcelJS.Column> = {
  header: "주식변동내역(pdf)",
  key: "report-2",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document3Column: Partial<ExcelJS.Column> = {
  header: "주주명부",
  key: "report-3",
  width: 10,
  style: { ...headerDefaultStyle },
};
const document4Column: Partial<ExcelJS.Column> = {
  header: "법인등기부등본",
  key: "report-4",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document5Column: Partial<ExcelJS.Column> = {
  header: "4대보험가입자명부",
  key: "report-5",
  width: 30,
  style: { ...headerDefaultStyle },
};
const document6Column: Partial<ExcelJS.Column> = {
  header: "요약사업계획서",
  key: "report-6",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document7Column: Partial<ExcelJS.Column> = {
  header: "전년도 결산재무제표",
  key: "report-7",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document8Column: Partial<ExcelJS.Column> = {
  header: "가결산재무제표",
  key: "report-8",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document9Column: Partial<ExcelJS.Column> = {
  header: "사업자등록증",
  key: "report-9",
  width: 20,
  style: { ...headerDefaultStyle, numFmt: "#,##0주" },
};
const document10Column: Partial<ExcelJS.Column> = {
  header: "벤처기업인증서",
  key: "report-10",
  width: 20,
  style: { ...headerDefaultStyle, numFmt: "#,##0원" },
};
const document11Column: Partial<ExcelJS.Column> = {
  header: "기업부설연구소인증서",
  key: "report-11",
  width: 20,
  style: { ...headerDefaultStyle, numFmt: "#,##0원" },
};

export const createReportUploadStatusList = async (
  title?: string,
  data?: any[]
) => {
  if (!data || data.length === 0) return;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("주식변동내역"); // sheet 이름이 My Sheet
  // sheet 데이터 설정

  const columnsArr = [portfolioName, phase, participantMain];
  const newData = data.map((item: any) => {
    const transData: any = {
      portfolioName: item.portfolioName,
      phase: item.phase === 5 ? "관리" : "손상",
      participantMain: item.participantMain,
    };
    if (item["report-1"] !== undefined) {
      const fileName = item["report-1"].file
        ? item["report-1"].file.uploadStatusName
        : "X";
      transData["report-1"] = fileName;
    }
    if (item["report-2"] !== undefined) {
      const fileName = item["report-2"].file
        ? item["report-2"].file.uploadStatusName
        : "X";
      transData["report-2"] = fileName;
    }
    if (item["report-3"] !== undefined) {
      const fileName = item["report-3"].file
        ? item["report-3"].file.uploadStatusName
        : "X";
      transData["report-3"] = fileName;
    }
    if (item["report-4"] !== undefined) {
      const fileName = item["report-4"].file
        ? item["report-4"].file.uploadStatusName
        : "X";
      transData["report-4"] = fileName;
    }
    if (item["report-5"] !== undefined) {
      const fileName = item["report-5"].file
        ? item["report-5"].file.uploadStatusName
        : "X";
      transData["report-5"] = fileName;
    }
    if (item["report-6"] !== undefined) {
      const fileName = item["report-6"].file
        ? item["report-6"].file.uploadStatusName
        : "X";
      transData["report-6"] = fileName;
    }
    if (item["report-7"] !== undefined) {
      const fileName = item["report-7"].file
        ? item["report-7"].file.uploadStatusName
        : "X";
      transData["report-7"] = fileName;
    }
    if (item["report-8"] !== undefined) {
      const fileName = item["report-8"].file
        ? item["report-8"].file.uploadStatusName
        : "X";
      transData["report-8"] = fileName;
    }
    if (item["report-9"] !== undefined) {
      const fileName = item["report-9"].file
        ? item["report-9"].file.uploadStatusName
        : "X";
      transData["report-9"] = fileName;
    }
    if (item["report-10"] !== undefined) {
      const fileName = item["report-10"].file
        ? item["report-10"].file.uploadStatusName
        : "X";
      transData["report-10"] = fileName;
    }
    if (item["report-11"] !== undefined) {
      const fileName = item["report-11"].file
        ? item["report-11"].file.uploadStatusName
        : "X";
      transData["report-11"] = fileName;
    }

    return transData;
  });

  console.log(newData);
  if (data[0]["report-1"] !== undefined) {
    columnsArr.push(document1Column);
  }
  if (data[0]["report-2"] !== undefined) {
    columnsArr.push(document2Column);
  }
  if (data[0]["report-3"] !== undefined) {
    columnsArr.push(document3Column);
  }
  if (data[0]["report-4"] !== undefined) {
    columnsArr.push(document4Column);
  }
  if (data[0]["report-5"] !== undefined) {
    columnsArr.push(document5Column);
  }
  if (data[0]["report-6"] !== undefined) {
    columnsArr.push(document6Column);
  }
  if (data[0]["report-7"] !== undefined) {
    columnsArr.push(document7Column);
  }
  if (data[0]["report-8"] !== undefined) {
    columnsArr.push(document8Column);
  }
  if (data[0]["report-9"] !== undefined) {
    columnsArr.push(document9Column);
  }
  if (data[0]["report-10"] !== undefined) {
    columnsArr.push(document10Column);
  }
  if (data[0]["report-11"] !== undefined) {
    columnsArr.push(document11Column);
  }

  worksheet.columns = [...columnsArr];

  const row1 = worksheet.getRow(1);
  row1.height = 20;

  newData?.forEach((item, index) => {
    worksheet.insertRow(index + 2, { ...item });
  });
  // 다운로드
  const mimeType = {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], mimeType);

  saveAs(blob, `${title ? `${title}_` : ""}제출 현황.xlsx`);
};
