import React from "react";
import styled from "styled-components";
import { FileType, NewOldStockType, QuorumType } from "../../../../type/data";
import useEvaluationTask from "../hook/useEvaluationTask";
import { InvestmentDeliberationCommitteeMinutes } from "./organism/InvestmentDeliberationCommitteeMinutes";

import { toast } from "react-toastify";
import { DefaultButton } from "lattice_core";
import { viewPdfPopup } from "../../../../common/commonUtil";

export interface MinutesProps {
  isFundManager?: boolean;
  fundAccount?: {
    acfundAccountId: number;
    accountName: string;
    newOldStock?: NewOldStockType; //신주,구주
  };
  buyCompany?: string;
  buyCompanyValue?: string;
  companyName?: string;
  date?: string;
  stockType?: string;
  totalInvestmentAmount?: string;
  totalTradeAmount?: string;
  investmentAmount?: string;
  issuingPrice?: string;
  curNewIssuingPrice?: string;
  postTotalStockNumber?: string;
  totalTradeStock?: string;
  stockNumber?: string;
  shareholdingRatio?: string;
  discount?: string;

  reportFile?: FileType;

  acUserName?: string;
  isAgreement?: boolean;
  comment?: string;
  signature?: {
    type: number;
    url?: string;
    path?: any[];
  };

  totalVotingRight?: number;
  totalInviteUserNum?: number;
  agreementNum?: number;
  oppositionNum?: number;

  votingResult?: string;

  quorumType?: QuorumType;
}

export const MobileEvaluationTask = () => {
  const [
    portfolioId,
    logoImage,
    navigate,
    targetDocument,
    signatureData,
    updateTargetDocument,
    setSignatureData,
    isVoteDone,
    isInAssessmentDate,
    onVote,
  ] = useEvaluationTask();

  return (
    <Container>
      <Wrapper>
        <MinuteWarpper>
          {targetDocument && (
            <InvestmentDeliberationCommitteeMinutes
              portfolioId={portfolioId}
              minutesDocument={targetDocument}
              signatureData={signatureData}
              logoImage={logoImage}
              onChangeData={(data) => {
                console.log(data);

                updateTargetDocument({ ...data });
              }}
              onChangeSignature={(data) => {
                console.log(data);

                setSignatureData(data);
              }}
            />
          )}
        </MinuteWarpper>
        <TitleContainer>
          <DefaultButton
            buttonType="colored-outline"
            sizeType="small"
            buttonColor="#000000"
            borderColor="var(--gray-600)"
            disabled={!targetDocument || !targetDocument.reportFile}
            onClick={() => {
              if (targetDocument && targetDocument.reportFile) {
                viewPdfPopup(targetDocument.reportFile.key, 1000);
              }
            }}
            text={"투자심사 보고서 열기"}
          />
          <DefaultButton
            buttonType="filled"
            sizeType="small"
            buttonColor="var(--primary-blue)"
            hoverColor="var(--blue-600)"
            disabled={
              targetDocument === undefined ||
              targetDocument.isAgreement === undefined ||
              targetDocument.comment === undefined ||
              (targetDocument.comment && targetDocument.comment.length < 20) ||
              !targetDocument.comment ||
              signatureData === undefined
            }
            onClick={() => {
              onVote();
            }}
            disabledOnClick={() => {
              if (!isInAssessmentDate) {
                toast.error("아직 투자심사 기간이 아닙니다.", {
                  autoClose: 1500,
                  toastId: "not-in-assessment-date",
                  position: "bottom-center",
                });
              }
            }}
            text={isVoteDone ? "수정하기" : "제출하기"}
          />
        </TitleContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MinuteWarpper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 63px);
  display: flex;
  flex-direction: column;

  border: 1px solid var(--gray-400);
`;

const TitleContainer = styled.div`
  position: sticky;
  width: 100%;
  height: 63px;
  min-height: 63px;
  bottom: 0;

  display: flex;
  flex-direction: row;
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;

  background-color: white;
  border-top: 1px solid var(--gray-400);
`;
