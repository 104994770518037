import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import StockHolderGridData from "./StockHolderGridData";

export interface GridProps {
  headers: GridHeaderProps[];
  data?: any[];
  bodyHeight: number;
  headerHeight: number;
  rowHeight: number;
  emptyMessage?: string;
  scrollAble?: boolean;
  onClickRow?: (data: any) => void;
}

export interface GridHeaderProps {
  header: string;
  key: string;
  width: number;

  style?: React.CSSProperties;
  customHeaderCell?: (data?: any) => JSX.Element;
  customCell?: (data: any, rowIndex?: number) => JSX.Element;
}

const StockHolderGrid: React.FC<GridProps> = ({
  headers,
  data = [],
  bodyHeight,
  headerHeight,
  rowHeight,
  emptyMessage,
  scrollAble = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [header, setHeader] = useState<GridHeaderProps[]>(headers);
  const _emptyMessage: string = emptyMessage || "리스트가 비었습니다.";

  useEffect(() => {
    setHeader(headers);
  }, [headers]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Container ref={ref}>
      <StockHolderGridData
        headers={header}
        allData={data.map((item: any, index) => ({ ...item, id: index }))}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        bodyHeight={bodyHeight}
        scrollAble={scrollAble}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #828282;

  background: rgba(0, 0, 0, 0.05);
  border: 1px solid #c4c4c4;
  border-radius: 10px;
`;

export default StockHolderGrid;
