import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { SelectorProps } from "../../../../../type/data";
import { ReactComponent as Arrow } from "../../../../../assets/image/dropdown_arrow.svg";

interface Props {
  isClear?: boolean;
  isReadOnly?: boolean;
  disabled?: boolean;
  defaultData?: SelectorProps;
  selectList: SelectorProps[];
  placehoder?: string;
  borderColor?: string;
  width?: number | string;
  height?: number | string;
  onChangeData: (data: SelectorProps) => void;
}

export const SelectorSearch: React.FC<Props> = (props) => {
  const {
    isReadOnly,
    disabled,
    defaultData,
    selectList,
    placehoder,
    borderColor = "var(--gray-300)",
    width = "100%",
    height = 40,
    onChangeData,
  } = props;

  const selectorRef = useRef<HTMLInputElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string>("");
  const [showList, updateShowList] = useState<boolean>(false);

  useEffect(() => {
    console.log(defaultData);
    if (defaultData) {
      const selectedData = selectList.find(
        (item) => item.id === defaultData.id
      );
      setValue(selectedData?.text || "");
    } else {
      setValue("");
    }
  }, [defaultData]);

  const handleMouseDown = (event: any) => {
    if (optionRef.current && !optionRef.current.contains(event.target)) {
      updateShowList(false);
    }
  };

  const rectPositoin = () => {
    const root = window.document.querySelector("#root__component");
    if (selectorRef.current && root) {
      const targetRect = selectorRef.current.getBoundingClientRect();
      if (optionRef.current) {
        optionRef.current.style.width = `${targetRect.width}px`;
        optionRef.current.style.top = `${
          targetRect.top + targetRect.height + 4
        }px`;
        optionRef.current.style.left = `${targetRect.left}px`;
      }
    }
  };
  const handleScroll = (e: any) => {
    updateShowList(false);
    selectorRef.current?.blur();
  };

  useEffect(() => {
    const root = window.document.querySelector("#root__component");

    window.addEventListener("mousedown", handleMouseDown);
    root && root.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      root && root.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showList) {
      rectPositoin();
    }
  }, [showList]);

  return (
    <Container className={`body-14-rg ${disabled ? "disabled" : ""}`}>
      <FlexRow
        style={{
          width: width,
          height: height,
        }}
      >
        <InputContainer
          ref={selectorRef}
          className={`${disabled || isReadOnly ? "disabled" : ""}`}
          style={{
            border: `1px solid ${borderColor}`,
            width: width,
            height: height,
          }}
          placeholder={placehoder}
          value={value}
          onFocus={() => {
            updateShowList((prev) => !prev);
          }}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={(e) => {
            const users = selectList.filter((item) =>
              item.text.includes(value)
            );

            if (e.key === "Enter") {
              if (users.length === 1) {
                onChangeData && onChangeData(users[0]);
                setValue("");
              }
            }
          }}
        />
        {!isReadOnly && !disabled && <Arrow className="arrow" />}
      </FlexRow>
      {!isReadOnly && !disabled && showList && (
        <ListContainer ref={optionRef}>
          {selectList
            .filter((item) => item.text.includes(value))
            .map((item) => {
              return (
                <ListItem
                  className={`${item.text === value ? "selected" : ""}`}
                  onClick={() => {
                    onChangeData && onChangeData(item);
                    setValue("");
                  }}
                >
                  {item.text}
                </ListItem>
              );
            })}
          {selectList.filter((item) => item.text.includes(value)).length ===
            0 && (
            <div className="body-14-rg" style={{ paddingLeft: 10 }}>
              검색결과 없음
            </div>
          )}
        </ListContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  &.disabled {
    background-color: var(--gray-50);
  }
`;
const FlexRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;

  .arrow {
    position: absolute;
    right: 10px;
    top: 10px;
    path {
      fill: var(--icon-02);
    }
  }
`;
const InputContainer = styled.input`
  width: 100%;
  height: 34px;
  border: 1px solid var(--gray-200);

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  padding-right: 22px;

  cursor: pointer;

  &.disabled {
    padding: 6px 0px;
    cursor: default;
    border-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  ::placeholder {
    color: var(--gray-500);
  }

  :hover {
    border: 1px solid var(--blue-200);
  }
  :focus {
    border: 1px solid var(--blue-200);
    outline: none;
  }
`;

const ListContainer = styled.div`
  position: fixed;
  max-height: 236px;
  display: flex;
  flex-direction: column;

  z-index: 2;

  padding: 8px 0px;

  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  background-color: var(--background-contents02);
  overflow: scroll;
`;

const ListItem = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 9px 16px;

  &.selected {
    background-color: var(--blue-100);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
