import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Document, PDFPageProxy, Page, pdfjs } from "react-pdf";
import { PDFPageImage } from "../interface/Minutes.interface";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  portfolioId: number;
  file: any;
  onLoaded: (pageNums: number, images: PDFPageImage[]) => void;
  onError: () => void;
}
const PDFDocument: React.FC<Props> = (props) => {
  const [pdfFile, setPdfFile] = useState<any>();

  const images = useRef<PDFPageImage[]>([]);
  const [numPages, setNumPages] = useState<number>(0);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    images.current = [];
  };

  const onRenderSuccess = async (page: PDFPageProxy) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const dpi = window.devicePixelRatio;
    const scale = 2; // Adjust scale to get better resolution

    if (context) {
      if ([90, 270].includes(page.rotate)) {
        canvas.width = page.originalHeight * scale * dpi;
        canvas.height = page.originalWidth * scale * dpi;
        canvas.style.width = `${page.originalHeight * scale}px`;
        canvas.style.height = `${page.originalWidth * scale}px`;
      } else {
        canvas.width = page.originalWidth * scale * dpi;
        canvas.height = page.originalHeight * scale * dpi;
        canvas.style.width = `${page.originalWidth * scale}px`;
        canvas.style.height = `${page.originalHeight * scale}px`;
      }

      context.setTransform(1, 0, 0, 1, 0, 0);

      await page
        .render({
          canvasContext: context,
          viewport: page.getViewport({ scale: scale * dpi }),
        })
        .promise.then(() => {
          // Convert canvas to image URL
          const imageUrl = canvas.toDataURL("image/png");
          images.current.push({
            pageNumber: page.pageNumber,
            imgUrl: imageUrl,
            width: canvas.width,
            height: canvas.height,
            aspectRatio: `${canvas.width}/${canvas.height}`,
          });

          if (images.current.length === numPages) {
            images.current.sort((a, b) => a.pageNumber - b.pageNumber);
            props.onLoaded(numPages, images.current);
          }
        });
    }
  };

  useEffect(() => {
    setPdfFile(props.file);
  }, [props.file]);

  return (
    <Container>
      <Document
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(e) => {
          console.log(e);
          props.onError();
        }}
      >
        {Array.from({ length: numPages }, (_, index) => (
          <Page
            key={index}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onRenderSuccess={onRenderSuccess}
          />
        ))}
      </Document>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: white;
`;
export default PDFDocument;
