import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DefaultUser } from "../../../../../assets/image/default_user_img.svg";
import { TinyUserInfo } from "../../../../../type/data";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { getYMD } from "../../../../../common/commonUtil";

interface Props {
  user: TinyUserInfo;
  date: string;
}
export const UserCard: React.FC<Props> = (props) => {
  const { user, date } = props;
  return (
    <Container>
      <AvatarContainer>
        {user.profileImgUrl ? (
          <img
            className="avatar"
            src={user.profileImgUrl}
            loading="lazy"
            alt={"profile img"}
          />
        ) : (
          <DefaultUser className="avatar" />
        )}
      </AvatarContainer>
      <UserInfoContainer>
        <FlexRow className="caption-12-md">
          <span style={{ width: 40, color: "var(--text-04)" }}>이름</span>
          <span>{user.name}</span>
        </FlexRow>
        <FlexRow className="caption-12-md">
          <span style={{ width: 40, color: "var(--text-04)" }}>담당일</span>
          <span>{getYMD(date)}</span>
        </FlexRow>
      </UserInfoContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: max-content;
  height: max-content;

  padding: 8px 12px;
  background: var(--background-contents01);
  border: 1px solid var(--gray-200);
  border-radius: 4px;

  gap: 24px;

  .icon {
    cursor: pointer;
  }
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    border: 1px solid var(--gray-200);
    border-radius: 50%;
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
