import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DocumentFile } from "../ReportingDetail";
import { getSBPData } from "../../repository/ReportingRepository";
import SummaryBusinessPlanV1 from "./v1/SummaryBusinessPlanV1";
import SummaryBusinessPlanV2 from "./v2/SummaryBusinessPlanV2";

interface Props {
  file?: DocumentFile;
  sbpData?: any;
}

const SummaryBusinessPlanViewer: React.FC<Props> = (props: Props) => {
  const [document, setDocument] = useState<any | undefined>(undefined);
  const getSBP = async () => {
    if (props.file && props.file.pfReportDocumentData) {
      const result = await getSBPData(props.file.pfReportDocumentData.id);
      if (result) {
        console.log(result);
        setDocument(result);
      }
    }
  };

  useEffect(() => {
    if (props.sbpData) {
      setDocument({ data: props.sbpData });
    } else {
      getSBP();
    }
  }, []);

  console.log(document);

  if (!document) return null;

  return document.data.ver === "1" ? (
    <SummaryBusinessPlanV1 document={document.data} />
  ) : document.data.ver === "2" ? (
    <SummaryBusinessPlanV2 document={document.data} />
  ) : null;
};
export default SummaryBusinessPlanViewer;
