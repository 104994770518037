import React from "react";
import styled, { css } from "styled-components";

import useExcavationCreate from "../hook/useExcavationCreate";
import { TitleSection } from "./components/TitleSection";
import { CompanyOverview } from "./organism/CompanyOverview";
import { CompanyCeoInfo } from "./organism/CompanyCeoInfo";
import { CompanyBusinessDetail } from "./organism/CompanyBusinessDetail";
import { CompanyExcavation } from "./organism/CompanyExcavation";
import { DefaultButton } from "lattice_core";

import SpinnerModal from "../../../components/modal/SpinnerModal";
import { NavigationLayout } from "../../../components/navigation/NavigationLayout";

const MobileExcavationCreate = () => {
  const [
    loadingModal,
    allIndustrialTech,
    allUser,
    allChannel,
    isSubmitAble,
    errorMSGArr,
    isWeaknessDuplicate,
    updateWeaknessDuplicate,
    onChangeData,
    onClickCancel,
    onSubmit,
    onCreatePortfolio,
  ] = useExcavationCreate();

  return (
    <ExcavationContainer>
      <ContentsHeader>
        <span className="heading-20-sb" style={{ color: "var(--text-01)" }}>
          새로운 기업 추가
        </span>
      </ContentsHeader>
      <ContentsBodyWrapper id="root__component">
        <ContentsBody>
          <TitleSection title="사업개요">
            <CompanyOverview
              allIndustrialTech={allIndustrialTech}
              onChangeData={onChangeData}
            />
          </TitleSection>

          <TitleSection title="발굴 정보">
            <CompanyExcavation
              allUser={allUser}
              allChannel={allChannel}
              onChangeData={onChangeData}
            />
          </TitleSection>

          <TitleSection title="기업 연락 정보">
            <CompanyCeoInfo
              onChangeData={onChangeData}
              errorMSGArr={errorMSGArr}
            />
          </TitleSection>

          <TitleSection title="기업 상세">
            <CompanyBusinessDetail onChangeData={onChangeData} />
          </TitleSection>
        </ContentsBody>
      </ContentsBodyWrapper>

      <Footer>
        <DefaultButton
          className="heading-16-md"
          buttonType="text"
          sizeType="small"
          text="취소"
          onClick={(e) => {
            e && e.stopPropagation();
            onClickCancel();
          }}
        />
        <DefaultButton
          className="heading-16-md"
          buttonType="filled"
          sizeType="small"
          buttonColor={"var(--primary-blue)"}
          hoverColor="var(--blue-600)"
          text="추가하기"
          disabled={!isSubmitAble}
          onClick={(e) => {
            e && e.stopPropagation();
            onSubmit();
          }}
        />
      </Footer>
      {loadingModal && (
        <SpinnerModal open={loadingModal} message={"수기 등록 중..."} />
      )}
    </ExcavationContainer>
  );
};

const ExcavationContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100%);
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  width: 100%;
  height: 62px;
  min-height: 62px;
  max-height: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  border-bottom: 1px solid var(--divider-200);
  background: var(--background-header);
`;
const ContentsBodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;
const ContentsBody = styled.div`
  flex: 1 0 0;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 32px;

  padding: 24px 0;
  padding-bottom: 102px;
`;
const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  bottom: 0px;
  padding: 0 24px;

  gap: 12px;
  z-index: 30;

  border-top: 2px solid var(--divider-200, #edeeef);
  background: var(--background-header, #fff);
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default MobileExcavationCreate;
