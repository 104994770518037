import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";

import { ReactComponent as Arrow } from "../../assets/image/icon-arrow-24.svg";

import usePDFViewer from "./usePDFViewer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  file: File;
}
const PDFViewerPage: React.FC<Props> = (props) => {
  const { file } = props;
  const [
    fullPageRef,
    scrollXY,
    isLoading,
    rootRefWidth,
    scale,
    loaded,
    blobFile,
    rootRef,
    numPages,
    pageNumber,
    renderedPageNumber,
    renderedScale,
    setRenderedPageNumber,
    setRenderedScale,
    setPageNumber,
    setNumPages,
    prevPage,
    nextPage,
    onChangeScale,
    onLoadSuccess,
    onScrollHandler,
    onPageLoaded,
  ] = usePDFViewer(file);

  if (!blobFile) return null;

  return (
    <Container ref={rootRef}>
      <div ref={fullPageRef} className="full__page" onScroll={onScrollHandler}>
        <Document
          // className="full__page"
          file={URL.createObjectURL(blobFile)}
          options={{
            cMapUrl: "https://unpkg.com/pdfjs-dist@2.16.105/cmaps/",
            cMapPacked: true,
          }}
          onLoadSuccess={onLoadSuccess}
        >
          <Page
            key={`${pageNumber}@${scale}`}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={scale}
            width={rootRefWidth.current}
            onLoadSuccess={(e) => {
              onPageLoaded();
            }}
          />
        </Document>
      </div>
      <PageController>
        <div className="arrow__btn" onClick={prevPage}>
          <Arrow width={16} height={16} />
        </div>
        <div className="caption-14-md" style={{ lineHeight: 1 }}>
          {pageNumber} of {numPages}
        </div>
        <div className="arrow__btn next" onClick={nextPage}>
          <Arrow width={16} height={16} />
        </div>
      </PageController>

      <ScaleController className="heading-16-md">
        <span
          className="arrow__btn"
          onClick={() => {
            onChangeScale(-0.5);
          }}
        >
          {" "}
          -{" "}
        </span>
        <span className="caption-12-md">{scale * 100}%</span>
        <span
          className="arrow__btn"
          onClick={() => {
            onChangeScale(0.5);
          }}
        >
          {" "}
          +{" "}
        </span>
      </ScaleController>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;

  .full__page {
    height: 100%;
    width: 100%;
    overflow: scroll;
    opacity: 1;
  }
`;
const PageController = styled.div`
  position: absolute;
  left: 50%;
  bottom: 24px;
  width: 180px;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  transform: translateX(-50%);

  padding: 0 12px;

  border-radius: 4px;
  border: 1px solid var(--gray-400, #d5d6d8);
  background: var(--background-contents01, #fff);
  /* light/shdow250-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.05);
  z-index: 3;

  .arrow__btn {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    :hover {
      border-radius: 4px;
      background-color: var(--hover-100);
    }
  }
  .next {
    transform: rotate(180deg);
  }
`;

const ScaleController = styled.div`
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 120px;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  padding: 0 12px;

  border-radius: 4px;
  border: 1px solid var(--gray-400, #d5d6d8);
  background: var(--background-contents01, #fff);
  /* light/shdow250-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.05);
  z-index: 3;

  .arrow__btn {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    :hover {
      border-radius: 4px;
      background-color: var(--hover-100);
    }
  }
`;

export default React.memo(PDFViewerPage);
