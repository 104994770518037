import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Radio } from "../../assets/image/icon_radio.svg";

interface Props {
  className?: string;
  isChecked?: boolean;
  onClick?: () => void;
}

export const RadioBox: React.FC<Props> = (props) => {
  const { className = "caption-12-rg", isChecked, onClick, children } = props;
  return (
    <Container
      className={`${className} ${isChecked ? "checked" : ""}`}
      onClick={onClick}
    >
      <Radio />
      <TitleContainer>{children}</TitleContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  &.checked {
    path {
      fill: var(--primary-blue);
    }
  }
`;
const TitleContainer = styled.div`
  width: 100%;
  color: var(--text-03);
`;
