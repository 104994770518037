import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RowTitleBox from "../../../../../components/box/RowTitleBox";

import { PortfolioPhase } from "../../../portfolio-detail/interface/PortfolioNavigation.interface";
import { BusinessInfoProps } from "../../../info/interface/PortfolioInfo.interface";
import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import { IndustryTech, SelectorProps } from "../../../../../type/data";
import CheckedText from "../../../../../components-2.0v/check-box/CheckedText";
import { Radio } from "../../../../../components-2.0v/radio-box/Radio";
import { FileUpload } from "../components/FileUpload";
import { ChangeDataProps } from "../../hook/usePortfolioInfoModify";
import { DealFlowPhase } from "../../../../deal-flow/interface/DealFlow.interface";

interface Props {
  businessInfo: BusinessInfoProps;
  allIndustrialTech: IndustryTech[];
  phase?: DealFlowPhase;
  portfolioPhase: PortfolioPhase;
  onChangeData: (data: ChangeDataProps) => void;
}
export const BusinessSummary: React.FC<Props> = (props) => {
  const {
    businessInfo,
    allIndustrialTech,
    phase,
    portfolioPhase,
    onChangeData,
  } = props;

  const [mainIndustrialTech, updateMainIndustrialTech] = useState<
    SelectorProps | undefined
  >(undefined);
  const [subIndustrialTech, updateSubIndustrialTech] = useState<
    SelectorProps[]
  >([]);
  const [allSelectorIndustrialTech, setAllSelectorIndustrialTech] = useState<
    SelectorProps[]
  >([]);

  useEffect(() => {
    const selectorData = allIndustrialTech.map((item): SelectorProps => {
      return {
        id: parseInt(`${item.acIndustrialTechId}`),
        text: item.name,
        data: item,
      };
    });
    setAllSelectorIndustrialTech(selectorData);
  }, [allIndustrialTech]);

  useEffect(() => {
    const main = businessInfo.industrialTech?.find((item) => item.isMain);
    const sub = businessInfo.industrialTech?.filter((item) => !item.isMain);

    const mainSelector = allSelectorIndustrialTech.find(
      (item) => item.id === main?.acIndustrialTech.acIndustrialTechId
    );
    const subSelector = allSelectorIndustrialTech.filter(
      (item) =>
        sub &&
        sub
          .map((item) => item.acIndustrialTech.acIndustrialTechId)
          .includes(item.id)
    );

    updateMainIndustrialTech(mainSelector);
    updateSubIndustrialTech(subSelector);
  }, [allSelectorIndustrialTech]);

  return (
    <Container>
      <RowTitleBox title="한줄 요약" required titleWidth={128}>
        <InputBox
          defaultValue={businessInfo.description}
          placeholder="제품/서비스 완성도 등의 측면에서 작성해 주세요."
          width={900}
          maxLength={50}
          isShowLength
          onChange={(value) => {
            onChangeData({ type: "description", value: value });
          }}
        />
      </RowTitleBox>

      <RowTitleBox title="주 산업 기술" required titleWidth={128}>
        <div style={{ marginTop: 10 }}>
          <Radio
            textClassName="caption-14-rg"
            defaultData={mainIndustrialTech?.text}
            selectList={allSelectorIndustrialTech}
            onChangeData={(data) => {
              updateMainIndustrialTech(data);
              onChangeData({ type: "mainIndustrialTech", value: data.data });

              const subMainSame = subIndustrialTech.findIndex(
                (item) => item.id === data.id
              );
              if (subMainSame !== -1) {
                subIndustrialTech.splice(subMainSame, 1);
                updateSubIndustrialTech([...subIndustrialTech]);
                onChangeData({
                  type: "subIndustrialTech",
                  value: [...subIndustrialTech],
                });
              }
            }}
          />
        </div>
      </RowTitleBox>

      <RowTitleBox title="부 산업 기술" titleWidth={128}>
        <IndustiryTechContainer>
          {allSelectorIndustrialTech
            .filter((item) => item.id !== mainIndustrialTech?.id)
            .map((item) => (
              <CheckedText
                className="caption-14-rg"
                isChecked={subIndustrialTech
                  .map((data) => data.id)
                  .includes(item.id)}
                onClick={() => {
                  const dataIndex = subIndustrialTech.findIndex(
                    (data) => data.id === item.id
                  );
                  if (dataIndex !== -1) {
                    subIndustrialTech.splice(dataIndex, 1);
                    updateSubIndustrialTech([...subIndustrialTech]);
                    onChangeData({
                      type: "subIndustrialTech",
                      value: [...subIndustrialTech.map((item) => item.data)],
                    });
                  } else {
                    const newData = [...subIndustrialTech, item];
                    updateSubIndustrialTech([...newData]);
                    onChangeData({
                      type: "subIndustrialTech",
                      value: [...newData.map((item) => item.data)],
                    });
                  }
                }}
              >
                {item.text}
              </CheckedText>
            ))}
        </IndustiryTechContainer>
      </RowTitleBox>

      {portfolioPhase === "EXCAVATION" && (
        <RowTitleBox
          title="상세 IR자료"
          required={phase && phase === "investment"}
          titleWidth={128}
        >
          <FileUpload
            defaultData={businessInfo.businessPlanFile?.fileName}
            width={524}
            height={40}
            maxSizeMB={61}
            onChangeFile={(data) => {
              onChangeData({
                type: "businessPlanFile",
                value: data,
              });
            }}
          />
        </RowTitleBox>
      )}

      {portfolioPhase === "MANAGE" && (
        <RowTitleBox title="상세 IR자료" titleWidth={128}>
          <FileUpload
            defaultData={businessInfo.detailProposal?.fileName}
            width={524}
            height={40}
            maxSizeMB={61}
            onChangeFile={(data) => {
              onChangeData({
                type: "detailProposal",
                value: data,
              });
            }}
          />
        </RowTitleBox>
      )}

      {portfolioPhase === "MANAGE" && (
        <RowTitleBox title="요약 IR자료" titleWidth={128}>
          <FileUpload
            defaultData={businessInfo.summaryProposal?.fileName}
            width={524}
            height={40}
            maxSizeMB={61}
            onChangeFile={(data) => {
              onChangeData({
                type: "summaryProposal",
                value: data,
              });
            }}
          />
        </RowTitleBox>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 28px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const IndustiryTechContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
  margin-top: 9px;
`;
