import React, { useEffect, useState } from "react";
import {
  OnlyNum,
  OnlyFloat,
  RemoveComma,
  RegexpNumberWithComma,
  RemoveHypen,
} from "../../../common/commonUtil";

export type InputType =
  | "text"
  | "number"
  | "numberWithComma"
  | "percent"
  | "businessNumber"
  | "residentRegistrationNumber"
  | "phone";

const useInput = () => {
  const getValueByType = (type: InputType, value: string) => {
    switch (type) {
      case "number":
        return OnlyNum(`${value}`);
      case "numberWithComma":
        value = OnlyNum(`${value}`);
        return RegexpNumberWithComma(RemoveComma(value));
      case "percent":
        const _value = `${value}`;
        return OnlyFloat(_value);
      case "phone": {
        // 숫자만 추출
        if (value.length === 0) return "";
        const digits = value.slice(0, 13).replace(/\D/g, "");
        // 정규식을 이용하여 하이픈 포맷 적용
        const formatted = digits.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        return formatted;
      }
      case "businessNumber": {
        console.log(value);
        let numValue = OnlyNum(`${value}`);
        if (numValue.length >= 10) {
          numValue = RemoveHypen(value);
          return `${numValue.slice(0, 3)}-${numValue.slice(
            3,
            5
          )}-${numValue.slice(5, 10)}`;
        }
        return numValue;
      }
      case "residentRegistrationNumber": {
        console.log(value);
        let numValue = OnlyNum(`${value}`);
        if (numValue.length >= 13) {
          numValue = RemoveHypen(value);
          return `${numValue.slice(0, 6)}-${numValue.slice(6, 13)}`;
        }
        return numValue;
      }
      default:
        return value;
    }
  };

  return [getValueByType] as const;
};

export default useInput;
