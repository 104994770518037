import React from "react";
import styled from "styled-components";
import { DefaultChip, DefaultChipWithCircle } from "lattice_core";
import { SBPTabProps } from "../interface/SBP.interface";

interface Props {
  isReadOnly: boolean;
  tabList: SBPTabProps[];
  selectedTabId: number;
  lastUpdate?: string;
  onClickTab: (id: number) => void;
}

export const SBPTabLayout: React.FC<Props> = (props: Props) => {
  if (props.tabList.length === 0) return null;
  return (
    <Container>
      <Wrapper>
        <TabListContainer>
          {props.tabList.map((item) => {
            return (
              <TabItemContainer
                className={`${
                  props.selectedTabId === item.id ? "selected" : ""
                }`}
                onClick={() => {
                  if (item.id === props.selectedTabId) return;
                  props.onClickTab(item.id);
                }}
              >
                <div className="heading-16-sb">
                  {item.id}. {item.name}
                </div>
                {!props.isReadOnly && (
                  <Chip
                    className={`caption-9-md ${
                      item.isComplete ? "completed" : ""
                    }`}
                  >
                    {item.isComplete ? "작성완료" : "작성필요"}
                  </Chip>
                )}
              </TabItemContainer>
            );
          })}
        </TabListContainer>
        {props.lastUpdate && (
          <span className="caption-12-rg" style={{ color: "var(--text-04)" }}>
            마지막 저장일시: {props.lastUpdate}
          </span>
        )}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  width: 100%;

  padding: 36px 0px 32px 0px;
  z-index: 1000;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+99&1+80,0+100 */
  background: -moz-linear-gradient(
    top,
    rgba(251, 252, 253, 1) 0%,
    rgba(251, 252, 253, 1) 80%,
    rgba(251, 252, 253, 0.05) 99%,
    rgba(251, 252, 253, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(251, 252, 253, 1) 0%,
    rgba(251, 252, 253, 1) 80%,
    rgba(251, 252, 253, 0.05) 99%,
    rgba(251, 252, 253, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(251, 252, 253, 1) 0%,
    rgba(251, 252, 253, 1) 80%,
    rgba(251, 252, 253, 0.05) 99%,
    rgba(251, 252, 253, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  border-radius: 8px;
  background-color: var(--background-pagebutton);
  padding: 12px;
`;

const TabListContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const TabItemContainer = styled.div`
  min-width: 260px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px 20px;
  border-radius: 8px;

  gap: 4px;

  transition: all 0.2s ease;

  cursor: pointer;

  &.selected {
    background: var(--background-contents01);
    box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.1);
    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    background-color: var(--gray-300);
  }
`;

const Chip = styled.span`
  width: fit-content;
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 20px;

  transition: all 0.2s ease;
  background-color: var(--gray-400);
  color: var(--text-02);

  &.completed {
    background-color: var(--blue-400);
  }
`;
