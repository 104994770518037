import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  defaultValue?: string;
  isShowValidationMsg?: boolean;
  validationMsg?: string;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onEnterPress?: (value: string) => void;
  transfromPixel: (value: number) => number;
}
const EditorText: React.FC<Props> = (props) => {
  const {
    className,
    style,
    defaultValue,
    isShowValidationMsg,
    validationMsg,
    placeholder,
    readOnly,
    onChange,
    onBlur,
    onFocus,
    onEnterPress,
    transfromPixel,
  } = props;

  const [value, updateValue] = useState<string>(defaultValue || "");
  const [focussing, updateFocussing] = useState<boolean>(false);

  useEffect(() => {
    updateValue(defaultValue || "");
  }, [defaultValue]);

  return (
    <Container
      className={className}
      style={style}
      transfromPixel={transfromPixel}
    >
      <InputContainer transfromPixel={transfromPixel}>
        <InputComponent
          readOnly={readOnly}
          disabled={readOnly}
          transfromPixel={transfromPixel}
          className={`${isShowValidationMsg ? "show__msg" : ""} body-14-md`}
          type="text"
          placeholder={placeholder}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onEnterPress && onEnterPress(value);
            }
          }}
          onChange={(e) => {
            updateValue(e.target.value);
            onChange && onChange(e);
          }}
          onFocus={(e) => {
            updateFocussing(true);
            onFocus && onFocus(e);
          }}
          onBlur={(e) => {
            updateFocussing(false);
            onBlur && onBlur(e);
          }}
          value={value}
        />
      </InputContainer>
      {validationMsg && isShowValidationMsg && (
        <span className="validation__msg body-14-md">{validationMsg}</span>
      )}
    </Container>
  );
};
const Container = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: ${(props) => props.transfromPixel(334)}px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.transfromPixel(8)}px;
  .validation__msg {
    color: var(--red-500);
  }
`;

const InputContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  position: relative;
  width: ${(props) => props.transfromPixel(332)}px;
  height: ${(props) => props.transfromPixel(40)}px;
  overflow: hidden;
`;
const InputComponent = styled.input<{
  transfromPixel: (value: number) => number;
}>`
  width: ${(props) => props.transfromPixel(332)}px;
  height: ${(props) => props.transfromPixel(40)}px;
  padding: ${(props) => props.transfromPixel(12)}px
    ${(props) => props.transfromPixel(10)}px;

  border: ${(props) => props.transfromPixel(1)}px solid var(--gray-300);
  border-radius: ${(props) => props.transfromPixel(4)}px;
  transition: 0.2s;
  background: var(--background-contents01);

  ::placeholder {
    color: var(--text-06);
  }

  :disabled {
    border: ${(props) => props.transfromPixel(1)}px solid transparent;
    :hover {
      background-color: var(--background-contents01);
    }
  }

  &.show__msg {
    border: ${(props) => props.transfromPixel(1)}px solid var(--red-500);
    animation: shake 200ms;
  }

  :focus {
    outline: none;
    border: ${(props) => props.transfromPixel(1)}px solid
      var(--focus-field-blue);

    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    background-color: var(--hover-100);
  }

  @keyframes shake {
    25% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    50% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
    75% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    100% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export default EditorText;
