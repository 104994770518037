import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

import { TableGridHeaderProps } from "./TableGrid";
import CustomHeader from "./CustomHeader";

import { ReactComponent as CheckT } from "../../../../../assets/image/new_check_box_true.svg";
import { ReactComponent as CheckF } from "../../../../../assets/image/new_check_box_false.svg";

interface Props {
  rootWidth?: number;
  headers: TableGridHeaderProps[];
  transfromPixel: (value: number) => number;
  checkable?: boolean;
  isChecked?: boolean;
  onCheckData?: () => void;
}

const TableHeader: React.FC<Props> = ({
  rootWidth = 2000,
  checkable,
  headers,
  onCheckData,
  isChecked,
  transfromPixel,
}) => {
  const getWidthAuto = () => {
    const width = headers
      .filter((item) => item.width !== -1)
      .map((item) => item.width)
      .reduce((p, c) => p + c, 0);

    const autoWidth = rootWidth - width;
    return autoWidth;
  };

  return (
    <Container>
      {checkable && (
        <CheckCellConatiner
          style={{
            width: transfromPixel(56),
            minWidth: transfromPixel(56),
            maxWidth: transfromPixel(56),
            height: transfromPixel(34),
            minHeight: transfromPixel(34),
            maxHeight: transfromPixel(34),
            backgroundColor: "#f6faff",
            borderBottom: "1px solid #e3e4e5",
          }}
          onClick={() => {
            onCheckData && onCheckData();
          }}
        >
          {isChecked ? (
            <CheckT width={transfromPixel(16)} height={transfromPixel(16)} />
          ) : (
            <CheckF width={transfromPixel(16)} height={transfromPixel(16)} />
          )}
        </CheckCellConatiner>
      )}
      {headers.map((item, index) => {
        return (
          <CellConatiner
            key={index}
            className={`body-14-rg ${index === 0 ? "first" : ""} ${
              index === headers.length - 1 ? "last" : ""
            }`}
            style={{
              ...item.style,
              width: item.width === -1 ? getWidthAuto() : item.width || 100,
              minWidth: item.width === -1 ? getWidthAuto() : item.width || 100,
              height: transfromPixel(34),
              minHeight: transfromPixel(34),
              maxHeight: transfromPixel(34),
            }}
          >
            {item.customHeaderCell ? (
              item.customHeaderCell()
            ) : (
              <CustomHeader
                title={item.header}
                transfromPixel={transfromPixel}
                sortType={item.sortType}
                sortable={item.sortable}
                onClick={item.onCellClicked}
              />
            )}
          </CellConatiner>
        );
      })}
    </Container>
  );
};

const Container = styled.tr`
  position: sticky;
  top: 0px;
  width: auto;
  display: flex;
  flex-direction: row;

  :nth-child(1) {
    border-top-left-radius: 4px;
  }
  :nth-last-child(1) {
    border-top-right-radius: 4px;
  }
`;

const CellConatiner = styled.td`
  width: 100%;
  cursor: default;
  color: var(--primary);
  background-color: #f6faff;
  border-bottom: 1px solid #e3e4e5;
`;

export const CheckCellConatiner = styled.td`
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.opened {
    path {
      fill: #d1e3ff;
    }
  }
`;

export default TableHeader;
