import React, { useEffect, useState } from "react";

import styled from "styled-components";

import { ReactComponent as CheckT } from "../../../../assets/image/new_check_box_true.svg";
import { ReactComponent as CheckF } from "../../../../assets/image/new_check_box_false.svg";
import { GridDataProps, GridHeaderProps } from "./grid/LatticeGrid";
import { CustomColorLabel } from "../../../../components-2.0v/label/CustomColorLabel";

interface Props {
  headerData: GridHeaderProps[];
  rowData: GridDataProps;
  transfromPixel: (value: number) => number;
  isChecking?: boolean;
  checkable?: boolean;
  isChecked?: boolean;
  isCheckedDataNotZero?: boolean;
  onClickRow?: () => void;
  onCheckData?: (data: GridDataProps) => void;
  fileDownloadCallback?: () => void;
}

interface ReportMobileData {
  portfolioName: string;
  participantMain: string;
  phase: number;
  totalRequiredFile: number;
  totalNotSubmitCount: number;
  totalSubmitCount: number;
  totalReadCount: number;
  totalRejectCount: number;
  totalConfirmCount: number;
}
const TableRow: React.FC<Props> = ({
  headerData,
  checkable,
  rowData,
  isChecking,
  isChecked,
  isCheckedDataNotZero,
  onCheckData,
  onClickRow,
  transfromPixel,
  fileDownloadCallback,
}) => {
  const [data, setData] = useState<ReportMobileData | undefined>(undefined);

  const getLabel = (title: string, color: string, count: number) => {
    return (
      <CellContainer
        className="body-14-rg"
        style={{
          gap: 5,
        }}
      >
        <Ball
          style={{
            width: 6,
            height: 6,
            backgroundColor: color,
          }}
        />
        <span className="body-14-rg">
          {title}({count}개)
        </span>
      </CellContainer>
    );
  };

  useEffect(() => {
    console.log(rowData);
    const newData: ReportMobileData = {
      portfolioName: rowData.data.portfolioName || "untitled",
      participantMain: rowData.data.participantMain || "없음",
      phase: rowData.data.phase,
      totalRequiredFile: 0,
      totalNotSubmitCount: 0,
      totalSubmitCount: 0,
      totalReadCount: 0,
      totalRejectCount: 0,
      totalConfirmCount: 0,
    };
    Object.keys(rowData.data)
      .filter((item) => item.includes("report"))
      .forEach((key) => {
        const reportData = rowData.data[key];
        if (reportData.required) {
          newData.totalRequiredFile++;
        }
        if (reportData.required && !reportData.file) {
          newData.totalNotSubmitCount++;
        } else {
          if (
            reportData.required &&
            [1, 2].includes(reportData.file.uploadStatusId)
          ) {
            newData.totalSubmitCount++;
          }
          if (
            reportData.required &&
            [3].includes(reportData.file.uploadStatusId)
          ) {
            newData.totalReadCount++;
          }
          if (
            reportData.required &&
            [4].includes(reportData.file.uploadStatusId)
          ) {
            newData.totalRejectCount++;
          }
          if (
            reportData.required &&
            [5].includes(reportData.file.uploadStatusId)
          ) {
            newData.totalConfirmCount++;
          }
        }
      });
    setData(newData);
  }, [rowData]);
  return (
    <Container
      className={`body-14-rg ${isChecked ? "checked__row" : ""}`}
      style={{
        cursor: checkable && isCheckedDataNotZero ? "pointer" : "default",
      }}
      onClick={() => {
        if (isCheckedDataNotZero) {
          if (rowData.data.isOpen) return;
          onCheckData && onCheckData(rowData);
        } else {
          onClickRow && onClickRow();
        }
      }}
    >
      {checkable && (
        <CheckCellConatiner
          className={rowData.data.isOpen ? "opened" : ""}
          style={{
            width: 24,
            minWidth: 24,
            maxWidth: 24,
            borderBottom: `1px solid #edeeef`,
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (rowData.data.isOpen) return;

            onCheckData && onCheckData(rowData);
          }}
        >
          {isChecked || rowData.data.isOpen ? (
            <CheckT width={20} height={20} />
          ) : (
            <CheckF width={20} height={20} />
          )}
        </CheckCellConatiner>
      )}
      <CardContainer>
        <FlexRow style={{ gap: 12 }}>
          <div className="heading-16-sb">{data?.portfolioName || ""}</div>
          {data?.phase && [5, 6].includes(data.phase) && (
            <CustomColorLabel
              bgColor={data?.phase === 5 ? "var(--blue-100)" : "var(--red-50)"}
              textColor={
                data?.phase === 5 ? "var(--blue-500)" : "var(--red-500)"
              }
            >
              {data?.phase === 5 ? "관리" : "손상"}
            </CustomColorLabel>
          )}
        </FlexRow>
        <FlexRow style={{ width: "100%", marginTop: 8 }}>
          <FlexRow style={{ flex: 1, gap: 10 }}>
            <span className="heading-14-sb" style={{ color: "var(--text-04)" }}>
              담당자
            </span>
            <div>{data?.participantMain || ""}</div>
          </FlexRow>
          <FlexRow style={{ flex: 1, gap: 10 }}>
            <span className="heading-14-sb" style={{ color: "var(--text-04)" }}>
              총 필수 보고서
            </span>
            <div>{data?.totalRequiredFile || ""}개</div>
          </FlexRow>
        </FlexRow>
        <FlexRow
          style={{ width: "100%", gap: 10, marginTop: 10, flexWrap: "wrap" }}
        >
          <span className="heading-14-sb" style={{ color: "var(--text-04)" }}>
            필수 보고서 현황
          </span>
          {data?.totalNotSubmitCount !== 0 &&
            getLabel(
              "미제출",
              "var(--text-04)",
              data?.totalNotSubmitCount || 0
            )}
          {data?.totalSubmitCount !== 0 &&
            getLabel("제출", "var(--green-400)", data?.totalSubmitCount || 0)}
          {data?.totalReadCount !== 0 &&
            getLabel("확인중", "#FFD84F", data?.totalReadCount || 0)}
          {data?.totalRejectCount !== 0 &&
            getLabel("반려", "#F6444F", data?.totalRejectCount || 0)}
          {data?.totalConfirmCount !== 0 &&
            getLabel("확정", "#4584FF", data?.totalConfirmCount || 0)}
        </FlexRow>
      </CardContainer>
    </Container>
  );
};

const Container = styled.td`
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid var(--gray-400);
  padding: 8px 16px;
  &.checked__row {
    background-color: #fafafb;
  }
`;
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CellContainer = styled.div`
  width: fit-content;
  height: fit-content;
  color: #1f2022; //text01
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
`;
const Ball = styled.div`
  border-radius: 50%;
`;

const CheckCellConatiner = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.opened {
    path {
      fill: #d1e3ff;
    }
  }
`;
export default React.memo(TableRow);
