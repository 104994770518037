import React from "react";
import styled from "styled-components";

import {
  AddComma,
  addCommasToIntegerPart,
  getYMD,
} from "../../../../../../common/commonUtil";
import {
  INVEST,
  InvestemntInfoAdminProps,
  InvestmentHistoryAdminProps,
} from "../../interface/Portfolio.interface";

interface Props {
  data: InvestemntInfoAdminProps;
}
export const InvestmentInfoRow: React.FC<Props> = ({ data }) => {
  return (
    <Container className={`caption-12-rg`}>
      <CellConatiner width={278} style={{ justifyContent: "flex-start" }}>
        {data.acFundAccount.nameKo}
      </CellConatiner>
      <CellConatiner width={100} style={{ justifyContent: "flex-start" }}>
        {data.stockType ? data.stockType.name : "정보없음"}
      </CellConatiner>
      <CellConatiner width={100} style={{ justifyContent: "flex-start" }}>
        {AddComma(data.number)} 주
      </CellConatiner>
      <CellConatiner width={200} style={{ justifyContent: "flex-end" }}>
        {AddComma(data.principal)} 원
      </CellConatiner>
      <CellConatiner
        width={80}
        style={{
          justifyContent: "flex-end",
          borderRight: "0.5px solid transparent",
        }}
      >
        {data.percentage} %
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  height: max-content;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  background-color: var(--background-contents01, #fff);

  /* &.error {
    background-color: var(--red-50);
  }
  :hover {
    background-color: var(--hover-100);
  } */
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  min-height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: default;
  color: var(--text-01);
  border-right: 0.5px solid var(--divider-300, #e3e4e5);
  border-bottom: 0.5px solid var(--divider-300, #e3e4e5);
  padding: 8px 10px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
