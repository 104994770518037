import React, { useRef } from "react";
import styled from "styled-components";
import { DefaultChip, DefaultChipWithCircle } from "lattice_core";
import { JsxElement } from "typescript";
import { SBPTabProps } from "../interface/SBP.interface";

interface Props {
  id?: string;
  title: string;
  children?: JSX.Element;
}

export const SBPSector: React.FC<Props> = (props: Props) => {
  return (
    <Container id={props.id}>
      <div className="heading-20-sb" style={{ color: "var(--text-01)" }}>
        {props.title}
      </div>
      <div>{props.children}</div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 16px;
`;
