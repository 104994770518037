import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { FundAccountDetailInfo } from "../../interface/FundAccountDetail.interface";

interface Props {
  data: FundAccountDetailInfo;
}
export const FundConditions: React.FC<Props> = (props) => {
  return (
    <Container>
      <TitleContainer className="heading-18-sb">
        <span>펀드 조건</span>
      </TitleContainer>
      <BodyContainer>
        <FlexRow className="body-14-md" style={{ gap: 8, flexWrap: "wrap" }}>
          {props.data.terms.length === 0 && (
            <div className="body-14-md" style={{ color: "var(--text-05)" }}>
              펀드 조건이 없습니다.
            </div>
          )}
          {props.data.terms.map((item, index) => (
            <Tag key={`term_${index}`}>{item}</Tag>
          ))}
        </FlexRow>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;

  border-bottom: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tag = styled.div`
  width: fit-content;
  padding: 6px 14px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  color: var(--text-04);
`;
