import React from "react";
import styled, { css } from "styled-components";
import DatabaseDatepicker from "../component/DatabaseDatepicker";

interface Props {
    width?: number;
    inputWidth?: number;
    minDate?: string;
    maxDate?: string;
    onChangeMinDate: (date: string) => void;
    onChangeMaxDate: (date: string) => void;
}
const DatabaseRangeDate = ({
    width,
    inputWidth,
    minDate,
    maxDate,
    onChangeMinDate,
    onChangeMaxDate,
}: Props) => {
    return (
        <Container width={width}>
            <DatabaseDatepicker
                width={inputWidth}
                value={minDate}
                onChangeValue={onChangeMinDate}
            />
            <span>~</span>
            <DatabaseDatepicker
                width={inputWidth}
                value={maxDate}
                onChangeValue={onChangeMaxDate}
            />
        </Container>
    );
};

const Container = styled.div(
    ({ width }: { width?: number }) => css`
        width: ${width ? `${width}px` : "100%"};
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    `
);

export default DatabaseRangeDate;
