import React, { RefObject, useEffect, useRef, useState } from "react";
import Styled, { css } from "styled-components";
import { ResultType } from "../../../interface/PrePitchingDetail.interface";

interface Props {
  type: string;
  item: ResultType;
}

interface ResultLinearType {
  index?: number;
  value?: number;
}
const ResultLinear: React.FC<Props> = (props) => {
  const maxValue = useRef<number>(0);

  const [render, setRender] = useState<boolean>(true);
  const reRender = () => setRender((prevState) => !prevState);

  const [data, setData] = useState<ResultLinearType[] | undefined>(
    props.item.answer?.map((item: any) => ({}))
  );

  const getData = () => {
    console.log(props.item);
    const _data = props.item.answer?.map((item: any, index: number) => {
      if (maxValue.current < item.value) {
        maxValue.current = item.value;
      }
      return {
        index: item.index,
        value: item.value,
      };
    });
    setData(_data || []);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Container className="body-14-rg ">
      {/* {props.item.answer && <span>응답 {props.item.answer.length}개</span>} */}
      <ResultContainer>
        <ChartContainer>
          <ChartBarConainer>
            {data?.map((item, index) => {
              return (
                <CartItem
                  key={index}
                  value={item.value || 0}
                  maxValue={maxValue.current}
                >
                  <div className="bar active">
                    <span className="font-regular-12 percent">
                      {item.value} (
                      {maxValue.current > 0
                        ? Math.floor(
                            (100 * (item.value || 0)) / maxValue.current
                          )
                        : 0}
                      %)
                    </span>
                  </div>
                </CartItem>
              );
            })}
          </ChartBarConainer>
          <ChartLegenContainer>
            {data?.map((item, index) => {
              return (
                <span key={index} className="item">
                  {item.index}
                </span>
              );
            })}
          </ChartLegenContainer>
        </ChartContainer>
        <LabelContainer>
          <span>{props.item.minRangeTitle}</span>
          <span>{props.item.maxRangeTitle}</span>
        </LabelContainer>
      </ResultContainer>
    </Container>
  );
};

const Container = Styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `
);

const ResultContainer = Styled.div(
  () => css`
    width: 100%;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;
  `
);

const ChartContainer = Styled.div(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `
);

const ChartBarConainer = Styled.ul(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `
);
const ChartLegenContainer = Styled.ul(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid var(--grey_20);
    .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 20px;
    }
  `
);

const CartItem = Styled.li(
  ({ value, maxValue }: { value: number; maxValue: number }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .bar {
      position: relative;
      width: 20px;
      height: 0px;
      background-color: var(--primary);
      transition: all 0.2s;
    }
    .active {
      height: ${Math.floor((200 * value) / maxValue)}px;
    }
    .percent {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-25%, -100%);
      width: fit-content;
    }
  `
);

const LabelContainer = Styled.div(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `
);

export default ResultLinear;
