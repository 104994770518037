import React from "react";
import styled from "styled-components";

import { ReactComponent as Help } from "../../../../../../assets/image/icon_tooltip.svg";

interface Props {
  className?: string;
  textClassName?: string;
  style?: React.CSSProperties;
  title: string;
  titleWidth?: number;
  minHeight?: number;
  tooltipId?: string;
  children: React.ReactNode;
  transfromPixel: (value: number) => number;
}
const RowTitleBox: React.FC<Props> = (props) => {
  const {
    className,
    textClassName = "heading-14-sb",
    style,
    title,
    titleWidth = 120,
    minHeight = 40,
    tooltipId,
    children,
    transfromPixel,
  } = props;
  return (
    <Container
      className={className}
      transfromPixel={transfromPixel}
      style={{ ...style, minHeight: transfromPixel(minHeight) }}
    >
      <Header
        className={textClassName}
        transfromPixel={transfromPixel}
        style={{
          width: transfromPixel(titleWidth),
          minWidth: transfromPixel(titleWidth),
          maxWidth: transfromPixel(titleWidth),
        }}
      >
        <div>
          {title.split("|").map((item) => (
            <div>{item}</div>
          ))}
        </div>
        {tooltipId && (
          <Help
            className={tooltipId}
            data-tooltip-delay-hide={2000}
            style={{ outline: "none" }}
          />
        )}
      </Header>
      <Body transfromPixel={transfromPixel}>{children}</Body>
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const Header = styled.div<{ transfromPixel: (value: number) => number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: var(--text-04);
  margin-top: 9px;

  .flex_column {
    display: flex;
    flex-direction: column;
  }
`;
const Body = styled.div<{ transfromPixel: (value: number) => number }>`
  flex-grow: 1;
  height: fit-content;
`;
export default RowTitleBox;
