import React from "react";
import styled from "styled-components";
import { DefaultButton } from "lattice_core";

import { ReactComponent as Close } from "../../../../../../assets/image/modal_close.svg";
import { ReactComponent as FileUpload } from "../../../../../../assets/image/icon-file_upload-32.svg";
import { rootStyle } from "../../../../../../style/ThemeStyles";
import { FinancialStatementInput } from "./FinancialStatementInput";

import { FinancialStatementType } from "../interface/FinancialStatement.interface";

import PDFViewerPage from "../../../../../../components-2.0v/viewer/PDFViewerPage";
import { Description } from "../../../../../../2.0lattice/components/description/Description";
import useFileUpload from "../../../../../reporting-detail/desktop/components/stock-change-history/useFileUpload";
import useDND from "../../../../../reporting-detail/desktop/components/stock-change-history/useDND";
import { ReportingFile } from "../../../../../reporting-detail/interface/ReportingDetail.interface";
import { TabLayout } from "./tab/TabLayout";
import { FinancialStatementFullProps } from "../../../../../../type/data";

interface Props {
  data: ReportingFile;
  financialStatement: FinancialStatementFullProps;
  localPdfFile?: File;
  year: number;
  quarter: number;
  isHalf: boolean;
  currency?: string;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFileDND: (e: any) => void;
  onChangeFinancialStatement: (data: FinancialStatementFullProps) => void;
  close: () => void;
  submit: () => void;
  onSubmitAble: () => boolean | undefined;
  validationAsset: () => boolean;
  validationDebtCapital: () => boolean;
  validationIncomeStatement: () => boolean;
  validationEqualAssetAndDebtCapital: () => boolean;
}
export const FinancialStatementModal: React.FC<Props> = (props) => {
  const {
    financialStatement,
    data,
    localPdfFile,
    year,
    quarter,
    isHalf,
    currency = "KRW",
    onChangeFile,
    onChangeFileDND,
    close,
    onChangeFinancialStatement,
    submit,
    onSubmitAble,
    validationAsset,
    validationDebtCapital,
    validationIncomeStatement,
    validationEqualAssetAndDebtCapital,
  } = props;

  const [fileRef, onClickFileRef] = useFileUpload();
  const [isDragging, dragRef] = useDND(onChangeFileDND);

  const getGoolgeUrl = (documentId: string) => {
    return `https://drive.google.com/file/d/${documentId}/preview`;
  };

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          {data.name}
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        <input
          type="file"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={onChangeFile}
        />
        <BodyContainer>
          {localPdfFile ? (
            <PDFViewerPage file={localPdfFile} />
          ) : data.file ? (
            <div className="viewer__iframe__wrapper">
              <iframe
                title="pdd-viewer"
                className="viewer__iframe"
                src={getGoolgeUrl(data.file.documentFile?.key || "")}
              />
            </div>
          ) : (
            <FileUploadContainer ref={dragRef}>
              <FileUpload width={60} height={60} />

              <div className="body-14-rg description">
                <span>파일을 드래그하거나</span>
                <br />
                <span>아래 버튼을 눌러 업로드해 주세요.</span>
              </div>
              <div className="upload__file">
                <DefaultButton
                  className="heading-16-md"
                  buttonType="filled"
                  sizeType="small"
                  buttonColor={rootStyle.getPropertyValue("--primary-blue")}
                  hoverColor={rootStyle.getPropertyValue("--blue-600")}
                  disabled={false}
                  text="파일 업로드"
                  onClick={() => {
                    onClickFileRef();
                  }}
                />
              </div>
            </FileUploadContainer>
          )}
          {(localPdfFile || props.data.file) && (
            <div style={{ position: "absolute", left: 10, top: 10 }}>
              <DefaultButton
                className="caption-12-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor={rootStyle.getPropertyValue("--text-01")}
                borderColor={rootStyle.getPropertyValue("--gray-400")}
                disabled={false}
                text="파일 교체"
                onClick={() => {
                  onClickFileRef();
                }}
              />
            </div>
          )}
        </BodyContainer>
        <BodyContainer style={{ borderRight: "1px solid var(--gray-300)" }}>
          <FinancialStatementContainer>
            <div className="heading-16-sb">요약 재무 정보</div>
            <Description style={{ marginTop: 16 }}>
              <FlexColumn
                className="body-14-md"
                style={{ lineHeight: 1, gap: 6 }}
              >
                <div>
                  * {quarter === 1 ? year - 1 : year}년 1월부터{" "}
                  {isHalf ? "6월" : "12월"}까지의 해당 정보를 작성해 주세요.
                </div>
                <div>* 현지통화({currency})로 입력해 주세요.</div>
                <div>
                  * 이익잉여금이 마이너스(-)일경우 결손금에 입력해주세요.
                </div>
              </FlexColumn>
            </Description>
            <TabLayout
              data={financialStatement}
              currency={currency}
              onChangeData={onChangeFinancialStatement}
              validationAsset={validationAsset}
              validationDebtCapital={validationDebtCapital}
              validationIncomeStatement={validationIncomeStatement}
            />
            <FlexRow
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 18,
              }}
            >
              {validationEqualAssetAndDebtCapital() ? (
                <div />
              ) : (
                <div
                  className="body-14-sb"
                  style={{ color: "var(--red-500)", marginTop: 10 }}
                >
                  * 자산총계와 부채와자본총계의 값은 같아야 합니다.
                </div>
              )}
              <DefaultButton
                className="heading-16-md"
                buttonType="filled"
                sizeType="small"
                buttonColor={rootStyle.getPropertyValue("--primary-blue")}
                hoverColor={rootStyle.getPropertyValue("--blue-600")}
                disabled={!onSubmitAble()}
                text={data.file ? "수정하기" : "제출하기"}
                onClick={() => {
                  submit();
                }}
              />
            </FlexRow>
          </FinancialStatementContainer>
        </BodyContainer>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 80vw;
  max-width: 1200px;
  height: 95vh;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  border-bottom: 1px solid var(--gray-300);

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  position: relative;
  flex: 1;
  height: 100%;

  .viewer__iframe__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .viewer__iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`;

const FileUploadContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  background: var(--background-page);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .description {
    margin-top: 20px;
    text-align: center;
    span {
      color: var(--text-04);
    }
  }

  .upload__file {
    margin-top: 30px;
  }

  svg {
    path {
      fill: var(--icon-02);
    }
  }
`;

const FinancialStatementContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const FinancialStatementInputContainer = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--light-gray-200, #edeeef);
  background: var(--light-background-contents-01, #fff);
  gap: 14px;

  margin-top: 8px;
`;

const FooterContainer = styled.div`
  width: 100%;
  padding-top: 34px;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`;

const CheckedText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .description {
    color: var(--text-01);
  }

  .contents {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .content {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .number {
        width: 24px;
        height: 24px;
        background-color: var(--blue-100);
        color: var(--primary-blue);
        border-radius: 12px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .item {
        color: var(--primary-blue);
      }
    }
  }
`;
