import React from "react";
import styled from "styled-components";

import { ExpertInfoProps } from "../../../../../type/data";

import { ReactComponent as Info } from "../../../../../assets/image/icon_info.svg";

import { ExpertFileCard } from "./ExpertFileCard";

interface Props {
  expertInfo?: ExpertInfoProps;
}

export const EvaluationExpertOpinionResult: React.FC<Props> = (props) => {
  const { expertInfo } = props;

  return (
    <Container>
      <FlexColumn style={{ gap: 8 }}>
        <DescriptionContainer className="caption-12-rg">
          <Info style={{ minWidth: 16, minHeight: 16 }} />
          <div>
            전문가 의견이 제출되었습니다. 아래 파일은 전문가 의견 증빙 서류
            입니다.
            <br />
            투자심사 보고서 템플릿 다운로드를 하면 의견이 반영된 템플릿을
            다운로드 할 수 있습니다.
          </div>
        </DescriptionContainer>
        <FileContainer>
          {expertInfo?.expertOpinion?.reportFile &&
            expertInfo.expertOpinion.reportFile.map((item, index) => {
              return <ExpertFileCard file={item} />;
            })}
        </FileContainer>
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  padding: 8px;
  gap: 4px;

  margin-top: 8px;

  background-color: var(--gray-50);
  color: var(--text-04);
  border-radius: 4px;
`;

const FileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 12px;
`;
