import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ExcavationFilterData,
  ExcavationFilterType,
  ExcavationPortfolioProps,
  ExcavationRegisteDealflowCompleteProps,
  ExcavationRegisteParticipant,
} from "../interface/Excavation.interface";
import {
  createPortfolioFolder,
  deletePortfolioComment,
  getExcavationPortfolio,
  getPortfolFolder,
  getPortfolioDetailInfo,
  modifyPortfolioComment,
  registePortfolioDealFlow,
  registePortfolioFolder,
  registePortfolioParticipant,
  sendPortfolioComment,
} from "../../../api/repository/portfolio/PortfolioRepository";

import {
  AcStructureProps,
  Channel,
  DealFlowHistory,
  PortfolioTargetData,
  IndustryTech,
  UserDetailInfo,
  UserInfo,
  PortfolioFolders,
} from "../../../type/data";

import { ReactComponent as Fail } from "../../../assets/image/icon_check_red.svg";

import { getMyDetailInfo, getMyInfo } from "../../../common/commonApiData";
import { PortfolioInfoProps } from "../../portfolio/info/interface/PortfolioInfo.interface";
import {
  getAllAcUser,
  getAllChannel,
  getAllIndustrialTech,
  getAllStructure,
} from "../../../api/repository/accelerator/AcceleratorRepository";
import {
  getNewDate,
  getTransformedId,
  getYMD,
  showToast,
} from "../../../common/commonUtil";
import { Bounce } from "react-toastify";
import { pid } from "process";
import { FOLDER_ID } from "../../../google/login/GoogleLoginUtil";

const useExcavation = (
  createFolder: (parent: string, name: string) => Promise<any>
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const initCompleted = useRef<boolean>(false);
  const myInfo = useRef<UserDetailInfo | undefined>(undefined);
  const contentsBodyRef = useRef<HTMLDivElement>(null);
  const [allUser, setAllUser] = useState<UserInfo[]>([]);
  const [allIndustyTech, setAllIndustyTech] = useState<IndustryTech[]>([]);
  const [allChannel, setAllChannel] = useState<Channel[]>([]);
  const [portfolioList, updatePortfolioList] = useState<
    ExcavationPortfolioProps[] | undefined
  >(undefined);

  const [selectedPortfolio, updateSelectedPortfolio] = useState<
    ExcavationPortfolioProps | undefined
  >(undefined);

  const [isOpenRegisteParticipant, setIsOpenRegisteParticipant] =
    useState<boolean>(false);

  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [isDetailShow, updateDetailShow] = useState<boolean>(false);

  const [portfolioInfo, updatePortfolioInfo] = useState<
    PortfolioInfoProps | undefined
  >(undefined);
  const [updateCommentId, setUpdateCommentId] = useState<number | undefined>(
    undefined
  );

  const [openProgress, updateOpenProgress] = useState<boolean>(false);
  const [progressIcon, updateProgressIcon] = useState<
    React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined
  >(undefined);
  const [progressMsg, updateProgressMsg] = useState<string>("");
  const [progress, updateProgress] = useState<number>(0);
  const [progressFail, updateProgressFail] = useState<boolean>(false);

  const [registeDealflowComplete, updateRegisteDealflowComplete] = useState<
    ExcavationRegisteDealflowCompleteProps | undefined
  >(undefined);

  const [myIndustrialTech, setMyIndustrialTech] = useState<number[]>([]);
  const [myHeadQuarterIndustrialTech, setMyHeadQuarterIndustrialTech] =
    useState<number[]>([]);

  const [filterData, setFilterData] = useState<ExcavationFilterData>({
    industry: [],
    channel: [],
    dateRange: {},
    keyword: "",
  });
  const [minDate, updateMinDate] = useState<string | undefined>(undefined);
  const [maxDate, updateMaxDate] = useState<string | undefined>(undefined);

  const [dropTargetPortfolio, setDropTargetPortfolio] = useState<
    PortfolioTargetData | undefined
  >(undefined);

  const goToPortfolioCreate = () => {
    navigate("/excavation/create");
  };

  const goToPortfolio = () => {
    if (!registeDealflowComplete) return;

    const to = `/portfolio/${getTransformedId(
      registeDealflowComplete.portfolioId
    )}/info`;
    navigate(to);
  };

  const stayExcavation = () => {
    updateRegisteDealflowComplete(undefined);
  };

  const onChangeFilterData = (type: ExcavationFilterType, data: any) => {
    switch (type) {
      case "industry":
        setFilterData({ ...filterData, industry: data });
        break;
      case "channel":
        setFilterData({ ...filterData, channel: data });
        break;
      case "dateRange":
        setFilterData({ ...filterData, dateRange: data });
        break;
      case "all": {
        setFilterData({
          ...filterData,
          industry: data.industry,
          channel: data.channel,
          dateRange: data.dateRange,
        });
        break;
      }
      case "keyword":
        setFilterData({ ...filterData, keyword: data });
        break;
      case "init":
        setFilterData({
          industry: [],
          channel: [],
          dateRange: {},
          keyword: "",
        });
        break;
    }
  };

  const getFilterPortfolio = () => {
    if (!portfolioList) return [];
    const filterPortfolio = portfolioList
      .filter((item) => {
        if (
          !filterData.dateRange.startDate ||
          !filterData.dateRange.startDate.date
        )
          return true;
        const sDate = getNewDate(filterData.dateRange.startDate.date);
        const targetDate = getNewDate(getYMD(item.createdAt));

        return sDate.getTime() <= targetDate.getTime();
      })
      .filter((item) => {
        if (!filterData.dateRange.endDate || !filterData.dateRange.endDate.date)
          return true;
        const eDate = getNewDate(filterData.dateRange.endDate.date);
        const targetDate = getNewDate(getYMD(item.createdAt));

        return eDate.getTime() >= targetDate.getTime();
      })
      .filter((item) => {
        return (
          (item.companyName &&
            item.companyName
              .toUpperCase()
              .includes(searchKeyword.toUpperCase())) ||
          (item.description &&
            item.description
              .toUpperCase()
              .includes(searchKeyword.toUpperCase())) ||
          item.acChannel.name
            .toUpperCase()
            .includes(searchKeyword.toUpperCase())
        );
      })
      .filter((item) => {
        if (filterData.industry.length === 0) return true;

        const pfIndustry = item.pfIndustrialTech.map(
          (industry) => industry.acIndustrialTech.acIndustrialTechId
        );
        return (
          pfIndustry.filter((industry) =>
            filterData.industry.includes(industry)
          ).length > 0
        );
      })
      .filter((item) => {
        if (filterData.channel.length === 0) return true;
        const channel = item.acChannel.acChannelId;
        return filterData.channel.includes(channel);
      });

    return filterPortfolio;
  };

  const goToScroll = () => {
    if (selectedPortfolio && contentsBodyRef.current) {
      const querySelectTarget = document.querySelector(
        `#EP_${selectedPortfolio.portfolioId}`
      );
      if (querySelectTarget) {
        const targetRect = querySelectTarget.getBoundingClientRect();
        const headerOffset = 106;
        const elementPosition = targetRect.top;
        const offsetPosition =
          elementPosition + contentsBodyRef.current.scrollTop - headerOffset;
        contentsBodyRef.current.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const initPortfolioInfo = async () => {
    console.log(selectedPortfolio);
    if (!selectedPortfolio) return;

    const result = await getPortfolioDetailInfo(selectedPortfolio.portfolioId);
    if (result) {
      console.log(result);
      updatePortfolioInfo({ ...result });
    }
  };

  const sendComment = async (data: string, callback: () => void) => {
    if (!selectedPortfolio) return;

    const commentResult = await sendPortfolioComment(
      selectedPortfolio.portfolioId,
      data
    );
    if (commentResult) {
      callback();
      await init();
      await initPortfolioInfo();
    }
  };

  const updateComment = async (pfCommentId: number, data: string) => {
    if (!selectedPortfolio) return;
    const commentResult = await modifyPortfolioComment(pfCommentId, data);
    if (commentResult) {
      initPortfolioInfo();
      setUpdateCommentId(undefined);
    }
  };

  const deleteComment = async (pfCommentId: number) => {
    if (!selectedPortfolio) return;
    const commentResult = await deletePortfolioComment(pfCommentId);
    if (commentResult) {
      initPortfolioInfo();
      setUpdateCommentId(undefined);
    }
  };

  const onSccess = () => {
    updateProgressMsg("포트폴리오 담당자 등록 완료!");
    setTimeout(() => {
      updateProgress(100);
    }, 100);
    setTimeout(() => {
      updateRegisteDealflowComplete({
        portfolioId: selectedPortfolio?.portfolioId || 0,
        companyName: selectedPortfolio?.companyName || "",
      });
      updateSelectedPortfolio(undefined);
      updatePortfolioInfo(undefined);
      updateDetailShow(false);
      updateProgress(0);
      updateOpenProgress(false);
      updateProgressFail(false);
    }, 1000);
  };

  const onFail = () => {
    updateProgressMsg("포트폴리오 미팅 검토하기 실패!");
    updateProgressFail(true);

    setTimeout(() => {
      updateProgress(100);
      updateProgressIcon(Fail);
    }, 100);

    setTimeout(() => {
      updateProgress(0);
      updateOpenProgress(false);
      updateProgressFail(false);
    }, 1000);
  };

  const createPortfolioFolders = async (
    portfolioId: number
  ): Promise<PortfolioFolders | undefined> => {
    try {
      const _folderId = FOLDER_ID;
      const _portfolioFolder: PortfolioFolders = {
        folderId: "",
        noteFolderId: "",
        reportFolderId: "",
        contractFolderId: "",
      };

      if (_folderId === undefined)
        return new Promise((resolve) => resolve(undefined));

      console.log("1");

      if (_portfolioFolder.folderId.length === 0) {
        const result = await createFolder(_folderId, `${portfolioId}`);
        console.log(result);
        if (result !== undefined) _portfolioFolder.folderId = result;
        else return new Promise((resolve) => resolve(undefined));
      }

      console.log("2");
      updateProgress(25);

      if (_portfolioFolder.reportFolderId.length === 0) {
        const reportResult = await createFolder(
          _portfolioFolder.folderId,
          "투자심사보고서"
        );
        if (reportResult !== undefined)
          _portfolioFolder.reportFolderId = reportResult;
      }

      console.log("3");
      updateProgress(30);

      if (_portfolioFolder.noteFolderId.length === 0) {
        const noteResult = await createFolder(
          _portfolioFolder.folderId,
          "미팅노트"
        );
        if (noteResult !== undefined)
          _portfolioFolder.noteFolderId = noteResult;
      }

      console.log("4");
      updateProgress(35);
      if (_portfolioFolder.contractFolderId.length === 0) {
        const contractResult = await createFolder(
          _portfolioFolder.folderId,
          "투자계약서"
        );
        if (contractResult !== undefined)
          _portfolioFolder.contractFolderId = contractResult;
      }

      console.log("5");
      updateProgress(40);
      return new Promise((resolve) => resolve(_portfolioFolder));
    } catch (error) {
      return new Promise((reject) => reject(undefined));
    }
  };

  const registeDealFlow = async (data: ExcavationRegisteParticipant) => {
    if (!selectedPortfolio) return;

    updateOpenProgress(true);
    updateProgress(70);
    updateProgressMsg("포트폴리오 담당자 등록 중...");
    const participantResult = await registePortfolioParticipant(
      selectedPortfolio.portfolioId,
      data
    );
    if (participantResult) {
      const dealflowResult = await registePortfolioDealFlow(
        selectedPortfolio.portfolioId
      );
      if (dealflowResult) updateProgress(90);
      init();
      return onSccess();
    }

    onFail();
  };

  const initIndustryTech = async () => {
    const myInfoResult = await getMyDetailInfo();
    const allStructure = await getAllStructure();

    if (allStructure && myInfoResult) {
      myInfo.current = myInfoResult;

      const headquarter = allStructure.headquarter.find((item) =>
        item.users.includes(myInfoResult.acUserId)
      );

      if (myInfoResult.acUserIndustrialTech !== undefined) {
        const myIndustry = myInfoResult.acUserIndustrialTech.map((item) =>
          parseInt(`${item.acIndustrialTechId}`)
        );
        setMyIndustrialTech(myIndustry);
      }

      if (headquarter) {
        const headquarterIndustry = headquarter.acIndustrialTech.map((item) =>
          parseInt(`${item.acIndustrialTechId}`)
        );
        setMyHeadQuarterIndustrialTech(headquarterIndustry);
      }
    }
  };

  const dropComplete = () => {
    init();
    showToast({
      customElement: (
        <div
          className="heading-16-sb"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <span>드롭되었습니다.</span>
        </div>
      ),
      backgroundColor: "var(--green-400)",
      color: "var(--text-02)",
      width: 274,
      milliSec: 1500,
      transition: Bounce,
      position: "bottom-center",
      toastId: new Date().toString(),
    });
    updateSelectedPortfolio(undefined);
    updatePortfolioInfo(undefined);
    updateDetailShow(false);
  };

  const init = async () => {
    const result = await getExcavationPortfolio();
    const userResult = await getAllAcUser("user");
    const industryTechResult = await getAllIndustrialTech();
    const channelResult = await getAllChannel();

    await initIndustryTech();

    if (userResult) setAllUser(userResult);
    if (industryTechResult) setAllIndustyTech(industryTechResult);
    if (channelResult) setAllChannel(channelResult);

    updatePortfolioList(result);
    if (result && result.length > 0) {
      updateMinDate(result[result.length - 1].createdAt);
      updateMaxDate(result[0].createdAt);
    }
    setTimeout(() => {
      initCompleted.current = true;
    }, 500);
  };

  useEffect(() => {
    if (portfolioList && portfolioList.length > 0) {
      const arr = location.search.replaceAll("?", "").split("&");
      const pId = arr.find((item: any) => item.includes("p_id"));
      if (pId) {
        const value = parseInt(pId.replaceAll("p_id=", ""));
        const target = portfolioList.find((item) => item.portfolioId === value);

        updateDetailShow(true);
        updateSelectedPortfolio(target);
      }
    }
  }, [portfolioList]);

  useEffect(() => {
    if (selectedPortfolio) {
      initPortfolioInfo();
    } else {
      updatePortfolioInfo(undefined);
    }
  }, [selectedPortfolio]);

  useEffect(() => {
    if (isDetailShow && selectedPortfolio && contentsBodyRef.current) {
      setTimeout(() => {
        goToScroll();
      }, 200);
    }
  }, [isDetailShow]);

  useEffect(() => {
    init();
  }, []);

  return [
    initCompleted.current,
    myInfo.current,
    minDate,
    maxDate,
    allUser,
    allIndustyTech,
    allChannel,
    myIndustrialTech,
    myHeadQuarterIndustrialTech,
    filterData,
    portfolioList,
    contentsBodyRef,
    searchKeyword,
    isDetailShow,
    selectedPortfolio,
    portfolioInfo,
    updateCommentId,
    isOpenRegisteParticipant,
    openProgress,
    progressIcon,
    progressMsg,
    progress,
    progressFail,
    registeDealflowComplete,
    dropTargetPortfolio,
    setDropTargetPortfolio,
    goToPortfolio,
    stayExcavation,
    updateOpenProgress,
    setIsOpenRegisteParticipant,
    setUpdateCommentId,
    updateSelectedPortfolio,
    updateSearchKeyword,
    getFilterPortfolio,
    onChangeFilterData,
    sendComment,
    updateComment,
    deleteComment,
    goToPortfolioCreate,
    updateDetailShow,
    registeDealFlow,
    dropComplete,
  ] as const;
};

export default useExcavation;
