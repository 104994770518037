import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createPrePitching,
  getPrePitchingTemplete,
  getPrepitchingList,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import {
  DealFlowPitchingData,
  PrePitchingData,
} from "../interface/PrePitching.interface";
import { DealFlowStatus } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import {
  getPortfolioIdInLocation,
  getTransformedId,
} from "../../../../common/commonUtil";

const usePrePitching = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const portfolioId = getPortfolioIdInLocation(location);

  const firstLoading = useRef<boolean>(true);
  const [pitchings, setPitchings] = useState<DealFlowPitchingData[]>([]);

  const goToPrepitchingDetail = (preEvaluationId: number) => {
    console.log("goToPrepitchingDetail");
    const to = `/portfolio/${getTransformedId(
      portfolioId
    )}/pitching-detail/${getTransformedId(preEvaluationId)}`;
    navigate(to);
  };

  const createPitching = async () => {
    const openDealflow = pitchings.find(
      (item) => item.status === DealFlowStatus.OPEN
    );
    const templeteResult = await getPrePitchingTemplete(portfolioId);

    if (!openDealflow || !templeteResult) return;

    const createResult = await createPrePitching(portfolioId, {
      dealFlowId: openDealflow.id,
      preEvaluationData: templeteResult,
    });

    if (createResult.success && createResult.pfPreEvaluationId) {
      goToPrepitchingDetail(createResult.pfPreEvaluationId);
    }
  };

  const init = async () => {
    const result = await getPrepitchingList(portfolioId);
    if (result) {
      firstLoading.current = false;
      const newData = result.map((item, index) => {
        const title =
          index + 1 - result.length === 0
            ? result.length === 1
              ? "피칭 심사"
              : "선행 피칭 심사"
            : `후행 ${result.length - index - 1} 피칭 심사`;
        return { ...item, title: title };
      });
      setPitchings([...newData]);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return [
    firstLoading.current,
    pitchings,
    createPitching,
    goToPrepitchingDetail,
    init,
  ] as const;
};

export default usePrePitching;
