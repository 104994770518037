import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Address } from "../../type/data";
import DaumModal from "../../components/modal/DaumModal";

interface Props {
  width?: number | string;
  className?: string;
  style?: React.CSSProperties;
  defaultValue?: Address;
  isShowValidationMsg?: boolean;
  validationMsg?: string;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (value: Address) => void;
  transfromPixel?: (value: number) => number;
}
const AddressSearch: React.FC<Props> = (props) => {
  const {
    width = "100%",
    className,
    style,
    defaultValue,
    isShowValidationMsg,
    validationMsg,
    placeholder,
    readOnly,
    onChange,
    transfromPixel = (n) => n,
  } = props;

  const [value, updateValue] = useState<Address | undefined>(defaultValue);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValue) updateValue(defaultValue);
  }, [defaultValue]);

  return (
    <Container
      className={className}
      style={{ ...style, width }}
      transfromPixel={transfromPixel}
    >
      <InputContainer transfromPixel={transfromPixel}>
        <InputComponent
          readOnly={readOnly}
          disabled={readOnly}
          transfromPixel={transfromPixel}
          className={`${isShowValidationMsg ? "show__msg" : ""} body-14-md`}
          type="text"
          placeholder={placeholder}
          onFocus={(e) => {
            setOpenModal(true);
            e.target.blur();
          }}
          value={
            value
              ? `${value.addr1} ${value.addr2} ${value.addr3} ${value.addrDetail}`
              : ""
          }
        />
      </InputContainer>
      {validationMsg && isShowValidationMsg && (
        <span className="validation__msg body-14-md">{validationMsg}</span>
      )}

      {openModal && (
        <DaumModal
          open={openModal}
          setValue={(address: Address) => {
            updateValue(address);
            props.onChange && props.onChange(address);
          }}
          toggle={() => {
            setOpenModal(false);
          }}
        />
      )}
    </Container>
  );
};
const Container = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.transfromPixel(8)}px;
  .validation__msg {
    color: var(--red-500);
  }
`;

const InputContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  position: relative;
  width: 100%;
  height: ${(props) => props.transfromPixel(40)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow: hidden;
`;
const InputComponent = styled.input<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: ${(props) => props.transfromPixel(40)}px;
  padding: ${(props) => props.transfromPixel(12)}px
    ${(props) => props.transfromPixel(10)}px;

  border: ${(props) => props.transfromPixel(1)}px solid var(--gray-300);
  border-radius: ${(props) => props.transfromPixel(4)}px;
  transition: 0.2s;
  background: var(--background-contents01);

  ::placeholder {
    color: var(--text-06);
  }

  :disabled {
    border: ${(props) => props.transfromPixel(1)}px solid transparent;
    :hover {
      background-color: var(--background-contents01);
      border-color: transparent;
    }
  }
  :read-only {
    color: var(--text-01);
    padding: ${(props) => props.transfromPixel(12)}px
      ${(props) => props.transfromPixel(0)}px;
  }

  &.show__msg {
    border: ${(props) => props.transfromPixel(1)}px solid var(--red-500);
    animation: shake 200ms;
  }

  :focus {
    outline: none;
    border: ${(props) => props.transfromPixel(1)}px solid
      var(--focus-field-blue);

    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    border-color: var(--focus-field-blue);
  }

  @keyframes shake {
    25% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    50% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
    75% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    100% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export default AddressSearch;
