import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../SummaryBusinessPlanV2Download";
import { SBPContentsPropsV2 } from "../../../interface/SBP.interface";
import { GovermentTaskComponent } from "./table/GovermentTaskComponent";
import { FundBorrowComponent } from "./table/FundBorrowComponent";
import RowTitleBox from "../../RowTitleBox";

interface Props {
  index: number;
  sbpDocument: SBPContentsPropsV2;
}

export const Status4: React.FC<Props> = (props) => {
  return (
    <FlexColumn style={{ gap: 8 }}>
      <div className="body-16-md" style={{ color: "var(--text-03)" }}>
        0{props.index}. 인력 변동
      </div>
      <FlexColumn
        style={{
          padding: 14,
          borderRadius: 4,
          gap: convertPixelToCm(24),
          backgroundColor: "var(--background-contents01)",
          border: "1px solid var(--gray-300)",
        }}
      >
        <FlexColumn>
          <div
            className="caption-12-md"
            style={{ color: "var(--text-03)" }}
          >{`${
            props.sbpDocument.year ? props.sbpDocument.year - 1 : "YYYY"
          }년 ${
            props.sbpDocument.quarter
              ? props.sbpDocument.quarter === 2
                ? "12"
                : "6"
              : "Error"
          }월말`}</div>
          <RowTitleBox
            textClassName="caption-12-md"
            title="임직원 수"
            titleWidth={convertPixelToCm(108 + 50)}
            transfromPixel={(n) => n}
          >
            <span
              className="caption-12-rg"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: 9,
              }}
            >
              {props.sbpDocument.businessStatus.employeeTurnover.curEmployee}명
            </span>
          </RowTitleBox>
        </FlexColumn>

        <FlexRow>
          <FlexColumn
            style={{
              flex: 1,
            }}
          >
            <div
              className="caption-12-md"
              style={{ color: "var(--text-03)" }}
            >{`${
              props.sbpDocument.year && props.sbpDocument.quarter
                ? props.sbpDocument.quarter === 2
                  ? props.sbpDocument.year
                  : props.sbpDocument.year - 1
                : "YYYY"
            }년 ${
              props.sbpDocument.quarter
                ? props.sbpDocument.quarter === 2
                  ? "1 - 6"
                  : "7 - 12"
                : "Error"
            }월`}</div>
            <RowTitleBox
              textClassName="caption-12-md"
              title="입사자 수"
              titleWidth={convertPixelToCm(108 + 50)}
              transfromPixel={(n) => n}
            >
              <span
                className="caption-12-rg"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: 9,
                }}
              >
                {
                  props.sbpDocument.businessStatus.employeeTurnover
                    .employeeOnboarding
                }
                명
              </span>
            </RowTitleBox>
          </FlexColumn>
          <FlexColumn
            style={{
              flex: 1,
            }}
          >
            <div
              className="caption-12-md"
              style={{ opacity: 0, cursor: "default" }}
            >{`{{N-1}}년 {7}-{12}월 `}</div>
            <RowTitleBox
              textClassName="caption-12-md"
              title="퇴사자 수"
              titleWidth={convertPixelToCm(108 + 50)}
              transfromPixel={(n) => n}
            >
              <span
                className="caption-12-rg"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: 9,
                }}
              >
                {
                  props.sbpDocument.businessStatus.employeeTurnover
                    .employeeAttrition
                }
                명
              </span>
            </RowTitleBox>
          </FlexColumn>
          <FlexColumn
            style={{
              flex: 1,
            }}
          >
            <div
              className="caption-12-md"
              style={{ color: "var(--text-03)" }}
            >{`${
              props.sbpDocument.year && props.sbpDocument.quarter
                ? props.sbpDocument.quarter === 2
                  ? props.sbpDocument.year
                  : props.sbpDocument.year - 1
                : "YYYY"
            }년 ${
              props.sbpDocument.quarter
                ? props.sbpDocument.quarter === 2
                  ? "6"
                  : "12"
                : "Error"
            }월말`}</div>
            <RowTitleBox
              textClassName="caption-12-md"
              title="임직원 수"
              titleWidth={convertPixelToCm(108 + 50)}
              transfromPixel={(n) => n}
            >
              <span
                className="caption-12-rg"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: 9,
                }}
              >
                {(props.sbpDocument.businessStatus.employeeTurnover
                  .curEmployee || 0) +
                  (props.sbpDocument.businessStatus.employeeTurnover
                    .employeeOnboarding || 0) -
                  (props.sbpDocument.businessStatus.employeeTurnover
                    .employeeAttrition || 0)}
                명
              </span>
            </RowTitleBox>
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
