import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../../assets/image/modal_close.svg";
import { DefaultButton } from "lattice_core";
import { SelectorProps } from "../../../../../../type/data";
import { excelDownloadFairValueAmount } from "../../../../../../excel-download/createFairValueAmount";
import { FairValueImport } from "./FairValueImport";
import { FairValueAmountExportProps } from "../../interface/Portfolio.interface";
import { FairValueTable } from "./FairValueTable";
import {
  getFairValueList,
  upsertFairValueList,
} from "../../../../../../api/repository/admin/AcceleratorRepository";

interface Props {
  close: () => void;
  submit: () => void;
}

export const FairValueAmountModal: React.FC<Props> = (props) => {
  const { close, submit } = props;
  const [selectedYear, updateSelectedYear] = useState<
    SelectorProps | undefined
  >(undefined);

  const [fairValueData, setFairValueData] = useState<
    FairValueAmountExportProps[] | undefined
  >(undefined);

  const exportExcelTemplete = async () => {
    if (!selectedYear || !selectedYear.data) return;

    const result = await getFairValueList(selectedYear.data);
    if (result) {
      excelDownloadFairValueAmount(selectedYear.data, result);
    }
  };

  const importFairValueData = (data: FairValueAmountExportProps[]) => {
    setFairValueData(data);
  };

  const onDataChange = (newData: FairValueAmountExportProps[]) => {
    setFairValueData([...newData]);
  };

  const isDisabled = () => {
    if (!fairValueData) return true;
    return (
      fairValueData.filter(
        (item) =>
          !item.amount ||
          !item.acFundAccountId ||
          !item.type ||
          !item.companyName ||
          !item.fundName ||
          !item.portfolioId ||
          !item.year
      ).length > 0
    );
  };

  const onUpsertFairValue = async () => {
    if (!fairValueData) return;

    const result = await upsertFairValueList(fairValueData);
    if (result) {
      submit();
    }
  };

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          공정거래 평가금액 일괄입력
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        {fairValueData ? (
          <FairValueTable
            fairValueData={fairValueData}
            onDataChange={onDataChange}
          />
        ) : (
          <FairValueImport
            selectedYear={selectedYear}
            updateSelectedYear={updateSelectedYear}
            exportExcelTemplete={exportExcelTemplete}
            importFairValueData={importFairValueData}
          />
        )}
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={close}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-purple)"}
            hoverColor={"var(--purple-600)"}
            text={"확인"}
            disabled={isDisabled()}
            onClick={onUpsertFairValue}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 800px;
  height: 90vh;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 60px - 74px);
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
