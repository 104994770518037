import { Cookies } from "react-cookie";
import {
  CashDateFlowProps,
  DateTime,
  LoginInfo,
  XIRRDataProps,
} from "../type/data";
import { ReactComponent as CheckRound } from "../assets/image/ic_check_round.svg";
import { ReactComponent as ToastError } from "../assets/image/toast_error.svg";
import { ReactComponent as ToastDelete } from "../assets/image/toast_delete.svg";
import { Bounce, toast, ToastTransition } from "react-toastify";
import moment from "moment";
import axios from "axios";
import * as api from "../api/api";
import { ReferenceDocumentInfo } from "../router/risk-management/reference-document/interface/ReferenceDocument.interface";
import { Logout } from "../router/login/desktop/DeskLogin";
import config from "../config";

export const publicEmailDomain = [
  "gmail.com",
  "daum.net",
  "naver.com",
  "kakao.com",
];
export const NOT_EMAIL_FORM = "이메일 형식이 아닙니다.";
export const NOT_CAMPANY_EMAIL = "회사 이메일을 입력해주세요.";

export const checkNumber = (value: string): boolean => {
  const regExp = /^\d+$/;
  return regExp.test(value);
};
export const checkEmailForm = (email: string): boolean => {
  if (email.length === 0) return true;
  const regExp =
    /^([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@(([\w\.\_\-])*[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/i;
  return regExp.test(email);
};

export const checkPublicEmailForm = (email: string): boolean => {
  const domain = email.slice(email.lastIndexOf("@") + 1);
  return publicEmailDomain.includes(domain);
};

export const checkLanguageKo = (value: string): boolean => {
  const regExp = /[^ㄱ-힣0-9 ]/gi;
  return regExp.test(value);
};

export const checkLanguageEn = (value: string): boolean => {
  const regExp = /[^a-zA-Z0-9 ]/gi;
  return regExp.test(value);
};

export const getHMOnlyNum = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  const newDate = typeof date === "string" ? getNewDate(date) : date;
  const h = newDate.getHours();
  const M = newDate.getMinutes();

  return `${h < 10 ? `0${h}` : h}:${M < 10 ? `0${M}` : M}`;
};

export const getYM = (date: string | Date | undefined) => {
  // if (typeof date === "string" && date.length === 0) return "";
  if (!date) return "yyyy-mm";

  let _date;
  if (typeof date === "string") {
    _date = getNewDate(date);
  } else {
    _date = date;
  }
  const y = _date.getFullYear();
  const m = _date.getMonth() + 1;

  return `${y}-${`${m}`.padStart(2, "0")}`;
};

export const getYMD = (date: string | Date | undefined) => {
  // if (typeof date === "string" && date.length === 0) return "";
  if (!date) return "yyyy-mm-dd";

  let _date;
  if (typeof date === "string") {
    _date = getNewDate(date);
  } else {
    _date = date;
  }
  const y = _date.getFullYear();
  const m = _date.getMonth() + 1;
  const d = _date.getDate();

  return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;
};

export const getYMDKo = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  let _date;
  if (typeof date === "string") {
    _date = getNewDate(date);
  } else {
    _date = date;
  }
  const y = _date.getFullYear();
  const m = _date.getMonth() + 1;
  const d = _date.getDate();

  return `${y}년 ${m < 10 ? `0${m}` : m}월 ${d < 10 ? `0${d}` : d}일`;
};

export const getYMDHMKo = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  let _date;
  if (typeof date === "string") {
    _date = getNewDate(date);
  } else {
    _date = date;
  }

  const y = _date.getFullYear();
  const M = _date.getMonth() + 1;
  const d = _date.getDate();
  const h = _date.getHours();
  const m = _date.getMinutes();

  return `${y}년 ${M < 10 ? `0${M}` : M}월 ${d < 10 ? `0${d}` : d}일 ${
    h < 10 ? `0${h}` : h
  }시 ${m < 10 ? `0${m}` : m}분`;
};

export const getYMDHM = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  let _date;
  if (typeof date === "string") {
    _date = getNewDate(date);
  } else {
    _date = date;
  }
  const y = _date.getFullYear();
  const M = _date.getMonth() + 1;
  const d = _date.getDate();
  const h = _date.getHours();
  const m = _date.getMinutes();

  return `${y}-${M < 10 ? `0${M}` : M}-${d < 10 ? `0${d}` : d} ${
    h < 10 ? `0${h}` : h
  }:${m < 10 ? `0${m}` : m}`;
};

export const getYMDHMS = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  let _date;
  if (typeof date === "string") {
    _date = getNewDate(date);
  } else {
    _date = date;
  }
  const y = _date.getFullYear();
  const M = _date.getMonth() + 1;
  const d = _date.getDate();
  const h = _date.getHours();
  const m = _date.getMinutes();
  const s = _date.getSeconds();

  return `${y}-${M < 10 ? `0${M}` : M}-${d < 10 ? `0${d}` : d} ${
    h < 12 ? "오전" : "오후"
  } ${h < 12 ? h : h - 12}:${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}`;
};

export const getYMDAAHMKo = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  let _date;
  if (typeof date === "string") {
    _date = getNewDate(date);
  } else {
    _date = date;
  }
  const y = _date.getFullYear();
  const M = _date.getMonth() + 1;
  const d = _date.getDate();
  const h = _date.getHours();
  const m = _date.getMinutes();

  return `${y}년 ${M < 10 ? `0${M}` : M}월 ${d < 10 ? `0${d}` : d}일 ${
    h < 12 ? "오전" : "오후"
  } ${h < 12 ? h : h - 12}시 ${m < 10 ? `0${m}` : m}분`;
};

export const getYMDHMSNoDelimiter = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  const newDate = typeof date === "string" ? getNewDate(date) : date;
  const y = newDate.getFullYear();
  const m = newDate.getMonth() + 1;
  const d = newDate.getDate();
  const h = newDate.getHours();
  const M = newDate.getMinutes();
  const S = newDate.getSeconds();

  return `${y}${m < 10 ? `0${m}` : m}${d < 10 ? `0${d}` : d}${
    h < 10 ? `0${h}` : h
  }${M < 10 ? `0${M}` : M}${S < 10 ? `0${S}` : S}`;
};

export const getRangeYear = (
  startYear: number,
  endYear: number,
  increamentNumber: number
): number[] => {
  const _years: number[] = [];
  for (let i = startYear; i < endYear; i++) {
    _years.push(i);
  }
  return _years;
};

export const getNewDate = (dateString?: string): Date => {
  if (!dateString) return moment().toDate();

  return moment(dateString).toDate();
};

export const OnlyNum = (value: string) => {
  if (value === undefined || value === null) return "";
  value = value.replace(/[^0-9]/g, ""); // 입력값이 숫자가 아니면 공백
  value = value.replace(/,/g, ""); // ,값 공백처리
  return value;
};

export const OnlyFloat = (value: string) => {
  if (value === undefined || value === null) return "";

  value = value.replace(/[^-+0-9.]/g, "");
  return value;
};
export const RemoveComma = (num: string | number) => {
  if (num === undefined || num === null) return "";
  if (typeof num === "number") num = `${num}`;

  return num.replaceAll(",", "");
};

export const AddComma = (num?: string | number | null) => {
  if (num === undefined || num === null) return "";
  if (typeof num === "number") num = `${num}`;
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.split(".")[0].replace(regexp, ",");
};

export const addCommasToIntegerPart = (
  inputNumber?: string | number | null
) => {
  if (inputNumber === undefined || inputNumber === null) return "";
  // 숫자를 문자열로 변환
  const numString =
    typeof inputNumber === "number" ? inputNumber.toString() : inputNumber;

  // 소수점 이하 부분과 정수 부분을 분리
  const parts = numString.split(".");
  const integerPart = parts[0];

  // 정수 부분에 쉼표 추가
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // 만약 소수점 이하 부분이 있으면 함께 반환
  if (parts.length > 1) {
    return `${integerWithCommas}.${parts[1]}`;
  }
  return integerWithCommas;
};

export const RegexpNumberWithComma = (num?: string | number | null) => {
  if (num === undefined || num === null) return "";
  if (typeof num === "number") num = `${num}`;

  const regexp = /\B(?=(\d{3})+(?!\d))/g;

  const splitNum = num.split(".");
  const integer = splitNum[0];
  const decimal = splitNum[1] || "";
  const resultNum = integer.replace(regexp, ",");

  return `${resultNum}${
    decimal.length > 0 && parseInt(`${decimal.slice(0, 3)}`, 10) > 0
      ? `.${decimal.slice(0, 3)}`
      : ""
  }`;
};

export const RegexpPhone = (num: string | number) => {
  if (num === undefined || num === null) return "";
  if (typeof num === "number") num = `${num}`;
  let formatNum = num;

  if (num.length === 11) {
    formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  } else if (num.length === 10) {
    if (num.slice(0, 2) === "02") {
      formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
  }
  return formatNum;
};

export const RegexpBusiness = (num: string | number) => {
  if (num === undefined || num === null) return "";
  if (typeof num === "number") num = `${num}`;

  let formatNum = num;

  if (num.length === 10) {
    formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
  }
  return formatNum;
};
export const RegexpCorporateNumber = (num: string | number) => {
  if (num === undefined || num === null) return "";
  if (typeof num === "number") num = `${num}`;

  let formatNum = num;

  if (num.length === 13) {
    formatNum = num.replace(/(\d{6})(\d{7})/, "$1-$2");
  }
  return formatNum;
};

export const RegexpFax = (num: string | number) => {
  if (num === undefined || num === null) return "";
  if (typeof num === "number") num = `${num}`;

  let formatNum = num;

  if (num.length === 11) {
    formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  return formatNum;
};

export const RemoveHypen = (num: string | number) => {
  if (num === undefined || num === null) return "";
  if (typeof num === "number") num = `${num}`;

  return num.replace(/[^\d]+/g, "");
};

export const getMyInfo = () => {
  const cookie = new Cookies();
  if (cookie.get("myInfo") === undefined) {
    Logout();
  }
  const myinfo = cookie.get("myInfo");
  const _myinfo: LoginInfo = {
    ...myinfo,
  };
  return _myinfo;
};

export const isManager = () => {
  const myInfo = getMyInfo();
  return myInfo.authorityId === 2;
};

export const getPortfolioIdInLocation = (location: any) => {
  const transformedObject = location.pathname.split("/")[2];
  const object = trnasfromBase64ToObject(transformedObject);

  return Number.parseInt(object.id);
};

export const getInvestmentAssessmentIdInLocation = (location: any) => {
  const transformedObject = location.pathname.split("/")[4];
  const object = trnasfromBase64ToObject(transformedObject);

  return Number.parseInt(object.id);
};

export const getReportAccountIdInLocation = (location: any) => {
  const transformedObject = location.pathname.split("/")[6];
  const object = trnasfromBase64ToObject(transformedObject);

  if (Number.isInteger(object.id)) {
    return Number.parseInt(object.id);
  } else {
    return object.id;
  }
};

export const getTransformedId = (id: number | string | undefined) => {
  if (id === undefined) {
    return 0;
  }
  return transformObjectToBase64({
    id: `${id}`,
  });
};

export const getKoreanMoneyUnit = (money: number | string) => {
  if (money === undefined || money === null) return "";
  const number = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
  const unit = ["", "만", "억", "조"];
  const smallUnit = ["", "십", "백", "천"];
  const numberList: string[] = money
    .toString()
    .replaceAll(",", "")
    .split("")
    .reverse();
  const result: string[] = [];
  const unitList: string[][] = [];
  for (let i = 0; i < Math.floor(numberList.length); i++) {
    unitList[i] = numberList.slice(4 * i, 4 * i + 4);
  }
  unitList.push(numberList.slice(numberList.length));
  unitList.forEach((units, i) => {
    let subString = "";
    units.forEach((item, item_i) => {
      if (item !== "0") {
        subString = number[parseInt(item)] + smallUnit[item_i] + subString;
      }
    });
    if (subString !== "") result.push(`${subString}${unit[i]} `);
  });
  return result.reverse().toString().replaceAll(",", "");
};

export const dateCalculator = (refDate: Date | string, offset: number) => {
  const date =
    typeof refDate === "string" ? getNewDate(refDate) : new Date(refDate);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + offset);
};

export const getRangeArray = (min: number, max: number): number[] => {
  const rangeArr: number[] = [];
  for (let i = min; i <= max; i++) {
    rangeArr.push(i);
  }
  return rangeArr;
};

export const getRoundNumber = (num: number, digit: number) => {
  let digitNumber = 1;
  for (let i = 1; i <= digit; i++) {
    digitNumber *= 10;
  }
  return Math.round((num + Number.EPSILON) * digitNumber) / digitNumber;
};

export const getFloatNum = (num: number, fixedNum: number) => {
  if (typeof num !== "number") return "0.00";

  return parseFloat(`${num}`).toFixed(fixedNum);
};

export const registeEntryPointToPortfolio = (
  entryPoint: "deal_flow" | "accelerating" | "database"
) => {
  const item = {
    value: entryPoint,
  };
  localStorage.removeItem("entryPoint");
  localStorage.setItem("entryPoint", JSON.stringify(item));
};
export const getEntryPointToPortfolio = (): string | null => {
  const itemStr = localStorage.getItem("entryPoint");
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  return item.value;
};

export const transformObjectToBase64 = (object: any): string => {
  const data = encodeURIComponent(JSON.stringify(object));
  return window.btoa(data);
};

export const trnasfromBase64ToObject = (data: string): any => {
  const decodedData = decodeURIComponent(window.atob(data));
  console.log(decodedData);
  return JSON.parse(decodedData);
};

export const showToast = (data: {
  toastId?: string;
  customElement: JSX.Element;
  backgroundColor?: string;
  color?: string;
  width?: number | string;
  height?: number;
  milliSec?: number;
  position?:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left";
  transition?: ToastTransition;
}) => {
  toast(data.customElement, {
    toastId: data.toastId || "custom-toast",
    autoClose: data.milliSec || 3000,
    style: {
      background: data.backgroundColor || "",
      color: data.color || "",
      width: data.width || "",
      height: data.height || "",
      minHeight: data.height || "",
      maxHeight: data.height || "",
      textAlign: "center",
    },
    bodyStyle: {
      textAlign: "center",
      margin: "0 auto",
    },
    hideProgressBar: true,
    closeButton: false,
    position: data.position || "bottom-right",
    transition: data.transition || Bounce,
  });
};

export const showCustomToast = (messages: string[], duration?: number) => {
  const messageDiv = (
    <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
      <CheckRound />
      <div>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </div>
    </div>
  );
  toast.success(messageDiv, {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: "save",
    autoClose: duration || 3000,
    closeButton: <ToastDelete />,
    style: {
      background: "white",
      width: "444px",
      height: "96px",
      position: "fixed",
      right: 0,
      bottom: 0,
    },
    bodyStyle: {
      color: "black",
      fontWeight: "500px",
      fontSize: "20px",
      lineHeight: "24px",
    },
    progressStyle: {
      background: "#83CC39",
    },
  });
};

export const showCustomToastFailure = (
  messages: string[],
  duration?: number
) => {
  const messageDiv = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "16px",
      }}
    >
      <ToastError />
      <div>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </div>
    </div>
  );
  toast.error(messageDiv, {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: "error",
    autoClose: duration || 3000,
    closeButton: <ToastDelete />,
    style: {
      background: "white",
      width: "444px",
      height: "96px",
      position: "fixed",
      right: 0,
      bottom: 0,
    },
    bodyStyle: {
      color: "black",
      fontWeight: "500px",
      fontSize: "20px",
      lineHeight: "24px",
    },
    progressStyle: {
      background: "#FF0033",
    },
  });
};

export const showErrorToast = (message: string) => {
  showToast({
    customElement: (
      <div
        className="heading-16-sb"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span>{message}</span>
      </div>
    ),
    backgroundColor: "var(--red-500)",
    color: "var(--text-white)",
    width: 274,
    milliSec: 1500,
    transition: Bounce,
    position: "bottom-center",
    toastId: new Date().toString(),
  });
};

export const downloadFileFromUrl = (url: string): void => {
  if (!url) return;

  const anchor = document.createElement("a");
  anchor.href = url;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const getNewUploadStatusId = (prevId?: number): number => {
  switch (prevId) {
    case 1:
    case 3:
      return 1;
    case 2:
    case 4:
      return 2;
    default:
      return 1;
  }
};

export const onDownload = async (s3Key: string, fileName: string) => {
  try {
    const fileUrl = (await axios.get(api.getFile(), { params: { key: s3Key } }))
      .data;

    let link: HTMLAnchorElement | null = document.createElement("a");
    link.href = fileUrl;
    link.download = `${fileName}`;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
  } catch (e) {
    console.log(e);
  }
};

export const checkMobile = () => {
  return window.innerWidth <= 765;
};

export const getHalfBoxText = (month: number | string) => {
  switch (Math.floor(Number.parseInt(`${month}`) / 6)) {
    case 0:
      return "상반기";
    case 1:
      return "하반기";
    default:
      return "상반기";
  }
};

export const referenceDocumentPopup = (
  data: ReferenceDocumentInfo,
  width?: number,
  height?: number
) => {
  const popupWidth = width || screen.width;
  const popupHeight = height || screen.height;
  const domainUrl = config.clientUrl || "";

  const key = transformObjectToBase64(data);

  const url = `${domainUrl}reference-document/${key}`;
  const specs = `width=${popupWidth},height=${popupHeight},left=0,top=0,toolbar=no,menubar=no`;

  window.open(url, "_blank", specs);
};

export const viewPdfPopup = (
  s3Key: string,
  width?: number,
  height?: number
) => {
  const popupWidth = width || screen.width;
  const popupHeight = height || screen.height;
  const domainUrl = config.clientUrl || "";

  const key = transformObjectToBase64({
    key: `${s3Key}`,
  });

  const url = `${domainUrl}pdf-viewer/${key}`;
  const specs = `width=${popupWidth},height=${popupHeight},left=0,top=0,toolbar=no,menubar=no`;

  window.open(url, "_blank", specs);
};

export const viewPdfPopupNoDownload = (
  s3Key: string,
  width?: number,
  height?: number
) => {
  const popupWidth = width || screen.width;
  const popupHeight = height || screen.height;
  const domainUrl = config.clientUrl || "";

  const key = transformObjectToBase64({
    key: `${s3Key}`,
  });

  const url = `${domainUrl}pdf-viewer/${key}/share`;
  const specs = `width=${popupWidth},height=${popupHeight},left=0,top=0,toolbar=no,menubar=no`;

  window.open(url, "_blank", specs);
};

export const getDateToDateTime = (date: string | Date): DateTime => {
  const _date = getYMDHM(date).split(" ");

  return { date: _date[0], time: _date[1] };
};

export const historyBack = (callback?: () => void) => {
  console.log("asdfdasfa");

  window.history.go(-1);
  if (callback) {
    let hasHistory = false;
    window.addEventListener("beforeunload", (e) => {
      hasHistory = true;
    });
    setTimeout(() => {
      if (!hasHistory && callback) {
        callback();
      }
    }, 200);
  }
};

/**
 * XIRR 함수
 * parameter
 *  - cashFlow, dates
 *
 * 설명
 *  일련의 현금 흐름과 해당 현금 흐름의 날짜를 고려하여 연간 수익률을 계산합니다.
 *  이를 위해 수치적 해석 방법인 Newton-Raphson 방법을 사용합니다.
 */

export const xirr = (cashFlows: number[], dates: Date[]) => {
  const maxIterations = 100;
  const precision = 0.0000001;
  let rate = 0.1;

  for (let i = 0; i < maxIterations; i++) {
    let fValue = 0;
    let fDerivative = 0;
    for (let j = 0; j < cashFlows.length; j++) {
      const dt = (dates[j].getTime() - dates[0].getTime()) / 31536000000; // 일수 차이를 연 단위로 변환
      const val = cashFlows[j] / Math.pow(1 + rate, dt);
      fValue += val;
      fDerivative += (-dt * val) / (1 + rate);
    }

    const newRate = rate - fValue / fDerivative;

    if (Math.abs(newRate - rate) < precision) {
      return newRate * 100; // 퍼센트로 반환
    }

    rate = newRate;
  }

  return null; // 수렴하지 않음
};

//회수 성과 CashDateFlow
export const getExitCashDateFlow = (data: XIRRDataProps[]) => {
  const cashDateFlow: CashDateFlowProps[] = [];

  data.sort((a, b) => {
    return a.date.getTime() - b.date.getTime();
  });

  data
    .filter((item) => item.type === 2)
    .forEach((item) => {
      const filterData = data.filter((obj) => {
        return obj.type === 1 && obj.date.getTime() <= item.date.getTime();
      });
      const totalAmount = filterData
        .map((item) => item.originAmount || 0)
        .reduce((p, c) => p + c, 0);

      filterData.forEach((obj) => {
        cashDateFlow.push({
          cash: -(
            ((obj.originAmount || 0) / totalAmount) *
            (item.originAmount || 0)
          ),
          date: obj.date,
        });
      });

      cashDateFlow.push({
        cash: item.exitAmount || 0,
        date: item.date,
      });
    });

  const sortData = cashDateFlow.sort(
    (a, b) => a.date.getTime() - b.date.getTime()
  );

  return sortData;
};

// 지분 성과 CashDateFlow
export const getStockRateCashDateFlow = (
  data: XIRRDataProps[],
  investedValue: number
) => {
  const cashDateFlow: CashDateFlowProps[] = [];

  const totalOriginAmount = data
    .filter((item) => item.type === 1)
    .map((item) => item.originAmount || 0)
    .reduce((p, c) => p + c, 0);
  const totalExitOriginAmount = data
    .filter((item) => item.type === 2)
    .map((item) => item.originAmount || 0)
    .reduce((p, c) => p + c, 0);

  data.sort((a, b) => {
    return a.date.getTime() - b.date.getTime();
  });

  data.forEach((item) => {
    const cash = item.originAmount
      ? -(
          item.originAmount -
          (item.originAmount / totalOriginAmount) * totalExitOriginAmount
        )
      : 0;

    if (item.type === 1 && cash !== 0) {
      cashDateFlow.push({
        cash: cash,
        date: item.date,
      });
    }
  });

  if (investedValue > 0) {
    cashDateFlow.push({
      cash: investedValue,
      date: getNewDate(),
    });
  }
  return cashDateFlow;
};

// 전체 성과 CashDateFlow
export const getCashDateFlow = (
  data: XIRRDataProps[],
  investedValue: number
) => {
  const cashDateFlow: CashDateFlowProps[] = [];

  data.sort((a, b) => {
    return a.date.getTime() - b.date.getTime();
  });

  data.forEach((item) => {
    if (item.type === 1) {
      cashDateFlow.push({
        cash: item.originAmount ? -item.originAmount : 0,
        date: item.date,
      });
    } else {
      cashDateFlow.push({
        cash: item.exitAmount ? item.exitAmount : 0,
        date: item.date,
      });
    }
  });

  if (investedValue > 0) {
    cashDateFlow.push({
      cash: investedValue,
      date: getNewDate(),
    });
  }
  return cashDateFlow;
};

export const getTransformDateToYearQuarter = (
  isStart: boolean,
  y: string,
  q: string
) => {
  switch (q) {
    case "1": {
      return `${y}-${isStart ? "01-01" : "03-31"}`;
    }
    case "2": {
      return `${y}-${isStart ? "04-01" : "06-30"}`;
    }
    case "3": {
      return `${y}-${isStart ? "07-01" : "09-30"}`;
    }
    case "4": {
      return `${y}-${isStart ? "10-01" : "12-31"}`;
    }
  }
};

export const roundToDecimals = (value: number, decimals?: number) => {
  decimals = decimals === undefined ? 3 : decimals;
  const division = 10 ** decimals;
  return Math.round(value * division) / division;
};

export const residentRegistrationNumberMaskStar = (value: string) => {
  const [firstPart, secondPart] = value.split("-");
  const maskedSecondPart = secondPart.charAt(0) + "******";
  return `${firstPart}-${maskedSecondPart}`;
};

export const getQuarterDate = (year: number, quater: number) => {
  switch (quater) {
    case 1:
      return `${year}-03-31`;
    case 2:
      return `${year}-06-30`;
    case 3:
      return `${year}-09-30`;
    default:
      return `${year}-12-31`;
  }
};
