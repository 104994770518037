import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  title?: string;
}

const TitleQuote: React.FC<Props> = (props) => {
  const { title, children } = props;
  return (
    <Container>
      <TitleContainer className="caption-14-md">{title}</TitleContainer>
      <QuoteContainer>{children}</QuoteContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const TitleContainer = styled.div`
  width: 100%;
`;
const QuoteContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  border-left: 1px solid var(--gray-200, #edeeef);
`;
export default TitleQuote;
