import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FundUser } from "../interface/FundAccount.interface";
import { ReactComponent as DefaultUser } from "../../../../../assets/image/default_user_img.svg";
import { ReactComponent as Del } from "../../../../../assets/image/chip_delete.svg";

interface Props {
  user: FundUser;
  deleteUser: (user: FundUser) => void;
}
export const FundUserCard: React.FC<Props> = (props) => {
  const { user, deleteUser } = props;
  return (
    <Container>
      <AvatarContainer>
        {user.profileImgUrl ? (
          <img
            className="avatar"
            src={user.profileImgUrl}
            loading="lazy"
            alt={"profile img"}
          />
        ) : (
          <DefaultUser className="avatar" />
        )}
      </AvatarContainer>
      <UserInfoContainer>
        <div className="caption-12-md">{user.name}</div>
        <Del
          className="icon"
          onClick={() => {
            deleteUser(user);
          }}
        />
      </UserInfoContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: max-content;
  height: max-content;

  padding: 8px 12px;
  background: var(--background-contents01);
  border: 1px solid var(--gray-200);
  border-radius: 4px;

  gap: 24px;

  .icon {
    cursor: pointer;
  }
`;

const AvatarContainer = styled.div`
  position: relative;
  width: 32px;
  height: 32px;

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    border: 1px solid var(--gray-200);
    border-radius: 50%;
  }
`;

const UserInfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
