import React, { useState } from "react";
import styled from "styled-components";
import { FundAccount, FundUser } from "../../interface/FundAccount.interface";
import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import { InputBox } from "../../../../../../components-2.0v/input-box/InputBox";
import { InputDate } from "../../../../../../components-2.0v/date-picker/InputDate";
import { FunduserSelector } from "../../component/FunduserSelector";
import {
  RemoveComma,
  getNewDate,
  getYMD,
  showToast,
} from "../../../../../../common/commonUtil";

import { FundTerms } from "../../component/FundTerms";
import { Bounce } from "react-toastify";
import { FundStatus } from "../../component/FundStatus";
import { DefaultButton } from "lattice_core";

interface Props {
  fundAccount: FundAccount;
  allFundAcocunt: FundAccount[];
  allUser: FundUser[];
  onChangeFundAccountInfo: (newData: FundAccount) => void;
}

export const StepFirst: React.FC<Props> = (props) => {
  const { fundAccount, allFundAcocunt, allUser, onChangeFundAccountInfo } =
    props;

  const [isAddOperatingDate, setIsAddOperatingDate] = useState<boolean>(
    fundAccount.addOperatingEndDate !== undefined &&
      fundAccount.addOperatingEndDate !== null
  );

  const checkDuplicateSerialNumber = (serialNumber: string) => {
    const duplicate = allFundAcocunt
      .filter((item) => item.acFundAccountId !== fundAccount.acFundAccountId)
      .find((item) => item.serialNumber === serialNumber);
    if (duplicate) {
      showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span>고유 번호가 중복되었습니다.</span>
          </div>
        ),
        backgroundColor: "var(--red-500)",
        color: "var(--white)",
        width: 274,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
      return false;
    }
    return true;
  };

  console.log(fundAccount);

  return (
    <Container>
      <FlexColumn style={{ gap: 16, flex: 6, padding: "24px" }}>
        <RowTitleBox required title="펀드 상태" titleWidth={95}>
          <FundStatus
            fundStatus={fundAccount.status}
            onChangeStatus={(status) => {
              fundAccount.status = status;
              onChangeFundAccountInfo(fundAccount);
            }}
          />
        </RowTitleBox>
        <RowTitleBox required title="고유 번호" titleWidth={95}>
          <InputBox
            textType={"businessNumber"}
            placeholder="펀드의 고유 번호(xxx-xx-xxxxx)를 입력해주세요."
            defaultValue={fundAccount.serialNumber}
            onChange={(value) => {
              checkDuplicateSerialNumber(value);
            }}
            onBlur={(value) => {
              fundAccount.serialNumber = value;
              onChangeFundAccountInfo(fundAccount);
            }}
          />
        </RowTitleBox>
        <FlexRow style={{ gap: 24 }}>
          <RowTitleBox
            required
            title="펀드명"
            titleWidth={95}
            style={{ flex: 1 }}
          >
            <InputBox
              placeholder="펀드명을 입력해주세요."
              defaultValue={fundAccount.nameKo}
              onBlur={(value) => {
                fundAccount.nameKo = value;
                onChangeFundAccountInfo(fundAccount);
              }}
            />
          </RowTitleBox>
          <RowTitleBox title="펀드 별칭" titleWidth={95} style={{ flex: 1 }}>
            <InputBox
              placeholder="펀드 별칭을 입력해주세요."
              defaultValue={fundAccount.nickName}
              onBlur={(value) => {
                fundAccount.nickName = value;
                onChangeFundAccountInfo(fundAccount);
              }}
            />
          </RowTitleBox>
        </FlexRow>

        <RowTitleBox required title="펀드명(영문)" titleWidth={95}>
          <InputBox
            placeholder="영문 펀드명을 입력해주세요."
            defaultValue={fundAccount.nameEn}
            onBlur={(value) => {
              fundAccount.nameEn = value;
              onChangeFundAccountInfo(fundAccount);
            }}
          />
        </RowTitleBox>

        <RowTitleBox required title="투자 기간" titleWidth={95}>
          <FlexRow style={{ gap: 11 }}>
            <InputDate
              width={135}
              defaultDate={fundAccount.investmentStartDate}
              onChange={(value) => {
                if (fundAccount.investmentEndDate) {
                  const sDate = getNewDate(value);
                  const eDate = getNewDate(fundAccount.investmentEndDate);
                  if (eDate.getTime() - sDate.getTime() <= 0) {
                    fundAccount.investmentEndDate = undefined;
                  }
                }
                fundAccount.investmentStartDate = getYMD(value);
                onChangeFundAccountInfo(fundAccount);
              }}
            />
            <span style={{ marginTop: 12 }}>~</span>
            <InputDate
              width={135}
              defaultDate={fundAccount.investmentEndDate}
              min={fundAccount.investmentStartDate}
              onChange={(value) => {
                const operatingStartDate = getNewDate(value);
                operatingStartDate.setDate(operatingStartDate.getDate() + 1);
                fundAccount.operatingStartDate = getYMD(operatingStartDate);
                fundAccount.investmentEndDate = getYMD(value);

                if (fundAccount.operatingEndDate) {
                  const sDate = getNewDate(value);
                  const eDate = getNewDate(fundAccount.operatingEndDate);
                  if (eDate.getTime() - sDate.getTime() <= 0) {
                    fundAccount.operatingEndDate = undefined;
                  }
                }
                onChangeFundAccountInfo(fundAccount);
              }}
            />
          </FlexRow>
        </RowTitleBox>

        <FlexRow style={{ gap: 24 }}>
          <RowTitleBox
            required
            title="운영 기간"
            titleWidth={95}
            style={{ flex: 1 }}
          >
            <FlexRow style={{ gap: 11 }}>
              <InputDate
                width={135}
                readonly
                defaultDate={fundAccount.operatingStartDate}
              />
              <span style={{ marginTop: 12 }}>~</span>
              <InputDate
                width={135}
                defaultDate={fundAccount.operatingEndDate}
                min={fundAccount.operatingStartDate}
                onChange={(value) => {
                  const operatingEndDate = getNewDate(value);
                  fundAccount.operatingEndDate = getYMD(operatingEndDate);
                  onChangeFundAccountInfo(fundAccount);
                }}
              />
            </FlexRow>
          </RowTitleBox>
          <RowTitleBox title="청산 연장" titleWidth={95} style={{ flex: 1 }}>
            {isAddOperatingDate ? (
              <FlexRow style={{ gap: 11 }}>
                <InputDate
                  width={135}
                  defaultDate={fundAccount.addOperatingEndDate}
                  min={fundAccount.operatingEndDate}
                  onChange={(value) => {
                    const addOperatingEndDate = getNewDate(value);
                    fundAccount.addOperatingEndDate =
                      getYMD(addOperatingEndDate);
                    onChangeFundAccountInfo(fundAccount);
                  }}
                />
              </FlexRow>
            ) : (
              <div style={{ marginTop: 5 }}>
                <DefaultButton
                  className="heading-14-sb"
                  sizeType="xxsmall"
                  buttonColor={"var(--primary-purple)"}
                  hoverColor={"var(--purple-700)"}
                  onClick={() => {
                    setIsAddOperatingDate((prev) => !prev);
                  }}
                  text="청산 연장하기"
                />
              </div>
            )}
          </RowTitleBox>
        </FlexRow>

        <FlexRow style={{ gap: 24 }}>
          <RowTitleBox
            required
            title="GP 수수료"
            titleWidth={95}
            style={{ flex: 3.9 }}
          >
            <InputBox
              textType={"numberWithComma"}
              placeholder="투자 수수료 + 운용 수수료 모두 합한 금액입력"
              defaultValue={
                fundAccount.gpFee ? `${fundAccount.gpFee}` : undefined
              }
              onBlur={(value) => {
                fundAccount.gpFee = parseFloat(RemoveComma(`${value}`));
                onChangeFundAccountInfo(fundAccount);
              }}
            />
          </RowTitleBox>
          <RowTitleBox
            required
            title="기준 수익율"
            titleWidth={95}
            style={{ flex: 2 }}
          >
            <InputBox
              textType={"percent"}
              placeholder="XX.XX%"
              defaultValue={`${fundAccount.baseRate || ""}`}
              onBlur={(value) => {
                fundAccount.baseRate =
                  value.length === 0 ? undefined : parseFloat(value);
                onChangeFundAccountInfo(fundAccount);
              }}
            />
          </RowTitleBox>
        </FlexRow>
        <RowTitleBox required title="정족수" titleWidth={95}>
          <InputBox
            width={332}
            textType={"number"}
            placeholder="투자 심사 개최에 필요한 최소 인원"
            defaultValue={`${fundAccount.quorum || ""}`}
            onBlur={(value) => {
              fundAccount.quorum = parseInt(value);
              onChangeFundAccountInfo(fundAccount);
            }}
          />
        </RowTitleBox>
        <RowTitleBox required title="펀드 조건" titleWidth={95}>
          <FundTerms
            terms={fundAccount.terms}
            onChangeTerms={(newTerms) => {
              console.log(newTerms);
              fundAccount.terms = newTerms;
              onChangeFundAccountInfo(fundAccount);
            }}
          />
        </RowTitleBox>
      </FlexColumn>

      <FlexColumn style={{ gap: 16, flex: 4, padding: "24px" }}>
        <RowTitleBox required title="대표 펀드 매니저" titleWidth={120}>
          <FunduserSelector
            type="single"
            userList={allUser}
            selectedUser={
              fundAccount.fundManageUser.length === 1
                ? [fundAccount.fundManageUser[0].acUser]
                : []
            }
            onClickUser={(user) => {
              console.log(user);
              fundAccount.fundManageUser = [{ acUser: user }];
              onChangeFundAccountInfo(fundAccount);
            }}
            onDeleteUser={(user) => {
              fundAccount.fundManageUser = [];
              onChangeFundAccountInfo(fundAccount);
            }}
          />
        </RowTitleBox>

        <RowTitleBox required title="핵심운용 인력" titleWidth={120}>
          <FunduserSelector
            type="multi"
            userList={allUser}
            selectedUser={fundAccount.coreOperationalUser.map(
              (item) => item.acUser
            )}
            onClickUser={(user) => {
              const index = fundAccount.coreOperationalUser.findIndex(
                (item) => item.acUser.acUserId === user.acUserId
              );
              if (index !== -1) {
                fundAccount.coreOperationalUser.splice(index, 1);
              } else {
                fundAccount.coreOperationalUser.push({ acUser: user });
              }
              onChangeFundAccountInfo(fundAccount);
            }}
            onDeleteUser={(user) => {
              const index = fundAccount.coreOperationalUser.findIndex(
                (item) => item.acUser.acUserId === user.acUserId
              );
              if (index !== -1) {
                fundAccount.coreOperationalUser.splice(index, 1);
              }
              onChangeFundAccountInfo(fundAccount);
            }}
          />
        </RowTitleBox>
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  gap: 44px;
  box-sizing: border-box;
  border-top: 1px solid var(--gray-400);
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
