import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { AuthrityDataProps, BluecardLinkProps } from "../type";
import { useLocation } from "react-router-dom";
import {
  getBluecardInfoBypfLinkId,
  registeAuthritoryData,
} from "../repository/BluecardRepository";

const useBluecardLink = () => {
  const location = useLocation();
  const rootRef = useRef<HTMLDivElement>(null);
  const [is404, setIs404] = useState<boolean>(false);
  const [isAuthority, setIsAuthority] = useState<boolean>(false);
  const [pfLinkId, updatePfLinkId] = useState<string | undefined>(undefined);
  const [bluecardInfo, updateBluecardInfo] = useState<
    BluecardLinkProps | undefined
  >(undefined);

  const [showToTopBtn, updateShowToTopBtn] = useState<boolean>(false);

  const checkLinkId = () => {
    const uuid = location.pathname.split("/")[3];
    updatePfLinkId(uuid);
    setIsAuthority(checkAuth(uuid));
  };

  const getBluecardInfo = async (linkId: string) => {
    const result = await getBluecardInfoBypfLinkId(linkId);
    if (result) {
      setIs404(result.info.length === 0);
      updateBluecardInfo(result);
    }
  };

  const onScrollHandler = (e: any) => {
    console.log("scroll");
    if (!rootRef.current) return;

    updateShowToTopBtn(rootRef.current.scrollTop > 1000);
  };

  const toGotop = () => {
    requestAnimationFrame(() => {
      if (!rootRef.current) return;
      rootRef.current.scrollTo({ top: 0, behavior: "smooth" });
    });
  };

  const sendAuthrityData = async (data: AuthrityDataProps) => {
    if (!pfLinkId) return;

    const result = await registeAuthritoryData(data, pfLinkId);
    if (result) {
      localStorage.setItem(pfLinkId, "true");
      setIsAuthority(true);
    }
  };

  const checkAuth = (pfLinkId: string) => {
    const data = localStorage.getItem(pfLinkId);
    if (data) {
      if (data === "true") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (pfLinkId != undefined) {
      getBluecardInfo(pfLinkId);
    }
  }, [pfLinkId]);

  useEffect(() => {
    if (bluecardInfo === undefined) return;
  }, [bluecardInfo]);

  useEffect(() => {
    if (!rootRef.current) return;
    rootRef.current.addEventListener("scroll", onScrollHandler);
    return () => {
      if (!rootRef.current) return;
      rootRef.current.removeEventListener("scroll", onScrollHandler);
    };
  }, []);

  useLayoutEffect(() => {
    checkLinkId();
  }, []);

  return [
    isAuthority,
    rootRef,
    bluecardInfo,
    showToTopBtn,
    toGotop,
    sendAuthrityData,
  ] as const;
};

export default useBluecardLink;
