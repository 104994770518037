import React from "react";
import styled from "styled-components";

interface Props {
  style?: React.CSSProperties;
}

export const CMSHeader: React.FC<Props> = (props) => {
  const HEIGHT = 40;
  return (
    <Container style={props.style}>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          flexGrow: 1,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        소속명
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 140,
          minWidth: 140,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        투자 기업 수
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 140,
          minWidth: 140,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        기 손상 기업 수
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 140,
          minWidth: 140,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        리스크 이슈 발생기업 수
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 140,
          minWidth: 140,
          height: HEIGHT,
          minHeight: HEIGHT,
          maxHeight: HEIGHT,
        }}
      >
        당 손상 기업 수
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.div`
  width: 100%;
  cursor: default;
  color: #000;
  background-color: var(--gray-500);
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  border-top: 2px solid #000;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 8px;

  &:nth-child(1) {
    border-left: 2px solid #000;
  }
`;
