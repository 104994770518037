import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { PortfolioNavigationProps } from "../../interface/PortfolioNavigation.interface";

import { getDealFlowPhaseToWord } from "../../../../deal-flow/interface/DealFlow.interface";
import PortfolioNavigationMenu from "../components/PortfolioNavigationMenu";
import { ReactComponent as Dashboard } from "../../../../../assets/image/portfolio_detail_dashboard.svg";
import { ReactComponent as Info } from "../../../../../assets/image/portfolio_detail_info.svg";
import { ReactComponent as Bluecard } from "../../../../../assets/image/portfolio_detail_bluecard.svg";
import { ReactComponent as InvestmentInfo } from "../../../../../assets/image/portfolio_detail_investment_info.svg";
import { ReactComponent as Pitching } from "../../../../../assets/image/portfolio_detail_pitching.svg";
import { ReactComponent as Evaluation } from "../../../../../assets/image/portfolio_detail_evaluation.svg";

import { ReactComponent as Drop } from "../../../../../assets/image/icon_drop_deal.svg";
import { ReactComponent as Next } from "../../../../../assets/image/icon_next.svg";
import { ReactComponent as Danger } from "../../../../../assets/image/icon_danger.svg";
import DefaultIndustry from "../../../../../assets/image/industry_default.png";
import { Tooltip } from "react-tooltip";

interface Props {
  isEditableUser?: boolean;
  portfolioInfo?: PortfolioNavigationProps;
  currentMenu: string;
  emptyData: string[];
  duplicateData: string[];
  goToMenu: (menu: string) => void;
  exitPortfolio: () => void;
  onClickDropButton?: () => void;
  onClickRequestContract?: () => void;
  onClickRequestContractCancel?: () => void;
  onClickNextDeal?: () => void;
}
const PortfolioNavigation: React.FC<Props> = (props) => {
  const {
    isEditableUser,
    portfolioInfo,
    currentMenu,
    emptyData,
    duplicateData,
    goToMenu,
    exitPortfolio,
    onClickDropButton,
    onClickRequestContract,
    onClickRequestContractCancel,
    onClickNextDeal,
  } = props;
  const INFO_LINE_TITLE_WIDTH = 64;
  if (!portfolioInfo) return null;
  const DangerTooltip = () => {
    if (emptyData.length === 0 && duplicateData.length === 0) return null;
    const dangerData: string[] = [];
    emptyData.forEach((item) => {
      switch (item) {
        case "companyName":
          dangerData.push("기업명을 입력해 주세요.");
          break;
        case "ceoName":
          dangerData.push("대표이사명 입력해 주세요.");
          break;
        case "ceoEmail":
          dangerData.push("이메일을 입력해 주세요.");
          break;
        case "ceoPhone":
          dangerData.push("전화번호를 입력해 주세요.");
          break;
        case "address":
          dangerData.push("주소를 입력해 주세요.");
          break;
        case "businessNumber":
          dangerData.push("사업자 등록번호를 입력해 주세요.");
          break;
        case "establishmentDay":
          dangerData.push("설립일을 입력해 주세요.");
          break;
        case "businessPlanFile":
          dangerData.push("상세 IR자료를 입력해 주세요.");
          break;
      }
    });
    duplicateData.forEach((item) => {
      switch (item) {
        case "companyName":
          dangerData.push("기업명이 중복되었어요.");
          break;
        case "ceoEmail":
          dangerData.push("이메일이 중복되었어요.");
          break;
        case "ceoPhone":
          dangerData.push("전화번호가 중복되었어요.");
          break;
        case "businessNumber":
          dangerData.push("사업자 등록번호가 중복되었어요.");
          break;
      }
    });

    return (
      <div style={{ marginTop: 2 }}>
        <Danger
          className="danger__bubble"
          // data-tooltip-delay-hide={2000}
          style={{ outline: "none" }}
        />
        <Tooltip
          className="body-14-rg"
          anchorSelect=".danger__bubble"
          place="bottom-start"
          border={0}
          style={{
            position: "fixed",
            zIndex: 99999999,
            lineHeight: 1.5,
            borderRadius: 4,
          }}
        >
          {dangerData.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </Tooltip>
      </div>
    );
  };

  return (
    <Container>
      {portfolioInfo.pfPhase.type !== "DROP" &&
        portfolioInfo.pfPhase.type !== "EXCAVATION" && (
          <PortfolioNavigationMenu
            isActive={currentMenu === "dashboard"}
            title="대시보드"
            onClick={() => {
              goToMenu("dashboard");
            }}
          />
        )}
      <PortfolioNavigationMenu
        isActive={["info", "info-modify"].includes(currentMenu)}
        title="기본 정보"
        dangerTooltip={<DangerTooltip />}
        onClick={() => {
          goToMenu("info");
        }}
      />
      {portfolioInfo.pfPhase.type !== "DROP" &&
        portfolioInfo.pfPhase.type !== "EXCAVATION" && (
          <PortfolioNavigationMenu
            isActive={currentMenu === "bluecard"}
            title="블루 카드"
            onClick={() => {
              goToMenu("bluecard");
            }}
          />
        )}
      {portfolioInfo.pfPhase.type !== "DROP" &&
        portfolioInfo.pfPhase.type !== "EXCAVATION" && (
          <PortfolioNavigationMenu
            isActive={currentMenu === "investment-info"}
            title="투자 정보"
            onClick={() => {
              goToMenu("investment-info");
            }}
          />
        )}
      {portfolioInfo.pfPhase.type !== "DROP" &&
        portfolioInfo.pfPhase.type !== "EXCAVATION" && (
          <PortfolioNavigationMenu
            isActive={currentMenu === "reporting"}
            title="분기 보고"
            onClick={() => {
              goToMenu("reporting");
            }}
          />
        )}

      {(portfolioInfo.phase || portfolioInfo.pfPhase.type !== "EXCAVATION") && (
        <PortfolioNavigationMenu
          isActive={["pitching-detail", "pitching"].includes(currentMenu)}
          title="피칭 심사"
          onClick={() => {
            goToMenu("pitching");
          }}
        />
      )}
      {(portfolioInfo.phase || portfolioInfo.pfPhase.type !== "EXCAVATION") && (
        <PortfolioNavigationMenu
          isActive={["evaluation-detail", "evaluation"].includes(currentMenu)}
          title="투자 심사"
          onClick={() => {
            goToMenu("evaluation");
          }}
        />
      )}

      {/* <FooterContaiiner>
        <div
          style={{
            background: "var(--gray-200)",
            width: "calc(100% - 48px)",
            height: 1,
            margin: "0 auto 8px auto",
          }}
        />
        {isEditableUser &&
          portfolioInfo.phase &&
          portfolioInfo.phase === "investment" && (
            <EventBtn
              className="heading-14-md"
              onClick={onClickRequestContract}
            >
              <Next />
              <span>계약요청</span>
            </EventBtn>
          )}
        {isEditableUser &&
          portfolioInfo.phase &&
          portfolioInfo.phase === "contract" && (
            <EventBtn
              className="heading-14-md"
              onClick={onClickRequestContractCancel}
            >
              <Next style={{ transform: "rotate(180deg)" }} />
              <span>계약요청 취소</span>
            </EventBtn>
          )}
        {isEditableUser &&
          portfolioInfo.pfPhase.type === "MANAGE" &&
          !portfolioInfo.phase && (
            <EventBtn className="heading-14-md" onClick={onClickNextDeal}>
              <Next />
              <span>후행 검토 단계로</span>
            </EventBtn>
          )}
        {isEditableUser && portfolioInfo.phase && (
          <EventBtn className="heading-14-md" onClick={onClickDropButton}>
            <Drop />
            <span>드롭</span>
          </EventBtn>
        )}
        <ExitBtn onClick={exitPortfolio}>나가기</ExitBtn>
      </FooterContaiiner> */}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  overflow: scroll;
  gap: 24px;

  padding: 0 24px;
`;

export default PortfolioNavigation;
