import { DateTime, UserInfo } from "../../../../type/data";
import { DealFlowStatus } from "../../portfolio-detail/interface/PortfolioNavigation.interface";

export type QuestionType =
  | "short"
  | "long"
  | "single"
  | "multiple"
  | "linear"
  | "title"
  | "image"
  | "youtube";

export enum PitchingStatus {
  OPEN = 1,
  PUBLISH = 2,
  PITCHING = 3,
  FINISH = 4,
}

export interface DealFlowPitchingData {
  id: number;
  title: string;
  status: DealFlowStatus;
  pfPreEvaluation: PrePitchingData[];
}

export interface PrePitchingData {
  status: PitchingStatus;
  preEvaluationId?: number | undefined;
  preEvaluationDate?: string | undefined;
  preEvaluationData?: SectionType[] | undefined;
  preEvaluationInviteUser?: UserInfo[];
  preEvaluationResult?: { acUserId: number }[];
  pfPreEvaluationResult: PrePichtingResult[];
  templateJson?: PrePitchingData;
  createdAt: string;
}

export interface PrePichtingResult {
  pfPreEvaluationResultId: number;
  templateJson: {
    preEvaluationData: SectionType[];
  };
  createdAt: string;
  updatedAt: string;
  acUser: {
    acUserId: number;
    name: string;
    profileImgKey?: string;
    profileImgUrl?: string;
    acStructure: {
      acStructureId: number;
      name: string;
    };
  };
}

export interface SectionType {
  title?: string;
  description?: string;
  list?: QuestionItemType[];
}

export interface QuestionItemType {
  type: QuestionType;
  title: string;

  isRequired?: boolean;
  description?: string;
  column?: ColumnType[];
  hasOtherOpinion?: boolean;
  minRange?: number;
  minRangeTitle?: string;
  maxRange?: number;
  maxRangeTitle?: string;

  imageData?: ImageType;
  youtubeData?: YoutubeType;

  answer?: undefined | number | ColumnType | ColumnType[] | string;
  otherOpinion?: OtherOpinion;
  // descriptionImg: string;
}

export interface ColumnType {
  title: string;
  isSelected: boolean;
  // Todo 나중에 확장시
  // isImage?: boolean;
  // imgUrl?: string;
}

export interface OtherOpinion {
  opinion: string;
  isSelected: boolean;
}
export interface ImageType {
  imageUrl?: string;
  width?: number;
  ratio?: number;
}
export interface YoutubeType {
  videoId?: string;
  thumbNail?: string;
  width?: number;
  ratio?: number;
}
