import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../SummaryBusinessPlanV2Download";

interface Props {
  front?: string;
  back?: string;
}

export const Header: React.FC<Props> = (props) => {
  return (
    <Container convertPixelToCm={convertPixelToCm}>
      <span className="heading-16-md">{props.front || ""}</span>{" "}
      <Divier convertPixelToCm={convertPixelToCm} />{" "}
      <span className="heading-16-md">{props.back || ""}</span>
    </Container>
  );
};

const Container = styled.div<{ convertPixelToCm: (n: number) => number }>`
  width: 100%;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;
  padding-bottom: 10px;

  span {
    width: max-content;
    min-width: max-content;
  }
`;

const Divier = styled.div<{ convertPixelToCm: (n: number) => number }>`
  width: 100%;
  height: 2px;
  background-color: var(--primary-black);
`;
