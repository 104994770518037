import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SBPTabLayout } from "./components/SBPTabLayout";
import { SBPStatus } from "./components/page/SBPStatus";
import { SBPPlan } from "./components/page/SBPPlan";
import { SBPRequest } from "./components/page/SBPRequest";
import useSBP from "./hook/useSBP";
import { getYMDHM } from "../../../../../../common/commonUtil";
import { DocumentFile } from "../../ReportingDetail";
import { SBPIssue } from "./components/page/SBPIssue";
import { SBPContentsPropsV2 } from "./interface/SBP.interface";

interface Props {
  document: SBPContentsPropsV2;
}

const SummaryBusinessPlanV2: React.FC<Props> = (props: Props) => {
  const { document } = props;
  const [tabList, selectedTabId, updateSelectedTabId, goToTop] =
    useSBP(document);

  return (
    <Container>
      <Body id="sbp__root">
        {document && (
          <ContentsContainer>
            <SBPTabLayout
              isReadOnly={true}
              tabList={tabList}
              selectedTabId={selectedTabId}
              onClickTab={(id) => {
                goToTop();
                updateSelectedTabId(id);
              }}
            />
            {selectedTabId === 1 && (
              <SBPStatus isReadOnly={true} data={document} />
            )}
            {selectedTabId === 2 && (
              <SBPPlan isReadOnly={true} data={document} />
            )}
            {selectedTabId === 3 && (
              <SBPIssue isReadOnly={true} data={document} />
            )}
            {selectedTabId === 4 && (
              <SBPRequest isReadOnly={true} data={document} />
            )}
          </ContentsContainer>
        )}
      </Body>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 46px 36px 46px;
  overflow: scroll;
  background-color: var(--background-page);
`;

const ContentsContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export default SummaryBusinessPlanV2;
