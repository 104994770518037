import React from "react";
import styled from "styled-components";
import { ReactComponent as Info } from "../../../../assets/image/icon_info.svg";

interface Props {
  text: string;
}

export const SectorDescription: React.FC<Props> = (props: Props) => {
  return (
    <Container>
      <Info />
      <div className="body-14-rg">{props.text}</div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--gray-50);
  border-radius: 4px;
  gap: 8px;
  padding: 12px 16px;
  color: var(--text-01);

  border: 1px solid var(--gray-300);
`;
