import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  RMEDIssue,
  RMEDREsultStatus,
} from "../../../evaluation-detail/interface/RMED.interface";
import {
  RMTEFilterDataProps,
  RMTEFilterType,
  RMTEStructureProps,
} from "../../interface/RMTE.interface";

import CheckedText from "../../../../../components-2.0v/check-box/CheckedText";
import CheckedChip from "../../../../excavation/desktop/components/CheckedChip";
import RefreshChip from "../../../../excavation/desktop/components/RefreshChip";

type Filter = "ISSUE" | "RISK_STATUS" | "STRUCTURE" | undefined;
interface Props {
  allIssue: RMEDIssue[];
  allRiskStatus: RMEDREsultStatus[];
  allStructure: RMTEStructureProps[];
  filterData?: RMTEFilterDataProps;
  onChangeFilterData: (type: RMTEFilterType, data: any) => void;
}

const FilterView: React.FC<Props> = (props) => {
  const {
    allIssue,
    allRiskStatus,
    allStructure,
    filterData = { issue: [], structure: [], riskStatus: [], keyword: "" },
    onChangeFilterData,
  } = props;

  const [isShowFilter, updateShowFilter] = useState<boolean>(false);
  const [openFilter, updateOpenFilter] = useState<Filter>(undefined);

  const [isShowFilterModal, updateShowFilterModal] = useState<boolean>(false);

  const onClickIssue = (item: number) => {
    const selectedIssue = [...(filterData?.issue || [])];
    const targetIndex = selectedIssue.findIndex((data) => data === item);
    if (targetIndex === -1) {
      onChangeFilterData("issue", [...selectedIssue, item]);
    } else {
      selectedIssue.splice(targetIndex, 1);
      onChangeFilterData("issue", [...selectedIssue]);
    }
  };

  const onClickRiskStatus = (item: number) => {
    const selectedRiskStatus = [...(filterData?.riskStatus || [])];
    const targetIndex = selectedRiskStatus.findIndex((data) => data === item);
    if (targetIndex === -1) {
      onChangeFilterData("riskStatus", [...selectedRiskStatus, item]);
    } else {
      selectedRiskStatus.splice(targetIndex, 1);
      onChangeFilterData("riskStatus", [...selectedRiskStatus]);
    }
  };

  const onClickStructure = (item: number) => {
    const selectedStructure = [...(filterData?.structure || [])];
    const targetIndex = selectedStructure.findIndex((data) => data === item);
    if (targetIndex === -1) {
      onChangeFilterData("structure", [...selectedStructure, item]);
    } else {
      selectedStructure.splice(targetIndex, 1);
      onChangeFilterData("structure", [...selectedStructure]);
    }
  };

  return (
    <Container>
      <FelxRow
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FelxRow style={{ gap: 12, flexWrap: "wrap" }}>
          <CheckedChip
            isSelected={openFilter === "ISSUE"}
            isActive={filterData.issue.length > 0}
            contentsString={`리스크${
              filterData.issue.length > 0 ? ` (${filterData.issue.length})` : ""
            }`}
            onClick={() => {
              if (openFilter === "ISSUE") {
                updateOpenFilter(undefined);
                updateShowFilter(false);
              } else {
                updateOpenFilter("ISSUE");
                updateShowFilter(true);
              }
            }}
          />
          <CheckedChip
            isSelected={openFilter === "RISK_STATUS"}
            isActive={filterData.riskStatus.length > 0}
            contentsString={`전망${
              filterData.riskStatus.length > 0
                ? ` (${filterData.riskStatus.length})`
                : ""
            }`}
            onClick={() => {
              if (openFilter === "RISK_STATUS") {
                updateOpenFilter(undefined);
                updateShowFilter(false);
              } else {
                updateOpenFilter("RISK_STATUS");
                updateShowFilter(true);
              }
            }}
          />
          <CheckedChip
            isSelected={openFilter === "STRUCTURE"}
            isActive={filterData.structure.length > 0}
            contentsString={`팀${
              filterData.structure.length > 0
                ? ` (${filterData.structure.length})`
                : ""
            }`}
            onClick={() => {
              if (openFilter === "STRUCTURE") {
                updateOpenFilter(undefined);
                updateShowFilter(false);
              } else {
                updateOpenFilter("STRUCTURE");
                updateShowFilter(true);
              }
            }}
          />
          <RefreshChip
            onClick={() => {
              onChangeFilterData("init", "");
            }}
          />
        </FelxRow>
      </FelxRow>
      <FilterContainer className={isShowFilter ? "show__filter" : ""}>
        <Divider style={{ marginBottom: 20 }} />
        {openFilter === "ISSUE" ? (
          <FilterWrapper>
            {allIssue.map((item, key) => {
              return (
                <CheckedText
                  key={key}
                  isChecked={filterData.issue.includes(item.id)}
                  onClick={() => {
                    onClickIssue(item.id);
                  }}
                >
                  {item.name}
                </CheckedText>
              );
            })}
          </FilterWrapper>
        ) : openFilter === "RISK_STATUS" ? (
          <FilterWrapper>
            {allRiskStatus.map((item, key) => {
              return (
                <CheckedText
                  key={key}
                  isChecked={filterData.riskStatus.includes(item.id)}
                  onClick={() => {
                    onClickRiskStatus(item.id);
                  }}
                >
                  {item.name}
                </CheckedText>
              );
            })}
          </FilterWrapper>
        ) : openFilter === "STRUCTURE" ? (
          <FilterWrapper>
            {allStructure.map((item, key) => {
              return (
                <CheckedText
                  key={key}
                  isChecked={filterData.structure.includes(item.acStructureId)}
                  onClick={() => {
                    onClickStructure(item.acStructureId);
                  }}
                >
                  {item.name}
                </CheckedText>
              );
            })}
          </FilterWrapper>
        ) : null}

        <Divider style={{ marginTop: 20 }} />
      </FilterContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 21px;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 0px;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-contents01);
  overflow: hidden;

  transition: all 0.2s ease;

  &.show__filter {
    height: fit-content;
    min-height: 92px;
    margin-top: 20px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-300);
`;
const FilterWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
  padding: 0 16px;
`;
const FilterDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-200);
`;

const RightContainer = styled.div`
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const FelxRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FelxColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export default FilterView;
