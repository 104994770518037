import React, { createRef, useRef } from "react";
import styled from "styled-components";

import SignatureImage from "../../../../mytask/organisms/SignatureImage";

import { getNewDate } from "../../../../../common/commonUtil";
import { MinutesProps } from "../../../../task/evaluation/desktop/DeskEvaluationTask";
import config from "../../../../../config";

interface Props {
  index: number;
  minutesDocument: MinutesProps;
  logoImage?: string;
  onLoaded: (index: number) => void;
}
export const MinuteDocumentPrint: React.FC<Props> = React.memo((props) => {
  const signImgRef = createRef<HTMLImageElement>();
  const loadedRef = useRef<boolean>(false);

  console.log(props.minutesDocument);

  const getNewStockContents = () => {
    const arr: { title: string; contents: string }[] = [];
    if (props.minutesDocument.fundAccount) {
      arr.push({
        title: "투자재원",
        contents: props.minutesDocument.fundAccount.accountName,
      });
    }
    if (props.minutesDocument.companyName) {
      arr.push({
        title: "업체명",
        contents: props.minutesDocument.companyName,
      });
    }
    if (props.minutesDocument.date) {
      arr.push({ title: "개최 일시", contents: props.minutesDocument.date });
    }
    if (props.minutesDocument.stockType) {
      arr.push({
        title: "주식의 종류",
        contents: props.minutesDocument.stockType,
      });
    }
    if (props.minutesDocument.totalInvestmentAmount) {
      arr.push({
        title: "총 투자금액(동일 라운드)",
        contents: props.minutesDocument.totalInvestmentAmount,
      });
    }

    if (props.minutesDocument.investmentAmount) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.acfundAccountId === 1
            ? "당사 투자 금액" // 본계정인 경우 "당사 투자금액"
            : "당 조합 투자 금액",
        contents: props.minutesDocument.investmentAmount,
      });
    }

    if (props.minutesDocument.issuingPrice) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.newOldStock?.newOldStockId === 1
            ? "신주 발행가"
            : "구주 매입가",
        contents: props.minutesDocument.issuingPrice,
      });
    }

    if (
      props.minutesDocument.fundAccount?.newOldStock?.newOldStockId === 2 &&
      props.minutesDocument.curNewIssuingPrice
    ) {
      arr.push({
        title: "최근 신주 발행가",
        contents: props.minutesDocument.curNewIssuingPrice,
      });
    }

    if (props.minutesDocument.postTotalStockNumber) {
      arr.push({
        title: "총 발행 주식 수(동일 라운드)",
        contents: props.minutesDocument.postTotalStockNumber,
      });
    }
    if (props.minutesDocument.stockNumber) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.acfundAccountId === 1
            ? "당사 배정 주식 수" // 본계정인 경우 "당사 배정 주식 수"
            : "당 조합 배정 주식 수",
        contents: props.minutesDocument.stockNumber,
      });
    }
    if (props.minutesDocument.shareholdingRatio) {
      arr.push({
        title: "투자 후 지분율",
        contents: props.minutesDocument.shareholdingRatio,
      });
    }
    return arr;
  };

  const getOldStockContents = () => {
    const arr: { title: string; contents: string }[] = [];
    if (props.minutesDocument.fundAccount) {
      arr.push({
        title: "투자재원",
        contents: props.minutesDocument.fundAccount.accountName,
      });
    }
    if (props.minutesDocument.companyName) {
      arr.push({
        title: "업체명",
        contents: props.minutesDocument.companyName,
      });
    }

    if (props.minutesDocument.buyCompany) {
      arr.push({
        title: "매입처",
        contents: props.minutesDocument.buyCompany,
      });
    }

    if (props.minutesDocument.date) {
      arr.push({ title: "개최 일시", contents: props.minutesDocument.date });
    }
    if (props.minutesDocument.stockType) {
      arr.push({
        title: "주식의 종류",
        contents: props.minutesDocument.stockType,
      });
    }

    if (props.minutesDocument.totalTradeAmount) {
      arr.push({
        title: "총 거래 금액",
        contents: props.minutesDocument.totalTradeAmount,
      });
    }

    if (props.minutesDocument.investmentAmount) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.acfundAccountId === 1
            ? "당사 거래 금액" // 본계정인 경우 "당사 투자금액"
            : "당 조합 거래 금액",
        contents: props.minutesDocument.investmentAmount,
      });
    }

    if (props.minutesDocument.issuingPrice) {
      arr.push({
        title: "구주 매입가",
        contents: props.minutesDocument.issuingPrice,
      });
    }
    if (props.minutesDocument.curNewIssuingPrice) {
      arr.push({
        title: "최근 신주 발행가",
        contents: props.minutesDocument.curNewIssuingPrice,
      });
    }

    if (props.minutesDocument.discount) {
      arr.push({
        title: "할인율",
        contents: props.minutesDocument.discount,
      });
    }

    if (props.minutesDocument.totalTradeStock) {
      arr.push({
        title: "총 배정 주식 수",
        contents: props.minutesDocument.totalTradeStock,
      });
    }

    if (props.minutesDocument.stockNumber) {
      arr.push({
        title:
          props.minutesDocument.fundAccount?.acfundAccountId === 1
            ? "당사 배정 주식 수" // 본계정인 경우 "당사 배정 주식 수"
            : "당 조합 배정 주식 수",
        contents: props.minutesDocument.stockNumber,
      });
    }

    if (props.minutesDocument.shareholdingRatio) {
      arr.push({
        title: "투자 후 지분율",
        contents: props.minutesDocument.shareholdingRatio,
      });
    }

    if (props.minutesDocument.buyCompanyValue) {
      arr.push({
        title: "구주 매입가 기준 기업 가치",
        contents: props.minutesDocument.buyCompanyValue,
      });
    }

    return arr;
  };

  const getRowItemsForAllDocs = () => {
    if (props.minutesDocument.fundAccount?.newOldStock?.newOldStockId === 1) {
      return getNewStockContents();
    }

    if (props.minutesDocument.fundAccount?.newOldStock?.newOldStockId === 2) {
      return getOldStockContents();
    }

    return [];
  };

  return (
    <Container>
      <HeaderTitle className="font-bold-20" marginTop={40}>
        투자심의위원회 의사록
      </HeaderTitle>
      <SubTitle className="font-medium-14" marginTop={24}>
        {props.minutesDocument.companyName} 투자 건에 대하여 아래와 같이
        투자심의위원회를 개최함.{" "}
      </SubTitle>

      <ContentsTable className="font-regular-14" marginTop={72}>
        {getRowItemsForAllDocs().map((item, index) => {
          return (
            <RowItem key={index}>
              <span className="row__item__title">{`${index + 1}. ${
                item.title
              }`}</span>
              <span className="row__item__contents">{item.contents}</span>
            </RowItem>
          );
        })}
      </ContentsTable>

      <RowItem className="font-regular-14" style={{ marginTop: 40 }}>
        <span className="row__item__title">
          {getRowItemsForAllDocs().length + 1}. 심의위원 의견
        </span>
      </RowItem>
      <Table>
        <Column style={{ width: 100, minWidth: 100, maxWidth: 100 }}>
          <div className="table_header">이름</div>
          <div className="table_data">{props.minutesDocument.acUserName}</div>
        </Column>
        <Column style={{ width: 120, minWidth: 120, maxWidth: 120 }}>
          <div className="table_header">가:O 부:X</div>
          <div className="table_data">
            {props.minutesDocument.isAgreement ? "가:O" : "부:X"}
          </div>
        </Column>
        <Column style={{ flexGrow: 1 }}>
          <div className="table_header">의견</div>
          <div
            className="table_data"
            style={{ alignItems: "flex-start", padding: 10 }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <pre
                className="body-14-rg"
                style={{
                  height: "fit-contents",
                  whiteSpace: "break-spaces",
                  wordBreak: "break-all",
                }}
              >
                {props.minutesDocument.comment}
              </pre>
            </div>
          </div>
        </Column>
        <Column style={{ width: 115, minWidth: 115, maxWidth: 115 }}>
          <div className="table_header">서명</div>
          <div className="table_data">
            <Signature>
              {props.minutesDocument.signature?.type === 0 &&
                props.minutesDocument.signature?.url && (
                  <img
                    ref={signImgRef}
                    className="sign-img"
                    src={
                      /^data:image/.test(props.minutesDocument.signature.url)
                        ? props.minutesDocument.signature.url || ""
                        : props.minutesDocument.signature.url +
                          "?" +
                          getNewDate().getTime()
                    }
                    onLoad={() => {
                      if (loadedRef.current) return;

                      if (signImgRef.current) {
                        if (
                          signImgRef.current.clientWidth >
                          signImgRef.current.clientHeight
                        ) {
                          signImgRef.current.style.width = `${115 * 0.9}px`;
                          signImgRef.current.style.height = "auto";
                          signImgRef.current.style.opacity = "1";
                        } else {
                          signImgRef.current.style.width = "auto";
                          signImgRef.current.style.height = `${115 * 0.9}px`;
                          signImgRef.current.style.opacity = "1";
                        }
                      }
                      loadedRef.current = true;
                      props.onLoaded(props.index);
                    }}
                    crossOrigin="anonymous"
                  />
                )}
              {props.minutesDocument.signature?.type === 1 &&
                props.minutesDocument.signature?.path && (
                  <SignatureImage
                    signatureData={props.minutesDocument.signature?.path}
                    onLoaded={(index) => {
                      props.onLoaded(index);
                    }}
                    index={1}
                  />
                )}
            </Signature>
          </div>
        </Column>
      </Table>

      <FooterTitle
        className="font-medium-14"
        marginTop={100}
        style={{ fontSize: 20 }}
      >
        {props.minutesDocument.fundAccount?.acfundAccountId === 1
          ? `${config.companyName}`
          : `${props.minutesDocument.fundAccount?.accountName}`}
      </FooterTitle>
      {props.logoImage && (
        <img
          className="logo"
          src={
            /^data:image/.test(props.logoImage)
              ? props.logoImage || ""
              : props.logoImage
          }
          crossOrigin="anonymous"
          alt="Logo"
        />
      )}
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  width: 794px;
  min-width: 794px;
  max-width: 794px;
  height: 1123px;
  min-height: 1123px;
  max-height: 1123px;
  display: flex;
  flex-direction: column;

  /* transform: scale(0.8); */

  background-color: #ffffff;
  padding: 80px;

  .logo {
    position: absolute;
    right: 60px;
    bottom: 60px;
  }
`;
const HeaderTitle = styled.div<{ marginTop?: number }>`
  width: 100%;
  text-align: center;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : "0px")};
  font-size: 24px;
`;
const SubTitle = styled.div<{ marginTop?: number }>`
  width: 100%;
  text-align: center;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : "0px")};
`;

const FooterTitle = styled.div<{ marginTop?: number }>`
  width: 100%;
  text-align: center;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : "0px")};
  font-size: 20;
  letter-spacing: 0.2px;
`;

const ContentsTable = styled.div<{ marginTop?: number }>`
  width: 100%;
  height: auto;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : "0px")};

  display: flex;
  flex-direction: column;

  gap: 6px;
`;
const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  line-height: 24px;
  letter-spacing: 0.1px;
  gap: 20px;
  .row__item__title {
    color: #828282;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
  }

  .row__item__contents {
    color: #000000;
  }
`;

const Table = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;

  border-top: 1px solid #3d3d3d;
  border-bottom: 1px solid #f0f0f0;
  margin-top: 8px;
  gap: 1px;
  background-color: #f0f0f0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  .table_header {
    width: 100%;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #fbfbfb;
    color: #828282;
  }
  .table_data {
    width: 100%;
    height: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }
`;

const Signature = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .signature__option {
    display: none;
    .signature__option__icon {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
      cursor: pointer;

      :hover {
        path {
          fill: var(--primary);
        }
      }
    }
  }

  :hover {
    .signature__option {
      display: flex;
    }
  }

  .sign-img {
    width: 84px;
    height: 60px;
  }
`;
