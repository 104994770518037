import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";

interface Props {
  pitchingStatus: 1 | 2 | 3 | 4;
  cardTitle: string;
  inviteUser?: string;
  date?: string;
  onClickCard?: () => void;
}
export const PrePitchingCard: React.FC<Props> = (props) => {
  const {
    pitchingStatus,
    cardTitle,
    inviteUser = "",
    date = "",
    onClickCard,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const getLabel = () => {
    switch (pitchingStatus) {
      case 1:
        return (
          <CustomColorLabel
            bgColor="var(--orange-500)"
            textColor="var(--text-02)"
          >
            심사준비
          </CustomColorLabel>
        );
      case 2:
        return (
          <CustomColorLabel
            bgColor="var(--yellow-500)"
            textColor="var(--text-02)"
          >
            심사대기
          </CustomColorLabel>
        );
      case 3:
        return (
          <CustomColorLabel
            bgColor="var(--green-500)"
            textColor="var(--text-02)"
          >
            심사중
          </CustomColorLabel>
        );
      case 4:
        return (
          <CustomColorLabel
            bgColor="var(--blue-400)"
            textColor="var(--text-02)"
          >
            심사완료
          </CustomColorLabel>
        );
    }
  };
  return (
    <Container ref={containerRef} onClick={onClickCard}>
      {getLabel()}
      <CardTitle className="heading-18-md">{cardTitle}</CardTitle>
      <Divider />

      <FlexColumn>
        <div className="body-14-rg" style={{ color: "var(--text-05)" }}>
          심사 위원
        </div>
        <UserNames className="body-14-md">
          {!inviteUser ? "미정" : inviteUser}
        </UserNames>
      </FlexColumn>

      <FlexColumn>
        <div className="body-14-rg" style={{ color: "var(--text-05)" }}>
          심사 일시
        </div>
        <div className="body-14-md" style={{ color: "var(--text-01)" }}>
          {!date ? "미정" : date}
        </div>
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  height: 257px;
  min-height: 257px;
  max-height: 257px;

  padding: 28px 24px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const CardTitle = styled.div`
  width: 100%;
  margin-top: 8px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const UserNames = styled.div`
  width: 100%;
  height: 44px;
  color: var(--text-01);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: var(--divider-100);
  margin-top: 16px;
  margin-bottom: 16px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
