import React, { Children, useEffect, useState } from "react";
import Modal, { Styles } from "react-modal";

interface ModalProps {
  isOpen: boolean;
  toggle?: () => void;
  isBackgroundTransparent?: boolean;
  isTransparent?: boolean;
  overlayColor?: string;
  borderRadius?: number;
  transformY?: string;
  zIndex?: number;
  isFullScreen?: boolean;
}

const ModalLayout: React.FC<ModalProps> = ({
  children,
  isOpen,
  isBackgroundTransparent = false,
  toggle = () => {},
  isTransparent = false,
  overlayColor,
  borderRadius = 10,
  transformY = "calc(-50%)",
  zIndex = 1000,
  isFullScreen = false,
}) => {
  Modal.setAppElement("#root");
  const [customStyles, updateCustomStyles] = useState<Styles | undefined>(
    undefined
  );

  const scrollHandler = () => {
    // 240 + 1480 + 8
    const maxWidth = 200 + 1480;
    const windowWidth = window.innerWidth;

    const _left =
      windowWidth > maxWidth ? 200 + 740 : (windowWidth - 200) / 2 + 200;

    const _customStyles = {
      content: {
        top: "50%",
        left: isFullScreen ? "50%" : _left,
        right: "auto",
        bottom: "auto",
        backgroundColor: isBackgroundTransparent ? "transparent" : "#ffffff",
        border: isBackgroundTransparent ? "transparent" : "#ffffff",
        transform: `translate(calc(-50%), ${transformY})`,
        padding: "-1px",
        borderRadius: borderRadius,
        filter: "drop-shadow(2px 2px 10px rgba(100, 103, 109, 0.5))",
        maxWidth: windowWidth / 2 === 0 ? windowWidth - 1 : windowWidth,
      },
      overlay: isTransparent
        ? { zIndex: zIndex, backgroundColor: "transparent" }
        : {
            zIndex: zIndex,
            backgroundColor: overlayColor
              ? overlayColor
              : "rgba(0, 0, 0, 0.16)",
          },
    };

    updateCustomStyles({ ..._customStyles });
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener("resize", scrollHandler);
    return () => {
      window.removeEventListener("resize", scrollHandler);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggle}
      contentLabel="Example Modal"
      style={customStyles}
    >
      {children}
    </Modal>
  );
};

export default ModalLayout;
