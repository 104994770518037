import axios from "axios";
import * as api from "../../api";
import {
  ExpertInfoProps,
  InvestmentAssessment,
  IrDocumentProps,
  Participant,
  PortfolioFolders,
  PortfolioInfoType,
  PortfolioUserInfo,
  ReportDocumentProps,
  ResponseProps,
  TIPS,
} from "../../../type/data";

import { createFolderToServer } from "../../../google/docs/GoogleDocsApi";
import {
  DealFlowCountProps,
  DealFlowParticipantProps,
  DealFlowPortfolioProps,
  DealFlowStructureFilterProps,
} from "../../../router/deal-flow/interface/DealFlow.interface";
import {
  DealFlowStatus,
  PortfolioNavEmptyDuplicateProps,
  PortfolioNavigationProps,
} from "../../../router/portfolio/portfolio-detail/interface/PortfolioNavigation.interface";
import {
  ExcavationPortfolioProps,
  ExcavationRegisteParticipant,
} from "../../../router/excavation/interface/Excavation.interface";
import {
  PortfolioInfoProps,
  PortfolioInfoResponse,
} from "../../../router/portfolio/info/interface/PortfolioInfo.interface";
import { PortfolioInfoModifyProps } from "../../../router/portfolio/info-modify/interface/PortfolioInfoModify.interface";
import { getNewDate, getYMD } from "../../../common/commonUtil";
import { ExcavationPortfolioCreateProps } from "../../../router/excavation-create/interface/ExcavationCreate.interface";
import {
  DealFlowPitchingData,
  PrePitchingData,
  SectionType,
} from "../../../router/portfolio/pre-pitching/interface/PrePitching.interface";
import {
  CompanyValueHistoryProps,
  EmployeeHistoryProps,
  PitchingResultType,
  SummaryFinanceInfoProps,
} from "../../../router/portfolio/pre-pitching-detail/interface/PrePitchingDetail.interface";
import { ResponseDealflowEvaluation } from "../../../router/portfolio/evaluation/interface/Evaluation.interface";
import {
  AcceleratingPortfolioProps,
  AcceleratingStructureFilterProps,
} from "../../../router/accelerating/interface/Accelerating.interface";
import { StockChangeListData } from "../../../2.0lattice/router/reporting/reporting-detail/components/StockChangeListConfirmModal";

import { BlueCardContent } from "../../../router/portfolio/bluecard-manage/interface/BluecardManage.interface";
import {
  RMEDContentsProps,
  RMEDDictionary,
  RMEDSaveProps,
  StockTransactionProps,
} from "../../../router/risk-management/evaluation-detail/interface/RMED.interface";
import { RiskManagementListProps } from "../../../router/risk-management/evaluation/interface/RME.interface";

import { RiskManagementMinuteData } from "../../../router/risk-management/minutes/interface/Minutes.interface";

import { FinanceDataTempProps } from "../../../router/finance-data-input/interface/FinanceDataInput.interface";
import {
  RiskManagementTotalEvaluation,
  RiskmanagementRangeProps,
} from "../../../router/risk-management/total-evaluation/interface/RMTE.interface";
import {
  AccountInvestmentInfoProps,
  CurrentInvestmentInfoProps,
  InvestmentInfoProps,
  RealDataProps,
  StockHolder,
} from "../../../router/portfolio/investment-info/interface/InvestmentInfo.interface";
import { ExpertDataProps } from "../../../router/portfolio/request-export-opinion/interface/RequestExpert.interface";
import {
  CapitalRaisingProps,
  StockChangeListProps,
} from "../../../router/portfolio-viewer-stock-change-history/interface/type";

export const createExcavationPortfolio = async (
  data: ExcavationPortfolioCreateProps,
  businessPlanFile?: any
): Promise<boolean> => {
  try {
    const formData = new FormData();
    data.pfExtraInfo &&
      formData.append("pfExtraInfo", JSON.stringify(data.pfExtraInfo));
    data.portfolio &&
      formData.append("portfolio", JSON.stringify(data.portfolio));
    data.pfIndustrialTech &&
      formData.append(
        "pfIndustrialTech",
        JSON.stringify(data.pfIndustrialTech)
      );
    businessPlanFile && formData.append("businessPlanFile", businessPlanFile);

    const result = await axios.post(api.registePortfolio(), formData);
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((reject) => reject(false));
  } catch (error: any) {
    console.log(error);
    return new Promise((reject) => reject(false));
  }
};
export const dropPortfolio = async (
  portfolioId: number,
  comment: string,
  dealFlowId?: number
): Promise<boolean> => {
  const body = {
    dealFlowId: dealFlowId,
    comment: comment,
  };
  const result = await axios.post(api.portfolioDrop(portfolioId), body);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};

export const registePortfolioParticipant = async (
  portfolioId: number,
  data: ExcavationRegisteParticipant
): Promise<boolean> => {
  try {
    const result = await axios.post(
      api.registePortfolioParticipant(portfolioId),
      data
    );
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((reject) => reject(false));
  } catch (error: any) {
    console.log(error);
    return new Promise((reject) => reject(false));
  }
};

export const registePortfolioDealFlow = async (
  portfolioId: number
): Promise<boolean> => {
  try {
    const result = await axios.post(api.registePortfolioDealflow(portfolioId));
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((reject) => reject(false));
  } catch (error: any) {
    console.log(error);
    return new Promise((reject) => reject(false));
  }
};

export const getExcavationPortfolio = async (): Promise<
  ExcavationPortfolioProps[] | undefined
> => {
  try {
    const result = await axios.get(api.getExcavationPortfolioList());
    if (result.status === 200) {
      const data = result.data.portfolioList;

      const portfolioList = data.map((item: any): ExcavationPortfolioProps => {
        return item;
      });
      return new Promise((resolve) => resolve(portfolioList));
    }
    return new Promise((reject) => reject(undefined));
  } catch (error: any) {
    console.log(error);
    return new Promise((reject) => reject(undefined));
  }
};

export const getDealFlowPortfolio = async (): Promise<
  | {
      portfolioList: DealFlowPortfolioProps[];
      filterList: DealFlowStructureFilterProps[];
      count: DealFlowCountProps;
    }
  | undefined
> => {
  try {
    const getPhasePriority = (pahse: string) => {
      switch (pahse) {
        case "review":
          return 1;
        case "pitching":
          return 2;
        case "investment":
          return 3;
        default:
          return 4;
      }
    };
    const result = await axios.get(api.getDealflowPortfolioList());
    if (result.status === 200) {
      const data = result.data.portfolioList;

      const count: DealFlowCountProps = result.data.count;
      const filterList = result.data.filterList.map(
        (item: any): DealFlowStructureFilterProps => {
          return { acStructureId: item.acStructureId, name: item.name };
        }
      );
      const portfolioList = data.map((item: any): DealFlowPortfolioProps => {
        return {
          portfolioId: item.portfolioId,
          companyName: item.companyName,
          ceoName: item.ceoName,
          description: item.description,
          isTips: item.isTips,
          createdAt: item.createdAt,
          acChannel: item.acChannel.name,
          pfComment: item.pfComment,
          pfPhase: item.pfPhase,
          phase: { type: item.phase, priority: getPhasePriority(item.phase) },
          pfParticipant: item.pfParticipant.map(
            (participant: any): DealFlowParticipantProps => {
              return {
                pfParticipantId: participant.pfParticipantId,
                isCharger: participant.screenRole.screenRoleId === 1,
                acUser: {
                  acUserId: participant.acUser.acUserId,
                  name: participant.acUser.name,
                  profileImgUrl: participant.acUser.profileImgUrl,
                  headquarter: participant.acUser.headquarter
                    ? {
                        acStructureId:
                          participant.acUser.headquarter.acStructureId,
                      }
                    : undefined,
                  team: {
                    acStructureId: participant.acUser.team.acStructureId,
                  },
                },
              };
            }
          ),
        };
      });
      return new Promise((resolve) =>
        resolve({
          portfolioList: portfolioList,
          filterList: filterList,
          count: count,
        })
      );
    }
    return new Promise((reject) => reject(undefined));
  } catch (error: any) {
    console.log(error);
    return new Promise((reject) => reject(undefined));
  }
};

export const getAcceleratingPortfolio = async (): Promise<
  | {
      portfolioList: AcceleratingPortfolioProps[];
      filterList: AcceleratingStructureFilterProps[];
    }
  | undefined
> => {
  try {
    const result = await axios.get(api.getAcceleratingPortfolioList());
    if (result.status === 200) {
      const data = result.data.portfolioList;

      const filterList = result.data.filterList.map(
        (item: any): AcceleratingStructureFilterProps => {
          return { acStructureId: item.acStructureId, name: item.name };
        }
      );
      const portfolioList = data.map(
        (item: any): AcceleratingPortfolioProps => {
          return {
            companyName: item.companyName,
            ceoName: item.ceoName,
            createdAt: item.createdAt,
            description: item.description,
            firstContractDate: item.firstContractDate,
            firstPaymentDate: item.firstPaymentDate,
            isTips: item.isTips,
            investmentAccount: item.investmentAccount,
            investInfo: item.investInfo,
            portfolioId: item.portfolioId,
            pfComment: item.pfComment,
            riskStatus: item.riskStatus,
            pfParticipant: item.pfParticipant.map(
              (participant: any): DealFlowParticipantProps => {
                return {
                  pfParticipantId: participant.pfParticipantId,
                  isCharger: participant.screenRole.screenRoleId === 1,
                  acUser: {
                    acUserId: participant.acUser.acUserId,
                    name: participant.acUser.name,
                    profileImgUrl: participant.acUser.profileImgUrl,
                    headquarter: participant.acUser.headquarter
                      ? {
                          acStructureId:
                            participant.acUser.headquarter.acStructureId,
                        }
                      : undefined,
                    team: {
                      acStructureId: participant.acUser.team.acStructureId,
                    },
                  },
                };
              }
            ),
          };
        }
      );
      return new Promise((resolve) =>
        resolve({
          portfolioList: portfolioList,
          filterList: filterList,
        })
      );
    }
    return new Promise((reject) => reject(undefined));
  } catch (error: any) {
    console.log(error);
    return new Promise((reject) => reject(undefined));
  }
};

//TODO 재혁님 api 완성되면 변경해야함
export const getPortfolioNavigationInfo = async (
  portfolioId: number
): Promise<PortfolioNavigationProps | undefined> => {
  try {
    const result = await axios.get(api.getPortfolioForNavigation(portfolioId));
    if (result.status === 200) {
      const portfolioData = result.data.portfolio;
      const newData: PortfolioNavigationProps = {
        portfolioId: portfolioData.portfolioId,
        companyName: portfolioData.companyName,
        companyImgUrl: portfolioData.companyImgUrl,
        companyImgKey: portfolioData.companyImgKey,
        pfIndustrialTech: portfolioData.pfIndustrialTech,
        pfPhase: portfolioData.pfPhase,
        phase: portfolioData.phase,
        mainParticipant: portfolioData.mainParticipant,
        subParticipant: portfolioData.subParticipant,
        dealFlowId: portfolioData.dealFlowId,
        riskStatus: portfolioData.riskStatus,
      };
      return new Promise((resolve) => resolve(newData));
    }
    return new Promise((reject) => reject(undefined));
  } catch (error: any) {
    return new Promise((reject) => reject(undefined));
  }
};

export const createPortfolioFolder = async (
  id: number,
  folders?: PortfolioFolders
): Promise<PortfolioFolders | undefined> => {
  try {
    const _portfolioFolder: PortfolioFolders = {
      folderId: folders?.folderId || "",
      noteFolderId: folders?.noteFolderId || "",
      reportFolderId: folders?.reportFolderId || "",
      contractFolderId: folders?.contractFolderId || "",
    };

    console.log("1");

    if (_portfolioFolder.folderId.length === 0) {
      const result = await createFolderToServer(`${id}`);
      if (result) _portfolioFolder.folderId = result.id;
      else return new Promise((resolve) => resolve(undefined));
    }

    console.log("2");

    if (_portfolioFolder.reportFolderId.length === 0) {
      const reportResult = await createFolderToServer(
        "투자심사보고서",
        _portfolioFolder.folderId
      );
      if (reportResult) _portfolioFolder.reportFolderId = reportResult.id;
    }

    console.log("3");

    if (_portfolioFolder.noteFolderId.length === 0) {
      const noteResult = await createFolderToServer(
        "미팅노트",
        _portfolioFolder.folderId
      );
      if (noteResult) _portfolioFolder.noteFolderId = noteResult.id;
    }

    console.log("4");
    if (_portfolioFolder.contractFolderId.length === 0) {
      const contractResult = await createFolderToServer(
        "투자계약서",
        _portfolioFolder.folderId
      );
      if (contractResult) _portfolioFolder.contractFolderId = contractResult.id;
    }

    console.log("5");
    return new Promise((resolve) => resolve(_portfolioFolder));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const registePortfolioFolder = async (
  id: number,
  folders: PortfolioFolders,
  isPut: boolean
): Promise<boolean | undefined> => {
  try {
    const body = {
      portfolio: id,
      folderId: folders.folderId,
      noteFolderId: folders.noteFolderId,
      reportFolderId: folders.reportFolderId,
      contractFolderId: folders.contractFolderId,
    };

    let result: any;
    if (isPut) {
      result = await axios.put(api.getPortfolioFolder(id), body);
    } else {
      result = await axios.post(api.getPortfolioFolder(id), body);
    }
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(false));
  } catch (error) {
    return new Promise((resolve) => resolve(undefined));
  }
};

export const getPortfolFolder = async (
  id: number
): Promise<{
  success: boolean;
  data?: PortfolioFolders;
  msg?: string;
  status?: any;
}> => {
  try {
    const result = await axios.get(api.getPortfolioFolder(id));
    console.log(result);

    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve({ success: true, data: result.data.portfolioFolder })
      );
    }
    return new Promise((resolve) => resolve({ success: false }));
  } catch (error: any) {
    return new Promise((reject) =>
      reject({ success: false, msg: `${error}`, status: error.response.status })
    );
  }
};

//포트폴리오 기본정보
export const getPortfolioDetailInfo = async (
  id: number
): Promise<PortfolioInfoProps | undefined> => {
  try {
    const result = await axios.get(api.getPortfolioDetail(id));
    if (result.status === 200) {
      return new Promise((resolve) => {
        const data: PortfolioInfoResponse = {
          ...result.data.portfolio,
          ceoTel: result.data.portfolio.ceoTell,
        };
        const mainParticipant = data.pfParticipant.find(
          (user) => user.screenRole.screenRoleId === 1
        );
        const subParticipant = data.pfParticipant.filter(
          (user) => user.screenRole.screenRoleId !== 1
        );
        const newData: PortfolioInfoProps = {
          startupInfo: {
            companyName: data.companyName,
            ceoName: data.ceoName,
            ceoTel: data.ceoTel || "",
            ceoEmail: data.ceoEmail || "",
            companyAddress:
              data.addr1 && data.addr2
                ? {
                    addr1: data.addr1,
                    addr2: data.addr2,
                    addr3: data.addr3,
                    addrDetail: data.addrDetail,
                    fullAddr: `${data.addr1} ${data.addr2}${
                      data.addr3 ? " " + data.addr3 : ""
                    }${data.addrDetail ? " " + data.addrDetail : ""}`,
                  }
                : undefined,
            businessNumber: data.businessNumber || "",
            establishDate: data.establishmentDay
              ? getYMD(data.establishmentDay)
              : undefined,
          },
          participantInfo: {
            channel: data.acChannel ? data.acChannel.name : "",
            teamName: mainParticipant ? mainParticipant.acUser.team.name : "",
            mainParticipant: mainParticipant,
            mainParticipantToText: mainParticipant
              ? mainParticipant.acUser.name
              : "",
            subParticipant: subParticipant,
            subParticipantToText:
              subParticipant.length > 0
                ? subParticipant.map((user) => user.acUser.name).join(", ")
                : "",
            excavator: data.excavator ? data.excavator.name : "",
            excavationDate: data.excavationDate || "",
            tips: data.isTips || "",
          },
          businessInfo: {
            description: data.description,
            industrialTechToText: data.pfIndustrialTech
              .map((tech) => `#${tech.acIndustrialTech.name}`)
              .join(", "),
            industrialTech: data.pfIndustrialTech,
            businessPlanFile: data.businessPlanFile,
            summaryProposal: data.pfIrProposal.find((item) => item.type === 1),
            detailProposal: data.pfIrProposal.find((item) => item.type === 2),
            companyImgKey: data.companyImgKey,
            companyImgUrl: data.companyImgUrl,
            thumbnailImgKey: data.thumbnailImgKey,
            thumbnailImgUrl: data.thumbnailImgUrl,
            problem: data.problem || "",
            solution: data.solution || "",
            extrainfoId: data.pfExtraInfo ? data.pfExtraInfo.id : undefined,
            companyContactEmail: data.companyContactEmail
              ? data.companyContactEmail
              : undefined,
            companyContactName: data.companyContactName
              ? data.companyContactName
              : undefined,
            companyContactNumber: data.companyContactNumber
              ? data.companyContactNumber
              : undefined,
            businessModel:
              data.pfExtraInfo && data.pfExtraInfo.content
                ? data.pfExtraInfo.content.find(
                    (item) => item.key === "businessModel"
                  )?.contents
                : "",
            team:
              data.pfExtraInfo && data.pfExtraInfo.content
                ? data.pfExtraInfo.content.find((item) => item.key === "team")
                    ?.contents
                : "",
            other:
              data.pfExtraInfo && data.pfExtraInfo.content
                ? data.pfExtraInfo.content.find((item) => item.key === "other")
                    ?.contents
                : "",
            comment: data.pfComment,
          },
        };

        return resolve(newData);
      });
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    console.log(error);
    return new Promise((resolve) => resolve(undefined));
  }
};

export const getPortfolioInfo = async (
  id: number
): Promise<PortfolioInfoType | undefined> => {
  try {
    const result = await axios.get(api.getPortfolio(id));
    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve({
          ...result.data.portfolio,
          ceoTel: result.data.portfolio.ceoTell,
        })
      );
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((resolve) => resolve(undefined));
  }
};

export const saveChangedPortfolioInfo = async (
  portfolioId: number,
  data: PortfolioInfoModifyProps
): Promise<boolean> => {
  try {
    const result = await axios.patch(
      api.saveChangedPortfolioInfo(portfolioId),
      data
    );
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(false));
  } catch (error) {
    return new Promise((resolve) => resolve(false));
  }
};

export const getPortfolioParticipant = async (
  id: number
): Promise<Participant[] | undefined> => {
  try {
    const result = await axios.get(api.getPortfolioParticipant(id));
    if (result.status === 200) {
      return new Promise((resolve) => resolve(result.data.pfParticipant));
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((resolve) => resolve(undefined));
  }
};

export const getPortfolioInvestmentAssessment = async (
  portfolioId: number,
  investmentAssessmentId: number
): Promise<InvestmentAssessment | undefined> => {
  try {
    const result = await axios.get(
      api.getInvestmentAssessmentDocument(portfolioId, investmentAssessmentId)
    );
    console.log(result);
    if (result.status === 200) {
      return new Promise((resolve) => resolve(result.data));
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((resolve) => resolve(undefined));
  }
};

export const getPfUser = async (
  portfolioId: number
): Promise<PortfolioUserInfo | undefined> => {
  try {
    const result = await axios.get(api.getPfUser(portfolioId));
    console.log(result);

    if (result.data.success) {
      return result.data.pfUser;
    }

    return undefined;
  } catch (e) {
    console.log(e);
  }
};

export const getSuggestBluecard = async (
  portfolioId: number | undefined
): Promise<
  | {
      itemSurmmary: string;
      companyDescription: string;
      investmentHighlight: string;
      problem: string;
      solutionBusinessModel: string;
      technology: string;
      productStatus: string;
      createdAt: string;
    }
  | undefined
> => {
  try {
    if (!portfolioId) return new Promise((reject) => reject(undefined));

    const result = await axios.get(api.getSuggestBlueCard(portfolioId));
    if (result.status === 200) {
      console.log(result);
      const bluecardSuggest = result.data.bluecardSuggest;
      const resultData = {
        itemSurmmary: bluecardSuggest.summary || "",
        companyDescription: bluecardSuggest.description || "",
        investmentHighlight: bluecardSuggest.investmentHighlight
          ? bluecardSuggest.investmentHighlight[0].value
          : "",
        problem: bluecardSuggest.problem
          ? bluecardSuggest.problem[0].value
          : "",
        solutionBusinessModel: bluecardSuggest.solutionBusinessModel
          ? bluecardSuggest.solutionBusinessModel[0].value
          : "",
        technology: bluecardSuggest.technology
          ? bluecardSuggest.technology[0].value
          : "",
        productStatus: bluecardSuggest.productStatus
          ? bluecardSuggest.productStatus[0].value
          : "",
        createdAt: bluecardSuggest.createdAt,
      };

      return new Promise((resolve) => resolve(resultData));
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (e) {
    return new Promise((reject) => reject(undefined));
  }
};

export const duplicateInfoCehck = async (value: {
  portfolioId?: number;
  companyName?: string;
  ceoName?: string;
  ceoEmail?: string;
  ceoPhone?: string;
  businessNumber?: string;
}): Promise<string[] | undefined> => {
  try {
    const result = await axios.post(api.portfolioInfoDuplicateCheck(), value);
    if (result.status === 200) {
      return new Promise((resolve) => resolve(result.data.duplicate));
    }
    return new Promise((reject) => reject(undefined));
  } catch (e) {
    console.log(e);
  }
};

export const registeIRProposalDocument = async (
  portfolioId: number,
  file: File,
  type: 1 | 2
): Promise<boolean> => {
  const formData = new FormData();
  formData.append("pfIrProposal", file);
  formData.append("type", `${type}`);

  const result = await axios.post(
    api.registeIrProposalDocument(portfolioId),
    formData
  );

  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};

//투자심사 등록
export const registeEvaluation = async (
  dealflowId: number,
  document: InvestmentAssessment
): Promise<{ success: boolean; investmentAssessmentId?: number }> => {
  try {
    const body = {
      dealFlow: { id: dealflowId },
      ...document,
    };
    const result = await axios.post(api.registeInvestmentAssessment(), body);
    if (result.status === 200) {
      return new Promise((resolve) => resolve(result.data));
    }
    return new Promise((resolve) => resolve({ success: false }));
  } catch (error) {
    return new Promise((resolve) => resolve({ success: false }));
  }
};

export const modifyEvaluation = async (
  document: InvestmentAssessment
): Promise<boolean> => {
  const result = await axios.put(api.updateInvestmentAssessment(), document);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};

//투자심사 확정
export const confirmAssessment = async (
  investmentAssessmentId: number,
  data: InvestmentAssessment
): Promise<boolean | undefined> => {
  try {
    const result = await axios.post(
      api.confirmEvaluation(investmentAssessmentId),
      data
    );
    return new Promise((resolve) => resolve(result.data.success));
  } catch (e) {
    return new Promise((reject) => reject(undefined));
  }
};

//투자심사 확정 취소
export const cancelAssessment = async (
  portfolioId: number | undefined,
  investmentAssessmentId: number | string | undefined
): Promise<boolean | undefined> => {
  try {
    const result = await axios.post(api.postCancelAssessment(), {
      portfolioId: `${portfolioId}`,
      investmentAssessmentId: `${investmentAssessmentId}`,
    });
    return new Promise((resolve) => resolve(result.data.success));
  } catch (e) {
    return new Promise((reject) => reject(undefined));
  }
};

//투자심사 문서 등록
export const registePortfolioDocument = async (
  portfolio: number,
  data: {
    documentType: string;
    acFundAccount: number;
    investmentAssessment?: number | string;
    file: number;
  }[]
): Promise<boolean | undefined> => {
  try {
    const body = data.map((item) => ({ ...item, portfolio: portfolio }));
    const result = await axios.post(
      api.registeInvestmentAssessmentDocument(portfolio),
      body
    );
    return new Promise((resolve) => resolve(result.data.success));
  } catch (e) {
    return new Promise((reject) => reject(undefined));
  }
};

export const getSpecialUserList = async (): Promise<
  { acUserId: number; email: string; name: string }[] | undefined
> => {
  const downloadOriginalRes = await axios.get(api.getDownloadOriginal());
  if (downloadOriginalRes.data.success) {
    return new Promise((resolve) => resolve(downloadOriginalRes.data.acUser));
  }
  return new Promise((reject) => reject(undefined));
};

//투자 심사 보고서 기업정보 요청 코드 검증
export const checkCompanyInfoCode = async (
  investmentAssessmentId: number,
  code: string
): Promise<{ success: boolean; msg?: string }> => {
  const result = await axios.post(api.checkPortfolioInfoAuthCode(), {
    investmentAssessmentId,
    code,
  });
  if (result.status === 200) {
    return new Promise((resolve) => resolve({ success: true }));
  }
  if (result.status === 400) {
    return new Promise((reject) =>
      reject({
        success: false,
        msg: result.data.message,
      })
    );
  }
  return new Promise((reject) =>
    reject({ success: false, msg: "알수 없는 오류" })
  );
};

//포트폴리오 개인정보 처리 방침 약관 동의 확인
export const checkTerms = async (
  portfolioId: number
): Promise<{ success: boolean; msg?: string }> => {
  const result = await axios.get(api.getPortfolioTerms(portfolioId));
  if (result.status === 200) {
    return new Promise((resolve) =>
      resolve({
        success: result.data.terms.privacy && result.data.terms.personal,
      })
    );
  }
  return new Promise((reject) => reject({ success: false }));
};

//포트폴리오 개인정보 처리 방침 약관 동의
export const agreeTermsByPortfolioId = async (body: {
  portfolioId: number;
  termsAndConditionsIds: number[];
}): Promise<{ success: boolean; msg?: string }> => {
  const result = await axios.post(api.agreeTerms(), body);
  if (result.status === 200) {
    return new Promise((resolve) =>
      resolve({
        success: result.data.success,
      })
    );
  }
  return new Promise((reject) => reject({ success: false }));
};

//투자심사 보고서 기업정보 입력 요청 메일 보내기
export const sendRequireCompanyInfoEmail = async (body: {
  portfolioId: number;
  investmentAssessmentId: number | string;
  exportLink: string;
}): Promise<{ success: boolean; createdAt: string } | undefined> => {
  const result = await axios.post(api.sendRequireCompanyInfoEmail(), body);
  if (result.status === 200) {
    return new Promise((resolve) =>
      resolve({
        success: result.data.success,
        createdAt: result.data.createdAt,
      })
    );
  }
  return new Promise((reject) => reject(undefined));
};

//포트폴리오 코멘트 달기
export const sendPortfolioComment = async (
  portfolioId: number,
  contents: string
): Promise<boolean> => {
  const result = await axios.post(api.postPortfolioComment(portfolioId), {
    content: contents,
  });
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};

//포트폴리오 코멘트 업데이트
export const modifyPortfolioComment = async (
  pfCommentId: number,
  contents: string
): Promise<boolean> => {
  const result = await axios.patch(api.patchPortfolioComment(pfCommentId), {
    content: contents,
  });
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};

//포트폴리오 코멘트 업데이트
export const deletePortfolioComment = async (
  pfCommentId: number
): Promise<boolean> => {
  const result = await axios.delete(api.deletePortfolioComment(pfCommentId));
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};

//포트폴리오 피칭 리스트 가져오기
export const getPrepitchingList = async (
  portfolioId: number
): Promise<DealFlowPitchingData[] | undefined> => {
  const result = await axios.get(api.getPitchingList(portfolioId));
  if (result.status === 200) {
    console.log(result);
    const resultData = result.data.dealFlowList.map(
      (item: any): DealFlowPitchingData => {
        return {
          ...item,
          pfPreEvaluation: item.pfPreEvaluation.map(
            (data: any): PrePitchingData => {
              return {
                ...data,
                preEvaluationId: data.pfPreEvaluationId,
                // preEvaluationData: data.templateJson.preEvaluationData,
              };
            }
          ),
        };
      }
    );
    return new Promise((resolve) => resolve(resultData));
  }
  return new Promise((reject) => reject(undefined));
};

//피칭 하나 조회
export const getPrepitchingOne = async (
  pfPreEvaluationId: number
): Promise<PrePitchingData | undefined> => {
  const result = await axios.get(api.getPitchingById(pfPreEvaluationId));
  if (result.status === 200) {
    console.log(result);
    return new Promise((resolve) =>
      resolve({
        ...result.data.preEvaluation,
        preEvaluationId: result.data.preEvaluation.pfPreEvaluationId,
        preEvaluationData:
          result.data.preEvaluation.templateJson.preEvaluationData,
        preEvaluationInviteUser:
          result.data.preEvaluation.templateJson.preEvaluationInviteUser,
      })
    );
  }
  return new Promise((reject) => reject(undefined));
};

//피칭 템플릿 받아오기
export const getPrePitchingTemplete = async (
  portfolioId: number
): Promise<SectionType[] | undefined> => {
  const result = await axios.get(api.getPitchingTemplete(portfolioId));
  if (result.status === 200) {
    console.log(result);
    return new Promise((resolve) =>
      resolve(result.data.data.preEvaluationData)
    );
  }
  return new Promise((reject) => reject(undefined));
};

//피칭 결과 받아오기
export const getPrePitchingResult = async (
  pfEvaluationId: number
): Promise<PitchingResultType[] | undefined> => {
  const result = await axios.get(api.getPitchingResult(pfEvaluationId));
  if (result.status === 200) {
    console.log(result);
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

//새로운 피칭 생성
export const createPrePitching = async (
  portfolioId: number,
  bodyData: { dealFlowId: number; preEvaluationData: SectionType[] }
): Promise<{ success: boolean; pfPreEvaluationId?: number }> => {
  const result = await axios.post(api.createPitching(portfolioId), bodyData);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject({ success: false }));
};

//피칭내용 수정 생성
export const modifyPrePitching = async (
  portfolioId: number,
  pitchingData: PrePitchingData
): Promise<boolean> => {
  console.log(pitchingData);
  const data = {
    ...pitchingData,
    portfolioId: portfolioId,
    preEvaluationResult: pitchingData.preEvaluationInviteUser?.map((item) => ({
      acUserId: item.id,
    })),
    templateJson: undefined,
  };
  const result = await axios.put(api.moidfyPitching(), data);
  if (result.status === 200) {
    console.log(result);
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};

//피칭내용 수정 생성
export const publishPrePitching = async (
  pfEvaluationId: number
): Promise<boolean> => {
  const result = await axios.patch(api.publishPitchingById(pfEvaluationId));
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};
//피칭내용 수정 생성
export const cancelPrePitching = async (
  pfEvaluationId: number
): Promise<boolean> => {
  const result = await axios.patch(api.cancelPitchingById(pfEvaluationId));
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};

//피칭내용 수정 생성
export const finishPrePitching = async (
  pfEvaluationId: number
): Promise<boolean> => {
  const result = await axios.patch(api.finishPitchingById(pfEvaluationId));
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(false));
};

//포트폴리오 투심 리스트 가져오기
export const getEvaluationList = async (
  portfolioId: number
): Promise<ResponseDealflowEvaluation[] | undefined> => {
  const result = await axios.get(api.getEvaluationListByDealFlow(portfolioId));
  if (result.status === 200) {
    console.log(result);
    const dealflowList: ResponseDealflowEvaluation[] = [
      ...result.data.dealFlowList,
    ];

    return new Promise((resolve) => resolve(dealflowList));
  }
  return new Promise((reject) => reject(undefined));
};

//포트폴리오 투심 하나 조회
export const getPortfolioEvaluation = async (
  investmentAssessmentId: number
): Promise<InvestmentAssessment | undefined> => {
  try {
    const result = await axios.get(
      api.getEvaluationByAssessmentId(investmentAssessmentId)
    );
    console.log(result);
    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve(result.data.investmentAssessment)
      );
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((resolve) => resolve(undefined));
  }
};

//포트폴리오 투심 개최
export const publishEvaluation = async (
  portfolioId: number,
  investmentAssessmentId: number,
  updatedAt?: string
): Promise<boolean> => {
  const body = {
    portfolioId: portfolioId,
    updatedAt: updatedAt,
  };
  const result = await axios.patch(
    api.publishEvaluation(investmentAssessmentId),
    body
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.success));
  }
  return new Promise((reject) => reject(false));
};

//포트폴리오 투심 개최 취소
export const cancelPublishEvaluation = async (
  portfolioId: number,
  investmentAssessmentId: number
): Promise<boolean> => {
  const body = {
    portfolioId: portfolioId,
  };
  const result = await axios.patch(
    api.cancelPublishEvaluation(investmentAssessmentId),
    body
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.success));
  }
  return new Promise((reject) => reject(false));
};

//포트폴리오 계약 요청
export const requestContract = async (
  portfolioId: number,
  dealFlowId: number,
  investmentAssessmentId: number
): Promise<boolean> => {
  const body = {
    portfolioId: portfolioId,
    dealFlowId: dealFlowId,
    investmentAssessmentId: investmentAssessmentId,
  };
  const result = await axios.patch(api.requestPortfolioContract(), body);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.success));
  }
  return new Promise((reject) => reject(false));
};

//포트폴리오 계약 요청
export const cancelRequestContract = async (
  portfolioId: number,
  dealflowId: number
): Promise<boolean> => {
  const body = {
    portfolioId: portfolioId,
  };
  const result = await axios.patch(
    api.cancelRequestPortfolioContract(dealflowId),
    body
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.success));
  }
  return new Promise((reject) => reject(false));
};

//포트폴리오 계약 요청
export const emptyDuplicateCheck = async (
  portfolioId: number
): Promise<PortfolioNavEmptyDuplicateProps | undefined> => {
  const result = await axios.get(
    api.portfolioDataEmptyAndDuplicate(portfolioId)
  );
  if (result.status === 200) {
    return new Promise((resolve) =>
      resolve({ duplicate: result.data.duplicate, empty: result.data.empty })
    );
  }
  return new Promise((reject) => reject(undefined));
};

//주식변동내역 확정 정보 요청
export const getStockChangeListInfo = async (
  reportDocumentId: number
): Promise<StockChangeListData | undefined> => {
  const result = await axios.get(
    api.getPortfolioStockChangeListInfo(reportDocumentId)
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }
  return new Promise((reject) => reject(undefined));
};

//딜플로우 상태 확인
export const getDealflowStatusById = async (
  id: number,
  type: "pitching" | "investmentAssessment"
): Promise<DealFlowStatus | undefined> => {
  const result = await axios.get(api.getDealflowStatus(id, type));
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.status));
  }
  return new Promise((reject) => reject(undefined));
};

export const getSummaryFinanceInfo = async (
  portfolioId: number
): Promise<SummaryFinanceInfoProps[] | undefined> => {
  const result = await axios.get(
    api.getSummaryFinanceChangeHistory(portfolioId)
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.pfFinancialStatement));
  }
  return new Promise((reject) => reject(undefined));
};

//인력변동내역 조회
export const getEmployeeChangeHistory = async (
  portfolioId: number
): Promise<EmployeeHistoryProps[] | undefined> => {
  const result = await axios.get(api.getEmployeeChangeHistory(portfolioId));
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.pfEmployeeHistory));
  }
  return new Promise((reject) => reject(undefined));
};

//기업가치 변동 내역
export const getCompanyValueChangeHistory = async (
  portfolioId: number
): Promise<CompanyValueHistoryProps[] | undefined> => {
  const result = await axios.get(api.getCompanyChangeHistory(portfolioId));
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.pfValueHistory));
  }
  return new Promise((reject) => reject(undefined));
};

//요약사업계획서 최근 확정 내역
export const getSBPLatest = async (
  portfolioId: number
): Promise<any | undefined> => {
  const result = await axios.get(api.getSummaryBusinessPlanLatest(portfolioId));
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.pfReportDocumentData));
  }
  return new Promise((reject) => reject(undefined));
};

//리스크관리 종료
export const closeRiskmanagement = async (
  year: number,
  quarterNum: number
): Promise<boolean | undefined> => {
  const result = await axios.patch(api.closeRiskmanagement(), {
    year,
    quarterNum,
  });
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.success));
  }
  return new Promise((reject) => reject(undefined));
};

//리스크관리 부분열기
export const partiallyOpenRiskmanagement = async (
  year: number,
  quarterNum: number,
  riskManagementIds: number[]
): Promise<boolean | undefined> => {
  const result = await axios.patch(api.partiallyRiskmanagement(), {
    year,
    quarterNum,
    riskManagementIds,
  });
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.success));
  }
  return new Promise((reject) => reject(undefined));
};

//리스크관리 기본 조회
export const getRMBasic = async (): Promise<RMEDDictionary | undefined> => {
  const result = await axios.get(api.getRiskManagementBasic());
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) =>
      resolve({ issue: data.issue, opinion: data.opinion, status: data.status })
    );
  }
  return new Promise((reject) => reject(undefined));
};

//리스크관리 목록 조회
export const getRiskManagementList = async (): Promise<
  | {
      date: {
        year: number;
        quarterNum: number;
      };
      riskManagement: RiskManagementListProps[];
    }
  | undefined
> => {
  const result = await axios.get(api.getRiskManagementList());
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) =>
      resolve({ date: data.date, riskManagement: data.riskManagement })
    );
  }
  return new Promise((reject) => reject(undefined));
};

//리스크관리 단일 조회
export const getRMData = async (
  riskManagementId: number
): Promise<RMEDContentsProps | undefined> => {
  const result = await axios.get(api.getRiskManagementData(riskManagementId));
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.riskManagement));
  }
  return new Promise((reject) => reject(undefined));
};

// 리스크 관리 평가 저장
export const submitRiskManagement = async (
  data: RMEDSaveProps
): Promise<boolean | undefined> => {
  const result = await axios.put(api.putRiskManagementEvaluation(), data);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(undefined));
};

// 요약 사업 계획서 단일 조회
export const getSBPDataByInfo = async (
  portfolioId: number,
  year: number,
  quarterNum: number
): Promise<any | undefined> => {
  const result = await axios.get(api.getSBPDataByInfo(portfolioId), {
    params: {
      year,
      quarterNum,
    },
  });
  if (result.status === 200 && result.data.data !== undefined) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.data.data));
  }
  return new Promise((reject) => reject(undefined));
};

// 재무제표 단일 조회
export const getFinanceDataByInfo = async (
  portfolioId: number,
  year: number,
  quarterNum: number
): Promise<any | undefined> => {
  const result = await axios.get(api.getFinanceDataByInfo(portfolioId), {
    params: {
      year,
      quarterNum,
    },
  });
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.data));
  }
  return new Promise((reject) => reject(undefined));
};

// 재무제표 단일 조회
export const getStockTransactionDataByInfo = async (
  portfolioId: number,
  year: number,
  quarterNum: number
): Promise<
  | { totalStockNumber: number; stockTransaction: StockTransactionProps[] }
  | undefined
> => {
  const result = await axios.get(api.getStockTransactionByInfo(portfolioId), {
    params: {
      year,
      quarterNum,
    },
  });
  if (result.status === 200) {
    const data = result.data;
    if (data.totalStockNumber > 0) {
      return new Promise((resolve) =>
        resolve({
          totalStockNumber: data.totalStockNumber,
          stockTransaction: data.stockTransaction,
        })
      );
    }
  }
  return new Promise((reject) => reject(undefined));
};

// 보고 문서 단일 조회
export const getReportDocumentByInfo = async (
  portfolioId: number,
  year: number,
  quarterNum: number,
  acPfDocumentId: number
): Promise<ReportDocumentProps | undefined> => {
  const result = await axios.get(api.getReportDocumentByInfo(), {
    params: {
      portfolioId,
      acPfDocumentId,
      year,
      quarterNum,
    },
  });
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.data));
  }
  return new Promise((reject) => reject(undefined));
};

// 리스크평가 출력용 데이터 조회
export const getRiskManagementMinute = async (
  year: number,
  quarterNum: number
): Promise<RiskManagementMinuteData | undefined> => {
  const result = await axios.get(api.getRiskManagementMinute(), {
    params: {
      year,
      quarterNum,
    },
  });
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) =>
      resolve({
        portfolio: data.portfolio,
        riskManagementReport: data.riskManagementReport,
      })
    );
  }
};

export const getRiskmanagementRange = async (): Promise<
  RiskmanagementRangeProps | undefined
> => {
  const result = await axios.get(api.getRiskmanagementRange());
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.range));
  }
  return new Promise((reject) => reject(undefined));
};

export const getRiskmanagementList = async (
  year: number,
  quarterNum: number
): Promise<RiskManagementTotalEvaluation[] | undefined> => {
  const result = await axios.get(api.getRiskmanagementTotalEvaluation(), {
    params: {
      year: year,
      quarterNum: quarterNum,
    },
  });
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.riskManagement));
  }
  return new Promise((reject) => reject(undefined));
};

export const getFinanceDataEmptyList = async (): Promise<
  FinanceDataTempProps[] | undefined
> => {
  const result = await axios.get(api.getFinanceDataEmpty());
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.result));
  }
  return new Promise((reject) => reject(undefined));
};

export const registeFinanceData = async (
  data: any
): Promise<boolean | undefined> => {
  const result = await axios.post(api.registeFinanceDataEmpty(), data);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(undefined));
};

//투자 정보 가져오기
export const getInvestmentInfo = async (
  portfolioId: number
): Promise<
  | {
      total: CurrentInvestmentInfoProps;
      accountInfo: AccountInvestmentInfoProps[];
    }
  | undefined
> => {
  const result = await axios.get(api.getPortfolioInvestmentInfo(portfolioId));
  if (result.status === 200) {
    const accountInfo = result.data.accountInfo.map(
      (item: any): AccountInvestmentInfoProps => {
        return {
          ...item,
          shareValue: item.stockType
            .map((data: any) => data.shareValue)
            .reduce((p: number, c: number) => p + c, 0),
          stock: item.stockType
            .map((data: any) => data.stock)
            .reduce((p: number, c: number) => p + c, 0),
          percentage:
            Math.round(
              item.stockType
                .map((data: any) => data.percentage)
                .reduce((p: number, c: number) => p + c, 0) * 100
            ) / 100,
        };
      }
    );
    return new Promise((resolve) =>
      resolve({
        total: result.data.total,
        accountInfo: accountInfo,
      })
    );
  }
  return new Promise((reject) => reject(undefined));
};

//주주명부 정보 가져오기
export const getStockholderList = async (
  portfolioId: number
): Promise<StockHolder[] | undefined> => {
  const result = await axios.get(api.getStockHolderList(portfolioId));

  if (result.status === 200) {
    const list = result.data.pfStockHolderList;
    list.sort((a: any, b: any) => {
      return b.stockNumber - a.stockNumber;
    });
    return new Promise((resolve) => resolve(list));
  }

  return new Promise((reject) => reject(undefined));
};

//투심 전문가 의견 요청
export const requestExpertOpinion = async (
  portfolioId: number,
  investmentAssessmentId: number,
  exportLink: string,
  expertTitle: string,
  expertEmail: string
): Promise<ResponseProps | undefined> => {
  const body = {
    portfolioId,
    investmentAssessmentId,
    exportLink,
    expertTitle,
    expertEmail,
  };
  const result = await axios.post(api.requestExpertOpinion(), body);

  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

//투심 전문가 의견 요청
export const reRequestExpertOpinion = async (
  portfolioId: number,
  investmentAssessmentId: number,
  exportLink: string,
  expertTitle: string,
  expertEmail: string
): Promise<ResponseProps | undefined> => {
  const body = {
    portfolioId,
    investmentAssessmentId,
    exportLink,
    expertTitle,
    expertEmail,
  };
  const result = await axios.post(api.reRequestExpertOpinion(), body);

  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const getInvestmentAssessmentDataForExpert = async (
  portfolioId: number,
  investmentAssessmentId: number
): Promise<ExpertDataProps | undefined> => {
  const result = await axios.get(
    api.getExpertInfoForExpert(portfolioId, investmentAssessmentId)
  );
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const submitExpertOpinion = async (
  portfolioId: number,
  investmentAssessmentId: number,
  expertInfo: ExpertInfoProps
): Promise<ResponseProps | undefined> => {
  const body = {
    portfolio: {
      portfolioId: portfolioId,
    },
    investmentAssessmentId: investmentAssessmentId,
    expertInfo,
  };
  const result = await axios.put(api.submitExpertOpinion(), body);
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data));
  }
  return new Promise((reject) => reject(undefined));
};

export const getStockChangeList = async (
  portfolioId: number
): Promise<StockChangeListProps[] | undefined> => {
  const result = await axios.get(api.portfolioIDStockChange(portfolioId));
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }
  return new Promise((reject) => reject(undefined));
};

export const getCapitalRaisingList = async (
  portfolioId: number
): Promise<CapitalRaisingProps[] | undefined> => {
  const result = await axios.get(api.portfolioIDCapitalraising(portfolioId));
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }
  return new Promise((reject) => reject(undefined));
};

export const getInitialFaceValue = async (
  portfolioId: number
): Promise<number | undefined> => {
  const result = await axios.get(api.portfolioIdFaceValue(portfolioId));
  console.log(result);
  if (result.status === 200 && result.data.result) {
    return new Promise((resolve) => resolve(result.data.result.initialValue));
  }
  return new Promise((reject) => reject(undefined));
};

export const getPortfolioCurrency = async (
  portfolioId: number
): Promise<string | undefined> => {
  const result = await axios.get(api.portfolioIdCurrency(portfolioId));
  console.log(result);
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.result));
  }
  return new Promise((reject) => reject(undefined));
};
