import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  CreateBuyerProps,
  ExitInfoProps,
  ExitProcessProps,
  PortfolioDetailAdminProps,
} from "../../interface/Portfolio.interface";
import {
  AcInvestmentOrganization,
  StakeHolderType,
} from "../../../../../../type/data";
import {
  createBuyer,
  getBuyer,
} from "../../../../../../api/repository/admin/AcceleratorRepository";
import { FundAccountCard } from "../../component/FundAccountCard";
import { AddComma, RemoveHypen } from "../../../../../../common/commonUtil";
import { DefaultButton } from "lattice_core";
import DefaultSearch from "../../../../../../components-2.0v/search/DefaultSearch";
import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import { ReactComponent as Radio } from "../../../../../../assets/image/icon_radio.svg";
import { ReactComponent as Check } from "../../../../../../assets/image/icon_check_new.svg";
import { InputBox } from "../../../../../../components-2.0v/input-box/InputBox";
import { BoxComponent } from "../../../../../../components-2.0v/box/BoxComponent";

interface Props {
  selectedPortfolioDetail: PortfolioDetailAdminProps;
  exitProcessInfo: ExitProcessProps[];
  onChangeData: (newData: ExitProcessProps[]) => void;
}

export const ExitSecond: React.FC<Props> = (props) => {
  const { selectedPortfolioDetail, exitProcessInfo, onChangeData } = props;

  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [allBuyer, setAllBuyer] = useState<AcInvestmentOrganization[]>([]);
  const [isRegisteBuyer, setIsRegisteBuyer] = useState<boolean>(false);

  const [selectedFundAccountId, updateSelectedFundAccountId] = useState<
    number | undefined
  >(undefined);

  const [newBuyer, updateNewBuyer] = useState<CreateBuyerProps>({});

  const selectedFundAccount = () => {
    return exitProcessInfo.find((item) => item.id === selectedFundAccountId);
  };

  const addBuyer = (organizationId: number, name: string) => {
    const targetAccount = exitProcessInfo.find(
      (account: ExitProcessProps) => account.id === selectedFundAccountId
    );

    if (targetAccount) {
      const index = targetAccount.exitInfo.findIndex((data: ExitInfoProps) => {
        return (
          data.acInvestmentOrganization.acInvestmentOrganizationId ===
          organizationId
        );
      });

      if (index !== -1) {
        targetAccount.exitInfo.splice(index, 1);
      } else {
        const newExitInfo: ExitInfoProps = {
          portfolio: {
            portfolioId: selectedPortfolioDetail.portfolioId,
          },
          acFundAccount: targetAccount.acFundAccount,
          stockType: targetAccount.stockType,
          acInvestmentOrganization: {
            acInvestmentOrganizationId: organizationId,
            name: name,
          },
        };
        targetAccount.exitInfo = [...targetAccount.exitInfo, newExitInfo];
      }
    }
    onChangeData([...exitProcessInfo]);
  };

  const closeRegisteBuyer = () => {
    setIsRegisteBuyer(false);
    updateNewBuyer({});
  };

  const getAllBuyer = async () => {
    const result = await getBuyer();
    setAllBuyer(result ? result : []);
  };

  const registeBuyer = async () => {
    const result = await createBuyer(newBuyer);
    if (result && result.success) {
      await getAllBuyer();
      addBuyer(result.id || 0, newBuyer.name || "");
      closeRegisteBuyer();
    }
  };

  const getFilterBuyer = () => {
    return allBuyer.filter((item) => {
      const uniqueNum =
        item.stakeHolder !== undefined
          ? RemoveHypen(item.stakeHolder.uniqueNumber)
          : "";
      return uniqueNum.includes(searchKeyword);
    });
  };

  useEffect(() => {
    getAllBuyer();
  }, []);
  return (
    <Container>
      <FlexColumn
        style={{
          flex: 2.5,
          height: "100%",
          overflow: "scroll",
          borderRight: "1px solid var(--gray-400)",
        }}
      >
        <div className="heading-16-b" style={{ marginLeft: 16, marginTop: 16 }}>
          재원
        </div>
        <FundAccountList>
          {exitProcessInfo.map((item) => {
            return (
              <CardContainer
                className={item.id === selectedFundAccountId ? "selected" : ""}
                onClick={() => {
                  updateSelectedFundAccountId(item.id);
                }}
              >
                <FlexRow>
                  <span className="body-14-sb">
                    {item.acFundAccount.nameKo}
                  </span>
                </FlexRow>
                <FlexRow className="caption-10-rg">
                  <span style={{ width: 80, color: "var(--text-04)" }}>
                    주식 종류
                  </span>
                  <span className="">
                    {item.stockType ? item.stockType.name : "정보없음"}
                  </span>
                </FlexRow>
              </CardContainer>
            );
          })}
        </FundAccountList>
      </FlexColumn>
      <FlexColumn
        style={{
          flex: 3,
          height: "100%",
          overflow: "scroll",
          borderRight: "1px solid var(--gray-400)",
        }}
      >
        {selectedFundAccountId && isRegisteBuyer && (
          <FlexColumn
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="heading-16-b"
              style={{ marginLeft: 16, marginTop: 16 }}
            >
              구매자 등록
            </div>
            <FlexColumn style={{ gap: 6, padding: "8px 16px 0px 16px" }}>
              <RowTitleBox required title="구매자 유형">
                <CheckboxContainer className="body-14-rg">
                  <div
                    className="check__item"
                    onClick={() => {
                      updateNewBuyer((prev) => ({
                        ...prev,
                        stakeType: StakeHolderType.INDIVIDUAL,
                      }));
                    }}
                  >
                    <Radio
                      className={
                        newBuyer.stakeType === StakeHolderType.INDIVIDUAL
                          ? "radiobox__checked"
                          : ""
                      }
                    />
                    <span>개인</span>
                  </div>

                  <div
                    className="check__item"
                    onClick={() => {
                      updateNewBuyer((prev) => ({
                        ...prev,
                        stakeType: StakeHolderType.CORPORATION,
                      }));
                    }}
                  >
                    <Radio
                      className={
                        newBuyer.stakeType === StakeHolderType.CORPORATION
                          ? "radiobox__checked"
                          : ""
                      }
                    />
                    <span>기관</span>
                  </div>

                  <div
                    className="check__item"
                    onClick={() => {
                      updateNewBuyer((prev) => ({
                        ...prev,
                        stakeType: StakeHolderType.FUND,
                      }));
                    }}
                  >
                    <Radio
                      className={
                        newBuyer.stakeType === StakeHolderType.FUND
                          ? "radiobox__checked"
                          : ""
                      }
                    />
                    <span>조합</span>
                  </div>
                </CheckboxContainer>
              </RowTitleBox>

              {newBuyer.stakeType && (
                <RowTitleBox
                  required
                  title={
                    newBuyer.stakeType === StakeHolderType.INDIVIDUAL
                      ? "이름"
                      : newBuyer.stakeType === StakeHolderType.CORPORATION
                      ? "기관명"
                      : "조합명"
                  }
                >
                  <InputBox
                    width={240}
                    placeholder="이름을 입력하세요."
                    onChange={(value) => {
                      updateNewBuyer((prev) => ({
                        ...prev,
                        name: value,
                      }));
                    }}
                  />
                </RowTitleBox>
              )}

              {newBuyer.stakeType && (
                <RowTitleBox
                  required
                  title={
                    newBuyer.stakeType === StakeHolderType.INDIVIDUAL
                      ? "주민등록 번호"
                      : newBuyer.stakeType === StakeHolderType.CORPORATION
                      ? "사업자 번호"
                      : "펀드 고유번호"
                  }
                >
                  <InputBox
                    width={240}
                    textType={
                      newBuyer.stakeType === StakeHolderType.INDIVIDUAL
                        ? "residentRegistrationNumber"
                        : "businessNumber"
                    }
                    placeholder={
                      newBuyer.stakeType === StakeHolderType.INDIVIDUAL
                        ? "xxxxxx-xxxxxxx"
                        : "xxx-xx-xxxxx"
                    }
                    onChange={(value) => {
                      updateNewBuyer((prev) => ({
                        ...prev,
                        uniqueNumber: value,
                      }));
                    }}
                  />
                </RowTitleBox>
              )}

              {newBuyer.stakeType &&
                newBuyer.stakeType !== StakeHolderType.INDIVIDUAL && (
                  <RowTitleBox title={"담당자"}>
                    <InputBox
                      width={240}
                      placeholder="이름을 입력하세요."
                      onChange={(value) => {
                        updateNewBuyer((prev) => ({
                          ...prev,
                          managerName: value,
                        }));
                      }}
                    />
                  </RowTitleBox>
                )}

              {newBuyer.stakeType && (
                <RowTitleBox title={"연락처"}>
                  <InputBox
                    width={240}
                    placeholder="010-XXXX-XXXX"
                    textType="phone"
                    onChange={(value) => {
                      updateNewBuyer((prev) => ({
                        ...prev,
                        managerPhone: value,
                      }));
                    }}
                  />
                </RowTitleBox>
              )}

              {newBuyer.stakeType && (
                <RowTitleBox title={"이메일"}>
                  <InputBox
                    width={240}
                    placeholder="이메일을 입력해주세요."
                    onChange={(value) => {
                      updateNewBuyer((prev) => ({
                        ...prev,
                        managerEmail: value,
                      }));
                    }}
                  />
                </RowTitleBox>
              )}

              {newBuyer.stakeType &&
                newBuyer.stakeType === StakeHolderType.FUND && (
                  <RowTitleBox title="GP 유형">
                    <CheckboxContainer className="body-14-rg">
                      <div
                        className="check__item"
                        onClick={() => {
                          updateNewBuyer((prev) => ({
                            ...prev,
                            gpStakeType: StakeHolderType.INDIVIDUAL,
                          }));
                        }}
                      >
                        <Radio
                          className={
                            newBuyer.gpStakeType === StakeHolderType.INDIVIDUAL
                              ? "radiobox__checked"
                              : ""
                          }
                        />
                        <span>개인</span>
                      </div>

                      <div
                        className="check__item"
                        onClick={() => {
                          updateNewBuyer((prev) => ({
                            ...prev,
                            gpStakeType: StakeHolderType.CORPORATION,
                          }));
                        }}
                      >
                        <Radio
                          className={
                            newBuyer.gpStakeType === StakeHolderType.CORPORATION
                              ? "radiobox__checked"
                              : ""
                          }
                        />
                        <span>기관</span>
                      </div>
                    </CheckboxContainer>
                  </RowTitleBox>
                )}

              {newBuyer.stakeType &&
                newBuyer.stakeType === StakeHolderType.FUND &&
                newBuyer.gpStakeType && (
                  <RowTitleBox
                    title={
                      newBuyer.gpStakeType === StakeHolderType.INDIVIDUAL
                        ? "GP 이름"
                        : "GP 기관명"
                    }
                  >
                    <InputBox
                      width={240}
                      placeholder="GP 이름을 입력해주세요."
                      onChange={(value) => {
                        updateNewBuyer((prev) => ({
                          ...prev,
                          gpName: value,
                        }));
                      }}
                    />
                  </RowTitleBox>
                )}

              {newBuyer.stakeType &&
                newBuyer.stakeType === StakeHolderType.FUND &&
                newBuyer.gpStakeType && (
                  <RowTitleBox
                    title={
                      newBuyer.gpStakeType === StakeHolderType.INDIVIDUAL
                        ? "GP 주민등록 번호"
                        : "GP 사업자 번호"
                    }
                  >
                    <InputBox
                      width={240}
                      textType={
                        newBuyer.stakeType === StakeHolderType.INDIVIDUAL
                          ? "residentRegistrationNumber"
                          : "businessNumber"
                      }
                      placeholder={
                        newBuyer.stakeType === StakeHolderType.INDIVIDUAL
                          ? "xxxxxx-xxxxxxx"
                          : "xxx-xx-xxxxx"
                      }
                      onChange={(value) => {
                        updateNewBuyer((prev) => ({
                          ...prev,
                          gpUniqueNumber: value,
                        }));
                      }}
                    />
                  </RowTitleBox>
                )}
              <FlexRow
                style={{
                  justifyContent: "flex-end",
                  marginTop: 0,
                  gap: 12,
                  marginRight: 4,
                }}
              >
                <DefaultButton
                  sizeType="xsmall"
                  text="취소"
                  onClick={closeRegisteBuyer}
                />
                <DefaultButton
                  sizeType="xsmall"
                  text="등록"
                  disabled={
                    !newBuyer.stakeType ||
                    !newBuyer.name ||
                    !newBuyer.uniqueNumber
                  }
                  onClick={registeBuyer}
                />
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        )}

        {selectedFundAccountId && !isRegisteBuyer && (
          <FlexColumn
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <FlexRow
              style={{
                marginLeft: 16,
                marginTop: 16,
                marginRight: 16,
                justifyContent: "space-between",
              }}
            >
              <div className="heading-16-b">구매자</div>
              <DefaultButton
                text="등록하기"
                onClick={() => {
                  setIsRegisteBuyer(true);
                }}
              />
            </FlexRow>
            <div style={{ padding: "0 16px", marginTop: 8 }}>
              <DefaultSearch
                value={searchKeyword}
                width={"100%"}
                placeholder="사업자 번호를 - 빼고 입력해주세요."
                onChangeValue={(value) => {
                  updateSearchKeyword(value);
                }}
                transfromPixel={(n) => n}
              />
            </div>
            <div
              className="body-14-sb"
              style={{
                padding: "0 16px",
                marginTop: 8,
                color: "var(--text-04)",
              }}
            >
              검색 목록
            </div>
            <BuyerListContainer>
              {getFilterBuyer().map((item) => {
                let isSelected = false;
                const selectedExitProcess = exitProcessInfo.find(
                  (item) => item.id === selectedFundAccountId
                );
                if (selectedExitProcess && item.acInvestmentOrganizationId) {
                  isSelected = selectedExitProcess.exitInfo
                    .map(
                      (info) =>
                        info.acInvestmentOrganization.acInvestmentOrganizationId
                    )
                    .includes(item.acInvestmentOrganizationId);
                }

                return (
                  <CardContainer>
                    <FlexRow
                      className="body-14-sb"
                      style={{
                        gap: 6,
                        alignItems: "center",
                        color: isSelected ? "var(--text-01)" : "var(--text-05)",
                      }}
                      onClick={() => {
                        addBuyer(
                          item.acInvestmentOrganizationId || 0,
                          item.name || ""
                        );
                      }}
                    >
                      <Check
                        className={isSelected ? "checkbox__checked" : ""}
                      />
                      <span>{item.name}</span>
                      <span>({item.stakeHolder?.uniqueNumber})</span>
                    </FlexRow>
                  </CardContainer>
                );
              })}
              {getFilterBuyer().length === 0 && (
                <FlexColumn
                  style={{
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 24,
                  }}
                >
                  <div
                    className="body-14-md"
                    style={{ color: "var(--text-04)" }}
                  >
                    {searchKeyword.length === 0
                      ? "구매자 목록이 비어있습니다."
                      : "선택 가능한 구매자가 없습니다."}
                    <br />
                    구매자를 등록하시겠습니까?
                  </div>
                  <DefaultButton
                    text="등록하기"
                    onClick={() => {
                      setIsRegisteBuyer(true);
                    }}
                  />
                </FlexColumn>
              )}
            </BuyerListContainer>
          </FlexColumn>
        )}
      </FlexColumn>

      <FlexColumn style={{ flex: 4, height: "100%", overflow: "scroll" }}>
        {selectedFundAccountId && (
          <FlexColumn style={{ padding: 24 }}>
            <div className="heading-24-sb">
              {selectedFundAccount()?.acFundAccount.nameKo}
            </div>
            <RowTitleBox title="주식종류">
              <div className="body-14-rg" style={{ marginTop: 9 }}>
                {selectedFundAccount()?.stockType?.name || "정보없음"}
              </div>
            </RowTitleBox>
            <RowTitleBox title="보유주식 수">
              <div className="body-14-rg" style={{ marginTop: 9 }}>
                {AddComma(selectedFundAccount()?.currentStock) || "정보없음"} 주
              </div>
            </RowTitleBox>
            <RowTitleBox title="투자 원금">
              <div className="body-14-rg" style={{ marginTop: 9 }}>
                {AddComma(selectedFundAccount()?.currentPrincipal) ||
                  "정보없음"}{" "}
                원
              </div>
            </RowTitleBox>
            <RowTitleBox title="지분율">
              <div className="body-14-rg" style={{ marginTop: 9 }}>
                {selectedFundAccount()?.currentPercentage || "정보없음"} %
              </div>
            </RowTitleBox>
            <RowTitleBox title="구매자 수">
              <div className="body-14-rg" style={{ marginTop: 9 }}>
                {selectedFundAccount()?.exitInfo.length} 명
              </div>
            </RowTitleBox>

            <RowTitleBox title="구매자 목록">
              <FlexRow style={{ flexWrap: "wrap", gap: 12 }}>
                {selectedFundAccount()?.exitInfo.map((buyer) => {
                  return (
                    <SelectedBuyerComponent>
                      <div className="caption-12-sb">
                        {buyer.acInvestmentOrganization.name}
                      </div>
                    </SelectedBuyerComponent>
                  );
                })}
              </FlexRow>
            </RowTitleBox>
          </FlexColumn>
        )}
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  border-top: 1px solid var(--gray-400);

  .checkbox__checked {
    rect {
      fill: var(--primary-purple);
      stroke: var(--primary-purple);
    }
    path {
      stroke: var(--white);
    }
  }
  .radiobox__checked {
    path {
      fill: var(--primary-purple);
    }
  }
`;

const FundAccountList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 6px 12px;

  border-radius: 4px;
  border: 1px solid var(--gray-300);
  background-color: var(--white);
  transition: all 0.2s ease;
  cursor: pointer;

  &.selected {
    background-color: var(--purple-200);
    :hover {
      background-color: var(--purple-200);
    }
  }
`;

const BuyerListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: scroll;

  padding: 4px 16px 16px 16px;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 20px;

  .check__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
`;

const SelectedBuyerComponent = styled.div`
  width: max-content;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents-01, #fff);

  padding: 6px 12px;
  gap: 8px;
`;
