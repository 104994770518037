import * as ExcelJS from "exceljs";

import { getNewDate, getYMD } from "../../../../../../common/commonUtil";

import {
  RealDataProps,
  StockChangeHistoryRealData,
  StockChangeHistoryRowData,
  StockChangeHistoryValidationData,
} from "../interface/Stock.interface";
import { StockHolder } from "../interface/StockHolder.interface";

export interface ResultRealData {
  stockHolderList: StockHolder[];
  issuingPrice: number;
  totalStocks: number;
  faceValue: number;
  currentDate: string;
}

export interface InvestmentRealDataResult {
  result: boolean;
  data?: ResultRealData;
  errIndex?: number;
  blurCount?: number;
  errType?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  errMsg?: string;
  errorStockHolderIndex?: number[];
}

/**
 * 주식 변동 내역 룰
 * 엑셀 읽기
 *  1. 주식변동내역 파일이 블루포인트에서 받은 code가 있는지 확인
 *  2. 날짜는 오름차순으로 정렬 되어야 한다.
 *  3. 전처리 가능한 데이터는 전처리 한다. (전처리가 된 값들은 알려준다. )
 *  4. 필수 값 확인한다. (일자, 변동내역, 주주명, 주민등록번호/사업자번호, 주식종류, 변동주식수, 액면가, 주당발행가, *구주거래일시 판매자(주주명, 사업자번호/주민등록번호))
 *
 * 주주명부 생성 프로세스
 *  1. 변동내역이 (설립자본금, 유상증자)는 같은 프로세스이다. 주주명부에서 주식수를 변동주식수 만큼 +
 *  2. 변동내역이 유상감자일 경우 주주명부에서 주식수를 변동주식수 만큼 -
 *  3. 변동내역이 (무상증자, 무상감자, 액면분할, 액면병합)일 경우 변동내역 시작부터 끝까지의 갯수가 시작 전까지의 주주명부와 같아야 한다.
 *  4. 변동내역이 무상증자, 무상감자 일 경우 변동주식수는 +, - 이고 주당발행가는 0원이다.
 *  5. 변동내역이 액면분할, 액면병합 일 경우 변동주식수는 _, - 이고 주당발행가액도 0원, 액면가액은 변한다. 액면가액이 변하면 다음 변화가 있을때까지 동일하다.
 * @param e
 * @param callback
 * @returns
 */

const preProcessDate = (data: string) => {
  // 특수문자, 괄호, 점, 공백 모두
  const reg = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim;
  const resultData = data.replace(reg, "-");
  const splitData = resultData.split("-");
  if (splitData.length !== 3) return "error";
  if (splitData[0].length !== 4) return "error";
  if (splitData[1].length > 2) return "error";
  if (splitData[2].length > 2) return "error";
  if (parseInt(splitData[1], 10) < 1 || parseInt(splitData[1], 10) > 12)
    return "error";
  if (parseInt(splitData[2], 10) < 1 || parseInt(splitData[2], 10) > 31)
    return "error";

  return `${splitData[0]}-${
    splitData[1].length === 1 ? `0${splitData[1]}` : splitData[1]
  }-${splitData[2].length === 1 ? `0${splitData[2]}` : splitData[2]}`;
};

const preProcessDefault = (data: string) => {
  const resultData = data.replaceAll(" ", "");
  return resultData.trim();
};

const preProcessStockChangeType = (data: string) => {
  const reg = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim;
  const resultData = data.replace(reg, "").toUpperCase();
  switch (resultData) {
    case "설립자본금":
      return "설립자본금";
    case "스톡옵션":
      return "스톡옵션";
    case "출자전환":
      return "출자전환";
    case "유상증자":
      return "유상증자";
    case "유상감자":
      return "유상감자";
    case "무상증자":
      return "무상증자";
    case "무상감자":
      return "무상감자";
    case "액면분할":
      return "액면분할";
    case "액면병합":
      return "액면병합";
    case "구주거래":
      return "구주거래";
    default:
      return "error";
  }
};

const preProcessRegistrationNumber = (data: string) => {
  const reg = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim;
  const resultData = data.replace(reg, "");
  if (resultData.length === 10)
    return `${resultData.slice(0, 3)}-${resultData.slice(
      3,
      5
    )}-${resultData.slice(5)}`;
  if (resultData.length === 13 || resultData.length === 7)
    return `${resultData.slice(0, 6)}-${resultData.slice(6, 7)}******`;
  return "error";
};

const preProcessStockHolderName = (data: string) => {
  const trimData = data.trim();
  const transData = trimData.replaceAll("㈜", "(주)");
  return transData;
};

const preProcessStockType = (data: string) => {
  const reg = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim;
  const resultData = data.replace(reg, "").toUpperCase();
  switch (resultData) {
    case "보통주":
    case "CS":
      return "보통주";
    case "상환우선주":
    case "RPS":
      return "상환우선주";
    case "전환우선주":
    case "CPS":
      return "전환우선주";
    case "상환전환우선주":
    case "RCPS":
      return "상환전환우선주";
    case "복수의결권주":
    case "Class B":
      return "복수의결권주";
    default:
      return "error";
  }
};

const preProcessNumberValue = (data: string) => {
  let result = data.replace(/[^0-9.]/g, "");

  if (result.split(".").length > 1) {
    let parts = result.split(".");

    result = parts[0] + "." + parts[1].slice(0, 2);
  }

  console.log(result);

  return result;
};

const getKeyByIndex = (index: number) => {
  switch (index) {
    case 1:
      return "date";
    case 2:
      return "stockChangeType";
    case 3:
      return "stageType";
    case 4:
      return "stockholderName";
    case 5:
      return "registrationNumber";
    case 6:
      return "relationship";
    case 7:
      return "country";
    case 8:
      return "stockType";
    case 9:
      return "fluctuateStockNumber";
    case 10:
      return "faceValue";
    case 11:
      return "issuingPrice";
    case 12:
      return "totalInvestmentAmount";
    case 13:
      return "seller";
    case 14:
      return "sellerRegistrationNumber";
    case 15:
      return "note";
    default:
      return "error";
  }
};

const gateWayPreProcess = (rowData: string[], key: string, value: string) => {
  switch (key) {
    case "date":
      const dateObj = getNewDate(value);
      return preProcessDate(
        dateObj.toString() === "Invalid Date" ? value : getYMD(dateObj)
      );
    case "stockChangeType":
      const stockChangeTypeData = preProcessStockChangeType(value);
      if (
        ![
          "설립자본금",
          "유상증자",
          "스톡옵션",
          "유상감자",
          "무상증자",
          "무상감자",
          "액면분할",
          "액면병합",
          "구주거래",
          "출자전환",
          "스톡옵션",
        ].includes(stockChangeTypeData)
      ) {
        return "error";
      }
      return stockChangeTypeData;
    case "stageType":
      return preProcessDefault(value);
    case "stockholderName":
      return preProcessStockHolderName(value);
    case "registrationNumber":
      if (value === undefined) return "error";
      if (value.length === 0) return "error";
      return preProcessDefault(value);
    case "relationship":
      return preProcessDefault(value);
    case "country":
      return preProcessDefault(value);
    case "stockType":
      const stockTypeData = preProcessStockType(value);
      if (
        ![
          "보통주",
          "상환우선주",
          "전환우선주",
          "상환전환우선주",
          "복수의결권주",
        ].includes(stockTypeData)
      ) {
        return "error";
      }
      return stockTypeData;
    case "fluctuateStockNumber":
      return preProcessNumberValue(value);
    case "faceValue":
      return preProcessNumberValue(value);
    case "issuingPrice":
      const issuingPriceData = preProcessNumberValue(value);
      if (
        ["무상증자", "무상감자", "액면분할", "액면병합"].includes(rowData[1])
      ) {
        return issuingPriceData === "0" ? issuingPriceData : "error";
      }
      return issuingPriceData;
    case "totalInvestmentAmount":
      return preProcessNumberValue(value);

    case "seller":
      if (rowData[1] === "구주거래") {
        if (value === undefined) return "error";
        if (value.length === 0) return "error";
        return preProcessStockHolderName(value);
      }
      return preProcessStockHolderName(value);
    case "sellerRegistrationNumber":
      if (rowData[1] === "구주거래") {
        if (value === undefined) return "error";
        if (value.length === 0) return "error";
        return preProcessDefault(value);
      }
      return preProcessDefault(value);
    case "note":
      return value ? preProcessDefault(value) : "";
    default:
      return "";
  }
};

const getCellText = (cell: any) => {
  switch (cell.model.type) {
    case 2:
    case 3:
    case 4:
      return `${cell.model.value}`;
    case 6:
      return `${cell.model.result}`;
    case 8:
      return `${cell.model.value.richText
        .map((item: any) => item.text)
        .join("")}`;
    default:
      return "";
  }
};

// 주식변동 내역 읽기
export const readStockChangeHistoryExcelFile = (
  file: File,
  callback: ({
    isReadSucess,
    realData,
    isFileChanged,
  }: {
    isReadSucess: boolean;
    realData: StockChangeHistoryRealData[];
    isFileChanged: boolean;
  }) => void
) => {
  if (!file) return;

  const workbook = new ExcelJS.Workbook();
  const reader = new FileReader();

  reader.readAsArrayBuffer(file);
  reader.onload = async () => {
    let readable = true;
    let isFileChanged = false;
    let isReadSucess = true; // 성공적으로 끝맞췄는가?
    const realData: StockChangeHistoryRealData[] = [];
    const rowData: any[] = [];
    const buffer: any = reader.result;
    if (buffer === null) return [];

    const result = await workbook.xlsx.load(buffer);

    result.worksheets.forEach((sheet, sheetIndex) => {
      if (sheetIndex === 0) {
        sheet.eachRow((row, rowNum) => {
          if (
            sheet.getRow(1).getCell(1).value !==
            "블루포인트파트너스 주식변동내역"
          ) {
            isFileChanged = true;
            isReadSucess = false;
          }

          if (readable && !isFileChanged && rowNum > 2) {
            const cellDatasToString: string[] = [];
            for (let i = 1; i <= 15; i += 1) {
              const cell = row.getCell(i);

              const originData = getCellText(cell);
              console.log(`${i} : ${originData}`);

              if (i === 1 && originData === "합계") {
                return (readable = false);
              }
              cellDatasToString.push(originData);
            }
            if (readable) {
              rowData.push(cellDatasToString);
            }
          }
        });

        rowData.forEach((row, rowNum) => {
          const cellDatas: StockChangeHistoryValidationData[] = [];
          row.forEach((cell: string, cellNum: number) => {
            const key = getKeyByIndex(cellNum + 1);
            const originData = cell;
            const data = gateWayPreProcess(row, key, originData);
            const position = {
              x: cellNum + 1,
              y: rowNum + 1,
            };
            const state = data === "error" ? "error" : "success";
            const validationMsg = state === "error" ? `에러다` : "";

            if (state === "error") isReadSucess = false;

            const newCellData: StockChangeHistoryValidationData = {
              state,
              key,
              data: state === "error" ? originData : data,
              originData,
              validationMsg,
              position,
            };
            cellDatas.push(newCellData);
          });

          const newRowData: StockChangeHistoryRowData = {
            date: cellDatas[0],
            stockChangeType: cellDatas[1],
            stageType: cellDatas[2],
            stockholderName: cellDatas[3],
            registrationNumber: cellDatas[4],
            relationship: cellDatas[5],
            country: cellDatas[6],
            stockType: cellDatas[7],
            fluctuateStockNumber: cellDatas[8],
            faceValue: cellDatas[9],
            issuingPrice: cellDatas[10],
            totalInvestmentAmount: cellDatas[11],
            seller: cellDatas[12]
              ? cellDatas[12]
              : {
                  state: "success",
                  key: "seller",
                  data: "",
                  originData: "",
                  position: { x: 13, y: rowNum + 1 },
                },
            sellerRegistrationNumber: cellDatas[13]
              ? cellDatas[13]
              : {
                  state: "success",
                  key: "sellerRegistrationNumber",
                  data: "",
                  originData: "",
                  position: { x: 14, y: rowNum + 1 },
                },
            note: cellDatas[14]
              ? cellDatas[14]
              : {
                  state: "success",
                  key: "note",
                  data: "",
                  originData: "",
                  position: { x: 15, y: rowNum + 1 },
                },
          };
          realData.push({
            data: newRowData,
            position: { row: `${rowNum + 1}`, y: rowNum + 1 },
          });
        });
      }
    });
    console.log(realData);
    callback({ isReadSucess, realData, isFileChanged });
  };
};

const detailStockHolderFind = (
  data: Map<
    string,
    {
      stocks: number;
      country: string;
      relationship: string;
      avgIssuingPrice: number;
      purchaseStockNum: number;
      percentage: number;
      stocksPrice: number;
    }
  >,
  targetInfo: string
): {
  msg: string;
  errorType: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  targetIndex: number;
} => {
  const target = targetInfo.split("!");
  const targetStockholderName = target[0];
  const targetRegistrationNumber = target[1];

  const keys = Array.from(data.keys());

  const stockholderNameArr = keys.filter((item) => {
    const sh = item.split("!");
    return sh[0] === targetStockholderName && sh[2] === target[2];
  });

  const stockholderNumberArr = keys.filter((item) => {
    const sh = item.split("!");
    return sh[1] === targetRegistrationNumber && sh[2] === target[2];
  });

  if (stockholderNameArr.length > 0 && stockholderNumberArr.length === 0) {
    const index = keys.findIndex((item) => {
      const sh = item.split("!");
      return sh[0] === targetStockholderName && sh[2] === target[2];
    });
    return {
      msg: `의 주민/사업자등록번호가 일치하지 않습니다. 다시 한번 확인해 주세요.`,
      errorType: 2,
      targetIndex: index,
    };
  }

  if (stockholderNameArr.length === 0 && stockholderNumberArr.length > 0) {
    const index = keys.findIndex((item) => {
      const sh = item.split("!");
      return sh[1] === targetRegistrationNumber && sh[2] === target[2];
    });
    return {
      msg: `명이 일치하지 않습니다. 다시 한번 확인해 주세요.`,
      errorType: 3,
      targetIndex: index,
    };
  }
  return {
    msg: `명과 주민/사업자등록번호가 일치하지 않습니다. 다시 한번 확인해 주세요.`,
    errorType: 4,
    targetIndex: -1,
  };
};

export const makeStockHolderList = (
  data: RealDataProps[]
): InvestmentRealDataResult => {
  const result = true;

  let currentDate = ""; // 제일 마지막 데이터 일자
  let totalStocks = 0; // 총 발행 주식 수
  let issuingPrice = 0; // 마지막 최신 주당 발행가액
  let faceValue = 0; // 마지막 최신 액면가액

  const stockHolderList = new Map<
    string,
    {
      stocks: number;
      country: string;
      relationship: string;
      avgIssuingPrice: number;
      purchaseStockNum: number;
      percentage: number;
      stocksPrice: number;
    }
  >(); // map 객채 생성

  for (let i = 0; i < data.length; i += 1) {
    const item = data[i];
    const targetKey = `${preProcessDefault(item.stockholderName)}!${
      item.registrationNumber
    }!${item.stockType}`;
    // 현재 생성된 주주명부에 해당 데이터 row의 주주가 있는가?
    const targetStockHolder = stockHolderList.get(targetKey);

    // 날짜
    const { date } = item;
    // 변동 주식수
    const { fluctuateStockNumber } = item;
    // 관계
    const { relationship } = item;

    // 현재 날짜가 바로 직전에 읽은 날짜 보다 과거인지 확인

    if (
      currentDate &&
      getNewDate(currentDate).getTime() > getNewDate(date).getTime()
    )
      return {
        result: false,
        errIndex: i,
        errType: 1,
        errMsg: `[행 : ${
          i + 3
        }]의 날짜 순서가 잘못되어 있습니다. 날짜를 정확히 입력해 주세요. `,
      };

    // 주식변동 타입이 무엇인지에 따라 프로세스 달라짐

    // 설립자본금 or 유상증자 =>
    if (
      item.stockChangeType === "설립자본금" ||
      item.stockChangeType === "유상증자"
    ) {
      // 마지막
      issuingPrice = item.issuingPrice;
      if (item.stockChangeType === "설립자본금") faceValue = item.faceValue;
      if (targetStockHolder) {
        targetStockHolder.stocks += fluctuateStockNumber;
        targetStockHolder.purchaseStockNum += fluctuateStockNumber;
        targetStockHolder.avgIssuingPrice +=
          item.issuingPrice * fluctuateStockNumber;
        targetStockHolder.relationship = relationship;
      } else {
        stockHolderList.set(targetKey, {
          stocks: item.fluctuateStockNumber,
          country: item.country,
          relationship: item.relationship,
          avgIssuingPrice: item.issuingPrice * fluctuateStockNumber,
          purchaseStockNum: fluctuateStockNumber,
          percentage: 0,
          stocksPrice: 0,
        });
      }
      currentDate = item.date;
    } else if (["스톡옵션", "출자전환"].includes(item.stockChangeType)) {
      // 이거 어떻게 해?
      if (targetStockHolder) {
        targetStockHolder.stocks += fluctuateStockNumber;
        targetStockHolder.purchaseStockNum += fluctuateStockNumber;
        targetStockHolder.avgIssuingPrice +=
          item.issuingPrice * fluctuateStockNumber;
        targetStockHolder.relationship = relationship;
      } else {
        stockHolderList.set(targetKey, {
          stocks: item.fluctuateStockNumber,
          country: item.country,
          relationship: item.relationship,
          avgIssuingPrice: item.issuingPrice * fluctuateStockNumber,
          purchaseStockNum: fluctuateStockNumber,
          percentage: 0,
          stocksPrice: 0,
        });
      }
      currentDate = item.date;
    } else if (item.stockChangeType === "유상감자") {
      currentDate = item.date;
      // 이거 어떻게 해?
      if (targetStockHolder) {
        // 빼면됨 나올 수 있는 에러 가지고 있는 주식수 보다 많다.
        if (targetStockHolder.stocks < fluctuateStockNumber) {
          return {
            result: false,
            errIndex: i,
            errType: 5,
            errMsg: `[행 : ${i + 3}]의 판매 주식 수가 ${item.seller}(${
              item.sellerRegistrationNumber
            })의 보유 주식 수와 맞지 않습니다. 다시 한번 확인해 주세요.`,
          };
        }
        targetStockHolder.stocks -= fluctuateStockNumber;
        targetStockHolder.relationship = relationship;
      } else {
        const detailStockHolderFindResult = detailStockHolderFind(
          stockHolderList,
          targetKey
        );
        return {
          result: false,
          errIndex: i,
          errType: detailStockHolderFindResult.errorType,
          errMsg: `[행 : ${i + 3}]의 주주${detailStockHolderFindResult.msg}`,
        };
      }
    } else if (item.stockChangeType === "구주거래") {
      // 판매자 정보 있음
      const sellerKey = `${preProcessDefault(item.seller || "")}!${
        item.sellerRegistrationNumber
      }!${item.stockType}`;
      const seller = stockHolderList.get(sellerKey);
      if (!seller) {
        const detailStockHolderFindResult = detailStockHolderFind(
          stockHolderList,
          targetKey
        );
        return {
          result: false,
          errIndex: i,
          errType: detailStockHolderFindResult.errorType,
          errMsg: `[행 : ${i + 3}]의 판매자${detailStockHolderFindResult.msg}`,
        };
      }

      if (seller.stocks < fluctuateStockNumber) {
        return {
          result: false,
          errIndex: i,
          errType: 5,
          errMsg: `[행 : ${i + 3}]의 판매 주식 수가 ${item.seller}(${
            item.sellerRegistrationNumber
          })의 보유 주식 수와 맞지 않습니다. 다시 한번 확인해 주세요.`,
        };
      }

      if (targetStockHolder) {
        targetStockHolder.stocks += fluctuateStockNumber;
        targetStockHolder.purchaseStockNum += fluctuateStockNumber;
        targetStockHolder.avgIssuingPrice +=
          item.issuingPrice * fluctuateStockNumber;
        targetStockHolder.relationship = relationship;
      } else {
        stockHolderList.set(targetKey, {
          stocks: item.fluctuateStockNumber,
          country: item.country,
          relationship: item.relationship,
          avgIssuingPrice: item.issuingPrice * fluctuateStockNumber,
          purchaseStockNum: fluctuateStockNumber,
          percentage: 0,
          stocksPrice: 0,
        });
      }

      seller.stocks -= fluctuateStockNumber;
      if (seller.stocks === 0) {
        stockHolderList.delete(sellerKey);
      }

      currentDate = item.date;
    } else {
      // 날짜
      const _date = item.date;
      // 변동내역
      const _stockChangeType = item.stockChangeType;
      // 현재까지 주주명부 리스트
      const stockHoldersCount = stockHolderList.size;
      // 날짜와변동내역이 같은 값 개수
      let _dataCount = 0;
      // index
      let j = i;
      const _targetDatas = [];
      // 같은날 같은 변동내역을 갖고 있는 데이터 갯수 구하기
      while (true) {
        const _targetData = data[j];
        if (
          _targetData &&
          _targetData.date === _date &&
          _targetData.stockChangeType === _stockChangeType
        ) {
          _targetDatas.push(_targetData);
          _dataCount += 1;
          j += 1;
        } else {
          break;
        }
      }

      // 개수가 같지 않으면 잘못된 것임
      if (stockHoldersCount !== _dataCount) {
        const notExistStockholderIndex: number[] = [];
        const keys = Array.from(stockHolderList.keys());
        const _targetsKeys = _targetDatas.map(
          (target) =>
            `${preProcessDefault(target.stockholderName)}!${
              target.registrationNumber
            }!${target.stockType}`
        );

        keys.forEach((key, index) => {
          if (!_targetsKeys.includes(key)) {
            notExistStockholderIndex.push(index);
          }
        });
        return {
          result: false,
          errMsg: `[행 : ${i + 3}]의 [${_stockChangeType}]의 경우 [행 : ${
            i + 3
          }]의 일자보다 위의 모든 주주가 포함되어야 합니다. 모든 주주에 대해 [${_stockChangeType}]을 입력했는지 다시 한번 확인해 주세요. `,
          errType: 6,
          errIndex: i,
          blurCount: _dataCount,
          errorStockHolderIndex: notExistStockholderIndex,
        };
      }

      // 배수 구하기
      let originTotalStock = 0;
      let finalTotalStock = 0;

      // 갯수가 같으니 그만큼 빼주고 더해줌
      for (j = i; j < i + _dataCount; j += 1) {
        const targetData = data[j];
        const _targetKey = `${preProcessDefault(
          targetData.stockholderName || ""
        )}!${targetData.registrationNumber}!${targetData.stockType}`;
        const _fluctuateStockNumber = targetData.fluctuateStockNumber;
        const _targetStockHolder = stockHolderList.get(_targetKey);

        if (_targetStockHolder === undefined) {
          const detailStockHolderFindResult = detailStockHolderFind(
            stockHolderList,
            _targetKey
          );
          return {
            result: false,
            errType: detailStockHolderFindResult.errorType,
            errMsg: `[행 : ${j + 3}]의 주주${detailStockHolderFindResult.msg}`,
            errIndex: j,
          };
        }
        // 원래 주식수
        originTotalStock += _targetStockHolder.stocks;

        if (["무상감자", "액면병합"].includes(_stockChangeType)) {
          if (_targetStockHolder.stocks >= _fluctuateStockNumber) {
            _targetStockHolder.stocks -= _fluctuateStockNumber;
            _targetStockHolder.purchaseStockNum -= _fluctuateStockNumber;
          } else {
            return {
              result: false,
              errMsg: `[행 : ${
                i + 3
              }]의 변동 주식수가 해당 주주의 현재 주식수로 계산할 수 없습니다. 다시 한번 확인해 주세요.`,
              errType: 7,
              errIndex: j,
            };
          }

          if (_stockChangeType === "액면병합") {
            faceValue = targetData.faceValue;
          }
        } else {
          _targetStockHolder.stocks += _fluctuateStockNumber;
          _targetStockHolder.purchaseStockNum += _fluctuateStockNumber;

          if (_stockChangeType === "액면분할") {
            faceValue = targetData.faceValue;
          }
        }
        finalTotalStock += _targetStockHolder.stocks;
      }

      if (["무상감자", "액면병합"].includes(_stockChangeType)) {
        issuingPrice *= parseFloat(
          (finalTotalStock / originTotalStock).toFixed(1)
        );
      } else {
        issuingPrice /= parseFloat(
          (finalTotalStock / originTotalStock).toFixed(1)
        );
      }
      i += _dataCount - 1;
      currentDate = data[i].date;
    }
  }

  stockHolderList.forEach((item) => (totalStocks += item.stocks || 0));
  stockHolderList.forEach((item) => {
    item.percentage = parseFloat(
      (((item.stocks || 0) / totalStocks) * 100).toFixed(4)
    );
    item.avgIssuingPrice = parseInt(
      `${(item.avgIssuingPrice || 0) / (item.purchaseStockNum || 0)}`,
      10
    );
    item.stocksPrice = item.avgIssuingPrice * (item.stocks || 0);
  });

  return {
    result,
    data: {
      stockHolderList: Array.from(stockHolderList, ([key, value]) => {
        const keySplit = key.split("!");
        return {
          ...value,
          stockholderName: keySplit[0],
          registrationNumber: keySplit[1],
          stockType: keySplit[2],
        };
      }).filter((item) => item.stocks > 0),
      currentDate,
      totalStocks,
      issuingPrice,
      faceValue,
    },
  };
};
