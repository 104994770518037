import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";

import { DefaultButton } from "lattice_core";
import {
  ActiveFundAccountType,
  FundManager,
  IAFinancialStatements2,
  IAReportAccount,
  IAReportUser,
  InvestmentAssessment,
  NewOldStockType,
  QuorumType,
  StockType,
  UserInfo,
} from "../../../../../type/data";
import {
  AddComma,
  RemoveComma,
  getNewDate,
  getRangeArray,
} from "../../../../../common/commonUtil";
import {
  getPortfolFolder,
  getPortfolioInfo,
  registeEvaluation,
} from "../../../../../api/repository/portfolio/PortfolioRepository";
import {
  getActiveFundaccount,
  getAllAcUser,
  getAllDictionary,
  getNewOldStock,
} from "../../../../../api/repository/accelerator/AcceleratorRepository";

import SpinnerModal from "../../../../../components/modal/SpinnerModal";

import {
  SelectBox,
  SelectBoxData,
} from "../../../evaluation-detail/desktop/components/SelectBox";
import {
  NewOldStockRadio,
  NewOldStockRadioProps,
} from "../../../evaluation-detail/desktop/components/NewOldStockRadio";
import ColumnTitleBox from "../../../../../components/box/ColumnTitleBox";
import InputBox from "../../../evaluation-detail/mobile/components/InputBox";

interface Props {
  portfolioId: number;
  dealflowId: number;
  isNext: boolean;
  close: () => void;
  callbackData: (investmentAssessmentId: number) => void;
  transfromPixel?: (value: number) => number;
}
const EvaluationOpenModal: React.FC<Props> = (props) => {
  const {
    portfolioId,
    dealflowId,
    isNext,
    close,
    callbackData,
    transfromPixel = (n) => n,
  } = props;
  const TITLE_WIDTH = 175;

  const [curPage, updatePage] = useState<number>(1);
  const [isOriginAccount, updateOriginAccount] = useState<boolean>(true);
  const [reportFolderId, updateReportFolderId] = useState<string>("");

  const [fundAccountList, updateFundAccountList] = useState<
    ActiveFundAccountType[]
  >([]);

  const [allAcUser, updateAllAcUser] = useState<UserInfo[]>([]);
  const [stockTypeList, updateStockTypeList] = useState<StockType[]>([]);
  const [quorumTypeList, updateQuorumTypeList] = useState<QuorumType[]>([]);
  const [newOldStockList, updateNewOldStockList] = useState<NewOldStockType[]>(
    []
  );
  const [fundManagerList, updateFundManagerList] = useState<FundManager[]>([]);
  const [loadingModal, updateLoadingModal] = useState<boolean>(false);

  const wrapperPageRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);

  // page 1
  const [issuingPrice, updateIssuingPrice] = useState<string>("");
  const [postTotalStockNumber, updatePostTotalStockNumber] =
    useState<string>("");
  const [previousTotalStockNumber, updatePreviousTotalStockNumber] =
    useState<string>("");
  const [faceValue, updateFaceValue] = useState<string>(""); // 액면가

  // page 2
  const [selectedFundAccount, updateFundAccount] = useState<
    ActiveFundAccountType | undefined
  >(undefined);
  const [selectedStockType, updateStockType] = useState<StockType | undefined>(
    undefined
  );
  const [selectedNewOldStockType, updateNewOldStockType] = useState<
    NewOldStockType | undefined
  >(undefined);

  const [selectedQuorumType, updateQuorumType] = useState<
    QuorumType | undefined
  >(undefined);

  const [allocationWay, updateAllocationWay] = useState<string>("제 3자 방식");
  const [investmentAmount, updateInvestmentAmount] = useState<string>(""); //당사 투자 금액 , 당 조합 투자 금액
  const [stockNumber, updateStockNumber] = useState<string>(""); // 당사 배정 주식 수, 당 조합 배정 주식 수
  const [oldStockPurchasePrice, updateOldStockPurchasePrice] =
    useState<string>(""); // 구주발행가

  const [shareholdingRatio, updateShareholdingRatio] = useState<string>(""); // 투자 후 지분율

  const [buyCompany, updateBuyCompany] = useState<string>("");
  const [initialIssuingPrice, updateInitialIssuingPrice] = useState<string>("");
  const [buyCompanyValue, updateBuyCompanyValue] = useState<string>("");
  const [curNewIssuingPrice, updateCurNewIssuingPrice] = useState<string>("");

  const participantData = useRef<
    | {
        participantMain: string;
        participantMainStructureName: string;
        participantMainTeamName: string;
        participantSub: string[];
      }
    | undefined
  >(undefined);

  const setBuycompanayValue = (stockNum: number | string) => {
    const data =
      typeof stockNum === "string" ? convertNumber(stockNum) : stockNum;
    const totalStockNumber =
      convertNumber(previousTotalStockNumber) +
      convertNumber(postTotalStockNumber);
    updateBuyCompanyValue(AddComma(data * totalStockNumber));
  };

  const setShareholdingRatio = (stockNum: number | string) => {
    const data =
      typeof stockNum === "string" ? convertNumber(stockNum) : stockNum;
    const totalStockNumber =
      convertNumber(previousTotalStockNumber) +
      convertNumber(postTotalStockNumber);
    updateShareholdingRatio(((data / totalStockNumber) * 100).toFixed(2));
  };

  const getPreviousComapnyValue = () => {
    const _issuingPrice =
      issuingPrice.length > 0 ? parseInt(RemoveComma(issuingPrice), 10) : 0;
    const ptStockNumber =
      previousTotalStockNumber.length > 0
        ? parseInt(RemoveComma(previousTotalStockNumber), 10)
        : 0;

    const _previousCompanyValue =
      _issuingPrice !== 0 && ptStockNumber !== 0
        ? `${_issuingPrice * ptStockNumber}`
        : "0";
    return _previousCompanyValue;
  };

  const getTotalInvestmentAmount = () => {
    const _issuingPrice =
      issuingPrice.length > 0 ? parseInt(RemoveComma(issuingPrice), 10) : 0;
    const ptStockNumber =
      postTotalStockNumber.length > 0
        ? parseInt(RemoveComma(postTotalStockNumber), 10)
        : 0;

    const _totalInvestmentAmount =
      _issuingPrice !== 0 && ptStockNumber !== 0
        ? `${_issuingPrice * ptStockNumber}`
        : "0";
    return _totalInvestmentAmount;
  };

  //신주 당사 투자 금액
  const getOurInvestmentAmount = () => {
    const _issuingPrice =
      issuingPrice.length > 0 ? parseInt(RemoveComma(issuingPrice), 10) : 0;
    const _stockNumber =
      stockNumber.length > 0 ? parseInt(RemoveComma(stockNumber), 10) : 0;

    const _totalInvestmentAmount =
      _issuingPrice !== 0 && _stockNumber !== 0
        ? `${_issuingPrice * _stockNumber}`
        : "0";
    return _totalInvestmentAmount;
  };
  //구주 당사 구입 금액
  const getOurPurchaseAmount = () => {
    const _oldStockPurchasePrice =
      oldStockPurchasePrice.length > 0
        ? parseInt(RemoveComma(oldStockPurchasePrice), 10)
        : 0;
    const _stockNumber =
      stockNumber.length > 0 ? parseInt(RemoveComma(stockNumber), 10) : 0;

    const _totalInvestmentAmount =
      _oldStockPurchasePrice !== 0 && _stockNumber !== 0
        ? `${_oldStockPurchasePrice * _stockNumber}`
        : "0";
    return _totalInvestmentAmount;
  };

  const getPostCompanyValue = () => {
    const _previousValue = convertNumber(getPreviousComapnyValue());
    const _totalInvestmentAmount = convertNumber(getTotalInvestmentAmount());
    const _postCompanyValue =
      _previousValue !== 0 && _totalInvestmentAmount !== 0
        ? `${_previousValue + _totalInvestmentAmount}`
        : "0";
    return _postCompanyValue;
  };

  const createDocumentInit = (folderId: string): InvestmentAssessment => {
    return {
      investmentAssessmentId: "newDocument",
      portfolio: {
        portfolioId: portfolioId || 0,
      },
      isNext: isNext,
      isConfirmed: false,
      participantMain: participantData.current?.participantMain,
      participantMainStructureName:
        participantData.current?.participantMainStructureName,
      participantMainTeamName: participantData.current?.participantMainTeamName,
      participantSub: participantData.current?.participantSub,
      isTips: "NO",
      folderId: folderId,
      investmentAssessmentReportAccount: [],

      // 회사 개요
      investmentAssessmentCompanyInfo: {
        investmentAssessmentCompanyInfoId: "newCompanyInfo",
      },
      //기여율
      contributionUsers: {
        isRequired: false,
        isChecked: false,
        data: {},
      },
      //지분 내역
      investmentAssessmentStakeStatement: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //주요 연혁
      investmentAssessmentCompanyHistory: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //주요 경영진 현황
      investmentAssessmentKeyManagement: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //임직원 현황
      investmentAssessmentCompanyMember: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //주요 재무 현황1
      investmentAssessmentFinancialStatement1: {
        isRequired: false,
        isChecked: false,
        data: {},
      },
      //주요 재무 현황2
      investmentAssessmentFinancialStatement2: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //차입금 현황
      investmentAssessmentDebt: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //정부지원사업 수행현황
      investmentAssessmentGovernmentProject: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //계약현황
      investmentAssessmentContractStatus: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //국내외 관련기업(경쟁사, 유사기업)현황
      investmentAssessmentSimilarBusiness: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },

      //매출/인력계획
      investmentAssessmentSalesManpowerPlan: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //자금소요 계획 (단기 Y+2)
      investmentAssessmentCostEstimationPlan: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //자금조달 계획 (단기 Y+2)
      investmentAssessmentFinancialSourcingPlan: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //마일스톤별 주요 KPI (단기 Y+2)
      investmentAssessmentMilestoneKPI: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //지식재산권 보유 현황
      investmentAssessmentIprsProcess: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //자본금 증자 연혁
      investmentAssessmentCapitalIncreaseHistory: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
      //자금 조달 계획
      investmentAssessmentFinancingPlan: {
        isRequired: false,
        isChecked: false,
        data: [{}],
      },
    };
  };
  const convertNumber = (value: string | number) => {
    const _value = RemoveComma(`${value}`);

    return parseInt(_value, 10);
  };

  const convertFloat = (value: string | number) => {
    const _value = `${value}`;
    return parseFloat(_value);
  };

  const createAccount = () => {
    if (!selectedFundAccount) return;

    const isExistQuorum =
      selectedFundAccount.quorum !== null &&
      selectedFundAccount.quorum !== undefined;

    // 해당 계정의 대펀을 찾는다.
    const fundManagerId: number | string | undefined = fundManagerList.find(
      (item) =>
        item.acFundAccount?.acFundAccountId ===
        selectedFundAccount.acFundAccountId
    )?.acUser?.acUserId;

    // 대펀 UserInfo를 찾는다.
    const fundManagerUserInfo: UserInfo | undefined = allAcUser.find(
      (item) => item.acUserId === fundManagerId
    );

    const inviteList = [];
    if (selectedFundAccount.acFundAccountId !== 1 && fundManagerUserInfo) {
      const inviteUserObj: IAReportUser = {
        acUser: {
          acUserId: parseInt(`${fundManagerUserInfo.id}`),
          name: fundManagerUserInfo.name,
          isShowProfile: fundManagerUserInfo.isShowProfile,
          profileImgUrl: fundManagerUserInfo.profileImgUrl,
          email: fundManagerUserInfo.email || "",
        },
        priorityInfo: {
          priorityType: 1,
          priorityCount: 2,
        },
      };
      inviteList.push(inviteUserObj);
    }

    const newAccount: IAReportAccount = {
      investmentAssessmentReportAccountId: `newReportAccount-0`,
      isCompleted: false,
      isPublished: false,
      investmentAssessmentInvite: inviteList,
      investmentAssessmentAccount: {
        // 신주 배정 방식 초기화
        accountName: selectedFundAccount.nameKo,
        acFundAccount: {
          acFundAccountId: parseInt(`${selectedFundAccount.acFundAccountId}`),
        },
        allocationWay: allocationWay,
        buyCompany: buyCompany,
        buyCompanyValue: convertNumber(buyCompanyValue),
        curNewIssuingPrice: convertNumber(curNewIssuingPrice),
        faceValue: convertNumber(faceValue),
        investmentAmount: convertNumber(investmentAmount),
        issuingPrice: convertNumber(issuingPrice),
        oldStockPurchasePrice: convertNumber(oldStockPurchasePrice),
        isExistQuorum: isExistQuorum,
        initialIssuingPrice: convertNumber(initialIssuingPrice),
        newOldStock: selectedNewOldStockType,
        note: "",
        postCompanyValue: convertNumber(getPostCompanyValue()),
        previousValue: convertNumber(getPreviousComapnyValue()),
        postTotalStockNumber: convertNumber(postTotalStockNumber),
        previousTotalStockNumber: convertNumber(previousTotalStockNumber),
        stockType: selectedStockType,
        stockNumber: convertNumber(stockNumber),
        shareholdingRatio: convertFloat(shareholdingRatio),
        stage: "",
        totalInvestmentAmount: convertNumber(getTotalInvestmentAmount()),
        quorumType: {
          number: isExistQuorum
            ? selectedFundAccount.quorum
            : selectedQuorumType?.number, // 본계정인 경우 본부 투심이 디폴트
          id: isExistQuorum ? undefined : selectedQuorumType?.id, // 본계정인 경우 본부 투심이 디폴트
          name: isExistQuorum ? undefined : selectedQuorumType?.name, // 본계정인 경우 본부 투심이 디폴트
        },
      },
      requiredTables: selectedFundAccount.tableForm,
    };
    return newAccount;
  };

  const createEvaluation = async () => {
    updateLoadingModal(true);

    const newDocument = createDocumentInit(reportFolderId);
    const newReportAccount = createAccount();
    const portfolioInfo = await getPortfolioInfo(portfolioId);

    if (portfolioInfo) {
      if (portfolioInfo.companyName)
        newDocument.investmentAssessmentCompanyInfo.companyName =
          portfolioInfo.companyName;

      if (portfolioInfo.ceoName)
        newDocument.investmentAssessmentCompanyInfo.ceoName =
          portfolioInfo.ceoName;

      if (portfolioInfo.addr1 && portfolioInfo.addr2) {
        newDocument.investmentAssessmentCompanyInfo.addr1 = portfolioInfo.addr1;
        newDocument.investmentAssessmentCompanyInfo.addr2 = portfolioInfo.addr2;
        newDocument.investmentAssessmentCompanyInfo.addr3 = portfolioInfo.addr3;
        newDocument.investmentAssessmentCompanyInfo.addrDetail =
          portfolioInfo.addrDetail;
      }

      if (portfolioInfo.businessNumber)
        newDocument.investmentAssessmentCompanyInfo.businessNumber =
          portfolioInfo.businessNumber;

      if (portfolioInfo.establishmentDay) {
        const establishDateYear = getNewDate(
          portfolioInfo.establishmentDay
        ).getFullYear();
        const nowYear = new Date().getFullYear();
        const newData = getRangeArray(establishDateYear, nowYear)
          .reverse()
          .slice(0, 3)
          .map((item, index): IAFinancialStatements2 => {
            return {
              year: item,
            };
          });

        newDocument.investmentAssessmentCompanyInfo.establishmentDay =
          portfolioInfo.establishmentDay;
        if (newDocument.investmentAssessmentFinancialStatement2) {
          newDocument.investmentAssessmentFinancialStatement2.data = newData;
        } else {
          newDocument.investmentAssessmentFinancialStatement2 = {
            isRequired: false,
            isChecked: false,
            data: newData,
          };
        }
      }
    }

    if (newReportAccount)
      newDocument.investmentAssessmentReportAccount.push(newReportAccount);

    const result = await registeEvaluation(dealflowId, newDocument);
    if (result.success && result.investmentAssessmentId !== undefined) {
      callbackData(result.investmentAssessmentId);
    }
    updateLoadingModal(false);
  };

  const validationCheckPage1 = () => {
    return (
      issuingPrice.length !== 0 &&
      postTotalStockNumber.length !== 0 &&
      previousTotalStockNumber.length !== 0 &&
      faceValue.length !== 0
    );
  };

  const validationCheckPage2 = () => {
    if (!selectedNewOldStockType) return false;
    if (!selectedFundAccount) return false;
    if (!selectedStockType) return false;
    if (isOriginAccount && !selectedQuorumType) return false;

    if (selectedNewOldStockType.newOldStockId === 1) {
      return (
        investmentAmount.length !== 0 &&
        stockNumber.length !== 0 &&
        shareholdingRatio.length !== 0
      );
    } else {
      return (
        investmentAmount.length !== 0 &&
        stockNumber.length !== 0 &&
        buyCompany.length !== 0 &&
        oldStockPurchasePrice.length !== 0 &&
        buyCompanyValue.length !== 0 &&
        curNewIssuingPrice.length !== 0 &&
        shareholdingRatio.length !== 0
      );
    }
  };

  const init = async () => {
    try {
      const result = await getPortfolFolder(portfolioId);
      updateReportFolderId(result.data?.reportFolderId || "");

      const fundAccount = await getActiveFundaccount();
      if (fundAccount) {
        updateFundAccountList(fundAccount);
        updateFundAccount(fundAccount[0]);
      }

      const allUsers = await getAllAcUser("user");
      updateAllAcUser(allUsers || []);

      const newOldStockList = await getNewOldStock();
      if (newOldStockList && newOldStockList.length > 0) {
        updateNewOldStockList(newOldStockList);
        updateNewOldStockType(newOldStockList[0]);
      }

      const dicResult = await getAllDictionary();
      updateStockTypeList([...(dicResult?.stockType || [])]);
      updateQuorumTypeList([...(dicResult?.quorumType || [])]);
      updateFundManagerList([...(dicResult?.fundManager || [])]);
    } catch (error) {}
  };

  const aaevent = (e: any) => {
    console.log(e);
  };

  useEffect(() => {
    init();

    window.addEventListener("focusin", aaevent);
    return () => {
      window.removeEventListener("focusin", aaevent);
    };
  }, []);

  useEffect(() => {}, []);

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          {curPage === 1 ? "라운드 정보 입력" : "투자 재원 정보 입력"}
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body>
        <PageWrapper
          ref={wrapperPageRef}
          style={{
            transform: `translateX(${curPage === 1 ? "0px" : "-100%"})`,
          }}
        >
          <PageContainer ref={pageRef}>
            <div className="heading-14-md">
              클럽딜의 경우 다른 투자사들의 투자 내용도 함께 포함해 값을 작성해
              주세요.
              <br />
              구주거래일 경우 액면가를 제외한 모든 입력란에 0을 입력해주세요.
            </div>
            <ColumnTitleBox
              textClassName="heading-14-md"
              title="신주 발행가 (A)"
              transfromPixel={transfromPixel}
            >
              <InputBox
                textType="numberWithComma"
                transfromPixel={transfromPixel}
                unit="원"
                placeholder="0"
                defaultValue={issuingPrice}
                onChange={(value) => {
                  updateIssuingPrice(value);
                }}
              />
            </ColumnTitleBox>
            <ColumnTitleBox
              textClassName="heading-14-md"
              title="기 발행 주식 수 (B)"
              transfromPixel={transfromPixel}
            >
              <InputBox
                textType="numberWithComma"
                transfromPixel={transfromPixel}
                unit="주"
                placeholder="0"
                defaultValue={previousTotalStockNumber}
                onChange={(value) => {
                  updatePreviousTotalStockNumber(value);
                }}
              />
            </ColumnTitleBox>
            <ColumnTitleBox
              textClassName="heading-14-md"
              title="총 발행 주식 수 (C)"
              transfromPixel={transfromPixel}
            >
              <InputBox
                textType="numberWithComma"
                transfromPixel={transfromPixel}
                unit="주"
                placeholder="0"
                defaultValue={postTotalStockNumber}
                onChange={(value) => {
                  updatePostTotalStockNumber(value);
                }}
              />
            </ColumnTitleBox>
            <ColumnTitleBox
              textClassName="heading-14-md"
              title="액면가 (D)"
              transfromPixel={transfromPixel}
            >
              <InputBox
                textType="numberWithComma"
                transfromPixel={transfromPixel}
                unit="원"
                placeholder="0"
                defaultValue={faceValue}
                onChange={(value) => {
                  updateFaceValue(value);
                }}
              />
            </ColumnTitleBox>
            <ColumnTitleBox
              textClassName="heading-14-md"
              title="투자 전 기업 가치 (A*B)"
              transfromPixel={transfromPixel}
            >
              <InputBox
                readOnly
                textType="numberWithComma"
                transfromPixel={transfromPixel}
                unit="원"
                placeholder="0"
                defaultValue={getPreviousComapnyValue()}
              />
            </ColumnTitleBox>

            <ColumnTitleBox
              textClassName="heading-14-md"
              title="투자 후 기업 가치 (A*(B+C))"
              transfromPixel={transfromPixel}
            >
              <InputBox
                readOnly
                textType="numberWithComma"
                transfromPixel={transfromPixel}
                unit="원"
                placeholder="0"
                defaultValue={getPostCompanyValue()}
              />
            </ColumnTitleBox>

            <ColumnTitleBox
              textClassName="heading-14-md"
              title="총 투자 금액 (A * C)"
              transfromPixel={transfromPixel}
            >
              <InputBox
                readOnly
                textType="numberWithComma"
                transfromPixel={transfromPixel}
                unit="원"
                placeholder="0"
                defaultValue={getTotalInvestmentAmount()}
              />
            </ColumnTitleBox>
          </PageContainer>
          {validationCheckPage1() && (
            <PageContainer>
              <ColumnTitleBox
                textClassName="heading-14-md"
                title="투자 재원"
                titleWidth={TITLE_WIDTH}
                transfromPixel={transfromPixel}
              >
                <SelectBox
                  editable
                  defaultValue={selectedFundAccount?.nameKo}
                  data={fundAccountList.map(
                    (item): SelectBoxData => ({
                      id: item.acFundAccountId,
                      text: item.nameKo,
                      data: item,
                    })
                  )}
                  onFocus={(e) => {
                    updatePage(2);
                  }}
                  onChange={(item) => {
                    console.log(item);
                    if (item.id === 1) {
                      updateQuorumType(undefined);
                    } else {
                      updateQuorumType({ number: item.data.quorum });
                    }

                    updateOriginAccount(item.id === 1);
                    updateFundAccount({ ...item.data });
                  }}
                />
              </ColumnTitleBox>
              <ColumnTitleBox
                textClassName="heading-14-md"
                title="주식 종류"
                titleWidth={TITLE_WIDTH}
                transfromPixel={transfromPixel}
              >
                <SelectBox
                  editable
                  defaultValue={selectedStockType?.name}
                  data={stockTypeList.map(
                    (item): SelectBoxData => ({
                      id: item.stockTypeId,
                      text: item.name,
                      data: item,
                    })
                  )}
                  onChange={(item) => {
                    console.log(item);
                    updateStockType({ ...item.data });
                  }}
                />
              </ColumnTitleBox>
              {isOriginAccount && (
                <ColumnTitleBox
                  textClassName="heading-14-md"
                  title="투자 프로세스"
                  titleWidth={TITLE_WIDTH}
                  transfromPixel={transfromPixel}
                >
                  <SelectBox
                    editable
                    defaultValue={selectedQuorumType?.name}
                    placeholder="선택하세요"
                    data={quorumTypeList.map(
                      (item): SelectBoxData => ({
                        id: item.id,
                        text: item.name,
                        data: item,
                      })
                    )}
                    onChange={(item) => {
                      console.log(item);
                      updateQuorumType({ ...item.data });
                    }}
                  />
                </ColumnTitleBox>
              )}
              <ColumnTitleBox
                textClassName="heading-14-md"
                title="신주/구주"
                titleWidth={TITLE_WIDTH}
                transfromPixel={transfromPixel}
              >
                <NewOldStockRadio
                  editable
                  data={newOldStockList.map(
                    (item): NewOldStockRadioProps => ({
                      id: item.newOldStockId,
                      text: item.name,
                      data: item,
                    })
                  )}
                  defaultValue={selectedNewOldStockType?.name}
                  onChange={(item) => {
                    updateNewOldStockType({ ...item.data });
                    updateInvestmentAmount(
                      item.id === 1
                        ? getOurInvestmentAmount()
                        : getOurPurchaseAmount()
                    );
                  }}
                />
              </ColumnTitleBox>

              {selectedNewOldStockType?.newOldStockId === 1 ? (
                <FlexColumn style={{ gap: 6 }}>
                  <ColumnTitleBox
                    textClassName="heading-14-md"
                    title="신주 배정 방식"
                    titleWidth={TITLE_WIDTH}
                    transfromPixel={transfromPixel}
                  >
                    <NewOldStockRadio
                      editable
                      data={[
                        { id: 1, text: "제 3자 방식", data: "제 3자 방식" },
                        { id: 2, text: "주주배정 방식", data: "주주배정 방식" },
                      ]}
                      defaultValue={allocationWay}
                      onChange={(item) => {
                        updateAllocationWay(item.text);
                      }}
                    />
                  </ColumnTitleBox>
                  <ColumnTitleBox
                    textClassName="heading-14-md"
                    title={
                      isOriginAccount
                        ? "당사 배정 주식 수"
                        : "당 조합 배정 주식 수"
                    }
                    titleWidth={TITLE_WIDTH}
                    transfromPixel={transfromPixel}
                  >
                    <InputBox
                      textType="numberWithComma"
                      transfromPixel={transfromPixel}
                      unit="주"
                      placeholder="0"
                      defaultValue={stockNumber}
                      onChange={(value) => {
                        updateStockNumber(value);
                        setShareholdingRatio(value);
                        if (issuingPrice.length > 0 && value.length > 0) {
                          const numIssuingPrice = parseInt(
                            RemoveComma(issuingPrice),
                            10
                          );
                          const numStockNumber = parseInt(
                            RemoveComma(value),
                            10
                          );
                          updateInvestmentAmount(
                            AddComma(numIssuingPrice * numStockNumber)
                          );
                        } else {
                          updateInvestmentAmount("");
                        }
                      }}
                    />
                  </ColumnTitleBox>
                  <FlexRow>
                    <ColumnTitleBox
                      textClassName="heading-14-md"
                      title="신주 발행가"
                      transfromPixel={transfromPixel}
                    >
                      <InputBox
                        readOnly
                        textType="numberWithComma"
                        transfromPixel={transfromPixel}
                        unit="원"
                        placeholder="0"
                        defaultValue={issuingPrice}
                      />
                    </ColumnTitleBox>
                    <ColumnTitleBox
                      textClassName="heading-14-md"
                      title="투자 후 지분율"
                      transfromPixel={transfromPixel}
                    >
                      <InputBox
                        readOnly
                        textType="percent"
                        transfromPixel={transfromPixel}
                        unit="%"
                        placeholder="0"
                        defaultValue={shareholdingRatio}
                      />
                    </ColumnTitleBox>
                  </FlexRow>
                  <FlexRow>
                    <ColumnTitleBox
                      textClassName="heading-14-md"
                      title={
                        isOriginAccount ? "당사 투자 금액" : "당 조합 투자 금액"
                      }
                      titleWidth={TITLE_WIDTH}
                      transfromPixel={transfromPixel}
                    >
                      <InputBox
                        readOnly={true}
                        textType="numberWithComma"
                        transfromPixel={transfromPixel}
                        unit="원"
                        placeholder="자동으로 계산됩니다."
                        defaultValue={investmentAmount}
                      />
                    </ColumnTitleBox>
                  </FlexRow>
                </FlexColumn>
              ) : (
                <FlexColumn style={{ gap: 6 }}>
                  <ColumnTitleBox
                    textClassName="heading-14-md"
                    title={
                      isOriginAccount
                        ? "당사 배정 주식 수"
                        : "당 조합 배정 주식 수"
                    }
                    transfromPixel={transfromPixel}
                  >
                    <InputBox
                      textType="numberWithComma"
                      transfromPixel={transfromPixel}
                      unit="주"
                      placeholder="0"
                      maxNum={convertNumber(previousTotalStockNumber)}
                      defaultValue={stockNumber}
                      onChange={(value) => {
                        updateStockNumber(value);
                        setShareholdingRatio(value);
                        if (
                          oldStockPurchasePrice.length > 0 &&
                          value.length > 0
                        ) {
                          const numOldStockPurchasePrice = parseInt(
                            RemoveComma(oldStockPurchasePrice),
                            10
                          );
                          const numStockNumber = parseInt(
                            RemoveComma(value),
                            10
                          );
                          updateInvestmentAmount(
                            AddComma(numOldStockPurchasePrice * numStockNumber)
                          );
                        } else {
                          updateInvestmentAmount("");
                        }
                      }}
                    />
                  </ColumnTitleBox>
                  <ColumnTitleBox
                    textClassName="heading-14-md"
                    title="최근 신주 발행가"
                    transfromPixel={transfromPixel}
                  >
                    <InputBox
                      textType="numberWithComma"
                      transfromPixel={transfromPixel}
                      unit="원"
                      placeholder="0"
                      defaultValue={curNewIssuingPrice}
                      onChange={(value) => {
                        updateCurNewIssuingPrice(value);
                      }}
                    />
                  </ColumnTitleBox>
                  <ColumnTitleBox
                    textClassName="heading-14-md"
                    title="구주매입가"
                    transfromPixel={transfromPixel}
                  >
                    <InputBox
                      textType="numberWithComma"
                      transfromPixel={transfromPixel}
                      unit="원"
                      placeholder="0"
                      defaultValue={oldStockPurchasePrice}
                      onChange={(value) => {
                        updateOldStockPurchasePrice(value);
                        setBuycompanayValue(value);
                        if (stockNumber.length > 0 && value.length > 0) {
                          const numOldStockPurchasePrice = parseInt(
                            RemoveComma(value),
                            10
                          );
                          const numStockNumber = parseInt(
                            RemoveComma(stockNumber),
                            10
                          );
                          updateInvestmentAmount(
                            AddComma(numOldStockPurchasePrice * numStockNumber)
                          );
                        } else {
                          updateInvestmentAmount("");
                        }
                      }}
                    />
                  </ColumnTitleBox>
                  <ColumnTitleBox
                    textClassName="heading-14-md"
                    title="매입처"
                    transfromPixel={transfromPixel}
                  >
                    <InputBox
                      textType="text"
                      transfromPixel={transfromPixel}
                      placeholder="매입처를 입력하세요."
                      defaultValue={buyCompany}
                      onChange={(value) => {
                        updateBuyCompany(value);
                      }}
                    />
                  </ColumnTitleBox>

                  <FlexRow>
                    <ColumnTitleBox
                      textClassName="heading-14-md"
                      title="투자 후 지분율"
                      transfromPixel={transfromPixel}
                    >
                      <InputBox
                        readOnly
                        textType="percent"
                        transfromPixel={transfromPixel}
                        unit="%"
                        placeholder="0"
                        defaultValue={shareholdingRatio}
                      />
                    </ColumnTitleBox>
                    <ColumnTitleBox
                      textClassName="heading-14-md"
                      title={
                        isOriginAccount ? "당사 거래 금액" : "당 조합 거래 금액"
                      }
                      transfromPixel={transfromPixel}
                    >
                      <InputBox
                        readOnly={true}
                        textType="numberWithComma"
                        transfromPixel={transfromPixel}
                        unit="원"
                        placeholder="자동으로 계산됩니다."
                        defaultValue={investmentAmount}
                      />
                    </ColumnTitleBox>
                  </FlexRow>
                  <ColumnTitleBox
                    textClassName="heading-14-md"
                    title="구주 매입가 기준 기업 가치"
                    titleWidth={TITLE_WIDTH}
                    transfromPixel={transfromPixel}
                  >
                    <InputBox
                      readOnly
                      textType="numberWithComma"
                      transfromPixel={transfromPixel}
                      unit="원"
                      placeholder="0"
                      defaultValue={buyCompanyValue}
                    />
                  </ColumnTitleBox>
                </FlexColumn>
              )}
            </PageContainer>
          )}
        </PageWrapper>
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={curPage === 1 ? "취소" : "이전"}
            onClick={() => {
              if (curPage === 1) {
                close();
              } else {
                updatePage(1);
              }
            }}
            transfromPixel={transfromPixel}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            disabled={
              curPage === 1 ? !validationCheckPage1() : !validationCheckPage2()
            }
            text={curPage === 1 ? "다음" : "완료"}
            onClick={() => {
              if (curPage === 1) {
                updatePage(2);
              } else {
                createEvaluation();
              }
            }}
            transfromPixel={props.transfromPixel}
          />
        </ButtonContainer>
      </Footer>
      {loadingModal && <SpinnerModal open={loadingModal} />}
    </Container>
  );
};

const Container = styled.div`
  width: 90vw;
  height: 90vh;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: all 0.2s ease;
`;

const PageContainer = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  gap: 6px;
  overflow: scroll;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  min-height: 72px;
  max-height: 72px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 24px 0px 0px;
  gap: 12px;
`;

const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export default EvaluationOpenModal;
