import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FundUser } from "../interface/FundAccount.interface";
import { FundUserCard } from "./FundUserCard";

interface Props {
  type: "single" | "multi";
  userList: FundUser[];
  selectedUser?: FundUser[];
  onClickUser: (user: FundUser) => void;
  onDeleteUser: (user: FundUser) => void;
}

export const FunduserSelector: React.FC<Props> = (props) => {
  const { type, userList, selectedUser, onClickUser, onDeleteUser } = props;
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, updateValue] = useState<string>("");
  const [focussing, updateFocussing] = useState<boolean>(false);

  const handleMouseDown = (event: any) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      updateFocussing(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <Container>
      <InputContainer ref={searchRef}>
        <InputComponent
          ref={inputRef}
          className={`body-14-md`}
          type="text"
          placeholder={"이름을 검색해주세요."}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              const filterUser = userList.filter(
                (item) =>
                  item.name.includes(value) &&
                  !selectedUser
                    ?.map((user) => user.acUserId)
                    .includes(item.acUserId)
              );
              if (filterUser.length === 1) {
                if (type === "single") {
                  inputRef.current?.blur();
                  updateFocussing(false);
                }
                onClickUser(filterUser[0]);
                updateValue("");
              }
            }
          }}
          onChange={(e) => {
            const data = e.target.value;
            updateValue(data);
          }}
          onFocus={(e) => {
            updateFocussing(true);
          }}
          value={value}
        />
        {focussing && (
          <SearchUserList>
            {userList
              .filter(
                (item) =>
                  item.name.includes(value) &&
                  !selectedUser
                    ?.map((user) => user.acUserId)
                    .includes(item.acUserId)
              )
              .map((item, index) => {
                return (
                  <SearchUserItem
                    key={index}
                    className="body-14-rg"
                    onClick={() => {
                      console.log(item);
                      onClickUser(item);
                      updateValue("");
                      updateFocussing(false);
                    }}
                  >
                    {item.name}
                  </SearchUserItem>
                );
              })}
            {userList.filter(
              (item) =>
                item.name.includes(value) &&
                !selectedUser
                  ?.map((user) => user.acUserId)
                  .includes(item.acUserId)
            ).length === 0 && (
              <div
                className="body-14-sb"
                style={{ color: "var(--text-04)", textAlign: "center" }}
              >
                검색 결과가 없습니다.
              </div>
            )}
          </SearchUserList>
        )}
      </InputContainer>
      <SelectUserList>
        {selectedUser?.map((item, index) => (
          <FundUserCard key={index} user={item} deleteUser={onDeleteUser} />
        ))}
      </SelectUserList>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--background-contents01);
`;

const InputContainer = styled.div`
  position: relative;
  width: 332px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
`;
const InputComponent = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  transition: 0.2s;
  padding: 6px 12px;
  background: var(--background-contents01);

  ::placeholder {
    color: var(--text-inactive);
  }

  :read-only {
    padding: 6px 0px;
    border: 1px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  :disabled {
    border: 1px solid transparent;
    background-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &.show__msg {
    border: 1px solid var(--red-500);
    animation: shake 200ms;

    :focus {
      border: 1px solid var(--red-500);
    }

    :hover {
      border-color: var(--red-500);
    }
  }

  :focus {
    outline: none;
    border-color: var(--focus-field-blue);

    :hover {
      background-color: var(--background-contents01);
    }
  }

  :hover {
    border-color: var(--focus-field-blue);
  }

  @keyframes shake {
    25% {
      transform: translateX(2px);
    }
    50% {
      transform: translateX(-2px);
    }
    75% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(-2px);
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const SearchUserList = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 200px;
  display: flex;
  flex-direction: column;

  overflow: scroll;

  top: 50px;
  left: 0;
  z-index: 1;

  border: 1px solid var(--gray-300);
  background-color: var(--white);
  padding-top: 8px;
  padding-bottom: 8px;
`;

const SearchUserItem = styled.div`
  width: 100%;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;

  transition: background-color 0.1s ease;

  cursor: pointer;
  :hover {
    background-color: var(--purple-100);
  }
`;

const SelectUserList = styled.div`
  width: 100%;
  height: auto;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 12px;
`;
