import React, { createRef, useEffect, useRef, useState } from "react";
import Styled, { css } from "styled-components";

import {
  ChannelAdminProps,
  ChannelProps,
} from "../interface/Channel.interface";
import { DefaultButton } from "lattice_core";
import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import { showToast } from "../../../../../common/commonUtil";
import { rootStyle } from "../../../../../style/ThemeStyles";
import { Bounce } from "react-toastify";

interface Props {
  channel: ChannelProps;
  selectedChannel?: ChannelAdminProps;
  cancel: () => void;
  resultChannel: (newChannel: ChannelAdminProps, isAddChannel: boolean) => void;
}
export const ChannelDetailModal: React.FC<Props> = (props) => {
  const [value, updateValue] = useState<string>("");

  const getNewLastId = () => {
    return (
      props.channel.activeChannel.length + props.channel.deactiveChannel.length
    );
  };

  const duplicateCheck = () => {
    let duplicated = false;
    duplicated =
      props.channel.activeChannel.filter((item) => {
        const compareA = item.name.split(" ").join("").toUpperCase();
        const compareB = value.split(" ").join("").toUpperCase();

        return compareA === compareB;
      }).length > 0 ||
      props.channel.deactiveChannel.filter((item) => {
        const compareA = item.name.split(" ").join("").toUpperCase();
        const compareB = value.split(" ").join("").toUpperCase();

        return compareA === compareB;
      }).length > 0;

    return duplicated;
  };

  const onSubmit = () => {
    if (duplicateCheck()) {
      showToast({
        customElement: (
          <span className="heading-16-sb">채널 이름이 중복 됩니다.</span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--red-500"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,

        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
    } else {
      const newData: ChannelAdminProps = props.selectedChannel
        ? { ...props.selectedChannel, name: value, isChange: true }
        : {
            isNew: true,
            isChange: false,
            isActive: true,
            name: value,
            acChannelId: `new__channel_${getNewLastId()}`,
          };
      props.resultChannel(newData, props.selectedChannel === undefined);
    }
  };

  useEffect(() => {
    if (props.selectedChannel) {
      updateValue(props.selectedChannel.name);
    }
  }, [props.selectedChannel]);
  return (
    <Container>
      <Header className="heading-20-sb">
        {props.selectedChannel ? "채널 정보" : "채널 생성"}
      </Header>
      <BodyContainer>
        <div className="body-14-md" style={{ color: "var(--text-04)" }}>
          채널
        </div>
        <InputBox
          placeholder="채널 이름을 입력해주세요."
          defaultValue={value}
          onChange={(text) => updateValue(text)}
        />
      </BodyContainer>
      <Footer>
        <DefaultButton
          className="heading-16-md"
          sizeType="small"
          buttonType="text"
          buttonColor="var(--text-01)"
          onClick={props.cancel}
          text="취소"
        />

        <DefaultButton
          id="add-keyword-button"
          className="heading-16-md"
          sizeType="small"
          buttonType="filled"
          buttonColor="var(--primary-purple)"
          hoverColor="var(--purple-700)"
          disabled={value.length === 0}
          onClick={onSubmit}
          text={props.selectedChannel ? "수정하기" : "추가하기"}
        />
      </Footer>
    </Container>
  );
};

const Container = Styled.div(
  () => css`
    position: relative;
    width: 480px;
    height: 260px;
    display: flex;
    flex-direction: column;

    background: #ffffff;
    box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    button {
      cursor: pointer;
    }
    .submit {
      min-width: 120px;
      padding: 8px 14px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      border: 0px;

      background-color: var(--primary);
      color: var(--white);
    }
    .cancel {
      min-width: 120px;
      padding: 8px 14px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      border: 0px;

      background-color: var(--white);
      color: var(--grey_50);
    }
  `
);

const Header = Styled.div(
  () => css`
    width: 100%;
    height: 56px;
    min-height: 56px;
    max-height: 56px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
  `
);

const BodyContainer = Styled.div(
  () => css`
    width: 100%;
    flex-grow: 1;
    padding: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `
);

const Footer = Styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    padding-right: 24px;
    width: 100%;
    height: 74px;
    min-height: 74px;
    max-height: 74px;
  `
);
