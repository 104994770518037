import React from "react";
import styled from "styled-components";
import UserCircle, {
  CircleUserProfileProps,
} from "../../../../../components-2.0v/user-item/UserCircle";
import { PortfolioCommentProps } from "../../interface/PortfolioInfo.interface";
import { getYMDKo } from "../../../../../common/commonUtil";
import { CommentEditor } from "../../desk/components/CommentEditor";
import { DefaultButton } from "lattice_core";
import { LoginInfo } from "../../../../../type/data";

interface Props {
  myInfo: LoginInfo;
  comment: PortfolioCommentProps;
  updateComment: (pfCommentId: number, data: string) => void;
  cancelUpdate: () => void;
}
export const CommentViewerEditor: React.FC<Props> = (props) => {
  const { myInfo, comment, updateComment, cancelUpdate } = props;
  const userInfo: CircleUserProfileProps = {
    profileImg: comment.acUser.profileImgUrl,
    userName: comment.acUser.name,
  };
  return (
    <Container>
      <UserCircle userInfo={userInfo} width={48} />
      <CommentContainer>
        <FlexRow className="body-14-md">
          <FlexRow style={{ width: undefined, flex: "1 0 0" }}>
            <span style={{ color: "var(--text-01, #1F2022)" }}>
              {comment.acUser.name}
            </span>
            <span style={{ color: "var(--text-05, #96999F)" }}>
              님이 {getYMDKo(comment.createdAt)}에 작성
              {comment.createdAt !== comment.updatedAt ? "(수정됨)" : ""}
            </span>
          </FlexRow>
          <div>
            <DefaultButton
              className="caption-12-md"
              buttonType="colored-outline"
              sizeType="xxsmall"
              buttonColor={"#1F2022"}
              borderColor={"#D5D6D8"}
              text="취소"
              onClick={(e) => {
                e && e.stopPropagation();
                cancelUpdate();
              }}
            />
          </div>
        </FlexRow>
        <CommentEditor
          defaultData={comment.content}
          maxHeight={200}
          onSubmit={(data) => {
            updateComment(comment.id, data);
          }}
        />
      </CommentContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const CommentContainer = styled.div`
  flex: 1 0 0;
  height: auto;
  display: flex;
  flex-direction: column;

  gap: 6px;
`;

const Comment = styled.pre`
  width: 100%;
  height: auto;
  min-height: 40px;

  word-break: break-all;
  white-space: break-spaces;

  color: var(--text-01, #1f2022);
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
