import React, { useState } from "react";
import styled, { css } from "styled-components";

import { DefaultButton } from "lattice_core";
import { UserInfo } from "../../../../../type/data";
import DefaultUserProfile from "../../../../../assets/image/default_user_profile.svg";
import { ReactComponent as DeleteIcon } from "../../../../../2.0lattice/assets/image/new_icon_delete.svg";
import { ReactComponent as Check } from "../../../../../assets/image/ic_check_round.svg";
import { ReactComponent as Checked } from "../../../../../assets/image/ic_checked_round.svg";

interface Props {
  allUser?: UserInfo[];
  onMoveStructure: (users: UserInfo[]) => void;
}

export const MoveEmployeeModal: React.FC<Props> = (props) => {
  const { allUser = [], onMoveStructure } = props;
  const [selectedUser, setSelectedUser] = useState<UserInfo[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [focurs, updateFocurs] = useState<boolean>(false);

  const onClickUser = (user: UserInfo) => {
    const index = selectedUser.findIndex((item) => item.id === user.id);
    if (index !== -1) {
      selectedUser.splice(index, 1);
      setSelectedUser([...selectedUser]);
    } else {
      setSelectedUser((prev) => [user, ...prev]);
    }
  };

  const SelectedUserComponent = (item: UserInfo, key: string) => {
    return (
      <SelectedUserItemContainer
        key={key}
        onClick={() => {
          onClickUser(item);
        }}
      >
        <ProfileImageContainer src={item.profileImgUrl || DefaultUserProfile} />
        <div className="caption-10-rg selected__user__name">{item.name}</div>
      </SelectedUserItemContainer>
    );
  };
  const UserComponent = (item: UserInfo, key: string) => {
    return (
      <CardContainer
        key={key}
        onClick={() => {
          onClickUser(item);
        }}
      >
        <FlexRow style={{ alignItems: "center", gap: 12 }}>
          <ProfileImageContainer
            src={item.profileImgUrl || DefaultUserProfile}
          />
          <div className="body-14-rg">{item.name}</div>
        </FlexRow>
        {selectedUser.map((selected) => selected.id).includes(item.id) ? (
          <Checked width={16} height={16} />
        ) : (
          <Check width={16} height={16} />
        )}
      </CardContainer>
    );
  };

  return (
    <Container>
      <Header>
        <span className="heading-18-sb">직원 이동</span>
        <DefaultButton
          className="heading-14-sb"
          sizeType="xxsmall"
          buttonColor={"var(--primary-purple)"}
          hoverColor={"var(--purple-700)"}
          disabled={selectedUser.length === 0}
          onClick={() => {
            onMoveStructure(selectedUser);
          }}
          text="현재 조직으로 이동"
        />
      </Header>
      <SelectedUserContainer
        className={`${selectedUser.length > 0 ? "show" : ""}`}
      >
        {selectedUser.map((item, index) => {
          return SelectedUserComponent(item, `selected_user_${index}`);
        })}
      </SelectedUserContainer>

      <Contents>
        <SearchContainer className={`${focurs ? "focursed" : ""}`}>
          <input
            className="search__input body-14-rg"
            type={"text"}
            placeholder={"이름 검색"}
            value={searchKeyword}
            onChange={(e) => {
              setSearchKeyword(e.target.value);
            }}
            onFocus={() => updateFocurs(true)}
            onBlur={() => updateFocurs(false)}
          />
          {searchKeyword.length > 0 && (
            <DeleteIcon
              width={16}
              height={16}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSearchKeyword("");
              }}
            />
          )}
        </SearchContainer>
        <FlexColumn>
          {allUser
            .filter(
              (item) =>
                item.acStructureId &&
                item.employmentStatusId &&
                [2, 3].includes(item.employmentStatusId)
            )
            .filter((item) =>
              item.name.toUpperCase().includes(searchKeyword.toUpperCase())
            )
            .map((item, index) => {
              return UserComponent(item, `user_${index}`);
            })}
        </FlexColumn>
      </Contents>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div(
  () => css`
    width: 100%;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    border-bottom: 1px solid var(--gray-300);

    .warnning {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #ffc800;
    }
  `
);

const SelectedUserContainer = styled.div`
  width: 100%;
  height: 0px;
  min-height: 0px;
  max-height: 0px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  padding-left: 24px;
  padding-right: 24px;

  gap: 8px;

  overflow: scroll;

  transition: all 0.2s ease;

  &.show {
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const Contents = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  gap: 14px;

  padding: 12px 24px;

  .group {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    .single__item {
      flex: 1;
    }
    .double__item {
      flex: 2;
    }
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProfileImageContainer = styled.img`
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 50%;
  border: 1px solid var(--gray-200);
`;

const SelectedUserItemContainer = styled.div`
  width: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  .selected__user__name {
    width: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const SearchContainer = styled.div`
  width: 100%;
  height: 36px;
  min-height: 36px;

  display: flex;
  flex-direction: row;
  align-items: center;

  /* light/background-contents01 */
  background: #ffffff;
  /* light/gray200 */
  border: 1px solid #edeeef;
  border-radius: 4px;

  padding: 0 10px;

  transition: all 0.2s ease;
  overflow: hidden;

  .search__input {
    flex-grow: 1;
    border: none;
    color: #1f2022; // text01
    ::placeholder {
      color: #BEC0C2//text06;
    }
    :focus {
      outline: none;
    }
  }

  &.focursed {
    border: 1px solid #a2c7ff;
  }
`;
