import { log } from "console";
import { DefaultButton } from "lattice_core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BtnScale from "../BtnScale";
import ValidationItem from "../ValidationItem";
import { ReactComponent as CloseBtn } from "../../../../../../assets/image/new_close_button.svg";
import useDND from "../useDND";

import {
  RealDataProps,
  StockChangeHistoryRealData,
} from "../interface/Stock.interface";

import {
  InvestmentRealDataResult,
  makeStockHolderList,
} from "../service/StockHolderService";
import StockHoldeList from "../StockHoldeList";
import { StockHolder } from "../interface/StockHolder.interface";

import { TableVirtuoso, VirtuosoHandle } from "react-virtuoso";
import RealDataHeader, { RealDataTableHeaderProps } from "./RealDataHeader";

import RealDataRow from "./RealDataRow";
import useMakeErrorReport from "./useMakeErrorReport";
import useResize from "../../../../../../hooks/useResize";

interface Props {
  data: StockChangeHistoryRealData[];
  screenRate: number;
  changeFile: () => void;
  dndFileChange: (e: any) => void;
  cancel?: () => void;
  transfromPixel: (value: number) => number;
}

export interface StockChangeErrorReport {
  groupId: number;
  step: "validation" | "makeStockHolderList";
  msg: string;
  errorType: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  xPosition: number;
  yPosition: number;
  rangeX: { sX: number; eX: number };
  rangeY: { sY: number; eY: number };
}

const StockChangeHistoryTableModal: React.FC<Props> = (props) => {
  const headers: RealDataTableHeaderProps[] = [
    {
      key: "date",
      headerTitle: "일자",
      width: 148,
      type: "text",
    },
    {
      key: "stockChangeType",
      headerTitle: "변동내역",
      width: 148,
      type: "text",
    },
    {
      key: "stageType",
      headerTitle: "스테이지",
      width: 148,
      type: "text",
    },
    {
      key: "stockholderName",
      headerTitle: "주주명",
      width: 274,
      type: "text",
    },
    {
      key: "registrationNumber",
      headerTitle: "사업자/주민등록번호",
      width: 164,
      type: "text",
    },
    {
      key: "relationship",
      headerTitle: "관계",
      width: 192,
      type: "text",
    },
    {
      key: "country",
      headerTitle: "국적",
      width: 148,
      type: "text",
    },
    {
      key: "stockType",
      headerTitle: "주식종류",
      width: 148,
      type: "text",
    },
    {
      key: "fluctuateStockNumber",
      headerTitle: "변동주식수",
      width: 148,
      type: "number",
    },
    {
      key: "faceValue",
      headerTitle: "액면가",
      width: 78,
      type: "number",
    },
    {
      key: "issuingPrice",
      headerTitle: "주당발행가액",
      width: 218,
      type: "number",
    },
    {
      key: "totalInvestmentAmount",
      headerTitle: "총거래금액",
      width: 148,
      type: "number",
    },
    {
      key: "seller",
      headerTitle: "판매자",
      width: 274,
      type: "text",
    },
    {
      key: "sellerRegistrationNumber",
      headerTitle: "판매자 사업자/주민등록번호",
      width: 214,
      type: "text",
    },
    {
      key: "note",
      headerTitle: "비고",
      width: 380,
      type: "text",
    },
  ];
  const isRendering = useRef<boolean>(false);

  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const tableRef = useRef<HTMLDivElement>(null);
  const virtuoso = useRef<VirtuosoHandle>(null);
  const timer = useRef<NodeJS.Timeout | undefined>(undefined);
  const [scrollStart, setScrollStart] = useState<boolean>(true);
  const [scrollEnd, setScrollEnd] = useState<boolean>(false);

  const showViewerScaleTimerId = useRef<any>();
  const [showViewerScale, updateShowViewerScale] = useState<boolean>(false);
  const [viewerScale, updateViewerScale] = useState<number>(1);

  const stockHolderListModalRef = useRef<HTMLDivElement>(null);
  const stockHolderListModalData = useRef<StockHolder[] | undefined>(undefined);
  const [stockChangeHistoryData, updateStockChangeHistoryData] = useState<
    StockChangeHistoryRealData[]
  >([]);
  const [stockHolderListModal, updateStockHolderListModal] =
    useState<boolean>(false);

  const [stockHolderListHeight, updateStockHolderListHeight] =
    useState<number>(0);
  const [isLoading, updateLoading] = useState<boolean>(false);

  const [exitModal, setExitModal] = useState<boolean>(false);

  const [errorReport, updateErrorReport] = useState<StockChangeErrorReport[]>(
    []
  );
  const errorMap = useRef<any[]>([]);
  const [completMakeStockHolderList, updateCompletMakeStockHolderList] =
    useState<InvestmentRealDataResult | undefined>(undefined);
  const [hoverReport, updateHoverReport] = useState<
    StockChangeErrorReport | undefined
  >(undefined);
  const [selectReport, updateSelectReport] = useState<
    StockChangeErrorReport | undefined
  >(undefined);

  const [makeErrorMap, makeErrorReport] = useMakeErrorReport();
  const [isDragging, dragRef] = useDND(props.dndFileChange);

  const [screenRate, transfromPixel] = useResize();

  const initStockChangeHistory = () => {
    updateSelectReport(undefined);
    updateSelectReport(undefined);
    updateCompletMakeStockHolderList(undefined);
  };

  const getRealData = () => {
    const data = stockChangeHistoryData.map((item) => {
      const rowData = item.data;
      const realData: RealDataProps = {
        date: rowData.date.data,
        stockChangeType: rowData.stockChangeType.data,
        stageType: rowData.stageType.data,
        stockholderName: rowData.stockholderName.data,
        registrationNumber: rowData.registrationNumber.data,
        relationship: rowData.relationship.data,
        country: rowData.country.data,
        stockType: rowData.stockType.data,
        fluctuateStockNumber: parseFloat(rowData.fluctuateStockNumber.data),
        faceValue: parseFloat(rowData.faceValue.data),
        issuingPrice: parseFloat(rowData.issuingPrice.data),
        totalInvestmentAmount: parseFloat(rowData.totalInvestmentAmount.data),
        seller: rowData.seller ? rowData.seller.data : undefined,
        sellerRegistrationNumber: rowData.sellerRegistrationNumber
          ? rowData.sellerRegistrationNumber.data
          : undefined,
        note: rowData.note ? rowData.note.data : undefined,
      };
      return realData;
    });
    console.log(data);
    return data;
  };

  useEffect(() => {
    console.log(props.data);
    if (props.data.length === 0) return;
    updateStockChangeHistoryData([...props.data]);
    isRendering.current = false;
    updateLoading(false);
  }, [props.data]);

  useEffect(() => {
    console.log(stockChangeHistoryData);
    if (stockChangeHistoryData.length === 0) return;
    if (isRendering.current) return;
    console.log("adsgasdga");
    initStockChangeHistory();

    errorMap.current = makeErrorMap(stockChangeHistoryData);
    const errorReportData: StockChangeErrorReport[] = makeErrorReport(
      errorMap.current
    );

    if (errorReportData.length === 0) {
      // 2단계
      const result = makeStockHolderList(getRealData());
      updateCompletMakeStockHolderList(result);

      if (!result.result && result.errIndex && result.errType) {
        errorReportData.push({
          groupId: errorReportData.length,
          step: "makeStockHolderList",
          msg: result.errMsg || "",
          errorType: result.errType,
          xPosition: 0,
          yPosition: 34 + result.errIndex * 50,
          rangeX: {
            sX: 0,
            eX: 13,
          },
          rangeY: {
            sY: result.errIndex,
            eY: result.errIndex,
          },
        });
      }
    }
    updateErrorReport([...errorReportData]);
    isRendering.current = true;
    updateLoading(true);
  }, [stockChangeHistoryData]);

  const handleMouseDown = (event: any) => {
    if (
      stockHolderListModalRef.current &&
      !stockHolderListModalRef.current.contains(event.target)
    ) {
      updateStockHolderListModal(false);
    }
  };

  useEffect(() => {
    if (stockHolderListModalRef.current) {
      updateStockHolderListHeight(
        stockHolderListModalRef.current.clientHeight - 98
      );
    }
    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.addEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <Container>
      <ModalHeader>
        <span className="heading-18-sb title">유효성 확인</span>
        <div className="close__btn" onClick={props.cancel}>
          <CloseBtn width={24} height={24} />
        </div>
      </ModalHeader>
      <ModalBody>
        <StockHolderTableContainer ref={dragRef}>
          <div
            ref={tableRef}
            style={{
              position: "relative",
              width: `100%`,
              maxWidth: `calc(100%  - 38px)`,
              height: "100%",
              maxHeight: `calc(100%  - 38px)`,
              margin: `28px`,
            }}
          >
            <TableVirtuoso
              ref={virtuoso}
              className={`${isScrolling ? "on-scrollbar" : "no-scrollbar"}`}
              style={{
                width: `100%`,
                height: `100%`,
              }}
              onScroll={(e) => {
                setIsScrolling(true);
                setScrollStart(e.currentTarget.scrollLeft === 0);
                setScrollEnd(
                  e.currentTarget.scrollLeft ===
                    e.currentTarget.scrollWidth - e.currentTarget.clientWidth
                );
              }}
              data={props.data}
              fixedHeaderContent={() => {
                return (
                  <RealDataHeader
                    headers={headers}
                    transfromPixel={transfromPixel}
                    viewerScale={viewerScale}
                  />
                );
              }}
              itemContent={(index, data) => {
                return (
                  <RealDataRow
                    key={index}
                    index={index}
                    viewerScale={viewerScale}
                    completMakeStockHolderList={completMakeStockHolderList}
                    selectedErrorReport={selectReport || hoverReport}
                    headers={headers}
                    rowData={data}
                    transfromPixel={transfromPixel}
                    onClickItem={(x, y) => {
                      const report = errorReport.find(
                        (item) =>
                          item.rangeX.sX <= x &&
                          item.rangeX.eX >= x &&
                          item.rangeY.sY <= y &&
                          item.rangeY.eY >= y
                      );
                      updateHoverReport(undefined);
                      updateSelectReport(report);
                    }}
                    onHoverItem={(x, y) => {
                      const report = errorReport.find(
                        (item) =>
                          item.rangeX.sX <= x &&
                          item.rangeX.eX >= x &&
                          item.rangeY.sY <= y &&
                          item.rangeY.eY >= y
                      );
                      updateHoverReport(report);
                    }}
                  />
                );
              }}
            />
            {!scrollStart && <ShadowLeft />}
            {!scrollEnd && (
              <ShadowRight style={{ right: transfromPixel(18) }} />
            )}
          </div>
          <div
            className={`scale__rate ${showViewerScale ? "show" : ""}`}
            style={{ top: 60, right: 60, zIndex: 1000 }}
          >{`${(viewerScale * 100).toFixed(0)}%`}</div>
          <div className="floatingBtn">
            <BtnScale
              type="decrease"
              disabled={viewerScale < 0.8}
              onClick={() => {
                updateShowViewerScale(true);
                updateViewerScale((prev) => prev - 0.1);
                clearTimeout(showViewerScaleTimerId.current);
                showViewerScaleTimerId.current = setTimeout(() => {
                  updateShowViewerScale(false);
                }, 3000);
              }}
            />

            <BtnScale
              type="increase"
              disabled={viewerScale > 1.3}
              onClick={() => {
                updateShowViewerScale(true);
                updateViewerScale((prev) => prev + 0.1);
                clearTimeout(showViewerScaleTimerId.current);
                showViewerScaleTimerId.current = setTimeout(() => {
                  updateShowViewerScale(false);
                }, 3000);
              }}
            />
          </div>
          {isDragging && <div className="file__upload" />}
        </StockHolderTableContainer>
        <ValidationAreaContainer
          onClick={() => {
            updateHoverReport(undefined);
            updateSelectReport(undefined);
          }}
        >
          <ValidationItem
            text="이 단계에서는 데이터의 형식과 정확한 데이터를 입력했는지 확인함"
            isSelected={false}
          />
          <div
            className="heading-18-sb"
            style={{ marginTop: 32, color: "#1F2022" }}
          >
            유효성 가이드
          </div>
          <ValidationList>
            {errorReport.map((item) => {
              return (
                <ValidationItem
                  step={item.step}
                  text={item.msg}
                  isSelected={item === selectReport || item === hoverReport}
                  onMouseEnter={() => {
                    console.log("mousein");
                    if (selectReport) return;

                    if (virtuoso.current) {
                      virtuoso.current.scrollTo({
                        left: (item.xPosition - 100) * viewerScale,
                        top: (item.yPosition - 34) * viewerScale,
                        behavior: "smooth",
                      });
                    }
                    updateHoverReport(item);
                  }}
                  onMouseLeave={() => {
                    updateHoverReport(undefined);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (selectReport === item) {
                      updateSelectReport(undefined);
                    } else {
                      if (virtuoso.current) {
                        virtuoso.current.scrollTo({
                          left: (item.xPosition - 100) * viewerScale,
                          top: (item.yPosition - 34) * viewerScale,
                          behavior: "smooth",
                        });
                      }
                      updateSelectReport(item);
                      updateHoverReport(undefined);
                    }
                  }}
                  onClickStockHolder={() => {
                    const sliceData = getRealData().slice(
                      0,
                      completMakeStockHolderList?.errIndex
                    );
                    console.log(sliceData);
                    stockHolderListModalData.current =
                      makeStockHolderList(sliceData).data?.stockHolderList;
                    updateStockHolderListModal(true);
                  }}
                />
              );
            })}
          </ValidationList>
          <ButtonContainer>
            <DefaultButton
              className="heading-16-md"
              buttonType="filled"
              sizeType="small"
              text="파일교체"
              buttonColor="#1F2022"
              hoverColor="#36383E"
              onClick={() => {
                props.changeFile();
                isRendering.current = false;
              }}
            />
          </ButtonContainer>
        </ValidationAreaContainer>
      </ModalBody>
      <RightModal
        ref={stockHolderListModalRef}
        className={stockHolderListModal ? "right__modal__show" : ""}
      >
        <div className="body-14-md right__modal__description">
          <span style={{ fontWeight: 700 }}>
            {completMakeStockHolderList?.errIndex}행
          </span>
          을 기준으로 하는 주주명부입니다. 확인 후 하단의 주식거래내역을 확인해
          주세요.
        </div>
        <StockHoldeList
          data={stockHolderListModalData.current || []}
          gridHeight={stockHolderListHeight}
          hilightIndex={completMakeStockHolderList?.errorStockHolderIndex}
        />
      </RightModal>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const ModalHeader = styled.div`
  width: 100%;
  height: 66px;
  min-height: 66px;
  max-height: 66px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 26px 12px 24px;
  border-bottom: 1px solid #f3f4f4;

  .title {
    color: #1f2022;
  }

  .close__btn {
    margin-bottom: 10px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      background: #f3f4f4;
      border-radius: 4px;
    }
  }
`;
const ModalBody = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const StockHolderTableContainer = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;

  .scale__rate {
    position: absolute;
    display: none;
    top: 28px;
    right: 28px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    color: #ffffff;

    &.show {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .floatingBtn {
    position: absolute;
    bottom: 28px;
    right: 28px;
    display: none;
    flex-direction: row;
    gap: 6px;
    z-index: 1000;
  }

  .file__upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f6faff;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%234584FFFF' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;
    z-index: 10;
  }

  :hover {
    .floatingBtn {
      display: flex;
    }
  }
`;

const RightModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1000px;
  height: 100%;

  padding: 44px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: -2px 2px 10px rgba(100, 103, 109, 0.3);
  overflow: scroll;
  transform: translateX(100%);
  transition: all 0.2s ease;
  z-index: 2000;

  .right__modal__description {
    color: #1f2022;
    margin-bottom: 32px;
  }

  &.right__modal__show {
    transform: translateX(0);
  }
`;
const ValidationAreaContainer = styled.div`
  width: 360px;
  min-width: 360px;
  max-width: 360px;
  padding: 28px 32px 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ValidationList = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 58px;
  min-height: 58px;
  max-height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
`;
const ShadowLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(50, 50, 50, 0) 93%,
    rgba(50, 50, 50, 0.1) 100%
  );
  z-index: 10;
`;

const ShadowRight = styled.div`
  position: absolute;
  top: 0;

  width: 100px;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(50, 50, 50, 0) 93%,
    rgba(50, 50, 50, 0.1) 100%
  );
  z-index: 10;
`;

export default StockChangeHistoryTableModal;
