import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createPrePitching,
  getEvaluationList,
  getPrePitchingTemplete,
  getPrepitchingList,
  registeEvaluation,
} from "../../../../api/repository/portfolio/PortfolioRepository";

import { DealFlowStatus } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import {
  getPortfolioIdInLocation,
  getTransformedId,
  getYMD,
} from "../../../../common/commonUtil";
import {
  DealFlowEvaluationData,
  EvaluationListProps,
  InvestmentStatus,
  ResponseInvestmentAssessment,
} from "../interface/Evaluation.interface";
import { getAllDictionary } from "../../../../common/commonApiData";
import { getAllFundaccount } from "../../../../api/repository/accelerator/AcceleratorRepository";
import { IAReportAccount, InvestmentAssessment } from "../../../../type/data";

const useEvaluation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const portfolioId = getPortfolioIdInLocation(location);

  const firstLoading = useRef<boolean>(true);
  const openDealflowId = useRef<number | undefined>(undefined);
  const isNext = useRef<boolean>(false);
  const [evaluations, setEvaluations] = useState<DealFlowEvaluationData[]>([]);
  const [evaluationOpenModal, updateEvaluationOpenModal] =
    useState<boolean>(false);

  const preEvaluationData = useRef<InvestmentAssessment | undefined>(undefined);
  const [askCloneEvaluationModal, updateAskCloneEvaluationModal] =
    useState<boolean>(false);
  const [loadingModal, updateLoadingModal] = useState<boolean>(false);

  const onClickCreatedEvaluation = () => {
    const openEvaluation = evaluations.find(
      (item) => item.status === DealFlowStatus.OPEN
    );
    if (!openEvaluation) return;

    if (openEvaluation.investmentAssessment.length > 0) {
      updateAskCloneEvaluationModal(true);
    } else {
      updateEvaluationOpenModal(true);
    }
  };
  const closeCreatedEvaluationModal = () => {
    updateEvaluationOpenModal(false);
  };

  const onCloneEvaluation = (isClone: boolean) => {
    const openEvaluation = evaluations.find(
      (item) => item.status === DealFlowStatus.OPEN
    );
    if (!openEvaluation) return;

    if (isClone) {
      const data = openEvaluation.investmentAssessment[0].investmentAssessment;
      if (openDealflowId.current && data)
        createEvaluation(openDealflowId.current, data);
    } else {
      updateEvaluationOpenModal(true);
    }
  };

  const createEvaluation = async (
    dealFlowId: number,
    data: InvestmentAssessment
  ) => {
    await updateLoadingModal(true);
    const initReportAccount: IAReportAccount[] =
      data.investmentAssessmentReportAccount.map((item) => {
        const newInviteList = item.investmentAssessmentInvite.map((invite) => {
          return {
            ...invite,
            isAgreement: undefined,
            comment: undefined,
            signatureTmp: undefined,
          };
        });
        return {
          ...item,
          isAgreement: undefined,
          isCompleted: false,
          isPublished: false,
          requestFundManager: undefined,
          fundManagerConfirm: undefined,
          investmentAssessmentInvite: newInviteList,
          documentManagement: undefined,
          reportFile: undefined,
          reportFileId: undefined,
          investmentAssessmentContractDocument: undefined,
        };
      });
    const newData: InvestmentAssessment = {
      ...data,
      investmentAssessmentId: "newDocument",
      isConfirmed: false,
      exportLink: undefined,
      exportLinkHistory: undefined,
      updatedAt: undefined,
      investmentAssessmentReportAccount: initReportAccount,
    };
    const result = await registeEvaluation(dealFlowId, newData);
    if (result.success && result.investmentAssessmentId !== undefined) {
      await updateLoadingModal(false);
      goToEvaluationDetail(result.investmentAssessmentId);
    }
  };

  const goToEvaluationDetail = (investmentAssessmentId: number) => {
    console.log("goToPrepitchingDetail");
    const to = `/portfolio/${getTransformedId(
      portfolioId
    )}/evaluation-detail/${getTransformedId(investmentAssessmentId)}`;

    navigate(to);
  };

  const getDuplicateNameCount = (
    investmentAssessment: ResponseInvestmentAssessment[],
    index: number
  ) => {
    const diffTitle = getYMD(investmentAssessment[index].createdAt);

    const result = investmentAssessment.slice(index + 1).filter((item) => {
      const title = getYMD(item.createdAt);
      return title === diffTitle;
    });

    return `-${result.length + 1}`;
  };

  const init = async () => {
    const result = await getEvaluationList(portfolioId);
    const dictionary = await getAllDictionary();
    console.log(result);
    if (result && dictionary) {
      firstLoading.current = false;
      const fundAccount = dictionary.acFundAccount || [];
      const transformData = result.map(
        (item, index): DealFlowEvaluationData => {
          const title =
            index + 1 - result.length === 0
              ? result.length === 1
                ? "투자 심사"
                : "선행 투자 심사"
              : `후행 ${result.length - index - 1} 투자 심사`;
          const transData = item.investmentAssessment.map(
            (invest, idx): EvaluationListProps => {
              const newData: EvaluationListProps = {
                investmentAssessmentId: invest.investmentAssessmentId,
                investmentAssessment: invest.assessmentTmp.assessment,
                title: `${getYMD(invest.createdAt).replaceAll(
                  "-",
                  ""
                )}${getDuplicateNameCount(item.investmentAssessment, idx)}`,
                status: invest.status,
                previousValue:
                  invest.assessmentTmp.assessment
                    .investmentAssessmentReportAccount[0]
                    .investmentAssessmentAccount.previousValue || 0,
                totalInvestmentAmount:
                  invest.assessmentTmp.assessment
                    .investmentAssessmentReportAccount[0]
                    .investmentAssessmentAccount.totalInvestmentAmount || 0,
                createdAt: invest.createdAt,
                fundAccounts:
                  invest.assessmentTmp.assessment.investmentAssessmentReportAccount.map(
                    (account) => {
                      const nickName = fundAccount.find(
                        (acc) =>
                          acc.acFundAccountId ===
                          account.investmentAssessmentAccount.acFundAccount
                            ?.acFundAccountId
                      );
                      return nickName
                        ? nickName.nickName || ""
                        : account.investmentAssessmentAccount.accountName || "";
                    }
                  ),
              };
              return newData;
            }
          );
          return {
            id: item.id,
            title: title,
            status: item.status,
            investmentAssessment: transData,
          };
        }
      );

      openDealflowId.current = transformData.find(
        (item) => item.status === DealFlowStatus.OPEN
      )?.id;
      isNext.current = transformData.length > 1;
      setEvaluations([...transformData]);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return [
    portfolioId,
    firstLoading.current,
    evaluations,
    openDealflowId.current,
    isNext.current,
    preEvaluationData.current,
    evaluationOpenModal,
    askCloneEvaluationModal,
    loadingModal,
    updateAskCloneEvaluationModal,
    onClickCreatedEvaluation,
    closeCreatedEvaluationModal,
    goToEvaluationDetail,
    onCloneEvaluation,
    init,
  ] as const;
};

export default useEvaluation;
