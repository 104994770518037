import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

interface Props {
  headerSticky: boolean;
}

export const TableHeader: React.FC<Props> = ({ headerSticky }) => {
  const height = 32;
  return (
    <Container
      className={`caption-12-rg`}
      style={{ position: headerSticky ? "sticky" : "static" }}
    >
      <CellConatiner
        width={140}
        height={height * 2}
        style={{ position: "sticky", left: 0, justifyContent: "flex-start" }}
      >
        기업명
      </CellConatiner>

      <CellConatiner
        width={72}
        height={height * 2}
        style={{ justifyContent: "flex-start" }}
      >
        담당자명
      </CellConatiner>

      <CellConatiner
        width={72}
        height={height * 2}
        style={{ justifyContent: "flex-start" }}
      >
        투자일
      </CellConatiner>
      <FlexColumn>
        <CellConatiner width={78 * 3}>투자원금(백만원)</CellConatiner>
        <FlexRow>
          <CellConatiner
            className="caption-10-rg"
            width={78}
            style={{ textAlign: "center" }}
          >
            회수원금
            <br />
            (A)
          </CellConatiner>
          <CellConatiner
            className="caption-10-rg"
            width={78}
            style={{ textAlign: "center" }}
          >
            지분가치 원금
            <br />
            (B)
          </CellConatiner>
          <CellConatiner
            className="caption-10-rg"
            width={78}
            style={{ textAlign: "center" }}
          >
            원금 총액
            <br />
            (A+B)
          </CellConatiner>
        </FlexRow>
      </FlexColumn>

      <FlexColumn>
        <CellConatiner width={78 * 3}>평가가치(백만원)</CellConatiner>
        <FlexRow>
          <CellConatiner className="caption-10-rg" width={78}>
            회수금(C)
          </CellConatiner>
          <CellConatiner className="caption-10-rg" width={78}>
            지분가치(D)
          </CellConatiner>
          <CellConatiner className="caption-10-rg" width={78}>
            총액(C+D)
          </CellConatiner>
        </FlexRow>
      </FlexColumn>

      <FlexColumn>
        <CellConatiner width={64 * 2}>회수성과</CellConatiner>
        <FlexRow>
          <CellConatiner className="caption-10-rg" width={64}>
            MOIC(X)
          </CellConatiner>
          <CellConatiner className="caption-10-rg" width={64}>
            IRR(%)
          </CellConatiner>
        </FlexRow>
      </FlexColumn>
      <FlexColumn>
        <CellConatiner width={64 * 2}>지분가치성과</CellConatiner>
        <FlexRow>
          <CellConatiner className="caption-10-rg" width={64}>
            MOIC(X)
          </CellConatiner>
          <CellConatiner className="caption-10-rg" width={64}>
            IRR(%)
          </CellConatiner>
        </FlexRow>
      </FlexColumn>
      <FlexColumn>
        <CellConatiner width={64 * 2} style={{ borderRight: 0 }}>
          평가가치성과
        </CellConatiner>
        <FlexRow>
          <CellConatiner className="caption-10-rg" width={64}>
            MOIC(X)
          </CellConatiner>
          <CellConatiner
            className="caption-10-rg"
            width={64}
            style={{ borderRight: 0 }}
          >
            IRR(%)
          </CellConatiner>
        </FlexRow>
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  width: max-content;
  top: 141px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  :nth-child(1) {
    border-top-left-radius: 4px;
  }
  :nth-last-child(1) {
    border-top-right-radius: 4px;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height || 32}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: var(--text-04);
  background-color: #f6faff;
  border-right: 0.5px solid var(--divider-300, #e3e4e5);
  border-bottom: 0.5px solid var(--divider-300, #e3e4e5);
  padding: 0 10px;
`;
