import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import { ReactComponent as PdfIcon } from "../../../../../assets/image/large_pdf.svg";
import {
  referenceDocumentPopup,
  viewPdfPopup,
} from "../../../../../common/commonUtil";

interface Props {
  portfolioId: number;
  year: number;
  quarter: number;
  defaultData?: RiskFileType[];
  placeholder?: string;
}

export interface RiskFileType {
  type: "google_pdf" | "s3_pdf";
  acPfDocumentId?: number;
  file: {
    key: string;
    fileName: string;
  };
}

export const CustomFileOnlyDownload: React.FC<Props> = ({
  portfolioId,
  year,
  quarter,
  defaultData,
  placeholder = "파일이 없습니다.",
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<RiskFileType[] | undefined>(undefined);

  const previewFile = async (item: RiskFileType) => {
    if (item.type === "google_pdf") {
      referenceDocumentPopup({
        type: "google_pdf",
        portfolioId: portfolioId,
        year: year,
        quarter: quarter,
        acPfDocumentId: item.acPfDocumentId,
      });
    }

    if (item.type === "s3_pdf") {
      viewPdfPopup(item.file.key);
    }
  };
  useEffect(() => {
    console.log(defaultData);
    setFiles(defaultData);
  }, [defaultData]);

  return (
    <Container className={`body-14-rg`}>
      {files?.map((item) => {
        return (
          <InputContainer style={{ justifyContent: "space-between" }}>
            <div
              style={{
                flexGrow: 1,
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PdfIcon
                width={20}
                height={20}
                style={{
                  cursor: "pointer",
                }}
              />
              <span
                style={{
                  overflow: "hidden",
                  marginLeft: 4,
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e && e.stopPropagation();
                  previewFile(item);
                }}
              >
                {item.file.fileName || ""}
              </span>
            </div>
          </InputContainer>
        );
      })}
      {(files === undefined || files.length === 0) && (
        <NoFiles className="body-14-rg">{placeholder}</NoFiles>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 420px;
  margin-top: 6px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  &.disabled {
    background-color: var(--gray-50);
  }
`;

const NoFiles = styled.div`
  width: 100%;
  padding: 4px 12px;

  color: var(--text-inactive);
`;

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 4px 12px;

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  gap: 4px;

  &.disabled {
    padding: 6px 0px;
    cursor: default;
    border-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .arrow {
    path {
      fill: var(--icon-02);
    }
  }

  :hover {
    background-color: var(--hover-100);
  }
  :focus {
    border: 1px solid var(--blue-200);
    outline: none;
  }
`;
