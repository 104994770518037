import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
  ChartOptions,
} from "chart.js";

import { CompanyValueHistoryProps } from "../../../pre-pitching-detail/interface/PrePitchingDetail.interface";

import { AddComma, getYMD } from "../../../../../common/commonUtil";
import { ChartContainer } from "../component/ChartContainer";

import { CompanyValueBarChart } from "../component/CompanyValueBarChart";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  companyValueHistory: CompanyValueHistoryProps[];
}

export const CompanyValueHistory: React.FC<Props> = (props) => {
  const { companyValueHistory } = props;

  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        onClick: (e) => {},
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },

      tooltip: {
        callbacks: {
          label: (tooltipItem) => {},
        },
      },
    },
    datasets: {
      bar: {
        barPercentage: 1,
        categoryPercentage: 1,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 500,
          callback: (value, index, ticks) => {
            if (index === 0) {
              return `${value} (억)`;
            }
            return AddComma(value);
          },
        },
      },
    },
  };

  useEffect(() => {
    const labels: string[] = [];

    companyValueHistory.forEach((item) => {
      labels.push(getYMD(item.date));
    });

    const dataset: any = [
      {
        label: "가치",
        data: companyValueHistory.map((item) => {
          return (item.value / 100000000).toFixed(0);
        }),
        backgroundColor: "rgba(162, 199, 255, 1)",
      },
    ];
    setChartData({ labels: labels, datasets: dataset });
  }, [companyValueHistory]);

  if (!chartData) return null;
  return (
    <ChartContainer
      title="기업 가치"
      description="설립일부터 현재까지의 기업가치와 투자금의 추세를 나타냅니다."
    >
      <CompanyValueBarChart data={chartData} options={options} />
    </ChartContainer>
  );
};
