import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  AddComma,
  getCashDateFlow,
  getExitCashDateFlow,
  getNewDate,
  getStockRateCashDateFlow,
  getTransformDateToYearQuarter,
  getYMD,
  xirr,
} from "../../../../../common/commonUtil";
import { getInvestmentPerformanceSummary } from "../../../../../api/repository/accelerator/DashboardRepository";
import { DateRangeProps, SelectRange } from "../component/SelectRange";
import { XIRRDataProps } from "../../../../../type/data";
import { InvestmentPerformanceTable } from "../../../fund-account-detail/desktop/organism/InvestmentPerformanceTable";
import { InvestmentPerformanceResponse } from "../../../fund-account-detail/interface/FundAccountDetail.interface";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  showTable?: boolean;
  headerSticky?: boolean;
  defaultRangeDate?: DateRangeProps;
  structureId?: number;
  selectedFundAccountId?: number[];
  selectedIndustryId?: number[];
  selectedUserId?: number;
  excavation?: boolean;
  investor?: boolean;
  charger?: boolean;
  assistant?: boolean;
}

export const InvestmentPerformanceReview: React.FC<Props> = (props) => {
  const {
    showTable,
    headerSticky,
    defaultRangeDate = {
      startYear: "2014",
      startQuarter: "1",
      endYear: `${getNewDate().getFullYear()}`,
      endQuarter: "4",
    },
    structureId,
    selectedFundAccountId,
    selectedIndustryId,
    selectedUserId,
    excavation,
    investor,
    charger,
    assistant,
  } = props;
  const DIVISION_VALUE = 100000000;
  const isNetworking = useRef<boolean>(false);

  const [totalMOIC, setTotalMOIC] = useState<number>(0);
  const [totalIRR, setTotalIRR] = useState<number>(0);
  const [totalInvestmentCount, setTotalInvestmentCount] = useState<number>(0);

  const [exitMOIC, setExitMOIC] = useState<number>(0);
  const [exitIRR, setExitIRR] = useState<number>(0);
  const [fullExitCount, setFullExitCount] = useState<number>(0);
  const [partExitCount, setPartExitCount] = useState<number>(0);

  const [stockRateMOIC, setStockRateMOIC] = useState<number>(0);
  const [stockRateIRR, setStockRateIRR] = useState<number>(0);
  const [stockRateInvestmentCount, setStockRateInvestmentCount] =
    useState<number>(0);

  const [totalOriginExitAmount, setTotalOriginExitAmount] = useState<number>(0); // 총 회수 원금
  const [totalOriginAmount, setTotalOriginAmount] = useState<number>(0); // 총 투자금
  const [totalExitAmount, setTotalExitAmount] = useState<number>(0); // 총 회수금
  const [totalInvestedValue, setTotalInvestedValue] = useState<number>(0); // 총 지분평가
  const [totalValue, setTotalValue] = useState<number>(0); //총 평가가치

  const [dateStart, setDateStart] = useState<string | undefined>(
    getTransformDateToYearQuarter(
      true,
      defaultRangeDate.startYear,
      defaultRangeDate.startQuarter
    )
  );
  const [dateEnd, setDateEnd] = useState<string | undefined>(
    getTransformDateToYearQuarter(
      false,
      defaultRangeDate.endYear,
      defaultRangeDate.endQuarter
    )
  );
  const [investmentPerformanceData, setInvestmentPerformanceData] = useState<
    InvestmentPerformanceResponse[]
  >([]);

  const getData = async () => {
    isNetworking.current = true;
    const investmentPerformanceResult = await getInvestmentPerformanceSummary({
      dateStart: dateStart,
      dateEnd: dateEnd,
      acStructureId: structureId,
      acFundAccountId: selectedFundAccountId,
      acIndustrialTechId: selectedIndustryId,
      acUserId: selectedUserId,
      excavation: excavation,
      investor: investor,
      charger: charger,
      assistant: assistant,
    });

    let tOriginExitAmount = 0;
    let tOriginAmount = 0;
    let tExitAmount = 0;
    let tInvestedValue = 0;

    let fExitCount = 0;
    let pExitCount = 0;

    let totalCashDateflow: {
      cash: number;
      date: Date;
    }[] = [];

    let exitCashDateFlow: {
      cash: number;
      date: Date;
    }[] = [];

    let stockRateCashDateFlow: {
      cash: number;
      date: Date;
    }[] = [];

    if (investmentPerformanceResult) {
      setInvestmentPerformanceData(investmentPerformanceResult);
      investmentPerformanceResult.forEach((item) => {
        const investedValue =
          item.riskStatus?.id === 5 ||
          item.riskStatus?.id === 6 ||
          item.damagedAt !== null ||
          item.pfPhase.type === "CLOSURE" ||
          !item.investmentInfo ||
          item.investmentInfo.length === 0
            ? 0
            : item.investmentInfo
                .map((info) => info.number * info.issuingPrice)
                .reduce((p, c) => p + c, 0); //지분평가

        tInvestedValue += investedValue;

        //회수 성과 IRR
        if (item.investmentExit && item.investmentAccount) {
          const exitIRRData: XIRRDataProps[] = [];
          const itemOriginInvestmentAmount = item.investmentAccount
            .map((account) => account.investmentAmount)
            .reduce((p, c) => p + c, 0);
          let itemTotalExitPrincipal = 0;
          item.investmentAccount.forEach((account) => {
            exitIRRData.push({
              date: getNewDate(account.paymentDate),
              type: 1,
              originAmount: account.investmentAmount,
              leftAmount: account.investmentAmount,
            });
          });

          item.investmentExit.forEach((exit) => {
            itemTotalExitPrincipal += exit.principal;
            exitIRRData.push({
              date: getNewDate(exit.date),
              type: 2,
              exitAmount: exit.amount,
              originAmount: exit.principal,
            });
          });

          if (itemOriginInvestmentAmount === itemTotalExitPrincipal) {
            fExitCount++;
          } else {
            pExitCount++;
          }

          const exitCDFlow = getExitCashDateFlow(exitIRRData);
          exitCashDateFlow = exitCashDateFlow.concat(exitCDFlow);
        }

        //지분평가 성과 IRR
        const stockRateIRRData: XIRRDataProps[] = [];
        item.investmentAccount &&
          item.investmentAccount.forEach((account) => {
            stockRateIRRData.push({
              date: getNewDate(account.paymentDate),
              type: 1,
              originAmount: account.investmentAmount,
              leftAmount: account.investmentAmount,
            });
          });

        item.investmentExit?.forEach((exit) => {
          stockRateIRRData.push({
            date: getNewDate(exit.date),
            type: 2,
            exitAmount: exit.amount,
            originAmount: exit.principal,
          });
        });
        const stockRateCDFlow = getStockRateCashDateFlow(
          stockRateIRRData,
          investedValue
        );
        stockRateCashDateFlow = stockRateCashDateFlow.concat(stockRateCDFlow);

        //전체 성과 IRR
        const IRRData: XIRRDataProps[] = [];
        item.investmentAccount.forEach((account) => {
          tOriginAmount += account.investmentAmount;
          IRRData.push({
            date: getNewDate(account.paymentDate),
            type: 1,
            originAmount: account.investmentAmount,
          });
        });
        item.investmentExit?.forEach((exit) => {
          tOriginExitAmount += exit.principal;
          tExitAmount += exit.amount;
          IRRData.push({
            date: getNewDate(exit.date),
            type: 2,
            originAmount: exit.principal,
            exitAmount: exit.amount,
          });
        });
        console.log(IRRData);
        const CDFLow = getCashDateFlow(IRRData, investedValue);
        totalCashDateflow = totalCashDateflow.concat(CDFLow);
      });

      exitCashDateFlow = exitCashDateFlow.sort(
        (a, b) => a.date.getTime() - b.date.getTime()
      );

      stockRateCashDateFlow = stockRateCashDateFlow.sort(
        (a, b) => a.date.getTime() - b.date.getTime()
      );

      totalCashDateflow = totalCashDateflow.sort(
        (a, b) => a.date.getTime() - b.date.getTime()
      );

      console.log(totalCashDateflow);
      console.log(stockRateCashDateFlow);
      const tIRR = xirr(
        totalCashDateflow.map((item) => item.cash),
        totalCashDateflow.map((item) => item.date)
      );

      const eIRR = xirr(
        exitCashDateFlow.map((item) => item.cash),
        exitCashDateFlow.map((item) => item.date)
      );

      const srIRR = xirr(
        stockRateCashDateFlow.map((item) => item.cash),
        stockRateCashDateFlow.map((item) => item.date)
      );

      setTotalMOIC((tInvestedValue + tExitAmount) / tOriginAmount);
      setTotalIRR(tIRR || 0);
      setTotalInvestmentCount(investmentPerformanceResult.length);

      setExitMOIC(tExitAmount / tOriginExitAmount);
      setExitIRR(eIRR || 0);

      setStockRateMOIC(tInvestedValue / (tOriginAmount - tOriginExitAmount));
      setStockRateIRR(srIRR || 0);
      setStockRateInvestmentCount(
        investmentPerformanceResult.filter(
          (item) => item.pfPhase.type === "MANAGE" && item.damagedAt === null
        ).length
      );

      setTotalOriginAmount(tOriginAmount);
      setTotalInvestedValue(tInvestedValue);
      setTotalValue(tInvestedValue + tExitAmount);
      setTotalOriginExitAmount(tOriginExitAmount);
      setTotalExitAmount(tExitAmount);

      setFullExitCount(fExitCount);
      setPartExitCount(pExitCount);
    }
    isNetworking.current = false;
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },

      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.formattedValue}원`;
          },
        },
      },
    },
    datasets: {
      bar: {
        barPercentage: 0.6,
        categoryPercentage: 0.5,
      },
    },
  };
  useEffect(() => {
    // if (isNetworking.current) return;
    getData();
  }, [
    structureId,
    selectedFundAccountId,
    selectedIndustryId,
    selectedUserId,
    excavation,
    investor,
    charger,
    assistant,
    dateStart,
    dateEnd,
  ]);
  return (
    <Wrapper>
      <Container>
        <TitleContainer className="heading-18-sb">
          <span>투자성과 요약</span>
          <SelectRange
            isOnlyYear
            defaultRangeDate={defaultRangeDate}
            onChangeRnage={(data) => {
              const sDate = getTransformDateToYearQuarter(
                true,
                data.startYear,
                data.startQuarter
              );
              const eDate = getTransformDateToYearQuarter(
                false,
                data.endYear,
                data.endQuarter
              );
              if (sDate && eDate) {
                setDateStart(sDate);
                setDateEnd(eDate);
              }
            }}
          />
        </TitleContainer>
        <BodyContainer>
          <FlexRow style={{ gap: 18 }}>
            <ChartWrapper>
              <div className="heading-16-sb">투자성과(회수 + 미회수)</div>
              <FlexRow style={{ gap: 8, alignItems: "center" }}>
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>MOIC</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {totalMOIC ? totalMOIC.toFixed(2) : 0}배
                  </span>
                </FlexRow>
                <Divider />
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>IRR</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {totalIRR.toFixed(2)}%
                  </span>
                </FlexRow>
                <Divider />
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>투자</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {totalInvestmentCount}건
                  </span>
                </FlexRow>
              </FlexRow>

              <FlexRow
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <FlexColumn style={{ gap: 12 }}>
                  <FlexColumn>
                    <div
                      className="heading-14-sb"
                      style={{ color: "var(--text-04)" }}
                    >
                      투자원금
                    </div>
                    <div
                      className="heading-20-b"
                      style={{ color: "var(--blue-700)" }}
                    >
                      {AddComma(
                        (totalOriginAmount / DIVISION_VALUE).toFixed(
                          totalOriginAmount / DIVISION_VALUE < 1 ? 2 : 0
                        )
                      )}
                      억
                    </div>
                  </FlexColumn>
                  <FlexColumn>
                    <div
                      className="heading-14-sb"
                      style={{ color: "var(--text-04)" }}
                    >
                      평가가치
                    </div>
                    <div
                      className="heading-20-b"
                      style={{ color: "var(--blue-700)" }}
                    >
                      {AddComma(
                        (totalValue / DIVISION_VALUE).toFixed(
                          totalValue / DIVISION_VALUE < 1 ? 2 : 0
                        )
                      )}
                      억
                    </div>
                  </FlexColumn>
                </FlexColumn>
                <div style={{ width: 190, height: 115 }}>
                  <Bar
                    style={{ display: "inline-block" }}
                    options={{
                      ...options,
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                        y: {
                          max: totalValue,
                          display: false,
                        },
                      },
                    }}
                    data={{
                      labels: ["투자 원금 ", "평가가치"],
                      datasets: [
                        {
                          label: "",
                          data: [totalOriginAmount, totalValue],
                          backgroundColor: "rgba(23, 58, 255, 1)",
                        },
                      ],
                    }}
                    width={190}
                    height={115}
                  />
                </div>
              </FlexRow>
            </ChartWrapper>
            <ChartWrapper>
              <div className="heading-16-sb">회수</div>
              <FlexRow style={{ gap: 8, alignItems: "center" }}>
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>MOIC</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {exitMOIC ? exitMOIC.toFixed(2) : 0}배
                  </span>
                </FlexRow>
                <Divider />
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>IRR</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {exitIRR.toFixed(2)}%
                  </span>
                </FlexRow>
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>완전</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {fullExitCount}건
                  </span>
                </FlexRow>
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>부분</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {partExitCount}건
                  </span>
                </FlexRow>
              </FlexRow>

              <FlexRow
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <FlexColumn style={{ gap: 12 }}>
                  <FlexColumn>
                    <div
                      className="heading-14-sb"
                      style={{ color: "var(--text-04)" }}
                    >
                      투자 원금
                    </div>
                    <div
                      className="heading-20-b"
                      style={{ color: "var(--blue-700)" }}
                    >
                      {AddComma(
                        (totalOriginExitAmount / DIVISION_VALUE).toFixed(
                          totalOriginExitAmount / DIVISION_VALUE < 1 ? 2 : 0
                        )
                      )}
                      억
                    </div>
                  </FlexColumn>
                  <FlexColumn>
                    <div
                      className="heading-14-sb"
                      style={{ color: "var(--text-04)" }}
                    >
                      회수 금액
                    </div>
                    <div
                      className="heading-20-b"
                      style={{ color: "var(--blue-700)" }}
                    >
                      {AddComma(
                        (totalExitAmount / DIVISION_VALUE).toFixed(
                          totalExitAmount / DIVISION_VALUE < 1 ? 2 : 0
                        )
                      )}
                      억
                    </div>
                  </FlexColumn>
                </FlexColumn>
                <div style={{ width: 190, height: 115 }}>
                  <Bar
                    style={{ display: "inline-block" }}
                    options={{
                      ...options,
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                        y: {
                          max: totalValue,
                          display: false,
                        },
                      },
                    }}
                    data={{
                      labels: ["투자 원금 ", "회수 금액"],
                      datasets: [
                        {
                          label: "",
                          data: [totalOriginExitAmount, totalExitAmount],
                          backgroundColor: "rgba(69, 132, 255, 1)",
                        },
                      ],
                    }}
                    width={190}
                    height={115}
                  />
                </div>
              </FlexRow>
            </ChartWrapper>

            <ChartWrapper>
              <div className="heading-16-sb">미회수 (지분평가) </div>
              <FlexRow style={{ gap: 8, alignItems: "center" }}>
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>MOIC</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {stockRateMOIC ? stockRateMOIC.toFixed(2) : 0}배
                  </span>
                </FlexRow>
                <Divider />
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>IRR</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {stockRateIRR.toFixed(2)}%
                  </span>
                </FlexRow>
                <Divider />
                <FlexRow className="heading-14-md" style={{ gap: 4 }}>
                  <span style={{ color: "var(--text-04)" }}>투자</span>
                  <span style={{ color: "var(--primary-blue)" }}>
                    {stockRateInvestmentCount}건
                  </span>
                </FlexRow>
              </FlexRow>

              <FlexRow
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <FlexColumn style={{ gap: 12 }}>
                  <FlexColumn>
                    <div
                      className="heading-14-sb"
                      style={{ color: "var(--text-04)" }}
                    >
                      투자 원금
                    </div>
                    <div
                      className="heading-20-b"
                      style={{ color: "var(--blue-700)" }}
                    >
                      {AddComma(
                        (
                          (totalOriginAmount - totalOriginExitAmount) /
                          DIVISION_VALUE
                        ).toFixed(
                          (totalOriginAmount - totalOriginExitAmount) /
                            DIVISION_VALUE <
                            1
                            ? 2
                            : 0
                        )
                      )}
                      억
                    </div>
                  </FlexColumn>
                  <FlexColumn>
                    <div
                      className="heading-14-sb"
                      style={{ color: "var(--text-04)" }}
                    >
                      미회수(지분평가)
                    </div>
                    <div
                      className="heading-20-b"
                      style={{ color: "var(--blue-700)" }}
                    >
                      {AddComma(
                        (totalInvestedValue / DIVISION_VALUE).toFixed(
                          totalInvestedValue / DIVISION_VALUE < 1 ? 2 : 0
                        )
                      )}
                      억
                    </div>
                  </FlexColumn>
                </FlexColumn>
                <div style={{ width: 190, height: 115 }}>
                  <Bar
                    style={{ display: "inline-block" }}
                    options={{
                      ...options,
                      scales: {
                        x: {
                          grid: {
                            display: false,
                          },
                        },
                        y: {
                          max: totalValue,
                          display: false,
                        },
                      },
                    }}
                    data={{
                      labels: ["투자 원금 ", "지분평가"],
                      datasets: [
                        {
                          label: "",
                          data: [
                            totalOriginAmount - totalOriginExitAmount,
                            totalInvestedValue,
                          ],
                          backgroundColor: "rgba(106, 85, 244, 1)",
                        },
                      ],
                    }}
                    width={190}
                    height={115}
                  />
                </div>
              </FlexRow>
            </ChartWrapper>
          </FlexRow>
        </BodyContainer>
      </Container>
      {showTable && (
        <InvestmentPerformanceTable
          headerSticky={headerSticky}
          initDefaultData={investmentPerformanceData}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChartWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;

  border-radius: 4px;

  border: 1px solid var(--divider-200, #edeeef);
  background: var(--background-page, #fcfcfd);
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.05);
`;

const Divider = styled.div`
  width: 1px;
  height: 10px;
  background-color: var(--gray-300);
`;
