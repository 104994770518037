import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  QuestionType,
  SectionType,
} from "../../../../portfolio/pre-pitching-detail/interface/PrePitchingDetail.interface";
import QuestionItem from "./QuestionItem";
import { PrePitchingTaskChangeType } from "../../interface/PrePitchingTask.interface";

interface Props {
  sectionItem: SectionType;
  onChangeData: (
    type: PrePitchingTaskChangeType,
    dataIndex: number,
    data: any
  ) => void;
}

const SectionView: React.FC<Props> = ({ sectionItem, onChangeData }) => {
  const isQuestionType = (type: QuestionType) =>
    type === "short" ||
    type === "long" ||
    type === "single" ||
    type === "multiple" ||
    type === "linear";

  useEffect(() => {}, []);

  if (!sectionItem) return null;

  return (
    <Container>
      <SectionTitle className="heading-20-sb">{sectionItem.title}</SectionTitle>
      <InfoBox>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="heading-16-sb" style={{ color: "var(--text-01)" }}>
            투자정보
          </div>
          <SectionText
            className="body-14-rg"
            style={{ color: "var(--text-01)", paddingLeft: 10 }}
          >
            {sectionItem.investmentInfo}
          </SectionText>
          <div
            className="heading-16-sb"
            style={{ marginTop: 10, color: "var(--text-01)" }}
          >
            기업정보
          </div>
          <SectionText
            className="body-14-rg"
            style={{ color: "var(--text-01)", paddingLeft: 10 }}
          >
            {sectionItem.companyInfo}
          </SectionText>
        </div>
      </InfoBox>

      {sectionItem.list?.map((item, index) =>
        isQuestionType(item.type) ? (
          <QuestionItem
            no={index + 1}
            item={item}
            dataIndex={index}
            onChangeData={onChangeData}
          />
        ) : null
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SectionTitle = styled.div`
  width: 100%;
  color: var(--light-text-01, #1f2022);
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid var(--light-gray-200, #edeeef);
  background: var(--light-background-contents-01, #fff);
`;
const SectionText = styled.pre`
  width: 100%;
  height: auto;
  min-height: 40px;

  word-break: break-all;
  white-space: break-spaces;

  color: var(--text-01, #1f2022);
`;
export default SectionView;
