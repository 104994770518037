import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import imageCompression from "browser-image-compression";
import { DefaultButton } from "lattice_core";

interface Props {
  defaultData?: string;
  imageWidth: number;
  imageHeight: number;
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  placeholder?: string;
  onChangeImage: (data: UploadImageProps) => void;
}

export interface UploadImageProps {
  isNew?: boolean;
  imageUrl: string;
  file?: File;
}
export const UploadImage: React.FC<Props> = (props) => {
  const {
    defaultData,
    imageWidth,
    imageHeight,
    maxSizeMB = 1,
    maxWidthOrHeight = 1024,
    placeholder,
    onChangeImage,
  } = props;
  const [imageUrl, setImageUrl] = useState<string | undefined>(defaultData);
  const imgRef = useRef<HTMLInputElement>(null);

  const compressionOption = {
    maxSizeMB: maxSizeMB,
    maxWidthOrHeight: maxWidthOrHeight,
    useWebWorker: true,
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files || event.target.files.length === 0) return;
    const imageFile = event.target.files[0];
    try {
      const compressedFile = await imageCompression(
        imageFile,
        compressionOption
      );

      const fileUrl = URL.createObjectURL(compressedFile);
      setImageUrl(fileUrl);
      onChangeImage({
        isNew: true,
        file: compressedFile,
        imageUrl: fileUrl,
      });

      event.target.value = "";
    } catch (error) {}
  };

  return (
    <Container>
      <ImageWrapper style={{ width: imageWidth, height: imageHeight }}>
        {!imageUrl ? (
          <Empty className="caption-11-rg">업로드된 이미지가 없습니다.</Empty>
        ) : (
          <Image src={imageUrl} />
        )}
      </ImageWrapper>
      <FlexColumn style={{ gap: 6 }}>
        <div>
          <DefaultButton
            className="caption-12-md"
            buttonType="colored-outline"
            sizeType="xxsmall"
            buttonColor={"#1F2022"}
            borderColor={"#D5D6D8"}
            text="파일 교체"
            onClick={(e) => {
              e && e.stopPropagation();
              if (imgRef && imgRef.current) {
                imgRef.current?.click();
              }
            }}
          />
        </div>
        <div className="caption-10-rg" style={{ color: "var(--text-04)" }}>
          {placeholder}
        </div>
      </FlexColumn>

      <input
        ref={imgRef}
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        onChange={(e) => {
          handleImageUpload(e);
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid var(--gray-200);
  border-radius: 4px;
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const Empty = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background-color: var(--gray-50);
  color: var(--text-05);
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
