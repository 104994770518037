import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Term } from "../../../type/data";

import CustomCheckBox from "./components/table/CustomCheckBox";
import { DefaultButton } from "lattice_core";

interface Props {
  privacy?: Term;
  personal?: Term;
  agreeTerms: (termsAndConditionsIds: number[]) => void;
}
const AgreeTerms: React.FC<Props> = (props) => {
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [personalChecked, setPersonalChecked] = useState<boolean>(false);
  if (!props.privacy || !props.personal) return null;
  return (
    <Container>
      <Logo
        className={"logo-20-hv"}
        style={{ textAlign: "center", color: "var(--primary-blue)" }}
      >
        Lattice
      </Logo>

      <AuthCodeContainer>
        <div className="heading-24-sb">
          래티스 서비스 이용약관에 동의해 주세요.
        </div>
        <FlexColumn style={{ marginTop: 40 }}>
          <CustomCheckBox
            defaultValue={personalChecked && privacyChecked}
            contents="모두 동의"
            onChangeData={(value) => {
              setPrivacyChecked(value);
              setPersonalChecked(value);
            }}
          />
          <div
            className="caption-12-rg"
            style={{ color: "var(--text-03)", marginLeft: 30 }}
          >
            서비스 이용을 위해 아래 약관에 모두 동의합니다.
          </div>
        </FlexColumn>
        <Divider />
        <FlexColumn>
          <CustomCheckBox
            defaultValue={privacyChecked}
            contents="(필수) 개인정보 처리방침"
            onChangeData={(value) => {
              setPrivacyChecked(value);
            }}
          />
          <TextBox className="caption-12-rg" style={{ marginTop: 12 }}>
            {props.privacy.content}
          </TextBox>
        </FlexColumn>
        <FlexColumn style={{ marginTop: 25 }}>
          <CustomCheckBox
            defaultValue={personalChecked}
            contents="(필수) 개인정보 수집 및 이용 동의"
            onChangeData={(value) => {
              setPersonalChecked(value);
            }}
          />
          <TextBox
            className="caption-12-rg"
            style={{ marginTop: 12, marginBottom: 25 }}
          >
            {props.personal.content}
          </TextBox>
        </FlexColumn>

        <DefaultButton
          className="heading-16-md"
          buttonType="filled"
          sizeType="small"
          disabled={!personalChecked || !privacyChecked}
          buttonColor="var(--primary-blue)"
          hoverColor="var(--blue-600)"
          borderColor="var(--gray-400)"
          text="동의하기"
          onClick={() => {
            if (props.privacy && props.personal) {
              props.agreeTerms([props.privacy.id, props.personal.id]);
            }
          }}
        />
      </AuthCodeContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Logo = styled.div`
  position: fixed;

  top: 45px;
  left: 50px;
`;

const AuthCodeContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-300);
  margin: 21px 0px 25px 0px;
`;

const TextBox = styled.pre`
  display: flex;
  width: 486px;
  height: 120px;
  max-height: 120px;
  padding: 9px 12px;
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  overflow: scroll;
  white-space: break-spaces;
`;

export default AgreeTerms;
