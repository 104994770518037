import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { CustomInput } from "./table/custom-cell/CustomInput";
import { TableGrid, TableGridHeaderProps } from "./table/TableGrid";
import { FundAccount, FundPurpose } from "../interface/FundAccount.interface";
import { RemoveComma, showToast } from "../../../../../common/commonUtil";
import { CustomTextArea } from "./table/custom-cell/CustomTextArea";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { DefaultButton } from "lattice_core";
import { Bounce } from "react-toastify";

interface Props {
  totalAmount: number;
  purposeList: FundPurpose[];
  onDataChange: (data: FundPurpose[]) => void;
}

export const PurposeTableComponent: React.FC<Props> = (props) => {
  const { totalAmount, purposeList, onDataChange } = props;

  const [render, setRender] = useState<boolean>(false);

  const headers: TableGridHeaderProps[] = [
    {
      header: "주목적",
      key: "isMain",
      width: 80,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <div className="flex__center">
            {data ? (
              <CustomColorLabel
                style={{ cursor: "pointer" }}
                bgColor="var(--primary-purple)"
                textColor="var(--white)"
              >
                주목적
              </CustomColorLabel>
            ) : (
              <CustomColorLabel
                style={{ cursor: "pointer" }}
                bgColor="var(--gray-500)"
                textColor="var(--white)"
                onClick={() => {
                  purposeList.forEach((item) => (item.isMain = false));
                  allData.isMain = true;
                  onDataChange(purposeList);
                }}
              >
                선택
              </CustomColorLabel>
            )}
          </div>
        );
      },
    },
    {
      header: "목적",
      key: "target",
      width: 360,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomTextArea
            placeholder="청년 기업에 70% 기여"
            defaultData={data}
            onChangeData={(value) => {
              if (!purposeList) return;
              allData.target = value;
              onDataChange(purposeList);
            }}
          />
        );
      },
    },
    {
      header: "목표금액",
      key: "amount",
      width: 200,

      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            transfromPixel={(n) => n}
            placeholder="100,000,000"
            defaultValue={data}
            onChange={(value) => {
              if (!purposeList) return;
              const amount = parseInt(RemoveComma(value));
              if (totalAmount < amount) {
                showToast({
                  customElement: (
                    <div
                      className="heading-16-sb"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <span>목표금액은 약정총액보다 클수 없습니다.</span>
                    </div>
                  ),
                  backgroundColor: "var(--red-500)",
                  color: "var(--white)",
                  width: 274,
                  milliSec: 1500,
                  transition: Bounce,
                  position: "bottom-center",
                  toastId: new Date().toString(),
                });
              }
            }}
            onBlur={(value) => {
              if (!purposeList) return;
              const amount = parseInt(RemoveComma(value));
              allData.amount = amount;
              allData.percent = parseFloat(
                ((amount / totalAmount) * 100).toFixed(2)
              );
              onDataChange(purposeList);
            }}
          />
        );
      },
    },
    {
      header: "퍼센트(%)",
      key: "percent",
      width: 110,

      customCell: (data, allData, isReadOnly, disabled) => {
        const value = data ? (data as number).toFixed(2) : `0`;

        return (
          <CustomInput
            textType="percent"
            transfromPixel={(n) => n}
            placeholder="00.00"
            defaultValue={`${data}`}
            onBlur={(value) => {
              if (!purposeList) return;
              const percent = parseFloat(value);
              allData.percent = percent;
              allData.amount = (totalAmount * percent) / 100;
              onDataChange(purposeList);
            }}
          />
        );
      },
    },
  ];

  if (!purposeList) return null;

  useEffect(() => {
    setRender((prev) => !prev);
  }, [purposeList]);

  return (
    <Container>
      <TableGrid
        header={headers}
        dataList={purposeList.map((item, index) => {
          return {
            id: index,
            identificationNumber: item.idx || 0,
            data: item,
          };
        })}
        hasAddButton
        hasDeleteButton
        onClickAddBtn={() => {
          console.log("asdfsaf");
          const lastId =
            purposeList.length === 0
              ? 0
              : Math.max.apply(
                  null,
                  purposeList.map((item) => item.id || 0)
                );
          purposeList.push({ idx: lastId + 1 });
          onDataChange(purposeList);
        }}
        onClickDeleteBtn={(idx) => {
          const index = purposeList.findIndex((item) => item.idx === idx);
          if (index !== -1) {
            purposeList.splice(index, 1);
          }
          onDataChange(purposeList);
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  overflow-x: scroll;

  padding: 16px;

  .flex__center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
