import * as ExcelJS from "exceljs";
import saveAs from "file-saver";
import { getNewDate, getYMD, getYMDHMS } from "../../common/commonUtil";

const headerDefaultStyle: Partial<ExcelJS.Style> = {
  font: {
    size: 8,
    bold: true,
  },
  alignment: {
    vertical: "middle",
    horizontal: "center",
  },
};
const portfolioId: Partial<ExcelJS.Column> = {
  // header: "기업번호",
  key: "portfolioId",
  width: 10,
  style: { ...headerDefaultStyle },
};
const portfolioName: Partial<ExcelJS.Column> = {
  // header: "기업명",
  key: "companyName",
  width: 30,
  style: { ...headerDefaultStyle },
};
const phase: Partial<ExcelJS.Column> = {
  // header: "기업번호",
  key: "phase",
  width: 10,
  style: { ...headerDefaultStyle },
};
const participantMain: Partial<ExcelJS.Column> = {
  // header: "담당자명",
  key: "mainParticipant",
  width: 10,
  style: { ...headerDefaultStyle },
};

const document1Column: Partial<ExcelJS.Column> = {
  // header: "투자일",
  key: "firstPaymentDate",
  width: 15,
  style: { ...headerDefaultStyle },
};
const document2Column: Partial<ExcelJS.Column> = {
  // header: "회수원금",
  key: "originExitAmount",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document3Column: Partial<ExcelJS.Column> = {
  // header: "지분가치원금",
  key: "originInvestmentAmount",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document4Column: Partial<ExcelJS.Column> = {
  // header: "원금총액",
  key: "totalOriginAmount",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document5Column: Partial<ExcelJS.Column> = {
  // header: "회수금",
  key: "exitAmount",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document6Column: Partial<ExcelJS.Column> = {
  // header: "지분가치금액",
  key: "investedValue",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document7Column: Partial<ExcelJS.Column> = {
  // header: "총액",
  key: "totalValue",
  width: 20,
  style: { ...headerDefaultStyle },
};
const document8Column: Partial<ExcelJS.Column> = {
  // header: "회수MOIC",
  key: "exitMOIC",
  width: 15,
  style: { ...headerDefaultStyle },
};
const document9Column: Partial<ExcelJS.Column> = {
  // header: "회수IRR",
  key: "exitIRR",
  width: 15,
  style: { ...headerDefaultStyle },
};
const document10Column: Partial<ExcelJS.Column> = {
  // header: "지분가치MOIC",
  key: "investedMOIC",
  width: 15,
  style: { ...headerDefaultStyle },
};
const document11Column: Partial<ExcelJS.Column> = {
  // header: "지분가치IRR",
  key: "investedIRR",
  width: 15,
  style: { ...headerDefaultStyle },
};

const document12Column: Partial<ExcelJS.Column> = {
  // header: "평가가치MOIC",
  key: "resultMOIC",
  width: 15,
  style: { ...headerDefaultStyle },
};

const document13Column: Partial<ExcelJS.Column> = {
  // header: "평가가치IRR",
  key: "resultIRR",
  width: 15,
  style: { ...headerDefaultStyle },
};

export const exportExcelByInvestmentPerformanceTable = async (data?: any[]) => {
  if (!data || data.length === 0) return;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("포트폴리오 투자성과 요약"); // sheet 이름이 My Sheet
  // sheet 데이터 설정

  const columnsArr = [
    portfolioId,
    portfolioName,
    participantMain,
    document1Column,
    document2Column,
    document3Column,
    document4Column,
    document5Column,
    document6Column,
    document7Column,
    document8Column,
    document9Column,
    document10Column,
    document11Column,
    document12Column,
    document13Column,
    phase,
  ];

  const newData = data.map((item: any) => {
    const transData: any = {
      portfolioId: item.portfolioId,
      companyName: item.companyName,
      mainParticipant: item.mainParticipant,
      firstPaymentDate: item.firstPaymentDate,
      originExitAmount: item.originExitAmount, //회수 원금
      originInvestmentAmount: item.originInvestmentAmount, // 지분가치 원금
      totalOriginAmount: item.originExitAmount + item.originInvestmentAmount,
      exitAmount: item.exitAmount, //회수금
      investedValue: item.investedValue, //지분가치금액
      totalValue: item.exitAmount + item.investedValue,
      exitMOIC: item.originExitAmount === 0 ? "-" : item.exitMOIC, //회수 MOIC
      exitIRR:
        item.originExitAmount === 0
          ? "-"
          : ["-0.00", "0.00"].includes(item.exitIRR.toFixed(2))
          ? "0.00"
          : item.exitIRR, //회수 IRR
      investedMOIC:
        item.phase === "DAMAGE" ||
        item.phase === "CLOSURE" ||
        item.originInvestmentAmount === 0
          ? "-"
          : item.investedMOIC, //지분가치 MOIC
      investedIRR:
        item.phase === "DAMAGE" ||
        item.phase === "CLOSURE" ||
        item.originInvestmentAmount === 0
          ? "-"
          : ["-0.00", "0.00"].includes(item.investedIRR.toFixed(2))
          ? "0.00"
          : item.investedIRR, //지분가치 IRR
      resultMOIC: item.resultMOIC === 0 ? "-" : item.resultMOIC, //평가가치 MOIC
      resultIRR:
        item.resultMOIC === 0
          ? "-"
          : ["-0.00", "0.00"].includes(item.resultIRR.toFixed(2))
          ? "0.00"
          : item.resultIRR,
      phase:
        item.phase === "MANAGE"
          ? "관리"
          : item.phase === "DAMAGE"
          ? "손상"
          : item.phase === "CLOSURE"
          ? "폐업"
          : item.phase === "EXIT"
          ? "엑싯"
          : "",
    };
    return transData;
  });

  console.log(newData);
  worksheet.columns = [...columnsArr];
  worksheet.mergeCells("A1:A2");
  worksheet.mergeCells("B1:B2");
  worksheet.mergeCells("C1:C2");
  worksheet.mergeCells("D1:D2");
  worksheet.mergeCells("E1:G1");
  worksheet.mergeCells("H1:J1");
  worksheet.mergeCells("K1:L1");
  worksheet.mergeCells("M1:N1");
  worksheet.mergeCells("O1:P1");
  worksheet.mergeCells("Q1:Q2");

  worksheet.getCell("A1").value = "기업번호";
  worksheet.getCell("B1").value = "기업명";
  worksheet.getCell("C1").value = "담당자명";
  worksheet.getCell("D1").value = "투자일";
  worksheet.getCell("E1").value = "투자원금";
  worksheet.getCell("E2").value = "회수원금(A)";
  worksheet.getCell("F2").value = "지분가치 원금(B)";
  worksheet.getCell("G2").value = "원금총액(A+B)";
  worksheet.getCell("H1").value = "평가가치";
  worksheet.getCell("H2").value = "회수금(C)";
  worksheet.getCell("I2").value = "지분가치(D)";
  worksheet.getCell("J2").value = "총액(C+D)";
  worksheet.getCell("K1").value = "회수성과";
  worksheet.getCell("K2").value = "MOIC";
  worksheet.getCell("L2").value = "IRR";
  worksheet.getCell("M1").value = "지분가치성과";
  worksheet.getCell("M2").value = "MOIC";
  worksheet.getCell("N2").value = "IRR";
  worksheet.getCell("O1").value = "전체성과";
  worksheet.getCell("O2").value = "MOIC";
  worksheet.getCell("P2").value = "IRR";
  worksheet.getCell("Q1").value = "상태";

  newData?.forEach((item, index) => {
    worksheet.insertRow(index + 3, { ...item });
  });
  worksheet.eachRow((row, rowNum) => {
    row.eachCell((cell, colNumber) => {
      if (rowNum > 2 && colNumber > 4) {
        cell.alignment = {
          vertical: "middle",
          horizontal: "right",
        };
      }
      if (rowNum > 2 && colNumber > 4 && colNumber < 11) {
        cell.numFmt = "#,##0.##";
      }

      if (rowNum > 2 && colNumber > 10) {
        cell.numFmt = "#.##";
      }
    });
  });
  // 다운로드
  const mimeType = {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], mimeType);

  saveAs(blob, `포트폴리오 투자성과 요약_${getYMDHMS(getNewDate())}.xlsx`);
};
