import { NavigationMenu, RouterAddress } from "./NewNavigationLayout";

export const NavMenu: NavigationMenu[] = [
  {
    pageAddress: "/admin/company-structure",
    addressName: "company-structure",
    pageName: "조직관리",
    type: "active",
  },
  {
    pageAddress: "/admin/industry-tech",
    addressName: "industry-tech",
    pageName: "산업기술 관리",
    type: "active",
  },
  {
    pageAddress: "/admin/fund-account",
    addressName: "fund-account",
    pageName: "펀드 관리",
    type: "active",
  },
  {
    pageAddress: "/admin/channel",
    addressName: "channel",
    pageName: "채널 관리",
    type: "active",
  },
  {
    pageAddress: "/admin/portfolio",
    addressName: "portfolio",
    pageName: "포트폴리오 관리",
    type: "active",
  },
];

export const allRouterList: RouterAddress[] = [
  {
    addressName: "admin",
    pageName: "관리자",
    isDisabled: true,
  },
  {
    addressName: "company-structure",
    pageName: "조직관리",
  },
  {
    addressName: "industry-tech",
    pageName: "산업기술 관리",
  },
  {
    addressName: "fund-account",
    pageName: "펀드 관리",
  },
  {
    addressName: "channel",
    pageName: "채널 관리",
  },

  {
    addressName: "portfolio",
    pageName: "포트폴리오 관리",
  },
];
