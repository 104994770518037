import { useEffect, useRef, useState } from "react";
import { useAsyncError, useLocation } from "react-router";
import styled, { css } from "styled-components";

import { toast } from "react-toastify";

import {
  cancelReport,
  confirmReport,
  confirmStockChangeListReport,
  getAllAccelratingPortfolio,
  getPortfolioReportDetail,
  getReportList,
  getReportRange,
  getSBPData,
  rejectReport,
} from "../../../reporting/repository/ReportingRepository";

import { ReactComponent as Loading } from "../../../../assets/image/loading.svg";
import { ReactComponent as Lock } from "../../../../assets/image/new_icon_lock.svg";

import ViewerPortfolioDetailModal from "./components/ViewerPortfolioDetailModal";
import { useNavigate } from "react-router-dom";

import Selector from "./components/Selector";
import ChatingFloat from "./components/chat/ChatingFloat";
import useChating from "./components/chat/useChating";
import RejectModal from "./components/RejectModal";

import StockChangeListConfirmModal from "./components/StockChangeListConfirmModal";
import SummaryBusinessPlanV2Download from "./organism/summary-business-plan/v2/components/download/SummaryBusinessPlanV2Download";
import {
  EmployeeTurnover,
  PatentInfo,
} from "./organism/summary-business-plan/v2/interface/SBP.interface";

import {
  ReportingFile,
  SelectorItemProps,
} from "../interface/ReportingDetail.interface";
import { ReactComponent as CheckChip } from "../../../../assets/image/new_step_chip.svg";

import { DefaultButton } from "lattice_core";

import axios from "axios";
import * as api from "../../../../api/api";
import {
  getMyInfo,
  getPortfolioIdInLocation,
} from "../../../../common/commonUtil";
import useResize from "../../../../hooks/useResize";
import RollingSelector, {
  RollingSelectorDataProps,
} from "../../../../components-2.0v/rolling-selector/RollingSelector";

import useGoogle from "../../../../hooks/useGoogle";
import StatusBall from "../../../reporting/mobile/components/StatusBall";
import { showMobileToast } from "../../../../2.0lattice/toast";

import ModalLayout from "../../../../components/modal/ModalLayout";
import ConfirmModal from "../../../../2.0lattice/components/modal/ConfirmModal";
import { DownloadingProgress } from "../../../reporting/mobile/components/ReportFileDownload";
import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";

import SubTab from "../../../reporting/mobile/components/SubTab";
import { AcceleratorReportRangeProps } from "../../../reporting/interface/Reporting.interface";

const MobilePortfolioReporting: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const { portfolioNavInfo, init, isEditable = false } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const portfolioId = getPortfolioIdInLocation(location);

  const [paused, updatePaused] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const myInfo = getMyInfo();
  const [screenRate, transfromPixel] = useResize();

  const portfolioName = useRef<string>("");

  const [portfolioRange, setPortfolioRange] = useState<
    AcceleratorReportRangeProps | undefined
  >(undefined);
  const [files, setFiles] = useState<ReportingFile[]>();
  const [yearSelectorList, updateYearSelectorList] = useState<
    SelectorItemProps[]
  >([]);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [selectedQuarter, setSelectedQuarter] = useState<number>(0);
  const [requiredDocument, updateRequiredDocument] = useState<ReportingFile[]>(
    []
  );
  const [optionDocument, updateOptionDocument] = useState<ReportingFile[]>([]);
  const [requiredDocumentCount, updateRequiredDocumentCount] =
    useState<number>(0);
  const [uploadedRequiredDocumentCount, updateUploadedRequiredDocumentCount] =
    useState<number>(0);

  const [isOpenPdf, setIsOpenPdf] = useState<boolean>(false);

  const selectedReport = useRef<ReportingFile | undefined>(undefined);
  const [viewerModal, updateViewerModal] = useState<boolean>(false);
  const [confirmModal, updateConfirmModal] = useState<boolean>(false);
  const [rejectModal, updateRejectModal] = useState<boolean>(false);
  const [sbpDownload, updateSbpDownload] = useState<boolean>(false);
  const [isAuthority, setIsAuthority] = useState<boolean>(
    isEditable || myInfo.authorityId === 2
  );
  const [isParticipant, setIsParticipant] = useState<boolean>(false);
  const [
    stockChangeListConfirmModal,
    updateStockChangeListConfirmConfirmModal,
  ] = useState<boolean>(false);
  const [confirmCancelModal, updateConfirmCancelModal] =
    useState<boolean>(false);

  const [forbiddenModal, updateForbiddenModal] = useState<boolean>(false);
  const [rejectCancelModal, updateRejectCancelModal] = useState<boolean>(false);

  const selectedReportDocumentId = useRef<number | undefined>(undefined);
  const selectedReportDocumentData = useRef<
    | {
        acPfDocumentId: number;
        pfReportDocumentDataId?: number;
      }
    | undefined
  >(undefined);

  const [
    isOpenChatingRef,
    showUnreadTooltip,
    unreadMsgCount,
    firstIndex,
    SBChatingMsg,
    currentReadChatItemId,
    isFirstOpen,
    isOpenChating,
    chatingFocusing,
    setPortfolioId,
    updateIsOpenChating,
    openChating,
    closeChating,
    updateChatingFocusing,
    nextPageChat,
    sendChating,
    clearUnreadMsgCount,
    callOpenChating,
  ] = useChating();

  const [
    isGapiReady,
    isLoggedIn,
    handleSignInClick,
    handleSignOutClick,
    getPDFBlobFile,
    onLoginLattice,
    createFolder,
  ] = useGoogle();

  const openPDF = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_black";
    link.click();
    setIsOpenPdf(false);
  };

  const openStockHolderFile = (key: string) => {
    const newUrl = `https://drive.google.com/file/d/${key}/preview`;
    setIsOpenPdf(true);
    openPDF(newUrl);
  };

  const openGooglePDF = async (pdfKey: string) => {
    if (!isGapiReady) return;
    setIsOpenPdf(true);
    const blob = await getPDFBlobFile(pdfKey);
    if (blob) {
      const newBlob = new Blob([blob], { type: "application/pdf" });
      const newUrl = window.URL.createObjectURL(newBlob);
      openPDF(newUrl);
    }
  };

  const openAwsPDF = async (pdfKey: string) => {
    setIsOpenPdf(true);
    const fileUrl = (
      await axios.get(api.getFile(), {
        params: {
          key: pdfKey,
        },
      })
    ).data;

    fetch(fileUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf", // 이래야 브라우저가 pdf라고 인식하고
        "Content-Disposition": "inline", // 이래야  브라우저가 다운로드말고 화면에 비추도록 한다
      },
    })
      .then((res) => {
        console.log(res);
        return res.blob();
      })
      .then((res) => {
        console.log(res);
        const newBlob = new Blob([res], { type: "application/pdf" });
        const newUrl = window.URL.createObjectURL(newBlob);
        openPDF(newUrl);
      });
  };

  const getItem = (item: ReportingFile, index: number) => {
    return (
      <div
        className={`document__item`}
        onClick={() => {
          openViewerModal(item);
        }}
      >
        <div className="document__item__title">
          {item.file ? (
            <div className={`document__check__chip`}>
              <CheckChip width={22} height={22} />
            </div>
          ) : (
            <div className={`body-14-md document__number`}>{index + 1}</div>
          )}
          <div
            className={
              item.file?.uploadStatus.uploadStatusId &&
              [1, 2].includes(item.file.uploadStatus.uploadStatusId)
                ? "heading-14-sb"
                : "body-14-rg"
            }
            style={{ flexGrow: 1 }}
          >
            {item.name}
          </div>
          {item.file &&
            [3, 4, 5].includes(item.file.uploadStatus.uploadStatusId) && (
              <StatusBall
                uploadStatusId={item.file.uploadStatus.uploadStatusId}
                uploadStatusName={item.file.uploadStatus.name}
                transfromPixel={(n) => n}
              />
            )}
        </div>
        {isOpen && isAuthority && item.file && (
          <div className="document__item__button__container">
            {[1, 2, 3].includes(item.file.uploadStatus.uploadStatusId) && (
              <DefaultButton
                className="caption-12-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor={"#1F2022"}
                borderColor={"#D5D6D8"}
                text="반려"
                transfromPixel={(n) => n}
                onClick={(e) => {
                  e?.stopPropagation();
                  item.file?.pfReportDocumentId &&
                    reject(item.file.pfReportDocumentId);
                }}
              />
            )}

            {[1, 2, 3].includes(item.file.uploadStatus.uploadStatusId) && (
              <DefaultButton
                className="caption-12-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor={"#1F2022"}
                borderColor={"#D5D6D8"}
                text="확정"
                transfromPixel={(n) => n}
                onClick={(e) => {
                  e?.stopPropagation();
                  if (!item.file || !item.file.pfReportDocumentId) return;

                  if (item.acPfDocumentId === 1) {
                    selectedReportDocumentId.current =
                      item.file.pfReportDocumentId;
                    updateStockChangeListConfirmConfirmModal(true);
                  } else if ([7, 8].includes(item.acPfDocumentId)) {
                    selectedReportDocumentData.current = {
                      acPfDocumentId: item.acPfDocumentId,
                      pfReportDocumentDataId:
                        item.file?.pfReportDocumentData !== undefined
                          ? item.file.pfReportDocumentData.id
                          : undefined,
                    };
                    confirm(item.file.pfReportDocumentId);
                  } else {
                    confirm(item.file.pfReportDocumentId);
                  }
                }}
              />
            )}

            {item.file.uploadStatus.uploadStatusId === 4 && (
              <DefaultButton
                className="caption-12-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor={"#1F2022"}
                borderColor={"#D5D6D8"}
                text="반려취소"
                transfromPixel={(n) => n}
                onClick={(e) => {
                  e?.stopPropagation();
                  item.file?.pfReportDocumentId &&
                    rejectCancel(item.file.pfReportDocumentId);
                }}
              />
            )}

            {item.file.uploadStatus.uploadStatusId === 5 && (
              <DefaultButton
                className="caption-12-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor={"#1F2022"}
                borderColor={"#D5D6D8"}
                text="확정취소"
                transfromPixel={(n) => n}
                onClick={(e) => {
                  e?.stopPropagation();
                  item.file?.pfReportDocumentId &&
                    confirmCancel(item.file.pfReportDocumentId);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const getPortfolioReportDocumentByRefData = () => {
    getPortfolioReportDocument();
  };

  const onConfirmStockChangeListReport = async (
    reportDocumentId: number,
    issuingPrice: number
  ) => {
    try {
      const result = await confirmStockChangeListReport(
        reportDocumentId,
        issuingPrice
      );
      if (result) {
        getPortfolioReportDocumentByRefData();
        showMobileToast("success", "확정했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConfirmReport = async () => {
    try {
      if (selectedReportDocumentId.current === undefined) return;

      let pfFinancialStatement: any = undefined;
      let pfEmployeeHistory: any = undefined;
      let pfPatent: any = undefined;

      if (
        selectedReportDocumentData.current !== undefined &&
        selectedReportDocumentData.current.pfReportDocumentDataId !== undefined
      ) {
        const result = await getSBPData(
          selectedReportDocumentData.current.pfReportDocumentDataId
        );

        if (result) {
          console.log(result);
          if (selectedReportDocumentData.current.acPfDocumentId === 6) {
            const employee: EmployeeTurnover =
              result.data.businessStatus.employeeTurnover;
            const patent: PatentInfo = result.data.businessStatus.patentInfo;
            const total =
              (employee.curEmployee || 0) +
              (employee.employeeOnboarding || 0) -
              (employee.employeeAttrition || 0);
            const entry = employee.employeeOnboarding || 0;
            const resignation = employee.employeeAttrition || 0;
            const directorEntry = employee.noEventClevel
              ? undefined
              : employee.cLevelChangeList
                  ?.filter((item) => item.isOnboarding === "입사자")
                  .map((item) => item.position || "")
                  .join("||");
            const directorResignation = employee.noEventClevel
              ? undefined
              : employee.cLevelChangeList
                  ?.filter((item) => item.isOnboarding === "퇴사자")
                  .map((item) => item.position || "")
                  .join("||");

            const domesticApplication =
              (patent.accumulatePatent?.domesticApplication || 0) +
              (patent.newPatent?.domesticApplication || 0);
            const internationalApplication =
              (patent.accumulatePatent?.internationalApplication || 0) +
              (patent.newPatent?.internationalApplication || 0);
            const domesticRegistration =
              (patent.accumulatePatent?.domesticRegistration || 0) +
              (patent.newPatent?.domesticRegistration || 0);
            const internationalRegistration =
              (patent.accumulatePatent?.internationalRegistration || 0) +
              (patent.newPatent?.internationalRegistration || 0);

            pfEmployeeHistory = {
              total: total,
              entry: entry,
              resignation: resignation,
              directorEntry: directorEntry,
              directorResignation: directorResignation,
            };

            pfPatent = {
              domesticApplication: domesticApplication,
              internationalApplication: internationalApplication,
              domesticRegistration: domesticRegistration,
              internationalRegistration: internationalRegistration,
            };
          } else {
            pfFinancialStatement = {
              ...result.data,
            };
          }
        }
      }
      const result = await confirmReport(
        selectedReportDocumentId.current,
        pfFinancialStatement,
        pfEmployeeHistory,
        pfPatent
      );

      if (result) {
        getPortfolioReportDocumentByRefData();
        showMobileToast("success", "확정했습니다.");
      }
      selectedReportDocumentId.current = undefined;
      selectedReportDocumentData.current = undefined;
    } catch (error) {
      console.log(error);
    }
  };
  const confirmCancelReport = async () => {
    try {
      if (selectedReportDocumentId.current === undefined) return;
      const result = await cancelReport(selectedReportDocumentId.current);
      if (result) {
        getPortfolioReportDocumentByRefData();
        showMobileToast("success", "확정을 취소했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRejectReport = async (reason: string) => {
    try {
      if (selectedReportDocumentId.current === undefined) return;

      const result = await rejectReport(
        selectedReportDocumentId.current,
        reason
      );
      if (result) {
        getPortfolioReportDocumentByRefData();
        showMobileToast("success", "반려했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rejectCancelReport = async () => {
    try {
      if (selectedReportDocumentId.current === undefined) return;
      const result = await cancelReport(selectedReportDocumentId.current);
      if (result) {
        getPortfolioReportDocumentByRefData();
        showMobileToast("success", "반려을 취소했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openViewerModal = (file: ReportingFile) => {
    if (!file.file || !file.file.documentFile) {
      return;
    } else if (file.extension === "web") {
    } else if (file.acPfDocumentId === 1) {
      openStockHolderFile(file.file.documentFile.key);
    } else if (file.file.type === "GOOGLE") {
      openGooglePDF(file.file.documentFile.key);
    } else if (file.file.type === "AWS") {
      openAwsPDF(file.file.documentFile.key);
    }
  };

  const confirm = (documentId: number) => {
    selectedReportDocumentId.current = documentId;
    updateConfirmModal(true);
  };
  const reject = (documentId: number) => {
    selectedReportDocumentId.current = documentId;
    updateRejectModal(true);
  };
  const confirmCancel = (documentId: number) => {
    selectedReportDocumentId.current = documentId;
    updateConfirmCancelModal(true);
  };
  const rejectCancel = (documentId: number) => {
    selectedReportDocumentId.current = documentId;
    updateRejectCancelModal(true);
  };

  const getPortfolioReportDocument = async () => {
    try {
      const result = await getPortfolioReportDetail(portfolioId, {
        year: selectedYear,
        quarterNum: selectedQuarter,
      });

      console.log(result);
      if (result) {
        setIsOpen(result.isOpen);
        setFiles([...result.reportData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReportRangeData = async () => {
    try {
      const data = await getReportRange();
      if (!data) return;

      const { acceleratorRange, portfolioRange, current } = data;
      const yearArray = Array.from(
        { length: acceleratorRange.end.year - acceleratorRange.start.year + 1 },
        (v, i) => acceleratorRange.start.year + i
      );
      const rangeData = yearArray.map(
        (value): RollingSelectorDataProps => ({
          id: value,
          text: `${value}년`,
          data: value,
        })
      );
      setPortfolioRange(data);
      updateYearSelectorList(rangeData);

      return {
        currentYear: current.year,
        currentQuarter: current.quarter,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const getEmptyMsg = () => {
    if (!portfolioRange) return;

    const acRange = portfolioRange.acceleratorRange;

    if (selectedYear < acRange.end.year) {
      return "투자 전 이므로 분기보고 파일이 없습니다.";
    } else if (portfolioRange.current.quarter < selectedQuarter) {
      return "분기보고가 열리지 않았습니다.";
    }
  };

  const dataInit = async () => {
    try {
      setIsFileLoaded(false);
      updateViewerModal(false);

      //엑셀러레이터 연도 range 가져오기
      const rangeResult = await getReportRangeData();
      if (rangeResult) {
        setSelectedYear(rangeResult.currentYear);
        setSelectedQuarter(rangeResult.currentQuarter);
      }
      setPortfolioId(portfolioId, isEditable || myInfo.authorityId === 2);
      setIsFileLoaded(true);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!files) return;

    const required = files.filter((item) => item.required);
    const option = files.filter((item) => !item.required);

    updateRequiredDocument(required);
    updateOptionDocument(option);
    updateRequiredDocumentCount(required.length);
    updateUploadedRequiredDocumentCount(
      required.filter((item) => item.file !== undefined).length
    );
  }, [files]);

  useEffect(() => {
    isOpenChatingRef.current = viewerModal;
  }, [viewerModal]);

  useEffect(() => {
    if (selectedYear && selectedQuarter) getPortfolioReportDocument();
  }, [selectedYear, selectedQuarter]);

  useEffect(() => {
    dataInit();
  }, []);

  if (!isLoaded) return null;

  return (
    <ContainerWrapper>
      <Container className="scroll__invisible">
        <SubTab>
          <RollingSelector
            data={yearSelectorList}
            defaultPosition={yearSelectorList.findIndex(
              (item: any) => item.id === selectedYear
            )}
            paused={paused}
            transfromPixel={transfromPixel}
            onChangeData={(data) => {
              data && setSelectedYear(data.data);
            }}
          />
          <QuarterContainer
            className="heading-16-md"
            style={{ fontSize: transfromPixel(16) }}
            transfromPixel={transfromPixel}
          >
            <QuarterItem
              className={`${selectedQuarter === 1 ? "selected" : ""}`}
              transfromPixel={transfromPixel}
              onClick={() => {
                if (selectedQuarter === 1) return;
                setSelectedQuarter(1);
              }}
            >
              1분기
            </QuarterItem>
            <QuarterItem
              className={`${selectedQuarter === 2 ? "selected" : ""}`}
              transfromPixel={transfromPixel}
              onClick={() => {
                if (selectedQuarter === 2) return;
                setSelectedQuarter(2);
              }}
            >
              2분기
            </QuarterItem>
            <QuarterItem
              className={`${selectedQuarter === 3 ? "selected" : ""}`}
              transfromPixel={transfromPixel}
              onClick={() => {
                if (selectedQuarter === 3) return;
                setSelectedQuarter(3);
              }}
            >
              3분기
            </QuarterItem>
            <QuarterItem
              className={`${selectedQuarter === 4 ? "selected" : ""}`}
              transfromPixel={transfromPixel}
              onClick={() => {
                if (selectedQuarter === 4) return;
                setSelectedQuarter(4);
              }}
            >
              4분기
            </QuarterItem>
          </QuarterContainer>
        </SubTab>
        <ContentContainer
          className="scroll__invisible"
          len={Math.floor(files?.length || 0)}
          transfromPixel={transfromPixel}
        >
          {!isFileLoaded ? null : files && files.length > 0 ? (
            <DocumentListContainer className={`scroll__invisible`}>
              <div className="document__list__upload__status">
                <span className="heading-18-md">제출서류</span>
                <span className="heading-14-md" style={{ color: "#96999F" }}>
                  {requiredDocumentCount}건 중 {uploadedRequiredDocumentCount}{" "}
                  건 제출
                </span>
              </div>
              <DocumentList style={{ marginTop: 12 }}>
                <div className="heading-14-sb document__list__gorup__title">
                  필수
                </div>
                {requiredDocument.map((item, index) => {
                  return getItem(item, index);
                })}
              </DocumentList>
              <DocumentList style={{ marginTop: 16 }}>
                <div className="heading-14-sb document__list__gorup__title">
                  옵션
                </div>
                {optionDocument.map((item, index) => {
                  return getItem(item, requiredDocument.length + index);
                })}
              </DocumentList>
            </DocumentListContainer>
          ) : (
            <div className="heading-18-md empty__container">
              <Lock />
              <div>{getEmptyMsg()}</div>
            </div>
          )}
        </ContentContainer>
        {files && viewerModal && selectedReport.current && (
          <ModalLayout isOpen={viewerModal} isFullScreen>
            <ViewerPortfolioDetailModal
              portfolioName={portfolioName.current}
              portfolioId={portfolioId}
              selectedReport={selectedReport.current}
              year={selectedYear}
              quarterNum={selectedQuarter}
              close={() => {
                selectedReportDocumentId.current = undefined;
                getPortfolioReportDocumentByRefData();
                updateViewerModal(false);
              }}
              transfromPixel={transfromPixel}
              getPortfolioReportDocumentByRefData={
                getPortfolioReportDocumentByRefData
              }
            />
          </ModalLayout>
        )}
        {confirmModal && (
          <ModalLayout
            isOpen={confirmModal}
            isFullScreen
            toggle={() => {
              updateConfirmModal(false);
              selectedReportDocumentId.current = undefined;
            }}
          >
            <ConfirmModal
              type="confirm"
              transfromPixel={transfromPixel}
              title={"확정하기"}
              contents={["이 파일을 확정할까요?"]}
              positiveTitle={"확정"}
              negativeTitle={"취소"}
              onClickNegativeBtn={() => {
                updateConfirmModal(false);
                selectedReportDocumentId.current = undefined;
                selectedReportDocumentData.current = undefined;
              }}
              onClickPositiveBtn={() => {
                onConfirmReport();
                updateConfirmModal(false);
              }}
            />
          </ModalLayout>
        )}

        {selectedReportDocumentId.current && stockChangeListConfirmModal && (
          <ModalLayout
            isOpen={stockChangeListConfirmModal}
            isFullScreen
            toggle={() => {
              updateStockChangeListConfirmConfirmModal(false);
              selectedReportDocumentId.current = undefined;
            }}
          >
            <StockChangeListConfirmModal
              reportDocumentId={selectedReportDocumentId.current}
              cancel={() => {
                updateStockChangeListConfirmConfirmModal(false);
                selectedReportDocumentId.current = undefined;
              }}
              stockChangeListConfirm={(reportDocumentId, issuingPrice) => {
                onConfirmStockChangeListReport(reportDocumentId, issuingPrice);
                updateStockChangeListConfirmConfirmModal(false);
                selectedReportDocumentId.current = undefined;
              }}
            />
          </ModalLayout>
        )}
        {rejectModal && (
          <ModalLayout
            isOpen={rejectModal}
            isFullScreen
            toggle={() => {
              updateRejectModal(false);
              selectedReportDocumentId.current = undefined;
            }}
          >
            <RejectModal
              transfromPixel={transfromPixel}
              cancel={() => updateRejectModal(false)}
              rejectSubmit={(text) => {
                onRejectReport(text);
                updateRejectModal(false);
                selectedReportDocumentId.current = undefined;
              }}
            />
          </ModalLayout>
        )}

        {confirmCancelModal && (
          <ModalLayout
            isOpen={confirmCancelModal}
            isFullScreen
            toggle={() => {
              updateConfirmCancelModal(false);
              selectedReportDocumentId.current = undefined;
            }}
          >
            <ConfirmModal
              type="confirm"
              transfromPixel={transfromPixel}
              title={"확정 취소"}
              contents={[`'확정' 상태를 취소할까요?`]}
              positiveTitle={"확인"}
              negativeTitle={"취소"}
              onClickNegativeBtn={() => {
                updateConfirmCancelModal(false);
                selectedReportDocumentId.current = undefined;
              }}
              onClickPositiveBtn={() => {
                //확정 취소 함수
                confirmCancelReport();
                updateConfirmCancelModal(false);
                selectedReportDocumentId.current = undefined;
              }}
            />
          </ModalLayout>
        )}

        {rejectCancelModal && (
          <ModalLayout
            isOpen={rejectCancelModal}
            isFullScreen
            toggle={() => {
              updateRejectCancelModal(false);
              selectedReportDocumentId.current = undefined;
            }}
          >
            <ConfirmModal
              type="confirm"
              transfromPixel={transfromPixel}
              title={"반려 취소"}
              contents={[`'반려' 상태를 취소할까요?`]}
              positiveTitle={"확인"}
              negativeTitle={"취소"}
              onClickNegativeBtn={() => {
                updateRejectCancelModal(false);
                selectedReportDocumentId.current = undefined;
              }}
              onClickPositiveBtn={() => {
                rejectCancelReport();
                updateRejectCancelModal(false);
                selectedReportDocumentId.current = undefined;
              }}
            />
          </ModalLayout>
        )}

        {forbiddenModal && (
          <ModalLayout
            isOpen={forbiddenModal}
            isFullScreen
            toggle={() => {
              updateForbiddenModal(false);
            }}
          >
            <ConfirmModal
              type="alert"
              transfromPixel={transfromPixel}
              title={"접근 불가"}
              contents={[
                `담당 포트폴리오가 아니면 접근할 수 없습니다.`,
                `포트폴리오 담당 심사역에게 문의해 주세요.`,
              ]}
              positiveTitle={"확인"}
              onClickPositiveBtn={() => {
                updateForbiddenModal(false);
              }}
            />
          </ModalLayout>
        )}

        {isFileLoaded && (isEditable || myInfo.authorityId === 2) && (
          <ChatingFloat
            unreadMsgCount={unreadMsgCount}
            showUnreadTooltip={showUnreadTooltip}
            SBChatingMsg={SBChatingMsg}
            firstIndex={firstIndex}
            currentReadChatItemId={currentReadChatItemId}
            isOpenChating={isOpenChating}
            chatingFocusing={chatingFocusing}
            updateChatingFocusing={updateChatingFocusing}
            closeChating={closeChating}
            openChating={openChating}
            nextPageChat={nextPageChat}
            sendChating={sendChating}
            clearUnreadMsgCount={clearUnreadMsgCount}
            transfromPixel={transfromPixel}
          />
        )}

        {sbpDownload &&
          selectedReport.current &&
          selectedReport.current.file && (
            <ModalLayout
              isOpen={sbpDownload}
              isFullScreen
              isBackgroundTransparent
              overlayColor="rgba(31, 32, 34, 0.5)"
              zIndex={-10000000}
            >
              <SummaryBusinessPlanV2Download
                file={selectedReport.current.file}
                companyName={"회사이름"}
                completeProgress={(current, total) => {
                  console.log(`완료페이지 ${current} / ${total}`);
                }}
                complete={() => {
                  updateSbpDownload(false);
                }}
              />
            </ModalLayout>
          )}

        {sbpDownload && (
          <ModalLayout
            isOpen={sbpDownload}
            isFullScreen
            isBackgroundTransparent
            overlayColor="rgba(31, 32, 34, 0.5)"
          >
            <DownloadingProgress className="body-16-md">
              <Loading
                className="rotating"
                width={transfromPixel(30)}
                height={transfromPixel(30)}
              />
              <div>파일 다운로드 중입니다.</div>
            </DownloadingProgress>
          </ModalLayout>
        )}
      </Container>
      {isOpenPdf && (
        <ModalLayout
          isOpen={isOpenPdf}
          isFullScreen
          isBackgroundTransparent
          overlayColor="rgba(31, 32, 34, 0.5)"
        >
          <DownloadingProgress className="body-16-md">
            <Loading
              className="rotating"
              width={transfromPixel(30)}
              height={transfromPixel(30)}
            />
            <div>파일 여는 중...</div>
          </DownloadingProgress>
        </ModalLayout>
      )}
    </ContainerWrapper>
  );
};

const ContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fbfcfd;

  display: flex;
  flex-direction: column;
  overflow: scroll;
  border-top: 1px solid var(--gray-300);
`;

const QuarterContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const QuarterItem = styled.div<{ transfromPixel: (value: number) => number }>`
  width: auto;
  height: ${(props) => props.transfromPixel(40)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.transfromPixel(8)}px
    ${(props) => props.transfromPixel(4)}px;
  color: #bec0c2;
  cursor: pointer;

  &.selected {
    color: #1f2022;
  }
`;

const ContentContainer = styled.div(
  ({
    len,
    transfromPixel,
  }: {
    len: number;
    transfromPixel: (value: number) => number;
  }) => css`
    position: relative;
    width: 100%;
    height: calc(100% - 41px);

    background: #fbfcfd;
    overflow: scroll;

    .empty__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: ${transfromPixel(20)}px;
      background: #fbfcfd;
      color: #7e8086;
      padding: 24px;
    }

    .box-container {
      height: 100%;
      overflow: scroll;

      width: 100%;
      max-width: calc(100% * 1920 / 1536);
      min-width: calc(100% * 1280 / 1536);

      padding: 0 ${transfromPixel(60)}px;

      .box {
        width: ${transfromPixel(1205)}px;
        height: calc(${transfromPixel(274)}px * 3 + ${transfromPixel(4)}px);

        display: flex;
        flex-direction: column;
        gap: ${transfromPixel(1)}px;

        background: #edeeef;

        margin: ${transfromPixel(40)}px auto ${transfromPixel(80)}px auto;

        .row {
          display: flex;
          flex-direction: row;
          gap: ${transfromPixel(1)}px;
          /* flex: 1; */
          height: calc(100% / 3);
          width: 100%;
          .empty-box {
            background: #fbfcfd;
            flex-grow: 1;
            height: ${transfromPixel(274)}px;

            box-shadow: 0 ${transfromPixel(1)}px 0 #fbfcfd,
              ${transfromPixel(1)}px 0 0 #fbfcfd
                ${len % 4 === 0 ? `, -${transfromPixel(1)}px 0 0 #FBFCFD` : ""};
          }
        }
      }
    }
  `
);

const TitleComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .back__btn {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    path {
      fill: var(--text-01);
    }
  }
`;

const SearchContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
`;
const DocumentListContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow-y: scroll;

  .document__list__upload__status {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`;

const DocumentList = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .document__list__gorup__title {
    color: #5b5d61;
  }

  .document__item {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    color: #5b5d61;

    background: var(--white);
    border: 1px solid #edeeef;
    border-radius: 4px;

    gap: 24px;

    cursor: pointer;
    transition: all 0.2s ease;

    .document__item__title {
      width: 100%;
      height: 22px;
      min-height: 22px;
      max-height: 22px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      color: #5b5d61;

      .document__check__chip {
        height: 22px;
        min-height: 22px;
        max-height: 22px;
      }

      .document__number {
        width: 22px;
        min-width: 22px;
        max-width: 22px;
        height: 22px;
        min-height: 22px;
        max-height: 22px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f3f4f4;
        color: #bec0c2;

        &.selected {
          background-color: #173aff;
          color: #ffffff;
        }
      }
    }

    .document__item__button__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;
    }

    :hover {
      border-color: #a2c7ff;
    }
  }
`;

export default MobilePortfolioReporting;
