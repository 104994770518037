import { createRef, useEffect, useRef, useState } from "react";
import Styled from "styled-components";
import { DateTime } from "../../../../type/data";

import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import usePrePitchingDetail from "../hook/usePrePitchingDetail";
import { PitchingStatus } from "../../pre-pitching/interface/PrePitching.interface";
import { PitchingModify } from "./organisms/PitchingModify";
import { PitchingProgress } from "./organisms/PitchingProgress";
import PitchingResult from "./organisms/PitchingResult";

import { SectionType } from "../interface/PrePitchingDetail.interface";

export type MODE = "CREATE" | "VIEWER" | "ACTIVE" | "RESULT" | undefined;
export interface PrePitchingData {
  isChange: boolean;
  preEvaluationId?: number | undefined;
  preEvaluationDate?: string | undefined;
  preEvaluationStartDate?: DateTime | undefined;
  preEvaluationEndDate?: DateTime | undefined;
  preEvaluationData?: SectionType[] | undefined;
  preEvaluationInviteList?: {
    acUserId: number | string;
    isAnswered?: boolean;
  }[];
}

const DeskPrePitchingDetail: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const { isEditable = false, setCurrentPageName } = props;

  const [
    isOpenDeal,
    allUser,
    pitchingData,
    releaseAble,
    isModifyMode,
    alReadyPublish,
    pitchingDataResult,
    setAlreadyPublish,
    onChangeData,
    updatePitchingData,
    publishPitching,
    cancelPitching,
    finishPitching,
    setModifyMode,
    publishingModify,
    isVotedCountZero,
  ] = usePrePitchingDetail(setCurrentPageName);

  if (pitchingData === undefined) return null;

  return (
    <Container>
      {[PitchingStatus.OPEN, PitchingStatus.PUBLISH].includes(
        pitchingData.status
      ) && (
        <PitchingModify
          isEditable={isOpenDeal && isEditable}
          pitchingData={pitchingData}
          allUser={allUser}
          releaseAble={releaseAble}
          isModifyMode={isModifyMode}
          alReadyPublish={alReadyPublish}
          setAlreadyPublish={setAlreadyPublish}
          onChangeData={onChangeData}
          updatePitchingData={updatePitchingData}
          publishPitching={publishPitching}
          cancelPitching={cancelPitching}
          finishPitching={finishPitching}
          setModifyMode={setModifyMode}
          publishingModify={publishingModify}
        />
      )}

      {pitchingData.status === PitchingStatus.PITCHING &&
        pitchingDataResult &&
        isVotedCountZero() && (
          <PitchingProgress
            allUser={allUser}
            isEditable={isOpenDeal && isEditable}
            pitchingData={pitchingData}
            publishingModify={publishingModify}
          />
        )}
      {pitchingData.status === PitchingStatus.PITCHING &&
        pitchingDataResult &&
        !isVotedCountZero() && (
          <PitchingResult
            isEditable={isOpenDeal && isEditable}
            pitchingData={pitchingData}
            prePitchingResult={pitchingDataResult}
            finishPitching={finishPitching}
          />
        )}

      {pitchingData.status === PitchingStatus.FINISH && pitchingDataResult && (
        <PitchingResult
          isEditable={isOpenDeal && isEditable}
          pitchingData={pitchingData}
          prePitchingResult={pitchingDataResult}
          finishPitching={finishPitching}
        />
      )}
    </Container>
  );
};

const Container = Styled.div`
  width: 100%;
  height: 100%;
`;
export default DeskPrePitchingDetail;
