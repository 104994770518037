import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
  ChartOptions,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
interface Props {
  data: ChartData;
  options?: ChartOptions;
}

export const CompanyValueBarChart: React.FC<Props> = (props) => {
  const { data, options } = props;

  return <Chart type="bar" options={options} data={data} />;
};
