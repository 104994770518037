import React, { useState } from "react";
import styled from "styled-components";

import CheckedText from "../../../../../../components-2.0v/check-box/CheckedText";
import {
  PersonalSearchList,
  PersonalSearchProps,
} from "../../../interface/PerformanceMetrics.interface";
import CheckedChip from "../../../../../excavation/desktop/components/CheckedChip";

interface Props {
  selectedChip: "main" | "excavation";
  selectedMainSubChip: PersonalSearchProps[];
  onChangeMainChip: (chip: "main" | "excavation") => void;
  onChangeFilter: (filter: PersonalSearchProps) => void;
}

const FilterView: React.FC<Props> = (props) => {
  const {
    selectedChip,
    onChangeMainChip,
    selectedMainSubChip,
    onChangeFilter,
  } = props;

  const [openFilter, updateOpenFilter] = useState<boolean>(false);

  return (
    <Container>
      <FelxRow
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FelxRow style={{ gap: 12, flexWrap: "wrap" }}>
          <CheckedChip
            isSelected={openFilter}
            isActive={selectedChip === "main"}
            contentsString={`투자성과`}
            onClick={() => {
              onChangeMainChip("main");
              updateOpenFilter((prev) => !prev);
            }}
          />
        </FelxRow>
      </FelxRow>
      <FilterContainer
        className={selectedChip === "main" && openFilter ? "show__filter" : ""}
      >
        <Divider style={{ marginBottom: 0 }} />
        {selectedChip === "main" ? (
          <FilterWrapper>
            {PersonalSearchList.map((item) => {
              return (
                <CheckedText
                  isChecked={
                    selectedMainSubChip.find(
                      (select) => select.type === item.type
                    ) !== undefined
                  }
                  checkedColor={item.isDefault ? "var(--blue-400)" : undefined}
                  onClick={() => {
                    onChangeFilter(item);
                  }}
                >
                  {item.text}
                </CheckedText>
              );
            })}
          </FilterWrapper>
        ) : null}

        <Divider style={{ marginTop: 0 }} />
      </FilterContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 21px;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 0px;
  min-height: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-contents01);
  overflow: hidden;

  transition: all 0.2s ease;

  &.show__filter {
    height: fit-content;
    min-height: 92px;
    margin-top: 20px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-300);
`;
const FilterWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
  padding: 0 16px;
`;
const FilterDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-200);
`;

const RightContainer = styled.div`
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const FelxRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FelxColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export default FilterView;
