import { DefaultButton } from "lattice_core";
import React from "react";
import styled from "styled-components";
import { getHMOnlyNum } from "../../../../../../../common/commonUtil";

import { ChatingSpeachBubbleProps } from "../useChating";
import Avatar from "./Avatar";

interface Props {
  msg: ChatingSpeachBubbleProps;
  transfromPixel: (value: number) => number;
}
const SBSystem: React.FC<Props> = (props) => {
  const getStartBubble = (text: string, boldText?: string[]) => {
    let resultText = text;
    const boldTextIndexArr = boldText?.map((item) => {
      resultText = resultText.replace(item, "/%s/");
      return item;
    });
    if (boldTextIndexArr) {
      const splitData = resultText.split("/");
      let boldIndex = -1;

      return (
        <div className="bubble__item__start body-14-rg">
          <div>
            {splitData.map((item, idx) => {
              if (
                item === "%s" &&
                boldTextIndexArr &&
                boldTextIndexArr[boldIndex + 1]
              ) {
                boldIndex += 1;
                return (
                  <span key={idx} style={{ fontWeight: "bold" }}>
                    {boldTextIndexArr[boldIndex]}
                  </span>
                );
              }
              return <span key={idx}>{item}</span>;
            })}
          </div>
          <DefaultButton
            className="caption-12-md guide__btn"
            buttonType="filled"
            sizeType="xxsmall"
            text="가이드 보기"
            onClick={() => {
              const link = document.createElement("a");
              link.href =
                "https://latticeguide.notion.site/37cbf4b1d92d41489bb4d828e18b638e?pvs=4";
              link.target = "_black";
              link.click();
            }}
          />
        </div>
      );
    }
    return (
      <div className="bubble__item__start body-14-rg">
        <div>{text}</div>
        <DefaultButton
          className="caption-12-md guide__btn"
          buttonType="filled"
          sizeType="xxsmall"
          text="가이드 보기"
          onClick={() => {
            const link = document.createElement("a");
            link.href =
              "https://brief-music-628.notion.site/37cbf4b1d92d41489bb4d828e18b638e";
            link.target = "_black";
            link.click();
          }}
        />
      </div>
    );
  };

  const getNormalBubble = (text: string, boldText?: string[]) => {
    let resultText = text;
    const boldTextIndexArr = boldText?.map((item) => {
      resultText = resultText.replace(item, "/%s/");
      return item;
    });
    if (boldTextIndexArr) {
      const splitData = resultText.split("/");
      let boldIndex = -1;

      return (
        <div className="bubble__item body-14-rg">
          {splitData.map((item, idx) => {
            if (
              item === "%s" &&
              boldTextIndexArr &&
              boldTextIndexArr[boldIndex + 1]
            ) {
              boldIndex += 1;
              return (
                <span key={idx} style={{ fontWeight: "bold" }}>
                  {boldTextIndexArr[boldIndex]}
                </span>
              );
            }
            return <span key={idx}>{item}</span>;
          })}
        </div>
      );
    }
    return <div className="bubble__item body-14-rg">{text}</div>;
  };

  return (
    <Container
      transfromPixel={props.transfromPixel}
      style={{
        paddingBottom: props.msg.groupIsLast
          ? props.transfromPixel(16)
          : props.transfromPixel(4),
      }}
    >
      <Avatar visible isLatticeBot transfromPixel={props.transfromPixel} />
      <ContentsContainer transfromPixel={props.transfromPixel}>
        <div className="avatar__title caption-12-sb">래티스봇</div>

        <BubbleContainer transfromPixel={props.transfromPixel}>
          <div className="bubble__list">
            {props.msg.type === 1
              ? getStartBubble(
                  props.msg.msgs?.text || "",
                  props.msg.msgs?.boldText
                )
              : getNormalBubble(
                  props.msg.msgs?.text || "",
                  props.msg.msgs?.boldText
                )}
          </div>
          <div className="bubble__time caption-12-rg">
            {getHMOnlyNum(props.msg.createAt)}
          </div>
        </BubbleContainer>
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  gap: ${(props) => props.transfromPixel(8)}px;

  padding-left: ${(props) => props.transfromPixel(24)}px;
  padding-right: ${(props) => props.transfromPixel(6)}px;
`;

const ContentsContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.transfromPixel(4)}px;
  .avatar__title {
    width: 100%;
    color: #5b5d61;
    line-height: ${(props) => props.transfromPixel(20)}px;
  }
`;

const BubbleContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: ${(props) => props.transfromPixel(4)}px;
  .bubble__list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.transfromPixel(4)}px;

    .bubble__item__start {
      width: fit-content;
      max-width: ${(props) => props.transfromPixel(254)}px;
      padding: ${(props) => props.transfromPixel(8)}px
        ${(props) => props.transfromPixel(12)}px;
      background: #f3f4f4;
      display: flex;
      flex-direction: column;
      border-radius: ${(props) => props.transfromPixel(8)}px;
      gap: ${(props) => props.transfromPixel(8)}px;
      border: 1px solid #e3e4e5;

      .guide__btn {
        width: fit-content;
      }
    }

    .bubble__item {
      width: fit-content;
      padding: ${(props) => props.transfromPixel(8)}px
        ${(props) => props.transfromPixel(12)}px;
      background: #ffffff;

      border: 1px solid #e3e4e5;
      border-radius: ${(props) => props.transfromPixel(4)}px;
    }
  }
  .bubble__time {
    color: #5b5d61;
    line-height: ${(props) => props.transfromPixel(20)}px;
  }
`;
export default SBSystem;
