import React from "react";
import styled from "styled-components";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

import { ReactComponent as Plus } from "../../../../../../assets/image/icon_plus.svg";

/**
 * @param id : number타입 그리드에서 순서를 나타낸다. 0 ~ n;
 * @param identificationNumber : 체크 할때 식별할 수 있는 유니크한 id
 * @param data : grid에 보여줘야 하는 데이터
 */
export interface TableGridProps {
  id: number;
  identificationNumber: number;
  data: any;
}

export interface TableGridHeaderProps {
  header: string;
  key: string;
  width: number;

  style?: React.CSSProperties;
  tooltipId?: string;
  customHeaderCell?: (allData?: TableGridProps[]) => JSX.Element;
  customCell?: (
    data: any,
    allData?: any,
    isReadOnly?: boolean,
    disabled?: boolean
  ) => JSX.Element;
}

interface Props {
  header: TableGridHeaderProps[];
  dataList?: TableGridProps[];
  isReadOnly?: boolean;
  disabled?: boolean;
  onClickAddBtn: () => void;
  onClickDeleteBtn: (id: number) => void;
}

export const TableGrid: React.FC<Props> = (props) => {
  const totalWidth =
    props.header
      .map((item) => item.width)
      .reduce((prev, cur) => prev + cur, 0) + 74;
  return (
    <Container>
      <TableHeader
        isReadOnly={props.isReadOnly}
        headers={props.header}
        transfromPixel={(n) => n}
      />
      {!props.disabled &&
        props.dataList &&
        props.dataList.map((item) => {
          return (
            <TableRow
              headerData={props.header}
              rowData={item}
              isReadOnly={props.isReadOnly}
              disabled={props.disabled}
              transfromPixel={(n) => n}
              isDeleteAble={props.dataList && props.dataList.length > 1}
              onClickDeleteBtn={props.onClickDeleteBtn}
            />
          );
        })}
      {!props.disabled && !props.isReadOnly && (
        <AddRow onClick={props.onClickAddBtn} style={{ width: totalWidth }}>
          <Plus />
          <span
            className="body-14-rg"
            style={{ color: "var(--text-inactive)" }}
          >
            추가입력
          </span>
        </AddRow>
      )}

      {props.disabled && !props.isReadOnly && (
        <AddRow style={{ width: totalWidth, cursor: "default" }}>
          <span
            className="body-14-rg"
            style={{ color: "var(--text-inactive)" }}
          >
            작성하시려면 아래 체크를 해제해주세요.
          </span>
        </AddRow>
      )}

      {props.disabled && props.isReadOnly && (
        <AddRow style={{ width: totalWidth, cursor: "default" }}>
          <span
            className="body-14-rg"
            style={{ color: "var(--text-inactive)" }}
          >
            입력한 내용이 없습니다.
          </span>
        </AddRow>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;

const AddRow = styled.div`
  height: 50px;
  padding: 14px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  border-bottom: 1px solid var(--divider-200);
  background-color: var(--background-contents01);

  cursor: pointer;
`;
