import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Sorting } from "../../../../assets/image/new_icon_sorting.svg";
import { ReactComponent as File } from "../../../../assets/image/new_icon_file.svg";

interface Props {
  title: string;
  documentId: number;
  hasOrder?: boolean;

  selectedSortItem?: number;
  selectedSortType?: number;

  transfromPixel: (value: number) => number;
  onOrdering?: (documentId: number, id: number) => void;
}

const CustomHeader: React.FC<Props> = (props) => {
  const {
    title,
    hasOrder,
    documentId,
    selectedSortItem,
    selectedSortType,
    transfromPixel,
    onOrdering,
  } = props;
  const [showOption, updateShowOption] = useState<boolean>(false);
  const orderRef = useRef<HTMLDivElement>(null);

  const itemStyle = {
    height: transfromPixel(40),
    paddingLeft: transfromPixel(16),
    paddingRight: transfromPixel(16),
    gap: transfromPixel(5),
    color: "#1F2022",
  };

  const ballStyle = {
    width: transfromPixel(6),
    minWidth: transfromPixel(6),
    maxWidth: transfromPixel(6),
    height: transfromPixel(6),
    minHeight: transfromPixel(6),
    maxHeight: transfromPixel(6),
  };

  const onClickOrdering = (id: number) => {
    onOrdering && onOrdering(documentId, id);
    updateShowOption(false);
  };

  const handleMouseDown = (event: any) => {
    if (orderRef.current && !orderRef.current.contains(event.target)) {
      updateShowOption(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  });
  return (
    <Container
      className="body-14-rg"
      style={{
        fontSize: transfromPixel(12),
        padding: `0 ${transfromPixel(20)}px`,
        gap: transfromPixel(2),
      }}
    >
      {title}
      {hasOrder && (
        <div
          className={`body-14-rg header__sorting ${
            props.selectedSortItem === props.documentId
              ? "selected__sorting"
              : ""
          } ${showOption ? "sorting__option__show" : ""}`}
          style={{ width: transfromPixel(18), height: transfromPixel(18) }}
          onClick={() => {
            if (selectedSortItem === -1 && selectedSortType === -1)
              updateShowOption((prev) => !prev);
            else {
              onOrdering && onOrdering(-1, -1);
            }
          }}
        >
          <Sorting width={transfromPixel(16)} height={transfromPixel(16)} />
        </div>
      )}
      {showOption && (
        <OrderList
          ref={orderRef}
          className="body-14-rg"
          style={{
            width: transfromPixel(164),
            paddingTop: transfromPixel(8),
            paddingBottom: transfromPixel(8),
          }}
        >
          <OrderListItem
            style={{
              ...itemStyle,
              color: "#96999F",
              cursor: "default",
            }}
          >
            정렬 선택
          </OrderListItem>
          <OrderListItem
            className={`${
              props.selectedSortItem === props.documentId &&
              props.selectedSortType === 0
                ? "selected__option"
                : ""
            }`}
            style={{
              ...itemStyle,
            }}
            onClick={() => onClickOrdering(0)}
          >
            <span>X</span>
            <span>(미제출)</span>
          </OrderListItem>
          <OrderListItem
            className={`${
              props.selectedSortItem === props.documentId &&
              props.selectedSortType === 1
                ? "selected__option"
                : ""
            }`}
            style={{
              ...itemStyle,
            }}
            onClick={() => onClickOrdering(1)}
          >
            <File width={transfromPixel(16)} height={transfromPixel(16)} />
            다운로드
          </OrderListItem>
          <OrderListItem
            className={`${
              props.selectedSortItem === props.documentId &&
              props.selectedSortType === 2
                ? "selected__option"
                : ""
            }`}
            style={{
              ...itemStyle,
            }}
            onClick={() => onClickOrdering(2)}
          >
            <File width={transfromPixel(16)} height={transfromPixel(16)} />
            재제출
          </OrderListItem>
          <OrderListItem
            className={`${
              props.selectedSortItem === props.documentId &&
              props.selectedSortType === 3
                ? "selected__option"
                : ""
            }`}
            style={{
              ...itemStyle,
            }}
            onClick={() => onClickOrdering(3)}
          >
            <Ball style={{ ...ballStyle, background: "#FFD84F" }} />
            확인중
          </OrderListItem>
          <OrderListItem
            className={`${
              props.selectedSortItem === props.documentId &&
              props.selectedSortType === 4
                ? "selected__option"
                : ""
            }`}
            style={{
              ...itemStyle,
            }}
            onClick={() => onClickOrdering(4)}
          >
            <Ball style={{ ...ballStyle, background: "#F6444F" }} />
            반려
          </OrderListItem>
          <OrderListItem
            className={`${
              props.selectedSortItem === props.documentId &&
              props.selectedSortType === 5
                ? "selected__option"
                : ""
            }`}
            style={{
              ...itemStyle,
            }}
            onClick={() => onClickOrdering(5)}
          >
            <Ball style={{ ...ballStyle, background: "#4584FF" }} />
            확정
          </OrderListItem>
        </OrderList>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: #96999f; //text05
  display: flex;
  flex-direction: row;
  align-items: center;

  .header__sorting {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    transition: all 0.2s ease;
    cursor: pointer;

    &.selected__sorting {
      path {
        fill: #4584ff;
      }
    }

    &.sorting__option__show {
      path {
        fill: #4584ff;
      }
    }

    :hover {
      path {
        fill: #4584ff;
      }
    }
  }
`;

const OrderList = styled.div`
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;

  background: #ffffff;
  /* light/shadow500-right */

  box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.1);
  border-radius: 4px;
  user-select: none;
`;

const OrderListItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;

  :nth-child(1) {
    background-color: transparent;
    :hover {
      background-color: transparent;
    }
  }

  &.selected__option {
    background-color: #f6faff;
    :hover {
      background-color: #f6faff;
    }
  }

  :hover {
    background-color: #fafafb;
  }
`;

const Ball = styled.div`
  border-radius: 50%;
`;

export default CustomHeader;
