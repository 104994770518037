import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Sector } from "../Sector";

import { FileType, InvestmentAssessment } from "../../../../../type/data";
import {
  PitchingResultType,
  ResultType,
} from "../../../pre-pitching-detail/interface/PrePitchingDetail.interface";
import { DefaultButton } from "lattice_core";
import { TitleBox } from "../../../../../components-2.0v/box/TitleBox";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import ResultItem from "../../../pre-pitching-detail/desktop/components/result/ResultItem";
import {
  viewPdfPopup,
  viewPdfPopupNoDownload,
} from "../../../../../common/commonUtil";

interface Props {
  businessPlanFile?: FileType;
  pitchingResult?: PitchingResultType[];
}

export const Section4: React.FC<Props> = (props: Props) => {
  const { businessPlanFile, pitchingResult } = props;
  const [data, updateData] = useState<ResultType[]>([]);

  useEffect(() => {
    if (pitchingResult && pitchingResult.length > 0) {
      if (pitchingResult[0].data) {
        const target = pitchingResult[0].data.find(
          (item) => item.title === "피칭 종합 의견"
        );
        const other = pitchingResult[0].data.filter(
          (item) => item.title !== "피칭 종합 의견"
        );
        if (target) {
          updateData([target, ...other]);
        }
      }
    }
  }, [pitchingResult]);
  return (
    <Container>
      <Sector title="IR자료" id="Section4_1">
        <DefaultButton
          text="IR자료 보기"
          onClick={() => {
            viewPdfPopupNoDownload(businessPlanFile?.key || "");
          }}
        />
      </Sector>
      <Sector title="피칭 결과" id="Section4_2">
        <ResultContainer
          style={{
            paddingBottom: 60,
          }}
        >
          {pitchingResult &&
            pitchingResult.map((item, index) => {
              return (
                <FlexColumn key={index}>
                  <TitleContainer>
                    <Title className="heading-18-md">{item.title}</Title>
                    {item.description && (
                      <RowTitleBox title="설명" style={{ padding: "0 28px" }}>
                        <Description className="body-14-rg">
                          {item.description}
                        </Description>
                      </RowTitleBox>
                    )}
                    {item.investmentInfo && (
                      <RowTitleBox
                        title="투자 정보"
                        style={{ padding: "0 28px" }}
                      >
                        <Description className="body-14-rg">
                          {item.investmentInfo}
                        </Description>
                      </RowTitleBox>
                    )}
                    {item.companyInfo && (
                      <RowTitleBox
                        title="기업 정보"
                        style={{ padding: "0 28px" }}
                      >
                        <Description className="body-14-rg">
                          {item.companyInfo}
                        </Description>
                      </RowTitleBox>
                    )}
                  </TitleContainer>
                  {data.map(
                    (data, idx) =>
                      data && (
                        <ResultItem userInfoHidden key={idx} item={data} />
                      )
                  )}
                </FlexColumn>
              );
            })}
        </ResultContainer>
      </Sector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ResultContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
  border-bottom: 1px solid var(--gray-200);
  background-color: var(--white);
`;

const Title = styled.div`
  width: 100%;
  padding: 14px 28px;
  border-bottom: 1px solid var(--gray-200);
  margin-bottom: 19px;
`;

const Description = styled.pre`
  width: 100%;
  height: auto;

  padding-top: 9px;

  word-break: break-all;
  white-space: break-spaces;

  color: var(--text-01, #1f2022);
`;
