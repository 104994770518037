import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

export interface RealDataTableHeaderProps {
  key: string;
  width: number;
  headerTitle: string;
  type: "number" | "float" | "text";
}

interface Props {
  headers: RealDataTableHeaderProps[];
  viewerScale: number;
  transfromPixel: (value: number) => number;
}

const RealDataHeader: React.FC<Props> = ({
  headers,
  viewerScale,
  transfromPixel,
}) => {
  return (
    <Container>
      {headers.map((item, index) => {
        return (
          <CellConatiner
            key={index}
            className={`body-14-rg`}
            style={{
              width: transfromPixel(item.width * viewerScale),
              minWidth: transfromPixel(item.width * viewerScale),
              maxWidth: transfromPixel(item.width * viewerScale),
              height: transfromPixel(34 * viewerScale),
              minHeight: transfromPixel(34 * viewerScale),
              maxHeight: transfromPixel(34 * viewerScale),
              paddingLeft: transfromPixel(20 * viewerScale),
              paddingRight: transfromPixel(20 * viewerScale),
              fontSize: transfromPixel(14 * viewerScale),
              borderRight:
                index !== headers.length - 1
                  ? `1px solid #e3e4e5`
                  : `1px solid transparent`,
              borderTopLeftRadius:
                index === 0 ? transfromPixel(4 * viewerScale) : 0,
              borderTopRightRadius:
                index === headers.length - 1
                  ? transfromPixel(4 * viewerScale)
                  : 0,
            }}
          >
            {item.headerTitle}
          </CellConatiner>
        );
      })}
    </Container>
  );
};

const Container = styled.tr`
  width: auto;
  display: flex;
  flex-direction: row;
  z-index: 10;

  :nth-child(1) {
  }
  :nth-last-child(1) {
    border-top-right-radius: 4px;
  }
`;

const CellConatiner = styled.td`
  width: 100%;
  cursor: default;
  background-color: #f6faff;
  border-bottom: 1px solid #e3e4e5;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #96999f;
`;

export default RealDataHeader;
