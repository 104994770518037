import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

interface Props {
  className?: string;
  style?: React.CSSProperties;
}
export const BoxComponent: React.FC<Props> = (props) => {
  const { className, children, style } = props;
  return (
    <Container className={className} style={style}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents-01, #fff);

  padding: 28px;
  gap: 10px;
`;
