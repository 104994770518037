import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IADebt } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";

import { RemoveComma, getYMD } from "../../../../../../common/commonUtil";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IADebt[];
  onDataChange: (data: IADebt[]) => void;
}

export const DebtTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<IADebt[] | undefined>(undefined);
  const headers: TableGridHeaderProps[] = [
    {
      header: "기관",
      key: "lender",
      width: 140,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="OO은행"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.lender = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "차입종류",
      key: "lenderType",
      width: 172,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="대출, 담보, 가수금"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.lenderType = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "이자율(%)",
      key: "interestRate",
      width: 108,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="percent"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="0.00"
            onBlur={(value) => {
              if (!dataList) return;

              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.interestRate = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "차입 금액 (백만 원)",
      key: "amount",
      width: 160,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1,000"
            onBlur={(value) => {
              if (!dataList) return;

              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.amount = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "차입 일자",
      key: "dateBorrowed",
      width: 164,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            isMonth
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultDate={data}
            onChangeDate={(date) => {
              if (!dataList) return;
              rowData.dateBorrowed = getYMD(date);
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "만기 일자",
      key: "expirationDate",
      width: 164,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            isMonth
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultDate={data}
            onChangeDate={(date) => {
              if (!dataList) return;
              rowData.expirationDate = getYMD(date);
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "비고 (옵션)",
      key: "note",
      width: 218,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="해지율, 연장 등"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.note = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newData: IADebt = {};
    const newDataList: IADebt[] = [...(dataList || []), newData];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    updateDataList(props.dataList);
  }, [props.dataList]);

  useEffect(() => {
    if (dataList) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
