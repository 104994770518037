import React from "react";
import styled from "styled-components";

import { InvestmentPerformanceReview } from "../../organism/InvestmentPerformanceReview";

import { PerformanceManageSection } from "./PerformanceManageSection";

interface Props {
  selectedFundAccountId: number[];
  selectedIndustryId: number[];
}
export const PerformanceSection: React.FC<Props> = (props) => {
  const { selectedFundAccountId, selectedIndustryId } = props;

  return (
    <Container>
      <SectionHeader className="heading-20-sb">
        <span>투자성과</span>
      </SectionHeader>
      <FlexColumn style={{ gap: 16 }}>
        <InvestmentPerformanceReview
          selectedFundAccountId={selectedFundAccountId}
          selectedIndustryId={selectedIndustryId}
        />
        <PerformanceManageSection
          selectedFundAccountId={selectedFundAccountId}
          selectedIndustryId={selectedIndustryId}
        />
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 12px;
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const SectionGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 24px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 24px;
`;
