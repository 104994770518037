import React, { useEffect, useRef, useState } from "react";
import { getRiskManagementMinute } from "../../../../api/repository/portfolio/PortfolioRepository";
import {
  MinuteComponentProps,
  MinutePDFProps,
  MinutePageProps,
  RMPortfolio,
  RMRIskManagementReport,
  RiskManagementMinuteData,
} from "../interface/Minutes.interface";
import { useNavigate } from "react-router-dom";
import { getNewDate, getYMD, getYMDHM } from "../../../../common/commonUtil";
import { Heading1 } from "../components/Heading1";
import { Heading2 } from "../components/Heading2";
import { Heading3 } from "../components/Heading3";
import { Description } from "../components/Description";
import { Label } from "../components/Label";
import { InfoDescription } from "../components/InfoDescription";
import { CMSHeader } from "../components/company-management-status/CMSHeader";
import { CMSRow } from "../components/company-management-status/CMSRow";
import { CMSFooter } from "../components/company-management-status/CMSFooter";
import { DCHeader } from "../components/damaged-company/DCHeader";
import { DCRow } from "../components/damaged-company/DCRow";
import { DCFooter } from "../components/damaged-company/DCFooter";
import { RMOHeader } from "../components/\brisk-management-overview/RMOHeader";
import { RMORow } from "../components/\brisk-management-overview/RMORow";
import { HeadingLabel1 } from "../components/HeadingLabel1";
import { SummaryFinancialInfo } from "../components/SummaryFinancialInfo";
import { ReasonSolution } from "../components/ReasonSolution";
import { RiskOpinion } from "../components/RiskOpinion";
import { ExaminerSignature } from "../components/ExaminerSignature";
import { gapi } from "gapi-script";
import PDFPage from "../components/PDFPage";
import { ReactComponent as FileUpload } from "../../../../assets/image/icon_file_upload.svg";

import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { Reason } from "../components/Reason";

import axios from "axios";
import * as api from "../../../../api/api";
import {
  LOGO_LARGE,
  LOGO_SMALL,
  getLogo,
} from "../../../../api/repository/accelerator/AcceleratorRepository";
import config from "../../../../config";

const useMinutes = (year: number, quarterNum: number) => {
  const navigate = useNavigate();
  const refArray = useRef<(HTMLDivElement | null)[]>([]);
  const divRefArr = useRef<(HTMLDivElement | null)[]>([]);
  const pdfDataWidthPageNum = useRef<MinutePDFProps[]>([]);
  const minuteCanvas = useRef<HTMLCanvasElement[]>([]);

  const [pdfDownloading, setPdfDownloading] = useState<boolean>(false);

  const [logoImage, setLogoImage] = useState<string | undefined>(undefined);
  const [rmMinuteData, setRmMinuteData] = useState<
    RiskManagementMinuteData | undefined
  >(undefined);
  const [pdfData, setPdfData] = useState<MinutePDFProps[] | undefined>(
    undefined
  );
  const [selectedLoadPdf, setSelectedLoadPdf] = useState<
    MinutePDFProps | undefined
  >(undefined);

  const [pdfLoaded, setPdfLoaded] = useState<boolean>(false);

  const [componentData, setComponentData] = useState<MinuteComponentProps[]>(
    []
  );
  const [pageData, setPageData] = useState<MinutePageProps[]>([]);
  const [selectedMinutePage, setSelectedMinutePage] = useState<
    MinutePageProps | undefined
  >(undefined);

  //pdf다운로드 관련
  const [progressIcon, updateProgressIcon] = useState<
    React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined
  >(FileUpload);
  const [fileDonwloading, setFilDonwloading] = useState<boolean>(false);
  const [openProgress, updateOpenProgress] = useState<boolean>(false);
  const [completePage, updateCompltePage] = useState<number>(0);
  const [progressFail, updateProgressFail] = useState<boolean>(false);
  const [progressMsg, updateProgressMsg] = useState<string>("");
  const [readyForCreatePdf, setReadyForCreatePdf] = useState<boolean>(false);

  const onClickExit = () => {
    navigate(-1);
  };

  const getComponents = (data: MinuteComponentProps, index: number) => {
    switch (data.type) {
      case "heading":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <Heading1 header={data.data?.header || ""} style={data.style} />
          </div>
        );
      case "heading2":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <Heading2 header={data.data?.header || ""} style={data.style} />
          </div>
        );
      case "heading3":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <Heading3 header={data.data?.header || ""} style={data.style} />
          </div>
        );
      case "heading_label_1":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <HeadingLabel1
              header={data.data?.header || ""}
              label={data.data?.label || ""}
              style={data.style}
            />
          </div>
        );
      case "description":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <Description
              description={data.data?.description || ""}
              style={data.style}
            />
          </div>
        );
      case "label":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <Label label={data.data?.label || ""} style={data.style} />
          </div>
        );
      case "info_description":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <InfoDescription
              description={data.data?.description || ""}
              style={data.style}
            />
          </div>
        );
      case "company_management_status_header":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <CMSHeader style={data.style} />
          </div>
        );
      case "company_management_status_row":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <CMSRow
              companyManagementStatus={data.data?.companyManagementStatus}
              style={data.style}
            />
          </div>
        );
      case "company_management_status_footer_sum":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <CMSFooter
              companyManagementStatusSum={data.data?.companyManagementStatusSum}
              style={data.style}
            />
          </div>
        );
      case "damaged_company_header":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <DCHeader style={data.style} />
          </div>
        );
      case "damaged_company_row":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <DCRow
              damagedCompany={data.data?.damagedCompany}
              style={data.style}
            />
          </div>
        );
      case "damaged_company_footer_sum":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <DCFooter
              damagedCompanyFinancialStatementValuationSum={
                data.data?.damagedCompanyFinancialStatementValuationSum
              }
              style={data.style}
            />
          </div>
        );
      case "risk_management_overview_header":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <RMOHeader
              style={data.style}
              riskManagementOverviewHeader={
                data.data?.riskManagementOverviewHeader
              }
            />
          </div>
        );
      case "risk_management_overview_row":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <RMORow
              riskManagementOverview={data.data?.riskManagementOverview}
              style={data.style}
            />
          </div>
        );
      case "summary_financial_info":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <SummaryFinancialInfo
              data={data.data?.summaryFinancialInfo}
              style={data.style}
            />
          </div>
        );
      case "reason_solution":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <ReasonSolution
              reasonSolution={data.data?.reasonSolution}
              style={data.style}
            />
          </div>
        );
      case "reason":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <Reason
              reasonSolution={data.data?.reasonSolution}
              style={data.style}
            />
          </div>
        );
      case "risk_opinion":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <RiskOpinion
              riskOpinion={data.data?.riskOpinion}
              style={data.style}
            />
          </div>
        );
      case "examiner_signature":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <ExaminerSignature
              signature={data.data?.signature}
              style={data.style}
              onLoaded={(signUrl) => {
                if (data.data && data.data.signature) {
                  data.data.signature.signUrl = signUrl;
                }
              }}
            />
          </div>
        );
      case "pdf_page":
        return (
          <div
            ref={(e) => {
              divRefArr.current[index] = e;
            }}
          >
            <PDFPage
              file={data.data?.pdfPage?.blobFile}
              pdfImage={data.data?.pdfPage?.pageImage}
              pageNum={data.data?.pdfPage?.pageNum}
            />
          </div>
        );
    }

    return null;
  };

  const getAddCommponent = (
    data: MinuteComponentProps
  ): { component: MinuteComponentProps; height: number } | undefined => {
    switch (data.type) {
      case "company_management_status_row":
      case "company_management_status_footer_sum":
        return {
          component: {
            type: "company_management_status_header",
            style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
          },
          height: 32,
        };
      case "damaged_company_row":
      case "damaged_company_footer_sum":
        return {
          component: {
            type: "damaged_company_header",
            style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
          },
          height: 32,
        };
      case "risk_management_overview_row":
        return {
          component: {
            type: "risk_management_overview_header",
            style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
            data: {
              riskManagementOverviewHeader: {
                twoYearsAgo:
                  quarterNum === 4
                    ? `${`${year - 1}`.slice(2)}년`
                    : `${`${year - 2}`.slice(2)}년`,
                oneYearsAgo:
                  quarterNum === 4
                    ? `${`${year}`.slice(2)}년`
                    : `${`${year - 1}`.slice(2)}년`,
              },
            },
          },
          height: 52,
        };
      default:
        return undefined;
    }
  };

  const setMinuteData = (data: RiskManagementMinuteData) => {
    const objArr: MinuteComponentProps[] = [];

    const originRiskManagementReport: {
      cur?: RMRIskManagementReport;
      prev?: RMRIskManagementReport;
    } = {};
    const fundRiskManagementReport: {
      cur?: RMRIskManagementReport;
      prev?: RMRIskManagementReport;
    } = {};

    const curY = year;
    const curQ = quarterNum;
    const prevY = quarterNum === 2 ? year - 1 : year;
    const prevQ = quarterNum === 2 ? 4 : 2;

    const currentReport = data.riskManagementReport.find(
      (item) =>
        `${item.year}` === `${curY}` && `${item.quarterNum}` === `${curQ}`
    );
    const prevReport = data.riskManagementReport.find(
      (item) =>
        `${item.year}` === `${prevY}` && `${item.quarterNum}` === `${prevQ}`
    );

    if (currentReport) {
      originRiskManagementReport.cur = {
        ...currentReport,
        riskManagementReport: currentReport.riskManagementReport
          .filter((item) => !item.isFund)
          .sort((a, b) => (b.invest || 0) - (a.invest || 0)),
        riskManagement: currentReport.riskManagement.filter(
          (item) => !item.isFund
        ),
      };
      fundRiskManagementReport.cur = {
        ...currentReport,
        riskManagementReport: currentReport.riskManagementReport
          .filter((item) => item.isFund)
          .sort((a, b) => (b.invest || 0) - (a.invest || 0)),
        riskManagement: currentReport.riskManagement.filter(
          (item) => item.isFund
        ),
      };
    }

    if (prevReport) {
      originRiskManagementReport.prev = {
        ...prevReport,
        riskManagementReport: prevReport.riskManagementReport
          .filter((item) => !item.isFund)
          .sort((a, b) => (b.invest || 0) - (a.invest || 0)),
        riskManagement: prevReport.riskManagement.filter(
          (item) => !item.isFund
        ),
      };
      fundRiskManagementReport.prev = {
        ...prevReport,
        riskManagementReport: prevReport.riskManagementReport
          .filter((item) => item.isFund)
          .sort((a, b) => (b.invest || 0) - (a.invest || 0)),
        riskManagement: prevReport.riskManagement.filter((item) => item.isFund),
      };
    }

    //본계정 정리본
    if (originRiskManagementReport.cur !== undefined) {
      const curDate = `${year}년 ${quarterNum === 2 ? "상반기" : "온기"}`;
      const prevDate = `${quarterNum === 2 ? year - 1 : year}년 ${
        quarterNum === 2 ? "온기" : "상반기"
      }`;
      objArr.push({
        type: "heading",
        isNewPage: true,
        style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
        data: { header: `본계정 (${config.companyName})` },
      });
      objArr.push({
        type: "description",
        style: { marginTop: 32, marginLeft: 24, marginRight: 24 },
        data: {
          description: `${curDate} 기준 투자육성관리 리스크관리위원회 진행 결과는 아래와 같습니다.`,
        },
      });
      if (originRiskManagementReport.cur) {
        objArr.push({
          type: "label",
          style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
          data: {
            label: `${curDate}`,
          },
        });

        objArr.push({
          type: "info_description",
          style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
          data: {
            description: "소속별 기업 관리 현황을 나타냅니다.",
          },
        });
        objArr.push({
          type: "company_management_status_header",
          style: { marginTop: 8, marginLeft: 24, marginRight: 24 },
          data: {},
        });

        const total = {
          invest: 0,
          previousDamage: 0,
          target: 0,
          damage: 0,
        };
        originRiskManagementReport.cur?.riskManagementReport.forEach((item) => {
          total.invest += item.invest || 0;
          total.previousDamage += item.previousDamage || 0;
          total.target += item.target || 0;
          total.damage += item.damage || 0;
          objArr.push({
            type: "company_management_status_row",
            style: { marginLeft: 24, marginRight: 24 },
            data: {
              companyManagementStatus: {
                structureName: item.organization,
                investmentedCompanyCount: item.invest || 0,
                prevDamagedCompanyCount: item.previousDamage || 0,
                evaluatedCompanyCount: item.target || 0,
                curDamagedCompanyCount: item.damage || 0,
              },
            },
          });
        });
        objArr.push({
          type: "company_management_status_footer_sum",
          style: { marginLeft: 24, marginRight: 24 },
          data: {
            companyManagementStatusSum: {
              investmentedCompanySum: total.invest,
              prevDamagedCompanySum: total.previousDamage,
              evaluatedCompanySum: total.target,
              curDamagedCompanySum: total.damage,
            },
          },
        });
        if (originRiskManagementReport.cur.riskManagement.length > 0) {
          objArr.push({
            type: "info_description",
            style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
            data: {
              description: `${curDate} 기준 손상 기업에 관한 정보입니다.`,
            },
          });
          objArr.push({
            type: "damaged_company_header",
            style: { marginTop: 8, marginLeft: 24, marginRight: 24 },
          });
          let totalAmount = 0;
          originRiskManagementReport.cur.riskManagement.forEach(
            (item, index) => {
              totalAmount += item.amount || 0;
              objArr.push({
                type: "damaged_company_row",
                style: { marginLeft: 24, marginRight: 24 },
                data: {
                  damagedCompany: {
                    no: index + 1,
                    structureName: item.organization,
                    companyName: item.companyName,
                    damageIssue: item.riskIssue,
                    financialStatementValuation: item.amount || 0,
                  },
                },
              });
            }
          );
          objArr.push({
            type: "damaged_company_footer_sum",
            style: { marginLeft: 24, marginRight: 24 },
            data: {
              damagedCompanyFinancialStatementValuationSum: totalAmount,
            },
          });
        }
      }
      if (originRiskManagementReport.prev) {
        objArr.push({
          type: "label",
          style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
          data: {
            label: `${prevDate}`,
          },
        });

        objArr.push({
          type: "info_description",
          style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
          data: {
            description: "본부별 기업 관리 현황을 나타냅니다.",
          },
        });
        objArr.push({
          type: "company_management_status_header",
          style: { marginTop: 8, marginLeft: 24, marginRight: 24 },
          data: {},
        });

        const total = {
          invest: 0,
          previousDamage: 0,
          target: 0,
          damage: 0,
        };
        originRiskManagementReport.prev.riskManagementReport.forEach((item) => {
          total.invest += item.invest || 0;
          total.previousDamage += item.previousDamage || 0;
          total.target += item.target || 0;
          total.damage += item.damage || 0;
          objArr.push({
            type: "company_management_status_row",
            style: { marginLeft: 24, marginRight: 24 },
            data: {
              companyManagementStatus: {
                structureName: item.organization,
                investmentedCompanyCount: item.invest || 0,
                prevDamagedCompanyCount: item.previousDamage || 0,
                evaluatedCompanyCount: item.target || 0,
                curDamagedCompanyCount: item.damage || 0,
              },
            },
          });
        });
        objArr.push({
          type: "company_management_status_footer_sum",
          style: { marginLeft: 24, marginRight: 24 },
          data: {
            companyManagementStatusSum: {
              investmentedCompanySum: total.invest,
              prevDamagedCompanySum: total.previousDamage,
              evaluatedCompanySum: total.target,
              curDamagedCompanySum: total.damage,
            },
          },
        });
        if (originRiskManagementReport.prev.riskManagement.length > 0) {
          objArr.push({
            type: "info_description",
            style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
            data: {
              description: `${prevDate} 기준 손상 기업에 관한 정보입니다.`,
            },
          });
          objArr.push({
            type: "damaged_company_header",
            style: { marginTop: 8, marginLeft: 24, marginRight: 24 },
          });
          let totalAmount = 0;
          originRiskManagementReport.prev.riskManagement.forEach(
            (item, index) => {
              totalAmount += item.amount || 0;
              objArr.push({
                type: "damaged_company_row",
                style: { marginLeft: 24, marginRight: 24 },
                data: {
                  damagedCompany: {
                    no: index + 1,
                    structureName: item.organization,
                    companyName: item.companyName,
                    damageIssue: item.riskIssue,
                    financialStatementValuation: item.amount || 0,
                  },
                },
              });
            }
          );
          objArr.push({
            type: "damaged_company_footer_sum",
            style: { marginLeft: 24, marginRight: 24 },
            data: {
              damagedCompanyFinancialStatementValuationSum: totalAmount,
            },
          });
        }
      }
    }
    //펀드 정리본
    if (fundRiskManagementReport.cur !== undefined) {
      const curDate = `${year}년 ${quarterNum === 2 ? "상반기" : "온기"}`;
      const prevDate = `${quarterNum === 2 ? year - 1 : year}년 ${
        quarterNum === 2 ? "온기" : "상반기"
      }`;
      objArr.push({
        type: "heading",
        isNewPage: true,
        style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
        data: { header: "펀드계정" },
      });
      objArr.push({
        type: "description",
        style: { marginTop: 32, marginLeft: 24, marginRight: 24 },
        data: {
          description: `${curDate} 기준 투자육성관리 리스크관리위원회 진행 결과는 아래와 같습니다.`,
        },
      });
      if (fundRiskManagementReport.cur) {
        objArr.push({
          type: "label",
          style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
          data: {
            label: `${curDate}`,
          },
        });

        objArr.push({
          type: "info_description",
          style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
          data: {
            description: "본부별 기업 관리 현황을 나타냅니다.",
          },
        });
        objArr.push({
          type: "company_management_status_header",
          style: { marginTop: 8, marginLeft: 24, marginRight: 24 },
          data: {},
        });

        const total = {
          invest: 0,
          previousDamage: 0,
          target: 0,
          damage: 0,
        };
        fundRiskManagementReport.cur.riskManagementReport.forEach((item) => {
          total.invest += item.invest || 0;
          total.previousDamage += item.previousDamage || 0;
          total.target += item.target || 0;
          total.damage += item.damage || 0;
          objArr.push({
            type: "company_management_status_row",
            style: { marginLeft: 24, marginRight: 24 },
            data: {
              companyManagementStatus: {
                structureName: item.organization,
                investmentedCompanyCount: item.invest || 0,
                prevDamagedCompanyCount: item.previousDamage || 0,
                evaluatedCompanyCount: item.target || 0,
                curDamagedCompanyCount: item.damage || 0,
              },
            },
          });
        });
        objArr.push({
          type: "company_management_status_footer_sum",
          style: { marginLeft: 24, marginRight: 24 },
          data: {
            companyManagementStatusSum: {
              investmentedCompanySum: total.invest,
              prevDamagedCompanySum: total.previousDamage,
              evaluatedCompanySum: total.target,
              curDamagedCompanySum: total.damage,
            },
          },
        });
        if (fundRiskManagementReport.cur.riskManagement.length > 0) {
          objArr.push({
            type: "info_description",
            style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
            data: {
              description: `${curDate} 기준 손상 기업에 관한 정보입니다.`,
            },
          });
          objArr.push({
            type: "damaged_company_header",
            style: { marginTop: 8, marginLeft: 24, marginRight: 24 },
          });
          let totalAmount = 0;
          fundRiskManagementReport.cur.riskManagement.forEach((item, index) => {
            totalAmount += item.amount || 0;
            objArr.push({
              type: "damaged_company_row",
              style: { marginLeft: 24, marginRight: 24 },
              data: {
                damagedCompany: {
                  no: index + 1,
                  structureName: item.organization,
                  companyName: item.companyName,
                  damageIssue: item.riskIssue,
                  financialStatementValuation: item.amount || 0,
                },
              },
            });
          });
          objArr.push({
            type: "damaged_company_footer_sum",
            style: { marginLeft: 24, marginRight: 24 },
            data: {
              damagedCompanyFinancialStatementValuationSum: totalAmount,
            },
          });
        }
      }
      if (fundRiskManagementReport.prev) {
        objArr.push({
          type: "label",
          style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
          data: {
            label: `${prevDate}`,
          },
        });

        objArr.push({
          type: "info_description",
          style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
          data: {
            description: "본부별 기업 관리 현황을 나타냅니다.",
          },
        });
        objArr.push({
          type: "company_management_status_header",
          style: { marginTop: 8, marginLeft: 24, marginRight: 24 },
          data: {},
        });

        const total = {
          invest: 0,
          previousDamage: 0,
          target: 0,
          damage: 0,
        };
        fundRiskManagementReport.prev.riskManagementReport.forEach((item) => {
          total.invest += item.invest || 0;
          total.previousDamage += item.previousDamage || 0;
          total.target += item.target || 0;
          total.damage += item.damage || 0;
          objArr.push({
            type: "company_management_status_row",
            style: { marginLeft: 24, marginRight: 24 },
            data: {
              companyManagementStatus: {
                structureName: item.organization,
                investmentedCompanyCount: item.invest || 0,
                prevDamagedCompanyCount: item.previousDamage || 0,
                evaluatedCompanyCount: item.target || 0,
                curDamagedCompanyCount: item.damage || 0,
              },
            },
          });
        });
        objArr.push({
          type: "company_management_status_footer_sum",
          style: { marginLeft: 24, marginRight: 24 },
          data: {
            companyManagementStatusSum: {
              investmentedCompanySum: total.invest,
              prevDamagedCompanySum: total.previousDamage,
              evaluatedCompanySum: total.target,
              curDamagedCompanySum: total.damage,
            },
          },
        });
        if (fundRiskManagementReport.prev.riskManagement.length > 0) {
          objArr.push({
            type: "info_description",
            style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
            data: {
              description: `${prevDate} 기준 손상 기업에 관한 정보입니다.`,
            },
          });
          objArr.push({
            type: "damaged_company_header",
            style: { marginTop: 8, marginLeft: 24, marginRight: 24 },
          });
          let totalAmount = 0;
          fundRiskManagementReport.prev.riskManagement.forEach(
            (item, index) => {
              totalAmount += item.amount || 0;
              objArr.push({
                type: "damaged_company_row",
                style: { marginLeft: 24, marginRight: 24 },
                data: {
                  damagedCompany: {
                    no: index + 1,
                    structureName: item.organization,
                    companyName: item.companyName,
                    damageIssue: item.riskIssue,
                    financialStatementValuation: item.amount || 0,
                  },
                },
              });
            }
          );
          objArr.push({
            type: "damaged_company_footer_sum",
            style: { marginLeft: 24, marginRight: 24 },
            data: {
              damagedCompanyFinancialStatementValuationSum: totalAmount,
            },
          });
        }
      }
    }

    if (data.portfolio.length > 0) {
      //포트폴리오
      const a = data.portfolio.filter(
        (item) =>
          item.riskManagement !== undefined &&
          item.riskManagement.riskStatus &&
          [5, 6].includes(item.riskManagement.riskStatus.id)
      );
      const b = data.portfolio.filter(
        (item) =>
          item.riskManagement !== undefined &&
          item.riskManagement.riskStatus &&
          ![5, 6].includes(item.riskManagement.riskStatus.id) &&
          item.riskManagement.riskIssueList !== undefined &&
          item.riskManagement.riskIssueList.length > 0
      );

      const c = data.portfolio.filter(
        (item) =>
          item.riskManagement !== undefined &&
          item.riskManagement.riskStatus &&
          [1, 2, 3, 4].includes(item.riskManagement.riskStatus.id) &&
          item.riskManagement.riskIssueList !== undefined &&
          item.riskManagement.riskIssueList.length === 0
      );

      const d = data.portfolio.filter(
        (item) =>
          item.riskManagement === undefined &&
          item.damagedAt === undefined &&
          !item.fullExit
      );
      const e = data.portfolio.filter(
        (item) => item.damagedAt === undefined && item.fullExit
      );

      const f = data.portfolio.filter(
        (item) => item.damagedAt !== undefined && !item.fullExit
      );
      const portfolio = [...a, ...b, ...c, ...d, ...e, ...f];
      const targetPortfolio = [...a, ...b];

      objArr.push({
        type: "heading_label_1",
        isNewPage: true,
        style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
        data: {
          header: "리스크관리 위원회 총괄표",
          label: `${portfolio.length}개의 기업`,
        },
      });

      objArr.push({
        type: "info_description",
        style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
        data: {
          description:
            "손상 기업 > 손상은 아니지만 해당 이슈가 있는 기업 > 해당 이슈가 없는 기업 순으로 나열됩니다.",
        },
      });

      console.log(`${year - 1}`.slice(2) + "년");
      objArr.push({
        type: "risk_management_overview_header",
        style: { marginTop: 8, marginLeft: 24, marginRight: 24 },
        data: {
          riskManagementOverviewHeader: {
            twoYearsAgo:
              quarterNum === 4
                ? `${`${year - 1}`.slice(2)}년`
                : `${`${year - 2}`.slice(2)}년`,
            oneYearsAgo:
              quarterNum === 4
                ? `${`${year}`.slice(2)}년`
                : `${`${year - 1}`.slice(2)}년`,
          },
        },
      });
      portfolio.forEach((item, index) => {
        const finalLastFinance = item.pfFinancialStatement?.find(
          (finance) =>
            `${finance.year}` === `${quarterNum === 4 ? year : year - 1}` &&
            `${finance.quarterNum}` === "1"
        );
        const currentFinance = item.pfFinancialStatement?.find(
          (finance) =>
            `${finance.year}` === `${year}` &&
            `${finance.quarterNum}` === `${quarterNum === 4 ? "4" : "1"}`
        );
        const twoYearsAgo = finalLastFinance
          ? finalLastFinance.totalEquity < 0
          : undefined;
        const oneYearsAgo = currentFinance
          ? currentFinance.totalEquity < 0
          : undefined;

        objArr.push({
          type: "risk_management_overview_row",
          style: { marginLeft: 24, marginRight: 24 },
          data: {
            riskManagementOverview: {
              no: index + 1,
              companyName: item.companyName,
              firstInvestmentData: getYMD(item.firstPaymentDate),
              mainParticipant: item.participantName,
              capitalImpairment: {
                twoYearsAgo: twoYearsAgo,
                oneYearsAgo: oneYearsAgo,
              },
              damageIssue: item.riskManagement
                ? item.riskManagement.riskIssueList
                  ? item.riskManagement.riskIssueList
                      .map((issue) => issue.riskIssue.name)
                      .join(",")
                  : ""
                : "",
              isDamaged:
                item.riskManagement &&
                item.riskManagement.riskStatus &&
                [5, 6].includes(item.riskManagement.riskStatus.id),
              isPrevDamaged: item.damagedAt !== undefined || item.fullExit,
            },
          },
        });
      });

      targetPortfolio.forEach((item) => {
        const closedCompanyIssue = item.riskManagement?.riskIssueList.find(
          (item) => item.riskIssue.id === 7
        );

        if (closedCompanyIssue && closedCompanyIssue.result) {
          //폐업
          objArr.push({
            type: "heading_label_1",
            isNewPage: true,
            style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
            data: {
              header: item.companyName,
              label: item.riskManagement?.riskStatus?.name || "",
            },
          });
          objArr.push({
            type: "heading2",
            style: { marginTop: 36, marginLeft: 24, marginRight: 24 },
            data: {
              header: "폐업진행 관련 담당 심사역 의견",
            },
          });
          objArr.push({
            type: "reason",
            style: { marginTop: 16, marginLeft: 24, marginRight: 24 },
            data: {
              reasonSolution: {
                header: `폐업진행`,
                reason: closedCompanyIssue.comment || "",
              },
            },
          });
        } else {
          //폐업진행 아님
          objArr.push({
            type: "heading_label_1",
            isNewPage: true,
            style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
            data: {
              header: item.companyName,
              label: item.riskManagement?.riskStatus?.name || "",
            },
          });

          if (item.pfFinancialStatement !== undefined) {
            objArr.push({
              type: "heading2",
              style: { marginTop: 32, marginLeft: 24, marginRight: 24 },
              data: {
                header: "1. 요약 재무 정보",
              },
            });

            if (item.pfFinancialStatement[0] !== undefined) {
              objArr.push({
                type: "summary_financial_info",
                style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
                data: {
                  summaryFinancialInfo: item.pfFinancialStatement[0],
                },
              });
            }

            if (item.pfFinancialStatement[1] !== undefined) {
              objArr.push({
                type: "summary_financial_info",
                style: { marginTop: 24, marginLeft: 24, marginRight: 24 },
                data: {
                  summaryFinancialInfo: item.pfFinancialStatement[1],
                },
              });
            }
          }

          if (item.riskManagement) {
            if (item.riskManagement.riskIssueList) {
              objArr.push({
                type: "heading2",
                style: { marginTop: 36, marginLeft: 24, marginRight: 24 },
                data: {
                  header: item.pfFinancialStatement
                    ? "2. 이슈 및 해결 방안"
                    : "1. 이슈 및 해결 방안",
                },
              });
              item.riskManagement.riskIssueList.forEach((issue, index) => {
                objArr.push({
                  type: "reason_solution",
                  style: { marginTop: 16, marginLeft: 24, marginRight: 24 },
                  data: {
                    reasonSolution: {
                      header: `0${index + 1}. ${issue.riskIssue.name}`,
                      reason: issue.comment,
                      solution: issue.solution,
                    },
                  },
                });
              });
            }
            if (item.riskManagement.riskOpinionList) {
              objArr.push({
                type: "heading2",
                style: { marginTop: 36, marginLeft: 24, marginRight: 24 },
                data: {
                  header: item.pfFinancialStatement
                    ? "3. 담당 심사역 관리 의견"
                    : "2. 담당 심사역 관리 의견",
                },
              });
              item.riskManagement.riskOpinionList.forEach((opinion, index) => {
                objArr.push({
                  type: "risk_opinion",
                  style: { marginTop: 16, marginLeft: 24, marginRight: 24 },
                  data: {
                    riskOpinion: {
                      header: `0${index + 1}. ${opinion.riskOpinion.name}`,
                      reason: opinion.comment,
                      result: opinion.result ? "긍정" : "우려",
                    },
                  },
                });
              });
            }
          }
        }
        objArr.push({
          type: "examiner_signature",
          style: { marginTop: 36, marginLeft: 24, marginRight: 24 },
          data: {
            signature: {
              status: item.riskManagement?.riskStatus?.name || "",
              participant: item.participantName,
              signatureTmp: item.riskManagement?.signatureTmp,
              signUrl: item.riskManagement?.signatureTmp?.url
                ? item.riskManagement.signatureTmp.url
                : undefined,
            },
          },
        });

        if (closedCompanyIssue?.riskIssueFile) {
          console.log(pdfDataWidthPageNum);
          const pdffile = pdfDataWidthPageNum.current.filter(
            (pdf) => pdf.portfolioId === item.portfolioId
          );
          pdffile.forEach((file) => {
            if (file && file.totalPage && file.totalPage > 0) {
              for (let i = 1; i <= file.totalPage; i++) {
                objArr.push({
                  type: "pdf_page",
                  isNewPage: true,
                  data: {
                    pdfPage: {
                      isError: file.isError,
                      blobFile: file.blobFile,
                      pageImage: file.pages ? file.pages[i - 1] : undefined,
                      pageNum: i,
                    },
                  },
                });
              }
            }
          });
        } else if (item.pfStockholderFile) {
          const pdffile = pdfDataWidthPageNum.current.find(
            (pdf) => pdf.portfolioId === item.portfolioId
          );
          if (pdffile && pdffile.totalPage && pdffile.totalPage > 0) {
            for (let i = 1; i <= pdffile.totalPage; i++) {
              objArr.push({
                type: "pdf_page",
                isNewPage: true,
                data: {
                  pdfPage: {
                    blobFile: pdffile.blobFile,
                    pageImage: pdffile.pages ? pdffile.pages[i - 1] : undefined,
                    pageNum: i,
                  },
                },
              });
            }
          }
        }
      });
    }

    setComponentData([...objArr]);
  };

  const getMinuteDate = async () => {
    const logoResult = await getLogo(LOGO_LARGE);
    const result = await getRiskManagementMinute(year, quarterNum);

    if (result) {
      setRmMinuteData(result);
    }
    setLogoImage(logoResult);
  };

  const getPDFBlobFile = async (fileId: string) => {
    const transformToBlob = (response: any) => {
      const blob = new Blob(
        [
          new Uint8Array(response.body.length).map((_, i) =>
            response.body.charCodeAt(i)
          ),
        ],
        { type: "application/pdf" }
      );
      return blob;
    };
    try {
      const response = await gapi.client.drive.files.get(
        {
          fileId: fileId,
          alt: "media",
        },
        {
          responseType: "blob",
        }
      );
      const blob = transformToBlob(response);
      return blob;
    } catch (error) {
      return undefined;
    }
  };

  const getPDFFile = async (fileId: string) => {
    if (fileId === undefined) return;

    const fileUrl = (
      await axios.get(api.getFile(), {
        params: {
          key: fileId,
        },
      })
    ).data;

    return fetch(fileUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf", // 이래야 브라우저가 pdf라고 인식하고
        "Content-Disposition": "inline", // 이래야  브라우저가 다운로드말고 화면에 비추도록 한다
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((res) => {
        const newBlob = new Blob([res], { type: "application/pdf" });
        return newBlob;
      });
  };

  const onLoaedPDF = (item: MinutePDFProps) => {
    pdfDataWidthPageNum.current.push(item);

    const notLoadedPdf = pdfData?.find(
      (item) =>
        !pdfDataWidthPageNum.current.map((item) => item.id).includes(item.id)
    );
    console.log(notLoadedPdf);
    if (notLoadedPdf) {
      setSelectedLoadPdf(notLoadedPdf);
    } else {
      setPdfData(undefined);
      setPdfLoaded(true);
    }
  };

  const getMinutePdf = async (data: RMPortfolio[]) => {
    try {
      const targetPdf: {
        portfolioId: number;
        pdfFileKey: string;
        type: "aws" | "google";
      }[] = [];

      data.forEach((item) => {
        const isCloaseIssue = item.riskManagement?.riskStatus?.id === 6;

        if (item.pfStockholderFile && !isCloaseIssue) {
          targetPdf.push({
            portfolioId: item.portfolioId,
            pdfFileKey: item.pfStockholderFile.key,
            type: "google",
          });
        } else {
          item.riskManagement?.riskIssueList.forEach((issue) => {
            if (issue.riskIssueFile) {
              issue.riskIssueFile.forEach((file) => {
                if (file.file?.key) {
                  targetPdf.push({
                    portfolioId: item.portfolioId,
                    pdfFileKey: file.file.key,
                    type: file.file.storage === "AWS_S3" ? "aws" : "google",
                  });
                }
              });
            }
          });
        }
      });

      const promiseArr = targetPdf.map(
        (item, index): Promise<{ data?: MinutePDFProps }> => {
          return new Promise(async (resolve, reject) => {
            const blob = await getPDFFile(item.pdfFileKey);

            console.log(blob);
            if (blob) {
              return resolve({
                data: {
                  id: index,
                  blobFile: blob,
                  portfolioId: item.portfolioId,
                  isLoaded: false,
                },
              });
            } else {
              resolve({});
            }
          });
        }
      );

      const result = await Promise.all(promiseArr);
      console.log(result);
      const filteredPdffile: MinutePDFProps[] = [];
      result.forEach((item) => {
        if (item.data !== undefined) {
          filteredPdffile.push(item.data);
        }
      });
      if (filteredPdffile.length === 0) {
        setPdfLoaded(true);
      } else {
        setPdfData([...filteredPdffile]);
        setSelectedLoadPdf(filteredPdffile[0]);
      }
    } catch (error) {}
  };

  const onClickDownloadPdf = () => {
    console.log(minuteCanvas.current);
    console.log(pageData);
    if (minuteCanvas.current.length !== pageData.length + 1) {
      minuteCanvas.current = [];
      setFilDonwloading(true);
      updateOpenProgress(true);
      updateProgressMsg(
        `리스크 관리 위원회 의사록 스캔 중... 1/${pageData.length}`
      );
      setSelectedMinutePage(undefined);
      updateCompltePage((prev) => prev + 1);
      updateProgressIcon(FileUpload);
    } else {
      setReadyForCreatePdf(true);
    }
  };

  const onLoadedCanvas = (canvas: HTMLCanvasElement, pageNumber: number) => {
    minuteCanvas.current.push(canvas);
    updateProgressMsg(
      `리스크 관리 위원회 의사록 스캔 중... ${pageNumber}/${pageData.length}`
    );
    if (pageNumber === pageData.length) {
      setReadyForCreatePdf(true);
      updateOpenProgress(false);
      updateCompltePage(0);
    } else {
      updateCompltePage((prev) => prev + 1);
      setSelectedMinutePage(pageData[pageNumber]);
    }
  };

  const compressPDF = (
    originpdf: jsPDF,
    canvas: HTMLCanvasElement,
    index: number
  ) => {
    const data = canvas.toDataURL("image/png", 1.0);
    if (index !== 0) {
      originpdf.addPage();
    }
    originpdf.addImage({
      imageData: data,
      format: "PNG",
      x: 0,
      y: 0,
      width: 210,
      height: 297,
      compression: "FAST",
    });
  };

  const downloadPdfFile = () => {
    try {
      if (minuteCanvas.current.length === 0) return;

      const originpdf = new jsPDF("p", "mm", "a4");
      for (let i = 0; i < minuteCanvas.current.length; i++) {
        const canvas = minuteCanvas.current[i];
        compressPDF(originpdf, canvas, i);
      }

      const originData = originpdf.output("blob");
      saveAs(originData, `리스크 관리 위원회 의사록_${getYMDHM(getNewDate())}`);

      setReadyForCreatePdf(false);
      setFilDonwloading(false);
    } catch (error) {
      setFilDonwloading(false);
    }
  };

  useEffect(() => {
    if (readyForCreatePdf) {
      setTimeout(() => {
        downloadPdfFile();
      }, 1000);
    }
  }, [readyForCreatePdf]);

  useEffect(() => {
    if (rmMinuteData) {
      gapi.client.load("drive", "v3", async (res: any) => {
        const targets = rmMinuteData.portfolio.filter(
          (item) =>
            item.riskManagement !== undefined &&
            item.riskManagement.riskStatus &&
            item.riskManagement.riskIssueList !== undefined &&
            item.riskManagement.riskIssueList.length > 0
        );

        getMinutePdf(targets);
      });
    }
  }, [rmMinuteData]);

  useEffect(() => {
    if (pdfLoaded && rmMinuteData) {
      setMinuteData(rmMinuteData);
    }
  }, [pdfLoaded]);

  useEffect(() => {
    if (componentData.length === 0) return;

    const minutePages: MinutePageProps[] = [];
    let totalPageHeight = 0;
    let currentPage = 1;
    console.log(divRefArr.current);
    divRefArr.current.forEach((item, index) => {
      if (index === 0) {
        minutePages.push({
          pageNumber: currentPage,
          componentsData: [componentData[index]],
        });
        totalPageHeight = item?.clientHeight || 0;
      } else if (componentData[index].isNewPage) {
        currentPage++;
        minutePages.push({
          pageNumber: currentPage,
          isPDFFile: componentData[index].type === "pdf_page",
          componentsData: [componentData[index]],
        });
        totalPageHeight = item?.clientHeight || 0;
      } else if (totalPageHeight + (item?.clientHeight || 0) > 1019) {
        currentPage++;
        const addCommponent = getAddCommponent(componentData[index]);
        const headerheight = addCommponent ? addCommponent.height : 0;

        minutePages.push({
          pageNumber: currentPage,
          componentsData: addCommponent
            ? [addCommponent.component, componentData[index]]
            : [
                {
                  ...componentData[index],
                  style: { marginTop: 12, marginLeft: 24, marginRight: 24 },
                },
              ],
        });
        totalPageHeight = headerheight + (item?.clientHeight || 0);
      } else {
        minutePages[currentPage - 1].componentsData.push(componentData[index]);
        totalPageHeight += item?.clientHeight || 0;
      }
    });

    console.log(minutePages);
    const signPage = minutePages.filter(
      (item) =>
        item.componentsData.filter((data) => data.type === "examiner_signature")
          .length > 0
    );
    console.log(
      signPage.filter(
        (item) =>
          item.componentsData.find((data) => data.type === "examiner_signature")
            ?.data?.signature?.signUrl === undefined
      )
    );
    setPageData(minutePages);
  }, [componentData]);

  useEffect(() => {
    getMinuteDate();
  }, []);

  return [
    logoImage,
    refArray,
    pdfData,
    pdfDataWidthPageNum.current,
    selectedLoadPdf,
    componentData,
    pageData,
    progressIcon,
    openProgress,
    completePage,
    progressFail,
    progressMsg,
    fileDonwloading,
    selectedMinutePage,
    readyForCreatePdf,
    pdfDownloading,
    onClickExit,
    getComponents,
    onLoaedPDF,
    onClickDownloadPdf,
    onLoadedCanvas,
    setPdfDownloading,
  ] as const;
};

export default useMinutes;
