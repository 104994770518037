import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { UserInfo } from "../../../../../../type/data";
import { CustomColorLabel } from "../../../../../../components-2.0v/label/CustomColorLabel";

import DefaultUserProfile from "../../../../../../assets/image/default_user_profile.svg";

interface Props {
  userInfo: UserInfo;
  isAuthorited: boolean;
  onClick?: () => void;
}

export const UserCard: React.FC<Props> = (props) => {
  const { userInfo, isAuthorited, onClick } = props;
  useEffect(() => {}, []);

  return (
    <Container onClick={onClick}>
      <UserInfoContainer>
        <div className="heading-20-sb">{userInfo.name}</div>
        <div
          className="body-14-rg"
          style={{ marginTop: 2, color: "var(--text-05)" }}
        >
          {userInfo.acStructure?.name || ""}
        </div>

        <div
          className="body-14-rg"
          style={{ marginTop: 2, color: "var(--text-05)" }}
        >
          {userInfo.acResponsibility?.name || "직책 없음"}
        </div>

        <div className="body-14-rg" style={{ marginTop: 10 }}>
          {userInfo.email || ""}
        </div>
        <div className="body-14-rg">
          {userInfo.phoneNumber || "연락처 없음"}
        </div>
        <FlexRow style={{ marginTop: 8, gap: 4 }}>
          {userInfo.acPosition && (
            <CustomColorLabel
              bgColor="var(--purple-100)"
              textColor="var(--primary-purple)"
            >
              {userInfo.acPosition.name}
            </CustomColorLabel>
          )}
        </FlexRow>
      </UserInfoContainer>

      <ProfileImageContainer>
        <img
          className="profile__img"
          src={props.userInfo.profileImgUrl || DefaultUserProfile}
        />
      </ProfileImageContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  width: 296px;
  height: max-content;

  background: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 4px;
  cursor: pointer;
  padding: 24px 24px 24px 24px;
`;

const UserInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProfileImageContainer = styled.div`
  position: absolute;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin-top: 16px;

  top: 0px;
  right: 24px;

  border: 1px solid var(--gray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .profile__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;
