import { SelectorProps, TIPS } from "../../../type/data";
import { PortfolioPhase } from "../../portfolio/portfolio-detail/interface/PortfolioNavigation.interface";

export interface AcceleratingPortfolioProps {
  companyName: string;
  ceoName: string;
  createdAt: string;
  description: string;
  damagedAt?: string;
  isTips: TIPS;
  investmentAccount: InvestmentAccountProps[];
  investInfo?: InvestmentInfoProp;
  portfolioId: number;
  pfComment: string[];
  pfParticipant: AcceleratingParticipantProps[];
  firstContractDate: string;
  firstPaymentDate: string;
  riskStatus?: {
    id: number;
    name: string;
  };
}

export interface InvestmentInfoProp {
  averageMultiple: string;
  companyValue: number;
}

export interface InvestmentAccountProps {
  investmentAccountId: number;
  acFundAccount: {
    acFundAccountId: number;
    nameKo: string;
    nickName?: string;
  };
}

export interface AcceleratingParticipantProps {
  pfParticipantId: number;
  isCharger: boolean;
  acUser: {
    acUserId: number;
    name: string;
    profileImgUrl?: string;
    headquarter?: {
      acStructureId: number;
    };
    team?: {
      acStructureId: number;
    };
  };
}

export interface AcceleratingStructureFilterProps {
  acStructureId: number;
  name: string;
}

export interface AcceleratingCountProps {
  riskStatus0: number;
  riskStatus1: number;
  riskStatus2: number;
  riskStatus3: number;
  riskStatus4: number;
  riskStatus5: number;
  riskStatus6: number;
  tipsExpected: number;
  tipsFixed: number;
  tipsNo: number;
  total: number;
}

export interface AcceleratingFilterProps {
  type: "my_deal" | "structure_deal";
  id: number;
}

export const sortingList: SelectorProps[] = [
  { id: 1, text: "최신순", data: "최신순" },
  { id: 2, text: "오래된순", data: "오래된순" },
];
