import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ExitProcessProps,
  InvestemntInfoAdminProps,
  PortfolioDetailAdminProps,
} from "../../interface/Portfolio.interface";
import { AddComma } from "../../../../../../common/commonUtil";
import { FundAccountCard } from "../../component/FundAccountCard";

interface Props {
  selectedPortfolioDetail: PortfolioDetailAdminProps;
  exitProcessInfo: ExitProcessProps[];
  onChangeData: (newData: ExitProcessProps[]) => void;
}

export const ExitFirst: React.FC<Props> = (props) => {
  const { selectedPortfolioDetail, exitProcessInfo, onChangeData } = props;

  const onClickFundAccount = (item: InvestemntInfoAdminProps) => {
    const index = exitProcessInfo.findIndex((info) => info.id === item.id);
    if (index === -1) {
      const newExitProcessInfo: ExitProcessProps = {
        id: item.id,
        portfolioId: selectedPortfolioDetail.portfolioId,
        acFundAccount: item.acFundAccount,
        stockType: item.stockType ? item.stockType : undefined,
        currentPrincipal: item.principal,
        currentStock: item.number,

        currentPercentage: item.percentage,
        exitInfo: [],
      };
      onChangeData([...exitProcessInfo, newExitProcessInfo]);
    } else {
      exitProcessInfo.splice(index, 1);
      console.log(exitProcessInfo);
      onChangeData([...exitProcessInfo]);
    }
  };

  return (
    <Container>
      <InvestmentInfoContainer>
        <FlexColumn style={{ marginTop: 24 }}>
          <div className="heading-18-b">주식 선택</div>
          <div className="body-14-rg" style={{ marginLeft: 10, marginTop: 12 }}>
            회수하려는 주식을 선택해주세요.
          </div>
        </FlexColumn>
        <FlexRow style={{ gap: 24, flexWrap: "wrap" }}>
          {selectedPortfolioDetail.investmentInfo.map((item) => {
            return (
              <FundAccountCard
                data={item}
                isSelected={
                  exitProcessInfo.find((info) => info.id === item.id) !==
                  undefined
                }
                onClickFundAccount={onClickFundAccount}
              />
            );
          })}
        </FlexRow>
      </InvestmentInfoContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  border-top: 1px solid var(--gray-400);
  padding: 24px;
`;

const InvestmentInfoContainer = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 24px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  width: 100%;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;

  &.selected {
    background-color: var(--purple-100);
  }
`;
