import React from "react";

import styled from "styled-components";
import { addCommasToIntegerPart } from "../../../../common/commonUtil";

interface Props {
  totalStockNum: number;
}

export const StockChangeFooter: React.FC<Props> = ({ totalStockNum }) => {
  const height = 40;
  return (
    <Container className="body-14-sb">
      <CellConatiner
        width={60 + 120 + 120 + 250}
        height={height}
        style={{ justifyContent: "center" }}
      >
        총 발행 주식수
      </CellConatiner>
      <CellConatiner
        width={220}
        height={height}
        style={{ justifyContent: "flex-end" }}
      >
        {addCommasToIntegerPart(totalStockNum)}
      </CellConatiner>

      <CellConatiner
        width={100 + 150 + 360 + 200}
        height={height}
        style={{
          justifyContent: "flex-start",
          borderRight: "0.5px solid transparent",
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  bottom: -32px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CellConatiner = styled.div<{ width: number; height?: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height || 32}px`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: var(--text-01);
  border-top: 1px solid var(--divider-300, #e3e4e5);
  border-right: 1px solid var(--divider-300, #e3e4e5);
  border-bottom: 1px solid var(--divider-300, #e3e4e5);
  background-color: var(--white);
  padding: 0 10px;
`;
