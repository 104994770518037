import React from "react";
import styled from "styled-components";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

/**
 * @param id : number타입 그리드에서 순서를 나타낸다. 0 ~ n;
 * @param identificationNumber : 체크 할때 식별할 수 있는 유니크한 id
 * @param data : grid에 보여줘야 하는 데이터
 */
export interface TableGridProps {
  id: number;
  identificationNumber: number;
  data: any;
}

export interface TableGridHeaderProps {
  header: string;
  key: string;
  width: number;
  style?: React.CSSProperties;
  tooltipId?: string;
  customHeaderCell?: (allData?: TableGridProps[]) => JSX.Element;
  customCell?: (data: any, allData?: any) => JSX.Element;
}

interface Props {
  header: TableGridHeaderProps[];
  dataList?: TableGridProps[];

  disabled?: boolean;
}

export const TableGrid: React.FC<Props> = (props) => {
  return (
    <Container>
      <TableHeader headers={props.header} transfromPixel={(n) => n} />
      {props.dataList &&
        props.dataList.map((item) => {
          return (
            <TableRow
              headerData={props.header}
              rowData={item}
              transfromPixel={(n) => n}
            />
          );
        })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;

const AddRow = styled.div`
  height: 50px;
  padding: 14px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  border-bottom: 1px solid var(--divider-200);
  background-color: var(--background-contents01);

  cursor: pointer;
`;
