import React, { useRef } from "react";
import styled from "styled-components";

interface Props {
  id?: string;
  title: string;
  children?: JSX.Element;
}

export const RMEDSector: React.FC<Props> = (props: Props) => {
  return (
    <Container id={props.id}>
      <div className="heading-20-b">{props.title}</div>
      <div>{props.children}</div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 16px;
`;
