import React, { useState } from "react";

import styled from "styled-components";
import { documentDownload } from "../../repository/ReportingRepository";
import { ReactComponent as Loading } from "../../../../assets/image/loading.svg";
import ModalLayout from "../../../../components/modal/ModalLayout";

interface Props {
  docucmentInfo: { fileId: number; extension: string; fileName: string };
  disabled?: boolean;
  children: React.ReactNode;
  transfromPixel: (value: number) => number;
  callbackFn?: () => void;
}

const ReportFileDownload: React.FC<Props> = ({
  docucmentInfo,
  disabled,
  children,
  transfromPixel,
  callbackFn,
}) => {
  const [modalOpen, updateModalOpen] = useState<boolean>(false);

  const downloadDocumentFile = async () => {
    try {
      console.log("asdfsf" + disabled);
      if (disabled) return;

      updateModalOpen(true);
      const result = await documentDownload(
        docucmentInfo.fileId,
        docucmentInfo.fileName,
        docucmentInfo.extension
      );
      if (result) {
        callbackFn && callbackFn();
      }
      updateModalOpen(false);
    } catch (error) {}
  };

  return (
    <Container>
      <div
        onClick={(e) => {
          e.stopPropagation();
          downloadDocumentFile();
        }}
      >
        {children}
      </div>
      {!disabled && modalOpen && (
        <ModalLayout
          isOpen={true}
          isFullScreen
          isBackgroundTransparent
          overlayColor="rgba(31, 32, 34, 0.5)"
        >
          <DownloadingProgress className="body-16-md">
            <Loading
              className="rotating"
              width={transfromPixel(30)}
              height={transfromPixel(30)}
            />
            <div>파일 다운로드 중입니다.</div>
          </DownloadingProgress>
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  height: fit-content;
`;

export const DownloadingProgress = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;

  path:nth-of-type(1) {
    fill: #000000;
    fill-opacity: 0.1;
  }
  path:nth-of-type(2) {
    fill: #ffffff;
  }

  /* Safari and Chrome */
  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }
`;
export default ReportFileDownload;
