import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Close } from "../../assets/image/ic_chip_delete.svg";
import { SelectorProps } from "../../type/data";

interface Props {
  type?: "single" | "multi";
  searchList?: SelectorProps[];
  selectedData?: SelectorProps[];
  disabled?: boolean;
  width?: number | string;
  maxHeight?: number;
  onChangeUser: (users: SelectorProps[]) => void;
}

export const ChipsSelector: React.FC<Props> = (props) => {
  const {
    type = "multi",
    disabled = false,
    searchList = [],
    width = 332,
    maxHeight = 256,
    onChangeUser,
  } = props;
  const rootRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchListRef = useRef<HTMLDivElement>(null);
  const [searchFocus, updateSearchFocus] = useState<boolean>(false);
  const [value, updateValue] = useState<string>("");

  const [render, updateRender] = useState<boolean>(false);
  const focusIndex = useRef<number>(-1);
  const searchFocusRef = useRef<boolean>(false);
  const valueRef = useRef<string>("");
  const searchListArrRef = useRef<SelectorProps[]>(props.searchList || []);
  const selectedData = useRef<SelectorProps[]>(props.selectedData || []);

  const reRender = () => updateRender((prev) => !prev);

  const selectComplete = () => {
    updateSearchFocus(false);
    searchFocusRef.current = false;
    updateValue("");
    valueRef.current = "";
    if (searchInputRef.current) searchInputRef.current.blur();
  };

  const mouseHandler = (event: any) => {
    if (rootRef.current && !rootRef.current.contains(event.target)) {
      selectComplete();
    }
  };

  const KeyDownHandler = (event: any) => {
    if (!searchFocusRef.current) return;

    switch (event.key) {
      case "Enter": {
        event.preventDefault();
        console.log("asdfsdaf");
        const data = searchListArrRef.current.filter((item) =>
          item.text.includes(valueRef.current)
        )[focusIndex.current];

        if (data) {
          onSelectUser(data);
        }
        break;
      }
      case "ArrowDown": {
        if (searchInputRef.current) searchInputRef.current.blur();

        if (
          focusIndex.current <
          searchListArrRef.current.filter((item) =>
            item.text.includes(valueRef.current)
          ).length -
            1
        ) {
          focusIndex.current = focusIndex.current + 1;
          searchListRef.current?.children
            .item(focusIndex.current)
            ?.scrollIntoView();

          reRender();
        }
        break;
      }
      case "ArrowUp": {
        if (searchInputRef.current) searchInputRef.current.blur();
        if (focusIndex.current > 0) {
          focusIndex.current = focusIndex.current - 1;
          searchListRef.current?.children
            .item(focusIndex.current)
            ?.scrollIntoView();
          reRender();
        }
        break;
      }
    }
  };

  const onSelectUser = (item: SelectorProps) => {
    const index = selectedData.current.findIndex(
      (user) => user.text === item.text
    );

    console.log(selectedData.current);
    if (index !== -1) {
      const newData = [
        ...selectedData.current.filter((user, idx) => idx !== index),
      ];
      onChangeUser([...newData]);
    } else {
      if (type === "multi") {
        onChangeUser([...selectedData.current, item]);
      } else {
        onChangeUser([item]);
      }
    }
  };

  useEffect(() => {
    console.log(props.selectedData);
    selectedData.current = props.selectedData || [];
    reRender();
  }, [props.selectedData]);

  useEffect(() => {
    searchListArrRef.current = searchList;
  }, [searchList]);

  useEffect(() => {
    window.addEventListener("keydown", KeyDownHandler);
    window.addEventListener("mousedown", mouseHandler);
    return () => {
      window.removeEventListener("keydown", KeyDownHandler);
      window.removeEventListener("mousedown", mouseHandler);
    };
  }, []);

  return (
    <Container
      ref={rootRef}
      disabled={disabled}
      style={{
        width: width,
        minWidth: width,
        maxWidth: width,
        borderColor: disabled
          ? "transparent"
          : searchFocus
          ? "var(--focus-field-blue)"
          : "var(--gray-300)",
        cursor: disabled ? "default" : "text",
      }}
      onClick={(e) => {
        if (disabled) return;

        focusIndex.current = -1;
        updateSearchFocus(true);
        searchFocusRef.current = true;
        if (searchInputRef.current) searchInputRef.current.focus();
      }}
    >
      <ChipListContainer>
        {selectedData.current.map((item) => {
          return (
            <Chip
              className="caption-12-rg"
              style={{
                cursor: disabled ? "default" : "pointer",
              }}
            >
              {item.text}
              {!disabled && (
                <Close
                  className="chip__delete__btn"
                  width={16}
                  height={16}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectUser(item);
                  }}
                />
              )}
            </Chip>
          );
        })}
        <Search
          ref={searchInputRef}
          className="body-14-rg"
          placeholder={
            selectedData.current.length === 0 ? "이름을 검색해주세요." : ""
          }
          style={{ width: selectedData.current.length === 0 ? "auto" : 60 }}
          value={value}
          onChange={(e) => {
            focusIndex.current = -1;
            reRender();
            updateValue(e.target.value);
            valueRef.current = e.target.value;
          }}
        />
      </ChipListContainer>
      {/* {!searchFocus && selectedData.current.length !== 0 && (
      )} */}

      {searchFocus && (
        <SearchListContainer
          className="scroll__invisible"
          ref={searchListRef}
          style={{ maxHeight: maxHeight }}
        >
          {searchList.filter((item) => item.text.includes(value)).length ===
            0 && (
            <div className="body-14-rg" style={{ padding: "0 12px" }}>
              검색결과 없음
            </div>
          )}
          {searchList
            .filter((item) => item.text.includes(value))
            .map((item, index) => {
              return (
                <SearchListItem
                  tabIndex={index}
                  className={`body-14-rg ${
                    selectedData.current
                      .map((user) => user.text)
                      .includes(item.text)
                      ? "selected"
                      : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectUser(item);
                    selectComplete();
                  }}
                >
                  {item.text}
                  {index === focusIndex.current && <FocusBorder />}
                </SearchListItem>
              );
            })}
        </SearchListContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  height: auto;
  min-height: 42px;

  background: var(--background-contents01);
  border: 1px solid var(--gray-300);
  border-radius: 4px;

  transition: all 0.2s ease;
  cursor: text;
  :hover {
    background-color: ${(props) =>
      props.disabled ? "var(--background-contents01)" : "var(--hover-100)"};
  }
`;

const ChipListContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 6px 12px;
  row-gap: 6px;
  column-gap: 8px;

  border-radius: 4px;
`;

const Chip = styled.div`
  width: auto;
  height: 28px;
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  line-height: 1;

  background: #e8f0ff;
  border-radius: 14px;

  cursor: pointer;

  .chip__delete__btn {
    :hover {
      path {
        fill: black;
      }
    }
  }
`;

const Search = styled.input`
  height: 28px;
  border: 0;
  background-color: transparent;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: var(--text-inactive);
  }
`;

const SearchListContainer = styled.div`
  position: absolute;
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  overflow: scroll;

  top: calc(100% + 10px);
  left: 0;

  padding: 8px 0px;

  background: var(--background-contents02);
  box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
  border-radius: 4px;

  z-index: 10;
`;

const SearchListItem = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 9px 16px;

  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  transition: all 0.2s ease;

  cursor: pointer;

  &.selected {
    background-color: var(--blue-100);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;

const FocusBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 2px;
  border: 2px solid blue;
`;
