import { FileType } from "../../../../../../../type/data";

export const getInitDataV2 = (
  year: number,
  quarter: number
): SBPContentsPropsV2 => {
  return {
    ver: "2",
    year,
    quarter,
    hasPastData: false,
    businessStatus: {
      solutionAndDevlopPoint: "",
      businessPoint: "",
      govTasks: [{}],
      noEventGovTask: false,
      fundBorrowed: [{}],
      noEventFundBorrowed: false,
      employeeTurnover: { noEventClevel: false, cLevelChangeList: [{}] },
      patentInfo: {},
    },
    businessPlan: {
      solutionAndDevlopPoint: "",
      businessPoint: "",
      capitalAcquisitionPlan: [{}],
      noEventCapitalAcquisition: false,
      estimatedCashFlow: {},
    },
    reportIssue: {
      executiveIssue: { text: "", isNotIssue: false },
      milestoneIssue: { text: "", isNotIssue: false },
      rivalCompanyEffect: { text: "", isNotIssue: false },
      macroEnvironmentalEffect: { text: "", isNotIssue: false },
    },
    requestAndEvaluation: {
      participant: { serviceList },
      bluepointPartners: {},
    },
  };
};

export interface SBPLocationProps {
  year: number;
  quarterNum: number;
  fileNum: number;
}

export interface SBPTabProps {
  id: number;
  name: string;
  isComplete: boolean;
  tabSectionList: SectionValidationProps[];
}

export interface SectionValidationProps {
  index: number;
  isComplete: boolean;
  sectionTitle: string;
  selectId: string;
}

export interface SBPContentsPropsV2 {
  ver: string;
  year: number;
  quarter: number;

  hasPastData?: boolean;
  businessStatus: SBPBusinessStatus;
  businessPlan: SBPBusinessPlan;
  reportIssue: SBPIssue;
  requestAndEvaluation: RequestAndEvaluation;
}

export interface SBPBusinessStatus {
  solutionAndDevlopPoint: string;
  businessPoint: string;
  govTasks: GovermentTask[];
  noEventGovTask: boolean;
  fundBorrowed: FundsBorrowed[];
  noEventFundBorrowed: boolean;
  employeeTurnover: EmployeeTurnover;
  patentInfo: PatentInfo;
}

export interface GovermentTask {
  taskName?: string;
  responsibleAgency?: string;
  startDate?: string;
  endDate?: string;
  govFunding?: number;
  govStatus?: string;
}

export interface FundsBorrowed {
  borrowingAgency?: string;
  borrowingDate?: string;
  repaymentDue?: string;
  fundPurpose?: string;
  borrowedAmount?: number;
  outstandingBalance?: number;
}

export interface EmployeeTurnover {
  curEmployee?: number;
  employeeOnboarding?: number;
  employeeAttrition?: number;
  noEventClevel: boolean;
  cLevelChangeList?: CLevelEmployee[];
}

export interface CLevelEmployee {
  isOnboarding?: string;
  name?: string;
  position?: string;
  hasRegistration?: boolean;
  resume?: FileType;
}

export interface PatentInfo {
  accumulatePatent?: {
    domesticApplication?: number;
    internationalApplication?: number;
    domesticRegistration?: number;
    internationalRegistration?: number;
  };
  newPatent?: {
    domesticApplication?: number;
    internationalApplication?: number;
    domesticRegistration?: number;
    internationalRegistration?: number;
  };
  note?: string;
}

// 사업계획
export interface SBPBusinessPlan {
  solutionAndDevlopPoint: string;
  businessPoint: string;

  capitalAcquisitionPlan: CapitalAcquisitionPlan[];
  noEventCapitalAcquisition: boolean;
  estimatedCashFlow: EstimatedCashFlow;
}

export interface CapitalAcquisitionPlan {
  solutionCapitalAcquisition?: string;
  capitalRaiseDate?: string;
  capitalAmount?: number;
  contents?: string;
}

export interface EstimatedCashFlow {
  govBalance?: number;
  cashOnHand?: number;
  expectedCashInflow?: number;
  reasonExpectedCashInflow?: string;
  estimatedGovFundingUsage?: number;
  totalExpectedExpense?: number;
  salesIssue?: string;
}

// 이슈 보고
export interface SBPIssue {
  executiveIssue: IssueProps;
  milestoneIssue: IssueProps;
  rivalCompanyEffect: IssueProps;
  macroEnvironmentalEffect: IssueProps;
}

export interface IssueProps {
  text: string;
  isNotIssue: boolean;
}

// 당사 요청 사항
export interface RequestAndEvaluation {
  participant: ParticipantRequestAndEvaluation;
  bluepointPartners: BluepointPartnersRequestAndEvaluation;
}

export interface ParticipantRequestAndEvaluation {
  serviceList?: SBPSelectorProps[];
  userSatisfactionService?: string;
  userRequestService?: string;
  evaluationMainParticipant?: {
    acUser: ParticipantUserInfo;
    satisfactionRate?: number;
    userExperienceFactors?: string;
  };
  evaluationSubParticipant?: {
    acUsers: ParticipantUserInfo[];
    satisfactionRate?: number;
    userExperienceFactors?: string;
  };
  requestContens?: string;
}

export interface ParticipantInfo {
  acUser: ParticipantUserInfo;
  screenRoleId: 1 | 2; // 1 : 담당자, 2: 조력자
}

export interface ParticipantUserInfo {
  acUserId: number;
  name: string;
  email: string;
  profileImgKey?: string;
  profileImgUrl?: string;
}

export interface BluepointPartnersRequestAndEvaluation {
  totalSatisfaction?: number;
  requestContens?: string;
}

export interface SBPSelectorProps {
  id: number;
  text: string;
  data: any;
}

export const serviceList: SBPSelectorProps[] = [
  {
    id: 1,
    text: "네트워킹 기회 마련 (타투자사, 관련업계, 잠재고객 등)",
    data: "네트워킹 기회 마련 (타투자사, 관련업계, 잠재고객 등)",
  },
  { id: 2, text: "사업 전략 차원의 도움", data: "사업 전략 차원의 도움" },
  { id: 3, text: "경영 차원의 도움 ", data: "경영 차원의 도움 " },
  { id: 4, text: "홍보 기회 제공 ", data: "홍보 기회 제공 " },
  { id: 5, text: "투자유치 차원의 도움", data: "투자유치 차원의 도움" },
  { id: 6, text: "없음", data: "없음" },
];

export const govStatusList: SBPSelectorProps[] = [
  { id: 1, text: "지원중", data: "지원중" },
  { id: 2, text: "협약중", data: "협약중" },
  { id: 3, text: "진행중", data: "진행중" },
  { id: 4, text: "종료", data: "종료" },
];

export const onBoardingStatusList: SBPSelectorProps[] = [
  { id: 1, text: "입사자", data: "입사자" },
  { id: 2, text: "퇴사자", data: "퇴사자" },
];

export const registrationStatusList: SBPSelectorProps[] = [
  { id: 1, text: "등기", data: "등기" },
  { id: 2, text: "비등기", data: "비등기" },
];

export const capitalStatusList: SBPSelectorProps[] = [
  { id: 1, text: "신주발행", data: "신주발행" },
  { id: 2, text: "자금차입", data: "자금차입" },
  { id: 3, text: "정부지원금", data: "정부지원금" },
  { id: 4, text: "가수금", data: "가수금" },
  { id: 5, text: "기타", data: "기타" },
];

export const capitalQuarterStatusList: SBPSelectorProps[] = [
  { id: 1, text: "1분기", data: "1분기" },
  { id: 2, text: "2분기", data: "2분기" },
  { id: 3, text: "3분기", data: "3분기" },
  { id: 4, text: "4분기", data: "4분기" },
];

export const capitalHalfQuarterStatusList: SBPSelectorProps[] = [
  { id: 3, text: "3분기", data: "3분기" },
  { id: 4, text: "4분기", data: "4분기" },
];
