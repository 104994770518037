import React from "react";
import { useMediaQuery } from "react-responsive";
import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";
import DeskPortfolioInfoModify from "./desktop/DeskPortfolioInfoModify";
import MobilePortfolioInfoModify from "./mobile/MobilePortfolioInfoModify";

const PortfolioDashboard: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobilePortfolioInfoModify {...props} />
  ) : (
    <DeskPortfolioInfoModify {...props} />
  );
};
export default PortfolioDashboard;
