import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  Channel,
  DatabaseHistory,
  IndustryTech,
  PortfolioPhaseProps,
  UserInfo,
} from "../../../../type/data";
import DatabaseChip from "../component/DatabaseChip";
import DatabaseDropdownCheckbox from "../component/DatabaseDropdownCheckbox";
import DatabaseInput from "../component/DatabaseInput";
import DatabaseIndustrialTechs from "./DatabaseIndustrialTechs";
import axios from "axios";
import * as api from "../../../../api/api";
import { DatabaseSearchData } from "../DeskDatabase";

import { ReactComponent as Update } from "../../../../assets/image/ic_update.svg";
export interface SearchValueType {
  keyword?: string;
  industry?: IndustryTech[];
  phase?: PortfolioPhaseProps[];
  participant?: UserInfo[];
  excavationChannel?: Channel[];
  curPage?: number;
}

export interface IsAllSelectedType {
  industry: boolean;
  phase: boolean;
  participant: boolean;
  excavationChannel: boolean;
}

interface Props {
  searchObject: SearchValueType;
  setSearchObject: React.Dispatch<React.SetStateAction<SearchValueType>>;
  isAllSelectedObject: React.MutableRefObject<IsAllSelectedType>;
  industrialTechs: IndustryTech[];
  phaseDictionary: PortfolioPhaseProps[];
  participants: UserInfo[];
  channels: Channel[];
}

const FilterTotalSearch = ({
  searchObject,
  setSearchObject,
  isAllSelectedObject,
  industrialTechs,
  phaseDictionary,
  participants,
  channels,
}: Props) => {
  const [isAllIndustrySelected, setIsAllIndustrySelected] =
    useState<boolean>(true);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const [selectedDropdownId, setSelectedDropdownId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    console.log(searchObject);
    if (
      searchObject.keyword ||
      (searchObject.excavationChannel &&
        searchObject.excavationChannel.length > 0 &&
        searchObject.excavationChannel.length < channels.length) ||
      (searchObject.industry &&
        searchObject.industry.length > 0 &&
        searchObject.industry.length < industrialTechs.length) ||
      (searchObject.participant &&
        searchObject.participant.length > 0 &&
        searchObject.participant.length < participants.length) ||
      (searchObject.phase &&
        searchObject.phase.length > 0 &&
        searchObject.phase.length < phaseDictionary.length)
    ) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }

    const isAllSelected: IsAllSelectedType = {
      industry: false,
      phase: false,
      participant: false,
      excavationChannel: false,
    };

    if (
      searchObject.industry &&
      searchObject.industry.length > 0 &&
      searchObject.industry.length === industrialTechs.length
    ) {
      isAllSelected.industry = true;
      setIsAllIndustrySelected(true);
    } else {
      isAllSelected.industry = false;
      setIsAllIndustrySelected(false);
    }
    if (
      searchObject.excavationChannel &&
      searchObject.excavationChannel.length > 0 &&
      searchObject.excavationChannel.length === channels.length
    ) {
      isAllSelected.excavationChannel = true;
    } else {
      isAllSelected.excavationChannel = false;
    }
    if (
      searchObject.participant &&
      searchObject.participant.length > 0 &&
      searchObject.participant.length === participants.length
    ) {
      isAllSelected.participant = true;
    } else {
      isAllSelected.participant = false;
    }
    if (
      searchObject.phase &&
      searchObject.phase.length > 0 &&
      searchObject.phase.length === phaseDictionary.length
    ) {
      isAllSelected.phase = true;
    } else {
      isAllSelected.phase = false;
    }
    isAllSelectedObject.current = isAllSelected;
    console.log(isAllSelected);
  }, [searchObject]);

  const onClickRefresh = () => {
    setSearchObject({
      ...searchObject,
      industry: industrialTechs,
      phase: phaseDictionary,
      participant: participants,
      excavationChannel: channels,
      curPage: 1,
    });
  };

  return (
    <Container>
      <Row
        className="heading-16-sb"
        style={{
          borderBottom: "1px solid var(--gray-400)",
        }}
      >
        상세 필터
      </Row>
      <Row>
        <RowSection>
          <span className="label font-medium-12">산업기술분류</span>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
            }}
          >
            <DatabaseIndustrialTechs
              selectedIndustrialTechs={searchObject.industry}
              onSelectedIndustrialTechs={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  industry: data,
                  curPage: 1,
                }));
              }}
              setIsAllIndustrySelected={setIsAllIndustrySelected}
              isAllIndustrySelected={isAllIndustrySelected}
              industrialTechs={industrialTechs}
            />
          </div>
        </RowSection>
      </Row>
      <Row
        style={{
          justifyContent: "flex-start",
          flexFlow: "row wrap",
          columnGap: "64px",
          rowGap: "4px",
        }}
      >
        <RowSection>
          <span className="label font-medium-12">기업상태</span>

          <div style={{ maxWidth: "240px", width: "240px" }}>
            <DatabaseDropdownCheckbox
              allItemList={phaseDictionary.map((data) => ({
                data: data,
                label: data.name || "",
              }))}
              selectedItemList={
                searchObject.phase?.map((data) => ({
                  data: data,
                  label: data.name || "",
                })) || []
              }
              isAllDataSelected={
                phaseDictionary.length === searchObject.phase?.length
              }
              isSame={(a, b) => {
                return a.pfPhaseId === b.pfPhaseId;
              }}
              isIncludesValue={(value, data) => {
                return data.name.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                console.log(dataList);

                setSearchObject((prev) => ({
                  ...prev,
                  phase: dataList.length === 0 ? phaseDictionary : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="phase"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </div>
        </RowSection>
        <RowSection>
          <span className="label font-medium-12">담당자(조력자)</span>

          <div style={{ maxWidth: "240px", width: "240px" }}>
            <DatabaseDropdownCheckbox
              allItemList={participants.map((data) => ({
                data: data,
                label: data.name || "",
              }))}
              selectedItemList={
                searchObject.participant?.map((data) => ({
                  data: data,
                  label: data.name || "",
                })) || []
              }
              isAllDataSelected={
                participants.length === searchObject.participant?.length
              }
              isSame={(a, b) => {
                return a.acUserId === b.acUserId;
              }}
              isIncludesValue={(value, data) => {
                return data.name.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                console.log(dataList);

                setSearchObject((prev) => ({
                  ...prev,
                  participant: dataList.length === 0 ? participants : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="participants"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </div>
        </RowSection>
        <RowSection>
          <span className="label font-medium-12">발굴채널</span>

          <div style={{ maxWidth: "240px", width: "240px" }}>
            <DatabaseDropdownCheckbox
              allItemList={channels.map((data) => ({
                data: data,
                label: data.name || "",
              }))}
              selectedItemList={
                searchObject.excavationChannel?.map((data) => ({
                  data: data,
                  label: data.name || "",
                })) || []
              }
              isAllDataSelected={
                channels.length === searchObject.excavationChannel?.length
              }
              isSame={(a, b) => {
                return a.acChannelId === b.acChannelId;
              }}
              isIncludesValue={(value, data) => {
                return data.name.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                console.log(dataList);

                setSearchObject((prev) => ({
                  ...prev,
                  excavationChannel:
                    dataList.length === 0 ? channels : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="excavationChannel"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </div>
        </RowSection>
      </Row>
      <SearchKeywordSection isSelected={isSelected}>
        <div className="chip-section">
          {searchObject?.industry &&
            searchObject.industry.length > 0 &&
            !isAllIndustrySelected &&
            searchObject.industry.map((industry, index) => (
              <div
                key={index}
                className={`element-chip ${index === 0 ? "first" : ""}`}
              >
                <DatabaseChip
                  type="keyword-general"
                  data={industry}
                  label={industry.name}
                  key={industry.name}
                  onClickDelete={(data) => {
                    setSearchObject((prev) => ({
                      ...prev,
                      industry: prev?.industry?.filter(
                        (item) =>
                          item.acIndustrialTechId !== data.acIndustrialTechId
                      ),
                      curPage: 1,
                    }));
                  }}
                />
              </div>
            ))}
          {searchObject?.phase &&
            searchObject.phase.length > 0 &&
            phaseDictionary.length !== searchObject.phase?.length &&
            searchObject.phase.map((phase, index) => (
              <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                <DatabaseChip
                  type="keyword-general"
                  data={phase}
                  label={phase.name || `${phase}`}
                  key={phase.name || `${phase}`}
                  onClickDelete={(data) => {
                    setSearchObject((prev) => {
                      const prevItem = prev?.phase?.filter(
                        (item) => item.pfPhaseId !== data.pfPhaseId
                      );
                      const updatedItem =
                        prevItem && prevItem.length > 0
                          ? prevItem
                          : [...phaseDictionary];
                      return {
                        ...prev,
                        phase: updatedItem,
                        curPage: 1,
                      };
                    });
                  }}
                />
              </div>
            ))}
          {searchObject?.participant &&
            searchObject.participant.length > 0 &&
            participants.length !== searchObject.participant?.length &&
            searchObject.participant?.map((participant, index) => (
              <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                <DatabaseChip
                  type="keyword-general"
                  data={participant}
                  label={participant.name || `${participant}`}
                  key={participant.name || `${participant}`}
                  onClickDelete={(data) => {
                    setSearchObject((prev) => {
                      const prevItem = prev?.participant?.filter(
                        (item) => item.acUserId !== data.acUserId
                      );
                      const updatedItem =
                        prevItem && prevItem.length > 0
                          ? prevItem
                          : [...participants];
                      return {
                        ...prev,
                        participant: updatedItem,
                        curPage: 1,
                      };
                    });
                  }}
                />
              </div>
            ))}
          {searchObject?.excavationChannel &&
            searchObject.excavationChannel.length > 0 &&
            channels.length !== searchObject.excavationChannel.length &&
            searchObject.excavationChannel?.map((channel, index) => (
              <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                <DatabaseChip
                  type="keyword-general"
                  data={channel}
                  label={channel.name || `${channel}`}
                  key={channel.name || `${channel}`}
                  onClickDelete={(data) => {
                    setSearchObject((prev) => {
                      const prevItem = prev?.excavationChannel?.filter(
                        (item) => item.acChannelId !== data.acChannelId
                      );
                      const updatedItem =
                        prevItem && prevItem.length > 0
                          ? prevItem
                          : [...channels];
                      return {
                        ...prev,
                        excavationChannel: updatedItem,
                        curPage: 1,
                      };
                    });
                  }}
                />
              </div>
            ))}
        </div>
        <div className="refresh-section">
          <div
            className="update-container"
            onClick={() => {
              if (isSelected) {
                onClickRefresh();
              }
            }}
          >
            <Update />
            <span className="font-regular-12">초기화</span>
          </div>
        </div>
      </SearchKeywordSection>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;

  border-bottom: 1px solid var(--grey_0);
`;

const RowSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;
  .label {
    width: 80px;
    min-width: 80px;
  }
`;

const SearchKeywordSection = styled.div(
  ({ isSelected }: { isSelected: boolean }) => css`
    width: 100%;
    min-height: 88px;
    height: fit-content;
    background: #f1f3f5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    display: flex;
    flex-direction: row;

    padding: 32px 16px 16px 16px;

    position: relative;

    .chip-section {
      width: 100%;
      display: flex;
      flex-direction: row;

      flex-flow: row wrap;

      //
      gap: 4px;
      align-items: center;
      height: fit-content;
      .element-chip.first {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:not(:first-of-type)::before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 16px;
          margin: 0 8px 0 4px;
          background: var(--grey_10);
        }
      }
    }
    .refresh-section {
      width: max-content;
      position: absolute;
      top: 10px;
      right: 10px;
      margin-left: 40px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .update-container {
        cursor: ${isSelected ? "pointer" : "default"};
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        span {
          color: ${isSelected ? "var(--primary)" : "#ABBBCD"};
        }
        path {
          fill: ${isSelected ? "var(--primary)" : "#ABBBCD"};
        }
      }
    }
  `
);

export default FilterTotalSearch;
