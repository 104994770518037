import React, { createRef, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as ButtonSearch } from "../../../../assets/image/button_search.svg";
import { ReactComponent as Reset } from "../../../../assets/image/icon_input_reset_blue.svg";
interface Props {
  type: "search" | "number";
  placeholder?: string;
  onClickSearch?: (value: string) => void;
  onChangeValue?: (value: string | number | undefined) => void;
  width?: number;
  height?: number;
  className?: string;
  disabled?: boolean;
  value?: string | number;
}
const DatabaseInput = ({
  type,
  placeholder,
  onClickSearch = () => {},
  width,
  height,
  className,
  disabled = false,
  onChangeValue = () => {},
  value,
}: Props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (value === "" && type === "search") {
      onClickSearch("");
    }
  }, [value]);
  return (
    <Container
      width={width}
      height={height}
      type={type}
      isFocused={isFocused}
      disabled={disabled}
    >
      <div className="input-container">
        <input
          placeholder={placeholder || ""}
          className={className || "font-regular-12"}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => {
            if (type === "number" && e.target.value) {
              onChangeValue(Number.parseFloat(e.target.value));
            } else {
              onChangeValue(e.target.value);
            }
          }}
          value={value ? value : ""}
          disabled={disabled}
          type={type === "number" ? "number" : ""}
          ref={inputRef}
          onKeyPress={(e) => {
            if (type === "search" && e.key === "Enter") {
              !disabled && type === "search" && onClickSearch(`${value}`);
              inputRef.current && inputRef.current.blur();
            }
          }}
          step="0.01"
        />
        {value !== undefined && `${value}`.length > 0 && (
          <div className="reset-container">
            <Reset
              onClick={() => {
                if (disabled) return;
                if (type === "number") {
                  onChangeValue(undefined);
                } else {
                  onChangeValue("");
                }
              }}
            />
          </div>
        )}
      </div>
      {type === "search" && (
        <div className="icon-container">
          <div className="divider" />
          <div
            className="icon"
            onClick={() =>
              !disabled && type === "search" && onClickSearch(`${value}`)
            }
          >
            <ButtonSearch />
          </div>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div(
  ({
    width,
    height,
    type,
    isFocused,
    disabled,
  }: {
    width?: number;
    height?: number;
    type: string;
    isFocused: boolean;
    disabled: boolean;
  }) => css`
    width: ${width ? `${width}px` : "100%"};
    height: ${height ? `${height}px` : "32px"};
    border: 1px solid ${isFocused ? "var(--primary)" : "var(--grey_10)"};
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;

    background: ${disabled ? "#f0f0f0" : "white"};

    :hover {
      background: ${disabled ? "#f0f0f0" : "#fafcff"};
    }

    .input-container {
      width: ${type === "search" ? "calc(100% - 40px)" : "100%"};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 8px;
      input {
        border: transparent;
        background: transparent;
        width: ${type === "search" ? "calc(100% - 40px)" : "100%"};
        margin: 8px;
        :focus {
          outline: none;
        }

        ::placeholder {
          font-family: "BPP Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: var(--grey_20);
        }

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }

      .reset-container {
        width: 14px;
        height: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          cursor: ${!disabled && "pointer"};
        }
      }
    }

    .icon-container {
      position: absolute;
      right: 10px;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .divider {
        width: 1px;
        height: 16px;
        background: #f0f0f0;
        border-radius: 2px;
      }

      .icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  `
);

export default DatabaseInput;
