import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMediaQuery } from "react-responsive";

import koLocale from "date-fns/locale/ko"; // 한국어 설정
import styled from "styled-components";

interface Props {
  type?: "date" | "dateTime";
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  onChangeDate: (date: string) => void;
}
export const DateTimePickerByMUI: React.FC<Props> = (props) => {
  const {
    type = "dateTime",
    defaultValue,
    disabled,
    placeholder = "YYYY년 MM월 DD일  HH시 mm분",
    onChangeDate,
  } = props;
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;

  const getDatePickerByType = () => {
    switch (type) {
      case "date": {
        return isMobile ? (
          <DemoItem>
            <MobileDatePicker
              readOnly={disabled}
              defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
              slotProps={{ textField: { size: "small" } }}
              format={defaultValue ? "YYYY년 MM월 DD일" : placeholder}
              onChange={(value) => {
                if (value) {
                  onChangeDate(value.toString());
                }
              }}
            />
          </DemoItem>
        ) : (
          <DemoItem>
            <DesktopDatePicker
              disabled={disabled}
              defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
              slotProps={{ textField: { size: "small" } }}
              format={defaultValue ? "YYYY년 MM월 DD일" : placeholder}
              onChange={(value) => {
                if (value) {
                  onChangeDate(value.toString());
                }
              }}
            />
          </DemoItem>
        );
      }
      case "dateTime": {
        return isMobile ? (
          <DemoItem>
            <MobileDateTimePicker
              readOnly={disabled}
              defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
              slotProps={{ textField: { size: "small" } }}
              format={
                defaultValue ? "YYYY년 MM월 DD일  HH시 mm분" : placeholder
              }
              onChange={(value) => {
                if (value) {
                  onChangeDate(value.toString());
                }
              }}
            />
          </DemoItem>
        ) : (
          <DemoItem>
            <DesktopDateTimePicker
              disabled={disabled}
              defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
              slotProps={{ textField: { size: "small" } }}
              format={
                defaultValue ? "YYYY년 MM월 DD일  HH시 mm분" : placeholder
              }
              onChange={(value) => {
                if (value) {
                  onChangeDate(value.toString());
                }
              }}
            />
          </DemoItem>
        );
      }
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={koLocale.code}
    >
      <Wrapper>
        <DemoContainer
          components={[
            "MobileDateTimePicker",
            "DesktopDateTimePicker",
            "MobileDatePicker",
            "DesktopDatePicker",
          ]}
        >
          {getDatePickerByType()}
        </DemoContainer>
      </Wrapper>
    </LocalizationProvider>
  );
};

const Wrapper = styled.div`
  .MuiInputBase-input {
    font-size: 12px;
  }
`;
