import React, { useState } from "react";
import styled, { css } from "styled-components";
import DatabaseDropdownRadio from "../component/DatabaseDropdownRadio";

interface Props {
  width?: number;
  inputWidth?: number;
  investmentYears: number[];
  minInvestmentYear?: number;
  maxInvestmentYear?: number;
  minInvestmentQuarter?: number;
  maxInvestmentQuarter?: number;
  onSelectedMinYear: (data: number | undefined) => void;
  onSelectedMaxYear: (data: number | undefined) => void;
  onSelectedMinQuarter: (data: number | undefined) => void;
  onSelectedMaxQuarter: (data: number | undefined) => void;
  selectedDropdownId?: string;
  setSelectedDropdownId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}
const DatabaseVintage = ({
  width,
  inputWidth,
  investmentYears,
  minInvestmentYear,
  maxInvestmentYear,
  minInvestmentQuarter,
  maxInvestmentQuarter,
  onSelectedMinYear,
  onSelectedMaxYear,
  onSelectedMinQuarter,
  onSelectedMaxQuarter,
  selectedDropdownId,
  setSelectedDropdownId,
}: Props) => {
  return (
    <Container width={width}>
      <div className="input-container">
        <DatabaseDropdownRadio
          allItemList={investmentYears.map((year) => ({
            data: year,
            label: `${year}`,
          }))}
          selectedItem={
            minInvestmentYear
              ? {
                  data: minInvestmentYear,
                  label: `${minInvestmentYear}`,
                }
              : undefined
          }
          onSelectedData={(data) => {
            onSelectedMinYear(data);
            if (!data) {
              onSelectedMinQuarter(undefined);
            } else if (maxInvestmentYear && data >= maxInvestmentYear) {
              onSelectedMaxYear(data);
              // 연도가 같아지니까 분기 비교
              if (
                minInvestmentQuarter &&
                maxInvestmentQuarter &&
                minInvestmentQuarter > maxInvestmentQuarter
              ) {
                onSelectedMaxQuarter(minInvestmentQuarter);
              }
            }
          }}
          width={inputWidth || 78}
          placeholder="최소"
          isIncludesValue={(value, data) => {
            return `${data}`.includes(value);
          }}
          dropdownId="minInvestmentYears"
          selectedDropdownId={selectedDropdownId}
          setSelectedDropdownId={setSelectedDropdownId}
          canSelectAll={true}
        />
        <DatabaseDropdownRadio
          allItemList={[1, 2, 3, 4].map((quarter) => ({
            data: quarter,
            label: `${quarter}`,
          }))}
          selectedItem={
            minInvestmentQuarter
              ? {
                  data: minInvestmentQuarter,
                  label: `${minInvestmentQuarter}`,
                }
              : undefined
          }
          placeholder="1"
          onSelectedData={(data) => {
            onSelectedMinQuarter(data);
            if (
              minInvestmentYear === maxInvestmentYear &&
              maxInvestmentQuarter &&
              data > maxInvestmentQuarter
            ) {
              onSelectedMaxQuarter(data);
            }
          }}
          width={inputWidth || 58}
          isIncludesValue={(value, data) => {
            return `${data}`.includes(value);
          }}
          dropdownId="minInvestmentQuarters"
          selectedDropdownId={selectedDropdownId}
          setSelectedDropdownId={setSelectedDropdownId}
          canSelectAll={false}
          disabled={minInvestmentYear ? false : true}
        />
        <span className="font-regular-12">분기</span>
      </div>
      <span className="font-regular-12">~</span>

      <div className="input-container">
        <DatabaseDropdownRadio
          allItemList={investmentYears.map((year) => ({
            data: year,
            label: `${year}`,
          }))}
          selectedItem={
            maxInvestmentYear
              ? {
                  data: maxInvestmentYear,
                  label: `${maxInvestmentYear}`,
                }
              : undefined
          }
          onSelectedData={(data) => {
            onSelectedMaxYear(data);
            if (!data) {
              onSelectedMaxQuarter(undefined);
            } else if (minInvestmentYear && data <= minInvestmentYear) {
              onSelectedMinYear(data);
              // 연도가 같아지니까 분기 비교
              if (
                minInvestmentQuarter &&
                maxInvestmentQuarter &&
                minInvestmentQuarter > maxInvestmentQuarter
              ) {
                onSelectedMinQuarter(maxInvestmentQuarter);
              }
            }
          }}
          width={inputWidth || 78}
          placeholder="최대"
          isIncludesValue={(value, data) => {
            return `${data}`.includes(value);
          }}
          dropdownId="maxInvestmentYears"
          selectedDropdownId={selectedDropdownId}
          setSelectedDropdownId={setSelectedDropdownId}
          canSelectAll={true}
        />
        <DatabaseDropdownRadio
          allItemList={[1, 2, 3, 4].map((quarter) => ({
            data: quarter,
            label: `${quarter}`,
          }))}
          selectedItem={
            maxInvestmentQuarter
              ? {
                  data: maxInvestmentQuarter,
                  label: `${maxInvestmentQuarter}`,
                }
              : undefined
          }
          placeholder="1"
          onSelectedData={(data) => {
            onSelectedMaxQuarter(data);

            if (
              minInvestmentYear === maxInvestmentYear &&
              minInvestmentQuarter &&
              data < minInvestmentQuarter
            ) {
              onSelectedMinQuarter(data);
            }
          }}
          width={inputWidth || 58}
          isIncludesValue={(value, data) => {
            return `${data}`.includes(value);
          }}
          dropdownId="maxInvestmentQuarters"
          selectedDropdownId={selectedDropdownId}
          setSelectedDropdownId={setSelectedDropdownId}
          canSelectAll={false}
          disabled={maxInvestmentYear ? false : true}
        />
        <span className="font-regular-12">분기</span>
      </div>
    </Container>
  );
};

const Container = styled.div(
  ({ width }: { width?: number }) => css`
    width: ${width ? `${width}px` : "100%"};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
      span {
        color: var(--grey_50);
      }
    }
  `
);

export default DatabaseVintage;
