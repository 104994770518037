import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";

import { ReactComponent as CheckT } from "../../../assets/image/new_check_box_true.svg";
import { ReactComponent as CheckF } from "../../../assets/image/new_check_box_false.svg";
import { AddComma } from "../../../../../common/commonUtil";

interface Props {
  damagedCompany?: {
    no?: number;
    structureName?: string;
    companyName?: string;
    damageIssue?: string;
    financialStatementValuation?: number;
  };
  style?: React.CSSProperties;
}

export const DCRow: React.FC<Props> = (props) => {
  const HEIGHT = 32;
  return (
    <Container style={props.style}>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 50,
          minWidth: 50,
          minHeight: HEIGHT,
          justifyContent: "center",
        }}
      >
        {props.damagedCompany?.no || ""}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 110,
          minWidth: 110,
          minHeight: HEIGHT,
        }}
      >
        {props.damagedCompany?.structureName || ""}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 150,
          minWidth: 150,

          minHeight: HEIGHT,
        }}
      >
        {props.damagedCompany?.companyName || ""}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          flexGrow: 1,
          minHeight: HEIGHT,
        }}
      >
        {props.damagedCompany?.damageIssue || ""}
      </CellConatiner>
      <CellConatiner
        className={`caption-12-rg`}
        style={{
          width: 120,
          minWidth: 120,
          minHeight: HEIGHT,
          justifyContent: "flex-end",
        }}
      >
        {props.damagedCompany?.financialStatementValuation
          ? AddComma(props.damagedCompany?.financialStatementValuation)
          : "-"}
      </CellConatiner>
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.div`
  width: 100%;
  cursor: default;

  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  background: var(--background-contents-01, #fff);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 2px 8px;

  color: #000;

  &:nth-child(1) {
    border-left: 2px solid #000;
  }
`;
