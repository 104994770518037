import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
  ChartOptions,
  ChartDataset,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import {
  AddComma,
  addCommasToIntegerPart,
} from "../../../../../common/commonUtil";
import {
  CapitalAcquisitionPlan,
  EstimatedCashFlow,
} from "../../../../../2.0lattice/router/reporting/reporting-detail/summary-business-plan/v1/interface/SBP.interface";
import styled from "styled-components";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  sbpData: any;
  scale?: number;
}

export const CashflowStatement: React.FC<Props> = (props) => {
  const { sbpData, scale = 1 } = props;

  const labels: string[] = [
    "기초현금",
    "예상자금조달 (+)",
    "예상매출 (+)",
    "예상지출액 (-)",
    "예상기말현금",
  ];
  const [chartData, setChartData] = useState<ChartDataset<"bar">[] | undefined>(
    []
  );

  useEffect(() => {
    if (!sbpData) return;

    const dataset: any = [];

    if (["1", "2"].includes(sbpData.ver)) {
      const data: EstimatedCashFlow = {
        ...sbpData.businessPlan.estimatedCashFlow,
      };

      const capitalData: CapitalAcquisitionPlan[] =
        sbpData.businessPlan.capitalAcquisitionPlan;

      const initialCashBalance =
        (data.govBalance || 0) + (data.cashOnHand || 0);

      const totalCapitalAmount = capitalData
        .map((item) => item.capitalAmount || 0)
        .reduce((prev, cur) => prev + cur, 0);
      const newSharesIssued = capitalData
        .filter((item) => item.solutionCapitalAcquisition === "신주발행")
        .map((item) => item.capitalAmount || 0)
        .reduce((prev, cur) => prev + cur, 0);
      const borrowinMoney = capitalData
        .filter((item) => item.solutionCapitalAcquisition === "자금차입")
        .map((item) => item.capitalAmount || 0)
        .reduce((prev, cur) => prev + cur, 0);
      const govSubsidy = capitalData
        .filter((item) => item.solutionCapitalAcquisition === "정부지원금")
        .map((item) => item.capitalAmount || 0)
        .reduce((prev, cur) => prev + cur, 0);
      const suspenseReceipts = capitalData
        .filter((item) => item.solutionCapitalAcquisition === "가수금")
        .map((item) => item.capitalAmount || 0)
        .reduce((prev, cur) => prev + cur, 0);
      const other = capitalData
        .filter((item) => item.solutionCapitalAcquisition === "기타")
        .map((item) => item.capitalAmount || 0)
        .reduce((prev, cur) => prev + cur, 0);

      const expectedCashInFlow = data.expectedCashInflow || 0;

      const expectedExpendse =
        (data.estimatedGovFundingUsage || 0) + (data.totalExpectedExpense || 0);

      dataset.push({
        label: "법인기초잔액",
        data: [
          [0, data.cashOnHand || 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        backgroundColor: "#523FCF",
      });

      dataset.push({
        label: "정부지원금잔액",
        data: [
          [0, data.govBalance || 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        backgroundColor: "#8E7DFA",
      });

      dataset.push({
        label: "신주발행",
        data: [
          [0, 0],
          [initialCashBalance, initialCashBalance + newSharesIssued],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        backgroundColor: "#173AFF",
      });
      dataset.push({
        label: "자금차입",
        data: [
          [0, 0],
          [0, borrowinMoney],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        backgroundColor: "#4584FF",
      });
      dataset.push({
        label: "정부지원금",
        data: [
          [0, 0],
          [0, govSubsidy],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        backgroundColor: "#A2C7FF",
      });
      dataset.push({
        label: "가수금",
        data: [
          [0, 0],
          [0, suspenseReceipts],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        backgroundColor: "#D1E3FF",
      });
      dataset.push({
        label: "기타",
        data: [
          [0, 0],
          [0, other],
          [0, 0],
          [0, 0],
          [0, 0],
        ],
        backgroundColor: "#E8F0FF",
      });

      dataset.push({
        label: "매출",
        data: [
          [0, 0],
          [0, 0],
          [
            initialCashBalance + totalCapitalAmount,
            initialCashBalance + totalCapitalAmount + expectedCashInFlow,
          ],
          [0, 0],
          [0, 0],
        ],
        backgroundColor: "rgba(209, 227, 255, 1)",
      });

      dataset.push({
        label: "정부지원금 지출계획",
        data: [
          [0, 0],
          [0, 0],
          [0, 0],
          [
            initialCashBalance +
              expectedCashInFlow +
              totalCapitalAmount -
              (data.estimatedGovFundingUsage || 0) -
              (data.totalExpectedExpense || 0),
            initialCashBalance +
              expectedCashInFlow +
              totalCapitalAmount -
              (data.totalExpectedExpense || 0),
          ],
          [0, 0],
        ],
        backgroundColor: "#F6444F",
      });
      dataset.push({
        label: "법인자금 지출계획",
        data: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, data.totalExpectedExpense || 0],
          [0, 0],
        ],
        backgroundColor: "#FFCACD",
      });

      dataset.push({
        label: "예상기말현금",
        data: [
          [0, 0],
          [0, 0],
          [0, 0],
          [0, 0],
          [
            0,
            initialCashBalance +
              expectedCashInFlow +
              totalCapitalAmount -
              expectedExpendse,
          ],
        ],
        backgroundColor: "rgba(209, 227, 255, 1)",
      });
    }

    setChartData(dataset);
  }, [sbpData]);

  if (!chartData) return null;
  return (
    <Container>
      <Bar
        style={{ display: "inline-block" }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
              labels: {
                boxWidth: 20,
              },
              onClick: (e) => {},
            },
            title: {
              display: false,
              text: "Chart.js Bar Chart",
            },

            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  console.log(tooltipItem);
                  const value = tooltipItem.formattedValue
                    .replace("[", "")
                    .replace("]", "")
                    .split(",");
                  const a = value[0] ? parseInt(value[0]) : 0;
                  const b = value[1] ? parseInt(value[1]) : 0;
                  return `${tooltipItem.dataset.label} : ${
                    tooltipItem.dataIndex === 3 ? "-" : ""
                  }${addCommasToIntegerPart(b - a)}원`;
                },
              },
            },
          },
          datasets: {
            bar: {
              barPercentage: 0.6,
              categoryPercentage: 0.6,
            },
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true,
              suggestedMax: scale,
              ticks: {
                callback: (value, index, ticks) => {
                  const tickNum = parseInt(`${value}`) / 100000000;
                  if (index === 0) {
                    return `${tickNum} (억)`;
                  }
                  return AddComma(tickNum);
                },
              },
            },
          },
        }}
        data={{ labels: labels, datasets: chartData }}
        height={350}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
