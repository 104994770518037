import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  PHASE_CLOSURE,
  PHASE_DAMAGE,
  PHASE_EXIT,
  PHASE_MANAGE,
  PortfolioAdminProps,
} from "../interface/Portfolio.interface";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { CircleUserList } from "../../../../accelerating/organism/CircleUserList";

interface Props {
  id?: string;
  portfolio: PortfolioAdminProps;
  isSelected: boolean;
  isShowDetail?: boolean;
  onClickCard?: () => void;
}
export const PortfolioCard: React.FC<Props> = (props) => {
  const { id, portfolio, isSelected, isShowDetail, onClickCard } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const getLabel = () => {
    const colorStyle = {
      textColor: "",
      bgColor: "",
    };
    switch (portfolio.pfPhase.pfPhaseId) {
      case PHASE_MANAGE:
        colorStyle.bgColor = "var(--green-100)";
        colorStyle.textColor = "var(--green-700)";
        break;
      case PHASE_DAMAGE:
        colorStyle.bgColor = "var(--red-200)";
        colorStyle.textColor = "var(--red-500)";
        break;
      case PHASE_CLOSURE:
        colorStyle.bgColor = "var(--gray-300)";
        colorStyle.textColor = "var(--gray-600)";
        break;
      case PHASE_EXIT:
        colorStyle.bgColor = "var(--blue-100)";
        colorStyle.textColor = "var(--blue-500)";
        break;
      default:
        colorStyle.bgColor = "";
        colorStyle.textColor = "";
        break;
    }
    return (
      <CustomColorLabel {...colorStyle}>
        {portfolio.pfPhase.name}
      </CustomColorLabel>
    );
  };

  return (
    <Container
      id={id}
      className={`${isShowDetail ? "is__show__Detail" : ""} ${
        isSelected ? "is__selected" : ""
      }`}
      ref={containerRef}
      onClick={onClickCard}
    >
      {getLabel()}
      <PortfolioName className="heading-18-sb">
        {portfolio.companyName}
      </PortfolioName>
      {!isShowDetail && (
        <Description className="caption-12-rg">
          {portfolio.description}
        </Description>
      )}

      {!isShowDetail && (
        <FlexRow
          style={{
            width: "100%",
            marginTop: 10,
            justifyContent: "space-between",
          }}
        >
          <CircleUserList
            userList={portfolio.pfParticipant.map((item) => ({
              profileImg: item.acUser.profileImgUrl,
              userName: item.acUser.name,
            }))}
            maxShowUserCount={2}
          />
          <VerticalDivder />
        </FlexRow>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  flex-grow: 0;
  flex-basis: 282px;

  height: 200px;
  min-height: 200px;
  max-height: 200px;

  padding: 24px 24px 24px 16px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  cursor: pointer;

  &.is__show__Detail {
    height: 106px;
    min-height: 106px;
    max-height: 106px;
  }

  &.is__selected {
    border: 1px solid var(--focus-field-blue, #a2c7ff);
    background-color: var(--blue-50);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
const IndustrialTech = styled.span`
  width: calc(100% - 20px);
  color: var(--primary-blue);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const PortfolioName = styled.div`
  width: 100%;
  margin-top: 6px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  width: 100%;
  min-height: 40px;
  margin-top: 12px;
  color: var(--text-05);

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const MoreWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;

  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;

  border-radius: 4px;
  transition: all 0.2 ease;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: var(--hover-200);
  }
`;

const ListContainer = styled.div`
  position: fixed;
  width: 164px;
  max-height: 236px;
  display: flex;
  flex-direction: column;

  z-index: 2;

  padding: 8px 0px;

  border-radius: 4px;
  box-shadow: 2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  background-color: var(--background-contents02);
  overflow: scroll;
`;

const ListItem = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 9px 16px;

  cursor: pointer;

  &.selected {
    background-color: var(--blue-100);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;
const VerticalDivder = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-200, #edeeef);
`;
