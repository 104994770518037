import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../../components/navigation/NavigationLayout";
import MobileRiskManagementEvaluation from "./mobile/MobileRiskManagementEvaluation";
import DeskRiskManagementEvaluation from "./desk/DeskRiskManagementEvaluation";

const RiskManagementEvaluation = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? (
        <MobileRiskManagementEvaluation />
      ) : (
        <DeskRiskManagementEvaluation />
      )}
    </NavigationLayout>
  );
};
export default RiskManagementEvaluation;
