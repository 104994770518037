import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { StockChangeHistoryRealData } from "../interface/Stock.interface";
import { StockChangeErrorReport } from "./StockChangeHistoryTableModal";

const useMakeErrorReport = () => {
  const getHeaderDataUseIndex = (index: number) => {
    const headerWidthData = [
      148, 148, 148, 274, 164, 192, 148, 148, 148, 148, 148, 148, 274, 214, 380,
    ];
    return headerWidthData[index];
  };

  const getCellPosition = (columNum: number, rowNum: number) => {
    let x = 0;
    let y = 0;
    for (let i = 0; i < columNum; i += 1) {
      x += getHeaderDataUseIndex(i);
    }
    y = 50 * rowNum + 34;

    return { x, y };
  };

  const makeErrorMap = (realData: StockChangeHistoryRealData[]) => {
    const errorMap: any[] = [];

    realData.forEach((item) => {
      const { data } = item;
      const rowErrorArr = [
        data.date !== undefined && data.date.state === "error",
        data.stockChangeType !== undefined &&
          data.stockChangeType.state === "error",
        data.stageType !== undefined && data.stageType.state === "error",
        data.stockholderName !== undefined &&
          data.stockholderName.state === "error",
        data.registrationNumber !== undefined &&
          data.registrationNumber.state === "error",
        data.relationship !== undefined && data.relationship.state === "error",
        data.country !== undefined && data.country.state === "error",
        data.stockType !== undefined && data.stockType.state === "error",
        data.fluctuateStockNumber !== undefined &&
          data.fluctuateStockNumber.state === "error",
        data.faceValue !== undefined && data.faceValue.state === "error",
        data.issuingPrice !== undefined && data.issuingPrice.state === "error",
        data.totalInvestmentAmount !== undefined &&
          data.totalInvestmentAmount.state === "error",
        data.seller !== undefined && data.seller.state === "error",
        data.sellerRegistrationNumber !== undefined &&
          data.sellerRegistrationNumber.state === "error",
        data.note !== undefined && data.note.state === "error",
      ];
      errorMap.push(rowErrorArr);
    });

    return errorMap;
  };
  const makeErrorReport = (errorMap: any[]) => {
    const errorReportData: StockChangeErrorReport[] = [];
    for (let i = 0; i < 15; i += 1) {
      let findError = false;
      let msg = "";
      let rowCounter = 0;
      let errorType: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 = 1;
      for (let j = 0; j < errorMap.length; j += 1) {
        if (errorMap[j][i]) {
          if (!findError) {
            msg += `[${String.fromCharCode("A".charCodeAt(0) + i)}:`;
            findError = true;
          }
          msg += `${j + 3},`;
          rowCounter += 1;
          if (!(j + 1 < errorMap.length && errorMap[j + 1][i])) {
            msg = msg.slice(0, msg.length - 1);
            switch (i) {
              case 0:
                msg += `]의 일자를 정확한 형식으로 입력해 주세요.`;
                errorType = 1;
                break;
              case 1:
                msg += `]의 변동내역 항목을 정확히 입력해 주세요.\n예시) 설립자본금, 유상증자, 유상감자, 무상증자, 무상감자, 액면분할, 액면합병, 구주거래`;
                errorType = 2;
                break;
              case 7:
                msg += `]의 주식종류를 정확히 입력해 주세요.\n예시) 보통주, 우선상환주, 전환우선주, 상환전환주, 상환전환우선주`;
                errorType = 3;
                break;
              case 8:
                msg += `]의 변동 주식수는 양수(+)의 숫자만 입력 가능합니다. 구주거래 및 액면합병/무상감자의 경우 자동으로 음수(-)로 변환됩니다.`;
                errorType = 4;
                break;
              case 10:
                msg += `]의 주당발행가액은 무상증자/무상감자/액면분할/액면합병의 경우 0원으로 입력해야 합니다. 변경 후 다시 시도해 주세요.`;
                errorType = 5;
                break;
              case 12:
                msg += `]은 구주거래일 경우 필수값입니다. 다시 한번 확인해 주세요.`;
                errorType = 6;
                break;
              case 13:
                msg += `]은 구주거래일 경우 필수값입니다. 다시 한번 확인해 주세요.`;
                errorType = 7;
                break;
              default:
                break;
            }
            const cellStartPosition = getCellPosition(i, j - rowCounter + 1);

            errorReportData.push({
              groupId: errorReportData.length,
              step: "validation",
              msg,
              errorType,
              xPosition: cellStartPosition.x,
              yPosition: cellStartPosition.y,
              rangeX: {
                sX: i,
                eX: i,
              },
              rangeY: {
                sY: j - rowCounter + 1,
                eY: j,
              },
            });

            msg = "";
            findError = false;
            rowCounter = 0;
          }
        }
      }
    }
    return errorReportData;
  };
  return [makeErrorMap, makeErrorReport] as const;
};

export default useMakeErrorReport;
