import React from "react";
import styled from "styled-components";
import { AddComma } from "../../../../../common/commonUtil";
import { StockholderHeader } from "../component/stockholder-table/StockholderHeader";
import { StockHolderGrouping } from "../../interface/InvestmentInfo.interface";
import { StockholderRow } from "../component/stockholder-table/StockholderRow";
import { StockholderFooter } from "../component/stockholder-table/StockholderFooter";

interface Props {
  list: StockHolderGrouping[];
}

export const PortfolioStockHolderList: React.FC<Props> = (props) => {
  const totalStockNum = props.list
    .map((item) => item.totalStockNumber)
    .reduce((p, c) => p + c, 0);
  const totalPercent = props.list
    .map((item) => item.totalPercentage)
    .reduce((p, c) => p + c, 0);
  const totalAmount = props.list
    .map((item) => item.totalAmount)
    .reduce((p, c) => p + c, 0);
  return (
    <Container className="font-medium-14">
      <StockholderHeader headerSticky={true} />
      <Body>
        {props.list.map((item: StockHolderGrouping, index: number) => {
          return <StockholderRow key={index} data={item} index={index} />;
        })}
      </Body>
      <StockholderFooter
        totalStockNum={totalStockNum}
        totalPercent={totalPercent}
        totalAmount={totalAmount}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

const Body = styled.div`
  width: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;
