import React, { useEffect, useRef, useState } from "react";
import Styled, { css } from "styled-components";
import { ResultType } from "../../../interface/PrePitchingDetail.interface";
import { getRangeArray } from "../../../../../../common/commonUtil";

interface Props {
  type: string;
  item: ResultType;
}

interface ResultMulti {
  title?: string;
  value?: number;
}
const ResultMultiple: React.FC<Props> = (props) => {
  const maxValue = useRef<number>(0);
  const [render, setRender] = useState<boolean>(true);
  const reRender = () => setRender((prevState) => !prevState);

  const [data, setData] = useState<ResultMulti[] | undefined>(
    props.item.answer?.map((item: any) => ({}))
  );

  const getData = () => {
    const _data = props.item.answer?.map(
      (item: any, index: number): ResultMulti => {
        if (maxValue.current < item.value) {
          maxValue.current = item.value;
        }
        return { title: item.index, value: item.value };
      }
    );

    if (props.item.hasOtherOpinion) {
      props.item.otherOpinion?.forEach((item) => {
        if (item.otherOpinion.isSelected) {
          _data?.push({ title: item.otherOpinion.opinion, value: 1 });
        }
      });
    }
    setData(_data || []);
  };

  useEffect(() => {
    getData();
  }, [props.item]);
  return (
    <Container className="body-14-rg ">
      {/* {props.item.answer && <span>응답 {props.item.answer.length}개</span>} */}
      <ResultContainer>
        {data?.map((item, index) => {
          return (
            <MultiItem
              key={index}
              value={item.value || 0}
              maxValue={maxValue.current || 0}
            >
              <span className="title">{item.title}</span>
              <div className="bar__wrapper">
                <div className="bar active"></div>
                <span className="percent">
                  {item.value} (
                  {maxValue.current > 0
                    ? Math.floor((100 * (item.value || 0)) / maxValue.current)
                    : 0}
                  %)
                </span>
              </div>
            </MultiItem>
          );
        })}
        <MultiChartLine>
          <span className="title">{}</span>
          <div className="line__wrapper">
            {getRangeArray(0, maxValue.current || 0).map((item, index) => {
              return (
                <ChartLegen
                  key={index}
                  value={Math.floor((400 * index) / maxValue.current)}
                >
                  {index}
                </ChartLegen>
              );
            })}
          </div>
        </MultiChartLine>
      </ResultContainer>
    </Container>
  );
};

const Container = Styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ResultContainer = Styled.ul(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `
);

const MultiChartLine = Styled.li(
  () => css`
    display: flex;
    flex-direction: row;
    height: 30px;
    .title {
      width: 218px;
      text-align: right;
      padding-right: 16px;
    }
    .line__wrapper {
      position: relative;
      width: 400px;
      height: 1px;
      background-color: var(--grey_20);
    }
  `
);
const ChartLegen = Styled.div(
  ({ value }: { value: number }) => css`
    position: absolute;
    top: 10px;
    left: ${value}px;
  `
);

const MultiItem = Styled.li(
  ({ value, maxValue }: { value: number; maxValue: number }) => css`
    display: flex;
    flex-direction: row;
    .title {
      width: 218px;
      text-align: right;
      padding-right: 16px;
    }
    .bar__wrapper {
      position: relative;
      width: 500px;
    }
    .bar {
      width: 0px;
      height: 20px;
      background-color: var(--blue-400);
      transition: all 0.2s;
    }
    .active {
      width: ${Math.floor((400 * value) / maxValue)}px;
    }
    .percent {
      position: absolute;
      top: 0;
      left: ${Math.floor((400 * value) / maxValue) + 10}px;
    }
  `
);

export default ResultMultiple;
