import React from "react";
import styled from "styled-components";

import { InvestmentHistoryProps } from "../../../interface/InvestmentInfo.interface";
import {
  AddComma,
  addCommasToIntegerPart,
  getYMD,
} from "../../../../../../common/commonUtil";
import { BoxComponent } from "../../../../../../components-2.0v/box/BoxComponent";

interface Props {
  data: InvestmentHistoryProps;
}
export const FundAccountDetailRow: React.FC<Props> = ({ data }) => {
  return (
    <Container className={`caption-12-rg`}>
      <FlexRow
        style={{ width: 60, justifyContent: "center", alignItems: "center" }}
      >
        {data.type === "INVEST" ? "투자" : "판매"}
      </FlexRow>
      <FlexColumn
        style={{
          flexGrow: 1,
          paddingTop: 12,
          paddingBottom: 12,
          paddingRight: 12,
        }}
      >
        <FlexRow style={{ width: "100%" }}>
          {addCommasToIntegerPart(data.amount)}원 ( {AddComma(data.stock)}주 *{" "}
          {addCommasToIntegerPart(data.issuingPrice)}원)
        </FlexRow>
        <FlexRow
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>{data.target}</div>
          <div>{getYMD(data.date)}</div>
        </FlexRow>
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  background-color: var(--background-contents01, #fff);
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
