import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Open } from "../../../../assets/image/icon_eye_open.svg";
import { ReactComponent as Close } from "../../../../assets/image/icon_eye_close.svg";

/**
 * @returns JSX.Element
 *
 * props {
 *  isPassword?: boolean;
 * defaultValue?: string;
 * isShowValidationMsg?: boolean;
 * validationMsg?: string;
 * style?: CSSProperties;
 * onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
 * onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
 * onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
 * onEnterPress?: (value: string) => void;
 * }
 *
 */

interface Props {
  isPassword?: boolean;
  defaultValue?: string;
  isShowValidationMsg?: boolean;
  validationMsg?: string;
  placeholder?: string;
  style?: CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onEnterPress?: (value: string) => void;
  transfromPixel: (value: number) => number;
}
const LoginInputBox: React.FC<Props> = (props): JSX.Element => {
  const [value, updateValue] = useState<string>("");
  const [focussing, updateFocussing] = useState<boolean>(false);
  const [showPassword, updateShowPassword] = useState<boolean>(false);

  useEffect(() => {
    if (props.defaultValue) {
      updateValue(props.defaultValue);
    }
  }, []);

  return (
    <Container
      className="body-14-md"
      style={props.style}
      transfromPixel={props.transfromPixel}
    >
      <InputContainer transfromPixel={props.transfromPixel}>
        <InputComponent
          transfromPixel={props.transfromPixel}
          className={`${
            props.isShowValidationMsg ? "show__msg" : ""
          } body-14-md`}
          style={{
            paddingRight: props.isPassword
              ? props.transfromPixel(42)
              : props.transfromPixel(10),
          }}
          type={props.isPassword && !showPassword ? "password" : "text"}
          placeholder={props.placeholder}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              props.onEnterPress && props.onEnterPress(value);
            }
          }}
          onChange={(e) => {
            updateValue(e.target.value);
            props.onChange && props.onChange(e);
          }}
          onFocus={(e) => {
            updateFocussing(true);
            props.onFocus && props.onFocus(e);
          }}
          onBlur={(e) => {
            updateFocussing(false);
            props.onBlur && props.onBlur(e);
          }}
          value={value}
        />
        <span
          className={`focus-border ${
            focussing && !props.isShowValidationMsg ? "focussing" : ""
          }`}
        >
          <i />
        </span>
        {props.isPassword && (
          <div
            className="password-icon"
            onClick={() => updateShowPassword((prev) => !prev)}
          >
            {showPassword ? <Open /> : <Close />}
          </div>
        )}
      </InputContainer>
      {props.validationMsg && props.isShowValidationMsg && (
        <span className="validation__msg body-14-md">
          {props.validationMsg}
        </span>
      )}
    </Container>
  );
};

const Container = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: ${(props) => props.transfromPixel(334)}px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.transfromPixel(8)}px;
  .validation__msg {
    color: var(--red-500);
  }
`;
const InputContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  position: relative;
  width: ${(props) => props.transfromPixel(332)}px;
  height: ${(props) => props.transfromPixel(46)}px;
  border-radius: ${(props) => props.transfromPixel(4)}px;
  overflow: hidden;

  .password-icon {
    position: absolute;
    width: ${(props) => props.transfromPixel(24)}px;
    height: ${(props) => props.transfromPixel(24)}px;
    right: ${(props) => props.transfromPixel(16)}px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    border-radius: ${(props) => props.transfromPixel(4)}px;
    :hover {
      background: var(--focus-basic);
    }

    svg {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .focus-border {
    ::before,
    ::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: var(--focus-field-blue);
      transition: 0.2s;
    }
    ::after {
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
    }
    i {
      ::before,
      ::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 0;
        background-color: var(--focus-field-blue);
        transition: 0.2s;
      }
      ::after {
        left: auto;
        right: 0;
        top: auto;
        bottom: 0;
      }
    }
  }
  .focus-border.focussing {
    ::before,
    ::after {
      width: 100%;
      transition: 0.3s;
    }

    i {
      ::before,
      ::after {
        height: 100%;
        transition: 0.4s;
      }
    }
  }
`;
const InputComponent = styled.input<{
  transfromPixel: (value: number) => number;
}>`
  width: ${(props) => props.transfromPixel(332)}px;
  height: ${(props) => props.transfromPixel(46)}px;
  padding: ${(props) => props.transfromPixel(12)}px
    ${(props) => props.transfromPixel(10)}px;

  border: ${(props) => props.transfromPixel(1)}px solid var(--gray-300);
  border-radius: ${(props) => props.transfromPixel(4)}px;
  transition: 0.4s;
  background: var(--background-contents01);

  &.show__msg {
    border: ${(props) => props.transfromPixel(1)}px solid var(--red-500);
    animation: shake 200ms;
  }

  :focus {
    outline: none;
  }

  @keyframes shake {
    25% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    50% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
    75% {
      transform: translateX(${(props) => props.transfromPixel(2)}px);
    }
    100% {
      transform: translateX(-${(props) => props.transfromPixel(2)}px);
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export default LoginInputBox;
