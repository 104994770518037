import React, { useEffect, useRef, useState } from "react";
import Styled from "styled-components";
import BubbleDefault from "../../../../../components/bubble_label/BubbleDefault";
import axios from "axios";
import * as api from "../../../../../api/api";
import { IndustryTech } from "../../../../../type/data";
import { IndustryLabel } from "./IndustryLabel";

interface Props {
  className?: string;
  allIndustryTech?: IndustryTech[];
  defaultData?: IndustryTech[];
  onChange?: (selected: IndustryTech[]) => void;
}

export const IndustrialTechSector: React.FC<Props> = (props) => {
  const { className, allIndustryTech = [], defaultData, onChange } = props;

  const [selectedIndustrialTech, updateSelectedIndustrialTech] = useState<
    IndustryTech[]
  >([]);

  const onSectorClick = (item: IndustryTech) => {
    const index = selectedIndustrialTech.findIndex(
      (industry) => industry.acIndustrialTechId === item.acIndustrialTechId
    );
    const newData = structuredClone(selectedIndustrialTech);
    if (index !== -1) {
      newData.splice(index, 1);
    } else {
      newData.push(item);
    }
    updateSelectedIndustrialTech([...newData]);
    onChange && onChange([...newData]);
  };

  useEffect(() => {
    updateSelectedIndustrialTech(defaultData || []);
  }, [defaultData]);

  return (
    <Container className={props.className || ""}>
      <SectorContainer>
        {allIndustryTech.map((item, index) => {
          return (
            <IndustryLabel
              key={index}
              isChecked={selectedIndustrialTech
                .map((item) => item.acIndustrialTechId)
                .includes(item.acIndustrialTechId)}
              onClick={() => {
                onSectorClick(item);
              }}
            >
              {item.name}
            </IndustryLabel>
          );
        })}
      </SectorContainer>
    </Container>
  );
};

const Container = Styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const SectorContainer = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  gap: 12px;
`;
