import React from "react";

import styled from "styled-components";
import { TableGridHeaderProps, TableGridProps } from "./TableGrid";
import CustomHeader from "./CustomHeader";

interface Props {
  isReadOnly?: boolean;
  headers: TableGridHeaderProps[];
  hasDeleteBtn: boolean;
  transfromPixel: (value: number) => number;
}

const TableHeader: React.FC<Props> = ({
  isReadOnly,
  headers,
  hasDeleteBtn,
  transfromPixel,
}) => {
  return (
    <Container>
      {headers.map((item, index) => {
        return (
          <CellConatiner
            key={index}
            className={`body-14-rg ${index === 0 ? "first" : ""} ${
              index === headers.length - 1 ? "last" : ""
            }`}
            style={{
              ...item.style,
              width: item.width || 100,
              minWidth: item.width || 100,
              minHeight: transfromPixel(34),
            }}
          >
            {item.customHeaderCell ? (
              item.customHeaderCell(item)
            ) : (
              <CustomHeader
                title={item.header}
                tooltipId={item.tooltipId}
                transfromPixel={transfromPixel}
                borderLeftHide={index === 0}
              />
            )}
          </CellConatiner>
        );
      })}
      {hasDeleteBtn && (
        <CellConatiner style={{ minWidth: 74 }}>
          {!isReadOnly && (
            <CustomHeader
              title="삭제"
              transfromPixel={transfromPixel}
              borderLeftHide={isReadOnly}
            />
          )}
        </CellConatiner>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0px;
  width: auto;
  display: flex;
  flex-direction: row;
`;

const CellConatiner = styled.div`
  width: 74px;
  cursor: default;
  color: var(--primary);
  background-color: #f6faff;
`;

export const CheckCellConatiner = styled.div`
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.opened {
    path {
      fill: #d1e3ff;
    }
  }
`;

export default TableHeader;
