import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { InvestmentProgressByDepartment } from "../../organism/InvestmentProgressByDepartment";
import { NewInvestment } from "../../organism/NewInvestment";
import { TipsInvestment } from "../../organism/TipsInvestment";
import { SelectorProps } from "../../../../../../type/data";
import {
  NewInvestmentProps,
  PerformanceTrendProps,
  TipsInvestmentProps,
} from "../../../interface/PerformanceMetrics.interface";
import {
  getPerformanceNewInvestment,
  getPerformanceTipsInvestment,
  getPerformanceTrend,
} from "../../../../../../api/repository/accelerator/DashboardRepository";
import { getNewDate, getRangeYear } from "../../../../../../common/commonUtil";
import { YearSelector } from "../../component/YearSelector";

interface Props {
  selectedFundAccountId: number[];
  selectedIndustryId: number[];
}

export const NewInvestmentSection: React.FC<Props> = (props) => {
  const { selectedFundAccountId, selectedIndustryId } = props;
  const isNetworking = useRef<boolean>(false);
  const [yearList, updateYearList] = useState<SelectorProps[]>([]);
  const [year, updateYear] = useState<SelectorProps | undefined>(undefined);
  const [performanceTrend, setPerformanceTrend] = useState<
    PerformanceTrendProps | undefined
  >(undefined);
  const [newInvestment, setNewInvestment] = useState<NewInvestmentProps[]>([]);
  const [tipsInvestment, setTipsInvestment] = useState<TipsInvestmentProps[]>(
    []
  );

  const getData = async () => {
    if (!year) return;

    isNetworking.current = true;
    const params = {
      year: year.id,
      acIndustrialTechId: selectedIndustryId,
      acFundAccountId: selectedFundAccountId,
    };

    const trendResult = await getPerformanceTrend(params);
    setPerformanceTrend(trendResult);

    const newInvestmentResult = await getPerformanceNewInvestment(params);
    newInvestmentResult && setNewInvestment(newInvestmentResult);

    const tipsInvestmentResult = await getPerformanceTipsInvestment(params);
    tipsInvestmentResult && setTipsInvestment(tipsInvestmentResult);
    isNetworking.current = false;
  };

  useEffect(() => {
    const curY = getNewDate().getFullYear();
    const data = getRangeYear(2013, curY, 1).map(
      (item, index): SelectorProps => {
        console.log(item);
        return {
          id: curY - index,
          text: `${curY - index}`.slice(2) + "년",
          data: curY - index,
        };
      }
    );

    updateYearList(data);
    updateYear(data[0]);
  }, []);

  useEffect(() => {
    // if (isNetworking.current) return;
    getData();
  }, [year, selectedFundAccountId]);

  return (
    <Container>
      <SectionHeader className="heading-20-sb">
        <span>신규투자</span>
        <YearSelector
          defaultData={year?.text}
          selectList={yearList}
          onChangeData={(data) => {
            updateYear(data);
          }}
        />
      </SectionHeader>
      <FlexRow style={{ gap: 16 }}>
        <div style={{ flex: 1, height: "100%" }}>
          <InvestmentProgressByDepartment
            performanceTrend={performanceTrend}
            showExcavation
          />
        </div>
        <div style={{ flex: 1, height: "100%" }}>
          <FlexColumn style={{ gap: 16, height: "100%" }}>
            <div style={{ flex: 1, height: "100%" }}>
              <NewInvestment isTotal newInvestment={newInvestment} />
            </div>
            <div style={{ flex: 1, height: "100%" }}>
              <TipsInvestment tipsInvestment={tipsInvestment} />
            </div>
          </FlexColumn>
        </div>
      </FlexRow>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
