import React, { useEffect, useRef, useState } from "react";

import { SBPContentsProps, SBPTabProps } from "../interface/SBP.interface";

import config from "../../../../../../../../config";

const useSBP = (document: SBPContentsProps) => {
  const [tabList, updateTabList] = useState<SBPTabProps[]>([]);
  const [selectedTabId, updateSelectedTabId] = useState<number>(1);

  const checkTabValidation = (sbpData: SBPContentsProps) => {
    if (!sbpData.quarter) return;

    const status: SBPTabProps = sbpData.quarter
      ? {
          id: 1,
          name: "반기 사업 현황",
          isComplete: false,
          tabSectionList: [
            {
              index: 1,
              sectionTitle: "사업 진행",
              isComplete: false,
              selectId: "status1",
            },
            {
              index: 2,
              sectionTitle: "정부 지원 과제 참여",
              isComplete: false,
              selectId: "status3",
            },
            {
              index: 3,
              sectionTitle: "자금 차입",
              isComplete: false,
              selectId: "status4",
            },
            {
              index: 4,
              sectionTitle: "인력 변동",
              isComplete: false,
              selectId: "status5",
            },
            {
              index: 5,
              sectionTitle: "투자 후 누적 출원 및 등록",
              isComplete: false,
              selectId: "status6",
            },
          ],
        }
      : {
          id: 1,
          name: "온기 사업 현황",
          isComplete: false,
          tabSectionList: [
            {
              index: 1,
              sectionTitle: "사업 진행",
              isComplete: false,
              selectId: "status1",
            },
            {
              index: 2,
              sectionTitle: "매출 실적",
              isComplete: false,
              selectId: "status2",
            },
            {
              index: 3,
              sectionTitle: "정부 지원 과제 참여",
              isComplete: false,
              selectId: "status3",
            },
            {
              index: 4,
              sectionTitle: "자금 차입",
              isComplete: false,
              selectId: "status4",
            },
            {
              index: 5,
              sectionTitle: "인력 변동",
              isComplete: false,
              selectId: "status5",
            },
            {
              index: 6,
              sectionTitle: "투자 후 누적 출원 및 등록",
              isComplete: false,
              selectId: "status6",
            },
          ],
        };

    const plan: SBPTabProps = {
      id: 2,
      name: sbpData.quarter === 2 ? "반기 사업 계획" : "온기 사업 계획",
      isComplete: false,
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "사업 계획",
          isComplete: false,
          selectId: "plan1",
        },
        {
          index: 2,
          sectionTitle: "자금 조달 계획",
          isComplete: false,
          selectId: "plan2",
        },
        {
          index: 3,
          sectionTitle: "현금 흐름 추정",
          isComplete: false,
          selectId: "plan3",
        },
      ],
    };

    const request: SBPTabProps = {
      id: 3,
      name: `${config.companyName} 요청 사항`,
      isComplete: false,
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "요청 사항",
          isComplete: true,
          selectId: "request1",
        },
        {
          index: 2,
          sectionTitle: "담당 심사역",
          isComplete: false,
          selectId: "request2",
        },
        {
          index: 3,
          sectionTitle: `${config.companyName}`,
          isComplete: false,
          selectId: "request3",
        },
      ],
    };

    updateTabList([status, plan, request]);
  };

  const initTabList = (sbpData: SBPContentsProps) => {
    checkTabValidation(sbpData);
  };

  const setSBP = (data: SBPContentsProps) => {
    initTabList(data);
  };

  const goToTop = () => {
    const root = window.document.querySelector("#sbp__root");

    if (root) {
      root.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const init = async () => {
    setSBP(document);
  };

  useEffect(() => {
    init();
  }, []);

  return [tabList, selectedTabId, updateSelectedTabId, goToTop] as const;
};

export default useSBP;
