import React from "react";
import styled from "styled-components";

import usePerformanceMetrics from "../hook/usePerformanceMetrics";
import { PerformanceMetricsTabType } from "../interface/PerformanceMetrics.interface";
import { SelectorProps } from "../../../../type/data";
import { DepartmentPerformance } from "./page/department/DepartmentPerformance";
import { PersonPerformance } from "./page/person/PersonPerformance";
import { CompanyPerformance } from "./page/company/CompanyPerformance";
import { SearchUser } from "./component/SearchUser";
import { NavigationLayout } from "../../../../components/navigation/NavigationLayout";
import CheckedChip from "../../../excavation/desktop/components/CheckedChip";

export const MobilePerformanceMetrics = () => {
  const [
    loaded,
    allUser,
    fundAccount,
    industry,
    selectedTab,
    selectedUser,
    allStructure,
    structureId,
    onChangeTab,
    onChangeStructure,
    onChangeUser,
  ] = usePerformanceMetrics();

  if (!loaded) return null;

  return (
    <Container>
      <ContentsContainer>
        <ContentsHeader>
          <FlexRow
            style={{
              alignItems: "flex-end",
              gap: 24,
              padding: "12px 24px 0px 24px",
              borderBottom: "1px solid var(--gray-200)",
            }}
          >
            <FlexRow>
              <TabItem
                className={`heading-20-sb ${
                  selectedTab === PerformanceMetricsTabType.COMPANY
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  onChangeTab(PerformanceMetricsTabType.COMPANY);
                }}
              >
                전체
              </TabItem>
            </FlexRow>
            <FlexRow style={{ alignItems: "flex-start", gap: 8 }}>
              <TabItem
                className={`heading-20-sb ${
                  selectedTab === PerformanceMetricsTabType.DEPARTMENT
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  onChangeTab(PerformanceMetricsTabType.DEPARTMENT);
                }}
              >
                조직
              </TabItem>
            </FlexRow>
            <FlexRow style={{ alignItems: "flex-start", gap: 8 }}>
              <TabItem
                className={`heading-20-sb ${
                  selectedTab === PerformanceMetricsTabType.PERSON
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  onChangeTab(PerformanceMetricsTabType.PERSON);
                }}
              >
                개인
              </TabItem>
            </FlexRow>
          </FlexRow>

          {selectedTab === PerformanceMetricsTabType.DEPARTMENT && (
            <StructureWarpper>
              <FlexRow
                style={{
                  width: "max-content",
                  gap: 12,
                  padding: "12px 24px",
                }}
              >
                {allStructure &&
                  allStructure.map((item) => {
                    return (
                      <CheckedChip
                        hideArrow
                        isActive={structureId === item.acStructureId}
                        contentsString={item.nickName || item.name}
                        onClick={() => {
                          onChangeStructure(item.acStructureId);
                        }}
                      />
                    );
                  })}
              </FlexRow>
            </StructureWarpper>
          )}
          {selectedTab === PerformanceMetricsTabType.PERSON && (
            <FlexColumn
              style={{
                padding: "12px 24px",
                borderBottom: "1px solid var(--gray-200)",
              }}
            >
              <FlexRow style={{ gap: 12, alignItems: "center" }}>
                <span
                  className="heading-14-md"
                  style={{ color: "var(--text-04)" }}
                >
                  선택된 사용자
                </span>
                <span className="heading-14-md">{selectedUser?.name}</span>
              </FlexRow>
              <Divider />
              <FlexRow style={{ gap: 12, alignItems: "center" }}>
                <span
                  className="heading-14-md"
                  style={{ color: "var(--text-04)" }}
                >
                  사용자 변경
                </span>
                <SearchUser
                  selectList={
                    allUser
                      ? allUser.map(
                          (item): SelectorProps => ({
                            id: parseInt(`${item.id}`),
                            text: item.name,
                            data: item,
                          })
                        )
                      : []
                  }
                  onChangeData={(user) => {
                    onChangeUser(user.data);
                  }}
                />
              </FlexRow>
            </FlexColumn>
          )}
        </ContentsHeader>
        <ContentsBody>
          {selectedTab === PerformanceMetricsTabType.COMPANY && (
            <CompanyPerformance
              industry={industry || []}
              fundAccount={fundAccount || []}
            />
          )}
          {selectedTab === PerformanceMetricsTabType.DEPARTMENT && (
            <DepartmentPerformance
              structureId={structureId}
              fundAccount={fundAccount || []}
            />
          )}

          {selectedTab === PerformanceMetricsTabType.PERSON && (
            <PersonPerformance selectedUser={selectedUser} />
          )}
        </ContentsBody>
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const ContentsContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;

  display: flex;
  flex-direction: column;

  z-index: 10;
  background: var(--background-header);
`;

const ContentsBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
`;

const StructureWarpper = styled.div`
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--gray-200);
  overflow: scroll;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabItem = styled.div`
  position: relative;
  padding-bottom: 12px;
  color: var(--text-inactive, #bec0c2);

  transition: all 0.2s ease;
  cursor: pointer;

  &.selected {
    color: var(--primary-blue);

    ::after {
      background-color: var(--primary-blue);
    }
  }

  ::after {
    content: " ";
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 2px;
    background-color: transparent;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-200);
`;
