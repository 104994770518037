import React from "react";
import styled, { css } from "styled-components";

import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import config from "../../../../config";

import { ReactComponent as Close } from "../../../../assets/image/modal_close.svg";
import { ReactComponent as CheckRound } from "../../../../assets/image/ic_check_round.svg";

interface Props {
  uuid: string;
  cancel: () => void;
}

const BluecardLinkModal: React.FC<Props> = (props) => {
  const getLinkUrl = () => {
    return config.clientUrl + `bluecard/link/${props.uuid}`;
  };

  const showToast = () => {
    const messageDiv = (
      <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        <CheckRound />
        <span className="font-medium-16" style={{ color: "black" }}>
          공유링크가 복사되었습니다.
        </span>
      </div>
    );
    toast.success(messageDiv, {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: "save",
      autoClose: 1000,
      style: {
        background: "white",
        width: "360px",
        height: "96px",
        position: "fixed",
        right: 0,
        bottom: 0,
      },
      progressStyle: {
        background: "#83CC39",
      },
    });
  };

  return (
    <PDFViewer>
      <Header>
        <span className="font-medium-20">블루카드 공유</span>
        <Close className="close" onClick={props.cancel} />
      </Header>
      <Body className="scroll__invisible">
        {props.uuid !== undefined && (
          <CopyToClipboard
            text={getLinkUrl()}
            onCopy={() => {
              showToast();
            }}
          >
            <UrlContainer>
              <UrlText className="font-regular-14">
                <span className="link_url">{getLinkUrl()}</span>
              </UrlText>
              <CopyBtn className="font-medium-14">링크복사</CopyBtn>
            </UrlContainer>
          </CopyToClipboard>
        )}
      </Body>
    </PDFViewer>
  );
};

const PDFViewer = styled.div`
  width: 768px;
  height: fit-content;

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid #c4c4c4;
  .close {
    cursor: pointer;
  }
`;

const Body = styled.div`
  width: 100%;
  padding: 40px 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UrlContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const UrlText = styled.div`
  width: 455px;
  max-width: 455px;
  height: auto;
  min-height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;

  .link_url {
    color: #000000;
    width: 100%;
    padding: 9px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const CopyBtn = styled.button`
  width: 120px;
  height: 42px;
  background: #24364a;
  border-radius: 8px;
  color: #ffffff;

  border: 0;

  cursor: pointer;
`;
export default BluecardLinkModal;
