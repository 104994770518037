import React from "react";
import styled from "styled-components";

import useReferenceDocument from "./hook/useReferenceDocument";

const ReferenceDocument = () => {
  const [isLoaded, refDocumentInfo, getBody, getTitle] = useReferenceDocument();

  if (!refDocumentInfo || !isLoaded) return null;

  return (
    <Container>
      <Header>
        <span className="heading-18-sb title">{`${refDocumentInfo.year}년 ${
          refDocumentInfo.quarter
        }분기 ${getTitle()}`}</span>
      </Header>
      <Body>{getBody()}</Body>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Header = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  gap: 16px;

  border-bottom: 1px solid var(--divider-200);

  .title {
    color: var(--text-01);
  }

  .back__btn {
    cursor: pointer;
  }
`;
const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  background-color: var(--background-page);

  &.viewer {
    height: calc(100vh - 78px);
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default ReferenceDocument;
