import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  getCompanyValueChangeHistory,
  getEmployeeChangeHistory,
  getSBPLatest,
  getSummaryFinanceInfo,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import {
  CompanyValueHistoryProps,
  EmployeeHistoryProps,
  SummaryFinanceInfoProps,
} from "../../pre-pitching-detail/interface/PrePitchingDetail.interface";
import {
  getNewDate,
  getPortfolioIdInLocation,
} from "../../../../common/commonUtil";
import { getToday } from "../../../../common/commonApiData";

const usePortfolioDashboard = () => {
  const portfolioId = getPortfolioIdInLocation(location);
  const [financeHistory, updateFinanceHistory] = useState<
    SummaryFinanceInfoProps[]
  >([]);

  const [employeeHistory, updateEmployeeHistory] = useState<
    EmployeeHistoryProps[]
  >([]);

  const [companyValueHistory, updateCompanyValueHistory] = useState<
    CompanyValueHistoryProps[]
  >([]);

  const [sbpData, updateSbpData] = useState<any>();

  const [serverTime, setServerTime] = useState<Date>(getNewDate());

  const initPortfolioDashboard = async () => {
    const financeResult = await getSummaryFinanceInfo(portfolioId);
    const employeeResult = await getEmployeeChangeHistory(portfolioId);
    const companyValueResult = await getCompanyValueChangeHistory(portfolioId);
    const sbpResult = await getSBPLatest(portfolioId);
    const serverTimeResult = await getToday();

    const a: SummaryFinanceInfoProps[] = [];
    if (financeResult) {
      financeResult.forEach((item) => {
        if (item.quarterNum === 1) {
          const findData = a.find(
            (data) => data.year === item.year - 1 && data.quarterNum === 4
          );
          if (findData) {
            findData.revenue = item.revenue;
            findData.operatingProfit = item.operatingProfit;
            findData.netIncome = item.netIncome;
            findData.totalAssets = item.totalAssets;
            findData.totalEquity = item.totalEquity;
            findData.totalLiabilities = item.totalLiabilities;
          } else {
            a.push({ ...item, year: item.year - 1, quarterNum: 4 });
          }
        }
        a.push(item);
      });
    }

    if (serverTimeResult) {
      setServerTime(getNewDate(serverTimeResult));
    }

    updateFinanceHistory(a);
    updateEmployeeHistory(employeeResult || []);
    updateCompanyValueHistory(companyValueResult || []);
    updateSbpData(sbpResult ? sbpResult.data : undefined);
  };

  const init = async () => {
    await initPortfolioDashboard();
  };

  useEffect(() => {
    init();
  }, [location.pathname]);

  return [
    financeHistory,
    employeeHistory,
    companyValueHistory,
    sbpData,
    serverTime,
  ] as const;
};

export default usePortfolioDashboard;
