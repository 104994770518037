import React from "react";
import { useMediaQuery } from "react-responsive";
import { PortfolioNavigationDefaultProps } from "../portfolio-detail/interface/PortfolioNavigation.interface";
import MobilePrePitchingDetail from "./mobile/MobilePrePitchingDetail";
import DeskPrePitchingDetail from "./desktop/DeskPrePitchingDetail";

const PortfolioDashboard: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? (
    <MobilePrePitchingDetail {...props} />
  ) : (
    <DeskPrePitchingDetail {...props} />
  );
};
export default PortfolioDashboard;
