import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  RMEDContentsProps,
  RMEDNecessaryInfoProps,
} from "../../../interface/RMED.interface";
import { RMEDSector } from "../RMEDSector";

import { AutoResizableTextarea } from "../AutoResizableTextarea";
import { RMEDSectorDescription } from "../RMEDSectorDescription";
import { OXCheckBox } from "../OXCheckBox";
import ColumnTitleBox from "../../../../../../components/box/ColumnTitleBox";
import RowTitleBox from "../../../../../../components/box/RowTitleBox";

interface Props {
  isReadOnly: boolean;
  isFullData: boolean;
  closedCompany?: boolean;
  data?: RMEDContentsProps;
  necessaryInfo?: RMEDNecessaryInfoProps;
  onChangeData?: (data: RMEDContentsProps) => void;
}

export const RMEDIssue: React.FC<Props> = (props: Props) => {
  const {
    isReadOnly,
    closedCompany,
    isFullData,
    data,
    necessaryInfo,
    onChangeData,
  } = props;

  if (!data) return null;

  return (
    <Container>
      <RMEDSector title="폐업진행" id="issue1">
        <FlexColumn style={{ gap: 8 }}>
          <RMEDSectorDescription text="평가일 기준 폐업(청산, 해산) 절차를 진행하고 있다고 판단할 경우, 관련 증빙자료 제출이 가능해야 합니다." />
          <IssueWrapper>
            <RowTitleBox
              title="폐업 진행"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <OXCheckBox
                defaultData={
                  data.riskIssueList.find((item) => item.riskIssue.id === 7)
                    ?.result
                }
                onChangeData={(result) => {
                  const issue = data.riskIssueList.find(
                    (item) => item.riskIssue.id === 7
                  );
                  if (issue && onChangeData) {
                    issue.result = result;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>
          </IssueWrapper>
        </FlexColumn>
      </RMEDSector>

      {!closedCompany && (
        <RMEDSector title="대내이슈" id="issue2">
          <FlexColumn style={{ gap: 34 }}>
            <RMEDSectorDescription text="기업의 답변 및 근거 자료를 바탕으로 이슈 여부를 판단해 주세요." />
            {!isFullData && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-16-sb">핵심인력 이탈 여부</div>
                <IssueWrapper>
                  <ColumnTitleBox
                    title="판단 기준"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={true}
                      isShowWordCounter
                      placeholder=""
                      defaultData={
                        "대표이사 또는 CTO, CFO 등 주요 등기 임원의 교체 및 이탈이 발생 하였는가?"
                      }
                    />
                  </ColumnTitleBox>
                  <RowTitleBox
                    title="이슈 평가"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <OXCheckBox
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 1
                        )?.result
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 1
                        );
                        if (issue && onChangeData) {
                          issue.result = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </IssueWrapper>
              </FlexColumn>
            )}
            {!isFullData && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-16-sb">임직원 급감 여부</div>
                <IssueWrapper>
                  <ColumnTitleBox
                    title="판단 기준"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={true}
                      isShowWordCounter
                      placeholder=""
                      defaultData={
                        "최근 6개월 내에 소속 임직원 수의  50% 이상의 턴오버가 발생하였는가?"
                      }
                      onChangeData={(value) => {
                        if (onChangeData) {
                          // data.reportIssue.executiveIssue.text = value;
                          // onChangeData(data);
                        }
                      }}
                    />
                  </ColumnTitleBox>
                  <RowTitleBox
                    title="이슈 평가"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <OXCheckBox
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 3
                        )?.result
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 3
                        );
                        if (issue && onChangeData) {
                          issue.result = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </IssueWrapper>
              </FlexColumn>
            )}
            {!isFullData && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-16-sb">완전자본잠식 여부</div>
                <IssueWrapper>
                  <ColumnTitleBox
                    title="판단 기준"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={true}
                      isShowWordCounter
                      placeholder=""
                      defaultData={
                        "직전 연도말 재무제표상 완전 자본 잠식 상태인가?"
                      }
                      onChangeData={(value) => {
                        if (onChangeData) {
                          // data.reportIssue.executiveIssue.text = value;
                          // onChangeData(data);
                        }
                      }}
                    />
                  </ColumnTitleBox>
                  <RowTitleBox
                    title="이슈 평가"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <OXCheckBox
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 8
                        )?.result
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 8
                        );
                        if (issue && onChangeData) {
                          issue.result = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </IssueWrapper>
              </FlexColumn>
            )}
            {!isFullData && (
              <FlexColumn style={{ gap: 16 }}>
                <div className="heading-16-sb">현금흐름 이슈</div>
                <IssueWrapper>
                  <ColumnTitleBox
                    title="판단 기준"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={true}
                      isShowWordCounter
                      placeholder=""
                      defaultData={
                        "유의미한 후속투자(투자금 5억 이상 or 취득지분률 1% 이상)를 받았으나 평가일 기준 2년이 경과하였는가?\n당사 투자 이후 2년간 후속투자 유치가 없었는가?"
                      }
                      onChangeData={(value) => {
                        if (onChangeData) {
                          // data.reportIssue.executiveIssue.text = value;
                          // onChangeData(data);
                        }
                      }}
                    />
                  </ColumnTitleBox>
                  <RowTitleBox
                    title="이슈 평가"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <OXCheckBox
                      defaultData={
                        data.riskIssueList.find(
                          (item) => item.riskIssue.id === 9
                        )?.result
                      }
                      onChangeData={(result) => {
                        const issue = data.riskIssueList.find(
                          (item) => item.riskIssue.id === 9
                        );
                        if (issue && onChangeData) {
                          issue.result = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </IssueWrapper>
              </FlexColumn>
            )}
            <FlexColumn style={{ gap: 16 }}>
              <div className="heading-16-sb">경영진 이슈</div>
              <IssueWrapper>
                <ColumnTitleBox
                  title="기업 답변"
                  textClassName="heading-14-md"
                  titleWidth={208}
                  style={{ width: "100%" }}
                  transfromPixel={(n) => n}
                >
                  <AutoResizableTextarea
                    isReadOnly={true}
                    isShowWordCounter
                    placeholder="경영진 이슈가 없습니다."
                    defaultData={
                      necessaryInfo?.curSBPIssue.executiveIssue.text || ""
                    }
                  />
                </ColumnTitleBox>
                <RowTitleBox
                  title="이슈 평가"
                  textClassName="heading-14-md"
                  titleWidth={208}
                  style={{ width: "100%" }}
                  transfromPixel={(n) => n}
                >
                  <OXCheckBox
                    defaultData={
                      data.riskIssueList.find((item) => item.riskIssue.id === 2)
                        ?.result
                    }
                    onChangeData={(result) => {
                      const issue = data.riskIssueList.find(
                        (item) => item.riskIssue.id === 2
                      );
                      if (issue && onChangeData) {
                        issue.result = result;
                        onChangeData(data);
                      }
                    }}
                  />
                </RowTitleBox>
              </IssueWrapper>
            </FlexColumn>
            <FlexColumn style={{ gap: 16 }}>
              <div className="heading-16-sb">Milestone 이슈</div>
              <IssueWrapper>
                <ColumnTitleBox
                  title="기업 답변"
                  textClassName="heading-14-md"
                  titleWidth={208}
                  style={{ width: "100%" }}
                  transfromPixel={(n) => n}
                >
                  <AutoResizableTextarea
                    isReadOnly={true}
                    isShowWordCounter
                    placeholder="Milestone 이슈가 없습니다."
                    defaultData={
                      necessaryInfo?.curSBPIssue.milestoneIssue.text || ""
                    }
                  />
                </ColumnTitleBox>
                <RowTitleBox
                  title="이슈 평가"
                  textClassName="heading-14-md"
                  titleWidth={208}
                  style={{ width: "100%" }}
                  transfromPixel={(n) => n}
                >
                  <OXCheckBox
                    defaultData={
                      data.riskIssueList.find((item) => item.riskIssue.id === 6)
                        ?.result
                    }
                    onChangeData={(result) => {
                      const issue = data.riskIssueList.find(
                        (item) => item.riskIssue.id === 6
                      );
                      if (issue && onChangeData) {
                        issue.result = result;
                        onChangeData(data);
                      }
                    }}
                  />
                </RowTitleBox>
              </IssueWrapper>
            </FlexColumn>
          </FlexColumn>
        </RMEDSector>
      )}

      {!closedCompany && (
        <RMEDSector title="대외이슈" id="issue3">
          <FlexColumn style={{ gap: 34 }}>
            <RMEDSectorDescription text="기업의 답변 및 근거 자료를 바탕으로 이슈 여부를 판단해 주세요." />
            <FlexColumn style={{ gap: 16 }}>
              <div className="heading-16-sb">경쟁사 영향</div>
              <IssueWrapper>
                <ColumnTitleBox
                  title="기업 답변"
                  textClassName="heading-14-md"
                  titleWidth={208}
                  style={{ width: "100%" }}
                  transfromPixel={(n) => n}
                >
                  <AutoResizableTextarea
                    isReadOnly={true}
                    isShowWordCounter
                    placeholder="경쟁사 이슈가 없습니다."
                    defaultData={
                      necessaryInfo?.curSBPIssue.rivalCompanyEffect.text || ""
                    }
                  />
                </ColumnTitleBox>
                <RowTitleBox
                  title="이슈 평가"
                  textClassName="heading-14-md"
                  titleWidth={208}
                  style={{ width: "100%" }}
                  transfromPixel={(n) => n}
                >
                  <OXCheckBox
                    defaultData={
                      data.riskIssueList.find((item) => item.riskIssue.id === 5)
                        ?.result
                    }
                    onChangeData={(result) => {
                      const issue = data.riskIssueList.find(
                        (item) => item.riskIssue.id === 5
                      );
                      if (issue && onChangeData) {
                        issue.result = result;
                        onChangeData(data);
                      }
                    }}
                  />
                </RowTitleBox>
              </IssueWrapper>
            </FlexColumn>
            <FlexColumn style={{ gap: 16 }}>
              <div className="heading-16-sb">거시환경 영향 여부</div>
              <IssueWrapper>
                <ColumnTitleBox
                  title="기업 답변"
                  textClassName="heading-14-md"
                  titleWidth={208}
                  style={{ width: "100%" }}
                  transfromPixel={(n) => n}
                >
                  <AutoResizableTextarea
                    isReadOnly={true}
                    isShowWordCounter
                    placeholder="거시환경 영향 이슈가 없습니다."
                    defaultData={
                      necessaryInfo?.curSBPIssue.macroEnvironmentalEffect
                        .text || ""
                    }
                  />
                </ColumnTitleBox>
                <RowTitleBox
                  title="이슈 평가"
                  textClassName="heading-14-md"
                  titleWidth={208}
                  style={{ width: "100%" }}
                  transfromPixel={(n) => n}
                >
                  <OXCheckBox
                    defaultData={
                      data.riskIssueList.find((item) => item.riskIssue.id === 4)
                        ?.result
                    }
                    onChangeData={(result) => {
                      const issue = data.riskIssueList.find(
                        (item) => item.riskIssue.id === 4
                      );
                      if (issue && onChangeData) {
                        issue.result = result;
                        onChangeData(data);
                      }
                    }}
                  />
                </RowTitleBox>
              </IssueWrapper>
            </FlexColumn>
          </FlexColumn>
        </RMEDSector>
      )}

      {!closedCompany && (
        <RMEDSector title="담당 심사역 관리 의견" id="issue4">
          <FlexColumn style={{ gap: 34 }}>
            <FlexColumn style={{ gap: 16 }}>
              <div className="heading-16-sb">사업적 성과 달성</div>
              <FlexColumn style={{ gap: 8 }}>
                <RMEDSectorDescription text="해당 기업에서 제시한 예상 매출액과 사업 성과 달성 계획에 근거하여, 담당 심사역의 의견을 제시해 주세요.|(전분기 대비 사업적 성과에 유의미한 긍정적 변동이 있거나 예상된다면 긍정으로 평가, 유의미한 긍정적 변동이 없고 계획의 타당성이 부족하다면 우려로 평가)" />
                <IssueWrapper>
                  <ColumnTitleBox
                    title="심사역 의견"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="예상 매출액과 사업 성과 달성 계획을 바탕으로 의견을 작성해 주세요."
                      defaultData={
                        data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 1
                        )?.comment
                      }
                      onChangeData={(value) => {
                        const opinion = data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 1
                        );
                        if (opinion && onChangeData) {
                          opinion.comment = value;
                          onChangeData(data);
                        }
                      }}
                    />
                  </ColumnTitleBox>
                  <RowTitleBox
                    title="전망 평가"
                    textClassName="heading-14-md"
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <OXCheckBox
                      defaultData={
                        data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 1
                        )?.result
                      }
                      text1="긍정"
                      text2="우려"
                      style={{ marginTop: 6, justifyContent: "flex-end" }}
                      onChangeData={(result) => {
                        const opinion = data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 1
                        );
                        if (opinion && onChangeData) {
                          opinion.result = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </IssueWrapper>
              </FlexColumn>
            </FlexColumn>
            <FlexColumn style={{ gap: 16 }}>
              <div className="heading-16-sb">평가 가치 유지</div>
              <FlexColumn style={{ gap: 8 }}>
                <RMEDSectorDescription text="기업의 자금조달계획과 시장 환경을 고려하여, 평가 가치의 유지와 상승의 기대에 대해 평가해 주세요.|(기업의 펀드레이징 계획이 순조롭게 진행될 것으로 보인다면 긍정으로 평가, 자금조달이 순조롭지 않을 것으로 예상된다면 우려로 평가)" />
                <IssueWrapper>
                  <ColumnTitleBox
                    title="심사역 의견"
                    textClassName="heading-14-md"
                    titleWidth={208}
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <AutoResizableTextarea
                      isReadOnly={false}
                      isShowWordCounter
                      placeholder="자금조달계획과 시장 환경을 바탕으로 의견을 작성해 주세요."
                      defaultData={
                        data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 2
                        )?.comment
                      }
                      onChangeData={(value) => {
                        const opinion = data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 2
                        );
                        if (opinion && onChangeData) {
                          opinion.comment = value;
                          onChangeData(data);
                        }
                      }}
                    />
                  </ColumnTitleBox>
                  <RowTitleBox
                    title="전망 평가"
                    textClassName="heading-14-md"
                    style={{ width: "100%" }}
                    transfromPixel={(n) => n}
                  >
                    <OXCheckBox
                      defaultData={
                        data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 2
                        )?.result
                      }
                      text1="긍정"
                      text2="우려"
                      style={{ marginTop: 6, justifyContent: "flex-end" }}
                      onChangeData={(result) => {
                        const opinion = data.riskOpinionList.find(
                          (item) => item.riskOpinion.id === 2
                        );
                        if (opinion && onChangeData) {
                          opinion.result = result;
                          onChangeData(data);
                        }
                      }}
                    />
                  </RowTitleBox>
                </IssueWrapper>
              </FlexColumn>
            </FlexColumn>
          </FlexColumn>
        </RMEDSector>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 40px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--gray-700);
        stroke: var(--gray-700);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--gray-700);
        }
      }
    }
  }
`;

const IssueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-radius: 4px;
  background-color: var(--background-contents01);
  border: 1px solid var(--gray-300);
`;
