import React from "react";
import styled from "styled-components";

import { PerformanceManageSection } from "./PerformanceManageSection";
import { InvestmentPerformanceReview } from "../../../../fund-account-detail/mobile/organism/InvestmentPerformanceReview";

interface Props {
  selectedFundAccountId: number[];
  selectedIndustryId: number[];
}
export const PerformanceSection: React.FC<Props> = (props) => {
  const { selectedFundAccountId, selectedIndustryId } = props;

  return (
    <Container>
      <SectionHeader className="heading-20-sb">
        <span>투자성과</span>
      </SectionHeader>
      <FlexColumn style={{ gap: 16 }}>
        <InvestmentPerformanceReview
          selectedFundAccountId={selectedFundAccountId}
          selectedIndustryId={selectedIndustryId}
        />
        <PerformanceManageSection
          selectedFundAccountId={selectedFundAccountId}
          selectedIndustryId={selectedIndustryId}
        />
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 12px;
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
