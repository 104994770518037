import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { DefaultButton } from "lattice_core";

import RowTitleBox from "../../../../../../components/box/RowTitleBox";
import { InputBox } from "../../../../../../components-2.0v/input-box/InputBox";
import {
  ExitProcessProps,
  PortfolioDetailAdminProps,
} from "../../interface/Portfolio.interface";
import {
  TableGrid,
  TableGridHeaderProps,
} from "../../component/exit-table/TableGrid";
import { CustomInput } from "../../component/exit-table/custom-cell/CustomInput";
import { CustomDate } from "../../component/exit-table/custom-cell/CustomDate";
import {
  AddComma,
  RemoveComma,
  getNewDate,
  getYMD,
  showToast,
} from "../../../../../../common/commonUtil";
import { rootStyle } from "../../../../../../style/ThemeStyles";
import { Bounce } from "react-toastify";

interface Props {
  exitProcessInfo: ExitProcessProps;
  onChangeData: (newData: ExitProcessProps) => void;
}

export const ExitTable: React.FC<Props> = (props) => {
  const { exitProcessInfo, onChangeData } = props;
  const headers: TableGridHeaderProps[] = [
    {
      header: "구매자",
      key: "acInvestmentOrganization",
      width: 235,
      customCell: (data, allData) => {
        return (
          <CustomInput
            textType="text"
            transfromPixel={(n) => n}
            defaultValue={data.name}
            isReadOnly
          />
        );
      },
    },
    {
      header: "회수일자",
      key: "date",
      width: 180,
      customCell: (data, allData) => {
        return (
          <CustomDate
            defaultDate={data}
            onChangeDate={(date) => {
              allData.date = getYMD(date);
              onChangeData(allData);
            }}
          />
        );
      },
    },
    {
      header: "주식수",
      key: "stock",
      width: 144,
      customCell: (data, allData) => {
        return (
          <CustomInput
            textType="numberWithComma"
            transfromPixel={(n) => n}
            placeholder="0"
            defaultValue={data}
            onBlur={(value) => {
              const stock = parseInt(RemoveComma(value));
              const percent = stock / exitProcessInfo.currentStock;

              allData.stock = stock;

              if (getStockAvailableForSale() >= 0) {
                allData.principal = exitProcessInfo.currentPrincipal * percent;
                if (allData.stockPrice !== undefined) {
                  allData.amount = allData.stockPrice * stock;
                }
              } else {
                allData.stock = undefined;
                allData.principal = undefined;
                showToast({
                  customElement: (
                    <span className="heading-16-sb">
                      회수 가능한 주식보다 클 수 없습니다.
                    </span>
                  ),
                  backgroundColor: rootStyle.getPropertyValue("--red-500"),
                  color: rootStyle.getPropertyValue("--text-02"),
                  width: 274,
                  height: 48,
                  milliSec: 1500,
                  transition: Bounce,
                  position: "bottom-center",
                  toastId: new Date().toString(),
                });
              }
              onChangeData(allData);
            }}
          />
        );
      },
    },
    {
      header: "주당가격",
      key: "stockPrice",
      width: 144,
      customCell: (data, allData) => {
        return (
          <CustomInput
            textType="numberWithComma"
            transfromPixel={(n) => n}
            placeholder="0"
            defaultValue={data}
            onBlur={(value) => {
              const stockPrice = parseInt(RemoveComma(value));
              allData.stockPrice = stockPrice;

              if (allData.stock !== undefined) {
                allData.amount = allData.stock * stockPrice;
              }

              onChangeData(allData);
            }}
          />
        );
      },
    },
    {
      header: "회수금액",
      key: "amount",
      width: 144,
      customCell: (data, allData) => {
        return (
          <CustomInput
            isReadOnly
            textType="numberWithComma"
            transfromPixel={(n) => n}
            placeholder="0"
            defaultValue={data}
          />
        );
      },
    },
    {
      header: "회수원금",
      key: "principal",
      width: 144,
      customCell: (data, allData) => {
        return (
          <CustomInput
            isReadOnly
            textType="numberWithComma"
            transfromPixel={(n) => n}
            placeholder="0"
            defaultValue={data}
          />
        );
      },
    },
    {
      header: "기업가치",
      key: "companyValue",
      width: 200,
      customCell: (data, allData) => {
        return (
          <CustomInput
            textType="numberWithComma"
            transfromPixel={(n) => n}
            placeholder="0"
            defaultValue={data}
            onBlur={(value) => {
              const companyValue = parseInt(RemoveComma(value));
              allData.companyValue = companyValue;
              onChangeData(allData);
            }}
          />
        );
      },
    },
  ];
  const getStockAvailableForSale = () => {
    const totalSaledStock = exitProcessInfo.exitInfo
      .map((item) => item.stock || 0)
      .reduce((p, c) => p + c, 0);
    return exitProcessInfo.currentStock - totalSaledStock;
  };
  const getPrincipalAvailableForSale = () => {
    const totalSaledPrincipal = exitProcessInfo.exitInfo
      .map((item) => item.principal || 0)
      .reduce((p, c) => p + c, 0);
    return exitProcessInfo.currentPrincipal - totalSaledPrincipal;
  };

  return (
    <Container>
      <div className="heading-16-b">{exitProcessInfo.acFundAccount.nameKo}</div>
      <FlexRow style={{ gap: 36 }}>
        <FlexRow className="body-14-sb" style={{ gap: 12 }}>
          <span style={{ color: "var(--text-04)" }}>주식 종류</span>
          <span>
            {exitProcessInfo.stockType
              ? exitProcessInfo.stockType.name
              : "정보없음"}
          </span>
        </FlexRow>
        <FlexRow className="body-14-sb" style={{ gap: 12 }}>
          <span style={{ color: "var(--text-04)" }}>회수 가능 주식수</span>
          <span>{AddComma(getStockAvailableForSale())}주</span>
        </FlexRow>
        <FlexRow className="body-14-sb" style={{ gap: 12 }}>
          <span style={{ color: "var(--text-04)" }}>회수 가능 원금</span>
          <span>{AddComma(getPrincipalAvailableForSale())}원</span>
        </FlexRow>
      </FlexRow>
      <TableGrid
        header={headers}
        dataList={exitProcessInfo.exitInfo.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
      />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
