import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import StockHolderGridDataCell from "./StockHolderGridDataCell";
import { GridHeaderProps } from "./StockHolderGrid";

interface Props {
  index: number;
  headers: GridHeaderProps[];
  rowHeight: number;
  rowData?: any;
}

const StockHolderGridDataRow: React.FC<Props> = ({
  index,
  headers,
  rowHeight,
  rowData,
}) => {
  const rowRef = useRef<HTMLDivElement>(null);
  return (
    <Container ref={rowRef} className="body-14-rg">
      {headers.map((item, idx) => {
        return (
          <StockHolderGridDataCell
            key={idx}
            header={item}
            cellData={rowData === undefined ? "" : rowData[`${item.key}`]}
            rowHeight={rowHeight}
            rowIndex={index}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
`;
export default React.memo(StockHolderGridDataRow);
