import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { DefaultButton } from "lattice_core";

import { getCustomIcon } from "./DefaultCard";
import { ReportingFile } from "../../interface/ReportingDetail.interface";

interface Props {
  portfolioId: number;
  isFileUploadable?: boolean;
  data: ReportingFile;
  isOpen: boolean;
  index: number;
  isAuthority?: boolean;
  transfromPixel: (value: number) => number;
  onClickCard?: () => void;
  onClickFile?: () => void;
  confirm: () => void;
  reject: () => void;
}

const UploadWebCard = (props: Props) => {
  console.log(props.data);
  return (
    <StyledCard
      transfromPixel={props.transfromPixel}
      isOpen={props.isOpen}
      onClick={(e) => {
        e.stopPropagation();
        props.onClickCard && props.onClickCard();
      }}
    >
      <Default
        style={{
          backgroundColor:
            props.data.file?.uploadStatus.uploadStatusId === 2
              ? "#FFF2F3"
              : "#ffffff",
        }}
        transfromPixel={props.transfromPixel}
      >
        <div className="required-container">
          <div
            className={`chip caption-11-rg ${
              props.data.required ? "required" : "option"
            }`}
          >
            {props.data.required ? "필수" : "변경 시 제출"}
          </div>
          {props.data.tag && (
            <div className="required-file chip caption-11-rg">
              {props.data.tag}
            </div>
          )}
        </div>
        <div className="file-name-card heading-20-sb">
          <span className={props.isOpen ? "" : "closed"}>
            {props.data.name}
          </span>
        </div>
        {props.data.file?.uploadStatus.uploadStatusId &&
          [1, 2].includes(props.data.file.uploadStatus.uploadStatusId) && (
            <UploadLabel
              className="caption-12-sb"
              style={{
                backgroundColor:
                  props.data.file.uploadStatus.uploadStatusId === 2
                    ? "#FE9041"
                    : "#ffd84f",
              }}
              transfromPixel={props.transfromPixel}
            >
              {props.data.file.uploadStatus.uploadStatusId === 2
                ? "파일이 교체되었습니다. 확인해 주세요."
                : "파일이 제출되었습니다. 확인해 주세요."}
            </UploadLabel>
          )}
        <div className="file-container">
          <FileUpload
            className="body-14-rg"
            style={{
              padding: `${props.transfromPixel(8)}px ${props.transfromPixel(
                12
              )}px`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.onClickFile && props.onClickFile();
            }}
          >
            {getCustomIcon(props.data.extension, {
              width: props.transfromPixel(24),
              height: props.transfromPixel(24),
            })}
            <div
              className="fileupload__file__name"
              style={{
                width: props.transfromPixel(195),
              }}
            >
              {`${props.data.acPfDocumentId < 10 ? "0" : ""}${
                props.data.acPfDocumentId
              }_${props.data.name}`}
            </div>
          </FileUpload>
          <div className="button-container">
            {props.isAuthority && props.isOpen && (
              <DefaultButton
                className="caption-12-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor={"#1F2022"}
                borderColor={"#D5D6D8"}
                disabled={!props.isOpen}
                text="반려"
                onClick={(e) => {
                  e && e.stopPropagation();
                  props.reject();
                }}
                transfromPixel={props.transfromPixel}
              />
            )}
            {props.isAuthority && props.isOpen && (
              <DefaultButton
                className="caption-12-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor={"#1F2022"}
                borderColor={"#D5D6D8"}
                disabled={!props.isOpen}
                text="확정"
                onClick={(e) => {
                  e && e.stopPropagation();
                  props.confirm();
                }}
                transfromPixel={props.transfromPixel}
              />
            )}
          </div>
        </div>
      </Default>
    </StyledCard>
  );
};

const StyledCard = styled.div(
  ({
    isOpen,
    transfromPixel,
  }: {
    isOpen: boolean;
    transfromPixel: (value: number) => number;
  }) => css`
    background: var(--background-contents01);
    width: ${transfromPixel(300)}px;
    height: ${transfromPixel(274)}px;
    overflow: hidden;
    position: relative;
    cursor: default;

    :hover {
      outline: 1px solid #a2c7ff;
    }
  `
);

const Default = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: 100%;
    height: 100%;
    padding: ${transfromPixel(24)}px ${transfromPixel(32)}px;
    position: relative;

    &.red-outline {
      outline: ${transfromPixel(1)}px solid red;
    }

    .required-container {
      display: flex;
      flex-direction: row;
      gap: ${transfromPixel(4)}px;
      .chip {
        padding: ${transfromPixel(2)}px ${transfromPixel(6)}px;
        border-radius: ${transfromPixel(2)}px;
      }

      .required {
        background: #060f40;
        color: #ffffff;
      }
      .option {
        background: #ffffff;
        color: #5b5d61;
        border: ${transfromPixel(1)}px solid #e3e4e5;
      }
      .required-file {
        background: #e3e4e5;
        color: #5b5d61;
      }
    }

    .file-name-card {
      margin-top: ${transfromPixel(8)}px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .closed {
        color: #bec0c2;
      }

      .icon {
        position: relative;
        width: fit-content;
        height: fit-content;
        flex: 1;

        .icon__wrap {
          padding: ${transfromPixel(4)}px;
          cursor: pointer;
        }

        .tool-tip {
          position: absolute;
          top: 100%;
          left: 0;
          display: none;
          background: rgba(31, 32, 34, 0.5);
          color: #ffffff;
          border-radius: ${transfromPixel(4)}px;

          padding: ${transfromPixel(6)}px 0;
          width: ${transfromPixel(96)}px;
          z-index: 1000;
          text-align: center;

          cursor: pointer;
          :hover {
            text-decoration: underline;
          }
        }

        :hover {
          .tool-tip {
            display: block;
          }
        }
      }
    }

    .file-container {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #f3f4f4;
      width: 100%;
      min-height: ${transfromPixel(120)}px;
      max-height: ${transfromPixel(120)}px;
      padding: ${transfromPixel(20)}px ${transfromPixel(24)}px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: ${transfromPixel(8)}px;

      .button-container {
        display: flex;
        flex-direction: row;
        gap: ${transfromPixel(8)}px;
      }
    }
  `
);

const UploadLabel = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;
    width: 100%;
    height: ${transfromPixel(26)}px;
    display: flex;
    justify-content: center;
    align-items: center;

    left: 0;
    bottom: ${transfromPixel(120)}px;

    color: #ffffff;
  `
);

const FileUpload = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  background: #ffffff;
  /* light/gray200 */

  border: 1px solid #edeeef;
  border-radius: 4px;
  gap: 4px;

  cursor: pointer;
  transition: all 0.2s ease;

  .fileupload__icon {
  }
  .fileupload__file__name {
    display: block;
    flex-direction: row;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :hover {
    background-color: #f3f4f4;
  }
`;
export default UploadWebCard;
