import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";

import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { FundDurationChart } from "../component/FundDurationChart";
import { getNewDate } from "../../../../../common/commonUtil";
import { FundAccountDetailInfo } from "../../interface/FundAccountDetail.interface";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface Props {
  data: FundAccountDetailInfo;
  toDate: string;
}
export const FundDuration: React.FC<Props> = (props) => {
  const [investmentDay, setInvestmentDay] = useState<number>(0);
  const [operationDay, setOperationDay] = useState<number>(0);
  const [addOperatingDay, setAddOperationDay] = useState<number>(0);
  const [toDay, setToDay] = useState<number>(0);

  const getDateDifference = (startDate: Date, endDate: Date) => {
    if (endDate.getTime() < startDate.getTime()) {
      return "기간만료";
    }

    let years = endDate.getFullYear() - startDate.getFullYear();
    let months = endDate.getMonth() - startDate.getMonth();
    let days = endDate.getDate() - startDate.getDate();

    // 일 차이 조정
    if (days < 0) {
      months--;
      const previousMonth = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        0
      ).getDate();
      days += previousMonth;
    }

    // 월 차이 조정
    if (months < 0) {
      years--;
      months += 12;
    }

    const year = years > 0 ? `${years}년` : "";
    const month = months > 0 ? `${months}개월` : "";
    const day = days > 0 ? `${days}일` : "";
    if (years > 0) {
      return year + " " + month;
    }
    if (months > 0) {
      return month + " " + day;
    }

    return day;
  };

  const getDaysDifference = (date1: string, date2: string) => {
    const oneDay = 24 * 60 * 60 * 1000; // 하루의 밀리초 수 (24시간 * 60분 * 60초 * 1000밀리초)

    // 두 날짜의 차이 (밀리초 단위)
    const differenceInTime = Math.abs(
      getNewDate(date2).getTime() - getNewDate(date1).getTime()
    );

    // 밀리초 단위의 차이를 일 단위로 변환
    const differenceInDays = Math.round(differenceInTime / oneDay);

    return differenceInDays;
  };

  useEffect(() => {
    const totalDays = getDaysDifference(
      props.data.fundDuration.startDate,
      props.data.fundDuration.addEndDate
        ? props.data.fundDuration.addEndDate
        : props.data.fundDuration.endDate
    );
    const investmentDays = getDaysDifference(
      props.data.fundDuration.startDate,
      props.data.fundDuration.investmentEndDate
    );

    console.log(props.data);
    const addOperatingDays = props.data.fundDuration.addEndDate
      ? getDaysDifference(
          props.data.fundDuration.endDate,
          props.data.fundDuration.addEndDate
        )
      : 0;

    const toDays = getDaysDifference(
      props.data.fundDuration.startDate,
      props.toDate
    );

    console.log(`totalDays = ${totalDays}`);
    console.log(`investmentDays = ${investmentDays}`);
    console.log(`addOperatingDays = ${addOperatingDays}`);
    console.log(`toDays = ${toDays}`);

    setInvestmentDay(investmentDays);
    setOperationDay(totalDays - investmentDays - addOperatingDay);
    setAddOperationDay(addOperatingDays);
    setToDay(toDays);
  });
  return (
    <Container>
      <TitleContainer className="heading-18-sb">
        <span>펀드 기간</span>
        <CustomColorLabel bgColor="var(--blue-50)" textColor="var(--blue-400)">
          존속기간 {((investmentDay + operationDay) / 365).toFixed(0)}년
        </CustomColorLabel>
      </TitleContainer>
      <BodyContainer>
        <FlexRow style={{ gap: 20 }}>
          <FlexColumn>
            <div className="body-14-sb" style={{ color: "var(--text-04)" }}>
              투자 기간
            </div>
            <div className="heading-20-b" style={{ color: "var(--blue-500)" }}>
              {getDateDifference(
                getNewDate(props.toDate),
                getNewDate(props.data.fundDuration.investmentEndDate)
              )}
            </div>
          </FlexColumn>
          <Divider />
          <FlexColumn>
            <div className="body-14-sb" style={{ color: "var(--text-04)" }}>
              운영 기간
            </div>
            <div className="heading-20-b" style={{ color: "var(--blue-500)" }}>
              {props.toDate < props.data.fundDuration.investmentEndDate
                ? getDateDifference(
                    getNewDate(props.data.fundDuration.investmentEndDate),
                    getNewDate(props.data.fundDuration.endDate)
                  )
                : getDateDifference(
                    getNewDate(props.toDate),
                    getNewDate(
                      props.data.fundDuration.addEndDate
                        ? props.data.fundDuration.addEndDate
                        : props.data.fundDuration.endDate
                    )
                  )}
            </div>
          </FlexColumn>
        </FlexRow>
        <div style={{ marginTop: 32 }}>
          <FundDurationChart
            investmentDay={investmentDay}
            operationDay={operationDay}
            addOperatingDay={addOperatingDay}
            toDay={toDay}
            fundStartDate={props.data.fundDuration.startDate}
            fundEndDate={props.data.fundDuration.endDate}
            fundInvestmentDate={props.data.fundDuration.investmentEndDate}
            addEndDate={props.data.fundDuration.addEndDate}
          />
        </div>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 28px 30px;

  border-radius: 4px;
  border: 1px solid var(--gray-200, #edeeef);
  background: var(--background-contents01, #fff);

  /* shdow350-down */
  box-shadow: 0px 2px 5px 0px rgba(100, 103, 109, 0.1);
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: var(--gray-300);
`;
