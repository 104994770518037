import {
  DateTime,
  InvestmentAssessment,
  UserInfo,
} from "../../../../type/data";
import { DealFlowStatus } from "../../portfolio-detail/interface/PortfolioNavigation.interface";

export enum InvestmentStatus {
  OPEN = 1,
  PUBLISH = 2,
  VOTING = 3,
  CONFIRM = 4,
  REQUEST = 5,
  CONTRACT = 6,
  REJECT = 7,
}

export interface ResponseDealflowEvaluation {
  id: number;
  status: DealFlowStatus;
  investmentAssessment: ResponseInvestmentAssessment[];
}
export interface ResponseInvestmentAssessment {
  investmentAssessmentId: number;
  isConfirmed: boolean;
  isContracted: boolean;
  status: InvestmentStatus;
  createdAt: string;
  assessmentTmp: {
    assessment: InvestmentAssessment;
    assessmentTmpId: number;
    updatedAt: string;
  };
}

export interface DealFlowEvaluationData {
  id: number;
  title: string;
  status: DealFlowStatus;
  investmentAssessment: EvaluationListProps[];
}

export interface EvaluationListProps {
  investmentAssessmentId: number;
  investmentAssessment: InvestmentAssessment;
  title: string;
  status: InvestmentStatus;
  fundAccounts: string[];
  previousValue: number;
  totalInvestmentAmount: number;
  createdAt: string;
  rejectMsg?: string;
}

export interface EvaluationContactRequestProps {
  title: string;
  investmentAssessmentId: number;
  isRequestAble: boolean;
  status: InvestmentStatus;
  reportAccountInfo: ContractReportAccount[];
}

export interface ContractReportAccount {
  isAgreement: boolean;
  accountName: string;
  investmentAmount: number;
  voteInviteUsers: string;
  voteInviteUserCount: number;
}
