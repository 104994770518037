import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { SBPContentsPropsV2 } from "../../interface/SBP.interface";
import { SBPSector } from "../SBPSector";

import { AutoResizableTextarea } from "../AutoResizableTextarea";
import { SBPSectorDescription } from "../SBPSectorDescription";

import { ReactComponent as Check } from "../../../../../../../assets/image/icon_check.svg";
import RowTitleBox from "../RowTitleBox";

interface Props {
  isReadOnly: boolean;
  data?: SBPContentsPropsV2;
  onChangeData?: (data: SBPContentsPropsV2) => void;
}

export const SBPIssue: React.FC<Props> = (props: Props) => {
  const { isReadOnly, data, onChangeData } = props;

  if (!data) return null;
  return (
    <Container>
      <SBPSector title="경영진 이슈" id="issue1">
        <FlexColumn style={{ gap: 8 }}>
          {!isReadOnly && (
            <SBPSectorDescription text="등기이사(사내/사외이사)나 경영진의 부정행위, 소송 등의 내부적 이슈 발생에 대해 작성해 주세요." />
          )}
          <IssueWrapper>
            <RowTitleBox
              title="이슈 보고"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={isReadOnly}
                placeholder="해당 이슈 없음"
                defaultData={data.reportIssue.executiveIssue.text}
              />
            </RowTitleBox>
          </IssueWrapper>
        </FlexColumn>
      </SBPSector>

      <SBPSector title="Milestone 이슈" id="issue2">
        <FlexColumn style={{ gap: 8 }}>
          <IssueWrapper>
            <RowTitleBox
              title="이슈 보고"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={isReadOnly}
                placeholder="해당 이슈 없음"
                defaultData={data.reportIssue.milestoneIssue.text}
              />
            </RowTitleBox>
          </IssueWrapper>
        </FlexColumn>
      </SBPSector>

      <SBPSector title="경쟁사 영향" id="issue3">
        <FlexColumn style={{ gap: 8 }}>
          <IssueWrapper>
            <RowTitleBox
              title="이슈 보고"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={isReadOnly}
                placeholder="해당 이슈 없음"
                defaultData={data.reportIssue.rivalCompanyEffect.text}
              />
            </RowTitleBox>
          </IssueWrapper>
        </FlexColumn>
      </SBPSector>

      <SBPSector title="거시환경 영향 여부" id="issue4">
        <FlexColumn style={{ gap: 8 }}>
          <IssueWrapper>
            <RowTitleBox
              title="이슈 보고"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={isReadOnly}
                placeholder="해당 이슈 없음"
                defaultData={data.reportIssue.macroEnvironmentalEffect.text}
              />
            </RowTitleBox>
          </IssueWrapper>
        </FlexColumn>
      </SBPSector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const IssueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 28px;
  border-radius: 4px;
  background-color: var(--background-contents01);
  border: 1px solid var(--gray-300);
`;
