import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Arrow } from "../../../../assets/image/btn_right.svg";
interface Props {
  hideLabel?: boolean;
  totalCount: number;
  countPerPage: number;
  curPage: number;
  totalPage: number;
  resultPortfolioListCount: number;
  updateCurPage:
    | React.Dispatch<React.SetStateAction<number>>
    | ((data: any) => void);
  customFunctions?: JSX.Element;
  searchKeyword?: string;
}

const DBResultListFunctionRow: React.FC<Props> = (props) => {
  const indexRef = useRef<HTMLDivElement>(null);
  const [openIndexMouseClick, updateIndexMouseClick] = useState<boolean>(false);
  const [openIndexMouseOver, updateIndexMouseOver] = useState<boolean>(false);

  const onClickPrev = () => {
    if (props.curPage === 1) {
      return;
    }
    props.updateCurPage(props.curPage - 1);
  };

  const onClickNext = () => {
    if (props.totalPage === 0 || props.curPage === props.totalPage) {
      return;
    }
    props.updateCurPage(props.curPage + 1);
  };

  const handleClick = (event: any) => {
    if (indexRef.current && !indexRef.current.contains(event.target)) {
      updateIndexMouseClick(false);
      updateIndexMouseOver(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <Container>
      <div className="function__row">
        {props.customFunctions ? props.customFunctions : <div />}
        <Pagination>
          <PaginationResultIndex
            ref={indexRef}
            className={`font-regular-12 ${
              props.totalPage === 1 ? "page__index__disabeld" : ""
            }`}
            onClick={() => {
              if (openIndexMouseClick) {
                updateIndexMouseClick(false);
              } else {
                updateIndexMouseClick(props.totalPage > 1);
              }
            }}
            onMouseOver={() => {
              updateIndexMouseOver(props.totalPage > 1);
            }}
            onMouseLeave={() => {
              updateIndexMouseOver(false);
            }}
          >
            {props.searchKeyword && props.searchKeyword.length > 0 ? (
              <div>
                <span style={{ color: "#2196F3" }}>
                  '{props.searchKeyword}'
                </span>
                <span>에 대한 검색결과 {props.totalCount}개 중 </span>
              </div>
            ) : (
              <span>전체 {props.totalCount}개 중 </span>
            )}
            <span>{props.countPerPage * (props.curPage - 1) + 1}</span>~
            <span>
              {props.countPerPage * (props.curPage - 1) +
                props.resultPortfolioListCount}
            </span>
            {(openIndexMouseClick || openIndexMouseOver) && (
              <PaginationFirstLast>
                <PaginationFirstLastItem
                  onClick={() => {
                    props.updateCurPage(1);
                  }}
                >
                  처음 페이지
                </PaginationFirstLastItem>
                <PaginationFirstLastItem
                  onClick={() => {
                    props.updateCurPage(props.totalPage);
                  }}
                >
                  마지막 페이지
                </PaginationFirstLastItem>
              </PaginationFirstLast>
            )}
          </PaginationResultIndex>
          <PaginationPrevNext>
            <PaginationPrevNextItem
              className={props.curPage === 1 ? "prev__next__disabeld" : ""}
              onClick={onClickPrev}
            >
              <Arrow className="left" />
            </PaginationPrevNextItem>
            <PaginationPrevNextItem
              className={
                props.totalPage === 0 || props.curPage === props.totalPage
                  ? "prev__next__disabeld"
                  : ""
              }
              onClick={onClickNext}
            >
              <Arrow className="right" />
            </PaginationPrevNextItem>
          </PaginationPrevNext>
        </Pagination>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 20;
  .function__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Pagination = styled.div`
  width: auto;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PaginationResultIndex = styled.div`
  position: relative;
  width: auto;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease;
  padding: 0 10px;
  border-radius: 4px;
  color: #828282;
  line-height: 1;
  cursor: pointer;

  :hover {
    background-color: #d8d8d8;
  }
  &.page__index__disabeld {
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }
`;

const PaginationPrevNext = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .left {
    transform: rotate(180deg);
  }
  .right {
    transform: rotate(0deg);
  }
`;

const PaginationPrevNextItem = styled.div`
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  min-height: 36px;
  max-height: 36px;

  border-radius: 50%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;

  :hover {
    background-color: #d8d8d8;
  }

  &.prev__next__disabeld {
    path {
      stroke: #d9d9d9;
    }

    :hover {
      background-color: transparent;
    }
  }
`;

const PaginationFirstLast = styled.div`
  position: absolute;
  top: 36px;
  left: 0;

  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  background: #ffffff;
  /* greyscale_10 */

  border: 1px solid #dcdcdc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  gap: 4px;
`;

const PaginationFirstLastItem = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  transition: all 0.3s ease;

  :hover {
    background-color: #dcdcdc;
  }
`;
export default DBResultListFunctionRow;
