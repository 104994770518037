import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { CustomInput } from "./table/custom-cell/CustomInput";
import { TableGrid, TableGridHeaderProps } from "./table/TableGrid";
import {
  InvestmentAssociationProps,
  InvestmentAssociationTableProps,
} from "../interface/FundAccount.interface";
import { RemoveComma } from "../../../../../common/commonUtil";

interface Props {
  associationList: InvestmentAssociationTableProps[];
  onDataChange: (data: InvestmentAssociationTableProps) => void;
}

export const AssociationTableComponent: React.FC<Props> = (props) => {
  const { associationList, onDataChange } = props;

  const [render, setRender] = useState<boolean>(false);

  const headers: TableGridHeaderProps[] = [
    {
      header: "기업/개인 명",
      key: "IOName",
      width: 300,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            transfromPixel={(n) => n}
            defaultValue={data}
            isReadOnly={true}
          />
        );
      },
    },
    {
      header: "사업자/주민등록 번호",
      key: "IORegistrationNumber",
      width: 150,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            transfromPixel={(n) => n}
            defaultValue={data}
            isReadOnly={true}
          />
        );
      },
    },
    {
      header: "약정 금액 (원)",
      key: "investmentAmount",
      width: 200,

      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            transfromPixel={(n) => n}
            placeholder="100,000,000"
            defaultValue={data}
            onBlur={(value) => {
              if (!associationList) return;
              allData.investmentAmount = parseInt(RemoveComma(value));
              onDataChange(allData);
            }}
          />
        );
      },
    },
    {
      header: "지분율 (%)",
      key: "amountRatio",
      width: 100,

      customCell: (data, allData, isReadOnly, disabled) => {
        const value = data ? (data as number).toFixed(2) : `0`;

        return (
          <div style={{ textAlign: "right", paddingRight: 10 }}>{value}</div>
        );
      },
    },
  ];

  if (!associationList) return null;

  useEffect(() => {
    setRender((prev) => !prev);
  }, [associationList]);

  return (
    <Container>
      <TableGrid
        header={headers}
        dataList={associationList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  overflow-x: scroll;

  padding: 16px;
`;
