import React, { useEffect, useRef, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Check } from "../../../../assets/image/checkbox_checked.svg";
import { ReactComponent as CheckBox } from "../../../../assets/image/checkbox.svg";
import axios from "axios";
import * as api from "../../../../api/api";

import { toast } from "react-toastify";

import { Box, Wrapper } from "../../../login/desktop/DeskLogin";
import LoginInputBox from "../../../login/desktop/components/LoginInputBox";
import { checkEmailForm } from "../../../../common/commonUtil";
import { DefaultButton } from "lattice_core";
import styled from "styled-components";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>(
    localStorage.getItem("email") || ""
  );
  const [password, setPassword] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(
    !!localStorage.getItem("email")
  );

  const [cookies, setCookie, removeCookie] = useCookies([
    "isAutoLogin",
    "sessing",
  ]);
  const [reload, setReload] = useState<boolean>(true);

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const submitLogin = async () => {
    try {
      const param = {
        email: email,
        password: password,
      };
      const result = await axios.post(api.loginMaster(), param, {
        withCredentials: true,
      });
      if (result.status === 200) {
        if (isChecked) {
          window.localStorage.setItem("email", email);
        } else window.localStorage.removeItem("email");
        onSuccessLogin();
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const onSuccessLogin = () => {
    setCookie("sessing", true);
    navigate("/admin/company-structure");
  };

  return (
    <Wrapper className="scroll__invisible">
      <Box>
        <div className={"login-container"}>
          <FlexRow style={{ gap: 8 }}>
            <div
              className={"logo-64-hv"}
              style={{ textAlign: "center", color: "var(--primary-purple)" }}
            >
              Lattice
            </div>
            <AdminLabel className="caption-10-sb">Admin</AdminLabel>
          </FlexRow>
          <LoginInputBox
            defaultValue={email}
            placeholder="이메일을 입력하세요."
            validationMsg="이메일 형식이 올바르지 않습니다."
            style={{ marginTop: 50 }}
            onChange={onChangeEmail}
            onBlur={onChangeEmail}
            onEnterPress={(value) => {
              setEmail(value);
            }}
            isShowValidationMsg={!checkEmailForm(email)}
            transfromPixel={(n) => n}
          />
          <LoginInputBox
            isPassword
            defaultValue={password}
            style={{ marginTop: !checkEmailForm(email) ? 8 : 16 }}
            placeholder="비밀번호"
            onChange={onChangePassword}
            onEnterPress={(value) => {
              setPassword(value);
              if (
                email.length > 0 &&
                checkEmailForm(email) &&
                value.length > 0
              ) {
                submitLogin();
              }
            }}
            isShowValidationMsg={false}
            transfromPixel={(n) => n}
          />

          <div style={{ width: "100%" }}>
            <CheckBoxContainer
              style={{ marginTop: 16 }}
              onClick={() => {
                setIsChecked(!isChecked);
              }}
            >
              {isChecked ? (
                <Check width={24} height={18} />
              ) : (
                <CheckBox width={24} height={24} />
              )}
              <span className="body-14-sb" style={{ paddingTop: 0 }}>
                이메일 저장하기
              </span>
            </CheckBoxContainer>
          </div>
          <div style={{ marginTop: 40 }}>
            <DefaultButton
              className="heading-16-md"
              buttonType="filled"
              sizeType="large"
              buttonColor="var(--primary-purple)"
              hoverColor="var(--purple-600)"
              disabled={
                email.length === 0 ||
                password.length === 0 ||
                !checkEmailForm(email)
              }
              onClick={submitLogin}
              text="로그인"
            />
          </div>
          <div className={"footer-container"}>
            <Link to="/" className={"font-regular-14"}>
              LATTICE Login으로 전환
            </Link>
          </div>
        </div>
      </Box>
    </Wrapper>
  );
};

const CheckBoxContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--gray-600);
  cursor: pointer;

  rect {
    fill: var(--primary-purple);
  }
`;

const AdminLabel = styled.label`
  width: max-content;
  height: max-content;
  padding: 2px 5px;
  background-color: var(--purple-800);
  color: var(--white);
  border-radius: 4px;
  margin-bottom: 12px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
export default AdminLogin;
