import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DeskNavigation, LocationProps } from "./DeskNavigation";
import { ReactComponent as Arrow } from "../../../2.0lattice/assets/image/new_icon_arrow.svg";
import { ReactComponent as Alarm } from "../../../assets/image/icon_alarm.svg";
import { getMyInfo, trnasfromBase64ToObject } from "../../../common/commonUtil";

import { allRouterList, NavMenu } from "./routerMap";
import { useNavigate } from "react-router-dom";
import useResize from "../../../2.0lattice/useResize";
import Notification from "../../../router/notification/Notification";

interface Props {
  isSpreadNavigation?: boolean;
}

export interface RouterAddress {
  addressName: string;
  pageName?: string;
  isDisabled?: boolean;
}
export interface NavigationMenu extends LocationProps {
  subMenu?: LocationProps[];
}

export const DeskNavigationLayout: React.FC<Props> = ({
  isSpreadNavigation = false,
  children,
}) => {
  const navigate = useNavigate();
  const alarmIconRef = useRef<HTMLDivElement>(null);
  const rightModalRef = useRef<HTMLDivElement>(null);

  const [openRightModal, updateRightModal] = useState<boolean>(false);
  const [alarmNew, setAlarmNew] = useState<boolean>(false);
  const [path, updatePath] = useState<LocationProps[]>([]);
  const [screenRate, transfromPixel] = useResize();

  useEffect(() => {
    //todo 다시보기
    console.log(window.location.pathname);
    const location = window.location.pathname;

    const path = location.split("/").filter((item) => item !== "");
    const stack: RouterAddress[] = [];
    console.log(path);
    path.forEach((item) => {
      if (allRouterList.map((item) => item.addressName).includes(item)) {
        const router = allRouterList.find(
          (router) => router.addressName === item
        );
        if (!router) return;

        router && stack.push(router);
      } else {
        const popData = stack.pop();
        if (!popData) return;
        const newPath = `${popData.addressName}/${item}`;
        const pageName: any = trnasfromBase64ToObject(item);
        console.log(pageName);

        const a = pageName["pageName"] ? pageName["pageName"] : "";

        stack.push({
          addressName: newPath,
          pageName:
            popData.pageName !== "" && popData.pageName !== undefined
              ? popData.pageName
              : a,
        });
      }
    });

    const lPath: LocationProps[] = stack.map((item, index): LocationProps => {
      return {
        pageAddress:
          "/" +
            stack
              .map((item) => item.addressName)
              .slice(0, index + 1)
              .join("/") || "",
        addressName:
          stack
            .map((item) => item.addressName)
            .slice(0, index + 1)
            .join("/") || "",
        pageName: item.pageName || "",
        type: "deactive",
        disabled: item.isDisabled,
      };
    });
    updatePath(lPath);
  }, [window.location.pathname]);

  const onMouseDown = (event: any) => {
    if (
      alarmIconRef.current?.contains(event.target) ||
      rightModalRef.current?.contains(event.target)
    ) {
      return;
    }

    updateRightModal(false);
  };

  useEffect(() => {
    window.addEventListener("mousedown", onMouseDown);
    return () => {
      window.removeEventListener("mousedown", onMouseDown);
    };
  }, []);

  if (!NavMenu) return null;

  return (
    <Container className="scroll__invisible">
      <Navigation className="scroll__invisible" transfromPixel={transfromPixel}>
        <DeskNavigation menus={NavMenu} transfromPixel={transfromPixel} />
      </Navigation>
      <Contents>
        {isSpreadNavigation && (
          <FoldSpreadBtn>
            <Arrow />
          </FoldSpreadBtn>
        )}
        <ContentsHeader transfromPixel={transfromPixel}>
          <LocationPathContainer transfromPixel={transfromPixel}>
            {path.map((item, index) => {
              return item.disabled ? (
                <div key={index} className="prev__path__container">
                  <span
                    className="caption-12-rg current_path"
                    style={{ fontSize: transfromPixel(12) }}
                  >
                    {item.pageName}
                  </span>
                  <Arrow className="path__arrow" />
                </div>
              ) : index < path.length - 1 ? (
                <div
                  key={index}
                  className="prev__path__container"
                  onClick={() => {
                    //예외 처리
                    if (item.addressName === "dashboard") {
                      navigate("/dashboard/fund-account");
                    } else {
                      navigate(item.pageAddress);
                    }
                  }}
                >
                  <span
                    className="caption-12-rg prev__path__item"
                    style={{ fontSize: transfromPixel(12) }}
                  >
                    {item.pageName}
                  </span>
                  <Arrow className="path__arrow" />
                </div>
              ) : (
                <span
                  key={index}
                  className="caption-12-rg current_path"
                  style={{ fontSize: transfromPixel(12) }}
                  onClick={() => {
                    // console.log(item);
                    // navigate(item.pageAddress)
                  }}
                >
                  {item.pageName}
                </span>
              );
            })}
          </LocationPathContainer>
          <HeaderRight>
            <AlarmContainer
              ref={alarmIconRef}
              className={openRightModal ? "open" : "close"}
              transfromPixel={transfromPixel}
              onClick={() => {
                updateRightModal((pre) => !pre);
              }}
            >
              <Alarm className="alarm__icon" />
              {alarmNew && <AlarmNewDot transfromPixel={transfromPixel} />}
            </AlarmContainer>
            <UserInfoContainer transfromPixel={transfromPixel}>
              <span
                className="body-14-rg user__name"
                style={{ fontSize: transfromPixel(14) }}
                onClick={() => {
                  navigate("/mypage");
                }}
              >
                {getMyInfo().name}
              </span>
              {/* <div className="user__option__btn">
              <Arrow
                className="user__option__icon"
                width={transfromPixel(16)}
                height={transfromPixel(16)}
              />
            </div> */}
            </UserInfoContainer>
          </HeaderRight>
        </ContentsHeader>
        <ContentChild transfromPixel={transfromPixel}>
          {children}
          <RightModal
            ref={rightModalRef}
            className={openRightModal ? "open" : ""}
          >
            <Notification
              onChangeNewNoti={(result) => {
                console.log(result);
                setAlarmNew(result);
              }}
              closeRightModal={() => {
                updateRightModal(false);
              }}
            />
          </RightModal>
        </ContentChild>
      </Contents>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  overflow: hidden;
`;

const Navigation = styled.div<{ transfromPixel: (value: number) => number }>`
  width: ${(props) => props.transfromPixel(216)}px;
  min-width: ${(props) => props.transfromPixel(216)}px;
  max-width: ${(props) => props.transfromPixel(216)}px;
  background: #ffffff;
  border-right: ${(props) => props.transfromPixel(2)}px solid #f3f4f4;
  transition: 0.5s;
  overflow-y: scroll;
`;

const FoldSpreadBtn = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; //background-header
  border-right: 1px solid #f3f4f4; //divider100
  border-bottom: 1px solid #f3f4f4; //divider100
  z-index: 2;

  transition: all 0.2s ease;

  :hover {
    background-color: #f3f4f4; //button-disabled
  }

  cursor: pointer;
`;
const Contents = styled.div`
  position: relative;
  flex-grow: 1;
  height: calc(100%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentsHeader = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: ${(props) => props.transfromPixel(40)}px;
  min-height: ${(props) => props.transfromPixel(40)}px;
  max-height: ${(props) => props.transfromPixel(40)}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => props.transfromPixel(60)}px;
  background: #ffffff;
  border-bottom: ${(props) => props.transfromPixel(1)}px solid #f3f4f4;
`;

const LocationPathContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: fit-content;
  height: ${(props) => props.transfromPixel(20)}px;
  margin-top: ${(props) => props.transfromPixel(4)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.transfromPixel(3)}px;

  .prev__path__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.transfromPixel(3)}px;
    color: #1f2022; // text01

    .prev__path__item {
      transition: all 0.2s ease;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
  }
  .current_path {
    color: #bec0c2; // text-disabled
    cursor: default;
  }
  .path__arrow {
    transform: rotate(180deg);
    path {
      fill: #1f2022; // icon01
    }
  }
`;
const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const UserInfoContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: auto;
  height: auto;
  margin-top: ${(props) => props.transfromPixel(2)}px;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .user__name {
    color: #1f2022;
    padding: ${(props) => props.transfromPixel(6)}px
      ${(props) => props.transfromPixel(8)}px;
  }

  .user__option__btn {
    width: ${(props) => props.transfromPixel(32)}px;
    min-width: ${(props) => props.transfromPixel(32)}px;
    max-width: ${(props) => props.transfromPixel(32)}px;
    height: ${(props) => props.transfromPixel(32)}px;
    min-height: ${(props) => props.transfromPixel(32)}px;
    max-height: ${(props) => props.transfromPixel(32)}px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => props.transfromPixel(4)}px;
    transition: all 0.2s ease;
    cursor: pointer;

    .user__option__icon {
      transform: rotate(-90deg);
      path {
        fill: #1f2022; // icon01
      }
    }

    :hover {
      background-color: #f4f5f7;
    }
  }
`;

const AlarmContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  position: relative;
  width: ${(props) => props.transfromPixel(32)}px;
  min-width: ${(props) => props.transfromPixel(32)}px;
  max-width: ${(props) => props.transfromPixel(32)}px;
  height: ${(props) => props.transfromPixel(32)}px;
  min-height: ${(props) => props.transfromPixel(32)}px;
  max-height: ${(props) => props.transfromPixel(32)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  border-radius: 50%;
  cursor: pointer;

  .alarm__icon {
    width: ${(props) => props.transfromPixel(20)}px;
    min-width: ${(props) => props.transfromPixel(20)}px;
    max-width: ${(props) => props.transfromPixel(20)}px;
    height: ${(props) => props.transfromPixel(20)}px;
    min-height: ${(props) => props.transfromPixel(20)}px;
    max-height: ${(props) => props.transfromPixel(20)}px;
  }

  &.open {
    path {
      fill: var(--primary-blue);
    }
  }

  :hover {
    background-color: var(--hover-100);
  }
`;

const AlarmNewDot = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  position: absolute;
  top: 5px;
  right: 5px;
  width: ${(props) => props.transfromPixel(6)}px;
  min-width: ${(props) => props.transfromPixel(6)}px;
  max-width: ${(props) => props.transfromPixel(6)}px;
  height: ${(props) => props.transfromPixel(6)}px;
  min-height: ${(props) => props.transfromPixel(6)}px;
  max-height: ${(props) => props.transfromPixel(6)}px;

  border-radius: 50%;
  background-color: var(--red-500);
`;

const ContentChild = styled.div<{ transfromPixel: (value: number) => number }>`
  position: relative;
  width: 100%;
  height: calc(100% - ${(props) => props.transfromPixel(40)}px);
  background-color: var(--background-page);
  overflow: hidden;
`;
const RightModal = styled.div`
  position: absolute;
  width: 290px;
  height: calc(100% - 24px);

  top: 8px;
  right: -290px;

  background-color: white;
  z-index: 10;
  transition: all 0.2s ease;
  box-shadow: -2px 2px 10px 0px rgba(100, 103, 109, 0.3);
  border-radius: 8px;
  &.open {
    right: 16px;
  }
`;
