import axios from "axios";
import * as api from "../../api";
import {
  AcStructureListProps,
  ActiveFundAccountType,
  Channel,
  DictionaryType,
  FundAccountType,
  IndustryTech,
  LoginResponse,
  NewOldStockType,
  SignatureInfo,
  StructureDetailProps,
  UserInfo,
} from "../../../type/data";
import {
  getNewDate,
  getYMD,
  getMyInfo as getUserInfo,
} from "../../../common/commonUtil";
import { getToday } from "../../../common/commonApiData";
import { DatabaseSearchData } from "../../../router/database/desktop/DeskDatabase";
import {
  DashboardFundAccountResponse,
  FundAccountInfoForDashboard,
} from "../../../router/dashboard/fund-account-info/interface/FundAccountInfo.interface";
import {
  FundAccountDetailInfo,
  FundAccountDetailInfoResponse,
  FundManagerProps,
} from "../../../router/dashboard/fund-account-detail/interface/FundAccountDetail.interface";

export const LOGO_SMALL = 1;
export const LOGO_LARGE = 2;

export type AcStructureListType = "both" | "team" | "headquarter";

export const getLogo = async (type: number): Promise<string | undefined> => {
  const result = await axios.get(api.acceleratingLogo(), {
    params: { type: type },
  });

  if (result.status === 200) {
    const fileResult = await axios.get(api.getFile(), {
      params: { key: result.data.logo.key, type: "image" },
    });
    if (fileResult.status === 200)
      return new Promise((resolve) => resolve(fileResult.data));
  }

  return new Promise((resolve) => resolve(undefined));
};

export const latticeLogin = async (
  email: string,
  isAutoLogin: boolean
): Promise<LoginResponse | undefined> => {
  const result = await axios.post(api.login(), {
    email: email,
    autoLogin: isAutoLogin,
  });
  if (result.status !== 200) return new Promise((reject) => reject(undefined));
  return new Promise((resolve) => resolve(result.data));
};

export const latticeSignUp = async (
  email: string,
  name: string
): Promise<boolean | undefined> => {
  const result = await axios.post("/auth/ac-user/signup", { email, name });
  if (result.status === 200) {
    return new Promise((resolve) => resolve(true));
  }
  return new Promise((reject) => reject(undefined));
};

export const getAllStructure = async (): Promise<
  AcStructureListProps | undefined
> => {
  const result = await axios.get(api.getStructure());
  if (result.status !== 200) return new Promise((reject) => reject(undefined));
  return new Promise((resolve) =>
    resolve({
      team: result.data.teamList,
      headquarter: result.data.headquarterList,
    })
  );
};

const getHeadquarterId = (
  allStructure: StructureDetailProps[],
  target: StructureDetailProps
): any => {
  if (target.parent === 0) return target.acStructureId;

  const _target = allStructure.find(
    (item) => item.acStructureId === target.parent
  );

  if (!_target) return;

  return getHeadquarterId(allStructure, _target);
};

export const getAllStructureDetail = async (): Promise<
  StructureDetailProps[] | undefined
> => {
  const result = await axios.get(api.getAcceleratorStructureDetail());
  if (result.status !== 200) return new Promise((reject) => reject(undefined));

  const data: StructureDetailProps[] = result.data.acStructure.sort(
    (a: any, b: any) => a.structureIndex - b.structureIndex
  );

  const structure = data
    .map((item) => ({
      ...item,
      headquarterId: getHeadquarterId(data, item),
    }))
    .sort((a: any, b: any) => a.headquarterId - b.headquarterId);

  return new Promise((resolve) => resolve(structure));
};

export const getAllAcUser = async (
  type: "admin" | "user",
  priorityUsers?: (number | string)[]
) => {
  try {
    let acUserList: any[] = [];
    if (type === "admin") {
      acUserList = (await axios.get(api.getAcAdminUsers())).data.acUserList;
    } else {
      acUserList = (await axios.get(api.getAcUsers())).data.acUserList;
    }
    const allUser =
      acUserList.map((item: any): UserInfo => {
        return { ...item, type: "AC_USER", id: item.acUserId };
      }) || [];

    if (priorityUsers && priorityUsers.length > 0) {
      const _priorityUsers = allUser.filter((item) =>
        priorityUsers.includes(item.id)
      );
      const _normalUsers = allUser.filter(
        (item) => !priorityUsers.includes(item.id)
      );
      return [..._priorityUsers, ..._normalUsers];
    }
    return allUser;
  } catch (error) {}
};

export const getAllChannel = async (): Promise<Channel[] | undefined> => {
  try {
    const result = await axios.get(api.getAcChannel());

    console.log(result);

    if (result.status !== 200)
      return new Promise((resolve) => resolve(undefined));

    const allChannel: Channel[] = result.data.acChannelList.map(
      (item: any) => ({ ...item })
    );

    return new Promise((resolve) => resolve(allChannel));
  } catch (error) {
    console.log(error);
  }
};

export const getAllIndustrialTech = async (): Promise<
  IndustryTech[] | undefined
> => {
  try {
    const result = await axios.get(api.getAcIndustrialTech());

    console.log(result);

    if (result.status !== 200)
      return new Promise((resolve) => resolve(undefined));

    const allIndustrialTech: IndustryTech[] = result.data.acIndustrialTech.map(
      (item: any) => ({ ...item })
    );

    return new Promise((resolve) => resolve(allIndustrialTech));
  } catch (error) {
    console.log(error);
  }
};

// 모든 Fundaccount 가져오기
export const getAllFundaccount = async (
  type: "admin" | "user"
): Promise<FundAccountType[] | undefined> => {
  const today = getNewDate(await getToday());

  const getFundaccoutnStatus = (item: any) => {
    let status = "expected_investment";
    const invest_s = getNewDate(item.investmentStartDate);
    const invest_e = getNewDate(item.investmentEndDate);
    const operation_s = getNewDate(item.operatingStartDate);
    const operation_e = getNewDate(item.operatingEndDate);
    if (
      today.getTime() >= invest_s.getTime() &&
      today.getTime() <= invest_e.getTime()
    ) {
      if (item.totalAmount === item.avaliableAmount) {
        // 총 재원과 사용 가능금액이 같으면 미사용
        status = "before_investment";
      } else {
        status = "investing";
      }
    }

    if (item.avaliableAmount === 0) {
      status = "out_of_financial_resources";
    }

    if (
      today.getTime() >= operation_s.getTime() &&
      today.getTime() <= operation_e.getTime()
    ) {
      status = "operation";
    }

    if (today.getTime > operation_e.getTime) {
      status = "end_of_operation";
    }
    return status;
  };

  try {
    let data: any[] = [];
    if (type === "admin") {
      data = (await axios.get(api.getAcAdminFundAccount())).data
        .acFundAccountList;
    } else {
      data = (await axios.get(api.getAcFundAccount())).data.acFundAccountList;
    }
    const fundAccountList = data.map((item: any): FundAccountType => {
      return {
        ...item,
        status: getFundaccoutnStatus(item),
        investmentStartDate: getYMD(item.investmentStartDate),
        investmentEndDate: getYMD(item.investmentEndDate),
        operatingStartDate: getYMD(item.operatingStartDate),
        operatingEndDate: getYMD(item.operatingEndDate),
      };
    });
    return new Promise((resolve) => resolve(fundAccountList));
  } catch (error) {}
};

// 모든 Fundaccount 가져오기
export const getActiveFundaccount = async (): Promise<
  ActiveFundAccountType[] | undefined
> => {
  try {
    const data = (await axios.get(api.getAcActiveFundAccount())).data
      .acFundAccountList;
    const fundAccountList = data.map((item: any): ActiveFundAccountType => {
      return {
        ...item,
      };
    });
    return new Promise((resolve) => resolve(fundAccountList));
  } catch (error) {
    console.log(error);
  }
};

// 신주 구주 리스트 가져오기
export const getNewOldStock = async (): Promise<
  NewOldStockType[] | undefined
> => {
  try {
    const data = (await axios.get(api.getNewOldStock())).data.newOldStock;
    const newOldStock = data.map((item: any): NewOldStockType => {
      return {
        ...item,
      };
    });
    return new Promise((resolve) => resolve(newOldStock));
  } catch (error) {
    console.log(error);
  }
};

//Accelerator - StockType, StockChangeType, StageType, Relationship 가져오기
export const getAllDictionary = async (): Promise<
  DictionaryType | undefined
> => {
  try {
    const data = (await axios.get(api.getDictionary())).data;
    const dictionary: DictionaryType = { ...data };

    return new Promise((resolve) => resolve(dictionary));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

export const getSignature = async (
  acUserId: number
): Promise<SignatureInfo | undefined> => {
  try {
    const data = (await axios.get(api.getSignature(acUserId))).data;
    if (data.signature) {
      const signatureData: SignatureInfo = { ...data.signature };
      return new Promise((resolve) => resolve(signatureData));
    } else {
      return new Promise((rejects) => rejects(undefined));
    }
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

export const getContractDocumentByFundAccount = async (
  acFundAccountId: number
): Promise<any[] | undefined> => {
  const result = await axios.get(
    api.getContractDocumentByAcFundAccountId(acFundAccountId)
  );

  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.template));
  }

  return new Promise((reject) => reject(undefined));
};

export const getDropReasonByAccelerator = async (): Promise<
  string[] | undefined
> => {
  const result = await axios.get(api.getDropType());

  if (result.status === 200) {
    return new Promise((resolve) =>
      resolve(result.data.dropType.map((item: any) => item.type))
    );
  }

  return new Promise((reject) => reject(undefined));
};

export const getFundAccountForDashboard = async (): Promise<
  FundAccountInfoForDashboard[] | undefined
> => {
  const result = await axios.get(api.getFundAccountsForDashboard());
  if (result.status === 200) {
    const data: DashboardFundAccountResponse[] = result.data.acFundAccount;
    const newData: FundAccountInfoForDashboard[] = data.map((item) => {
      return {
        acFundAccountId: item.acFundAccountId,
        status: item.status,
        type: item.type,
        nickName: item.nickName,
        serialNumber: item.serialNumber,
        totalAmount: item.totalAmount,
        fundDuration: {
          addEndDate: item.addOperatingEndDate,
          startDate: item.investmentStartDate,
          endDate: item.operatingEndDate,
          investmentEndDate: item.investmentEndDate,
        },
        totalOriginalInvestment: item.investmentAccount
          .map((item) => item.investmentAmount || 0)
          .reduce((p, c) => p + c, 0),
        totalOriginalExit: item.investmentExit
          .map((item) => item.principal || 0)
          .reduce((p, c) => p + c, 0),
        totalExitAmount: item.investmentExit
          .map((item) => item.amount || 0)
          .reduce((p, c) => p + c, 0),
        investedNum: item.investmentAccount.length,
        riskStatus: item.riskStatus,
      };
    });

    console.log(newData);
    return new Promise((resolve) => resolve(newData));
  }

  return new Promise((reject) => reject(undefined));
};

export const getFundAccountDetailForDashboard = async (
  acFundAccountId: number
): Promise<FundAccountDetailInfo | undefined> => {
  const result = await axios.get(
    api.getFundAccountDetailForDashboard(acFundAccountId)
  );
  if (result.status === 200) {
    const data: FundAccountDetailInfoResponse = result.data.result;
    const newData: FundAccountDetailInfo = {
      type: data.type,
      status: data.status,
      acFundAccountId: data.acFundAccountId,
      nickName: data.nickName,
      nameKo: data.nameKo,
      nameEn: data.nameEn,
      totalAmount: data.totalAmount,
      investmentOrganizationCount: data.investmentOrganizationCount,
      serialNumber: data.serialNumber,
      concept: data.concept,
      baseRate: data.baseRate,
      terms: data.terms ? data.terms.split("||") : [],
      fundDuration: {
        addEndDate:
          data.addOperatingEndDate !== null
            ? data.addOperatingEndDate
            : undefined,
        startDate: data.investmentStartDate,
        endDate: data.operatingEndDate,
        investmentEndDate: data.investmentEndDate,
      },
      gpFee: data.gpFee || 0,
      totalOriginalInvestment: data.investmentAccount
        .map((item) => item.investmentAmount)
        .reduce((p, c) => p + c, 0),
      totalOriginalExit: data.investmentExit
        .map((item) => item.principal)
        .reduce((p, c) => p + c, 0),
      investmentPurpose: data.investmentPurpose.map((item) => ({
        ...item,
        purposeAmount: item.purposeAmount
          .map((item) => item.investmentAmount)
          .reduce((p, c) => p + c, 0),
      })),
    };
    console.log(newData);
    return new Promise((resolve) => resolve(newData));
  }

  return new Promise((reject) => reject(undefined));
};

export const getFundAccountManagersForDashboard = async (
  acFundAccountId: number
): Promise<FundManagerProps[] | undefined> => {
  const result = await axios.get(
    api.getFundAccountFundManagerForDashboard(acFundAccountId)
  );
  if (result.status === 200) {
    return new Promise((resolve) => resolve(result.data.fundManager));
  }

  return new Promise((reject) => reject(undefined));
};
