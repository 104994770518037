import React, { useState, useRef } from "react";
import styled from "styled-components";

import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { RiskManagementListProps } from "../../interface/RME.interface";

interface Props {
  data: RiskManagementListProps;
  onClick?: () => void;
}

export const EvaluationWaitCard: React.FC<Props> = (props) => {
  const { data, onClick } = props;
  const getLabel = () => {
    switch (data.status) {
      case 2:
        return (
          <CustomColorLabel
            textColor="var(--text-05, #96999F)"
            bgColor="var(--gray-200, #EDEEEF)"
          >
            평가대기
          </CustomColorLabel>
        );
      case 3:
        return (
          <CustomColorLabel
            textColor="var(--blue500, #173AFF)"
            bgColor="var(--blue100, #E8F0FF)"
          >
            평가중
          </CustomColorLabel>
        );
    }

    return null;
  };
  return (
    <Container onClick={onClick}>
      {getLabel()}
      <Title className="heading-18-sb">{data.portfolio.companyName}</Title>
      <Description className="caption-12-rg">
        {data.portfolio.description}
      </Description>
    </Container>
  );
};

const Container = styled.div`
  width: 282px;
  height: 158px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  border-radius: 4px;
  border: 1px solid var(--gray200, #edeeef);
  background: var(--background-contents-01, #fff);

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;

const Title = styled.div`
  color: var(--text01, #1f2022);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 6px;
`;
const Description = styled.div`
  display: -webkit-box;
  margin-top: 12px;
  height: 40px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text05, #96999f);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
