import { DefaultButton } from "lattice_core";
import React, { CSSProperties, useState } from "react";
import styled, { css } from "styled-components";
import { rootStyle } from "../../../../style/ThemeStyles";

import { ReactComponent as IconExcel } from "../../../../assets/image/icon-xlsx.svg";
import { ReactComponent as IconPdf } from "../../../../assets/image/icon-pdf.svg";
import { ReactComponent as IconFile } from "../../../../assets/image/icon-file.svg";
import { ReactComponent as IconJpg } from "../../../../assets/image/icon-jpg.svg";
import { ReactComponent as IconPng } from "../../../../assets/image/icon-png.svg";
import { ReactComponent as IconWeb } from "../../../../assets/image/icon-web.svg";

import { ReactComponent as IconAlert } from "../../../../assets/image/icon-alert-16.svg";

import { documentDownload } from "../../repository/ReportingRepository";

import SpinnerModal from "../../../../components/modal/SpinnerModal";
import FileUploaderSingle from "./FileUploaderSingle";
import { ReportingFile } from "../../../reporting-detail/interface/ReportingDetail.interface";

interface Props {
  isAuthority?: boolean;
  dragAble?: boolean;
  isDragging?: boolean;
  data: ReportingFile;
  isOpen?: boolean;
  index: number;
  buttonTitle?: string;
  isValidation?: boolean;
  transfromPixel: (value: number) => number;
  onClickButton?: () => void;
  reject: () => void;
  rejectCancel: () => void;
  confirm: () => void;
  confirmCancel: () => void;
}

export const getCustomIcon = (
  extension: string,
  style: CSSProperties,
  type?: string
): JSX.Element => {
  switch (extension) {
    case "excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return <IconExcel style={style} />;
    case "pdf":
    case "application/pdf":
      return <IconPdf style={style} />;
    case "web":
      return <IconWeb style={style} />;
    default:
  }

  if (extension === "file") {
    switch (type) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <IconExcel style={style} />;
      case "application/pdf":
        return <IconPdf style={style} />;
      case "image/jpg":
      case "image/jpeg":
        return <IconJpg style={style} />;
      case "image/png":
        return <IconPng style={style} />;
      default:
        return <IconFile style={style} />;
    }
  }
  return <IconFile style={style} />;
};

const BaseCard = (props: Props) => {
  const {
    isAuthority,
    data,
    isOpen,
    index,
    buttonTitle,
    isValidation,
    transfromPixel,
    onClickButton,
    reject,
    rejectCancel,
    confirm,
    confirmCancel,
  } = props;

  const [isHoverIcon, setIsHoverIcon] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const getLabel = () => {
    switch (data.file?.uploadStatus?.uploadStatusId) {
      case 1:
        return (
          <UploadLabel
            className="caption-12-sb"
            style={{
              backgroundColor: "#ffd84f",
            }}
            transfromPixel={props.transfromPixel}
          >
            파일이 제출되었습니다. 확인해 주세요.
          </UploadLabel>
        );
      case 2:
        return (
          <UploadLabel
            className="caption-12-sb"
            style={{
              backgroundColor: "#FE9041",
            }}
            transfromPixel={props.transfromPixel}
          >
            파일이 교체되었습니다. 확인해 주세요.
          </UploadLabel>
        );
      default:
        return null;
    }
  };
  return (
    <StyledCard
      className={`${
        data.file?.uploadStatus?.uploadStatusId === 4 ? "reject" : ""
      }`}
      transfromPixel={transfromPixel}
      isOpen={isOpen}
    >
      <FlexRow style={{ gap: transfromPixel(4) }}>
        <Chip
          className={`caption-11-rg ${data?.required ? "required" : "option"}`}
          transfromPixel={transfromPixel}
        >
          {data?.required ? "필수" : "변경 시 제출"}
        </Chip>
        {data?.tag && (
          <Chip
            className="required-file caption-11-rg"
            transfromPixel={transfromPixel}
          >
            {data?.tag}
          </Chip>
        )}
      </FlexRow>
      <FileName
        className={`heading-20-sb ${isOpen ? "open" : "closed"}`}
        transfromPixel={transfromPixel}
      >
        {data?.name}
        {isValidation === false && (
          <div
            className="icon"
            onMouseOver={() => setIsHoverIcon(true)}
            onMouseOut={() => setIsHoverIcon(false)}
            onFocus={() => setIsHoverIcon(true)}
            onBlur={() => setIsHoverIcon(false)}
          >
            <IconAlert />
            {isHoverIcon && (
              <div className="tool-tip caption-11-rg">유효성 확인 오류</div>
            )}
          </div>
        )}
      </FileName>

      {isOpen && !data.file && buttonTitle && (
        <TemplateBtn transfromPixel={transfromPixel}>
          <DefaultButton
            className="caption-12-md"
            buttonType="colored-outline"
            sizeType="xxsmall"
            buttonColor={rootStyle.getPropertyValue("--text-01")}
            borderColor={rootStyle.getPropertyValue("--gray-400")}
            disabled={false}
            text={buttonTitle}
            transfromPixel={transfromPixel}
            onClick={(e) => {
              e?.stopPropagation();
              onClickButton && onClickButton();
            }}
          />
        </TemplateBtn>
      )}
      {!data.file && (
        <BottomComponent>
          <NumberFileIcon transfromPixel={transfromPixel}>
            <span className="reporting-card-font number">
              {`${index + 1}`.padStart(2, "0")}
            </span>
            {getCustomIcon(data?.extension || "", {
              width: transfromPixel(60),
              height: transfromPixel(60),
            })}
          </NumberFileIcon>
        </BottomComponent>
      )}
      {data.file && (
        <BottomComponent>
          <FileComponent transfromPixel={transfromPixel}>
            <FileUploaderSingle
              className="body-14-rg"
              width={252}
              isEditable={isOpen}
              file={data}
              fileName={data.file?.documentFile?.fileName}
              isDeletable={false}
              onClickFile={async () => {
                if (!data.file?.pfReportDocumentId) return;
                setIsDownloading(true);
                await documentDownload(
                  data.file.pfReportDocumentId,
                  data.file.documentFile?.fileName || "untitle",
                  data.extension || "pdf"
                );
                setIsDownloading(false);
              }}
              customIcon={getCustomIcon(
                data.extension || "",
                {
                  width: 24,
                  height: 24,
                },
                data.file?.documentFile?.fileType
              )}
              transfromPixel={transfromPixel}
            />
            {isAuthority && isOpen && (
              <div className="button-container">
                {[1, 2, 3].includes(data.file.uploadStatus.uploadStatusId) &&
                  buttonTitle && (
                    <DefaultButton
                      className="caption-12-md"
                      buttonType="colored-outline"
                      sizeType="xxsmall"
                      buttonColor={rootStyle.getPropertyValue("--text-01")}
                      borderColor={rootStyle.getPropertyValue("--gray-400")}
                      text={buttonTitle}
                      transfromPixel={props.transfromPixel}
                      onClick={(e) => {
                        e?.stopPropagation();
                        onClickButton && onClickButton();
                      }}
                    />
                  )}

                {[1, 2, 3].includes(data.file.uploadStatus.uploadStatusId) && (
                  <DefaultButton
                    className="caption-12-md"
                    buttonType="colored-outline"
                    sizeType="xxsmall"
                    buttonColor={rootStyle.getPropertyValue("--text-01")}
                    borderColor={rootStyle.getPropertyValue("--gray-400")}
                    text="반려"
                    transfromPixel={props.transfromPixel}
                    onClick={(e) => {
                      e?.stopPropagation();
                      reject();
                    }}
                  />
                )}
                {[1, 2, 3].includes(data.file.uploadStatus.uploadStatusId) && (
                  <DefaultButton
                    className="caption-12-md"
                    buttonType="colored-outline"
                    sizeType="xxsmall"
                    buttonColor={rootStyle.getPropertyValue("--text-01")}
                    borderColor={rootStyle.getPropertyValue("--gray-400")}
                    text="확정"
                    transfromPixel={props.transfromPixel}
                    onClick={(e) => {
                      e?.stopPropagation();
                      confirm();
                    }}
                  />
                )}
                {[4].includes(data.file.uploadStatus.uploadStatusId) && (
                  <DefaultButton
                    className="caption-12-md"
                    buttonType="colored-outline"
                    sizeType="xxsmall"
                    buttonColor={rootStyle.getPropertyValue("--text-01")}
                    borderColor={rootStyle.getPropertyValue("--gray-400")}
                    disabled={!props.isOpen}
                    text="반려취소"
                    transfromPixel={props.transfromPixel}
                    onClick={(e) => {
                      e?.stopPropagation();
                      rejectCancel();
                    }}
                  />
                )}
                {[5].includes(data.file.uploadStatus.uploadStatusId) && (
                  <DefaultButton
                    className="caption-12-md"
                    buttonType="colored-outline"
                    sizeType="xxsmall"
                    buttonColor={rootStyle.getPropertyValue("--text-01")}
                    borderColor={rootStyle.getPropertyValue("--gray-400")}
                    disabled={!props.isOpen}
                    text="확정취소"
                    transfromPixel={props.transfromPixel}
                    onClick={(e) => {
                      e?.stopPropagation();
                      confirmCancel();
                    }}
                  />
                )}
              </div>
            )}
          </FileComponent>
        </BottomComponent>
      )}
      {data.file?.uploadStatus?.uploadStatusId === 4 && (
        <CircleRefuse
          className="heading-14-md"
          style={{
            borderColor: "var(--red-500)",
            color: "var(--red-500)",
          }}
          transfromPixel={props.transfromPixel}
        >
          반려
        </CircleRefuse>
      )}
      {data.file?.uploadStatus?.uploadStatusId === 5 && (
        <CircleRefuse
          className="heading-14-md"
          style={{
            borderColor: "var(--blue-400)",
            color: "var(--blue-400)",
          }}
          transfromPixel={props.transfromPixel}
        >
          확정
        </CircleRefuse>
      )}

      {data.file?.uploadStatus?.uploadStatusId === 4 && data.file.comment && (
        <RejectReason transfromPixel={props.transfromPixel}>
          <span>반려사유 </span>
          {props.data.file?.comment || ""}
        </RejectReason>
      )}

      {getLabel()}
      {isDownloading && (
        <SpinnerModal open={isDownloading} message="문서 다운로드 중..." />
      )}
    </StyledCard>
  );
};

const StyledCard = styled.div<{
  transfromPixel: (value: number) => number;
  isOpen?: boolean;
}>`
  position: relative;
  width: ${(props) => Math.floor(props.transfromPixel(300))}px;
  height: ${(props) => props.transfromPixel(274)}px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  background: var(--background-contents-01, #fff);

  padding: ${(props) => props.transfromPixel(24)}px
    ${(props) => props.transfromPixel(32)}px;

  cursor: default;

  &.reject {
    background: var(--light-red-50, #fff2f3);
  }

  :hover {
    ${(props) => (props.isOpen ? "outline: 2px solid var(--blue-300)" : "")};
  }
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Chip = styled.div<{ transfromPixel: (value: number) => number }>`
  padding: ${(props) => props.transfromPixel(2)}px
    ${(props) => props.transfromPixel(6)}px;
  border-radius: ${(props) => props.transfromPixel(2)}px;

  &.required {
    background: var(--blue-800);
    color: var(--text-02);
  }
  &.option {
    background: var(--white);
    color: var(--text-03);
    border: ${(props) => props.transfromPixel(1)}px solid var(--gray-300);
  }
  &.required-file {
    background: var(--gray-300);
    color: var(--text-03);
  }
`;

const FileName = styled.div<{ transfromPixel: (value: number) => number }>`
  margin-top: ${(props) => props.transfromPixel(8)}px;
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.transfromPixel(4)}px;
  &.open {
    color: var(--text-01);
  }
  &.closed {
    color: var(--text-disabled);
  }

  .icon {
    position: relative;
    flex: 1;
    width: fit-content;
    .tool-tip {
      position: absolute;
      top: ${(props) => props.transfromPixel(25)}px;
      background: var(--dim-500);
      color: var(--text-02);
      border-radius: ${(props) => props.transfromPixel(4)}px;

      padding: ${(props) => props.transfromPixel(6)}px 0;
      width: ${(props) => props.transfromPixel(96)}px;
      z-index: 1000;
      text-align: center;
    }
  }
`;

const TemplateBtn = styled.div<{ transfromPixel: (value: number) => number }>`
  margin-top: ${(props) => props.transfromPixel(16)}px;
`;

const BottomComponent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const NumberFileIcon = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 0px ${(props) => props.transfromPixel(32)}px
    ${(props) => props.transfromPixel(24)}px
    ${(props) => props.transfromPixel(32)}px;

  .number {
    color: var(--gray-300);
  }
`;

const FileComponent = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  background: var(--gray-100);
  min-height: ${(props) => props.transfromPixel(120)}px;
  max-height: ${(props) => props.transfromPixel(120)}px;
  padding: ${(props) => props.transfromPixel(20)}px
    ${(props) => props.transfromPixel(24)}px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.transfromPixel(8)}px;

  .button-container {
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.transfromPixel(8)}px;
  }
`;

const UploadLabel = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;
    width: 100%;
    height: ${transfromPixel(26)}px;
    display: flex;
    justify-content: center;
    align-items: center;

    left: 0;
    bottom: ${transfromPixel(120)}px;

    color: #ffffff;
  `
);

const CircleRefuse = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;
    top: ${transfromPixel(24)}px;
    right: ${transfromPixel(24)}px;
    border: ${transfromPixel(1)}px solid var(--red-500);
    border-radius: ${transfromPixel(24)}px;
    width: ${transfromPixel(48)}px;
    height: ${transfromPixel(48)}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `
);

const RejectReason = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;

    width: calc(100% - ${transfromPixel(64)}px);
    height: ${transfromPixel(42)}px;
    left: ${transfromPixel(32)}px;
    top: ${transfromPixel(98)}px;
    line-height: ${transfromPixel(20)}px;
    max-height: ${transfromPixel(40)}px;

    background-color: transparent;
    color: #f6444f;
    z-index: 100;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    :hover {
      width: calc(100% - ${transfromPixel(24)}px);
      height: fit-content;

      left: ${transfromPixel(12)}px;
      top: ${transfromPixel(78)}px;
      padding: ${transfromPixel(20)}px;

      max-height: max-content;

      display: block;
      -webkit-line-clamp: none;
      -webkit-box-orient: vertical;
      overflow: hidden;

      color: #ffffff;
      background-color: #f6444f;
      background: rgba(31, 32, 34, 0.8);
      border-radius: ${transfromPixel(4)}px;
    }
  `
);

export default BaseCard;
