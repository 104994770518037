import { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { EvaluationCard } from "../components/EvaluationCard";
import { CreateEvaluationCard } from "../components/CreateEvaluationCard";

import { getYMD, getYMDHM } from "../../../../../common/commonUtil";
import { EvaluationEmpty } from "./EvaluationEmpty";
import { EvaluationListProps } from "../../interface/Evaluation.interface";
import { toUnitless } from "@mui/material/styles/cssUtils";
interface Props {
  sectionTitle: string;
  showCreateCard: boolean;
  createAble: boolean;
  evaluationList: EvaluationListProps[];
  onClickCard: (investmentAssessmentId: number) => void;
  onClickCreateEvaluation?: () => void;
}
export const EvaluationSection: React.FC<Props> = (props) => {
  const {
    sectionTitle,
    showCreateCard,
    createAble,
    evaluationList,
    onClickCard,
    onClickCreateEvaluation,
  } = props;
  useEffect(() => {}, []);
  return (
    <Container>
      <SectionTitle className="heading-18-md">{sectionTitle}</SectionTitle>
      <CardList>
        {!showCreateCard && evaluationList.length === 0 && (
          <EvaluationEmpty msg={["열린 투자 심사가 없어요."]} />
        )}
        {showCreateCard && (
          <CreateEvaluationCard
            onClickCard={() => {
              createAble &&
                onClickCreateEvaluation &&
                onClickCreateEvaluation();
            }}
            disabled={!createAble}
          />
        )}
        {evaluationList.map((item, key) => {
          return (
            <EvaluationCard
              key={key}
              evaluationCardInfo={item}
              onClickCard={() => {
                onClickCard(item.investmentAssessmentId);
              }}
            />
          );
        })}
      </CardList>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: calc(282px * 4 + 96px);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 32px 0px 24px 0px;
`;

const SectionTitle = styled.div`
  color: var(--text-01);
`;

const CardList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
`;
