import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FinanceDataProps,
  RMEDContentsProps,
  RMEDLocationProps,
} from "../interface/RMEDV.interface";
import {
  getMyInfo,
  showToast,
  trnasfromBase64ToObject,
} from "../../../../common/commonUtil";

import {
  getFinanceDataByInfo,
  getPortfolioParticipant,
  getRMData,
  getReportDocumentByInfo,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import { ReportDocumentProps, UserInfo } from "../../../../type/data";
import { RiskFileType } from "../desktop/components/CustomFileOnlyDownload";
import { Bounce } from "react-toastify";

const useRiskManagementEvaluationDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isViewer = useRef<boolean>(false);
  const isLoaded = useRef<boolean>(false);
  const initRMED = useRef<boolean>(false);

  const document = useRef<RMEDContentsProps | undefined>(undefined);
  const originDocument = useRef<RMEDContentsProps | undefined>(undefined);

  const riskManagementId = useRef<number>(0);
  const year = useRef<number>(0);
  const quarter = useRef<number>(0);
  const mainParticipant = useRef<UserInfo | undefined>(undefined);

  const stockholderListDocument = useRef<ReportDocumentProps | undefined>(
    undefined
  );

  const [currentFS, setCurrentFS] = useState<FinanceDataProps | undefined>(
    undefined
  );
  const [lastYearFS, setLastYearFS] = useState<FinanceDataProps | undefined>(
    undefined
  );

  const [closedCompany, setClosedCompany] = useState<boolean | undefined>(
    undefined
  );
  const [closedCompanyFile, setClosedCompanyFile] = useState<
    RiskFileType[] | undefined
  >(undefined);
  const [loadCompleted, setLoadCompleted] = useState<boolean>(false);
  const [isParticipant, setIsParticipant] = useState<boolean>(false);
  const [updatedAt, setUpdatedAt] = useState<string>("");

  const getDocumentInfo = () => {
    const locationPath = location.pathname.split("/");
    const transformedObject = locationPath[4];
    if (locationPath.length > 5) {
      isViewer.current = locationPath[5] === "viewer";
    }
    const object = trnasfromBase64ToObject(transformedObject);
    return object;
  };

  const onClickBackBtn = () => {
    navigate(-1);
    // navigate("/quarter-management/evaluation", { replace: true });
  };

  const onClickModifyBtn = () => {
    const path = location.pathname.split("/");
    navigate(path.slice(0, path.length - 1).join("/"));
  };

  const onErrorBack = (text: string) => {
    showToast({
      customElement: (
        <div
          className="heading-16-sb"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>{text}</span>
        </div>
      ),
      backgroundColor: "var(--red-500)",
      color: "var(--text-white)",
      width: 274,
      height: 48,
      milliSec: 1500,
      transition: Bounce,
      position: "bottom-center",
      toastId: new Date().toString(),
    });

    onClickBackBtn();
  };

  const setRMED = async (data?: RMEDContentsProps) => {
    if (!data) return;

    if ([1, 2, 3].includes(data.status)) {
      onErrorBack("잘못 된 접근 입니다.");
    }

    const newData: RMEDContentsProps = { ...data };

    year.current = newData.year;
    quarter.current = newData.quarterNum;
    riskManagementId.current = newData.id;

    const participantResult = await getPortfolioParticipant(
      data.portfolio.portfolioId
    );

    const stockHolderListResult = await getReportDocumentByInfo(
      data.portfolio.portfolioId,
      data.year,
      data.quarterNum,
      3
    );

    const currentFSResult: FinanceDataProps = await getFinanceDataByInfo(
      data.portfolio.portfolioId,
      year.current,
      quarter.current
    );

    const lastYearFSResult: FinanceDataProps = await getFinanceDataByInfo(
      data.portfolio.portfolioId,
      year.current,
      1
    );

    setCurrentFS(currentFSResult);
    setLastYearFS(lastYearFSResult);

    if (stockHolderListResult) {
      stockholderListDocument.current = stockHolderListResult;
    }

    if (participantResult) {
      const myinfo = getMyInfo();
      mainParticipant.current = participantResult.find(
        (item) => item.screenRoleId === 1
      )?.acUser;

      setIsParticipant(
        `${myinfo.id}` === `${mainParticipant.current?.acUserId}`
      );
    }

    const closedCompanyIssue = newData.riskIssueList.find(
      (item) => item.riskIssue.id === 7
    );

    document.current = structuredClone(newData);
    originDocument.current = structuredClone(newData);

    setClosedCompany(closedCompanyIssue?.result);
    setClosedCompanyFile(
      closedCompanyIssue?.riskIssueFile?.map((file): RiskFileType => {
        return {
          type: "s3_pdf",
          file: {
            fileName: file.file?.fileName || "",
            key: file.file?.key || "",
          },
        };
      })
    );

    setUpdatedAt(data.updatedAt);
    setLoadCompleted(true);
  };

  const getFileDefault = (): RiskFileType[] => {
    console.log(closedCompany);
    console.log(stockholderListDocument.current);

    if (closedCompany) {
      console.log(closedCompanyFile);
      return closedCompanyFile || [];
    } else if (
      !closedCompany &&
      stockholderListDocument.current !== undefined
    ) {
      console.log("dklfjsldajlf");
      return [
        {
          type: "s3_pdf",
          acPfDocumentId: 3,
          file: {
            fileName: stockholderListDocument.current.documentFile.fileName,
            key: stockholderListDocument.current.documentFile.key,
          },
        },
      ];
    }
    return [];
  };

  const init = async () => {
    if (initRMED.current) return;

    initRMED.current = true;
    isLoaded.current = false;
    const documentInfo: RMEDLocationProps = { ...getDocumentInfo() };
    if (documentInfo) {
      const rmedData = await getRMData(documentInfo.riskManagementId);

      setRMED(rmedData);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return [
    isViewer,
    year.current,
    quarter.current,
    document.current,
    updatedAt,
    currentFS,
    lastYearFS,
    loadCompleted,
    closedCompany,
    closedCompanyFile,
    isParticipant,
    mainParticipant.current,
    stockholderListDocument.current,
    onClickBackBtn,
    getFileDefault,
    onClickModifyBtn,
  ] as const;
};

export default useRiskManagementEvaluationDetail;
