import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bounce } from "react-toastify";

import {
  showToast,
  trnasfromBase64ToObject,
} from "../../../../common/commonUtil";

import { Term } from "../../../../type/data";
import axios from "axios";

import {
  agreeTermsByPortfolioId,
  checkCompanyInfoCode,
  checkTerms,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import { getTermsList } from "../../../../api/repository/common/CommonRepository";

const getIds = (location: any, type: "portfolio" | "investmentAssessment") => {
  const code = location.pathname.split("/")[3];
  const { portfolioId, investmentAssessmentId } = trnasfromBase64ToObject(code);

  if (type === "portfolio") {
    return portfolioId;
  } else {
    return investmentAssessmentId;
  }
};

const useRequestExpert = () => {
  const location = useLocation();

  const portfolioId = useRef<number>(getIds(location, "portfolio"));
  const investmentAssessmentId = useRef<number>(
    getIds(location, "investmentAssessment")
  );
  const [authSuccess, setAuthSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [isAuthSuccess, setIsAuthSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [privacy, setPrivacy] = useState<Term | undefined>(undefined);
  const [personal, setPersonal] = useState<Term | undefined>(undefined);
  const [isPortfolioTermAgree, setIsPortfolioTermAgree] = useState<
    boolean | undefined
  >(undefined);

  const checkAuthCode = async (code: string) => {
    try {
      if (!investmentAssessmentId.current) return;

      const result = await checkCompanyInfoCode(
        investmentAssessmentId.current,
        code
      );
      if (result.success) {
        setIsAuthSuccess(true);
        await checkPortfolioTerms();
        setTimeout(() => {
          setAuthSuccess(true);
        }, 1500);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response);
        if (error.response && error.response.status === 400) {
          setIsAuthSuccess(false);
          showToast({
            customElement: (
              <div
                className="heading-16-sb"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {error.response.data.message === "Invalid" ? (
                  <span>
                    인증코드가 틀렸습니다.
                    <br />
                    다시 정확히 입력해주세요.
                  </span>
                ) : (
                  <span>
                    만료된 인증코드입니다.
                    <br />
                    메일을 다시 확인해 주세요.
                  </span>
                )}
              </div>
            ),
            backgroundColor: "var(--red-500)",
            color: "var(--white)",
            width: 274,
            milliSec: 3000,
            transition: Bounce,
            position: "bottom-center",
            toastId: new Date().toString(),
          });
        }
      }
    }
  };

  const checkPortfolioTerms = async () => {
    if (!portfolioId.current) return;

    const result = await checkTerms(portfolioId.current);
    setIsPortfolioTermAgree(result.success);
    if (!result.success) {
      getTerms();
    }
  };

  const getTerms = async () => {
    const result = await getTermsList();
    if (result) {
      setPrivacy(result.privacy);
      setPersonal(result.personal);
    }
  };

  const agreeTerms = async (termsAndConditionsIds: number[]) => {
    if (!portfolioId.current) return;
    const result = await agreeTermsByPortfolioId({
      portfolioId: portfolioId.current,
      termsAndConditionsIds: termsAndConditionsIds,
    });
    if (result.success) {
      await checkPortfolioTerms();
    }
  };

  useEffect(() => {
    const htmlTitle = window.document.querySelector("title");
    if (htmlTitle) {
      console.log(htmlTitle);
      htmlTitle.innerHTML = "전문가 의견";
    }
  }, []);

  return [
    portfolioId,
    investmentAssessmentId,
    authSuccess,
    isAuthSuccess,
    isPortfolioTermAgree,
    privacy,
    personal,
    setIsAuthSuccess,
    checkAuthCode,
    getTerms,
    agreeTerms,
  ] as const;
};

export default useRequestExpert;
