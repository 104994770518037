import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Channel, IndustryTech, UserInfo } from "../../../type/data";
import {
  getAllAcUser,
  getAllChannel,
  getAllIndustrialTech,
} from "../../../api/repository/accelerator/AcceleratorRepository";
import {
  DefaultExtraInfo,
  ExcavationPortfolioChangeDataProps,
  ExcavationPortfolioCreateProps,
} from "../interface/ExcavationCreate.interface";
import { ErrorMSG } from "../../portfolio/info-modify/hook/usePortfolioInfoModify";
import { checkEmailForm, getNewDate, getYMD } from "../../../common/commonUtil";
import {
  createExcavationPortfolio,
  duplicateInfoCehck,
} from "../../../api/repository/portfolio/PortfolioRepository";
import { uploadFile } from "../../../api/repository/common/CommonRepository";

const useExcavationCreate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const portfolioInfo = useRef<ExcavationPortfolioCreateProps>({
    pfExtraInfo: { content: DefaultExtraInfo },
  });
  const [allIndustrialTech, setAllIndustrialTech] = useState<IndustryTech[]>(
    []
  );
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [isWeaknessDuplicate, updateWeaknessDuplicate] =
    useState<boolean>(false);
  const [isSubmitAble, updateSubmitAble] = useState<boolean>(false);
  const [errorMSGArr, setErrorMSGArr] = useState<ErrorMSG[]>([]);
  const [allUser, setAllUser] = useState<UserInfo[]>([]);
  const [allChannel, setAllChannel] = useState<Channel[]>([]);

  const init = async () => {
    const industrialTechResult = await getAllIndustrialTech();
    const userResult = await getAllAcUser("user");
    const channelResult = await getAllChannel();
    if (industrialTechResult) setAllIndustrialTech(industrialTechResult);
    if (userResult) setAllUser(userResult);
    if (channelResult) setAllChannel(channelResult);
  };

  const onClickCancel = () => {
    navigate(-1);
  };

  const checkSubmitAble = () => {
    if (!portfolioInfo) return false;

    const errArr: ErrorMSG[] = [];
    const companyName = portfolioInfo.current.portfolio?.companyName;
    const ceoName = portfolioInfo.current.portfolio?.ceoName;
    const ceoTel = portfolioInfo.current.portfolio?.ceoTell;
    const ceoEmail = portfolioInfo.current.portfolio?.ceoEmail;
    const mainIndustriTech = portfolioInfo.current.pfIndustrialTech?.find(
      (item) => item.isMain
    );
    const description = portfolioInfo.current.portfolio?.description;
    const acChannel = portfolioInfo.current.portfolio?.acChannel;

    if (ceoEmail) {
      if (!checkEmailForm(ceoEmail)) {
        errArr.push({
          type: "ceoEmail",
          value: "올바른 형식으로 입력해주세요.",
        });
      }

      if (ceoEmail.includes("bluepoint.ac")) {
        errArr.push({ type: "ceoEmail", value: "기업 도메인을 입력해주세요." });
      }
    }

    if (ceoTel && (ceoTel.length !== 13 || ceoTel.slice(0, 3) !== "010")) {
      errArr.push({ type: "ceoTel", value: "올바른 형식으로 입력해주세요." });
    }

    setErrorMSGArr([...errArr]);

    if (!companyName || !mainIndustriTech || !description || !acChannel) {
      return updateSubmitAble(false);
    }

    updateSubmitAble(true);
  };

  const onCreatePortfolio = async () => {
    setLoadingModal(true);

    const createResult = await createExcavationPortfolio(
      {
        ...portfolioInfo.current,
        portfolio: {
          ...portfolioInfo.current.portfolio,
          excavationDate:
            portfolioInfo.current.portfolio?.excavationDate ||
            getYMD(getNewDate()),
        },
      },
      portfolioInfo.current.portfolio &&
        portfolioInfo.current.portfolio.businessPlanFile
        ? portfolioInfo.current.portfolio.businessPlanFile.file
        : undefined
    );

    setLoadingModal(false);
    if (createResult) {
      navigate(-1);
    }
  };

  const onSubmit = async () => {
    await onCreatePortfolio();
  };

  const onChangeData = (data: ExcavationPortfolioChangeDataProps) => {
    switch (data.type) {
      case "companyName":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          companyName: data.value,
        };
        break;
      case "description":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          description: data.value,
        };
        break;
      case "ceoName":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          ceoName: data.value,
        };
        break;
      case "ceoTel":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          ceoTell: data.value,
        };
        break;
      case "ceoEmail":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          ceoEmail: data.value,
        };
        break;
      case "acChannel":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          acChannel: data.value,
        };
        break;
      case "excavator":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          excavator: data.value,
        };
        break;
      case "businessPlanFile":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          businessPlanFile: data.value,
        };
        break;
      case "problem":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          problem: data.value,
        };
        break;
      case "solution":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          solution: data.value,
        };
        break;

      case "excavationDate":
        portfolioInfo.current.portfolio = {
          ...portfolioInfo.current.portfolio,
          excavationDate: data.value,
        };
        break;
      case "businessModel":
        if (portfolioInfo.current.pfExtraInfo) {
          portfolioInfo.current.pfExtraInfo = {
            content: portfolioInfo.current.pfExtraInfo?.content.map((item) => {
              if (item.key === "businessModel")
                return { ...item, contents: data.value };
              return { ...item };
            }),
          };
        }
        break;
      case "team":
        if (portfolioInfo.current.pfExtraInfo) {
          portfolioInfo.current.pfExtraInfo = {
            content: portfolioInfo.current.pfExtraInfo?.content.map((item) => {
              if (item.key === "team") return { ...item, contents: data.value };
              return { ...item };
            }),
          };
        }
        break;
      case "other":
        if (portfolioInfo.current.pfExtraInfo) {
          portfolioInfo.current.pfExtraInfo = {
            content: portfolioInfo.current.pfExtraInfo?.content.map((item) => {
              if (item.key === "other")
                return { ...item, contents: data.value };
              return { ...item };
            }),
          };
        }
        break;
      case "mainPfIndustrialTech":
        if (portfolioInfo.current.pfIndustrialTech) {
          const notMainIndustriTech = portfolioInfo.current.pfIndustrialTech
            .filter(
              (item) =>
                item.acIndustrialTech.acIndustrialTechId !==
                data.value.acIndustrialTech.acIndustrialTechId
            )
            .filter((item) => !item.isMain);
          portfolioInfo.current.pfIndustrialTech = [
            ...notMainIndustriTech,
            data.value,
          ];
        } else {
          portfolioInfo.current.pfIndustrialTech = [data.value];
        }
        break;
      case "subPfIndustrialTech": {
        if (portfolioInfo.current.pfIndustrialTech) {
          const mainIndustriTech = portfolioInfo.current.pfIndustrialTech.find(
            (item) => item.isMain
          );
          portfolioInfo.current.pfIndustrialTech = [
            ...data.value,
            mainIndustriTech || [],
          ];
        } else {
          console.log("asdfkljalkfjaslafj");
          portfolioInfo.current.pfIndustrialTech = [...data.value];
        }
        break;
      }
    }
    checkSubmitAble();
    console.log(data);
    console.log(portfolioInfo);
  };

  useEffect(() => {
    init();
  }, []);

  return [
    loadingModal,
    allIndustrialTech,
    allUser,
    allChannel,
    isSubmitAble,
    errorMSGArr,
    isWeaknessDuplicate,
    updateWeaknessDuplicate,
    onChangeData,
    onClickCancel,
    onSubmit,
    onCreatePortfolio,
  ] as const;
};

export default useExcavationCreate;
