import React from "react";
import styled from "styled-components";

interface Props {
  style?: React.CSSProperties;
  backgroundColor?: string;
  text?: string;
}

export const LabelDefault: React.FC<Props> = ({
  style,
  backgroundColor = "white",
  text = "",
}) => {
  return (
    <Container
      className="caption-11-rg"
      style={{ ...style, backgroundColor: backgroundColor }}
    >
      {text}
    </Container>
  );
};

const Container = styled.div`
  padding: 2px 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 2px;
  color: var(--white);
`;
