import { GPProps } from "../router/portfolio/investment-info/interface/InvestmentInfo.interface";
import {
  CountryProps,
  RelationshipProps,
  StockChangeEmptyProps,
  StockChangeListProps,
  StockChangeTypeProps,
  StockTypeProps,
} from "../router/portfolio-viewer-stock-change-history/interface/type";

import {
  addCommasToIntegerPart,
  getNewDate,
  roundToDecimals,
} from "./commonUtil";

export interface ShareHolderConfirmProps {
  success: boolean;
  shareHolder: ShareholderProps[];
  curStockPrice: number;
  totalStockNumber: number;
  faceValue: number;
  emptyFiled?: StockChangeEmptyProps[];
  errorData?: StockChangeErrorProps;
}

export interface StakeHolderDetailProps {
  id: number;
  type: number; // 1: 개인, 2: 법인, 3: 펀드
  uniqueNumber: string;
  name: string;
  country?: CountryProps;
  gp?: GPProps;
}

export interface StakeHolderProps {
  id: number;
  name: string;
  email?: string;
  phone?: string;
  managerName?: string;
  managerEmail?: string;
  managerPhone?: string;
  relationship: RelationshipProps;
  stakeHolder: StakeHolderDetailProps;

  isOwner: boolean;
  isInUse: boolean;
  isStockholder: boolean;
}

export interface ShareholderProps {
  stockNumber: number;
  pfStakeholder: StakeHolderProps;
  stockType: StockTypeProps;

  percentage?: number;
  id?: number;
  faceValue?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface StockChangeErrorProps {
  id: number;
  No: number;
  type:
    | "ERR_HAS_EMPTY_VALUE"
    | "ERR_NO_FOUNDING_CAPITAL"
    | "ERR_MULTI_FOUNDING_CAPITAL"
    | "ERR_NO_TARGET"
    | "ERR_NO_SELLER"
    | "ERR_NO_STOCK_TYPE"
    | "ERR_INSUFFICIENT_STOCKS"
    | "ERR_INSUFFICIENT_CHANGE_STOCKS"
    | "ERR_MISMATCHED_SHAREHOLDER_LIST";
  msg: string;
  errorData: {
    noTarget?: {
      buyer: StakeHolderProps;
      stockType: StockTypeProps;
    };
    noSeller?: {
      seller?: StakeHolderProps;
      stockType: StockTypeProps;
    };
    noStockType?: {
      buyer: StakeHolderProps;
      pfConvertTransaction: {
        stockNumber: number;
        stockType: StockTypeProps;
      };
    };
    insufficientStocks?: {
      buyer: StakeHolderProps;
      buyerStockNumber: number;
      stockType: StockTypeProps;
      sellerStockNumber: number;
    };
    insufficientChangeStocks?: {
      pfConvertTransaction: {
        stockNumber: number;
        stockType: StockTypeProps;
      };
      stockNumber: number;
    };
    mismatchedShareholderList?: {
      shareholderList: ShareholderProps[];
    };
  };
}

const isCheckedMakeShareholderList = (table: StockChangeListProps[]) => {
  const newEmptyData: StockChangeEmptyProps[] = [];
  table.forEach((item) => {
    const emptyList: string[] = [];
    if (
      !item.stockChangeType &&
      !item.buyer &&
      !item.stockType &&
      !item.stockNumber &&
      !item.stockPrice
    )
      return;

    if (!item.stockChangeType) emptyList.push("stockChangeType");
    if (item.stockChangeType?.id !== 14 && !item.buyer) emptyList.push("buyer");
    if (item.stockChangeType?.id !== 14 && !item.stockType)
      emptyList.push("stockType");
    if (item.stockChangeType?.id !== 14 && item.stockNumber === undefined)
      emptyList.push("stockNumber");
    if (item.stockChangeType?.id !== 14 && item.stockPrice === undefined)
      emptyList.push("stockPrice");
    if (item.stockChangeType?.id === 2 && !item.round) emptyList.push("round");
    if (item.stockChangeType?.id === 2 && !item.stockChangeSubType)
      emptyList.push("stockChangeSubType");
    if (item.stockChangeType?.id === 3 && !item.stockChangeSubType)
      emptyList.push("stockChangeSubType");
    if (item.stockChangeType?.id === 3 && !item.seller)
      emptyList.push("seller");
    if (item.stockChangeType?.id === 12 && !item.pfConvertTransaction)
      emptyList.push("pfConvertTransaction");
    if (item.stockChangeType?.id === 14 && !item.faceValue)
      emptyList.push("faceValue");

    if (
      item.stockChangeType?.type === 2 &&
      (!item.pfStockBatchInfo ||
        !item.pfStockBatchInfo.numerator ||
        !item.pfStockBatchInfo.denominator)
    )
      emptyList.push("pfStockBatchInfo");

    if (emptyList.length > 0) {
      newEmptyData.push({ id: item.id, errorKey: emptyList });
    }
  });

  return newEmptyData;
};

export const getStockTypeBatch = (
  table: StockChangeListProps[],
  date: string,
  type: StockChangeTypeProps,
  index: number
) => {
  const data: StockChangeListProps[] = [];
  for (let i = index; i < table.length; i += 1) {
    const targetDate = getNewDate(table[i].date);
    const compDate = getNewDate(date);

    if (
      table[i].stockChangeType?.id === type.id &&
      targetDate.getFullYear() === compDate.getFullYear() &&
      targetDate.getMonth() === compDate.getMonth() &&
      targetDate.getDate() === compDate.getDate()
    )
      data.push(table[i]);
  }

  return data;
};

export const getShareholderList = (
  table: StockChangeListProps[],
  faceValue: number
) => {
  let curStockPrice = 0;
  let isError = false;

  const newShareholderList: ShareholderProps[] = [];
  const errorData: StockChangeErrorProps[] = [];
  const checkEmptyRow = isCheckedMakeShareholderList(table);

  // if (checkEmptyRow.length > 0) {
  //   isError = true;
  //   errorData.push({
  //     id: 0,
  //     No: 0,
  //     type: "ERR_HAS_EMPTY_VALUE",
  //     msg: `빈값이 존재하는 줄이 있습니다.`,
  //     errorData: {},
  //   });
  // }

  console.log(table);
  if (table.filter((item) => item.stockChangeType?.id === 1).length === 0) {
    isError = true;
    errorData.push({
      id: 0,
      No: 0,
      type: "ERR_NO_FOUNDING_CAPITAL",
      msg: `설립자본금의 정보가 없습니다.`,
      errorData: {},
    });
  }

  for (let i = 0; i < table.length; i += 1) {
    const item = table[i];
    if (isError) break;

    if (
      item.stockChangeType &&
      [14].includes(item.stockChangeType.id) &&
      item.faceValue
    ) {
      faceValue = item.faceValue;
    }

    if (
      item.buyer &&
      item.stockChangeType &&
      item.stockType &&
      item.stockNumber
    ) {
      const target = newShareholderList.find(
        (shareholder) =>
          shareholder.pfStakeholder.id === item.buyer?.id &&
          shareholder.stockType.stockTypeId === item.stockType?.stockTypeId
      );

      if (item.stockChangeType.type === 1) {
        if ([1, 2, 10, 11].includes(item.stockChangeType.id)) {
          if (
            item.stockChangeType.id === 1 &&
            !(i === 0 || table[i - 1].stockChangeType?.id === 1)
          ) {
            isError = true;
            errorData.push({
              id: item.id,
              No: i + 1,
              type: "ERR_MULTI_FOUNDING_CAPITAL",
              msg: `No.${i + 1}에는 설립자본금이 존재할 수 없습니다.`,
              errorData: {},
            });

            break;
          }

          if ([1, 2].includes(item.stockChangeType.id)) {
            curStockPrice = item.stockPrice || 0;
          }

          if (target) {
            target.stockNumber += item.stockNumber || 0;
          } else {
            newShareholderList.push({
              stockNumber: item.stockNumber,
              stockType: item.stockType,
              pfStakeholder: item.buyer,
            });
          }
        } else if ([4, 13].includes(item.stockChangeType.id)) {
          if (!target) {
            isError = true;
            errorData.push({
              id: item.id,
              No: i + 1,
              type: "ERR_NO_TARGET",
              msg: `No.${i + 1}의 주주는 주주명부에 존재하지 않습니다.`,
              errorData: {
                noTarget: {
                  buyer: item.buyer,
                  stockType: item.stockType,
                },
              },
            });

            break;
          }
          target.stockNumber -= item.stockNumber;
        } else if ([3].includes(item.stockChangeType.id)) {
          // 구주거래
          const seller = newShareholderList.find(
            (shareholder) =>
              shareholder.pfStakeholder.id === item.seller?.id &&
              shareholder.stockType.stockTypeId === item.stockType?.stockTypeId
          );
          if (!seller) {
            isError = true;
            errorData.push({
              id: item.id,
              No: i + 1,
              type: "ERR_NO_SELLER",
              msg: `No.${i + 1}의 매도자 정보가 주주명부에 없습니다.`,
              errorData: {
                noSeller: {
                  seller: item.seller,
                  stockType: item.stockType,
                },
              },
            });

            break;
          }
          if (seller.stockNumber < item.stockNumber) {
            isError = true;
            errorData.push({
              id: item.id,
              No: i + 1,
              type: "ERR_INSUFFICIENT_STOCKS",
              msg: `No.${i + 1}의 매도자(${
                seller.pfStakeholder.name
              })의 보유 주식수(${addCommasToIntegerPart(
                seller.stockNumber
              )})보다 매수 주식수(${addCommasToIntegerPart(
                item.stockNumber
              )})가 더 큽니다.`,
              errorData: {
                insufficientStocks: {
                  buyer: item.buyer,
                  buyerStockNumber: item.stockNumber,
                  stockType: item.stockType,
                  sellerStockNumber: seller.stockNumber,
                },
              },
            });

            break;
          }
          seller.stockNumber -= item.stockNumber;
          if (target) {
            target.stockNumber += item.stockNumber;
          } else {
            newShareholderList.push({
              stockNumber: item.stockNumber,
              stockType: item.stockType,
              pfStakeholder: item.buyer,
            });
          }
        } else if (
          [12].includes(item.stockChangeType.id) &&
          item.pfConvertTransaction
        ) {
          // 주식전환
          const changeTarget = newShareholderList.find(
            (shareholder) =>
              shareholder.pfStakeholder.id === item.buyer?.id &&
              shareholder.stockType.stockTypeId ===
                item.pfConvertTransaction?.stockType.stockTypeId
          );

          if (!changeTarget) {
            isError = true;
            errorData.push({
              id: item.id,
              No: i + 1,
              type: "ERR_NO_STOCK_TYPE",
              msg: `No.${
                i + 1
              }에서 주주가 보유하는 주식 중 변경하려는 주식이 없습니다.`,
              errorData: {
                noStockType: {
                  buyer: item.buyer,
                  pfConvertTransaction: item.pfConvertTransaction,
                },
              },
            });

            break;
          }

          if (
            changeTarget &&
            changeTarget.stockNumber < item.pfConvertTransaction.stockNumber
          ) {
            isError = true;
            errorData.push({
              id: item.id,
              No: i + 1,
              type: "ERR_INSUFFICIENT_CHANGE_STOCKS",
              msg: `No.${i + 1}의 주주의 보유 주식수(${addCommasToIntegerPart(
                changeTarget.stockNumber
              )}) 보다 변경하려는 주식수(${addCommasToIntegerPart(
                item.pfConvertTransaction.stockNumber
              )})가 큽니다.`,
              errorData: {
                insufficientChangeStocks: {
                  pfConvertTransaction: item.pfConvertTransaction,
                  stockNumber: changeTarget.stockNumber,
                },
              },
            });

            break;
          }

          changeTarget.stockNumber -= item.pfConvertTransaction.stockNumber;

          if (target) {
            target.stockNumber += item.stockNumber;
          } else {
            newShareholderList.push({
              stockNumber: item.stockNumber,
              stockType: item.stockType,
              pfStakeholder: item.buyer,
            });
          }
        }
      } else if (item.stockChangeType.type === 2) {
        const typeData = getStockTypeBatch(
          table,
          item.date,
          item.stockChangeType,
          i
        );
        if (
          typeData.length !==
          newShareholderList.filter(
            (shareholder) => shareholder.stockNumber > 0
          ).length
        ) {
          isError = true;
          errorData.push({
            id: item.id,
            No: i + 1,
            type: "ERR_MISMATCHED_SHAREHOLDER_LIST",
            msg: `No.${
              i + 1
            }의 해당 타입은 해당 줄 이전까지의 주주명부 수 만큼 존재 해야 합니다.`,
            errorData: {
              mismatchedShareholderList: {
                shareholderList: newShareholderList,
              },
            },
          });

          break;
        }
        if (
          [5, 7].includes(item.stockChangeType.id) &&
          item.pfStockBatchInfo?.numerator &&
          item.pfStockBatchInfo.denominator
        ) {
          curStockPrice =
            (curStockPrice * item.pfStockBatchInfo.numerator) /
            (item.pfStockBatchInfo.numerator +
              item.pfStockBatchInfo.denominator);
          if (item.stockChangeType.id === 7) {
            faceValue =
              (faceValue * item.pfStockBatchInfo.numerator) /
              (item.pfStockBatchInfo.numerator +
                item.pfStockBatchInfo.denominator);
          }
        }

        if (
          [6, 8].includes(item.stockChangeType.id) &&
          item.pfStockBatchInfo?.numerator &&
          item.pfStockBatchInfo.denominator
        ) {
          curStockPrice =
            (curStockPrice * item.pfStockBatchInfo.numerator) /
            item.pfStockBatchInfo.denominator;
          if (item.stockChangeType.id === 8) {
            faceValue =
              (faceValue * item.pfStockBatchInfo.numerator) /
              item.pfStockBatchInfo.denominator;
          }
        }

        for (let j = 0; j < typeData.length; j += 1) {
          const batchItem = typeData[j];
          const batchTarget = newShareholderList.find(
            (shareholder) =>
              shareholder.pfStakeholder.id === batchItem.buyer?.id &&
              shareholder.stockType.stockTypeId ===
                batchItem.stockType?.stockTypeId
          );
          if (!batchTarget) {
            isError = true;
            errorData.push({
              id: item.id,
              No: i + 1,
              type: "ERR_NO_TARGET",
              msg: `No.${i + j + 1}의 주주는 주주명부에 존재하지 않습니다.`,
              errorData: {
                noTarget: {
                  buyer: item.buyer,
                  stockType: item.stockType,
                },
              },
            });

            break;
          }
          if ([5, 7].includes(batchItem.stockChangeType!.id)) {
            batchTarget.stockNumber += batchItem.stockNumber || 0;
          } else if ([6, 8].includes(batchItem.stockChangeType!.id)) {
            batchTarget.stockNumber -= batchItem.stockNumber || 0;
          }
        }
        i += typeData.length - 1;
      }
    }
  }

  const totalStockNumber = newShareholderList
    .map((item) => item.stockNumber)
    .reduce((prev, cur) => prev + cur, 0);
  newShareholderList.forEach((item) => {
    item.percentage = roundToDecimals(
      (item.stockNumber / totalStockNumber) * 100,
      3
    );
  });

  const result: ShareHolderConfirmProps = {
    success: !isError,
    shareHolder: newShareholderList.filter((item) => item.stockNumber > 0),
    curStockPrice,
    totalStockNumber,
    faceValue,
    emptyFiled: checkEmptyRow,
    errorData: errorData.length > 0 ? errorData[0] : undefined,
  };

  return result;
};
