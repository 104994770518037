import {
  Address,
  FileType,
  IndustryTech,
  PfIndustrialTech,
  TIPS,
  UserInfo,
} from "../../../../type/data";
import { PortfolioExtraInfo } from "../../info-modify/interface/PortfolioInfoModify.interface";
import { PortfolioPhase } from "../../portfolio-detail/interface/PortfolioNavigation.interface";

export enum BusinessInfoTab {
  SUMMARY = 1,
  DETAIL = 2,
  COMMENT = 3,
}

export interface PortfolioInfoResponse {
  acChannel?: {
    acChannelId: number;
    name: string;
  };
  portfolioId: number;
  companyName: string;
  ceoName: string;
  ceoEmail: string;
  description: string;
  ceoTel?: string;
  addr1?: string;
  addr2?: string;
  addr3?: string;
  addrDetail?: string;
  businessPlanFile?: {
    fileName: string;
    fileSize: number;
    fileType: string;
    key: string;
  };
  problem?: string;
  solution?: string;
  establishmentDay?: string;
  excavationDate: string;
  isTips: TIPS;
  businessNumber?: string;
  companyImgKey?: string;
  companyImgUrl?: string;
  thumbnailImgKey?: string;
  thumbnailImgUrl?: string;
  companyContactEmail?: string;
  companyContactName?: string;
  companyContactNumber?: string;
  pfIndustrialTech: {
    pfIndustrialTechId: number;
    isMain: boolean;
    acIndustrialTech: {
      acIndustrialTechId: number;
      name: string;
    };
  }[];
  pfPhase: PortfolioPhase;
  pfParticipant: ParticipantProps[];
  excavator?: {
    acUserId: number;
    name: string;
  };
  pfComment: PortfolioCommentProps[];
  pfExtraInfo?: PortfolioExtraInfo;
  pfIrProposal: IRProposal[];
}

export interface IRProposal {
  pfIrProposalId: number;
  fileName: string;
  type: number;
  fileType: string;
  s3Key: string;
  createdAt: string;
  updatedAt: string;
}

export interface ParticipantProps {
  pfParticipantId: number;
  screenRole: {
    screenRoleId: number;
    name: string;
  };
  acUser: {
    acUserId: number;
    name: string;
    acStructure: {
      acStructureId: number;
      name: string;
      structureIndex: number;
      parent: {
        acStructureId: number;
        name: string;
        structureIndex: number;
        parent?: number;
      };
    };
    headquarter: {
      acStructureId: number;
      name: string;
      structureIndex: number;
    };
    team: {
      acStructureId: number;
      name: string;
      structureIndex: number;
    };
  };
}

export interface PortfolioInfoProps {
  businessInfo: BusinessInfoProps;
  participantInfo: ParticipantInfoProps;
  startupInfo: StartupInfoProps;
}

export interface StartupInfoProps {
  companyName?: string;
  ceoName?: string;
  ceoTel?: string;
  ceoEmail?: string;
  companyAddress?: Address;
  businessNumber?: string;
  establishDate?: string;
}

export interface ParticipantInfoProps {
  channel?: string;
  teamName?: string;
  mainParticipant?: ParticipantProps;
  mainParticipantToText?: string;
  subParticipant?: ParticipantProps[];
  subParticipantToText?: string;
  excavator?: string;
  excavationDate?: string;
  tips?: TIPS;
}

export interface BusinessInfoProps {
  description?: string;
  industrialTechToText?: string;
  industrialTech?: PfIndustrialTech[];
  businessPlanFile?: FileType;

  summaryProposal?: IRProposal;
  detailProposal?: IRProposal;

  companyImgKey?: string;
  companyImgUrl?: string;
  thumbnailImgKey?: string;
  thumbnailImgUrl?: string;

  companyContactEmail?: string;
  companyContactName?: string;
  companyContactNumber?: string;

  extrainfoId?: number;
  problem?: string;
  solution?: string;
  businessModel?: string;
  team?: string;
  other?: string;
  comment?: PortfolioCommentProps[];
}

export interface PortfolioCommentProps {
  id: number;
  content: string;
  acUser: {
    acUserId: number;
    name: string;
    profileImgUrl?: string;
    profileImgKey?: string;
  };
  createdAt: string;
  updatedAt: string;
}
