import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as IconV } from "../../assets/image/icon_v.svg";

interface Props {
  steps: StepProps[];
  currentStep: number;
  onClickStep: (step: number) => void;
}

export interface StepProps {
  step: number;
  text: string;
  isValidate: boolean;
}

interface StepArrProps {
  type: "step" | "line";
  data?: StepProps;
}

export const StepComponent: React.FC<Props> = (props) => {
  const { steps, currentStep, onClickStep } = props;
  const [stepArr, setStepArr] = useState<StepArrProps[]>([]);

  const StepItem = (item: StepProps, isSelected: boolean, index: number) => {
    return (
      <StepContainer
        key={index}
        onClick={() => {
          onClickStep(item.step);
        }}
      >
        <StepIcon
          className={`heading-16-md ${isSelected ? "selected" : ""}`}
          isValidation={item.isValidate}
        >
          {item.isValidate ? <IconV /> : item.step}
          <div
            className={`caption-12-md step__text ${
              isSelected ? "text__selected" : ""
            }`}
          >
            {item.text}
          </div>
        </StepIcon>
      </StepContainer>
    );
  };

  useEffect(() => {
    const newData: StepArrProps[] = [];
    steps.forEach((item, index) => {
      newData.push({ type: "step", data: item });
      if (index !== steps.length - 1) {
        newData.push({ type: "line" });
      }
    });
    setStepArr(newData);
  }, [steps]);

  return (
    <Container>
      {stepArr.map((item, index) => {
        if (item.type === "step" && item.data) {
          return StepItem(item.data, currentStep === item.data.step, index);
        } else {
          return <Line key={index} />;
        }
      })}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Line = styled.div`
  width: 64px;
  height: 4px;
  min-width: 64px;
  min-height: 4px;
  max-width: 64px;
  max-height: 4px;
  background-color: var(--gray-100);
  margin-top: 12px;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const StepIcon = styled.div<{ isValidation: boolean }>`
  position: relative;
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isValidation ? "var(--purple-100)" : "var(--gray-200)"};
  color: var(--text-inactive);

  cursor: pointer;
  &.selected {
    background-color: var(--primary-purple);
    color: var(--white);
    path {
      fill: var(--white);
    }
  }

  .step__text {
    position: absolute;
    width: max-content;
    top: calc(100%);
    padding-top: 8px;
    left: 50%;
    transform: translateX(-50%);
    color: ${(props) =>
      props.isValidation ? "var(--purple-300)" : "var(--gray-400)"};
    &.text__selected {
      color: var(--primary-purple);
    }
  }
`;

const PortfolioName = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 14px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Flex1 = styled.div`
  flex: 1;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divder = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: var(--gray-200, #edeeef);
`;
