import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { Bounce } from "react-toastify";
import { DefaultButton } from "lattice_core";
import { ReactComponent as Plus } from "../../../../../../../assets/image/icon_plus.svg";
import { ReactComponent as PdfIcon } from "../../../../../../../assets/image/large_pdf.svg";

import { FileType } from "../../../../../../../type/data";
import { onDownload, showToast } from "../../../../../../../common/commonUtil";
import {
  deleteFile,
  uploadFile,
} from "../../../../../../../api/repository/common/CommonRepository";
import { rootStyle } from "../../../../../../../style/ThemeStyles";
import { showMobileToast } from "../../../../../../../2.0lattice/toast";

interface Props {
  portfolioId: number;
  isReadOnly?: boolean;
  disabled?: boolean;
  defaultData?: FileType;
  onChangeData?: (data?: FileType) => void;
}

export const CustomFile: React.FC<Props> = ({
  portfolioId,
  isReadOnly,
  disabled,
  defaultData,
  onChangeData,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<FileType | undefined>();

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const targetFile = e.target.files[0];
    if (targetFile.type !== "application/pdf") {
      showMobileToast("error", "PDF 파일만 업로드 가능합니다.");
    }

    // upload 파일
    const uploadResult = await uploadFile(
      targetFile,
      "riskManagement",
      portfolioId
    );

    // 성공시 기존파일 있으면 삭제
    if (uploadResult && file) {
      await deleteFile(file.key);
    }

    // onChnagefile 함수 태우기
    if (uploadResult) {
      setFile(uploadResult);
      onChangeData && onChangeData(uploadResult);
    }
    e.target.value = "";
  };

  const downloadFile = async (s3Key: string, fileName: string) => {
    try {
      await onDownload(s3Key, fileName);
      showToast({
        customElement: (
          <span className="heading-16-sb">파일 다운로드 완료 :)</span>
        ),
        backgroundColor: rootStyle.getPropertyValue("--green-400"),
        color: rootStyle.getPropertyValue("--text-02"),
        width: 274,
        height: 48,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const removeFile = async (s3Key: string) => {
    const result = await deleteFile(s3Key);
    if (result) {
      setFile(undefined);
      onChangeData && onChangeData(undefined);
    }
  };

  useEffect(() => {
    setFile(defaultData);
  }, [defaultData]);

  return (
    <Container className={`body-14-rg ${disabled ? "disabled" : ""}`}>
      {!file && (
        <InputContainer
          className={`${disabled || isReadOnly ? "disabled" : ""}`}
          style={{ color: "var(--text-04)" }}
          onClick={(e) => {
            e && e.stopPropagation();
            if (!isReadOnly && inputRef.current) {
              inputRef.current.click();
            }
          }}
        >
          {!isReadOnly && <Plus />}
          <span>{isReadOnly ? "해당없음" : "파일 업로드"}</span>
        </InputContainer>
      )}
      {file && (
        <InputContainer
          className={`${disabled || isReadOnly ? "disabled" : ""}`}
          style={{ justifyContent: "space-between" }}
        >
          <div
            style={{
              flexGrow: 1,
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PdfIcon
              width={20}
              height={20}
              style={{
                cursor: "pointer",
              }}
            />
            <span
              style={{
                maxWidth: isReadOnly ? undefined : 259,
                overflow: "hidden",
                marginLeft: 4,
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e && e.stopPropagation();
                downloadFile(file.key, file.fileName);
              }}
            >
              {file.fileName}
            </span>
          </div>
          {!isReadOnly && (
            <div>
              <DefaultButton
                className="caption-10-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor="var(--text-01)"
                borderColor="var(--gray-400)"
                onClick={(e) => {
                  e && e.stopPropagation();
                  if (inputRef.current) {
                    inputRef.current.click();
                  }
                }}
                text="교체하기"
              />
            </div>
          )}
        </InputContainer>
      )}
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        onChange={onChange}
        accept="application/pdf"
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 420px;
  padding: 8px 20px;

  display: flex;
  flex-direction: row;

  &.disabled {
    background-color: var(--gray-50);
  }
`;

const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid var(--gray-200);

  border-radius: 4px;
  transition: all 0.2s ease;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
  gap: 4px;

  &.disabled {
    padding: 6px 0px;
    cursor: default;
    border-color: transparent;
    :hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .arrow {
    path {
      fill: var(--icon-02);
    }
  }

  :hover {
    border: 1px solid var(--blue-200);
  }
  :focus {
    border: 1px solid var(--blue-200);
    outline: none;
  }
`;
