import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { ReactComponent as Arrow } from "../../../../assets/image/new_icon_arrow.svg";
import { SelectorItemProps } from "../../interface/ReportingDetail.interface";
interface Props {
  data: SelectorItemProps[];
  defaultItemIndex?: number;
  disabled?: boolean;
  transfromPixel: (value: number) => number;
  onSelectItem: (data: SelectorItemProps) => void;
}

const Selector = (props: Props) => {
  const { data, defaultItemIndex, disabled, transfromPixel, onSelectItem } =
    props;
  const isDefaultItemIndex = useRef<boolean>(false);
  const [selectListOpen, updateSelectListOpen] = useState<boolean>(false);
  const [selectedItem, updateSelectedItem] = useState<
    SelectorItemProps | undefined
  >(undefined);

  useEffect(() => {
    if (!isDefaultItemIndex.current && selectedItem !== undefined) {
      onSelectItem(selectedItem);
      updateSelectListOpen(false);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (defaultItemIndex !== undefined && defaultItemIndex !== -1) {
      isDefaultItemIndex.current = true;
      updateSelectedItem(data[defaultItemIndex]);
    }
  }, [defaultItemIndex]);

  return (
    <Container className="body-14-rg" transfromPixel={transfromPixel}>
      <SelectorContainer
        transfromPixel={transfromPixel}
        onClick={() => updateSelectListOpen((prev) => !prev)}
      >
        <div>{selectedItem ? selectedItem.text : "선택값 없음"}</div>
        <Arrow
          className={`arrow ${selectListOpen ? "open" : ""}`}
          width={transfromPixel(16)}
          height={transfromPixel(16)}
        />
      </SelectorContainer>
      {selectListOpen && (
        <SelectListContainer
          className="scroll__invisible"
          transfromPixel={transfromPixel}
        >
          {data.map((item) => {
            return (
              <SelectListItem
                className="body-14-rg"
                transfromPixel={transfromPixel}
                onClick={() => {
                  isDefaultItemIndex.current = false;
                  updateSelectedItem(item);
                }}
              >
                {item.text}
              </SelectListItem>
            );
          })}
        </SelectListContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  position: relative;
  width: ${(props) => props.transfromPixel(164)}px;
  height: ${(props) => props.transfromPixel(34)}px;
  cursor: default;
`;

const SelectorContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: ${transfromPixel(164)}px;
    height: ${transfromPixel(34)}px;
    padding: 0px ${transfromPixel(16)}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border: ${transfromPixel(1)}px solid #edeeef;
    border-radius: ${transfromPixel(4)}px;
    background-color: #ffffff;

    cursor: pointer;

    .arrow {
      transform: rotate(-90deg);
      /* transition: all 0.2s ease; */
      &.open {
        transform: rotate(90deg);
      }
    }

    :hover {
      /* border: ${transfromPixel(1)}px solid #a2c7ff; */
    }
  `
);

const SelectListContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;
    top: calc(100% + ${transfromPixel(10)}px);
    left: 0;
    width: 100%;
    height: fit-content;
    max-height: ${transfromPixel(36 * 6 + 16)}px;
    padding: ${transfromPixel(8)}px 0px;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.1);
    border-radius: ${transfromPixel(4)}px;

    background-color: #ffffff;
    z-index: 1001;
  `
);

const SelectListItem = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: 100%;
    height: ${transfromPixel(36)}px;
    padding: ${transfromPixel(7)}px ${transfromPixel(16)}px;
    display: flex;
    flex-direction: row;

    cursor: pointer;

    :hover {
      background-color: #f3f4f4;
    }
  `
);
export default Selector;
