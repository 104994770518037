import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BubbleCheck from "./BubbleCheck";
import { ReactComponent as Refresh } from "../../../../assets/image/refresh.svg";

interface Props {
  onClick?: () => void;
}

const RefreshChip: React.FC<Props> = (props) => {
  const { onClick } = props;
  return (
    <BubbleCheck onClick={onClick}>
      <Container>
        <Refresh />
      </Container>
    </BubbleCheck>
  );
};

const Container = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  &.is__active {
    path {
      stroke: var(--blue-400);
    }
  }
`;
export default RefreshChip;
