import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useResize from "../../2.0lattice/useResize";
import useBluecardLink from "./hooks/useBluecardLink";
import BluecardLinkCard from "./components/BluecardLinkCard";

import { ReactComponent as Logo } from "../../2.0lattice/assets/image/new_lattice_logo.svg";
import { ReactComponent as Top } from "../../assets/image/icon_top.svg";
import { ReactComponent as Mail } from "../../assets/image/icon_mail.svg";
import AuthorityForm from "./components/AuthorityForm";

import useDevice from "../../useDevice";

const BluecardLinkPage = () => {
  const [screenRate, transfromPixel] = useResize();
  const [
    isAuthority,
    rootRef,
    bluecardInfo,
    showToTopBtn,
    toGotop,
    sendAuthrityData,
  ] = useBluecardLink();

  const [isMobile] = useDevice();

  return (
    <Container className="scroll__invisible" ref={rootRef}>
      {!isMobile && (
        <LogoContainer transfromPixel={transfromPixel}>
          <Logo width={transfromPixel(82)} height={transfromPixel(20)} />
        </LogoContainer>
      )}
      {isAuthority ? (
        <BluecardPreview>
          {bluecardInfo?.info.map((item) => {
            return (
              <BluecardLinkCard
                isMobile={isMobile}
                transfromPixel={transfromPixel}
                bluecardInfo={item}
              />
            );
          })}
        </BluecardPreview>
      ) : (
        <AuthrityContainer>
          <AuthorityForm
            sendAuthrityData={sendAuthrityData}
            transfromPixel={transfromPixel}
          />
        </AuthrityContainer>
      )}
      {isAuthority && showToTopBtn && (
        <ToGoTopContainer onClick={toGotop}>
          <Top width={isMobile ? 18 : 24} height={isMobile ? 18 : 24} />
        </ToGoTopContainer>
      )}
      {/* {isAuthority && (
        <SendEmailContainer>
          <Mail width={isMobile ? 18 : 24} height={isMobile ? 18 : 24} />
        </SendEmailContainer>
      )} */}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  overflow: scroll;
  background-color: var(--background-page);
`;

const LogoContainer = styled.div<{ transfromPixel: (value: number) => number }>`
  position: fixed;
  width: fit-content;
  height: fit-content;

  left: 40px;
  top: 40px;
`;

const BluecardPreview = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 40px 0;
  gap: 20px;

  @media only screen and (max-width: 765px) {
    padding: 0;
    gap: 0px;
  }
`;

const AuthrityContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ToGoTopContainer = styled.div`
  position: fixed;

  right: 60px;
  /* bottom: 140px; */
  bottom: 60px;

  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-contents01);
  filter: drop-shadow(2px 2px 10px rgba(100, 103, 109, 0.3));

  transition: all 0.2s ease;

  cursor: pointer;

  @media only screen and (max-width: 765px) {
    width: 45px;
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    min-height: 45px;
    max-height: 45px;

    right: 24px;
    bottom: calc(24px + 45px + 12px);
  }
`;

const SendEmailContainer = styled.div`
  position: fixed;

  right: 60px;
  bottom: 60px;

  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-contents01);
  filter: drop-shadow(2px 2px 10px rgba(100, 103, 109, 0.3));

  transition: all 0.2s ease;

  cursor: pointer;

  @media only screen and (max-width: 765px) {
    width: 45px;
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    min-height: 45px;
    max-height: 45px;

    right: 24px;
    bottom: 24px;
  }
`;

export default BluecardLinkPage;
