import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as True } from "../../../../../assets/image/icon_o.svg";
import { ReactComponent as False } from "../../../../../assets/image/icon_x.svg";

interface Props {
  isReadOnly?: boolean;
  defaultData?: boolean;
  text1?: string;
  text2?: string;
  style?: React.CSSProperties;
  onChangeData?: (data: boolean) => void;
}

export const OXCheckBox: React.FC<Props> = (props) => {
  const { isReadOnly, text1, text2, style, defaultData, onChangeData } = props;

  const [oxValue, setOXValue] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    console.log(defaultData);
    setOXValue(defaultData);
  }, [defaultData]);

  return (
    <Container className="heading-14-sb" style={style}>
      <BoxContainer
        className={
          oxValue === undefined || oxValue === null
            ? ""
            : oxValue
            ? "selected"
            : ""
        }
        style={{ padding: text1 && text2 ? "4px 16px" : "10px" }}
        onClick={() => {
          setOXValue(true);
          onChangeData && onChangeData(true);
        }}
      >
        {text1 && text2 ? text1 : <True className="icon" />}
      </BoxContainer>
      <BoxContainer
        className={
          oxValue === undefined || oxValue === null
            ? ""
            : oxValue
            ? ""
            : "selected"
        }
        style={{ padding: text1 && text2 ? "4px 16px" : "10px" }}
        onClick={() => {
          setOXValue(false);
          onChangeData && onChangeData(false);
        }}
      >
        {text1 && text2 ? text2 : <False className="icon" />}
      </BoxContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
`;

const BoxContainer = styled.div`
  .icon {
    width: 18px;
    height: 18px;
  }

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  background: var(--background-contents-01, #fff);
  color: var(--gray-600);

  &.selected {
    color: white;
    background: var(--primary-blue, #173aff);
    border-color: var(--primary-blue, #173aff);
    circle {
      stroke: white;
    }
    path {
      fill: white;
      stroke: white;
    }
  }
`;
