import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../assets/image/modal_close.svg";
import { DefaultButton } from "lattice_core";
import CheckedText from "../check-box/CheckedText";
import { PortfolioTargetData } from "../../type/data";
import { TextareaBox } from "../input-box/TextareaBox";
import { dropPortfolio } from "../../api/repository/portfolio/PortfolioRepository";
import { getDropReasonByAccelerator } from "../../api/repository/accelerator/AcceleratorRepository";

interface Props {
  targetData: PortfolioTargetData;
  close: () => void;
  callback: () => void;
}

export const PortfolioDropModal: React.FC<Props> = (props) => {
  const { targetData, close, callback } = props;
  const { companyName, portfolioId, dealFlowId } = targetData;

  const [dropReason, setDropReason] = useState<string[]>([]);
  const [selectedReason, updateSelectedReason] = useState<string[]>([]);
  const [otherReason, updateOtherReason] = useState<string>("");

  const getDropReason = async () => {
    const result = await getDropReasonByAccelerator();
    if (result) {
      setDropReason([...result]);
    }
  };
  useEffect(() => {
    getDropReason();
  }, []);

  if (dropReason.length === 0) return null;
  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          드롭
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        <FlexColumn>
          <div className="heading-14-md">{`'${companyName}' 기업을 드롭하는 사유를 선택해 주세요.`}</div>
          <FlexColumn style={{ gap: 12, marginTop: 24 }}>
            {dropReason.map((item, key) => {
              return (
                <CheckedText
                  key={key}
                  isChecked={selectedReason.includes(item)}
                  onClick={() => {
                    const findindex = selectedReason.findIndex(
                      (selected) => selected === item
                    );
                    console.log(item);
                    console.log(findindex);
                    if (findindex !== -1) {
                      selectedReason.splice(findindex, 1);
                      console.log(selectedReason);

                      updateSelectedReason([...selectedReason]);
                    } else {
                      updateSelectedReason([...selectedReason, item]);
                    }
                  }}
                >
                  {item}
                </CheckedText>
              );
            })}
            <FlexColumn style={{ width: "100%", gap: 12 }}>
              <CheckedText isChecked={otherReason.length !== 0}>
                {"기타"}
              </CheckedText>
              <TextareaBox
                maxLength={100}
                placeholder="기타 사유가 있다면 작성해 주세요."
                onChangeDataRealTime={(data) => updateOtherReason(data)}
              />
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={() => {
              close();
            }}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            disabled={selectedReason.length === 0 && otherReason.length === 0}
            text={"드롭"}
            onClick={async () => {
              const comment =
                selectedReason.join("&") +
                (otherReason.length > 0 ? `&${otherReason}` : "");
              await dropPortfolio(portfolioId, comment, dealFlowId);
              await callback();
              await close();
            }}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 90vw;
  max-width: 428px;

  max-height: 95vh;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  padding: 20px 36px;
  gap: 32px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
