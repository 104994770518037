import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { addCommasToIntegerPart } from "../../../common/commonUtil";

interface Props {
  title: string;
  value?: string | number;
  currency?: string;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const FinancialStatementInput: React.FC<Props> = (props) => {
  const { title, value = "", currency = "KRW", onChangeValue } = props;
  const [isFocus, updateIsFocus] = useState<boolean>(false);

  const getValue = () => {
    return isFocus ? `${value}` : addCommasToIntegerPart(value);
  };
  return (
    <Container>
      <span className="heading-14-md title">{title}</span>
      <FlexRow className="body-14-rg" style={{ gap: 8 }}>
        <span>{currency}</span>
        <CurrencyInput
          type="text"
          value={getValue()}
          onChange={onChangeValue}
          onFocus={() => {
            updateIsFocus(true);
          }}
          onBlur={() => updateIsFocus(false)}
        />
      </FlexRow>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: var(--text-01, #1f2022);

  .title {
    color: var(--text-04, #7e8086);
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CurrencyInput = styled.input`
  width: 140px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  padding: 9px 12px;
  :focus {
    outline: none;
    border: 1px solid var(--blue-400);
  }
`;
