import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  closeRiskmanagement,
  getRMBasic,
  getRiskmanagementList,
  getRiskmanagementRange,
  partiallyOpenRiskmanagement,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import {
  RMTECountInfoProps,
  RMTEFilterDataProps,
  RMTEFilterType,
  RMTEOpenType,
  RMTEStructureProps,
  RiskManagementTotalEvaluation,
  RiskmanagementRangeProps,
} from "../interface/RMTE.interface";
import {
  getMyInfo,
  getNewDate,
  transformObjectToBase64,
} from "../../../../common/commonUtil";
import { RollingSelectorDataProps } from "../../../../2.0lattice/components/rolling-selector/RollingSelector";
import { RMEDDictionary } from "../../evaluation-detail/interface/RMED.interface";
import { Bounce } from "react-toastify";
import {
  SubmitStatusProps,
  exportExcelByRiskmanagementSubmitStatus,
} from "../SubmitStatusTableService";
import {
  getTotalEvaluationHistory,
  setTotalEvaluationHistory,
} from "../../../../common/pageHistoryUtil";
import { TotalEvaluationHistory } from "../../../../type/data";
import config from "../../../../config";
import { showDeskToast, showMobileToast } from "../../../../2.0lattice/toast";
import { useMediaQuery } from "react-responsive";

const useRiskManagementTotalEvaluation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  const rootRef = useRef<HTMLDivElement>(null);
  const riskmanagementRange = useRef<RiskmanagementRangeProps | undefined>(
    undefined
  );
  const basicDictionary = useRef<RMEDDictionary | undefined>(undefined);
  const allRiskManagementData = useRef<RiskManagementTotalEvaluation[]>([]);

  const myInfo = getMyInfo();
  const [keyword, updateKeyword] = useState<string>("");

  const closing = useRef<boolean>(false);
  const [paused, updatePaused] = useState<boolean>(false);

  const [yearSelectorList, updateYearSelectorList] = useState<
    RollingSelectorDataProps[]
  >([]);

  const [selectedYear, updateSelectedYear] = useState<number | undefined>(
    undefined
  );
  const [selectedQuarter, updateSelectedQuarter] = useState<number | undefined>(
    undefined
  );

  const [filterData, setFilterData] = useState<RMTEFilterDataProps | undefined>(
    undefined
  );

  const [teamData, setTeamData] = useState<RMTEStructureProps[]>([]);
  const [listData, setListData] = useState<RiskManagementTotalEvaluation[]>([]);
  const [notSubmittedData, setNotSubmittedData] = useState<
    RiskManagementTotalEvaluation[]
  >([]);
  const [checkedData, updateCheckedData] = useState<
    RiskManagementTotalEvaluation[]
  >([]);
  const [showCheckedDataList, updateShowCheckedDataList] =
    useState<boolean>(false);
  const [countInfo, updateCountInfo] = useState<RMTECountInfoProps>({
    totalCount: 0,
    submitedCount: 0,
    openPortfolioCount: 0,
  });
  const [openType, setOpenType] = useState<RMTEOpenType>("closed");

  const onClickShowCheckedDataList = () =>
    updateShowCheckedDataList((prev) => !prev);
  const onChangeKeyword = (keyword: string) => {
    updateKeyword(keyword);
    onChangeFilterData("keyword", keyword);
  };
  const onChangeYear = (year: number) => {
    if (!riskmanagementRange.current) return;
    const startValue =
      riskmanagementRange.current.start.year * 10 +
      riskmanagementRange.current.start.quarterNum;
    const endValue =
      riskmanagementRange.current.end.year * 10 +
      riskmanagementRange.current.end.quarterNum;
    const curValue = year * 10 + (selectedQuarter || 0);

    updateSelectedYear(year);
    onChangeFilterData("init", undefined);

    if (startValue > curValue) {
      updateSelectedQuarter(riskmanagementRange.current.start.quarterNum);
    } else if (endValue < curValue) {
      updateSelectedQuarter(riskmanagementRange.current.end.quarterNum);
    }
  };
  const onChangeQuarter = (quarter: number) => {
    console.log("여기도 타나??");
    if (riskmanagementRange.current === undefined || selectedYear === undefined)
      return;

    const targetDate = getNewDate(
      `${selectedYear}-${quarter === 2 ? "06-30" : "12-31"}`
    );
    const startDate = getNewDate(
      `${riskmanagementRange.current.start.year}-${
        riskmanagementRange.current.start.quarterNum === 2 ? "06-30" : "12-31"
      }`
    );
    const endDate = getNewDate(
      `${riskmanagementRange.current.end.year}-${
        riskmanagementRange.current.end.quarterNum === 2 ? "06-30" : "12-31"
      }`
    );

    if (
      targetDate.getTime() >= startDate.getTime() &&
      targetDate.getTime() <= endDate.getTime()
    ) {
      updateSelectedQuarter(quarter);
      onChangeFilterData("init", undefined);
    } else {
      isMobile
        ? showMobileToast("error", "선택할 수 없는 반기입니다.")
        : showDeskToast("error", "선택할 수 없는 반기입니다.");
    }
  };

  const goToMinutePreview = () => {
    const popupWidth = screen.width;
    const popupHeight = screen.height;
    const domainUrl = config.clientUrl || "";

    if (
      myInfo.authorityId === 2 &&
      selectedYear !== undefined &&
      selectedQuarter !== undefined
    ) {
      const url = `${domainUrl}risk-management-minute-preview?year=${selectedYear}&quarterNum=${selectedQuarter}`;
      const specs = `width=${popupWidth},height=${popupHeight},left=0,top=0,toolbar=no,menubar=no`;
      window.open(url, "_blank", specs);
    }
  };

  const goNextDetail = (item: RiskManagementTotalEvaluation) => {
    if ([4, 5].includes(item.status)) {
      const code = transformObjectToBase64({
        riskManagementId: item.id,
      });
      navigate(`/quarter-management/risk-management/detail/${code}/viewer`);
    }
  };

  const excelDownloadSubmitStauts = () => {
    const excelData = allRiskManagementData.current.map(
      (item, index): SubmitStatusProps => {
        const submitStatus =
          item.status === 1
            ? "보고대기"
            : item.status === 2
            ? "평가대기"
            : item.status === 3
            ? "평가중"
            : item.status === 4
            ? "제출완료"
            : "확정완료";
        const riskStatus = [1, 2, 3].includes(item.status)
          ? "-"
          : item.riskStatus.name;
        const riskIssue = [1, 2, 3].includes(item.status)
          ? "-"
          : item.riskIssueList.map((issue) => issue.riskIssue.name).join(", ");
        return {
          portfolioId: item.portfolio.portfolioId,
          companyName: item.portfolio.companyName,
          participants: item.portfolio.pfParticipant
            .map((user) => user.acUser.name)
            .join(", "),
          submitStatus: submitStatus,
          riskStatus: riskStatus,
          riskIssue: riskIssue,
        };
      }
    );
    exportExcelByRiskmanagementSubmitStatus(excelData);
  };

  const onClickCloseRiskManagement = async () => {
    if (closing.current) return;
    if (myInfo.authorityId !== 2) return;
    if (selectedYear === undefined || selectedQuarter === undefined) return;

    closing.current = true;
    const result = await closeRiskmanagement(selectedYear, selectedQuarter);
    closing.current = false;
    if (result) {
      getRiskManagementTotalEvaluationList();
      isMobile
        ? showMobileToast("success", "리스크 관리 위원회가 종료되었습니다.")
        : showDeskToast("success", "리스크 관리 위원회가 종료되었습니다.");
    }
  };

  const onClickPartiallydOpen = async () => {
    if (closing.current) return;
    if (myInfo.authorityId !== 2) return;
    if (selectedYear === undefined || selectedQuarter === undefined) return;
    if (checkedData.length === 0) return;

    const message = `${checkedData[0].portfolio.companyName} 외 ${
      checkedData.length - 1
    }개의 포트폴리오 리스크평가를 다시 열었습니다.`;

    closing.current = true;
    const result = await partiallyOpenRiskmanagement(
      selectedYear,
      selectedQuarter,
      checkedData.map((item) => item.id)
    );
    closing.current = false;
    if (result) {
      getRiskManagementTotalEvaluationList();
      onInitPortfolio();
      isMobile
        ? showMobileToast("success", message)
        : showDeskToast("success", message);
    }
  };

  const onClickExistNotSubmit = () => {
    isMobile
      ? showMobileToast("error", "리스크 평가 제출이 모두 완료되지 않았습니다.")
      : showDeskToast("error", "리스크 평가 제출이 모두 완료되지 않았습니다.");
  };

  const onCheckedPortfolio = (data: RiskManagementTotalEvaluation) => {
    const checkedIndex = checkedData.findIndex((item) => item.id === data.id);
    if (checkedIndex !== -1) {
      checkedData.splice(checkedIndex, 1);
      updateCheckedData([...checkedData]);
    } else {
      updateCheckedData((prev) => [...prev, data]);
    }
  };
  const onInitPortfolio = () => {
    updateCheckedData([]);
  };

  const init = async () => {
    try {
      riskmanagementRange.current = await getRiskmanagementRange();
      const dictionaryResult = await getRMBasic();
      if (!riskmanagementRange.current || !dictionaryResult) return;

      const yearArray = Array.from(
        {
          length:
            riskmanagementRange.current.end.year -
            riskmanagementRange.current.start.year +
            1,
        },
        (v, i) => {
          const year = riskmanagementRange.current
            ? riskmanagementRange.current.start.year + i
            : 0;
          return year;
        }
      );
      const rangeData = yearArray.map(
        (value): RollingSelectorDataProps => ({
          id: value,
          text: `${value}년`,
          data: value,
        })
      );
      basicDictionary.current = dictionaryResult;
      updateYearSelectorList(rangeData);
      updateSelectedYear(riskmanagementRange.current.end.year);
      updateSelectedQuarter(riskmanagementRange.current.end.quarterNum);
    } catch (error) {
      console.log(error);
    }
  };

  const getRiskManagementTotalEvaluationList = async () => {
    const pushData = (
      structures: RMTEStructureProps[],
      data: RMTEStructureProps
    ) => {
      const structureIndex = data.structureIndex;
      const isHeadquater = `${structureIndex}`.length === 1;
      if (isHeadquater) {
        const targetHeadquarter = structures.find(
          (item) => item.structureIndex === structureIndex
        );
        !targetHeadquarter && structures.push({ ...data, children: [] });
      } else {
        const headquaterIndex = Math.floor(structureIndex / 10);
        const targetHeadquarter = structures.find(
          (item) => item.structureIndex === headquaterIndex
        );
        if (targetHeadquarter) {
          if (targetHeadquarter.children) {
            const targetTeam = targetHeadquarter.children.find(
              (item) => item.structureIndex === structureIndex
            );
            !targetTeam && targetHeadquarter.children.push({ ...data });
          } else {
            targetHeadquarter.children = [{ ...data }];
          }
        }
      }
    };

    if (!selectedYear || !selectedQuarter) return;

    const reportListData = await getRiskmanagementList(
      selectedYear,
      selectedQuarter
    );
    const structures: RMTEStructureProps[] = [];
    const structuresExpand: RMTEStructureProps[] = [];

    if (reportListData) {
      const submitData = reportListData
        .filter((item) => [4, 5].includes(item.status))
        .sort((a, b) => {
          return (
            getNewDate(b.updatedAt).getTime() -
            getNewDate(a.updatedAt).getTime()
          );
        });
      const notSubmitData = reportListData.filter((item) =>
        [1, 2, 3].includes(item.status)
      );
      submitData.forEach((item) => {
        const participant = item.portfolio.pfParticipant.find(
          (item) => item.screenRole.screenRoleId === 1
        );

        if (participant) {
          if (participant.acUser.headquarter) {
            pushData(structures, participant.acUser.headquarter);
          }
          if (participant.acUser.team) {
            pushData(structures, participant.acUser.team);
          }
        }
      });
      structures.forEach(
        (item) =>
          item.children &&
          item.children.sort((a, b) => a.structureIndex - b.structureIndex)
      );
      structures.sort((a, b) => a.structureIndex - b.structureIndex);
      structures.forEach((item) => {
        structuresExpand.push(item);
        item.children &&
          item.children.forEach((child) => {
            structuresExpand.push(child);
          });
      });

      const isCurrentQuarter =
        selectedYear === riskmanagementRange.current?.end.year &&
        selectedQuarter === riskmanagementRange.current.end.quarterNum;
      const notFonfirmFile = reportListData.filter((item) =>
        [1, 2, 3, 4].includes(item.status)
      );
      const confirmFile = reportListData.filter((item) => item.status === 5);

      setOpenType(
        !isCurrentQuarter
          ? "closed"
          : notFonfirmFile.length > 0 && confirmFile.length === 0
          ? "open"
          : notFonfirmFile.length > 0 && confirmFile.length > 0
          ? "partiallydOpen"
          : isCurrentQuarter &&
            notFonfirmFile.length === 0 &&
            confirmFile.length > 0
          ? "openAbleClosed"
          : "closed"
      );
      setListData(submitData);
      setNotSubmittedData(notSubmitData);
      setTeamData([...structuresExpand]);
      updateCountInfo({
        totalCount: reportListData.length,
        submitedCount: reportListData.filter((item) => item.status === 4)
          .length,
        openPortfolioCount: reportListData.filter((item) => item.status === 4)
          .length,
      });
      allRiskManagementData.current = reportListData;
    }
  };

  const onChangeFilterData = (type: RMTEFilterType, data: any) => {
    const filter = filterData
      ? filterData
      : {
          issue: [],
          riskStatus: [],
          structure: [],
          keyword: "",
        };

    switch (type) {
      case "issue":
        setFilterData({ ...filter, issue: data });
        break;
      case "riskStatus":
        setFilterData({ ...filter, riskStatus: data });
        break;
      case "structure":
        setFilterData({
          ...filter,
          structure: data,
        });
        break;
      case "keyword":
        setFilterData({
          issue: [],
          riskStatus: [],
          structure: [],
          keyword: data,
        });
        break;
      case "init":
        setFilterData({
          issue: [],
          riskStatus: [],
          structure: [],
          keyword: "",
        });
        break;
    }
  };

  const getFilterPortfolio = () => {
    const data = listData
      .filter((item) => {
        if (filterData && filterData.keyword.length > 0) {
          const upperCompanyName = item.portfolio.companyName.toUpperCase();
          const upperDescription = item.portfolio.description.toUpperCase();
          return (
            upperCompanyName.includes(filterData.keyword.toUpperCase()) ||
            upperDescription.includes(filterData.keyword.toUpperCase())
          );
        }
        return true;
      })
      .filter((item) => {
        if (
          filterData &&
          filterData.issue.length > 0 &&
          filterData.issue.length !== basicDictionary.current?.issue.length
        ) {
          const itemIssue = item.riskIssueList.map((risk) => risk.riskIssue.id);
          return (
            itemIssue.filter((issue) => filterData.issue.includes(issue))
              .length > 0
          );
        }
        return true;
      })
      .filter((item) => {
        if (
          filterData &&
          filterData.riskStatus.length > 0 &&
          filterData.riskStatus.length !==
            basicDictionary.current?.status.length
        ) {
          return filterData.riskStatus.includes(item.riskStatus.id);
        }
        return true;
      })
      .filter((item) => {
        if (filterData && filterData.structure.length > 0) {
          const participant = item.portfolio.pfParticipant.find(
            (item) => item.screenRole.screenRoleId === 1
          );

          return (
            (participant?.acUser.headquarter &&
              filterData.structure.includes(
                participant.acUser.headquarter.acStructureId
              )) ||
            (participant?.acUser.team &&
              filterData.structure.includes(
                participant.acUser.team.acStructureId
              ))
          );
        }
        return true;
      });

    return data;
  };

  const onScrollHandler = (e: any) => {
    setTotalEvaluationHistory({
      search: {
        scrollTop: e.target.scrollTop,
      },
    });
  };

  useEffect(() => {
    const historyData: TotalEvaluationHistory = {
      search: {},
    };

    if (keyword) {
      historyData.search = { ...historyData.search, keyword: keyword };
    }

    if (selectedYear) {
      historyData.search = { ...historyData.search, year: selectedYear };
    }
    if (selectedQuarter) {
      historyData.search = { ...historyData.search, quarter: selectedQuarter };
    }
    if (filterData) {
      historyData.search = {
        ...historyData.search,
        riskIssue: filterData.issue,
        riskStatus: filterData.riskStatus,
        structure: filterData.structure,
      };
    }
    setTotalEvaluationHistory(historyData);
  }, [keyword, selectedYear, selectedQuarter, filterData]);

  useEffect(() => {
    const totalEvaluationHistory = getTotalEvaluationHistory();
    if (!totalEvaluationHistory) return;

    const history = totalEvaluationHistory.search;
    if (!history) return;

    if (rootRef.current && history.scrollTop) {
      rootRef.current.scrollTo({
        top: history.scrollTop,
      });
    }

    if (history.keyword) {
      updateKeyword(history.keyword);
    }

    if (history.year) {
      updateSelectedYear(history.year);
    }

    console.log(history);
    setFilterData({
      keyword: history.keyword || "",
      issue: history.riskIssue || [],
      riskStatus: history.riskStatus || [],
      structure: history.structure || [],
    });
  }, [listData]);

  useEffect(() => {
    checkedData.length === 0 && updateShowCheckedDataList(false);
  }, [checkedData]);

  useEffect(() => {
    init();
    if (rootRef.current) {
      rootRef.current.addEventListener("scroll", onScrollHandler);
    }
    return () => {
      if (rootRef.current) {
        rootRef.current.removeEventListener("scroll", onScrollHandler);
      }
    };
  }, []);

  useEffect(() => {
    getRiskManagementTotalEvaluationList();
    console.log(`${selectedYear} - ${selectedQuarter}`);
  }, [selectedYear, selectedQuarter]);

  return [
    rootRef,
    openType,
    myInfo,
    keyword,
    paused,
    listData,
    riskmanagementRange,
    yearSelectorList,
    selectedYear,
    selectedQuarter,
    basicDictionary.current,
    teamData,
    filterData,
    checkedData,
    showCheckedDataList,
    notSubmittedData,
    countInfo,
    onChangeYear,
    onChangeQuarter,
    getFilterPortfolio,
    onChangeFilterData,
    onChangeKeyword,
    goNextDetail,
    onCheckedPortfolio,
    onClickShowCheckedDataList,
    onInitPortfolio,
    goToMinutePreview,
    onClickCloseRiskManagement,
    onClickPartiallydOpen,
    excelDownloadSubmitStauts,
    onClickExistNotSubmit,
  ] as const;
};

export default useRiskManagementTotalEvaluation;
