import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import styled from "styled-components";
import { GridHeaderProps } from "./StockHolderGrid";
import StockHolderGridDataRow from "./StockHolderGridDataRow";
import { useScroll } from "./hook/useScroll";
import StockHolderGridHeader from "./StockHolderGridHeader";

interface Props {
  headers: GridHeaderProps[];
  allData: any[];
  bodyHeight: number;
  headerHeight: number;
  rowHeight: number;
  scrollAble: boolean;
}

export interface SelectedCell {
  index: number;
  key: string;
}

export interface CellPosition {
  rowIndex: number;
  colIndex: number;
}

const StockHolderGridData: React.FC<Props> = ({
  headers,
  allData,
  bodyHeight,
  headerHeight,
  rowHeight,
  scrollAble,
}) => {
  const [scrollTop, ref, scrollStart, scrollEnd] = useScroll();

  const totalItemCount = allData.length;
  const itemHeight = rowHeight;
  const totalHeight = totalItemCount * itemHeight;
  const containerHeight = bodyHeight - 24;
  const nodePadding = 5;

  const startIndex = Math.max(
    Math.floor(scrollTop / itemHeight) - nodePadding,
    0
  );

  const visibleNodeCount =
    Math.floor(containerHeight / itemHeight) + 2 * nodePadding;

  const offsetY = startIndex * itemHeight;

  const renderVisibleChildren = () => {
    const data: any[] = [];
    const start = Math.max(0, startIndex);
    const end = Math.min(startIndex + visibleNodeCount, allData.length - 1);
    if (start <= end) {
      for (let i = start; i <= end; i += 1) {
        data.push(allData[i]);
      }
    }
    console.log(data);
    return data.map((item, idx) => {
      console.log(startIndex);
      return (
        <StockHolderGridDataRow
          key={idx + startIndex}
          index={idx + startIndex}
          rowHeight={rowHeight}
          headers={headers}
          rowData={allData.find(
            (dataItem: any) => dataItem.id === idx + startIndex
          )}
        />
      );
    });
  };

  useEffect(() => {
    console.log(allData);
  }, [allData]);

  useEffect(() => {
    if (ref.current) {
      ref.current.add;
    }
  }, []);
  return (
    <Warpper>
      <Container
        ref={ref}
        id="viewport_container"
        style={{
          width: "100%",
          height: containerHeight,
          display: "flex",
          flexDirection: "column",
          overflowY: scrollAble ? "scroll" : "hidden",
          overflowX: "visible",
        }}
      >
        <StockHolderGridHeader headers={headers} headerHeight={headerHeight} />
        <div
          id="virtual_container"
          style={{
            height: totalHeight,
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              transform: `translateY(${offsetY}px)`,
            }}
          >
            {renderVisibleChildren()}
          </div>
        </div>
      </Container>
    </Warpper>
  );
};

const Warpper = styled.div`
  width: 100%;
  position: relative;
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
export default StockHolderGridData;
