import React from "react";
import styled from "styled-components";
import { getHMOnlyNum } from "../../../../../../common/commonUtil";

import { ChatUserProps } from "../chatingRepository";
import { ChatingSpeachBubbleProps } from "../useChating";
import Avatar from "./Avatar";

interface Props {
  msg: ChatingSpeachBubbleProps;
  transfromPixel: (value: number) => number;
}

const SBUserMe: React.FC<Props> = (props) => {
  const getText = (
    isFirst: boolean,
    isLast: boolean,
    createAt: string,
    text: string,
    user?: ChatUserProps,
    boldText?: string[]
  ) => {
    let resultText = text;

    if (!user) return null;

    const color = {
      background: user.type === "P" ? "#E8F0FF" : "#ECE9FF",
    };

    const boldTextIndexArr = boldText?.map((item) => {
      resultText = resultText.replace(item, "/%s/");
      return item;
    });
    if (boldTextIndexArr) {
      const splitData = resultText.split("/");
      let boldIndex = -1;
      return (
        <div className="bubble__item__wrapper">
          {isFirst && <Avatar transfromPixel={props.transfromPixel} />}

          <div className="bubble__item__container">
            {isFirst && <span>{user.name}</span>}
            <div className="bubble__item body-14-rg" style={color}>
              {splitData.map((item) => {
                if (
                  item === "%s" &&
                  boldTextIndexArr &&
                  boldTextIndexArr[boldIndex + 1]
                ) {
                  boldIndex += 1;
                  return (
                    <span style={{ fontWeight: "bold" }}>
                      {boldTextIndexArr[boldIndex]}
                    </span>
                  );
                }
                return <span>{item}</span>;
              })}
            </div>
          </div>
          {isLast && <span className="bubble__time">{createAt}</span>}
        </div>
      );
    }
    return (
      <div className="bubble__item__wrapper">
        <Avatar visible={isFirst} transfromPixel={props.transfromPixel} />
        <div className="bubble__item__container">
          {isFirst && (
            <span className="bubble__item__user caption-12-sb">
              {user.name}
            </span>
          )}
          <p className="bubble__item body-14-rg" style={color}>
            {text}
          </p>
        </div>
        {isLast && (
          <span className="bubble__time caption-12-rg">
            {getHMOnlyNum(createAt)}
          </span>
        )}
      </div>
    );
  };

  return (
    <Container
      transfromPixel={props.transfromPixel}
      style={{
        paddingBottom: props.msg.groupIsLast
          ? props.transfromPixel(16)
          : props.transfromPixel(4),
      }}
    >
      <BubbleContainer transfromPixel={props.transfromPixel}>
        <div className="bubble__list">
          {getText(
            props.msg.groupIsFirst || false,
            props.msg.groupIsLast || false,
            props.msg.createAt,
            props.msg.msgs?.text || "",
            props.msg?.user,
            props.msg.msgs?.boldText
          )}
        </div>
      </BubbleContainer>
    </Container>
  );
};

const Container = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  gap: ${(props) => props.transfromPixel(8)}px;

  padding-left: ${(props) => props.transfromPixel(24)}px;
  padding-right: ${(props) => props.transfromPixel(6)}px;
`;

const BubbleContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: auto;
  display: flex;
  .bubble__list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    gap: ${(props) => props.transfromPixel(4)}px;

    .bubble__item__wrapper {
      width: 100%;
      max-width: ${(props) => props.transfromPixel(254)}px;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: ${(props) => props.transfromPixel(4)}px;

      .bubble__item__container {
        width: fit-content;
        display: flex;
        flex-direction: column;
        margin-left: ${(props) => props.transfromPixel(4)}px;

        .bubble__item__user {
          color: #5b5d61;
        }
      }

      .bubble__item {
        width: fit-content;
        padding: ${(props) => props.transfromPixel(8)}px
          ${(props) => props.transfromPixel(12)}px;
        color: #1f2022;

        border-radius: ${(props) => props.transfromPixel(4)}px;
        overflow: hidden;
        word-break: break-all;
        white-space: break-spaces;
      }
      .bubble__time {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        color: #5b5d61;
        line-height: ${(props) => props.transfromPixel(20)}px;
      }
    }
  }
`;
export default SBUserMe;
