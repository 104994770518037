import React, { createRef, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Send } from "../../../../../assets/image/send_comment.svg";

interface Props {
  defaultData?: string;
  maxHeight?: number;
  maxLength?: number;
  onSubmit: (data: string, callback: () => void) => void;
}
export const CommentEditor: React.FC<Props> = (props) => {
  const { defaultData, maxHeight = 520, maxLength = 1000, onSubmit } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [value, updateValue] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log("sdfasdfasddfagadgag");
    updateValue(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      console.log(textareaRef);
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const defaultTextareaHeight = () => {
    if (textareaRef.current) {
      console.log(textareaRef);
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `66px`;
    }
  };

  useEffect(() => {
    if (!defaultData) return;
    updateValue(defaultData);
  }, [defaultData]);

  useEffect(() => {
    if (value.length > 0) adjustTextareaHeight();
    else defaultTextareaHeight();
  }, [value]);

  return (
    <Container className={focused ? "focused" : ""}>
      <Comment
        ref={textareaRef}
        className="body-14-md"
        style={{ maxHeight: maxHeight }}
        value={value}
        onChange={handleTextareaChange}
        maxLength={maxLength}
        placeholder="메모 작성하기..."
        onFocus={(e) => setFocused(true)}
        onBlur={(e) => setFocused(false)}
      />
      <SendBtnContainer>
        <SendBtn
          className={value.length > 0 ? "filled" : ""}
          onClick={() => {
            if (value.length > 0)
              onSubmit(value, () => {
                updateValue("");
              });
          }}
        >
          <Send />
        </SendBtn>
      </SendBtnContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 10px 10px 10px 16px;

  border-radius: 4px;
  border: 1px solid var(--gray-300, #e3e4e5);
  background: var(--background-contents01, #fff);

  transition: all 0.2s ease;

  &.focused {
    border-color: var(--focus-field-blue);
  }

  :hover {
    border-color: var(--focus-field-blue);
  }
`;

const Comment = styled.textarea`
  flex: 1 0 0;
  height: 0px;
  min-height: 66px;

  overflow: scroll;

  word-break: break-all;
  white-space: break-spaces;

  color: var(--text-01, #1f2022);
  border: 0;
  resize: none;
  :focus {
    outline: none;
  }
`;

const SendBtnContainer = styled.div`
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const SendBtn = styled.div`
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  min-height: 24px;
  max-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &.filled {
    rect {
      fill: var(--primary-blue);
    }
    path {
      stroke: #ffffff;
    }
  }
`;
