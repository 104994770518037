export enum AccountType {
  INVESTMENT = 1,
  OPERATING = 2,
  WATING = 3,
  PAUSE = 4,
  EXPIRED = 5,
}

export interface DashboardFundAccountResponse {
  type: 1 | 2;
  status: AccountType;
  acFundAccountId: number;
  totalAmount: number;
  investmentStartDate: string;
  investmentEndDate: string;
  operatingStartDate: string;
  operatingEndDate: string;
  addOperatingEndDate?: string | null;
  isActive: boolean;
  serialNumber: string;
  nickName: string;
  investmentAccount: { investmentAmount: number }[];
  investmentExit: { amount: number; principal: number }[];
  riskStatus: { id: number; amount: number }[];
}

export interface FundDurationProps {
  addEndDate?: string | null;
  startDate: string;
  endDate: string;
  investmentEndDate: string;
}

export interface FundAccountInfoForDashboard {
  acFundAccountId: number;
  type: 1 | 2;
  status: AccountType;
  totalAmount: number; //약정총액
  fundDuration: FundDurationProps; // 펀드 존속 기간
  totalOriginalInvestment: number;
  totalOriginalExit: number;
  totalExitAmount: number;
  investedNum: number;
  serialNumber: string;
  nickName: string;
  riskStatus: { id: number; amount: number }[];
}
