import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import { AddComma, getNewDate } from "../../../../../common/commonUtil";
import {
  AccountType,
  FundAccountInfoForDashboard,
} from "../../interface/FundAccountInfo.interface";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  fundAccount: FundAccountInfoForDashboard;
  toDay: string;
  onClickCard?: () => void;
}
export const FundAccountCard: React.FC<Props> = (props) => {
  const { fundAccount, toDay, onClickCard } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const getLabel = () => {
    switch (fundAccount.status) {
      case AccountType.INVESTMENT:
        return (
          <CustomColorLabel
            bgColor="var(--green-500)"
            textColor="var(--text-02)"
          >
            투자가능
          </CustomColorLabel>
        );
      case AccountType.OPERATING:
        return (
          <CustomColorLabel
            bgColor="var(--blue-400)"
            textColor="var(--text-02)"
          >
            투자완료
          </CustomColorLabel>
        );
      case AccountType.WATING:
        return (
          <CustomColorLabel
            bgColor="var(--yellow-500)"
            textColor="var(--text-02)"
          >
            대기중
          </CustomColorLabel>
        );
      case AccountType.PAUSE:
        return (
          <CustomColorLabel
            bgColor="var(--gray-700)"
            textColor="var(--text-02)"
          >
            일시중지
          </CustomColorLabel>
        );
      case AccountType.EXPIRED:
        return (
          <CustomColorLabel
            bgColor="var(--gray-500)"
            textColor="var(--text-02)"
          >
            청산
          </CustomColorLabel>
        );
    }
  };

  const getExitLabel = () => {
    const isIncrease =
      fundAccount.totalExitAmount >
      (fundAccount.acFundAccountId === 1
        ? fundAccount.totalOriginalInvestment
        : fundAccount.totalAmount);
    return (
      <CustomColorLabel
        className="caption-12-sb"
        bgColor={isIncrease ? "var(--red-50)" : "var(--blue-100)"}
        textColor={isIncrease ? "var(--red-500)" : "var(--primary-blue)"}
      >
        회수금액 {(fundAccount.totalExitAmount / 100000000).toFixed(0)}억
      </CustomColorLabel>
    );
  };

  const getDateDifference = () => {
    const startDate = getNewDate(toDay);
    const endDate = fundAccount.fundDuration.addEndDate
      ? getNewDate(fundAccount.fundDuration.addEndDate)
      : getNewDate(fundAccount.fundDuration.endDate);

    let years = endDate.getFullYear() - startDate.getFullYear();
    let months = endDate.getMonth() - startDate.getMonth();
    let days = endDate.getDate() - startDate.getDate();

    // 일 차이 조정
    if (days < 0) {
      months--;
      const previousMonth = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        0
      ).getDate();
      days += previousMonth;
    }

    // 월 차이 조정
    if (months < 0) {
      years--;
      months += 12;
    }

    const year = years > 0 ? `${years}년` : "";
    const month = months > 0 ? `${months}월` : "";
    const day = days > 0 ? `${days}일` : "";
    if (years > 0) {
      return year;
    }
    if (months > 0) {
      return month;
    }

    return day;
  };
  const getChart = () => {
    const riskStatus1 = fundAccount.riskStatus.find((item) => item.id === 1);
    const riskStatus2 = fundAccount.riskStatus.find((item) => item.id === 2);
    const riskStatus3 = fundAccount.riskStatus.find((item) => item.id === 3);
    const riskStatus4 = fundAccount.riskStatus.find((item) => item.id === 4);
    const riskStatus5 = fundAccount.riskStatus.find((item) => item.id === 5);
    const riskStatus6 = fundAccount.riskStatus.find((item) => item.id === 6);
    const riskStatus0 = fundAccount.riskStatus.find((item) => item.id === 0);

    const totalAmount = fundAccount.riskStatus
      .map((item) => item.amount)
      .reduce((p, c) => p + c, 0);
    return (
      <div
        style={{
          position: "absolute",
          top: 34,
          right: 32,
          width: 80,
          height: 80,
        }}
      >
        <Doughnut
          style={{ display: "inline-block" }}
          data={{
            labels: ["성장", "관찰", "우려", "손상우려", "손상", "미제출"],
            datasets: [
              {
                label: "",
                data: [
                  riskStatus1?.amount || 0,
                  riskStatus2?.amount || 0,
                  riskStatus3?.amount || 0,
                  riskStatus4?.amount || 0,
                  riskStatus5?.amount || 0,
                  riskStatus0?.amount || 0,
                ],
                backgroundColor: [
                  "rgba(69, 132, 255, 1)",
                  "rgba(59, 214, 153, 1)",
                  "rgba(255, 216, 79, 1)",
                  "rgba(254, 144, 65, 1)",
                  "rgba(255, 114, 123, 1)",
                  "rgba(237, 238, 239, 1)",
                ],
                borderColor: [
                  "rgba(69, 132, 255, 1)",
                  "rgba(59, 214, 153, 1)",
                  "rgba(255, 216, 79, 1)",
                  "rgba(254, 144, 65, 1)",
                  "rgba(255, 114, 123, 1)",
                  "rgba(237, 238, 239, 1)",
                ],
                borderWidth: 1,
              },
            ],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
                text: "Chart.js Bar Chart",
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => {
                    return `${(tooltipItem.parsed / 100000000).toFixed(0)}억`;
                  },
                },
              },
            },
            devicePixelRatio: 10,
            cutout: 25,
          }}
        />
      </div>
    );
  };

  const getLabelConainer = () => {
    return (
      <FlexRow style={{ gap: 4 }}>
        {getLabel()}
        {![AccountType.WATING, AccountType.EXPIRED].includes(
          fundAccount.type
        ) &&
          fundAccount.acFundAccountId !== 1 && (
            <CustomColorLabel
              bgColor="var(--blue-100)"
              textColor="var(--primary-blue)"
            >
              청산{fundAccount.fundDuration.addEndDate ? "(연장)" : ""} D-
              {getDateDifference()}
            </CustomColorLabel>
          )}
      </FlexRow>
    );
  };
  return (
    <Container ref={containerRef} onClick={onClickCard}>
      {getLabelConainer()}
      <PortfolioName className="heading-18-sb">
        {fundAccount.nickName}
      </PortfolioName>
      <FlexColumn>
        <div className="caption-12-rg sub__title">
          {fundAccount.acFundAccountId === 1 ? "사용금액(원)" : "약정금액(원)"}
        </div>
        <FlexRow style={{ gap: 12 }}>
          <div className="heading-20-sb">
            {AddComma(
              fundAccount.acFundAccountId === 1
                ? fundAccount.totalOriginalInvestment
                : fundAccount.totalAmount
            )}
          </div>
          {getExitLabel()}
        </FlexRow>
      </FlexColumn>
      <Divder />
      <FlexColumn style={{ width: "100%", gap: 6 }}>
        {fundAccount.acFundAccountId !== 1 &&
          fundAccount.type === AccountType.INVESTMENT && (
            <FlexRow>
              <Flex1>
                <FlexColumn>
                  <div className="caption-12-rg sub__title">사용 금액(원)</div>
                  <div className="caption-12-md">
                    {AddComma(fundAccount.totalOriginalInvestment)}
                  </div>
                </FlexColumn>
              </Flex1>
              <Flex1>
                <FlexColumn>
                  <div className="caption-12-rg sub__title">잔여 금액(원)</div>
                  <div className="caption-12-md">
                    {AddComma(
                      fundAccount.totalAmount -
                        fundAccount.totalOriginalInvestment
                    )}
                  </div>
                </FlexColumn>
              </Flex1>
            </FlexRow>
          )}
        {(fundAccount.acFundAccountId === 1 ||
          fundAccount.type === AccountType.OPERATING) && (
          <FlexRow>
            <Flex1>
              <FlexColumn>
                <div className="caption-12-rg sub__title">회수한 원금(원)</div>
                <div className="caption-12-md">
                  {AddComma(fundAccount.totalOriginalExit)}
                </div>
              </FlexColumn>
            </Flex1>
            <Flex1>
              <FlexColumn>
                <div className="caption-12-rg sub__title">
                  회수 가능 원금(원)
                </div>
                <div className="caption-12-md">
                  {AddComma(
                    fundAccount.totalOriginalInvestment -
                      fundAccount.totalOriginalExit
                  )}
                </div>
              </FlexColumn>
            </Flex1>
          </FlexRow>
        )}

        <FlexRow>
          <Flex1>
            <FlexColumn>
              <div className="caption-12-rg sub__title">투자건수(건)</div>
              <div className="caption-12-md">{fundAccount.investedNum}</div>
            </FlexColumn>
          </Flex1>
          <Flex1>
            <FlexColumn>
              <div className="caption-12-rg sub__title">시리얼</div>
              <div className="caption-12-md">{fundAccount.serialNumber}</div>
            </FlexColumn>
          </Flex1>
        </FlexRow>
        {getChart()}
      </FlexColumn>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;

  padding: 34px 24px;
  border-radius: 4px;

  background-color: var(--background-contents01);
  border: 1px solid var(--gray-200);
  transition: all 0.2s ease;

  color: var(--text-01);

  cursor: pointer;

  .sub__title {
    color: var(--text-05, #96999f);
  }

  :hover {
    background-color: var(--hover-100);
  }
`;

const PortfolioName = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 14px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Flex1 = styled.div`
  flex: 1;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Divder = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 18px;
  margin-bottom: 18px;
  background-color: var(--gray-200, #edeeef);
`;
