import React from "react";
import styled, { css } from "styled-components";
import { TextareaBox } from "../../../../../../components-2.0v/input-box/TextareaBox";

interface Props {
  editable?: boolean;
}
export const Long: React.FC<Props> = (props) => {
  const { editable } = props;
  return (
    <Container>
      {editable ? (
        <TextareaBox placeholder="장문형 텍스트" />
      ) : (
        <Text className="body-14-rg">장문형 텍스트</Text>
      )}
    </Container>
  );
};

const Container = styled.div(
  () => css`
    display: flex;
  `
);

const Text = styled.div`
  width: 100%;
  height: 90px;
  border: 1px solid var(--gray-300);
  padding: 6px 12px;

  border-radius: 4px;

  color: var(--gray-500);

  &.read__only {
    border: 1px solid transparent;
    padding: 9px 0px;

    :focus {
      outline: none;
      border: 1px solid transparent;
    }
  }
  ::placeholder {
    color: var(--text-inactive);
  }
  :focus {
    outline: none;
    border: 1px solid var(--blue-300);
  }
`;
