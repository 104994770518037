import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IASimilarBusiness } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";
import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { RemoveComma, getYMD } from "../../../../../../common/commonUtil";
import { CustomTextArea } from "../../table/custom-cell/CustomTextArea";
import { domesticInternationalTypeList } from "../../../interface/RequestExpert.interface";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IASimilarBusiness[];
  onDataChange?: (data: IASimilarBusiness[]) => void;
}

export const SimilarBusinessTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<IASimilarBusiness[] | undefined>(
    undefined
  );
  const headers: TableGridHeaderProps[] = [
    {
      header: "구분",
      key: "type",
      width: 100,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultData={data}
            selectList={domesticInternationalTypeList}
            placehoder="선택"
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.type = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "회사이름",
      key: "compnayName",
      width: 150,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="회사이름 입력"
            onBlur={(value) => {
              if (!dataList) return;
              rowData.compnayName = value;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },

    {
      header: "사업요약",
      key: "businessSummary",
      width: 285,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomTextArea
            isReadOnly={isReadOnly}
            defaultData={data}
            placeholder={`대략적인 사업내용을 작성해주세요.`}
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.businessSummary = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },

    {
      header: "주요고객사",
      key: "clientCompany",
      width: 180,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomTextArea
            isReadOnly={isReadOnly}
            defaultData={data}
            placeholder={`고객사를 입력해주세요.`}
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.clientCompany = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "매출액(억원)",
      key: "salesAmount",
      width: 120,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1,000"
            onBlur={(value) => {
              if (!dataList) return;
              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.salesAmount = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "누적투자(억원)",
      key: "cumulativeInvestment",
      width: 120,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1,000"
            onBlur={(value) => {
              if (!dataList) return;
              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.cumulativeInvestment = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "주요 투자자",
      key: "majorInvestor",
      width: 168,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomTextArea
            isReadOnly={isReadOnly}
            defaultData={data}
            placeholder={`투자자 정보를 입력하세요.`}
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.majorInvestor = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newData: IASimilarBusiness = {};
    const newDataList: IASimilarBusiness[] = [...(dataList || []), newData];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    updateDataList(props.dataList);
  }, [props.dataList]);

  useEffect(() => {
    if (dataList && props.onDataChange) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
