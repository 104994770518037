import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { TableGridHeaderProps, TableGridProps } from "./TableGrid";

interface Props {
  headerData: TableGridHeaderProps[];
  rowData: TableGridProps;
  isDeleteAble?: boolean;
  isReadOnly?: boolean;
  disabled?: boolean;
  transfromPixel: (value: number) => number;
  onClickDeleteBtn: (id: number) => void;
}

const TableRow: React.FC<Props> = ({
  headerData,
  rowData,
  isDeleteAble,
  isReadOnly,
  disabled,
  transfromPixel,
  onClickDeleteBtn,
}) => {
  return (
    <Container
      className="body-14-rg"
      style={{
        cursor: "default",
        height: transfromPixel(50),
        minHeight: transfromPixel(50),
        maxHeight: transfromPixel(50),
      }}
    >
      {headerData.map((item, idx) => {
        return (
          <CellContainer
            key={idx}
            style={{
              width: item.width || 100,
              minWidth: item.width || 100,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item.customCell
              ? item.customCell(
                  rowData.data[item.key],
                  rowData.data,
                  isReadOnly,
                  disabled
                )
              : null}
          </CellContainer>
        );
      })}
      <CellContainer
        style={{
          width: 74,
          minWidth: 74,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          alignItems: "center",
          borderLeft: isReadOnly
            ? "1px solid transparent"
            : "1px solid var(--divider-200)",
        }}
      ></CellContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  transform: all 0.2s ease;
  background-color: #ffffff;
`;

const CellContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-left: 1px solid var(--divider-200);
  border-bottom: 1px solid var(--divider-200);

  .delete {
    transition: all 0.2s ease;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }
    &.disabled {
      rect {
        fill: var(--gray-200);
      }
      path {
        stroke: var(--icon-02);
      }
      :hover {
        rect {
          fill: var(--gray-200);
        }
      }
    }
  }

  &:nth-child(1) {
    border-left: 1px solid transparent;
  }
`;
export default React.memo(TableRow);
