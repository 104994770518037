import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import ReportFileDownload from "../../../reporting/desktop/components/ReportFileDownload";

import { ReactComponent as Close } from "../../../../assets/image/new_close_button.svg";
import { ReactComponent as Lock } from "../../../../assets/image/new_icon_lock.svg";
import { ReactComponent as Loading } from "../../../../assets/image/loading.svg";
import { ReactComponent as Download } from "../../../../assets/image/new_icon_download.svg";

import { readReport } from "../../../reporting/repository/ReportingRepository";

import SummaryBusinessPlanViewer from "../organism/summary-business-plan/SummaryBusinessPlanViewer";
import { PDFViewer } from "../../../../components-2.0v/pdf-viewer/PDFViewer";
import { getMyInfo } from "../../../../common/commonUtil";
import { FinancialStatementViewer } from "../../../reporting/mobile/components/financial-statements/organism/FinancialStatementViewer";
import { ReportingFile } from "../../interface/ReportingDetail.interface";

interface Props {
  portfolioName: string;
  portfolioId: number;
  selectedReport: ReportingFile;
  year: number;
  quarterNum: number;
  close: () => void;

  transfromPixel: (value: number) => number;
  getPortfolioReportDocumentByRefData: () => void;
}

const ViewerPortfolioDetailModal = (props: Props) => {
  const {
    portfolioId,
    portfolioName,
    selectedReport,
    year,
    quarterNum,
    close,
    transfromPixel,
    getPortfolioReportDocumentByRefData,
  } = props;

  const [documentLoaded, updateDocumentLoaded] = useState<boolean>(false);

  const getGoolgeUrl = (documentId: string, extension: string) => {
    return `https://drive.google.com/file/d/${documentId}/preview`;
    // switch (extension) {
    //   case "excel":
    //     return `https://docs.google.com/spreadsheets/d/${documentId}/edit?usp=sharing&ouid=112598283255709341659&rtpof=true&sd=true`;
    //   default:
    //     return `https://drive.google.com/file/d/${documentId}/preview`;
    //   // return `https://drive.google.com/file/d/${documentId}/view`;
    // }
  };

  const onReadDocument = async () => {
    try {
      const myInfo = getMyInfo();
      if (
        myInfo.authorityId === 2 &&
        selectedReport &&
        selectedReport.file &&
        [1, 2].includes(selectedReport.file.uploadStatus.uploadStatusId)
      ) {
        await readReport(selectedReport.file.pfReportDocumentId);
        await props.getPortfolioReportDocumentByRefData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getViewerComponent = () => {
    if (
      selectedReport === undefined ||
      selectedReport.file === undefined ||
      selectedReport.file === null ||
      selectedReport.file.documentFile === undefined
    ) {
      return (
        <div className="heading-18-md empty__container">
          <Lock />
          <div>제출된 서류가 없습니다.</div>
        </div>
      );
    }

    if (selectedReport.extension === "web") {
      return (
        <div className="heading-18-md empty__container">
          <SummaryBusinessPlanViewer file={selectedReport.file} />
        </div>
      );
    }

    if ([1].includes(selectedReport.acPfDocumentId)) {
      return (
        <div className="viewer__iframe__wrapper">
          <iframe
            className="viewer__iframe"
            src={getGoolgeUrl(
              selectedReport.file.documentFile?.key || "",
              selectedReport.extension
            )}
            onLoad={() => {
              updateDocumentLoaded(true);
            }}
          />

          {!documentLoaded && (
            <div className="viewer__iframe__load__container">
              <Loading
                className="rotating"
                width={props.transfromPixel(30)}
                height={props.transfromPixel(30)}
              />
            </div>
          )}
        </div>
      );
    }

    if ([7, 8].includes(selectedReport.acPfDocumentId)) {
      return (
        <div className="heading-18-md empty__container">
          <FinancialStatementViewer
            portfolioId={props.portfolioId}
            year={props.year}
            quarterNum={props.quarterNum}
            data={selectedReport}
          />
        </div>
      );
    }

    return (
      <PDFViewer
        type={selectedReport.file.type}
        pdfKey={selectedReport.file.documentFile.key}
      />
    );
  };

  useEffect(() => {
    console.log(selectedReport);
    updateDocumentLoaded(false);
    onReadDocument();
  }, [selectedReport]);

  return (
    <Conainer>
      <Header transfromPixel={props.transfromPixel}>
        <span
          className="heading-18-sb"
          style={{ paddingTop: props.transfromPixel(14) }}
        >
          {props.portfolioName}
        </span>
        <div className="close__btn" onClick={props.close}>
          <Close />
        </div>
      </Header>
      <Body transfromPixel={props.transfromPixel}>
        <ViewerContainer transfromPixel={props.transfromPixel}>
          <div className="viewer__header">
            <div>{props.selectedReport.name}</div>
            {selectedReport &&
            selectedReport.extension !== "web" &&
            selectedReport.file ? (
              <ReportFileDownload
                docucmentInfo={{
                  fileId: selectedReport.file.pfReportDocumentId,
                  extension: selectedReport.extension,
                  fileName:
                    selectedReport.file.documentFile?.fileName || "untitled",
                }}
                transfromPixel={props.transfromPixel}
                callbackFn={props.getPortfolioReportDocumentByRefData}
              >
                <FileDownload
                  className="body-14-rg"
                  transfromPixel={props.transfromPixel}
                >
                  다운로드
                  <Download
                    width={props.transfromPixel(16)}
                    height={props.transfromPixel(16)}
                  />
                </FileDownload>
              </ReportFileDownload>
            ) : (
              <FileDownload
                transfromPixel={props.transfromPixel}
                style={{ userSelect: "none", opacity: 0, cursor: "default" }}
              >
                다운로드
              </FileDownload>
            )}
          </div>
          <div className="viewer">{getViewerComponent()}</div>
        </ViewerContainer>
      </Body>
    </Conainer>
  );
};

const Conainer = styled.div(
  () => css`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `
);

const Header = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: 100%;
    height: ${transfromPixel(66)}px;
    min-height: ${transfromPixel(66)}px;
    max-height: ${transfromPixel(66)}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${transfromPixel(1)}px solid #edeeef;
    padding-left: ${transfromPixel(24)}px;
    padding-right: ${transfromPixel(20)}px;

    .close__btn {
      width: ${transfromPixel(32)}px;
      height: ${transfromPixel(32)}px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: ${transfromPixel(4)}px;
      transition: background-color 0.2s ease;

      margin-top: ${transfromPixel(3)}px;
      cursor: pointer;
      :hover {
        background-color: #f3f4f4;
      }
    }
  `
);

const Body = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: 100%;
    height: calc(100% - ${transfromPixel(66)}px);
    display: flex;
    flex-direction: row;
  `
);

const ViewerContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: ${transfromPixel(1)}px solid #edeeef;
    border-right: ${transfromPixel(1)}px solid #edeeef;

    &.spread {
      width: calc(100vw);
    }

    .viewer__header {
      position: relative;
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: ${transfromPixel(1)}px solid #edeeef;

      padding: 12px 24px;
    }

    .viewer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-bottom: ${transfromPixel(1)}px solid #edeeef;

      .viewer__iframe__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .viewer__iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }

        .viewer__iframe__load__container {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .empty__container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${transfromPixel(20)}px;
        background: #fbfcfd;
        color: #7e8086;
      }
    }

    /* Safari and Chrome */
    @-webkit-keyframes rotating {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    .rotating {
      -webkit-animation: rotating 1s linear infinite;
      -moz-animation: rotating 1s linear infinite;
      -ms-animation: rotating 1s linear infinite;
      -o-animation: rotating 1s linear infinite;
      animation: rotating 1s linear infinite;
    }
  `
);

const FoldSpreadBtn = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;
    width: ${transfromPixel(32)}px;
    height: ${transfromPixel(32)}px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff; //background-header

    z-index: 2;

    :hover {
      background-color: #f3f4f4; //button-disabled
    }

    cursor: pointer;

    .arrow {
      transition: all 0.2s ease;
      :hover {
        rect:nth-child(1) {
          fill: var(--hover-100);
        }
      }
    }
  `
);

const FileDownload = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: ${transfromPixel(93)}px;
    height: ${transfromPixel(34)}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${transfromPixel(4)}px;

    background-color: #ffffff;
    border: ${transfromPixel(1)}px solid #d5d6d8;
    border-radius: ${transfromPixel(4)}px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    path {
      fill: #1f2022;
    }

    :hover {
      background-color: #f3f4f4;
    }

    &.disabled {
      background-color: #ffffff;
      border-color: #f3f4f4;
      color: #bec0c2;
      cursor: default;
      path {
        fill: #bec0c2;
      }
      :hover {
        background-color: #ffffff;
      }
    }
  `
);

const ChatingContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: ${transfromPixel(380)}px;
    min-width: ${transfromPixel(380)}px;
    max-width: ${transfromPixel(380)}px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.folded {
      width: ${transfromPixel(0)}px;
      min-width: ${transfromPixel(0)}px;
      max-width: ${transfromPixel(0)}px;
      padding: 0px;
      opacity: 0;
    }
  `
);

export default ViewerPortfolioDetailModal;
