import React from "react";
import styled from "styled-components";
import { BoxComponent } from "../../../../../components-2.0v/box/BoxComponent";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { StartupInfoProps } from "../../interface/PortfolioInfo.interface";

interface Props {
  startupInfo: StartupInfoProps;
  emptyData?: string[];
  duplicateData?: string[];
}
export const StartupInfo: React.FC<Props> = (props) => {
  const { startupInfo, emptyData = [], duplicateData = [] } = props;
  console.log(startupInfo);
  return (
    <BoxComponent>
      <RowTitleBox title="기업명" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            emptyData.includes("companyName") ||
            duplicateData.includes("companyName")
              ? "is__danger"
              : startupInfo.companyName && startupInfo.companyName.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {startupInfo.companyName && startupInfo.companyName.length > 0
            ? startupInfo.companyName
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="대표 이사" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            emptyData.includes("ceoName")
              ? "is__danger"
              : startupInfo.ceoName && startupInfo.ceoName.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {startupInfo.ceoName && startupInfo.ceoName.length > 0
            ? startupInfo.ceoName
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="전화 번호" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            emptyData.includes("ceoPhone") || duplicateData.includes("ceoPhone")
              ? "is__danger"
              : startupInfo.ceoTel && startupInfo.ceoTel.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {startupInfo.ceoTel && startupInfo.ceoTel.length > 0
            ? startupInfo.ceoTel
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="이메일" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            emptyData.includes("ceoEmail") || duplicateData.includes("ceoEmail")
              ? "is__danger"
              : startupInfo.ceoEmail && startupInfo.ceoEmail.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {startupInfo.ceoEmail && startupInfo.ceoEmail.length > 0
            ? startupInfo.ceoEmail
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="주소" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            emptyData.includes("address")
              ? "is__danger"
              : startupInfo.companyAddress
              ? ""
              : "is__empty"
          }`}
        >
          {startupInfo.companyAddress
            ? startupInfo.companyAddress.fullAddr
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="사업자 등록번호" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            emptyData.includes("businessNumber") ||
            duplicateData.includes("businessNumber")
              ? "is__danger"
              : startupInfo.businessNumber &&
                startupInfo.businessNumber.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {startupInfo.businessNumber && startupInfo.businessNumber.length > 0
            ? startupInfo.businessNumber
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
      <RowTitleBox title="설립일" titleWidth={128}>
        <TextBox
          className={`body-14-rg ${
            emptyData.includes("establishmentDay")
              ? "is__danger"
              : startupInfo.establishDate &&
                startupInfo.establishDate.length > 0
              ? ""
              : "is__empty"
          }`}
        >
          {startupInfo.establishDate && startupInfo.establishDate.length > 0
            ? startupInfo.establishDate
            : "입력된 내용이 없습니다."}
        </TextBox>
      </RowTitleBox>
    </BoxComponent>
  );
};

const TextBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-01, #1f2022);

  &.is__empty {
    color: var(--text-05);
  }

  &.is__danger {
    color: var(--red-500, #f6444f);
  }
`;
