import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IndustryTech, SelectorProps } from "../../../../type/data";
import RowTitleBox from "../../../../components/box/RowTitleBox";
import { InputBox } from "../../../../components-2.0v/input-box/InputBox";
import { Radio } from "../../../../components-2.0v/radio-box/Radio";
import CheckedText from "../../../../components-2.0v/check-box/CheckedText";
import { BoxComponent } from "../../../../components-2.0v/box/BoxComponent";
import { ExcavationPortfolioChangeDataProps } from "../../interface/ExcavationCreate.interface";

interface Props {
  allIndustrialTech: IndustryTech[];
  onChangeData: (data: ExcavationPortfolioChangeDataProps) => void;
}
export const CompanyOverview: React.FC<Props> = (props) => {
  const { allIndustrialTech, onChangeData } = props;

  const [mainIndustrialTech, updateMainIndustrialTech] = useState<
    SelectorProps | undefined
  >(undefined);
  const [subIndustrialTech, updateSubIndustrialTech] = useState<
    SelectorProps[]
  >([]);
  const [allSelectorIndustrialTech, setAllSelectorIndustrialTech] = useState<
    SelectorProps[]
  >([]);

  useEffect(() => {
    const selectorData = allIndustrialTech.map((item): SelectorProps => {
      return {
        id: parseInt(`${item.acIndustrialTechId}`),
        text: item.name,
        data: item,
      };
    });
    setAllSelectorIndustrialTech(selectorData);
  }, [allIndustrialTech]);

  return (
    <BoxComponent>
      <RowTitleBox title="기업명" required titleWidth={128}>
        <InputBox
          placeholder="법인등기부등본상 법인명과 일치하도록 입력해 주세요."
          width={486}
          onBlur={(value) => {
            onChangeData({ type: "companyName", value: value });
          }}
        />
      </RowTitleBox>
      <RowTitleBox title="주 산업 기술" required titleWidth={128}>
        <div style={{ marginTop: 10 }}>
          <Radio
            textClassName="caption-14-rg"
            defaultData={mainIndustrialTech?.text}
            selectList={allSelectorIndustrialTech}
            onChangeData={(data) => {
              updateMainIndustrialTech(data);
              onChangeData({
                type: "mainPfIndustrialTech",
                value: {
                  isMain: true,
                  acIndustrialTech: {
                    acIndustrialTechId: data.id,
                  },
                },
              });

              const subMainSame = subIndustrialTech.findIndex(
                (item) => item.id === data.id
              );
              if (subMainSame !== -1) {
                subIndustrialTech.splice(subMainSame, 1);
                updateSubIndustrialTech([...subIndustrialTech]);
              }
            }}
          />
        </div>
      </RowTitleBox>

      <RowTitleBox title="부 산업 기술" titleWidth={128}>
        <IndustiryTechContainer>
          {allSelectorIndustrialTech
            .filter((item) => item.id !== mainIndustrialTech?.id)
            .map((item, index) => (
              <CheckedText
                key={index}
                className="caption-14-rg"
                isChecked={subIndustrialTech
                  .map((data) => data.id)
                  .includes(item.id)}
                onClick={() => {
                  const dataIndex = subIndustrialTech.findIndex(
                    (data) => data.id === item.id
                  );
                  if (dataIndex !== -1) {
                    subIndustrialTech.splice(dataIndex, 1);
                    updateSubIndustrialTech([...subIndustrialTech]);
                    onChangeData({
                      type: "subPfIndustrialTech",
                      value: subIndustrialTech.map((item) => {
                        return {
                          isMain: false,
                          acIndustrialTech: {
                            acIndustrialTechId: item.id,
                          },
                        };
                      }),
                    });
                  } else {
                    const newData = [...subIndustrialTech, item];
                    updateSubIndustrialTech([...newData]);
                    onChangeData({
                      type: "subPfIndustrialTech",
                      value: newData.map((item) => {
                        return {
                          isMain: false,
                          acIndustrialTech: {
                            acIndustrialTechId: item.id,
                          },
                        };
                      }),
                    });
                  }
                }}
              >
                {item.text}
              </CheckedText>
            ))}
        </IndustiryTechContainer>
      </RowTitleBox>

      <RowTitleBox title="한줄 요약" required titleWidth={128}>
        <InputBox
          placeholder="제품/서비스 완성도 등의 측면에서 작성해 주세요."
          width={900}
          maxLength={50}
          isShowLength
          onChange={(value) => {
            onChangeData({ type: "description", value: value });
          }}
        />
      </RowTitleBox>
    </BoxComponent>
  );
};

const IndustiryTechContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
  margin-top: 9px;
`;
