import React from "react";
import styled from "styled-components";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  textColor?: string;
  bgColor?: string;
  onClick?: () => void;
}

export const BubbleLabel: React.FC<Props> = ({
  className = "caption-10-md",
  style = {},
  textColor = "var(--gray-600)",
  bgColor = "var(--gray-100)",
  onClick,
  children,
}) => {
  return (
    <Container
      className={className}
      style={{ ...style, color: textColor, backgroundColor: bgColor }}
      onClick={onClick}
    >
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  width: max-content;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;
