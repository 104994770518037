import React, { useEffect, useRef } from "react";
import { DragLayerMonitorProps } from "@minoru/react-dnd-treeview";

import { StructureInfo } from "../../interface/Structure.interface";
import styled from "styled-components";

type Props = {
  monitorProps: DragLayerMonitorProps<StructureInfo>;
};

export const CustomDragPreview: React.FC<Props> = (props) => {
  const item = props.monitorProps.item;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const modalRoot = document.querySelector("#structureModal");

    if (modalRoot && containerRef.current) {
      const rect = modalRoot.getBoundingClientRect();
      containerRef.current.style.transform = `translate(-${rect.x}px,-${rect.y}px)`;
    }
  }, []);

  return (
    <Ccontainer ref={containerRef}>
      <div className={"label"}>{item.text}</div>
    </Ccontainer>
  );
};

const Ccontainer = styled.div`
  align-items: center;
  background-color: #1967d2;
  border-radius: 4px;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25),
    0 0 0 1px rgba(0, 0, 0, 0.08);
  color: #fff;
  display: inline-grid;
  font-size: 14px;
  gap: 8px;
  grid-template-columns: auto auto;
  padding: 4px 8px;
  pointer-events: none;
  .label {
    align-items: center;
    display: flex;
  }
`;
