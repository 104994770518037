import React from "react";
import styled from "styled-components";

import DefaultButton from "../../../../../../components/button/DefaultButton";

interface Props {
  handleSignInClick: () => void;
  close: () => void;
}
export const EvaluationGoogleLoginModal: React.FC<Props> = (props) => {
  const { handleSignInClick } = props;
  return (
    <Container>
      <div>Google 엑세스가 만료 되었습니다. 로그인 해주세요.</div>
      <DefaultButton
        width={408}
        type="outline"
        onClick={() => {
          handleSignInClick();
          close();
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <img
            src="https://www.svgrepo.com/show/355037/google.svg"
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          구글 로그인
        </div>
      </DefaultButton>
    </Container>
  );
};

const Container = styled.div`
  width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 36px 24px;
`;
