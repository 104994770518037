import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FileType, NewOldStockType, QuorumType } from "../../../../type/data";
import useEvaluationTask from "../hook/useEvaluationTask";
import DocumentLayout from "../../../portfolio/create-evaluation-document/DocumentLayout";

import { InvestmentDeliberationCommitteeMinutes } from "./organism/InvestmentDeliberationCommitteeMinutes";

import { toast } from "react-toastify";
import { DefaultButton } from "lattice_core";
import { historyBack, viewPdfPopup } from "../../../../common/commonUtil";

const getParamsInLocation = (pathName: string, param: string): string => {
  const params = pathName.split("/");
  console.log(params);
  switch (param) {
    case "portfolioId":
      return params[2];
    case "investmentAssessmentId":
      return params[4];
    case "reportId":
      return params[6];
    default:
      return "-1";
  }
};

export interface MinutesProps {
  isFundManager?: boolean;
  fundAccount?: {
    acfundAccountId: number;
    accountName: string;
    newOldStock?: NewOldStockType; //신주,구주
  };
  buyCompany?: string;
  buyCompanyValue?: string;
  companyName?: string;
  date?: string;
  stockType?: string;
  totalInvestmentAmount?: string;
  totalTradeAmount?: string;
  investmentAmount?: string;
  issuingPrice?: string;
  curNewIssuingPrice?: string;
  postTotalStockNumber?: string;
  totalTradeStock?: string;
  stockNumber?: string;
  shareholdingRatio?: string;
  discount?: string;

  reportFile?: FileType;

  acUserName?: string;
  isAgreement?: boolean;
  comment?: string;
  signature?: {
    type: number;
    url?: string;
    path?: any[];
  };

  totalVotingRight?: number;
  totalInviteUserNum?: number;
  agreementNum?: number;
  oppositionNum?: number;

  votingResult?: string;

  quorumType?: QuorumType;
}

export const DeskEvaluationTask = () => {
  const [
    portfolioId,
    logoImage,
    navigate,
    targetDocument,
    signatureData,
    updateTargetDocument,
    setSignatureData,
    isVoteDone,
    isInAssessmentDate,
    onVote,
  ] = useEvaluationTask();

  return (
    <Container>
      <Wrapper>
        <MinuteWarpper>
          {targetDocument && (
            <InvestmentDeliberationCommitteeMinutes
              portfolioId={portfolioId}
              minutesDocument={targetDocument}
              signatureData={signatureData}
              logoImage={logoImage}
              onChangeData={(data) => {
                console.log(data);

                updateTargetDocument({ ...data });
              }}
              onChangeSignature={(data) => {
                console.log(data);

                setSignatureData(data);
              }}
            />
          )}
          <TitleContainer>
            <DefaultButton
              buttonType="filled"
              sizeType="small"
              buttonColor="var(--primary-blue)"
              hoverColor="var(--blue-600)"
              disabled={
                targetDocument === undefined ||
                targetDocument.isAgreement === undefined ||
                targetDocument.comment === undefined ||
                (targetDocument.comment &&
                  targetDocument.comment.length < 20) ||
                !targetDocument.comment ||
                signatureData === undefined
              }
              onClick={() => {
                onVote();
              }}
              disabledOnClick={() => {
                if (!isInAssessmentDate) {
                  toast.error("아직 투자심사 기간이 아닙니다.", {
                    autoClose: 1500,
                    toastId: "not-in-assessment-date",
                    position: "bottom-center",
                  });
                }
              }}
              text={isVoteDone ? "수정하기" : "제출하기"}
            />
          </TitleContainer>
          <Asdf>
            <DefaultButton
              buttonType="text"
              sizeType="small"
              buttonColor="#000000"
              disabled={!targetDocument || !targetDocument.reportFile}
              onClick={() => {
                if (targetDocument && targetDocument.reportFile) {
                  viewPdfPopup(targetDocument.reportFile.key, 1000);
                }
              }}
              text={"투자심사 보고서 열기"}
            />
          </Asdf>
        </MinuteWarpper>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;
const Wrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding: 60px 0px;
`;

const MinuteWarpper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;

  border: 1px solid var(--gray-400);
`;

const Asdf = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  flex-direction: row;
  padding: 0 24px;
  align-items: center;
  justify-content: flex-end;

  background-color: white;
  border-top: 1px solid var(--gray-400);
`;
