import React from "react";
import styled from "styled-components";

import { ReactComponent as Excel } from "../../../assets/image/new_icon_xlsx.svg";
import { ReactComponent as Pdf } from "../../../assets/image/new_icon_pdf.svg";

interface Props {
  uploadStatusId: number;
  uploadStatusName: string;
  transfromPixel: (value: number) => number;
}

const StatusBall: React.FC<Props> = (props) => {
  const { uploadStatusId, uploadStatusName, transfromPixel } = props;

  const ballStyle = {
    width: transfromPixel(6),
    minWidth: transfromPixel(6),
    maxWidth: transfromPixel(6),
    height: transfromPixel(6),
    minHeight: transfromPixel(6),
    maxHeight: transfromPixel(6),
  };

  const getBallColor = () => {
    switch (uploadStatusId) {
      case 3:
        return "#FFD84F";
      case 4:
        return "#F6444F";
      case 5:
        return "#4584FF";
      default:
        return "#ffffff";
    }
  };

  return (
    <Container
      className="body-14-rg"
      style={{
        gap: transfromPixel(5),
      }}
    >
      <Ball style={{ ...ballStyle, background: getBallColor() }} />
      <span>{uploadStatusName}</span>
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Ball = styled.div`
  border-radius: 50%;
`;

export default StatusBall;
