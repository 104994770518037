import React from "react";
import styled from "styled-components";

import { Question } from "./survey-item/Question";
import { SectionType } from "../../interface/PrePitchingDetail.interface";

interface Props {
  sectionItem: SectionType;
}
export const PitchingQuestions: React.FC<Props> = (props) => {
  const { sectionItem } = props;
  return (
    <Container>
      {sectionItem.list?.map((item, key) => {
        return <Question key={key} questionItem={item} />;
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 32px;
`;
