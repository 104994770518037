import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { getDatabaseDamaged } from "../../../../../../api/repository/database/DatabaseRepository";
import {
  DatabaseHistory,
  DBDamagedPortfolio,
  DPBPDamaged,
} from "../../../../../../type/data";
import FilterDamaged, {
  InputSearchValueType,
  IsAllSelectedType,
  SearchValueType,
} from "../../../organisms/FilterDamaged";
import DBResultListFunctionRow from "../../DBResultListFunctionRow";

import DamagedDatabaseGrid from "./DamagedDatabaseGrid";
import DamagedModal from "./DamagedModal";
import { setDatabaseHistory } from "../../../../../../common/pageHistoryUtil";
import useDamagedSearch from "./useDamagedSearch";
import DatabaseInput from "../../DatabaseInput";
interface Props {
  isShowFilter: boolean;
  history?: DatabaseHistory;
}
/**
 * 손상/폐업, 드롭 탭 화면
 *
 * 구성
 *  - 손상/폐업,드롭 검색 조건 컴포넌트
 *  - 결과 Grid
 */
const DamagedTab: React.FC<Props> = (props) => {
  const [
    searchObject,
    inputChipObject,
    investmentYears,
    totalCount,
    countPerPage,
    totalPage,
    resultPortfolios,
    currentPortfolio,
    openPortfolioModal,
    industrialTechs,
    round,
    participants,
    addrList,
    fundAccountList,
    setSearchObject,
    setInputChipObject,
    onPortfolioModal,
    updatePortfolioModal,
  ] = useDamagedSearch();

  const [keyword, setKeyword] = useState<string>(
    props.history?.search?.keyword || ""
  );

  if (!searchObject) return null;

  return (
    <Container>
      <ResultContainer
        style={{
          paddingRight: props.isShowFilter ? 0 : 60,
          paddingTop: 24,
          gap: 8,
        }}
      >
        <DBResultListFunctionRow
          totalCount={totalCount}
          countPerPage={countPerPage}
          curPage={searchObject.curPage || 1}
          totalPage={totalPage}
          resultPortfolioListCount={
            resultPortfolios ? resultPortfolios.portfolio.length : 0
          }
          customFunctions={
            <FlexRow style={{ width: 600, alignItems: "center" }}>
              <span className="body-14-sb" style={{ width: 60 }}>
                검색어
              </span>
              <DatabaseInput
                type="search"
                placeholder="기업명 또는 관련 키워드를 2글자 이상 입력해 주세요."
                onClickSearch={(value) => {
                  if (typeof value === "string") {
                    setSearchObject({
                      ...searchObject,
                      keyword: keyword,
                      curPage: 1,
                    });
                  }
                }}
                onChangeValue={(value) => {
                  if (typeof value === "string") {
                    setKeyword(value);
                  }
                }}
                value={keyword || ""}
              />
            </FlexRow>
          }
          updateCurPage={(updatedPage: number) => {
            setSearchObject((prev) => ({
              ...prev,
              curPage: updatedPage,
            }));
          }}
          searchKeyword={searchObject.keyword}
        />
        {resultPortfolios && (
          <DamagedDatabaseGrid
            resultList={resultPortfolios}
            onPortfolioModal={onPortfolioModal}
          />
        )}
      </ResultContainer>

      <FilterContainer className={props.isShowFilter ? "show__filter" : ""}>
        <FilterDamaged
          searchObject={searchObject}
          setSearchObject={setSearchObject}
          inputChipObject={inputChipObject}
          setInputChipObject={setInputChipObject}
          industrialTechs={industrialTechs}
          participants={participants}
          round={round}
          investmentYears={investmentYears}
          addrList={addrList}
          fundAccountList={fundAccountList}
        />
      </FilterContainer>

      {resultPortfolios && currentPortfolio.current && openPortfolioModal && (
        <DamagedModal
          isOpen={openPortfolioModal}
          close={() => {
            updatePortfolioModal(false);
          }}
          portfolioInfo={currentPortfolio.current}
          refresh={async () => {
            // await getDamagedPortfolioList();
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const ResultContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  gap: 4px;

  .function__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .function__row__group {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .per__page__item {
    cursor: pointer;
    color: #828282;
  }

  .per__page__item__selected {
    cursor: pointer;
    font-weight: 600;
    color: var(--primary);
  }

  .divider {
    width: 1px;
    height: 12px;
    background-color: #c4c4c4;
  }
`;

const FilterContainer = styled.div`
  width: 0px;
  height: 100%;
  transition: all 0.3s ease;
  border-left: 1px solid #f3f4f4;
  overflow: hidden;
  &.show__filter {
    width: 700px;
    transition: all 0.3s ease;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export default DamagedTab;
