import React, { useEffect, useState } from "react";
import styled from "styled-components";

import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import SpinnerModal from "../../../../components/modal/SpinnerModal";
import { getDatabaseAccelerating } from "../../../../api/repository/database/DatabaseRepository";
import { DBBPAccelerating } from "../../../../type/data";
import { DefaultButton } from "lattice_core";
import { SearchValueType } from "../organisms/FilterAccelerating";

interface DataProps {
  portfolioId: string;
  companyName: string;
  acFundAccount: string;
  investmentDate: string;
  purchasePrincipal: string;
  companyValue: string;
  ownershipPercentage: string;
  realizedMultiple: string;
  unrealizedMultiple: string;
  pfParticipant: string;
  pfIndustrialTech: string;
  salesAmount: string;
  round: string;
  description: string;
  addr1: string;
}

interface Props {
  getBodyBySearchObject: () => any;
  totalCount: number;
}
const AcceleratingExcelDownload: React.FC<Props> = (props) => {
  const [downloading, updateDownloading] = useState<boolean>(false);
  const headerDefaultStyle: Partial<ExcelJS.Style> = {
    font: {
      size: 8,
      bold: true,
    },
    alignment: {
      vertical: "middle",
      horizontal: "center",
    },
  };

  const portfolioId: Partial<ExcelJS.Column> = {
    header: "ID",
    key: "portfolioId",
    width: 8,
    style: { ...headerDefaultStyle },
  };
  const companyName: Partial<ExcelJS.Column> = {
    header: "기업명",
    key: "companyName",
    width: 30,
    style: { ...headerDefaultStyle },
  };
  const acFundAccount: Partial<ExcelJS.Column> = {
    header: "투자계정",
    key: "acFundAccount",
    width: 30,
    style: { ...headerDefaultStyle },
  };
  const investmentDate: Partial<ExcelJS.Column> = {
    header: "빈티지",
    key: "investmentDate",
    width: 15,
    style: { ...headerDefaultStyle },
  };
  const purchasePrincipal: Partial<ExcelJS.Column> = {
    header: "투자금",
    key: "purchasePrincipal",
    width: 15,
    style: { ...headerDefaultStyle },
  };

  const companyValue: Partial<ExcelJS.Column> = {
    header: "기업가치",
    key: "companyValue",
    width: 15,
    style: { ...headerDefaultStyle },
  };

  const ownershipPercentage: Partial<ExcelJS.Column> = {
    header: "지분율",
    key: "ownershipPercentage",
    width: 15,
    style: { ...headerDefaultStyle },
  };
  const realizedMultiple: Partial<ExcelJS.Column> = {
    header: "실현 멀티플",
    key: "realizedMultiple",
    width: 15,
    style: { ...headerDefaultStyle },
  };
  const unrealizedMultiple: Partial<ExcelJS.Column> = {
    header: "미실현 멀티플",
    key: "unrealizedMultiple",
    width: 15,
    style: { ...headerDefaultStyle },
  };
  const pfParticipant: Partial<ExcelJS.Column> = {
    header: "담당자(조력자)",
    key: "pfParticipant",
    width: 25,
    style: { ...headerDefaultStyle },
  };
  const pfIndustrialTech: Partial<ExcelJS.Column> = {
    header: "산업기술분류",
    key: "pfIndustrialTech",
    width: 25,
    style: { ...headerDefaultStyle },
  };
  const salesAmount: Partial<ExcelJS.Column> = {
    header: "매출",
    key: "salesAmount",
    width: 25,
    style: { ...headerDefaultStyle },
  };
  const round: Partial<ExcelJS.Column> = {
    header: "라운드",
    key: "round",
    width: 25,
    style: { ...headerDefaultStyle },
  };
  const description: Partial<ExcelJS.Column> = {
    header: "아이템 요약",
    key: "description",
    width: 50,
    style: { ...headerDefaultStyle },
  };
  const addr1: Partial<ExcelJS.Column> = {
    header: "소재지",
    key: "addr1",
    width: 15,
    style: { ...headerDefaultStyle },
  };

  const onClickExcelDownload = async () => {
    if (props.totalCount === 0 || props.totalCount === undefined) return;

    await updateDownloading(true);
    const data = await getAllAcceleratingList();
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("포트폴리오"); // sheet 이름이 My Sheet
    worksheet.columns = [
      portfolioId,
      companyName,
      acFundAccount,
      investmentDate,
      purchasePrincipal,
      companyValue,
      ownershipPercentage,
      realizedMultiple,
      unrealizedMultiple,
      pfParticipant,
      pfIndustrialTech,
      salesAmount,
      round,
      description,
      addr1,
    ];
    const row1 = worksheet.getRow(1);
    row1.height = 20;
    data?.forEach((item, index) => {
      worksheet.insertRow(index + 2, {
        ...item,
      });
    });
    // 다운로드
    const mimeType = {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `포트폴리오_전체_리스트.xlsx`);
    await updateDownloading(false);
  };
  const getAllAcceleratingList = async () => {
    try {
      const param = {
        ...props.getBodyBySearchObject(),
        pageIndex: 1,
        dataPerPage: props.totalCount,
      };
      const data = await getDatabaseAccelerating(param);
      if (data && data.data) {
        console.log(data);
        const portfolio = data.data.portfolio.map((item): DataProps => {
          return {
            portfolioId: `${item.portfolioId}`,
            companyName: item.companyName ? item.companyName : "-",
            acFundAccount: item.acFundAccount
              ? item.acFundAccount
                  .map(
                    (fund) => `${fund.nickName ? fund.nickName : fund.nameKo}`
                  )
                  .join(",")
              : "-",
            investmentDate: item.investmentDate
              ? item.investmentDate.slice(0, 4)
              : "-",
            purchasePrincipal: item.purchasePrincipal
              ? `${(item.purchasePrincipal / 100000000).toFixed(1)}억원`
              : "-",
            companyValue: item.companyValue
              ? `${(item.companyValue / 100000000).toFixed(1)}억원`
              : "-",
            ownershipPercentage: item.ownershipPercentage
              ? `${item.ownershipPercentage}%`
              : "-",
            realizedMultiple: item.realizedMultiple
              ? `${item.realizedMultiple.toFixed(2)}X`
              : "-",
            unrealizedMultiple: item.unrealizedMultiple
              ? `${item.unrealizedMultiple.toFixed(2)}X`
              : "-",
            pfParticipant: item.pfParticipant
              ? item.pfParticipant.map((user) => user.acUser.name).join(", ")
              : "-",
            pfIndustrialTech: item.pfIndustrialTech
              ? item.pfIndustrialTech
                  .map((industry) => `#${industry.acIndustrialTech.name}`)
                  .join(", ")
              : "-",
            salesAmount: item.salesAmount
              ? `${(item.salesAmount / 100000000).toFixed(1)}억원`
              : "-",
            round: item.round ? item.round.name : "-",
            description: item.description ? item.description : "-",
            addr1: item.addr1 ? item.addr1 : "-",
          };
        });
        return portfolio;
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <DefaultButton
        className="body-14-sb"
        sizeType="xsmall"
        buttonType="filled"
        text="전체 포트폴리오 다운로드"
        buttonColor="var(--green-500)"
        hoverColor="var(--green-700)"
        onClick={onClickExcelDownload}
      />
      <SpinnerModal
        message="관리중인 포트폴리오 다운로드...."
        open={downloading}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: #c4c4c4;

  user-select: none;
`;

export default AcceleratingExcelDownload;
