import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { FundAccountUsedRate } from "../../organism/FundAccountUsedRate";
import { IndustriTechRate } from "../../organism/IndustriTechRate.tsx";

import {
  OperatingFundProps,
  OperatingIndustryProps,
} from "../../../interface/PerformanceMetrics.interface";

import {
  getPerformanceOperatingFund,
  getPerformanceOperatingIndustry,
} from "../../../../../../api/repository/accelerator/DashboardRepository";
import { FundAccountLight } from "../../../../../../type/data";

interface Props {
  structureId?: number;
  selectedFundAccount?: FundAccountLight[];
}

export const OperationStatusManagementSection: React.FC<Props> = (props) => {
  const { structureId, selectedFundAccount } = props;

  const isNetworking = useRef<boolean>(false);
  const [fundData, setFundData] = useState<OperatingFundProps>({
    fund: [],
    orig: [],
  });
  const [industryData, setIndustryData] = useState<OperatingIndustryProps[]>(
    []
  );

  const getData = async () => {
    isNetworking.current = true;
    const fundResult = await getPerformanceOperatingFund({
      acFundAccountId: selectedFundAccount?.map((item) => item.acFundAccountId),
      acStructureId: structureId,
    });

    const industryResult = await getPerformanceOperatingIndustry({
      acFundAccountId: selectedFundAccount?.map((item) => item.acFundAccountId),
      acStructureId: structureId,
    });

    if (fundResult) {
      setFundData({ ...fundResult });
    }
    if (industryResult) {
      setIndustryData(industryResult);
    }
    isNetworking.current = false;
  };

  useEffect(() => {
    // if (isNetworking.current) return;
    getData();
  }, [structureId, selectedFundAccount]);

  return (
    <Container>
      <SectionHeader className="heading-20-sb">
        <span>운용현황 관리</span>
      </SectionHeader>
      <FlexColumn style={{ gap: 16 }}>
        <FundAccountUsedRate fundData={fundData} />
        <IndustriTechRate industryData={industryData} />
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
