import React, { useState } from "react";
import styled from "styled-components";
import useNotification from "../hook/useNotification";
import {
  NotificationCardProps,
  NotificationStatus,
} from "../interface/Notification.interface";
import NotificationCard from "../components/NotificationCard";
import AccordionTab from "../components/AccordionTab";
import { LoginInfo, SelectorProps } from "../../../type/data";
import { OrderingBtn } from "../components/OrderingBtn";
import { getNewDate, getYMD } from "../../../common/commonUtil";
import { useNavigate } from "react-router-dom";

interface Props {
  doneFinanceDataInput: boolean;
  myInfo: LoginInfo;
  notiGorup: SelectorProps[];
  selectedOrder: string;
  getFilteredMyTask: () => NotificationCardProps[];
  getMyTaskInProgress: () => NotificationCardProps[];
  goTolink: (card: NotificationCardProps, isNotification?: boolean) => void;
  onChangeOrder: (data: SelectorProps) => void;
}
export const MyTask: React.FC<Props> = (props) => {
  const {
    doneFinanceDataInput,
    myInfo,
    notiGorup,
    selectedOrder,
    getFilteredMyTask,
    getMyTaskInProgress,
    goTolink,
    onChangeOrder,
  } = props;
  const navigate = useNavigate();
  const getCount = (count: number) => {
    return count < 10 ? `0${count}` : `${count}`;
  };
  return (
    <Container>
      {!doneFinanceDataInput && (
        <AccordionTab title="데이터 입력" subTitle={getCount(1)}>
          <div
            onClick={() => {
              navigate("/finance-data-input");
            }}
          >
            <NotificationCard
              cardItem={{
                id: 999,
                status: NotificationStatus.PENDING,
                link: "",
                title: "재무정보 입력",
                description: "재무정보를 입력해주세요.",
                createdAt: getYMD(getNewDate()),
                category: {
                  id: 999,
                  title: "할일",
                  description: "asdfdasdf",
                  group: { id: 999, name: "모름" },
                },
                portfolio: {
                  portfolioId: 999999,
                  companyName: "재무정보",
                },
              }}
            />
          </div>
        </AccordionTab>
      )}
      <AccordionTab
        title="새로운 할일"
        subTitle={getCount(getFilteredMyTask().length)}
      >
        <FlexColumn style={{ gap: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <OrderingBtn
              defaultData={selectedOrder}
              placeholder="우선항목 선택"
              optionDescription="우선항목 선택"
              selectList={notiGorup}
              onChangeData={onChangeOrder}
            />
          </div>
          {getFilteredMyTask().map((item, index) => {
            return (
              <NotificationCard
                key={`newMyTaskCard${index}`}
                cardItem={item}
                goTolink={goTolink}
              />
            );
          })}
          {getFilteredMyTask().length === 0 && (
            <Empty className="body-14-md">새로운 할일이 없어요.</Empty>
          )}
        </FlexColumn>
      </AccordionTab>
      {myInfo.authorityId === 1 && <Divider />}
      {myInfo.authorityId === 1 && (
        <AccordionTab
          title="진행중"
          subTitle={getCount(getMyTaskInProgress().length)}
        >
          <FlexColumn style={{ gap: 10 }}>
            {getMyTaskInProgress().map((item, index) => {
              return (
                <NotificationCard
                  key={`progressCard${index}`}
                  cardItem={item}
                  goTolink={goTolink}
                />
              );
            })}
            {getMyTaskInProgress().length === 0 && (
              <Empty className="body-14-md">진행중인 일이 없어요.</Empty>
            )}
          </FlexColumn>
        </AccordionTab>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  max-height: 1px;
  min-height: 1px;
  background-color: var(--gray-200, #edeeef);
`;

const Empty = styled.div`
  width: 100%;
  height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--text-04, #7e8086);
  text-align: center;
`;
