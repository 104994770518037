import React, { useState } from "react";
import styled from "styled-components";

import { SBPContentsProps } from "../../interface/SBP.interface";
import { SBPSector } from "../SBPSector";

import { AutoResizableTextarea } from "../AutoResizableTextarea";
import { SBPSectorDescription } from "../SBPSectorDescription";

import RowTitleBox from "../RowTitleBox";

interface Props {
  isReadOnly: boolean;
  data?: SBPContentsProps;
  onChangeData?: (data: SBPContentsProps) => void;
}

export const SBPRequest: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;

  if (!data) return null;
  return (
    <Container>
      <SBPSector title="요청 사항" id="request1">
        <FlexColumn style={{ gap: 8 }}>
          <FlexColumn
            style={{
              padding: 28,
              borderRadius: 4,
              gap: 24,
              backgroundColor: "var(--background-contents01)",
              border: "1px solid var(--gray-300)",
            }}
          >
            <RowTitleBox
              textClassName="heading-14-md"
              title="담당 심사역"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                defaultData={
                  data.requestAndEvaluation.participant.requestContens
                }
                placeholder="입력한 내용이 없습니다."
                onChangeData={(value) => {
                  if (onChangeData) {
                    data.requestAndEvaluation.participant.requestContens =
                      value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>
            <RowTitleBox
              textClassName="heading-14-md"
              title="요청 사항"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AutoResizableTextarea
                isReadOnly={props.isReadOnly}
                isShowWordCounter
                defaultData={
                  data.requestAndEvaluation.bluepointPartners.requestContens
                }
                placeholder="입력한 내용이 없습니다."
                onChangeData={(value) => {
                  if (onChangeData) {
                    data.requestAndEvaluation.bluepointPartners.requestContens =
                      value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>
          </FlexColumn>
        </FlexColumn>
      </SBPSector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
