import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { SelectorProps } from "../../type/data";
import { RadioBox } from "./RadioBox";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  defaultData?: string;
  textClassName?: string;
  selectList: SelectorProps[];
  onChangeData?: (data: SelectorProps) => void;
}

export const Radio: React.FC<Props> = ({
  isReadOnly,
  disabled,
  defaultData,
  selectList,
  textClassName,
  onChangeData,
}) => {
  const [selectedItem, updateSelectedItem] = useState<
    SelectorProps | undefined
  >(undefined);

  useEffect(() => {
    console.log(defaultData);
    if (defaultData) {
      const target = selectList.find((item) => item.text === defaultData);
      console.log(target);
      updateSelectedItem(target);
    }
  }, [defaultData]);

  return (
    <Container>
      {selectList.map((item) => (
        <RadioBox
          className={textClassName}
          isChecked={selectedItem?.id === item.id}
          onClick={() => {
            updateSelectedItem(item);
            onChangeData && onChangeData(item);
          }}
        >
          {item.text}
        </RadioBox>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 12px;
`;
