import React from "react";
import styled from "styled-components";
import { BoxComponent } from "../../../../../components-2.0v/box/BoxComponent";

import { BusinessSummary } from "./BusinessSummary";
import { BusinessDetail } from "./BusinessDetail";

import { PortfolioPhase } from "../../../portfolio-detail/interface/PortfolioNavigation.interface";
import { BusinessInfoProps } from "../../../info/interface/PortfolioInfo.interface";
import { IndustryTech } from "../../../../../type/data";
import { BusinessLogo } from "./BusinessLogo";
import { ChangeDataProps } from "../../hook/usePortfolioInfoModify";
import { DealFlowPhase } from "../../../../deal-flow/interface/DealFlow.interface";

interface Props {
  portfolioPhase: PortfolioPhase;
  businessInfo: BusinessInfoProps;
  allIndustrialTech: IndustryTech[];
  phase?: DealFlowPhase;
  onChangeData: (data: ChangeDataProps) => void;
}
export const BusinessInfo: React.FC<Props> = (props) => {
  const {
    businessInfo,
    allIndustrialTech,
    phase,
    portfolioPhase,
    onChangeData,
  } = props;
  return (
    <BoxComponent style={{ padding: 0, gap: 0, marginBottom: 32 }}>
      <BusinessSummary
        businessInfo={businessInfo}
        portfolioPhase={portfolioPhase}
        allIndustrialTech={allIndustrialTech}
        phase={phase}
        onChangeData={onChangeData}
      />

      {portfolioPhase === "EXCAVATION" && (
        <div>
          <Divider />
          <BusinessDetail
            businessInfo={businessInfo}
            onChangeData={onChangeData}
          />
        </div>
      )}
      {portfolioPhase !== "EXCAVATION" && (
        <div>
          <Divider />
          <BusinessLogo
            businessInfo={businessInfo}
            onChangeData={onChangeData}
          />
        </div>
      )}
    </BoxComponent>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--gray-200);
`;
