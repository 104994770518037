import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import usePortfolio from "./hook/usePortfolio";
import ModalLayout from "../../../../components/modal/ModalLayout";
import NewNavigationLayout from "../../navigation/NewNavigationLayout";
import DefaultSearch from "../../../../components-2.0v/search/DefaultSearch";
import { PortfolioCard } from "./component/PortfolioCard";
import { DefaultButton } from "lattice_core";
import { ReactComponent as Close } from "../../../../assets/image/excavation_close.svg";
import { PortfolioDetail } from "./organism/PortfolioDetail";
import { ChangeParticipant } from "./organism/ChangeParticipant";
import {
  MAIN_PARTICIPANT,
  PHASE_CLOSURE,
  PHASE_DAMAGE,
  PHASE_EXIT,
  PHASE_MANAGE,
} from "./interface/Portfolio.interface";
import { ChangeExcavator } from "./organism/ChangeExcavator";
import { InputDateModal } from "./organism/InputDateModal";
import { InvestmentExit } from "./organism/exit/InvestmentExit";
import { InvestExitHistoryDetail } from "./organism/InvestExitHistoryDetail";
import { excelDownloadFairValueAmount } from "../../../../excel-download/createFairValueAmount";
import { FairValueAmountModal } from "./organism/fair-value/FairValueAmountModal";
import { RegisteFairValueModal } from "./organism/RegisteFairValueModal";

const Portfolio = () => {
  const [
    contentsBodyRef,
    selectedFairValue,
    searchKeyword,
    allUser,
    selectedPortfolio,
    selectedPortfolioDetail,
    investExitData,
    isDetailShow,
    openChangeParticipantModal,
    openChangeExcavatorModal,
    openChangePhaseDamage,
    openChangePhaseClosure,
    openExitModal,
    importFairValueDataModal,
    portfolioFairValueDataModal,
    updateSearchKeyword,
    getFilterPortfolio,
    onClickPortfolio,
    closeDetail,
    onClickParticipantChange,
    closeParticipantChange,
    changeParticipant,
    onClickExcavatorChange,
    closeExcavatorChange,
    changeExcavator,
    chagneStatusDamage,
    chagneStatusClosure,
    chagneStatusUndo,
    onClickPortfolioStatus,
    closePortfolioStatus,
    onClickExit,
    closeExit,
    onExitPortfolio,
    onClickInvestExitHistoryRow,
    closeInvestExitModal,
    onExitCancel,
    onClickImportFairValueData,
    closeImportFairValueDataModal,
    getPortfolio,
    onClickPortfolioFairValueData,
    closePortfolioFairValueDataModal,
  ] = usePortfolio();

  return (
    <NewNavigationLayout>
      <Wrapper>
        <ExcavationContainer>
          <ContentsHeader>
            <span className="heading-20-sb" style={{ color: "var(--text-01)" }}>
              포트폴리오 관리
            </span>
            <FlexRow style={{ gap: 12 }}>
              <DefaultButton
                text="공정가치 평가금액 일괄입력"
                buttonType="filled"
                sizeType="small"
                buttonColor={"var(--primary-purple)"}
                hoverColor="var(--purple-600)"
                onClick={() => {
                  onClickImportFairValueData();
                }}
              />
              <DefaultSearch
                value={searchKeyword}
                placeholder="기업명으로 검색해 주세요."
                transfromPixel={(n) => n}
                onChangeValue={(value) => {
                  updateSearchKeyword(value);
                }}
                onPressEnter={(value) => {
                  updateSearchKeyword(value);
                }}
              />
            </FlexRow>
          </ContentsHeader>
          <ContentsBodyWrapper>
            <ContentsBody
              id="body__component"
              ref={contentsBodyRef}
              className={isDetailShow ? "isShow" : ""}
            >
              <ExcavationList>
                {getFilterPortfolio().map((item, index) => {
                  return (
                    <PortfolioCard
                      id={`EP_${item.portfolioId}`}
                      key={index}
                      portfolio={item}
                      isSelected={
                        item.portfolioId === selectedPortfolio?.portfolioId
                      }
                      isShowDetail={isDetailShow}
                      onClickCard={() => {
                        onClickPortfolio(item);
                      }}
                    />
                  );
                })}
              </ExcavationList>
            </ContentsBody>
            <ContentsDetailWrapper className={isDetailShow ? "isShow" : ""}>
              <ContentsDetail id="root__component">
                {isDetailShow &&
                  selectedPortfolio &&
                  selectedPortfolioDetail && (
                    <PortfolioDetail
                      portfolio={selectedPortfolioDetail}
                      onClickInvestExitHistoryRow={onClickInvestExitHistoryRow}
                      onClickParticipantChange={onClickParticipantChange}
                      onClickExcavatorChange={onClickExcavatorChange}
                      onClickPortfolioFairValueData={
                        onClickPortfolioFairValueData
                      }
                      onClickExit={onClickExit}
                    />
                  )}
              </ContentsDetail>
              {selectedPortfolioDetail && isDetailShow && (
                <DetailFooter>
                  <div>
                    {selectedPortfolioDetail.pfPhase.pfPhaseId ===
                      PHASE_DAMAGE && (
                      <DefaultButton
                        className="heading-16-md"
                        buttonType="outline"
                        sizeType="small"
                        buttonColor={"var(--red-500)"}
                        borderColor={"#D5D6D8"}
                        text="손상 취소"
                        onClick={(e) => {
                          e && e.stopPropagation();
                          chagneStatusUndo();
                        }}
                      />
                    )}
                    {selectedPortfolioDetail.pfPhase.pfPhaseId ===
                      PHASE_EXIT && (
                      <DefaultButton
                        className="heading-16-md"
                        buttonType="outline"
                        sizeType="small"
                        buttonColor={"var(--red-500)"}
                        borderColor={"#D5D6D8"}
                        text="엑싯 취소"
                        onClick={(e) => {
                          e && e.stopPropagation();
                          chagneStatusUndo();
                        }}
                      />
                    )}
                    {selectedPortfolioDetail.pfPhase.pfPhaseId ===
                      PHASE_CLOSURE && (
                      <DefaultButton
                        className="heading-16-md"
                        buttonType="outline"
                        sizeType="small"
                        buttonColor={"var(--red-500)"}
                        borderColor={"#D5D6D8"}
                        text="폐업 취소"
                        onClick={(e) => {
                          e && e.stopPropagation();
                          chagneStatusUndo();
                        }}
                      />
                    )}
                  </div>
                  <FlexRow style={{ gap: 12 }}>
                    {[PHASE_MANAGE].includes(
                      selectedPortfolioDetail.pfPhase.pfPhaseId
                    ) && (
                      <DefaultButton
                        className="heading-16-md"
                        buttonType="filled"
                        sizeType="small"
                        buttonColor={"var(--primary-purple)"}
                        hoverColor="var(--purple-600)"
                        text="투자철회"
                        onClick={(e) => {
                          e && e.stopPropagation();
                        }}
                      />
                    )}
                    {[PHASE_MANAGE].includes(
                      selectedPortfolioDetail.pfPhase.pfPhaseId
                    ) && (
                      <DefaultButton
                        className="heading-16-md"
                        buttonType="filled"
                        sizeType="small"
                        buttonColor={"var(--primary-purple)"}
                        hoverColor="var(--purple-600)"
                        text="손상"
                        onClick={(e) => {
                          e && e.stopPropagation();
                          onClickPortfolioStatus(PHASE_DAMAGE);
                        }}
                      />
                    )}
                    {[PHASE_MANAGE, PHASE_DAMAGE].includes(
                      selectedPortfolioDetail.pfPhase.pfPhaseId
                    ) && (
                      <DefaultButton
                        className="heading-16-md"
                        buttonType="filled"
                        sizeType="small"
                        buttonColor={"var(--primary-purple)"}
                        hoverColor="var(--purple-600)"
                        text="폐업"
                        onClick={(e) => {
                          e && e.stopPropagation();
                          onClickPortfolioStatus(PHASE_CLOSURE);
                        }}
                      />
                    )}
                  </FlexRow>
                </DetailFooter>
              )}
              {isDetailShow && (
                <DetailCloseBtn
                  onClick={() => {
                    closeDetail();
                  }}
                >
                  <Close />
                </DetailCloseBtn>
              )}
            </ContentsDetailWrapper>
          </ContentsBodyWrapper>

          {selectedPortfolioDetail && openExitModal && (
            <ModalLayout isOpen={openExitModal} isFullScreen toggle={closeExit}>
              <InvestmentExit
                close={closeExit}
                exit={onExitPortfolio}
                selectedPortfolioDetail={selectedPortfolioDetail}
              />
            </ModalLayout>
          )}
          {openChangePhaseDamage && (
            <ModalLayout
              isOpen={openChangePhaseDamage}
              isFullScreen
              toggle={() => {
                closePortfolioStatus(PHASE_DAMAGE);
              }}
            >
              <InputDateModal
                title="손상처리"
                close={() => {
                  closePortfolioStatus(PHASE_DAMAGE);
                }}
                submit={(date) => {
                  chagneStatusDamage(date);
                }}
              />
            </ModalLayout>
          )}

          {openChangePhaseClosure && (
            <ModalLayout
              isOpen={openChangePhaseClosure}
              isFullScreen
              toggle={() => {
                closePortfolioStatus(PHASE_CLOSURE);
              }}
            >
              <InputDateModal
                title="폐업처리"
                close={() => {
                  closePortfolioStatus(PHASE_CLOSURE);
                }}
                submit={(date) => {
                  chagneStatusClosure(date);
                }}
              />
            </ModalLayout>
          )}

          {openChangeExcavatorModal && (
            <ModalLayout
              isOpen={openChangeExcavatorModal}
              isFullScreen
              toggle={closeExcavatorChange}
            >
              <ChangeExcavator
                allUser={allUser}
                defaultExcavation={
                  selectedPortfolioDetail
                    ? selectedPortfolioDetail.excavator
                    : undefined
                }
                close={closeExcavatorChange}
                setValue={(user) => {
                  changeExcavator(user.data);
                }}
              />
            </ModalLayout>
          )}

          {openChangeParticipantModal && (
            <ModalLayout
              isOpen={openChangeParticipantModal}
              isFullScreen
              toggle={closeParticipantChange}
            >
              <ChangeParticipant
                allUser={allUser}
                defaultMainParticipant={
                  selectedPortfolio
                    ? selectedPortfolio.pfParticipant.find(
                        (item) =>
                          item.screenRole.screenRoleId === MAIN_PARTICIPANT
                      )?.acUser
                    : undefined
                }
                close={closeParticipantChange}
                setValue={(user, reason) => {
                  changeParticipant(user.data, reason);
                }}
              />
            </ModalLayout>
          )}

          {investExitData && (
            <ModalLayout
              isOpen={investExitData !== undefined}
              isFullScreen
              toggle={closeInvestExitModal}
            >
              <InvestExitHistoryDetail
                data={investExitData}
                close={closeInvestExitModal}
                onClickExitCancel={onExitCancel}
              />
            </ModalLayout>
          )}

          {importFairValueDataModal && (
            <ModalLayout isOpen={importFairValueDataModal} isFullScreen>
              <FairValueAmountModal
                close={closeImportFairValueDataModal}
                submit={() => {
                  closeImportFairValueDataModal();
                  getPortfolio();
                }}
              />
            </ModalLayout>
          )}

          {portfolioFairValueDataModal && selectedPortfolioDetail && (
            <ModalLayout
              isOpen={portfolioFairValueDataModal}
              isFullScreen
              toggle={closePortfolioFairValueDataModal}
            >
              <RegisteFairValueModal
                close={closePortfolioFairValueDataModal}
                portfolioId={selectedPortfolioDetail.portfolioId}
                paymentDate={selectedPortfolioDetail.firstPaymentDate || ""}
                portfolioFairValue={selectedPortfolioDetail.pfFairValue || []}
                defaultYear={selectedFairValue?.year}
                submit={() => {
                  closePortfolioFairValueDataModal();
                  getPortfolio();
                }}
              />
            </ModalLayout>
          )}
        </ExcavationContainer>
      </Wrapper>
    </NewNavigationLayout>
  );
};
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: scroll;
`;
const ExcavationContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 1320px;

  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  width: 100%;
  height: 62px;
  min-height: 62px;
  max-height: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;

  border-bottom: 1px solid var(--divider-200);
  background: var(--background-header);
`;
const ContentsBodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;
const ContentsBody = styled.div`
  flex: 1 0 0;
  height: 100%;
  max-width: calc(282px * 4 + 96px);
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  margin: 0 auto;
  overflow: scroll;
  gap: 32px;

  &.isShow {
    max-width: 282px;
  }
`;
const ContentsDetail = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 66px);
  overflow: scroll;
`;

const ContentsDetailWrapper = styled.div`
  position: relative;
  width: 0px;
  min-width: 0px;
  height: 100%;
  overflow: hidden;
  transition: all 0.2s ease-in;

  &.isShow {
    width: calc(100% - 282px - 120px);
    min-width: 960px;
    overflow: hidden;

    border-left: 1px solid var(--gray-200, #edeeef);
    background: var(--background-page, #fcfcfd);
    box-shadow: -2px 2px 10px 0px rgba(100, 103, 109, 0.1);
    transition: all 0.2s ease-in;
  }
`;

const DetailCloseBtn = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  top: 50%;
  left: 0;

  transform: translateY(-50%);

  width: 24px;
  height: 48px;
  min-width: 24px;
  min-height: 48px;
  max-width: 24px;
  max-height: 48px;

  border-radius: 0px 4px 4px 0px;
  border-top: 1px solid var(---gray-200, #edeeef);
  border-right: 1px solid var(---gray-200, #edeeef);
  border-bottom: 1px solid var(---gray-200, #edeeef);
  background: var(--background-contents-01, #fff);

  cursor: pointer;
`;

const DetailFooter = styled.div`
  position: absolute;
  width: 100%;
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  bottom: 0px;
  padding: 0 60px;

  border-top: 2px solid var(--divider-200, #edeeef);
  background: var(--background-header, #fff);
`;
const ExcavationList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default Portfolio;
