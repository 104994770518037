import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../../assets/image/modal_close.svg";
import { ReactComponent as Info } from "../../../../../../assets/image/icon_info.svg";
import { DefaultButton } from "lattice_core";

import InputBox from "../../components/InputBox";
import { InvestmentAssessment } from "../../../../../../type/data";
import { AddComma, RemoveComma } from "../../../../../../common/commonUtil";
import ColumnTitleBox from "../../../../../../components/box/ColumnTitleBox";

interface Props {
  document: InvestmentAssessment;
  isUpdateAuth: boolean;
  close: () => void;
  transfromPixel: (value: number) => number;
  onChangeDocument: (item: InvestmentAssessment) => void;
}
const EvaluationCommonInfoModal: React.FC<Props> = (props) => {
  const { document, isUpdateAuth, close, transfromPixel, onChangeDocument } =
    props;
  const TITLE_WIDTH = 250;

  // page 1
  const [issuingPrice, updateIssuingPrice] = useState<string>("");
  const [postTotalStockNumber, updatePostTotalStockNumber] =
    useState<string>("");
  const [previousTotalStockNumber, updatePreviousTotalStockNumber] =
    useState<string>("");
  const [faceValue, updateFaceValue] = useState<string>(""); // 액면가

  const convertNumber = (value: string | number) => {
    const _value = RemoveComma(`${value}`);
    return parseInt(_value, 10);
  };

  const getShareholdingRatio = (value: number) => {
    const totalStockNumber =
      convertNumber(previousTotalStockNumber) +
      convertNumber(postTotalStockNumber);

    return parseFloat(((value / totalStockNumber) * 100).toFixed(2));
  };

  const getPreviousComapnyValue = () => {
    const _issuingPrice =
      issuingPrice.length > 0 ? parseInt(RemoveComma(issuingPrice), 10) : 0;
    const ptStockNumber =
      previousTotalStockNumber.length > 0
        ? parseInt(RemoveComma(previousTotalStockNumber), 10)
        : 0;

    const _previousCompanyValue =
      _issuingPrice !== 0 && ptStockNumber !== 0
        ? `${_issuingPrice * ptStockNumber}`
        : "0";
    return _previousCompanyValue;
  };

  const getTotalInvestmentAmount = () => {
    const _issuingPrice =
      issuingPrice.length > 0 ? parseInt(RemoveComma(issuingPrice), 10) : 0;
    const ptStockNumber =
      postTotalStockNumber.length > 0
        ? parseInt(RemoveComma(postTotalStockNumber), 10)
        : 0;

    const _totalInvestmentAmount =
      _issuingPrice !== 0 && ptStockNumber !== 0
        ? `${_issuingPrice * ptStockNumber}`
        : "0";
    return _totalInvestmentAmount;
  };

  const getPostCompanyValue = () => {
    const _previousValue = convertNumber(getPreviousComapnyValue());
    const _totalInvestmentAmount = convertNumber(getTotalInvestmentAmount());
    const _postCompanyValue =
      _previousValue !== 0 && _totalInvestmentAmount !== 0
        ? `${_previousValue + _totalInvestmentAmount}`
        : "0";
    return _postCompanyValue;
  };
  const validationCheckPage1 = () => {
    return (
      issuingPrice.length !== 0 &&
      postTotalStockNumber.length !== 0 &&
      previousTotalStockNumber.length !== 0 &&
      faceValue.length !== 0
    );
  };

  const modifyCommonInfo = () => {
    const cloneDocument: InvestmentAssessment = {
      ...structuredClone(document),
    };
    cloneDocument.investmentAssessmentReportAccount.forEach((item) => {
      const account = item.investmentAssessmentAccount;

      account.previousValue = convertNumber(getPreviousComapnyValue());
      account.postCompanyValue = convertNumber(getPostCompanyValue());
      account.totalInvestmentAmount = convertNumber(getTotalInvestmentAmount());
      account.postTotalStockNumber = convertNumber(postTotalStockNumber);
      account.previousTotalStockNumber = convertNumber(
        previousTotalStockNumber
      );
      account.issuingPrice = convertNumber(issuingPrice);
      account.shareholdingRatio = getShareholdingRatio(
        account.stockNumber || 0
      );
      account.faceValue = convertNumber(faceValue);
    });
    onChangeDocument(cloneDocument);
  };

  const init = () => {
    if (document.investmentAssessmentReportAccount.length > 0) {
      const account =
        document.investmentAssessmentReportAccount[0]
          .investmentAssessmentAccount;
      updateIssuingPrice(AddComma(account.issuingPrice));
      updatePostTotalStockNumber(AddComma(account.postTotalStockNumber));
      updatePreviousTotalStockNumber(
        AddComma(account.previousTotalStockNumber)
      );
      updateFaceValue(AddComma(account.faceValue));
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          라운드 정보 {isUpdateAuth ? "수정" : ""}
        </span>
        <div className="close">
          <Close onClick={close} />
        </div>
      </Header>
      <Body>
        <PageContainer>
          {isUpdateAuth ? (
            <DescriptionContainer className="caption-12-rg">
              <Info style={{ minWidth: 16, minHeight: 16 }} />
              <div>
                클럽딜의 경우 다른 투자사들의 투자 내용도 함께 포함해 값을
                작성해 주세요.
                <br />
                구주거래일 경우 액면가를 제외한 모든 입력란에 0을 입력해주세요.
              </div>
            </DescriptionContainer>
          ) : (
            <div className="heading-14-md">
              다른 투자사들을 포함한 이번 라운드 정보 입니다.
            </div>
          )}

          <ColumnTitleBox
            textClassName="heading-14-md"
            title="투자 단가 (A)"
            titleWidth={TITLE_WIDTH}
            transfromPixel={transfromPixel}
          >
            <InputBox
              readOnly={!isUpdateAuth}
              textType="numberWithComma"
              transfromPixel={transfromPixel}
              unit="원"
              placeholder="0"
              defaultValue={issuingPrice}
              onChange={(value) => {
                updateIssuingPrice(value);
              }}
            />
          </ColumnTitleBox>
          <ColumnTitleBox
            textClassName="heading-14-md"
            title="기 발행 주식 수 (B)"
            titleWidth={TITLE_WIDTH}
            transfromPixel={transfromPixel}
          >
            <InputBox
              readOnly={!isUpdateAuth}
              textType="numberWithComma"
              transfromPixel={transfromPixel}
              unit="주"
              placeholder="0"
              defaultValue={previousTotalStockNumber}
              onChange={(value) => {
                updatePreviousTotalStockNumber(value);
              }}
            />
          </ColumnTitleBox>

          <ColumnTitleBox
            textClassName="heading-14-md"
            title="총 발행 주식 수(C)"
            titleWidth={TITLE_WIDTH}
            transfromPixel={transfromPixel}
          >
            <InputBox
              readOnly={!isUpdateAuth}
              textType="numberWithComma"
              transfromPixel={transfromPixel}
              unit="주"
              placeholder="0"
              defaultValue={postTotalStockNumber}
              onChange={(value) => {
                updatePostTotalStockNumber(value);
              }}
            />
          </ColumnTitleBox>
          <ColumnTitleBox
            textClassName="heading-14-md"
            title="액면가 (D)"
            titleWidth={TITLE_WIDTH}
            transfromPixel={transfromPixel}
          >
            <InputBox
              readOnly={!isUpdateAuth}
              textType="numberWithComma"
              transfromPixel={transfromPixel}
              unit="원"
              placeholder="0"
              defaultValue={faceValue}
              onChange={(value) => {
                updateFaceValue(value);
              }}
            />
          </ColumnTitleBox>

          <ColumnTitleBox
            textClassName="heading-14-md"
            title="투자 전 기업 가치 (A*B)"
            titleWidth={TITLE_WIDTH}
            transfromPixel={transfromPixel}
          >
            <InputBox
              readOnly
              textType="numberWithComma"
              transfromPixel={transfromPixel}
              unit="원"
              placeholder="0"
              defaultValue={getPreviousComapnyValue()}
            />
          </ColumnTitleBox>
          <ColumnTitleBox
            textClassName="heading-14-md"
            title="투자 후 기업 가치 (A*(B+C))"
            titleWidth={TITLE_WIDTH}
            transfromPixel={transfromPixel}
          >
            <InputBox
              readOnly
              textType="numberWithComma"
              transfromPixel={transfromPixel}
              unit="원"
              placeholder="0"
              defaultValue={getPostCompanyValue()}
            />
          </ColumnTitleBox>
          <ColumnTitleBox
            textClassName="heading-14-md"
            title="총 투자 금액 (A*C)"
            titleWidth={TITLE_WIDTH}
            transfromPixel={transfromPixel}
          >
            <InputBox
              readOnly
              textType="numberWithComma"
              transfromPixel={transfromPixel}
              unit="원"
              width={188}
              placeholder="0"
              defaultValue={getTotalInvestmentAmount()}
            />
          </ColumnTitleBox>
        </PageContainer>
      </Body>
      <Footer>
        <ButtonContainer>
          {isUpdateAuth && (
            <DefaultButton
              className="heading-16-md"
              buttonType="text"
              sizeType="small"
              buttonColor={"#1F2022"}
              text={"취소"}
              onClick={() => {
                close();
              }}
              transfromPixel={transfromPixel}
            />
          )}
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            disabled={isUpdateAuth ? !validationCheckPage1() : false}
            text={isUpdateAuth ? "완료" : "확인"}
            onClick={() => {
              if (isUpdateAuth) {
                modifyCommonInfo();
              } else {
                close();
              }
            }}
            transfromPixel={props.transfromPixel}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 90vw;
  height: 90vh;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

const PageContainer = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0 24px;
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  min-height: 72px;
  max-height: 72px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 24px 0px 0px;
  gap: 12px;
`;

const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  padding: 8px;
  gap: 4px;

  margin-top: 8px;

  background-color: var(--gray-50);
  color: var(--text-04);
  border-radius: 4px;
`;

export default EvaluationCommonInfoModal;
