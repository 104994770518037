import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { TableGridHeaderProps, TableGridProps } from "./TableGrid";
import { ReactComponent as Delete } from "../../../../../assets/image/icon_table_delete.svg";
import { AddComma } from "../../../../../common/commonUtil";

interface Props {
  headerData: TableGridHeaderProps[];
  allRowData: TableGridProps[];
  transfromPixel: (value: number) => number;
}

const TableRow: React.FC<Props> = ({
  headerData,
  allRowData,
  transfromPixel,
}) => {
  const getSumData = (index: number, key: string) => {
    switch (index) {
      case 0:
        return "합계";
      case 1: {
        const sumData = allRowData
          .map((item) => item.data[key] || 0)
          .reduce((prev, cur) => prev + cur, 0);
        return `${AddComma(sumData)} 주`;
      }
      case 2: {
        const sumData = allRowData
          .map((item) => item.data[key] || 0)
          .reduce((prev, cur) => prev + cur, 0);
        return `${sumData} %`;
      }
      case 3: {
        const sumData = allRowData
          .map((item) => item.data[key] || 0)
          .reduce((prev, cur) => prev + cur, 0);
        return `${AddComma(sumData)} 주`;
      }
      case 4: {
        const sumData = allRowData
          .map((item) => item.data[key] || 0)
          .reduce((prev, cur) => prev + cur, 0);
        return `${sumData} %`;
      }
      default:
        return "";
    }
  };
  return (
    <Container
      className="body-14-rg"
      style={{
        cursor: "default",
        minHeight: transfromPixel(50),
      }}
    >
      {headerData.map((item, idx) => {
        return (
          <CellContainer
            key={idx}
            style={{
              width: item.width || 100,
              minWidth: item.width || 100,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {getSumData(idx, item.key)}
          </CellContainer>
        );
      })}
      <CellContainer
        style={{
          width: 74,
          minWidth: 74,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingTop: 8,
          borderLeft: "1px solid transparent",
        }}
      ></CellContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: row;
  transform: all 0.2s ease;
  background-color: #ffffff;
`;

const CellContainer = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  border-left: 1px solid var(--divider-200);
  border-bottom: 1px solid var(--divider-200);

  &:nth-child(1) {
    border-left: 1px solid transparent;
  }
`;
export default React.memo(TableRow);
