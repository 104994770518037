import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { DBTotalSearchPortfolio, DPBPTotalSearch } from "../../../../type/data";
import DBPortfolioPhase from "./DBPortfolioPhase";
import ColumnTitleBox from "../../../../components/box/ColumnTitleBox";
import PortfolioParticipant from "./PortfolioParticipant";
import RowTitleBox from "../../../../components/box/RowTitleBox";

interface Props {
  data: DBTotalSearchPortfolio;
  onClickRow?: () => void;
}

const TotalCard: React.FC<Props> = (props) => {
  const { data, onClickRow } = props;
  return (
    <Container
      onClick={() => {
        onClickRow && onClickRow();
      }}
    >
      <FlexRow
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <div className="heading-18-sb">{data.companyName}</div>
        <div>
          <DBPortfolioPhase phase={data.pfPhase} />
        </div>
      </FlexRow>
      <div className="caption-12-rg" style={{ color: "var(--primary-blue)" }}>
        {data.pfIndustrialTech
          .map((item: any) => `#${item.acIndustrialTech.name}`)
          .join(", ")}
      </div>
      <div className="description body-14-rg">{data.description}</div>
      <FlexRow
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <ColumnTitleBox title="담당자">
          <PortfolioParticipant participants={data.pfParticipant} />
        </ColumnTitleBox>
        <ColumnTitleBox title="채널">
          <div className="body-14-rg" style={{ marginTop: 6 }}>
            {data.acChannel.name}
          </div>
        </ColumnTitleBox>
      </FlexRow>
    </Container>
  );
};

const Container = styled.tr`
  position: relative;
  width: 100%;
  height: max-content;
  padding: 6px 24px;
  display: flex;
  flex-direction: column;
  transform: all 0.2s ease;
  background-color: #ffffff;
  border-bottom: 1px solid var(--gray-300);

  &.checked__row {
    background-color: #fafafb;
  }

  .description {
    width: calc(100vw - 48px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default React.memo(TotalCard);
