import { SelectorProps } from "../../../../type/data";

export interface SectionTabProps {
  id: number;
  name: string;
  isComplete: boolean;
  tabSectionList: SectionValidationProps[];
}

export interface SectionValidationProps {
  index: number;
  isComplete: boolean;
  sectionTitle: string;
  selectId: string;
}

export const fullTimeStatusList: SelectorProps[] = [
  { id: 1, text: "여", data: "여" },
  { id: 2, text: "부", data: "부" },
];

export const relationshipList: SelectorProps[] = [
  { id: 1, text: "본인", data: "본인" },
  { id: 2, text: "가족", data: "가족" },
  { id: 3, text: "친인척", data: "친인척" },
  { id: 4, text: "출신 학교", data: "출신 학교" },
  { id: 5, text: "이전 직장", data: "이전 직장" },
  { id: 6, text: "지인 추천 채용", data: "지인 추천 채용" },
  { id: 7, text: "일반 채용", data: "일반 채용" },
];

export const registrationStatusList: SelectorProps[] = [
  { id: 1, text: "여", data: "여" },
  { id: 2, text: "부", data: "부" },
];

export const capitalIsuanceTypeList: SelectorProps[] = [
  { id: 1, text: "설립자본금", data: "설립자본금" },
  { id: 2, text: "유상증자", data: "유상증자" },
  { id: 3, text: "무상증자", data: "무상증자" },
  { id: 4, text: "가수금증자", data: "가수금증자" },
];

export const domesticInternationalTypeList: SelectorProps[] = [
  { id: 1, text: "국내", data: "국내" },
  { id: 2, text: "국외", data: "국외" },
];

export const halfTypeList: SelectorProps[] = [
  { id: 1, text: "상반기", data: "상반기" },
  { id: 2, text: "하반기", data: "하반기" },
];

export const capitalIncreaseTypeList: SelectorProps[] = [
  { id: 1, text: "신주발행", data: "신주발행" },
  { id: 2, text: "자금차입", data: "자금차입" },
  { id: 3, text: "정부지원금", data: "정부지원금" },
  { id: 4, text: "가수금", data: "가수금" },
  { id: 5, text: "기타", data: "기타" },
];

export const investmentStageTypeList: SelectorProps[] = [
  { id: 1, text: "Seed", data: "Seed" },
  { id: 2, text: "Pre-A", data: "Pre-A" },
  { id: 3, text: "Series-A", data: "Series-A" },
  { id: 4, text: "Series-B", data: "Series-B" },
  { id: 5, text: "Series-C", data: "Series-C" },
  { id: 6, text: "Series-D", data: "Series-D" },
];
