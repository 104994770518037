import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../SummaryBusinessPlanV2Download";

interface Props {
  currentPage?: number;
  totalPage?: number;
}

export const Footer: React.FC<Props> = (props) => {
  return (
    <Container className="heading-16-md" convertPixelToCm={convertPixelToCm}>
      {`${props.currentPage || 0}`.padStart(2, "0")}/
      {`${props.totalPage || 0}`.padStart(2, "0")}
    </Container>
  );
};

const Container = styled.div<{ convertPixelToCm: (n: number) => number }>`
  width: 100%;
  height: ${(props) => props.convertPixelToCm(52)}px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;

  padding-top: ${(props) => props.convertPixelToCm(12)}px;

  span {
    width: max-content;
    min-width: max-content;
  }
`;

const Divier = styled.div<{ convertPixelToCm: (n: number) => number }>`
  width: 100%;
  height: 1px;
  background-color: var(--primary-black);
`;
