import React, { useState } from "react";
import styled from "styled-components";

import { PerformanceManageSection } from "./PerformanceManageSection";
import { FundAccountLight } from "../../../../../../type/data";
import { getNewDate } from "../../../../../../common/commonUtil";
import { DateRangeProps } from "../../component/SelectRange";
import { InvestmentPerformanceReview } from "../../../../fund-account-detail/mobile/organism/InvestmentPerformanceReview";

interface Props {
  structureId?: number;
  selectedFundAccount?: FundAccountLight[];
}
export const PerformanceSection: React.FC<Props> = (props) => {
  const { structureId, selectedFundAccount } = props;

  const defaultRangeDate: DateRangeProps = {
    startYear: `${getNewDate().getFullYear() - 1}`,
    startQuarter: "1",
    endYear: `${getNewDate().getFullYear()}`,
    endQuarter: "4",
  };

  return (
    <Container>
      <SectionHeader className="heading-20-sb">
        <span>투자성과</span>
      </SectionHeader>
      <FlexColumn style={{ gap: 16 }}>
        <InvestmentPerformanceReview
          showTable
          structureId={structureId}
          selectedFundAccountId={selectedFundAccount?.map(
            (item) => item.acFundAccountId
          )}
        />
        <PerformanceManageSection
          structureId={structureId}
          selectedFundAccountId={selectedFundAccount?.map(
            (item) => item.acFundAccountId
          )}
          defaultRangeDate={defaultRangeDate}
        />
      </FlexColumn>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 12px;
`;

const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
