import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FundsBorrowed, GovermentTask } from "../../../interface/SBP.interface";
import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  fundDataList?: FundsBorrowed[];
  onDataChange: (data: FundsBorrowed[]) => void;
}

export const FundBorrowComponent: React.FC<Props> = (props) => {
  const [fundDataList, updateFundDataList] = useState<
    FundsBorrowed[] | undefined
  >(undefined);
  const headers: TableGridHeaderProps[] = [
    {
      header: "기관",
      key: "borrowingAgency",
      width: 220,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            transfromPixel={(n) => n}
            placeholder="기술신용보증기금"
            defaultValue={data}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "시작 일자",
      key: "borrowingDate",
      width: 182,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            defaultDate={data}
            onChangeDate={(date) => {
              console.log(date);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "상환 기한",
      key: "repaymentDue",
      width: 182,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomDate
            defaultDate={data}
            onChangeDate={(date) => {
              console.log(date);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "자금 목적",
      key: "fundPurpose",
      width: 186,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="text"
            transfromPixel={(n) => n}
            defaultValue={data}
            placeholder="연구장비 구입"
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "차입 금액 (원)",
      key: "borrowedAmount",
      width: 178,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            transfromPixel={(n) => n}
            defaultValue={data}
            placeholder="100,000,000"
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "상환 후 잔액 (원)",
      key: "outstandingBalance",
      width: 178,
      customCell: (data, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            transfromPixel={(n) => n}
            defaultValue={data}
            placeholder="30,000,000"
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newDate: FundsBorrowed = {};
    const newfundData: FundsBorrowed[] = [...(fundDataList || []), newDate];
    updateFundDataList(newfundData);
  };

  const onClickDeleteBtn = (id: number) => {
    if (!fundDataList) return;
    const newfundData = fundDataList.filter((item, index) => index !== id);
    updateFundDataList([...newfundData]);
  };

  useEffect(() => {
    updateFundDataList(props.fundDataList);
  }, [props.fundDataList]);

  useEffect(() => {
    if (fundDataList) props.onDataChange([...fundDataList]);
  }, [fundDataList]);

  if (!fundDataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={fundDataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;
