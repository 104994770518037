import React, { useState } from "react";
import styled from "styled-components";
import useNotification from "../hook/useNotification";
import {
  NotificationCardProps,
  NotificationStatus,
  notiOrderList,
} from "../interface/Notification.interface";
import NotificationCard from "../components/NotificationCard";
import AccordionTab from "../components/AccordionTab";
import { SelectorProps } from "../../../type/data";
import { OrderingBtn } from "../components/OrderingBtn";
import { DefaultButton } from "lattice_core";

interface Props {
  selectedOrderNoti: number;
  goTolink: (card: NotificationCardProps, isNotification?: boolean) => void;
  onChangeNotiOrder: (data: SelectorProps) => void;
  deleteNotificationById: (id: number) => void;
  deleteReadNotification: () => void;
  getOrderedNotification: () => NotificationCardProps[];
}
export const Noti: React.FC<Props> = (props) => {
  const {
    selectedOrderNoti,
    goTolink,
    onChangeNotiOrder,
    deleteNotificationById,
    deleteReadNotification,
    getOrderedNotification,
  } = props;

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DefaultButton
          sizeType="xxsmall"
          className="caption-12-md"
          buttonType="colored-outline"
          buttonColor="var(--text-01)"
          borderColor="var(--gray-300)"
          disabled={
            getOrderedNotification().filter(
              (item) => item.status === NotificationStatus.COMPLETE
            ).length === 0
          }
          onClick={deleteReadNotification}
          text="읽은 알림 삭제"
        />
        <OrderingBtn
          defaultData={
            notiOrderList.find((item) => item.id === selectedOrderNoti)?.text
          }
          selectList={notiOrderList}
          onChangeData={onChangeNotiOrder}
        />
      </div>
      {getOrderedNotification().map((item) => {
        return (
          <NotificationCard
            isNotification
            cardItem={item}
            goTolink={goTolink}
            onDeleteCard={deleteNotificationById}
          />
        );
      })}

      {getOrderedNotification().length === 0 && (
        <Empty className="body-14-md">새로운 알림이 없어요.</Empty>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 17px 17px 17px;
  gap: 10px;
  overflow: scroll;
`;

const Empty = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: var(--text-04, #7e8086);
  text-align: center;
`;
