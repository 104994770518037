import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { QuestionItemType } from "../../../interface/PrePitchingDetail.interface";

import { Short } from "./Short";
import { Long } from "./Long";
import { Single } from "./Single";
import { Multi } from "./Multi";

interface Props {
  questionItem: QuestionItemType;
}
export const Question: React.FC<Props> = (props) => {
  const { questionItem } = props;
  const [question, updateQuestion] = useState<QuestionItemType | undefined>(
    undefined
  );

  const getBodyByType = () => {
    if (!question) return "";
    switch (question.type) {
      case "short":
        return <Short />;
      case "long":
        return <Long />;
      case "single":
        return <Single columns={question.column || []} />;
      case "multiple":
        return <Multi columns={question.column || []} />;
      case "linear":
        return "선형 질문";
    }
  };

  useEffect(() => {
    updateQuestion(questionItem);
  }, [questionItem]);
  return (
    <Container>
      <QuestionHeader className="heading-16-sb">
        <span>{question?.title}</span>
      </QuestionHeader>
      <QuestionBody className="body-14-rg">{getBodyByType()}</QuestionBody>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const QuestionHeader = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const QuestionBody = styled.div`
  width: 100%;
`;
