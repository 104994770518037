import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { getNewDate } from "../../../../common/commonUtil";
import { BlueCardInfo } from "../../../../type/data";
import { BluecardFilterProps } from "../organisms/PreviewBlueCard";

interface Props {
  portfolioBluecard: BlueCardInfo;
  bluecardFilter: BluecardFilterProps;
  onChangeLink?: (line: number) => void;
  onImageLoaded?: () => void;
}

const BluecardPrint = (props: Props) => {
  const logoRef = useRef<HTMLImageElement>(null);
  const IMAGE_COUNT = 2;

  const [loadImageCount, updateLoadImageCount] = useState<number>(0);
  const [imageLoaded, updateImageLoaded] = useState<string[]>([]);

  const companyImgLoaded = useRef<boolean>(false);
  const thumbnailImgLoaded = useRef<boolean>(false);
  const investmentHighlightRef = useRef<HTMLDivElement>(null);
  const problemRef = useRef<HTMLDivElement>(null);
  const solutionBusinessModelRef = useRef<HTMLDivElement>(null);
  const technologyRef = useRef<HTMLDivElement>(null);
  const productStatusRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let totalHeight = 0;
    let countEmptyImage = 0;
    if (
      props.portfolioBluecard.companyImgUrl === null ||
      props.portfolioBluecard.companyImgUrl === undefined ||
      (props.portfolioBluecard.companyImgUrl &&
        props.portfolioBluecard.companyImgUrl.length !== 0)
    ) {
      updateImageLoaded((prev) => [...prev, "companyImg"]);
    }

    if (
      props.portfolioBluecard.thumbnailImgUrl === null ||
      props.portfolioBluecard.thumbnailImgUrl === undefined ||
      (props.portfolioBluecard.thumbnailImgUrl &&
        props.portfolioBluecard.thumbnailImgUrl.length !== 0)
    ) {
      updateImageLoaded((prev) => [...prev, "thumbnailImg"]);
    }

    if (investmentHighlightRef.current) {
      totalHeight += investmentHighlightRef.current.clientHeight;
    }
    if (problemRef.current) {
      totalHeight += problemRef.current.clientHeight;
    }
    if (solutionBusinessModelRef.current) {
      totalHeight += solutionBusinessModelRef.current.clientHeight;
    }
    if (technologyRef.current) {
      totalHeight += technologyRef.current.clientHeight;
    }
    if (productStatusRef.current) {
      totalHeight += productStatusRef.current.clientHeight;
    }
    if (props.onChangeLink) {
      props.onChangeLink(Math.round(totalHeight / 16));
    }

    updateLoadImageCount(countEmptyImage);
  }, [props.portfolioBluecard]);

  useEffect(() => {
    const isLoadedCompanyImg =
      imageLoaded.find((item) => item === "companyImg") !== undefined;
    const isLoadedThumbnailImg =
      imageLoaded.find((item) => item === "thumbnailImg") !== undefined;

    if (isLoadedCompanyImg && isLoadedThumbnailImg && props.onImageLoaded) {
      props.onImageLoaded();
    }
  }, [imageLoaded]);

  return (
    <A4Container>
      <Header>
        <div className="left__container">
          <div className="logo">
            {props.portfolioBluecard.companyImgUrl &&
            props.portfolioBluecard.companyImgUrl.length !== 0 ? (
              <img
                ref={logoRef}
                src={
                  /^data:image/.test(
                    props.portfolioBluecard.companyImgUrl || ""
                  )
                    ? props.portfolioBluecard.companyImgUrl || ""
                    : props.portfolioBluecard.companyImgUrl +
                      "?" +
                      getNewDate().getTime()
                }
                className="logo__img"
                crossOrigin="anonymous"
                alt=""
                onLoad={(e) => {
                  if (companyImgLoaded.current) return;

                  companyImgLoaded.current = true;
                  updateImageLoaded((prev) => [...prev, "companyImg"]);
                  if (logoRef.current) {
                    if (
                      logoRef.current.naturalWidth >
                      logoRef.current.naturalHeight
                    ) {
                      logoRef.current.style.width = "100%";
                      logoRef.current.style.height = "auto";
                    } else {
                      logoRef.current.style.width = "auto";
                      logoRef.current.style.height = "100%";
                    }
                  }
                }}
              />
            ) : (
              <EmptyImage className="font-medium-12"></EmptyImage>
            )}
          </div>
          <IndustrialTechList>
            {props.portfolioBluecard.industrialTechText
              .split(",")
              .map((tech, index) => (
                <IndustrialTechItem className="caption-10-rg" key={index}>
                  {tech}
                </IndustrialTechItem>
                // <div className="tech-item" key={`teck-item-${index}`}>
                //   {tech.name}
                // </div>
              ))
              .slice(0, 2)}
          </IndustrialTechList>
          <div className="heading-24-b company__name">
            {props.portfolioBluecard.companyName}
          </div>
          <div className="caption-12-md description">
            {props.portfolioBluecard.itemSurmmary}
          </div>
        </div>
        <div className="right__container">
          <div className="homepage-url">
            {props.portfolioBluecard.homepageUrl ?? ""}
          </div>
          <div className="thumbnail-img-wrap">
            {props.portfolioBluecard.thumbnailImgUrl &&
            props.portfolioBluecard.thumbnailImgUrl.length !== 0 ? (
              <img
                src={
                  /^data:image/.test(
                    props.portfolioBluecard.thumbnailImgUrl || ""
                  )
                    ? props.portfolioBluecard.thumbnailImgUrl || ""
                    : props.portfolioBluecard.thumbnailImgUrl +
                      "?" +
                      getNewDate().getTime()
                }
                className="company-thumbnail-img"
                crossOrigin="anonymous"
                alt=""
                onLoad={(e) => {
                  if (thumbnailImgLoaded.current) return;

                  thumbnailImgLoaded.current = true;
                  updateImageLoaded((prev) => [...prev, "thumbnailImg"]);
                }}
              />
            ) : (
              <EmptyImage className="caption-10-rg"></EmptyImage>
            )}
          </div>

          <div className="items">
            <div className="item">
              <div className="caption-10-rg item-title">대표자</div>
              <div className="caption-10-rg item-content">
                {props.portfolioBluecard.ceoName ?? ""}
              </div>
            </div>
            <div className="item">
              <div className="caption-10-rg item-title">설립일</div>
              <div
                className="caption-10-rg item-content"
                style={{ fontWeight: 800 }}
              >
                {props.portfolioBluecard.establishmentDay ?? ""}
              </div>
            </div>
            <div className="item">
              <div className="caption-10-rg item-title">소재지</div>
              <div className="caption-10-rg item-content">
                {props.portfolioBluecard.companyAddressAddr1 ?? ""}
              </div>
            </div>
          </div>
        </div>
      </Header>
      {(props.bluecardFilter.companyValue ||
        props.bluecardFilter.investmentAmount ||
        props.bluecardFilter.userName ||
        props.bluecardFilter.userEmail ||
        props.bluecardFilter.userPhone) && (
        <OtherInfo className="caption-10-rg">
          {(props.bluecardFilter.companyValue ||
            props.bluecardFilter.investmentAmount) && (
            <div className="other__info__row">
              {props.bluecardFilter.companyValue && (
                <div className="other__info__row__item">
                  <span className="other__info__row__item__title">
                    기업가치(억원)
                  </span>
                  <span className="other__info__row__item__contents">
                    {(
                      (props.portfolioBluecard.companyValue || 0) / 100000000
                    ).toFixed(2)}
                  </span>
                </div>
              )}
              {props.bluecardFilter.investmentAmount && (
                <div className="other__info__row__item">
                  <span className="other__info__row__item__title">
                    투자금(억원)
                  </span>
                  <span className="other__info__row__item__contents">
                    {(
                      (props.portfolioBluecard.investmentAmount || 0) /
                      100000000
                    ).toFixed(2)}
                  </span>
                </div>
              )}
            </div>
          )}
          {(props.bluecardFilter.userName ||
            props.bluecardFilter.userPhone) && (
            <div className="other__info__row">
              {props.bluecardFilter.userName && (
                <div className="other__info__row__item">
                  <span className="other__info__row__item__title">담당자</span>
                  <span className="other__info__row__item__contents">
                    {props.portfolioBluecard.userName}
                  </span>
                </div>
              )}
              {props.bluecardFilter.userPhone && (
                <div className="other__info__row__item">
                  <span className="other__info__row__item__title">
                    전화번호
                  </span>
                  <span className="other__info__row__item__contents">
                    {!props.portfolioBluecard.userPhone ||
                    props.portfolioBluecard.userPhone.length === 0
                      ? "-"
                      : props.portfolioBluecard.userPhone}
                  </span>
                </div>
              )}
            </div>
          )}
          {props.bluecardFilter.userEmail && (
            <div className="other__info__row">
              <div className="other__info__row__item">
                <span className="other__info__row__item__title">이메일</span>
                <span className="other__info__row__item__contents">
                  {props.portfolioBluecard.userEmail}
                </span>
              </div>
            </div>
          )}
        </OtherInfo>
      )}

      <Body>
        {props.bluecardFilter.investmentHighlight &&
        props.portfolioBluecard.investmentHighlight &&
        props.portfolioBluecard.investmentHighlight !== "" ? (
          <div className="pdf__contents__section">
            <div className="heading-14-sb contents__section__title">{`Investment Highlight`}</div>
            <div
              className="caption-10-rg contents__section__item"
              ref={investmentHighlightRef}
            >
              {props.portfolioBluecard.investmentHighlight}
            </div>
          </div>
        ) : (
          <></>
        )}

        {props.bluecardFilter.problem &&
        props.portfolioBluecard.problem &&
        props.portfolioBluecard.problem !== "" ? (
          <div className="pdf__contents__section">
            <div className="heading-14-sb contents__section__title">{`Problem`}</div>
            <div
              className="caption-10-rg contents__section__item"
              ref={problemRef}
            >
              {props.portfolioBluecard.problem}
            </div>
          </div>
        ) : (
          <></>
        )}

        {props.bluecardFilter.solutionBusinessModel &&
        props.portfolioBluecard.solutionBusinessModel &&
        props.portfolioBluecard.solutionBusinessModel !== "" ? (
          <div className="pdf__contents__section">
            <div className="heading-14-sb contents__section__title">{`Solution & Business Model`}</div>
            <div
              className="caption-10-rg contents__section__item"
              ref={solutionBusinessModelRef}
            >
              {props.portfolioBluecard.solutionBusinessModel}
            </div>
          </div>
        ) : (
          <></>
        )}

        {props.bluecardFilter.technology &&
        props.portfolioBluecard.technology &&
        props.portfolioBluecard.technology !== "" ? (
          <div className="pdf__contents__section">
            <div className="heading-14-sb contents__section__title">{`Technology`}</div>
            <div
              className="caption-10-rg contents__section__item"
              ref={technologyRef}
            >
              {props.portfolioBluecard.technology}
            </div>
          </div>
        ) : (
          <></>
        )}

        {props.bluecardFilter.productStatus &&
        props.portfolioBluecard.productStatus &&
        props.portfolioBluecard.productStatus !== "" ? (
          <div className="pdf__contents__section">
            <div className="heading-14-sb contents__section__title">{`Product Status`}</div>
            <div
              className="caption-10-rg contents__section__item"
              ref={productStatusRef}
            >
              {props.portfolioBluecard.productStatus}
            </div>
          </div>
        ) : (
          <></>
        )}
      </Body>
    </A4Container>
  );
};

export const A4Container = styled.div`
  width: calc(210px * 3);
  height: calc(297px * 3 - 2px);
  background: var(--white);
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;

  .left__container {
    flex: 6;
    display: flex;
    flex-direction: column;
    .logo {
      position: relative;
      width: 58px;
      min-width: 58px;
      max-width: 58px;
      height: 58px;
      min-height: 58px;
      max-height: 58px;
      /* border: 1px solid #f0f0f0; */
      .logo__img {
        position: absolute;
        width: calc(100% - 1px);
        height: calc(100% - 1px);
        object-fit: contain !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .company__name {
      margin-top: 10px;
      color: var(--text-01);
      white-space: normal;
      word-break: break-all;
      flex-wrap: wrap;
    }

    .description {
      margin-top: 6px;
      color: var(--text-05);
      margin-right: 6px;
      line-height: 16px;
      white-space: normal;
      word-break: break-all;
      flex-wrap: wrap;
      white-space: normal;
      word-break: break-all;
    }
  }
  .right__container {
    flex: 4;
    display: flex;
    flex-direction: column;

    .homepage-url {
      font-family: "Open Sans";
      font-weight: 400;
      font-size: 5px;
      line-height: 7px;

      text-align: right;

      color: #b3b3b3;
    }
    .thumbnail-img-wrap {
      width: 224px;
      min-width: 224px;
      max-width: 224px;
      height: 132px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 1px;
      /* border: 1px solid #f0f0f0; */
      overflow: hidden;

      .company-thumbnail-img {
        width: 100%;
        height: 100%;
        max-width: 224px;
        max-height: 132px;
        object-fit: cover;
      }
    }
    .items {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      margin-top: 6px;
      gap: 8px;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2px;

        .item-title {
          color: var(--text-05);
        }

        .item-content {
          color: var(--text-03);
        }
      }
    }
  }
`;
const OtherInfo = styled.div`
  width: 100%;
  height: auto;
  background: #fbfbfb;
  padding: 12px 16px;

  display: flex;
  flex-direction: column;
  gap: 6px;

  margin-top: 16px;

  .other__info__row {
    display: flex;
    flex-direction: row;
    gap: 24px;

    .other__info__row__item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .other__info__row__item__title {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        color: #b4b4b4;
      }
      .other__info__row__item__contents {
        min-width: 100px;
        color: #000000;
      }
    }
  }
`;
const Body = styled.div`
  width: 100%;
  height: auto;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .pdf__contents__section {
    width: 100%;
    display: flex;
    flex-direction: column;

    .contents__section__title {
      width: 100%;
      padding-bottom: 4px;
      border-bottom: 1px solid var(--divider-300);
    }

    .contents__section__item {
      margin-top: 8px;
      overflow: hidden;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;

      /* background: green; */
    }
  }
`;

const IndustrialTechList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const IndustrialTechItem = styled.div`
  padding: 0px 6px;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
`;

const EmptyImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b3b3b3;
  font-size: 10px;
`;

export default BluecardPrint;
