import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  DatabaseHistory,
  FundAccountType,
  IndustryTech,
  StageType,
  UserInfo,
} from "../../../../type/data";
import DatabaseChip from "../component/DatabaseChip";
import DatabaseDropdownCheckbox from "../component/DatabaseDropdownCheckbox";
import DatabaseInput from "../component/DatabaseInput";
import DatabaseIndustrialTechs from "./DatabaseIndustrialTechs";
import DatabaseRangeInput from "./DatabaseRangeInput";
import axios from "axios";
import * as api from "../../../../api/api";
import DatabaseVintage from "./DatabaseVintage";
import { DatabaseSearchData } from "../DeskDatabase";
import { setDatabaseHistory } from "../../../../common/pageHistoryUtil";
import { getNewDate } from "../../../../common/commonUtil";

import { ReactComponent as Update } from "../../../../assets/image/ic_update.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/image/ic_arrow_up.svg";
import { RoundProps } from "../../../portfolio-viewer-stock-change-history/interface/type";

export interface SearchValueType {
  keyword?: string;
  industry?: IndustryTech[];
  participant?: UserInfo[];
  fundAccounts?: FundAccountType[];
  round?: RoundProps[];
  addr1?: string[];
  minInvestmentYear?: number;
  maxInvestmentYear?: number;
  minInvestmentQuarter?: number;
  maxInvestmentQuarter?: number;
  minPurchasePrincipal?: number;
  maxPurchasePrincipal?: number;
  minCompanyValue?: number;
  maxCompanyValue?: number;
  minSalesAmount?: number;
  maxSalesAmount?: number;
  minOwnershipPercentage?: number;
  maxOwnershipPercentage?: number;
  minRealizedMultiple?: number;
  maxRealizedMultiple?: number;
  minUnrealizedMultiple?: number;
  maxUnrealizedMultiple?: number;

  curPage?: number;

  sortItem?: string;
  sortType?: "asc" | "desc";
}

export interface InputSearchValueType {
  minPurchasePrincipal?: number;
  maxPurchasePrincipal?: number;
  minCompanyValue?: number;
  maxCompanyValue?: number;
  minSalesAmount?: number;
  maxSalesAmount?: number;
  minOwnershipPercentage?: number;
  maxOwnershipPercentage?: number;
  minRealizedMultiple?: number;
  maxRealizedMultiple?: number;
  minUnrealizedMultiple?: number;
  maxUnrealizedMultiple?: number;
}

export interface IsAllSelectedType {
  industry: boolean;
  participant: boolean;
  fundAccounts: boolean;
  stages: boolean;
  addr1: boolean;
}

interface Props {
  searchObject: SearchValueType;
  setSearchObject: React.Dispatch<
    React.SetStateAction<SearchValueType | undefined>
  >;
  inputChipObject: InputSearchValueType;
  setInputChipObject: React.Dispatch<
    React.SetStateAction<InputSearchValueType>
  >;
  isAllSelectedObject: React.MutableRefObject<IsAllSelectedType>;
  industrialTechs: IndustryTech[];
  participants: UserInfo[];
  round: RoundProps[];
  investmentYears: number[];
  addrList: string[];
  fundAccountList: FundAccountType[];
}

const FilterAccelerating = ({
  searchObject,
  setSearchObject,
  inputChipObject,
  setInputChipObject,
  isAllSelectedObject,
  industrialTechs,
  participants,
  round,
  investmentYears,
  addrList,
  fundAccountList,
}: Props) => {
  const [isAllIndustrySelected, setIsAllIndustrySelected] =
    useState<boolean>(true);

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [existInputValue, setExistInputValue] = useState<boolean>(false);
  const [selectedDropdownId, setSelectedDropdownId] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (
      searchObject.keyword ||
      (searchObject.industry &&
        searchObject.industry.length > 0 &&
        searchObject.industry.length < industrialTechs.length) ||
      (searchObject.participant &&
        searchObject.participant.length > 0 &&
        searchObject.participant.length < participants.length) ||
      (searchObject.fundAccounts &&
        searchObject.fundAccounts.length > 0 &&
        searchObject.fundAccounts.length < fundAccountList.length) ||
      (searchObject.round &&
        searchObject.round.length > 0 &&
        searchObject.round.length < round.length) ||
      (searchObject.addr1 &&
        searchObject.addr1.length > 0 &&
        searchObject.addr1.length < addrList.length) ||
      searchObject.minInvestmentYear ||
      searchObject.maxInvestmentYear ||
      searchObject.minInvestmentQuarter ||
      searchObject.maxInvestmentQuarter ||
      inputChipObject.minPurchasePrincipal ||
      inputChipObject.maxPurchasePrincipal ||
      inputChipObject.minCompanyValue ||
      inputChipObject.maxCompanyValue ||
      inputChipObject.minSalesAmount ||
      inputChipObject.maxSalesAmount ||
      inputChipObject.minOwnershipPercentage ||
      inputChipObject.maxOwnershipPercentage ||
      inputChipObject.minRealizedMultiple ||
      inputChipObject.maxRealizedMultiple ||
      inputChipObject.minUnrealizedMultiple ||
      inputChipObject.maxUnrealizedMultiple
    ) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }

    if (
      searchObject.minPurchasePrincipal ||
      searchObject.maxPurchasePrincipal ||
      searchObject.minCompanyValue ||
      searchObject.maxCompanyValue ||
      searchObject.minSalesAmount ||
      searchObject.maxSalesAmount ||
      searchObject.minOwnershipPercentage ||
      searchObject.maxOwnershipPercentage ||
      searchObject.minRealizedMultiple ||
      searchObject.maxRealizedMultiple ||
      searchObject.minUnrealizedMultiple ||
      searchObject.maxUnrealizedMultiple
    ) {
      setExistInputValue(true);
    } else {
      setExistInputValue(false);
    }

    if (
      searchObject.industry &&
      searchObject.industry.length > 0 &&
      searchObject.industry.length === industrialTechs.length
    ) {
      setIsAllIndustrySelected(true);
    } else {
      setIsAllIndustrySelected(false);
    }

    /* 메인 조건은 searchObject 변경될 때마다 필터링  */
    /* 상세 조건은 버튼 눌러야 필터링 */
  }, [searchObject, inputChipObject]);

  const onClickRefresh = () => {
    setSearchObject({
      ...searchObject,
      industry: industrialTechs,
      participant: participants,
      fundAccounts: fundAccountList,
      round: round,
      addr1: addrList,
      minInvestmentYear: undefined,
      maxInvestmentYear: undefined,
      minInvestmentQuarter: undefined,
      maxInvestmentQuarter: undefined,
      minPurchasePrincipal: undefined,
      maxPurchasePrincipal: undefined,
      minCompanyValue: undefined,
      maxCompanyValue: undefined,
      minSalesAmount: undefined,
      maxSalesAmount: undefined,
      minOwnershipPercentage: undefined,
      maxOwnershipPercentage: undefined,
      minRealizedMultiple: undefined,
      maxRealizedMultiple: undefined,
      minUnrealizedMultiple: undefined,
      maxUnrealizedMultiple: undefined,
      curPage: 1,
      sortItem: undefined,
      sortType: undefined,
    });
    setInputChipObject({
      minPurchasePrincipal: undefined,
      maxPurchasePrincipal: undefined,
      minCompanyValue: undefined,
      maxCompanyValue: undefined,
      minSalesAmount: undefined,
      maxSalesAmount: undefined,
      minOwnershipPercentage: undefined,
      maxOwnershipPercentage: undefined,
      minRealizedMultiple: undefined,
      maxRealizedMultiple: undefined,
      minUnrealizedMultiple: undefined,
      maxUnrealizedMultiple: undefined,
    });
  };

  const onClickApplyButton = () => {
    const newInputChip = {
      minPurchasePrincipal: searchObject.minPurchasePrincipal,
      maxPurchasePrincipal: searchObject.maxPurchasePrincipal,
      minCompanyValue: searchObject.minCompanyValue,
      maxCompanyValue: searchObject.maxCompanyValue,
      minSalesAmount: searchObject.minSalesAmount,
      maxSalesAmount: searchObject.maxSalesAmount,
      minOwnershipPercentage: searchObject.minOwnershipPercentage,
      maxOwnershipPercentage: searchObject.maxOwnershipPercentage,
      minRealizedMultiple: searchObject.minRealizedMultiple,
      maxRealizedMultiple: searchObject.maxRealizedMultiple,
      minUnrealizedMultiple: searchObject.minUnrealizedMultiple,
      maxUnrealizedMultiple: searchObject.maxUnrealizedMultiple,
    };
    setSearchObject((prev) => ({ ...prev, ...newInputChip }));
    setInputChipObject({ ...newInputChip });
  };

  return (
    <Container>
      <Row
        className="heading-16-sb"
        style={{
          borderBottom: "1px solid var(--gray-400)",
        }}
      >
        상세 필터
      </Row>
      <Row>
        <RowSection>
          <span className="label font-medium-12">산업기술분류</span>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
            }}
          >
            <DatabaseIndustrialTechs
              selectedIndustrialTechs={searchObject.industry}
              onSelectedIndustrialTechs={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  industry: data,
                  curPage: 1,
                }));
              }}
              setIsAllIndustrySelected={setIsAllIndustrySelected}
              isAllIndustrySelected={isAllIndustrySelected}
              industrialTechs={industrialTechs}
            />
          </div>
        </RowSection>
      </Row>
      <Row
        style={{
          justifyContent: "flex-start",
          flexFlow: "row wrap",
          columnGap: "64px",
          rowGap: "4px",
        }}
      >
        <RowSection>
          <span className="label font-medium-12">담당자(조력자)</span>

          <div style={{ maxWidth: "360px", width: "360px" }}>
            <DatabaseDropdownCheckbox
              allItemList={participants.map((data) => ({
                data: data,
                label: data.name || "",
              }))}
              selectedItemList={
                searchObject.participant?.map((data) => ({
                  data: data,
                  label: data.name || "",
                })) || []
              }
              isAllDataSelected={
                participants.length === searchObject.participant?.length
              }
              isSame={(a, b) => {
                return a.acUserId === b.acUserId;
              }}
              isIncludesValue={(value, data) => {
                return data.name.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                console.log(dataList);

                setSearchObject((prev) => ({
                  ...prev,
                  participant: dataList.length === 0 ? participants : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="participants"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </div>
        </RowSection>
        <RowSection>
          <span className="label font-medium-12">투자계정</span>{" "}
          <div style={{ maxWidth: "360px", width: "360px" }}>
            <DatabaseDropdownCheckbox
              allItemList={fundAccountList.map((data) => ({
                data: data,
                label: data.nameKo || "",
              }))}
              selectedItemList={
                searchObject.fundAccounts?.map((data) => ({
                  data: data,
                  label: data.nameKo || "",
                })) || []
              }
              isAllDataSelected={
                fundAccountList.length === searchObject.fundAccounts?.length
              }
              isSame={(a, b) => {
                return a.acFundAccountId === b.acFundAccountId;
              }}
              isIncludesValue={(value, data) => {
                return data.nameKo.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                setSearchObject((prev) => ({
                  ...prev,
                  fundAccounts:
                    dataList.length === 0 ? fundAccountList : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="fundAccount"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </div>
        </RowSection>
      </Row>

      <Row
        style={{
          justifyContent: "flex-start",
          flexFlow: "row wrap",
          columnGap: "64px",
          rowGap: "4px",
        }}
      >
        <RowSection>
          <span className="label font-medium-12">라운드</span>

          <div style={{ maxWidth: "360px", width: "360px" }}>
            <DatabaseDropdownCheckbox
              allItemList={round.map((data) => ({
                data: data,
                label: data.name || "",
              }))}
              selectedItemList={
                searchObject.round?.map((data) => ({
                  data: data,
                  label: data.name || "",
                })) || []
              }
              isAllDataSelected={round.length === searchObject.round?.length}
              isSame={(a, b) => {
                return a.id === b.id;
              }}
              isIncludesValue={(value, data) => {
                return data.name.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                setSearchObject((prev) => ({
                  ...prev,
                  round: dataList.length === 0 ? round : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="stage"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </div>
        </RowSection>
        <RowSection>
          <span className="label font-medium-12">소재지</span>
          <div style={{ maxWidth: "360px", width: "360px" }}>
            <DatabaseDropdownCheckbox
              allItemList={addrList.map((data) => ({
                data: data,
                label: data || "",
              }))}
              selectedItemList={
                searchObject.addr1?.map((data) => ({
                  data: data,
                  label: data || "",
                })) || []
              }
              isAllDataSelected={addrList.length === searchObject.addr1?.length}
              isSame={(a, b) => {
                return a === b;
              }}
              isIncludesValue={(value, data) => {
                return data.includes(value);
              }}
              onSelectedData={(dataList: any) => {
                setSearchObject((prev) => ({
                  ...prev,
                  addr1: dataList.length === 0 ? addrList : dataList,
                  curPage: 1,
                }));
              }}
              dropdownId="addr1"
              selectedDropdownId={selectedDropdownId}
              setSelectedDropdownId={setSelectedDropdownId}
            />
          </div>
        </RowSection>
        <RowSection>
          <span className="label font-medium-12">빈티지</span>
          <DatabaseVintage
            investmentYears={investmentYears}
            minInvestmentYear={searchObject.minInvestmentYear}
            maxInvestmentYear={searchObject.maxInvestmentYear}
            onSelectedMinYear={(data) => {
              setSearchObject((prev) => ({
                ...prev,
                minInvestmentYear: data,
              }));
            }}
            onSelectedMaxYear={(data) => {
              setSearchObject((prev) => ({
                ...prev,
                maxInvestmentYear: data,
                curPage: 1,
              }));
            }}
            minInvestmentQuarter={searchObject.minInvestmentQuarter}
            maxInvestmentQuarter={searchObject.maxInvestmentQuarter}
            onSelectedMinQuarter={(data) => {
              setSearchObject((prev) => ({
                ...prev,
                minInvestmentQuarter: data,
                curPage: 1,
              }));
            }}
            onSelectedMaxQuarter={(data) => {
              setSearchObject((prev) => ({
                ...prev,
                maxInvestmentQuarter: data,
                curPage: 1,
              }));
            }}
            selectedDropdownId={selectedDropdownId}
            setSelectedDropdownId={setSelectedDropdownId}
          />
        </RowSection>
      </Row>

      <DetailRow
        style={{
          justifyContent: "flex-start",
          flexFlow: "row wrap",
          columnGap: "64px",
          rowGap: "4px",
        }}
      >
        <RowSection>
          <span className="label font-medium-12">투자금</span>
          <div style={{ maxWidth: "240px", width: "240px" }}>
            <DatabaseRangeInput
              unit="억원"
              minValue={searchObject.minPurchasePrincipal}
              maxValue={searchObject.maxPurchasePrincipal}
              onChangeMin={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  minPurchasePrincipal: data,
                  curPage: 1,
                }));
              }}
              onChangeMax={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  maxPurchasePrincipal: data,
                  curPage: 1,
                }));
              }}
            />
          </div>
        </RowSection>
        <RowSection>
          <span className="label font-medium-12">기업가치</span>
          <div style={{ maxWidth: "240px", width: "240px" }}>
            <DatabaseRangeInput
              unit="억원"
              minValue={searchObject.minCompanyValue}
              maxValue={searchObject.maxCompanyValue}
              onChangeMin={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  minCompanyValue: data,
                  curPage: 1,
                }));
              }}
              onChangeMax={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  maxCompanyValue: data,
                  curPage: 1,
                }));
              }}
            />
          </div>
        </RowSection>
        <RowSection>
          <span className="label font-medium-12">매출</span>
          <div style={{ maxWidth: "240px", width: "240px" }}>
            <DatabaseRangeInput
              unit="억원"
              minValue={searchObject.minSalesAmount}
              maxValue={searchObject.maxSalesAmount}
              onChangeMin={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  minSalesAmount: data,
                  curPage: 1,
                }));
              }}
              onChangeMax={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  maxSalesAmount: data,
                  curPage: 1,
                }));
              }}
            />
          </div>
        </RowSection>
      </DetailRow>
      <DetailRow
        style={{
          justifyContent: "flex-start",
          flexFlow: "row wrap",
          columnGap: "64px",
          rowGap: "4px",
        }}
      >
        <RowSection>
          <span className="label font-medium-12">지분율</span>
          <div style={{ maxWidth: "240px", width: "240px" }}>
            <DatabaseRangeInput
              inputWidth={64}
              unit="%"
              minValue={searchObject.minOwnershipPercentage}
              maxValue={searchObject.maxOwnershipPercentage}
              onChangeMin={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  minOwnershipPercentage: data,
                  curPage: 1,
                }));
              }}
              onChangeMax={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  maxOwnershipPercentage: data,
                  curPage: 1,
                }));
              }}
            />
          </div>
        </RowSection>
        <RowSection>
          <span className="label font-medium-12">실현 멀티플</span>
          <div style={{ maxWidth: "240px", width: "240px" }}>
            <DatabaseRangeInput
              inputWidth={64}
              unit="X"
              minValue={searchObject.minRealizedMultiple}
              maxValue={searchObject.maxRealizedMultiple}
              onChangeMin={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  minRealizedMultiple: data,
                  curPage: 1,
                }));
              }}
              onChangeMax={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  maxRealizedMultiple: data,
                  curPage: 1,
                }));
              }}
            />
          </div>
        </RowSection>
        <RowSection>
          <span className="label font-medium-12">미실현 멀티플</span>
          <div style={{ maxWidth: "240px", width: "240px" }}>
            <DatabaseRangeInput
              inputWidth={64}
              unit="X"
              minValue={searchObject.minUnrealizedMultiple}
              maxValue={searchObject.maxUnrealizedMultiple}
              onChangeMin={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  minUnrealizedMultiple: data,
                  curPage: 1,
                }));
              }}
              onChangeMax={(data) => {
                setSearchObject((prev) => ({
                  ...prev,
                  maxUnrealizedMultiple: data,
                  curPage: 1,
                }));
              }}
            />
          </div>
        </RowSection>
        <RowSection
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            flex: 1,
          }}
          existInputValue={existInputValue}
        >
          <button
            className="apply-button font-regular-12"
            onClick={() => existInputValue && onClickApplyButton()}
          >
            적용하기
          </button>
        </RowSection>
      </DetailRow>
      <SearchKeywordSection isSelected={isSelected}>
        <div className="chip-section">
          {searchObject?.industry &&
            searchObject.industry.length > 0 &&
            !isAllIndustrySelected &&
            searchObject.industry.map((industry, index) => (
              <div
                key={index}
                className={`element-chip ${index === 0 ? "first" : ""}`}
              >
                <DatabaseChip
                  type="keyword-general"
                  data={industry}
                  label={industry.name}
                  onClickDelete={(data) => {
                    setSearchObject((prev) => ({
                      ...prev,
                      industry: prev?.industry?.filter(
                        (item) =>
                          item.acIndustrialTechId !== data.acIndustrialTechId
                      ),
                      curPage: 1,
                    }));
                  }}
                  key={industry.name}
                />
              </div>
            ))}
          {searchObject?.participant &&
            searchObject.participant.length > 0 &&
            participants.length !== searchObject.participant?.length &&
            searchObject.participant?.map((participant, index) => (
              <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                <DatabaseChip
                  type="keyword-general"
                  data={participant}
                  label={participant.name || `${participant}`}
                  onClickDelete={(data) => {
                    setSearchObject((prev) => {
                      const prevItem = prev?.participant?.filter(
                        (item) => item.acUserId !== data.acUserId
                      );
                      const updatedItem =
                        prevItem && prevItem.length > 0
                          ? prevItem
                          : [...participants];
                      return {
                        ...prev,
                        participant: updatedItem,
                        curPage: 1,
                      };
                    });
                  }}
                  key={participant.name || `${participant}`}
                />
              </div>
            ))}
          {searchObject?.fundAccounts &&
            searchObject.fundAccounts.length > 0 &&
            fundAccountList.length !== searchObject.fundAccounts?.length &&
            searchObject.fundAccounts?.map((account, index) => (
              <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                <DatabaseChip
                  type="keyword-general"
                  data={account}
                  label={account.nameKo || `${account}`}
                  onClickDelete={(data) => {
                    setSearchObject((prev) => {
                      const prevItem = prev?.fundAccounts?.filter(
                        (item) => item.acFundAccountId !== data.acFundAccountId
                      );
                      const updatedItem =
                        prevItem && prevItem.length > 0
                          ? prevItem
                          : [...fundAccountList];
                      return {
                        ...prev,
                        fundAccounts: updatedItem,
                        curPage: 1,
                      };
                    });
                  }}
                  key={account.nameKo || `${account}`}
                />
              </div>
            ))}
          {searchObject?.round &&
            searchObject.round.length > 0 &&
            round.length !== searchObject.round.length &&
            searchObject.round?.map((item, index) => (
              <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                <DatabaseChip
                  type="keyword-detail"
                  data={item}
                  label={item.name || `${item}`}
                  key={item.name || `${item}`}
                  onClickDelete={(data) => {
                    setSearchObject((prev) => {
                      const prevItem = prev?.round?.filter(
                        (item) => item.id !== data.id
                      );
                      const updatedItem =
                        prevItem && prevItem.length > 0 ? prevItem : [...round];
                      return {
                        ...prev,
                        round: updatedItem,
                        curPage: 1,
                      };
                    });
                  }}
                />
              </div>
            ))}
          {searchObject?.addr1 &&
            searchObject.addr1.length > 0 &&
            addrList.length !== searchObject.addr1.length &&
            searchObject.addr1?.map((addr, index) => (
              <div className={`element-chip ${index === 0 ? "first" : ""}`}>
                <DatabaseChip
                  type="keyword-detail"
                  data={addr}
                  label={addr || `${addr}`}
                  key={addr || `${addr}`}
                  onClickDelete={(data) => {
                    setSearchObject((prev) => {
                      const prevItem = prev?.addr1?.filter(
                        (item) => item !== data
                      );
                      const updatedItem =
                        prevItem && prevItem.length > 0
                          ? prevItem
                          : [...addrList];
                      return {
                        ...prev,
                        addr1: updatedItem,
                        curPage: 1,
                      };
                    });
                  }}
                />
              </div>
            ))}
          {(searchObject?.minInvestmentYear ||
            searchObject?.maxInvestmentYear) && (
            <div className="element-chip first">
              <DatabaseChip
                type="keyword-detail"
                data={{}}
                label={`빈티지 ${searchObject?.minInvestmentYear || ""}${
                  searchObject?.minInvestmentYear &&
                  searchObject?.minInvestmentQuarter
                    ? ` ${searchObject?.minInvestmentQuarter}분기`
                    : ""
                }~${searchObject?.maxInvestmentYear || ""}${
                  searchObject?.maxInvestmentYear &&
                  searchObject?.maxInvestmentQuarter
                    ? ` ${searchObject?.maxInvestmentQuarter}분기`
                    : ""
                }`}
                onClickDelete={(data) => {
                  setSearchObject((prev) => ({
                    ...prev,
                    minInvestmentYear: undefined,
                    maxInvestmentYear: undefined,
                    minInvestmentQuarter: undefined,
                    maxInvestmentQuarter: undefined,
                    curPage: 1,
                  }));
                }}
              />
            </div>
          )}
          {(inputChipObject?.minPurchasePrincipal ||
            inputChipObject?.maxPurchasePrincipal) && (
            <div className="element-chip first">
              <DatabaseChip
                type="keyword-detail"
                data={{}}
                label={`투자금 ${inputChipObject?.minPurchasePrincipal || ""}${
                  inputChipObject?.minPurchasePrincipal &&
                  inputChipObject?.maxPurchasePrincipal
                    ? "~"
                    : ""
                }${inputChipObject?.maxPurchasePrincipal || ""}억원${
                  inputChipObject.minPurchasePrincipal
                    ? inputChipObject.maxPurchasePrincipal
                      ? ""
                      : " 이상"
                    : " 이하"
                }`}
                onClickDelete={(data) => {
                  setInputChipObject((prev) => ({
                    ...prev,
                    minPurchasePrincipal: undefined,
                    maxPurchasePrincipal: undefined,
                  }));
                  setSearchObject((prev) => ({
                    ...prev,
                    minPurchasePrincipal: undefined,
                    maxPurchasePrincipal: undefined,
                    curPage: 1,
                  }));
                }}
              />
            </div>
          )}
          {(inputChipObject?.minCompanyValue ||
            inputChipObject?.maxCompanyValue) && (
            <div className="element-chip first">
              <DatabaseChip
                type="keyword-detail"
                data={{}}
                label={`기업가치 ${inputChipObject?.minCompanyValue || ""}${
                  inputChipObject?.minCompanyValue &&
                  inputChipObject?.maxCompanyValue
                    ? "~"
                    : ""
                }${inputChipObject?.maxCompanyValue || ""}억원${
                  inputChipObject.minCompanyValue
                    ? inputChipObject.maxCompanyValue
                      ? ""
                      : " 이상"
                    : " 이하"
                }`}
                onClickDelete={(data) => {
                  setInputChipObject((prev) => ({
                    ...prev,
                    minCompanyValue: undefined,
                    maxCompanyValue: undefined,
                  }));
                  setSearchObject((prev) => ({
                    ...prev,
                    minCompanyValue: undefined,
                    maxCompanyValue: undefined,
                    curPage: 1,
                  }));
                }}
              />
            </div>
          )}
          {(inputChipObject?.minSalesAmount ||
            inputChipObject?.maxSalesAmount) && (
            <div className="element-chip first">
              <DatabaseChip
                type="keyword-detail"
                data={{}}
                label={`매출 ${inputChipObject?.minSalesAmount || ""}${
                  inputChipObject?.minSalesAmount &&
                  inputChipObject?.maxSalesAmount
                    ? "~"
                    : ""
                }${inputChipObject?.maxSalesAmount || ""}억원${
                  inputChipObject.minSalesAmount
                    ? inputChipObject.maxSalesAmount
                      ? ""
                      : " 이상"
                    : " 이하"
                }`}
                onClickDelete={(data) => {
                  setInputChipObject((prev) => ({
                    ...prev,
                    minSalesAmount: undefined,
                    maxSalesAmount: undefined,
                    curPage: 1,
                  }));
                  setSearchObject((prev) => ({
                    ...prev,
                    minSalesAmount: undefined,
                    maxSalesAmount: undefined,
                    curPage: 1,
                  }));
                }}
              />
            </div>
          )}
          {(inputChipObject?.minOwnershipPercentage ||
            inputChipObject?.maxOwnershipPercentage) && (
            <div className="element-chip first">
              <DatabaseChip
                type="keyword-detail"
                data={{}}
                label={`지분율 ${
                  inputChipObject?.minOwnershipPercentage || ""
                }${
                  inputChipObject?.minOwnershipPercentage &&
                  inputChipObject?.maxOwnershipPercentage
                    ? "~"
                    : ""
                }${inputChipObject?.maxOwnershipPercentage || ""}%${
                  inputChipObject.minOwnershipPercentage
                    ? inputChipObject.maxOwnershipPercentage
                      ? ""
                      : " 이상"
                    : " 이하"
                }`}
                onClickDelete={(data) => {
                  setInputChipObject((prev) => ({
                    ...prev,
                    minOwnershipPercentage: undefined,
                    maxOwnershipPercentage: undefined,
                  }));
                  setSearchObject((prev) => ({
                    ...prev,
                    minOwnershipPercentage: undefined,
                    maxOwnershipPercentage: undefined,
                    curPage: 1,
                  }));
                }}
              />
            </div>
          )}
          {(inputChipObject?.minRealizedMultiple ||
            inputChipObject?.maxRealizedMultiple) && (
            <div className="element-chip first">
              <DatabaseChip
                type="keyword-detail"
                data={{}}
                label={`실현 멀티플 ${
                  inputChipObject?.minRealizedMultiple || ""
                }${
                  inputChipObject?.minRealizedMultiple &&
                  inputChipObject?.maxRealizedMultiple
                    ? "~"
                    : ""
                }${inputChipObject?.maxRealizedMultiple || ""}X${
                  inputChipObject.minRealizedMultiple
                    ? inputChipObject.maxRealizedMultiple
                      ? ""
                      : " 이상"
                    : " 이하"
                }`}
                onClickDelete={(data) => {
                  setInputChipObject((prev) => ({
                    ...prev,
                    minRealizedMultiple: undefined,
                    maxRealizedMultiple: undefined,
                  }));
                  setSearchObject((prev) => ({
                    ...prev,
                    minRealizedMultiple: undefined,
                    maxRealizedMultiple: undefined,
                    curPage: 1,
                  }));
                }}
              />
            </div>
          )}
          {(inputChipObject?.minUnrealizedMultiple ||
            inputChipObject?.maxUnrealizedMultiple) && (
            <div className="element-chip first">
              <DatabaseChip
                type="keyword-detail"
                data={{}}
                label={`미실현 멀티플 ${
                  inputChipObject?.minUnrealizedMultiple || ""
                }${
                  inputChipObject?.minUnrealizedMultiple &&
                  inputChipObject?.maxUnrealizedMultiple
                    ? "~"
                    : ""
                }${inputChipObject?.maxUnrealizedMultiple || ""}X${
                  inputChipObject.minUnrealizedMultiple
                    ? inputChipObject.maxUnrealizedMultiple
                      ? ""
                      : " 이상"
                    : " 이하"
                }`}
                onClickDelete={(data) => {
                  setInputChipObject((prev) => ({
                    ...prev,
                    minUnrealizedMultiple: undefined,
                    maxUnrealizedMultiple: undefined,
                  }));
                  setSearchObject((prev) => ({
                    ...prev,
                    minUnrealizedMultiple: undefined,
                    maxUnrealizedMultiple: undefined,
                    curPage: 1,
                  }));
                }}
              />
            </div>
          )}
        </div>
        <div className="refresh-section">
          <div
            className="update-container"
            onClick={() => {
              if (isSelected) {
                onClickRefresh();
              }
            }}
          >
            <Update />
            <span className="font-regular-12">초기화</span>
          </div>
        </div>
      </SearchKeywordSection>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;

  border-bottom: 1px solid var(--grey_0);
`;

const DetailRow = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;

  border-bottom: 1px solid var(--grey_0);

  background: #f8fafd;
`;

const RowSection = styled.div(
  ({
    openDetailSearch,
    existInputValue,
  }: {
    openDetailSearch?: boolean;
    existInputValue?: boolean;
  }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 16px;
    .label {
      width: 80px;
      min-width: 80px;
    }

    .open-detail-search {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2px;
      span {
        color: ${openDetailSearch ? "#3D3D3D" : "var(--grey_50)"};
      }
      path {
        stroke: ${openDetailSearch ? "#3D3D3D" : "var(--grey_50)"};
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .apply-button {
      border: 1px solid var(--BPP_blue);
      padding: 8px 10px;
      border-radius: 4px;
      cursor: ${existInputValue ? "pointer" : ""};

      background: ${existInputValue ? "var(--BPP_blue)" : "white"};
      color: ${existInputValue ? "white" : "var(--BPP_blue)"};
    }
  `
);

const SearchKeywordSection = styled.div(
  ({ isSelected }: { isSelected: boolean }) => css`
    width: 100%;
    /* height: 100%; */
    min-height: 88px;
    height: fit-content;
    background: #f1f3f5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    display: flex;
    flex-direction: row;

    padding: 32px 16px 16px 16px;

    position: relative;

    .chip-section {
      width: 100%;
      display: flex;
      flex-direction: row;

      flex-flow: row wrap;

      //
      gap: 4px;
      align-items: center;
      height: fit-content;
      .element-chip.first {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:not(:first-of-type)::before {
          content: "";
          display: inline-block;
          width: 1px;
          height: 16px;
          margin: 0 8px 0 4px;
          background: var(--grey_10);
        }
      }
    }
    .refresh-section {
      width: max-content;
      position: absolute;
      top: 10px;
      right: 10px;
      margin-left: 40px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .update-container {
        cursor: ${isSelected ? "pointer" : "default"};
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        span {
          color: ${isSelected ? "var(--primary)" : "#ABBBCD"};
        }
        path {
          fill: ${isSelected ? "var(--primary)" : "#ABBBCD"};
        }
      }
    }
  `
);

export default FilterAccelerating;
