import React from "react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import styled from "styled-components";

type Props = {
  node: NodeModel;
  depth: number;
};

export const Placeholder: React.FC<Props> = (props) => {
  const left = props.depth * 24;
  return <Container style={{ left }} />;
};

const Container = styled.div`
  background-color: #1967d2;
  height: 2px;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  top: 0;
`;
