import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  DBDamagedPortfolio,
  DPBPDamaged,
  FundAccountType,
  IndustryTech,
  UserInfo,
} from "../../../../../../type/data";

import {
  getDatabaseAlumni,
  getDatabaseDamaged,
  getDatabaseSearchData,
} from "../../../../../../api/repository/database/DatabaseRepository";
import {
  getDatabaseHistory,
  setDatabaseHistory,
} from "../../../../../../common/pageHistoryUtil";
import {
  getAllAcUser,
  getAllIndustrialTech,
} from "../../../../../../api/repository/accelerator/AcceleratorRepository";

import { RoundProps } from "../../../../../portfolio-viewer-stock-change-history/interface/type";
import { getNewDate } from "../../../../../../common/commonUtil";
import {
  InputSearchValueType,
  SearchValueType,
} from "../../../organisms/FilterDamaged";

const useDamagedSearch = () => {
  const isSearching = useRef<boolean>(false);
  const currentPortfolio = useRef<DBDamagedPortfolio | undefined>(undefined);

  const countPerPage = 30;
  const [totalCount, updateTotalCount] = useState<number>(0);
  const [totalPage, updateTotalPage] = useState<number>(0);

  const [resultPortfolios, updateResultPortfolios] = useState<
    DPBPDamaged | undefined
  >(undefined);
  const [searchObject, setSearchObject] = useState<SearchValueType | undefined>(
    undefined
  );
  const [inputChipObject, setInputChipObject] = useState<InputSearchValueType>(
    {}
  );

  const [openPortfolioModal, updatePortfolioModal] = useState<boolean>(false);

  const [industrialTechs, setIndustrialTechs] = useState<IndustryTech[]>([]);
  const [participants, setParticipants] = useState<UserInfo[]>([]);
  const [round, setRound] = useState<RoundProps[]>([]);
  const [addrList, setAddrList] = useState<string[]>([]);
  const [fundAccountList, setFundAccountList] = useState<FundAccountType[]>([]);
  const [investmentYears, setInvestmentYears] = useState<number[]>([]);

  const onPortfolioModal = (portfolio: DBDamagedPortfolio) => {
    console.log("clicn portfolio modal");
    currentPortfolio.current = portfolio;
    updatePortfolioModal(true);
  };

  const getAlumniPortfolioList = async (page?: number) => {
    if (isSearching.current || !searchObject) return;

    try {
      isSearching.current = true;
      const result = await getDatabaseDamaged({
        pageIndex: searchObject.curPage || 1,
        dataPerPage: countPerPage,
        searchWord: searchObject.keyword,
        acIndustrialTech:
          searchObject.industry!.length === industrialTechs.length ||
          searchObject.industry!.length === 0 // 전체선택은 params에 포함시키지 않는다
            ? undefined
            : searchObject.industry,
        acUser:
          searchObject.participant!.length === participants.length ||
          searchObject.participant!.length === 0
            ? undefined
            : searchObject.participant,
        addr1:
          searchObject.addr1!.length === addrList.length ||
          searchObject.addr1!.length === 0
            ? undefined
            : searchObject.addr1,
        acFundAccount:
          searchObject.fundAccounts!.length === fundAccountList.length ||
          searchObject.fundAccounts!.length === 0
            ? undefined
            : searchObject.fundAccounts,
        round:
          searchObject.round!.length === round.length ||
          searchObject.round!.length === 0
            ? undefined
            : searchObject.round,
        minInvestmentYear: searchObject.minInvestmentYear,
        maxInvestmentYear: searchObject.maxInvestmentYear,
        minInvestmentQuarter: searchObject.minInvestmentQuarter,
        maxInvestmentQuarter: searchObject.maxInvestmentQuarter,
        minPurchasePrincipal:
          inputChipObject.minPurchasePrincipal &&
          Math.floor(inputChipObject.minPurchasePrincipal * 100000000),
        maxPurchasePrincipal:
          inputChipObject.maxPurchasePrincipal &&
          Math.floor(inputChipObject.maxPurchasePrincipal * 100000000),
        periodType: inputChipObject.periodType,
        minPeriod: inputChipObject.minPeriod,
        maxPeriod: inputChipObject.maxPeriod,
      });
      setDatabaseHistory({
        page: searchObject.curPage || 1,
        search: {
          keyword: searchObject.keyword,
          acIndustrialTechId: searchObject.industry,
          acUser: searchObject.participant,
          addr1: searchObject.addr1,
          acFundAccountId: searchObject.fundAccounts,
          roundId: searchObject.round,

          minInvestmentYear: searchObject.minInvestmentYear || 0,
          maxInvestmentYear: searchObject.maxInvestmentYear || 0,
          minInvestmentQuarter: searchObject.minInvestmentQuarter || 0,
          maxInvestmentQuarter: searchObject.maxInvestmentQuarter || 0,
          minPurchasePrincipal: inputChipObject.minPurchasePrincipal || 0,
          maxPurchasePrincipal: inputChipObject.maxPurchasePrincipal || 0,

          periodType: inputChipObject.periodType || "",
          minPeriod: inputChipObject.minPeriod || "",
          maxPeriod: inputChipObject.maxPeriod || "",
        },
      });

      console.log(result);
      if (result.success) {
        updateTotalPage(result.data?.totalPage || 1);
        updateTotalCount(result.data?.totalCount || 0);
        updateResultPortfolios(result.data);
      }
      isSearching.current = false;
    } catch (error) {
      isSearching.current = false;
    }
  };

  const init = async () => {
    const addr1: string[] = [];
    const history = getDatabaseHistory();
    const searchData = await getDatabaseSearchData();
    const industrialResult = await getAllIndustrialTech();
    const allUserResult = await getAllAcUser("user");
    const _investmentYears: number[] = [];
    for (var year = getNewDate().getFullYear(); year >= 2014; year--) {
      _investmentYears.splice(0, 0, year);
    }

    if (searchData) {
      searchData.addr1?.forEach((item) => addr1.push(item.addr1));
      setRound(searchData.round || []);
      setFundAccountList(searchData.acFundAccount || []);
      setAddrList(addr1);
    }

    industrialResult && setIndustrialTechs(industrialResult);
    allUserResult && setParticipants(allUserResult);
    setInvestmentYears(_investmentYears);

    console.log(history);

    setSearchObject({
      keyword: history?.search?.keyword || "",
      industry: history?.search?.acIndustrialTechId || industrialResult,
      participant: history?.search?.acUser || allUserResult,
      fundAccounts:
        history?.search?.acFundAccountId || searchData?.acFundAccount,
      round: history?.search?.roundId || searchData?.round,
      addr1: history?.search?.addr1 || addr1,
      curPage: history?.page || 1,

      //
      minInvestmentYear: history?.search?.minInvestmentYear || undefined,
      maxInvestmentYear: history?.search?.maxInvestmentYear || undefined,
      minInvestmentQuarter: history?.search?.minInvestmentQuarter || undefined,
      maxInvestmentQuarter: history?.search?.maxInvestmentQuarter || undefined,
      minPurchasePrincipal: history?.search?.minPurchasePrincipal || undefined,
      maxPurchasePrincipal: history?.search?.maxPurchasePrincipal || undefined,
      periodType: history?.search?.periodType || undefined,
      minPeriod: history?.search?.minPeriod || undefined,
      maxPeriod: history?.search?.maxPeriod || undefined,
    });

    setInputChipObject({
      minPurchasePrincipal: history?.search?.minPurchasePrincipal || undefined,
      maxPurchasePrincipal: history?.search?.maxPurchasePrincipal || undefined,
      periodType: history?.search?.periodType || undefined,
      minPeriod: history?.search?.minPeriod || undefined,
      maxPeriod: history?.search?.maxPeriod || undefined,
    });
  };

  useEffect(() => {
    if (
      industrialTechs.length > 0 &&
      round.length > 0 &&
      participants.length > 0 &&
      fundAccountList.length > 0 &&
      addrList.length > 0
    )
      getAlumniPortfolioList();
  }, [
    searchObject,
    industrialTechs,
    round,
    participants,
    fundAccountList,
    addrList,
  ]);

  useEffect(() => {
    if (!openPortfolioModal) {
      currentPortfolio.current = undefined;
    }
  }, [openPortfolioModal]);

  useEffect(() => {
    init();
  }, []);

  return [
    searchObject,
    inputChipObject,
    investmentYears,
    totalCount,
    countPerPage,
    totalPage,
    resultPortfolios,
    currentPortfolio,
    openPortfolioModal,
    industrialTechs,
    round,
    participants,
    addrList,
    fundAccountList,
    setSearchObject,
    setInputChipObject,
    onPortfolioModal,
    updatePortfolioModal,
  ] as const;
};

export default useDamagedSearch;
