import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IAMilestoneKPI } from "../../../../../../type/data";

import { TableGrid, TableGridHeaderProps } from "../../table/TableGrid";
import { CustomInput } from "../../table/custom-cell/CustomInput";
import { CustomDate } from "../../table/custom-cell/CustomDate";

import { RemoveComma, getYMD } from "../../../../../../common/commonUtil";
import { CustomSelector } from "../../table/custom-cell/CustomSelector";
import { investmentStageTypeList } from "../../../interface/REI.interface";
import { CustomTextArea } from "../../table/custom-cell/CustomTextArea";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  dataList?: IAMilestoneKPI[];
  onDataChange: (data: IAMilestoneKPI[]) => void;
}

export const MilestoneKpiTable: React.FC<Props> = (props) => {
  const [dataList, updateDataList] = useState<IAMilestoneKPI[] | undefined>(
    undefined
  );
  const headers: TableGridHeaderProps[] = [
    {
      header: "기간",
      key: "kpiStartDate",
      width: 140,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <FlexColum>
            <CustomDate
              isMonth
              isReadOnly={isReadOnly}
              disabled={disabled}
              defaultDate={rowData["kpiStartDate"]}
              onChangeDate={(date) => {
                if (!dataList) return;
                rowData.kpiStartDate = getYMD(date);
                updateDataList([...dataList]);
              }}
            />
            <div style={{ lineHeight: 1 }}>~</div>
            <CustomDate
              isMonth
              isReadOnly={isReadOnly}
              disabled={disabled}
              defaultDate={rowData["kpiEndDate"]}
              onChangeDate={(date) => {
                if (!dataList) return;
                rowData.kpiEndDate = getYMD(date);
                updateDataList([...dataList]);
              }}
            />
          </FlexColum>
        );
      },
    },
    {
      header: "투자단계",
      key: "investmentStage",
      width: 145,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomSelector
            isReadOnly={isReadOnly}
            disabled={disabled}
            defaultData={data}
            selectList={investmentStageTypeList}
            placehoder="선택"
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.investmentStage = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "자금(억원)",
      key: "costEstimationAmount",
      width: 100,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1"
            onBlur={(value) => {
              if (!dataList) return;

              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.costEstimationAmount = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "인력(명)",
      key: "peopleNum",
      width: 90,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomInput
            textType="numberWithComma"
            defaultValue={data}
            transfromPixel={(n) => n}
            placeholder="1"
            onBlur={(value) => {
              if (!dataList) return;

              const numValue =
                value.length > 0 ? parseInt(RemoveComma(value), 10) : undefined;
              rowData.peopleNum = numValue;
              updateDataList([...dataList]);
            }}
            disabled={disabled}
            isReadOnly={isReadOnly}
          />
        );
      },
    },
    {
      header: "마일스톤",
      key: "mileston",
      width: (1200 - 155 - 130 - 190 - 74) / 3,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomTextArea
            isReadOnly={isReadOnly}
            defaultData={data}
            placeholder={`1.플랫폼 베타버전 완성\n2.시제품 제작`}
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.mileston = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "리스크",
      key: "risk",
      width: (1200 - 155 - 130 - 190 - 74) / 3,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomTextArea
            isReadOnly={isReadOnly}
            defaultData={data}
            placeholder={`1.시스템 연동 시 기술적 이슈 발생`}
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.risk = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
    {
      header: "KPI",
      key: "kpi",
      width: (1200 - 155 - 130 - 190 - 74) / 3,
      customCell: (data, rowData, allData, isReadOnly, disabled) => {
        return (
          <CustomTextArea
            isReadOnly={isReadOnly}
            defaultData={data}
            placeholder={`1.플랫폼 베타버전 및 제휴시스템 연동\n2.시제품 제작 매출 3억원 달성\n3.MAU 5,000명 확보`}
            onChangeData={(value) => {
              if (!dataList) return;
              rowData.kpi = value;
              updateDataList([...dataList]);
            }}
          />
        );
      },
    },
  ];
  const addRowData = () => {
    const newData: IAMilestoneKPI = {};
    const newDataList: IAMilestoneKPI[] = [...(dataList || []), newData];
    updateDataList(newDataList);
  };
  const onClickDeleteBtn = (id: number) => {
    if (!dataList) return;

    const newDataList = dataList.filter((item, index) => index !== id);
    updateDataList([...newDataList]);
  };

  useEffect(() => {
    updateDataList(props.dataList);
  }, [props.dataList]);

  useEffect(() => {
    if (dataList) props.onDataChange([...dataList]);
  }, [dataList]);

  if (!dataList) return null;

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={dataList.map((item, index) => {
          return {
            id: index,
            identificationNumber: index,
            data: item,
          };
        })}
        onClickAddBtn={addRowData}
        onClickDeleteBtn={onClickDeleteBtn}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexColum = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
