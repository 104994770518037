import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../components/navigation/NavigationLayout";
import MobileReporting from "./mobile/MobileReporting";
import DeskReporting from "./desktop/DeskReporting";

const Reporting = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobileReporting /> : <DeskReporting />}
    </NavigationLayout>
  );
};
export default Reporting;
