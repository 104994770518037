import { DefaultButton } from "lattice_core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";

import ChatDivider from "./speech-bubble/ChatDivider";

import SBSystem from "./speech-bubble/SBSystem";
import SBUserMe from "./speech-bubble/SBUserMe";
import SBUserOther from "./speech-bubble/SBUserOther";
import { ReactComponent as CloseBtn } from "../../../../../../assets/image/new_close_button.svg";
import { ChatingSpeachBubbleProps } from "./useChating";

import CurrentRead from "./speech-bubble/CurrentRead";

interface Props {
  firstIndex: number;
  isOpenChating: boolean;
  SBChatingMsg: ChatingSpeachBubbleProps[];
  currentReadChatItemId: number;
  chatingFocusing: boolean;
  top?: number;
  updateChatingFocusing: React.Dispatch<React.SetStateAction<boolean>>;
  nextPageChat: () => void;
  cancel?: () => void;
  clearUnreadMsgCount: () => void;
  sendChating: (text: string) => void;
  transfromPixel: (value: number) => number;
}

const ChatingDetail: React.FC<Props> = (props) => {
  const isEnteredRef = useRef<boolean>(false);

  const [value, updateValue] = useState<string>("");
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const virtuoso = useRef<VirtuosoHandle>(null);
  const timer = useRef<NodeJS.Timeout | undefined>(undefined);

  const followOutput = useCallback((isAtBottom: boolean) => {
    console.log("MessagesList: followOutput isAtBottom", isAtBottom);
    return isAtBottom ? "smooth" : false;
  }, []);

  useEffect(() => {
    if (props.isOpenChating && virtuoso.current) {
      virtuoso.current.autoscrollToBottom();
    }
    props.clearUnreadMsgCount();
  }, [props.isOpenChating]);

  const handleScroll = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setIsScrolling(false);
      timer.current = undefined;
    }, 3000);
  };

  useEffect(() => {
    if (isScrolling) {
      handleScroll();
    }
  }, [isScrolling]);

  return (
    <Container>
      <Header transfromPixel={props.transfromPixel}>
        <span className="heading-18-md header__title">알림</span>
        {props.cancel && (
          <div className="close__btn" onClick={props.cancel}>
            <CloseBtn />
          </div>
        )}
      </Header>
      <Body transfromPixel={props.transfromPixel}>
        <Virtuoso
          className={`${isScrolling ? "on-scrollbar" : "no-scrollbar"}`}
          ref={virtuoso}
          isScrolling={(e) => {
            e && setIsScrolling(true);
          }}
          style={{
            height: "100%",
            paddingTop: 10,
            overscrollBehavior: "contain",
          }}
          initialTopMostItemIndex={props.SBChatingMsg.length - 1}
          firstItemIndex={Math.max(0, props.firstIndex)}
          startReached={props.nextPageChat}
          data={props.SBChatingMsg}
          followOutput={followOutput}
          itemContent={(index, data) => {
            if (data.type === 1 || data.type === 2) {
              return (
                <SBSystem
                  key={index}
                  msg={data}
                  transfromPixel={props.transfromPixel}
                />
              );
            }
            if (data.type === 3) {
              if (data.isMyChat) {
                return (
                  <SBUserMe
                    key={index}
                    msg={data}
                    transfromPixel={props.transfromPixel}
                  />
                );
              }
              return (
                <SBUserOther
                  key={index}
                  msg={data}
                  transfromPixel={props.transfromPixel}
                />
              );
            }
            if (data.type === 4) {
              return (
                <ChatDivider
                  date={data.createAt}
                  transfromPixel={props.transfromPixel}
                />
              );
            }

            if (data.type === 5) {
              return <CurrentRead transfromPixel={props.transfromPixel} />;
            }

            return null;
          }}
        />
      </Body>
      <Footer transfromPixel={props.transfromPixel}>
        <div
          className={`chat__text__container ${
            props.chatingFocusing ? "focusing" : ""
          }`}
        >
          <textarea
            className="text__container body-14-rg"
            value={value}
            onChange={(e) => {
              updateValue(e.target.value);
            }}
            onFocus={() => {
              props.updateChatingFocusing(true);
            }}
            onBlur={() => {
              props.updateChatingFocusing(false);
            }}
            onKeyDown={(e) => {
              if (
                !e.shiftKey &&
                e.code === "Enter" &&
                e.currentTarget.value.length &&
                isEnteredRef.current === false
              ) {
                isEnteredRef.current = true;
                props.sendChating(value);
                virtuoso.current?.scrollToIndex({
                  index: 10000,
                });
                e.currentTarget.value = "";
                updateValue("");
              } else if (isEnteredRef.current === true) {
                isEnteredRef.current = false;
                e.currentTarget.value = "";
                updateValue("");
              }

              if (
                !e.shiftKey &&
                e.code === "Enter" &&
                e.currentTarget.value.length === 0
              ) {
                e.preventDefault();
              }
            }}
          />
          <div className="send__container">
            <DefaultButton
              className="caption-12-md"
              buttonType="filled"
              sizeType="xxsmall"
              disabled={value.length === 0}
              text="전송"
              buttonColor="#173AFF"
              hoverColor="#112CBF"
              onClick={() => {
                props.sendChating(value);
                virtuoso.current?.scrollToIndex({
                  index: 10000,
                });
                updateValue("");
              }}
            />
          </div>
        </div>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  height: ${(props) => props.transfromPixel(60)}px;
  min-height: ${(props) => props.transfromPixel(60)}px;
  padding: 0 ${(props) => props.transfromPixel(24)}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) => props.transfromPixel(1)}px solid #edeeef;

  .header__title {
    padding-top: ${(props) => props.transfromPixel(10)}px;
  }

  .close__btn {
    width: ${(props) => props.transfromPixel(32)}px;
    height: ${(props) => props.transfromPixel(32)}px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    :hover {
      background: #f3f4f4;
      border-radius: ${(props) => props.transfromPixel(4)}px;
    }
  }
`;
const Body = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  height: ${(props) => props.transfromPixel(101)}px;
  min-height: ${(props) => props.transfromPixel(101)}px;
  display: flex;
  flex-direction: row;
  border-top: ${(props) => props.transfromPixel(1)}px solid #edeeef;
  padding: ${(props) => props.transfromPixel(16)}px
    ${(props) => props.transfromPixel(24)}px;

  .chat__text__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: ${(props) => props.transfromPixel(1)}px solid #edeeef;
    border-radius: ${(props) => props.transfromPixel(4)}px;
    transition: all 0.2s ease;
    overflow: hidden;
    &.focusing {
      border: ${(props) => props.transfromPixel(1)}px solid #a2c7ff;
    }
    .text__container {
      flex-grow: 1;
      height: 100%;
      outline: none;
      border: 0px;
      resize: none;
      padding: ${(props) => props.transfromPixel(6)}px
        ${(props) => props.transfromPixel(12)}px;
      :focus {
        outline: none;
      }
    }
    .send__container {
      width: ${(props) => props.transfromPixel(77)}px;
      min-width: ${(props) => props.transfromPixel(77)}px;
      height: ${(props) => props.transfromPixel(68)}px;
      min-height: ${(props) => props.transfromPixel(68)}px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default ChatingDetail;
