import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Document, Page, pdfjs } from "react-pdf";
import { PDFPageImage } from "../interface/Minutes.interface";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  file?: any;
  pdfImage?: PDFPageImage;
  pageNum?: number;
  isError?: boolean;
  onLoaded?: () => void;
}
const PDFPage: React.FC<Props> = (props) => {
  if (!props.pdfImage) return null;
  return (
    <Container>
      {props.isError ? (
        <PageLoadedError className="body-14-rg">
          PDF를 불러오지 못했습니다.
        </PageLoadedError>
      ) : (
        <img
          src={props.pdfImage.imgUrl}
          alt={`Page ${props.pageNum}`}
          style={{
            width: "100%",
            height:
              props.pdfImage.width > props.pdfImage.height
                ? "fit-content"
                : "100%",
            aspectRatio: props.pdfImage.aspectRatio,
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: calc(210mm);
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: white;
`;

const PageLoadedError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default PDFPage;
