import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CountingBtn from "../components/CountingBtn";
import { OrderingBtn } from "../components/OrderingBtn";
import {
  AcceleratingCountProps,
  AcceleratingFilterProps,
  AcceleratingStructureFilterProps,
  sortingList,
} from "../../interface/Accelerating.interface";
import { UserInfo } from "../../../../type/data";

interface Props {
  myInfo?: UserInfo;
  count?: AcceleratingCountProps;
  filterList: AcceleratingStructureFilterProps[];
  filterItem?: AcceleratingFilterProps;
  sortingItem: string;
  updateSortingItem: React.Dispatch<React.SetStateAction<string>>;
  updateFilterItem: React.Dispatch<
    React.SetStateAction<AcceleratingFilterProps | undefined>
  >;
}

const FilterView: React.FC<Props> = (props) => {
  const {
    myInfo,
    count,
    filterList,
    sortingItem,
    updateSortingItem,
    filterItem,
    updateFilterItem,
  } = props;
  return (
    <Container>
      <FilterContainer className="caption-12-md">
        {myInfo && (
          <FilterChip
            className={filterItem?.type === "my_deal" ? "selected" : ""}
            onClick={() => {
              if (
                filterItem &&
                filterItem.type === "my_deal" &&
                filterItem.id === parseInt(`${myInfo.id || 0}`)
              ) {
                return updateFilterItem(undefined);
              }
              updateFilterItem({
                id: parseInt(`${myInfo.id || 0}`),
                type: "my_deal",
              });
            }}
          >
            마이 포트폴리오
          </FilterChip>
        )}
        {myInfo && (
          <FilterChip
            className={
              filterItem &&
              filterItem.type === "structure_deal" &&
              filterItem.id === parseInt(`${myInfo.acStructureId || 0}`)
                ? "selected"
                : ""
            }
            onClick={() => {
              if (
                filterItem &&
                filterItem.type === "structure_deal" &&
                filterItem.id === parseInt(`${myInfo.acStructureId || 0}`)
              ) {
                return updateFilterItem(undefined);
              }
              updateFilterItem({
                id: parseInt(`${myInfo.acStructureId || 0}`),
                type: "structure_deal",
              });
            }}
          >
            팀 포트폴리오
          </FilterChip>
        )}
        {filterList.length > 0 && <FilterDivider />}
        {filterList.map((item, index) => {
          return (
            <FilterChip
              key={index}
              className={
                filterItem &&
                filterItem.type === "structure_deal" &&
                filterItem.id === item.acStructureId
                  ? "selected"
                  : ""
              }
              onClick={() => {
                if (
                  filterItem &&
                  filterItem.type === "structure_deal" &&
                  filterItem.id === item.acStructureId
                ) {
                  return updateFilterItem(undefined);
                }
                updateFilterItem({
                  id: item.acStructureId,
                  type: "structure_deal",
                });
              }}
            >
              {item.name}
            </FilterChip>
          );
        })}
      </FilterContainer>
      <RightContainer>
        {count && <CountingBtn count={count} />}
        <OrderingBtn
          defaultData={sortingItem}
          selectList={sortingList}
          onChangeData={(data) => {
            updateSortingItem(data.text);
          }}
        />
      </RightContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FilterContainer = styled.div`
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
const FilterChip = styled.span`
  width: max-content;
  height: 32px;
  min-height: 32px;
  max-height: 32px;

  padding: 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border-radius: 16px;

  color: var(--text-04);
  border: 1px solid var(--gray-300);
  background-color: var(--background-contents01);
  cursor: pointer;
  transition: all 0.2s ease;

  &.selected {
    color: var(--blue-400);
    border: 1px solid var(--blue-400);
    background-color: var(--blue-50);
  }

  :hover {
    color: var(--blue-400);
    border: 1px solid var(--blue-400);
    background-color: var(--blue-50);
  }
`;
const FilterDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--gray-200);
`;

const RightContainer = styled.div`
  width: auto;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export default FilterView;
