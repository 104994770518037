import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../../components/navigation/NavigationLayout";
import DeskFundAccountInfo from "./desktop/DeskFundAccountInfo";
import MobileFundAccountInfo from "./mobile/MobileFundAccountInfo";

const FundAccountInfo = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return (
    <NavigationLayout>
      {isMobile ? <MobileFundAccountInfo /> : <DeskFundAccountInfo />}
    </NavigationLayout>
  );
};
export default FundAccountInfo;
