import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import axios from "axios";
import * as api from "../../api/api";
import { ReactComponent as Loading } from "../../assets/image/loading.svg";
import useGoogle from "../../hooks/useGoogle";
import { useMediaQuery } from "react-responsive";

interface Props {
  pdfKey: string;
  type: "AWS" | "GOOGLE";
}
export const PDFViewer: React.FC<Props> = (props) => {
  const { pdfKey, type } = props;
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  const [
    isGapiReady,
    isLoggedIn,
    handleSignInClick,
    handleSignOutClick,
    getPDFBlobFile,
    onLoginLattice,
    createFolder,
  ] = useGoogle();

  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [fileLoding, setFileLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getGoolgeUrl = async () => {
    try {
      console.log(pdfKey, isGapiReady);
      if (pdfKey === undefined || !isGapiReady) return;
      setFileLoading(true);
      const blob = await getPDFBlobFile(pdfKey);
      if (blob) {
        const newBlob = new Blob([blob], { type: "application/pdf" });
        const newUrl = window.URL.createObjectURL(newBlob);
        setPdfUrl(newUrl);
      }
      setFileLoading(false);
    } catch (error) {
      setIsError(true);
    }
  };

  const getPDFFile = async () => {
    try {
      if (pdfKey === undefined) return;
      setFileLoading(true);
      const fileUrl = (
        await axios.get(api.getFile(), {
          params: {
            key: pdfKey,
          },
        })
      ).data;

      fetch(fileUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf", // 이래야 브라우저가 pdf라고 인식하고
          "Content-Disposition": "inline", // 이래야  브라우저가 다운로드말고 화면에 비추도록 한다
        },
      })
        .then((res) => {
          console.log(res);
          return res.blob();
        })
        .then((res) => {
          console.log(res);
          const newBlob = new Blob([res], { type: "application/pdf" });
          const newUrl = window.URL.createObjectURL(newBlob);
          setPdfUrl(newUrl);
          setFileLoading(false);
        });
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    setFileLoading(true);
    setPdfUrl("");

    if (!isLoggedIn || !isGapiReady) return;
    setIsError(false);
    type === "AWS" ? getPDFFile() : getGoolgeUrl();
  }, [isLoggedIn, isGapiReady, props.pdfKey]);

  useEffect(() => {
    if (isMobile) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_black";
      link.click();
    }
  }, [pdfUrl]);
  return (
    <Container>
      {pdfUrl.length > 0 && (
        <object
          data={pdfUrl}
          type="application/pdf"
          width={"100%"}
          height={"100%"}
        >
          <embed
            title="pdd-viewer"
            className="viewer__iframe"
            src={pdfUrl}
            type="application/pdf"
            width={"100%"}
            height={"100%"}
          />
        </object>
      )}
      {!isError && fileLoding && (
        <Loading className="rotating" width={30} height={30} />
      )}
      {isError && (
        <div className="heading-18-md" style={{ color: "#7e8086" }}>
          <div>파일을 찾을 수 없습니다.</div>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);

  .viewer__iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }
`;
