import React from "react";
import styled from "styled-components";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

/**
 * @param id : number타입 그리드에서 순서를 나타낸다. 0 ~ n;
 * @param identificationNumber : 체크 할때 식별할 수 있는 유니크한 id
 * @param data : grid에 보여줘야 하는 데이터
 */
export interface TableGridProps {
  id: number;
  identificationNumber: number;
  data: any;
}

export interface TableGridHeaderProps {
  header: string;
  key: string;
  width: number;

  type?: "text" | "number";
  style?: React.CSSProperties;
  aligin?: "start" | "center" | "end";
  tooltipId?: string;
  customHeaderCell?: (allData?: TableGridProps[]) => JSX.Element;
  customCell?: (
    data: any,
    allData?: any,
    isReadOnly?: boolean,
    disabled?: boolean
  ) => JSX.Element;
}

interface Props {
  header: TableGridHeaderProps[];
  dataList?: TableGridProps[];
  isReadOnly?: boolean;
  disabled?: boolean;
}

export const TableGrid: React.FC<Props> = (props) => {
  const totalWidth =
    props.header
      .map((item) => item.width)
      .reduce((prev, cur) => prev + cur, 0) + 74;
  return (
    <Container>
      <TableHeader isReadOnly={props.isReadOnly} headers={props.header} />
      {!props.disabled &&
        props.dataList &&
        props.dataList.map((item) => {
          return (
            <TableRow
              headerData={props.header}
              rowData={item}
              isReadOnly={props.isReadOnly}
              disabled={props.disabled}
              transfromPixel={(n) => n}
              isDeleteAble={props.dataList && props.dataList.length > 1}
            />
          );
        })}
      {props.disabled && props.isReadOnly && (
        <AddRow
          style={{
            width: totalWidth,
            cursor: "default",
            height: 50 * (764 / 1200),
            padding: `${14 * (764 / 1200)}px ${20 * (764 / 1200)}`,
          }}
        >
          <span
            className="caption-12-rg"
            style={{ color: "var(--text-inactive)" }}
          >
            입력한 내용이 없습니다.
          </span>
        </AddRow>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;

const AddRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  border-bottom: 1px solid var(--divider-200);
  background-color: var(--background-contents01);

  cursor: pointer;
`;
