import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";

import { DefaultButton } from "lattice_core";

import RowTitleBox from "../../../../../components/box/RowTitleBox";

import { getYMD } from "../../../../../common/commonUtil";
import { InputDate } from "../../../../../components-2.0v/date-picker/InputDate";

interface Props {
  title?: string;
  close: () => void;
  submit: (date: string) => void;
}

export const InputDateModal: React.FC<Props> = (props) => {
  const { title = "안내", close, submit } = props;
  const [value, setValue] = useState<string>("");

  const onClickSubmit = () => {
    if (value.length === 0) return;
    submit(value);
    close();
  };

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          {title}
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        <RowTitleBox title="일자" titleWidth={90} style={{ marginTop: 24 }}>
          <InputDate
            onChange={(date) => {
              if (date) setValue(getYMD(date));
            }}
          />
        </RowTitleBox>
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={close}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-blue)"}
            hoverColor={"var(--blue-600)"}
            disabled={value.length === 0}
            text={"확인"}
            onClick={onClickSubmit}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 480px;
  min-width: 480px;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  padding: 0px 36px;
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
