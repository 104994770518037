import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { NewInvestmentSection } from "./NewInvestmentSection";
import { PerformanceSection } from "./PerformanceSection";
import { ExpectationManagementSection } from "./ExpectationManagementSection";
import { OperationStatusManagementSection } from "./OperationStatusManagementSection";

import { FundAccountLight, IndustryTech } from "../../../../../../type/data";
import FilterView from "./FilterView";
import { TotalPerformance } from "./TotalPerformance";

interface Props {
  industry: IndustryTech[];
  fundAccount: FundAccountLight[];
}
export const CompanyPerformance: React.FC<Props> = (props) => {
  const { fundAccount, industry } = props;

  const [selectedFundAccount, updateSelectedFundAccount] = useState<
    FundAccountLight[]
  >([...fundAccount]);
  const [selectedIndustry, updateSelectedIndustry] = useState<IndustryTech[]>([
    ...industry,
  ]);

  const onChangeFundAccount = (account?: FundAccountLight) => {
    if (!account) return;
    const index = selectedFundAccount.findIndex(
      (item) => item.acFundAccountId === account.acFundAccountId
    );
    if (index !== -1) {
      if (selectedFundAccount.length === fundAccount.length) {
        updateSelectedFundAccount([account]);
      } else {
        selectedFundAccount.splice(index, 1);
        updateSelectedFundAccount([...selectedFundAccount]);
      }
    } else {
      updateSelectedFundAccount([...selectedFundAccount, account]);
    }
  };

  const onChangeIndustry = (industryTech?: IndustryTech) => {
    if (!industryTech) return;
    const index = selectedIndustry.findIndex(
      (item) => item.acIndustrialTechId === industryTech.acIndustrialTechId
    );
    if (index !== -1) {
      if (selectedIndustry.length === industry.length) {
        updateSelectedIndustry([industryTech]);
      } else {
        selectedIndustry.splice(index, 1);
        updateSelectedIndustry([...selectedIndustry]);
      }
    } else {
      updateSelectedIndustry([...selectedIndustry, industryTech]);
    }
  };

  const onClickInitBtn = () => {
    updateSelectedFundAccount([...fundAccount]);
    updateSelectedIndustry([...industry]);
  };

  useEffect(() => {
    if (fundAccount.length > 0 && selectedFundAccount.length === 0) {
      updateSelectedFundAccount([...fundAccount]);
    }

    if (industry.length > 0 && selectedIndustry.length === 0) {
      updateSelectedIndustry([...industry]);
    }
  }, [selectedFundAccount, selectedIndustry]);
  return (
    <Container>
      <FilterView
        industry={industry}
        fundAccount={fundAccount}
        selectedIndustry={selectedIndustry}
        selectedFundAccount={selectedFundAccount}
        onChangeIndustry={onChangeIndustry}
        onChangeFundAccount={onChangeFundAccount}
        onClickInitBtn={onClickInitBtn}
      />
      <div style={{ marginTop: 36 }}>
        <TotalPerformance
          selectedFundAccountId={selectedFundAccount.map(
            (item) => item.acFundAccountId
          )}
          selectedIndustryId={selectedIndustry.map((item) =>
            parseInt(`${item.acIndustrialTechId}`)
          )}
        />
      </div>
      <div style={{ marginTop: 36 }}>
        <NewInvestmentSection
          selectedFundAccountId={selectedFundAccount.map(
            (item) => item.acFundAccountId
          )}
          selectedIndustryId={selectedIndustry.map((item) =>
            parseInt(`${item.acIndustrialTechId}`)
          )}
        />
      </div>

      <div style={{ marginTop: 36 }}>
        <PerformanceSection
          selectedFundAccountId={selectedFundAccount.map(
            (item) => item.acFundAccountId
          )}
          selectedIndustryId={selectedIndustry.map((item) =>
            parseInt(`${item.acIndustrialTechId}`)
          )}
        />
      </div>

      <div style={{ marginTop: 36 }}>
        <ExpectationManagementSection
          selectedFundAccountId={selectedFundAccount.map(
            (item) => item.acFundAccountId
          )}
          selectedIndustryId={selectedIndustry.map((item) =>
            parseInt(`${item.acIndustrialTechId}`)
          )}
        />
      </div>

      <div style={{ marginTop: 36 }}>
        <OperationStatusManagementSection
          selectedFundAccountId={selectedFundAccount.map(
            (item) => item.acFundAccountId
          )}
          selectedIndustryId={selectedIndustry.map((item) =>
            parseInt(`${item.acIndustrialTechId}`)
          )}
        />
      </div>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
`;
