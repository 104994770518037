import React, { useState } from "react";
import styled from "styled-components";

import NewNavigationMenu from "./NewNavigationMenu";
import { ReactComponent as Logo } from "../../../2.0lattice/assets/image/new_lattice_logo.svg";
import { NavigationMenu } from "./NewNavigationLayout";
import { useNavigate } from "react-router-dom";

interface Props {
  menus: NavigationMenu[];
  transfromPixel: (value: number) => number;
}

export interface LocationProps {
  pageAddress: string;
  addressName: string;
  pageName: string;
  type: "active" | "deactive";
  disabled?: boolean;
  validationAuth?: () => boolean;
}

const NewNavigation: React.FC<Props> = ({ menus, transfromPixel }) => {
  const navigate = useNavigate();
  const [spreadIndex, updateSpreadIndex] = useState<number | undefined>(
    menus.findIndex(
      (menu) =>
        menu.subMenu &&
        menu.subMenu.find((submenu) =>
          window.location.pathname.split("/").includes(submenu.addressName)
        )
    )
  );

  return (
    <Container>
      <LogoContainer transfromPixel={transfromPixel}>
        <FlexColumn style={{ gap: 12 }}>
          <AdminLabel className="caption-10-sb">Admin</AdminLabel>
          <Logo width={transfromPixel(82)} height={transfromPixel(20)} />
        </FlexColumn>
      </LogoContainer>
      <MenuContainer transfromPixel={transfromPixel}>
        {menus.map((item, index) => {
          if (item.validationAuth === undefined || item.validationAuth()) {
            return (
              <NewNavigationMenu
                key={index}
                index={index}
                item={item}
                isSpread={index === spreadIndex}
                onChangeSpreadItem={(idx) => {
                  updateSpreadIndex(idx !== spreadIndex ? idx : undefined);
                }}
                transfromPixel={transfromPixel}
              />
            );
          }

          return null;
        })}
      </MenuContainer>
      <NavDivitder transfromPixel={transfromPixel} />
      <ExitBtn
        className="heading-14-md"
        transfromPixel={transfromPixel}
        onClick={() => {
          navigate("/");
        }}
      >
        나가기
      </ExitBtn>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LogoContainer = styled.div<{ transfromPixel: (value: number) => number }>`
  width: fit-content;
  height: fit-content;
  padding: ${(props) => props.transfromPixel(20)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.transfromPixel(24)}px auto
    ${(props) => props.transfromPixel(82)}px auto;

  cursor: pointer;

  path {
    fill: var(--primary-purple);
  }
`;
const MenuContainer = styled.ul<{ transfromPixel: (value: number) => number }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.transfromPixel(8)}px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const AdminLabel = styled.label`
  width: max-content;
  height: max-content;
  padding: 2px 5px;
  background-color: var(--purple-800);
  color: var(--white);
  border-radius: 4px;
`;

const ExitBtn = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  height: ${(props) => props.transfromPixel(40)}px;
  min-height: ${(props) => props.transfromPixel(40)}px;
  max-height: ${(props) => props.transfromPixel(40)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #1f2022; // text01
  padding-left: ${(props) => props.transfromPixel(45)}px;
  padding-right: ${(props) => props.transfromPixel(18)}px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 24px;
  margin-top: 12px;
`;

export const NavDivitder = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: calc(100% - ${(props) => props.transfromPixel(48)}px);
  height: 1px;
  margin: 0 auto;
  background-color: var(--gray-300);
`;

export default NewNavigation;
