import React from "react";
import styled from "styled-components";
import DefaultSearch from "../../../components-2.0v/search/DefaultSearch";
import FilterView from "./organism/FilterView";

import useAccelerating from "../hook/useAccelerating";
import { AcceleratingPortfolioCard } from "./organism/AcceleratingPortfolioCard";

const DeskAccelerating = () => {
  const [
    rootRef,
    initCompleted,
    myInfo,
    filterList,
    searchKeyword,
    sortingItem,
    filterItem,
    updateSearchKeyword,
    updateSortingItem,
    updateFilterItem,
    getFilterPortfolio,
    getCount,
    initFilter,
    goToPortfolio,
  ] = useAccelerating();

  return (
    <Container ref={rootRef}>
      <AcceleratingContainer>
        <ContentsHeader>
          <span className="heading-20-sb" style={{ color: "var(--text-01)" }}>
            관리
          </span>
          <DefaultSearch
            value={searchKeyword}
            placeholder="기업명, 대표명, 키워드로 검색해 주세요."
            transfromPixel={(n) => n}
            onChangeValue={(value) => {
              updateSearchKeyword(value);
              if (initCompleted) initFilter();
            }}
            onPressEnter={(value) => {
              updateSearchKeyword(value);
            }}
          />
        </ContentsHeader>
        <ContentsBody>
          <FilterView
            myInfo={myInfo}
            count={getCount()}
            filterList={filterList || []}
            filterItem={filterItem}
            sortingItem={sortingItem}
            updateSortingItem={updateSortingItem}
            updateFilterItem={updateFilterItem}
          />
          <AcceleratingList>
            {getFilterPortfolio().map((item, index) => {
              return (
                <AcceleratingPortfolioCard
                  key={index}
                  portfolio={item}
                  onClickCard={() => {
                    goToPortfolio(item.portfolioId);
                  }}
                />
              );
            })}
          </AcceleratingList>
        </ContentsBody>
      </AcceleratingContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const AcceleratingContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 1320px;
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 62px;
  min-height: 62px;
  max-height: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  z-index: 10;

  border-bottom: 1px solid var(--divider-200);
  background: var(--background-header);
`;

const ContentsBody = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  margin: 0 auto;

  gap: 32px;
`;
const AcceleratingList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
`;

export default DeskAccelerating;
