import React, {
  createElement,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import { ReactComponent as Calendar } from "../../assets/image/icon_calendar.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale"; //한국어 설정
import {
  getHMOnlyNum,
  getNewDate,
  getYMD,
  getYMDAAHMKo,
} from "../../common/commonUtil";
import { DateTime } from "../../type/data";

interface Props {
  date?: DateTime;
  disabled?: boolean;
  onChangeDate: (date: DateTime) => void;
}
export const PickerDateTime: React.FC<Props> = (props) => {
  const { date, disabled = false, onChangeDate } = props;

  const [aa, updateAA] = useState<Date | undefined>(undefined);

  const selectedDate = useRef<Date | undefined>(undefined);
  const onChangeTime = (value: Date) => {
    const newDate: DateTime = {
      date: getYMD(value),
      time: getHMOnlyNum(value),
    };
    onChangeDate({ ...newDate });
  };

  useEffect(() => {
    if (date) {
      const _date = getNewDate(`${date?.date} ${date?.time}`);
      selectedDate.current = _date;
      updateAA(_date);
    }
  }, [date]);

  return (
    <Container className={`body-14-rg ${disabled ? "disabled" : ""}`}>
      {disabled && (
        <div className="body-14-rg">
          {date?.date && date?.time ? (
            <span style={{ color: "var(--text-01)" }}>
              {getYMDAAHMKo(`${date?.date || ""} ${date?.time || ""}`)}
            </span>
          ) : (
            <span style={{ color: "var(--text-06)" }}>날짜 미지정</span>
          )}
        </div>
      )}
      {!disabled && <Calendar />}
      {!disabled && (
        <StyledDatePicker
          selected={aa}
          placeholderText="일시를 선택해주세요."
          onChange={(date: Date) => {
            updateAA(date);
          }}
          onCalendarClose={() => {
            if (aa) onChangeTime(aa);
          }}
          locale={ko}
          showTimeSelect
          withPortal
          dateFormat="yyyy년 MM월 dd일 aa hh시 mm분"
        />
      )}
      {/* {!disabled && (
        <TimeContainer>
          <input
            className="body-14-rg"
            type="date"
            style={{ color: date?.date ? "black" : "var(--text-disabled)" }}
            value={date?.date || ""}
            onChange={(e) => {
              onChangeTime("date", e.target.value);
            }}
          />
        </TimeContainer>
      )}
      {!disabled && (
        <TimeContainer>
          <input
            className="body-14-rg"
            type="time"
            style={{ color: date?.time ? "black" : "var(--text-disabled)" }}
            value={date?.time || ""}
            onChange={(e) => {
              onChangeTime("time", e.target.value);
            }}
          />
        </TimeContainer>
      )} */}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  border: 1px solid var(--gray-300);
  border-radius: 4px;
  padding: 0 10px;

  overflow: hidden;
  cursor: pointer;

  transition: all 0.2s ease;

  :hover {
    background-color: var(--hover-100);
  }

  &.disabled {
    cursor: default;
    border-color: transparent;
    :hover {
      background-color: transparent;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
    font-family: "SUIT Regular";
    font-size: 12px;
    letter-spacing: -1%;
  }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
    font-family: "SUIT Regular";
    font-size: 14px;
    letter-spacing: -1%;
  }

  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    position: relative;
    border-top-right-radius: 0.3rem;
    height: 88.3px;
    ::before {
      content: "시간";
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translate(-50%, 0);

      font-family: "SUIT SemiBold";
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -1%;
    }
  }

  .react-datepicker-time__header {
    display: none;
  }

  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-family: "SUIT SemiBold";
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -1%;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: var(--blue-500);
    color: #fff;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--blue-500);
    color: white;
    font-weight: bold;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: var(--dim-100);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }
`;

const TimeContainer = styled.div`
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 12px;

  input {
    border: 0;
    :focus {
      outline: none;
    }
    ::placeholder {
      color: var(--text-disabled);
    }
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 220px;
  height: 40px;
  border: 0;

  color: #707070;
  cursor: pointer;

  background-color: transparent;
  color: var(--text-01);

  ::placeholder {
    color: var(--text-disabled);
  }
  :focus {
    outline: none;
  }
`;
