import React from "react";
import styled from "styled-components";

import { ParticipantHisotryAdminProps } from "../interface/Portfolio.interface";
import { UserCard } from "../component/UserCard";
import { ReactComponent as Arrow } from "../../../../../assets/image/change_arrow.svg";

interface Props {
  participantHistory: ParticipantHisotryAdminProps[];
}
export const ParticipantHistory: React.FC<Props> = (props) => {
  const { participantHistory } = props;
  return (
    <Container>
      {participantHistory.map((item, index) => {
        return (
          <ParticipantContainer>
            <UserCard user={item.acUser} date={item.date} />
            {participantHistory.length - 1 !== index && (
              <Arrow className="icon" />
            )}
          </ParticipantContainer>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
`;

const ParticipantContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  .icon {
    width: 24px;
    height: 24px;
  }
`;
