import React from "react";
import styled, { css } from "styled-components";
import { NotificationCardProps } from "../interface/Notification.interface";
import { getYMDHMKo } from "../../../common/commonUtil";
import { NotificationStatus } from "../interface/Notification.interface";
import { ReactComponent as Remove } from "../../../assets/image/icon_close.svg";

interface Props {
  cardItem: NotificationCardProps;
  isNotification?: boolean;
  onDeleteCard?: (id: number) => void;
  goTolink?: (card: NotificationCardProps, isNotification?: boolean) => void;
}
const NotificationCard: React.FC<Props> = (props) => {
  const { cardItem, isNotification, onDeleteCard, goTolink } = props;

  return (
    <Container
      onClick={() => {
        goTolink && goTolink(cardItem, isNotification);
      }}
    >
      <PortfolioName
        className={`caption-10-md ${
          cardItem.status === NotificationStatus.COMPLETE ? "readed" : ""
        }`}
      >
        {cardItem.portfolio.companyName}
      </PortfolioName>
      <Title
        className={`heading-16-sb ${
          cardItem.status === NotificationStatus.COMPLETE ? "readed" : ""
        }`}
      >
        {cardItem.title || cardItem.category.title}
      </Title>
      <Description
        className={`caption-12-rg ${
          cardItem.status === NotificationStatus.COMPLETE ? "readed" : ""
        }`}
      >
        {cardItem.description || cardItem.category.description}
      </Description>
      <Date
        className={`caption-10-rg ${
          cardItem.status === NotificationStatus.COMPLETE ? "readed" : ""
        }`}
      >
        {getYMDHMKo(cardItem.createdAt)}
      </Date>
      {onDeleteCard && (
        <DeleteBtn
          onClick={(e) => {
            e.stopPropagation();
            onDeleteCard && onDeleteCard(cardItem.id);
          }}
        >
          <Remove />
        </DeleteBtn>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;

  border-radius: 8px;

  border: 1px solid var(--gray-200);

  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    background: var(--hover-100, #f4f5f6);
  }
`;

const PortfolioName = styled.div`
  color: var(--blue-400, #4584ff);
  &.readed {
    color: var(--blue-300, #a2c7ff);
  }
`;
const Title = styled.div`
  color: var(--text-01, #1f2022);
  &.readed {
    color: var(--text-inactive, #bec0c2);
  }
`;
const Description = styled.div`
  color: var(--text-04);
  margin-top: 4px;
  &.readed {
    color: var(--text-inactive, #bec0c2);
  }
`;

const Date = styled.div`
  color: var(--text-05);
  &.readed {
    color: var(--text-inactive, #bec0c2);
  }
`;

const DeleteBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.2s ease;
  :hover {
    background-color: var(--gray-300);
  }
`;

export default NotificationCard;
