import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { ReactComponent as Exit } from "../../../../../assets/image/ic_portfolio_exit.svg";

import { historyBack } from "../../../../../common/commonUtil";

interface Props {
  title: string;
}
const TaskLayout: React.FC<Props> = ({ title, children }) => {
  const navigate = useNavigate();

  const onClickExit = () => {
    historyBack(() => {
      navigate("/deal-flow");
    });
  };

  return (
    <Wrapper>
      <Container>
        <div className="tab_layout_header">
          <span className="font-medium-20" style={{ color: "var(--text-01)" }}>
            {title}
          </span>
          <div className="exit__btn" onClick={onClickExit}>
            <Exit className="exit__icon" fill="var(--text-01)" />
            <span className="font-medium-14">나가기</span>
          </div>
        </div>
        <div className="contents scroll__invisible">{children}</div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
`;
const Container = styled.div`
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;

  border-left: 1px solid var(--gray-300);
  border-right: 1px solid var(--gray-300);
  .tab_layout_header {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;

    padding: 0 24px;
    border-bottom: 1px solid var(--gray-300);

    .exit__btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      cursor: pointer;

      .exit__icon {
        width: 18px;
        height: 18px;
      }
      span {
        color: var(--text-01);
      }
    }
  }
  .contents {
    width: 100%;
    height: 100%;
    background-color: var(--background-page);
    overflow: scroll;
    margin: 0 24px;
  }
`;

export default TaskLayout;
