import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { convertPixelToCm } from "../SummaryBusinessPlanV2Download";

interface Props {
  title: string;
  description: string;
  style?: React.CSSProperties;
}

export const PageTitle: React.FC<Props> = (props) => {
  return (
    <Container convertPixelToCm={convertPixelToCm} style={props.style}>
      <span className="heading-24-sb" style={{ color: "var(--text-01)" }}>
        {props.title}
      </span>
      <span className="body-14-rg" style={{ color: "var(--text-05)" }}>
        {props.description}
      </span>
    </Container>
  );
};

const Container = styled.div<{ convertPixelToCm: (n: number) => number }>`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${(props) => props.convertPixelToCm(2)}px;
  margin-bottom: ${(props) => props.convertPixelToCm(32)}px;

  span {
    width: max-content;
    min-width: max-content;
  }
`;
