import React from "react";
import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";
import styled from "styled-components";

import usePortfolioDashboard from "../hook/usePortfolioDashboard";
import { FinancialStatement } from "./organism/FinancialStatement";
import { IncomeStatement } from "./organism/IncomeStatement";
import { EmployeeHistory } from "./organism/EmployeeHistory";
import { CompanyValueHistory } from "./organism/CompanyValueHistory";
import { CashflowStatement } from "./organism/CashflowStatement";

const MobilePortfolioDashboard: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const {
    portfolioId,
    portfolioNavInfo,
    isEditable,
    init,
    emptyData = [],
    duplicateData = [],
  } = props;

  const [
    financeHistory,
    employeeHistory,
    companyValueHistory,
    sbpData,
    serverTime,
  ] = usePortfolioDashboard();

  return (
    <Container>
      <ContentContainer id={"root__component"}>
        <ContentsWrapper>
          <FinancialStatement
            financeHistory={financeHistory}
            serverTime={serverTime}
          />
          <IncomeStatement
            financeHistory={financeHistory}
            serverTime={serverTime}
          />
          <EmployeeHistory
            employeeHistory={employeeHistory}
            serverTime={serverTime}
          />
          <CompanyValueHistory companyValueHistory={companyValueHistory} />
          <CashflowStatement sbpData={sbpData} />
        </ContentsWrapper>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Flex1 = styled.div`
  flex: 1;
`;

export default MobilePortfolioDashboard;
