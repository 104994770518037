import { useEffect, useRef, useState } from "react";
import { useAsyncError, useLocation } from "react-router";
import styled, { css } from "styled-components";

import { toast } from "react-toastify";

import {
  cancelReport,
  changeReportingFile,
  confirmReport,
  confirmStockChangeListReport,
  getAllAccelratingPortfolio,
  getPortfolioReportDetail,
  getReportList,
  getReportRange,
  getSBPData,
  postReportingFile,
  rejectReport,
} from "../../../reporting/repository/ReportingRepository";
import ConfirmedCard from "./components/ConfirmedCard";
import DefaultCard from "./components/DefaultCard";
import RejectedCard from "./components/RejectedCard";
import UploadCard from "./components/UploadCard";

import { ReactComponent as Loading } from "../../../../assets/image/loading.svg";
import { ReactComponent as Lock } from "../../../../assets/image/new_icon_lock.svg";

import ViewerPortfolioDetailModal from "./components/ViewerPortfolioDetailModal";
import { useNavigate } from "react-router-dom";
import ChatingFloat from "./components/chat/ChatingFloat";
import useChating from "./components/chat/useChating";
import RejectModal from "./components/RejectModal";

import UploadWebCard from "./components/UploadWebCard";

import StockChangeListConfirmModal from "./components/StockChangeListConfirmModal";
import SummaryBusinessPlanV2Download from "./organism/summary-business-plan/v2/components/download/SummaryBusinessPlanV2Download";
import {
  EmployeeTurnover,
  PatentInfo,
} from "./organism/summary-business-plan/v2/interface/SBP.interface";
import {
  ReportingFile,
  ReportingFileBody,
  ReportingFileReuploadBody,
} from "../interface/ReportingDetail.interface";
import MainTab from "../../../../components-2.0v/tab/MainTab";
import {
  getMyInfo,
  getPortfolioIdInLocation,
} from "../../../../common/commonUtil";
import useResize from "../../../../hooks/useResize";
import RollingSelector, {
  RollingSelectorDataProps,
} from "../../../../components-2.0v/rolling-selector/RollingSelector";
import { AcceleratorReportRangeProps } from "../../../reporting/interface/Reporting.interface";
import { DictionaryType } from "../../../../type/data";
import { showDeskToast } from "../../../../2.0lattice/toast";
import FinancialStatementCard from "../../../reporting/desktop/components/financial-statements/FinancialStatementCard";
import { getAllDictionary } from "../../../../api/repository/accelerator/AcceleratorRepository";
import SubTab from "../../../../components-2.0v/tab/SubTab";
import ModalLayout from "../../../../components/modal/ModalLayout";
import ConfirmModal from "../../../../2.0lattice/components/modal/ConfirmModal";
import { DownloadingProgress } from "../../../reporting/desktop/components/ReportFileDownload";
import { PortfolioNavigationDefaultProps } from "../../portfolio-detail/interface/PortfolioNavigation.interface";

const DeskPortfolioReporting: React.FC<PortfolioNavigationDefaultProps> = (
  props
) => {
  const { portfolioNavInfo, init, isEditable = false } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const portfolioId = getPortfolioIdInLocation(location);
  const [paused, updatePaused] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isFileLoaded, setIsFileLoaded] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hoveredOrDraggedId, setHoveredOrDraggedId] = useState<
    number | undefined
  >(undefined);

  const myInfo = getMyInfo();
  const [screenRate, transfromPixel] = useResize();

  const portfolioName = useRef<string>("");
  const [files, setFiles] = useState<ReportingFile[]>();

  const [reportRangeData, updateReportRangeData] = useState<
    AcceleratorReportRangeProps | undefined
  >(undefined);
  const [yearSelectorList, updateYearSelectorList] = useState<
    RollingSelectorDataProps[]
  >([]);
  const [dictionary, setDictionary] = useState<DictionaryType | undefined>(
    undefined
  );
  const [portfolioRange, setPortfolioRange] = useState<
    AcceleratorReportRangeProps | undefined
  >(undefined);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [selectedQuarter, setSelectedQuarter] = useState<number>(0);

  const selectedReport = useRef<ReportingFile | undefined>(undefined);
  const [fileUploading, updateFileUploading] = useState<boolean>(false);
  const [fileDownload, updateFileDownload] = useState<boolean>(false);
  const [viewerModal, updateViewerModal] = useState<boolean>(false);
  const [confirmModal, updateConfirmModal] = useState<boolean>(false);
  const [rejectModal, updateRejectModal] = useState<boolean>(false);
  const [sbpDownload, updateSbpDownload] = useState<boolean>(false);
  const [isAuthority, setIsAuthority] = useState<boolean>(
    isEditable || myInfo.authorityId === 2
  );
  const [isParticipant, setIsParticipant] = useState<boolean>(false);
  const [
    stockChangeListConfirmModal,
    updateStockChangeListConfirmConfirmModal,
  ] = useState<boolean>(false);
  const [confirmCancelModal, updateConfirmCancelModal] =
    useState<boolean>(false);

  const [forbiddenModal, updateForbiddenModal] = useState<boolean>(false);
  const [rejectCancelModal, updateRejectCancelModal] = useState<boolean>(false);

  const selectedReportDocumentId = useRef<number | undefined>(undefined);
  const selectedReportDocumentData = useRef<
    | {
        acPfDocumentId: number;
        pfReportDocumentDataId?: number;
      }
    | undefined
  >(undefined);

  const [
    isOpenChatingRef,
    showUnreadTooltip,
    unreadMsgCount,
    firstIndex,
    SBChatingMsg,
    currentReadChatItemId,
    isFirstOpen,
    isOpenChating,
    chatingFocusing,
    setPortfolioId,
    updateIsOpenChating,
    openChating,
    closeChating,
    updateChatingFocusing,
    nextPageChat,
    sendChating,
    clearUnreadMsgCount,
    callOpenChating,
  ] = useChating();

  const getPortfolioReportDocumentByRefData = () => {
    getPortfolioReportDocument();
  };

  const onConfirmStockChangeListReport = async (
    reportDocumentId: number,
    issuingPrice: number
  ) => {
    try {
      const result = await confirmStockChangeListReport(
        reportDocumentId,
        issuingPrice
      );
      if (result) {
        getPortfolioReportDocumentByRefData();
        showDeskToast("success", "확정했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConfirmReport = async () => {
    try {
      if (selectedReportDocumentId.current === undefined) return;

      let pfFinancialStatement: any = undefined;
      let pfEmployeeHistory: any = undefined;
      let pfPatent: any = undefined;

      if (
        selectedReportDocumentData.current !== undefined &&
        selectedReportDocumentData.current.pfReportDocumentDataId !== undefined
      ) {
        const result = await getSBPData(
          selectedReportDocumentData.current.pfReportDocumentDataId
        );

        if (result) {
          console.log(result);
          if (selectedReportDocumentData.current.acPfDocumentId === 6) {
            const employee: EmployeeTurnover =
              result.data.businessStatus.employeeTurnover;
            const patent: PatentInfo = result.data.businessStatus.patentInfo;
            const total =
              (employee.curEmployee || 0) +
              (employee.employeeOnboarding || 0) -
              (employee.employeeAttrition || 0);
            const entry = employee.employeeOnboarding || 0;
            const resignation = employee.employeeAttrition || 0;
            const directorEntry = employee.noEventClevel
              ? undefined
              : employee.cLevelChangeList
                  ?.filter((item) => item.isOnboarding === "입사자")
                  .map((item) => item.position || "")
                  .join("||");
            const directorResignation = employee.noEventClevel
              ? undefined
              : employee.cLevelChangeList
                  ?.filter((item) => item.isOnboarding === "퇴사자")
                  .map((item) => item.position || "")
                  .join("||");

            const domesticApplication =
              (patent.accumulatePatent?.domesticApplication || 0) +
              (patent.newPatent?.domesticApplication || 0);
            const internationalApplication =
              (patent.accumulatePatent?.internationalApplication || 0) +
              (patent.newPatent?.internationalApplication || 0);
            const domesticRegistration =
              (patent.accumulatePatent?.domesticRegistration || 0) +
              (patent.newPatent?.domesticRegistration || 0);
            const internationalRegistration =
              (patent.accumulatePatent?.internationalRegistration || 0) +
              (patent.newPatent?.internationalRegistration || 0);

            pfEmployeeHistory = {
              total: total,
              entry: entry,
              resignation: resignation,
              directorEntry: directorEntry,
              directorResignation: directorResignation,
            };

            pfPatent = {
              domesticApplication: domesticApplication,
              internationalApplication: internationalApplication,
              domesticRegistration: domesticRegistration,
              internationalRegistration: internationalRegistration,
            };
          } else {
            pfFinancialStatement = {
              ...result.data,
            };
          }
        }
      }
      const result = await confirmReport(
        selectedReportDocumentId.current,
        pfFinancialStatement,
        pfEmployeeHistory,
        pfPatent
      );

      if (result) {
        getPortfolioReportDocumentByRefData();
        showDeskToast("success", "확정했습니다.");
      }
      selectedReportDocumentId.current = undefined;
      selectedReportDocumentData.current = undefined;
    } catch (error) {
      console.log(error);
    }
  };
  const confirmCancelReport = async () => {
    try {
      if (selectedReportDocumentId.current === undefined) return;
      const result = await cancelReport(selectedReportDocumentId.current);
      if (result) {
        getPortfolioReportDocumentByRefData();
        showDeskToast("success", "확정을 취소했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRejectReport = async (reason: string) => {
    try {
      if (selectedReportDocumentId.current === undefined) return;

      const result = await rejectReport(
        selectedReportDocumentId.current,
        reason
      );
      if (result) {
        getPortfolioReportDocumentByRefData();
        showDeskToast("success", "반려했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const rejectCancelReport = async () => {
    try {
      if (selectedReportDocumentId.current === undefined) return;
      const result = await cancelReport(selectedReportDocumentId.current);
      if (result) {
        getPortfolioReportDocumentByRefData();
        showDeskToast("success", "반려을 취소했습니다.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openViewerModal = (file: ReportingFile) => {
    selectedReport.current = file;
    updateViewerModal(true);
    callOpenChating();
  };

  const onFileUpload = async (param: ReportingFileBody) => {
    try {
      updateFileUploading(true);
      param.year = selectedYear;
      param.quarterNum = selectedQuarter;
      const res = await postReportingFile(param);
      if (res?.success) {
        getPortfolioReportDocumentByRefData();
        toast.success("파일 업로드에 성공했습니다.", {
          position: "bottom-center",
          autoClose: 1500,
          toastId: "file-upload-fail",
        });
      } else {
        toast.error("파일 업로드에 실패했습니다.", {
          position: "bottom-center",
          autoClose: 1500,
          toastId: "file-upload-fail",
        });
      }
    } catch (error) {
      toast.error("파일 업로드에 실패했습니다.", {
        position: "bottom-center",
        autoClose: 1500,
        toastId: "file-upload-fail",
      });
    } finally {
      updateFileUploading(false);
    }
  };

  const onFileChange = async (param: ReportingFileReuploadBody) => {
    try {
      updateFileUploading(true);
      const res = await changeReportingFile(param);
      if (res) {
        getPortfolioReportDocumentByRefData();
        toast.success("파일 교체에 성공했습니다.", {
          position: "bottom-center",
          autoClose: 1500,
          toastId: "file-upload-fail",
        });
      } else {
        toast.error("파일 교체에 실패했습니다.", {
          position: "bottom-center",
          autoClose: 1500,
          toastId: "file-upload-fail",
        });
      }
    } catch (error) {
      toast.error("파일 교체에 실패했습니다.", {
        position: "bottom-center",
        autoClose: 1500,
        toastId: "file-upload-fail",
      });
    } finally {
      updateFileUploading(false);
    }
  };

  const getCard = (file: ReportingFile, index: number) => {
    if ([7, 8].includes(file.acPfDocumentId)) {
      return (
        <FinancialStatementCard
          portfolioId={portfolioId}
          isAuthority={isAuthority}
          data={file}
          isOpen={isOpen}
          index={index}
          screenWidthRate={1}
          year={selectedYear}
          quarter={selectedQuarter}
          isHalf={selectedQuarter === 2}
          transfromPixel={transfromPixel}
          onFileReupload={() => {
            getPortfolioReportDocumentByRefData();
          }}
          onClickCard={() => {
            openViewerModal(file);
          }}
          reject={() => {
            selectedReportDocumentId.current = file.file?.pfReportDocumentId;
            updateRejectModal(true);
          }}
          rejectCancel={() => {
            selectedReportDocumentId.current = file.file?.pfReportDocumentId;
            updateRejectCancelModal(true);
          }}
          confirm={() => {
            console.log(file);
            selectedReportDocumentId.current = file.file?.pfReportDocumentId;
            selectedReportDocumentData.current = {
              acPfDocumentId: file.acPfDocumentId,
              pfReportDocumentDataId:
                file.file?.pfReportDocumentData !== undefined &&
                file.file?.pfReportDocumentData !== null
                  ? file.file.pfReportDocumentData.id
                  : undefined,
            };

            updateConfirmModal(true);
          }}
          confirmCancel={() => {
            selectedReportDocumentId.current = file.file?.pfReportDocumentId;
            updateConfirmCancelModal(true);
          }}
        />
      );
    }
    if (file.file) {
      if ([1, 2, 3].includes(file.file.uploadStatus.uploadStatusId)) {
        return file.extension === "web" ? (
          <UploadWebCard
            portfolioId={portfolioId}
            data={file}
            isOpen={isOpen}
            index={index}
            isAuthority={
              isAuthority ||
              (file.acPfDocumentId === 6 &&
                myInfo.authorityId === 1 &&
                isParticipant)
            }
            transfromPixel={transfromPixel}
            onClickFile={() => {
              selectedReport.current = file;
              updateSbpDownload(true);
            }}
            onClickCard={() => {
              openViewerModal(file);
            }}
            reject={() => {
              selectedReportDocumentId.current = file.file?.pfReportDocumentId;
              updateRejectModal(true);
            }}
            confirm={() => {
              selectedReportDocumentId.current = file.file?.pfReportDocumentId;
              selectedReportDocumentData.current = {
                acPfDocumentId: file.acPfDocumentId,
                pfReportDocumentDataId:
                  file.file?.pfReportDocumentData !== undefined
                    ? file.file.pfReportDocumentData.id
                    : undefined,
              };

              updateConfirmModal(true);
            }}
          />
        ) : (
          <UploadCard
            portfolioId={portfolioId}
            data={file}
            isOpen={isOpen}
            index={index}
            isFileUploadable
            isAuthority={
              isAuthority ||
              ([1, 2, 3, 6, 7, 8].includes(file.acPfDocumentId) &&
                myInfo.authorityId === 1 &&
                isParticipant)
            }
            dictionary={dictionary}
            transfromPixel={transfromPixel}
            onFileChange={onFileChange}
            onClickCard={() => {
              openViewerModal(file);
            }}
            isHoveredOrDragged={hoveredOrDraggedId === index}
            setHoveredOrDraggedId={(id: number | undefined) => {
              setHoveredOrDraggedId(id);
            }}
            reject={() => {
              selectedReportDocumentId.current = file.file?.pfReportDocumentId;
              updateRejectModal(true);
            }}
            confirm={() => {
              selectedReportDocumentId.current = file.file?.pfReportDocumentId;
              console.log(file);
              if (file.acPfDocumentId === 1) {
                updateStockChangeListConfirmConfirmModal(true);
              } else if ([7, 8].includes(file.acPfDocumentId)) {
                selectedReportDocumentData.current = {
                  acPfDocumentId: file.acPfDocumentId,
                  pfReportDocumentDataId:
                    file.file?.pfReportDocumentData !== undefined
                      ? file.file.pfReportDocumentData.id
                      : undefined,
                };
              } else {
                updateConfirmModal(true);
              }
            }}
            callbackFn={() => {
              getPortfolioReportDocument();
            }}
          />
        );
      }

      if (file.file.uploadStatus.uploadStatusId === 4) {
        return (
          <RejectedCard
            portfolioId={portfolioId}
            data={file}
            isOpen={isOpen}
            index={index}
            dictionary={dictionary}
            transfromPixel={transfromPixel}
            isAuthority={
              isAuthority ||
              ([1, 2, 3, 6, 7, 8].includes(file.acPfDocumentId) &&
                myInfo.authorityId === 1 &&
                isParticipant)
            }
            onClickCard={() => {
              openViewerModal(file);
            }}
            isHoveredOrDragged={hoveredOrDraggedId === index}
            setHoveredOrDraggedId={(id: number | undefined) => {
              setHoveredOrDraggedId(id);
            }}
            rejectCancel={() => {
              selectedReportDocumentId.current = file.file?.pfReportDocumentId;
              updateRejectCancelModal(true);
            }}
          />
        );
      }
      if (file.file.uploadStatus.uploadStatusId === 5) {
        return (
          <ConfirmedCard
            portfolioId={portfolioId}
            data={file}
            isOpen={isOpen}
            index={index}
            dictionary={dictionary}
            transfromPixel={transfromPixel}
            isAuthority={
              isAuthority ||
              ([1, 2, 3, 6, 7, 8].includes(file.acPfDocumentId) &&
                myInfo.authorityId === 1 &&
                isParticipant)
            }
            onClickCard={() => {
              openViewerModal(file);
            }}
            isHoveredOrDragged={hoveredOrDraggedId === index}
            setHoveredOrDraggedId={(id: number | undefined) => {
              setHoveredOrDraggedId(id);
            }}
            confirmCancel={() => {
              selectedReportDocumentId.current = file.file?.pfReportDocumentId;
              updateConfirmCancelModal(true);
            }}
          />
        );
      }
    }

    return (
      <DefaultCard
        portfolioId={portfolioId}
        data={file}
        isOpen={isOpen}
        index={index}
        dictionary={dictionary}
        isAuthority={
          isAuthority ||
          ([1, 2, 3, 6, 7, 8].includes(file.acPfDocumentId) &&
            myInfo.authorityId === 1 &&
            isParticipant)
        }
        isFileUploadable={true}
        onFileUpload={onFileUpload}
        onClickCard={() => {
          openViewerModal(file);
        }}
        transfromPixel={transfromPixel}
        isHoveredOrDragged={hoveredOrDraggedId === index}
        setHoveredOrDraggedId={(id: number | undefined) => {
          setHoveredOrDraggedId(id);
        }}
      />
    );
  };

  const getPortfolioReportDocument = async () => {
    try {
      const result = await getPortfolioReportDetail(portfolioId, {
        year: selectedYear,
        quarterNum: selectedQuarter,
      });

      console.log(result);
      if (result) {
        setIsOpen(result.isOpen);
        setFiles([...result.reportData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getReportRangeData = async () => {
    try {
      const data = await getReportRange();
      if (!data) return;

      const { acceleratorRange, portfolioRange, current } = data;
      const yearArray = Array.from(
        { length: acceleratorRange.end.year - acceleratorRange.start.year + 1 },
        (v, i) => acceleratorRange.start.year + i
      );
      const rangeData = yearArray.map(
        (value): RollingSelectorDataProps => ({
          id: value,
          text: `${value}년`,
          data: value,
        })
      );
      setPortfolioRange(data);
      updateYearSelectorList(rangeData);

      return {
        currentYear: current.year,
        currentQuarter: current.quarter,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const getEmptyMsg = () => {
    if (!portfolioRange) return;

    const acRange = portfolioRange.acceleratorRange;

    if (selectedYear < acRange.end.year) {
      return "투자 전 이므로 분기보고 파일이 없습니다.";
    } else if (
      portfolioRange.portfolioRange &&
      selectedQuarter < portfolioRange.portfolioRange.start.quarter
    ) {
      return "투자 전 이므로 분기보고 파일이 없습니다.";
    } else if (portfolioRange.current.quarter < selectedQuarter) {
      return "분기보고가 열리지 않았습니다.";
    }
  };

  const dataInit = async () => {
    try {
      setIsFileLoaded(false);
      updateViewerModal(false);

      const result = await getAllDictionary();
      setDictionary(result);

      //엑셀러레이터 연도 range 가져오기
      const rangeResult = await getReportRangeData();
      if (rangeResult) {
        setSelectedYear(rangeResult.currentYear);
        setSelectedQuarter(rangeResult.currentQuarter);
      }

      setPortfolioId(portfolioId, isEditable || myInfo.authorityId === 2);

      setIsFileLoaded(true);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isOpenChatingRef.current = viewerModal;
  }, [viewerModal]);

  useEffect(() => {
    if (selectedYear && selectedQuarter) getPortfolioReportDocument();
  }, [selectedYear, selectedQuarter]);

  useEffect(() => {
    dataInit();
  }, []);

  if (!isLoaded) return null;

  return (
    <Container className="scroll__invisible">
      <MainTab>
        <RollingSelector
          data={yearSelectorList}
          defaultPosition={yearSelectorList.findIndex(
            (item: any) => item.id === selectedYear
          )}
          paused={paused}
          transfromPixel={transfromPixel}
          onChangeData={(data) => {
            data && setSelectedYear(data.data);
          }}
        />
      </MainTab>
      <SubTab className="top__sticky">
        <QuarterContainer
          className="heading-16-md"
          style={{ fontSize: transfromPixel(16) }}
          transfromPixel={transfromPixel}
        >
          <QuarterItem
            className={`${selectedQuarter === 1 ? "selected" : ""}`}
            transfromPixel={transfromPixel}
            onClick={() => {
              if (selectedQuarter === 1) return;
              setSelectedQuarter(1);
            }}
          >
            1분기
          </QuarterItem>
          <QuarterItem
            className={`${selectedQuarter === 2 ? "selected" : ""}`}
            transfromPixel={transfromPixel}
            onClick={() => {
              if (selectedQuarter === 2) return;

              setSelectedQuarter(2);
            }}
          >
            2분기
          </QuarterItem>
          <QuarterItem
            className={`${selectedQuarter === 3 ? "selected" : ""}`}
            transfromPixel={transfromPixel}
            onClick={() => {
              if (selectedQuarter === 3) return;

              setSelectedQuarter(3);
            }}
          >
            3분기
          </QuarterItem>
          <QuarterItem
            className={`${selectedQuarter === 4 ? "selected" : ""}`}
            transfromPixel={transfromPixel}
            onClick={() => {
              if (selectedQuarter === 4) return;

              setSelectedQuarter(4);
            }}
          >
            4분기
          </QuarterItem>
        </QuarterContainer>
      </SubTab>
      <ContentContainer
        className="scroll__invisible"
        len={Math.floor(files?.length || 0)}
        transfromPixel={transfromPixel}
      >
        {!isFileLoaded ? null : files && files.length > 0 ? (
          <div className="box-container scroll__invisible">
            <div className="box">
              {[...Array(Math.ceil((files?.length || 0) / 4))].map(
                (item, rowIndex) => {
                  if (!files) return;

                  const isFullRow = (rowIndex + 1) * 4 <= files.length;

                  return (
                    <div className="row">
                      {files
                        ?.slice(rowIndex * 4, (rowIndex + 1) * 4)
                        .map((file, colIndex) =>
                          getCard(file, rowIndex * 4 + colIndex)
                        )}
                      {!isFullRow && <div className="empty-box" />}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        ) : (
          <div className="heading-18-md empty__container">
            <Lock />
            <div>{getEmptyMsg()}</div>
          </div>
        )}
      </ContentContainer>
      {files && viewerModal && (
        <ModalLayout isOpen={viewerModal} isFullScreen>
          <ViewerPortfolioDetailModal
            isOpen={isOpen}
            portfolioName={portfolioName.current}
            portfolioId={portfolioId}
            year={selectedYear}
            quarterNum={selectedQuarter}
            selectedReport={selectedReport.current}
            isManager={myInfo.authorityId === 2}
            transfromPixel={transfromPixel}
            firstIndex={firstIndex}
            isOpenChating={true}
            SBChatingMsg={SBChatingMsg}
            currentReadChatItemId={currentReadChatItemId}
            chatingFocusing={chatingFocusing}
            updateChatingFocusing={updateChatingFocusing}
            nextPageChat={nextPageChat}
            clearUnreadMsgCount={clearUnreadMsgCount}
            sendChating={sendChating}
            files={files}
            close={() => {
              selectedReportDocumentId.current = undefined;
              getPortfolioReportDocumentByRefData();
              updateViewerModal(false);
            }}
            getPortfolioReportDocumentByRefData={
              getPortfolioReportDocumentByRefData
            }
            confirm={(documentId) => {
              selectedReportDocumentId.current = documentId;
              updateConfirmModal(true);
            }}
            reject={(documentId) => {
              selectedReportDocumentId.current = documentId;
              updateRejectModal(true);
            }}
            confirmCancel={(documentId) => {
              selectedReportDocumentId.current = documentId;
              updateConfirmCancelModal(true);
            }}
            rejectCancel={(documentId) => {
              selectedReportDocumentId.current = documentId;
              updateRejectCancelModal(true);
            }}
          />
        </ModalLayout>
      )}
      {confirmModal && (
        <ModalLayout
          isOpen={confirmModal}
          isFullScreen
          toggle={() => {
            updateConfirmModal(false);
            selectedReportDocumentId.current = undefined;
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"확정하기"}
            contents={["이 파일을 확정할까요?"]}
            positiveTitle={"확정"}
            negativeTitle={"취소"}
            onClickNegativeBtn={() => {
              updateConfirmModal(false);
              selectedReportDocumentId.current = undefined;
              selectedReportDocumentData.current = undefined;
            }}
            onClickPositiveBtn={() => {
              onConfirmReport();
              updateConfirmModal(false);
            }}
          />
        </ModalLayout>
      )}

      {selectedReportDocumentId.current && stockChangeListConfirmModal && (
        <ModalLayout
          isOpen={stockChangeListConfirmModal}
          isFullScreen
          toggle={() => {
            updateStockChangeListConfirmConfirmModal(false);
            selectedReportDocumentId.current = undefined;
          }}
        >
          <StockChangeListConfirmModal
            reportDocumentId={selectedReportDocumentId.current}
            cancel={() => {
              updateStockChangeListConfirmConfirmModal(false);
              selectedReportDocumentId.current = undefined;
            }}
            stockChangeListConfirm={(reportDocumentId, issuingPrice) => {
              onConfirmStockChangeListReport(reportDocumentId, issuingPrice);
              updateStockChangeListConfirmConfirmModal(false);
              selectedReportDocumentId.current = undefined;
            }}
          />
        </ModalLayout>
      )}
      {rejectModal && (
        <ModalLayout
          isOpen={rejectModal}
          isFullScreen
          toggle={() => {
            updateRejectModal(false);
            selectedReportDocumentId.current = undefined;
          }}
        >
          <RejectModal
            transfromPixel={transfromPixel}
            cancel={() => updateRejectModal(false)}
            rejectSubmit={(text) => {
              onRejectReport(text);
              updateRejectModal(false);
              selectedReportDocumentId.current = undefined;
            }}
          />
        </ModalLayout>
      )}

      {confirmCancelModal && (
        <ModalLayout
          isOpen={confirmCancelModal}
          isFullScreen
          toggle={() => {
            updateConfirmCancelModal(false);
            selectedReportDocumentId.current = undefined;
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"확정 취소"}
            contents={[`'확정' 상태를 취소할까요?`]}
            positiveTitle={"확인"}
            negativeTitle={"취소"}
            onClickNegativeBtn={() => {
              updateConfirmCancelModal(false);
              selectedReportDocumentId.current = undefined;
            }}
            onClickPositiveBtn={() => {
              //확정 취소 함수
              confirmCancelReport();
              updateConfirmCancelModal(false);
              selectedReportDocumentId.current = undefined;
            }}
          />
        </ModalLayout>
      )}

      {rejectCancelModal && (
        <ModalLayout
          isOpen={rejectCancelModal}
          isFullScreen
          toggle={() => {
            updateRejectCancelModal(false);
            selectedReportDocumentId.current = undefined;
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"반려 취소"}
            contents={[`'반려' 상태를 취소할까요?`]}
            positiveTitle={"확인"}
            negativeTitle={"취소"}
            onClickNegativeBtn={() => {
              updateRejectCancelModal(false);
              selectedReportDocumentId.current = undefined;
            }}
            onClickPositiveBtn={() => {
              rejectCancelReport();
              updateRejectCancelModal(false);
              selectedReportDocumentId.current = undefined;
            }}
          />
        </ModalLayout>
      )}

      {forbiddenModal && (
        <ModalLayout
          isOpen={forbiddenModal}
          isFullScreen
          toggle={() => {
            updateForbiddenModal(false);
          }}
        >
          <ConfirmModal
            type="alert"
            transfromPixel={transfromPixel}
            title={"접근 불가"}
            contents={[
              `담당 포트폴리오가 아니면 접근할 수 없습니다.`,
              `포트폴리오 담당 심사역에게 문의해 주세요.`,
            ]}
            positiveTitle={"확인"}
            onClickPositiveBtn={() => {
              updateForbiddenModal(false);
            }}
          />
        </ModalLayout>
      )}
      {isFileLoaded && (isEditable || myInfo.authorityId === 2) && (
        <ChatingFloat
          unreadMsgCount={unreadMsgCount}
          showUnreadTooltip={showUnreadTooltip}
          SBChatingMsg={SBChatingMsg}
          firstIndex={firstIndex}
          currentReadChatItemId={currentReadChatItemId}
          isOpenChating={isOpenChating}
          chatingFocusing={chatingFocusing}
          updateChatingFocusing={updateChatingFocusing}
          closeChating={closeChating}
          openChating={openChating}
          nextPageChat={nextPageChat}
          sendChating={sendChating}
          clearUnreadMsgCount={clearUnreadMsgCount}
          transfromPixel={transfromPixel}
        />
      )}

      {sbpDownload && selectedReport.current && selectedReport.current.file && (
        <ModalLayout
          isOpen={sbpDownload}
          isFullScreen
          isBackgroundTransparent
          overlayColor="rgba(31, 32, 34, 0.5)"
          zIndex={-10000000}
        >
          <SummaryBusinessPlanV2Download
            file={selectedReport.current.file}
            companyName={"회사이름"}
            completeProgress={(current, total) => {
              console.log(`완료페이지 ${current} / ${total}`);
            }}
            complete={() => {
              updateSbpDownload(false);
            }}
          />
        </ModalLayout>
      )}

      {sbpDownload && (
        <ModalLayout
          isOpen={sbpDownload}
          isFullScreen
          isBackgroundTransparent
          overlayColor="rgba(31, 32, 34, 0.5)"
        >
          <DownloadingProgress className="body-16-md">
            <Loading
              className="rotating"
              width={transfromPixel(30)}
              height={transfromPixel(30)}
            />
            <div>파일 다운로드 중입니다.</div>
          </DownloadingProgress>
        </ModalLayout>
      )}

      {fileUploading && (
        <ModalLayout
          isOpen={fileUploading}
          isFullScreen
          isBackgroundTransparent
          overlayColor="rgba(31, 32, 34, 0.5)"
        >
          <DownloadingProgress className="body-16-md">
            <Loading
              className="rotating"
              width={transfromPixel(30)}
              height={transfromPixel(30)}
            />
            <div>파일 업로드 중입니다.</div>
          </DownloadingProgress>
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fbfcfd;

  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

const QuarterContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.transfromPixel(18)}px;
`;

const QuarterItem = styled.div<{ transfromPixel: (value: number) => number }>`
  width: auto;
  height: ${(props) => props.transfromPixel(40)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.transfromPixel(8)}px
    ${(props) => props.transfromPixel(4)}px;
  color: #bec0c2;
  cursor: pointer;

  &.selected {
    color: #1f2022;
  }
`;

const ContentContainer = styled.div(
  ({
    len,
    transfromPixel,
  }: {
    len: number;
    transfromPixel: (value: number) => number;
  }) => css`
    position: relative;
    width: 100%;
    flex-grow: 1;
    background: #fbfcfd;
    overflow: scroll;

    .empty__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: ${transfromPixel(20)}px;
      background: #fbfcfd;
      color: #7e8086;
      padding: 24px;
    }

    .box-container {
      height: 100%;
      overflow: auto;

      width: 100%;
      max-width: calc(100% * 1920 / 1536);
      min-width: calc(100% * 1280 / 1536);

      padding: 0 ${transfromPixel(60)}px;

      .box {
        width: max-content;
        height: max-content;
        padding: 1px;

        display: flex;
        flex-direction: column;
        gap: ${transfromPixel(1)}px;

        background: #edeeef;

        margin: ${transfromPixel(40)}px auto ${transfromPixel(80)}px auto;

        .row {
          display: flex;
          flex-direction: row;
          gap: ${transfromPixel(1)}px;
          /* flex: 1; */
          height: calc(100% / 3);
          width: 100%;
          .empty-box {
            background: #fbfcfd;
            flex-grow: 1;
            height: ${transfromPixel(274)}px;

            box-shadow: 0 ${transfromPixel(1)}px 0 #fbfcfd,
              ${transfromPixel(1)}px 0 0 #fbfcfd
                ${len % 4 === 0 ? `, -${transfromPixel(1)}px 0 0 #FBFCFD` : ""};
          }
        }
      }
    }
  `
);

export default DeskPortfolioReporting;
