import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as IconExcel } from "../../../../assets/image/icon-xlsx.svg";
import { DefaultButton } from "lattice_core";
import { excelDownloadStockChangeList } from "../../../../excel-download/createStockChangeList";
import { getQuarterDate } from "../../../../common/commonUtil";

interface Props {
  portfolioId: number;
  year: number;
  quarter: number;
  isOpen: boolean;
  index: number;
  fileName: string;
  currency: string;
  setHoveredOrDraggedId?: (id: number | undefined) => void;
}

const StockChangeListDownloadCard = (props: Props) => {
  return (
    <StyledCard
      onMouseEnter={() => {
        props.setHoveredOrDraggedId && props.setHoveredOrDraggedId(props.index);
      }}
      onMouseLeave={() => {
        props.setHoveredOrDraggedId && props.setHoveredOrDraggedId(undefined);
      }}
      isOpen={props.isOpen}
    >
      <Default style={{}}>
        <div className="required-container">
          <div className={`chip caption-11-rg option`}>
            주식변동이력 다운로드
          </div>
        </div>
        <div className="file-name-card heading-20-sb">
          <span className={props.isOpen ? "" : "closed"}>
            주식변동내역(엑셀)
          </span>
        </div>
        <div
          className="caption-12-rg"
          style={{ marginTop: 8, color: "var(--blue-700)" }}
        >
          포트폴리오사의 주식변동내역을 기반으로한 다운로드 파일입니다. 제출한
          파일이 아닙니다.
        </div>

        <div className="file-container">
          <FileUpload
            className="body-14-rg"
            style={{
              padding: `8px 12px`,
            }}
            onClick={() => {
              excelDownloadStockChangeList(
                props.portfolioId,
                getQuarterDate(props.year, props.quarter),
                props.currency
              );
            }}
          >
            <IconExcel style={{ width: 24, height: 24 }} />
            <div
              className="fileupload__file__name"
              style={{
                width: 195,
              }}
            >
              {props.fileName}
            </div>
          </FileUpload>
          <div className="button-container">
            {props.isOpen && (
              <div style={{ opacity: 0 }}>
                <DefaultButton
                  className="caption-12-md"
                  buttonType="colored-outline"
                  sizeType="xxsmall"
                  buttonColor={"#1F2022"}
                  borderColor={"#D5D6D8"}
                  disabled={true}
                  text=""
                />
              </div>
            )}
          </div>
        </div>
      </Default>
    </StyledCard>
  );
};

const StyledCard = styled.div(
  ({ isOpen }: { isOpen: boolean }) => css`
    background: var(--white);
    width: 300px;
    height: 274px;
    overflow: hidden;
    position: relative;
    cursor: default;

    :hover {
      outline: ${isOpen ? 2 : 0}px solid #a2c7ff;
    }
  `
);

const Default = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 32px;
  position: relative;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
  }

  &.red-outline {
    outline: 1px solid red;
  }

  .required-container {
    display: flex;
    flex-direction: row;
    gap: 4px;
    .chip {
      padding: 2px 6px;
      border-radius: 2px;
    }

    .required {
      background: #060f40;
      color: #ffffff;
    }
    .option {
      background: #ffffff;
      color: #5b5d61;
      border: 1px solid #e3e4e5;
    }
    .required-file {
      background: #e3e4e5;
      color: #5b5d61;
    }
  }

  .file-name-card {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .closed {
      color: #bec0c2;
    }

    .icon {
      position: relative;
      width: fit-content;
      height: fit-content;
      flex: 1;

      .icon__wrap {
        padding: 4px;
        cursor: pointer;
      }

      .tool-tip {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        background: rgba(31, 32, 34, 0.5);
        color: #ffffff;
        border-radius: 4px;

        padding: 6px 0;
        width: 96px;
        z-index: 1000;
        text-align: center;

        cursor: pointer;
        :hover {
          text-decoration: underline;
        }
      }

      :hover {
        .tool-tip {
          display: block;
        }
      }
    }
  }

  .file-number {
    position: absolute;
    font-family: SUIT;
    font-style: normal;
    top: 220px;
    left: 32px;
    font-size: 34px;
    line-height: 30px;
    color: #e3e4e5;
  }

  .file-container {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #f3f4f4;
    width: 100%;
    min-height: 120px;
    max-height: 120px;
    padding: 20px 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    .button-container {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
`;

const FileUpload = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  background: #ffffff;
  /* light/gray200 */

  border: 1px solid #edeeef;
  border-radius: 4px;
  gap: 4px;

  cursor: pointer;
  transition: all 0.2s ease;

  .fileupload__icon {
  }
  .fileupload__file__name {
    display: block;
    flex-direction: row;
    align-items: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :hover {
    background-color: #f3f4f4;
  }
`;

export default StockChangeListDownloadCard;
