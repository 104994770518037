import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as DefaultProfile } from "../../assets/image/default_user_profile.svg";

export interface CircleUserProfileProps {
  profileImg?: string;
  userName: string;
}
interface Props {
  userInfo: CircleUserProfileProps;
  width?: number;
}

export const UserCircle: React.FC<Props> = (props) => {
  const { width = 32 } = props;
  return (
    <Container width={props.width || 36}>
      {props.userInfo.profileImg ? (
        <ImageCircle src={props.userInfo.profileImg} />
      ) : (
        <DefaultProfile
          style={{
            width: width,
            height: width,
            minWidth: width,
            minHeight: width,
            maxWidth: width,
            maxHeight: width,
          }}
        />
      )}
    </Container>
  );
};
const Container = styled.div(
  ({ width }: { width: number }) => css`
    position: relative;
    width: ${`${width}px`};
    height: ${`${width}px`};
    min-width: ${`${width}px`};
    min-height: ${`${width}px`};
    max-width: ${`${width}px`};
    max-height: ${`${width}px`};

    overflow: hidden;
    border-radius: 50%;
    border: 1px solid var(--divider-100);
    background-color: var(--background-contents01);
  `
);
const ImageCircle = styled.img(
  () => css`
    width: 100%;
    height: 100%;
  `
);
export default UserCircle;
