import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GovermentTask } from "../../../../interface/SBP.interface";
import { TableGrid, TableGridHeaderProps } from "../grid/TableGrid";

interface Props {
  isReadOnly?: boolean;
  disabled?: boolean;
  govDataList?: GovermentTask[];
}

export const GovermentTaskComponent: React.FC<Props> = (props) => {
  const headers: TableGridHeaderProps[] = [
    {
      header: "사업명",
      key: "taskName",
      width: (220 + 36) * (764 / 1200),
    },
    {
      header: "담당 기관",
      key: "responsibleAgency",
      width: (220 + 16) * (764 / 1200),
    },
    {
      header: "시작일",
      key: "startDate",
      width: (146 + 20) * (764 / 1200),
    },
    {
      header: "종료일",
      key: "endDate",
      width: (146 + 20) * (764 / 1200),
    },
    {
      header: "정부출연금 (원)",
      key: "govFunding",
      type: "number",
      aligin: "end",
      width: 178 * (764 / 1200),
    },
    {
      header: "현황",
      key: "govStatus",
      width: 144 * (764 / 1200),
    },
  ];

  return (
    <Container>
      <TableGrid
        isReadOnly={props.isReadOnly}
        disabled={props.disabled}
        header={headers}
        dataList={
          props.govDataList
            ? props.govDataList.map((item, index) => {
                return {
                  id: index,
                  identificationNumber: index,
                  data: item,
                };
              })
            : []
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
