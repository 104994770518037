import React from "react";
import styled from "styled-components";
import DefaultSearch from "../../../../components-2.0v/search/DefaultSearch";
import { FundAccountCard } from "./component/FundAccountCard";
import useFundAccountInfo from "../hook/useFundAccountInfo";
import { NavigationLayout } from "../../../../components/navigation/NavigationLayout";

const MobileFundAccountInfo = () => {
  const [
    rootRef,
    initCompleted,
    searchKeyword,
    toDay,
    updateSearchKeyword,
    getFilterFundAccount,
    goToFundAccountDetail,
  ] = useFundAccountInfo();

  return (
    <Container>
      <FundAccountContainer>
        <ContentsHeader>
          <span className="heading-20-sb" style={{ color: "var(--text-01)" }}>
            재원 대시보드
          </span>
          <DefaultSearch
            value={searchKeyword}
            placeholder="재원명, 시리얼번호 검색"
            width={200}
            transfromPixel={(n) => n}
            onChangeValue={(value) => {
              updateSearchKeyword(value);
            }}
            onPressEnter={(value) => {
              updateSearchKeyword(value);
            }}
          />
        </ContentsHeader>
        <ContentsBody>
          <FundAccountList>
            {getFilterFundAccount().map((item, index) => {
              return (
                <FundAccountCard
                  key={`fundAccount_${index}`}
                  fundAccount={item}
                  toDay={toDay}
                  onClickCard={() => {
                    goToFundAccountDetail(item.acFundAccountId, "상세");
                  }}
                />
              );
            })}
          </FundAccountList>
        </ContentsBody>
      </FundAccountContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`;

const FundAccountContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentsHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 62px;
  min-height: 62px;
  max-height: 62px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  z-index: 10;

  border-bottom: 1px solid var(--divider-200);
  background: var(--background-header);
`;

const ContentsBody = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;

  margin: 0 auto;

  gap: 32px;
`;
const FundAccountList = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
`;

export default MobileFundAccountInfo;
