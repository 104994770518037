import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Sector } from "../Sector";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { SectorDescription } from "../SectorDescription";
import {
  IAFinancialStatements2,
  InvestmentAssessment,
} from "../../../../../type/data";
import InputBox from "../InputBox";
import { CustomDate } from "../table/custom-cell/CustomDate";
import {
  RemoveComma,
  getRangeArray,
  getYMD,
} from "../../../../../common/commonUtil";
import { CompanyHistoryTable } from "./organism/CompanyHistoryTable";
import AddressSearch from "../AddressSearch";
import { PickerDate } from "../PickerDate";
import IndustrySearch from "../IndustrySearch";

interface Props {
  isReadOnly: boolean;
  data?: InvestmentAssessment;
  onChangeData?: (data: InvestmentAssessment) => void;
}

export const Section1: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;
  if (!data) return null;

  return (
    <Container>
      <Sector title="기업개요" id="Section1_1">
        <FlexColumn style={{ gap: 8 }}>
          <SectorDescription
            text={`다음은 투자 심사를 위해 필요한 정보들입니다. 제출하시는 정보가 정확하고 자세할수록 정확한 투자심사에 도움이 됩니다.`}
          />
          <FlexColumn
            style={{
              padding: 28,
              borderRadius: 4,
              gap: 24,
              backgroundColor: "var(--background-contents01)",
              border: "1px solid var(--gray-300)",
            }}
          >
            <RowTitleBox
              title="기업명"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="text"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="정확한 기업명을 입력해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.companyName !== undefined
                    ? `${data.investmentAssessmentCompanyInfo.companyName}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.companyName = value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="대표이사"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="text"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="대표이사 성함을 입력해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.ceoName !== undefined
                    ? `${data.investmentAssessmentCompanyInfo.ceoName}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.ceoName = value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="사업자등록번호"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="text"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="사업자등록번호 10자리(xxx-xx-xxxxx)를 입력해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.businessNumber !==
                  undefined
                    ? `${data.investmentAssessmentCompanyInfo.businessNumber}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.businessNumber = value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="법인등록번호"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="text"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="법인등록번호 13자리(xxxxxx-xxxxxxx)를 입력해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.corporationNumber !==
                  undefined
                    ? `${data.investmentAssessmentCompanyInfo.corporationNumber}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.corporationNumber =
                      value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="주소"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <AddressSearch
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="도로명, 지번, 건물명으로 검색해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.addr1 &&
                  data.investmentAssessmentCompanyInfo.addr2
                    ? {
                        addr1: data.investmentAssessmentCompanyInfo.addr1,
                        addr2: data.investmentAssessmentCompanyInfo.addr2,
                        addr3: data.investmentAssessmentCompanyInfo.addr3,
                        addrDetail:
                          data.investmentAssessmentCompanyInfo.addrDetail,
                      }
                    : undefined
                }
                onChange={(address) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.addr1 = address.addr1;
                    data.investmentAssessmentCompanyInfo.addr2 = address.addr2;
                    data.investmentAssessmentCompanyInfo.addr3 = address.addr3;
                    data.investmentAssessmentCompanyInfo.addrDetail =
                      address.addrDetail;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="기업 전화 번호"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="text"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="전화번호를 -를 포함해 입력해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.tel !== undefined
                    ? `${data.investmentAssessmentCompanyInfo.tel}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.tel = value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="팩스 번호"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="text"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="팩스 번호를 -를 포함해 입력해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.faxTel !== undefined
                    ? `${data.investmentAssessmentCompanyInfo.faxTel}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.faxTel = value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="표준 코드 / 업종"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <IndustrySearch
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="업종명으로 검색해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.industryType &&
                  data.investmentAssessmentCompanyInfo.industryCode
                    ? {
                        industryType:
                          data.investmentAssessmentCompanyInfo.industryType,
                        industryCode:
                          data.investmentAssessmentCompanyInfo.industryCode,
                      }
                    : undefined
                }
                onChange={(industryValue) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.industryType =
                      industryValue.industryType;
                    data.investmentAssessmentCompanyInfo.industryCode =
                      industryValue.industryCode;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="주요 제품"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="text"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="주요 제품에 대해 입력해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.majorProduct !==
                  undefined
                    ? `${data.investmentAssessmentCompanyInfo.majorProduct}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.majorProduct = value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="설립일"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <PickerDate
                width={148}
                defaultDate={
                  data.investmentAssessmentCompanyInfo.establishmentDay
                }
                onChangeDate={(date) => {
                  console.log(date);

                  if (
                    onChangeData &&
                    data.investmentAssessmentFinancialStatement2
                  ) {
                    const establishDateYear = date.getFullYear();
                    const nowYear = new Date().getFullYear();
                    const newData = getRangeArray(establishDateYear, nowYear)
                      .reverse()
                      .slice(0, 3)
                      .map((item, index): IAFinancialStatements2 => {
                        const a =
                          data.investmentAssessmentFinancialStatement2 &&
                          data.investmentAssessmentFinancialStatement2.data[
                            index
                          ]
                            ? data.investmentAssessmentFinancialStatement2.data[
                                index
                              ]
                            : [];

                        return {
                          ...a,
                          year: item,
                        };
                      });
                    data.investmentAssessmentCompanyInfo.establishmentDay =
                      getYMD(date);
                    data.investmentAssessmentFinancialStatement2.data = newData;

                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="임직원 수"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType={"numberWithComma"}
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={108}
                placeholder="00"
                unit="명"
                defaultValue={
                  data.investmentAssessmentCompanyInfo.employeeNumber !==
                  undefined
                    ? `${data.investmentAssessmentCompanyInfo.employeeNumber}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.employeeNumber =
                      value.length > 0
                        ? parseInt(RemoveComma(value), 10)
                        : undefined;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="결산월"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType={"number"}
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={108}
                placeholder="12"
                unit="월"
                defaultValue={
                  data.investmentAssessmentCompanyInfo.settlementMonth !==
                  undefined
                    ? `${data.investmentAssessmentCompanyInfo.settlementMonth}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.settlementMonth =
                      value.length > 0
                        ? parseInt(RemoveComma(value), 10)
                        : undefined;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>

            <RowTitleBox
              title="회계 법인명"
              textClassName="heading-14-md"
              titleWidth={208}
              style={{ width: "100%" }}
              transfromPixel={(n) => n}
            >
              <InputBox
                textType="text"
                readOnly={props.isReadOnly}
                transfromPixel={(n) => n}
                width={486}
                placeholder="회계 법인명을 입력해 주세요."
                defaultValue={
                  data.investmentAssessmentCompanyInfo.accountingFirm !==
                  undefined
                    ? `${data.investmentAssessmentCompanyInfo.accountingFirm}`
                    : undefined
                }
                onBlur={(value) => {
                  if (onChangeData) {
                    data.investmentAssessmentCompanyInfo.accountingFirm = value;
                    onChangeData(data);
                  }
                }}
              />
            </RowTitleBox>
          </FlexColumn>
        </FlexColumn>
      </Sector>

      <Sector title="주요 연혁" id="Section1_2">
        <FlexColumn style={{ gap: 8 }}>
          <CompanyHistoryTable
            isReadOnly={props.isReadOnly}
            disabled={data.investmentAssessmentCompanyHistory?.isChecked}
            dataList={data.investmentAssessmentCompanyHistory?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentCompanyHistory: {
                    ...data.investmentAssessmentCompanyHistory,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
        </FlexColumn>
      </Sector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  .check__box {
    width: 20px;
    height: 20px;
    :hover {
      rect {
        fill: var(--hover-100);
      }
    }

    &.checked {
      rect {
        fill: var(--gray-700);
        stroke: var(--gray-700);
      }
      path {
        stroke: var(--icon-04);
      }
      :hover {
        rect {
          fill: var(--gray-700);
        }
      }
    }
  }
`;
