import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { BluecardInfoInBluelink } from "../type";
import { BluecardFilterProps } from "../../database/desktop/organisms/PreviewBlueCardLink";
import { BlueCardInfo, IRProposal } from "../../../type/data";
import { DefaultButton } from "lattice_core";
import BluecardPrint from "../../database/desktop/component/BluecardPrint";
import {
  checkMobile,
  transformObjectToBase64,
  viewPdfPopup,
} from "../../../common/commonUtil";
import config from "../../../config";

interface Props {
  isMobile: boolean;
  bluecardInfo: BluecardInfoInBluelink;
  transfromPixel: (value: number) => number;
}
const BluecardLinkCard: React.FC<Props> = (props) => {
  const bluecardWrapperRef = useRef<HTMLDivElement>(null);
  const { bluecardInfo, transfromPixel } = props;
  const [filter, updateFilter] = useState<BluecardFilterProps>();
  const [bluecard, updateBluecard] = useState<BlueCardInfo>();
  const [proposalSurmmary, updateProposalSurmmary] = useState<
    IRProposal | undefined
  >(undefined);
  const [proposalDetail, updateProposalDetail] = useState<
    IRProposal | undefined
  >(undefined);

  const [ratio, updateRatio] = useState<number>(1);
  const [width, updateWidth] = useState<number | undefined>(undefined);
  const [height, updateHeight] = useState<number | undefined>(undefined);

  const previewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const bluecardFilter: BluecardFilterProps = {
      companyValue: bluecardInfo.activeItem.includes("companyValue"),
      investmentAmount: bluecardInfo.activeItem.includes("investmentAmount"),
      userName: bluecardInfo.activeItem.includes("userName"),
      userEmail: bluecardInfo.activeItem.includes("userEmail"),
      userPhone: bluecardInfo.activeItem.includes("userPhone"),
      investmentHighlight: bluecardInfo.activeItem.includes(
        "investmentHighlight"
      ),
      problem: bluecardInfo.activeItem.includes("problem"),
      solutionBusinessModel: bluecardInfo.activeItem.includes(
        "solutionBusinessModel"
      ),
      productStatus: bluecardInfo.activeItem.includes("productStatus"),
      technology: bluecardInfo.activeItem.includes("technology"),
      irProposalSurmmary:
        bluecardInfo.activeItem.includes("irProposalSurmmary"),
      irProposalDetail: bluecardInfo.activeItem.includes("irProposalDetail"),
    };

    if (props.bluecardInfo.pfIrProposal) {
      const surmmaryIr = props.bluecardInfo.pfIrProposal.find(
        (item) => item.type === 1
      );
      const detailIr = props.bluecardInfo.pfIrProposal.find(
        (item) => item.type === 2
      );
      updateProposalSurmmary(surmmaryIr);
      updateProposalDetail(detailIr);
    }

    updateFilter(bluecardFilter);
    updateBluecard(bluecardInfo.bluecardInfo);
  }, [bluecardInfo]);

  const setBluecardSize = () => {
    if (!bluecardWrapperRef.current) return;

    const ratio = checkMobile() ? window.innerWidth / 630 : 595 / 630;

    updateRatio(ratio);
    updateWidth(bluecardWrapperRef.current.clientWidth * ratio);
    updateHeight(bluecardWrapperRef.current.clientHeight * ratio);
  };

  const resizeHandler = (e: any) => {
    setBluecardSize();
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  if (!filter || !bluecard) return null;
  return (
    <Wrapper>
      <Container ref={previewRef}>
        <Header>
          <span
            className={props.isMobile ? "heading-14-sb" : "heading-16-sb"}
            style={{
              maxWidth: props.isMobile ? 100 : "auto",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {bluecard.companyName}
          </span>
          <FlackRowContainer style={{ gap: 8 }}>
            {filter.irProposalSurmmary && proposalSurmmary && (
              <DefaultButton
                className="caption-12-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor="var(--text-01)"
                borderColor="var(--gray-400)"
                onClick={() => {
                  console.log("요약 Ir click");
                  const domainUrl = config.clientUrl || "";

                  const key = transformObjectToBase64({
                    key: `${proposalSurmmary.s3Key}`,
                  });

                  const url = `${domainUrl}pdf-viewer/${key}/share`;
                  const specs = `width=${screen.width},height=${screen.height},left=0,top=0,toolbar=no,menubar=no`;

                  console.log(url);
                  window.open(url, "_blank", specs);
                }}
                text="요약 IR보기"
              />
            )}
            {filter.irProposalDetail && proposalDetail && (
              <DefaultButton
                className="caption-12-md"
                buttonType="colored-outline"
                sizeType="xxsmall"
                buttonColor="var(--text-01)"
                borderColor="var(--gray-400)"
                onClick={() => {
                  console.log("요약 Ir click");
                  const domainUrl = config.clientUrl || "";

                  const key = transformObjectToBase64({
                    key: `${proposalDetail.s3Key}`,
                  });

                  const url = `${domainUrl}pdf-viewer/${key}/share`;
                  const specs = `width=${screen.width},height=${screen.height},left=0,top=0,toolbar=no,menubar=no`;

                  console.log(url);
                  window.open(url, "_blank", specs);
                }}
                text="상세 IR보기"
              />
            )}
          </FlackRowContainer>
        </Header>
        <Body
          style={{
            width: width,
            height: height,
          }}
        >
          <BluecardWrapper
            ref={bluecardWrapperRef}
            style={{
              transformOrigin: "top left",
              transform: `scale(${ratio})`,
            }}
          >
            <BluecardPrint
              bluecardFilter={filter}
              portfolioBluecard={bluecard}
              onChangeLink={(line) => {
                setBluecardSize();
              }}
            />
          </BluecardWrapper>
        </Body>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Container = styled.div`
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  border: 1px solid var(--gray-200);
  @media only screen and (max-width: 765px) {
    margin: 0;
  }
`;

const Header = styled.div`
  width: 100%;
  max-width: 595px;
  height: 100%;
  min-height: 48px;
  max-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: var(--background-contents01);

  border-bottom: 1px solid var(--gray-200);

  @media only screen and (max-width: 765px) {
    width: 100vw;
    max-width: 100vw;
    min-width: 0px;

    min-height: 48px;
    max-height: 48px;
    overflow: hidden;
  }
`;
const Body = styled.div`
  width: 100%;
  min-width: 595px;
  max-width: 595px;
  height: 840px;

  @media only screen and (max-width: 765px) {
    max-width: 100%;
    min-width: 0px;
  }
`;
const BluecardWrapper = styled.div``;

const FlackRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export default BluecardLinkCard;
