import React, { useEffect, useRef, useState } from "react";
import Styled from "styled-components";
import {
  QuestionItemType,
  QuestionType,
} from "../../../../portfolio/pre-pitching-detail/interface/PrePitchingDetail.interface";
import { InputBox } from "../../../../../components-2.0v/input-box/InputBox";
import { TextareaBox } from "../../../../../components-2.0v/input-box/TextareaBox";
import { PrePitchingTaskChangeType } from "../../interface/PrePitchingTask.interface";

interface Props {
  type: QuestionType;
  dataIndex: number;
  item: QuestionItemType;
  onChangeData: (
    type: PrePitchingTaskChangeType,
    dataIndex: number,
    data: any
  ) => void;
}
const ViewerText: React.FC<Props> = ({
  type,
  item,
  dataIndex,
  onChangeData,
}) => {
  const [value, updateValue] = useState<string>("");

  useEffect(() => {
    onChangeData(type, dataIndex, value);
  }, [value]);

  return (
    <Container>
      {type === "short" ? (
        <InputBox
          defaultValue={item.answer ? `${item.answer}` : ""}
          placeholder="의견을 간단히 입력해주세요."
          onChange={(e) => {
            updateValue(e);
          }}
        />
      ) : (
        <TextareaBox
          defaultData={item.answer ? `${item.answer}` : ""}
          placeholder="의견을 자세히 입력해주세요."
          maxHeight={200}
          onChangeDataRealTime={(data) => {
            updateValue(data);
          }}
        />
      )}
    </Container>
  );
};

const Container = Styled.div`
  display: flex;
  input[type=text]{
    width: 100%;
  }
  textarea{
    resize: none;
    width: 100%;
    min-height: 5rem;
  }
`;

export default ViewerText;
