import React from "react";
import styled from "styled-components";
import { FinanceDataProps } from "../../evaluation-detail/interface/RMED.interface";
import { AddComma } from "../../../../common/commonUtil";
import { InfoDescription } from "./InfoDescription";

interface Props {
  data?: FinanceDataProps;
  style?: React.CSSProperties;
}

export const SummaryFinancialInfo: React.FC<Props> = (props) => {
  const HEADER_HEIGHT = 40;
  const CELL_HEIGHT = 32;
  const targetDate = props.data
    ? `${props.data.year}년 ${props.data.quarterNum === 2 ? "1~6월" : "1~12월"}`
    : "";
  return (
    <Wrapper style={props.style}>
      <InfoDescription
        description={`${targetDate}에 해당하는 요약 재무 정보입니다. (단위 : 원)`}
      />
      <FlexColumn>
        <Container>
          <HeaderCell
            className={`caption-12-rg`}
            style={{
              flex: 1,
              height: HEADER_HEIGHT,
              minHeight: HEADER_HEIGHT,
              maxHeight: HEADER_HEIGHT,
            }}
          >
            매출액
          </HeaderCell>
          <HeaderCell
            className={`caption-12-rg`}
            style={{
              flex: 1,
              height: HEADER_HEIGHT,
              minHeight: HEADER_HEIGHT,
              maxHeight: HEADER_HEIGHT,
            }}
          >
            영업이익
          </HeaderCell>
          <HeaderCell
            className={`caption-12-rg`}
            style={{
              flex: 1,
              height: HEADER_HEIGHT,
              minHeight: HEADER_HEIGHT,
              maxHeight: HEADER_HEIGHT,
            }}
          >
            당기순이익
          </HeaderCell>
          <HeaderCell
            className={`caption-12-rg`}
            style={{
              flex: 1,
              height: HEADER_HEIGHT,
              minHeight: HEADER_HEIGHT,
              maxHeight: HEADER_HEIGHT,
            }}
          >
            자산총계
          </HeaderCell>
          <HeaderCell
            className={`caption-12-rg`}
            style={{
              flex: 1,
              height: HEADER_HEIGHT,
              minHeight: HEADER_HEIGHT,
              maxHeight: HEADER_HEIGHT,
            }}
          >
            부채총계
          </HeaderCell>
          <HeaderCell
            className={`caption-12-rg`}
            style={{
              flex: 1,
              height: HEADER_HEIGHT,
              minHeight: HEADER_HEIGHT,
              maxHeight: HEADER_HEIGHT,
            }}
          >
            자본총계
          </HeaderCell>
        </Container>
        <Container>
          <CellConatiner
            className={`caption-12-rg`}
            style={{
              flex: 1,
              minHeight: CELL_HEIGHT,
              justifyContent: "flex-end",
            }}
          >
            {AddComma(props.data?.revenue || 0)}
          </CellConatiner>
          <CellConatiner
            className={`caption-12-rg`}
            style={{
              flex: 1,
              minHeight: CELL_HEIGHT,
              justifyContent: "flex-end",
            }}
          >
            {AddComma(props.data?.operatingProfit || 0)}
          </CellConatiner>
          <CellConatiner
            className={`caption-12-rg`}
            style={{
              flex: 1,
              minHeight: CELL_HEIGHT,
              justifyContent: "flex-end",
            }}
          >
            {AddComma(props.data?.netIncome || 0)}
          </CellConatiner>
          <CellConatiner
            className={`caption-12-rg`}
            style={{
              flex: 1,
              minHeight: CELL_HEIGHT,
              justifyContent: "flex-end",
            }}
          >
            {AddComma(props.data?.totalAssets || 0)}
          </CellConatiner>
          <CellConatiner
            className={`caption-12-rg`}
            style={{
              flex: 1,
              minHeight: CELL_HEIGHT,
              justifyContent: "flex-end",
            }}
          >
            {AddComma(props.data?.totalLiabilities || 0)}
          </CellConatiner>
          <CellConatiner
            className={`caption-12-rg`}
            style={{
              flex: 1,
              minHeight: CELL_HEIGHT,
              justifyContent: "flex-end",
            }}
          >
            {AddComma(props.data?.totalEquity || 0)}
          </CellConatiner>
        </Container>
      </FlexColumn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Container = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
`;

const HeaderCell = styled.div`
  width: 100%;
  cursor: default;
  background-color: var(--gray-500);
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  border-top: 2px solid #000;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 8px;

  color: #000;

  &:nth-child(1) {
    border-left: 2px solid #000;
  }
`;

const CellConatiner = styled.div`
  width: 100%;
  cursor: default;

  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  background: var(--background-contents-01, #fff);

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 8px;

  color: #000;

  &:nth-child(1) {
    border-left: 2px solid #000;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
