import React, { useEffect } from "react";
import styled from "styled-components";

import { EvaluationContactRequestProps } from "../../../evaluation/interface/Evaluation.interface";

import { ContractEvaluationCard } from "../components/ContractEvaluationCard";

interface Props {
  selectedId?: number;
  cards: EvaluationContactRequestProps[];
  selectCard: (id: number) => void;
}

export const ContractEvaluationRadio: React.FC<Props> = (props) => {
  const { selectedId, cards, selectCard } = props;

  useEffect(() => {
    console.log(cards);
  });
  return (
    <Container>
      {cards.map((item, key) => {
        return (
          <ContractEvaluationCard
            key={key}
            isSelected={item.investmentAssessmentId === selectedId}
            card={item}
            onClick={() => {
              selectCard(item.investmentAssessmentId);
            }}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 20px;
`;
