import axios from "axios";
import * as api from "../../../api/api";

import saveAs from "file-saver";

import {
  AcceleratorReportRangeProps,
  PortfolioQuarterReportProps,
  PortfolioReportDocumentListProps,
  PortfolioReportDocumentProps,
} from "../interface/Reporting.interface";
import {
  AcceleratedPortfolioProps,
  ReportingFile,
  ReportingFileBody,
  ReportingFileReuploadBody,
} from "../../reporting-detail/interface/ReportingDetail.interface";

export const getReportRange = async (): Promise<
  AcceleratorReportRangeProps | undefined
> => {
  try {
    const result = await axios.get(api.getReportRangeByAccelerator());
    if (result.status === 200) {
      return new Promise((resolve) => resolve(result.data));
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const openQuarter = async (
  year: number,
  quarterNum: number
): Promise<boolean | undefined> => {
  try {
    const result = await axios.post(api.openQuarter(), {
      year,
      quarterNum,
    });
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const closeQuarter = async (): Promise<boolean | undefined> => {
  try {
    const result = await axios.patch(api.closeQuarter());
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const openSelectPortfolioQuarter = async (
  year: number,
  quarterNum: number,
  portfolioArr: { portfolioId: number }[]
): Promise<boolean | undefined> => {
  try {
    const result = await axios.post(api.openSelectPortfolioQuarter(), {
      year,
      quarterNum,
      portfolioArr,
    });
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const getOpenQuarter = async (): Promise<
  { year: number; quarterNum: number } | undefined
> => {
  try {
    const result = await axios.get(api.getOpenedQuarter());
    if (result.status === 200 && result.data.result && result.data.quarter) {
      return new Promise((resolve) => resolve(result.data.quarter));
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const getReportList = async (
  param: any
): Promise<
  | {
      acPfDocument: PortfolioReportDocumentListProps[];
      portfolioList: PortfolioQuarterReportProps[];
      isExist: boolean;
    }
  | undefined
> => {
  try {
    const result = await axios.get(api.getReportListByAccelerator(), {
      params: param,
    });
    console.log(result);
    if (result.status === 200) {
      const portfolioList = result.data.portfolioList.map(
        (item: any): PortfolioQuarterReportProps => {
          return {
            quarterId: item.quarterId,
            isOpen: item.isOpen,
            portfolio: item.portfolio,
            pfReportDocument: item.pfReportDocument.map(
              (document: any): PortfolioReportDocumentProps => {
                return {
                  pfReportDocumentId: document.pfReportDocumentId,
                  acPfDocument: document.acPfDocument,
                  uploadStatus: document.uploadStatus,
                  documentFile: document.documentFile,
                  pfReportDocumentData: document.pfReportDocumentData,
                };
              }
            ),
          };
        }
      );
      const acPfDocument = result.data.acPfDocument.map(
        (pfDocument: any): PortfolioReportDocumentListProps => {
          return {
            acPfDocumentId: pfDocument.acPfDocumentId,
            name: pfDocument.name,
            extension: pfDocument.extension,
            required: pfDocument.required,
          };
        }
      );
      return new Promise((resolve) =>
        resolve({ acPfDocument, portfolioList, isExist: result.data.isExist })
      );
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    console.log(error);
    return new Promise((reject) => reject(undefined));
  }
};

export const getFileType = (extension?: string): string => {
  if (!extension) return "";
  switch (extension) {
    case "excel":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "pdf":
      return "application/pdf";
    default:
      return "application/pdf";
  }
};

export const downloadFileFromUrl = (url: string): void => {
  if (!url) return;

  const anchor = document.createElement("a");
  anchor.href = url;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const documentDownload = async (
  reportDocumentId: number,
  fileName: string,
  extension: string
): Promise<boolean | undefined> => {
  try {
    const res = await axios.get(api.getReportingFile(reportDocumentId));
    if (res.status !== 200) return new Promise((reject) => reject(false));

    if (res.data.type === "file") {
      const blob = new Blob([new Uint8Array([...res.data.file.data]).buffer], {
        type: getFileType(extension),
      });
      await saveAs(blob, `${fileName}`);
    } else if (res.data.type === "url") {
      await downloadFileFromUrl(res.data.downloadUrl);
    }
    return new Promise((resolve) => resolve(true));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};
export const getAllAccelratingPortfolio = async (): Promise<
  AcceleratedPortfolioProps[] | undefined
> => {
  try {
    const result = await axios.get(api.getallAcceleratedPortfolio());
    if (result.status === 200) {
      return new Promise((resolve) => resolve(result.data.portfolioList));
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const getPortfolioReportDetail = async (
  portfolioId: number,
  param: any
): Promise<{ isOpen: boolean; reportData: ReportingFile[] } | undefined> => {
  try {
    const result = await axios.get(api.getPortfolioReportDetail(portfolioId), {
      params: param,
    });
    if (result.status === 200) {
      console.log(result);
      const data = result.data.documents.map(
        (item: any): ReportingFile => ({
          acPfDocumentId: item.acPfDocumentId,
          extension: item.extension,
          name: item.name,
          required: item.required,
          tag: item.tag,
          file: item.file,
        })
      );
      return new Promise((resolve) =>
        resolve({ isOpen: result.data.isOpen, reportData: data })
      );
    }
    return new Promise((resolve) => resolve(undefined));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const confirmStockChangeListReport = async (
  reportDocumentId: number,
  issuingPrice: number
): Promise<boolean | undefined> => {
  try {
    const result = await axios.patch(api.updateConfirmStockChangeListReport(), {
      pfReportDocumentId: reportDocumentId,
      issuingPrice: issuingPrice,
    });
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(false));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const confirmReport = async (
  reportDocumentId: number,
  pfFinancialStatement?: any,
  pfEmployeeHistory?: any,
  pfPatent?: any
): Promise<boolean | undefined> => {
  try {
    const result = await axios.patch(api.updateConfirmReport(), {
      pfReportDocumentId: reportDocumentId,
      pfFinancialStatement: pfFinancialStatement,
      pfEmployeeHistory: pfEmployeeHistory,
      pfPatent: pfPatent,
    });
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(false));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const rejectReport = async (
  reportDocumentId: number,
  reason: string
): Promise<boolean | undefined> => {
  try {
    const result = await axios.patch(api.updateRejectReport(), {
      pfReportDocumentId: reportDocumentId,
      comment: reason,
    });
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(false));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const cancelReport = async (
  reportDocumentId: number
): Promise<boolean | undefined> => {
  try {
    const result = await axios.patch(api.updateCancelReport(), {
      pfReportDocumentId: reportDocumentId,
    });
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(false));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

export const readReport = async (
  reportDocumentId: number
): Promise<boolean | undefined> => {
  try {
    const result = await axios.patch(api.updateReadReport(), {
      pfReportDocumentId: reportDocumentId,
    });
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(false));
  } catch (error) {
    return new Promise((reject) => reject(undefined));
  }
};

//리얼 데이터 저장
export const registeRealStockTransaction = async (
  data: any
): Promise<boolean | undefined> => {
  try {
    const result = await axios.post(api.postRealStockTransaction(), data);
    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(false));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

//특정 포트폴리오 파일 올리기
export const postReportingFile = async (
  param: ReportingFileBody
): Promise<{ success: boolean; isFullRequired?: boolean } | undefined> => {
  try {
    const formData = new FormData();

    formData.append("documentFile", param.documentFile);
    formData.append("acPfDocumentId", `${param.acPfDocumentId}`);
    formData.append("year", `${param.year}`);
    formData.append("quarterNum", `${param.quarterNum}`);
    formData.append("isValidation", `${param.isValidation === false ? 0 : 1}`);
    formData.append("fileNum", `${(param.index || 0) + 1}`.padStart(2, "0"));
    formData.append("portfolioId", `${param.portfolioId}`);
    if (param.templateId) {
      formData.append("templateId", `${param.templateId}`);
    }
    if (param.data) {
      formData.append("data", JSON.stringify(param.data));
    }

    console.log(param.documentFile);
    const result = await axios.post(api.postReportingFile(), formData);

    if (result.status === 200) {
      return new Promise((resolve) => resolve(result.data));
    }
    return new Promise((resolve) => resolve({ success: false }));
  } catch (e) {
    return new Promise((rejects) => rejects(undefined));
  }
};
//특정 포트폴리오 파일 교체하기 올리기
export const changeReportingFile = async (
  param: ReportingFileReuploadBody
): Promise<boolean | undefined> => {
  try {
    if (!param.pfReportDoumentId) return;

    const formData = new FormData();

    formData.append("documentFile", param.documentFile);
    formData.append("isValidation", `${param.isValidation === false ? 0 : 1}`);
    formData.append("uploadStatusId", `${param.uploadStatusId || ""}`);
    formData.append("portfolioId", `${param.portfolioId}`);
    if (param.pfReportDocumentDataId) {
      formData.append(
        "pfReportDocumentDataId",
        `${param.pfReportDocumentDataId}`
      );
    }

    if (param.data) {
      formData.append("data", JSON.stringify(param.data));
    }

    console.log(param.documentFile);
    const result = await axios.post(
      api.changeReportingFile(param.pfReportDoumentId),
      formData
    );

    if (result.status === 200) {
      return new Promise((resolve) => resolve(true));
    }
    return new Promise((resolve) => resolve(false));
  } catch (e) {
    return new Promise((rejects) => rejects(undefined));
  }
};

export const getSBPData = async (
  pfReportDocumentDataId: number
): Promise<any | undefined> => {
  try {
    const result = await axios.get(api.getSBPData(pfReportDocumentDataId));
    if (result.status === 200) {
      return new Promise((resolve) =>
        resolve(result.data.pfReportDocumentData)
      );
    }
    return new Promise((rejects) => rejects(undefined));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};

// 재무제표 단일 조회 -리포트
export const getFinanceDataByReporting = async (
  portfolioId: number,
  year: number,
  quarterNum: number
): Promise<any | undefined> => {
  const result = await axios.get(api.reportPortfolioIdFinance(portfolioId), {
    params: {
      year,
      quarterNum,
    },
  });
  if (result.status === 200) {
    const data = result.data;
    return new Promise((resolve) => resolve(data.data));
  }
  return new Promise((reject) => reject(undefined));
};

//요약재무제표 정보 수정

export const saveFinancialStatementData = async (
  data: any
): Promise<any | undefined> => {
  try {
    const result = await axios.put(api.reportData(), data);
    if (result.status === 200)
      return new Promise((resolve) => resolve(result.data));
    return new Promise((rejects) => rejects(undefined));
  } catch (error) {
    return new Promise((rejects) => rejects(undefined));
  }
};
