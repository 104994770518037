import { Position, Responsibility, UserInfo } from "../../../../../type/data";

export type StructureHeader =
  | "NotAuthority"
  | "BoardMember"
  | "Quitter"
  | "AllMembers";

export type OtherAuthority =
  | "BoardMember"
  | "ReportManager"
  | "DownloadOriginal";

export const defaultStructureHeaderList: StructureHeaderProps[] = [
  { type: "NotAuthority", title: "미인증", isOptional: true },
  { type: "BoardMember", title: "경영진" },
  { type: "Quitter", title: "퇴사자" },
  { type: "AllMembers", title: "전체 구성원" },
];

export interface StructureHeaderProps {
  type: StructureHeader;
  title: string;
  count?: number;
  isOptional?: boolean;
}

export interface StructureInfo {
  isNew?: boolean;
  isChange?: boolean;
  isDelete?: boolean;
  acStructureId?: number | string;
  name: string;
  nickName?: string;
  parent?: number | string;
  structureIndex?: number;
  depth?: (number | string | undefined)[];
  userCount?: number;
}

export interface StructureDataProps {
  name: string;
  structureIndex?: number;
  structureInfo: StructureInfo;
  userData: UserInfo[];
}

export interface SelectorProps {
  id: number | string;
  text: string;
  data: any;
}

export interface StructureTreeProps {
  info: StructureInfo;
  children: StructureTreeProps[];
}

export interface ResponsibilityProps {
  responsibility: Responsibility;
  isUsed: boolean;
}

export interface PositionProps {
  position: Position;
  isUsed: boolean;
}
