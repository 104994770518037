import React from "react";
import { useMediaQuery } from "react-responsive";

import { NavigationLayout } from "../../components/navigation/NavigationLayout";
import MobileReportingDetail from "./mobile/MobileReportingDetail";
import DeskReportingDetail from "./desktop/DeskReportingDetail";

const ReportingDetail = () => {
  //const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const isMobile = false;
  return isMobile ? <MobileReportingDetail /> : <DeskReportingDetail />;
};
export default ReportingDetail;
