import React, { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface Props {
  cellIndex?: number;
  minDate?: string;
  maxDate?: string;
  data?: string;
  width?: number;
  onChangeData?: (date: string) => void;
}
export const DatePickerMUI: React.FC<Props> = ({
  cellIndex,
  minDate,
  maxDate,
  data,
  width = 200,
  onChangeData,
}) => {
  const [value, setValue] = useState<any | undefined>(
    data ? dayjs(data) : undefined
  );

  useEffect(() => {
    setValue(data ? dayjs(data) : undefined);
  }, [data]);

  useEffect(() => {
    console.log({ cellIndex, value });
  }, [value]);

  return (
    <Container className="body-14-rg" width={width}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DemoItem>
            <DatePicker
              value={value}
              slotProps={{ textField: { size: "small" } }}
              format="YYYY-MM-DD"
              minDate={minDate ? dayjs(minDate) : undefined}
              maxDate={maxDate ? dayjs(maxDate) : undefined}
              onChange={(date) => {
                setValue(date);
                if (date && onChangeData) {
                  onChangeData(date.toString());
                }
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </Container>
  );
};

const Container = styled.div<{ width: number }>`
  width: ${(props) => `${props.width}px`};
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  .css-10o2lyd-MuiStack-root {
    padding-top: 0;

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-color: var(--gray-300);
    }
  }
  .MuiStack-root {
    padding-top: 0px !important;
  }
`;
