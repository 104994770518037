import React from "react";
import styled, { css } from "styled-components";
import { addCommasToIntegerPart } from "../../../../../../../common/commonUtil";
import { StockChangeHistoryRealData } from "../interface/Stock.interface";
import { InvestmentRealDataResult } from "../service/StockHolderService";
import { RealDataTableHeaderProps } from "./RealDataHeader";
import { StockChangeErrorReport } from "./StockChangeHistoryTableModal";

interface Props {
  index: number;
  viewerScale: number;
  completMakeStockHolderList?: InvestmentRealDataResult;
  selectedErrorReport?: StockChangeErrorReport;
  headers: RealDataTableHeaderProps[];
  rowData: StockChangeHistoryRealData;
  transfromPixel: (value: number) => number;
  onClickItem: (x: number, y: number) => void;
  onHoverItem: (x: number, y: number) => void;
}

const RealDataRow: React.FC<Props> = ({
  index,
  viewerScale,
  completMakeStockHolderList,
  selectedErrorReport,
  headers,
  rowData,
  transfromPixel,
  onClickItem,
  onHoverItem,
}) => {
  const getData = (key: string) => {
    switch (key) {
      case "date":
        return rowData.data.date;
      case "stockChangeType":
        return rowData.data.stockChangeType;
      case "stageType":
        return rowData.data.stageType;
      case "stockholderName":
        return rowData.data.stockholderName;
      case "registrationNumber":
        return rowData.data.registrationNumber;
      case "relationship":
        return rowData.data.relationship;
      case "country":
        return rowData.data.country;
      case "stockType":
        return rowData.data.stockType;
      case "fluctuateStockNumber":
        return rowData.data.fluctuateStockNumber;
      case "faceValue":
        return rowData.data.faceValue;
      case "issuingPrice":
        return rowData.data.issuingPrice;
      case "totalInvestmentAmount":
        return rowData.data.totalInvestmentAmount;
      case "seller":
        return rowData.data.seller;
      case "sellerRegistrationNumber":
        return rowData.data.sellerRegistrationNumber;
      default:
        return rowData.data.note;
    }
  };

  const isSelected = (x: number, y: number) => {
    if (selectedErrorReport === undefined) return "";

    if (selectedErrorReport.rangeX.sX === selectedErrorReport.rangeX.eX) {
      if (
        selectedErrorReport.rangeX.sX <= x &&
        selectedErrorReport.rangeX.eX >= x &&
        selectedErrorReport.rangeY.sY <= y &&
        selectedErrorReport.rangeY.eY >= y
      ) {
        if (
          selectedErrorReport.rangeY.sY === y &&
          selectedErrorReport.rangeY.eY === y
        )
          return "select";

        if (selectedErrorReport.rangeY.sY === y) return "y__front";

        if (selectedErrorReport.rangeY.eY === y) return "y__end";
        return "y__middle";
      }
    }

    if (selectedErrorReport.rangeY.sY === selectedErrorReport.rangeY.eY) {
      if (
        selectedErrorReport.rangeX.sX <= x &&
        selectedErrorReport.rangeX.eX >= x &&
        selectedErrorReport.rangeY.sY <= y &&
        selectedErrorReport.rangeY.eY >= y
      ) {
        if (
          selectedErrorReport.rangeX.sX === x &&
          selectedErrorReport.rangeX.eX === x
        )
          return "select";

        if (selectedErrorReport.rangeX.sX === x) return "x__front";

        if (selectedErrorReport.rangeX.eX === x) return "x__end";
        return "x__middle";
      }
    }

    return "";
  };

  return (
    <Container transfromPixel={transfromPixel}>
      {headers.map((item, idx) => {
        const data = getData(item.key);

        let isBlur = false;
        if (completMakeStockHolderList?.errIndex) {
          const errIndex = completMakeStockHolderList?.errIndex || 0;
          const blurCount = completMakeStockHolderList?.blurCount || 0;
          isBlur = errIndex + blurCount < index;
        }

        return (
          <CellConatiner
            key={idx}
            className={`body-14-rg ${data.state} ${
              completMakeStockHolderList?.errIndex &&
              completMakeStockHolderList.errIndex === index
                ? "error"
                : ""
            } ${isBlur ? "blur" : ""} ${isSelected(idx, index)}`}
            style={{
              width: transfromPixel(item.width * viewerScale),
              minWidth: transfromPixel(item.width * viewerScale),
              maxWidth: transfromPixel(item.width * viewerScale),
              height: transfromPixel(50 * viewerScale),
              minHeight: transfromPixel(50 * viewerScale),
              maxHeight: transfromPixel(50 * viewerScale),
              paddingLeft: transfromPixel(20 * viewerScale),
              paddingRight: transfromPixel(20 * viewerScale),
              fontSize: transfromPixel(14 * viewerScale),
              justifyContent:
                item.type === "number" ? "flex-end" : "flex-start",
              borderRight:
                idx !== headers.length - 1
                  ? `1px solid #e3e4e5`
                  : `1px solid transparent`,
            }}
            onClick={() => {
              onClickItem(idx, index);
            }}
            onMouseEnter={() => {
              onHoverItem(idx, index);
            }}
          >
            {item.type === "number"
              ? addCommasToIntegerPart(data.data)
              : data.data}
          </CellConatiner>
        );
      })}
    </Container>
  );
};

const Container = styled.tr(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    width: max-content;
    display: flex;
    flex-direction: row;
  `
);

const CellConatiner = styled.td`
  height: 100%;
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e3e4e5;

  &.success {
    background-color: #ffffff;
    color: #1f2022;
    cursor: default;
  }

  &.error {
    background-color: #fff2f3;
    color: #f6444f;
    cursor: pointer;
  }

  &.select {
    border: 1px solid #f6444f !important;
  }

  &.y__front {
    border-top: 1px solid #f6444f !important;
    border-left: 1px solid #f6444f !important;
    border-right: 1px solid #f6444f !important;
  }

  &.y__middle {
    border-left: 1px solid #f6444f !important;
    border-right: 1px solid #f6444f !important;
  }

  &.y__end {
    border-left: 1px solid #f6444f !important;
    border-right: 1px solid #f6444f !important;
    border-bottom: 1px solid #f6444f !important;
  }

  &.x__front {
    border-top: 1px solid #f6444f !important;
    border-left: 1px solid #f6444f !important;
    border-bottom: 1px solid #f6444f !important;
  }

  &.x__middle {
    border-top: 1px solid #f6444f !important;
    border-bottom: 1px solid #f6444f !important;
  }

  &.x__end {
    border-top: 1px solid #f6444f !important;
    border-right: 1px solid #f6444f !important;
    border-bottom: 1px solid #f6444f !important;
  }

  &.blur {
    background: #f3f4f4;
    filter: blur(1px);
    color: #7e8086;
  }
`;

export default RealDataRow;
