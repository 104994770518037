import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { DateTime, UserInfo } from "../../../../../type/data";
import { PickerDateTime } from "../../../../../components-2.0v/date-picker/PickerDateTime";
import { PrePitchingData } from "../../interface/PrePitchingDetail.interface";
import { getDateToDateTime } from "../../../../../common/commonUtil";
import { UserItem } from "../components/UserItem";
import ColumnTitleBox from "../../../../../components/box/ColumnTitleBox";

interface Props {
  isEditable: boolean;
  pitchingData: PrePitchingData;
  allUser: UserInfo[];
  statusLoaded: (allResponsed: boolean, responseZero: boolean) => void;
}
export const PitchingProgressStatus: React.FC<Props> = (props) => {
  const { allUser, isEditable, pitchingData, statusLoaded } = props;
  const [invitedUsers, updateInvitedUser] = useState<UserInfo[]>([]);
  const [responsedUsers, updateResponsedUser] = useState<UserInfo[]>([]);
  const [noResponsedUsers, updateNoResponsedUser] = useState<UserInfo[]>([]);
  const [date, updateDate] = useState<DateTime | undefined>(undefined);

  useEffect(() => {
    if (pitchingData.preEvaluationDate) {
      updateDate(getDateToDateTime(pitchingData.preEvaluationDate));
    }
    if (
      pitchingData.pfPreEvaluationResult &&
      pitchingData.pfPreEvaluationResult.length > 0
    ) {
      let allSelectedUser: UserInfo[] = [];
      let allResponsedUser: UserInfo[] = [];
      let allNoResponsedUser: UserInfo[] = [];
      pitchingData.pfPreEvaluationResult.forEach((item) => {
        const user = allUser.find((data) => data.id === item.acUser.acUserId);
        if (user) {
          allSelectedUser.push({ ...user });
          if (item.templateJson !== null) {
            allResponsedUser.push({ ...user });
          } else {
            allNoResponsedUser.push({ ...user });
          }
        }
      });

      updateInvitedUser(allSelectedUser);
      updateResponsedUser(allResponsedUser);
      updateNoResponsedUser(allNoResponsedUser);

      statusLoaded(
        allSelectedUser.length === allResponsedUser.length &&
          allNoResponsedUser.length === 0,
        allResponsedUser.length === 0
      );
    }
  }, [pitchingData, allUser]);
  return (
    <Container>
      <ColumnTitleBox title="초대한 심사위원" titleWidth={128}>
        <TextBox className={`body-14-rg`}>{invitedUsers.length}명</TextBox>
      </ColumnTitleBox>
      <ColumnTitleBox title="심사 시작일자" titleWidth={128}>
        <TextBox className={`body-14-rg`} style={{ marginLeft: -10 }}>
          <PickerDateTime
            date={date}
            onChangeDate={(date) => {}}
            disabled={true}
          />
        </TextBox>
      </ColumnTitleBox>
      <ColumnTitleBox title="응답 완료" titleWidth={128}>
        <UserList>
          {responsedUsers.map((item) => {
            return <UserItem user={item} isEditable={false} />;
          })}
          {responsedUsers.length === 0 && (
            <TextBox className="body-14-rg">없음</TextBox>
          )}
        </UserList>
      </ColumnTitleBox>
      <ColumnTitleBox title="응답 대기" titleWidth={128}>
        <TextBox className={`body-14-rg`}>
          <UserList>
            {noResponsedUsers.map((item) => {
              return <UserItem user={item} isEditable={false} />;
            })}
            {noResponsedUsers.length === 0 && (
              <TextBox className="body-14-rg">없음</TextBox>
            )}
          </UserList>
        </TextBox>
      </ColumnTitleBox>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const TextBox = styled.div`
  flex: 1 0 0;
  height: auto;
  min-height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-01, #1f2022);

  &.is__empty {
    color: var(--text-05);
  }
`;

const UserList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 8px;
`;
