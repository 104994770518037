import React, { useEffect, useRef, useState } from "react";

const usePDFViewer = (file: File) => {
  const fullPageRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const rootRefWidth = useRef<number>(0);
  const loaded = useRef<boolean>(false);

  const scrollXY = useRef<{ x: number; y: number } | undefined>(undefined);

  const [blobFile, updateBlobFile] = useState<Blob | undefined>(undefined);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState<number>(1);
  const [renderedPageNumber, setRenderedPageNumber] = useState<number | null>(
    null
  );
  const [renderedScale, setRenderedScale] = useState<number | null>(null);

  const isLoading =
    renderedPageNumber !== pageNumber || renderedScale !== scale;

  const prevPage = () => {
    if (numPages === 0 || pageNumber === 1 || !loaded.current) return;
    setPageNumber((prev) => prev - 1);
    loaded.current = false;
  };

  const nextPage = () => {
    if (numPages === 0 || pageNumber === numPages || !loaded.current) return;
    setPageNumber((prev) => prev + 1);
    loaded.current = false;
  };

  const onChangeScale = (offSet: number) => {
    let newScale = scale + offSet;

    if (newScale > 2) newScale = 2;
    if (newScale < 1) newScale = 1;

    setScale(newScale);
  };

  const onLoadSuccess = async (res: any) => {
    loaded.current = true;
    setNumPages(res.numPages);
  };

  const onPageLoaded = () => {
    if (scrollXY.current !== undefined) {
      if (fullPageRef.current) {
        console.log(`scrollSY`, scrollXY.current);
        fullPageRef.current.scrollTop = scrollXY.current.y;
        fullPageRef.current.scrollLeft = scrollXY.current.x;
      }
    }
  };

  const onScrollHandler = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.currentTarget;
    if (target && target.scrollLeft !== 0 && target.scrollTop !== 0) {
      scrollXY.current = { x: target.scrollLeft, y: target.scrollTop };
      console.log(target, scrollXY.current);
    }
  };

  const initPage = () => {
    setPageNumber(1);
    setNumPages(0);
  };

  useEffect(() => {
    initPage();
    const reader = new FileReader();
    reader.onload = (e) => {
      if (reader.result instanceof ArrayBuffer) {
        const blob = new Blob([reader.result], { type: file.type });
        updateBlobFile(blob);
      }
    };
    reader.readAsArrayBuffer(file);
  }, [file]);

  useEffect(() => {
    if (rootRef.current) {
      rootRefWidth.current = rootRef.current.clientWidth;
      rootRef.current.style.maxWidth = `${rootRef.current.clientWidth}px`;
    }
  }, [rootRef.current]);

  return [
    fullPageRef,
    scrollXY,
    isLoading,
    rootRefWidth,
    scale,
    loaded,
    blobFile,
    rootRef,
    numPages,
    pageNumber,
    renderedPageNumber,
    renderedScale,
    setRenderedPageNumber,
    setRenderedScale,
    setPageNumber,
    setNumPages,
    prevPage,
    nextPage,
    onChangeScale,
    onLoadSuccess,
    onScrollHandler,
    onPageLoaded,
  ] as const;
};

export default usePDFViewer;
