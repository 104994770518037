import React, { memo } from "react";
import styled from "styled-components";

interface Props {
  isLoggedIn?: boolean;
  onLoginLattice: () => void;
  handleSignInClick: () => void;
}

export const GoogleLoginBtn = memo((props: Props) => {
  const { isLoggedIn, onLoginLattice, handleSignInClick } = props;
  return (
    <GoogleButtonContainer
      onClick={() => {
        if (isLoggedIn) {
          onLoginLattice();
        } else {
          handleSignInClick();
        }
      }}
    >
      <img
        src="https://www.svgrepo.com/show/355037/google.svg"
        style={{
          width: "20px",
          height: "20px",
        }}
      />
      <span className="body-14-md" style={{ color: "var(--text-04)" }}>
        google로 시작하기
      </span>
    </GoogleButtonContainer>
  );
});
const GoogleButtonContainer = styled.div`
  width: 332px;
  height: 46px;
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  transition: all 0.2s ease-in-out;

  cursor: pointer;

  :hover {
    background-color: var(--hover-100);
  }
`;
