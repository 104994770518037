import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Sector } from "../Sector";

import { SectorDescription } from "../SectorDescription";
import { InvestmentAssessment } from "../../../../../type/data";

import { CompanyMemberTable } from "./organism/CompanyMemberTable";
import { StakeStatementTable } from "./organism/StakeStatementTable";
import { FinancialStatementTable } from "./organism/FinancialStatementTable";
import { CapitalIncreaseHistoryTable } from "./organism/CapitalIncreaseHistoryTable";
import { DebtTable } from "./organism/DebtTable";
import { FinancingPlanTable } from "./organism/FinancingPlanTable";
import { ReactComponent as Check } from "../../../../../assets/image/icon_check_new.svg";
import NothingChecked from "../table/NothingChecked";
import { GovernmentProjectTable } from "./organism/GovernmentProjectTable";
import { CostEstimationPlanTable } from "./organism/CostEstimationPlanTable";
import { FinancialSourchingPlanTable } from "./organism/FinancialSourchingPlanTable";
import { MilestoneKpiTable } from "./organism/MilestoneKpiTable";

interface Props {
  isReadOnly: boolean;
  data?: InvestmentAssessment;
  onChangeData?: (data: InvestmentAssessment) => void;
}

export const Section4: React.FC<Props> = (props: Props) => {
  const { data, onChangeData } = props;

  const [IAGovProjectCheck, updateIAGovProjectCheck] = useState<
    boolean | undefined
  >(false);
  const [IAFPCheck, updateIAFPCheck] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (!data) return;

    if (data.investmentAssessmentGovernmentProject) {
      updateIAGovProjectCheck(
        data.investmentAssessmentGovernmentProject?.isChecked
      );
    }

    if (data.investmentAssessmentFinancingPlan) {
      updateIAFPCheck(data.investmentAssessmentFinancingPlan?.isChecked);
    }

    console.log(data);
  }, [data]);

  if (!data) return null;
  return (
    <Container>
      <Sector title="정부 지원 과제" id="Section4_1">
        <FlexColumn style={{ gap: 8 }}>
          <GovernmentProjectTable
            isReadOnly={props.isReadOnly}
            disabled={IAGovProjectCheck}
            dataList={data.investmentAssessmentGovernmentProject?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentGovernmentProject: {
                    ...data.investmentAssessmentGovernmentProject,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
          <NothingChecked
            defaultValue={data.investmentAssessmentGovernmentProject?.isChecked}
            onChangeData={(checked) => {
              if (
                onChangeData &&
                data.investmentAssessmentGovernmentProject !== undefined
              ) {
                updateIAGovProjectCheck(checked);
                data.investmentAssessmentGovernmentProject.isChecked = checked;
                onChangeData(data);
              }
            }}
          />
        </FlexColumn>
      </Sector>

      <Sector title="(Y+2) 자금 소요 계획" id="Section4_2">
        <FlexColumn style={{ gap: 8 }}>
          <SectorDescription
            text={`조달할 자금의 총액은 소요할 자금의 총액 이상이여야 합니다.`}
          />
          <FlexRow style={{ gap: 8 }}>
            <CostEstimationPlanTable
              isReadOnly={props.isReadOnly}
              disabled={false}
              dataList={data.investmentAssessmentCostEstimationPlan?.data}
              onDataChange={(newDataList) => {
                if (onChangeData) {
                  const newData: InvestmentAssessment = {
                    ...data,
                    investmentAssessmentCostEstimationPlan:
                      data.investmentAssessmentCostEstimationPlan
                        ? {
                            ...data.investmentAssessmentCostEstimationPlan,
                            data: newDataList,
                          }
                        : {
                            isRequired: false,
                            isChecked: false,
                            data: [...newDataList],
                          },
                  };

                  onChangeData(newData);
                }
              }}
            />
            <FinancialSourchingPlanTable
              isReadOnly={props.isReadOnly}
              disabled={false}
              dataList={data.investmentAssessmentFinancialSourcingPlan?.data}
              onDataChange={(newDataList) => {
                if (onChangeData) {
                  const newData: InvestmentAssessment = {
                    ...data,
                    investmentAssessmentFinancialSourcingPlan:
                      data.investmentAssessmentFinancialSourcingPlan
                        ? {
                            ...data.investmentAssessmentFinancialSourcingPlan,
                            data: newDataList,
                          }
                        : {
                            isRequired: false,
                            isChecked: false,
                            data: [...newDataList],
                          },
                  };

                  onChangeData(newData);
                }
              }}
            />
          </FlexRow>
        </FlexColumn>
      </Sector>

      <Sector title="(Y+2) 투자 단계별 마일스톤 및 주요 KPI" id="Section4_3">
        <FlexColumn style={{ gap: 8 }}>
          <MilestoneKpiTable
            isReadOnly={props.isReadOnly}
            disabled={false}
            dataList={data.investmentAssessmentMilestoneKPI?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentMilestoneKPI:
                    data.investmentAssessmentMilestoneKPI
                      ? {
                          ...data.investmentAssessmentMilestoneKPI,
                          data: newDataList,
                        }
                      : {
                          isRequired: false,
                          isChecked: false,
                          data: [...newDataList],
                        },
                };

                onChangeData(newData);
              }
            }}
          />
        </FlexColumn>
      </Sector>

      <Sector title="자금 조달 계획" id="Section4_4">
        <FlexColumn style={{ gap: 8 }}>
          <FinancingPlanTable
            isReadOnly={props.isReadOnly}
            disabled={IAFPCheck}
            dataList={data.investmentAssessmentFinancingPlan?.data}
            onDataChange={(newDataList) => {
              if (onChangeData) {
                const newData: InvestmentAssessment = {
                  ...data,
                  investmentAssessmentFinancingPlan: {
                    ...data.investmentAssessmentFinancingPlan,
                    data: newDataList,
                  },
                };

                onChangeData(newData);
              }
            }}
          />
          <NothingChecked
            defaultValue={data.investmentAssessmentFinancingPlan?.isChecked}
            onChangeData={(checked) => {
              if (
                onChangeData &&
                data.investmentAssessmentFinancingPlan !== undefined
              ) {
                updateIAFPCheck(checked);
                data.investmentAssessmentFinancingPlan.isChecked = checked;
                onChangeData(data);
              }
            }}
          />
        </FlexColumn>
      </Sector>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px 56px 0px;
  gap: 56px;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
